import { ReadyState } from 'meeting/angularjs/shared/communication/socket.service';
import { bind, EventEmitter, interval, platform } from 'utils/util';

const UPDATE_INTERVAL = 1000;
const SHOW_TIMEOUT = 5000;
const HIDE_TIMEOUT = 2000;
const START_OPEN_TIMEOUT = 5000;



export default class ConnectionNotificationService {
  static get $inject() {
    return [
      'notificationService',
      'meetingSocketService',
      'waitingRoomSocketService',
    ];
  }

  constructor(
    notificationService,
    meetingSocketService,
    waitingRoomSocketService
  ) {
    bind(this);
    EventEmitter.setup(this, ['show']);

    this.notificationService = notificationService;
    this.meetingSocketService = meetingSocketService;
    this.waitingRoomSocketService = waitingRoomSocketService;

    this.startOpenTimeout = null;
    this.startOpenNotification = null;

    this.notification = null;
    this.showTimeout = null;
    this.hideTimeout = null;
    this.updateInterval = null;


    this.meetingSocketService.on('startOpen', () => this._onStartOpen(this.meetingSocketService));
    this.meetingSocketService.on('open', this._onOpen);
    this.meetingSocketService.on('close localClose', this._onClose);

    this.waitingRoomSocketService.on(
      'startOpen',
      () => this._onStartOpen(this.waitingRoomSocketService)
    );
    this.waitingRoomSocketService.on('open', this._onOpen);
    this.waitingRoomSocketService.on('close localClose', this._onClose);
  }


  get isShown() {
    return this.notification || this.startOpenNotification;
  }

  _onStartOpen(socketService) {
    this.socketService = socketService;
    if(!this.notification) {
      $timeout.cancel(this.startOpenTimeout);
      this.startOpenTimeout = $timeout(this._showStartOpenNotification, START_OPEN_TIMEOUT);
    }
  }

  _showStartOpenNotification() {
    this.startOpenNotification = this.notificationService.warning(
      gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'This is taking longer than expected. Try refreshing the page or find a better internet connection.'
      ),
      { delay: -1 }
    );
  }

  _hideStartOpenNotification() {
    $timeout.cancel(this.startOpenTimeout);
    if(this.startOpenNotification) {
      this.startOpenNotification.cancel();
      this.startOpenNotification = null;
    }
  }


  _onOpen() {
    this._hideStartOpenNotification();
    this._hide();
  }

  _onClose(reason, reopen) {
    if(reopen) {
      this._show();
    } else {
      this._hide();
    }
  }


  _show() {
    this._cancelTimeouts();

    if(this.notification) {
      this._update();
      this.updateInterval = interval.setInterval(this._update, UPDATE_INTERVAL);

    } else {
      this.showTimeout = $timeout(this._showNow, SHOW_TIMEOUT);
    }
  }

  _showNow() {
    this._cancelTimeouts();
    this._hideStartOpenNotification();

    if(!this.notification) {
      this.notification = this.notificationService.warning(
        this._getMessage(),
        { delay: -1 }
      );
      this.updateInterval = interval.setInterval(this._update, UPDATE_INTERVAL);
      this.emit('show', true);
    }
  }


  _hide() {
    this._cancelTimeouts();

    if(this.notification) {
      this._update();
      this.hideTimeout = $timeout(this._hideNow, HIDE_TIMEOUT);
    }
  }

  _hideNow() {
    this._cancelTimeouts();

    if(this.notification) {
      this.notification.cancel();
      this.notification = null;
      this.emit('show', false);
    }
  }


  _update() {
    if(this.notification) {
      this.notification.setMessage(this._getMessage());
    }
  }


  _getMessage() {
    if(this.socketService.state === ReadyState.OPEN) {
      return gettextCatalog.getString('You have been reconnected!');

    } else {
      let seconds = platform(0, Math.ceil(this.socketService.getTimeUntilReopen() / 1000));
      let button = this.socketService.state === ReadyState.CONNECTING ?
        `<span
          class="btn my-1 disabled"
          translate
        >
          Reconnecting...
        </span>` :
        `<span
          class="btn my-1"
          ng-click="ctrl.connectionNotificationService.openSocket()"
          translate
        >
          Retry now
        </span>`;

      /* eslint-disable max-len */
      return gettextCatalog.getString(
        '<div {{ attributes }}>Uh-oh... The connection seems to be lost. Are you connected to the Internet? Attempting to reconnect in {{ seconds }}s. {{ button }}</div>',
        {
          attributes: 'ng-controller="ConnectionNotificationCtrl as ctrl"',
          seconds: seconds,
          button: button,
        }
      );
      /* eslint-enable max-len */
    }
  }


  _cancelTimeouts() {
    $timeout.cancel(this.showTimeout);
    this.showTimeout = null;

    $timeout.cancel(this.hideTimeout);
    this.hideTimeout = null;

    interval.clearInterval(this.updateInterval);
    this.updateInterval = null;
  }

  openSocket() {
    this.socketService.open();
    this._update();
  }
}
