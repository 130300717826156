import { object } from 'utils/util';
import MediaDevice from '../../shared/audioVideo/MediaDevice';

const ICONS = {
  audioinput:  'utils/icons/tl/24x24_mic_filled.svg',
  videoinput:  'utils/icons/tl/24x24_videocam_outline.svg',
  audiooutput: 'utils/icons/tl/24x24_volume_up.svg',
};

/**
 * DevicePickerInfo represents a dropdown for picking a device for a particular stream kind.
 *
 * It stores which particular device is active, which kind of stream the picker is for and has
 * helper functions for listing all devices in the device picker dropdown.
 *
 * Whenever a new device is picked, these object are remade with the new device as activeDevice
 */
export default class DevicePickerInfo {
  constructor(
    mediaDeviceService,
    activeDevice,
    kind,
    stream,
    shouldShowPicker,
    _shouldShowTester
  ) {
    this.mediaDeviceService = mediaDeviceService;
    this.stream = stream;
    this.shouldShowPicker = shouldShowPicker;
    this.kind = kind;
    this.activeDevice = activeDevice;

    this.icon = ICONS[this.kind];
    this.shouldShowTester = _shouldShowTester && (this.kind === 'audiooutput' || this.stream);
  }

  /**
   * A human readable, translated if applicable, identifier of the stream kind
   */
  get name() {
    return gettextCatalog.getString(MediaDevice.Name[this.kind]);
  }

  /**
   * The text value of the dropdown
   */
  get label() {
    if(this.activeDevice) {
      return this.activeDevice.label || this.unknownDeviceLabel;
    } else {
      return gettextCatalog.getString('No {{ deviceName }} active', { deviceName: this.name });
    }
  }

  get unknownDeviceLabel() {
    /// {device} can be "camera", "microphone" or "speaker"
    return gettextCatalog.getString('Unknown {{ device }}', { device: this.name });
  }

  /**
   * Helper function for retrieving all devices of the DevicePicker kind
   */
  get devices() {
    return object.filter(
      this.mediaDeviceService.devices[this.kind],
      (id, device) => device.default || !device.meta
    );
  }
}
