import { ContactForm } from 'contactForm/models/ContactForm';
import { EmailTemplate } from 'emailTemplates/models/EmailTemplate';
import * as fields from 'utils/fields';
import Model from 'utils/models';
import { TimeDelta } from 'utils/util';
import { AppointmentTypeConfig } from './AppointmentTypeConfig';
import { AppointmentLocation } from './appointment-location';

// Keep in sync with src/scheduling/models.py
export const MAX_BOOKING_DAYS = 90;

export const PickHostMethod = Object.freeze({
  SELECT_HOST: 'select_host',
  RANDOM_HOST: 'random_host',
  SELECT_HOST_OR_RANDOM_HOST: 'select_host_or_random_host',
});

export const PaymentOption = Object.freeze({
  NO_PAYMENT: 'no_payment',
  PAYMENT_NECESSARY: 'payment_necessary',
});


function createDefaultEmailTemplate(field) {
  if(ANGULAR_SCOPE.templateInfo) {
    const modelFactory = field.parent.modelFactory;
    return modelFactory.createInstance(EmailTemplate, {
      values: {
        subject: ANGULAR_SCOPE.templateInfo[field.fieldName].subjectDefault,
        text: ANGULAR_SCOPE.templateInfo[field.fieldName].textDefault,
      },
    });
  } else {
    return null;
  }
}

function createDefaultContactForm(field) {
  const modelFactory = field.parent.modelFactory;
  return modelFactory.createInstance(ContactForm);
}


export class AppointmentType extends Model {
  static get fields() {
    return {
      name: new fields.StringField({}),
      description: new fields.StringField({
        default: ''
      }),
      duration: new fields.DurationField({
        default: new TimeDelta({ minutes: 30 })
      }),
      locations: new fields.JSONField({
        default: [AppointmentLocation.ONLINE],
      }),
      offlineAddress: new fields.StringField({
        default: ''
      }),
      pickHostMethod: new fields.ChoiceField({
        choices: Object.values(PickHostMethod),
        default: PickHostMethod.SELECT_HOST,
      }),
      meetingTemplateId: new fields.StringField(),
      customFromDate: new fields.DateField(),
      customToDate: new fields.DateField(),
      timeSlotsInterval: new fields.DurationField({
        default: new TimeDelta({ minutes: 30 }),
      }),
      customMaxDays: new fields.DurationField({
        default: new TimeDelta({ days: 90 })
      }),
      customBuffer: new fields.DurationField({
        default: null,
      }),
      bufferBefore: new fields.DurationField({
        default: new TimeDelta({ minutes: 0 })
      }),
      bufferAfter: new fields.DurationField({
        default: new TimeDelta({ minutes: 0 })
      }),
      showPublic: new fields.BooleanField({
        default: true
      }),
      calendarReminder: new fields.DurationField({}),
      emailReminder: new fields.DurationField({}),

      paymentOption: new fields.ChoiceField({
        choices: Object.values(PaymentOption),
        default: PaymentOption.NO_PAYMENT,
      }),
      paymentAmount: new fields.NumberField(),
      paymentCurrency: new fields.StringField({
        default: 'EUR',
      }),
      paymentTerms: new fields.StringField(),

      appointmentTypeConfigs: new fields.RelatedField({
        Model: AppointmentTypeConfig,
        withIdField: false,
        many: true,
        readOnly: false,
        default: [],
      }),

      confirmationEmailTemplate: new fields.RelatedField({
        Model: EmailTemplate,
        withIdField: false,
        default: createDefaultEmailTemplate,
      }),
      calendarEventTemplate: new fields.RelatedField({
        Model: EmailTemplate,
        withIdField: false,
        default: createDefaultEmailTemplate,
      }),
      reminderEmailTemplate: new fields.RelatedField({
        Model: EmailTemplate,
        withIdField: false,
        default: createDefaultEmailTemplate,
      }),
      cancellationEmailTemplate: new fields.RelatedField({
        Model: EmailTemplate,
        withIdField: false,
        default: createDefaultEmailTemplate,
      }),
      extraInviteeConfirmationEmailTemplate: new fields.RelatedField({
        Model: EmailTemplate,
        withIdField: false,
        default: createDefaultEmailTemplate,
      }),
      extraInviteeCancellationEmailTemplate: new fields.RelatedField({
        Model: EmailTemplate,
        withIdField: false,
        default: createDefaultEmailTemplate,
      }),
      redirectUrl: new fields.StringField(),
      enableRedirectParameters: new fields.BooleanField({ default: true }),
      confirmationScreenAfterBooking: new fields.BooleanField({ default: true }),
      allowGuestCancellationReschedule: new fields.BooleanField({ default: true }),

      contactForm: new fields.RelatedField({
        Model: ContactForm,
        withIdField: false,
        default: createDefaultContactForm,
      }),

      numExtraInviteesAllowed: new fields.NumberField({ default: 0 }),
    };
  }


  static get $inject() {
    return [
      'requestUserService',
    ].concat(Model.$inject);
  }


  constructor(...args) {
    super(...args);

    let activeConfigs = this.appointmentTypeConfigs.filter(config => config.active);
    this._isTeamAppointmentType = !(
      activeConfigs.length === 1
      && activeConfigs[0].userId === this.requestUserService.user.id
    );
    this.previouslyActiveConfigs = null;
  }


  static get canDuplicateFromId() {
    return true;
  }

  // eslint-disable-next-line no-unused-vars
  static get basePath() {
    return 'appointmentTypes';
  }

  static get defaultInclude() {
    return ['appointmentTypeConfigs.user.schedulingConfig.state'];
  }


  getOrCreateAppointmentTypeConfig(user) {
    let config = this.appointmentTypeConfigs.find(config => config.userId === user.id);
    if(!config) {
      config = this.createAppointmentTypeConfig(user);
    }
    return config;
  }
  createAppointmentTypeConfig(user) {
    let config = this.modelFactory.createInstance(AppointmentTypeConfig, {
      values: {
        user: user,
        userId: user.id,
      },
    });
    this.appointmentTypeConfigs.push(config);
    this.markDirty('appointmentTypeConfigs');
    return config;
  }


  get isTeamAppointmentType() {
    return this._isTeamAppointmentType;
  }
  set isTeamAppointmentType(isTeamAppointmentType) {
    if(isTeamAppointmentType === this._isTeamAppointmentType) {
      return;
    }

    this._isTeamAppointmentType = isTeamAppointmentType;
    if(!isTeamAppointmentType) {
      this.rememberActiveAppointmentTypeConfigs();
      this.appointmentTypeConfigs.forEach(config => config.active = false);
      let myConfig = this.getOrCreateAppointmentTypeConfig(this.requestUserService.user);
      myConfig.active = true;
    } else {
      this.restoreActiveAppointmentTypeConfigs();
    }
  }

  rememberActiveAppointmentTypeConfigs() {
    this.previouslyActiveConfigs = this.appointmentTypeConfigs
      .filter(config => config.active);
  }
  restoreActiveAppointmentTypeConfigs() {
    if(this.previouslyActiveConfigs) {
      this.previouslyActiveConfigs.forEach(config => config.active = true);
      this.previouslyActiveConfigs = null;
    }
  }
}

export class UserAppointmentType extends AppointmentType {
  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers) {
    return `users/${identifiers.userId}/appointmentTypes`;
  }
}

export class OrganizationAppointmentType extends AppointmentType {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/appointmentTypes`;
  }
}

export class UserPublicAppointmentType extends AppointmentType {
  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers) {
    return `users/${identifiers.userId}/appointmentTypes/public`;
  }
}

export class OrganizationPublicAppointmentType extends AppointmentType {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/appointmentTypes/public`;
  }
}
