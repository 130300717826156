const PREVIEW_BUTTON = `
<div
  class="preview-button ml-auto mb-1 btn"
  translate
>
  Send me a preview email
</div>
`;

HtmlPreviewMailDirective.$inject = [
  '$compile',
  'apiService',
  'notificationService',
];


// When filling in custom html in a form, this directive makes it possible to send a mail
// containing that custom html snippet to the current user.
export default function HtmlPreviewMailDirective(
  $compile,
  apiService,
  notificationService
) {
  return {
    restrict: 'A',
    link: function($scope, $elem) {
      function linkTextArea() {
        let $elemFormGroup = $elem.parents('.form-group');
        let $elemBtn =  $compile(PREVIEW_BUTTON)($scope);
        $elemBtn.on('click', sendPreviewMail);
        $elemFormGroup.find('.formfield-hint').append($elemBtn);
        updateButton();
        $elem.on('change input', updateButton);
      }

      function sendPreviewMail() {
        let htmlInput = $elem.val();
        if(!htmlInput || $elem.prop('disabled')) {
          return;
        }
        let data = {
          htmlInput: htmlInput
        };
        apiService.post('users/me/emailTemplatePreview', data)
          .then(() => {
            notificationService.success(
              gettextCatalog.getString('Preview email succcesfully sent!')
            );
            setError();
          })
          .catch(error => {
            if(error.response.status === 400) {
              setError(error.message);
            } else {
              notificationService.error(gettextCatalog.getString(
                'Something went wrong sending the email. Please try again later.'
              ));
            }
          });
      }

      function updateButton() {
        let $previewBtn = $elem.parent().find('.preview-button');
        if(!$elem.val() || $elem.prop('disabled')) {
          $previewBtn.addClass('disabled');
        } else {
          $previewBtn.removeClass('disabled');
        }
      }

      if($elem[0].tagName === 'TEXTAREA') {
        linkTextArea($scope, $elem);
      }


      function setError(error) {
        let $elemFormGroup = $elem.parents('.form-group');
        let $elemTextarea = $elemFormGroup.find('textarea');
        let $elemErrorList = $elemFormGroup.find('.errorlist');

        $elemTextarea.removeClass('error');
        $elemErrorList.remove();

        if(error != null) {
          $elemTextarea.addClass('error');

          $elemErrorList = $('<ul class="errorlist"></ul>');
          $elemFormGroup.find('label').after($elemErrorList);

          let $elemError = $('<li class="error"></li>');
          $elemError.text(error);
          $elemErrorList.append($elemError);
        }
      }
    },
  };
}
