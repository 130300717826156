import './angularjs.module';
import './angularjsDowngradedComponents';

import { NgModule } from '@angular/core';
import { ContactFormConfigModule } from './contact-form-config';
import { ContactFormPageModule } from './contact-form-page';
import { ContactFormPreviewModule } from './contact-form-preview';
import { ContactFormPublicModule } from './contact-form-public';
import { ContactFormResponsesModule } from './contact-form-responses';
import { ContactFormsModule } from './contact-forms';


@NgModule({
  exports: [
    ContactFormConfigModule,
    ContactFormPreviewModule,
    ContactFormPageModule,
    ContactFormPublicModule,
    ContactFormResponsesModule,
    ContactFormsModule,
  ],
})
export class ContactFormModule {}
