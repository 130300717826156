import { format } from 'utils/util';

FormInfoDirective.$inject = [
  '$compile',
  'hintService',
];

const TEMPLATE_ICON = `
  <i
    class="icon icon--inline clickable ml-2"
    svg="'utils/icons/tl/24x24_info_badged_filled.svg'"
    ng-show="!hint.shouldShow"
    ng-click="hint.toggle()"
  ></i>
`;

const TEMPLATE_FIELD = `
  <div
    class="field field--info p"
    ng-show="hint.shouldShow"
  >
    <field-icon [type]="'info'"></field-icon>
    <div class="flex-1 d-flex align-items-center">
      <div class="flex-1">%s</div>
      <div
        class="btn btn--round btn--sm my--1 mr--1"
        ng-click="hint.setSeen()"
      >
        <div class="btn__icon" svg="'utils/icons/tl/24x24_close_outline.svg'"></div>
      </div>
    </div>
  </div>
`;


export default function FormInfoDirective(
  $compile,
  hintService
) {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {
      let infoId = $attr.formInfoId;
      let info = $attr.formInfo;

      let hint = hintService.get(infoId);

      let $childScope = $scope.$new();
      $childScope.hint = hint;

      let $elemIcon = angular.element(TEMPLATE_ICON);
      $elem.append($elemIcon);
      $elem.addClass('d-flex align-items-center');
      $compile($elemIcon)($childScope);

      let htmlField = format(TEMPLATE_FIELD, info);
      let $elemField = angular.element(htmlField);
      $elem.after($elemField);
      $compile($elemField)($childScope);
    }
  };
}
