import { Component, Inject } from '@angular/core';
import {
  ContactForm, ContactFormAnswer, ContactFormResponse, QuestionType
} from 'contactForm/models';
import { DataPageComponent } from 'dashboard/data-page/data-page.component';
import { SiteService } from 'utils/site.service';
import { UrlService } from 'utils/url.service';


@Component({
  selector: 'contact-form-responses',
  templateUrl: './contact-form-responses.component.html',
})
export class ContactFormResponsesComponent extends DataPageComponent {
  override perPage = 10;

  private contactFormId = ANGULAR_SCOPE.contactFormId;
  public contactForm: ContactForm;
  public responses: ContactFormResponse[] = [];
  public error?: string;


  constructor(
    @Inject('hintService') private hintService,
    @Inject('usageTrackingService') private usageTrackingService,

    public urlService: UrlService,
    public siteService: SiteService,
  ) {
    super();
  }


  get headerText() {
    if(this.contactForm) {
      return $localize `Responses for ${this.contactForm.name}`;
    } else {
      return $localize `Responses…`;
    }
  }


  override fetch() {
    return this.fetchResponses();
  }

  override fetchInitial() {
    return this.fetchContactForm();
  }


  fetchContactForm() {
    const modelConfig = {
      model: ContactForm,
      identifiers: { id: this.contactFormId },
    };
    return this.readCancelable(modelConfig).then(({ data: contactForm }) => {
      this.contactForm = contactForm;
    });
  }


  fetchResponses() {
    const modelConfig = {
      model: ContactFormResponse,
    };
    const apiConfig = {
      params: {
        perPage: this.perPage,
        page: this.page,
        filter: `contactFormId = "${this.contactFormId}"`,
      },
    };
    return this.listCancelable(modelConfig, apiConfig)
      .then(({ data: responses }) => {
        this.responses = responses as ContactFormResponse[];
      });
  }


  isEmpty(contactFormAnswer: ContactFormAnswer) {
    const answer = contactFormAnswer.answer;
    return (
      answer === ''
      || answer == null
      || Array.isArray(answer) && answer.length === 0
    );
  }

  isURL(answer: ContactFormAnswer) {
    return answer.type === QuestionType.URL;
  }
  isAttachment(answer: ContactFormAnswer) {
    return answer.type === QuestionType.ATTACHMENT;
  }
  isRegular(answer: ContactFormAnswer) {
    return !this.isURL(answer) && !this.isAttachment(answer);
  }

  getLink(text: string) {
    if(!text.match(/^https?:\/\//)) {
      text = `http://${text}`;
    }
    return text;
  }


  get shouldShowTLFPromo() {
    return this.siteService.site.isStandalone
      && this.requestUserService.user.isAdmin
      && !this.requestUserService.user.organization.hasActiveTeamleaderIntegration
      && this.hintService.get('contactFormResponsesTlfPromo').shouldShow;
  }

  hideTLFPromo() {
    this.hintService.get('contactFormResponsesTlfPromo').setSeen();
    this.usageTrackingService.createSegmentEvent('TLFPromoClosed', 'contactForm');
  }

  trackTLFPromoInteraction() {
    this.usageTrackingService.createSegmentEvent('TLFPromoClicked', 'contactForm');
  }
}
