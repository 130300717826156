angular.module('vecteraUtil')
  .run(['tmhDynamicLocaleCache', function(tmhDynamicLocaleCache) {
    var PLURAL_CATEGORY = {
      ZERO: 'zero',
      ONE: 'one',
      TWO: 'two',
      FEW: 'few',
      MANY: 'many',
      OTHER: 'other'
    };

    function getDecimals(n) {
      n = n + '';
      var i = n.indexOf('.');
      return (i === -1) ? 0 : n.length - i - 1;
    }

    function getVF(n, opt_precision) {
      var v = opt_precision;

      if(undefined === v) {
        v = Math.min(getDecimals(n), 3);
      }

      var base = Math.pow(10, v);
      var f = ((n * base) | 0) % base;
      return { v: v, f: f };
    }

    tmhDynamicLocaleCache.put('nl', {
      'DATETIME_FORMATS': {
        'AMPMS': [
          'a.m.',
          'p.m.'
        ],
        'DAY': [
          'zondag',
          'maandag',
          'dinsdag',
          'woensdag',
          'donderdag',
          'vrijdag',
          'zaterdag'
        ],
        'ERANAMES': [
          'voor Christus',
          'na Christus'
        ],
        'ERAS': [
          'v.Chr.',
          'n.Chr.'
        ],
        'FIRSTDAYOFWEEK': 0,
        'MONTH': [
          'januari',
          'februari',
          'maart',
          'april',
          'mei',
          'juni',
          'juli',
          'augustus',
          'september',
          'oktober',
          'november',
          'december'
        ],
        'SHORTDAY': [
          'zo',
          'ma',
          'di',
          'wo',
          'do',
          'vr',
          'za'
        ],
        'SHORTMONTH': [
          'jan.',
          'feb.',
          'mrt.',
          'apr.',
          'mei',
          'jun.',
          'jul.',
          'aug.',
          'sep.',
          'okt.',
          'nov.',
          'dec.'
        ],
        'STANDALONEMONTH': [
          'januari',
          'februari',
          'maart',
          'april',
          'mei',
          'juni',
          'juli',
          'augustus',
          'september',
          'oktober',
          'november',
          'december'
        ],
        'WEEKENDRANGE': [
          5,
          6
        ],
        'fullDate': 'EEEE d MMMM y',
        'longDate': 'd MMMM y',
        'medium': 'd MMM y HH:mm:ss',
        'mediumDate': 'd MMM y',
        'mediumTime': 'HH:mm:ss',
        'short': 'd/MM/yy HH:mm',
        'shortDate': 'd/MM/yy',
        'shortTime': 'HH:mm'
      },
      'NUMBER_FORMATS': {
        'CURRENCY_SYM': '\u20ac',
        'DECIMAL_SEP': ',',
        'GROUP_SEP': '.',
        'PATTERNS': [
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 3,
            'minFrac': 0,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '',
            'posPre': '',
            'posSuf': ''
          },
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 2,
            'minFrac': 2,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '\u00a0\u00a4',
            'posPre': '',
            'posSuf': '\u00a0\u00a4'
          }
        ]
      },
      'id': 'nl-be',
      'localeID': 'nl_BE',
      'pluralCat': function(n, opt_precision) {
        var i = n | 0;
        var vf = getVF(n, opt_precision);
        if(i === 1 && vf.v === 0) {
          return PLURAL_CATEGORY.ONE;
        }
        return PLURAL_CATEGORY.OTHER;
      }
    });
  }]);