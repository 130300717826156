import MeetingRecordingFactory from './meetingRecordingFactory.service';
import RecordingService from './recording.service';
import RecorderService from './recorder.service';
import RecordingController from './recording.controller';
import RemoteRecordingModalController from './remoteRecordingModal.controller';


angular
  .module('meeting')

  .service('meetingRecordingFactory', MeetingRecordingFactory)
  .service('recordingService', RecordingService)
  .service('recorderService', RecorderService)

  .controller('RecordingCtrl', RecordingController)
  .controller('RemoteRecordingModalCtrl', RemoteRecordingModalController);
