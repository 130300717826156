import template from './tooltips.html?raw';

import { platform, Rect } from 'utils/util';
import { Position } from './tooltip.service';
import { TOOLTIP_SPACING } from '../../cssFramework/variables.js';

const SCREEN_MARGIN = 2;

/* eslint-disable comma-spacing, array-bracket-spacing */
const alternativePositions = Object.freeze({
  [Position.TOP]:    [Position.BOTTOM, Position.RIGHT,  Position.LEFT ],
  [Position.BOTTOM]: [Position.TOP   , Position.RIGHT,  Position.LEFT ],
  [Position.LEFT]:   [Position.RIGHT , Position.BOTTOM, Position.TOP  ],
  [Position.RIGHT]:  [Position.LEFT  , Position.BOTTOM, Position.RIGHT],
});
/* eslint-enable comma-spacing, array-bracket-spacing */


class TooltipsController {
  static get $inject() {
    return [
      '$element',
      'tooltipService',
    ];
  }

  constructor(
    $elem,
    tooltipService
  ) {
    this._bind();

    this.$elem = $elem;
    this.tooltipService = tooltipService;

    this.isShown = false;
    this.$elemParent = null;
    this.content = null;
    this.position = null;

    this.$elemTooltip = null;
    this.$elemTooltipContent = null;
    this.$elelmTooltipArrow = null;
    this.showTimeout = null;
  }

  _bind() {
    this._onShow = this._onShow.bind(this);
    this._onHide = this._onHide.bind(this);
    this._updatePosition = this._updatePosition.bind(this);
  }

  $onInit() {
    this.$elemTooltip = this.$elem.find('.tooltip');
    this.$elemTooltipContent = this.$elemTooltip.find('.tooltip__content');
    this.$elemTooltipArrow = this.$elemTooltip.find('.tooltip__arrow');

    this.tooltipService.on('show', this._onShow);
    this.tooltipService.on('hide', this._onHide);
  }


  _onShow($elem, content, position) {
    this.isShown = true;
    this.$elemParent = $elem;
    this.content = content;
    this.position = position;

    // Hide the element until its position is set
    this._hide();

    // Let the size settle
    this.showTimeout = $timeout(this._updatePosition);
  }


  _onHide() {
    this.isShown = false;
    this.$parentElem = null;
    this.content = null;

    $timeout.cancel(this.showTimeout);
    this.showTimeout = null;
  }


  _hide() {
    this.$elemTooltip.css('visibility', 'hidden');
  }


  _updatePosition() {
    if(!this._isParentVisible) {
      this._hide();
      return;
    }

    let position, anchor, rect;

    let positions = [this.position]
      .concat(alternativePositions[this.position])
      .concat([this.position]);
    for(let i = 0; i < positions.length; i++) {
      position = positions[i];
      [anchor, rect] = this._getCoordinates(position);
      if(this._isOnScreen(anchor, rect, position)) {
        break;
      }
    }

    this.$elemTooltip.css({
      visibility: 'visible',
      left: Math.round(anchor.x),
      top:  Math.round(anchor.y),
    });
    this.$elemTooltipContent.css({
      left: Math.round(rect.left),
      top:  Math.round(rect.top),
    });

    Object.values(Position).forEach(p => {
      this.$elemTooltip.toggleClass(     'tooltip--'        + p, p === position);
      this.$elemTooltipArrow.toggleClass('tooltip__arrow--' + p, p === position);
    });
  }


  get _isParentVisible() {
    return (
      this.$elemParent[0]
      && this.$elemParent[0].offsetParent != null
    );
  }


  _getCoordinates(position) {
    let rectDocument = Rect.fromFixedAncestor(this.$elemParent[0]);
    let rectViewport = Rect.fromViewport();
    let rectTooltip = Rect.fromElem(this.$elemTooltipContent[0]);
    let rectParent = Rect.fromElem(this.$elemParent[0]);

    let anchor = {};
    let rect = new Rect({
      left: 0,
      top: 0,
      width: rectTooltip.width,
      height: rectTooltip.height,
    });


    switch(position) {
      case Position.BOTTOM:
        anchor.y = rectParent.bottom + TOOLTIP_SPACING + rectDocument.top;
        break;

      case Position.TOP:
        anchor.y = rectParent.top - TOOLTIP_SPACING + rectDocument.top,
        rect.top = -rectTooltip.height;
        break;

      case Position.RIGHT:
        anchor.x = rectParent.right + TOOLTIP_SPACING + rectDocument.left;
        break;

      case Position.LEFT:
        anchor.x = rectParent.left - TOOLTIP_SPACING + rectDocument.left;
        rect.left = -rectTooltip.width;
        break;
    }

    if(this._isVertical(position)) {
      anchor.x = rectParent.left + rectParent.width / 2 + rectDocument.left;
      rect.left = platform(
        SCREEN_MARGIN  - anchor.x,
        -rect.width / 2,
        rectViewport.width - SCREEN_MARGIN - anchor.x - rect.width
      );
    } else {
      anchor.y = rectParent.top + rectParent.height / 2 + rectDocument.top;
      rect.top = platform(
        SCREEN_MARGIN  - anchor.y,
        -rect.height / 2,
        rectViewport.height - SCREEN_MARGIN - anchor.y - rect.height
      );
    }

    return [anchor, rect];
  }


  _isOnScreen(anchor, rect, position) {
    let rectViewport = Rect.fromViewport();
    if(this._isVertical(position)) {
      return (
        anchor.y + rect.top >= SCREEN_MARGIN
        && anchor.y + rect.bottom <= rectViewport.height - SCREEN_MARGIN
      );
    } else {
      return (
        anchor.x + rect.left >= SCREEN_MARGIN
        && anchor.x + rect.right <= rectViewport.width - SCREEN_MARGIN
      );
    }
  }


  _isVertical(position) {
    return position === Position.TOP || position === Position.BOTTOM;
  }
}


export default {
  controller: TooltipsController,
  controllerAs: 'tooltipsCtrl',
  template,
};
