import { errors, object, logger, EventEmitter, ColorManager } from 'utils/util';
import Session from './Session';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';
import { array } from 'utils/util';

const State = Session.State;
const colorManager = new ColorManager();


export const SYNCED_PROPERTIES = Object.freeze({
  username:                        { default: ''                    },
  email:                           { default: ''                    },
  firstName:                       { default: '', writable: true    },
  lastName:                        { default: ''                    },
  fullName:                        { default: ''                    },
  shortName:                       { default: ''                    },
  initials:                        { default: ''                    },
  profileImage:                    { default: ''                    },

  isAuthenticated:                 { default: false                 },
  isAdmin:                         { default: false                 },
  organization:                    { default: {}                    },

  languageCode:                    { default: '', writable: true    },

  emailIsVerified:                 { default: true                  },

  meetingTutorialShowWhiteboard:   { default: false, writable: true },
  meetingTutorialShowSettings:     { default: false, writable: true },
  meetingTutorialShowCamMic:       { default: false, writable: true },
  meetingTutorialShowInvite:       { default: false, writable: true },
  meetingTutorialShowParticipants: { default: false, writable: true },
  meetingTutorialShowContent:      { default: false, writable: true },
  meetingShowWhiteboardLimit:      { default: false, writable: true },

  thirdPartyProviderData:          { default: {}                    },
});


export default class User {
  static get State() {
    return State;
  }


  static _getInfo() {
    throw new errors.NotImplementedError('The method User._getInfo() must be overriden');
  }
  static _setInfo() {
    throw new errors.NotImplementedError('The method User._setInfo() must be overriden');
  }


  constructor(userService, id) {
    EventEmitter.setup(this, [
      'state',
      'join', 'exit', 'sleep', 'wakeup',
      'knockJoin', 'knockExit', 'knockSleep', 'knockWakeup'
    ]);

    this.userService = userService;
    this._id = id;

    object.forEach(SYNCED_PROPERTIES, (property, config) => {
      let propertyConfig = {
        get: this._getSyncedProperty.bind(this, property),
      };
      if(config.writable) {
        propertyConfig.set = this._setSyncedProperty.bind(this, property);
      }
      Object.defineProperty(this, property, propertyConfig);
    });

    this.sessions = new Set();
    this.extra = {};

    this.state = null;
    this.stateBeforeSleep = null;
    this.colorIndex = null;
    this.color = colorManager.getDefaultColor();

    this._updateState();
  }


  get isInitialized() {
    // Only userService.me can be uninitialized. This happens when you load a meeting room as an
    // unauthentiated user (not even authenticated as a lazy user).
    return this._id != null;
  }
  get id() {
    if(!this.isInitialized) {
      throw new errors.IllegalStateError('The user has not been initialized');
    }
    return this._id;
  }
  set id(id) {
    this._id = id;
  }

  get isMe() {
    return this === this.userService.me;
  }

  get isLazy() {
    return !this.isAuthenticated;
  }

  get stateWithoutSleep() {
    return this.state === State.ASLEEP ?
      this.stateBeforeSleep :
      this.state;
  }


  isDead() {
    return this.state === Session.State.DEAD;
  }

  isKnocking() {
    return this.state === Session.State.KNOCKING;
  }

  isAlive() {
    return this.state === Session.State.ALIVE;
  }

  isAsleep() {
    return this.state === Session.State.ASLEEP;
  }


  _getSyncedProperty(property) {
    if(!this.isInitialized) {
      return SYNCED_PROPERTIES[property].default;
    }

    let info = User._getInfo(this, true);
    if(info.hasOwnProperty(property)) {
      return info[property];
    } else {
      return SYNCED_PROPERTIES[property].default;
    }
  }

  _setSyncedProperty(property, value) {
    this.set(property, value).catch(error => {
      logger.withContext({ userId: this.id }).warn(error);
    });
  }


  /**
   * Set one or more properties on the local user instance.
   *
   * @param {string|object} property - Either a property name, or an object that contains multiple
   *  property-value mappings.
   * @param {any} value - If property is a string: the corresponding value. If property is an
   *  object: this parameter is ignored.
   * @returns {Promise} A promise that is resolved when the changes have been written to the
   *  backend, or rejected when writing the changes has failed.
   */
  set(property, value) {
    if(!this.isMe) {
      return;
    }

    let properties = object.isObject(property) ?
      property :
      { [property]: value };

    let isDirty = object.some(properties, (property, value) =>  value !== this[property]);
    if(isDirty) {
      return User._setInfo(this, properties);
    } else {
      return $q.resolve();
    }
  }



  /**
   * Set the full name of a user as a single string.
   *
   * The name is parsed into a first and last name as follows: The part before the first space is
   * the first name, everything else is the last name.
   *
   * @param {string} fullName - The full name of the user.
   * @returns {Promise} A promise that is resolved when the changes have been written to the
   *  backend, or rejected when writing the changes has failed.
   */
  setFullName(fullName) {
    let parts = fullName.split(' ');
    let firstName = parts[0];
    let lastName = parts.slice(1).join(' ');
    return this.set({
      firstName: firstName,
      lastName: lastName,
    });
  }


  /*******************
   * Manage sessions *
   *******************/

  get accessLevel() {
    let accessLevels = [...this.sessions]
      .filter(session => (
        session.state === Session.State.ALIVE
        || session.state === Session.State.ASLEEP
        && session.stateBeforeSleep === Session.State.ALIVE
      ))
      .map(session => session.accessLevel);
    accessLevels.push(AccessLevel.SHOULD_KNOCK);
    return array.max(accessLevels, accessLevel => accessLevel.level);
  }


  addSession(session) {
    if(this.sessions.has(session)) {
      throw new errors.IllegalStateError('Duplicate session added');
    }

    session.setUser(this);
    this.sessions.add(session);
    this._updateState();
  }


  removeSession(session) {
    if(!this.sessions.has(session)) {
      throw new errors.IllegalStateError('Session does not exist');
    }

    session.setUser(null);
    this.sessions.delete(session);
    this._updateState();
  }


  /*********************************
   * Locally calculated properties *
   *********************************/

  _updateState() {
    let numSessions = {};
    Object.values(State).forEach(state => {
      numSessions[state] = 0;
    });
    this.sessions.forEach(session => {
      numSessions[session.state]++;
    });

    if(numSessions[State.ALIVE] > 0) {
      this.state = State.ALIVE;
      this.stateBeforeSleep = State.ALIVE;

    } else if(numSessions[State.KNOCKING] > 0) {
      this.state = State.KNOCKING;
      this.stateBeforeSleep = State.KNOCKING;

    } else if(numSessions[State.ASLEEP] > 0) {
      this.state = State.ASLEEP;

    } else {
      this.state = State.DEAD;
    }

    this._updateColor();
    this._updateExtra();
  }


  _updateColor() {
    if(this.state === State.ALIVE && this.colorIndex === null) {
      this.colorIndex = colorManager.claimColorIndex();

    } else if(this.state !== State.ALIVE && this.colorIndex !== null) {
      colorManager.forsakeColorIndex(this.colorIndex);
      this.colorIndex = null;
    }

    this.color = colorManager.getColor(this.colorIndex);
  }


  _updateExtra() {
    this.extra = {};
    Array.from(this.sessions)
      .filter(session => !session.isDead())
      .forEach(session => {
        Object.assign(this.extra, session.extra);
      });
  }
}
