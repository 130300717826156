import { browser, format, logger, file, EventEmitter, platform, Rect } from 'utils/util';

import VecteraFile from './files/VecteraFile';
import WhiteboardTile from './WhiteboardTile';
import Page from './Page';
import { Status } from './documents/DocumentRenderer';


const MAX_IMPLICIT_NAME_LENGTH = 30;
const MIN_ZOOM_LEVEL = 0.1;
const MAX_ZOOM_LEVEL = 5;
const ZOOM_BUTTON_FACTOR = 1.2;
const DRAW_DEBOUNCED_TIMEOUT = 300;

// The fabric.js canvas has a slight margin to make it larger than the tile. This margin
// is relative to the size of the tile, so a .3 margin means a 30% size increase in every
// dimension
export const RENDER_MARGIN = 0.3;

// Any fabric event that happens within the following pixel distance from the tile edge, is
// scrolled into view. This margin is relative to contentSize, so a .05 margin means that at least
// 5% of the contentSize dimension is visible between the input and the edge of the tile
const SCROLL_INTO_VIEW_MARGIN = 0.05;

// Zoom out so that the entire whiteboard is visible on fullscreen 16:9 screens at init
export const DEFAULT_ZOOM_LEVEL = 0.90;

/**
 * Drawing the whiteboard is handled by various files, and loosely mimics the MVC pattern
 * - Whiteboard: The Model of the whiteboard, containing the necessary data structures.
 * - WhiteboardTileBodyDirective: The Controller of the whiteboard, uses information from the model
 *   to correctly draw the whiteboard, and handle pan and zoom events.
 * - WhiteboardTileScrollbarDirective: renders interactive scrollbars around the whiteboard.
 * - Renderers: Because all whiteboard content is rendered onto canvases, there is no traditional
 *   HTML view. Instead, we have several renderers, which each renders onto its own canvas, that
 *   function as a sort of Controller-View hybrid.
 *   - PaperRenderer: Stores all info about interactive shapes, without actually rendering them
 *     onto the screen. This is offloaded to FabricRenderer and FormulaRenderer.
 *   - FabricRenderer: Render fabric.js shapes onto a canvas, and manage interactivity.
 *   - FormulaRenderer: Render Mathquil.js formulas onto a canvas, and manage interactivity.
 *   - DocumentRenderer: If a whiteboard has a document, DocumentRenderer renders it as a kind of
 *     non-interactive background.
 *
 * The whiteboard dimensions are tracked using 2 variables:
 * - contentSize: the dimensions of the original imported content
 * - contentRect: the dimensions of the current content, including eventual expansions. This area
 *   is visible in the UI as a white "paper", while the area outside of it is greyed out.
 *
 * Syncing the whiteboard view between meeting room peers happens by communicating 2 parameters:
 * contentFocus and zoomLevel. These parameters are the "single source of truth". To render the
 * whiteboard contents, these parameters are translated into the local parameters contentOffset
 * and pixelsPerPoint.
 *
 * Finally, within the DOM, the whiteboard consists of the following elements:
 * - WhiteboardTileBodyDirective.$elemCanvasWrapper is the html element for the interactive canvas.
 *   This is a bit larger than the tile itself, so that some content is pre-rendered while panning/
 *   zooming. This html element is stationary* relative to the tile: when the whiteboard is panned,
 *   the content of the contained canvases will be translated by their respective renderers, but
 *   the canvases stay in the same place.
 *   *Actually, while a pan/zoom action is taking place, $elemCanvasWrapper is transformed using
 *   css transforms to enable a responsive UI. As soon as the pan/zoom action is done, \
 *   $elemCanvasWrapper is restored to its original position, and the renderers re-render at the
 *   viewport.
 * - WhiteboardTileBodyDirective.$elemWindow is a visual representation of contentRect. Inside it,
 *   the background is white; outside it, the background is light grey.
 *
 * Distances in the whiteboard are based on one of two 2-dimensional ortogonal coordinate systems
 *
 * The content coordinate system:
 *  - the origin is the origin of the contentRect,
 *  - the unit of length for x/y axes are determined by the contentSize width/height respectively
 * This means that the point (0,0) is the origin of contentRect and the point (1,1) is the
 * bottom right corner of the contentSize
 *
 * The tile coordinate system:
 *  - the origin is top left corner of the tile,
 *  - the unit of length for x/y axes are determined by the tile rectBody width/height respectively
 * This means that the point (0,0) is the origin of tile rectBody and the point (1,1) is the
 * bottom right corner of the tile rectBody
 *
 * Note that while these are orthoganal, these are not cartesian coordinate systems.
 */

/**
 * A location within the whiteboard coordinate system
 *
 * @typedef {Object} ContentCoordinate
 * @property {number} x
 * @property {number} y
 */

/**
 * A dimension within the whiteboard coordinate system
 *
 * @typedef {Object} ContentDimension
 * @property {number} width
 * @property {number} height
 */


/**
 * A Rect within the whiteboard coordinate system
 *
 * not to be confused with this.contentRect, which is a Rect with dimensions denoted in pts
 * instead of in the whiteboard coordinate system
 *
 * @typedef {Rect} ContentRect
 */

/**
 * A location within the tile coordinate system
 *
 * @typedef {Object} TileCoordinate
 * @property {number} x
 * @property {number} y
 */

export default class Whiteboard {
  constructor(
    apiService,
    meetingService,
    notificationService,
    tileFactory,
    documentRendererFactory,
    paperRendererFactory,

    id,
    name,
    nameIsManual,
    contentSize,
    isSnapshot
  ) {
    this._bind();

    this.apiService = apiService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;

    EventEmitter.setup(this, [
      'remove',
      'draw',
      'name',
      'viewport', // this.contentFocus or this.zoomLevel is updated
      'contentRect', // this.contentRect is updated
      'page',
      'pageAdd',
      'pageRemove',
      'initializing',
      'addDefaultShapes'
    ]);

    this.id = id;
    this.maxPageId = 0;

    this.initializing = true;
    this.removed = false;
    this.removedSynced = false;
    this.forceImmediateDraw = true;

    this.name = name;
    this.nameSynced = name;
    this.nameIsManual = nameIsManual;
    this.autoNameShape = null;

    this.pages = [];
    this.page = null;
    this.pageIndex = -1;
    this.pageIndexSynced = -1;

    /**
     * The base dimensions of the whiteboard content, denoted in deskTop publishing points (pts).
     * Imported PDF files have their width/height read from the encoded dimensions (at 72 pts per
     * inch), other files are resized to fit inside an A4_SIZE frame. Empty whiteboards are also
     * initialized with an empty A4_SIZE frame.
     *
     * This parameter forms the base of the whiteboard coordinate system. All other parameter are
     * relative to the origin and dimensions of the contentSize.
     */
    this.contentSize = {
      width: contentSize.width,
      height: contentSize.height
    };

    /**
     * The pts dimensions of the interactive whiteboard, the part on which can be drawn.
     * It keeps track of the dimensions relative to the origin (the top-left corner) of the
     * contentRect at initialisation: Take cr0 the contentRect at init and cr1 the contentRect
     * after a transformation
     *
     * cr0: 0,0,100,100 -> the interactive content is 100pts wide and 100pts high
     *  -> expanding the content by 100pts to the left results in cr1: -100,0,200,100
     *  -> expanding the content by 100pts to the right results in cr1: 0,0,200,100
     *  -> expanding the content by 100pts to the top results in cr1: 0,-100,100,200
     *  -> expanding the content by 100pts to the bottom results in cr1: 0,0,100,200
     *
     * @type {Rect}
     */
    this.contentRect = new Rect({
      left: 0,
      top: 0,
      width: contentSize.width,
      height: contentSize.height,
    });

    /**
     * contentOffset tracks the relative location of the origin of contentRect
     * with regards to the tile.
     *
     * Consider a contentRect `cr = [0, 0, 100, 100]`. If contentOffset `co = [0, 0]` the topleft
     * corner of the contentRect is placed in the topleft corner of the tile. `co = [0.5, 0.5]`
     * moves the topleft corner of contentRect to the center of the tile.
     * If contentRect is extended to the left so that `cr = [-100, 0, 200, 100]`, the origin of
     * contentRect (the point with coordinates [0, 0]) no longer coincides with its topleft corner
     * (which now has coordinates [-100, 0]). So `co = [0.5, 0.5]` still places the origin of
     * contentRect in the center of the tile, but visually the topleft corner of contentRect has
     * now moved to the left.
     *
     * This variable is not communicated between peers but it is calculated locally based on the
     * value of contentFocus and zoomLevel
     *
     * @type {TileCoordinate)
     */
    this.contentOffset = {
      x: null,
      y: null,
    };

    /**
     * The "focal point" is the point that is shown at the center of the whiteboard. It is
     * expressed in relative coordinates, where [0, 0] is the topleft corner of the original
     * content, and [1, 1] is the bottomright corner. We initialize it at [0.5, 0.5] to center the
     * content.
     * Similar to contentOffset, when contentRect is extended so that its origin and topleft corner
     * no longer coincide, a focal point of [0, 0] denotes the origin of contentRect, not its
     * topleft corner.
     *
     * This value should be synced for all participants. It is the "single source of truth" on
     * which the rest of the drawing of the whiteboard is based.
     * contentFocusSynced  is the value that has already been synced with the other participants.
     * During a pan/zoom action, contentFocus and contentFocusSynced will differ for a short while.
     *
     * @type {ContentCoordinate)
     */
    this.contentFocus = {
      x: 0.5,
      y: 0.5,
    };
    this.contentFocusSynced = Object.assign({}, this.contentOffset);

    /**
     * zoomLevel is interpreted such that at 100% zoom, one edge of the original content fits
     * exactly within the tile. The other edge will show more than 100% of its length. Which edge
     * is the "short" edge depends on the aspect ratios of the original content and the tile:
     * when the aspect ratio of the tile is greater than the aspect ratio of the original content,
     * then 100% of the height will be visible. When the aspect ratio of the tile is smaller than
     * the aspect ratio of the original content, then 100% of the width will be visible.
     *
     * @type {number)
     */
    this.zoomLevel = DEFAULT_ZOOM_LEVEL;
    this.zoomLevelSynced = DEFAULT_ZOOM_LEVEL;

    this.tile = tileFactory.create(WhiteboardTile, this);
    this.tile.on('active', this._onTileActive);
    this.tile.on('draw', this._onTileDraw);

    this.document = null;
    this.downloadWhenInactive = false;
    this.documentRenderer = documentRendererFactory.create(this);
    this.paperRenderer = paperRendererFactory.create(this);

    // These properties store the values of this.canvasPan and this.pixelsPerPoint at the moment
    // of the last non-temporary render. The content renderers need these values for asynchronous
    // renders, and we need them to decide whether re-rendering is useful.
    this.canvasSizeRendered = this.canvasSize;
    this.canvasPanRendered = this.canvasPan;
    this.pixelsPerPointRendered = this.pixelsPerPoint;

    /**
     * isSnapshot stores whether the whiteboard is used as a destination for stream snapshots.
     * When a stream snapshot is made, snapshotService will attempt to add this snapshot
     * as a page to an existing snapshot whiteboard. If no whiteboard exists where isSnapshot is
     * true, one will be made.
     *
     * @type {boolean}
     */
    this.isSnapshot = isSnapshot;
  }

  _bind() {
    this._onTileActive = this._onTileActive.bind(this);
    this._onTileDraw = this._onTileDraw.bind(this);
    this._drawDebounced = debounce(this._drawDebounced.bind(this), DRAW_DEBOUNCED_TIMEOUT);
  }


  /**********************
   * General properties *
   **********************/

  remove(synced) {
    if(!this.removed) {
      this.removed = true;
      if(synced) {
        this.removedSynced = true;
      }

      this.emit('remove', this);
    }
  }


  setName(name, manual) {
    if(!manual && this.nameIsManual) {
      return;
    }

    if(!manual && name.length > MAX_IMPLICIT_NAME_LENGTH) {
      name = name.substring(0, MAX_IMPLICIT_NAME_LENGTH - 2) + '...';
    }

    if(name !== this.name || manual !== this.nameIsManual) {
      this.name = name;
      this.nameIsManual = manual;
      this.emit('name', this, this.name, this.nameIsManual);
    }
  }


  get hasDocument() {
    return !!this.document;
  }


  setDocument(vecteraFile) {
    this.document = vecteraFile;

    // Add as many pages as the document contains
    let firstIndex = this.numPages;
    let pages = [];

    for(let index = firstIndex; index < firstIndex + vecteraFile.numPages; index++) {
      let pageId = this.addPage(index, true);
      pages.push(pageId);
    }

    // Open the first page of the document
    this.setPageIndex(firstIndex, true);
    this.documentRenderer.setFile(vecteraFile, pages);
  }


  takeMeetingSnapshot(maxDimension) {
    let width = Math.min(
      maxDimension,
      maxDimension / this.contentRect.height * this.contentRect.width
    );
    let height = width / this.contentRect.width * this.contentRect.height;

    function createImage(dataURL) {
      if(dataURL) {
        return $q(resolve => {
          let image = new Image(width, height);
          image.onload = () => resolve(image);
          image.src = dataURL;
        });
      }
    }
    let promises = [
      this.documentRenderer.renderSnapshot(width),
      this.paperRenderer.fabricRenderer.renderSnapshot(width),
      this.paperRenderer.formulaRenderer.renderSnapshot(width),
    ];
    promises = promises.map(promise => promise.then(createImage));

    return $q.all(promises)
      .then(images => {
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);

        images
          .filter(images => !!images)
          .forEach(image => ctx.drawImage(image, 0, 0, width, height));
        return $q(resolve => {
          canvas.toBlob(resolve, 'image/jpeg', .85);
        });
      })
      .then(blob => {
        // eslint-disable-next-line no-irregular-whitespace
        let name = `${this.name} (${this.pageIndex + 1} / ${this.numPages})`;
        return {
          blob: blob,
          name: name,
        };
      });
  }


  renderSvgExport() {
    let promises = [
      this.paperRenderer.fabricRenderer.renderSvgExport(),
      this.paperRenderer.formulaRenderer.renderSvgExport(),
    ];

    return $q.all(promises).then($elemRenderers => {
      let $elem = angular.element('<div></div>');
      $elemRenderers
        .filter($elemRenderer => $elemRenderer)
        .forEach($elemRenderer => $elem.append($elemRenderer));

      return $elem;
    });
  }


  downloadDocument() {
    if(!this.document) {
      return;
    }

    if(browser.supportsInlineDownload()) {
      this._downloadDocumentInline();
    } else {
      let path = this.document.urls[VecteraFile.ConvertedToPdf.FALSE].remote;
      let url = this.apiService.baseUrl + path;
      file.downloadUrl(url, this.document.name);
    }
  }


  _downloadDocumentInline() {
    this.document.getLocalUrl(VecteraFile.ConvertedToPdf.FALSE, true)
      .then(localUrl => {
        file.downloadUrl(localUrl, this.document.name);
      })
      .catch(error => {
        logger.warn(error);
        this.notificationService.warning(gettextCatalog.getString(
          'Something went wrong while downloading your file. Please try again later.'
        ));
      });
  }


  download() {
    let path = format('meetings/%s/whiteboards/%s/', this.meetingService.id, this.id);
    let filename = file.splitExt(this.name)[0] + '.pdf';

    if(browser.supportsInlineDownload()) {
      this._downloadInline(path, filename);
    } else {
      file.downloadUrl(this.apiService.baseUrl + path, filename);
    }
  }


  _downloadInline(path, filename) {

    this.apiService.get(path, {
      messages: {
        initial: gettextCatalog.getString(
          'Creating whiteboard {{ name }}. This may take a while...',
          { name: this.name }
        ),
        progress: gettextCatalog.getString(
          'Downloading whiteboard {{ name }}...',
          { name: this.name }
        ),
        error: gettextCatalog.getString(
          `
            Something went wrong while downloading whiteboard {{ name }}. <br>
            We're sorry for the inconvenience. Please try again later.
          `,
          { name: this.name }
        ),
      },
      progress: {
        start: 20,
      },
      maxRetries: 1,
      // It's not clear why, but non-incognito Chrome refuses to download big files when
      // responseType = blob.
      responseType: 'arraybuffer',
    })
      .then(response => {
        let arrayBuffer = response.data;
        let blob = new Blob([arrayBuffer], { type: response.headers('Content-Type') });
        let localFile = file.fromBlob(blob, filename);
        file.download(localFile);
      })
      .catch(error => {
        logger.withContext({ whiteboardName: filename }).info(
          'Error while downloading whiteboard:'
        );
        logger.warn(error.message || error);
      });
  }



  /****************
   * Manage pages *
   ****************/

  get numPages() {
    return this.pages.length;
  }


  setPageIndex(index, synced) {
    if(synced == null) {
      synced = false;
    }

    index = platform(0, index, this.pages.length - 1);

    let page = this.pages[index];
    if(index !== this.pageIndex || page !== this.page) {
      this.pageIndex = index;
      this.page = page;
      if(synced) {
        this.pageIndexSynced = index;
      }

      this.documentRenderer.setPage(page);
      this.paperRenderer.setPage(page);
      this.emit('page', this, page, index);

      // when opening a new page, rerender so the focus remains within the content bounds
      this.setViewport(this.contentFocus, this.zoomLevel, false);
    }
  }


  addPage(index, synced, addLogo, addInitTextBox) {
    if(synced == null) {
      synced = false;
    }
    if(addLogo == null) {
      addLogo = true;
    }
    if(addInitTextBox == null) {
      addInitTextBox = false;
    }

    let pageId = ++this.maxPageId;
    let page = new Page(pageId, synced);
    this.pages.splice(index, 0, page);
    this.emit('pageAdd', this, page, index);
    if(!synced) {
      this.emit('addDefaultShapes', this, page, addLogo, addInitTextBox);
    }

    if(this.pageIndex >= index) {
      this.setPageIndex(this.pageIndex + 1, true);
    // If no page was selected yet: select the current page
    } else if(this.pageIndex === -1) {
      this.setPageIndex(index, true);
    }

    return page;
  }


  removePage(index, synced) {
    if(synced == null) {
      synced = false;
    }

    let page = this.pages[index];
    if(!page) {
      return;
    }

    page.synced = synced;
    this.pages.splice(index, 1);

    if(this.numPages === 0) {
      this.pageIndex = -1;
      let newPage = this.addPage(0, true);
      if(!synced) {
        this.emit('addDefaultShapes', this, newPage, true, false);
      }

    } else if(this.pageIndex === index) {
      this.setPageIndex(index === this.numPages ? index - 1 : index, true);

    } else if(this.pageIndex > index) {
      this.setPageIndex(this.pageIndex - 1, true);
    }

    this.emit('pageRemove', this, page, index);
  }


  nextPage() {
    if(this.pageIndex === this.numPages - 1) {
      this.addPage(this.numPages);
    }
    this.setPageIndex(this.pageIndex + 1);
  }

  previousPage() {
    if(this.pageIndex === 0) {
      return;
    }
    this.setPageIndex(this.pageIndex - 1);
  }



  /*******************
   * Manage viewport *
   *******************/

  get minZoomLevel() {
    return MIN_ZOOM_LEVEL;
  }
  get maxZoomLevel() {
    return MAX_ZOOM_LEVEL;
  }

  /**
   * Zoom in on the content, if pointerFocus is given, zoom towards that focus
   *
   * @param {number} let argFactor - factor to increase the zoomlevel by
   * @param {ContentCoordinate} pointerFocus - an x/y coordinate relative to contentSize
   * @param {boolean} temporary - whether to re-render the fabric.js canvas after completion
   */
  zoom(argFactor, pointerFocus, temporary) {
    // If no pointer is given, zoom towards the current focus (centre of the screen)
    let zoomFocus = pointerFocus == null ? this.contentFocus : pointerFocus;

    let newZoomLevel = platform(MIN_ZOOM_LEVEL, this.zoomLevel * argFactor, MAX_ZOOM_LEVEL);
    let factor = newZoomLevel / this.zoomLevel;

    let newFocus = {
      x: (this.contentFocus.x + (factor - 1) * zoomFocus.x) / factor,
      y: (this.contentFocus.y + (factor - 1) * zoomFocus.y) / factor,
    };

    this.setViewport(newFocus, newZoomLevel, temporary);
  }

  zoomIn() {
    this.zoom(ZOOM_BUTTON_FACTOR, null, true);
  }
  zoomOut() {
    this.zoom(1 / ZOOM_BUTTON_FACTOR, null, true);
  }

  /**
   *
   * @param {Object} diff - object with {x,y} keys that contain pixel values
   * @param {boolean} temporary
   */
  scroll(diff, temporary) {
    let relDiffX = (diff.x / this.pixelsPerPoint) / this.contentSize.width;
    let relDiffY = (diff.y / this.pixelsPerPoint) / this.contentSize.height;

    this.setViewport({
      x: this.contentFocus.x - relDiffX,
      y: this.contentFocus.y - relDiffY,
    }, this.zoomLevel, temporary);
  }

  /**
   * Scroll the content so that the given rect is not only visible but also a respectable
   * distance away from the tile edges. It is assumed that the given rect can fit inside the tile.
   *
   * @param {Rect} rect rect within contentRect that should be visibile, denoted in pts distance to
   *    contentSize origin
   */
  scrollIntoView(rect) {
    let newContentFocus = Object.assign({}, this.contentFocus);
    let viewportBounds = this.rectViewportBounds;

    // translate pts Rect to ContentRect
    let relRect = {
      left: rect.left / this.contentSize.width,
      right: rect.right / this.contentSize.width,
      top: rect.top / this.contentSize.height,
      bottom: rect.bottom / this.contentSize.height
    };

    if(relRect.left < (viewportBounds.left + SCROLL_INTO_VIEW_MARGIN)) {
      // left edge of the input rect is outside of the left edge of the acceptable location.
      // Move the contentFocus with the distance between the left edge of the visible bound and
      // the left edge of the input Rect such that the two edges align, then move the focus with
      // SCROLL_INTO_VIEW_MARGIN
      newContentFocus.x -= (viewportBounds.left - relRect.left) + SCROLL_INTO_VIEW_MARGIN;
    } else if(relRect.right > (viewportBounds.right - SCROLL_INTO_VIEW_MARGIN)) {
      newContentFocus.x -= (viewportBounds.right - relRect.right) - SCROLL_INTO_VIEW_MARGIN;
    }

    if(relRect.top < (viewportBounds.top + SCROLL_INTO_VIEW_MARGIN)) {
      newContentFocus.y -= (viewportBounds.top - relRect.top) + SCROLL_INTO_VIEW_MARGIN;
    } else if(relRect.bottom > (viewportBounds.bottom - SCROLL_INTO_VIEW_MARGIN)) {
      newContentFocus.y -= (viewportBounds.bottom - relRect.bottom) - SCROLL_INTO_VIEW_MARGIN;
    }

    if(newContentFocus.x !== this.contentFocus.x || newContentFocus.y !== this.contentFocus.y) {
      this.setViewport(newContentFocus, this.zoomLevel, false);
    }
  }

  /**
   * re-calculate content offset and redraw whiteboard
   *
   * set this.contentFocus and/or this.zoomLevel to a new value, and automatically update the
   * contentOffset to reflect a better representation of the new focus. Next, emit the
   * "viewport has changed" event and redraw the whiteboard
   *
   * @param {ContentCoordinate} contentFocus
   * @param {number} zoomLevel
   * @param {boolean} temporary
   */
  setViewport(newContentFocus, newZoomLevel, temporary) {
    if(this.contentFocus !== newContentFocus) {
      // content focus should not leave the contentRect bounds
      let contentFocusbounds = this.rectContentRelative;
      this.contentFocus = {
        x: platform(contentFocusbounds.left, newContentFocus.x, contentFocusbounds.right),
        y: platform(contentFocusbounds.top, newContentFocus.y, contentFocusbounds.bottom),
      };
    }

    if(this.zoomLevel !== newZoomLevel) {
      this.zoomLevel = newZoomLevel;
    }

    // content width/height relative to tile width/height
    let relConWidth = (this.contentSize.width * this.pixelsPerPoint) / this.rectTileBody.width;
    let relConHeight = (this.contentSize.height * this.pixelsPerPoint) / this.rectTileBody.height;

    this.contentOffset = {
      x: 0.5 - (this.contentFocus.x * relConWidth),
      y: 0.5 - (this.contentFocus.y * relConHeight),
    };

    this.emit('viewport', this);
    this._draw(temporary);
  }


  setContentRect(rect) {
    if(
      rect.left !== this.contentRect
      || rect.right !== this.contentRect.right
      || rect.top !== this.contentRect.top
      || rect.bottom !== this.contentRect.bottom
    ) {
      this.contentRect = rect.clone();

      this.emit('contentRect', this, this.contentRect);
    }
  }


  /********
   * Draw *
   ********/

  /**
   * Return the current dimensions of the tile, taking into account if it is (in)active
   */
  get rectTileBody() {
    return !this.tile.active && this.tile.rectBodyInactive ?
      this.tile.rectBodyInactive :
      this.tile.rectBody;
  }

  /**
   * Calculate the pixel dimensions of the fabric.js canvas
   */
  get canvasSize() {
    if(this.tile.active) {
      // An active tile has a canvas that fills the available space, with an additional margin.
      // This way when the content is tranformed with css, the shapes nearest to the visible area
      // are already rendered
      return {
        width: Math.round(this.rectTileBody.width  * (1 + 2 * RENDER_MARGIN)),
        height: Math.round(this.rectTileBody.height * (1 + 2 * RENDER_MARGIN))
      };
    } else {
      // An inactive tile only shows the base contentSize
      return {
        width: this.contentSize.width * this.pixelsPerPoint,
        height: this.contentSize.height * this.pixelsPerPoint,
      };
    }
  }

  /**
   * The current pan of the fabric.js canvas, denotes the offset of the shapes within the fabric.js
   * relative to their original location (defined by the fabric.js canvas origin)
   *
   * Not to be confused with the left/top offset of the html <canvas> element, which is
   * calculated in whiteboardTileBody.directive
   */
  get canvasPan() {
    if(this.tile.active) {
      // Pan so that the canvas origin is placed on the contentRect origin
      return {
        x: Math.round(-this.rectTileBody.width  * (this.contentOffset.x + RENDER_MARGIN)),
        y: Math.round(-this.rectTileBody.height * (this.contentOffset.y + RENDER_MARGIN)),
      };
    } else {
      // An inactive tile only shows the base contentSize with the origin in its original place:
      // do not pan
      return {
        x: 0,
        y: 0
      };
    }
  }

  /**
   * Calculate the pixel size of a deskTop publishing point (pt) at the current settings. See the
   * comment near the initialization of this.zoomLevel for how this is calculated.
   *
   * @type {number}
   */
  get pixelsPerPoint() {
    if(this.tile.active) {
      let tileAspectRatio = this.rectTileBody.width / this.rectTileBody.height;
      let contentAspectRatio = this.contentSize.width / this.contentSize.height;
      if(tileAspectRatio < contentAspectRatio) {
        return this.zoomLevel * this.rectTileBody.width  / this.contentSize.width;
      } else {
        return this.zoomLevel * this.rectTileBody.height / this.contentSize.height;
      }
    } else {
      // Inactive tiles have a fixed width and ignore user zoomLevel, render at 100% zoom.
      return this.rectTileBody.width  / this.contentSize.width;
    }
  }


  getPixelsPerPointSnapshot(width) {
    return width / this.contentRect.width;
  }
  getCanvasSizeSnapshot(width) {
    return {
      width: this.contentRect.width * this.getPixelsPerPointSnapshot(width),
      height: this.contentRect.height * this.getPixelsPerPointSnapshot(width),
    };
  }
  getCanvasPanSnapshot(width) {
    return {
      x: this.contentRect.left * this.getPixelsPerPointSnapshot(width),
      y: this.contentRect.top * this.getPixelsPerPointSnapshot(width),
    };
  }

  get pixelsPerPointSvgExport() {
    return 1;
  }
  get canvasSizeSvgExport() {
    return {
      width: this.contentRect.width * this.pixelsPerPointSvgExport,
      height: this.contentRect.height * this.pixelsPerPointSvgExport,
    };
  }
  get canvasPanSvgExport() {
    return {
      x: this.contentRect.left * this.pixelsPerPointSvgExport,
      y: this.contentRect.top * this.pixelsPerPointSvgExport,
    };
  }



  /**
   * Dimensions of the contentRect, relative to the base contentSize
   *
   * @type {ContentRect}
   */
  get rectContentRelative() {
    return new Rect({
      left: this.contentRect.left / this.contentSize.width,
      right: this.contentRect.right / this.contentSize.width,
      top: this.contentRect.top / this.contentSize.height,
      bottom: this.contentRect.bottom / this.contentSize.height,
    });
  }

  /**
   * Calculate the dimensions of the area that is currently visible, relative to contentSize and
   * the origin of contentRect.
   *
   * Consider a tile of 2000x1000 pixels, with a contentRect of 500x500 pixels, of which the origin
   * is located at (250px,250px).
   *
   * The viewport bounds is then {left: -0.5, right: 3.5, top: -0.5, bottom: 1.5}
   *
   * @type {ContentRect}
   */
  get rectViewportBounds() {
    // Assume the contentFocus is centered on the screen:
    // the pts distance between an edge and the focus, is equal to half the
    // pixel width of the tile, converted to pts
    let ptDistToContentFocusWidth = (this.rectTileBody.width / 2) / this.pixelsPerPoint;
    let ptDistToContentFocusHeight = (this.rectTileBody.height / 2) / this.pixelsPerPoint;
    // The pts distance can than be calculatd to be relative to the contentSize
    let relXDistToContentFocus = ptDistToContentFocusWidth / this.contentSize.width;
    let relYDistToContentFocus = ptDistToContentFocusHeight / this.contentSize.height;

    return new Rect({
      left: this.contentFocus.x - relXDistToContentFocus,
      right: this.contentFocus.x + relXDistToContentFocus,
      top: this.contentFocus.y - relYDistToContentFocus,
      bottom: this.contentFocus.y + relYDistToContentFocus,
    });
  }

  get pixelContentOffset() {
    return {
      x: this.contentOffset.x * this.rectTileBody.width,
      y: this.contentOffset.y * this.rectTileBody.height
    };
  }

  _onTileActive() {
    this.documentRenderer.setActive(this.tile.active);
    this.paperRenderer.setActive(this.tile.active);
    this.forceImmediateDraw = true;
  }

  _onTileDraw() {
    if(this.initializing) {
      this.initializing = false;
      this.emit('initializing', this, false);
    }

    // Something happened to the tile: also recalculate/redraw the content
    this.setViewport(this.contentFocus, this.zoomLevel, false);
  }


  _draw(temporary) {
    if(this.initializing) {
      return;
    }

    if(this.forceImmediateDraw) {
      temporary = false;
      this.forceImmediateDraw = false;
    }

    let shouldDrawDebounced = temporary;
    this._drawDebounced(shouldDrawDebounced);
    this._doDraw(temporary);
  }

  _drawDebounced(shouldDraw) {
    if(shouldDraw) {
      this._doDraw(false);
    }
  }

  /**
   * Draw the canvas:
   * - If temporary: don't communicate to the renderers. When the 'draw' event arrives in
   *   WhiteboardTileBodyDirective, a css transformation will be applied to a wrapper element
   *   around the renderer canvases, so the renderers don't need to do anything. This enables a
   *   responsive UI.
   * - If not temporary: WhiteboardTileBodyDirective will restore the wrapper element to its
   *   default position, and the renderers should redraw their content to their respective
   *   canvases at a new viewport.
   *
   * @param {boolean} temporary
   */
  _doDraw(temporary) {
    if(this._contentRenderersShouldDoDraw(temporary)) {
      this.canvasSizeRendered = this.canvasSize;
      this.canvasPanRendered = this.canvasPan;
      this.pixelsPerPointRendered = this.pixelsPerPoint;

      this.documentRenderer.updateViewport();
      this.paperRenderer.updateViewport();
    }

    this.emit('draw', this, temporary);
  }

  _contentRenderersShouldDoDraw(temporary) {
    return (
      !temporary
      && this._viewportHasChanged
      && this._tileContentIsShown
    );
  }

  get _viewportHasChanged() {
    let canvasSize = this.canvasSize;
    let canvasPan = this.canvasPan;
    let pixelsPerPoint = this.pixelsPerPoint;
    return (
      canvasSize.width !== this.canvasSizeRendered.width
      || canvasSize.height !== this.canvasSizeRendered.height
      || canvasPan.x !== this.canvasPanRendered.x
      || canvasPan.y !== this.canvasPanRendered.y
      || pixelsPerPoint !== this.pixelsPerPointRendered
    );
  }

  get _tileContentIsShown() {
    return this.tile.active || this.previewAvailable;
  }



  setDownloadWhenInactive(downloadWhenInactive) {
    if(downloadWhenInactive !== this.downloadWhenInactive) {
      this.downloadWhenInactive = downloadWhenInactive;
      this.tile.emit('change');
    }
  }

  get previewAvailable() {
    return (
      this.tile.active // Active tiles always have the eye placeholder
      || !this.document
      || this.downloadWhenInactive
      || this.documentRenderer.pdf
      || this.documentRenderer.status === Status.DOWNLOADING
    );
  }
}
