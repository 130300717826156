import { Component, Host } from '@angular/core';
import {
  AppointmentTypeConfigurationCheckService
} from 'scheduling/manage/appointment-types/appointment-type-configuration-check.service';
import { PaymentOption } from 'scheduling/models/AppointmentType';
import {
  SettingsSegmentComponent,
  SettingsSegmentState
} from 'utils/settings/settings-segment/settings-segment.component';
import {
  SettingsComponent
} from 'utils/settings/settings.component';
import { UrlService } from 'utils/url.service';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';
import { SiteService } from 'utils/site.service';


@Component({
  selector: 'appointment-type-payments[instance]',
  templateUrl: 'appointment-type-payments.component.html',
  styleUrls: ['appointment-type-payments.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypePaymentsComponent
  }]
})
export class AppointmentTypePaymentsComponent extends AppointmentTypeSettingsComponent {

  static override formFields = [
    'paymentAmount',
    'paymentCurrency',
    'paymentTerms',
  ];

  readonly currencies: string[] = ANGULAR_SCOPE.currencies
    .filter(currency => currency.stripeSupported)
    .map(currency => currency.code);
  readonly SettingsSegmentState = SettingsSegmentState;


  constructor(
    public appointmentTypeConfigurationCheckService: AppointmentTypeConfigurationCheckService,
    public urlService: UrlService,
    public siteService: SiteService,
    @Host() public segment: SettingsSegmentComponent,
  ) {
    super();
  }


  get paymentEnabled() {
    return this.instance.paymentOption === PaymentOption.PAYMENT_NECESSARY;
  }
  set paymentEnabled(enabled: boolean) {
    this.instance.paymentOption = enabled ?
      PaymentOption.PAYMENT_NECESSARY :
      PaymentOption.NO_PAYMENT;
  }


  get stripeConfig() {
    return this.appointmentTypeConfigurationCheckService.stripeConfig;
  }
  get stripeConfigInvalid() {
    return (
      this.paymentEnabled
      && !this.stripeConfig?.isActive
    );
  }

  get shouldShowForm() {
    return this.paymentEnabled || this.stripeConfig?.isActive;
  }
  get shouldShowUpgradeInfo() {
    return !this.shouldShowForm && this.hasFreeSubscription;
  }
  get shouldShowStripeInfo() {
    return !this.shouldShowForm && !this.shouldShowUpgradeInfo;
  }

  get upgradeText() {
    const proPlanName = this.siteService.site.proPlanName;
    return $localize `Upgrade to ${proPlanName} to accept payments on this meeting type.`;
  }


  override get valid() {
    return super.valid && !this.stripeConfigInvalid;
  }
}
