/**
 * Service to keep track of streams that are actively in use, meaning they are audible/visible in
 * the meeting room.
 */
import { Injectable } from '@angular/core';

import { EventEmitter, bind } from 'utils/util';

import { Stream, StreamKind, StreamState } from './Stream';
import { StreamService } from './stream.service';
import { ReplaySubject } from 'rxjs';


@Injectable()
export class ActiveAudioStreamService {
  /** Stream ID => Stream */
  private activeStreams: Map<string, Stream> = new Map();
  private _hasActiveStreams$ = new ReplaySubject<boolean>(1);
  
  public eventEmitter: EventEmitter;
  public hasActiveStreams$ = this._hasActiveStreams$.asObservable();
  
  constructor(
    private streamService: StreamService,
  ) {
    bind(this);
    this.eventEmitter = EventEmitter.setup(this, ['add', 'remove']);

    for (const stream of this.streamService.audioStreams) {
      this.addStream(stream);
    }
    this.streamService.eventEmitter.on('add', this.addStream);
    this.streamService.eventEmitter.on('remove', this.removeStream);
  }

  public streams(): Iterable<Stream> {
    return this.activeStreams.values();
  }

  private addStream(stream: Stream): void {
    if(stream.kind !== StreamKind.AUDIO) {
      return;
    }
    stream.eventEmitter.on('state enabled', this.onStreamChanged);
    this.onStreamChanged(stream);
  }

  private removeStream(stream: Stream): void {
    if(stream.kind !== StreamKind.AUDIO) {
      return;
    }
    stream.eventEmitter.off('state enabled', this.onStreamChanged);
    this.tryRemoveActiveStream(stream);
  }

  /**
   * Ensures the state of the stream is aligns with membership in `activeStream`, i.e. if the
   * stream is active and not muted, it should be in the set, otherwise not.
   */
  private onStreamChanged(stream: Stream): void {
    // The >= CONNECTED is there to cover both the case of local streams which stay at CONNECTED
    // and the case of remote streams which seem to skip CONNECTED and go straight to PLAYING.
    if (stream.state >= StreamState.CONNECTED && stream.enabled) {
      this.tryAddActiveStream(stream);
    } else {
      this.tryRemoveActiveStream(stream);
    }
  }

  private tryAddActiveStream(stream: Stream): void {
    if (this.activeStreams.has(stream.id)) {
      return;
    }
    this.activeStreams.set(stream.id, stream);
    this.eventEmitter.emit('add', stream);
    this._hasActiveStreams$.next(this.hasActiveStreams());
  }

  private hasActiveStreams(): boolean {
    return this.activeStreams.size > 0;
  }

  private tryRemoveActiveStream(stream: Stream): void {
    if (!this.activeStreams.has(stream.id)) {
      return;
    }
    this.activeStreams.delete(stream.id);
    this.eventEmitter.emit('remove', stream);
    this._hasActiveStreams$.next(this.hasActiveStreams());
  }
}
