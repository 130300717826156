import { format, readableDate, string } from 'utils/util';
import EMOJIS from './emojis.json';
import anchorme from 'anchorme';


const EMOJI_REGEXES =
  Object.keys(EMOJIS.codes)
    .sort((str1, str2) => str1.length - str2.length)
    .reverse()
    .map(code => new RegExp(escapeRegex(code), 'g'));
const EMOJI_REPLACE_STR = '<span emoji-image="\'%s\'"></span>';

function escapeRegex(str) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}


export default class Message {
  constructor($sce, id, sender, content, datetime, synced) {
    this.id = id;
    this.sender = sender;  // A Session object
    this.rawContent = content;
    this.content = this._processContent($sce, content);
    this.synced = synced;

    this.datetime = datetime;
    this.datetimeString = readableDate(datetime);
  }

  get system() {
    return false;
  }


  _processContent($sce, rawContent) {
    let content = string.escapeHTML(rawContent);
    content = content.replace('{{', '{<span>{</span>');
    content = anchorme({
      input: content,
      options: {
        attributes: {
          target: '_blank',
        },
      },
    });
    content = this._replaceEmoji(content);
    content = $sce.trustAsHtml(content);
    return content;
  }

  _replaceEmoji(content) {
    EMOJI_REGEXES.forEach(regex => {
      content = content.replace(regex, (match, index) => {
        let replace = false;
        if(match[0] === ':' && match[match.length - 1] === ':') {
          replace = true;
        } else {
          let prevChar = content[index - 1] || ' ';
          let nextChar = content[index + match.length] || ' ';
          replace = /\s/.test(prevChar) && /\s/.test(nextChar);
        }

        if(replace) {
          return format(EMOJI_REPLACE_STR, match);
        } else {
          return match;
        }
      });
    });

    return content;
  }
}
