export default class KnockerModalController {
  static get $inject() {
    return [
      'knockerService',
    ];
  }

  constructor(
    knockerService
  ) {
    this.knockerService = knockerService;
  }


  get tile() {
    return this.knockerService.unsnoozedKnockerTiles[0];
  }
  get user() {
    return this.tile ? this.tile.user : null;
  }
  get name() {
    return this.user ? this.user.fullName : '';
  }

  snoozeTile() {
    this.knockerService.snoozeTile(this.tile);
  }

  admit(admit) {
    this.knockerService.admit(this.user, admit);
  }

  get isKnockingString() {
    return gettextCatalog.getString(
      '{{ fullName }} is knocking',
      { fullName: this.name }
    );
  }
}
