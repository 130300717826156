<!-- Header -->
<div *ngFor="let unavailability of interfaceState; let i = index">
  <div class="d-flex flex-wrap p mr--8">
    <div class="mr-8">
      <label for="customFromDate" i18n>
        Start date
      </label>
      <datepicker
        id="customFromDate"
        [(ngModel)]="unavailability.from"
        [disabled]="readonly"
        (ngModelChange)="updateModelState()"
        data-testid="unavailabilityFromDatepicker"
      >
      </datepicker>
    </div>
    <div class="d-flex mr-8">
      <div>
        <label for="customToDate" i18n>
          End date (inclusive)
        </label>
        <datepicker
          id="customToDate"
          [(ngModel)]="unavailability.to"
          [disabled]="readonly"
          (ngModelChange)="updateModelState()"
          data-testid="unavailabilityToDatepicker"
        >
        </datepicker>
      </div>
      <div *ngIf="!readonly">
        <!-- Placeholder to ensure alignment of the button with the input fields -->
        <label>&nbsp;</label>
        <div
          class="btn btn--square ml-2"
          (click)="removeUnavailability(i)"
          data-testid="removeUnavailabilityButton"
        >
          <svg-icon class="btn__icon" [svgName]="'trash'"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="text-error-400" *ngIf="isPartiallyFilled" i18n>
  Unavailabilities should always have a start and end date.
</div>


<div
  *ngIf="!readonly && shouldShowAdder"
  class="btn btn--outline-primary"
  [class.disabled]="readonly"
  (click)="addEmptyUnavailability()"
  data-testid="addUnavailabilityButton"
>
  <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
  <span i18n>Add dates</span>
</div>
