import { EventEmitter, browser, string, array } from 'utils/util';

const MODIFIERS = ['ctrl', 'alt', 'shift'];


export default class ShortcutService {
  constructor() {
    this._bind();

    this.eventEmitter = new EventEmitter([], true);

    $document.on('keydown', this._onKeyDown);
  }

  _bind() {
    this._onKeyDown = this._onKeyDown.bind(this);
  }


  _isMacLike() {
    return browser.isMacOS() || browser.isIOS();
  }


  on(eventsStr, callback) {
    eventsStr.split(' ').forEach(eventStr => {
      let eventCode = this._getEventCodeFromString(eventStr);
      this.eventEmitter.on(eventCode, callback);
    });
  }

  off(eventsStr, callback) {
    eventsStr.split(' ').forEach(eventStr => {
      let eventCode = this._getEventCodeFromString(eventStr);
      this.eventEmitter.off(eventCode, callback);
    });
  }


  getPrettyString(eventStr) {
    return this.getPrettyParts(eventStr).join('+');
  }

  getPrettyParts(eventStr) {
    let canonicalStr = this._getEventCodeFromString(eventStr);
    let canonicalParts = canonicalStr.split('+');
    return canonicalParts.map(part => {
      if(this._isMacLike()) {
        if(part === 'ctrl') {
          part = 'cmd';
        } else if(part === 'alt') {
          part = 'option';
        }
      }
      return part = string.capitalize(part);
    });
  }


  _getEventCodeFromString(string) {
    let parts = string.split('+');
    return this._getEventCodeFromParts(parts);
  }

  getEventCodeFromEvent($event) {
    if(!$event.key) {
      return '';
    }

    let parts = [];

    // We currently use the modifier "ctrl" both for the Ctrl on Windows and the Meta key on Mac.
    // We do not support the Meta key on Windows or the Ctrl key on Mac yet. When we start to do
    // so, we may need to find a better name for what we currently call "ctrl".
    if(
      !this._isMacLike() && $event.ctrlKey && $event.key !== 'Control'
      || this._isMacLike() && $event.metaKey && $event.key !== 'Meta'
    ) {
      parts.push('ctrl');
    }
    if($event.altKey && $event.key !== 'Alt') {
      parts.push('alt');
    }
    if($event.shiftKey && $event.key !== 'Shift') {
      parts.push('shift');
    }

    parts.push(String.fromCharCode($event.keyCode));
    return this._getEventCodeFromParts(parts);
  }


  _getEventCodeFromParts(argParts) {
    let parts = argParts.map(part => part.toLowerCase());

    let canonicalParts = MODIFIERS.filter(modifier => array.has(parts, modifier));
    let key = parts[parts.length - 1];
    canonicalParts.push(key);

    return canonicalParts.join('+');
  }


  _onKeyDown($event) {
    this.$event = $event;

    let $target = angular.element($event.target);
    let nodeName = $target[0].nodeName.toLowerCase();
    let isInput = nodeName === 'input' || nodeName === 'textarea';
    if(isInput && $target.attr('allow-shortcuts') == null) {
      return;
    }

    let eventCode = this.getEventCodeFromEvent($event);
    if(eventCode in this.eventEmitter.callbacks) {
      $event.preventDefault();
      $rootScope.$evalAsync(() => {
        this.eventEmitter.emit(eventCode);
      });
    }
  }
}
