export function getLanguageCode() {
  // Use the browser default if window.REQUEST_USER.languageCode is not provided
  return window.REQUEST_USER && window.REQUEST_USER.languageCode || undefined;
}


export function getLocaleTime(dateTime, languageCode) {
  if(languageCode == null) {
    languageCode = getLanguageCode();
  }

  let toLocaleString;
  try {
    // Not sure when this fails, but for some people this throws
    // "RangeError: Incorrect locale information provided"
    toLocaleString = dateTime.toLocaleTimeString(languageCode);
  } catch(e) {
    toLocaleString = dateTime.toLocaleTimeString();
  }

  // Remove special IE characters
  toLocaleString = toLocaleString.replace(/\u200E/g, '');
  toLocaleString = toLocaleString.replace(/(:\S{2}):\S{2}/, '$1');
  return toLocaleString;
}


export function getLocaleDate(dateTime, options, languageCode) {
  if(languageCode == null) {
    languageCode = getLanguageCode();
  }

  try {
    // Not sure when this fails, but for some people this throws
    // "RangeError: Incorrect locale information provided"
    return dateTime.toLocaleDateString(languageCode, options);
  } catch(e) {
    return dateTime.toLocaleDateString(undefined, options);
  }
}


export function getLocaleDateTime(date, options, languageCode) {
  return getLocaleDate(date, options, languageCode) + ' - ' + getLocaleTime(date, languageCode);
}

export function toISODateString(date) {
  if(date == null) {
    return null;
  }
  return (
    date.getFullYear() + '-'
    + ('0' + (date.getMonth() + 1)).slice(-2) + '-'
    + ('0' + date.getDate()).slice(-2)
  );
}

export function getFirstDayOfWeek(moment) {
  // Save the old locale because otherwise the availability headers in "edit appointment type"
  // are "ma. di. wo. ..." in dutch. Before translations, we best use english for these headers.
  let oldLocale = moment.locale();
  moment.locale(window.LANGUAGE_CODE);
  let firstDayOfWeek = moment.localeData().firstDayOfWeek();
  moment.locale(oldLocale);
  return firstDayOfWeek;
}


export function stringToDate(value) {
  return value ? new Date(value) : null;
}

export function isToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()
  );
}

export function isTomorrow(date) {
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

  return (
    date.getDate() === tomorrow.getDate()
    && date.getMonth() === tomorrow.getMonth()
    && date.getFullYear() === tomorrow.getFullYear()
  );
}
