import template from './mainChatHeader.html?raw';

import { DROPDOWN_ID_RESET_CONFIRMATION } from './mainChatButton.component';


class MainChatHeaderController {
  static get $inject() {
    return [
      '$attrs',
      'dropdownService',
      'resetService',
      'userService',
    ];
  }

  constructor(
    $attrs,
    dropdownService,
    resetService,
    userService
  ) {
    this.$attrs = $attrs;
    this.dropdownService = dropdownService;
    this.resetService = resetService;
    this.userService = userService;
  }


  get isClosable() {
    return this.$attrs.onClose != null;
  }
  close() {
    this.onClose();
  }


  toggleResetConfirmationDropdown($event) {
    this.dropdownService.toggle(DROPDOWN_ID_RESET_CONFIRMATION, $event);
  }
}


export default {
  controller: MainChatHeaderController,
  controllerAs: 'mainChatHeaderCtrl',
  template,

  bindings: {
    onClose: '&',
  },
};
