<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h3 i18n>Form name</h3>
  <confirm-change
    [value]="instance.name"
    [shouldConfirm]="!!instance.id"
    [dialogText]="confirmNameChangeDialogText"
  >
    <input
      class="input"
      formControlName="name"
      [withSettingsErrors]="errors.name"
      data-testid="contactFormNameInput"
      cdkFocusInitial
    />
  </confirm-change>


  <ng-container *ngIf="shouldShowTeamleaderFieldMapping">
    <div class="segment__divider"></div>

    <h3 i18n>What happens with a completed form?</h3>
    <entity-creation [instance]="instance"></entity-creation>
  </ng-container>


  <div class="segment__divider"></div>


  <h3 i18n>Add questions</h3>
  <contact-form-config-questions
    [instance]="instance"
    [errors]="errors"
  ></contact-form-config-questions>
</form>
