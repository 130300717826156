import './sidebarBubble';

import DashboardController from './dashboard.controller';
import SidebarController from './sidebar.controller';


angular
  .module('dashboard')
  .controller('DashboardCtrl', DashboardController)
  .controller('SidebarCtrl', SidebarController);
