import template from './testAudioOutput.html?raw';
import { MODAL_ID as MODAL_ID_SETTINGS } from './settings.service';
import { bind } from 'utils/util';


class TestAudioOutputController {
  static get $inject() {
    return [
      '$element',
      'soundFactory',
      'modalService',
    ];
  }

  constructor(
    $elem,
    soundFactory,
    modalService
  ) {
    bind(this);

    this.$elem = $elem;
    this.soundFactory = soundFactory;
    this.modalService = modalService;

    this.testing = false;
    this.testSound = soundFactory.create(window.URLS.testSound, { loop: true });
    this.modalService.on('show', this._onModalShow);
  }

  $onInit() {
    this.$elemProgress = this.$elem.find('.settings__test-audio-progress');
  }

  $onDestroy() {
    this.stopTesting();
  }

  startTesting() {
    this.testing = true;
    this.testSound.play();
    this._draw();
  }

  stopTesting() {
    this.testing = false;
    this.testSound.stop();
  }

  _onModalShow() {
    if(this.testing && !this.modalService.get(MODAL_ID_SETTINGS).isShown) {
      this.stopTesting();
    }
  }

  _draw() {
    if(this.$elemProgress) {
      let progress = this.testSound.duration === 0 ?
        0 :
        this.testSound.currentTime / this.testSound.duration;
      this.$elemProgress.css('width', progress * 100 + '%');
    }

    if(this.testing) {
      window.requestAnimationFrame(this._draw);
    }
  }
}


export default {
  controller: TestAudioOutputController,
  controllerAs: 'testAudioOutputCtrl',
  template,

  bindings: {
    stream: '<',
  },
};
