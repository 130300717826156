import { Component } from '@angular/core';
import {
  Option, SingleSelectInputComponent
} from '../single-select-input/single-select-input.component';


@Component({
  selector: 'yes-no-input',
  templateUrl: '../single-select-input/single-select-input.component.html',
})
export class YesNoInputComponent extends SingleSelectInputComponent {

  private yes = $localize `Yes`;
  private no = $localize `No`;

  override get options(): Option[] {
    return [
      ['1', this.yes],
      ['0', this.no],
    ];
  }
}
