import { NgModule } from '@angular/core';
import { UtilsModule } from 'utils/utils.module';
import { SmartSummaryTestComponent } from './smart-summary-test.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    UtilsModule,
    FormsModule,
    CommonModule
  ],
  declarations: [
    SmartSummaryTestComponent,
  ],
  exports: [
    SmartSummaryTestComponent,
  ],
})
export class SmartSummaryTestModule {}
