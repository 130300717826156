export const BASE_URL = (() => {
  let baseUrl = '';
  if(location.host === ANGULAR_SCOPE.site.domain) {
    baseUrl = '/api/v1';
  } else if(location.host === ANGULAR_SCOPE.teamleaderCloudSiteDomain) {
    baseUrl = '/_customer-meeting/api/v1';
  } else {
    // custom domain
    baseUrl = '/_api/v1';
  }

  return baseUrl;
})();
