
import { NgModule } from '@angular/core';
import { StreamService } from './stream.service';
import { ReadyToPlayService } from './readyToPlay.service';
import { StreamFactory } from './streamFactory.service';
import { AudioContextService } from './audioContext.service';

@NgModule({
  imports: [
  ],
  exports: [
  ],
  providers: [
    AudioContextService,
    StreamService,
    ReadyToPlayService,
    StreamFactory,
  ],
})
export class StreamModule {}
