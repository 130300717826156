import './p2p';
import './janus';

import VideoCompatibleService from './videoCompatible.service';
import RtcConfigurationService from './rtcConfiguration.service';
import PeerConnectionService from './peerConnection.service';

angular
  .module('meeting')
  .service('videoCompatibleService', VideoCompatibleService)
  .service('rtcConfigurationService', RtcConfigurationService)
  .service('peerConnectionService', PeerConnectionService);
