import NotesSyncWarningModal from './notesSyncWarning.modal.html?raw';

const MODAL_ID = 'notes_sync_warning';

export default class NotesSyncWarningService {
  static get $inject() {
    return [
      'integrationSyncService',
      'modalService',
    ];
  }

  constructor(
    integrationSyncService,
    modalService
  ) {
    this.integrationSyncService = integrationSyncService;
    this.modalService = modalService;

    this.dismissed = false;

    this.modalService.register(
      MODAL_ID,
      gettext('Sync your notes'),
      'utils/icons/tl/24x24_cloud_upload_outline.svg',
      NotesSyncWarningModal,
      {}
    );
  }

  shouldShow() {
    return !this.dismissed && this.integrationSyncService.canSaveNotes();
  }

  showModal(callback) {
    this.modalService.show(MODAL_ID, { 'callback': callback });
  }

  hideModal() {
    this.dismissed = true;
    this.modalService.hide(MODAL_ID);
  }
}
