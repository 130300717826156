import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { SvgIconModule } from 'utils/ui-components/svg-icon';
import { HelpTooltipModule } from 'utils/ui-components/help-tooltip';
import { ProFeatureBadgeModule } from 'utils/ui-components/pro-feature-badge';
import { ProFeatureLockModule } from 'utils/ui-components/pro-feature-lock';
import { DirectivesModule } from 'utils/directives';
import { ProfileImagesModule } from 'utils/ui-components/profile-images';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { FontPickerComponent } from './combo-box/font-picker/font-picker.component';
import { MultiSelectDropdownComponent } from './multi-select/multi-select-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    OverlayModule,
    SvgIconModule,
    HelpTooltipModule,
    ProFeatureBadgeModule,
    ProFeatureLockModule,
    ProfileImagesModule,
  ],
  declarations: [
    ComboBoxComponent,
    DropdownSelectComponent,
    FontPickerComponent,
    MultiSelectDropdownComponent,
  ],
  exports: [
    ComboBoxComponent,
    DropdownSelectComponent,
    FontPickerComponent,
    MultiSelectDropdownComponent,
  ],
})
export class DropdownsModule {}
