

CharacterCountDirective.$inject = [
  '$compile',
];

export default function CharacterCountDirective(
  $compile
) {
  return {
    restrict:'A',
    scope: true,
    link: function($scope, $elem) {
      $scope.maxCharacterCount = $elem.attr('maxLength');
      $elem.on('change input', () => $scope.$evalAsync(update));

      let charactersStr = gettextCatalog.getString('characters');

      const TEMPLATE_PREVIEW = `
        <div
          ng-bind-html="characterCount + '/' + maxCharacterCount + ' ${charactersStr}'"
          class="mt-1 text-muted"
          ng-class="{ 'text-error': characterCount > maxCharacterCount }"
        ></div>
      `;

      let $elemCharCount = $compile(TEMPLATE_PREVIEW)($scope);
      // Use a timeout because of some weird behaviour when the DOM is still loading some elements
      $timeout(() => {
        let $insertAfterElem = $elem.parent('.input-group').length > 0 ?
          $elem.parent('.input-group') :
          $elem;
        $elemCharCount.insertAfter($insertAfterElem);
      });

      update();


      function update() {
        $scope.characterCount = $elem.val().replace(/\r\n/g, '\n').length;
      }
    },
  };
}
