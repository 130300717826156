<form class="contact-form-customization" [formGroup]="formGroup" (ngSubmit)="onSubmit()" data-testid="contactFormCustomizationSegmentContent">
  <div class="contact-form-customization__settings-segment">
    <div *ngIf="!shouldShowUpgradeCTA">
      <h3 i18n>General</h3>

      <h4 i18n>Images</h4>

      <div *ngIf="!isAdmin" class="field bg-neutral-100 p">
        <!-- No field icon, horizontal space is too small -->
        <div i18n>
          The logo that is shown on your contact form can only be changed by an admin of your team.
        </div>
      </div>

      <div *ngIf="isAdmin && hasSetImages" class="field bg-neutral-100 p">
        <!-- No field icon, horizontal space is too small -->
        <div i18n>
          The logo that is shown on your contact form can be configured in your <a [href]="urlService.urls.customization" target="_blank">team settings.</a>
        </div>
      </div>

      <ng-container *ngIf="isAdmin && !hasSetImages">
        <div class="form-group form-group--sm">
          <label class="d-flex">
            <span i18n>Logo</span>
            <help-tooltip
              text="This logo will also be used on meeting room and booking pages"
              i18n-text
            ></help-tooltip>
          </label>

          <image-picker
            [(ngModel)]="logo"
            [ngModelOptions]="{standalone: true}"
            uploadText="Upload logo…"
            i18n-uploadText
            clearText="Clear logo"
            i18n-clearText
            [defaultImage]="origWhitelabelSettings?.logo?.url"
            [withSettingsErrors]="organizationErrors.logo"
          ></image-picker>
        </div>

        <div class="form-group form-group--sm">
          <label i18n>Favicon</label>

          <image-picker
            [(ngModel)]="favicon"
            [ngModelOptions]="{standalone: true}"
            uploadText="Upload favicon…"
            i18n-uploadText
            clearText="Clear favicon"
            i18n-clearText
            [defaultImage]="origWhitelabelSettings?.favicon"
            [withSettingsErrors]="organizationErrors.favicon"
          ></image-picker>
        </div>
      </ng-container>



      <h4 i18n>Font</h4>

      <div class="form-group form-group--sm">
        <div class="d-flex">
          <div class="flex-1 mr-4">
            <label i18n>Title</label>
            <font-picker
              formControlName="titleFontFamily"
              [placeholder]="customization.titleFontFamily"
            ></font-picker>
          </div>

          <div>
            <label i18n>Size</label>
            <input
              class="input input--font-size"
              formControlName="titleFontSize"
              [placeholder]="customization.titleFontSize"
              type="number"
              min="1"
            >
          </div>
        </div>

        <settings-errors [settingsErrors]="errors.titleFontFamily"></settings-errors>
        <settings-errors [settingsErrors]="errors.titleFontSize"></settings-errors>
      </div>

      <div class="form-group form-group--sm">
        <label i18n>Title color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="titleTextColor"
          [withSettingsErrors]="errors.titleTextColor"
          [defaultColor]="customization.titleTextColor"
        ></color-picker>
      </div>


      <div class="form-group form-group--sm">
        <div class="d-flex">
          <div class="flex-1 mr-4">
            <label i18n>Body</label>
            <font-picker
              formControlName="fontFamily"
              [placeholder]="customization.fontFamily"
            ></font-picker>
          </div>

          <div>
            <label i18n>Size</label>
            <input
              class="input input--font-size"
              formControlName="fontSize"
              [placeholder]="customization.fontSize"
              type="number"
              min="1"
            >
          </div>
        </div>

        <settings-errors [settingsErrors]="errors.fontFamily"></settings-errors>
        <settings-errors [settingsErrors]="errors.fontSize"></settings-errors>
      </div>

      <div class="form-group form-group--sm">
        <label i18n>Text color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="textColor"
          [withSettingsErrors]="errors.textColor"
          [defaultColor]="customization.textColor"
        ></color-picker>
      </div>

      <div *ngIf="instance.askToAcceptTerms" class="form-group form-group--sm">
        <label i18n>Link color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="linkColor"
          [withSettingsErrors]="errors.linkColor"
          [defaultColor]="customization.linkColor"
        ></color-picker>
      </div>


      <h4 i18n>Background</h4>

      <div class="form-group form-group--sm">
        <label i18n>Page color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="backgroundColor"
          [withSettingsErrors]="errors.backgroundColor"
          [defaultColor]="customization.backgroundColor"
        ></color-picker>
      </div>

      <div class="form-group form-group--sm">
        <label i18n>Form color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="formColor"
          [withSettingsErrors]="errors.formColor"
          [defaultColor]="customization.formColor"
        ></color-picker>
      </div>

      <h4 i18n>Button color</h4>

      <div class="form-group form-group--sm">
        <label i18n>Button color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="buttonColor"
          [withSettingsErrors]="errors.buttonColor"
          [defaultColor]="customization.buttonColor"
        ></color-picker>
      </div>

      <div class="form-group form-group--sm">
        <label i18n>Button text color</label>
        <color-picker
          class="contact-form-customization__color-picker"
          formControlName="buttonTextColor"
          [withSettingsErrors]="errors.buttonTextColor"
          [defaultColor]="customization.buttonTextColor"
        ></color-picker>
      </div>
    </div>

    <div *ngIf="shouldShowUpgradeCTA" class="contact-form-customization__upgrade-cta">
      <svg-icon [svgPath]="'contactForm__customizeUpgrade'"></svg-icon>

      <h3 i18n>Popular forms have colors!</h3>
      <div class="mb-8">
        <p i18n>Hi {{ firstName }}, studies show that customising a form entices users more to complete a form.</p>
        <div i18n>
          Our <b>{{ proPlanName }}</b> package offers a lot of options to personalise the form:
        </div>
        <ul>
          <li i18n>Add a logo and a favicon</li>
          <li i18n>Change fonts and sizes</li>
          <li i18n>Add background colors</li>
          <li i18n>Customize submit button</li>
        </ul>
      </div>

      <upgrade-button></upgrade-button>
    </div>

  </div>


  <div class="contact-form-customization__divider"></div>

  <div class="contact-form-customization__preview-segment">
    <h3 class="align-self-md-center" i18n>Page specific</h3>

    <div class="mb-10 align-self-md-center">
      <scope-switcher
        [(scope)]="scope"
        [scopes]="scopes"
      ></scope-switcher>
    </div>

    <div class="mb-2">
      <div *ngIf="scope === ContactFormPageScope.FORM_PAGE">
        <div class="form-group form-group--sm mr-4">
          <label i18n>Introduction</label>
          <textarea
            class="textarea"
            rows="2"
            formControlName="introductionText"
            [withSettingsErrors]="errors.introductionText"
            [placeholder]="this.customization.introductionText"
            maxLength="200"
            character-count
          ></textarea>
        </div>
        <div class="form-group form-group--sm contact-form-customization__button-text">
          <label i18n>Button text</label>
          <input
            class="input"
            formControlName="sendButtonText"
            [withSettingsErrors]="errors.sendButtonText"
            [placeholder]="this.customization.sendButtonText"
            maxLength="{{ instance.fields.sendButtonText.maxLength }}"
            character-count
          >
        </div>
      </div>

      <div *ngIf="scope === ContactFormPageScope.SUCCESS_PAGE">
        <div class="form-group form-group--sm">
          <label i18n>Title text</label>
          <textarea
            class="textarea"
            rows="2"
            formControlName="successTitle"
            [withSettingsErrors]="errors.successTitle"
            [placeholder]="this.customization.successTitle"
            maxLength="200"
            character-count
            ></textarea>
        </div>
        <div class="form-group form-group--sm">
          <label i18n>Success text</label>
          <textarea
            class="textarea"
            rows="2"
            formControlName="successText"
            [withSettingsErrors]="errors.successText"
            [placeholder]="this.customization.successText"
            maxLength="200"
            character-count
            ></textarea>
        </div>
        <div class="form-group form-group--sm">
          <label i18n>Success image</label>
          <image-picker
            formControlName="successImage"
            [withSettingsErrors]="errors.successImage"
            [defaultImage]="{
              path: 'contactForm__highFive'
            }"
          ></image-picker>
        </div>

        <div *ngIf="shouldShowUpgradeCTA" class="mb-6 mt-6">
          <pro-feature-badge
            class="btn"
            featureText="Add downloadable file"
            i18n-featureText
          >
          </pro-feature-badge>
        </div>
        <div *ngIf="!shouldShowUpgradeCTA">
          <div class="text-h4 mt-6 mb-6" i18n>Add downloadable file</div>
          <div class="form-group form-group--sm">
            <label class="d-flex mb-4">
              <span i18n>Choose a way to present the downloadable file</span>
            </label>
            <div class="mb-3">
              <input
                type="radio"
                class="radio"
                id="downloadLink"
                [value]="DownloadOption.LINK"
                [(ngModel)]="downloadOption"
                [ngModelOptions]="{standalone: true}"
                (change)="resetFile()"
              />
              <label
                class="radio__label"
                for="downloadLink"
              >
                <span class="text-nowrap mr-2" i18n>Add link</span>
                <input
                  *ngIf="downloadOption === DownloadOption.LINK"
                  class="input"
                  [(ngModel)]="downloadLinkUrlInput"
                  (ngModelChange)="updateDownloadLinkUrlModel()"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="https://www.yourhomepage.eu/download"
                  i18n-placeholder
                />
              </label>
              <div class="mt-2 mb-2" [withSettingsErrors]="errors.downloadLink"></div>
            </div>

            <div>
              <input
                type="radio"
                class="radio"
                id="downloadFile"
                (change)="resetDownloadLink()"
                [value]="DownloadOption.FILE"
                [(ngModel)]="downloadOption"
                [ngModelOptions]="{standalone: true}"
              />
              <label
                class="radio__label"
                for="downloadFile"
              >
                <span class="text-nowrap mr-2" i18n>Add file (max. 10MB)</span>
                <file-picker
                  *ngIf="downloadOption === DownloadOption.FILE"
                  [(ngModel)]="file"
                  (ngModelChange)="onChangeFile()"
                  [ngModelOptions]="{standalone: true}"
                  uploadText="Upload file"
                  i18n-uploadText
                  clearText="Clear file"
                  i18n-clearText
                ></file-picker>
              </label>
              <div class="mt-2 mb-2" [withSettingsErrors]="errors.files"></div>
            </div>
          </div>
          <div class="form-group form-group--sm contact-form-customization__button-text">
            <label i18n>Download button text</label>
            <input
              class="input"
              formControlName="downloadButtonText"
              [withSettingsErrors]="errors.downloadButtonText"
              [placeholder]="this.customization.downloadButtonText"
              maxLength="{{ instance.fields.downloadButtonText.maxLength }}"
              character-count
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <preview class="bg-neutral-100">
        <div class="position-relative">
          <div class="contact-form-customization__preview">
            <contact-form-preview
              [scope]="scope"
              [hideScopeSwitcher]="true"
              [contactForm]="instance"
              [hideRecaptcha]="true"
            ></contact-form-preview>
          </div>

          <div
            class="btn btn--round btn--translucent-inverted position-absolute top-0 right-0 mt-2 mr-2"
            [modalTriggerFor]="modalPreview"
            [modalIcon]="{name: 'visible'}"
            [modalTitle]="instance.name"
          >
            <svg-icon class="btn__icon" [svgName]="'fullscreen'"></svg-icon>
          </div>
        </div>
      </preview>
    </div>
  </div>
</form>

<ng-template #modalPreview>
  <contact-form-preview
    [contactForm]="instance"
    [scope]="scope"
  ></contact-form-preview>
</ng-template>
