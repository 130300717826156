export default class ImageInputController {
  static get $inject() {
    return [
      '$element',
      '$scope',
    ];
  }

  constructor(
    $elem,
    $scope
  ) {
    this._bind();

    this.$elem = $elem;
    this.$scope = $scope;

    this.$elemFile = this.$elem.find('input[type="file"]');
    this.$elemClear = this.$elem.find('input[type="checkbox"]');

    this.disabled = this.$elemFile.attr('disabled') === 'disabled';
    this.clearable = this.$elemFile.attr('clearable') != null;
    this.imageSrc = this.$elem.find('.file-input__current').attr('href');
    this.geometry = this._parseGeometryString(this.$elemFile.attr('geometry'));
    this.crop = this.$elemFile.attr('crop');

    this.$elemFile.on('change', this._onChange);

    this.showButtons = false;
  }

  _bind() {
    this._onChange = this._onChange.bind(this);
  }


  get style() {
    let style = {};

    if(this.crop) {
      style = {
        width:  this.geometry[0] + 'px',
        height: this.geometry[1] + 'px',
        backgroundImage: `url("${this.imageSrc}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'noRepeat',
      };
      if(this.crop === 'circle') {
        style.borderRadius = '50%';
      }

    } else if(this.geometry) {
      style = {
        maxWidth:  `min(100%, ${this.geometry[0]}px)`,
        maxHeight: `${this.geometry[1]}px`,
      };
    }

    return style;
  }


  _parseGeometryString(geometryString) {
    let match = geometryString.match(/(\d*)x(\d*)/);
    return [
      match[1] === '' ? null : parseInt(match[1]),
      match[2] === '' ? null : parseInt(match[2]),
    ];
  }

  upload() {
    this.$elemFile.click();
  }

  _onChange($event) {
    let file = $event.target.files[0];

    if(file) {
      let reader = new FileReader();
      reader.addEventListener('load', event => {
        this.$scope.$evalAsync(() => this.imageSrc = event.target.result);
      });
      reader.readAsDataURL(file);
      this.$elemClear.prop('checked', false);
    } else {
      this.delete();
    }
  }

  delete() {
    this.$elemClear.prop('checked', true);
    this.imageSrc = null;
    this.$elemFile.val('');
  }
}
