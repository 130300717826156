import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { bind } from 'utils/util';


@Injectable({
  providedIn: 'root',
})
export class ReadyToPlayService {
  callbacks: { audio: Set<() => void>; video: Set<() => void>; };
  notification: any;

  private unlistener?: () => void;
  renderer2: Renderer2;

  constructor(
    @Inject('notificationService') private notificationService,
    rendererFactory: RendererFactory2
  ) {
    bind(this);
    this.notification = this.notificationService.warning('', { delay: -1 });
    this.renderer2 = rendererFactory.createRenderer(null, null);

    this.callbacks = {
      audio: new Set(),
      video: new Set(),
    };
  }


  once(kind, callback) {
    this.callbacks[kind].add(callback);
    this.updateNotification();
  }


  off(kind, callback) {
    this.callbacks[kind].delete(callback);
    this.updateNotification();
  }


  private updateNotification() {
    if(this.unlistener) {
      this.unlistener();
    }
    const promptKinds = Object.keys(this.callbacks).filter(kind => this.callbacks[kind].size > 0);

    let message = '';
    if(promptKinds.length > 0) {
      const kinds = promptKinds.join($localize ` and `);
      // eslint-disable-next-line max-len
      message = $localize `Autoplay seems to be disabled in your browser settings. Click anywhere to resume ${kinds}.`;
      this.unlistener = this.renderer2.listen('document', 'pointerup', this.onUserGesture);
    }
    this.notification.setMessage(message);
  }

  private onUserGesture() {
    Object.values(this.callbacks).forEach(callbacks => {
      callbacks.forEach(callback => callback());
      callbacks.clear();
    });
    this.notification.setMessage('');
  }
}
