import { bind, object } from 'utils/util';

/**
 * ModelFactory is responsible for creating model instances. Additionally, together with Models.js
 * it implements all CRUDL (Create, Read, Update, Delete, List) API operations.
 *
 * Any operation that results in a new model instance (createInstance, Read & List),
 * is located in the modelFactory
 *
 * Any operation that can be applied on an existing model instance (Create, Update, Delete)
 * is located in Models.js
 */
export default class ModelFactory {
  static get $inject() {
    return [
      '$injector',
      'apiService',
    ];
  }

  constructor(
    $injector,
    apiService
  ) {
    bind(this);

    this.$injector = $injector;
    this.apiService = apiService;
  }


  /**
   * Instantiate a local instance of a model, without communication with the API.
   * @param cls A class that extends models.Model.
   * @param {Object} config Will be passed to the model constructor.
   */
  createInstance(cls, config = {}) {
    let dependencies = cls.$inject.map(dependency => this.$injector.get(dependency));
    return new cls(config, ...dependencies);
  }


  /**
   * Instantiate a local instance of a model, by pulling data from the API.
   * @param cls A class that extends models.Model.
   * @param modelConfig See the option models.Model(). The following additional options are
   *  supported:
   *  - id: Fetch the model instance with this id. The API path will be generated using cls.path.
   * @param apiConfig Passed to apiService.call.
   */
  list(modelConfig, apiConfig = {}) {
    const cls = modelConfig.model;
    const fullModelConfig = this._buildFullModelConfig(modelConfig);
    const fullApiConfig = this._buildFullApiConfig(fullModelConfig, apiConfig);

    let apiPromise = this.apiService.get(cls.listPath(modelConfig.identifiers), fullApiConfig);
    let promise = apiPromise.then(response => {
      return {
        response: response,
        data: response.data.map(dataItem => {
          let options = Object.assign({}, fullModelConfig, { data: dataItem });
          return this.createInstance(cls, options);
        })
      };
    });
    promise.__apiPromise = apiPromise;
    return promise;
  }


  /**
   * Instantiate a local instance of a model, by pulling data from the API.
   * @param cls A class that extends models.Model.
   * @param modelConfig See the option models.Model(). The following additional options are
   * @param apiConfig Passed to apiService.call.
   */
  read(modelConfig, apiConfig = {}) {
    const cls = modelConfig.model;
    const fullModelConfig = this._buildFullModelConfig(modelConfig);
    const fullApiConfig = this._buildFullApiConfig(fullModelConfig, apiConfig);

    const apiPromise = this.apiService.get(cls.readPath(modelConfig.identifiers), fullApiConfig);
    const promise = apiPromise.then(response => {
      const options = Object.assign({}, fullModelConfig, { data: response.data });

      return {
        response: response,
        data : this.createInstance(cls, options)
      };
    });
    promise.__apiPromise = apiPromise;
    return promise;
  }

  _buildFullModelConfig(modelConfig) {
    const defaultModelConfig = {
      include: modelConfig.model.defaultInclude,
    };
    return object.assignDeep({}, defaultModelConfig, modelConfig);
  }

  _buildFullApiConfig(fullModelConfig, apiConfig) {
    const defaultApiConfig = {
      params: {
        include: fullModelConfig.include.join(','),
      }
    };
    return object.assignDeep({}, defaultApiConfig, apiConfig);
  }


  cancel(promise) {
    this.apiService.cancel(promise.__apiPromise);
  }
}
