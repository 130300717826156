import './chat';
import './clearContent';
import './cobrowse';
import './content';
import './endCall';
import './feedback';
import './knockers';
import './meetingTemplateConfig';
import './notesSyncWarning';
import './recording';
import './reset';
import './screenshare';
import './settings';
import './sharing';
import './tiles';
import './users';
import './videoViewport';
import './whiteboard';
