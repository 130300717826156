import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SchedulingConfig } from 'scheduling/models/SchedulingConfig';
import { RequestUserService } from 'utils/requestUser.service';
import { ErrorValue } from 'utils/settings/settings.component';
import { UrlService } from 'utils/url.service';
import { array, bind, Time, TimeRange, storage } from 'utils/util';


enum Interface {
  TABULAR = 'tabular',
  CALENDAR = 'calendar',
}
type Availability = TimeRange[][];

const CALENDAR_STEP = 30;  // Minutes
const CALENDAR_NUM_STEPS = 24 * 60 / CALENDAR_STEP;

const CALENDAR_CELL_CLASS = 'availability-picker__calendar-cell';
const CALENDAR_CELL_CLASS_SELECTED = CALENDAR_CELL_CLASS + '--selected';

function getCalendarTimeFromIndex(timeIndex: number): Time {
  const hours = Math.floor(timeIndex * CALENDAR_STEP / 60);
  const minutes = timeIndex * CALENDAR_STEP % 60;
  return new Time(hours, minutes);
}
function getCalendarIndexFromTime(time: Time, roundUp: boolean) {
  const timeIndex = (time.hours * 60 + time.minutes) / CALENDAR_STEP;
  return roundUp ? Math.ceil(timeIndex) : Math.floor(timeIndex);
}


/**
 * A variant of TimeRange, used inside the tabular picker to account for situations where the
 * `from` or `to` field is not yet filled in (e.g. right after adding a new, empty range).
 */
class OptionalTimeRange {
  constructor(
    public from: Time | null = null,
    public to: Time | null = null,
  ) {}
}



/** The coordinates of a "cell" inside the calendar view */
type CalendarTarget = {
  weekdayIndex: number,
  timeIndex: number,
}
/**
 * When the user is dragging a range on the calendar, this type contains everything we need to
 * know about that action.
 */
type CalendarRange = {
  from: CalendarTarget
  to: CalendarTarget,
  selected: boolean,
}


/**
 * Render a host's availability as an interactive component.
 *
 * The component has two variants:
 * - A tabular one where each weekday is a row, and each availability range is a subrow.
 * - A calendar view where you can drag a selection around to toggle availability.
 *
 * Each interface has a few properties to maintain its state, prefixed with `tabular` or
 * `calendar`.
 */
@Component({
  selector: 'availability-picker[availability]',
  templateUrl: './availability-picker.component.html',
  styleUrls: ['./availability-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityPickerComponent implements AfterViewInit, OnChanges {
  readonly Interface = Interface;
  readonly interfaces = [Interface.TABULAR, Interface.CALENDAR];
  readonly interfaceNames = {
    [Interface.TABULAR]: $localize `Hours`,
    [Interface.CALENDAR]: $localize `Calendar`,
  };
  readonly weekdays = [
    $localize `Monday`,
    $localize `Tuesday`,
    $localize `Wednesday`,
    $localize `Thursday`,
    $localize `Friday`,
    $localize `Saturday`,
    $localize `Sunday`,
  ];
  readonly weekdayAbbreviations = [
    $localize `:abbreviation of Monday:Mon`,
    $localize `:abbreviation of Tueday:Tue`,
    $localize `:abbreviation of Wednesday:Wed`,
    $localize `:abbreviation of Thursday:Thu`,
    $localize `:abbreviation of Friday:Fri`,
    $localize `:abbreviation of Saturday:Sat`,
    $localize `:abbreviation of Sunday:Sun`,
  ];
  readonly calendarDates = Array.from(Array(CALENDAR_NUM_STEPS).keys())
    .map(getCalendarTimeFromIndex)
    .map(time => new Date(0, 0, 0, time.hours, time.minutes));

  /**
   * The single-source of truth for this component's state. The 2 interfaces read this state and
   * transform it into another property that is easier to render in the template. When the user
   * changes something in the interface, the changes are immediately written back to
   * this.availability.
   * The value of this variable is not watched for changes. If you want to update the state, you
   * need to change the reference of the variable. Similarly, when this component makes changes to
   * the availability, it does not update the value of the variable, but creates an entirely new
   * one and updates the reference.
   */
  @Input() availability!: Availability;
  @Output() availabilityChange = new EventEmitter<Availability>();
  @Input() errors?: ErrorValue;
  @Input() readonly = false;
  @Input() schedulingConfig?: SchedulingConfig;

  readonly currentInterfaceStorageKey = 'availabilityPickerInterface';
  currentInterface = storage.getItem(this.currentInterfaceStorageKey) || Interface.TABULAR;


  /**
   * A deep copy of the availability with some changes.
   *
   * - `tabularState` may contain invalid availability ranges (e.g. with missing `from` and or `to`
   *   properties).
   * - `tabularState` remembers availability ranges when the user disables a weekday. If the user
   *   later re-enables that weekday, their previously configured availability ranges will still
   *   be present.
   */
  tabularState: OptionalTimeRange[][] = [];
  /**
   * A shallow copy of `availability` that contains the availability that was used to build the
   * current tabular state. This property allows us to make a distinction between:
   * - When a weekday is disabled as a result of the user interacting with the tabular interface,
   *   we want to remember the selected availability ranges (see `tabularState`).
   * - When a weekday is disabled as a result of external changes (either an interaction with the
   *   calendar interface or something outside of this component), we want to forget the selected
   *   availability ranges.
   * This property acts more or less like a boolean flag `tabularStateNeedsUpdate`, but is a bit
   * more robust.
   */
  tabularAvailabilitySource?: Availability;
  /** Whether a weekday is currently enabled in the tabular interface. */
  tabularWeekdaysEnabled: boolean[] = [];


  @ViewChild('calendar') calendar?: ElementRef<HTMLElement>;
  /** A list of the HTML elements that represent the CalendarTargets */
  private calendarCells!: NodeListOf<Element>;
  /** If the user is dragging: the current selection. */
  private calendarSelection?: CalendarRange;


  constructor(
    public urlService: UrlService,
    private requestUserService: RequestUserService,
  ) {
    bind(this);
  }


  ngAfterViewInit() {
    this.initCalendarInterface();
    this.updateForm();
  }


  ngOnChanges(changes: SimpleChanges) {
    if(changes.availability) {
      this.updateForm();
    }
  }

  updateForm() {
    this.updateTabularStateFromAvailability();
    this.updateCalendarStateFromAvailability();
  }



  setInterface(interface_: Interface) {
    this.currentInterface = interface_;
    storage.setItem(this.currentInterfaceStorageKey, interface_);
  }


  setAvailability(availability) {
    this.availability = availability;
    this.availabilityChange.emit(availability);
  }

  get isSelf() {
    return (
      this.schedulingConfig
      && this.schedulingConfig.userId === this.requestUserService.user.id
    );
  }



  /************************
   * Tabular view helpers *
   ************************/

  private updateTabularStateFromAvailability() {
    if(!this.tabularStateNeedsUpdate) {
      return;
    }

    this.tabularState = this.availability.map(listOfAvailabilityRanges => {
      return listOfAvailabilityRanges.map(availabilityRange => {
        return new OptionalTimeRange(availabilityRange.from, availabilityRange.to);
      });
    });
    this.tabularWeekdaysEnabled = this.availability.map(listOfAvailailabilityRanges => {
      return listOfAvailailabilityRanges.length > 0;
    });

    this.tabularAvailabilitySource = this.availability;
  }


  updateAvailabilityFromTabularState() {
    const availability: Availability = this.tabularState.map(
      (listOfAvailabilityRanges, index) => {
        if(this.tabularWeekdaysEnabled[index]) {
          return listOfAvailabilityRanges
            .filter(availabilityRange => {
              return availabilityRange.from != null && availabilityRange.to != null;
            })
            .map(availabilityRange => {
              return new TimeRange(availabilityRange.from as Time, availabilityRange.to as Time);
            });
        } else {
          return [];
        }
      }
    );
    // Update `this.tabularAvailabilitySource` so that `updateTabularStateFromAvailability` will
    // know it was an interaction with the tabular interface that caused the modification of
    // `this.availability`, and it doesn't need to update the local state.
    this.tabularAvailabilitySource = availability;
    this.setAvailability(availability);
  }


  get tabularStateNeedsUpdate() {
    return this.availability !== this.tabularAvailabilitySource;
  }


  onWeekdayEnabledChanged(weekdayIndex) {
    if(
      this.tabularWeekdaysEnabled[weekdayIndex]
      && this.tabularState[weekdayIndex].length === 0
    ) {
      const timeRange = TimeRange.toInternalValue(['09:00', '17:00']);
      this.tabularState[weekdayIndex].push(timeRange);
    }
    this.updateAvailabilityFromTabularState();
  }


  addAvailabilityRange(weekdayIndex: number) {
    const timeRange = new OptionalTimeRange();
    this.tabularState[weekdayIndex].push(timeRange);
    this.updateAvailabilityFromTabularState();
  }


  removeAvailabilityRange(weekdayIndex: number, availabilityIndex: number) {
    this.tabularState[weekdayIndex].splice(availabilityIndex, 1);
    this.updateAvailabilityFromTabularState();
  }



  /*************************
   * Calendar view helpers *
   *************************/

  private initCalendarInterface() {
    if(!this.calendar) {
      return;
    }
    this.calendarCells = this.calendar.nativeElement
      .querySelectorAll('.' + CALENDAR_CELL_CLASS);
    this.calendar.nativeElement.addEventListener('mousedown', this.startCalendarDrag);
    this.calendar.nativeElement.addEventListener('touchstart', this.startCalendarDrag);
  }


  private updateCalendarStateFromAvailability() {
    if(!this.calendar) {
      return;
    }

    // Reset the calendar: set all cells to unselected
    this.updateCalendarFromRange({
      from: { weekdayIndex: 0, timeIndex: 0 },
      to: { weekdayIndex: 6, timeIndex: CALENDAR_NUM_STEPS - 1 },
      selected: false,
    });

    // Set selected cells based on `this.availability`.
    this.availability.forEach((listOfAvailabilityRanges, weekdayIndex) => {
      listOfAvailabilityRanges.forEach(availabilityRange => {
        this.updateCalendarFromAvailabilityRange(weekdayIndex, availabilityRange, true);
      });
    });

    // Override based on `this.calendarSelection`.
    if(this.calendarSelection) {
      this.updateCalendarFromRange(this.calendarSelection);
    }
  }


  private updateAvailabilityFromCalendarState() {
    const availability: Availability = Array(7).fill(null)
      .map((_, weekdayIndex) => {
        const listOfAvailabilityRanges: TimeRange[] = [];

        let from: Time | null = null;
        for(let timeIndex = 0; timeIndex <= CALENDAR_NUM_STEPS; timeIndex++) {
          const selected = timeIndex < CALENDAR_NUM_STEPS ?
            this.isCalendarCellSelected({ weekdayIndex, timeIndex }) :
            false;

          if(!from && selected) {
            from = getCalendarTimeFromIndex(timeIndex);
          } else if(from && !selected) {
            const to = getCalendarTimeFromIndex(timeIndex % CALENDAR_NUM_STEPS);
            const availabilityRange = new TimeRange(from, to);
            listOfAvailabilityRanges.push(availabilityRange);
            from = null;
          }
        }
        return listOfAvailabilityRanges;
      });
    this.setAvailability(availability);
  }


  /**
   * Get the HTML element corresponding to a given CalendarTarget.
   */
  private getCalendarCell(target: CalendarTarget) {
    const index = target.timeIndex * 7 + target.weekdayIndex;
    return this.calendarCells[index];
  }


  /**
   * Check if a given CalendarTarget is currently selected in the calendar view.
   */
  private isCalendarCellSelected(target: CalendarTarget) {
    const cell = this.getCalendarCell(target);
    const selected = cell.classList.contains(CALENDAR_CELL_CLASS_SELECTED);
    return selected;
  }


  /**
   * Set the selected state of a single calendar cell.
   */
  private setCalendarCellSelected(target: CalendarTarget, selected: boolean) {
    const cell = this.getCalendarCell(target);
    cell.classList.toggle(CALENDAR_CELL_CLASS_SELECTED, selected);
  }


  /**
   * Update the selected state of a range of calendar cells.
   */
  private updateCalendarFromRange(range: CalendarRange) {
    const { from, to, selected } = range;
    const weekdayIndexes = from.weekdayIndex === to.weekdayIndex ?
      [from.weekdayIndex] :
      from.weekdayIndex < to.weekdayIndex ?
        array.range(from.weekdayIndex, to.weekdayIndex + 1) :
        array.range(to.weekdayIndex, from.weekdayIndex + 1);
    const timeIndexes = from.timeIndex === to.timeIndex ?
      [from.timeIndex] :
      from.timeIndex < to.timeIndex ?
        array.range(from.timeIndex, to.timeIndex + 1) :
        array.range(to.timeIndex, from.timeIndex + 1);
    weekdayIndexes.forEach(weekdayIndex => {
      timeIndexes.forEach(timeIndex => {
        this.setCalendarCellSelected({ weekdayIndex, timeIndex }, selected);
      });
    });
  }


  /**
   * Update the selected state of a range of calendar cells, based on a TimeRange.
   */
  private updateCalendarFromAvailabilityRange(
    weekdayIndex: number,
    availabilityRange: TimeRange,
    selected: boolean,
  ) {
    const fromIndex = getCalendarIndexFromTime(availabilityRange.from, false);
    // An availability range that ends at 00:00 indicates that it ends at 00:00 of the *next* day.
    const toIndex = availabilityRange.to.equals(new Time(0, 0)) ?
      CALENDAR_NUM_STEPS - 1 :
      getCalendarIndexFromTime(availabilityRange.to, true) - 1;

    this.updateCalendarFromRange({
      from: { weekdayIndex: weekdayIndex, timeIndex: fromIndex },
      to: { weekdayIndex: weekdayIndex, timeIndex: toIndex },
      selected: selected,
    });
  }


  /**
   * Find the CalendarTarget that a mouse event is interacting with.
   */
  private getCalendarTarget(event: MouseEvent | TouchEvent): CalendarTarget {
    const position = 'touches' in event ? event.touches[0] : event;
    let weekdayIndex = 0;
    for(weekdayIndex = 0; weekdayIndex < 7; weekdayIndex++) {
      const cell = this.getCalendarCell({ weekdayIndex, timeIndex: 0 });
      const offset = cell.getBoundingClientRect().left + window.scrollX;
      if(position.pageX < offset) {
        break;
      }
    }
    weekdayIndex = Math.max(0, weekdayIndex - 1);

    let timeIndex;
    for(timeIndex = 0; timeIndex < CALENDAR_NUM_STEPS; timeIndex++) {
      const cell = this.getCalendarCell({ weekdayIndex: 0, timeIndex });
      const offset = cell.getBoundingClientRect().top + window.scrollY;
      if(position.pageY < offset) {
        break;
      }
    }
    timeIndex = Math.max(0, timeIndex - 1);

    return {
      weekdayIndex,
      timeIndex,
    };
  }


  startCalendarDrag(event: MouseEvent | TouchEvent) {
    if(this.readonly) {
      return;
    }

    const target = this.getCalendarTarget(event);
    const selected = !this.isCalendarCellSelected(target);
    this.calendarSelection = {
      from: target,
      to: target,
      selected: selected,
    };
    this.updateCalendarStateFromAvailability();

    window.addEventListener('mousemove', this.onCalendarDrag);
    window.addEventListener('touchmove', this.onCalendarDrag);
    window.addEventListener('mouseup', this.stopCalendarDrag);
    window.addEventListener('touchend', this.stopCalendarDrag);
  }


  private onCalendarDrag(event: MouseEvent | TouchEvent) {
    if(!this.calendarSelection) {
      this.stopCalendarDrag();
      return;
    }
    this.calendarSelection.to = this.getCalendarTarget(event);
    this.updateCalendarStateFromAvailability();
  }


  private stopCalendarDrag(event?: MouseEvent | TouchEvent) {
    window.removeEventListener('mousemove', this.onCalendarDrag);
    window.removeEventListener('touchmove', this.onCalendarDrag);
    window.removeEventListener('mouseup', this.stopCalendarDrag);
    window.removeEventListener('touchend', this.stopCalendarDrag);
    if(!this.calendarSelection) {
      return;
    }

    if(event) {
      this.calendarSelection.to = this.getCalendarTarget(event);
    }
    this.updateAvailabilityFromCalendarState();
    delete this.calendarSelection;
  }
}
