import { interval, logger, object } from 'utils/util';
import { ScreenType } from '../../main/streams/screenshareStream.service';
import { StreamType } from  'meeting/meeting-room/stream';
import CobrowseTile from './CobrowseTile';


const KEEP_ALIVE_INTERVAL = 1000;

/* eslint-disable max-len */
const ErrorMessage = Object.freeze({
  CHROME_URL: 'Cobrowsing is not possible on chrome:// URLs. You can visit a different page to continue.',
  WEB_STORE_URL: 'Cobrowsing is not possible in the Chrome Web Store. You can visit a different page to continue.',
  OTHER_URL: 'Failed to connect to page. You can visit a different page to continue cobrowsing.',
  SESSION_DOES_NOT_EXIST: 'The cobrowsing session has disconnected. Please stop the session and try again.',
  UNKNOWN: 'An unknown error occured. Please stop the session and try again.',
});
const Pattern = Object.freeze({
  CHROME_URL: /^Cannot access a chrome:\/\/ URL$/,
  WEB_STORE_URL: /^The extensions gallery cannot be scripted.$/,
  OTHER_URL: /Cannot access contents of url ".*". Extension manifest must request permission to access this host./,
});
/* eslint-enable */



/**
 * This service was originally written to allow a single participant to share multiple cobrowsing
 * sessions, similar to ScreenshareService. This has proven to cause more confusion than it adds
 * value, so it is not possible to do this through the view anymore. This service still supports it
 * though, which is why we have the property `this.localTiles` (an object of Tile instances) and
 * not `this.localTile` (a single Tile instance).
 */

export default class CobrowseService {
  static get $inject() {
    return [
      'notificationService',
      'chromeExtensionService',
      'meetingBroadcastService',
      'privateMessageService',
      'streamService',
      'localStreamService',
      'tileService',
      'tileFactory',
    ];
  }

  constructor(
    notificationService,
    chromeExtensionService,
    meetingBroadcastService,
    privateMessageService,
    streamService,
    localStreamService,
    tileService,
    tileFactory
  ) {
    this._bind();

    this.notificationService = notificationService;
    this.chromeExtensionService = chromeExtensionService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.privateMessageService = privateMessageService;
    this.streamService = streamService;
    this.localStreamService = localStreamService;
    this.tileService = tileService;
    this.tileFactory = tileFactory;

    this.tiles = {};
    this.tileUrls = {};
    this.localTiles = {};

    this.messageQueue = {};
    this.warnNotification = null;

    this.keepAliveInterval = null;

    streamService.on('add', this._onStreamAdd);
    streamService.on('remove', this._onStreamRemove);
    meetingBroadcastService.on('cobrowse-url', this._onBroadcastUrl, false);
    meetingBroadcastService.on('cobrowse-zoom', this._onBroadcastZoomLevel, false);
    meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);
    privateMessageService.on('cobrowse-event', this._onPrivateMessageEvent);
    privateMessageService.on('cobrowse-response', this._onPrivateMessageResponse);

    chromeExtensionService.on('cobrowse-vectera', this._onExtensionMessage);
  }

  _bind() {
    this._keepAlive = this._keepAlive.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this._onStreamAdd = this._onStreamAdd.bind(this);
    this._onStreamRemove = this._onStreamRemove.bind(this);
    this._onExtensionMessage = this._onExtensionMessage.bind(this);
    this._onBroadcastUrl = this._onBroadcastUrl.bind(this);
    this._onBroadcastZoomLevel = this._onBroadcastZoomLevel.bind(this);
    this._afterBroadcastInitialization = this._afterBroadcastInitialization.bind(this);
    this._onPrivateMessageEvent = this._onPrivateMessageEvent.bind(this);
    this._onPrivateMessageResponse = this._onPrivateMessageResponse.bind(this);
  }


  /****************************************
   * Keep connection with extension alive *
   ****************************************/


  _keepAlive() {
    let tile = Object.values(this.localTiles)[0];
    if(!tile) {
      return;
    }

    this._sendMessageToExtension(tile, {
      type: '--keepalive--',
    });
  }


  _updateKeepAliveInterval() {
    let shouldKeepAlive = object.length(this.localTiles) > 0;
    if(shouldKeepAlive === !!this.keepAliveInterval) {
      return;
    }

    if(shouldKeepAlive) {
      this.keepAliveInterval = interval.setInterval(this._keepAlive, KEEP_ALIVE_INTERVAL);
    } else {
      interval.clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = null;
    }
  }


  _updateWarnNotification() {
    let shouldShow = object.length(this.localTiles) > 0;
    if(shouldShow === !!this.warnNotification) {
      return;
    }

    if(shouldShow) {
      this.warnNotification = this.notificationService.warning(
        gettextCatalog.getString(
          'You are currently cobrowsing. All participants can control your browser.'
        ),
        { delay: -1 }
      );
    } else {
      this.warnNotification.cancel();
      this.warnNotification = null;
    }
  }



  /****************
   * Manage tiles *
   ****************/

  _getTileId(stream) {
    return `${stream.session.id}:${stream.groupId}`;
  }
  _getTile(stream) {
    return this.tiles[this._getTileId(stream)];
  }


  _addTile(stream) {
    let id = this._getTileId(stream);
    let tile = this.tileFactory.create(CobrowseTile, id, stream);
    this.tiles[tile.id] = tile;

    if(stream.isLocal) {
      this.localTiles[tile.sourceId] = tile;
      this._processMessageQueue(tile);
      this._updateKeepAliveInterval();
      this._updateWarnNotification();

    } else if(this.tileUrls[tile.id]) {
      tile.setUrl(this.tileUrls[tile.id]);
    }

    this.tileService.add(tile);
  }


  _removeTile(stream) {
    let tile = this._getTile(stream);
    if(!tile) {
      return;
    }

    delete this.tiles[tile.id];
    if(stream.isLocal) {
      delete this.localTiles[tile.sourceId];
      this._sendMessageToExtension(tile, { type: 'cleanup', final: true });
      this._updateKeepAliveInterval();
      this._updateWarnNotification();
    }

    this.tileService.remove(tile);
  }


  _onStreamAdd(stream) {
    if(!(stream.type === StreamType.COBROWSE || stream.type === StreamType.COBROWSE_AUDIO)) {
      return;
    }

    if(stream.type === StreamType.COBROWSE) {
      this._addTile(stream);

    } else {
      let tile = this._getTile(stream);
      if(tile) {
        tile.setAudio(stream);
      }
    }

    this._startStream(stream);
  }


  _onStreamRemove(stream) {
    if(stream.type === StreamType.COBROWSE) {
      this._removeTile(stream);
    }
  }


  _afterBroadcastInitialization() {
    Object.values(this.tiles).forEach(tile => {
      this._startStream(tile.streams.video);
      if(tile.streams.audio) {
        this._startStream(tile.streams.audio);
      }
    });
  }


  _startStream(stream) {
    if(
      !this.meetingBroadcastService.initializing
      && (stream.type === StreamType.COBROWSE || !stream.user.isMe)
    ) {
      stream.start();
    }
  }



  /******************************
   * Add and remove local tiles *
   ******************************/

  add() {
    this.localStreamService.add(
      [StreamType.COBROWSE, StreamType.COBROWSE_AUDIO], ScreenType.TAB);
  }


  remove(tiles) {
    if(tiles == null) {
      tiles = Object.values(this.localTiles);
    }
    if(tiles.length === undefined) {
      tiles = [tiles];
    }

    let promises = tiles.map(tile => this._remove(tile));
    return $q.all(promises);
  }


  _remove(tile) {
    if(!tile.session.isLocal) {
      return;
    }

    let promises = [this.localStreamService.remove(tile.streams.video)];
    if(tile.streams.audio) {
      promises.push(this.localStreamService.remove(tile.streams.audio));
    }
    return $q.all(promises);
  }



  /***************************
   * Extension communication *
   ***************************/

  sendMessageToPage(tile, message) {
    if(!tile.session && !tile.streams.video) {
      return;
    }

    if(tile.session.isLocal) {
      this._sendMessageToExtension(tile, message)
        .catch(error => {
          return {
            error: error,
          };
        })
        .then(response => this._onExtensionCallback(tile, message.type, response));

    } else {
      this._sendMessageToPeer(tile, message);
    }
  }


  _sendMessageToExtension(tile, message) {
    let extensionMessage = {
      type: 'cobrowse',
      sourceId: tile.sourceId,
      message: message,
    };

    return this.chromeExtensionService.sendMessage(extensionMessage)
      .catch(error => {
        this._onExtensionError(tile, error);
      });
  }



  _onExtensionCallback(tile, messageType, response) {
    if(!response) {
      return;
    }

    if(response.error) {
      this._onUnknownExtensionError(tile, response.error);

    } else if(messageType === 'mousemove' && response) {
      tile.setCursor(response.cursor);
    } else if(messageType === 'copy' || messageType === 'paste') {
      this.setCopiedText(response.text);
    }
  }


  _onExtensionMessage(extensionMessage) {
    let sourceId = extensionMessage.sourceId;
    let message = extensionMessage.message;
    let tile = this.localTiles[sourceId];

    if(tile) {
      $rootScope.$evalAsync(() => {
        this._onExtensionMessageSafe(tile, message);
      });

    } else {
      if(!this.messageQueue[sourceId]) {
        this.messageQueue[sourceId] = [];
      }
      this.messageQueue[sourceId].push(message);
    }
  }


  _onExtensionMessageSafe(tile, message) {
    switch(message.type) {
      case 'connectSuccess':
        this._onExtensionConnectSuccess(tile);
        break;

      case 'connectError':
        this._onExtensionConnectError(tile, message.error);
        break;

      case 'url':
        this._onPageUrl(tile, message.url);
        break;

      case 'zoomLevel':
        this._onPageZoomLevel(tile, message.zoomLevel);
        break;
    }
  }


  _processMessageQueue(tile) {
    let sourceId = tile.sourceId;
    let queue = this.messageQueue[sourceId];
    if(!queue) {
      return;
    }

    while(queue.length > 0) {
      let message = queue.shift();
      this._onExtensionMessageSafe(tile, message);
    }
    delete this.messageQueue[sourceId];
  }



  _onExtensionConnectSuccess(tile) {
    tile.setConnected(true);
  }


  _onExtensionConnectError(tile, error) {
    tile.setConnected(false);

    if(error.message.match(Pattern.CHROME_URL)) {
      tile.errorMessage = ErrorMessage.CHROME_URL;

    } else if(error.message.match(Pattern.WEB_STORE_URL)) {
      tile.errorMessage = ErrorMessage.WEB_STORE_URL;

    } else if(error.message.match(Pattern.OTHER_URL)) {
      tile.errorMessage = ErrorMessage.OTHER_URL;

    } else {
      this._onUnknownExtensionError(tile, error);
    }
  }


  _onExtensionError(tile, error) {
    tile.setConnected(false);

    if(error.name === 'SessionDoesNotExist') {
      tile.errorMessage = ErrorMessage.SESSION_DOES_NOT_EXIST;
    } else {
      this._onUnknownExtensionError(tile, error);
    }
  }


  _onUnknownExtensionError(tile, errorObj) {
    let error = new Error(errorObj.message);
    error.name = errorObj.name;
    error.stack = errorObj.stack;
    logger.warn(error);

    tile.errorMessage = ErrorMessage.UNKNOWN;
  }




  /********************************
   * Peer broadcast communication *
   ********************************/

  _onPageUrl(tile, url) {
    if(url !== tile.url) {
      tile.setUrl(url);
      // Because the stream-add event is only sent after a debounce, we have to postponse sending
      // cobrowse-url as well
      $timeout(() => {
        this.meetingBroadcastService.send('cobrowse-url', false, [], tile.id, url);
      });
    }
  }

  _onPageZoomLevel(tile, zoomLevel) {
    zoomLevel = Math.round(zoomLevel * 1000) / 1000;
    if(zoomLevel !== tile.zoomLevel) {
      tile.zoomLevel = zoomLevel;
      // Because the stream-add event is only sent after a debounce, we have to postponse sending
      // cobrowse-zoom as well
      $timeout(() => {
        this.meetingBroadcastService.send('cobrowse-zoom', false, [], tile.id, zoomLevel);
      });
    }
  }


  _onBroadcastUrl(channel, session, datetime, tileId, url) {
    let tile = this.tiles[tileId];
    if(tile) {
      tile.setUrl(url);

    // The stream-add event is only sent after a debounce, so it arrives later than the first
    // cobrowse-url event
    } else {
      this.tileUrls[tileId] = url;
    }
  }

  _onBroadcastZoomLevel(channel, session, datetime, tileId, zoomLevel) {
    let tile = this.tiles[tileId];
    if(tile) {
      tile.zoomLevel = zoomLevel;
    }
  }



  /******************************
   * Peer private communication *
   ******************************/

  _sendMessageToPeer(tile, message) {
    let data = {
      tileId: tile.id,
      message: message,
    };
    this.privateMessageService.sendUnreliable('cobrowse-event', tile.session, data);
  }


  _onPrivateMessageEvent(channel, session, data) {
    let { tileId, message } = data;
    let tile = this.tiles[tileId];
    if(!tile) {
      return;
    }

    this._sendMessageToExtension(tile, message)
      .catch(error => {
        return {
          error: error,
        };
      })
      .then(response => {
        let responseData = {
          tileId: tileId,
          messageType: message.type,
          response: response,
        };
        this.privateMessageService.sendUnreliable('cobrowse-response', session, responseData);
      });
  }


  _onPrivateMessageResponse(channel, session, data) {
    let { tileId, messageType, response } = data;
    let tile = this.tiles[tileId];
    if(!tile) {
      return;
    }

    this._onExtensionCallback(tile, messageType, response);
  }



  /*************
   * Clipboard *
   *************/

  doCopy(tile) {
    this.sendMessageToPage(tile, {
      type: 'copy',
    });
  }

  doCut(tile) {
    this.sendMessageToPage(tile, {
      type: 'cut',
    });
  }

  doPaste(tile) {
    if(navigator.clipboard) {
      navigator.clipboard.readText()
        .catch(error => {
          logger.warn(error);
        })
        .then(text => {
          if(text) {
            this.sendMessageToPage(tile, {
              type: 'paste',
              text: text,
            });
          }
        });
    }
  }

  setCopiedText(text) {
    if(navigator.clipboard) {
      navigator.clipboard.writeText(text);
    }
  }
}
