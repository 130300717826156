export default class DashboardController {
  static get $inject() {
    return [];
  }

  constructor() {
    this.sidebarIsExpanded = false;
  }


  toggleSidebar(show) {
    if(show == null) {
      show = !this.sidebarIsExpanded;
    }

    this.sidebarIsExpanded = !this.sidebarIsExpanded;
  }
}
