import Dropdown from 'utils/angularjs/dropdown/Dropdown';
import templateDropdown from './confirmation.dropdown.html?raw';

const DROPDOWN_ID_CONFIRMATION = 'confirm';

ConfirmationDropdownDirective.$inject = [
  'dropdownService',
  '$parse',
];


export default function ConfirmationDropdownDirective(
  dropdownService,
  $parse
) {
  return {
    link: function($scope, $elem, $attr) {
      dropdownService.register(
        DROPDOWN_ID_CONFIRMATION,
        templateDropdown,
        {
          align: Dropdown.Align.END,
        }
      );

      $elem.on('click', (event) => {
        $scope.$evalAsync(() => {
          let text = $scope.$eval($attr.confirmationText);
          let buttonText = $scope.$eval($attr.confirmationButtonText);
          let cancelText = $attr.confirmationCancelText === undefined ?
            gettextCatalog.getString('Cancel') :
            $scope.$eval($attr.confirmationCancelText);
          let dropdownScope = {
            callback: callback,
            text: text,
            cancelText: cancelText,
            buttonText: buttonText,
          };
          dropdownService.toggle(DROPDOWN_ID_CONFIRMATION, event, dropdownScope);
        });
      });

      function callback() {
        $parse($attr.confirmationCallback)($scope);
      }
    }
  };
}
