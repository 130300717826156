import { Component, Input } from '@angular/core';

@Component({
  selector: 'usage-bar[usage][allowedUsage]',
  templateUrl: './usage-bar.component.html',
  styleUrls: ['./usage-bar.component.scss']
})
export class UsageBarComponent {
  @Input() usage!: number;
  @Input() allowedUsage!: number;

  public get usagePercentage(): number {
    return (this.usage / this.allowedUsage) * 100;
  }

  public get hasHighUsage(): boolean {
    return this.usagePercentage > 90;
  }

  public get hasMediumUsage(): boolean {
    return this.usagePercentage > 70 && this.usagePercentage <= 90;
  }
}
