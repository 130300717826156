import templateModal from './sharing.modal.html?raw';
import templateDropdownNew from './sharingNew.dropdown.html?raw';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';

import { DROPDOWN_ID as DROPDOWN_ID_NEW } from './sharingNew.controller';
import { DROPDOWN_ID_RESULTS } from 'utils/angularjs/searchUser/searchUser.component.js';
import {
  DROPDOWN_ID_ACCESS_LEVEL
} from 'utils/angularjs/accessLevel/accessLevel.component';

// If you change this, also change the "Share" link in the dashboard
export const MODAL_ID = 'share';

export default class SharingController {
  static get $inject() {
    return [
      'userService',
      'meetingService',
      'modalService',
      'dropdownService',
      'sharingService',
      'siteService',
    ];
  }

  constructor(
    userService,
    meetingService,
    modalService,
    dropdownService,
    sharingService,
    siteService
  ) {

    modalService.register(
      MODAL_ID,
      gettext('Invite settings'),
      'utils/icons/tl/24x24_share_android_outline.svg',
      templateModal
    );

    dropdownService.register(
      DROPDOWN_ID_NEW,
      templateDropdownNew,
      {
        cssClasses: 'dropdown-deprecated--no-margin',
        embeds: [DROPDOWN_ID_RESULTS, DROPDOWN_ID_ACCESS_LEVEL],
        preRender: true,  // Save the query between shows
      });

    this.userService = userService;
    this.meetingService = meetingService;
    this.modalService = modalService;
    this.dropdownService = dropdownService;
    this.sharingService = sharingService;
    this.siteService = siteService;

    this.sharingService.clearError();
  }


  get me() {
    return this.userService.me;
  }
  get mySession() {
    return this.userService.mySession;
  }


  get footerBtnIcon() {
    if(this.sharingService.publicAccessLevel === AccessLevel.SHOULD_KNOCK) {
      return 'utils/icons/tl/24x24_lock_outline.svg';
    } else if(this.sharingService.publicAccessLevel === AccessLevel.CAN_ALWAYS_JOIN) {
      return 'utils/icons/tl/24x24_lock_open_outline.svg';
    } else {
      // For guests this.sharingService.publicAccessLevel is null
      return 'utils/icons/tl/24x24_share_android_outline.svg';
    }
  }


  get publicLinkTooltip() {
    /* eslint-disable */
    if(this.mySession.accessLevel.isHost) {
      /// make sure that the terms in quotes correspond to the terms in the accesslevel dropdown options
      return gettextCatalog.getString('Invite people to this meeting room with this public link. We recommend setting this to "Should knock". Use "Can always join" only if no sensitive information is shared in the meeting room.');
    } else {
      return gettextCatalog.getString('Invite people to this meeting room with this public link.');
    }
    /* eslint-enable */
  }

  get accessLevelText() {
    return gettextCatalog.getString(
      'Your access level: {{ accessLevel }}',
      { accessLevel: this.mySession.accessLevel.infoText }
    );
  }

  get permissionsArray() {
    return Object.values(this.sharingService.permissions);
  }


  get subscriptionLink() {
    return this.siteService.site.subscriptionLink;
  }

  get proPlanName() {
    return this.siteService.site.proPlanName;
  }

  toggleNewDropdown($event) {
    this.dropdownService.toggle(DROPDOWN_ID_NEW, $event);
  }


  showModal() {
    this.modalService.show(MODAL_ID);
  }
}
