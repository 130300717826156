import errors from './errors';
import format from './format';

export type TimeDeltaUnit = 'days' | 'hours' | 'minutes' | 'seconds';
export type TimeDeltaInput = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export default class TimeDelta {
  static toInternalValue(s): TimeDelta {
    const match = s.match(/^(?:(\d+) )?(\d+):(\d+):(\d+)(?:.(\d+))?$/);
    if(!match) {
      throw new errors.InvalidArgumentError('Invalid duration string');
    }

    return new TimeDelta({
      days: parseInt(match[1] || 0),
      hours: parseInt(match[2] || 0),
      minutes: parseInt(match[3] || 0),
      seconds: parseInt(match[4] || 0),
    });
  }

  static createFromUnit(unit: TimeDeltaUnit, value: number) {
    if(Number.isFinite(value)) {
      return new TimeDelta({
        [unit]: value,
      });
    } else {
      throw new Error('could not create TimeDelta');
    }
  }

  seconds: number;

  constructor(values: TimeDeltaInput) {
    let res = 0;
    res += values.seconds || 0;
    res += (values.minutes || 0) * 60;
    res += (values.hours || 0) * 60 * 60;
    res += (values.days || 0) * 60 * 60 * 24;
    this.seconds = res;
  }


  get minutes(): number {
    return Math.floor(this.seconds / 60);
  }

  get hours(): number {
    return Math.floor(this.seconds / (60 * 60));
  }

  get days(): number {
    return Math.floor(this.seconds / (60 * 60 * 24));
  }

  toRepresentation(): string {
    const days = Math.floor(this.seconds / (60 * 60 * 24));
    let remainder = this.seconds % (60 * 60 * 24);

    const hours = Math.floor(remainder / (60 * 60));
    remainder = remainder % (60 * 60);

    const minutes = Math.floor(remainder / 60);
    const seconds = remainder % 60;

    let s = format(
      '%s:%s:%s',
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    );
    if(days !== 0) {
      s = format('%s %s', days, s);
    }
    return s;
  }

  equals(timedelta: TimeDelta): boolean {
    return this.seconds === timedelta.seconds;
  }

  getUnit(unit): number {
    return this[unit];
  }
}
