import { array, bind, browser, interval, logger, object } from 'utils/util';
import { DeviceKindToStreamType, StreamType } from  'meeting/meeting-room/stream';
import Session from '../../main/users/Session';


const RETRY_INTERVAL = 1000;
const RETRY_AMOUNT = 5;


/**
 * Manage the audio and video stream of the users in the meeting.
 */
export default class UserStreamService {
  static get $inject() {
    return [
      'browserService',
      'focusService',
      'localStreamService',
      'mediaDeviceService',
      'meetingBroadcastService',
      'meetingService',
      'notificationService',
      'privateMessageService',
      'settingsService',
      'segmentationSettingsService',
      'shortcutService',
      'streamService',
      'userService',
    ];
  }

  constructor(
    browserService,
    focusService,
    localStreamService,
    mediaDeviceService,
    meetingBroadcastService,
    meetingService,
    notificationService,
    privateMessageService,
    settingsService,
    segmentationSettingsService,
    shortcutService,
    streamService,
    userService
  ) {
    bind(this);

    this.browserService = browserService;
    this.focusService = focusService;
    this.localStreamService = localStreamService;
    this.mediaDeviceService = mediaDeviceService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.privateMessageService = privateMessageService;
    this.settingsService = settingsService;
    this.segmentationSettingsService = segmentationSettingsService;
    this.shortcutService = shortcutService;
    this.streamService = streamService;
    this.userService = userService;

    this.add = this.browserService.wrapWebRTCSupport(this.add);

    this.notificationVideoDisabled = null;

    this.streams = {
      [StreamType.AUDIO]: {},
      [StreamType.VIDEO]: {},
    };

    this._setupListeners();
  }


  _setupListeners() {
    this.mediaDeviceService.on(
      'preferredAudioInput preferredVideoInput',
      this._onPreferredInputDevice
    );
    this.segmentationSettingsService.on('resetStream', this.resetLocalVideoStream);
    this.settingsService.on('suppressMicNoise', this.resetLocalAudioStream);
    this.privateMessageService.on('request-stream-stop', this._onPrivateMessageRequestStop);

    this.streamService.on('add', this._onStreamAdd);
    this.streamService.on('remove', this._onStreamRemove);
    this.meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);

    this.shortcutService.on('alt+a', this.toggleWithShortcut.bind(this, StreamType.AUDIO));
    this.shortcutService.on('alt+v', this.toggleWithShortcut.bind(this, StreamType.VIDEO));

    if(!browser.supportsUnfocusedVideo()) {
      this.focusService.on('isVisible', this._onVisible);
    }
  }


  resetLocalVideoStream() {
    if(this.has([StreamType.VIDEO])) {
      this.remove(StreamType.VIDEO);
      this.add(StreamType.VIDEO);
    }
  }


  resetLocalAudioStream() {
    if(this.has([StreamType.AUDIO])) {
      this.remove(StreamType.AUDIO);
      this.add(StreamType.AUDIO);
    }
  }

  /**
   * React to the user explicitly selecting an input device.
   * - If a disabled stream exists that uses this device: enable that stream.
   * - Otherwise: stop all streams with the same type as the device, and start a new stream that
   *   will use the newly selected device.
   * @param {MediaDevice} device
   */
  _onPreferredInputDevice(device, isExplicitAction) {
    if(!isExplicitAction) {
      return;
    }

    let type = DeviceKindToStreamType[device.kind];
    let stream = Object.values(this.localStreamService.streams[type])
      .find(stream => stream.inputDevice === device);
    if(stream) {
      this.enableStream(stream);
    } else {
      this.remove(type);
      this.add(type);
    }
  }


  requestStop(session, type) {
    let data = { type };
    this.privateMessageService.send('request-stream-stop', session, data);
  }



  /****************************************
   * Manage local audio and video streams *
   ****************************************/

  has(type) {
    return object.length(this.localStreamService.streams[type]) > 0;
  }
  hasEnabled(type) {
    return object.some(
      this.localStreamService.streams[type],
      (streamId, stream) => stream.enabled
    );
  }
  hasAudioEnabled() {
    return this.hasEnabled(StreamType.AUDIO);
  }
  hasVideoEnabled() {
    return this.hasEnabled(StreamType.VIDEO);
  }


  add(...types) {
    this.localStreamService.add(types);
  }


  removeStream(stream) {
    this.localStreamService.remove(stream);
  }

  remove(...types) {
    types.forEach(type => {
      Object.values(this.localStreamService.streams[type]).forEach(this.removeStream);
    });
  }


  enableStream(stream) {
    this.localStreamService.setEnabled(stream, true);
  }
  enable(type) {
    Object.values(this.localStreamService.streams[type]).forEach(this.enableStream);
  }

  disableStream(stream) {
    this.localStreamService.setEnabled(stream, false);
  }
  disable(type) {
    Object.values(this.localStreamService.streams[type]).forEach(this.disableStream);
  }


  /**
   * Start a stream of type `type`. If a disabled stream of this type exists, it is enabled.
   * Otherwise a new stream is started.
   * @param {StreamType} type
   */
  start(type) {
    this.has(type) ? this.enable(type) : this.add(type);
  }
  startAudio() {
    this.start(StreamType.AUDIO);
  }
  startVideo() {
    this.start(StreamType.VIDEO);
  }
  startAudioVideo() {
    // This is only be called from the greet screen, when no streams are added yet.
    this.add(StreamType.VIDEO, StreamType.AUDIO);
  }


  /**
   * Stop any local streams of type `type`.
   *
   * When stopping a video stream, we remove the stream entirely, which also drops the camera
   * capture, and closes the WebRTC connection with peers. When stopping an audio stream, we only
   * disable the stream, This keeps the microphone capture and WebRTC connections open, which
   * allows for very fast unmuting.
   * @param {StreamType} type
   */
  stop(type) {
    type === StreamType.AUDIO ? this.disable(type) : this.remove(type);
  }
  stopAudio() {
    this.stop(StreamType.AUDIO);
  }
  stopVideo() {
    this.stop(StreamType.VIDEO);
  }


  toggle(type) {
    this.hasEnabled(type) ? this.stop(type) : this.start(type);
  }
  toggleWithShortcut(type) {
    if(this.shortcutsAreEnabled) {
      this.toggle(type);
    }
  }
  get shortcutsAreEnabled() {
    return this.userService.mySession.isJoined() && !this.meetingService.isTemplate;
  }


  _onPrivateMessageRequestStop(channel, session, data) {
    let { type } = data;
    if(this.hasEnabled(type)) {
      this.stop(type);
      this.notificationService.info(gettextCatalog.getString(
        `
          {{ name }} has muted your microphone.
          Re-enable it in the footer if you want to speak again.
        `,
        { name: session.user.firstName }
      ));
    }
  }


  _onVisible() {
    this.focusService.isVisible ? this.enable(StreamType.VIDEO) : this.disable(StreamType.VIDEO);
  }



  /***********************************************
   * Retry streams that stop with unknown reason *
   ***********************************************/

  _onStreamAdd(stream) {
    if(!array.has([StreamType.AUDIO, StreamType.VIDEO], stream.type)) {
      return;
    }

    if(stream.isLocal) {
      stream.on('ended', this._retryStream);
    }

    if(
      !this.meetingBroadcastService.initializing
      && stream.user.isMe
      && !stream.isLocal
      && stream.session.state !== Session.State.KNOCKING
      && this.has(stream.type)
    ) {
      this.remove(stream.type);
    }

    this.streams[stream.type][stream.id] = stream;
    this._startStream(stream);
  }


  _onStreamRemove(stream) {
    stream.off('ended', this._retryStream);
    if(stream.type in this.streams) {
      delete this.streams[stream.type][stream.id];
    }
  }


  _afterBroadcastInitialization() {
    Object.values(this.streams).forEach(streamsOfType => {
      Object.values(streamsOfType).forEach(this._startStream);
    });
  }


  _startStream(stream) {
    if(this._shouldStartStream(stream)) {
      stream.start();
    }
  }

  _shouldStartStream(stream) {
    return (
      !this.meetingBroadcastService.initializing
      && stream.session.isJoined()
      && (stream.type === StreamType.VIDEO || !stream.user.isMe));
  }


  _retryStream(stream) {
    let device = stream.inputDevice;
    if(!device) {
      this._showStoppedNotification(stream);
      return;
    }

    let retryCount = 0;
    let retryInterval = interval.setInterval(() => {
      retryCount++;
      if(retryCount > RETRY_AMOUNT) {
        interval.clearInterval(retryInterval);
        this._onRetryFailure(stream);

      } else {
        try {
          this.mediaDeviceService.setPreferredDevice(device);
          interval.clearInterval(retryInterval);
          this._onRetrySuccess(stream);
        } catch(error) {
          logger.info(error);
        }
      }
    }, RETRY_INTERVAL);
  }

  _onRetrySuccess(stream) {
    this.add(stream.type);
  }
  _onRetryFailure(stream) {
    this._showStoppedNotification(stream);
  }


  _showStoppedNotification(stream) {
    this.notificationService.info(gettextCatalog.getString(
      'Your {{ deviceName }} seems to have stopped working. Please retry with a different device.',
      { deviceName: stream.deviceName }
    ));
  }

  _hideVideoDisabledNotification() {
    if(this.notificationVideoDisabled) {
      this.notificationVideoDisabled.cancel();
      this.notificationVideoDisabled = null;
    }
  }
}
