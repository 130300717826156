import { format, logger } from 'utils/util';


export default class PrivateMessageDispatcher {
  static getEmitterChannel(channel, session) {
    if(session == null) {
      return channel;
    } else {
      return format('%s:%s', channel, session.id);
    }
  }

  constructor(meetingReliableSocketService, eventEmitter, session) {
    this.meetingReliableSocketService = meetingReliableSocketService;
    this.eventEmitter = eventEmitter;
    this.session = session;

    this.maxSentId = 0;
    this.maxReceivedId = 0;
    this.receiveQueue = [];
    this.peerConnection = null;

    this.logMissedMessageTimeout = null;
  }


  setPeerConnection(peerConnection) {
    this.peerConnection = peerConnection;
  }


  send(channel, message) {
    let messageId = ++this.maxSentId;
    let data = [messageId, message];
    this.meetingReliableSocketService.send(channel, [this.session.id, data], this.session);

    logger
      .withContext({ remoteSessionId: this.session.id })
      .debug('Sent PM %s on channel %s:', messageId, channel, message);
  }


  sendUnreliable(channel, message) {
    if(this.peerConnection) {
      this.peerConnection.sendPrivateMessage([channel, message]);
    } else {
      this.send(channel, message);
    }
  }


  onMessage(channel, data) {
    let [messageId, message] = data;

    this.receiveQueue[messageId] = {
      channel: channel,
      message: message,
    };
    this._processReceiveQueue();

    if(messageId > this.maxReceivedId) {
      logger
        .withContext({ remoteSessionId: this.session.id })
        .debug('Received PM %s on channel %s:', messageId, channel, message);

      if(this.logMissedMessageTimeout == null) {
        this.logMissedMessageTimeout = $timeout(this._logMissedMessage.bind(this), 30000);
      }
    }
  }


  onUnreliableMessage(channel, message) {
    this._emitReceivedMessage(channel, null, message);
  }


  _processReceiveQueue() {
    while(this.receiveQueue[this.maxReceivedId + 1]) {
      this.maxReceivedId++;
      let { channel, message } = this.receiveQueue[this.maxReceivedId];
      delete this.receiveQueue[this.maxReceivedId];
      this._emitReceivedMessage(channel, this.maxReceivedId, message);

      $timeout.cancel(this.logMissedMessageTimeout);
      this.logMissedMessageTimeout = null;
    }
  }

  _logMissedMessage() {
    if((this.session.isAlive() || this.session.isKnocking())) {
      logger
        .withContext({
          messageId: this.maxReceivedId + 1,
          remoteSessionId: this.session.id,
        })
        .warn('Failed to receive private message after 30s');
    }
  }


  _emitReceivedMessage(channel, messageId, message) {
    let args = [channel, this.session].concat(message);
    let channels = [
      PrivateMessageDispatcher.getEmitterChannel(channel),
      PrivateMessageDispatcher.getEmitterChannel(channel, this.session),
    ];

    channels.forEach(channel => {
      this.eventEmitter.emit(channel, ...args);
    });

    logger
      .withContext({ remoteSessionId: this.session.id })
      .debug('Processed PM %s on channel %s:', messageId, channel, message);
  }
}
