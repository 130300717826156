import { Injectable } from '@angular/core';
import {
  Organization,
  SerializedOrganization,
  OrganizationBackendService
} from 'organization/organization-backend.service';


@Injectable({
  providedIn: 'root',
})
export class ViewOrganizationService {
  public organization: Organization;

  constructor(
    private organizationBackendService: OrganizationBackendService,
  ) {
    const viewOrg = ANGULAR_SCOPE.viewOrganization as SerializedOrganization;
    this.organization = this.organizationBackendService.mapOrganizationToFrontEndModel(viewOrg);
  }
}
