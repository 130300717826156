import { browser, logger, format } from 'utils/util';
import BaseLocalRecording from 'meeting/angularjs/shared/recordings/BaseLocalRecording';


export default class RecordingsService {
  static get $inject() {
    return [
      '$http',
      '$cookies',
      'apiService',
      'notificationService',
      'chromeExtensionService',
      'dashboardRecordingFactory',
      'requestUserService'
    ];
  }

  constructor(
    $http,
    $cookies,
    apiService,
    notificationService,
    chromeExtensionService,
    dashboardRecordingFactory,
    requestUserService
  ) {
    this._bind();

    this.$http = $http;
    this.$cookies = $cookies;
    this.apiService = apiService;
    this.notificationService = notificationService;
    this.chromeExtensionService = chromeExtensionService;
    this.dashboardRecordingFactory = dashboardRecordingFactory;
    this.requestUserService = requestUserService;

    this.cloudRecordings = [];
    this.localRecordings = [];
    this.loadingCloud = true;
    this.loadingLocal = true;
    this.meetingInfoPromises = {};
    this.displayCloudRecordings = null;
    this.displayLocalRecordings = null;

    this.urls = window.URLS;

    this.chromeExtensionService.on('isInstalled', this._onExtensionInstalled);
    this.chromeExtensionService.on('recording', this._onExtensionMessage);

    this._populateRecordings();
  }

  _bind() {
    this._populateCloudRecordings = this._populateCloudRecordings.bind(this);
    this._populateLocalRecordings = this._populateLocalRecordings.bind(this);
    this._populateRecordings = this._populateRecordings.bind(this);
    this._setLocalRecordings = this._setLocalRecordings.bind(this);
    this.sendMessageToExtension = this.sendMessageToExtension.bind(this);
    this._onExtensionInstalled = this._onExtensionInstalled.bind(this);
    this._onExtensionMessage = this._onExtensionMessage.bind(this);
    this._sortRecordings = this._sortRecordings.bind(this);
  }

  get loading() {
    return this.loadingLocal || this.loadingCloud;
  }

  _populateRecordings() {
    this._populateCloudRecordings();
    this._populateLocalRecordings();
  }

  _buildDisplayRecordings(recordings) {
    let displayRecordings = recordings.slice();

    recordings.forEach(recording => {
      recording.noMeetingInfo = !recording.meeting;
      recording.fromOwnedMeeting = (
        recording.meeting
        && recording.meetingOwner.id === this.requestUserService.user.id
      );
      recording.fromOrganizationMeeting = (
        recording.meeting
        && recording.meetingOwner.organizationId === this.requestUserService.user.organizationId
      );
      recording.fromExternalMeeting = (
        recording.meeting
        && recording.meetingOwner.organizationId !== this.requestUserService.user.organizationId
      );
    });

    if(recordings.some(
      recording => (
        !recording.meeting
      )
    )) {
      displayRecordings.push({
        isSubtitle: true,
        subtitle: gettextCatalog.getString('No meeting room info'),
        noMeetingInfo: true,
        fromOwnedMeeting: false,
        fromOrganizationMeeting: false,
        fromExternalMeeting: false,
      });
    }

    if(recordings.some(
      recording => (
        recording.meeting
        && recording.meetingOwner.id === this.requestUserService.user.id
      )
    )) {
      displayRecordings.push({
        isSubtitle: true,
        subtitle: gettextCatalog.getString('Recordings in your meeting rooms'),
        noMeetingInfo: false,
        fromOwnedMeeting: true,
        fromOrganizationMeeting: true,
        fromExternalMeeting: false,
      });
    }

    if(recordings.some(
      recording => (
        recording.meeting
        && recording.meetingOwner.id !== this.requestUserService.user.id
        && recording.meetingOwner.organizationId === this.requestUserService.user.organizationId
      )
    )) {
      let tooltip = gettextCatalog.getString(
        'Only recordings from meeting rooms where you are a host are shown.'
      );
      displayRecordings.push({
        isSubtitle: true,
        subtitle: `
        <div class="d-flex align-items-center">
          <span translate>Recordings in meeting rooms of your team</span>
          <div
            class="icon icon--inline icon--sm clickable ml-1"
            svg="'utils/icons/tl/14x14_info_badged_filled.svg'"
            tooltip="'${tooltip.replace('\'', '\\\'')}'"
          ></div>
        </div>
        `,
        noMeetingInfo: false,
        fromOwnedMeeting: false,
        fromOrganizationMeeting: true,
        fromExternalMeeting: false,
      });
    }

    if(recordings.some(
      recording => (
        recording.meeting
        && recording.meetingOwner.organizationId !== this.requestUserService.user.organizationId
      )
    )) {
      displayRecordings.push({
        isSubtitle: true,
        subtitle: gettextCatalog.getString('Recordings in meeting rooms outside your team'),
        noMeetingInfo: false,
        fromOwnedMeeting: false,
        fromOrganizationMeeting: false,
        fromExternalMeeting: true,
      });
    }

    displayRecordings.sort(this._sortRecordings);
    return displayRecordings;
  }

  _sortRecordings(recording1, recording2) {
    return (
      !!recording2.fromOwnedMeeting - !!recording1.fromOwnedMeeting
      || !!recording2.fromOrganizationMeeting - !!recording1.fromOrganizationMeeting
      || !!recording2.fromExternalMeeting - !!recording1.fromExternalMeeting
      || !!recording2.noMeetingInfo - !!recording1.noMeetingInfo
      || !!recording2.isSubtitle - !!recording1.isSubtitle
      || (recording2.start > recording1.start ? 1 : -1)
    );
  }

  /*******************
  * Cloud recordings *
  *******************/

  _populateCloudRecordings() {
    let queryParams = {
      perPage: 'all',
      include: 'meeting,meetingOwner,createdBy',
    };
    let query = new URLSearchParams(queryParams);
    let url = format('recordings?%s', query);
    this.apiService.get(url)
      .then(response => this._setCloudRecordings(response))
      .catch(error => {
        this.notificationService.error(
          gettextCatalog.getString(
            `Something went wrong loading your recordings.
             Please refresh the page to try again.`
          ),
          { delay: -1 }
        );
        logger.warn(error);
      })
      .finally(() => {
        this.loadingCloud = false;
      });
  }

  _setCloudRecordings(response) {
    this.cloudRecordings = [];
    let recordings = response.data;
    this.cloudRecordings = recordings.map(recording => {
      return this.dashboardRecordingFactory.createDashboardCloudRecording(
        recording
      );
    });

    this.displayCloudRecordings = this._buildDisplayRecordings(this.cloudRecordings);
  }

  /********************
   * Local recordings *
   *******************/


  _onExtensionInstalled() {
    this._populateLocalRecordings();
  }


  _onExtensionMessage(message) {
    switch(message.action) {
      case 'upload-progress':
        this._onUploadProgress(message.id, message.progress);
        break;
    }
  }

  sendMessageToExtension(message, errorMessage, errorCallback) {
    if(
      !browser.supportsRecording()
      || !this.chromeExtensionService.isInstalled
    ) {
      return $q.resolve();
    }

    if(errorCallback == null) {
      errorCallback = error => {
        if(error === 'NotSupportedError') {
          return;
        }
        logger.warn(error);
        if(errorMessage) {
          this.notificationService.warning(errorMessage);
        }
      };
    }

    let extensionMessage = Object.assign(
      { type: 'recording' },
      message
    );
    return this.chromeExtensionService.sendMessage(extensionMessage).catch(errorCallback);
  }

  _onUploadProgress(id, progress) {
    let recording = this.localRecordings.find(recording => recording.id === id);
    if(recording) {
      recording.setUploadProgress(progress * 100);
    }
  }


  _populateLocalRecordings() {
    return this.sendMessageToExtension({
      action: 'list',
    })
      .then(this._setLocalRecordings)
      .finally(() => {
        this.loadingLocal = false;
      });
  }


  _setLocalRecordings(recordings) {
    this.localRecordings = [];
    if(recordings == null || recordings.length === 0) {
      return;
    }

    recordings = recordings.filter(r => r.state !== BaseLocalRecording.State.RECORDING);

    let promises = recordings.map(recording => {
      return this._getMeetingInfo(recording.meetingId).then(meeting => {
        recording.meeting = meeting;
      });
    });

    $q.all(promises).then(() => {
      this.localRecordings = recordings.map(recording => {
        return this.dashboardRecordingFactory.createDashboardLocalRecording(
          this.sendMessageToExtension,
          recording
        );
      });
      this.displayLocalRecordings = this._buildDisplayRecordings(this.localRecordings);
    });
  }

  _getMeetingInfo(id) {
    if(id == null) {
      return $q.resolve();
    }
    if(!(id in this.meetingInfoPromises)) {
      this.meetingInfoPromises[id] = this._fetchMeetingInfo(id);
    }
    return this.meetingInfoPromises[id];
  }

  _fetchMeetingInfo(id) {
    return this.apiService.get(`meetings/${id}/?include=owner`)
      .then(response => response.data)
      .catch(error => {
        if(error.response.status === 404) {
          return null;
        } else {
          this.notificationService.error(
            gettextCatalog.getString(
              'Something went wrong loading your local recordings. Please refresh the page.'
            ),
            { delay: -1 }
          );
          return $q(angular.noop);
        }
      });
  }


  delete(recording) {
    recording.delete()
      .catch(error => {
        this.notificationService.error(gettextCatalog.getString(
          `Something went wrong deleting your recording.
          Please try again or visit the meeting room to delete the recording.`
        ));
        logger.warn(error);
      })
      .finally(this._populateRecordings);
  }


  uploadTooltip(recording) {
    /* eslint-disable max-len */
    if(recording.allowCloudRecordings) {
      return gettextCatalog.getString('Upload to your account');
    }
    if(recording.meeting === null) {
      // Deleted meeting
      return gettextCatalog.getString('Recordings from deleted meeting rooms cannot be uploaded. You can still download the recording.');
    }
    if(
      recording.meeting
      && recording.meeting.settings
      && !recording.meeting.settings.allowCloudRecordings
    ) {
      return gettextCatalog.getString('Uploading is disabled for security reasons. Contact your team admin for more info.');
    }
    if(recording.properties.meetingName) {
      // Not deleted, but legacy recording (before deploy of the recording dashboard feature)
      return gettextCatalog.getString('This recording was created with a previous version of the extension. Therefore it must be uploaded from within the meeting room.');
    }
    /* eslint-enable max-len */
  }

  upload(localRecording) {
    localRecording.upload()
      .catch(error => {
        this.notificationService.error(gettextCatalog.getString(
          `Something went wrong uploading your recording.
          Please try again or visit the meeting room to upload the recording.`
        ));
        logger.warn(error);
      })
      .finally(this._populateRecordings);
  }

  convert(localRecording) {
    localRecording.convert()
      .catch(error => {
        this.notificationService.error(gettextCatalog.getString(
          `Something went wrong converting your recording.
          Please try again or visit the meeting room to convert the recording.`
        ));
        logger.warn(error);
      })
      .finally(this._populateRecordings);
  }
}
