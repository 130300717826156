<div class="profile-images">
  <div
    *ngFor="let user of users | slice:0:maxShownUsers;let index=index;"
    class="profile-images__item"
    [ngStyle]="{ 'zIndex': maxShownUsers - index }"
  >
    <div [matTooltip]="getUserName(user)">
      <profile-image
        [user]="user"
        [size]="size"
        [colorMode]="colorMode"
      ></profile-image>
    </div>
  </div>
  <div
    *ngIf="users.length > maxShownUsers"
    class="profile-images__item profile-images__item--overflow"
  >
    <div
      class="profile-image"
      [matTooltip]="hiddenUserNames"
      [ngStyle]="{
        fontSize: (size / 2.5) + 'px',
        width: size + 'px',
        height: size + 'px'
      }"
    >
      +{{ users.length - maxShownUsers }}
    </div>
  </div>
</div>
