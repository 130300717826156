import ScreenshareStreamService from './screenshareStream.service';
import LocalStreamService from './localStream.service';
import StreamsRequestableService from './streamsRequestable.service';

import PictureInPictureService from './pictureInPicture.service';
import MediaDeviceNotificationService from './mediaDeviceNotification.service';
import SilentDeviceNotificationService from './silentDeviceNotification.service';
import SilentDeviceNotificationController from './silentDeviceNotification.controller';
import MediaDeviceNotificationController from './mediaDeviceNotification.controller';
import BindStreamDirective from './bindStream.directive';

import SegmentationSettingsService from '../../components/settings/segmentationSettings.service';

angular
  .module('meeting')

  .service('segmentationSettingsService', SegmentationSettingsService)

  .service('screenshareStreamService', ScreenshareStreamService)
  .service('localStreamService', LocalStreamService)
  .service('streamsRequestableService', StreamsRequestableService)

  .service('pictureInPictureService', PictureInPictureService)

  .service('mediaDeviceNotificationService', MediaDeviceNotificationService)

  .service('silentDeviceNotificationService', SilentDeviceNotificationService)
  .controller('silentDeviceNotificationCtrl', SilentDeviceNotificationController)

  .controller('MediaDeviceNotificationCtrl', MediaDeviceNotificationController)
  .directive('bindStream', BindStreamDirective);
