import DurationFilter from './duration.filter';
import FilesizeFilter from './filesize.filter';
import HelpArticleFilter from './helpArticle.filter';
import ReadableDateFilter from './readableDate.filter';
import TimeSinceFilter from './timeSince.filter';

angular
  .module('vecteraUtil')
  .filter('duration', DurationFilter)
  .filter('filesize', FilesizeFilter)
  .filter('helpArticle', HelpArticleFilter)
  .filter('readableDate', ReadableDateFilter)
  .filter('timeSince', TimeSinceFilter);
