import { NgModule } from '@angular/core';
import { UtilsModule } from 'utils/utils.module';
import { HubSpotWidgetComponent } from './hubspot-widget/hubspot-widget.component';
import { AdminPulseWidgetComponent } from './adminpulse-widget/adminpulse-widget.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    OverlayModule,
  ],
  declarations: [
    HubSpotWidgetComponent,
    AdminPulseWidgetComponent
  ],
  exports: [
    HubSpotWidgetComponent,
    AdminPulseWidgetComponent
  ]
})
export class MeetingSidebarModule {}
