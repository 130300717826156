import { Component, OnInit } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { ContactFormSettingsComponent } from '../../contact-form-settings.component';
import { TeamMembersService } from 'organization/teamMembers.service';
import { ComboBoxOption } from 'utils/form-elements/dropdowns/combo-box/combo-box.component';
import { Member } from 'organization/models/Member';


@Component({
  selector: 'contact-form-notifications[instance]',
  templateUrl: './contact-form-notifications.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactFormNotificationsComponent
  }]
})
export class ContactFormNotificationsComponent
  extends ContactFormSettingsComponent
  implements OnInit {

  public userToNotify;
  public notifyUser = false;
  private teamMembers!: Member[];

  public notifyOptions: ComboBoxOption[] = [];
  public initialized = false;
  static override formFields = [
    'allowUserNotification',
  ];

  constructor(
    private teamMemberService: TeamMembersService,
  ) {
    super();
  }


  override ngOnInit() {
    super.ngOnInit();
    this.teamMemberService.get().then(teamMembers => {
      this.teamMembers = teamMembers;
      this.setInitialUserToNotify();
      this.notifyOptions = this.teamMemberService.buildComboBoxOptions(teamMembers, false);
      this.initialized = true;
    });
  }

  private setInitialUserToNotify() {
    let userToNotifyId;

    if(this.instance.id) {
      userToNotifyId = (
        this.instance.usersToNotify.length > 0 ?
          this.instance.usersToNotify[0].id :
          null
      );
    } else {
      userToNotifyId = this.requestUserService.user.id;
    }

    this.setUserOption(userToNotifyId);
  }

  private setUserOption(userToNotifyId) {
    this.userToNotify = this.teamMembers.find(
      member => member.isAccepted && member.isActive && member.id === userToNotifyId
    );
    this.notifyUser = !!this.userToNotify;
  }


  onNotifyUserCheckboxChange() {
    if(!this.notifyUser) {
      this.instance.usersToNotify = [];
    } else {
      this.setUserOption(this.requestUserService.user.id);
    }
  }

  onChangeUserToNotify() {
    if(!this.initialized) {
      return;
    }

    if(this.userToNotify) {
      this.instance.usersToNotify = [this.userToNotify];
    } else {
      this.instance.usersToNotify = [];
    }
  }
}
