import { Injectable } from '@angular/core';
import { SiteService } from 'utils/site.service';
import { SvgIcon } from '../../svg-icon';


export type LogoVariant = 'icon' | 'icon_nopadding' | 'compact' | 'full' | 'inline';
export type LogoColor = 'color' | 'light' | 'dark';

@Injectable({
  providedIn: 'root',
})
export class AppLogoService {

  constructor(
    private siteService: SiteService
  ) {
  }

  getSvgIcon(color: LogoColor = 'dark', variant: LogoVariant): SvgIcon {
    const postFix = color === 'color' ? '' : '_monochrome';
    const product = this.siteService.site.isStandalone ? 'standalone' : 'teamleader';
    const path = `logo__${product}_${variant}${postFix}`;
    return {
      path: path
    };
  }

}
