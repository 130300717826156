import { Component, Inject } from '@angular/core';

import { Hint } from 'utils/angularjs/hints/Hint';

import { PrivateNotesService } from './private-notes.service';
import { SmartSummaryService } from '../../../../smart-summary/smart-summary.service';
import { NotesSyncService } from '../integration-sync/notes-sync.service';
import { SYNC_SERVICE } from '../integration-sync/integration-sync.service';
import { PrivateNotesTrackingService } from './private-notes-tracking.service';
import { ADMIN_PULSE } from 'integrations/adminpulse/adminpulse.service';


@Component({
  selector: 'private-notes',
  templateUrl: './private-notes.component.html',
  styleUrls: ['./private-notes.component.scss'],
  providers: [{ provide: SYNC_SERVICE, useExisting: NotesSyncService }],
})
export class PrivateNotesComponent {
  constructor(
    public privateNotesService: PrivateNotesService,
    @Inject('hintService') private hintService,
    private smartSummaryService: SmartSummaryService,
    public syncService: NotesSyncService,
    private privateNotesTrackingService: PrivateNotesTrackingService
  ) {}

  public get shouldShowIntegrationSyncButton(): boolean {
    return this.syncService.activeIntegrations().length > 0;
  }

  public get canUseSmartSummary(): boolean {
    return this.smartSummaryService.canUseSmartSummary;
  }

  public get shouldShowNewIndicator(): boolean {
    return this.syncService.canSaveContent() && this.hint.shouldShow;
  }

  public clearNewIndicator(): void {
    this.hint.setSeen();
  }

  private get hint(): Hint {
    return this.hintService.get('meetingNotesSyncMoved');
  }

  public sharedViaIntegration(integrations: string[]): void {
    if (integrations.includes(ADMIN_PULSE)) {
      this.privateNotesTrackingService.trackPrivateNotesSharedToAdminpulse();
    }
  }
}
