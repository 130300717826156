

export function fixURL(inputURL) {
  if(inputURL == null || inputURL === '') {
    return '';
  } else if(!inputURL.match(/^https?:\/\//)) {
    // url starts does not start with 'https://' or 'http://'
    return 'https://' + inputURL;
  } else {
    return inputURL;
  }
}
