import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class UrlQueryParamsService {
  private _queryParams = new URLSearchParams(window.location.search);

  constructor(private location: Location) { }

  get queryParams(): URLSearchParams {
    return this._queryParams;
  }

  set queryParams(params: URLSearchParams) {
    this._queryParams = params;
    this.updateURL();
  }

  public setQueryParam(key: string, value: string): void {
    this._queryParams.set(key, value);
    this.updateURL();
  }

  public removeQueryParam(key: string): void {
    this._queryParams.delete(key);
    this.updateURL();
  }

  private updateURL(): void {
    this.location.go(window.location.pathname, this._queryParams.toString());
  }
}
