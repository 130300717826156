import { Component } from '@angular/core';
import { QuestionTypeConfigComponent } from '../question-type-config.component';

@Component({
  templateUrl: './money-config.component.html',
  styleUrls: ['./money-config.component.scss'],
  providers: [{
    provide: QuestionTypeConfigComponent,
    useExisting: MoneyConfigComponent
  }],
})
export class MoneyConfigComponent extends QuestionTypeConfigComponent {
  readonly currencies = ANGULAR_SCOPE.currencies.map(currency => currency.code);

  get currency(): string {
    if(this.question.extra.currency == null) {
      this.question.extra.currency = this.currencies[0];
    }
    return this.question.extra.currency;
  }
  set currency(currency: string) {
    this.question.extra.currency = currency;
  }
}
