import PaperRenderer from './PaperRenderer';
import FabricRenderer from './FabricRenderer';
import FormulaRenderer from './FormulaRenderer';


export default class PaperRendererFactory {
  static get $inject() {
    return [
      'chromeExtensionService',
      'clipboard',
      'contextMenuService',
      'editingUserService',
      'fabricShapeFactory',
      'notificationService',
      'toolService',
      'userService',
    ];
  }

  constructor(
    chromeExtensionService,
    clipboard,
    contextMenuService,
    editingUserService,
    fabricShapeFactory,
    notificationService,
    toolService,
    userService
  ) {
    this.chromeExtensionService = chromeExtensionService;
    this.clipboard = clipboard;
    this.contextMenuService = contextMenuService;
    this.editingUserService = editingUserService;
    this.fabricShapeFactory = fabricShapeFactory;
    this.notificationService = notificationService;
    this.toolService = toolService;
    this.userService = userService;
  }


  create(whiteboard) {
    let paperRenderer = new PaperRenderer(this.userService, whiteboard);
    new FabricRenderer(
      this.chromeExtensionService,
      this.clipboard,
      this.contextMenuService,
      this.editingUserService,
      this.fabricShapeFactory,
      this.notificationService,
      this.toolService,
      whiteboard,
      paperRenderer
    );
    new FormulaRenderer(
      this.toolService,
      whiteboard,
      paperRenderer
    );
    return paperRenderer;
  }
}
