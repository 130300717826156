import { Component, Inject, Input } from '@angular/core';
import { SiteService } from 'utils/site.service';
import { LogoVariant } from '../app-logo/app-logo.component';


export type PoweredByTint = 'light' | 'dark';

/**
 * show a badge signifying that a feature is powered by Vectera/teamleader
 *
 * Example usage:
 *
 * ```
 * <powered-by
 *   *ngIf="shouldShowPoweredBy"
 *   style="logo-only"
 *   utmSource="contact_form"
 *   segmentSource="contact_form"
 * ></powered-by>
 * ```
 */
@Component({
  selector: 'powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss']
})
export class PoweredByComponent {
  @Input() segmentSource?: string;
  @Input() utmSource?: string;
  @Input() utmCampaign?: string;
  @Input() style: 'full' | 'icon-only' | 'logo-only' | 'text-only' = 'full';
  @Input() tint: PoweredByTint = 'dark';
  @Input() showText = true;


  constructor(
    @Inject('usageTrackingService') private usageTrackingService,

    public siteService: SiteService,
  ) {}

  get poweredByText() {
    if(this.style !== 'icon-only') {
      return $localize `Powered by`;
    } else {
      return '';
    }
  }

  get logoVariant(): LogoVariant {
    if(this.style === 'logo-only' || this.style === 'icon-only') {
      return 'icon_nopadding';
    } else {
      return 'inline';
    }
  }

  get utmTags() {
    let res = '';
    if(this.utmSource) {
      res = `?utm_medium=referral&utm_source=${this.utmSource}`;
      if(this.utmCampaign) {
        res = res + `&utm_campaign=${this.utmCampaign}`;
      }
    }
    return res;
  }

  get href() {
    return this.siteService.site.domain;
  }

  /******************
  * Usage tracking *
  *****************/

  trackLogoClick() {
    if(this.segmentSource) {
      if(this.siteService.site.isTeamleader) {
        this.usageTrackingService.createSegmentEvent(
          'teamleaderLogo.clicked',
          this.segmentSource
        );
      } else {
        this.usageTrackingService.createSegmentEvent(
          'vecteraLogo.clicked',
          this.segmentSource
        );
      }
    }
  }
}
