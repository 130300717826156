import { Stream } from  'meeting/meeting-room/stream';
import { VideoStream }  from './VideoStream';
import { ScreenStream } from './ScreenStream';
import { AudioStream } from './AudioStream';
import { Inject, Injectable } from '@angular/core';
import { ReadyToPlayService } from './readyToPlay.service';
import { StreamType } from './Stream';
import Session from 'meeting/angularjs/main/users/Session';
import { AudioContextService } from './audioContext.service';

@Injectable({
  providedIn: 'root',
})
export class StreamFactory {

  constructor(
    @Inject('notificationService') private notificationService,
    @Inject('settingsService') private settingsService,
    @Inject('focusService') private focusService,
    @Inject('segmentationSettingsService') private segmentationSettingsService,
    @Inject('videoCompatibleService') private videoCompatibleService,

    public audioContextService: AudioContextService,
    public readyToPlayService: ReadyToPlayService,
  ) {}

  create(
    streamType: StreamType,
    streamId: string,
    groupId: string,
    session: Session
  ) {
    if(
      streamType === StreamType.AUDIO
      || streamType === StreamType.SCREEN_AUDIO
      || streamType === StreamType.COBROWSE_AUDIO
    ) {
      return new AudioStream(
        streamType, streamId, groupId, session,
        this.readyToPlayService,
        this.audioContextService,
        this.notificationService,
        this.settingsService,
      );
    } else if(
      streamType === StreamType.VIDEO
    ) {
      return new VideoStream(
        streamType, streamId, groupId, session,
        this.readyToPlayService,
        this.notificationService,
        this.focusService,
        this.segmentationSettingsService,
        this.videoCompatibleService
      );
    } else if(
      streamType === StreamType.SCREEN
      || streamType === StreamType.COBROWSE
    ) {
      return new ScreenStream(
        streamType, streamId, groupId, session,
        this.readyToPlayService,
        this.notificationService,
        this.focusService,
        this.segmentationSettingsService,
        this.videoCompatibleService
      );
    } else {
      return new Stream(
        streamType, streamId, groupId, session,
        this.readyToPlayService
      );
    }
  }
}
