import * as fields from 'utils/fields';
import Model from 'utils/models';
import { CRMEntity } from './MappingField';
import { User } from 'core/models/User';

export class MappingConfig extends Model {
  static get fields() {
    return {
      createContact: new fields.BooleanField({ default: true }),
      createCompany: new fields.BooleanField({ default: false }),
      createDeal: new fields.BooleanField({ default: true }),
    };
  }

  static get basePath() {
    throw Error('A MappingConfig can only be requested through the parent ContactForm.');
  }


  toRepresentation(...args) {
    // This model can be nested inside a ContactForm. In this case, the contactFormId field is used
    // to identify the instance, so it always needs to be present on the representation.
    let data = super.toRepresentation(...args);
    data.contactFormId = this.contactFormId;
    return data;
  }


  get settingNames() {
    return {
      [CRMEntity.CONTACT]: 'createContact',
      [CRMEntity.COMPANY]: 'createCompany',
      [CRMEntity.DEAL]: 'createDeal',
    };
  }

  shouldCreate(entity) {
    return this[this.settingNames[entity]];
  }
  setShouldCreate(entity, value) {
    this[this.settingNames[entity]] = value;
  }

  get isEnabled() {
    return this.createContact || this.createCompany || this.createDeal;
  }
}


export class VecteraMappingConfig extends MappingConfig {}
export class TeamleaderMappingConfig extends MappingConfig {
  static get fields() {
    return {
      createContact: new fields.BooleanField({ default: true }),
      createCompany: new fields.BooleanField({ default: false }),
      createDeal: new fields.BooleanField({ default: true }),
      tags: new fields.JSONField({
        default: () => {},
      }),
      dealOwner: new fields.RelatedField({ Model: User }),
      dealPipelineId: new fields.StringField(),
    };
  }
}
