import {
  Component,
  EventEmitter,
  forwardRef,
  Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { search, sortKind } from 'fast-fuzzy';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { DropdownOption } from '../dropdown-select/dropdown-select.component';

import { SimpleControlValueAccessor } from '../../simple-control-value-accessor';

export type ComboBoxOption = {
  label: string,
  value: any,
  disabled?: boolean;
  icon?: SvgIcon
  disabledHelpText?: string
  requiresUpgrade?: boolean
};
export type ComboBoxCategory = {
  label?: string,
  options: ComboBoxOption[],
}

export type ComboBoxOptionInput = ComboBoxOption | DropdownOption;
export type ComboBoxCategoryInput = {
  label?: string,
  options: ComboBoxOptionInput[],
}

export type ComboBoxInput = ComboBoxCategoryInput[] | ComboBoxOptionInput[];



/**
 * An advanced dropdown-select that offers more functionality:
 *  - filtering based on user input
 *  - splitting into categories
 *  - tooltips or upgrade badges for disabled items
 *
 * The `options` input enumerates all the options that can be selected. There are 3 different
 * formats for the input:
 * - a list of strings
 * - a list of ComboBoxOptions. (Note that ComboBoxOption is compatible with DropdownOption.)
 * - a list of ComboBoxCategories, each of which contains a list of strings or ComboBoxOptions.
 *
 * ComboBoxOptions are objects with the following properties:
 * - label: the text that is shown in the dropdown
 * - value: the value that is emitted when the option is selected
 * - disabled: if true, the option will be grayed out or filtered out of the suggestions
 * - icon: override the default icon
 * - disabledHelpText: if present, will show a tooltip next to a disabled option
 * - requiresUpgrade: if true, show the option as a pro-feature-badge
 *
 * Suggestions can be added on top of filtering by passing a list of Options to the
 * `suggestions` input
 */
@Component({
  selector: 'combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true,
    },
  ],
})
export class ComboBoxComponent
  extends SimpleControlValueAccessor<any>
  implements OnChanges {

  @Input() options: ComboBoxInput = [];
  @Input() placeholder = $localize `Select…`;
  @Input() clearable = false;
  @Output() queryChange = new EventEmitter<string>();
  @Output() inputCleared = new EventEmitter<string>();
  @Output() optionPicked = new EventEmitter<any>();

  public categories: ComboBoxCategory[] = [];
  public selectedOption?: ComboBoxOption;
  public inputString = '';
  public canShowProfileImages = false;

  isOpen = false;

  ngOnChanges(changes: SimpleChanges) {
    if(changes.options) {
      this.buildCategories();
      this.updateSelectedOption();
    }
  }


  onQueryChange() {
    this.clearSelectedOption();
    if(this.inputString === '') {
      this.writeValue(null);
    }
    this.queryChange.emit(this.inputString);
  }

  shouldShowProfileImage(option) {
    return this.canShowProfileImages && option.value;
  }

  private buildCategories() {
    this.categories = this.parseOptionsInput(this.options);
    this.canShowProfileImages = this.categories.every( category => {
      return category.options.every( option => {
        return  !option.value || option.value.hasOwnProperty('fullName');
      });
    });
  }

  private parseOptionsInput(options: ComboBoxInput): ComboBoxCategory[] {
    if(options.length === 0) {
      return [];

    } else if(this.isListOfCategories(options)) {
      return options.map(categoryInput => ({
        label: categoryInput.label,
        options: this.parseListOfOptions(categoryInput.options),
      }));

    } else {
      return [{
        options: this.parseListOfOptions(options),
      }];
    }
  }


  private parseListOfOptions(options: ComboBoxOptionInput[]) : ComboBoxOption[] {
    return options.map(option => this.parseOption(option));
  }

  private parseOption(option: ComboBoxOptionInput) : ComboBoxOption {
    if(typeof option === 'string') {
      return {
        label: option,
        value: option,
      };
    } else {
      return option;
    }
  }


  private isListOfCategories(options: ComboBoxInput): options is ComboBoxCategoryInput[] {
    return options.length === 0 || options[0].hasOwnProperty('options');
  }


  get filteredCategories() : ComboBoxCategory[] {
    if(!this.categories) {
      return [];
    }

    // only filter based on input when the user has interacted with the input string
    // (which would have resulted in the selected option being cleared)
    if(!this.selectedOption && this.inputString) {
      const resOptions : ComboBoxCategory[] = [];
      this.categories.forEach(category => {
        const categoryResOptions = this.filterCategory(category);
        if(categoryResOptions.length > 0) {
          resOptions.push({
            label: category.label,
            options: categoryResOptions,
          });
        }
      });
      return resOptions;
    } else {
      return this.categories;
    }
  }


  private filterCategory(category: ComboBoxCategory) : ComboBoxOption[] {
    return search(
      this.inputString,
      category.options,
      {
        keySelector: opt => opt.label,
        sortBy: sortKind.insertOrder,
        threshold: 0.8
      }
    ).filter(opt => !opt.disabled);
  }


  override writeValue(value: any): void {
    super.writeValue(value);
    this.updateSelectedOption();
  }


  private updateSelectedOption() {
    let selectedOption: ComboBoxOption | undefined = undefined;
    for(const category of this.categories) {
      if(selectedOption) {
        break;
      } else {
        for(const option of category.options) {
          if((option.value === this.value)
            || (option.value?.id && option.value?.id === this?.value?.id)) {
            selectedOption = option;
            break;
          }
        }
      }
    }

    this.selectedOption = selectedOption;
    if(this.selectedOption) {
      this.inputString = this.selectedOption.label;
    } else {
      this.inputString = '';
    }
  }

  optionToTestId(option: ComboBoxOption) {
    return option.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  }


  clearInput() {
    this.writeValue(null);
    this.inputCleared.emit();
  }

  clearSelectedOption() {
    delete this.selectedOption;
  }

  focusInput($event) {
    $event.target.select();
    this.isOpen = true;
  }

  onBackdropClick() {
    // When focus is lost, we no longer want to show the search query as this might look like
    // a selected option.
    this.updateSelectedOption();
    this.isOpen = false;
  }

  pickOption(option: ComboBoxOption) {
    if(!option.disabled) {
      this.writeValue(option.value);
      this.isOpen = false;
      this.optionPicked.emit(option.value);
    }
  }
}
