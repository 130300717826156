import templateModal from './notSupported.modal.html?raw';
import { browser } from 'utils/util';

const MODAL_ID = 'notSupported';


export default class NotSupportedService {
  static get $inject() {
    return [
      'modalService',
    ];
  }

  constructor(
    modalService
  ) {
    this.modalService = modalService;

    this.modalService.register(
      MODAL_ID,
      gettext('Browser not supported'),
      'utils/icons/tl/24x24_warning_badged_filled.svg',
      templateModal,
      { priority: 31, dismissable: false }
    );

    if(!browser.supportsVectera()) {
      this.modalService.show(MODAL_ID);
    }
  }
}
