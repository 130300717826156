import { Rect, platform } from 'utils/util';

// We crop portrait video's with a very small aspect ratio (e.g. 9:16) to 3:4.
export const MIN_TILE_ASPECT_RATIO = 3 / 4;


export default class UserTileBin {
  constructor(tiles, tileBinArray) {
    this.tiles = tiles;
    this.tileBinArray = tileBinArray;
  }


  get spacing() {
    return this.tileBinArray.spacing;
  }

  get height() {
    return this.tileBinArray.tileHeight;
  }

  get width() {
    if(this.tiles.length === 0) {
      return 0;
    } else {
      return (
        this.aspectRatio * this.height
        + this.spacing * Math.max(this.tiles.length - 1, 0)
      );
    }
  }

  get aspectRatio() {
    return this.tiles.reduce(
      (aspectRatio, tile) => aspectRatio + this._getTileAspectRatio(tile),
      0
    );
  }

  _getTileAspectRatio(tile) {
    return platform(MIN_TILE_ASPECT_RATIO, tile.aspectRatio);
  }


  draw(top, left) {
    this.tiles.forEach(tile => {
      let tileWidth = this.height * this._getTileAspectRatio(tile);
      let rect = new Rect({
        left: Math.round(left),
        top: Math.round(top),
        width: tileWidth,
        height: this.height,
      });
      tile.draw(rect);
      left += tileWidth + this.spacing;
    });
  }
}
