import { bind, browser, format } from 'utils/util';
import { MODAL_ID as MODAL_ID_SHARING } from '../sharing/sharing.controller';
import { MODAL_ID as MODAL_ID_SETTINGS, Quality } from './settings.service';
import { MODAL_ID as MODAL_ID_RESET } from '../reset/reset.service';
import { MODAL_ID_SAVE_AS_TEMPLATE } from '../meetingTemplateConfig/meetingTemplateConfig.service';

const SET_QUALITY_DEBOUNCE = 1000;

export default class SettingsController {
  static get $inject() {
    return [
      'browserService',
      'modalService',
      'dropdownService',
      'meetingService',
      'meetingTemplateConfigService',
      'userService',
      'rtcConfigurationService',
      'resetService',
      'settingsService',
      'siteService',
    ];
  }

  constructor(
    browserService,
    modalService,
    dropdownService,
    meetingService,
    meetingTemplateConfigService,
    userService,
    rtcConfigurationService,
    resetService,
    settingsService,
    siteService
  ) {
    bind(this);

    this.browserService = browserService;
    this.modalService = modalService;
    this.dropdownService = dropdownService;
    this.meetingService = meetingService;
    // Not used, but needs to be initialized so that the 'Save as template' modal is registered.
    this.meetingTemplateConfigService = meetingTemplateConfigService;
    this.userService = userService;
    this.rtcConfigurationService = rtcConfigurationService;
    this.resetService = resetService;
    this.settingsService = settingsService;
    this.siteService = siteService;

    this.browser = browser;

    this.me = userService.me;
    this.mySession = userService.mySession;

    this.strictFirewallMode = false;
    rtcConfigurationService.on('configuration', this._updateStrictFirewallMode);
    this._updateStrictFirewallMode();

    this.autoReset = this.meetingService.settings.autoReset;

    this.settingsService.on('videoQuality', this._onVideoQuality);
    this._setHighVideoQualityDebounced = debounce(this._setHighVideoQuality, SET_QUALITY_DEBOUNCE);
    this.isHighVideoQuality = this.settingsService.videoQuality === Quality.HIGH;

    this.settingsService.on('disableIncomingVideo', this._onDisableIncomingVideo);
    this.isIncomingVideoDisabled = this.settingsService.disableIncomingVideo;
  }

  _updateStrictFirewallMode() {
    const Mode = this.rtcConfigurationService.Mode;
    this.strictFirewallMode = (this.rtcConfigurationService.mode === Mode.TCP);
  }

  setStrictFirewallMode(strictFirewallMode) {
    this.strictFirewallMode = strictFirewallMode;
    const Mode = this.rtcConfigurationService.Mode;
    let mode = strictFirewallMode ? Mode.TCP : Mode.UDP;
    this.rtcConfigurationService.setMode(mode);
  }

  _onVideoQuality() {
    this.setHighVideoQuality(this.settingsService.videoQuality === Quality.HIGH);
  }

  setHighVideoQuality(isHighVideoQuality) {
    this._setHighVideoQualityDebounced(isHighVideoQuality);
  }

  _setHighVideoQuality(isHighVideoQuality) {
    this.isHighVideoQuality = isHighVideoQuality;
    let videoQuality = isHighVideoQuality ? Quality.HIGH : Quality.NORMAL;
    this.settingsService.setVideoQuality(videoQuality);
  }

  _onDisableIncomingVideo() {
    this.setDisableIncomingVideo(this.settingsService.disableIncomingVideo);
  }

  setDisableIncomingVideo(disableIncomingVideo) {
    this.isIncomingVideoDisabled = disableIncomingVideo;
    this.settingsService.setDisableIncomingVideo(disableIncomingVideo);
  }

  openInvite() {
    this.modalService.hide(MODAL_ID_SETTINGS);
    this.modalService.show(MODAL_ID_SHARING);
  }

  openResetModal() {
    this.modalService.show(MODAL_ID_RESET);
    this.modalService.hide(MODAL_ID_SETTINGS);
  }

  confirmResetTemplate() {
    this.resetService.reset({ 'sessions': true });
  }

  openSaveAsTemplate() {
    this.modalService.hide(MODAL_ID_SETTINGS);
    this.modalService.show(MODAL_ID_SAVE_AS_TEMPLATE);
  }

  get presentTooltip() {
    if(this.meetingService.settings.presenterMode) {
      // eslint-disable-next-line max-len
      return gettextCatalog.getString('You are presenting. Other participants cannot collaborate or navigate. Everyone is on the same page.');
    } else {
      // eslint-disable-next-line max-len
      return gettextCatalog.getString('Start presenting. In presentation mode, other participants will not be able to collaborate nor navigate. Everyone is on the same page.');
    }
  }

  get upgradeLinkSetting() {
    return gettextCatalog.getString(
      '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to change these settings.',
      {
        url: format('href="%s"', this.siteService.site.subscriptionLink),
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

  get upgradeLinkTemplate() {
    return gettextCatalog.getString(
      '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to save this room as a template.',
      {
        url: format('href="%s" target="_blank"', this.siteService.site.subscriptionLink),
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

}
