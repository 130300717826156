<div
  *ngIf="!hideScopeSwitcher"
  class="mb-10 d-flex justify-content-md-center"
>
  <scope-switcher
    [(scope)]="scope"
    [scopes]="scopes"
  ></scope-switcher>
</div>


<contact-form-page
  [contactForm]="contactForm"
  class="pointer-events-none container-type-inline-size"
  [readonly]="true"
  [view]="contactFormPageView"
  [hideRecaptcha]="hideRecaptcha"
></contact-form-page>
