import VecteraFile from './VecteraFile';
import { file, errors, format, logger, object } from 'utils/util';
import templateModalUploadSpreadsheet from './uploadSpreadsheet.modal.html?raw';


const MAX_FILE_SIZE = 10 * 1024 * 1024;
const POLL_INTERVAL = 2000;
const STILL_PROCESSING_TIMEOUT = 20 * 1000;
const MODAL_ID = 'uploadSpreadsheet';

export default class FileService {
  static get $inject() {
    return [
      'requestService',
      'apiService',
      'meetingService',
      'notificationService',
      'modalService',
    ];
  }

  constructor(
    requestService,
    apiService,
    meetingService,
    notificationService,
    modalService
  ) {
    this._bind();

    this.requestService = requestService;
    this.apiService = apiService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.modalService = modalService;

    this.modalService.register(
      MODAL_ID,
      gettext('Spreadsheet upload'),
      'utils/icons/tl/24x24_cloud_upload_outline.svg',
      templateModalUploadSpreadsheet
    );

    this.uploadSpreadsheetDefer = null;

    this.files = {};
    this.uploads = {};
    this.createCloudFileInfos = {};
    this.maxTransferId = 0;

    this.getInfoIds = new Set();

    this.modalService.on('show', this._onModalShow);
  }

  _bind() {
    this._onUploadError = this._onUploadError.bind(this);
    this._onModalShow = this._onModalShow.bind(this);
  }

  _getInfoUrl(id) {
    return format('files/%s', id);
  }

  _getUniqueTransferId() {
    return ++this.maxTransferId;
  }


  // Note: only use getInfo = false if you are 100% sure the info is NOT needed
  get(id, getInfo) {
    if(getInfo == null) {
      getInfo = true;
    }

    if(!(id in this.files)) {
      let urlTemplate = 'files/%s/%s/';
      this.files[id] = new VecteraFile(this.apiService, urlTemplate, id);

      if(getInfo) {
        this._getInfo(id);
      }
    }

    return this.files[id];
  }


  _getInfo(id) {
    if(id in this.getInfoIds) {
      return;
    }

    this.getInfoIds.add(id);
    this.apiService.get(this._getInfoUrl(id), {
      maxRetries: Infinity
    })
      .then(response => {
        let info = Object.assign({}, response.data);
        this.files[id].setInfo(info);
      })
      .catch(error => {
        if(error.response.status === 403) {
          // This normally only happens when you are kicked out of the meeting room before you have
          // had time to start getting the file info.
          // Do nothing.

        } else if(error.response.status === 404) {
          logger.withContext({ fileId: id }).error('File does not exist');

        } else {
          throw error;
        }
      })
      .finally(() => {
        this.getInfoIds.delete(id);
      });
  }

  /**
   * Upload a localfile to the vectera database
   *
   * @param {Object} localFile
   * @returns {VecteraFile}
   */
  upload(localFile) {
    let typeInfo;

    return this._getType(localFile)
      .then(argTypeInfo => {
        typeInfo = argTypeInfo;
        if(typeInfo.filetype === VecteraFile.Type.UNSUPPORTED) {
          throw new errors.FileTypeError(format(
            'Unsupported file: filename "%s", mimetype "%s"',
            localFile.name, localFile.type));
        }

        if(VecteraFile.isSpreadsheet(typeInfo.mimeType)) {
          return this._getSpreadsheetConfirmation();
        }
      })
      .then(() => {
        let filename = typeInfo.filename;
        let blob = file.toBlob(localFile);
        let data = new FormData();
        data.append('file', blob, filename);
        data.append('meetingId', this.meetingService.id);
        let config = {
          headers: {
            'Content-Type': undefined,
          },
        };

        return this._createLocalOrCloudFile({
          filename: filename,
          size: localFile.size,
          uploadData: data,
          uploadConfig: config,
          uploadMessage: gettextCatalog.getString(
            'Uploading <b>{{ filename }}</b>',
            { filename: filename }
          )
        });
      })
      .then(vecteraFile => {
        if(vecteraFile) {
          vecteraFile.setLocalFile(localFile, VecteraFile.ConvertedToPdf.FALSE);
          return vecteraFile;
        }
      });
  }


  createFromCloudFile(cloudFileInfo, uploadMessage) {
    // cloudFileInfo = {
    //   filename: string,
    //   mimeType: string [optional],
    //   size: number,
    //   url: string,
    //   headers: object [optional],
    // }

    return $q.resolve()
      .then(() => {
        let filename = cloudFileInfo.filename;
        let mimeType = cloudFileInfo.mimeType;
        let extension = filename.split('.').pop().toLowerCase();
        if(!VecteraFile.isSupported(mimeType, extension)) {
          throw new errors.FileTypeError(format(
            'Unsupported file: filename "%s", mimetype "%s"',
            filename, mimeType));
        }

        if(
          VecteraFile.isSpreadsheet(cloudFileInfo.originalMimeType, extension)
          || VecteraFile.isSpreadsheet(cloudFileInfo.mimeType, extension)
        ) {
          return this._getSpreadsheetConfirmation();
        }
      })
      .then(() => {
        if(uploadMessage == null) {
          uploadMessage = gettextCatalog.getString(
            'Creating <b>{{ filename }}</b>',
            { filename: cloudFileInfo.filename }
          );
        }

        let data = {
          meetingId: this.meetingService.id,
          cloudUrl: cloudFileInfo.url,
          cloudHeaders: JSON.stringify(cloudFileInfo.headers || {}),
          cloudName: cloudFileInfo.filename,
        };

        return this._createLocalOrCloudFile({
          filename: cloudFileInfo.filename,
          size: cloudFileInfo.size,
          uploadData: data,
          uploadMessage: uploadMessage,
        });
      });
  }


  _createLocalOrCloudFile(fileInfo) {
    // fileInfo = {
    //   filename: string,
    //   size: number,
    //   uploadData: object,
    //   uploadConfig: object [optional],
    //   uploadMessage: string,
    // }

    let transferId = this._getUniqueTransferId();
    let uploadInfo = {
      transferId: transferId,
      fileInfo: fileInfo,
      progressBar: null,
      request: null,
    };
    this.uploads[transferId] = uploadInfo;

    let promise = $q.resolve()
      .then(() => {
        this._checkSize(fileInfo.size);

        let data = fileInfo.uploadData;
        let config = Object.assign({}, fileInfo.uploadConfig, {
          uploadEventHandlers: {
            progress: this._onUploadProgress.bind(this, transferId),
          },
        });

        uploadInfo.progressBar = this.notificationService.progress(
          fileInfo.uploadMessage,
          this._cancelUploadWithId.bind(this, transferId)
        );

        let apiRequest = this.apiService.post('files', data, config);
        uploadInfo.request = apiRequest.catch(this._onUploadError);
        uploadInfo.request.__requestPromise = apiRequest;

        return uploadInfo.request;
      })

      .then(response => {
        uploadInfo.progressBar.setMessage(fileInfo.uploadMessage);
        $timeout(this._setMessageStillProcessing.bind(this, transferId), STILL_PROCESSING_TIMEOUT);
        return this._pollUploadedFileInfo(transferId, response);
      })

      .finally(() => {
        delete this.uploads[transferId];
        if(uploadInfo.progressBar) {
          uploadInfo.progressBar.cancel();
        }
      });

    promise._transferId = transferId;
    return promise;
  }


  _onUploadError(error) {
    let response = error.response;
    let message;
    if(!response && !response.data) {
      logger.warn(response);
      message = gettextCatalog.getString(`
        Something went wrong while uploading your file.
        Please try again later or with a different file.
      `);
    } else if(
      response.data.nonFieldErrors
      && response.data.nonFieldErrors[0] === 'Available cloud storage exceeded.'
    ) {
      message = gettextCatalog.getString(
        'You don\'t have enough cloud space available to upload this file.'
      );
    } else {
      message = error.message;
    }
    throw new errors.ServerError(message);
  }


  _checkSize(size) {
    if(size > MAX_FILE_SIZE) {
      this.notificationService.warning(gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'You\'re uploading a large file. Keep in mind that it may take some time to upload and download.'
      ));
    }
  }


  _getType(localFile) {
    return $q.resolve()
      .then(() => {
        let blob = file.toBlob(localFile, 10 * 1024);
        let data = new FormData();
        data.append('file', blob, localFile.name);
        return this.apiService.post('filetype/', data, {
          headers: {
            'Content-Type': undefined,
          },
        });
      })
      .then(response => {
        let typeInfo = Object.assign({}, response.data);
        typeInfo.filetype = VecteraFile.Type[typeInfo.filetype];
        return typeInfo;
      });
  }


  _pollUploadedFileInfo(transferId, response) {
    let uploadInfo = this.uploads[transferId];
    if(!uploadInfo) {
      return;
    }

    let fileInfo = response.data;
    if(fileInfo.error === true) {
      uploadInfo.progressBar.cancel();
      throw new errors.FileInfoError('Error while getting file info');

    } else if(fileInfo.pages != null) {
      let file = this.get(fileInfo.id, false);
      file.setInfo(fileInfo);
      return file;

    } else {
      return $timeout(POLL_INTERVAL)
        .then(() => {
          let fileId = fileInfo.id;
          return this.apiService.get(this._getInfoUrl(fileId));
        })
        .then(this._pollUploadedFileInfo.bind(this, transferId));
    }
  }


  _setMessageStillProcessing(transferId) {
    let uploadInfo = this.uploads[transferId];
    if(uploadInfo) {
      uploadInfo.progressBar.setMessage(gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'Still processing file <b>{{ filename }}</b>.<br/> This is taking longer than usual due to the complexity of the file. Consider converting the file to a pdf yourself.',
        { filename: uploadInfo.fileInfo.filename }
      ));
    }
  }


  _onUploadProgress(transferId, event) {
    let uploadInfo = this.uploads[transferId];
    if(uploadInfo) {
      let progress = event.loaded / event.total * 100;
      uploadInfo.progressBar.setProgress(progress * 0.8);
    }
  }



  cancelUpload(promise) {
    this._cancelUploadWithId(promise._transferId);
  }

  _cancelUploadWithId(transferId) {
    let uploadInfo = this.uploads[transferId];
    if(!uploadInfo) {
      return;
    }

    uploadInfo.progressBar.cancel();
    this.apiService.cancel(uploadInfo.request.__requestPromise);
    delete this.uploads[transferId];
  }


  get isUploading() {
    return object.length(this.uploads) > 0;
  }


  _pollCloudFileInfo(transferId, response) {
    let uploadInfo = this.createCloudFileInfos[transferId];
    if(!uploadInfo) {
      return;
    }

    let fileInfo = response.data;
    if(fileInfo.error === true) {
      throw new errors.FileInfoError('Error while getting file info');

    } else if(fileInfo.pages != null) {
      let file = this.get(fileInfo.id, false);
      fileInfo.filetype = VecteraFile.Type[fileInfo.filetype];
      file.setInfo(fileInfo);
      return file;

    } else {
      return $timeout(POLL_INTERVAL)
        .then(() => {
          let fileId = fileInfo.id;
          return this.apiService.get(this._getInfoUrl(fileId));
        })
        .then(this._pollCloudFileInfo.bind(this, transferId));
    }
  }



  /***************************************************
   * Ask confirmation before uploading a spreadsheet *
   ***************************************************/

  _getSpreadsheetConfirmation() {
    if(!this.uploadSpreadsheetDefer) {
      this.uploadSpreadsheetDefer = $q.defer();
      this.modalService.show(MODAL_ID);
    }

    return this.uploadSpreadsheetDefer.promise;
  }


  confirmUploadSpreadsheet() {
    this.uploadSpreadsheetDefer.resolve();
    this.uploadSpreadsheetDefer = null;
    this.modalService.hide(MODAL_ID);
  }


  _onModalShow(modal) {
    if(!modal || modal.id !== MODAL_ID) {
      // Hide the modal permanently in case it is only masked by another modal
      this.modalService.hide(MODAL_ID);
      this.uploadSpreadsheetDefer = null;
    }
  }
}
