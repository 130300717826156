<div class="dropdown dropdown--scrollable" cdkMenu style="width: 400px;">
  <div class="dropdown__list-item disabled border-bottom">
    <span *ngIf="scope === DashboardScope.PERSONAL" i18n>Your booking links</span>
    <span *ngIf="scope === DashboardScope.TEAM" i18n>Your team's booking links</span>
  </div>


  <ng-container *ngIf="!stateService.isReady">
    <div class="dropdown__list-item disabled justify-content-center">
      <loading [timeout]="2000"></loading>
    </div>
  </ng-container>


  <ng-container *ngIf="stateService.isReady && appointmentTypes.length === 0">
    <div class="dropdown__list-item">
      <a
        class="btn btn--sm btn--outline-primary flex-1"
        [href]="urlService.urls.createAppointmentType"
      >
        <svg-icon class="btn__icon" [svgName]="'calendar'" [svgSize]="14"></svg-icon>
        <div class="btn__label" i18n>Get started with scheduling</div>
      </a>
    </div>
  </ng-container>


  <ng-container *ngIf="stateService.isReady && appointmentTypes.length > 0">
    <div
      class="dropdown__list-item border-bottom"
      [copyText]="bookingPageUrl"
    >
      <div class="text-h3 mr-4">
        <span *ngIf="scope === DashboardScope.PERSONAL" i18n>Personal booking page</span>
        <span *ngIf="scope === DashboardScope.TEAM" i18n>Team booking page</span>
      </div>
      <svg-icon [svgName]="'copy'" class="ml-auto my-2"></svg-icon>
    </div>

    <div
      *ngFor="let appointmentType of appointmentTypes"
      class="dropdown__list-item border-bottom"
      [copyText]="appointmentType.url"
    >
      <div class="line-clamp-2 mr-4">
        <badge
          *ngFor="let badge of getLocationBadges(appointmentType)"
          [badge]="badge"
          class="mr-1"
        ></badge>
        <span>{{ appointmentType.name }}</span>
      </div>
      <svg-icon [svgName]="'copy'" class="ml-auto my-2"></svg-icon>
    </div>

    <div class="dropdown__list-item disabled d-flex" *ngIf="canCreate">
      <div class="mr-16" i18n>None of these?</div>
      <a
        class="btn btn--sm btn--outline-primary ml-auto"
        [href]="urlService.urls.createAppointmentType"
      >
        <svg-icon class="btn__icon" [svgName]="'add'" [svgSize]="14"></svg-icon>
        <div class="btn__label" i18n>Add a meeting type</div>
      </a>
    </div>
  </ng-container>
</div>
