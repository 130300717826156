import MeetingLocalRecording from './MeetingLocalRecording';
import MeetingCloudRecording from './MeetingCloudRecording';


export default class MeetingRecordingFactory {
  static get $inject() {
    return [
      '$http',
      '$cookies',
      'apiService',
      'userService',
      'meetingService',
      'streamService',
      'notificationService',
      'requestUserService',
    ];
  }

  constructor(
    $http,
    $cookies,
    apiService,
    userService,
    meetingService,
    streamService,
    notificationService,
    requestUserService
  ) {
    this.$http = $http;
    this.$cookies = $cookies;
    this.apiService = apiService;
    this.userService = userService;
    this.meetingService = meetingService;
    this.streamService = streamService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;
  }

  createMeetingLocalRecording(sendMessageToExtension, properties) {
    properties.meeting = this.meetingService;
    return new MeetingLocalRecording(
      this.userService,
      this.streamService,
      this.$http,
      this.$cookies,
      this.apiService,
      this.notificationService,
      this.requestUserService,
      sendMessageToExtension,
      properties);
  }

  createMeetingCloudRecording(properties) {
    properties.meeting = this.meetingService;
    return new MeetingCloudRecording(
      this.userService,
      this.streamService,
      this.apiService,
      this.notificationService,
      properties);
  }
}
