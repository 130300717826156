
import { storage } from 'utils/util';

const NOTIFICATION_SETTINGS = 'notificationSettings';


export default class WaitingRoomNotificationService {
  static get $inject() {
    return [
      'notifyService',
      'audioContextService',
      'focusService',
      'siteService',
      'waitingRoomSessionsService',
      'notificationService',
    ];
  }

  constructor(
    notifyService,
    audioContextService,
    siteService,
    focusService,
    waitingRoomSessionsService,
    notificationService
  ) {
    this._bind();

    this.notifyService =  notifyService;
    this.audioContextService =  audioContextService;
    this.siteService =  siteService;
    this.focusService =  focusService;
    this.waitingRoomSessionsService = waitingRoomSessionsService;
    this.notificationService = notificationService;

    this.waitingRoomSessionsService.on('knock-join', this._onKnockJoin);

    let defaultNotifications = {
      ownMeetings: false,
      teamWaitingRoom: false,
    };
    this.notifications = storage.getJSONItem(NOTIFICATION_SETTINGS, defaultNotifications);
    if(!this.waitingRoomSessionsService.teamWaitingRoomEnabled) {
      this.notifications.teamWaitingRoom = false;
    }

    this.notifyService.setNotificationSound(window.URLS.knockerSound);
  }


  _bind() {
    this._onKnockJoin = this._onKnockJoin.bind(this);
  }


  _onKnockJoin(waitingRoomSession) {
    this.notify(waitingRoomSession);
  }


  toggleNotifications(type) {
    this.notifications[type] = !this.notifications[type];
    storage.setJSONItem(NOTIFICATION_SETTINGS, this.notifications);
  }


  get notificationPermission() {
    if(window.Notification) {
      return Notification.permission;
    } else {
      return null;
    }
  }

  get hasDektopNotificationPermission() {
    return this.notificationPermission === 'granted';
  }


  get isMissingPermissions() {
    let hasSetSomeNotifications = Object.values(this.notifications).some(val => val);
    return hasSetSomeNotifications && !this.hasDektopNotificationPermission;
  }


  notify(waitingRoomSession) {
    if(
      !this.focusService.hasFocus
      && !waitingRoomSession.redirected
      && ((
        this.waitingRoomSessionsService.ownMeetingKnockingSessions.includes(waitingRoomSession)
        && this.notifications.ownMeetings
      ) || (
        this.waitingRoomSessionsService.teamWaitingRoomSessions.includes(waitingRoomSession)
        && this.notifications.teamWaitingRoom
      ))
    ) {
      this.notifyService.notify(false);
      this._setDesktopNotification(waitingRoomSession);
    }
  }


  _setDesktopNotification(waitingRoomSession) {
    if(!this.hasDektopNotificationPermission || this.focusService.hasFocus) {
      return;
    }
    let message = gettextCatalog.getString(
      '{{ fullName }} is knocking in one of your meeting rooms',
      { fullName: waitingRoomSession.session.user.fullName }
    );

    try {
      if(window.Notification) {
        let notification = new window.Notification(message, {
          icon: window.URLS.images.icon,
        });
        notification.addEventListener('click', this._onNotificationClick);
      }
    } catch(error) {
      // `new Notification()` is not allowed on mobile Chrome, this raises a TypeError: "Failed to
      // construct 'Notification': Illegal constructor. Use
      // ServiceWorkerRegistration.showNotification() instead."
      // We don't support mobile notifications for now, but we'll add them in the future.
      if(error.name !== 'TypeError') {
        throw error;
      }
    }
  }

  requestPermission() {
    if(window.Notification) {
      window.Notification.requestPermission();
    }
  }


  _onNotificationClick(event) {
    let notification = event.target;
    notification.close();
    window.focus();
  }

  showErrorNotification(message) {
    this.notificationService.error(message);
  }

  handlePopupBlocked() {

    const NOTIFICATION_POP_UP_LINK = `
    We can't open the link. Allow pop-up windows for our site in your browser.
    <a
      target="_blank"
      href="${this.siteService.getHelpArticle('waitingRoomBrowserSettings')}"
    >How to allow pop-up windows</a>?
    `;
    this.notificationService.error(NOTIFICATION_POP_UP_LINK, { delay: 30000 });
  }
}
