export default function EnterBlurDirective() {
  return {
    restrict: 'A',

    link: function($scope, $elem) {
      $elem.on('keypress', $event => {
        if($event.keyCode === 13) {
          $scope.$evalAsync(() => $elem.blur());
        }
      });
    },
  };
}
