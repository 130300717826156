import './desktop';
import './phone';

import TileService from './tile.service';
import TileFactory from './tileFactory.service';

angular
  .module('meeting')
  .service('tileService', TileService)
  .service('tileFactory', TileFactory);
