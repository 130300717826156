import { Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


@Component({
  selector: 'single-line-input',
  templateUrl: './single-line-input.component.html',
})
export class SingleLineInputComponent extends ContactFormQuestionInputComponent<string> {
  parsePrefill(string: string) {
    return string;
  }
}
