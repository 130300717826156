import { errors, object, EventEmitter, logger } from 'utils/util';
import { SignalStrength } from '../../signalStrength/signalStrength.service';
import JanusHandle from './JanusHandle';
import { promisifyJanus } from './Janus';
import StatsTracker from '../StatsTracker';

const MAX_CONNECTION_TIME = 30000;


export default class JanusStreamHandle extends JanusHandle {
  constructor(
    settingsService,
    signalStrengthService,
    janus,
    masterHandle,
    session,
    groupId
  ) {
    super(janus);
    EventEmitter.setup(this, ['stats', 'localIceCandidate', 'connected']);

    this.settingsService = settingsService;
    this.signalStrengthService = signalStrengthService;

    this.masterHandle = masterHandle;
    this.session = session;
    this.groupId = groupId;

    this.statsTracker = null;
    this.negotiateInfo = {};
    this.negotiateDefer = null;

    this.isConnected = false;
    this.warnConnectionFailedTimeout = null;

    this.streams = {
      audio: null,
      video: null,
    };
    this.videoBandwidth = 0;  // kbps
  }


  get roomId() {
    return this.masterHandle.roomId;
  }
  get sessionId() {
    return this.masterHandle.sessionId;
  }
  get id() {
    return `${this.session.id}:stream=${this.groupId}`;
  }

  get peerConnection() {
    return this.handle ? this.handle.webrtcStuff.pc : null;
  }


  addStream(stream) {
    if(this.streams[stream.kind]) {
      logger.warn(
        `Tried to add ${stream.id}, but ${this.streams[stream.kind].id} is already added`
      );
      throw new errors.IllegalStateError(`A stream of kind ${stream.kind} is already added.`);
    }

    this.streams[stream.kind] = stream;
    this._onStreamsChanged();
  }

  removeStream(stream) {
    if(stream !== this.streams[stream.kind]) {
      throw new errors.IllegalStateError('Tried to remove a stream that was not added.');
    }

    this.streams[stream.kind] = null;
    if(this.streams.audio || this.streams.video) {
      this._onStreamsChanged();
    } else {
      this.destroy();
    }
  }


  _onStreamsChanged() {
    this.init();
  }


  setVideoBandwidth(videoBandwidth) {
    if(videoBandwidth === this.videoBandwidth) {
      return;
    }

    this.videoBandwidth = videoBandwidth;
    this._onVideoBandwidth(videoBandwidth);
  }

  _onVideoBandwidth() {}


  _onMessage(message) {
    if(message.videoroom && message.videoroom === 'slow_link') {
      const kinds = Object.keys(this.streams).filter(kind => !!this.streams[kind]);
      for(const kind of kinds) {
        this.signalStrengthService.add(kind, SignalStrength.BAD);
      }
    }
  }


  _bootstrapPeerConnection() {
    this.statsTracker = new StatsTracker(this.handle.webrtcStuff.pc);
    this.statsTracker.on('stats', this._onStats);
  }


  _onDestroyed() {
    super._onDestroyed();
    if(this.statsTracker) {
      this.statsTracker.off('stats', this._onStats);
      this.statsTracker.destroy();
      this.statsTracker = null;
    }
  }


  _getAttachConfig() {
    return Object.assign(
      super._getAttachConfig(),
      {
        onlocalicecandidate: this._apply(this._onLocalIceCandidate),
        onremoteicecandidate: this._apply(this._onRemoteIceCandidate),
        iceState: this._apply(this._onIceState),
        webrtcState: this._apply(this._onWebrtcState),
      }
    );
  }


  negotiate(iceRestart) {
    this._enqueue(() => {
      if(![JanusHandle.State.NEGOTIATED, JanusHandle.State.JOINED].includes(this.state)) {
        this.logger.info('Not going to negotiate in state', this.state);
        return;
      }
      return this._negotiateNow(iceRestart);
    });
  }

  renegotiate(iceRestart) {
    this._enqueue(() => {
      if(this.state !== JanusHandle.State.NEGOTIATED) {
        this.logger.info('Not going to renegotiate in state', this.state);
        return;
      }
      return this._negotiateNow(iceRestart);
    });
  }


  doIceRestart() {
    this.renegotiate(true);
  }


  _negotiateNow(iceRestart = false) {
    this._assertState([JanusHandle.State.JOINED, JanusHandle.State.NEGOTIATED]);

    const negotiateInfo = this._getNegotiateInfo();
    if(!iceRestart && object.isEqual(negotiateInfo, this.negotiateInfo)) {
      // No negotiation is needed
      this.logger.info('Cancelling negotiation as nothing changed');
      return;
    }

    this.negotiateDefer = $q.defer();
    this._startNegotiation(iceRestart);
    return this.negotiateDefer.promise;
  }


  _createOffer(iceRestart) {
    return $q.resolve().then(() => {
      const config = this._getJsepConfig(iceRestart);
      const isFirstNegotiation = (this.handle.webrtcStuff.pc == null);
      const promise = promisifyJanus(this.handle.createOffer)(config);
      if(isFirstNegotiation) {
        this._bootstrapPeerConnection();
      }
      return promise;
    });
  }


  _createAnswer(jsep) {
    return $q.resolve().then(() => {
      const config = Object.assign(
        {
          jsep: jsep,
        },
        this._getJsepConfig()
      );
      const isFirstNegotiation = (this.handle.webrtcStuff.pc == null);
      const promise = promisifyJanus(this.handle.createAnswer)(config);
      if(isFirstNegotiation) {
        this._bootstrapPeerConnection();
      }
      return promise;
    });
  }


  _sendJsep(jsep) {
    const config = this._getNegotiateConfig();
    return this.sendMessage(config, jsep);
  }


  _getJsepConfig() {
    return {
      trickle: true,
    };
  }


  _setVideoParameters() {
    return $q.resolve();
  }


  _getNegotiateConfig() {
    return {};
  }



  _onRemoteOffer(offer) {
    this.logger.info('Got offer:', offer && offer.sdp);
    // If we got this offer as a result of a call to _startNegotiation()
    if(this.state === JanusHandle.State.NEGOTIATION_REQUESTED) {
      return this._handleRemoteOffer(offer);
    } else {
      return this._enqueue(() => {
        if(![JanusHandle.State.JOINED, JanusHandle.State.NEGOTIATED].includes(this.state)) {
          this.logger.info('Discarded remote offer in state', this.state);
          return;
        }
        this.negotiateDefer = $q.defer();
        return this._handleRemoteOffer(offer);
      });
    }
  }

  _handleRemoteOffer(offer) {
    this._setState(JanusHandle.State.NEGOTIATING);
    this.negotiateInfo = this._getNegotiateInfo();
    this._createAnswer(offer)
      .then(jsep => this._sendJsep(jsep))
      .then(() => this._postNegotiate())
      .catch(error => this._onError(error));

    return this.negotiateDefer.promise;
  }


  _onRemoteAnswer(answer) {
    this.logger.info('Got answer:', answer && answer.sdp);
    return this._setAnswer(answer)
      .then(() => this._postNegotiate())
      .catch(error => this._onError(error));
  }


  _setAnswer(answer) {
    const config = {
      jsep: answer,
    };
    return promisifyJanus(this.handle.handleRemoteJsep)(config);
  }


  _postNegotiate() {
    return this._setVideoParameters().then(() => {
      this._setState(JanusHandle.State.NEGOTIATED);
      this.negotiateDefer.resolve();
      this.negotiateDefer = null;
    });
  }


  _onLocalIceCandidate(candidate) {
    this.emit('localIceCandidate', candidate);
    if(this.statsTracker) {
      this.statsTracker.onIceRestart();
    }
  }

  _onRemoteIceCandidate() {
    if(this.statsTracker) {
      this.statsTracker.onIceRestart();
    }
  }

  _logIceCandidates() {
    if(!this.peerConnection) {
      return $q.resolve();
    }
    return this.peerConnection.getStats().then(stats => {
      const candidates = {
        local: [],
        remote: [],
      };
      let selectedPair = {
        local: null,
        remote: null,
      };

      for(const report of stats) {
        if(report.type === 'local-candidate' || report.type === 'remote-candidate') {
          // eslint-disable-next-line max-len
          const candidate = `${report.id}=${report.candidateType} ${report.address}:${report.port}/${report.protocol}`;
          candidates[report.type.split('-')[0]].push(candidate);
        } else if(report.type === 'candidate-pair' && report.nominated) {
          selectedPair = {
            local: report.localCandidateId,
            remote: report.remoteCandidateId,
          };
        }
      }

      this.logger.info(
        `Local ICE candidates: ${candidates.local.join(', ')}. `
        + `Remote ICE candidates: ${candidates.remote.join(', ')}. `
        + `Selected ICE candidate pair: ${selectedPair.local} -> ${selectedPair.remote}.`
      );
    });
  }


  _onStats(stats) {
    for(const track of this.mediaStream.getTracks()) {
      const stream = this.streams[track.kind];
      if(stream) {
        const report = stats[this.direction][track.kind];
        this.emit('stats', this, stream, track, report);
      }
    }
  }


  _onIceState(state) {
    this.logger.info('iceState:', state);
    const connected = state === 'connected' || state === 'completed';
    const disconnected = state === 'disconnected' || state === 'failed';

    if(connected) {
      $timeout.cancel(this.warnConnectionFailedTimeout);
      this.warnConnectionFailedTimeout = null;
      this._logIceCandidates();

    } else {
      if(!this.warnConnectionFailedTimeout) {
        this.warnConnectionFailedTimeout = $timeout(() => {
          if(this.state === JanusHandle.State.DESTROYED) {
            return;
          }
          this._logIceCandidates().then(() => {
            this.logger.warn('Failed to connect after %ss', MAX_CONNECTION_TIME / 1000);
            this.destroy();
          });
        }, MAX_CONNECTION_TIME);
      }
      if(disconnected) {
        this.doIceRestart();
      }
    }
  }


  _onWebrtcState(isConnected) {
    this.logger.info('WebRTC state:', isConnected ? 'connected' : 'disconnected');
    this.isConnected = isConnected;
    this.emit('connected', this, isConnected);
  }
}
