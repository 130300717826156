import { errors, bind } from 'utils/util';
import User from './User';
import UserInfoManager from './UserInfoManager';



export default class UserInfoService {
  static get $inject() {
    return [
      'apiService',
      'meetingBroadcastService',
      'userService',
      'meetingService',
      'requestUserService',
    ];
  }

  constructor(
    apiService,
    meetingBroadcastService,
    userService,
    meetingService,
    requestUserService
  ) {
    bind(this);


    this.apiService = apiService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.userService = userService;
    this.meetingService = meetingService;
    this.requestUserService = requestUserService;

    this.managers = new Map();

    this._setupListeners();

    if(window.REQUEST_USER) {
      this._getManager(this.userService.me).update(window.REQUEST_USER);
    }


    User._getInfo = this._getUserInfo;
    User._setInfo = this._setUserInfo;

    this.meetingBroadcastService.on('_session', this._onSessionInfo, false);
  }

  _setupListeners() {
    this.meetingBroadcastService.on('_user-info', this.updateUserInfo, true);

    // meetingService will emit all the info it receives on join. We want to listen
    // to some of these so that we can pro-actively create the corresponding User objects.
    this.meetingService.on('owner', this._initializeUser);
    this.meetingService.on('activeUsers', activeUsers => {
      activeUsers.forEach(this._initializeUser);
    });

    this.meetingService.on('size', () => {
      if(this.meetingService.userIsOwner(this.userService.me)) {
        this._getManager(this.userService.me).fetch();
      }
    });
  }


  _getManager(user) {
    if(!this.managers.has(user)) {
      this.managers.set(user, new UserInfoManager(
        this.apiService,
        this.userService,
        this.requestUserService,
        user
      ));
    }

    return this.managers.get(user);
  }


  _getUserInfo(user) {
    let manager = this._getManager(user);
    if(manager.state === UserInfoManager.State.EMPTY) {
      manager.fetch(user);
    }

    return manager.info;
  }


  _setUserInfo(user, info) {
    let manager = this._getManager(user);
    return manager.set(info);
  }

  _initializeUser(info) {
    if(info.id == null) {
      throw new errors.InvalidArgumentError('info.id must be set');
    }

    const user = this.userService.getOrCreate(info.id);
    this._getManager(user).fetch();
  }

  updateUserInfo(info) {
    if(info.id == null) {
      throw new errors.InvalidArgumentError('info.id must be set');
    }

    let user = this.userService.getOrCreate(info.id);
    let manager = this._getManager(user);
    manager.update(info);

    return user;
  }


  _onSessionInfo(info) {
    this.updateUserInfo(info.user);
    this.userService.setMySessionId(info.id);
  }
}
