import { browser } from 'utils/util';

export default class NotSupportedController {
  static get $inject() {
    return [
      '$location',
    ];
  }

  constructor(
    $location
  ) {
    this.$location = $location;
    this.browser = browser;
  }


  get recommendation() {
    if(browser.isIOS() && browser.isSafari()) {
      return gettextCatalog.getString(`
        We recommend that you update iOS to the latest version
        or switch to the desktop to start your online meeting.
      `);

    } else if(browser.isIOS()) {
      return gettextCatalog.getString(`
        We recommend that you open the meeting room in Safari, or switch to the desktop.
      `);

    } else {
      return gettextCatalog.getString(`
        We recommend that you open the meeting room in
        <a {urlChrome}>Chrome</a> or
        <a {urlFirefox}>Firefox</a>.
      `, {
        urlChrome: 'href="https://www.google.com/chrome/browser" target="_blank"',
        urlFirefox: 'href="https://www.mozilla.org/firefox" target="_blank"',
      });
    }
  }


  get url() {
    return this.$location.absUrl();
  }
}
