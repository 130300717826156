import { Component, Input } from '@angular/core';
import { SiteService } from 'utils/site.service';

/**
 * Clearly show that a feature is locked behind a subscription play by summarizing the
 * feature and showing a badge in marketing colors stating that is a "Pro"/"Beyond" feature
 * Clicking this badge links to the subscriptions page
 *
 * Example usage:
 *
 * ```
 * # With HTML attributes
 *   <pro-feature-badge
 *    *ngIf="hasFreeSubscription"
 *     featureText="Change your fancy setting"
 *     i18n-featureText
 *   >
 *   </pro-feature-badge>
 */
@Component({
  selector: 'pro-feature-badge[featureText]',
  templateUrl: './pro-feature-badge.component.html',
  styleUrls: ['./pro-feature-badge.component.scss'],

})
export class ProFeatureBadgeComponent {

  @Input() featureText!: string;
  @Input() showIcon = true;

  constructor(
    public siteService: SiteService,
  ) {}

  get planText() {
    const proPlanName = `<b>${this.siteService.site.proPlanName}</b>`;
    return $localize `:Example; Pro feature:${proPlanName} feature`;
  }
}
