import { NgModule } from '@angular/core';
import { providers } from './angularjs-upgraded-providers';
import { components } from './angularjs-upgraded-components';

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  providers: [
    ...providers,
  ],
})
export class AngularjsUpgradedMeetingModule {}
