import templateModal from './reset.modal.html?raw';
import { format, array, object, EventEmitter } from 'utils/util';

export const MODAL_ID = 'reset';

export default class ResetService {
  static get $inject() {
    return [
      'meetingService',
      'modalService',
      'notificationService',
      'apiService',
      'meetingBroadcastService',
      'userService'
    ];
  }

  constructor(
    meetingService,
    modalService,
    notificationService,
    apiService,
    meetingBroadcastService,
    userService
  ) {
    this._bind();
    EventEmitter.setup(this, ['reset']);

    this.modalService = modalService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.apiService = apiService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.userService = userService;

    this.resetDoneNotification = null;
    this.resetting = false;
    this.lastResetOptions = {};

    modalService.register(
      MODAL_ID,
      gettext('Reset meeting room'),
      'utils/icons/tl/24x24_refresh_outline.svg',
      templateModal
    );

    this.meetingBroadcastService.on('_reset', this._onMeetingReset, true);
  }


  _bind() {
    this.showResetSuccesNotification = this.showResetSuccesNotification.bind(this);
    this._onMeetingReset = this._onMeetingReset.bind(this);
  }


  _onMeetingReset(resetParameters) {
    this.emit('reset', resetParameters);
    if(this.userService.iAmHost) {
      this.showResetSuccesNotification(resetParameters);
    }
  }


  hideModal() {
    this.modalService.hide(MODAL_ID);
  }

  reset(argOptions) {
    this.resetting = true;

    let defaultOptions = {
      sessions: false,
      chat: false,
      whiteboards: false,
      recordings: false,
      userPermissions: false,
    };

    let options = Object.assign({}, defaultOptions, argOptions);

    let resetParameters = {
      sessions: options.sessions,
      chat: options.chat,
      whiteboards: options.whiteboards,
      recordings: options.recordings,
      userPermissions: options.userPermissions,
    };

    let url = format('meetings/%s/reset/', this.meetingService.id);
    return this.apiService.patch(url, resetParameters)
      .catch(error => {
        let message = error.message;
        if(!message) {
          message = gettextCatalog(
            'Something went wrong while resetting the room. Please try again.'
          );
        }
        this.notificationService.error(message);
      })
      .finally(() => {
        this.hideModal();
        this.lastResetOptions = options;
        this.resetting = false;
      });
  }

  showResetSuccesNotification(resetParameters) {
    // Another modal is shown if sessions are resetted, so no notification is necessary
    if(resetParameters.sessions) {
      return;
    }

    let message = this._getResetMessage(resetParameters);
    this.resetDoneNotification = this.notificationService.success(message);
  }


  _getResetMessage(argResetParameters) {
    /* eslint-disable max-len */
    let resetItems = {
      sessions:        { text: gettextCatalog.getString('participant activity'),  isPlural: false, },
      chat:            { text: gettextCatalog.getString('chat history'),          isPlural: false, },
      whiteboards:     { text: gettextCatalog.getString('whiteboards'),           isPlural: true,  },
      files:           { text: gettextCatalog.getString('files'),                 isPlural: true,  },
      recordings:      { text: gettextCatalog.getString('cloud recordings'),      isPlural: true,  },
      userPermissions: { text: gettextCatalog.getString('specific user access'),  isPlural: false  },
    };
    /* eslint-enable max-len */

    let resetParameters = Object.assign({}, argResetParameters, {
      files: argResetParameters.whiteboards,
    });

    let texts = [];
    let isPlural = object.length(object.filter(resetParameters, (key, value) => value)) > 1;
    for(let item in resetItems) {
      if(resetParameters[item]) {
        texts.push(resetItems[item].text);
        isPlural = isPlural || resetItems[item].isPlural;
      }
    }

    let joinedArray = array.joinVerbose(texts, ',', gettextCatalog.getString('and'));
    if(isPlural) {
      return gettextCatalog.getString('The {{ items }} have been reset.', { items: joinedArray });
    } else {
      return gettextCatalog.getString('The {{ item }} has been reset.', { item: joinedArray });
    }
  }
}
