export default function DropdownSubmenuWrapperDirective() {
  return {
    restrict: 'C',
    link: function($scope, $elem) {
      $elem.on('click', onClick);
      let $elemSubmenu = $elem.find('.dropdown__submenu');

      function onClick($event) {
        let $target = angular.element($event.target);
        while($target.length > 0 && !$target.is($elemSubmenu)) {
          if($target.is($elem)) {
            toggleMenu();
            break;
          }
          $target = $target.parent();
        }
      }

      function toggleMenu() {
        if($elemSubmenu.hasClass('active')) {
          $elemSubmenu.css({
            height: 0,
          });
          setTimeout(() => {
            $elemSubmenu.removeClass('active');
          }, 200);

        } else {
          $elemSubmenu.css({
            height: '',
          });
          $elemSubmenu.addClass('active');
          let height = $elemSubmenu.height();
          $elemSubmenu.css({
            height: 0,
          });
          setTimeout(() => {
            $elemSubmenu.css({
              height: height,
            });
          });
        }
      }
    }
  };
}
