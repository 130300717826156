<div class="mb-2">
  <combo-box
    class="text-teal-500"
    [options]="countryOptions"
    [ngModel]="getSubValue('country')"
    (ngModelChange)="setSubValue('country', $event)"
    placeholder="Country"
    i18n-placeholder
  ></combo-box>
</div>

<div class="mb-2 d-flex flex-direction-row">
  <input
    class="input flex-4 mr-2 text-teal-500"
    [ngModel]="getSubValue('street')"
    (ngModelChange)="setSubValue('street', $event)"
    placeholder="Street"
    i18n-placeholder
  />
  <input
    class="input flex-1 text-teal-500"
    [ngModel]="getSubValue('number')"
    (ngModelChange)="setSubValue('number', $event)"
    placeholder="Nr"
    i18n-placeholder
  />
</div>
<div class="mb-2 d-flex flex-direction-row">
  <input
    class="input flex-2 mr-2 text-teal-500"
    [ngModel]="getSubValue('zipcode')"
    (ngModelChange)="setSubValue('zipcode', $event)"
    placeholder="Zip Code"
    i18n-placeholder
  />
  <input
    class="input flex-3 text-teal-500"
    [ngModel]="getSubValue('city')"
    (ngModelChange)="setSubValue('city', $event)"
    placeholder="City"
    i18n-placeholder
  />
</div>
