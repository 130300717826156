const DEFAULT_OPTIONS = Object.freeze({
  name: '',
  shorthand: '',
  validateNextFunc: () => true,
  nextFunc: () => $q.resolve()
});

export default class WizardStep {

  /**
   * A WizardStep shows one panel of a Wizard.
   *
   * @param {String} id: the id of the WizardStep
   * @param {String} template: the template that is shown if this step is the current wizard step.
   * @param {String} argOptions.name: the name of the current step. Not the same as wizard title.
   * @param {String} argOptions.shorthand: A short name of the current step, to be used in the
   * progress bar
   * @param {Function} argOptions.validateNextFunc: the callback that will determine whether the
   * user can proceed to the next step. This function returns a boolean.
   * @param {Function} argOptions.nextFunc: the callback that is executed if the user proceeds to
   * the next step. This function returns a Promise.
   *
   * If a callback is not defined, a default one is used.
   */
  constructor(
    id,
    template,
    argOptions
  ) {
    this.id = id;
    this.template = template;
    this.index = null;

    let options = Object.assign({}, DEFAULT_OPTIONS, argOptions);
    Object.assign(this, options);
  }

  get translatedName() {
    return gettextCatalog.getString(this.name);
  }

  get translatedShorthand() {
    return gettextCatalog.getString(this.shorthand);
  }

  setIndex(index) {
    this.index = index;
  }
}
