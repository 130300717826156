<div class="checkbox__wrapper">
  <input
    type="checkbox"
    class="checkbox"
    [name]="question.id"
    [id]="question.id + '_checkbox'"
    [ngModel]="value"
    (ngModelChange)="writeValue($event)"
  />
  <label
    class="checkbox__label"
    [for]="question.id + '_checkbox'"
  >
    <span [class.required]="question.required">
      {{ question.label }}
    </span>
  </label>
</div>
