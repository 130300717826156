export function length(obj) {
  return Object.keys(obj).length;
}


export function clear(obj) {
  for(let key in obj) {
    delete obj[key];
  }
}


export function filter(obj, fn) {
  return Object.keys(obj)
    .filter(key => fn(key, obj[key]))
    .reduce((result, key) => (result[key] = obj[key], result), {});
}


export function map(obj, fn) {
  return Object.keys(obj)
    .map(key => fn(key, obj[key]))
    .reduce((result, val) => (result[val[0]] = val[1], result), {});
}


export function forEach(obj, fn) {
  return Object.keys(obj)
    .forEach(key => fn(key, obj[key]));
}


export function some(obj, fn) {
  return Object.keys(obj)
    .some(key => fn(key, obj[key]));
}


export function setDefault(obj, key, value) {
  if(!(key in obj)) {
    obj[key] = value;
  }
  return obj[key];
}


export function pop(obj, key) {
  let value = obj[key];
  delete obj[key];
  return value;
}


export function fromTuples(...tuples) {
  return Object.assign(...tuples.map(([key, val]) => ({ [key]: val })));
}



export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}


export function assignDeep(target, ...sources) {
  if(sources.length === 0) {
    return target;
  }
  const source = sources.shift();

  if(isObject(target) && isObject(source)) {
    forEach(source, (key, value) => {
      if(isObject(value)) {
        if(!target[key]) {
          target[key] = {};
        }
        assignDeep(target[key], value);

      } else {
        target[key] = value;
      }
    });
  }

  return assignDeep(target, ...sources);
}


export function isEqual(obj1, obj2) {
  if(length(obj1) !== length(obj2)) {
    return false;
  }

  for(let key in obj1) {
    if(!obj2.hasOwnProperty(key) || obj2[key] !== obj1[key]) {
      return false;
    }
  }

  return true;
}


/**
 * Get a property of a nested object structure, based on the property path.
 *
 * Example:
 * let obj = {
 *   field: {
 *     one: 1,
 *     two: 2,
 *   },
 * };
 * resolve('field.one', obj);  // Result: 1.
 *
 * @param {Object} obj - The object to query. Defaults to self.
 * @param {string} path - The property path.
 * @returns {any} The value in the object at the property path, or undefined if the property does
 *                not exist.
 */
export function resolve(obj, path) {
  let parts = path.split('.');
  return parts.reduce((prev, curr) => prev && prev[curr], obj);
}


export function setResolve(obj, path, value) {
  let parts = path.split('.');
  let parentPath = parts.slice(0, parts.length - 1).join('.');
  let property = parts[parts.length - 1];

  let parentObj = parentPath ? resolve(obj, parentPath) : obj;
  parentObj[property] = value;
}
