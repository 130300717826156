import WizardStep from './WizardStep';
import { bind, logger } from 'utils/util';


const DEFAULT_OPTIONS = Object.freeze({
  title: null,
  showSteps: true,
  closeable: false,
  footerHTML: '',
  scope: {},
});


/**
 * A Wizard consists of a number of steps, reachable using back and next buttons.
 * Every step has a template that will be shown. The Wizard also keeps info about errors that
 * might arise during a step.
 *
 * @param {String} id - Id of the wizard
 * @param {Object} argOptions - object containing finetuning options
 * @param {String} argOptions.title - title of the wizard
 * @param {boolean} argOptions.showSteps - whether the progression should be shown in a sidebar
 * @param {boolean} argOptions.closeable - whether the wizard should have a close button.
 * @param {String} argOptions.footerHTML - add some html to the footer of the sidebar
 * @param {Object} argOptions.scope - used to pass variables (such as a controller) to the wizard
 */
export default class Wizard {

  constructor(id, argOptions) {
    bind(this);

    let options = Object.assign({}, DEFAULT_OPTIONS, argOptions);
    Object.assign(this, options);

    this.id = id;
    this.currentStep = null;
    this.steps = [];

    this.isProcessing = false;
    this.errors = {};
  }


  /**
   * Adds a new step to the Wizard.
   *
   * See WizardStep.constructor for the meaning of the parameters.
   */
  addStep(id, template, argOptions) {
    let step = new WizardStep(id, template, argOptions);

    step.setIndex(this.steps.length);
    this.steps.push(step);
  }

  /**
   * Sets the first step as the current step
   */
  start() {
    if(this.steps.length > 0) {
      this.setCurrentStep(this.steps[0]);
    }
  }


  setCurrentStep(step) {
    this.currentStep = step;
  }


  get currentStepIndex() {
    return this.currentStep.index;
  }

  get translatedFooterHTML() {
    return gettextCatalog.getString(this.footerHTML);
  }

  /***************
   * WIZARD FLOW *
   **************/

  get shouldShowBack() {
    return this.currentStepIndex !== 0;
  }


  back() {
    this._clearErrors();
    this.currentStep = this.steps[Math.max(this.currentStepIndex - 1, 0)];
  }


  /**
   * Validates if the user can proceed to the next step. This is only possible if the wizard
   * is not processing (i.e in the middle of proceeding to the next step) and the validation
   * callback is successful. The callback function returns a boolean.
   *
   * @returns {boolean}
   */
  validateNext() {
    return !this.isProcessing && this.currentStep.validateNextFunc();
  }


  /**
   * Marks the wizard as 'processing' while the next callback is executed. If successful,
   * the wizard stops processing and sets the next step. The callback function returns a promise.
   */
  next() {
    this._clearErrors();
    if(!this.validateNext()) {
      return;
    }
    this._startProcessing();
    $q.when(this.currentStep.nextFunc())
      .then(this._setNextStep)
      .catch(error => {
        this.setAPIErrors(error.response);
      })
      .finally(this._stopProcessing);
  }


  _setNextStep() {
    this._clearErrors();
    this.currentStep = this.steps[Math.min(
      this.currentStepIndex + 1,
      this.steps.length - 1
    )];
  }


  get nextText() {
    if(this.currentStepIndex === this.steps.length - 1) {
      return gettextCatalog.getString('Finish');
    } else {
      return gettextCatalog.getString('Continue');
    }
  }

  get translatedTitle() {
    return gettextCatalog.getString(this.title);
  }

  /**********
   * ERRORS *
   *********/

  /**
   * Set the errors variable to the data of the errorResponse. If the error status is 500, we set
   * a generic error message and log the error.
   *
   * @param {Object} errorResponse: the error response from an API call
   */
  setAPIErrors(errorResponse) {
    if(errorResponse.status !== 500) {
      Object.assign(this.errors, errorResponse.data);
    } else {
      logger.error(errorResponse);

      this.errors['genericErrorMessage'] = gettextCatalog.getString(
        `Something went wrong processing your request. Please reload the page or
        <a {{ url }}>contact us</a> if the issue persists.`,
        { url: 'href="#" onclick="vecteraShowSupport()"' }
      );
    }
  }

  _clearErrors() {
    this.errors = {};
  }


  /*******
  * UTIL *
  ********/

  _startProcessing() {
    this.isProcessing = true;
  }

  _stopProcessing() {
    this.isProcessing = false;
  }
}
