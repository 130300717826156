import { object } from 'utils/util';


const MAX_USERS = 4;
const NOTIFICATION_DURATION = 20000;


export default class MaxUsersNotificationService {
  static get $inject() {
    return [
      'meetingService',
      'userService',
      'meetingBroadcastService',
      'notificationService',
    ];
  }

  constructor(
    meetingService,
    userService,
    meetingBroadcastService,
    notificationService
  ) {
    this._bind();

    this.meetingService = meetingService;
    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.notificationService = notificationService;

    this.hasBeenShown = false;

    this.users = {};

    this._setupListeners();
  }


  _bind() {
    this._addUser = this._addUser.bind(this);
    this._removeUser = this._removeUser.bind(this);
    this._showNotification = this._showNotification.bind(this);
  }


  _setupListeners() {
    this.userService.on('userJoin', this._addUser);
    this.userService.on('userExit', this._removeUser);
  }


  _addUser(user) {
    this.users[user.id] = user;
    let numUsers = object.length(this.users);

    if(
      !this.meetingService.settings.allowUseMediaserver
      && !this.meetingBroadcastService.initializing
      && numUsers > MAX_USERS
      && !this.hasBeenShown
    ) {
      this._showNotification();
      this.hasBeenShown = true;
    }
  }

  _removeUser(user) {
    delete this.users[user.id];
  }


  _showNotification() {
    this.notificationService.info(
      gettextCatalog.getString(
        /* eslint-disable max-len */
        /// amount is always more than 3
        'Wow, more than {{ amount }} people! Please continue, but keep in mind that you may experience connection issues depending on your device and connection quality.',
        { amount: MAX_USERS }
      ),
      {
        delay: NOTIFICATION_DURATION
      }
    );
  }
}
