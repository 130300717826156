import { ContactWidgetsComponent } from './contact-widgets';
import { ContactWidgetConfigComponent } from './contact-widget-config';

import { downgradeComponent } from '@angular/upgrade/static';

angular
  .module('contactWidget', [])

  .directive(
    'contactWidgets',
    downgradeComponent({ component: ContactWidgetsComponent })
  )
  .directive(
    'contactWidgetConfig',
    downgradeComponent({ component: ContactWidgetConfigComponent })
  );
