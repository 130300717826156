import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { bind } from 'utils/util';
import {
  Appointment,
  UserAppointment,
  OrganizationAppointment
} from 'scheduling/models/Appointment';
import { UrlService } from 'utils/url.service';
import { SchedulingState } from 'scheduling/variables';
import {
  DashboardScope, DataPageComponent
} from 'dashboard/data-page/data-page.component';

type SchedulingActionTarget = '_self' | '_blank';
class SchedulingAction {
  constructor(
    public text: string,
    public url: string,
    public target: SchedulingActionTarget = '_self',
  ) { }
}

@Component({
  selector: 'upcoming-appointments',
  templateUrl: './upcoming-appointments.component.html',
})
export class UpcomingAppointmentsComponent extends DataPageComponent implements OnChanges {
  // we cannot use 'scope' since it's a property on DataPageComponent. scopePasser is only
  // used to pass on 'scope' from the <scope-switcher> to the DataPageComponent
  @Input() public scopePasser = DashboardScope.PERSONAL;

  public appointments: Appointment[] = [];

  constructor(
    public urlService: UrlService,
  ) {
    super();
    bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scopePasser) {
      this.scope = changes.scopePasser.currentValue;
    }
  }

  override fetch(): Promise<any> {
    const modelConfig = this.scope === DashboardScope.PERSONAL ?
      {
        model: UserAppointment,
        identifiers: { userId: this.requestUserService.user.id }
      } :
      {
        model: OrganizationAppointment,
        identifiers: { organizationId: this.requestUserService.user.organizationId }
      };

    const apiConfig = {
      params: {
        filter: `isCancelled = false AND end > ${new Date().toISOString()}`,
        orderBy: 'start',
        page: this.page,
        perPage: 10,
      }
    };

    return this.listCancelable(modelConfig, apiConfig).then(({ data: appointments }) => {
      this.appointments = appointments as Appointment[];
    });
  }

  get schedulingActions(): Record<SchedulingState, SchedulingAction> {
    return {
      new: new SchedulingAction(
        $localize`Get started: connect your calendar`,
        this.urlService.urls.connectScheduling,
      ),
      authenticated: new SchedulingAction(
        $localize`Authorize your calendar connection`,
        this.urlService.urls.connectScheduling,
      ),
      authorized: new SchedulingAction(
        $localize`Get started: set up your preferences`,
        this.urlService.urls.configureScheduling,
      ),
      configured: new SchedulingAction(
        $localize`Create your first meeting type`,
        this.urlService.urls.appointmentTypes,
      ),
      bookable: new SchedulingAction(
        $localize`Plan a meeting`,
        this.urlService.urls.planMeeting,
        '_blank',
      ),
      expired: new SchedulingAction(
        $localize`Reconnect your calendar`,
        this.urlService.urls.connectScheduling,
      ),
      disconnected: new SchedulingAction(
        $localize`Reconnect your calendar`,
        this.urlService.urls.connectScheduling,
      )
    };
  }

  get shouldShowTeamTeaser(): boolean {
    return (
      this.scope === DashboardScope.TEAM
      && this.requestUserService.user.organization.isSolo
    );
  }

  get schedulingAction(): SchedulingAction {
    const schedulingState: SchedulingState = this.requestUserService.user.schedulingConfig.state;
    return this.schedulingActions[schedulingState];
  }

  get inviteUrl(): string {
    return this.urlService.urls.teamMembers;
  }
}
