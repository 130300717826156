export default class AccessLevel {
  static getFromId(id) {
    return Object.values(this).find(accessLevel => accessLevel.id === id);
  }

  constructor(
    id,
    _label,
    isHost,
    canAlwaysJoin,
    shouldKnock,
    _infoText,
    userInfoText,
    _dropdownInfoText,
    icon,
    level
  ) {
    this.id = id;
    this._label = _label;
    this.isHost = isHost;
    this.canAlwaysJoin = canAlwaysJoin;
    this.shouldKnock = shouldKnock;
    this._infoText = _infoText;
    /**
     * userInfoText has a "userName" token that need to be filled in, it can only be translated by
     * gettext where the token value is known. At the time of writing this is when AccessLevelInfo
     * is built in the getter of the userTile.
     */
    this.userInfoText = userInfoText;
    this._dropdownInfoText = _dropdownInfoText;
    this.level = level;
    this.icon = icon;
  }

  get label() {
    return gettextCatalog.getString(this._label);
  }

  get infoText() {
    return this._infoText ? gettextCatalog.getString(this._infoText) : null;
  }

  get dropdownInfoText() {
    return this._dropdownInfoText ? gettextCatalog.getString(this._dropdownInfoText) : null;
  }
}

/* eslint-disable max-len */
AccessLevel.SHOULD_KNOCK = new AccessLevel(
  'should_knock',
  gettext('Should knock'),
  false,
  false,
  true,
  gettext('You should knock and wait for a host to let you into the meeting room.'),
  gettext('{{ userName }} should knock and wait for a host to let them into the meeting room.'),
  gettext('When joining the meeting room, guests with this access level have to knock and wait for a meeting host to let them in.'),
  'utils/icons/tl/24x24_lock_outline.svg',
  0
);
AccessLevel.CAN_ALWAYS_JOIN = new AccessLevel(
  'can_always_join',
  gettext('Can always join'),
  false,
  true,
  false,
  gettext('You can always join the meeting room, even when the host is not present.'),
  gettext('{{ userName }} can always join the meeting room, even when the host is not present.'),
  gettext('Guests with this access level can join the meeting room at any time, even when the host is not present.'),
  'utils/icons/tl/24x24_lock_open_outline.svg',
  1
);
AccessLevel.IS_HOST = new AccessLevel(
  'is_host',
  gettext('Is host'),
  true,
  true,
  false,
  gettext('You are a meeting host. You can invite people, let knockers in, change the meeting room settings and see private notes.'),
  gettext('{{ userName }} is a meeting host. They can invite people, let knockers in, change the meeting room settings and see private notes.'),
  gettext('Meeting hosts can invite people, let knockers in, change the meeting room settings and see private notes.'),
  'utils/icons/tl/24x24_verified_outline.svg',
  2
);
/* eslint-enable max-len */

// Dummy accessLevels to be able to render it nicely or remove the accesslevel
AccessLevel.DISABLED = new AccessLevel(
  'disabled',
  gettext('Disabled')
);
AccessLevel.REMOVE = new AccessLevel(
  'remove',
  gettext('Remove specific user access')
);
