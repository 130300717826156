import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, timer } from 'rxjs';

import UserService from 'meeting/angularjs/main/users/user.service';
import { BroadcastService } from 'meeting/meeting-room/communication/broadcast.service';
import {
  CachedMeetingState,
  CachedSmartSummaryState,
} from 'meeting/meeting-room/communication/session-backend.service';
import { ActiveAudioStreamService } from 'meeting/meeting-room/stream/active-audio-stream.service';
import { RequestUserService } from 'utils/requestUser.service';
import { ModalService } from 'utils/ui-components/modal/modal.service';
import { EventEmitter, assertOrLog, assertOrThrow, bind, logger } from 'utils/util';
import { ViewOrganizationService } from 'utils/view-organization.service';

import {
  SmartSummaryNoticeModalComponent
} from './notice-modal/smart-summary-notice-modal.component';
import { SmartSummaryBackendService } from './smart-summary-backend.service';
import { SmartSummaryRecorder } from './smart-summary-recorder';
import { SmartSummaryTrackingService } from './smart-summary-tracking.service';


export enum ErrorType {
  transcription = 'transcription',
  summarization = 'summarization',
}

const ERROR_CODES_MESSAGE = {
  // eslint-disable-next-line max-len
  'empty_transcription': $localize `The duration of the audio was too short to generate a summary. I'm afraid you'll have to try again.`
};
const DEFAULT_ERROR_MESSAGES = {
  // eslint-disable-next-line max-len
  [ErrorType.transcription]: $localize `Something went wrong while transcribing the meeting. I'm afraid you'll have to try again.`,
  // eslint-disable-next-line max-len
  [ErrorType.summarization]:  $localize `Something went wrong while summarizing the meeting. I'm afraid you'll have to try again.`
};


export type State =
  | { stage: 'limit-reached' }
  | { stage: 'unstarted' }
  | { stage: 'recording', iAmControllingHost: false }
  | {
    stage: 'recording',
    iAmControllingHost: true,
    recorder: SmartSummaryRecorder,
    // Whether we have enough recorded audio to be able to accurately detect the language used
    recordingLongEnough: boolean,
    startedAt: number,
  }
  | { stage: 'generating-transcription', iAmControllingHost: boolean }
  | { stage: 'generating-summary', iAmControllingHost: boolean }
  | { stage: 'summary-generated', iAmControllingHost: boolean, summary: Summary }
  | { stage: 'errored', errorMessage: string };


export type Transcript = {
  utterances: Utterance[];
  languageCode: string | null;
  duration: number;
}

export type Utterance = {
  text: string;
  speaker: string;
  start: number;
}

export type Summary = {
  asPlainText: string;
  asHtml: string;
};

type Usage = {
  secondsUsed: number;
  secondsAllowed: number;
};

export type SummaryGeneratedEventData = {
  summary: Summary;
  transcript: Transcript,
}

@Injectable()
export class SmartSummaryService {
  public eventEmitter: EventEmitter;
  public usage: Usage = { secondsUsed: 0, secondsAllowed: 0 };
  public state: State = { stage: 'unstarted' };
  public consentGiven = false;

  private _transcriptionGenerated$ = new ReplaySubject<Transcript>(1);
  private _summaryGenerated$ = new ReplaySubject<Summary>(1);

  constructor(
    private meetingBroadcastService: BroadcastService,
    @Inject('meetingService') private meetingService,
    @Inject('notificationService') private notificationService,
    private modalService: ModalService,
    private smartSummaryBackendService: SmartSummaryBackendService,
    private activeAudioStreamService: ActiveAudioStreamService,
    private viewOrganizationService: ViewOrganizationService,
    private userService: UserService,
    private requestUserService: RequestUserService,
    private smartSummaryTrackingService: SmartSummaryTrackingService,
  ) {
    bind(this);
    this.eventEmitter = EventEmitter.setup(this, ['started']);
    this.initializeService();
  }

  private initializeService(): void {
    this.meetingBroadcastService.on(
      'cached-meeting-state',
      this.onCachedMeetingState,
      false,
    );
    this.meetingBroadcastService.on(
      'smart-summary-recording-started',
      this.onSomeoneElseStartedRecording,
      false,
    );
    this.meetingBroadcastService.on(
      'smart-summary-recording-stopped',
      this.onRecordingStopped,
      true,
    );
    this.meetingBroadcastService.on(
      'smart-summary-transcript-generated',
      this.onTranscriptGenerated,
      true,
    );
    this.meetingBroadcastService.on(
      'smart-summary-transcription-failed',
      this.onTranscriptionFailed,
      true,
    );
    this.meetingBroadcastService.on(
      'smart-summary-summary-generated',
      this.onSummaryGenerated,
      true,
    );
    this.meetingBroadcastService.on(
      'smart-summary-usage-updated',
      this.onSummaryUsageUpdated,
      true
    );
    this.meetingBroadcastService.on(
      'smart-summary-summary-failed',
      this.onSummaryFailed,
      true,
    );
    this.meetingBroadcastService.on(
      'smart-summary-cleared',
      () => this.clear(false),
      true,
    );

    this.userService.mySession.on('state', this.onMySessionState);

    const { subscription } = this.viewOrganizationService.organization;
    this.usage = {
      secondsUsed: subscription.smartSummarySecondsUsed,
      secondsAllowed: subscription.totalAllowedSmartSummarySeconds,
    };
    if (this.hasReachedUsageLimit) {
      this.setState({ stage: 'limit-reached' });
    }
  }

  private onMySessionState(): void {
    if(
      !this.userService.mySession.isJoined()
      && this.state.stage === 'recording'
      && this.state.iAmControllingHost
    ) {
      this.stopRecording();
      this.smartSummaryTrackingService.trackStoppedTranscriptionWhenLeavingMeetingRoom();
      this.notificationService.info(
        $localize `Your transcription has been stopped and is being generated.
        It might take a few minutes before the summary and transcription have been generated.
        If you quickly rejoin the meeting room, please wait for it to be visible`,
        { delay: 60000 }
      );
    }
  }

  public get transcriptionGenerated$(): Observable<Transcript> {
    return this._transcriptionGenerated$.asObservable();
  }

  public get summaryGenerated$(): Observable<Summary> {
    return this._summaryGenerated$.asObservable();
  }

  public get canUseSmartSummary(): boolean {
    return (
      this.userService.iAmHost
      && this.viewOrganizationService.organization.canUseSmartSummary
      && this.requestUserService.user.organizationId === this.meetingService.owner.organizationId
    );
  }

  public openNoticeModal(): void {
    if (this.consentGiven) {
      return;
    }
    const componentPortal = new ComponentPortal(SmartSummaryNoticeModalComponent, null);
    const dialog = this.modalService.openOnceAtATime<boolean>('smart-summary-consent', {
      componentPortal: componentPortal,
      modalClass: 'modal--sm',
      title: $localize`This meeting is being transcribed`,
      icon: {
        name: 'edit'
      },
      disableClose: true,
    });
    if (dialog != null) {
      dialog.closed.subscribe(consentGiven => {
        if (consentGiven) {
          this.consentGiven = true;
        }
      });
    }
  }

  public startRecording(): void {
    assertOrLog(this.state.stage === 'unstarted');
    this.meetingBroadcastService.send('smart-summary-recording-started', false, []);
    const recorder = new SmartSummaryRecorder(this.activeAudioStreamService);
    recorder.eventEmitter.on('error', this.onRecordingError);
    const startedAt = Date.now();
    this.setState({
      stage: 'recording',
      iAmControllingHost: true,
      recorder,
      recordingLongEnough: false,
      startedAt,
    });
    timer(60_000).subscribe(() => {
      if (
        this.state.stage === 'recording'
        && this.state.iAmControllingHost
        && this.state.startedAt === startedAt
      ) {
        this.state.recordingLongEnough = true;
      }
    });
  }

  public async stopRecording(): Promise<void> {
    assertOrThrow(
      this.state.stage === 'recording'
      && this.state.iAmControllingHost,
    );
    const audio = await this.state.recorder.stop();
    this.setState({
      stage: 'generating-transcription',
      iAmControllingHost: this.state.iAmControllingHost
    });
    try {
      await this.smartSummaryBackendService.sendAudio(audio, this.userService.mySession.id);
    } catch (error) {
      logger.error(error);
      this.setErrorState(ErrorType.transcription);
    }
  }

  public clear(byMe = true): void {
    if (this.hasReachedUsageLimit) {
      this.setState({ stage: 'limit-reached' });
    } else {
      this.setState({ stage: 'unstarted' });
    }
    if (byMe) {
      this.meetingBroadcastService.send('smart-summary-cleared', true, []);
    }
  }

  private onCachedMeetingState(
    _channel: unknown,
    _senderId: unknown,
    _timestamp: unknown,
    meetingState: CachedMeetingState,
  ): void {
    // The UNSTARTED and SUMMARY_GENERATED states are always set by the replaying of persistent
    // events
    switch (meetingState.smartSummaryState) {
      case CachedSmartSummaryState.RECORDING:
        this.state = { stage: 'recording', iAmControllingHost: false };
        this.eventEmitter.emit('started');
        break;
      case CachedSmartSummaryState.GENERATING_TRANSCRIPTION:
        this.state = { stage: 'generating-transcription', iAmControllingHost: false };
        break;
      case CachedSmartSummaryState.GENERATING_SUMMARY:
        this.state = { stage: 'generating-summary', iAmControllingHost: false };
        break;
      case CachedSmartSummaryState.ERRORED:
        this.setErrorState(ErrorType.summarization);
        break;
    }
  }

  private setErrorState(errorType: ErrorType, errorCode: string | null = null ): void {
    let errorMessage = DEFAULT_ERROR_MESSAGES[errorType];
    if(errorCode) {
      errorMessage = ERROR_CODES_MESSAGE[errorCode] || errorMessage;
    }
    this.setState({ stage: 'errored', errorMessage: errorMessage });
  }

  private get hasReachedUsageLimit(): boolean {
    return this.usage.secondsUsed >= this.usage.secondsAllowed;
  }

  private onSomeoneElseStartedRecording(): void {
    this.eventEmitter.emit('started');

    // There is a small possiblity for a race condition between two hosts to start recording at the
    // same time. This check will at least stop from both reaching a state that shows that the
    // other person is recording, while in fact no one is recording anymore. I guess the race will
    // be resolved by whomever clicks "stop" first.
    if (this.state.stage === 'recording' && this.state.iAmControllingHost) {
      return;
    }
    this.setState({ stage: 'recording', iAmControllingHost: false });
  }

  private onRecordingStopped(): void {
    this.setState({ stage: 'generating-transcription', iAmControllingHost: false });
  }

  private onRecordingError(error: unknown): void {
    logger.error(error);

    assertOrThrow(
      this.state.stage === 'recording'
      && this.state.iAmControllingHost,
    );
    this.state.recorder.stop();
    this.setErrorState(ErrorType.transcription);
  }

  private async onTranscriptGenerated(
    _channel: unknown,
    _senderId: unknown,
    _timestamp: unknown,
    _message: unknown
  ): Promise<void> {
    // The transcript will be sent in the 'smart-summary-summary-generated' event.
    // This is only to show feedback to the user and update the duration
    assertOrLog(this.state.stage === 'generating-transcription');

    this.setState({
      stage: 'generating-summary',
      iAmControllingHost: this.state.iAmControllingHost
    });
  }

  private async onSummaryGenerated(
    _channel: unknown,
    _senderId: unknown,
    _timestamp: unknown,
    summaryData: SummaryGeneratedEventData,
  ): Promise<void> {
    this._summaryGenerated$.next(summaryData.summary);
    this._transcriptionGenerated$.next(summaryData.transcript);

    // Don't send the event when replaying the event. Also only send the event once, for the
    // controlling host.
    if (this.state.stage === 'generating-summary' && this.state.iAmControllingHost) {
      this.smartSummaryTrackingService.trackSummaryGenerated(summaryData.transcript);
      this.setState({
        stage: 'summary-generated',
        iAmControllingHost: this.state.iAmControllingHost,
        summary: summaryData.summary
      });
    } else {
      // This event was either replayed, which means nobody is controlling host or
      // it was received by a non-controlling host.
      this.setState(
        { stage: 'summary-generated', iAmControllingHost: false, summary: summaryData.summary }
      );
    }
  }

  private onSummaryUsageUpdated(
    _channel: unknown,
    _senderId: unknown,
    _timestamp: unknown,
    newUsage: number,
  ): void {
    this.usage.secondsUsed = newUsage;
  }

  private onTranscriptionFailed(): void {
    assertOrLog(this.state.stage === 'generating-transcription');
    this.setErrorState(ErrorType.transcription);
  }
  private onSummaryFailed(
    _channel: unknown,
    _senderId: unknown,
    _timestamp: unknown,
    errorCode: string | null,
  ): void {
    assertOrLog(this.state.stage === 'generating-summary');
    this.setErrorState(ErrorType.summarization, errorCode);
  }

  public get isRecording(): boolean {
    return (
      this.state.stage === 'recording'
      && this.state.iAmControllingHost
    );
  }

  public requestMoreHours(): void {
    const recipient = encodeURIComponent('support@vectera.com');
    const subject = encodeURIComponent($localize`Request more Smart Summary hours`);
    /* eslint-disable */
    const body = encodeURIComponent($localize
`Hi

I would like to request more Smart Summary hours.
Can we get in touch?

Kind regards`);
    /* eslint-enable */
    window.open(`mailto:${recipient}?subject=${subject}&body=${body}`);
  }

  private setState(state: State): void {
    if (this.state.stage === 'limit-reached') {
      return;
    } else {
      this.state = state;
    }
  }

  public hasSummary(): boolean {
    return (
      this.state.stage === 'summary-generated'
      && this.state.summary.asHtml !== ''
      && this.state.summary.asPlainText !== ''
    );
  }
}
