<div class="form-group">
  <label class="d-flex align-items-center" for="redirectUrl">
    <span i18n>Redirect URL</span>
    <help-tooltip
      text="The URL your customer will be redirected to after booking. By default redirecting takes 5 seconds after submitting. You can change this by unchecking the option below."
      i18n-text
    >
    </help-tooltip>
  </label>
  <input
    id="redirectUrl"
    class="input"
    [disabled]="!hasPermissionToEdit"
    [(ngModel)]="redirectUrlInput"
    (ngModelChange)="hasPermissionToEdit && updateRedirectUrlModel()"
    [withSettingsErrors]="errors.redirectUrl"
  >
</div>

<form [formGroup]="formGroup">
  <div class="form-group">
    <div class="mb-4">
      <input
        id="redirectParamsCheckbox"
        type="checkbox"
        class="checkbox"
        formControlName="enableRedirectParameters"
        [withSettingsErrors]="errors.enableRedirectParameters"

        />
      <label class="d-flex align-items-center" for="redirectParamsCheckbox" class="checkbox__label">
        <span i18n>Enable redirect parameters</span>
        <help-tooltip
          text="When you set up a redirect url and this option is selected, the meeting information (guest name, time and more) will be appended to the url. This is useful if you want to use this information on your own webpage."
          i18n-text
        >
        </help-tooltip>
      </label>
    </div>

    <div>
      <input
        id="confirmationCheckbox"
        type="checkbox"
        class="checkbox"
        formControlName="confirmationScreenAfterBooking"
        [withSettingsErrors]="errors.confirmationScreenAfterBooking"
        />
      <label class="d-flex align-items-center" for="confirmationCheckbox" class="checkbox__label">
        <span>{{confirmationScreenTooltipText}}</span>
        <help-tooltip
          text="Whether a confirmation screen is shown before redirecting after a meeting of this type is booked. Only relevant if a redirect URL is set up."
          i18n-text
        >
        </help-tooltip>
      </label>
    </div>
  </div>
</form>
