import { OnInit, Component, OnChanges } from '@angular/core';
import {
  SettingsComponent
} from 'utils/settings/settings.component';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';
import { SiteService } from 'utils/site.service';
import { fixURL } from 'utils/util/url';


@Component({
  selector: 'appointment-type-redirecting[instance]',
  templateUrl: 'appointment-type-redirecting.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeRedirectingComponent
  }]
})
export class AppointmentTypeRedirectingComponent
  extends AppointmentTypeSettingsComponent
  implements OnInit, OnChanges {

  public redirectUrlInput = '';

  static override formFields = [
    'redirectUrl',
    'enableRedirectParameters',
    'confirmationScreenAfterBooking'
  ];

  constructor(
    private siteService: SiteService,
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.redirectUrlInput = this.instance.redirectUrl;
  }

  get confirmationScreenTooltipText() {
    if(this.siteService.site.isTeamleader) {
      return $localize `Show a Teamleader confirmation screen before redirecting`;
    } else {
      return $localize `Show a Vectera confirmation screen before redirecting`;
    }
  }

  updateRedirectUrlModel() {
    this.instance.redirectUrl = fixURL(this.redirectUrlInput);
  }
}
