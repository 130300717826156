import { Component } from '@angular/core';
import { NumberInputComponent } from '../number-input/number-input.component';


@Component({
  selector: 'money-input',
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.scss'],
})
export class MoneyInputComponent extends NumberInputComponent {}
