import { Inject, Injectable } from '@angular/core';
import { Memoize } from 'typescript-memoize';
import { AdminPulseConfig } from 'integrations/models/AdminPulseConfig';
import { logger } from 'utils/util';
import { ComboBoxOption } from 'utils/form-elements/dropdowns';
import { NotesIntegrationService } from 'integrations/notes-integration.service';
import { SmartSummaryTrackingService } from 'meeting/smart-summary/smart-summary-tracking.service';

export type AdminPulseRelation = {
  id: string,
  firstName: string,
  name: string,
  legalForm: string,
  isTakenInMeetingKey: string,
}

export type AdminPulseInteraction = {
  meetingId?: string,
  hostEmail?: string,
  notes?: string,
}

@Injectable()
export class AdminPulseService extends NotesIntegrationService {
  public priceList = null;

  constructor(
    @Inject('apiService') private apiService,
    @Inject('modelFactory') private modelFactory,

    private smartSummaryTrackingService: SmartSummaryTrackingService
  ) {
    super('adminPulse', 'AdminPulse', $localize `AdminPulse meeting record`);
  }

  public get isActive(): boolean {
    return this.relationConnected;
  }

  @Memoize()
  getConfig(): Promise<AdminPulseConfig> {
    return this.modelFactory.read({
      model: AdminPulseConfig,
      identifiers: {
        organizationId: this.requestUserService.user.organizationId
      }
    }).then(({ data: adminPulseConfig }) => {
      return adminPulseConfig;
    }).catch(error => {
      logger.error(error);
    });
  }

  @Memoize()
  async fetchAdminPulseRelations(): Promise<AdminPulseRelation[]> {
    if(!this.isConnected) {
      return [];
    }
    return (await this.apiService.get('integrations/adminpulse/relations')).data;
  }

  get relationConnected() {
    return this.isConnected && this.meetingService.adminpulseRelation;
  }

  getRelationComboBoxOptions(relations: AdminPulseRelation[]): ComboBoxOption[] {
    return relations.map( relation => {
      // eslint-disable-next-line max-len
      const helpText = $localize `This relation is already linked to meeting room ${relation.isTakenInMeetingKey}`;
      return {
        label: this.getRelationDisplayInfo(relation),
        value: relation.id,
        disabled: !!relation.isTakenInMeetingKey,
        disabledHelpText: helpText
      };
    });
  }

  async fetchRelationData(relationId): Promise<AdminPulseRelation> {
    return (await this.apiService.get(
      `integrations/adminpulse/relation/${relationId}`,
    )).data;
  }

  public getRelationDisplayInfo(relation: AdminPulseRelation): string {
    let res = relation.name;
    if(relation.firstName) {
      res += ' ' + relation.firstName;
    }
    if(relation.legalForm) {
      res += ' ' + relation.legalForm;
    }
    return res;
  }

  public async rawPush(data: AdminPulseInteraction): Promise<void> {
    const fullData: AdminPulseInteraction = {
      ...data,
      ...{
        meetingId: this.meetingService.id,
        hostEmail: this.userService.me.email,
      }
    };

    await this.apiService.post(
      'integrations/adminpulse/interaction',
      fullData
    );
  }
}
