import template from './dropdowns.html?raw';


class DropdownsController {
  static get $inject() {
    return [
      'dropdownService',
    ];
  }

  constructor(
    dropdownService
  ) {
    this.dropdownService = dropdownService;
  }
}


export default {
  controller: DropdownsController,
  controllerAs: 'dropdownsCtrl',
  template,
};
