import {
  Component,
  ElementRef,
  NgZone,
  ViewChild,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { SmartSummaryService, Summary } from '../../../../smart-summary/smart-summary.service';
import {
  SmartSummaryTrackingService
} from '../../../../smart-summary/smart-summary-tracking.service';
import { MatTooltip } from '@angular/material/tooltip';


@UntilDestroy()
@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnChanges {
  @ViewChild('summaryContainer') summaryContainer!: ElementRef;
  @ViewChild('tooltip') tooltip!: MatTooltip;
  @Input() isMaximized = false;

  private _isCollapsed = true;
  private _canCollapse = false;

  private _summary: Summary | null = null;

  constructor(
    private smartSummaryService: SmartSummaryService,
    private smartSummaryTrackingService: SmartSummaryTrackingService,
    private ngZone: NgZone
  ) {
    this.smartSummaryService.summaryGenerated$
      .pipe(untilDestroyed(this))
      .subscribe((summary: Summary) => {
        this._summary = summary;

        // Wait for Angular to finish at least 1 render.
        // Summary needs to be filled in and a render needs to happen
        // to give a height to the summaryContainer
        this.ngZone.onStable
          .pipe(untilDestroyed(this), first())
          .subscribe(() => {
            this.checkForOverflow();
          });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isMaximized && this.summaryContainer) {
      this.handleMaximizedChange(changes.isMaximized.currentValue);
    }
  }

  public handleMaximizedChange(isMaximized: boolean): void {
    if(isMaximized) {
      // Never collapse summary on maximized
      this._canCollapse = false;
      this._isCollapsed = false;
      return;
    }

    this._canCollapse = false;
    this._isCollapsed = true;

    this.ngZone.onStable
      .pipe(untilDestroyed(this), first())
      .subscribe(() => {
        this.checkForOverflow();
      });
  }

  checkForOverflow(): void {
    if (this.isOverflown(this.summaryContainer.nativeElement)) {
      this._canCollapse = true;
      this._isCollapsed = true;
    }
  }


  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  public get canCollapse(): boolean {
    return this._canCollapse;
  }

  public get summary(): Summary | null {
    return this._summary;
  }

  public toggleCollapsed(): void {
    this._isCollapsed = !this._isCollapsed;
  }

  public isOverflown(element: HTMLElement): boolean {
    return element.offsetHeight < element.scrollHeight;
  }

  public copyToClipboard(): void {
    if (this._summary) {
      this.smartSummaryTrackingService.trackSummaryCopied();
      navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([this._summary.asHtml], { type: 'text/html' }),
          'text/plain': new Blob([this._summary.asPlainText], { type: 'text/plain' }),
        })
      ]).then(() => this.showAndHideTooltipWithDelay());
    }
  }

  private showAndHideTooltipWithDelay(): void {
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 3000);
  }

  public sharedViaEmail(): void {
    this.smartSummaryTrackingService.trackSummarySharedViaEmail();
  }
}
