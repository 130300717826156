import { Component, Inject, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'core/models/User';
import { RequestUserService } from 'utils/requestUser.service';
import { Organization } from 'organization/models/Organization';
import { OrganizationBackendService } from '../../organization-backend.service';
import {
  OrganizationInvitationBackendService,
  OrganizationInvitation
} from '../../organization-invite-backend.service';
import { isValidEmail, logger } from 'utils/util';

type InviteEmail = {
  email: string;
  inputValidationErrorMessage: string;
}

@Component({
  selector: 'invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {

  public inviteEmails: InviteEmail[] = [
    {
      email: '',
      inputValidationErrorMessage: '',
    }
  ];
  public inviteMessage = '';
  public inviteMessageErrorMessage = '';
  public successMails: string[] = [];
  public failedMails: string[] = [];
  public showInviteStatusScreen = false;

  @Input() organization!: Organization;
  @Input() existingInvitationEmails: string[] = [];
  @Input() existingMemberEmails: string[] = [];
  @Input() inviter: User | null = null;

  @Output() invitationsAdded = new EventEmitter<OrganizationInvitation[]>();

  constructor(
    @Inject('notificationService') private notificationService,
    private requestUserService: RequestUserService,
    private organizationBackendService: OrganizationBackendService,
    private organizationInvitationBackendService: OrganizationInvitationBackendService,
  ) { }

  public ngOnInit(): void {
    if (this.inviter === null) {
      this.inviter = this.requestUserService.user;
    }
    this.inviteMessage = this.defaultInviteMessage;
  }

  public onInviteMessageChanged(): void {
    if (this.inviteMessage.trim() === '') {
      this.inviteMessageErrorMessage = $localize`Please enter an invite message.`;
    } else {
      this.inviteMessageErrorMessage = '';
    }
  }

  private emailIsEmpty(inviteEmail: InviteEmail): boolean {
    return inviteEmail.email === '';
  }

  public onEmailChange(inviteEmail: InviteEmail): void {
    if (!this.emailIsEmpty(this.inviteEmails[this.inviteEmails.length - 1])) {
      this.addEmptyEmail();
    }
    this.validateInput(inviteEmail);
  }

  private addEmptyEmail(): void {
    this.inviteEmails.push(
      {
        email: '',
        inputValidationErrorMessage: '',
      }
    );
  }

  private validateInput(inviteEmail: InviteEmail): void {
    let message = '';
    if (this.isExistingMember(inviteEmail.email)) {
      message = $localize`Someone with this email address is already a member of your team.`;
    } else if (this.isExistingInvite(inviteEmail.email)) {
      message = $localize`There already exists an invitation for this email address.`;
    } else if (this.isDuplicateInvite(inviteEmail.email)) {
      message = $localize`This email address is already added to the list.`;
    }
    inviteEmail.inputValidationErrorMessage = message;
  }



  public onBlur(inviteEmail: InviteEmail): void {
    if (!this.emailIsEmpty(inviteEmail) && !isValidEmail(inviteEmail.email)) {
      inviteEmail.inputValidationErrorMessage = $localize`Please enter a valid email address.`;
    }
  }

  private isExistingMember(email: string): boolean {
    return this.existingMemberEmails.includes(email);
  }

  private isExistingInvite(email: string): boolean {
    return this.existingInvitationEmails.includes(email);
  }

  private isDuplicateInvite(email: string): boolean {
    const isDuplicateInvite = this.inviteEmails.filter(
      i => i.email === email
    ).length > 1;
    return isDuplicateInvite && email !== '';
  }

  public removeEmail(index: number): void {
    this.inviteEmails.splice(index, 1);
  }

  public shouldShowDeleteOption(index: number) {
    return index !== this.inviteEmails.length - 1;
  }

  public get canSendInvites(): boolean {
    return !this.hasInputErrors && this.numEmails > 0 && this.inviteMessageErrorMessage === '';
  }

  public get numEmails(): number {
    return this.inviteEmails.filter(inviteEmail => !this.emailIsEmpty(inviteEmail)).length;
  }

  public get hasInputErrors(): boolean {
    return this.inviteEmails.some(inviteEmail => inviteEmail.inputValidationErrorMessage !== '');
  }

  public sendAllInvites(): void {
    if (!this.canSendInvites) {
      return;
    }

    this.sendInvites(this.inviteEmails.map(inviteEmail => inviteEmail.email));
  }

  private async sendInvites(emails: string[]): Promise<void> {
    const responses = await Promise.all(emails
      .filter(email => email !== '')
      .map(async (email) => {
        try {
          return await this.organizationInvitationBackendService.sendInvite(
            this.organization.id,
            this.inviteMessage,
            email,
          );
        } catch (error) {
          const inviteEmail = this.inviteEmails.find(inviteEmail => inviteEmail.email === email);
          if (inviteEmail) {
            this.failedMails.push(inviteEmail.email);
          }
          return null;
        }
      })
    );

    // Not using filter with response != null because the compiler doesn't infer that the resulting
    // array does not contain null
    const newInvites: OrganizationInvitation[] = [];
    for (const response of responses) {
      if (response !== null) {
        newInvites.push(response);
      }
    }
    this.invitationsAdded.emit(newInvites);
    this.successMails = newInvites.map(invite => invite.email);
    this.showInviteStatusScreen = true;
  }

  /****************
  * Permanent url *
  ****************/

  public get permanentInviteUrl(): string {
    return this.organization.permanentInviteUrl;
  }

  public async disablePermanentInviteUrl(): Promise<void> {
    try {
      const updatedOrganization = await this.organizationBackendService.disablePermanentInviteUrl(
        this.organization.id
      );
      this.organization = updatedOrganization;
    } catch (error) {
      logger.error(error);
      this.notificationService.error(
        $localize`Something went wrong while disabling the permanent invite link. Please try again later.`  // eslint-disable-line max-len
      );
    }
  }

  public async enablePermanentInviteUrl(): Promise<void> {
    try {
      const updatedOrganization = await this.organizationBackendService.enablePermanentInviteUrl(
        this.organization.id
      );
      this.organization = updatedOrganization;
    } catch (error) {
      logger.error(error);
      this.notificationService.error(
        $localize`Something went wrong while enabling the permanent invite link. Please try again later.`  // eslint-disable-line max-len
      );
    }
  }

  /*******
  * Misc *
  *******/

  public backToInputScreenWithFailedMails(): void {
    this.showInviteStatusScreen = false;
    this.inviteEmails = this.failedMails.map(
      email => (
        { email, inputValidationErrorMessage: '' }
      )
    );
    this.addEmptyEmail();
    this.failedMails = [];
    this.successMails = [];
  }

  public get defaultInviteMessage(): string {
    const teamName = this.organization.name;
    const firstName = this.inviter.firstName;

    return $localize`Hello!

I'd like to invite you to my team '${teamName}'.

Kind regards,
${firstName}`;
  }
}
