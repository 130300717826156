import { Component } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { ContactFormSettingsComponent } from '../../contact-form-settings.component';


@Component({
  selector: 'contact-form-builder[instance]',
  templateUrl: './contact-form-builder.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactFormBuilderComponent
  }]
})
export class ContactFormBuilderComponent extends ContactFormSettingsComponent {
  static override formFields = [
    'name',
  ];


  get shouldShowTeamleaderFieldMapping() {
    return this.requestUserService.user.organization.hasActiveTeamleaderIntegration;
  }

  get confirmNameChangeDialogText() {
    // eslint-disable-next-line max-len
    return $localize `<p>When you change the name of your contact form, this will impact both <b>the contact form URL</b> and <b>the embedded link of your contact form</b>.</p><p>Are you sure you want to change the name of the contact form?</p>`;
  }


  override get valid() {
    return (
      super.valid
      && (this.errors.questions || []).length === 0
    );
  }
}
