import templateDropdown from './recordingShare.dropdown.html?raw';
import Dropdown from 'utils/angularjs/dropdown/Dropdown';

const DROPDOWN_ID_RECORDING_SHARE = 'recordingShare';

RecordingShareDirective.$inject = [
  'dropdownService',
];

export default function RecordingShareDirective(
  dropdownService
) {
  return {
    link: function($scope, $elem, $attr) {
      let align = $attr.recordingShareAlign;
      if(align !== Dropdown.Align.START && align !== Dropdown.Align.END) {
        align = Dropdown.Align.END;
      }

      dropdownService.register(
        DROPDOWN_ID_RECORDING_SHARE,
        templateDropdown,
        {
          cssClasses: 'dropdown-deprecated--large-padding dropdown--no-margin',
          align: align
        }
      );

      $elem.on('click', (event) => {
        $scope.$evalAsync(() => {
          let recording = $scope.$eval($attr.recordingShareRecording);
          let scope = {
            recording: recording,
          };
          dropdownService.toggle(DROPDOWN_ID_RECORDING_SHARE, event, scope);
        });
      });
    }
  };
}
