import 'angular';

export let dependencies = [
  'booking',
  'contactForm',
  'contactWidget',
  'dashboard',
  'integrations',
  'filePicker',
  'meeting',
  'scheduling',
];


angular.module('vectera', dependencies);
