import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { bind } from 'utils/util';
import { SettingsIconType } from '../settings-icon/settings-icon.component';
import { SettingsComponent } from '../settings.component';
import { SettingsSegmentGroupComponent } from './settings-segment-group.component';


export enum SettingsSegmentState {
  SELECTED,
  PRISTINE,
  DIRTY,
}


@Component({
  selector: 'settings-segment[name]',
  templateUrl: './settings-segment.component.html',
  styleUrls: ['./settings-segment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsSegmentComponent implements AfterContentInit {
  public State = SettingsSegmentState;

  @Input() name!: string;
  @Input() description?: string;
  @Input() hideNext = false;
  /**
   * This single parameter currently changes several things about the appearance of the segment:
   * - The segments are glued together instead of having a connecting line between them.
   * - `customIcon` is used instead of showing the index/validation status of the step.
   * - The description is shown.
   * It's unclear whether all of this should be handled by a single parameter, or whether there
   * are different combinations possible. To be cleared up if we use segments again.
   **/
  @Input() optional = false;
  @Input('icon') customIcon?: SvgIcon;

  @ContentChild(SettingsComponent) settings!: SettingsComponent<any>;
  public index = 0;
  public state = SettingsSegmentState.PRISTINE;
  public stateChange = new EventEmitter<SettingsSegmentState>();

  @ViewChild('segmentHeader', { read: ElementRef }) segmentHeader!: ElementRef<HTMLElement>;

  constructor(
    @Host() public segmentGroup: SettingsSegmentGroupComponent,
  ) {
    bind(this);
  }


  ngAfterContentInit() {
    if(!this.settings) {
      throw new Error('This settings segment does not have a SettingsComponent child');
    }

    this.settings.submitForm.subscribe(this.onSettingsSubmit);
  }


  get settingsIconType(): SettingsIconType {
    if(this.state === SettingsSegmentState.PRISTINE) {
      return 'pristine';
    } else if(this.state === SettingsSegmentState.SELECTED) {
      return 'selected';
    } else if(!this.settings.valid) {
      return 'invalid';
    } else {
      return 'valid';
    }
  }


  setState(state: SettingsSegmentState) {
    if(state !== this.state) {
      this.state = state;
      this.stateChange.emit(this.state);
    }
  }

  select() {
    this.setState(SettingsSegmentState.SELECTED);
    if(this.segmentHeader) {
      setTimeout(() => {
        const bounding = this.segmentHeader.nativeElement.getBoundingClientRect();
        if(bounding.top < 0
          || bounding.bottom >= (window.innerHeight || document.documentElement.clientHeight)
        ) {
          (this.segmentHeader.nativeElement as HTMLElement).scrollIntoView();
        }
      });
    }
  }

  unselect() {
    if(this.state === SettingsSegmentState.SELECTED) {
      this.setState(SettingsSegmentState.DIRTY);
    }
  }

  toggle() {
    if(this.state === SettingsSegmentState.SELECTED) {
      this.unselect();
    } else {
      this.select();
    }
  }


  selectNextStep() {
    if(this.hideNext) {
      return;
    }

    this.segmentGroup.selectNextSegment(this);
  }


  onSettingsSubmit() {
    if(this.optional) {
      this.unselect();
    } else {
      this.selectNextStep();
    }
  }
}
