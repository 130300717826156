import template from './recordings.html?raw';
import { browser } from 'utils/util';


class RecordingsComponent {
  static get $inject() {
    return [
      'dropdownService',
      'notificationService',
      'recordingsService',
      'siteService',
    ];
  }

  constructor(
    dropdownService,
    notificationService,
    recordingsService,
    siteService
  ) {
    this.dropdownService = dropdownService;
    this.notificationService = notificationService;
    this.recordingsService = recordingsService;
    this.siteService = siteService;
  }

  get upgradeLink() {
    return gettextCatalog.getString(
      '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to create recordings.',
      {
        url: `href="${this.siteService.site.subscriptionLink}" target="_blank"`,
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

  showPreviewError() {
    this.notificationService.warning(browser.errorMessages.webm());
  }

  supportsPreview() {
    return browser.supportsWebm();
  }

  get previewTooltip() {
    return this.supportsPreview() ?
      gettextCatalog.getString('Preview') :
      browser.errorMessages.webm();
  }
}


export default {
  controller: RecordingsComponent,
  controllerAs: 'recordingsCtrl',
  template,
};
