import { array, browser, errors, EventEmitter, interval, object } from 'utils/util';
import MediaDevice from './MediaDevice';


export const DEVICE_KINDS = Object.freeze(['audioinput', 'videoinput', 'audiooutput']);
const DeviceKindToPreferredEvent = Object.freeze({
  audioinput: 'preferredAudioInput',
  videoinput: 'preferredVideoInput',
  audiooutput: 'preferredAudioOutput',
});


export default class MediaDeviceService {
  static get $inject() {
    return [];
  }

  constructor() {
    this._bind();
    EventEmitter.setup(this, [
      // Emitted when anything changes to the list of available devices
      'audioinput',
      'videoinput',
      'audiooutput',
      'all',
      // Emitted when the preferred device changes
      'preferredAudioInput',
      'preferredVideoInput',
      'preferredAudioOutput',
    ]);

    this.devices = {};
    this.permissions = {};
    DEVICE_KINDS.forEach(kind => {
      this.devices[kind] = {};
      this.permissions[kind] = false;
    });

    if(browser.supportsWebRTC()) {
      this._updateFromList([]);
      interval.setInterval(this.update.bind(this, true), 3000);
      this.update(true);
    }
  }

  _bind() {
    this.update = this.update.bind(this);
    this._updateFromList = this._updateFromList.bind(this);
  }


  update(ignoreErrors = false) {
    let promise = this._enumerateDevices().then(this._updateFromList);
    if(ignoreErrors) {
      promise = promise.catch(angular.noop);
    }
    return promise;
  }


  _enumerateDevices() {
    if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      let enumeratePromise = navigator.mediaDevices.enumerateDevices();
      let timeoutPromise = $timeout(angular.noop, 5000).then(() => {
        throw new errors.EnumerateDevicesTimeout('enumerateDevices() call timed out');
      });

      return $q.race([enumeratePromise, timeoutPromise]).then(deviceList => deviceList || []);
    } else {
      return Promise.resolve([]);
    }
  }


  _updateFromList(deviceList) {
    let newPermissions = {};
    DEVICE_KINDS.forEach(kind => newPermissions[kind] = false);

    let newDevices = {};
    DEVICE_KINDS.forEach(kind => newDevices[kind] = {});

    let oldPreferredDevices = {};
    DEVICE_KINDS.forEach(kind => oldPreferredDevices[kind] = this.getPreferredDevice(kind));

    deviceList.forEach(device => {
      if(device.label) {
        newPermissions[device.kind] = true;
      }

      // CubebAggregateDevice is a weird meta device on Mac OS FF. When you try to select
      // it, it sometimes leads to an overconstrained webrtc error. So we just skip it.
      if(!device.label.match('CubebAggregateDevice')) {
        newDevices[device.kind][device.deviceId] = device;
      }
    });

    // When no cam or mic permissions have been given yet, enumerateDevices() doesn't return any
    // camera devices on desktop Safari. Add a dummy one so that
    // localStreamService._getAvailableStreamTypes() doesn't remove the camera.
    if(
      browser.isSafari()
      && browser.isDesktop()
      && object.length(newDevices.videoinput) === 0
    ) {
      newDevices.videoinput[''] = {
        deviceId: '',
        groupId: '',
        kind: 'videoinput',
        label: '',
      };
    }


    let addedDeviceIds = this._getDevicesDiff(this.devices, newDevices);
    let removedDeviceIds = this._getDevicesDiff(newDevices, this.devices);
    let permissionChanged = {};
    let addedDevices = {};
    let removedDevices = {};

    DEVICE_KINDS.forEach(kind => {
      permissionChanged[kind] = (newPermissions[kind] !== this.permissions[kind]);
      removedDevices[kind] = removedDeviceIds[kind]
        .map(id => this.devices[kind][id])
        .filter(device => !!device);
      removedDevices[kind].forEach(device => {
        delete this.devices[kind][device.id];
        device.destroy();
      });


      Object.values(this.devices[kind]).forEach(device => {
        let rawDevice = newDevices[kind][device.id];
        if(rawDevice) {
          device.device = rawDevice;
        }
      });

      addedDevices[kind] = addedDeviceIds[kind]
        .map(id => new MediaDevice(newDevices[kind][id], this.devices[kind]));
      addedDevices[kind].forEach(device => this.devices[kind][device.id] = device);
    });

    this.permissions = newPermissions;
    this._notifyListeners(addedDevices, removedDevices, permissionChanged);

    DEVICE_KINDS.forEach(kind => {
      let preferredDevice = this.getPreferredDevice(kind);
      if(preferredDevice !== oldPreferredDevices[kind]) {
        this._emitPreferredDevice(kind, false);
      }
    });
  }


  _getDevicesDiff(devices1, devices2) {
    return DEVICE_KINDS.reduce((diff, kind) => {
      diff[kind] = array.diff(Object.keys(devices1[kind]), Object.keys(devices2[kind]));
      return diff;
    }, {});
  }


  _notifyListeners(addedDevices, removedDevices, permissionsChanged) {
    let allEmitArgs = [];

    DEVICE_KINDS.forEach(kind => {
      let added = addedDevices[kind];
      let removed = removedDevices[kind];

      if(added.length > 0 || removed.length > 0) {
        allEmitArgs.push([kind, added, removed, permissionsChanged[kind]]);
      }
    });

    if(allEmitArgs.length > 0) {
      allEmitArgs.push(['all', addedDevices, removedDevices, permissionsChanged]);

      $rootScope.$evalAsync(() => {
        allEmitArgs.forEach(emitArgs => this.emit(...emitArgs));
      });
    }
  }

  getAvailableKinds() {
    return DEVICE_KINDS.filter(kind => object.length(this.devices[kind]) > 0);
  }



  /***************************
   * Manage preferred devices *
   ****************************/

  /**
   * Set the device that the user prefers to use.
   *
   * @param {MediaDevice} device
   */
  setPreferredDevice(device) {
    // Setting the preferred device if no stream of that type is already active must start a new
    // stream. So even if the provided device was already the preferred device, we still need to
    // emit an event.
    device.setPreferred();
    this._emitPreferredDevice(device.kind, true);
  }


  /**
   * Given a device kind, get the device that the user has most recently indicated that they
   * prefers to use. If the user has never given a preference, nothing is returned.
   *
   * @param {String} kind
   * @returns {MediaDevice|undefined}
   */
  getPreferredDevice(kind) {
    return Object.values(this.devices[kind])
      .filter(device => device.preference > 0)
      .sort((device1, device2) => device2.preference - device1.preference)[0];
  }

  get preferredAudioInput() {
    return this.getPreferredDevice('audioinput');
  }
  get preferredVideoInput() {
    return this.getPreferredDevice('videoinput');
  }
  get preferredAudioOutput() {
    return this.getPreferredDevice('audiooutput');
  }


  /**
   * Get a different input for a specific kind, cycling though all available devices.
   *
   * @param {String} kind
   * @returns {MediaDevice|undefined}
   */
  getNextAvailableInput(kind) {
    let deviceList = Object.values(this.devices[kind])
      .sort((device1, device2) => device2.id - device1.id);

    if(deviceList.length < 2) {
      // only 0 or 1 devices: no next device available
      return;
    }

    let currentIdx = deviceList.findIndex(device => device.active);
    return deviceList[(++currentIdx) % deviceList.length];
  }


  _emitPreferredDevice(kind, isExplicitAction) {
    let event = DeviceKindToPreferredEvent[kind];
    let device = this.getPreferredDevice(kind);
    this.emit(event, device, isExplicitAction);
  }
}
