export type HexColor = string;
export type HSLColor = { h: number; s: number; l: number; };
export type RGBColor = { r: number; g: number; b: number; };

/*****************************************************************************
 * Adapted from https://github.com/bgrins/TinyColor/blob/master/tinycolor.js *
 *****************************************************************************/

export function hslToRgb(hsl) {
  const q = hsl.l < 0.5 ? hsl.l * (1 + hsl.s) : hsl.l + hsl.s - hsl.l * hsl.s;
  const p = 2 * hsl.l - q;
  return {
    r: 255 * hueToRgb(p, q, hsl.h / 360 + 1 / 3),
    g: 255 * hueToRgb(p, q, hsl.h / 360),
    b: 255 * hueToRgb(p, q, hsl.h / 360 - 1 / 3),
  };
}

function hueToRgb(p, q, t) {
  if(t < 0) {
    t += 1;
  }
  if(t > 1) {
    t -= 1;
  }
  if(t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if(t < 1 / 2) {
    return q;
  }
  if(t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
}


export function rgbToHsl(argRgb): HSLColor {
  const rgb = {
    r: argRgb.r / 255,
    g: argRgb.g / 255,
    b: argRgb.b / 255,
  };
  const max = Math.max(rgb.r, rgb.g, rgb.b);
  const min = Math.min(rgb.r, rgb.g, rgb.b);
  const l = (max + min) / 2;
  let h = 0;
  let s = 0;

  if(max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max) {
      case rgb.r: h = (rgb.g - rgb.b) / d + (rgb.g < rgb.b ? 6 : 0); break;
      case rgb.g: h = (rgb.b - rgb.r) / d + 2; break;
      case rgb.b: h = (rgb.r - rgb.g) / d + 4; break;
    }

    h *= 60;
  }

  return { h: h, s: s, l: l };
}


export function hexToRgb(hex): RGBColor {
  if(/^#([a-f0-9]{3}){1,2}$/i.test(hex)) {
    let c = hex.substring(1).split('');
    if(c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return {
      r: (c >> 16 ) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
    };
  }
  throw new Error('Bad Hex');
}

export function hexToHsl(hex): HSLColor {
  return rgbToHsl(hexToRgb(hex));
}


export function rgbToHex(rgb): HexColor {
  return '#' + (1 << 24 | rgb.r << 16 | rgb.g << 8 | rgb.b).toString(16).slice(1) as HexColor;
}

export function hslToHex(hsl): HexColor {
  return rgbToHex(hslToRgb(hsl)) as HexColor;
}


export function propertyToHex(cssColor, doc = document): HexColor {
  const rgb = getComputedStyle(doc.documentElement).getPropertyValue(cssColor);
  const rgb_arr = rgb.trim().split(',');
  const hex =  rgbToHex(
    {
      r: rgb_arr[0],
      g: rgb_arr[1],
      b: rgb_arr[2],
    }
  );
  return hex as HexColor;
}


export function isValidHexColor(color): boolean {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return reg.test(color);
}


export function fullHexValue(color): HexColor {
  if(color && color.length === 4) {
    return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
  } else {
    return color;
  }
}
