import BaseCloudRecording from '../../shared/recordings/BaseCloudRecording';

export default class MeetingCloudRecording extends BaseCloudRecording {
  constructor(
    userService,
    streamService,
    ...args
  ) {
    super(...args);

    this.userService = userService;
    this.streamService = streamService;
  }

  get downloadMessage() {
    let downloadMessage = super.downloadMessage;

    /* eslint-disable max-len */
    if(this._shouldShowDownloadWarning) {
      downloadMessage += `
        <p class="field field--warning" translate>
          You are still in a call. If your audio/video quality is suffering, we recommend downloading the recording after the meeting is over.
        </p>
      `;
    }
    /* eslint-enable max-len */

    return downloadMessage;
  }


  get _shouldShowDownloadWarning() {
    return this.streamService.streams.find(stream => !stream.isLocal);
  }


  get canDelete() {
    let session = this.userService.mySession;
    return session.accessLevel.isHost || session.user.id === this.createdBy.id;
  }
}
