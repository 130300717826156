import { diff, patch } from 'utils/util/diff';
import { parseText } from './parser';


const SYNC_PROPERTIES_LINE = {
  left: true,
  top: true,
  width: true,
  angle: true,

  strokeColor: true,
  strokeWidth: true,
};

const SYNC_PROPERTIES_PATH = {
  left: true,
  top: true,
  angle: true,

  path: true,

  strokeColor: true,
  strokeWidth: true,
};


const SYNC_PROPERTIES = {
  rectangle: {
    left: true,
    top: true,
    width: true,
    height: true,
    angle: true,

    strokeColor: true,
    strokeWidth: true,
  },

  ellipse: {
    left: true,
    top: true,
    rx: true,
    ry: true,
    angle: true,

    strokeColor: true,
    strokeWidth: true,
  },


  triangle: {
    left: true,
    top: true,
    width: true,
    height: true,
    angle: true,

    flipY: true,

    strokeColor: true,
    strokeWidth: true,
  },

  line: SYNC_PROPERTIES_LINE,
  arrow: SYNC_PROPERTIES_LINE,

  pencil: SYNC_PROPERTIES_PATH,
  marker: SYNC_PROPERTIES_PATH,

  text: {
    left: true,
    top: true,
    requestWidth: true,
    requestHeight: true,
    angle: true,

    // fTextD: true,
    fText: true,

    // As long as isInitTextBox is true, this shape is not used to update the whiteboard title.
    // When the shape is edited for the first time, isInitTextBox is set to false.
    isInitTextBox: true,
  },

  formula: {
    left: true,
    top: true,

    latex: true,

    color: true,
    fontSize: true,
  },

  image: {
    left: true,
    top: true,
    width: true,
    height: true,
    angle: true,

    scaleX: true,
    scaleY: true,
    flipX: true,
    flipY: true,

    url: true,
    fileId: true,
    logoName: true,
  },
};

const DEFAULT_PROPERTIES = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  angle: 0,
  scaleX: 1,
  scaleY: 1,
};



export default class Shape {
  static get defaultText() {
    return gettextCatalog.getString('Type something here...');
  }
  static get defaultTextWidth() {
    return 300;
  }
  static get defaultTextHeight() {
    return 10;
  }
  static get SYNC_PROPERTIES() {
    return SYNC_PROPERTIES;
  }


  constructor(id, type, properties, pageId) {
    this.id = id;
    this.type = type;
    this.pageId = pageId;
    this.indexOnPage = -1;

    this.addSynced = false;
    this.removeSynced = false;
    this.properties = {};
    this.syncedProperties = {};

    this.update(DEFAULT_PROPERTIES);
    this.setPropertiesSynced();
    this.update(properties);
  }


  update(properties) {
    let update = {};

    for(let key in properties) {
      let updateKey, updateValue;

      if(key === 'fTextD') {
        if(properties.fTextD != null && !('fText' in properties) && 'fText' in this.properties) {
          updateKey = 'fText';
          updateValue =  patch(this.properties.fText || '', properties.fTextD);
        }
      } else {
        updateKey = key;
        updateValue = properties[key];
      }

      if(updateKey && updateValue !== this.properties[updateKey]) {
        this.properties[updateKey] = updateValue;
        update[updateKey] = updateValue;
      }
    }

    return update;
  }


  setPropertiesSynced() {
    for(let key in SYNC_PROPERTIES[this.type]) {
      this.syncedProperties[key] = this.properties[key];
    }
    if(this.type === 'text') {
      this.syncedProperties.fText = this.properties.fText;
    }
  }


  getSyncUpdate() {
    let update = {};

    for(let key in SYNC_PROPERTIES[this.type]) {
      if(key === 'fTextD') {
        let syncedFText = this.syncedProperties.fText || '';
        let fText = this.properties.fText || '';
        if(fText !== syncedFText) {
          update.fTextD = diff(syncedFText, fText);
        }

      } else {
        let value = this.properties[key];
        let syncedValue = this.syncedProperties[key];
        if(value !== syncedValue) {
          update[key] = value;
        }
      }
    }

    return update;
  }


  getBounds() {
    let angle  = this.properties.angle * (Math.PI / 180);
    let left   = this.properties.left;
    let top    = this.properties.top;
    let width  = this.properties.width * this.properties.scaleX;
    let height = this.properties.height * this.properties.scaleY;

    let widthX  =  width  * Math.cos(angle);
    let widthY  =  width  * Math.sin(angle);
    let heightX = -height * Math.sin(angle);
    let heightY =  height * Math.cos(angle);

    return {
      left:   left + Math.min(0, heightX) + Math.min(0, widthX),
      top:    top  + Math.min(0, heightY) + Math.min(0, widthY),
      right:  left + Math.max(0, heightX) + Math.max(0, widthX),
      bottom: top  + Math.max(0, heightY) + Math.max(0, widthY),
    };
  }


  intersectsWithEraser(pointer, maxDistance) {
    if(this.type === 'text' || this.type === 'formula' || this.type === 'image') {
      return false;
    }

    let angle  = this.properties.angle * (Math.PI / 180);
    let left   = this.properties.left;
    let top    = this.properties.top;
    let width  = this.properties.width * this.properties.scaleX;
    let height = this.properties.height * this.properties.scaleY;

    // rotate based on http://math.stackexchange.com/a/814981

    // rotate pointer back
    let pointerLeft = (
      left
      + Math.cos(-angle) * (pointer.x - left)
      - Math.sin(-angle) * (pointer.y - top)
    );
    let pointerTop = (
      top
      + Math.sin(-angle) * (pointer.x - left)
      + Math.cos(-angle) * (pointer.y - top)
    );

    let dx = Math.max(0, left - pointerLeft, pointerLeft - (left + width));
    let dy = Math.max(0, top  - pointerTop,  pointerTop  - (top + height));

    return (dx * dx + dy * dy < maxDistance * maxDistance);
  }


  isEmpty() {
    switch(this.type) {
      case 'text':
        if(
          this.properties.isPristine
          && parseText(this.properties.fText)[0] === Shape.defaultText
        ) {
          return true;
        }
        let text = this.properties.fText != null ? this.properties.fText : this.properties.text;
        return text.trim() === '';

      case 'formula':
        return this.properties.latex.trim() === '';

      default:
        return false;
    }
  }
}
