import * as array from './array';
import errors from './errors';
import format from './format';
import { noop } from './functional';
import { bind } from './misc';
import * as raf from './raf';


const EVENT_OPTIONS = Object.freeze({
  passive: false,
});

/**
 * @param {boolean} diffSinceStart - Whether to get the move diff since the start of dragging
 *  (true) or since the last drag event (false)
 * @param {function} onStart - Callback for when the user starts dragging
 * @param {function} onDrag - Callback for when the user drags
 * @param {function} onStop - Callback for when the user stops dragging
 * @param {boolean|array<string>|string} preventDefault - Whether to call preventDefault on the
 *  mouse and touch events that are handled by the DragListener
 *  * true: preventDefault is called on all the handled events.
 *  * false: preventDefault is not called on any event.
 *  * array of strings (e.g. ['mousedown', 'touchstart']): the list of events on which
 *    preventDefault should be called.
 *  * comma-separated list of strings (e.g. 'mousedown,touchstart'): same as an array of strings.
 * @param {boolean|array<string>|string} stopPropagation - Similar to the preventDefault option,
 *  but for calling stopPropagation on the handled events.
 */
const DEFAULT_OPTIONS = Object.freeze({
  diffSinceStart: true,
  onStart: noop,
  onDrag: noop,
  onStop: noop,
  enabled: true,
  preventDefault: true,
  stopPropagation: false,
});
const HANDLED_EVENTS = [
  'mousedown',
  'mousemove',
  'mouseup',
  'touchstart',
  'touchmove',
  'touchend',
];


/**
 * Listen on an element for mouse or touch dragging.
 * @param {Object} elem - The element on which to listen.
 * @param {[Object]} options - See DEFAULT_OPTIONS.
 */
export default class DragListener {
  constructor(elem, options) {
    bind(this);

    let unknownOptions = Object.keys(options).filter(key => !DEFAULT_OPTIONS.hasOwnProperty(key));
    if(unknownOptions.length > 0) {
      throw new errors.InvalidArgumentError(format('Unknown options:', unknownOptions));
    }

    this.elem = elem;
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    this.options.preventDefault = this._parseEventList(this.options.preventDefault);
    this.options.stopPropagation = this._parseEventList(this.options.stopPropagation);

    this.dragging = false;
    this.lastEvent = null;
    this.startPointers = null;

    if(this.options.enabled) {
      this.options.enabled = false;
      this.enable();
    }
  }


  _parseEventList(option) {
    if(option === true) {
      return HANDLED_EVENTS;
    } else if(option === false) {
      return [];
    } else if(typeof option === 'string') {
      return option.split(',');
    }
  }


  enable() {
    if(this.options.enabled) {
      return;
    }
    this.options.enabled = true;

    this.elem.addEventListener('mousedown', this._onMouseDown);
    this.elem.addEventListener('touchstart', this._onTouchStart);
  }

  disable() {
    if(!this.options.enabled) {
      return;
    }
    this.options.enabled = false;

    this.elem.removeEventListener('mousedown', this._onMouseDown);
    this.elem.removeEventListener('touchstart', this._onTouchStart);

    if(this.dragging) {
      this.stopDrag();
    }
  }

  toggle(enabled) {
    enabled ? this.enable() : this.disable();
  }



  _onMouseDown(event) {
    if(event.which === 1) {
      this._onEvent(event);
      this.startDrag(event);
      document.addEventListener('mousemove', this._onMouseMove, EVENT_OPTIONS);
      document.addEventListener('mouseup', this._onMouseUp);
    }
  }

  _onTouchStart(event) {
    if(event.touches && event.touches.length > 0) {
      this._onEvent(event);
      this.startDrag(event);
      document.addEventListener('touchmove', this._onTouchMove, EVENT_OPTIONS);
      document.addEventListener('touchend', this._onTouchEnd);
    }
  }


  _onMouseMove(event) {
    this._onEvent(event);
    this.lastEvent = event;
  }

  _onTouchMove(event) {
    this._onEvent(event);
    if(event.touches && event.touches.length > 0) {
      this.lastEvent = event;
    }
  }


  _onMouseUp(event) {
    this._onEvent(event);
    this.stopDrag();

    document.removeEventListener('mousemove', this._onMouseMove);
    document.removeEventListener('mouseup', this._onMouseUp);
  }

  _onTouchEnd(event) {
    this._onEvent(event);
    this.stopDrag();

    document.removeEventListener('touchmove', this._onTouchMove);
    document.removeEventListener('touchend', this._onTouchEnd);
  }


  _onEvent(event) {
    if(array.has(this.options.preventDefault, event.type)) {
      event.preventDefault();
    }
    if(array.has(this.options.stopPropagation, event.type)) {
      event.stopPropagation();
    }
  }



  startDrag(event) {
    this.dragging = true;
    this.lastEvent = null;
    this.startPointers = this.getPointers(event);
    this.options.onStart(this.startPointers.slice());

    // Kickstart the requestAnimationFrame loop
    this.drag();
  }


  drag() {
    if(this.lastEvent) {
      let pointers = this.getPointers(this.lastEvent);
      let numPointers = Math.min(pointers.length, this.startPointers.length);
      let pointersDiff = new Array(numPointers);
      for(let i = 0; i < numPointers; i++) {
        pointersDiff[i] = {
          x: pointers[i].x - this.startPointers[i].x,
          y: pointers[i].y - this.startPointers[i].y
        };
      }

      this.options.onDrag(pointersDiff.slice());

      this.lastEvent = null;
      if(!this.options.diffSinceStart) {
        this.startPointers = pointers;
      }
    }

    if(this.dragging) {
      raf.requestAnimationFrame(this.drag);
    }
  }


  stopDrag() {
    if(!this.dragging) {
      return;
    }

    this.dragging = false;

    // Drag one last time, so we use the final mouse position
    this.drag();

    this.options.onStop();
  }

  /**
   * A pointer location defined by the x and y pixel distance to the top-left
   * of the window
   *
   * @typedef {Object} Pointer
   * @property {number} x - The X Coordinate
   * @property {number} y - The Y Coordinate
   */

  /**
   * Extract pointer location information from a click event
   *
   * @param {Event} event
   * @returns {[Pointer]}
   */
  getPointers(event) {
    let rawPointers = event.touches || [event];

    let pointers = new Array(rawPointers.length);
    for(let i = 0; i < rawPointers.length; i++) {
      pointers[i] = {
        x: rawPointers[i].clientX,
        y: rawPointers[i].clientY,
      };
    }

    return pointers;
  }
}
