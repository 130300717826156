import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UtilsModule } from 'utils/utils.module';

import { NotesSyncButtonComponent } from './notes/integration-sync/notes-sync-button.component';
import { NotesHeaderComponent } from './notes-header/notes-header.component';
import { NotesModalComponent } from './notes/notes-modal/notes-modal.component';
import { NotesModalButtonComponent } from './notes/notes-modal-button.component';
import { PrivateNotesComponent } from './notes/private-notes/private-notes.component';
import { PrivateNotesService } from './notes/private-notes/private-notes.service';
import { ShareMenuComponent } from './notes/share-menu/share-menu.component';
import {
  SmartSummaryComponent
} from './notes/smart-summary/smart-summary.component';
import { SummaryComponent } from './notes/summary/summary.component';
import { TranscriptionComponent } from './notes/transcription/transcription.component';
import {
  NotesTrialBannerComponent
} from './notes/notes-trial-banner/notes-trial-banner.component';
import { SmartSummarySyncService } from './notes/integration-sync/smart-summary-sync.service';
import { NotesSyncService } from './notes/integration-sync/notes-sync.service';
import { PrivateNotesTrackingService } from './notes/private-notes/private-notes-tracking.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    OverlayModule,
    MatTooltipModule,
  ],
  providers: [
    PrivateNotesService,
    PrivateNotesTrackingService,
    SmartSummarySyncService,
    NotesSyncService,
  ],
  declarations: [
    NotesSyncButtonComponent,
    NotesHeaderComponent,
    NotesModalButtonComponent,
    NotesModalComponent,
    NotesTrialBannerComponent,
    PrivateNotesComponent,
    ShareMenuComponent,
    SmartSummaryComponent,
    SummaryComponent,
    TranscriptionComponent,
  ],
  exports: [
    NotesModalButtonComponent,
  ]
})
export class MeetingFooterModule { }
