import { errors, format, EventEmitter, array } from 'utils/util';
import Modal from './Modal';


export default class ModalService {
  static get $inject() {
    return [
      '$rootElement',
      '$compile',
      '$location',
    ];
  }

  constructor(
    $rootElement,
    $compile,
    $location
  ) {
    this._bind();
    EventEmitter.setup(this, ['show']);

    this.$rootElement = $rootElement;
    this.$compile = $compile;
    this.$location = $location;

    this.modals = {};

    // A list of modals that have been requested to be shown, sorted by priority (high -> low) and
    // request order (first -> last). The first element is the modal that should currently be
    // shown.
    this.shownModals = [];

    // Inject the element after the angular.js bootstrap has been completed, otherwise the element
    // may be compiled twice: once by us and once during bootstrapping.
    $timeout(this._injectElem);
  }

  _bind() {
    this._injectElem = this._injectElem.bind(this);
  }


  _injectElem() {
    let $elem = angular.element('<modals></modals>');
    let $elemCompiled = this.$compile($elem)($rootScope);
    this.$rootElement.append($elemCompiled);
  }


  register(id, title, iconUrl, template, options) {
    if(!(id in this.modals)) {
      this.modals[id] = new Modal(id, title, iconUrl, template, options);
    }

    let modal = this.modals[id];
    if(modal.title !== title
        || modal.iconUrl !== iconUrl
        || modal.template !== template) {
      throw new errors.IllegalStateError(format(
        'Modal "%s" has already been registered with different properties', id));
    }

    if(modal.id === this.$location.search().modal) {
      this.show(modal.id);
    }

    return modal;
  }


  get(id) {
    let modal = this.modals[id];
    if(!modal) {
      throw new errors.InvalidArgumentError(format('Unknown modal id:', id));
    }

    return modal;
  }

  getShown() {
    return this.shownModals[0];
  }


  show(id, scope) {
    let modal = this.get(id);

    if(modal.isShown) {
      array.remove(this.shownModals, modal);
    }

    modal.show(scope);

    let i = 0;
    while(i < this.shownModals.length && this.shownModals[i].priority > modal.priority) {
      i++;
    }
    this.shownModals.splice(i, 0, modal);

    this.emit('show', this.shownModals[0]);
  }


  hideAllExcept(exludedModalsIds) {
    if(exludedModalsIds == null) {
      exludedModalsIds = [];
    }
    let modalsToHide = Object.values(this.modals).filter(
      modal => (modal.isShown && !exludedModalsIds.includes(modal.id))
    );
    modalsToHide.forEach(modal => this.hide(modal.id));
  }
  hideAll() {
    this.hideAllExcept();
  }


  hide(id) {
    let modal = this.get(id);

    if(modal.isShown) {
      array.remove(this.shownModals, modal);
      modal.hide();
      this.emit('show', this.shownModals[0]);
    }
  }


  toggle(id, show, scope) {
    if(show == null) {
      show = !this.get(id).isShown;
    }

    if(show) {
      this.show(id, scope);
    } else {
      this.hide(id);
    }
  }


  // For debugging purposes: list all registered modals sorted by priority
  print() {
    let fieldNames = ['Priority', 'Key', 'Dismissable'];
    let fieldLengths = [
      fieldNames[0].length,
      Math.max(fieldNames[1].length, ...Object.values(this.modals).map(modal => modal.id.length)),
      fieldNames[2].length,
    ];

    this.printLine(fieldNames, fieldLengths, 'font-weight: bold;');

    Object.values(this.modals)
      .sort((m1, m2) => m2.priority - m1.priority)
      .forEach(modal => this.printLine(
        [modal.priority, modal.id, modal.dismissable ? 'x' : ''], fieldLengths));
  }

  printLine(values, lengths, style) {
    let parts = [];
    for(let i = 0; i < values.length; i++) {
      let value = format(values[i]);
      parts.push(value + ' '.repeat(lengths[i] - value.length));
    }
    console.debug('%c' + parts.join(' | '), style);  // eslint-disable-line no-console
  }
}
