import { EventEmitter } from 'utils/util';


export const SignalStrength = Object.freeze({
  BAD: 0,
  MEDIUM: 2,
  GOOD: 3,
  NO_STATS: 4,
});

const DATA_TTL = 5000;



export default class SignalStrengthService {
  static get $inject() {
    return [];
  }

  constructor() {
    EventEmitter.setup(this, ['audio', 'video']);

    this.recentSignalStrengths = {
      audio: [],
      video: []
    };
    this.signalStrength = {
      audio: SignalStrength.GOOD,
      video: SignalStrength.GOOD,
    };
  }


  add(kind, signalStrength) {
    this.recentSignalStrengths[kind].push(signalStrength);
    $timeout(this._dequeue.bind(this, kind), DATA_TTL);

    if(signalStrength < this.signalStrength[kind]) {
      this._update(kind);
    }
  }

  _dequeue(kind) {
    this.recentSignalStrengths[kind].splice(0, 1);
    this._update(kind);
  }


  _update(kind) {
    let signalStrength = Math.min(
      SignalStrength.GOOD,
      ...this.recentSignalStrengths[kind]
    );

    if(signalStrength !== this.signalStrength[kind]) {
      this.signalStrength[kind] = signalStrength;
      this.emit(kind, signalStrength);
    }
  }
}
