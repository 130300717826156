import template from './permissionArrow.html?raw';


class PermissionArrowController {
  static get $inject() {
    return [
      'permissionArrowService',
    ];
  }

  constructor(
    permissionArrowService
  ) {
    this.permissionArrowService = permissionArrowService;
    this.classes = [
      'permission-arrow--x-' + permissionArrowService.position.x,
      'permission-arrow--y-' + permissionArrowService.position.y,
    ];
  }
}


export default {
  controller: PermissionArrowController,
  controllerAs: 'permissionArrowCtrl',
  template,
};
