import { format } from '../util';


InputAutoSizeDirective.$inject = [
  '$compile',
];


export default function InputAutoSizeDirective($compile) {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {

      let $elemWrapper = angular.element('<div class="input-auto-size__wrapper"></div>');

      let $elemSizer = angular.element(format(
        '<div class="input input-auto-size__sizer">{{ %s || "a" }}</div>', $attr.ngModel));
      $elemWrapper.append($elemSizer);

      $elem.after($elemWrapper);
      $elem.removeAttr('input-auto-size');
      $elem.addClass('input-auto-size__input');
      $elemWrapper.append($elem);

      $compile($elemWrapper)($scope);
    },
  };
}
