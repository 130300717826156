import template from './geoPicker.html?raw';

import { bind, logger, storage } from 'utils/util';
import GeoPickerResult from './GeoPickerResult';

const LOCAL_STORAGE_KEY_CLIENT_LOCATION = 'geoPickerClientLocation';


class GeoPickerController {
  static get $inject() {
    return [
      '$element',
      '$location',
      '$scope',
      'apiService',
      'modelFactory',
      'requestUserService',
    ];
  }

  constructor(
    $elem,
    $location,
    $scope,
    apiService,
    modelFactory,
    requestUserService
  ) {
    bind(this);

    this.$elem = $elem;
    this.$location = $location;
    this.$scope = $scope;
    this.apiService = apiService;
    this.modelFactory = modelFactory;
    this.requestUserService = requestUserService;

    this.query = '';
    this.querySubmitted = '';

    this.result = null;
    this.error = null;
    this.searchPromise = null;

    this.clientLocation = storage.getJSONItem(LOCAL_STORAGE_KEY_CLIENT_LOCATION, {});
    if(!this.clientLocation.lat || !this.clientLocation.lng) {
      this.clientLocation = null;
    }
    this._getClientLocation();

    this.$scope.$on('$locationChangeSuccess', this._onLocationChange);
  }


  get isLoading() {
    return !!this.searchPromise;
  }


  _updateLocation() {
    let search = this.$location.search();
    if(this.querySubmitted) {
      search.q = this.querySubmitted;
    }
    this.$location.search(search);
  }

  _onLocationChange() {
    let search = this.$location.search();
    this.querySubmitted = this.query = search.q || '';
    if(this.querySubmitted) {
      this.search();
    }
  }


  focusInput() {
    this.$elem.find('input').focus();
  }


  submitQuery() {
    if(!this.query) {
      return;
    }
    this.querySubmitted = this.query;
    this._updateLocation();
    this.search();
  }


  search() {
    this.cancelSearch();

    let organizationId = window.ANGULAR_SCOPE.viewOrganization.id;
    let path = `organizations/${organizationId}/nearestAppointmentTypes`;
    let data = {
      address: this.query,
      language: this.requestUserService.user.languageCode,
    };
    if(this.clientLocation) {
      data.near = {
        lat: this.clientLocation.lat,
        lng: this.clientLocation.lng,
      };
    }
    let promise = this.apiService.post(path, data);
    this.searchPromise = promise;

    promise
      .then(response => {
        if(promise !== this.searchPromise) {
          return;
        }
        this.result = this.modelFactory.createInstance(GeoPickerResult, { data: response.data });
        this.error = null;
      })
      .catch(error => {
        if(promise !== this.searchPromise) {
          return;
        }

        this.result = null;
        this.error = error.message || error;
      })
      .finally(() => {
        if(promise !== this.searchPromise) {
          return;
        }
        this.searchPromise = null;
      });
  }


  cancelSearch() {
    if(this.searchPromise) {
      this.apiService.cancel(this.searchPromise);
      this.searchPromise = null;
    }
  }


  _getClientLocation() {
    return $q((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    })
      .then(location => {
        this.clientLocation = {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        };
        // Store the client location in localStorage so that is immediately available when
        // reloading the page with a query set in the url.
        storage.setJSONItem(LOCAL_STORAGE_KEY_CLIENT_LOCATION, this.clientLocation);
      })
      .catch(error => {
        logger.info(error);
      });
  }
}


export default {
  controller: GeoPickerController,
  controllerAs: 'geoPickerCtrl',
  template,
};
