import Whiteboard from './Whiteboard';


export default class WhiteboardFactory {
  static get $inject() {
    return [
      'apiService',
      'meetingService',
      'notificationService',
      'tileFactory',
      'documentRendererFactory',
      'paperRendererFactory',
    ];
  }

  constructor(
    apiService,
    meetingService,
    notificationService,
    tileFactory,
    documentRendererFactory,
    paperRendererFactory
  ) {
    this.apiService = apiService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.tileFactory = tileFactory;
    this.documentRendererFactory = documentRendererFactory;
    this.paperRendererFactory = paperRendererFactory;
  }

  create(...args) {
    return new Whiteboard(
      this.apiService,
      this.meetingService,
      this.notificationService,
      this.tileFactory,
      this.documentRendererFactory,
      this.paperRendererFactory,
      ...args);
  }
}
