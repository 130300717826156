<div
  class="settings-icon"
  [class.settings-icon--selected]="settingsIconType === 'selected'"
  [class.settings-icon--valid]="settingsIconType === 'valid'"
  [class.settings-icon--invalid]="settingsIconType === 'invalid'"
>
  <svg-icon
    *ngIf="settingsIconType === 'valid'"
    class="icon icon--sm"
    [svgName]="'checkmark'"
    [svgSize]="14"
  ></svg-icon>
  <svg-icon
    *ngIf="settingsIconType === 'invalid'"
    class="icon icon--sm"
    [svgName]="'warning'"
    [svgSize]="14"
  ></svg-icon>
  <span *ngIf="settingsIconType === 'pristine' || settingsIconType === 'selected'">
    {{ (settingsIconIndex || 0) + 1 }}
  </span>
</div>
