import Model from 'utils/models';
import * as fields from 'utils/fields';
import GeoPickerAppointmentType from './GeoPickerAppointmentType';

export default class GeoPickerResult extends Model {
  static get fields() {
    return {
      address: new fields.StringField(),
      appointmentTypes: new fields.RelatedField({
        Model: GeoPickerAppointmentType,
        many: true,
      }),
    };
  }
}
