import { Pipe, PipeTransform } from '@angular/core';
import { dateTime } from 'utils/util';


@Pipe({ name: 'localeTime' })
export class LocaleTimePipe implements PipeTransform {
  transform(value: Date): string {
    return dateTime.getLocaleTime(value);
  }
}
