export default class PasteService {
  static get $inject() {
    return [
      'userService',
      'uploadContentService',
    ];
  }

  constructor(
    userService,
    uploadContentService
  ) {
    this._bind();

    this.userService = userService;
    this.uploadContentService = uploadContentService;

    $document.on('paste', this._onPaste);
  }

  _bind() {
    this._onPaste = this._onPaste.bind(this);
  }


  _onPaste($event) {
    if(this.userService.mySession.isSpectator) {
      return;
    }

    let items = $event.originalEvent.clipboardData.items;
    for(let i = 0; i < items.length; i++) {
      let item = items[i];

      if(item.kind === 'file') {
        let file = item.getAsFile();
        if(file) {
          $rootScope.$apply(() => this.uploadContentService.uploadContent([file]));
        }
      }
    }
  }
}
