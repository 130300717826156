import { format } from 'utils/util';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';


const INFO_TIMEOUT = 16000;

/* eslint-disable max-len */
export const CAN_ALWAYS_JOIN_MESSAGE_TEMPLATE = gettext('I\'d like to invite you to my online meeting room. You can join the meeting room anytime, even when I\'m not there.');

export const SHOULD_KNOCK_MESSAGE_TEMPLATE = gettext('I\'d like to invite you to my online meeting room.');

export const IS_HOST_MESSAGE_TEMPLATE = gettext('I\'d like to invite you to my online meeting room. You can join the meeting room as a host.');
/* eslint-enable max-len */


export const TEMPLATE_MAP = {
  [AccessLevel.SHOULD_KNOCK.id]: SHOULD_KNOCK_MESSAGE_TEMPLATE,
  [AccessLevel.CAN_ALWAYS_JOIN.id]: CAN_ALWAYS_JOIN_MESSAGE_TEMPLATE,
  [AccessLevel.IS_HOST.id]: IS_HOST_MESSAGE_TEMPLATE
};


export default class SharingService {
  static get $inject() {
    return [
      'meetingService',
      'apiService',
      'dropdownService',
      'userInfoService',
      'userService',
      'notificationService'
    ];
  }

  constructor(
    meetingService,
    apiService,
    dropdownService,
    userInfoService,
    userService,
    notificationService
  ) {
    this._bind();

    this.meetingService = meetingService;
    this.apiService = apiService;
    this.dropdownService = dropdownService;
    this.userInfoService = userInfoService;
    this.userService = userService;
    this.notificationService = notificationService;

    this.defaultUserAccessLevel = AccessLevel.CAN_ALWAYS_JOIN;
    this.possiblePublicAccessLevels = [
      AccessLevel.SHOULD_KNOCK,
      AccessLevel.CAN_ALWAYS_JOIN
    ];
    this.possiblePrivateAccessLevels = [
      AccessLevel.DISABLED,
      AccessLevel.CAN_ALWAYS_JOIN,
      AccessLevel.IS_HOST
    ];
    this.possibleTeamAccessLevels = [
      AccessLevel.SHOULD_KNOCK,
      AccessLevel.CAN_ALWAYS_JOIN,
      AccessLevel.IS_HOST
    ];
    this.possibleNewUserAccessLevels = [
      AccessLevel.SHOULD_KNOCK,
      AccessLevel.CAN_ALWAYS_JOIN,
      AccessLevel.IS_HOST,
    ];
    this.possibleUserAccessLevels = this.possibleNewUserAccessLevels.concat(AccessLevel.REMOVE);
    this.permissions = {};
    this.localInvites = new Set();
    this.infoMessage = null;
    this.errorMessage = null;
    this.publicLink = meetingService.url;

    // Used when editing meeting templates to check whether we should show 'Saving..' in the banner
    this.isSaving = false;

    this.meetingService.on('permissions', this._onMeetingPermissions);
    this.meetingService.on('publicAccessLevel', this._onMeetingPublicAccessLevel);
    this.meetingService.on('teamAccessLevel', this._onMeetingTeamAccessLevel);
    this._onMeetingPublicAccessLevel(this.meetingService.publicAccessLevel);
    this._onMeetingTeamAccessLevel(this.meetingService.teamAccessLevel);
  }

  _bind() {
    this._onMeetingPermissions = this._onMeetingPermissions.bind(this);
    this._onMeetingPublicAccessLevel = this._onMeetingPublicAccessLevel.bind(this);
    this._onMeetingTeamAccessLevel = this._onMeetingTeamAccessLevel.bind(this);
    this._setError = this._setError.bind(this);
    this._clearInfoMessage = this._clearInfoMessage.bind(this);
    this._endProcessing = this._endProcessing.bind(this);
  }

  _onMeetingPermissions(permissions) {
    this.permissions = {};
    permissions
      .filter(permission => !!permission.user)
      .forEach(permission => {
        let user = this.userInfoService.updateUserInfo(permission.user);
        this.permissions[permission.id] = Object.assign({}, permission, {
          user: user,
          accessLevel: this.getAccessLevelFromPermission(permission)
        });
      });

    this.privateAccessPermission = (
      permissions.find(
        permission => permission.user === null && permission.dateDeleted == null)
    );
    this.privateAccessLevel = (
      this.privateAccessPermission ?
        this.getAccessLevelFromPermission(this.privateAccessPermission) :
        AccessLevel.DISABLED
    );
  }

  _onMeetingPublicAccessLevel(publicAccessLevel) {
    this.publicAccessLevel = AccessLevel.getFromId(publicAccessLevel);
  }
  _onMeetingTeamAccessLevel(teamAccessLevel) {
    this.teamAccessLevel = AccessLevel.getFromId(teamAccessLevel);
  }


  setPublicAccessLevel(accessLevel) {
    this._startProcessing();
    let data = {
      publicAccessLevel: accessLevel.id
    };
    let url = format('meetings/%s/', this.meetingService.id);
    this.apiService.patch(url, data)
      .then((response) => {
        this.publicAccessLevel = AccessLevel.getFromId(response.data.publicAccessLevel);
        this.clearError();
      })
      .catch(this._setError)
      .finally(this._endProcessing);
  }

  setPrivateAccessLevel(accessLevel) {
    let url;
    let apiMethod;

    if(accessLevel === AccessLevel.DISABLED && this.privateAccessPermission) {
      this._startProcessing();
      this._removePermission(this.privateAccessPermission)
        .then(() => {
          this.privateAccessPermission = null;
          this.privateAccessLevel = AccessLevel.DISABLED;
          // eslint-disable-next-line max-len
          this._setInfoMessage(gettextCatalog.getString('You have disabled private link access for this meeting room. People with the old link will no longer have access. If you re-enable private link access, a new link will be generated.'));
          this.clearError();
        })
        .catch(this._setError)
        .finally(this._endProcessing);

      return;
    }

    if(this.privateAccessPermission) {
      url = format('permissions/%s', this.privateAccessPermission.id);
      apiMethod = this.apiService.patch.bind(this.apiService);
    } else {
      url = 'permissions/';
      apiMethod = this.apiService.post.bind(this.apiService);
    }
    this._setPrivateAccessLevel(accessLevel, apiMethod, url);
  }


  _setPrivateAccessLevel(accessLevel, apiMethod, url) {
    let oldAccessLevel = this.privateAccessLevel;
    let data = {
      meetingId: this.meetingService.id,
      accessLevel: accessLevel.id
    };
    this._startProcessing();
    apiMethod(url, data)
      .then((response) => {
        this.privateAccessPermission = response.data;
        this.privateAccessLevel = this.getAccessLevelFromPermission(response.data);
        this._checkPermissionLowered(oldAccessLevel, this.privateAccessLevel, 'private');
        this.clearError();
      })
      .catch(this._setError)
      .finally(this._endProcessing);
  }


  setTeamAccessLevel(accessLevel) {
    let oldAccessLevel = this.teamAccessLevel;
    let data = {
      teamAccessLevel: accessLevel.id
    };
    let url = format('meetings/%s/', this.meetingService.id);
    this._startProcessing();
    this.apiService.patch(url, data)
      .then((response) => {
        this.teamAccessLevel = AccessLevel.getFromId(response.data.teamAccessLevel);
        this.clearError();
        this._checkPermissionLowered(oldAccessLevel, this.teamAccessLevel, 'team');
      })
      .catch(this._setError)
      .finally(this._endProcessing);
  }


  inviteUser(userId, email, inviteMessage, accessLevel) {
    return this._inviteUser(userId, email, inviteMessage, accessLevel)
      .catch(this._setError);
  }


  resendInvite(permission) {
    let inviteMessage = (
      permission.inviteMessage
      || this.getInviteMessage(TEMPLATE_MAP[permission.accessLevel.id])
    );
    return this._inviteUser(permission.userId, null, inviteMessage, permission.accessLevel)
      .then(() => {
        let message = gettextCatalog.getString('Your invite was successfully resent');
        this.notificationService.success(message);
      })
      .catch(this._setError);
  }


  _inviteUser(userId, email, inviteMessage, accessLevel) {
    let url = 'permissions';
    let data = {
      meetingId: this.meetingService.id,
      inviteMessage: inviteMessage,
      accessLevel: accessLevel.id
    };
    if(userId) {
      data.userId = userId;
    } else {
      data.email = email;
    }
    this._startProcessing();
    return this.apiService.post(url, data)
      .then(response => {
        let permission = response.data;
        this.localInvites.add(permission.id);
        this.clearError();
      })
      .finally(this._endProcessing);
  }


  setUserAccessLevel(accessLevel, permission) {
    let oldAccessLevel = permission.accessLevel;
    if(accessLevel === AccessLevel.REMOVE) {
      this._removePermission(permission)
        .then(() => {
          this.clearError();
        })
        .catch(this._setError)
        .finally(this._endProcessing);
      return;
    }

    let data = {
      accessLevel: accessLevel.id
    };
    let url = format('permissions/%s', permission.id);
    this._startProcessing();
    this.apiService.patch(url, data)
      .then(() => {
        this._checkPermissionLowered(
          oldAccessLevel,
          accessLevel,
          'specific',
          permission.user.fullName
        );
        this.clearError();
      })
      .catch(this._setError)
      .finally(this._endProcessing);
  }

  sendVerificationMail() {
    return this.apiService.post('users/me/emailVerification/')
      .then(response => {
        this.notificationService.success(response.data.message);
      })
      .catch(error => {
        this.notificationService.error(error.message);
      });
  }

  getAccessLevelFromPermission(permission) {
    if(permission.accessLevel) {
      return AccessLevel.getFromId(permission.accessLevel);
    }
  }

  _removePermission(permission) {
    let url = format('permissions/%s/', permission.id);
    return this.apiService.delete(url);
  }

  _setError(error) {
    this.errorMessage = error && error.message;
  }

  clearError() {
    this._setError();
  }

  // By default info messages for this service are not relevant for templates.
  _setInfoMessage(message, templateRelevant) {
    if(templateRelevant == null) {
      templateRelevant = false;
    }

    if(!templateRelevant && this.meetingService.isTemplate) {
      return;
    }

    this.infoMessage = message;
    $timeout(
      this._clearInfoMessage,
      INFO_TIMEOUT
    );
  }

  _clearInfoMessage() {
    this.infoMessage = '';
  }

  _checkPermissionLowered(oldAccessLevel, newAccessLevel, type, name) {
    if(newAccessLevel.level < oldAccessLevel.level) {
      if(type === 'specific') {
        this._setInfoMessage(gettextCatalog.getString(
          'You have lowered the access level for {{ name }}.',
          { name: name }
        ));
      } else if(type === 'team') {
        this._setInfoMessage(gettextCatalog.getString(
          'You have lowered the access level for your team members.'
        ));
      } else if(type === 'private') {
        this._setInfoMessage(gettextCatalog.getString(
          'You have lowered the access level for people with a private link.'
        ));
      } else {
        this._setInfoMessage(gettextCatalog.getString(
          'You have lowered the access level.'
        ));
      }
    }
  }


  _startProcessing() {
    this.isSaving = true;
  }

  _endProcessing() {
    this.isSaving = false;
  }

  /**
   * Translate and fill in the {{ description }} of an invite message.
   * return it as a formatted message with a heading and footer
   *
   * @param {string} message_template - a message containing a {{ description }} token
   * @returns {string}
   */
  getInviteMessage(message_template) {
    /// used in invite emails heading
    let heading = gettextCatalog.getString('Hello!');

    /// used as closing in invite emails
    let footing = gettextCatalog.getString('Kind regards');

    let content = gettextCatalog.getString(message_template);


    return format(`${heading}

${content}

${footing},
${this.userService.me.firstName}`);
  }
}
