import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatStringArray'
})
export class FormatStringArrayPipe implements PipeTransform {
  transform(input: Array<string>, maxItems = 0, separator = $localize `and`): string {
    const count = input.length;
    if(count === 1) {
      return input[0];
    } else if(maxItems > 0 && maxItems < count) {
      const firstItems = input.slice(0, maxItems).join(', ');
      const hiddenItemsCount = count - maxItems;
      if(hiddenItemsCount === 1) {
        return $localize `:example; userA, userB and 1 other:${firstItems} ${separator} 1 other`;
      } else {
        return $localize `:example; userA, userB and 3 others:${firstItems} ${separator} ${hiddenItemsCount} others`; // eslint-disable-line max-len
      }
    } else {
      const firstItems = input.slice(0, count - 1).join(', ');
      const lastItem = input[count - 1];
      return $localize `:example; userA, userB and userC:${firstItems} ${separator} ${lastItem}`;
    }
  }
}
