import { Component } from '@angular/core';
import { SiteService } from 'utils/site.service';
@Component({
  selector: 'notes-trial-banner',
  templateUrl: './notes-trial-banner.component.html',
})
export class NotesTrialBannerComponent {
  constructor(
    private siteService: SiteService,
  ) {}

  public get subscriptionLink(): string {
    return this.siteService.site.subscriptionLink;
  }
}
