import { WHITEBOARD_TOOLS_WIDTH } from '../../variables';

import ContentTile from '../content/ContentTile';
import Tile from '../tiles/Tile';
import { DEFAULT_ZOOM_LEVEL } from './Whiteboard';

const TUTORIAL_COLLAPSE_MIN_WIDTH = 1220;
const TUTORIAL_COLLAPSE_MAX_HEIGHT = 680;


export default class WhiteboardTile extends ContentTile {
  constructor(whiteboard, ...args) {
    super(whiteboard.id, ...args);

    this.whiteboard = whiteboard;
    this.tutorialCollapsed = false;
  }

  get aspectRatio() {
    return this.active ? this.aspectRatioActive : this.aspectRatioInactive;
  }

  get aspectRatioInactive() {
    let size = this.whiteboard.contentSize;
    return size.width / size.height;
  }

  get aspectRatioActive() {
    let size = this.whiteboard.contentSize;
    if(size.width / size.height < 0.9) {
      return 1.25;
    } else {
      let height = (size.height * DEFAULT_ZOOM_LEVEL + size.width * (1 - DEFAULT_ZOOM_LEVEL));
      return size.width / height;
    }
  }

  get type() {
    return Tile.Type.WHITEBOARD;
  }

  get isLocked() {
    return this.meetingService.hardLimitNumEventsReached;
  }

  get sidebarWidth() {
    return this.isSpectator || this.isLocked ? 0 : WHITEBOARD_TOOLS_WIDTH;
  }

  get previewAvailable() {
    return this.whiteboard.previewAvailable;
  }


  takeMeetingSnapshot(maxDimension) {
    return this.whiteboard.takeMeetingSnapshot(maxDimension);
  }


  draw(...args) {
    super.draw(...args);

    this.tutorialCollapsed = (
      this.rect.width >= TUTORIAL_COLLAPSE_MIN_WIDTH
      && this.rect.height < TUTORIAL_COLLAPSE_MAX_HEIGHT);
  }
}
