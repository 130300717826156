import template from './meetingEnded.modal.html?raw';

import { bind, getQueryParameter } from 'utils/util';


function getMinActiveDuration() {
  let minActiveDuration = parseInt(getQueryParameter('minActiveDuration'));
  if(Number.isNaN(minActiveDuration)) {
    minActiveDuration = 60 * 5;  // 5 minutes
  }
  return minActiveDuration * 1000;
}


export const MODAL_ID_CALL_ENDED_DISMISSABLE = 'meetingEndedDismissable';
export const MODAL_ID_CALL_ENDED = 'meetingEnded';
const MIN_ACTIVE_DURATION = getMinActiveDuration();
const CALL_ENDED_RECORDING_NOTIFICATION_DURATION = 1000 * 15;
const REDIRECT_TIMEOUT = 1000 * 3;
const FEEDBACK_REDIRECT_TIMEOUT = 1000 * 8;


export default class MeetingEndedService {
  static get $inject() {
    return [
      'apiService',
      'feedbackService',
      'languageService',
      'meetingBroadcastService',
      'meetingService',
      'modalService',
      'notificationService',
      'recordingService',
      'userService',
      'unloadService',
      'notesSyncService',
      'activeIntervalService',
    ];
  }

  constructor(
    apiService,
    feedbackService,
    languageService,
    meetingBroadcastService,
    meetingService,
    modalService,
    notificationService,
    recordingService,
    userService,
    unloadService,
    notesSyncService,
    activeIntervalService
  ) {
    bind(this);

    this.apiService = apiService;
    this.feedbackService = feedbackService;
    this.languageService = languageService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.modalService = modalService;
    this.notificationService = notificationService;
    this.recordingService = recordingService;
    this.userService = userService;
    this.unloadService = unloadService;
    this.notesSyncService = notesSyncService;
    this.activeIntervalService = activeIntervalService;


    modalService.register(
      MODAL_ID_CALL_ENDED_DISMISSABLE,
      gettext('Call Ended'),
      'utils/icons/tl/24x24_phone_end_outline.svg',
      template
    );
    modalService.register(
      MODAL_ID_CALL_ENDED,
      gettext('Call Ended'),
      'utils/icons/tl/24x24_phone_end_outline.svg',
      template,
      { dismissable: false }
    );

    this.notificationTimeout = null;
    this.notification = null;

    meetingBroadcastService.afterInitialization().then(this._setupListeners);
  }

  _setupListeners() {
    this.userService.on('sessionExit', this._onSessionExit);
  }

  _removeListeners() {
    this.userService.off('sessionExit', this._onSessionExit);
  }


  get redirectTimeout() {
    return this.shouldAskFeedback ? FEEDBACK_REDIRECT_TIMEOUT : REDIRECT_TIMEOUT;
  }

  get shouldShowMeetingEnded() {
    return !this.activeIntervalService.currentIntervalIsActive
      && (
        this.shouldAskFeedback
        || this.shouldShowUploadWarning
        || this.shouldForwardWhenEnded
        || this.notesSyncService.canSaveContent()
      );
  }

  get shouldShowUploadWarning() {
    return !this.recordingService.isSynched;
  }

  get shouldAskFeedback() {
    return (
      this.activeIntervalService.timeActive > MIN_ACTIVE_DURATION
      && this.feedbackService.shouldAskAfterMeetingEnded
    );
  }

  get shouldForwardWhenEnded() {
    return (
      this.meetingService.settings.forwardUrl
      && this.userService.mySession.dateJoined != null
      && !this.userService.iAmHost
    );
  }

  get shouldForward() {
    return (
      this.userService.mySession.isDead()
      && this.shouldForwardWhenEnded
    );
  }

  get shouldForwardImmediately() {
    return (
      this.shouldForward
      && this.meetingService.settings.forwardImmediately
    );
  }


  /**
   * Close notifications and show the meeting ended modal
   */
  showModal() {
    this._removeListeners();
    this.hideNotification();

    if(this.shouldForward) {
      this.modalService.show(MODAL_ID_CALL_ENDED);
    } else {
      this.modalService.show(MODAL_ID_CALL_ENDED_DISMISSABLE);
    }
  }

  hideModal() {
    this.modalService.hideAll();
    this.onModalFinish();
  }

  onModalInteraction() {
    if(this.forwardTimeout) {
      $timeout.cancel(this.forwardTimeout);
      this.forwardTimeout = null;
    }
  }

  onModalFinish() {
    if(this.shouldForward) {
      this._forwardOnMeetingEnded();
    }
  }

  /**
   * Try to show a notification, can only be called once.
   * If there are unsynched recordings (not uploaded in the cloud), it will
   * show a message in the feedback notification.
   */
  showNotification() {
    // Overwrite any subsequent calls with no-operation
    this.showNotification = angular.noop;
    // Wait a second before showing the notification, in case it is actually the modal we want to
    // show. For example, when ending the meeting for everyone, you receive the other participants'
    // session-exit events before your own.
    this.showNotificationTimeout = $timeout(this.showNotificationAfterTimeout, 1000);
  }


  showNotificationAfterTimeout() {
    if(this.shouldShowUploadWarning) {
      this.notification = this.notificationService.info(
        `
          <div ng-controller="MeetingEndedCtrl as meetingEndedCtrl">
            <p translate>
              The conversation seems to be over. You still have unsaved local recording(s).
            </p>
            <div class="d-flex justify-content-end">
              <div
                class="btn mr-2"
                ng-click="meetingEndedCtrl.meetingEndedService.showModal()"
                translate
              >
                Give feedback
              </div>
              <div
                class="btn btn--primary"
                ng-click="meetingEndedCtrl.meetingEndedService.recordingService.showModal()"
                translate
              >
                Upload recording(s)
              </div>
            </div>
          </div>
        `,
        {
          delay: CALL_ENDED_RECORDING_NOTIFICATION_DURATION,
        }
      );
    } else {
      this.notification = this.notificationService.info(`
        <div ng-controller="MeetingEndedCtrl as meetingEndedCtrl">
          <div class="p" ng-bind-html="meetingEndedCtrl.feedbackService.prompt"></div>
          <div class="d-flex justify-content-end">
            <div
              class="btn btn--primary"
              ng-click="meetingEndedCtrl.meetingEndedService.showModal()"
              translate
            >
              Give feedback
            </div>
          </div>
      `);
    }
  }


  hideNotification() {
    $timeout.cancel(this.showNotificationTimeout);
    this.showNotificationTimeout = null;
    if(this.notification) {
      this.notification.cancel();
    }
  }


  /****************************
   * Automatically show modal *
   ****************************/

  /**
   * If a meeting room turns inactive, handle any unfinished business:
   *  - Ask for feedback
   *  - Notify about any unsaved recordings
   *  - Notify about a redirection
   *
   * Whenever a session leaves, check if the room is now inactive and if something of note should
   * be communicated (feedback/upload notification/redirect notification)
   *  - If the local session was the one that exited, show the meeting ended modal
   *  - Else, if a different session exited, show a vectera notification
   *
   * @param {Session} session
   */
  _onSessionExit(session) {
    if(!this.shouldShowMeetingEnded) {
      return;
    }

    if(session.isLocal) {
      if(this.shouldForwardImmediately) {
        this._forwardOnMeetingEnded();
      } else {
        if(this.shouldForward) {
          this.forwardTimeout = $timeout(this._forwardOnMeetingEnded, this.redirectTimeout);
        }
        this.showModal();
      }
    } else if(!session.isSoftExited()) {
      this.showNotification();
    }
  }

  _forwardOnMeetingEnded() {
    let forwardUrl = new URL(
      this.meetingService.settings.forwardUrl
    );
    forwardUrl.searchParams.append('meetingKey', this.meetingService.key);
    forwardUrl.searchParams.append('meetingId', this.meetingService.id);
    forwardUrl.searchParams.append('guest', this.userService.me.fullName);
    forwardUrl.searchParams.append(
      'owner',
      this.userService.get(this.meetingService.ownerId).fullName
    );

    this.unloadService.redirectWithoutConfirmation(forwardUrl.toString());
  }

}
