import { NgModule } from '@angular/core';
import { SharingOptionsComponent } from './sharing-options.component';
import { SvgIconModule } from 'utils/ui-components/svg-icon';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'utils/directives/directives.module';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { PipesModule } from 'utils/pipes';

@NgModule({
  imports: [
    SvgIconModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    QRCodeModule,
    PipesModule
  ],
  declarations: [
    SharingOptionsComponent,
  ],
  exports: [
    SharingOptionsComponent,
  ],
})
export class SharingOptionsModule {}
