// By convention, ISO 639 language codes should appear in lower case
// (as opposed to country codes, which are capitalized).

// keep this in sync with languages.py
export const languages = {
  all: [
    { name: $localize `English`, code: 'en' },
    { name: $localize `Dutch`, code: 'nl' },
    { name: $localize `French`, code: 'fr' },
    { name: $localize `German`, code: 'de' },
    { name: $localize `Spanish`, code: 'es' },
    { name: $localize `Italian`, code: 'it' },
    { name: $localize `Chinese`, code: 'zh' },
    { name: $localize `Japanese`, code: 'ja' },
    { name: $localize `Portuguese`, code: 'pt' },
    { name: $localize `Greek`, code: 'el' },
    { name: $localize `Turkish`, code: 'tr' },
    { name: $localize `Czech`, code: 'cs' },
    { name: $localize `Somali`, code: 'so' },
    { name: $localize `Slovak`, code: 'sk' },
    { name: $localize `Russian`, code: 'ru' },
    { name: $localize `Hungarian`, code: 'hu' },
    { name: $localize `Bulgarian`, code: 'bg' },
    { name: $localize `Bosnian`, code: 'bs' },
    { name: $localize `Arabic`, code: 'ar' },
    { name: $localize `Albanian`, code: 'sq' },
    { name: $localize `Romanian`, code: 'ro' },
    { name: $localize `Polish`, code: 'pl' },
    { name: $localize `Catalan`, code: 'ca' },
    { name: $localize `Danish`, code: 'da' },
    { name: $localize `Ukranian`, code: 'uk' },
    { name: $localize `Lithuanian`, code: 'lt' },
    { name: $localize `Norwegian`, code: 'no' },
    { name: $localize `Finnish`, code: 'fi' },
    { name: $localize `Swedish`, code: 'sv' },

  ],
  // languages considered primary in TLF and Vectera
  primary: [
    { name: $localize `English`, code: 'en' },
    { name: $localize `Dutch`, code: 'nl' },
    { name: $localize `French`, code: 'fr' },
    { name: $localize `German`, code: 'de' },
    { name: $localize `Spanish`, code: 'es' },
    { name: $localize `Italian`, code: 'it' },
  ],

  // KEEP THIS IN SYNC WITH bin/pull-translations
  // language with vectera i18n support
  localized: [
    { name: $localize `English`, code: 'en' },
    { name: $localize `Dutch`, code: 'nl' },
    { name: $localize `French`, code: 'fr' },
    { name: $localize `German`, code: 'de' },
    { name: $localize `Spanish`, code: 'es' },
    { name: $localize `Italian`, code: 'it' },
    { name: $localize `Lithuanian`, code: 'lt' },
  ]
};


export const languageCodeToNameMap = languages.all.reduce((map, language) => {
  map[language.code] = language.name;
  return map;
}, {});
