import templateStepSize from './wizardSteps/desktop/1.size.wizardStep.html?raw';
import templateStepSetup from './wizardSteps/desktop/2.setup.wizardStep.html?raw';

import Wizard from 'utils/angularjs/wizard/Wizard';
import { string, bind, format } from 'utils/util';

export const Template = Object.freeze({
  SIZE: 'organizationSizeDesktop',
  SETUP: 'organizationSetupDesktop',
  OVERVIEW: 'organizationOverviewDesktop',
});

export const WIZARD_ID_ONBOARDING = 'onboardingWizard';

export default class OnboardingController {
  static get $inject() {
    return [
      'apiService',
      'onboardingService',
      'requestUserService',
      'browserService',
    ];
  }

  constructor(
    apiService,
    onboardingService,
    requestUserService,
    browserService
  ) {
    bind(this);

    this.apiService = apiService;
    this.onboardingService = onboardingService;
    this.requestUserService = requestUserService;
    this.browserService = browserService;

    this.organizationName = null;
    this.organizationSetupIsValid = false;

    this.selectedSize = null;
    this.personalLink = null;
    this.wizard = null;

    this._initWizard();
    this.hideAppCues();

    /* eslint-disable max-len */
    /**
     * mobileGroup determines on which row the icons should show on mobile (the screen is too small to
     * show them side by side)
     */
    this.ORGANIZATION_SIZES = Object.freeze({
      SOLO:      { id: 'solo',        title:gettextCatalog.getString('Solo'),        icon:'dashboard/angularjs/onboarding/icons/solo.svg',       mobileGroup: 1 },
      SMALL:     { id: 'small',       title:'2-10',                                  icon:'dashboard/angularjs/onboarding/icons/small.svg',      mobileGroup: 1 },
      MEDIUM:    { id: 'medium',      title:'10-50',                                 icon:'dashboard/angularjs/onboarding/icons/medium.svg',     mobileGroup: 2 },
      CORPORATE: { id: 'corporate',   title:'50+',                                   icon:'dashboard/angularjs/onboarding/icons/corporate.svg',  mobileGroup: 2 },
    });
    /* eslint-enable max-len */

  }

  hideAppCues() {
    window.__vecteraDisableAppcues();
  }

  _initWizard() {
    this.wizard = new Wizard(
      WIZARD_ID_ONBOARDING,
      {
        title: gettext('Set up your account'),
        showSteps: false,
        closeable: false,
        scope: { onboardingCtrl: this },
      }
    );

    this.wizard.addStep(
      Template.SIZE,
      templateStepSize,
      {
        name: gettext('What is the size of your team?'),
        shorthand: gettext('Team size'),
        validateNextFunc: this.validateOrganizationSize
      }
    );

    this.wizard.addStep(
      Template.SETUP,
      templateStepSetup,
      {
        name: gettext('Set up your meeting room links'),
        shorthand: gettext('Meeting links'),
        validateNextFunc: this.validateOrganizationSetup,
        nextFunc: this.finishOrganizationSetup
      }
    );

    this.wizard.start();
  }

  get organizationSizes() {
    return Object.values(this.ORGANIZATION_SIZES);
  }

  selectSize(size) {
    this.selectedSize = size;
  }

  get slugifiedOrganizationName() {
    return string.slugify(this.organizationName);
  }

  /**
  * The validation function to determine if a size is selected in the first step of the
  * onboarding wizard.
  *
  * @returns {boolean}
  */
  validateOrganizationSize() {
    return this.selectedSize != null;
  }


  /**
   * The validation function to determine if the slug of the organization is a valid slug in the
   * second step of the onboarding wizard.
   *
   * @returns {boolean}
   */
  validateOrganizationSetup() {
    return this.organizationSetupIsValid;
  }

  onOrganizationSetupIsValid(isValid) {
    this.organizationSetupIsValid = isValid;
  }

  onOrganizationName(name) {
    this.organizationName = name;
  }


  /**
  * The function that is executed after the second step in the onboarding wizard. The name and size
  * of the organization is set on the organization. After a successfull patch, the
  * personal waiting room link is fetched to display in the last step of the onboarding wizard.
  *
  * @return {Promise}
  */
  finishOrganizationSetup() {
    let path = format('organizations/%s', this.requestUserService.user.organization.id);
    let data = {
      name: this.organizationName,
      onboardingSize: this.selectedSize.id,
      needsOnboarding: false,
    };

    return this.apiService.patch(path, data)
      .then(this._getUserInfo).then(response => {
        this.personalLink = response.data.personalWaitingRoomUrl;
      })
      .then(this.onboardingService.finishWizard);
  }


  _getUserInfo() {
    return this.apiService.get('users/me/');
  }
}
