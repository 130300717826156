import Tile from '../tiles/Tile';
import { CONTENT_HEADER_HEIGHT, CONTENT_HEADER_HEIGHT_INACTIVE } from '../../variables';
import { Rect } from 'utils/util';

export default class ContentTile extends Tile {
  static get $inject() {
    return [
      'browserService',
      'meetingService',
    ].concat(Tile.$inject);
  }
  constructor(id, browserService, meetingService, ...args) {
    super(id, ...args);

    this.browserService = browserService;
    this.meetingService = meetingService;

    this.$elemBodyInactive = null;
    this.$elemInactive = null;
    this.$elemActive = null;
    this.rectBodyInactive = null;
    this.rectInactive = new Rect({ left: 0, top: 0, width: 0, height: 0 });

    /**
     * isCollapsed stores whether an inactive tile shows a preview of the content. A collapsed tile
     * will only show the tile header.
     *
     * @type {boolean}
     * @public
     */
    this.isCollapsed = false;
  }

  get category() {
    return Tile.Category.CONTENT;
  }

  get headerHeight() {
    return this.active ?
      this.isSpectator ?
        0 :
        CONTENT_HEADER_HEIGHT :
      this.headerHeightInactive;
  }
  get headerHeightInactive() {
    return CONTENT_HEADER_HEIGHT_INACTIVE;
  }

  get previewAvailable() {
    return true;
  }


  drawInactive(rect) {
    if(rect != null) {
      this.rectInactive = rect.clone();
      this.rectBodyInactive = new Rect({
        left: this.rectInactive.left + this.borderWidth,
        top: this.rectInactive.top + this.headerHeightInactive + this.borderWidth,
        right: this.rectInactive.right - this.borderWidth,
        bottom: this.rectInactive.bottom - this.borderWidth,
      });
    }

    if(this.$elemInactive && this.rectInactive) {
      this.$elemInactive.css({
        left: this.rectInactive.left + 'px',
        top: this.rectInactive.top - this.scrollInactive + 'px',
        width: this.rectInactive.width + 'px',
        height: this.rectInactive.height + 'px',
      });
      this.$elemInactive.find('.tile').toggleClass('tile--inactive-selected', this.active);
      this.drawActive = this.active;
    }

    if(this.$elemBodyInactive && this.rectInactive) {
      let width = this.rectInactive.width;
      let height = this.isCollapsed ?
        Math.floor(width / this.aspectRatioInactive) :
        this.rectInactive.height - this.headerHeightInactive;
      this.$elemBodyInactive.css({
        width:  width  + 'px',
        height: height + 'px',
      });
    }

    this.emit('draw', this);
  }


  setScrollInactive(scroll) {
    this.scrollInactive = scroll;
    this.drawInactive();
  }


  _setElem($elem) {
    super._setElem($elem);
    this.$elemBodyInactive = $elem.find('.content-tile__body--inactive');
  }

  _removeElem() {
    this.$elemBodyInactive = null;
  }
}
