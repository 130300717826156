import { Injectable } from '@angular/core';

export enum State {
  INITIALIZING,
  LOADING,
  READY,
  SAVING,
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public state = State.INITIALIZING;

  get State() {
    return State;
  }

  setState(state: State) {
    this.state = state;
  }


  get isInitializing() {
    return this.state === State.INITIALIZING;
  }

  setInitializing() {
    this.state = State.INITIALIZING;
  }

  get isLoading() {
    return this.state === State.LOADING;
  }

  setLoading() {
    this.state = State.LOADING;
  }

  get isReady() {
    return this.state === State.READY || this.state === State.SAVING;
  }

  setReady() {
    this.state = State.READY;
  }

  get isSaving() {
    return this.state === State.SAVING;
  }

  setSaving() {
    this.state = State.SAVING;
  }

}



/**
 * When a specific component or service needs to store its own state, use this service instead of
 * the global StateService.
 */
@Injectable()
export class LocalStateService extends StateService {}
