import { Pipe, PipeTransform } from '@angular/core';
import { I18nPluralPipe } from '@angular/common';
import { TimeDelta } from 'utils/util';

class Format {
  constructor(
    public divider: number,
    public timePortionShort: string,
    public timePortionZero: string,
    public timePortionSingular: string,
    public timePortionPlural: string,
  ) {}
}

const formats = [
  new Format(
    1,
    $localize `:Abbreviation of second:s`,
    $localize `0 seconds`,
    $localize `1 second`,
    $localize `# seconds`,
  ),
  new Format(
    60,
    $localize `:Abbreviation of minutes:m`,
    $localize `0 minutes`,
    $localize `1 minute`,
    $localize `# minutes`,
  ),
  new Format(
    60 * 60,
    $localize `:Abbreviation of hours:h`,
    $localize `0 hours`,
    $localize `1 hour`,
    $localize `# hours`,
  ),
  new Format(
    60 * 60 * 24,
    $localize `:Abbreviation of days:d`,
    $localize `0 days`,
    $localize `1 day`,
    $localize `# days`,
  ),
];

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor(private i18nPluralPipe: I18nPluralPipe) {}

  transform(input: number|TimeDelta, fullText = false, numParts = 2): string {
    let seconds = typeof input === 'number' ? input : input.seconds;

    if(seconds === 0) {
      if(fullText) {
        return `0 ${formats[0].timePortionZero}`;
      } else {
        return `0${formats[0].timePortionShort}`;
      }
    } else if(seconds < formats[0].divider) {
      if(fullText) {
        return `1 ${formats[0].timePortionSingular}`;
      } else {
        return `1${formats[0].timePortionShort}`;
      }
    }

    const readableParts: string[] = [];
    let firstPartFound = false;
    for(let i = formats.length - 1; i >= 0; i--) {
      const divider = formats[i].divider;
      const quotient = Math.floor(seconds / divider);
      const remainder = seconds % divider;
      seconds = remainder;
      if(quotient === 0) {
        // Only return adjacent parts: e.g 2h 5m. Not 2h 5s. In the last case, return 2h
        if(firstPartFound) {
          break;
        } else {
          continue;
        }
      }

      let readablePart;
      if(fullText) {
        readablePart = this.transformPluralSingular(
          quotient,
          formats[i].timePortionZero,
          formats[i].timePortionSingular,
          formats[i].timePortionPlural,
        );
      } else {
        readablePart = `${quotient}${formats[i].timePortionShort}`;
      }

      readableParts.push(readablePart);
      firstPartFound = true;
      if(readableParts.length === numParts) {
        return readableParts.join(' ');
      }
    }
    return readableParts.join(' ');
  }

  transformPluralSingular(value: number, zero: string, singular: string, plural: string) {
    return this.i18nPluralPipe.transform(value, {
      '=0': zero,
      '=1': singular,
      'other': plural,
    });
  }
}
