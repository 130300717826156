<div class="notes-header">
  <div *ngIf="canUseSmartSummary" class="notes-header__smart-summary-usage">
    <div class="d-flex align-items-center gap-2">
      <div i18n>Free smart summary hours used</div>
      <div>&bull;</div>
      <div class="text-normal d-flex align-items-center">
        {{ smartSummarySecondsUsed | duration:false:2:'minutes':'hours' }}
        /
        {{ totalAllowedSmartSummarySeconds | duration:false:2:'minutes':'hours' }}
      </div>
    </div>
    <usage-bar
      [usage]="smartSummarySecondsUsed"
      [allowedUsage]="totalAllowedSmartSummarySeconds"
      class="notes-header__smart-summary-usage-bar"
    ></usage-bar>
  </div>
</div>
