import { SignalStrength } from 'meeting/angularjs/main/signalStrength/signalStrength.service';
import { StreamKind } from  'meeting/meeting-room/stream';
import {
  array,
  bind,
  browser,
  errors,
  EventEmitter,
  format,
  getQueryParameter,
  interval,
  logger,
  object
} from 'utils/util';
import StatsTracker from '../StatsTracker';

const CONNECT_DEBOUNCE = 250;  // Make sure this is bigger than tileService's REDRAW_THROTTLE
const MAX_CONNECTION_TIME = 30000;

const KEEP_ALIVE_INTERVAL = 3000;
const KEEP_ALIVE_TIMEOUT = 10000;

const CONSTRAINTS = {
  optional: [
    { googCpuOveruseDetection: true },
  ],
};

const VIDEO_INCOMPATIBLE_MESSAGE = (
  'Session error code: ERROR_CONTENT. '
  + 'Session error description: Failed to set remote video description send parameters..'
);


const SignalingState = Object.freeze({
  CLOSED: 'closed',
  SENT_REQ: 'sent req',
  GOT_REQ: 'got req',
  SENT_ACK: 'sent ack',
  GOT_ACK: 'got ack',
  SENT_OFFER: 'sent offer',
  GOT_OFFER: 'got offer',
  GOT_ANSWER: 'got answer',
  STABLE: 'stable',
});

const State = Object.freeze({
  STOPPED: 'stopped',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
});

/**
 * @readonly
 * @enum {number} When a negotiation is needed, both clients signal to each other which kind of
 *                negotiation they want to perform: their requested `ReconnectType`. The "maximum"
 *                `ReconnectType` is chosen. For example: if Alice wants to do a SOFT reconnection,
 *                and Bob wants to do a RESTART reconnection, they will settle on a RESTART
 *                reconnection.
 *
 * @property {number} FALSE:       The client is not aware of any changes that require
 *                                 renegotiation.
 * @property {number} SOFT:        The client wants to renegotiate, but does not request an ICE
 *                                 negotiation. This happens for example when a client requests or
 *                                 unrequests a remote stream. This is the most common form of
 *                                 renegotiation.
 * @property {number} ICE_RESTART: The client has noticed changes to the local network connection
 *                                 that may require a new ICE negotation.
 * @property {number} RESTART:     A negotation from scratch is needed. Both clients should delete
 *                                 their RTCPeerConnection instances and start a new negotation.
 *                                 This happens for example when a RTCPeerConnection instance has
 *                                 encountered an error.
 * @property {number} CLOSE:       The client wants to close the peer connection entirely. This
 *                                 happens when neither of the peers is requesting any streams
 *                                 anymore.
 */
const ReconnectType = Object.freeze({
  FALSE: 0,
  SOFT: 1,
  ICE_RESTART: 2,
  RESTART: 3,
  CLOSE: 4,
});

const DataChannel = Object.freeze({
  KEEP_ALIVE: 0,
  SIGNAL_STRENGTHS: 1,
  PRIVATE_MESSAGE: 3,
  REQ_VIDEO_PARAMETERS: 4,
  ACK_VIDEO_PARAMETERS: 5,
});
const dataHandlers = {
  [DataChannel.KEEP_ALIVE]: '_onKeepAlive',
  [DataChannel.SIGNAL_STRENGTHS]: '_onSignalStrengthsFromPeer',
  [DataChannel.PRIVATE_MESSAGE]: '_onPrivateMessage',
  [DataChannel.REQ_VIDEO_PARAMETERS]: '_onReqVideoParameters',
  [DataChannel.ACK_VIDEO_PARAMETERS]: '_onAckVideoParameters',
};


/**
 * The codec preference we give to each combination of [mimeType, isHardwareAccelerated]. Items
 * that occur earlier in the list get a higher priority. Items not on the list get the lowest
 * priority.
 *
 * If they are hardware-accelerated, we prefer modern codecs (VP8 & VP9). If they are not hardware-
 * accelerated, we prefer the older but faster codec H264.
 * @readonly
 */
const CODEC_PREFERENCE = Object.freeze([
  ['video/VP8', true],
  ['video/VP9', true],
  ['video/H264', true],
  ['video/H264', false],
  ['video/VP8', false],
  ['video/VP9', false],
]);

const isOffererQueryParam = getQueryParameter('isOfferer');
const IS_OFFERER_OVERRIDE = (
  isOffererQueryParam === 'true' || isOffererQueryParam === '1' ?
    true :
    isOffererQueryParam === 'false' || isOffererQueryParam === '0' ?
      false :
      null
);

const videoCodecQueryParam = (getQueryParameter('videoCodec') || '').toUpperCase();
const mimeTypes = new Set(CODEC_PREFERENCE.map(item => item[0]));
const VIDEO_CODEC_OVERRIDE = mimeTypes.has('video/' + videoCodecQueryParam) ?
  'video/' + videoCodecQueryParam :
  null;

/**
 * We make sure the peer with the lowest sessionId is always the want to send the RTCOffer,
 * the peer with the highest sessionid always sends the RTCAnswer. This is not necessary
 * according to the WebRTC spec, but there is an incompatibility between Chrome and Firefox:
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1329028
 *
 * There are 2 scenarios for a full negotiation, depicted below. Peer A has a lower sessionId
 * than peer B. REQ and ACK contain the streamIds that a peer wants to receive.
 *
 * A          B            A               B
 * |   REQ    |            |      REQ      |
 * |--------->|            |<--------------|
 * |          |            |               |
 * |   ACK    |            |  ACK + OFFER  |
 * |<---------|            |-------------->|
 * |          |            |               |
 * |  OFFER   |            |    ANSWER     |
 * |--------->|            |<--------------|
 * |          |
 * |  ANSWER  |
 * |<---------|
 *
 */

export default class PeerConnectionP2P {
  static get State() {
    return State;
  }
  static get ReconnectType() {
    return ReconnectType;
  }

  constructor(
    userService,
    privateMessageService,
    notificationService,
    streamService,
    rtcConfigurationService,
    settingsService,
    videoCompatibleService,
    signalStrengthService,
    siteService,
    meetingService,

    session,
    connectionId
  ) {
    bind(this);
    EventEmitter.setup(this, [
      'state',
      'privateMessage',
      'track',
      'stats',
      'badConnectionWithRelayServer',
    ]);

    this.userService = userService;
    this.privateMessageService = privateMessageService;
    this.notificationService = notificationService;
    this.streamService = streamService;
    this.rtcConfigurationService = rtcConfigurationService;
    this.settingsService = settingsService;
    this.videoCompatibleService = videoCompatibleService;
    this.signalStrengthService = signalStrengthService;
    this.siteService = siteService;
    this.meetingService = meetingService;


    this.session = session;
    this.connectionId = connectionId;

    this.isOfferer = IS_OFFERER_OVERRIDE != null ?
      IS_OFFERER_OVERRIDE :
      session.id < this.userService.mySession.id;

    this.rtcPeerConnection = null;
    this.mediaStream = new MediaStream();  // eslint-disable-line compat/compat
    /**
     * All the streams that are currently being sent over this peerConnection. `local` streams are
     * the streams where we are the publisher, while `remote` streams are the remote party is the
     * publisher, i.e. we are the subscriber.
     */
    this.streams = {
      local: {
        audio: null,
        video: null,
      },
      remote: {
        audio: null,
        video: null,
      },
    };

    /**
     * The resolution at which the video streams are currently displayed. The naming may be
     * confusing: videoResolution.local is the resolution at which the local video stream is being
     * displayed at the remote party.
     */
    this.videoResolution = {
      local: 0,
      remote: 0,
    };
    /**
     * Derived from this.videoResolution by calling settingsService.getSendBandwidth()
     */
    this.videoBandwidth = {
      local: 0,
      remote: 0,
    };
    this.statsTracker = null;
    this.stats = null;

    this.state = State.STOPPED;
    this.signalingState = SignalingState.STABLE;
    this.signalingStateBeforeClose = SignalingState.STABLE;
    this.receiveMessagePromise = $q.resolve();
    this.setVideoParametersPromise = $q.resolve();
    this.shouldSetCodecPreferences = true;

    this.iceCandidateQueue = [];
    this.hasFirstLocalIceCandidate = false;
    this.hasLastLocalIceCandidate = false;
    this.hasFirstRemoteIceCandidate = false;
    this.hasLastRemoteIceCandidate = false;
    this.noCandidatesWarning = null;

    this.maxNegotiationId = 0;
    this.negotiationId = null;
    this.iceNegotiationId = null;

    this.nextReconnectType = ReconnectType.FALSE;
    this.currentReconnectType = ReconnectType.FALSE;
    this.createConnectionPromise = $q.resolve();
    this.warnConnectionFailedTimeout = null;

    this.keepAliveInterval = null;
    this.keepAliveTimeout = null;

    this.dataChannels = {};
    this.dataQueues = {};

    this._connectDebounced = debounce(this._connectDebounced, CONNECT_DEBOUNCE);

    this.session.on('exit knockExit', this.close);
  }


  get signaling() {
    return (
      this.signalingState !== SignalingState.CLOSED
      && this.signalingState !== SignalingState.STABLE
    );
  }

  get connecting() {
    return this.state === State.CONNECTING;
  }

  get id() {
    return format('%s-%s', this.session.id, this.connectionId);
  }



  /*******************
   * General methods *
   *******************/

  _log(...args) {
    this._logWithLevel('debug', ...args);
  }

  _warn(...args) {
    this._logWithLevel('warn', ...args);
  }

  _logWithLevel(level, ...args) {
    logger.withContext({ peerConnection: this.id })[level](...args);
  }


  _apply(fn) {
    return function() {
      $rootScope.$evalAsync(() => fn(...arguments));
    };
  }


  _setSignalingState(signalingState) {
    this.signalingState = signalingState;
    this._updateState();
  }


  _updateState() {
    let state;

    if(!this.rtcPeerConnection || this.signalingState === SignalingState.CLOSED) {
      state = State.STOPPED;

    } else if(this.signaling) {
      state = State.CONNECTING;

    } else {
      let iceConnectionState = this.rtcPeerConnection.iceConnectionState;
      switch(iceConnectionState) {
        case 'new':
        case 'checking':
        case 'connecting':  // Edge
        case 'failed':
        case 'disconnected':
        case 'closed':
          state = State.CONNECTING;
          break;

        case 'connected':
        case 'completed':
          state = State.CONNECTED;
          break;

        default:
          state = State.CONNECTING;
          logger.error('Unknown ice connection state:', iceConnectionState);
          break;
      }
    }

    if(state !== this.state) {
      this._log('Set state from %s to %s', this.state, state);

      let oldConnecting = this.state === State.CONNECTING;
      let newConnecting = state === State.CONNECTING;
      if(oldConnecting && !newConnecting && this.warnConnectionFailedTimeout) {
        $timeout.cancel(this.warnConnectionFailedTimeout);
        this.warnConnectionFailedTimeout = null;
      } else if(!oldConnecting && newConnecting && !this.warnConnectionFailedTimeout) {
        this.warnConnectionFailedTimeout = $timeout(() => {
          this._log('Failed to connect after %ss', MAX_CONNECTION_TIME / 1000);
        }, MAX_CONNECTION_TIME);
      }

      this.state = state;
      if(state === State.CONNECTED) {
        this._resetStreamMonitoring();
      }
      this.emit('state', this, state);
    }
  }


  _setNextReconnectType(nextReconnectType) {
    this.nextReconnectType = nextReconnectType;
  }


  _resetStreamMonitoring() {
    this.getRemoteStreams().forEach(streamInfo => {
      streamInfo.stream.resetMonitoring();
    });
  }


  _getUniqueNegotiationId() {
    return this.userService.getUniqueId(++this.maxNegotiationId);
  }

  /**
   * initialise the this.rtcPeerConnection class variable, together with any related variables
   * and handlers
   *
   * @param {boolean} forceCreate if true, close the existing connection before creation
   * @returns {Promise} Promise that resolves when all variables have been set
   */
  _createRtcPeerConnection(forceCreate) {
    this.createConnectionPromise = this.createConnectionPromise
      .then(() => {
        if(forceCreate) {
          this.close();
        }

        if(!this.rtcPeerConnection) {
          this._log('Open connection');
          return this.rtcConfigurationService.get();
        }
      })
      .then(configuration => {
        if(configuration) {
          try {
            this.rtcPeerConnection = new window.RTCPeerConnection(configuration, CONSTRAINTS);
          } catch(error) {
            // This happens in Firefox if there is no internet connection.
            // Just retry after a timeout
            if(error.name === 'InvalidStateError') {
              return $timeout(this._createRtcPeerConnection, 1000);
            } else {
              throw error;
            }
          }

          // Each of these listeners should be unset in `this.close()`
          this.rtcPeerConnection.ontrack = this._apply(this._onAddRemoteTrack);
          this.rtcPeerConnection.onicecandidate = this._apply(this._onLocalIceCandidate);
          this.rtcPeerConnection.oniceconnectionstatechange =
            this._apply(this._onIceConnectionStateChange);
          this.rtcConfigurationService.on('configuration', this._onConfiguration);
          this.settingsService.on('videoQuality', this._onChangeQuality);

          this.statsTracker = new StatsTracker(this.rtcPeerConnection);
          this.statsTracker.on('stats', this._onStats);
          this.statsTracker.on('statsLogged', this._onStatsLogged);

          this.statsTracker.on('selectedCandidatePairChanged', this._onCandidatePairChanged);
          this._updateBytesPerSecondTarget();

          this._openDataChannels();
          this.keepAliveInterval = interval.setInterval(this._sendKeepAlive, KEEP_ALIVE_INTERVAL);
          this._onKeepAlive();
        }

        this._updateState();
      });

    return this.createConnectionPromise;
  }

  _onStatsLogged(logString) {
    logger.error(
      this.meetingService.key + '- ' + this.session._id + ' - ' + this.session.user.fullName + ' - ' + logString  // eslint-disable-line
    );
  }


  /**
   * Helper function to throw an EscapePromiseError if rtcPeerConnection is not set
   *
   * @param {string} task - a description of the task that is being done at the time of call
   */
  _checkRtcPeerConnectionOrEscape(task) {
    if(!this.rtcPeerConnection) {
      throw new errors.EscapePromiseError(
        format('this.rtcPeerConnection is null! %s', task)
      );
    }
  }

  close() {
    if(this.rtcPeerConnection) {
      this._log('Close connection');

      this.rtcPeerConnection.ontrack = null;
      this.rtcPeerConnection.onicecandidate = null;
      this.rtcPeerConnection.oniceconnectionstatechange = null;
      this.rtcConfigurationService.off('configuration', this._onConfiguration);
      this.settingsService.off('videoQuality', this._onChangeQuality);

      this.statsTracker.destroy();
      this.statsTracker.off('stats', this._onStats);
      this.statsTracker.off('selectedCandidatePairChanged', this._onCandidatePairChanged);
      this.statsTracker = null;
      this.stats = null;

      interval.clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = null;
      $timeout.cancel(this.keepAliveTimeout);
      this.keepAliveTimeout = null;

      this._closeDataChannels();
      this.iceCandidateQueue = [];

      try {
        this.rtcPeerConnection.close();
      } catch(error) {
        if(error.name === 'InvalidStateError') {
          this._log('Failed to close peerconnection:', error);
        } else {
          throw error;
        }
      }
      this.rtcPeerConnection = null;
    }

    Object.values(this.streams.local)
      .filter(angular.identity)
      .forEach(streamInfo => {
        this._removeLocalStream(streamInfo.stream);
      });

    this.signalingState = SignalingState.CLOSED;
    this._updateState();
  }



  destroy() {
    Object.values(this.streams.remote)
      .filter(angular.identity)
      .forEach(streamInfo => {
        this._removeRemoteStream(streamInfo.stream);
      });

    this.close();
  }



  _onConfiguration(configuration) {
    if(!this.rtcPeerConnection) {
      return;
    }

    try {
      this.rtcPeerConnection.setConfiguration(configuration);
    } catch(error) {
      this._log(error);
      this.connect(ReconnectType.RESTART);
    }
  }


  _onChangeSize() {
    if(this.signalingState === SignalingState.CLOSED) {
      return;
    }

    this._log('Change size');
    this._requestRemoteSetVideoParameters();
  }

  _onChangeQuality() {
    if(this.signalingState === SignalingState.CLOSED) {
      return;
    }

    this._log('Change quality');
    this._requestRemoteSetVideoParameters();
  }


  _getTransceiver(kind) {
    if(this.rtcPeerConnection && browser.supportsWebRTCTransceivers()) {
      return this.rtcPeerConnection.getTransceivers()
        .filter(transceiver => transceiver.receiver.track.kind === kind)[0];
    }
  }



  /*************************
   * Manage remote streams *
   *************************/

  getRemoteStreams() {
    return Object.values(this.streams.remote)
      .filter(streamInfo => !!streamInfo);
  }

  getRemoteStreamIds() {
    return this.getRemoteStreams()
      .map(streamInfo => streamInfo.stream.id);
  }


  subscribe(stream) {
    this._addRemoteStream(stream);
    this._connectAfterRequestUnrequest();
  }

  unsubscribe(stream) {
    this._removeRemoteStream(stream);
    this._connectAfterRequestUnrequest();
  }


  _addRemoteStream(stream) {
    this._log('Request remote %s stream %s', stream.kind, stream.id);
    if(this.streams.remote[stream.kind]) {
      throw new errors.IllegalStateError(format(
        'PC already has a remote %s stream', stream.kind));
    }

    if(stream.kind === StreamKind.VIDEO) {
      stream.on('displaySize', this._onChangeSize);
      this.videoResolution.remote = 0;
    }

    let streamInfo = {
      stream: stream,
      track: null,
    };
    this.streams.remote[stream.kind] = streamInfo;

    let track = this._getRemoteTrack(stream.kind);
    if(track) {
      this._setRemoteTrack(track);
    }
  }

  _getRemoteTrack(kind) {
    if(!this.rtcPeerConnection) {
      return null;
    }

    let tracks;
    if(this.rtcPeerConnection.getReceivers) {
      tracks = this.rtcPeerConnection.getReceivers().map(receiver => receiver.track);
    } else {
      tracks = this.rtcPeerConnection.getRemoteStreams().reduce((tracks, stream) => {
        return tracks.concat(stream.getTracks());
      }, []);
    }

    return tracks.filter(track => track.kind === kind)[0];
  }


  _removeRemoteStream(stream) {
    this._log('Unrequest remote %s stream %s', stream.kind, stream.id);
    if(stream !== this.streams.remote[stream.kind].stream) {
      throw new errors.IllegalStateError('Not subscribed to remote stream');
    }

    if(stream.kind === StreamKind.VIDEO) {
      stream.off('displaySize', this._onChangeSize);
    }
    this.streams.remote[stream.kind] = null;
  }


  _connectAfterRequestUnrequest() {
    let reconnectType = ReconnectType.SOFT;
    if(!browser.supportsPeerConnectionAddRemoveTrack()) {
      this._log('Need to restart after unrequest remote track');
      reconnectType = ReconnectType.RESTART;
    }
    this.connect(reconnectType);
  }


  _onAddRemoteTrack(event) {
    this._setRemoteTrack(event.track);
  }


  _setRemoteTrack(track) {
    let streamInfo = this.streams.remote[track.kind];
    if(streamInfo) {
      this._log('Set remote %s track', track.kind);
      streamInfo.track = track;
      this.emit('track', this, track);
    }
  }



  /************************
   * Manage local streams *
   ************************/

  _addLocalStream(stream) {
    return $q.resolve().then(() => {
      let promise;
      let track = stream.track;
      let sender = null;

      this.mediaStream.addTrack(track);
      let transceiver = this._getTransceiver(stream.kind);
      if(transceiver) {
        sender = transceiver.sender;
        promise = sender.replaceTrack(track);
      } else {
        sender = this.rtcPeerConnection.addTrack(track, this.mediaStream);
      }

      this.streams.local[stream.kind] = {
        stream: stream,
        track: track,
        sender: sender,
      };

      return promise;
    });
  }


  _removeLocalStream(stream) {
    if(stream !== this.streams.local[stream.kind].stream) {
      throw new errors.IllegalStateError('Local stream does not match added stream');
    }

    let streamInfo = this.streams.local[stream.kind];
    this.mediaStream.removeTrack(streamInfo.track);
    this.streams.local[stream.kind] = null;

    return $q.resolve().then(() => {
      let promise;
      if(this.rtcPeerConnection) {
        if(browser.supportsWebRTCTransceivers() && streamInfo.sender) {
          promise = streamInfo.sender.replaceTrack(null);
        } else {
          this.rtcPeerConnection.removeTrack(streamInfo.sender);
        }
      }
      return promise;
    });
  }


  getLocalStreams() {
    return Object.values(this.streams.local)
      .filter(streamInfo => !!streamInfo);
  }



  /***************************
   * Communication with peer *
   ***************************/

  _send(channel) {
    let message = new Array(arguments.length + 3);

    // Channel
    message[0] = arguments[0];

    // Receiver
    message[1] = this.session;

    // Connection id
    message[2] = this.connectionId;

    // Signaling id
    message[3] = (
      channel === 'rtc-icecandidate' ?
        this.iceNegotiationId :
        this.negotiationId);

    // Rest of the message
    for(let i = 1; i < arguments.length; i++) {
      message[i + 3] = arguments[i];
    }

    this.privateMessageService.send(...message);
  }


  onMessage() {
    this.receiveMessagePromise = this.receiveMessagePromise.then(
      this.receiveMessage.bind(this, arguments));
  }

  receiveMessage(args) {
    let channel = args[0];
    let negotiationId = args[3];
    let accept = this._shouldAcceptMessage(channel, negotiationId);

    if(accept) {
      // Strip channel and senderId
      let strippedArgs = new Array(args.length - 3);
      for(let i = 3; i < args.length; i++) {
        strippedArgs[i - 3] = args[i];
      }

      switch(channel) {
        case 'rtc-icecandidate':
          return this._onMessageIceCandidate.apply(this, strippedArgs);

        case 'rtc-req':
          return this._onMessageReq.apply(this, strippedArgs);

        case 'rtc-ack':
          return this._onMessageAck.apply(this, strippedArgs);

        case 'rtc-offer':
          return this._onMessageOffer.apply(this, strippedArgs);

        case 'rtc-answer':
          return this._onMessageAnswer.apply(this, strippedArgs);

        case 'rtc-error':
          return this._onMessageError.apply(this, strippedArgs);

        default:
          return $q.reject(new errors.InvalidArgumentError(format(
            'Unrecognized rtc channel:', channel)));
      }

    } else {
      this._log('Rejected message', args);
      return $q.resolve();
    }
  }


  _shouldAcceptMessage(channel, negotiationId) {
    let correctState, correctNegotiationId;

    switch(channel) {
      case 'rtc-icecandidate':
        correctState = this.signalingState !== SignalingState.CLOSED;
        correctNegotiationId = negotiationId === this.iceNegotiationId;
        break;

      case 'rtc-req':
        correctState = (
          this.signalingState !== SignalingState.SENT_REQ
          || this.isOfferer
        );
        correctNegotiationId = true;
        break;

      case 'rtc-ack':
        correctState = this.signalingState === SignalingState.SENT_REQ;
        correctNegotiationId = negotiationId === this.negotiationId;
        break;

      case 'rtc-offer':
        correctState = (
          this.signalingState === SignalingState.SENT_REQ
          || this.signalingState === SignalingState.SENT_ACK
        );
        correctNegotiationId = negotiationId === this.negotiationId;
        break;

      case 'rtc-answer':
        correctState = this.signalingState === SignalingState.SENT_OFFER;
        correctNegotiationId = negotiationId === this.negotiationId;
        break;

      case 'rtc-error':
        correctState = true;
        correctNegotiationId = true;
        break;

      default:
        throw new errors.InvalidArgumentError(
          'Unrecognized rtc channel:', channel);
    }

    return correctState && correctNegotiationId;
  }


  _onMessageIceCandidate(negotiationId, iceCandidate) {
    this._log('Got ice candidate:', negotiationId, iceCandidate ? iceCandidate.candidate : null);
    return this._addRemoteIceCandidate(iceCandidate);
  }


  _onMessageReq(
    negotiationId,
    remoteReconnectType,
    requestedLocalStreams,
    isRetry
  ) {
    if(isRetry !== true) {
      isRetry = false;
    }

    this._log('Got req:', negotiationId, remoteReconnectType, requestedLocalStreams);

    return this._processReq(negotiationId, remoteReconnectType, requestedLocalStreams)
      .then(() => {
        if(this.currentReconnectType === ReconnectType.CLOSE) {
          return this._sendClosingAck();
        } else {
          return this._processRequestedLocalStreams(requestedLocalStreams)
            .then(() => this._sendAckOrOffer());
        }
      })
      .catch(error => {
        // Don't handle these in errors with this._onNegotiationError. If we did handle
        // them, we would send a new REQ to the other peer, which will be ignored if the
        // other peer is the offerer, because he is in state SENT_REQ.
        if(error.constructor === errors.EscapePromiseError) {
          return;
        } else if(!isRetry) {
          this._log(error);
          this._setNextReconnectType(ReconnectType.RESTART);
          return this._onMessageReq(
            negotiationId,
            remoteReconnectType,
            requestedLocalStreams,
            true
          );

        } else {
          throw error;
        }
      });
  }


  _onMessageAck(
    negotiationId,
    remoteReconnectType,
    requestedLocalStreams
  ) {
    this._log('Got ack:', negotiationId, remoteReconnectType, requestedLocalStreams);

    return this._processAck(remoteReconnectType, requestedLocalStreams)
      .then(() => {
        if(this.currentReconnectType !== ReconnectType.FALSE) {
          return this._processRequestedLocalStreams(requestedLocalStreams)
            .then(() => this._sendOffer());
        }
      })
      .catch(this._onNegotiationError);
  }


  _onMessageOffer(
    negotiationId,
    offer,
    _a,  // Deprecated, kept to maintain param order
    _b,  // Deprecated, kept to maintain param order
    remoteReconnectType,
    requestedLocalStreams
  ) {
    this._log('Got offer:', negotiationId, remoteReconnectType, requestedLocalStreams);

    let processAckIfReconnectPromise = $q.resolve();
    if(remoteReconnectType != null) {
      processAckIfReconnectPromise = this._processAck(remoteReconnectType, requestedLocalStreams)
        .then(() => this._processRequestedLocalStreams(requestedLocalStreams));
    }

    return processAckIfReconnectPromise
      .then(() => this._processOffer(offer))
      .then(this._sendAnswer)
      .catch(this._onNegotiationError);
  }

  _onMessageAnswer(
    negotiationId,
    answer
  ) {
    this._log('Got answer:', negotiationId);

    return this._processAnswer(answer)
      .catch(this._onNegotiationError);
  }


  _onMessageError(
    negotiationId,
    error
  ) {
    if(error === 'video-compatibility') {
      this._setVideoCompatible(false);
    }
  }



  /*********************
   * Negotiation logic *
   *********************/

  connect(reconnectType) {
    if(this.isLocal) {
      return;
    }
    if(reconnectType != null) {
      this._setNextReconnectType(Math.max(this.nextReconnectType, reconnectType));
    }

    this._connectDebounced();
  }

  // Debounce connects to avoid double rtc-negotiations when adding both audio and video
  _connectDebounced() {
    if(
      this.nextReconnectType === ReconnectType.FALSE
      || this.signaling
      || this.signalingState === SignalingState.CLOSED
    ) {
      return;
    }

    this.negotiationId = this._getUniqueNegotiationId();
    if(!this.rtcPeerConnection) {
      this._log('rtcPeerConnection doesn\'t exist');
      this.nextReconnectType = ReconnectType.RESTART;
    }

    let requestedLocalStreamIds = this.getRemoteStreamIds();

    this._send(
      'rtc-req',
      this.nextReconnectType,
      requestedLocalStreamIds,
      1600 * 900,  // Deprecated, to be deleted after VECT-1131 has been deployed
      1  // Deprecated, to be deleted after VECT-1131 has been deployed
    );

    this.currentReconnectType = this.nextReconnectType;
    this._setNextReconnectType(ReconnectType.FALSE);
    this._setSignalingState(SignalingState.SENT_REQ);
  }


  _finishConnect() {
    this._log('Finish connect');

    // Make sure `this.signaling === false` when we call `this.connect()`
    this._setSignalingState(SignalingState.STABLE);
    this.currentReconnectType = ReconnectType.FALSE;

    this._requestRemoteSetVideoParameters();

    if(this.nextReconnectType !== ReconnectType.FALSE) {
      this.connect();
    }
  }



  _onIceConnectionStateChange() {
    if(!this.rtcPeerConnection) {
      return;
    }

    this._updateState();

    let state = this.rtcPeerConnection.iceConnectionState;
    if(state === 'failed' || state === 'closed') {
      this._log('Ice connection state is', this.rtcPeerConnection.iceConnectionState);

      $timeout.cancel(this.warnConnectionFailedTimeout);
      this.warnConnectionFailedTimeout = null;

      this.connect(ReconnectType.RESTART);
    }
  }

  _onLocalIceCandidate(event) {
    let iceCandidate = event.candidate;

    this._log('Local ice candidate:', iceCandidate ? iceCandidate.candidate : null);
    if(!iceCandidate || !iceCandidate.candidate) {
      this.hasLastLocalIceCandidate = true;
      if(!this.hasFirstLocalIceCandidate) {
        let text = gettextCatalog.getString(
          `
            A browser setting or extension is blocking audio/video communication.
            <a {{ url }}>Get help</a>.
          `,
          { url:
            `href="${this.siteService.getHelpArticle('iceCandidatesBlocked')}" target="_blank"`
          }
        );
        this.noCandidatesWarning = this.notificationService.warning(text, { delay: -1 });
      }
    }

    let iceCandidateInit = null;
    if(iceCandidate) {
      this.hasFirstLocalIceCandidate = true;
      if(this.noCandidatesWarning) {
        this.noCandidatesWarning.cancel();
        this.noCandidatesWarning = null;
      }

      iceCandidateInit = {
        candidate: iceCandidate.candidate,
        sdpMid: iceCandidate.sdpMid,
        sdpMLineIndex: iceCandidate.sdpMLineIndex,
      };
      if(iceCandidate.usernameFragment) {
        iceCandidateInit.usernameFragment = iceCandidate.usernameFragment;
      }
    }

    if(
      this.signalingState === SignalingState.GOT_REQ && this.isOfferer
      || this.signalingState === SignalingState.GOT_ACK
      || this.signalingState === SignalingState.GOT_OFFER
    ) {
      this.iceCandidateQueue.push(iceCandidateInit);
    } else {
      this._sendIceCandidate(iceCandidateInit);
    }
  }


  _addRemoteIceCandidate(iceCandidateInit) {
    return $q.resolve()
      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while adding remote ICE candidate');

        let iceCandidate = null;
        if(iceCandidateInit) {
          iceCandidate = new RTCIceCandidate(iceCandidateInit);
          // Firefox sometimes sends the last candidate as an empty string (not sure when?), but
          // Safari doesn't support this format.
          if(iceCandidate.candidate === '') {
            iceCandidate = null;
          }
        }

        if(iceCandidate && iceCandidate.candidate) {
          this.hasFirstRemoteIceCandidate = true;
        } else {
          this.hasLastRemoteIceCandidate = true;
        }

        return this.rtcPeerConnection.addIceCandidate(iceCandidate);
      })
      .catch(this._onNegotiationError);
  }



  _updateNextReconnectFromRequestedLocalStreams(requestedLocalStreamIds) {
    if(browser.supportsPeerConnectionAddRemoveTrack()) {
      return;
    }

    let diff = this._getRequestedStreamDiff(requestedLocalStreamIds);
    if(diff.remove.length > 0 || diff.add.length > 0) {
      this._log('Need to restart after adding or removing local track');
      this.nextReconnectType = ReconnectType.RESTART;
    }
  }



  _processReq(negotiationId, remoteReconnectType, requestedLocalStreamIds) {
    this._updateNextReconnectFromRequestedLocalStreams(requestedLocalStreamIds);

    return $q.resolve().then(() => {
      let oldSignalingState = this.signalingState;
      this._setSignalingState(SignalingState.GOT_REQ);

      if(oldSignalingState === SignalingState.SENT_REQ) {
        this._log('Signaling state is', oldSignalingState);
        this._setNextReconnectType(this.currentReconnectType);
      } else if(
        oldSignalingState !== SignalingState.CLOSED
        && oldSignalingState !== SignalingState.STABLE
      ) {
        this._log('Signaling state is', oldSignalingState);
        this._setNextReconnectType(ReconnectType.RESTART);
      }

      let requestedRemoteStreams = this.getRemoteStreamIds();
      if(requestedRemoteStreams.length === 0 && requestedLocalStreamIds.length === 0) {
        this._log('No local or remote streams');
        this._setNextReconnectType(ReconnectType.CLOSE);
      } else if(!this.rtcPeerConnection) {
        this._log('rtcPeerConnection doesn\'t exist');
        this._setNextReconnectType(ReconnectType.RESTART);
      }

      this.currentReconnectType = Math.max(this.nextReconnectType, remoteReconnectType);
      this._setNextReconnectType(ReconnectType.FALSE);

      this.negotiationId = negotiationId;
      if(this.currentReconnectType >= ReconnectType.ICE_RESTART) {
        this._setIceNegotiationId(negotiationId);
      }
    });
  }


  _processAck(remoteReconnectType, requestedLocalStreamIds) {
    if(requestedLocalStreamIds) {
      this._updateNextReconnectFromRequestedLocalStreams(requestedLocalStreamIds);
    }

    return $q.resolve().then(() => {
      this._setSignalingState(SignalingState.GOT_ACK);
      this.currentReconnectType = remoteReconnectType;
      if(this.currentReconnectType >= ReconnectType.ICE_RESTART) {
        this._setIceNegotiationId(this.negotiationId);
      }

      if(this.currentReconnectType === ReconnectType.CLOSE) {
        this.close();
        this._finishConnect();
      }
    });
  }


  /**
   * perform business logic when receiving an answer
   *
   * @param {RTCSessionDescription} answer
   * @returns {Promise} a Promise that resolves when the answer is processed
   */
  _processAnswer(answer) {
    this._setSignalingState(SignalingState.GOT_OFFER);
    this._log('Set remote answer', answer);

    return this.rtcPeerConnection.setRemoteDescription(answer)
      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while processing answer');
        this._finishConnect();
      });
  }


  /**
   * perform business logic when receiving an offer
   *
   * @param {RTCSessionDescription} offer
   * @returns {Promise} a Promise that resolves when the offer is processed
   */
  _processOffer(offer) {
    this._setSignalingState(SignalingState.GOT_OFFER);
    this._log('Set remote offer', offer);

    return this.rtcPeerConnection.setRemoteDescription(offer);
  }


  _setIceNegotiationId(negotiationId) {
    this.iceNegotiationId = negotiationId;
    this.hasFirstLocalIceCandidate = false;
    this.hasLastLocalIceCandidate = false;
    this.hasFirstRemoteIceCandidate = false;
    this.hasLastRemoteIceCandidate = false;
    if(this.statsTracker) {
      this.statsTracker.onIceRestart();
    }
    $timeout.cancel(this.keepAliveTimeout);
    this.keepAliveTimeout = null;
  }


  /**
   * create a connection, set the necessary class variables and add the requested stream(s)
   * while removing any obsolete streams.
   *
   * @param {string[]} requestedLocalStreamIds the stream IDs to be added as "active streams"
   * @returns {Promise} a Promise that resolves when the stream has been added
   */
  _processRequestedLocalStreams(requestedLocalStreamIds) {
    let restart = (this.currentReconnectType === ReconnectType.RESTART);
    return this._createRtcPeerConnection(restart)
      .then(() => {
        this._checkRtcPeerConnectionOrEscape('after _createRtcPeerConnection');

        let diff = this._getRequestedStreamDiff(requestedLocalStreamIds);

        return $q.all(diff.remove.map(this._removeLocalStream))
          .then(() => $q.all(diff.add.map(this._addLocalStream)))
          .then(this._updateTransceiverDirection)
          .catch(error => {
            // See https://bugs.webkit.org/show_bug.cgi?id=174327
            // Hopefully we can switch Safari to transceivers soon, but this bug is
            // blocking it: https://bugs.webkit.org/show_bug.cgi?id=184911
            if(browser.isSafari() && error.name === 'InvalidAccessError') {
              logger.info(error);
            } else {
              logger.warn(error);
            }

            this._setNextReconnectType(ReconnectType.RESTART);
          });
      });
  }


  /**
   * Get the streams that need to be added and removed, based on the currently broadcasting streams
   * and the streams the remote peer has requested.
   *
   * @param {number[]} requestedLocalStreamIds - a list of stream IDs
   * @returns {Object} an object with a 'remove' and 'add' property. 'remove' is a
   *                   list of Stream objects that would be removed when adding the stream IDs,
   *                   'add' is a list of Stream objects that would be added when adding the stream
   *                   IDs
   */
  _getRequestedStreamDiff(requestedLocalStreamIds) {
    let requestedLocalStreams = requestedLocalStreamIds
      .map(streamId => this.streamService.getLocal(streamId))
      .filter(stream => !!stream);
    let addedLocalStreams = this.getLocalStreams()
      .map(streamInfo => streamInfo.stream);

    return {
      remove: array.diff(requestedLocalStreams, addedLocalStreams),
      add: array.diff(addedLocalStreams, requestedLocalStreams),
    };
  }


  _updateTransceiverDirection() {
    if(!browser.supportsWebRTCTransceiverDirection()) {
      return;
    }

    // Not setting the transceiver directions explicitly causes bugs when removing and then
    // re-adding tracks.
    Object.values(StreamKind).forEach(kind => {
      let hasLocal = !!this.streams.local[kind];
      let hasRemote = !!this.streams.remote[kind];
      let direction = (
        hasLocal && hasRemote ?
          'sendrecv' :
          hasLocal ?
            'sendonly' :
            hasRemote ?
              'recvonly' :
              'inactive'
      );
      let transceiver = this._getTransceiver(kind);
      if(transceiver) {
        transceiver.direction = direction;
      }
    });
  }


  _sendClosingAck() {
    return $q.resolve().then(() => {
      this._send('rtc-ack', ReconnectType.CLOSE);
      this.close();
      this._finishConnect();
    });
  }

  /**
   * If local has a higher sessionId, send the offer during the handshake.
   * If local has a lower sessionId, an offer from remote is received, so send an
   * ACK instead.
   *
   * @returns {Promise} Promise that resolves after the relevant message is sent
   */
  _sendAckOrOffer() {
    let requestedRemoteStreams = this.getRemoteStreamIds();

    if(this.isOfferer) {
      return this._sendOffer(
        this.currentReconnectType,
        requestedRemoteStreams,
        1600 * 900,  // Deprecated, to be deleted after VECT-1131 has been deployed
        1  // Deprecated, to be deleted after VECT-1131 has been deployed
      );
    } else {
      this._send(
        'rtc-ack',
        this.currentReconnectType,
        requestedRemoteStreams,
        1600 * 900,  // Deprecated, to be deleted after VECT-1131 has been deployed
        1  // Deprecated, to be deleted after VECT-1131 has been deployed
      );
      this._setSignalingState(SignalingState.SENT_ACK);
    }
  }


  /**
   * @param {ReconnectType} reconnectType
   * @param {Stream[]} requestedRemoteStreams
   * @param {number} resolution
   * @param {videoQualtiyService.Quality} quality
   * @returns {Promise} Promise that resolves when the offer has been sent
   */
  _sendOffer(reconnectType, requestedRemoteStreams, resolution, quality) {
    return this._createOffer()
      .then(offer => {
        this._checkRtcPeerConnectionOrEscape('after creating offer');

        let videoBandwidth = this.videoBandwidth.local;
        this._log('sending offer',
          offer, videoBandwidth, reconnectType,
          requestedRemoteStreams, resolution, quality
        );
        this._send(
          'rtc-offer',
          offer,
          500,  // Deprecated, kept to maintain param order
          0,  // Deprecated, kept to maintain param order
          reconnectType,
          requestedRemoteStreams,
          resolution,
          quality
        );
        this._setSignalingState(SignalingState.SENT_OFFER);

        this._sendIceCandidateQueue();
      });
  }


  _sendAnswer() {
    return this._createAnswer()
      .then(answer => {
        this._checkRtcPeerConnectionOrEscape('after createAnswer');

        let videoBandwidth = this.videoBandwidth.local;
        this._log('sending answer', answer, videoBandwidth);
        this._send(
          'rtc-answer',
          answer,
          500  // Deprecated, to be deleted after VECT-1131 is deployed
        );

        this._sendIceCandidateQueue();
        this._finishConnect();
      });
  }


  /**
   * use the rtcPeerConnection to create an RTCSessionDescription to be passed to the peer in
   * the "Offer" step of the webrtc handshake, munge the SDP, set it as the
   * local description and return it.
   *
   * @return {Promise<RTCSessionDescription>} a Promise that returns the Offer when resolved
   */
  _createOffer() {
    return $q.resolve()
      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while setting codec preference');
        return this._setCodecPreferences();
      })

      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while trying to create offer');
        this._log('Create local offer');

        return this.rtcPeerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
          iceRestart: this.currentReconnectType === ReconnectType.ICE_RESTART,
        });
      })

      .then(offer => {
        this._checkRtcPeerConnectionOrEscape('while trying to set offer');
        this._log('Set local offer', offer);

        return this.rtcPeerConnection.setLocalDescription(offer)
          .catch(error => {
            return this._onSetLocalDescriptionError(error);
          });
      })

      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while trying to return offer');
        return this.rtcPeerConnection.localDescription;
      });
  }

  /**
   * create a Session Description to be passed to the peer as the "Answer" step of the webrtc
   * handshake, set it as the local description and return it
   *
   * @return {Promise<RTCSessionDescription>} - a Promise that returns the Answer when resolved
   */
  _createAnswer() {
    return $q.resolve()
      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while setting codec preference');
        return this._setCodecPreferences();
      })

      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while trying to create local answer');
        this._log('Create local answer');
        return this.rtcPeerConnection.createAnswer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });
      })

      .then(answer => {
        this._checkRtcPeerConnectionOrEscape('while trying to set local answer');
        this._log('Set local answer', answer);
        return this.rtcPeerConnection.setLocalDescription(answer)
          .catch(error => {
            return this._onSetLocalDescriptionError(error);
          });
      })

      .then(() => {
        this._checkRtcPeerConnectionOrEscape('while trying to return local answer');
        return this.rtcPeerConnection.localDescription;
      });
  }


  _getRemoteVideoResolution() {
    let streamInfo = this.streams.remote.video;
    if(streamInfo) {
      return streamInfo.stream.displaySize.x * streamInfo.stream.displaySize.y;
    } else {
      return 0;
    }
  }

  _sendIceCandidate(iceCandidate) {
    this._send('rtc-icecandidate', iceCandidate);
  }

  _sendIceCandidateQueue() {
    while(this.iceCandidateQueue.length) {
      let iceCandidate = this.iceCandidateQueue.shift();
      this._sendIceCandidate(iceCandidate);
    }
  }


  _onSetLocalDescriptionError(error) {
    this._log('Set local description error: %s', error);
    // If an error is encountered while setting the local SDP, this may be because of a bug in
    // Android (e.g. see https://stackoverflow.com/questions/69937781, which has a workaround for
    // the native WebRTC library, but not for the browser). As a precaution, we disable setting the
    // codec preferences for all future negotiations.
    this.shouldSetCodecPreferences = false;
    throw error;
  }


  _onNegotiationError(error) {
    if(error.constructor === errors.EscapePromiseError) {
      return;
    }

    if(error.message && error.message.match(VIDEO_INCOMPATIBLE_MESSAGE)) {
      this._log('No video compatibility');
      this._send('rtc-error', 'video-compatibility');
      this._setVideoCompatible(false);
    } else {
      this._log('Negotiation error: %s', error);
    }

    // Retry
    this._setNextReconnectType(ReconnectType.RESTART);
    this._finishConnect();
  }


  _setVideoCompatible(compatible) {
    this.videoCompatibleService.set(this.session, compatible);
  }

  /********************
   * Codec preference *
   ********************/


  /**
   * Set the preferred codecs on the video RTCRtpSender (if it exists).
   *
   * This uses RTCRtpSender.getCapabilities() to get a list of supported codecs,
   * MediaCapabilities.encodingInfo() to test whether they are hardware-accelerated, and
   * CODEC_PREFERENCE to determine their preference.
   *
   * @returns Promise
   */
  _setCodecPreferences() {
    // VECT-2896:
    // If Firefox is the offerer, the handshake fails. Disabling setcodedPreferences fixes
    // this behaviour, but the exact reason why needs to be figured out.
    return;

    // if(!this.shouldSetCodecPreferences) {
    //   return;
    // }

    // let transceiver = this._getTransceiver('video');
    // if(!transceiver && browser.supportsWebRTCTransceivers()) {
    //   transceiver = this.rtcPeerConnection.addTransceiver('video');
    // }
    // if(!transceiver) {
    //   return $q.resolve();
    // }

    // let capabilities = this._getReceiverCapabilities('video');
    // let codecs = capabilities.codecs.slice();
    // return this._getEncodingInfos(codecs).then(codecInfos => {
    //   let sortedCodecs = codecInfos
    //     .filter(item => item.encodingInfo.supported)
    //     .map(item => ({
    //       codec: item.codec,
    //       preference: this._getCodecPreference(item.codec, item.encodingInfo)
    //     }))
    //     .sort((i1, i2) => i1.preference - i2.preference)
    //     .map(item => item.codec);

    //   this._log('Set codec preference:', sortedCodecs);
    //   this._setTransceiverCodecPreferences(transceiver, sortedCodecs);
    // });
  }


  // /**
  //  * Set the prefered codecs on a transceiver. Does nothing if the transceiver does not support
  //  * this.
  //  * @param {RTCRtpTransceiver} transceiver
  //  * @param {RTCRtpCodecCapability[]} codecs
  //  */
  // _setTransceiverCodecPreferences(transceiver, codecs) {
  //   if(transceiver.setCodecPreferences) {
  //     transceiver.setCodecPreferences(codecs);
  //   } else {
  //     this._log('Transceiver does not support setting the codec');
  //   }
  // }


  /**
   * Get the sender capabilities, using a call to RTCRtpSender.getCapabilities(). Returns empty
   * capabilities if this API is not supported by the browser
   *
   * @param {string} kind 'audio' or 'video'
   * @returns {RTCRtpCapabilities}
   */
  _getReceiverCapabilities(kind) {
    if(RTCRtpReceiver.getCapabilities) {
      return RTCRtpReceiver.getCapabilities(kind);
    } else {
      return {
        codecs: [],
        headerExtensions: [],
      };
    }
  }


  /**
   * Get the encoding info for every codec in a list.
   *
   * @param {RTCRtpCodecCapability[]} codecs A list of codecs as returned by
   *  RTCRtpSender.getCapabilities().
   *
   * @returns {Promise<object[]>} A promise fulfilling with an array of objects. Every item in the
   *  array contains the following attributes:
   *  - codec {RTCRtpCodecCapability} An item from the `codecs` array.
   *  - encodingInfo {object} The corresponding encodingInfo, as return by
   *    `MediaCapabilities.encodingInfo()`.
   */
  _getEncodingInfos(codecs) {
    let promises = codecs.map(codec => {
      return this._getEncodingInfo(codec).then(encodingInfo => ({
        codec: codec,
        encodingInfo: encodingInfo,
      }));
    });
    return $q.all(promises);
  }


  /**
   * Get the encoding info of a codec using `MediaCapabilities.encodingInfo()`. Returns dummy info
   * if this API is not supported.
   *
   * @param {RTCRtpCodecCapability} codec
   * @returns {Promise<object>} A promise fulfilling with the encoding info as returned by
   *  MediaCapabilities.encodingInfo(), containing the keys `supported`, `smooth` and
   * `powerEfficient`.
   */
  _getEncodingInfo(codec) {
    if(browser.supportsEncodingInfo()) {
      let config = {
        type: 'webrtc',
        video: {
          contentType: codec.mimeType,
          width: 640,
          height: 360,
          bitrate: 300000,
          framerate: 24,
        },
      };
      return navigator.mediaCapabilities.encodingInfo(config);

    } else {
      return Promise.resolve({
        supported: true,
        smooth: false,
        powerEfficient: false,
      });
    }
  }


  /**
   * Get the preference of using a given codec for encoding.
   * @param {RTCRtpCodecCapability} codec
   * @param {object} encodingInfo As returned by MediaCapabilities.encodingInfo().
   * @returns {number} The preference for this codec. A lower number indicates a higher priority.
   */
  // eslint-disable-next-line no-unused-vars
  _getCodecPreference(codec, encodingInfo) {
    let preference = CODEC_PREFERENCE.findIndex(([mimeType, isAccelerated]) => {
      // We currently don't take HW-accelerated support into account, because the interplay between
      // browsers with different support is tricky. Example:
      // - Chrome without HW-accelerated support for anything
      // - Safari with HW-accelerated support for everything
      // The desired result is: Chrome sends in H.264, Safari sends in VP8. But the actual result
      // is the other way around: Chrome sends in VP8 and Safari sends in H.264. It may be possible
      // to fix this by sending codec support from the sender to the receiver and calling
      // transceiver.setCodecPreferences() there, but I feel like we're spending too much time
      // finding cases where VP8 might be a good idea after all, while defaulting to H.264 in
      // almost all cases is probably fine for now.
      // return mimeType === codec.mimeType && isAccelerated === encodingInfo.powerEfficient;
      return mimeType === codec.mimeType && isAccelerated === false;
    });
    if(preference === -1) {
      preference = CODEC_PREFERENCE.length;
    }
    if(VIDEO_CODEC_OVERRIDE && VIDEO_CODEC_OVERRIDE === codec.mimeType) {
      preference = 0;
    }
    return preference;
  }



  /***************
   * SDP munging *
   ***************/

  /**
   * Scale down the video during "normal" video calls.
   */
  _setVideoParameters(videoQuality) {
    return this.setVideoParametersPromise.then(() => {
      return this._setVideoParametersInner(videoQuality);
    });
  }


  _setVideoParametersInner(videoQuality) {
    let { stream, sender } = (this.streams.local.video || {});
    if(!sender || !sender.setParameters || !sender.track) {
      return $q.resolve();
    }

    let params = this.settingsService.getSenderParameters(sender);
    let bitrateKbps = this.videoBandwidth.local;
    let videoHeight = this.settingsService.getVideoHeight(stream, videoQuality);
    params = this.settingsService.setVideoParameterBandwidth(sender, params, bitrateKbps);
    params = this.settingsService.setVideoParameterHeight(sender, params, videoHeight);

    this._log('Set video parameters:', params.encodings[0]);
    return sender.setParameters(params);
  }



  /**********************
   * Connection quality *
   **********************/

  _onStats(stats) {
    this.stats = stats;
    this._emitStats(stats);
    this._updateSignalStrength(stats);
  }


  _emitStats(stats) {
    Object.keys(this.streams).forEach(location => {
      let direction = location === 'local' ? 'outbound' : 'inbound';
      Object.keys(this.streams[location]).forEach(kind => {
        let streamInfo = this.streams[location][kind];
        if(streamInfo && streamInfo.track) {
          let report = stats[direction][kind];
          this.emit('stats', this, streamInfo.stream, streamInfo.track, report);
        }
      });
    });
  }


  _updateSignalStrength(stats) {
    let signalStrengths = {
      audio: this._calculateSignalStrength(this.streams.remote.audio, stats.inbound.audio),
      video: this._calculateSignalStrength(this.streams.remote.video, stats.inbound.video),
    };
    this._setSignalStrength('inbound', 'audio', signalStrengths.audio);
    this._setSignalStrength('inbound', 'video', signalStrengths.video);
    this._sendSignalStrengthsToPeer(signalStrengths);
  }


  _calculateSignalStrength(streamInfo, report) {
    if(!streamInfo || !streamInfo.track || report.packetsLostPerSecond == null) {
      return SignalStrength.NO_STATS;
    } else if(report.packetsLostPerSecond === 0) {
      return SignalStrength.GOOD;
    } else if(report.packetsLostPerSecond < 4) {
      return SignalStrength.MEDIUM;
    } else {
      return SignalStrength.BAD;
    }
  }


  _setSignalStrength(direction, kind, signalStrength) {
    this.signalStrengthService.add(kind, signalStrength);
    this._checkBadConnectionWithRelayServer(direction, kind, signalStrength);
  }

  /**
   * If the signalstrength is bad, check if it is beceause the connection is using a relay server.
   * @param {string} direction
   * @param {string} kind
   * @param {SignalStrength} signalStrength
   * @returns
   */
  _checkBadConnectionWithRelayServer(direction, kind, signalStrength) {
    if(!this.stats) {
      return;
    }

    let report = this.stats[direction][kind];
    if(
      signalStrength === SignalStrength.BAD
      && report
      && report.transport
      && report.transport.selectedCandidatePair
      && report.transport.selectedCandidatePair.localCandidate
      && report.transport.selectedCandidatePair.localCandidate.candidateType === 'relay'
    ) {
      this.emit('badConnectionWithRelayServer', this);
    }
  }


  _onCandidatePairChanged() {
    if(this.hasLastLocalIceCandidate && this.hasLastRemoteIceCandidate) {
      this._log('Ice candidate pair changed');
      this.connect(ReconnectType.ICE_RESTART);
    }
  }


  _sendSignalStrengthsToPeer(signalStrengths) {
    this._sendData(DataChannel.SIGNAL_STRENGTHS, signalStrengths);
  }

  _onSignalStrengthsFromPeer(signalStrengths) {
    object.forEach(signalStrengths, (kind, signalStrength) => {
      this._setSignalStrength('outbound', kind, signalStrength);
    });
  }



  /*******************
   * Video bandwidth *
   *******************/

  _requestRemoteSetVideoParameters() {
    if(!this.rtcPeerConnection) {
      return;
    }
    this.videoResolution.remote = this._getRemoteVideoResolution();
    this._sendReqVideoParameters();
  }

  _setRemoteVideoBandwidth(bandwidth) {
    this.videoBandwidth.remote = bandwidth;
    this._updateBytesPerSecondTarget();
  }

  _setLocalResolution(resolution, quality) {
    this.videoResolution.local = resolution;
    this.videoBandwidth.local = this.settingsService.getSendBandwidth(resolution, quality);
    this._updateBytesPerSecondTarget();
  }


  _updateBytesPerSecondTarget() {
    if(this.statsTracker) {
      this.statsTracker.setBytesPerSecondTarget('inbound', this.videoBandwidth.remote / 8 * 1000);
      this.statsTracker.setBytesPerSecondTarget('outbound', this.videoBandwidth.local / 8 * 1000);
    }
  }



  _sendReqVideoParameters() {
    let message = {
      resolution: this.videoResolution.remote,
      quality: this.settingsService.videoQuality,
    };
    this._log('req video parameters', message);
    this._sendData(DataChannel.REQ_VIDEO_PARAMETERS, message);
  }

  _onReqVideoParameters(message) {
    this._setLocalResolution(message.resolution, message.quality);
    this._setVideoParameters(message.quality)
      .then(() => {
        this._sendAckVideoParameters();
      })
      .catch(error => {
        // These errors are not critical, since they are only about setting resolution etc.
        // We can continue the call
        logger.info(error);
      });
  }


  _sendAckVideoParameters() {
    let message = {
      bandwidth: this.videoBandwidth.local,
    };
    this._log('ack video parameters', message);
    this._sendData(DataChannel.ACK_VIDEO_PARAMETERS, message);
  }

  _onAckVideoParameters(message) {
    this._setRemoteVideoBandwidth(message.bandwidth);
  }





  /**************
   * Keep alive *
   **************/

  _sendKeepAlive() {
    this._sendData(DataChannel.KEEP_ALIVE, {});
  }


  _onKeepAlive() {
    $timeout.cancel(this.keepAliveTimeout);
    this.keepAliveTimeout = $timeout(this._onKeepAliveTimeout, KEEP_ALIVE_TIMEOUT);
  }


  _onKeepAliveTimeout() {
    this._log('Keep alive timeout after %ss', KEEP_ALIVE_TIMEOUT / 1000);
    if(this.rtcPeerConnection) {
      this.connect(ReconnectType.RESTART);
    }
  }



  /*********************************************
   * Send and receive data to/from remote peer *
   *********************************************/

  _openDataChannels() {
    for(let key in DataChannel) {
      let id = DataChannel[key];
      let dataChannel = this.rtcPeerConnection.createDataChannel('AudioStats', {
        negotiated: true,
        id: id,
      });
      dataChannel.onopen = this._onOpenDataChannel.bind(this, id);
      dataChannel.onmessage = this._onDataChannelMessage.bind(this, id);
      this.dataChannels[id] = dataChannel;
      this.dataQueues[id] = [];
    }
  }

  _closeDataChannels() {
    for(let key in DataChannel) {
      let id = DataChannel[key];
      this.dataChannels[id].onopen = null;
      this.dataChannels[id].onmessage = null;
      this.dataChannels[id].close();
      delete this.dataChannels[id];
    }
  }


  _sendData(dataChannelId, message) {
    let dataChannel = this.dataChannels[dataChannelId];
    if(!dataChannel) {
      return;
    }

    if(dataChannel.readyState === 'open') {
      let data = JSON.stringify(message);
      dataChannel.send(data);

    } else {
      this.dataQueues[dataChannelId].push(message);
    }
  }


  _onOpenDataChannel(dataChannelId) {
    let messages = this.dataQueues[dataChannelId];
    this.dataQueues[dataChannelId] = [];
    messages.forEach(message => {
      this._sendData(dataChannelId, message);
    });
  }


  _onDataChannelMessage(dataChannelId, event) {
    let message = JSON.parse(event.data);
    this[dataHandlers[dataChannelId]](message);
  }


  sendPrivateMessage(message) {
    this._sendData(DataChannel.PRIVATE_MESSAGE, message);
  }
  _onPrivateMessage(message) {
    this.emit('privateMessage', this, message);
  }
}
