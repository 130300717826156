ShowForToolsDirective.$inject = [
  '$animate',
  'toolService',
];


const NG_HIDE_CLASS = 'ng-hide';
const NG_HIDE_IN_PROGRESS_CLASS = 'ng-hide-animate';


export default function ShowForToolsDirective(
  $animate,
  toolService
) {

  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {
      let showForTools = new Set($attr.showForTools.split(/\s+/));

      toolService.on('formatTool', onTool);
      $scope.$on('destroy', () => {
        toolService.off('formatTool', onTool);
      });
      onTool();


      function onTool() {
        let show = showForTools.has(toolService.selected.formatTool);

        $animate[show ? 'removeClass' : 'addClass']($elem, NG_HIDE_CLASS, {
          tempClasses: NG_HIDE_IN_PROGRESS_CLASS
        });
      }
    }
  };
}
