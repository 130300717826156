import EMOJIS from './emojis.json';


export default class emojiController {
  static get $inject() {
    return [
      'emojiService',
    ];
  }


  constructor(
    emojiService
  ) {
    this.emojiService = emojiService;

    this.emojiList = EMOJIS.categories['Smileys & People'];
  }


  addEmoji(str) {
    this.emojiService.dropdownChatCtrl.addToInput(str + ' ');
  }
}
