import { browser } from 'utils/util';


const SHOW_TIMEOUT = 1000;
const HIDE_TIMEOUT = 2000;


export default class PermissionArrowService {
  static get $inject() {
    return [];
  }

  constructor() {
    this._bind();

    this.isShown = false;
    this.position = Object.freeze({
      x: browser.isSafari() || browser.isOpera() ? 'center' : 'left',
      y: 'top',
    });

    this.showTimeout = null;
    this.hideTimeout = null;
  }

  _bind() {
    this._showNow = this._showNow.bind(this);
    this.hide = this.hide.bind(this);
  }


  show() {
    if(this.showTimeout) {
      return;
    }
    // The permission modal on phones is sufficiently clear on its own.
    if(!browser.isDesktop()) {
      return;
    }

    $timeout.cancel(this.hideTimeout);
    this.hideTimeout = null;

    this.showTimeout = $timeout(this._showNow, SHOW_TIMEOUT);
  }


  _showNow() {
    this._cancelTimeouts();
    this.hideTimeout = $timeout(this.hide, HIDE_TIMEOUT);
    this.isShown = true;
  }


  hide() {
    this._cancelTimeouts();
    this.isShown = false;
  }


  _cancelTimeouts() {
    $timeout.cancel(this.showTimeout);
    this.showTimeout = null;

    $timeout.cancel(this.hideTimeout);
    this.hideTimeout = null;
  }
}
