import { Time } from './Time';


export class TimeRange {
  constructor(
    public from: Time,
    public to: Time,
  ) {}


  toRepresentation() {
    return [
      this.from.toRepresentation(),
      this.to.toRepresentation(),
    ];
  }


  static toInternalValue(data) {
    const from = Time.toInternalValue(data[0]);
    const to = Time.toInternalValue(data[1]);
    const timeRange = new TimeRange(from, to);
    return timeRange;
  }
}
