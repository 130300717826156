import { Component, ElementRef, ViewChild } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';
import { ErrorValue } from 'utils/settings/settings.component';


@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent
  extends ContactFormQuestionInputComponent<number> {

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;


  parsePrefill(string: string) {
    const value = parseFloat(string);
    if(isNaN(value)) {
      return null;
    } else {
      return value;
    }
  }


  override validate(): ErrorValue {
    if(this.input.nativeElement.validity.valid) {
      return [];
    } else {
      return [$localize `Please enter a valid number.`];
    }
  }
}
