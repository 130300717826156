import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularjsUpgradedUtilsModule } from 'utils/angularjs-upgraded';
import { ProfileImagesComponent } from './profile-images.component';

@NgModule({
  imports: [
    AngularjsUpgradedUtilsModule,
    CommonModule,
    MatTooltipModule,
  ],
  declarations: [
    ProfileImagesComponent,
  ],
  exports: [
    ProfileImagesComponent,
  ],
})
export class ProfileImagesModule {}
