
import { EventEmitter, platform } from 'utils/util';
import StreamTileMixin from '../../main/streams/StreamTileMixin';


const DEFAULT_MAX_ZOOM_LEVEL = 3;

const PTZ_NATIVE_ASPECT_RATIO = 16 / 9;
const PTZ_HORIZONTAL_FOV = 60;  // Degrees
const PTZ_PAN_RANGE = 270; // Degrees
const PTZ_TILT_RANGE = 120;  // Degrees
// magic number that maps pixel distances to ptz capabilities distances
const PTZ_SCROLL_MULTIPLIER = 0.002;

const ZOOM_MULTIPLIER = 0.008;
const ZOOM_STEP = 80;

const SCROLL_STEP = 80;
const SCROLL_THROTTLE = 333; // pan/tilt max 3 times per second

export const PTZ_MAX_ZOOM_LEVEL = 10;


export default function ViewportTileMixin(Superclass) {
  const Baseclass = StreamTileMixin(Superclass);
  return class ViewportTile extends Baseclass {
    constructor(...args) {
      super(...args);
      EventEmitter.setup(this, ['viewport']);

      // Each offset is a float between 0 and 1 indicating the percentage of offset between the top
      // left corner of the global image and the top left corner of its viewport.
      this.offset = {
        x: 0,
        y: 0,
      };

      // zoomlevel is a float between 1 and DEFAULT_MAX_ZOOM_LEVEL, indicating
      // the inverse of the size of the viewport in comparison to the global image
      //
      // a zoomlevel of 3 indicates that the width of the viewport is 1/3 of the global image
      this.zoomLevel = 1;
      this.maxZoomLevel = DEFAULT_MAX_ZOOM_LEVEL;

      this.offsetSynced = Object.assign({}, this.offset);
      this.zoomLevelSynced = this.zoomLevel;

      this.scrollInterval = null;
      this.scrollControls = Object.freeze({
        'up': this.scrollUp,
        'down': this.scrollDown,
        'left': this.scrollLeft,
        'right': this.scrollRight,
      });
    }


    _setStream(stream, kind) {
      super._setStream(stream, kind);

      if(kind === 'video' && stream.supportsPTZ) {
        this.initPTZViewport(stream);
      }

    }


    _removeStream(kind) {
      if(kind === 'video' && this.streams.video) {
        if(this.streams.video.isLocal) {
          this.setViewport({ x: 0, y: 0 }, 1);
        } else {
          this.setSyncedViewport({ x: 0, y: 0 }, 1);
        }
      }

      super._removeStream(kind);
    }


    get isViewportEnabled() {
      return (
        this.active
        && !this.userService.mySession.isSpectator
        && this.streams.video
      );
    }

    get isViewportDraggable() {
      return (
        this.isViewportEnabled
        && (this.zoomLevel > 1 ||  this.streams.video.supportsPTZ)
      );
    }

    get isPTZEnabled() {
      return (
        this.active
        && !this.userService.mySession.isSpectator
        && this.streams.video
        && this.streams.video.supportsPTZ
      );
    }

    /*****************
     *** Scrolling ***
     *****************/

    scrollToCenter() {
      if(this.isPTZEnabled && this.streams.video) {
        this.centerPTZViewport(this.streams.video);
      } else {
        this.setViewport({ x: 0.5, y: 0.5 }, 1);
      }
    }


    /**
     * repeated calls to a camera to move will be queued, so allow enough time for the camera
     * to perform the command before sending another, to discourage "sticky" behaviour
     *
     * The amount of time that a move takes is impossible to determine, so this there will always
     * be a chance for either choppy movement or overshooting.
     *
     * @param {string} direction a key in the scrollControls dict
     */
    startContinuousScroll(direction) {
      this.stopContinuousScroll();

      let scrollFtion = this.scrollControls[direction];
      scrollFtion.call(this);
      this.scrollInterval = $interval(() => scrollFtion.call(this), SCROLL_THROTTLE);
    }

    stopContinuousScroll() {
      if(this.scrollInterval) {
        $interval.cancel(this.scrollInterval);
      }
    }

    scrollLeft() {
      this.doScroll({ x: SCROLL_STEP, y: 0 });
    }

    scrollRight() {
      this.doScroll({ x: -SCROLL_STEP, y: 0 });
    }

    scrollUp() {
      this.doScroll({ x: 0, y: SCROLL_STEP });
    }

    scrollDown() {
      this.doScroll({ x: 0, y: -SCROLL_STEP });
    }

    doScroll(argDiff, startOffset) {
      if(startOffset == null) {
        startOffset = this.offset;
      }
      let stream = this.streams.video;
      if(!stream) {
        return;
      }

      let diff;
      if(stream.supportsPTZ) {
        let multiplierX = PTZ_SCROLL_MULTIPLIER * this.PTZHorizontalFOV / this.PTZPanRange;
        let multiplierY = PTZ_SCROLL_MULTIPLIER * this.PTZVerticalFOV / this.PTZTiltRange;
        diff = {
          x: argDiff.x / this.zoomLevel * multiplierX,
          y: argDiff.y / this.zoomLevel * multiplierY,
        };
      } else {
        diff = {
          x: argDiff.x / this.rect.width / this.zoomLevel,
          y: argDiff.y / this.rect.height / this.zoomLevel,
        };
      }

      if(this.isViewportMirrored) {
        diff.x = -diff.x;
      }
      let offset = {
        x: startOffset.x - diff.x,
        y: startOffset.y - diff.y,
      };


      this.setOffset(offset);
    }

    /***************
     *** Zooming ***
     ***************/

    zoomIn() {
      this._zoomWithButton(1);
    }

    zoomOut() {
      this._zoomWithButton(-1);
    }

    _zoomWithButton(direction) {
      let diff = direction * ZOOM_STEP;
      let mouseOffset = { x: 0.5, y: 0.5 };
      this.doZoom(diff, mouseOffset);
    }

    /**
     *
     *
     * @param {number} diff - the number of pixels that was scrolled
     * @param {Object} mouseOffset - {x: offset, y: offset}, with offset being a number between
     *   0 and 1, indicating the relative distance from the mouse cursor to the top right corner
     *   of the tile
     */
    doZoom(diff, mouseOffset) {
      let stream = this.streams.video;
      if(!stream) {
        return;
      }

      let zoomFactor = platform(
        1 / this.zoomLevel,
        Math.pow(1.1, diff * ZOOM_MULTIPLIER * this.maxZoomLevel),
        this.maxZoomLevel / this.zoomLevel
      );
      let zoomLevel = this.zoomLevel * zoomFactor;
      let offset;

      if(stream.supportsPTZ) {
        offset = this.offset;
      } else {
        offset = {
          x: this.offset.x + mouseOffset.x / this.zoomLevel * (1 - 1 / zoomFactor),
          y: this.offset.y + mouseOffset.y / this.zoomLevel * (1 - 1 / zoomFactor),
        };
      }

      this.setViewport(offset, zoomLevel);
    }


    initPTZViewport(stream) {
      this.maxZoomLevel = PTZ_MAX_ZOOM_LEVEL;
      if(stream.isLocal) {
        this.centerPTZViewport(stream);
      }
    }

    /**
     * Center the PTZ camera on the "home" position, which is located in the
     * middle of the pan and tilt interval
     *
     * @param {Stream} stream - a ptz-capable videostream
     */
    centerPTZViewport(stream) {
      let cap = stream.trackCapabilities;
      let offset = {
        x: .5,
        y: .5,
      };
      if(cap.pan.min < 0 && cap.pan.max > 0) {
        offset.x = (0 - cap.pan.min) / (cap.pan.max - cap.pan.min);
      }
      if(cap.tilt.min < 0 && cap.tilt.max > 0) {
        offset.y = (0 - cap.tilt.min) / (cap.tilt.max - cap.tilt.min);
      }

      this.setViewport(offset, 1);
    }


    /**
     * constrain the zoomlevel and offsets so that the viewport rectangle is within the
     * edges of the width and height. Also emits the 'viewport' event with
     * this data
     *
     * @param {Object} offset - object containing {x: x-offset, y: y-offset}
     * @param {float} zoomLevel - a float between 1 and max zoom level
     */
    setViewport(offset, zoomLevel) {
      let stream = this.streams.video;
      if(!stream) {
        return;
      }

      zoomLevel = platform(1, zoomLevel, this.maxZoomLevel);
      if(zoomLevel < 1.01) {
        zoomLevel = 1;
      }

      let maxOffset = stream.supportsPTZ ? 1 : 1 - 1 / zoomLevel;
      offset = {
        x: platform(0, offset.x, maxOffset),
        y: platform(0, offset.y, maxOffset),
      };

      this._setValidatedViewport(offset, zoomLevel);
    }


    setSyncedViewport(offset, zoomLevel) {
      Object.assign(this.offsetSynced, offset);
      this.zoomLevelSynced = zoomLevel;
      this._setValidatedViewport(offset, zoomLevel);
    }


    _setValidatedViewport(offset, zoomLevel) {
      Object.assign(this.offset, offset);
      this.zoomLevel = zoomLevel;

      this.emit('viewport', this, this.offset, this.zoomLevel);

      let stream = this.streams.video;
      if(stream && stream.supportsPTZ && stream.isLocal) {
        this._applyPTZConstraints();
      }
    }


    setOffset(offset) {
      this.setViewport(offset, this.zoomLevel);
    }


    _applyPTZConstraints() {
      let stream = this.streams.video;

      let cap = stream.trackCapabilities;
      let constraints = {
        pan: this._getLinearPTZConstraint(this.offset.x, [0, 1], cap.pan),
        tilt: this._getLinearPTZConstraint(this.offset.y, [0, 1], cap.tilt),
        zoom: this._getExponentialPTZConstraint(this.zoomLevel, [1, this.maxZoomLevel], cap.zoom),
      };

      stream.applyPTZConstraints(constraints);
    }


    _getLinearPTZConstraint(value, valueRange, cap) {
      let factor = (cap.max - cap.min) / (valueRange[1] - valueRange[0]);
      let scaledValue = (value - valueRange[0]) * factor + cap.min;
      return this._getPTZConstraint(scaledValue, valueRange, cap);
    }

    _getExponentialPTZConstraint(value, valueRange, cap) {
      let base = Math.pow(valueRange[1] / valueRange[0], 1 / (cap.max - cap.min));
      let factor = 1 / Math.pow(base, cap.min);
      let scaledValue = Math.log(value / factor) / Math.log(base);
      return this._getPTZConstraint(scaledValue, valueRange, cap);
    }

    _getPTZConstraint(value, valueRange, cap) {
      let stepIndex = Math.floor((value - cap.min) / cap.step);
      let stepSetting = stepIndex * cap.step + cap.min;
      let constrainedSetting = platform(cap.min, stepSetting, cap.max);
      return constrainedSetting;
    }


    get PTZHorizontalFOV() {
      return PTZ_HORIZONTAL_FOV / PTZ_NATIVE_ASPECT_RATIO * this.aspectRatio;
    }
    get PTZVerticalFOV() {
      return this.PTZHorizontalFOV / this.aspectRatio;
    }

    get PTZPanRange() {
      return PTZ_PAN_RANGE;
    }
    get PTZTiltRange() {
      return PTZ_TILT_RANGE;
    }
  };
}
