import { array, interval } from 'utils/util';


export default class MainChatSyncService {
  static get $inject() {
    return [
      'userService',
      'meetingBroadcastService',
      'chatBoxService',
    ];
  }

  constructor(
    userService,
    meetingBroadcastService,
    chatBoxService
  ) {
    this._bind();

    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.chatBox = chatBoxService.get('main');

    this.sendTypingInterval = null;


    this.meetingBroadcastService.on('chat-message', this._onBroadcastMessage, true);
    this.meetingBroadcastService.on('chat-typing', this._onBroadcastTyping, false);

    this.userService.on('userJoin', this._onUserJoin);
    this.userService.on('userExit', this._onUserExit);

    this.chatBox.on('message', this._onChatBoxMessage);
    this.chatBox.on('typing', this._onChatBoxTyping);
  }

  _bind() {
    this._onBroadcastMessage = this._onBroadcastMessage.bind(this);
    this._onBroadcastTyping = this._onBroadcastTyping.bind(this);
    this._onUserJoin = this._onUserJoin.bind(this);
    this._onUserExit = this._onUserExit.bind(this);
    this._onChatBoxMessage = this._onChatBoxMessage.bind(this);
    this._onChatBoxTyping = this._onChatBoxTyping.bind(this);
    this._sendTyping = this._sendTyping.bind(this);
  }


  _onBroadcastMessage(channel, session, datetime, conversationId, id, content) {
    if(session.isLocal) {
      this.chatBox.removeMessage(id);
    }
    this.chatBox.addMessage(id, session, content, datetime, true);
  }


  _onBroadcastTyping(channel, session) {
    if(!session.user.isMe) {
      this.chatBox.addTyping(session.user);
    }
  }


  _onUserJoin(user, prevUser) {
    if(prevUser == null) {
      let action;
      if(user.isAlive) {
        /// used as a chat message when a user joins the meeting room: "<Username> joined"
        action = gettextCatalog.getString('joined');
      } else {
        /// used as a chat message when a user knocked on the meeting room: "<Username> knocked"
        action = gettextCatalog.getString('knocked');
      }
      this._logUser(user, action);
    }
  }

  _onUserExit(user, nextUser) {
    if(nextUser == null) {
      /// used as a chat message when a user left the meeting room: "<Username> left"
      let action = gettextCatalog.getString('left');
      this._logUser(user, action);
    }
    this.chatBox.removeTyping(user);
  }

  _logUser(user, action) {
    if(!this.meetingBroadcastService.initializing && !user.isMe) {
      this.chatBox.addSystemMessage(user, action);
    }
  }


  _onChatBoxMessage(message) {
    if(message.synced) {
      return;
    }

    this.meetingBroadcastService.send(
      'chat-message', false, [], 'group',
      message.id, message.rawContent
    );
  }


  _onChatBoxTyping(users) {
    let me = this.userService.me;
    let meIsTyping = array.has(users, me);

    if(meIsTyping && !this.sendTypingInterval) {
      this._sendTyping();
      this.sendTypingInterval = interval.setInterval(this._sendTyping, 4000);
    } else if(!meIsTyping && this.sendTypingInterval) {
      interval.clearInterval(this.sendTypingInterval);
      this.sendTypingInterval = null;
    }
  }

  _sendTyping() {
    this.meetingBroadcastService.send('chat-typing', false, []);
  }
}
