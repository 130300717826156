import {
  Directive, ElementRef, Input, OnChanges, SimpleChanges
} from '@angular/core';


type EventsInput = string | string[];


@Directive({
  selector: '[stopPropagation]',
  exportAs: 'stopPropagation',
})
export class StopPropagationDirective implements OnChanges {
  @Input() stopPropagation!: EventsInput;

  constructor(
    private elementRef: ElementRef,
  ) {}


  ngOnChanges(changes: SimpleChanges) {
    if('stopPropagation' in changes) {
      const previousEvents = this.getEvents(changes.stopPropagation.previousValue);
      const currentEvents = this.getEvents(changes.stopPropagation.currentValue);

      this.unsubscribe(previousEvents);
      this.subscribe(currentEvents);
    }
  }

  private getEvents(events?: EventsInput): string[] {
    if(events == null) {
      return [];
    } else if(typeof events === 'string') {
      return [events];
    } else {
      return events;
    }
  }


  private subscribe(events: string[]) {
    events.forEach(event => {
      this.elementRef.nativeElement.addEventListener(event, this.handleEvent);
    });
  }

  private unsubscribe(events: string[]) {
    events.forEach(event => {
      this.elementRef.nativeElement.removeEventListener(event, this.handleEvent);
    });
  }


  private handleEvent(event: any) {
    event.stopPropagation();
  }
}
