export function diff(a, b) {
  if(a === b) {
    return '';
  }

  let prefix = 0;
  while(a[prefix] === b[prefix]) {
    prefix++;
  }

  let postfix = 0;
  while(
    a[a.length - 1 - postfix] === b[b.length - 1 - postfix]
    && a.length - postfix - prefix > 0
    && b.length - postfix - prefix > 0
  ) {
    postfix++;
  }

  let aShort = a.substring(prefix, a.length - postfix);
  let bShort = b.substring(prefix, b.length - postfix);

  let operations;
  if(aShort.length < 10 || bShort.length < 10 || aShort.length * bShort.length > 5000) {
    operations = getSimpleDiff(aShort, bShort);
  } else {
    operations = getComplexDiff(aShort, bShort);
  }

  for(let i = 0; i < operations.length; i++) {
    operations[i][1] += prefix;
  }

  let diff = operationsToDiff(operations);
  return diff;
}


export function patch(a, diff) {
  let b = '';
  let operations = diffToOperations(diff);
  operations = [['i', 0, '']].concat(operations).concat([['i', a.length, '']]);

  for(let i = 1; i < operations.length; i++) {
    let prevOperation = operations[i - 1];
    let operation = operations[i];

    let insertStart = prevOperation[1];
    let insertEnd = operation[1];
    if(prevOperation[0] === 'r') {
      insertStart += prevOperation[2];
    }
    b += a.substring(insertStart, insertEnd);

    if(operation[0] === 'i') {
      b += operation[2];
    }
  }

  return b;
}




function getComplexDiff(a, b) {
  let lcs = getLongestCommonSubsequence(a, b);
  lcs = lcs.filter(function(sequence) {
    return sequence[2].length > 2;
  });
  lcs = [[0, 0, '']].concat(lcs).concat([[a.length, b.length, '']]);
  let operations = [];

  for(let i = 1; i < lcs.length; i++) {
    let prevSequence = lcs[i - 1];
    let sequence = lcs[i];

    let removeStart = prevSequence[0] + prevSequence[2].length;
    let removeEnd = sequence[0];
    if(removeEnd > removeStart) {
      operations.push(['r', removeStart, removeEnd - removeStart]);
    }

    let insertStart = prevSequence[1] + prevSequence[2].length;
    let insertEnd = sequence[1];
    if(insertEnd > insertStart) {
      operations.push(['i', sequence[0], b.substring(insertStart, insertEnd)]);
    }
  }

  return operations;
}


function getSimpleDiff(a, b) {
  let operations = [];

  if(a.length > 0) {
    operations.push(['r', 0, a.length]);
  }

  if(b.length > 0) {
    operations.push(['i', a.length, b]);
  }

  return operations;
}


function getLongestCommonSubsequence(a, b) {
  let matrix = [];
  for(let i = -1; i < a.length; i++) {
    matrix[i] = new Array(b.length);
    matrix[i][-1] = 0;
  }
  for(let j = -1; j < b.length; j++) {
    matrix[-1][j] = 0;
  }

  for(let i = 0; i < a.length; i++) {
    for(let j = 0; j < b.length; j++) {
      let prefixLcsLength;
      if(a[i] === b[j]) {
        prefixLcsLength = matrix[i - 1][j - 1] + 1;
      } else if(matrix[i - 1][j] >= matrix[i][j - 1]) {
        prefixLcsLength = matrix[i - 1][j];
      } else {
        prefixLcsLength = matrix[i][j - 1];
      }
      matrix[i][j] = prefixLcsLength;
    }
  }

  let i = a.length - 1;
  let j = b.length - 1;
  let newSequence = true;
  let lcs = [];
  while(i > -1 && j > -1) {
    if(matrix[i][j] === matrix[i - 1][j]) {
      i--;
      newSequence = true;

    } else if(matrix[i][j] === matrix[i][j - 1]) {
      j--;
      newSequence = true;

    } else {
      if(newSequence) {
        lcs.unshift([0, 0, '']);
        newSequence = false;
      }
      lcs[0] = [i, j, a[i] + lcs[0][2]];
      i--;
      j--;
    }
  }

  return lcs;
}


function diffToOperations(diff) {
  let diffs = splitDiff(diff);
  let operations = diffs.map(singleDiff => {
    let action = singleDiff[0];

    let firstColonIndex = singleDiff.indexOf(':');
    let start = parseInt(singleDiff.substring(1, firstColonIndex));

    let definition = singleDiff.substring(firstColonIndex + 1);
    if(action === 'r') {
      definition = parseInt(definition);
    }

    return [action, start, definition];
  });
  return operations;
}


function splitDiff(diff) {
  let parts = [''];
  for(let i = 0; i < diff.length; i++) {
    if(diff[i] === ';') {
      parts.push('');

    } else {
      if(diff[i] === '\\') {
        i++;
      }
      parts[parts.length - 1] = parts[parts.length - 1] + diff[i];
    }
  }
  return parts;
}


function operationsToDiff(operations) {
  return operations
    .map(function(operation) {
      let action = operation[0];
      let start = operation[1];
      let definition = operation[2];
      if(action === 'i') {
        definition = definition.replaceAll('\\', '\\\\').replaceAll(';', '\\;');
      }
      return action + start + ':' + definition;
    })
    .join(';');
}
