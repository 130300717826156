import {
  AfterContentInit, Component,
  ContentChildren, QueryList
} from '@angular/core';
import {
  SettingsSegmentState,
  SettingsSegmentComponent
} from './settings-segment.component';


export enum SettingState {
  NOT_READY,
  INVALID,
  VALID,
  PENDING,
}

@Component({
  selector: 'settings-segment-group',
  template: '<ng-content></ng-content>',
})
export class SettingsSegmentGroupComponent implements AfterContentInit {
  @ContentChildren(SettingsSegmentComponent, { descendants: true }) segments!:
    QueryList<SettingsSegmentComponent>;

  ngAfterContentInit(): void {
    this.segments.forEach((segment, index) => {
      segment.stateChange.subscribe(this.onSegmentStateChange.bind(this, segment));
      segment.index = index;
    });
    this.segments.first.select();
  }


  onSegmentStateChange(segment: SettingsSegmentComponent, state: SettingsSegmentState) {
    // When a segment is selected: unselect all other segments
    if(state === SettingsSegmentState.SELECTED) {
      this.segments
        .filter(s => s !== segment)
        .forEach(s => s.unselect());
    }
  }


  selectNextSegment(currentSegment: SettingsSegmentComponent) {
    currentSegment.unselect();
    const currentIndex = currentSegment.index;
    this.segments.get(currentIndex + 1)?.select();
  }
}
