import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ContactForm } from 'contactForm/models/ContactForm';
import { ContactFormQuestion, QuestionType } from 'contactForm/models/ContactFormQuestion';
import { MappingConfig } from 'contactForm/models/MappingConfig';
import {
  CRMEntity, Provider, MappingField
} from 'contactForm/models/MappingField';
import { Errors } from 'utils/settings/settings.component';
import { array, object } from 'utils/util';
import { ContactFormSettingsComponent } from '../contact-form-settings.component';
import { MappingFieldsService, QuestionConfigType } from '../mapping-fields.service';
import { SiteService } from 'utils/site.service';

type State = 'loading' | 'ready';

type InterfaceQuestion = {
  /** An item in `instance.questions`. */
  instance: ContactFormQuestion,
  /**
   * A copy of the question instance, used to store the currently existing question state.
   * if null, this means that the question has not been communicated to the API yet
  */
  APIInstance: ContactFormQuestion | null,
  /** Whether the question is currently expanded for editing. */
  isSelected: boolean,
  errors: Errors,
};


@Component({
  selector: 'contact-form-config-questions[instance]',
  templateUrl: './contact-form-config-questions.component.html',
  styleUrls: ['contact-form-config-questions.component.scss'],
})
export class ContactFormConfigQuestionsComponent
  extends ContactFormSettingsComponent
  implements OnInit, OnChanges {

  public state: State = 'loading';

  /**
   * Deep copy of instance.questions with some added metadata necessary for rendering the
   * interface. Changes are only written back to instance.questions after saving a question.
   */
  public interfaceQuestions: InterfaceQuestion[] = [];
  public defaultQuestions: Set<ContactFormQuestion> = new Set();

  public readonly MappingConfigFieldNames: Record<Provider, string> = {
    [Provider.TEAMLEADER]: 'teamleaderMappingConfig',
    [Provider.VECTERA]: 'vecteraMappingConfig',
  };

  constructor(
    public siteService: SiteService,
    private mappingFieldsService: MappingFieldsService,
    @Inject('modelFactory') private modelFactory,
    @Inject('usageTrackingService') private usageTrackingService,
  ) {
    super();
  }


  override ngOnInit() {
    super.ngOnInit();
    this.init();
  }


  async init() {
    await this.mappingFieldsService.get();
    this.updateMapping();
    this.state = 'ready';
  }


  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if(changes.instance) {
      changes.instance.previousValue && this.removeListeners(changes.instance.previousValue);
      changes.instance.currentValue && this.setupListeners(changes.instance.currentValue);
    }

    if(this.state === 'ready') {
      if(changes.instance) {
        this.updateMapping();
      }
      if(changes.errors) {
        this.updateInterfaceQuestions();
      }
    }
  }


  private setupListeners(instance: ContactForm) {
    Object.values(Provider).forEach((provider: Provider) => {
      const config = instance[this.MappingConfigFieldNames[provider]];
      if(config) {
        this.setupConfigListeners(config);
      } else {
        instance.once(this.MappingConfigFieldNames[provider], this.setupConfigListeners);
      }
    });
  }

  private removeListeners(instance: ContactForm) {
    Object.values(Provider).forEach((provider: Provider) => {
      const config = instance[this.MappingConfigFieldNames[provider]];
      if(config) {
        this.removeConfigListeners(config);
      }
      instance.off(this.MappingConfigFieldNames[provider], this.setupConfigListeners);
    });
  }

  private setupConfigListeners(config: MappingConfig) {
    config.on('createContact createCompany createDeal', this.updateMapping);
  }
  private removeConfigListeners(config: MappingConfig) {
    config.off('createContact createCompany createDeal', this.updateMapping);
  }


  get questions() {
    return this.instance.questions as ContactFormQuestion[];
  }
  set questions(questions: ContactFormQuestion[]) {
    this.instance.questions = questions;
  }

  /**
   * Create `interfaceQuestions` based on the current `instance.questions`.
   */
  private updateInterfaceQuestions() {
    this.interfaceQuestions = this.questions.map((question, i) => {
      const contactFormErrors = this.errors as Errors | undefined;
      const errors = contactFormErrors?.questions?.[i] || {};
      return this.createInterfaceQuestion(question, errors);
    });

    if(this.interfaceQuestions.length === 0 && this.hasPermissionToEdit) {
      this.add();
    }
  }


  /**
   * Update `instance.questions` based on the current `interfaceQuestions`.
   */
  private updateInstance() {
    this.interfaceQuestions.forEach((interfaceQuestion, i) => {
      interfaceQuestion.instance.order = i;
    });

    this.questions = this.interfaceQuestions.map(interfaceQuestion => {
      return interfaceQuestion.instance;
    });
    this.updateMapping();
  }


  get isEditingNewQuestion() {
    return this.interfaceQuestions.some(interfaceQuestion => {
      return interfaceQuestion.isSelected && interfaceQuestion.APIInstance == null;
    });
  }

  hasErrors(interfaceQuestion: InterfaceQuestion) {
    return object.length(interfaceQuestion.errors) > 0;
  }

  shouldWarnAboutDowngrade(interfaceQuestion: InterfaceQuestion) {
    return (
      this.requestUserService.user.organization.subscription.status === 'free'
      && interfaceQuestion.APIInstance
      && !interfaceQuestion.APIInstance.allowFree
    );
  }

  get downgradeTooltip() {
    const proPlanName = this.siteService.site.proPlanName;
    // eslint-disable-next-line max-len
    return $localize `This question uses a ${proPlanName} feature and will not be shown to your end user`;
  }

  add() {
    this.clearSelected();

    const newInstance: ContactFormQuestion = this.modelFactory.createInstance(ContactFormQuestion);
    newInstance.order = this.questions.length;
    this.questions.push(newInstance);
    const interfaceQuestion: InterfaceQuestion = {
      instance: newInstance,
      APIInstance: null,
      errors: {},
      isSelected: true,
    };
    this.interfaceQuestions.push(interfaceQuestion);
    this.track('addedQuestion');
  }

  delete(interfaceQuestion: InterfaceQuestion) {
    array.remove(this.interfaceQuestions, interfaceQuestion);
    this.updateInstance();
  }


  drop($event: CdkDragDrop<InterfaceQuestion[]>) {
    array.move(this.interfaceQuestions, $event.previousIndex, $event.currentIndex);
    this.updateInstance();
  }


  setSelected(interfaceQuestion: InterfaceQuestion) {
    // Calling `confirmEditing` rebuilds the `interfaceQuestions` array, so at the end of this
    // method `interfaceQuestion` may not be in that array anymore. That's why we use the index
    // to locate it instead.
    const index = this.interfaceQuestions.indexOf(interfaceQuestion);

    const selectedQuestion = this.interfaceQuestions.find(ic => ic.isSelected);
    if(selectedQuestion) {
      this.confirmEditing(selectedQuestion);
      if(this.hasErrors(selectedQuestion)) {
        return;
      }
    }

    this.interfaceQuestions[index].isSelected = true;
  }
  clearSelected() {
    this.interfaceQuestions.forEach(ic => ic.isSelected = false);
  }


  cancelEditing(interfaceQuestion: InterfaceQuestion) {
    if(!interfaceQuestion.APIInstance) {
      this.delete(interfaceQuestion);
    } else {
      this.restoreAPIData(interfaceQuestion);
      this.clearSelected();
    }
  }

  confirmEditing(interfaceQuestion: InterfaceQuestion) {
    const errors = this.validate(interfaceQuestion);
    interfaceQuestion.errors = errors;
    if(object.length(errors) > 0) {
      return;
    }

    interfaceQuestion.errors = {};
    this.updateInstance();
    this.clearSelected();
  }


  private validate(interfaceQuestion: InterfaceQuestion): Errors {
    const errors: Errors = {};
    if(!interfaceQuestion.instance.label) {
      errors.label = [$localize `Please choose a label for your question`];
    }

    const isSelectQuestion = array.has(
      [QuestionType.SINGLE_SELECT, QuestionType.MULTI_SELECT],
      interfaceQuestion.instance.type
    );
    if(isSelectQuestion && interfaceQuestion.instance.extra.options?.length === 0) {
      errors.type = [$localize `Please choose at least one option for your question`];
    }

    if(
      this.shouldShowTeamleaderFieldMapping
      && !interfaceQuestion.instance.getMappedFieldId(Provider.TEAMLEADER)
    ) {
      errors.extra = {
        mappedFields: {
          teamleader: [$localize `Please choose a Teamleader Focus field`],
        },
      };
    }

    return errors;
  }


  /**
   * Encapsulate an existing instance in an InterfaceQuestion object
   */
  private createInterfaceQuestion(
    instance: ContactFormQuestion,
    errors: Errors = {}
  ): InterfaceQuestion {
    const APIInstance: ContactFormQuestion =
      this.modelFactory.createInstance(ContactFormQuestion);
    this.copyQuestionData(instance, APIInstance);
    return {
      instance,
      APIInstance,
      errors: errors,
      isSelected: false,
    };
  }

  private restoreAPIData(question: ContactFormQuestion) {
    this.copyQuestionData(question.APIInstance, question.instance);
  }

  private copyQuestionData(from: ContactFormQuestion, to: ContactFormQuestion) {
    Object.keys(from.fields).forEach(field => {
      to[field] = from[field];
      if(from.isDirty(field)) {
        to.markDirty(field);
      }
    });
  }


  /******************
   * Mapping fields *
   ******************/

  private getMappingConfig(provider: Provider) {
    return this.instance[this.MappingConfigFieldNames[provider]];
  }

  private mappingConfigIsEnabled(provider: Provider) {
    return this.getMappingConfig(provider)?.isEnabled;
  }

  private shouldCreate(provider: Provider, entity: CRMEntity) {
    return (
      this.mappingConfigIsEnabled(provider)
      && this.getMappingConfig(provider).shouldCreate(entity)
    );
  }


  get shouldShowTeamleaderFieldMapping() {
    return (
      this.requestUserService.user.organization.hasActiveTeamleaderIntegration
      && this.getMappingConfig(Provider.TEAMLEADER)?.isEnabled
    );
  }

  get teamleaderMappingNonFieldErrors() {
    const contactFormErrors = this.errors as Errors | undefined;
    const questionsErrors = contactFormErrors?.questions as Errors[] | undefined;
    if(questionsErrors) {
      // If there are n questions, the `questionsErrors` array contains n+1 elements. The last
      // element contains errors that are not specific to a single question. See also
      // contact_form.mapping.base:MappingClient.validate_questions_mapping.
      // Question-specific errors are shown inside the respective questions, and ignored here.
      const errors = questionsErrors[questionsErrors.length - 1];
      return errors;
    } else {
      return {};
    }
  }


  private setMappedField(question: ContactFormQuestion, provider: Provider, field: any) {
    if(!question.extra.mappedFields) {
      question.extra.mappedFields = {};
    }
    const mappedField = { id: field.id || field };
    question.extra.mappedFields[provider] = mappedField;
    question.markDirty('extra');
  }

  private unsetMappedField(question: ContactFormQuestion, provider: Provider) {
    if(!question.extra.mappedFields || !question.extra.mappedFields[provider]) {
      return;
    }
    delete question.extra.mappedFields[provider];
    if(Object.keys(question.extra.mappedFields).length === 0) {
      delete question.extra.mappedFields;
    }
    question.markDirty('extra');
  }

  private getMappedQuestion(provider: Provider, fieldId: string) {
    return this.questions.find(q => q.getMappedFieldId(provider) === fieldId);
  }


  private updateMapping() {
    if(!this.mappingFieldsService.mappingFields[Provider.VECTERA]) {
      return;
    }

    Object.values(Provider).forEach(provider => {
      this.removeIllegalMappings(provider);
      if(this.mappingConfigIsEnabled(provider)) {
        this.mapExistingQuestions(provider);
        this.addMissingQuestions(provider);
      }
    });
    // We need a second pass of mapExistingQuestions in order to map non-required fields to
    // questions that were added by a later provider.
    Object.values(Provider).forEach(provider => {
      if(this.mappingConfigIsEnabled(provider)) {
        this.mapExistingQuestions(provider);
      }
    });
    this.mapExistingQuestionsToVectera();
    this.instance.markDirty('questions');

    this.updateInterfaceQuestions();
    this.updateMappingFieldsInUse();
    this.updateDefaultQuestions();
  }


  /**
   * Clean up after the user disables the mapping the an entity: remove all questions that are
   * mapped to disabled entities, unless the questions are still mapped to another provider, in
   * which case the mapping to this provider is removed, but the question is kept.
   *
   * NOTE: we do this because every question MUST be mapped to a Teamleader field. When this
   * constraint is dropped, we will need to revisit the entire logic around disabling a checkbox.
   * This whole implementation feels very fragile, so I hope this will be soon.
   */
  private removeIllegalMappings(provider) {
    this.questions = this.questions.filter(question => {
      const fieldId = question.getMappedFieldId(provider);
      const field = question.getMappedField(provider);
      const mappedProviders = Object.keys(question.extra.mappedFields || {});
      const hasOtherRequiredMapping = mappedProviders
        .filter(p => p !== provider)
        .find(provider => question.getMappedField(provider)?.required);

      const shouldUnset = (
        fieldId && !field
        || field && !this.shouldCreate(provider, field.entity)
      );
      // This condition must be kept in sync with the one in
      // EntityCreationComponent.updateQuestionsToBeRemoved
      const shouldRemove = (
        field
        && !this.shouldCreate(provider, field.entity)
        && (mappedProviders.length === 1 || !hasOtherRequiredMapping)
        // This last condition is only here for backwards compatibility: during the migration of
        // TLF field mapping, if a form had a non-required "company name" question, we disabled
        // the creation of a company for that form, but kept the non-required question. The user
        // will have to fix this situation manually when first editing the form.
        && !(question.isDefault && !question.required)
      );

      if(shouldUnset) {
        this.unsetMappedField(question, provider);
      }
      return !shouldRemove;
    });
  }

  /**
   * Look for questions that are already mapped to other providers, and "piggyback" on these
   * questions by mapping them to the corresponding field for `provider`.
   */
  private mapExistingQuestions(provider: Provider) {
    this.questions
      .filter(question => question.getMappedField(provider) == null)
      .forEach(question => this.mapExistingQuestion(question, provider));
  }

  private mapExistingQuestion(question: ContactFormQuestion, provider: Provider) {
    const field = Object.values(Provider)
      .map(otherProvider => question.getMappedFieldId(otherProvider))
      .filter(fieldId => fieldId != null)
      .map(fieldId => this.mappingFieldsService.getMappingField(provider, fieldId))
      .find(field => field && this.shouldCreate(provider, field.entity));

    if(field) {
      this.setMappedField(question, provider, field);
    }
  }


  /**
   * Handle the special case contact.phone. When any questions of type
   * PHONE_NUMBER exist, we automatically map the first of these to the
   * Vectera contact.phone field.
   */
  private mapExistingQuestionsToVectera() {
    if(!this.mappingConfigIsEnabled(Provider.VECTERA)) {
      return;
    }

    const contactPhoneQuestion = this.getMappedQuestion(Provider.VECTERA, 'contact.phone');
    const phoneQuestion = this.questions.find(q => q.type === QuestionType.PHONE_NUMBER);
    if(!contactPhoneQuestion && phoneQuestion) {
      this.setMappedField(phoneQuestion, Provider.VECTERA, 'contact.phone');
    }
  }


  private addMissingQuestions(provider) {
    Object.values(this.mappingFieldsService.mappingFields[provider])
      .filter(field => field.required && this.shouldCreate(provider, field.entity))
      .filter(field => this.getMappedQuestion(provider, field.id) == null)
      .forEach(field => this.addMissingQuestion(provider, field));
  }

  private addMissingQuestion(provider, field) {
    const question = this.modelFactory.createInstance(ContactFormQuestion, {
      values: {
        label: field.name,
        order: this.questions.length,
        required: true,
        type: field.questionTypes[0],
        extra: {
          mappedFields: {
            [provider]: {
              id: field.id,
            },
          },
        },
      },
    });
    this.questions.push(question);
    this.instance.markDirty('questions');
  }


  private updateDefaultQuestions() {
    this.defaultQuestions = new Set();
    Object.values(Provider).forEach((provider: Provider) => {
      Object.values(this.mappingFieldsService.mappingFields[provider])
        .filter(field => field.required && this.shouldCreate(provider, field.entity))
        .map(field => this.getMappedQuestion(provider, field.id))
        .forEach(field => this.defaultQuestions.add(field.id));
    });
  }


  public track(subEvent) {
    const event = `${this.trackSource}.${subEvent}`;
    this.usageTrackingService.createSegmentEvent(event, this.trackSource);
  }

  public updateMappingFieldsInUse() {
    const mappingFieldsInUse: Record<Provider, Set<MappingField>> = {};
    Object.values(Provider).forEach((provider: Provider) => {
      mappingFieldsInUse[provider] = new Set();
    });

    this.questions.forEach(question => {
      Object.keys(question.extra.mappedFields || {}).forEach((provider) => {
        mappingFieldsInUse[provider].add(question.extra.mappedFields[provider]);
      });
    });

    Object.values(Provider).forEach((provider: Provider) => {
      this.mappingFieldsService.updateMappingFieldsInUse(
        provider, mappingFieldsInUse[provider], QuestionConfigType.BASE
      );
    });
  }

  private get trackSource() {
    if(this.instance.parent) {
      return 'appointmentType';
    } else {
      return 'contactForm';
    }
  }
}
