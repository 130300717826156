import errors from './errors';


export class Time {
  constructor(
    public hours: number,
    public minutes: number
  ) {}

  toRepresentation() {
    const hours = this.hours.toString().padStart(2, '0');
    const minutes = this.minutes.toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  static toInternalValue(data) {
    const match = data.match(/^(\d?\d):(\d?\d)$/);
    if(match) {
      return new Time(parseInt(match[1]), parseInt(match[2]));
    } else {
      throw new errors.InvalidArgumentError(`Invalid time: ${data}`);
    }
  }


  equals(time: Time) {
    return this.hours === time.hours && this.minutes === time.minutes;
  }
}
