import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UpgradeButtonComponent } from './upgrade-button.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UpgradeButtonComponent,
  ],
  exports: [
    UpgradeButtonComponent,
  ],
})
export class UpgradeButtonModule {}
