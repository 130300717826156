import './sessionNotifications';

import BroadcastService from './broadcast.service';
import PrivateMessageService from './privateMessage.service';
import PrivateMessagePeerConnectionsService from './privateMessagePeerConnections.service';
import MeetingReliableSocketService from './meetingReliableSocket.service';
import MeetingSocketService from './meetingSocket.service';


angular
  .module('meeting')
  .service('meetingBroadcastService', BroadcastService)
  .service('privateMessageService', PrivateMessageService)
  .service('privateMessagePeerConnectionsService', PrivateMessagePeerConnectionsService)
  .service('meetingSocketService', MeetingSocketService)
  .service('meetingReliableSocketService', MeetingReliableSocketService);
