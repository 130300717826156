import { NgModule } from '@angular/core';

import { ActiveAudioStreamService } from './active-audio-stream.service';
import { AudioContextService } from './audioContext.service';
import { ReadyToPlayService } from './readyToPlay.service';
import { StreamFactory } from './streamFactory.service';
import { StreamService } from './stream.service';

@NgModule({
  imports: [
  ],
  exports: [
  ],
  providers: [
    ActiveAudioStreamService,
    AudioContextService,
    ReadyToPlayService,
    StreamFactory,
    StreamService,
  ],
})
export class StreamModule {}
