import { EventEmitter } from 'utils/util';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';

const State = Object.freeze({
  DEAD: 'dead',
  KNOCKING: 'knocking',
  ALIVE: 'alive',
  ASLEEP: 'asleep',
});

// This dict should be kept up to date with meeting/models.py and
// websocket-server/src/meetings/util.js. See meeting/models.py for the meaning of the different
// exit reasons.
const ExitReason = Object.freeze({
  EXIT: 'exit',
  ACCESS_DENIED: 'kicked',
  FORCED_ENDED: 'forcedEnded',
  REDIRECT: 'redirect',
  LOST: 'lost',
  STALE_ACCESS_LEVEL: 'accessLevel',
  UNKNOWN: 'unknown',
  SIMULTANEOUS_SESSION: 'sim',
});

const SOFT_EXIT_REASONS = new Set([
  ExitReason.LOST,
  ExitReason.UNKNOWN,
  ExitReason.SIMULTANEOUS_SESSION,
  ExitReason.STALE_ACCESS_LEVEL,
]);


export default class Session {
  static get State() {
    return State;
  }
  static get ExitReason() {
    return ExitReason;
  }
  static get SOFT_EXIT_REASONS() {
    return SOFT_EXIT_REASONS;
  }

  constructor(userService, id) {
    EventEmitter.setup(this, [
      'state',
      'join', 'exit', 'sleep', 'wakeup',
      'knockJoin', 'knockExit', 'knockSleep', 'knockWakeup'
    ]);

    this.userService = userService;
    this._id = id;
    this.isLocal = false;

    this.extra = {};
    this.accessLevel = AccessLevel.SHOULD_KNOCK;

    this.user = null;
    this.stateBeforeSleep = null;
    this.state = Session.State.DEAD;
    this.exitReason = null;

    this.dateKnocked = null;
    this.dateJoined = null;
    this.dateExited = null;
  }

  get id() {
    return this._id;
  }

  get stateWithoutSleep() {
    return this.state === State.ASLEEP ?
      this.stateBeforeSleep :
      this.state;
  }


  setUser(user) {
    this.user = user;
  }

  setAccessLevel(accessLevel) {
    this.accessLevel = accessLevel;
  }


  setState(state) {
    if(state !== this.state) {
      if(state === State.ASLEEP) {
        this.stateBeforeSleep = this.state;
      } else {
        this.stateBeforeSleep = null;
      }
      this.state = state;

      if(this.user) {
        this.user._updateState();
      }
    }
  }


  isDead() {
    return this.state === State.DEAD;
  }

  isKnocking() {
    return this.state === State.KNOCKING;
  }

  isAlive() {
    return this.state === State.ALIVE;
  }

  isAsleep() {
    return this.state === State.ASLEEP;
  }

  isJoined() {
    return (
      this.state === State.ALIVE
      || this.stateBeforeSleep === State.ALIVE
    );
  }

  isSoftExited() {
    return (this.isDead() || this.isAsleep()) && SOFT_EXIT_REASONS.has(this.exitReason);
  }
}
