import { Pipe, PipeTransform } from '@angular/core';
import { SiteService } from 'utils/site.service';

@Pipe({
  name: 'helpArticle'
})
export class HelpArticlePipe implements PipeTransform {
  constructor(public siteService: SiteService) {}

  transform(id: string): string {
    return this.siteService.getHelpArticle(id);
  }
}
