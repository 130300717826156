import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UsageTrackingService {
  constructor() {}

  createSegmentEvent(eventName: string, source: string, properties: object | null = null) {
    window.segmentTrack(eventName, source, properties);
  }
}
