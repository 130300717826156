import WaitingRoomSessionsService from './waitingRoomSessions.service.js';
import WaitingRoomBroadcastService from './waitingRoomBroadcast.service';
import WaitingRoomSocketService from './waitingRoomSocket.service.js';
import WaitingRoomReliableSocketService from './waitingRoomReliableSocket.service.js';
import WaitingRoomNotificationService from './waitingRoomNotification.service.js';

angular
  .module('dashboard')
  .service('waitingRoomBroadcastService', WaitingRoomBroadcastService)
  .service('waitingRoomNotificationService', WaitingRoomNotificationService)
  .service('waitingRoomReliableSocketService', WaitingRoomReliableSocketService)
  .service('waitingRoomSessionsService', WaitingRoomSessionsService)
  .service('waitingRoomSocketService', WaitingRoomSocketService);
