import { bind } from 'utils/util';
import { CloseReason } from 'meeting/angularjs/shared/communication/socket.service';
import Session from 'meeting/angularjs/main/users/Session';


export default class MeetingSyncService {
  static get $inject() {
    return [
      'meetingBroadcastService',
      'meetingService',
      'meetingSocketService',
    ];
  }

  constructor(
    meetingBroadcastService,
    meetingService,
    meetingSocketService
  ) {
    bind(this);

    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.meetingSocketService = meetingSocketService;

    this.meetingService.on(meetingService.MeetingState.ENDED, this.onMeetingEnded);
    this.meetingSocketService.on('close', this.onSocketClose);

    this.meetingBroadcastService.on('_state', this.onState, false);
    this.meetingBroadcastService.on('_meeting-info', this.onMeetingInfo, false);
    this.meetingBroadcastService.on('_session', this.onSessionInfo, false);
  }


  onMeetingEnded() {
    this.meetingSocketService.close(CloseReason.CLIENT_LEAVING);
  }

  onSocketClose(closeReason) {
    let meetingEndedExitReasons = {
      [CloseReason.ACCESS_DENIED]: Session.ExitReason.ACCESS_DENIED,
      [CloseReason.MEETING_RESET]: Session.ExitReason.FORCED_ENDED,
      [CloseReason.FORCED_ENDED]: Session.ExitReason.FORCED_ENDED,
      [CloseReason.EXITED]: Session.ExitReason.EXIT,
    };
    let exitReason = meetingEndedExitReasons[closeReason];

    if(exitReason) {
      this.meetingService.setState(this.meetingService.MeetingState.ENDED, exitReason);
    } else if(this.meetingService.state !== this.meetingService.MeetingState.ENDED) {
      this.meetingService.state = this.meetingService.MeetingState.EXITED;
    }
  }


  onState(state) {
    this.meetingService.state = state;
  }

  onMeetingInfo(info) {
    this.meetingService.setInfo(info);
  }
  onSessionInfo(info) {
    this.onMeetingInfo(info.meeting);
  }
}
