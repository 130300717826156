import { platform, Rect } from 'utils/util';
import { getLayout, getFlexibleLayout } from './util';
import Tile from '../Tile';
import FloatingTile from './FloatingTile';


const TILE_MARGIN = 3;

const FLOATING_TILE_MIN_VISIBLE = 60;
const FLOATING_TILE_SNAP = 15;
const FLOATING_TILE_ASPECT_RATIO = 1.5;


export default class TileRendererPhone {
  static get $inject() {
    return [
      'browserService',
      'meetingService',
      'tileService',
    ];
  }

  constructor(
    browserService,
    meetingService,
    tileService
  ) {
    this._bind();

    this.browserService = browserService;
    this.meetingService = meetingService;
    this.tileService = tileService;

    this.$elem = null;

    this.floatingTile = new FloatingTile('floating');

    this.rect = new Rect({
      top: 0,
      left: 0,
      width: 100,  // Arbitrary
      height: 100,  // Arbitrary
    });
    /**
     * The rect in which content should be drawn, relative to this.$elem. The area outside this
     * rect is used for the header and footer.
     */
    this.interfaceSize = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };

    /**
     * When you set the floating tile position by dragging it, we store that position in
     * floatingTileRectBeforeResize, and we store the rect of the parent in rectBeforeResize.
     * During rendering, we calculate the current position of the floating tile transforming it
     * according to the difference between rect and rectBeforeResize. This way the floating tiles
     * keeps a logical position even after resizing the screen / toggling the interface.
     */
    this.floatingTileRectBeforeResize = new Rect({
      top: this.rect.width - TILE_MARGIN,
      left: this.rect.height - TILE_MARGIN,
      width: 0,
      height: 0,
    });
    this.rectBeforeResize = this.rect.clone();


    browserService.on('deviceType', this._draw);
    tileService.on('draw', this._draw);
    window.addEventListener('resize', () => {
      // requestAnimationFrame is necessary on mobile Safari: otherwise the old window size is
      // reported
      requestAnimationFrame(this._onWindowResize);
    });
  }

  _bind() {
    this._onWindowResize = this._onWindowResize.bind(this);
    this._draw = this._draw.bind(this);
    this._sortPeopleTiles = this._sortPeopleTiles.bind(this);
  }


  get activeContentTile() {
    // Bednet: the laptop that is placed inside the classroom should always show the pupil tile
    // fullscreen. Never show content tiles.
    if(this.meetingService.bednetLocation === this.meetingService.BednetLocation.CLASSROOM) {
      return null;
    } else {
      return this.tileService.activeContentTile;
    }
  }

  get activeTiles() {
    if(this.activeContentTile) {
      return [this.activeContentTile];
    } else {
      return Object.values(this.tileService.tiles)
        .filter(tile => (
          tile.type === Tile.Type.USER
          && !tile.user.isMe
          && !(
            this.meetingService.bednetLocation === this.meetingService.BednetLocation.CLASSROOM
            && (
              tile.user.extra.bednet_location === this.meetingService.BednetLocation.TEACHER
            )
          )
        ));
    }
  }



  setElem($elem) {
    this.$elem = $elem;
    this._onWindowResize();
  }


  setFloatingTilePosition(position) {
    let tileRect = this._getFloatingTileRect();
    tileRect = tileRect.set(position);
    tileRect = this._snapFloatingTileRect(tileRect, this.rect);

    this.floatingTileRectBeforeResize = tileRect;
    this.rectBeforeResize = this.rect;

    this._drawFloatingTile();
  }


  setInterfaceSize(interfaceSize) {
    this.interfaceSize = interfaceSize;
    this._onWindowResize();
  }


  _onWindowResize() {
    if(!this.$elem) {
      return;
    }

    let rectElem = Rect.fromElem(this.$elem[0]);
    this.rect = new Rect({
      top:    this.interfaceSize.top,
      left:   this.interfaceSize.left,
      width:  rectElem.width  - (this.interfaceSize.left + this.interfaceSize.right ),
      height: rectElem.height - (this.interfaceSize.top  + this.interfaceSize.bottom),
    });
    this._draw();
  }



  _draw() {
    if(
      !this.$elem
      || this.browserService.deviceType !== this.browserService.DeviceType.PHONE
      || this.rect.width <= 0
      || this.rect.height <= 0
    ) {
      return;
    }

    Object.values(this.tileService.peopleTiles).forEach(tile => {
      tile.isCollapsed = false;
    });


    if(this.activeContentTile) {
      if(this.activeContentTile.type === Tile.Type.WHITEBOARD) {
        this._drawWhiteboardTile();
      } else {
        this._drawScreenTile();
      }
    } else {
      this._drawRemoteUserTiles();
    }
    this._drawFloatingTile();
  }


  _drawFloatingTile() {
    if(!this.$elem) {
      return;
    }

    let layout = this._getFloatingTileLayout();
    this.floatingTile.setLayout(layout);

    layout.tiles.forEach(tileInfo => {
      tileInfo.tile.isEmbeddedInFloatingTile = true;
      tileInfo.tile.setActive(false);
    });

    this.floatingTile.scrollInactive = 0;
    this.floatingTile.draw(this._getFloatingTileRect());
  }


  _getFloatingTileLayout() {
    let tiles = Object.values(this.tileService.peopleTiles)
      .filter(tile => tile.type === Tile.Type.USER);
    if(!this.activeContentTile) {
      tiles = tiles.filter(tile => tile.user.isMe);
    }
    tiles = tiles.sort(this._sortPeopleTiles);
    let maxSize = this._getFloatingTileMaxSize();

    let layout = getFlexibleLayout(tiles, maxSize, FLOATING_TILE_ASPECT_RATIO);
    return layout;
  }


  _getFloatingTileMaxSize() {
    let rectViewport = Rect.fromViewport();
    let minViewportSize = Math.min(rectViewport.width, rectViewport.height);
    let maxTileSize = 100 + minViewportSize * .2;
    return maxTileSize - 2 * this.floatingTile.borderWidth;
  }


  _getFloatingTileRect() {
    if(this.rect.width <= 0 || this.rect.height <= 0) {
      return this.floatingTileRectBeforeResize;
    }

    // Transform the tile position in case the viewport or tile aspect ratio have changed
    let rectA = this.rectBeforeResize;
    let rectFloatingA = this.floatingTileRectBeforeResize;
    let rectB = this.rect;
    let rectFloatingB = new Rect({
      width: this.floatingTile.layout.rect.width,
      height: this.floatingTile.layout.rect.height,
    });

    // Maintain snap if floating tile size has changed
    if(Math.abs(rectFloatingA.left - (rectA.left + TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectFloatingB.left = rectB.left + TILE_MARGIN;
    } else if(Math.abs(rectFloatingA.right - (rectA.right - TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectFloatingB.left = (rectB.right - TILE_MARGIN) - rectFloatingB.width;
    } else {
      let scale = (rectB.width - rectFloatingB.width) / (rectA.width - rectFloatingA.width);
      rectFloatingB.left = rectB.left + (rectFloatingA.left - rectA.left) * scale;
    }

    if(Math.abs(rectFloatingA.top - (rectA.top + TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectFloatingB.top = rectB.top + TILE_MARGIN;
    } else if(Math.abs(rectFloatingA.bottom - (rectA.bottom - TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectFloatingB.top = (rectB.bottom - TILE_MARGIN) - rectFloatingB.height;
    } else {
      let scale = (rectB.height - rectFloatingB.height) / (rectA.height - rectFloatingA.height);
      rectFloatingB.top = rectB.top + (rectFloatingA.top - rectA.top) * scale;
    }

    return this._snapFloatingTileRect(rectFloatingB, rectB);
  }


  _snapFloatingTileRect(argRectTile, rect) {
    let rectTile = argRectTile.clone();
    // Bound the tile to the visible area
    rectTile.left = platform(
      rect.left + FLOATING_TILE_MIN_VISIBLE - rectTile.width,
      rectTile.left,
      rect.right - FLOATING_TILE_MIN_VISIBLE
    );
    rectTile.top = platform(
      rect.top + FLOATING_TILE_MIN_VISIBLE - rectTile.height,
      rectTile.top,
      rect.bottom - FLOATING_TILE_MIN_VISIBLE
    );

    if(Math.abs(rectTile.left - (rect.left + TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectTile.left = rect.left + TILE_MARGIN;
    } else if(Math.abs(rectTile.right - (rect.right - TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectTile.left = (rect.right - TILE_MARGIN) - rectTile.width;
    }

    if(Math.abs(rectTile.top - (rect.top + TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectTile.top = rect.top + TILE_MARGIN;
    } else if(Math.abs(rectTile.bottom - (rect.bottom - TILE_MARGIN)) < FLOATING_TILE_SNAP) {
      rectTile.top = (rect.bottom - TILE_MARGIN) - rectTile.height;
    }

    return rectTile;
  }


  _drawWhiteboardTile() {
    let tile = this.activeContentTile;
    let rect = new Rect({
      top:    this.rect.top    + TILE_MARGIN,
      left:   this.rect.left   + TILE_MARGIN,
      right:  this.rect.right  - TILE_MARGIN,
      bottom: this.rect.bottom - TILE_MARGIN,
    });
    tile.draw(rect);
  }


  _drawScreenTile() {
    let tile = this.activeContentTile;

    let availWidth = this.rect.width - TILE_MARGIN * 2;
    let availHeight = this.rect.height - TILE_MARGIN * 2;

    let width = Math.min(
      availWidth,
      (availHeight - tile.headerHeight) * tile.aspectRatio + tile.sidebarWidth);
    let height = (width - tile.sidebarWidth) / tile.aspectRatio + tile.headerHeight;
    let left = this.rect.left + TILE_MARGIN + Math.floor((availWidth  - width ) / 2);
    let top  = this.rect.top  + TILE_MARGIN + Math.floor((availHeight - height) / 2);

    let rect = new Rect({
      left: left,
      top: top,
      width: width,
      height: height,
    });
    tile.draw(rect);
  }



  _drawRemoteUserTiles() {
    let tiles = this.activeTiles.sort(this._sortPeopleTiles);
    tiles.forEach(tile => {
      tile.isEmbeddedInFloatingTile = false;
      tile.setActive(true);
    });
    let layout = getLayout(tiles, this.rect, TILE_MARGIN);
    layout.tiles.forEach(({ tile, rect }) => {
      tile.draw(rect);
    });
  }


  _sortPeopleTiles(t1, t2) {
    if(t1.user.isMe) {
      return 1;
    } else if(t2.user.isMe) {
      return -1;
    } else {
      return t1.aspectRatio - t2.aspectRatio;
    }
  }
}
