import { browser, interval, logger, object } from 'utils/util';
import { StreamKind, StreamState } from  'meeting/meeting-room/stream';


const WATCH_ASPECT_RATIO_INTERVAL = 1000;
const ASLEEP_TIMEOUT = 5000;
const CONNECTING_TIMEOUT = 5000;

let shouldShowStats = false;

/**
 * Used to display stats on the tile. Each entry has 3 elements:
 * - The entry label.
 * - A filter: null if the entry applies to all streams, 'audio' or 'video' if then entry applies
 *   only to one of both kinds.
 * - A method that build the entry value for a stream.
 */
/* eslint-disable max-len */
const STATS = [
  ['Bitrate', null, stats => `${Math.round(stats.bytesPerSecond * 8 / 1000)} Kbps`],
  ['Target bitrate', 'video', stats => stats.bytesPerSecondTarget != null ? `${Math.round(stats.bytesPerSecondTarget * 8 / 1000)} Kbps` : ''],
  ['Packets lost', null, stats => stats.packetsLostPerSecond != null ? `${stats.packetsLostPerSecond}/s` : ''],
  ['Resolution', 'video', stats => stats.frameWidth != null ? `${stats.frameWidth}x${stats.frameHeight}` : ''],
  ['Framerate', 'video', stats => stats.framesPerSecond != null ? `${stats.framesPerSecond} fps` : ''],
  ['Codec', null, stats => stats.codec ? stats.codec.mimeType.replace(/[^/]+\//, '') : ''],
  ['Audio level', 'audio', stats => stats.audioLevel != null ? Number.parseFloat(stats.audioLevel).toFixed(3) : ''],
  ['Mode', null, stats => stats.peerConnectionMode],
  ['Local candidate', null, stats => stats.transport && stats.transport.selectedCandidatePair && stats.transport.selectedCandidatePair.localCandidate ? stats.transport.selectedCandidatePair.localCandidate.candidateType : ''],
  ['Remote candidate', null, stats => stats.transport && stats.transport.selectedCandidatePair && stats.transport.selectedCandidatePair.remoteCandidate ? stats.transport.selectedCandidatePair.remoteCandidate.candidateType : ''],
];
/* eslint-enable max-len */


export default function StreamTileMixin(Superclass) {
  return class StreamTile extends Superclass {
    static get $inject() {
      return [
        'contextMenuService',
        'settingsService'
      ].concat(Superclass.$inject);
    }
    constructor(id, aspectRatio, contextMenuService, settingsService, ...args) {
      super(id, ...args);
      this.contextMenuService = contextMenuService;
      this.settingsService = settingsService;

      this.aspectRatio = aspectRatio;
      this.defaultAspectRatio = aspectRatio;

      this.session = null;
      this.streams = {
        audio: null,
        video: null,
      };

      this.asleep = false;
      this.asleepTimeout = null;

      this.connections = new Set();
      this.connecting = false;
      this.connectingTimeout = null;

      this.webRTCError = browser.supportsWebRTC() ?
        null :
        browser.errorMessages.webRTC();
    }

    _bind() {
      super._bind();

      this._onSessionState = this._onSessionState.bind(this);
      this._updateConnecting = this._updateConnecting.bind(this);
      this._onVideoState = this._onVideoState.bind(this);
      this._watchAspectRatio = this._watchAspectRatio.bind(this);
      this._onContextMenu = this._onContextMenu.bind(this);
      this.toggleStats = this.toggleStats.bind(this);
    }

    destroy() {
      super.destroy();

      this.removeAudio();
      this.removeVideo();
    }


    get user() {
      return this.session && this.session.user;
    }

    get sourceId() {
      return this.streams.video && this.streams.video.sourceId;
    }

    _streamIsActive(kind) {
      return !!(
        this.streams[kind]
        && this.streams[kind].enabled
        && this.streams[kind].requested
      );
    }
    get videoActive() {
      return this._streamIsActive(StreamKind.VIDEO);
    }
    get audioActive() {
      return this._streamIsActive(StreamKind.AUDIO);
    }

    get showConnectingMessage() {
      return this.rect && this.rect.width > 140 && this.rect.height > 100;
    }


    get aspectRatioInactive() {
      return this.aspectRatio;
    }
    get aspectRatioActive() {
      return this.aspectRatio;
    }


    setElem($elem) {
      if(this.$elem) {
        this.$elem.off('contextmenu', this._onContextMenu);
      }
      $elem.on('contextmenu', this._onContextMenu);

      super.setElem($elem);
      if(this.streams.video) {
        this._updateWatchRatioInterval();
        this._onVideoState();
      }
    }


    draw(...args) {
      super.draw(...args);
      this._setVideoDisplaySize();
    }


    _setVideoDisplaySize() {
      if(this.streams.video && this.rect) {
        this.streams.video.setDisplaySize({
          x: this.rect.width,
          y: this.rect.height,
        });
      }
    }


    _onSessionState() {
      let asleep = !this.session.isLocal && this.session.isAsleep();
      if(!asleep) {
        this._setAsleep(false);

      } else if(asleep && !this.asleepTimeout) {
        this.asleepTimeout = $timeout(() => {
          this._setAsleep(true);
        }, ASLEEP_TIMEOUT);
      }
    }


    _setAsleep(asleep) {
      $timeout.cancel(this.asleepTimeout);
      this.asleepTimeout = null;
      this.asleep = asleep;
    }


    takeMeetingSnapshot(maxDimension) {
      if(!this.streams.video) {
        return $q.resolve();
      }

      let name = gettextCatalog.getString(
        '{{ username }}\'s screen',
        { username: this.user.shortName }
      );
      let width = this.aspectRatio > 1 ? maxDimension : maxDimension * this.aspectRatio;
      return this.streams.video.takeSnapshot(null, null, null, width)
        .then(blob => {
          return {
            name: name,
            blob: blob,
          };
        });
    }


    /**************************
     * Set and remove streams *
     **************************/

    _setStream(stream, kind) {
      this._removeStream(kind);
      this.streams[kind] = stream;
      stream.on('state', this._updateConnecting);
      this._updateConnecting();
      this._logStreams();
    }

    _removeStream(kind) {
      let stream = this.streams[kind];
      if(stream) {
        this.streams[kind] = null;
        stream.off('state', this._updateConnecting);
        this._updateConnecting();
        this._logStreams();
      }
    }

    _logStreams() {
      let streamIds = Object.values(this.streams)
        .filter(angular.identity)
        .map(stream => stream.id);
      logger.info(`Tile ${this.id} has streams ${streamIds.join(', ')}`);
    }


    setAudio(stream) {
      if(stream === this.streams.audio) {
        return;
      }
      this._setStream(stream, 'audio');
    }

    removeAudio() {
      this._removeStream('audio');
    }


    setVideo(stream) {
      if(stream === this.streams.video) {
        return;
      }

      this.removeVideo(false);
      this._setStream(stream, 'video');


      stream.on('state enabled', this._onVideoState);
      this._onVideoState();

      stream.on('loadeddata', this._watchAspectRatio);
      this._updateWatchRatioInterval();

      this.session = stream.session;
      this.session.on('state', this._onSessionState);
      this._onSessionState();

      this._setVideoDisplaySize();
    }

    removeVideo(emitChange) {
      if(emitChange == null) {
        emitChange = true;
      }

      let stream = this.streams.video;
      if(!stream) {
        return;
      }


      stream.off('state enabled', this._onVideoState);
      this._onVideoState();

      stream.off('loadeddata', this._watchAspectRatio);
      this._updateWatchRatioInterval();

      this._removeStream('video');

      this.session.off('state', this._onSessionState);
      this._onSessionState();
      this.session = null;

      if(emitChange) {
        this.emit('change', this);
      }
    }


    _updateWatchRatioInterval() {
      if(this.$elem && this.streams.video) {
        this.watchAspectRatioInterval = interval.setInterval(
          this._watchAspectRatio, WATCH_ASPECT_RATIO_INTERVAL);
      } else {
        interval.clearInterval(this.watchAspectRatioInterval);
      }
    }


    _updateConnecting() {
      let connecting = Object.values(this.streams)
        .some(stream => (
          stream
          && (
            stream.state === StreamState.REQUESTED
            || stream.state === StreamState.CONNECTING
          )
        ));

      if(!connecting) {
        this._setConnecting(false);

      } else if(!this.connectingTimeout) {
        this.connectingTimeout = $timeout(
          this._setConnecting.bind(this, true),
          CONNECTING_TIMEOUT
        );
      }
    }

    _setConnecting(connecting) {
      this.connecting = connecting;
      $timeout.cancel(this.connectingTimeout);
      this.connectingTimeout = null;
    }


    /**********************
     * Statistics overlay *
     **********************/

    _onContextMenu($event) {
      if(this.hasStats) {
        let label;
        if(shouldShowStats) {
          label = gettextCatalog.getString('Hide stats');
        } else {
          label = gettextCatalog.getString('Show stats');
        }
        let items = [
          {
            label: label,
            callback: this.toggleStats
          },
        ];
        this.contextMenuService.show(items, $event);
      }
    }


    toggleStats(shouldShow) {
      if(shouldShow == null) {
        shouldShow = !shouldShowStats;
      }
      shouldShowStats = shouldShow;
    }

    get hasStats() {
      return this.session && !this.session.isLocal;
    }

    get shouldShowStats() {
      return (
        shouldShowStats
        && this.active
        && this.videoActive
        && this.hasStats
      );
    }

    get shouldShowIncomingVideoDisabledWarning() {
      return (
        this.settingsService.disableIncomingVideo
        && !this.user.isMe
        && !this.webRTCError
      );
    }

    reEnableVideo() {
      this.settingsService.setDisableIncomingVideo(false);
    }

    get stats() {
      let rows = STATS.map(([label, filter, fn]) => {
        let stats = object.map({ video: null, audio: null }, kind => {
          let value = '';
          if(
            this.streams[kind]
            && this.streams[kind].stats
            && (filter == null || filter === kind)
          ) {
            value = fn(this.streams[kind].stats);
          }
          return [kind, value];
        });
        return `
          <tr>
            <th>${label}</th>
            <td>${stats.video}</td>
            <td>${stats.audio}</td>
          </tr>
        `;
      });
      let body = rows.join('');

      return `
        <table class="tile__stats-table">
          <tr>
            <th></th>
            <th>Video</th>
            <th>Audio</th>
          </tr>
          ${body}
        </table>
      `;
    }


    /**********************************************
     * Emit change events on video stream updates *
     **********************************************/

    _onVideoState() {
      this._updateAspectRatio(true);
    }

    _watchAspectRatio() {
      this._updateAspectRatio(false);
    }

    _updateAspectRatio(forceEmitChange) {
      if(!this.$elem) {
        return;
      }

      let aspectRatio =
        this.streams.video && this.streams.video.getAspectRatio() || this.defaultAspectRatio;
      this.setAspectRatio(aspectRatio, forceEmitChange);
    }


    setAspectRatio(aspectRatio, forceEmitChange) {
      if(forceEmitChange == null) {
        forceEmitChange = false;
      }

      if(aspectRatio !== this.aspectRatio || forceEmitChange) {
        this.aspectRatio = aspectRatio;
        this.emit('change', this);
      }
    }
  };
}
