import { bind, EventEmitter, errors } from 'utils/util';
import PeerConnectionP2P from './PeerConnectionP2P';


const PRIVATE_MESSAGE_CHANNELS = Object.freeze([
  'rtc-icecandidate',
  'rtc-req',
  'rtc-offer',
  'rtc-answer',
  'rtc-ack',
  'rtc-error',
]);


export default class p2p2HandleManager {
  static get $inject() {
    return [
      'userService',
      'privateMessageService',
      'notificationService',
      'streamService',
      'rtcConfigurationService',
      'settingsService',
      'siteService',
      'videoCompatibleService',
      'signalStrengthService',
      'meetingService',
    ];
  }
  constructor(
    userService,
    privateMessageService,
    notificationService,
    streamService,
    rtcConfigurationService,
    settingsService,
    siteService,
    videoCompatibleService,
    signalStrengthService,
    meetingService
  ) {
    bind(this);
    EventEmitter.setup(this, ['add', 'track', 'connected', 'stats']);

    this.userService = userService;
    this.privateMessageService = privateMessageService;
    this.notificationService = notificationService;
    this.streamService = streamService;
    this.rtcConfigurationService = rtcConfigurationService;
    this.settingsService = settingsService;
    this.siteService = siteService;
    this.videoCompatibleService = videoCompatibleService;
    this.signalStrengthService = signalStrengthService;
    this.meetingService = meetingService;

    this.initialized = false;
    this.peerConnections = {};
    this.messageQueue = [];

    PRIVATE_MESSAGE_CHANNELS.forEach(event => {
      this.privateMessageService.on(event, this._onMessage);
    });

    this.hasShownTURNWarning = false;
  }


  get mode() {
    return 'p2p';
  }
  get mediaserverUrl() {
    return '';
  }



  init() {
    if(this.initialized) {
      return;
    }

    this.initialized = true;
    this._processMessageQueue();
  }


  destroy() {
    if(!this.initialized) {
      return;
    }

    Object.values(this.peerConnections).forEach(sessionPeerConnections => {
      sessionPeerConnections.forEach(peerConnection => {
        peerConnection.destroy();
      });
    });
    this.peerConnections = {};
    this.initialized = false;
  }



  doIceRestart() {
    Object.values(this.peerConnections).forEach(sessionPeerConnections => {
      sessionPeerConnections.forEach(peerConnection => {
        peerConnection.connect(PeerConnectionP2P.ReconnectType.ICE_RESTART);
      });
    });
  }


  getSessionPeerConnections(session) {
    if(!this.peerConnections[session.id]) {
      this.peerConnections[session.id] = [];
    }
    return this.peerConnections[session.id];
  }


  _getOrCreatePeerConnection(session, connectionId) {
    let peerConnections = this.getSessionPeerConnections(session);
    if(connectionId == null) {
      connectionId = peerConnections.length;
    }

    for(
      let newConnectionId = peerConnections.length;
      newConnectionId <= connectionId;
      newConnectionId++
    ) {
      let peerConnection = new PeerConnectionP2P(
        this.userService,
        this.privateMessageService,
        this.notificationService,
        this.streamService,
        this.rtcConfigurationService,
        this.settingsService,
        this.videoCompatibleService,
        this.signalStrengthService,
        this.siteService,
        this.meetingService,

        session,
        newConnectionId
      );
      peerConnection.on('track', this._onTrack);
      peerConnection.on('state', this._onState);
      peerConnection.on('stats', this._onStats);
      peerConnection.on('badConnectionWithRelayServer', this._onBadConnectionWithRelayServer);
      peerConnections[newConnectionId] = peerConnection;
      this.emit('add', peerConnection);
    }

    return peerConnections[connectionId];
  }


  _getPeerConnectionForRemoteStream(stream) {
    let peerConnections = this.getSessionPeerConnections(stream.session);
    let peerConnection = peerConnections.find(peerConnection => {
      let existingStreamInfos = Object.values(peerConnection.streams.remote)
        .filter(stream => !!stream);
      return (
        existingStreamInfos.length === 0
        || existingStreamInfos.length === 1
        && existingStreamInfos[0].stream.groupId === stream.groupId
        && existingStreamInfos[0].stream.kind !== stream.kind
      );
    });

    if(!peerConnection) {
      peerConnection = this._getOrCreatePeerConnection(stream.session);
    }

    return peerConnection;
  }


  publish() {
    this.init();
  }

  unpublish() {
    // Do nothing
  }


  subscribe(stream) {
    this.init();

    let peerConnection = this._getPeerConnectionForRemoteStream(stream);
    peerConnection.subscribe(stream);
  }


  unsubscribe(stream) {
    let peerConnections = this.getSessionPeerConnections(stream.session);
    let peerConnection = peerConnections.find(peerConnection => {
      let streamInfo = peerConnection.streams.remote[stream.kind];
      return streamInfo && streamInfo.stream === stream;
    });

    if(peerConnection) {
      peerConnection.unsubscribe(stream);
    } else {
      throw new errors.IllegalStateError('Not subscribed to remote stream');
    }
  }


  _onMessage(...args) {
    this.messageQueue.push(args);
    this._processMessageQueue();
  }

  _processMessageQueue() {
    if(!this.initialized) {
      return;
    }

    while(this.messageQueue.length) {
      let [channel, session, connectionId, ...args] = this.messageQueue.shift();
      let connection = this._getOrCreatePeerConnection(session, connectionId);
      connection.onMessage(channel, session, connectionId, ...args);
    }
  }


  _onTrack(peerConnection, track) {
    let streamInfo = peerConnection.streams.remote[track.kind];
    if(streamInfo) {
      this.emit('track', streamInfo.stream, track);
      this._onState(peerConnection);
    }
  }


  _onState(peerConnection) {
    let isConnected = (peerConnection.state === PeerConnectionP2P.State.CONNECTED);
    let streams = Object.values(peerConnection.streams.remote)
      .filter(angular.identity)
      .map(streamInfo => streamInfo.stream);
    this.emit('connected', streams, isConnected);
  }


  _onStats(peerConnection, stream, track, stats) {
    this.emit('stats', stream, track, stats);
  }

  _onBadConnectionWithRelayServer() {
    if(!this.hasShownTURNWarning) {
      this.notificationService.warning(
        gettextCatalog.getString(
          'Your connection is not optimal. <a {{ helpUrl }}>Get help</a>.',
          { helpUrl: `href="${this.siteService.getHelpArticle('badConnection')}" target="_blank"` }
        ),
        { retentiveId: 'turn-warning', delay: -1 }
      );
      this.hasShownTURNWarning = true;
    }
  }
}
