import { InjectionToken } from '@angular/core';
import { ErrorValue } from './settings/settings.component';

// to be able to inject with the `inject()` function we need to use
// an InjectionToken instead of a string as the provider key
export const MODEL_FACTORY_TOKEN = new InjectionToken<any>('modelFactory');
export const API_SERVICE_TOKEN = new InjectionToken<any>('apiService');
// eslint-disable-next-line max-len
export const NotificationService = new InjectionToken<NotificationServiceType>('notificationService');

// AngularJS services use untyped properties, which typescript doesn't like.
// Explicitely type some of these properties here as a stopgap measure, but this should be fixed
// when a Service is upgraded.
export type NotificationServiceType = {
  info(notificationString: string): void
  success(notificationString: string): void
  warning(notificationString: string): void
  error(notificationString: string): void
  handleError(
    error: Error,
    notification: string,
    handleValidationErrors: 'server' | 'page' | 'none'
  ): ErrorValue
}

export const upgradedProviders = [
  {
    provide: 'apiService',
    useFactory: (i: any) => i.get('apiService'),
    deps: ['$injector']
  },
  {
    provide: API_SERVICE_TOKEN,
    useFactory: (i: any) => i.get('apiService'),
    deps: ['$injector']
  },
  {
    provide: 'browserService',
    useFactory: (i: any) => i.get('browserService'),
    deps: ['$injector']
  },
  {
    provide: 'dropdownService',
    useFactory: (i: any) => i.get('dropdownService'),
    deps: ['$injector']
  },
  {
    provide: 'hintService',
    useFactory: (i: any) => i.get('hintService'),
    deps: ['$injector']
  },
  {
    provide: 'languageService',
    useFactory: (i: any) => i.get('languageService'),
    deps: ['$injector']
  },
  {
    provide: 'modalService',
    useFactory: (i: any) => i.get('modalService'),
    deps: ['$injector']
  },
  {
    provide: 'modelFactory',
    useFactory: (i: any) => i.get('modelFactory'),
    deps: ['$injector']
  },
  {
    provide: MODEL_FACTORY_TOKEN,
    useFactory: (i: any) => i.get('modelFactory'),
    deps: ['$injector']
  },
  {
    provide: 'notificationService',
    useFactory: (i: any) => i.get('notificationService'),
    deps: ['$injector']
  },
  {
    provide: NotificationService,
    useFactory: (i: any) => i.get('notificationService'),
    deps: ['$injector']
  },
  {
    provide: 'usageTrackingService',
    useFactory: (i: any) => i.get('usageTrackingService'),
    deps: ['$injector']
  },
  {
    provide: 'waitingRoomSessionsService',
    useFactory: (i: any) => i.get('waitingRoomSessionsService'),
    deps: ['$injector']
  },
  {
    provide: 'waitingRoomSocketService',
    useFactory: (i: any) => i.get('waitingRoomSocketService'),
    deps: ['$injector']
  },
  {
    provide: 'notifyService',
    useFactory: (i: any) => i.get('notifyService'),
    deps: ['$injector']
  },
  {
    provide: 'focusService',
    useFactory: (i: any) => i.get('focusService'),
    deps: ['$injector']
  },
  {
    provide: 'onboardingService',
    useFactory: (i: any) => i.get('onboardingService'),
    deps: ['$injector']
  },
  {
    provide: 'meetingService',
    useFactory: (i: any) => i.get('meetingService'),
    deps: ['$injector']
  },
  {
    provide: 'featureFlagService',
    useFactory: (i: any) => i.get('featureFlagService'),
    deps: ['$injector']
  },
];
