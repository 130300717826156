let formats = [
  [0, gettext('just now'), gettext('just now')],
  [60, gettext('{{n}} minute ago'), gettext('{{n}} minutes ago')],
  [60 * 60, gettext('{{n}} hour ago'), gettext('{{n}} hours ago')],
  [60 * 60 * 24, gettext('{{n}} day ago'), gettext('{{n}} days ago')],
  [60 * 60 * 24 * 30, gettext('{{n}} month ago'), gettext('{{n}} months ago')],
  [60 * 60 * 24 * 365, gettext('{{n}} year ago'), gettext('{{n}} years ago')],
];


/**
 * Usage:
 * - {{ date | timeSince }} for a static calculation.
 * - {{ date | timeSince:$root.window.Date.now() }} for a calculation that updates every second.
 */
export default function TimeSinceFilter() {
  return function(from, until) {
    if(until == null) {
      until = Date.now();
    }
    let diff = (until - from) / 1000;

    for(let i = formats.length - 1; i >= 0; i--) {
      let [divider, formatStrSingular, formatStrPlural] = formats[i];
      let displayDiff = Math.floor(diff / divider);
      if(displayDiff === 0) {
        continue;
      }

      let formatStr = displayDiff === 1 ? formatStrSingular : formatStrPlural;
      if(formatStr.includes('{n}')) {
        return gettextCatalog.getString(formatStr, { n : displayDiff });
      } else {
        return gettextCatalog.getString(formatStr);
      }
    }
  };
}
