<div *ngIf="!stateService.isReady"><loading></loading></div>
<div *ngIf="stateService.isReady">
  <form>
    <div class="d-flex flex-column">
      <div
        *ngIf="readCalendars.length === 0"
        class="field field--error"
      >
        <field-icon [type]="'error'"></field-icon>
        <span i18n>We could not find any calendars to check for. Either reconnect your calendar in the previous step with the correct permissions, or make sure that you have readable calendars configured with your provider.</span>
      </div>

      <div
        *ngIf="readCalendars.length >= 0 && writeableCalendars.length === 0"
        class="field field--error"
      >
        <field-icon [type]="'error'"></field-icon>
        <span i18n>We could not find any calendars that events can be written to. Either reconnect your calendar in the previous step with the correct permissions, or make sure that you have writeable calendars configured with your provider.</span>
      </div>

      <h3 i18n>Calendars to check for availability</h3>

      <div  class="mb-2" *ngFor="let cal of readCalendars | slice:0:nReadCalendarsToShow; let idx = index; trackBy:readCalendarTrackBy">
          <input
            id="id_read_calendars_{{idx}}"
            class="checkbox"
            type="checkbox"
            name="calendar-config-read-calendar_{{idx}}"
            [(ngModel)]="cal.checked"
          />
          <label
            for="id_read_calendars_{{idx}}"
            class="checkbox__label"
          >
            {{ cal.calendar.name }}
          </label>
      </div>

      <a
        [hidden]="showAllReadCalendars
          || providerCalendars.length < nReadCalendarsToShow
          || readCalendars.length === 0"
        (click)="showAllReadCalendars = true"
      >
        <span>
          <span i18n> See all calendars </span>
          <svg-icon class="icon icon--inline" [svgName]="'chevron_down'"></svg-icon>
        </span>

      </a>
    </div>

    <br>

    <div class="d-flex flex-column">
      <h3 i18n>Choose a calendar in which events will be created</h3>

      <div class="mb-2" *ngFor="let cal of visibleWriteCalendars; let idx = index; trackBy:calendarTrackBy">
        <div class="radio__wrapper">
          <input
            name="calendar-config-write-calendar"
            id="id_write_calendars_{{idx}}"
            type="radio"
            class="radio"
            [(ngModel)]="writeCalendar"
            [value]="cal.id"
          />
          <label
            for="id_write_calendars_{{idx}}"
            class="radio__label">{{ cal.name }}</label>
        </div>
      </div>

      <a
        [hidden]="showAllWriteCalendars
        || writeableCalendars.length <= ITEM_COLLAPSE_LIMIT
        || writeableCalendars.length === 0"
        (click)="showAllWriteCalendars = true"
      >
        <span>
          <span i18n> See all calendars </span>
          <svg-icon class="icon icon--inline" [svgName]="'chevron_down'"></svg-icon>
        </span>

      </a>
    </div>
  </form>
</div>
