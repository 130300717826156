import { NgModule } from '@angular/core';
import { UtilsModule } from 'utils/utils.module';
import { CommonModule } from '@angular/common';
import { IntegrationSyncComponent } from './notes/integration-sync/integration-sync.component';
import { PrivateNotesComponent } from './notes/private-notes.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { PrivateNotesService } from './notes/private-notes.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    OverlayModule,
  ],
  providers: [
    PrivateNotesService,
  ],
  declarations: [
    IntegrationSyncComponent,
    PrivateNotesComponent,
  ],
  exports: [
    PrivateNotesComponent,
  ]
})
export class MeetingFooterModule {}
