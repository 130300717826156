
import {
  Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RequestUserService } from 'utils/requestUser.service';
import { bind, logger } from 'utils/util';


/**
 * Usually, an error object is something like:
 * ```
 * {
 *  field1: ['Validation error 1', 'Validation error2'],
 *  field2: ['Validation error 3'],
 * }
 * ```
 *
 * But in the case of nested objects, errors can also be nested:
 * ```
 * {
 *  field1: ['Validation error 1'],
 *  nestedObject: {
 *    field2: ['Validation error 2'],
 *  },
 *  listOfNestedObjects: [
 *    {
 *      field3: ['Validation error 3'],
 *    },
 *    {
 *      field4: ['Validation error 4'],
 *    }
 *  ]
 * }
 * ```
 */
export type ErrorValue = (
  string[]
  | { [field: string]: ErrorValue }
  | { [field: string]: ErrorValue }[]
);
export type Errors = { [field: string]: ErrorValue };


export function isErrors(obj: ErrorValue): obj is Errors {
  return (
    typeof obj === 'object'
    && !Array.isArray(obj)
  );
}

/**
 * the ErrorValue object is typed such that any key is a valid property name,
 * which means that `length` could be a property of the object mapped to a string.
 */
export function numberOfErrors(obj: ErrorValue): number {
  if(typeof obj.length === 'number') {
    return obj.length;
  } else {
    return Object.keys(obj).length;
  }
}

/**
 * Render a form that is tied to a model instance.
 *
 * To use this component, your class should provide a model `instance` and a list of `formFields`.
 * Based on this, the SettingsComponent will generate a `formGroup` that you can use in your
 * template to bind model fields to inputs using `formControlName`.
 */
@Component({
  template: '',
})
export abstract class SettingsComponent<T> implements OnChanges, OnInit {
  /** An object that contains validation errors, e.g. as returned by the API. */
  @Input() errors: Errors = {};

  /**
   * Emitted when this form wants to be submitted, e.g. when the user presses Enter while in an
   * input field. We use "submitForm" as name, because "submit" is some sort of built-in variable
   * which leads to unexpected behaviour
   */
  @Output() submitForm = new EventEmitter<void>();

  /** A list of model fields that will be included inside this form.
   *
   * This supports nested Relatedfields by delimiting parent and child with a dot:
   * static override formFields = [
   *   'teamleaderMappingConfig.dealOwner',
   *   'prefilledAnswers',
   * ];
   *
   * Note that this syntax also requires the template to account for this:
   *
   * <form [formGroup]="formGroup">
   *   <div formGroupName="teamleaderMappingConfig">
   *     <combo-box formControlName="dealOwner"></combo-box>
   *   </div>
   *   <textarea formControlName=prefilledAnswers></textarea>
   * </form>
   */
  protected static formFields: string[] = [];

  /** The model instance that the form is bound to. */
  @Input() instance!: T;

  /** The FormGroup that can be used to bind to inputs in your template. */
  public formGroup: FormGroup;

  requestUserService: RequestUserService;

  constructor(
  ) {
    bind(this);
    this.formGroup = this.createFormGroup();
    this.requestUserService = inject(RequestUserService);
  }


  ngOnInit(): void {
    if(!this.hasPermissionToEdit) {
      const Component = this.constructor as typeof SettingsComponent;
      Component.formFields.forEach(fieldName => {
        this.formGroup.get(fieldName)?.disable();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.instance) {
      this.onInstanceChange();
    }
  }

  onInstanceChange() {
    for(const fieldName of this.fieldNames) {
      const field = this.formGroup.get(fieldName);
      if(field) {
        field.setValue(this.getInstanceValue(fieldName));
      }
    }
  }

  get isAdmin() {
    return this.requestUserService.user.isAdmin;
  }

  get hasFreeSubscription() {
    return this.requestUserService.user.subscription.status === 'free';
  }

  get hasPermissionToEdit() {
    return true;
  }


  private get fieldNames() {
    return (<typeof SettingsComponent> this.constructor).formFields;
  }


  private createFormGroup(): FormGroup {
    const fields: { [fieldName: string]: FormControl | FormGroup } = {};
    for(const fieldName of this.fieldNames) {
      const formControl = new FormControl(this.getInstanceValue(fieldName));
      formControl.valueChanges.subscribe(this.onValueChanges.bind(this, fieldName));
      if(fieldName.indexOf('.') > -1) {
        const [parent, child] = fieldName.split('.');
        if(fields[parent]) {
          if('addControl' in fields[parent]) {
            (fields[parent] as FormGroup).addControl(child, formControl);
          } else {
            logger.error('Trying to add a control to something that isn\'t a FormGroup');
          }
        } else {
          fields[parent] = new FormGroup({
            [child]: formControl
          });
        }
      } else {
        fields[fieldName] = formControl;
      }
    }
    const formGroup = new FormGroup(fields);
    return formGroup;
  }


  get valid(): boolean {
    for(const fieldName of this.fieldNames) {
      if(this.errors[fieldName]) {
        return false;
      }
    }
    return true;
  }

  onValueChanges(fieldName: string) {
    const formControl = this.formGroup.get(fieldName);
    if(formControl) {
      if(fieldName.indexOf('.') > -1) {
        const [parent, child] = fieldName.split('.');
        this.instance[parent][child] = formControl.value;
      } else {
        this.instance[fieldName] = formControl.value;
      }
    }
  }

  getInstanceValue(fieldName: string) {
    if(this.instance == null) {
      return null;
    }
    if(fieldName.indexOf('.') > -1) {
      const [parent, child] = fieldName.split('.');
      return this.instance[parent][child];
    } else {
      return this.instance[fieldName];
    }
  }


  onSubmit() {
    this.submitForm.emit();
  }
}
