<ng-template #inviteMemberModal>
  <invite-modal
    [organization]="organization"
    [existingInvitationEmails]="inviteEmails"
    [existingMemberEmails]="memberEmails"
    (invitationsAdded)="onInvitationsAdded($event)"
  ></invite-modal>
</ng-template>

<div class="dashboard__loading" *ngIf="!hasLoadedMembers"></div>

<h1
  class="page-title d-md-flex justify-content-between align-items-center mb-18"
  data-testid="membersElement"
>
  <div class="d-flex align-items-center">
    <div i18n>Member management</div>

    <svg-icon
      class="icon clickable ml-3"
      [svgName]="'info'"
      [svgStyle]="'badged_filled'"
      *ngIf="!hint.shouldShow && isRequestUserAdmin && hasChargebeeSubscription"
      (click)="hint.toggle()"
      matTooltip="Click for more info"
      i18n-matTooltip
      data-testid="toggleHint"
    ></svg-icon>
  </div>
  <div *ngIf="hasLoadedInvites && hasLoadedMembers">
    <div
      *ngIf="hasPermissionToManage && hasPermissionToInvite"
      class="btn btn--primary "
      [ngClass]="{ disabled: !hasPermissionToInvite }"
      [matTooltip]="inviteTooltip"
      i18n-matTooltip

      [modalTriggerFor]="inviteMemberModal"
      [modalIcon]="{name: 'user_add'}"
      modalTitle="Invite team member"
      i18n-modalTitle

      data-testid="inviteMemberButton"
    >
      <svg-icon
        class="btn__icon"
        [svgName]="'user_add'"
      ></svg-icon>
      <span i18n>Invite team member</span>
    </div>
    <div
      *ngIf="hasPermissionToManage && !hasPermissionToInvite"
      class="disabled btn btn--primary "
      [matTooltip]="inviteTooltip"
      i18n-matTooltip
    >
      <svg-icon
        class="btn__icon"
        [svgName]="'user_add'"
      ></svg-icon>
      <span i18n>Invite team member</span>
    </div>
  </div>
</h1>

<div
  class="card mb-15"
  *ngIf="hint.shouldShow && isRequestUserAdmin && hasChargebeeSubscription"
>
  <p>
    <span i18n>
      When members join or leave your team, <strong>your subscription quantity will be updated automatically.</strong>
    </span>
    <span i18n>You can check your subscription info <a data-testid="subscriptionLink" href="{{ siteService.site.subscriptionLink }}">here</a>.</span>
  </p>
  <div
    class="btn btn--secondary"
    (click)="hint.setSeen()"
    data-testid="gotItButton"
    i18n
  >
    Got it!
  </div>
</div>

<div *ngIf="!hasLoadedMembers">
  <h4 class="d-flex align-items-center">
    <loading></loading>
    <span class="ml-2" i18n>Loading your team members...</span>
  </h4>
</div>



<div *ngIf="hasLoadedMembers">
  <h2 class="organization-members__table-title">
    <div i18n>Team members</div>
    <div>
      <div
        class="badge badge--primary ml-2 mt-1"
        data-testid="activeMembersCountBadge"
      >
        {{ activeMembers.length | i18nPlural: activeMembersCountMapping }}
      </div>
      <div
        class="badge badge--error ml-2 mt-1"
        *ngIf="suspendedMembers.length > 0"
        data-testid="suspendMembersCountBadge"
      >
        {{ suspendedMembers.length | i18nPlural: suspendedMembersCountMapping }}
      </div>
    </div>
  </h2>

  <div class="card card--no-padding overflow-x-auto">
    <table class="table table--hoverable align-middle">
      <thead>
        <tr>
          <th scope="col" i18n>Name</th>
          <th scope="col" i18n>Joined</th>
          <th scope="col" class="organization-members__storage-usage-header">
            <div class="d-none d-xl-block">
                <div i18n>Storage used</div>
                <div class="d-flex align-items-center">
                  <div class="text-normal" i18n>Total: {{ organization.storageUsed | fileSize }} / {{ organization.storageTotal | fileSize }}</div>
                  <svg-icon
                    class="icon icon--sm clickable ml-2"
                    [svgName]="'info'"
                    [svgStyle]="'badged_filled'"
                    matTooltip="Recordings and meeting room files use up cloud space. You can see the size of each meeting on your dashboard. Storage is shared across your team."
                    i18n-matTooltip
                  ></svg-icon>
                </div>
            </div>
            <div class="d-xl-none">
              <div i18n>Total storage used</div>
              <div class="text-normal d-flex align-items-center">
                {{ organization.storageUsed | fileSize }} / {{ organization.storageTotal | fileSize }}
                <svg-icon
                  class="icon icon--sm clickable ml-2"
                  [svgName]="'info'"
                  [svgStyle]="'badged_filled'"
                  matTooltip="Recordings and meeting room files use up cloud space. You can see the size of each meeting on your dashboard. Storage is shared across your team."
                  i18n-matTooltip
                  ></svg-icon>
              </div>
            </div>
            <usage-bar
              [usage]="organization.storageUsed"
              [allowedUsage]="organization.storageTotal"
              class="organization-members__storage-usage-bar"
            ></usage-bar>
          </th>
          <th scope="col" i18n>Is admin</th>
          <th scope="col" *ngIf="hasPermissionToManage"></th>
        </tr>
      </thead>
    <tbody>
      <tr *ngFor="let member of members" data-testid="memberRow">
        <td>
          <div class="d-flex align-items-center">

            <profile-image
              class="flex-none mr-5"
              [user]="member"
              color-mode="'auto'"
            ></profile-image>

            <div>
              <div>
                <span class="text-bold" [ngClass]="{'text-muted': !member.isActive}">{{ member.fullName }}</span>
                <span *ngIf="isMe(member)" class="badge badge--primary" data-testid="itsMeBadge" i18n>You</span>
                <span *ngIf="!member.isActive" class="badge badge--error" data-testid="suspendedBadge" i18n>Suspended</span>
              </div>
              <div
                class="text-muted"
                [copyText]="member.email"
                matTooltip="Click to copy"
                i18n-matTooltip
              >
                {{ member.email }}
              </div>
            </div>
          </div>
        </td>

        <td>
          <span *ngIf="member.dateJoined !== null" [matTooltip]="member.dateJoined | readableTimestamp">
            {{ member.dateJoined | timeSince }}
          </span>
        </td>

        <td>
          {{ member.storageUsed | fileSize }}
        </td>

        <td>
          <svg-icon
            *ngIf="member.isAdmin"
            class="use-icon-color text-success-500"
            [svgName]="'checkmark_badged'"
            [svgStyle]="'filled'"

            data-testid="adminBadge"
          >
          </svg-icon>
        </td>
        <td class="table__cell--icon" *ngIf="hasPermissionToManage">
          <div
            class="btn btn--square"
            [cdkMenuTriggerFor]="actions"
            [cdkMenuTriggerData]="{ member: member }"
            data-testid="actionsButton"
          >
            <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="hasLoadedInvites && invites.length > 0">
  <h2 class="organization-members__table-title">
    <div i18n>Invites</div>
    <div
      class="badge badge--primary ml-2 mt-1"
      data-testid="invitesCountBadge"
    >
      {{ invites.length | i18nPlural: invitesCountMapping }}
    </div>
  </h2>

  <div class="card card--no-padding overflow-x-auto">
      <table class="table table--hoverable align-middle" data-testid="inviteTable">
        <thead>
          <tr>
            <th scope="col" i18n>Email</th>
            <th scope="col" i18n>Invite sent</th>
            <th scope="col"></th>
          </tr>
        </thead>
      <tbody>
        <tr *ngFor="let invite of invites" data-testid="inviteRow">
          <td>
            <div class="d-flex align-items-center">
              <svg-icon
                class="text-primary-400 ml-2 mr-4 icon icon--inline"
                [svgName]="'mail'"
                data-testid="invitedUserIcon"
              >
              </svg-icon>
              <div>
                <span
                  [copyText]="invite.email"
                  matTooltip="Click to copy"
                  i18n-matTooltip

                  data-testid="inviteRowEmail"
                >
                  {{ invite.email }}
                </span>
                <span *ngIf="hasPermissionToManage">
                  (<a (click)="resendInvite(invite)" i18n data-testid="resendInviteButton">Resend invite</a>)
                </span>
              </div>
            </div>
          </td>

          <td>
            <span [matTooltip]="invite.dateUpdated | readableTimestamp">
              {{ invite.dateUpdated | timeSince }}
            </span>
          </td>

          <td class="table__cell--icon">
            <div
              class="btn btn--square text-error-400"
              *ngIf="hasPermissionToManage"
              data-testid="deleteInviteButton"
              matTooltip="Delete invite"
              i18n-matTooltip

              [dialog]="deleteInviteConfirmation"
              dialogTitle="Delete invite"
              i18n-dialogTitle
              [dialogIcon]="{ name: 'mail' }"
              dialogConfirmText="Delete invite"
              i18n-dialogConfirmText
              dialogConfirmButtonClass="btn--error"
              [dialogConfirmedCallback]="deleteInvite.bind(this, invite)"

              data-testid="deleteInviteButton"
            >
              <svg-icon class="btn__icon" [svgName]="'trash'"></svg-icon>
            </div>
            <ng-template #deleteInviteConfirmation>
              <p i18n>Are you sure you want to delete the invite to {{ invite.email }}?</p>
              <p i18n>The invite link that was sent to them will no longer be valid.</p>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #actions let-member="member">
  <div class="dropdown" cdkMenu data-testid="actionsDropdown">
    <div cdkMenuItem></div>

    <div
      *ngIf="canRevokeAdminRights(member)"
      class="dropdown__list-item"

      [dialog]="revokeAdminConfirmation"
      dialogTitle="Admin rights"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'lock' }"
      dialogConfirmText="Revoke admin rights"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--error"
      [dialogConfirmedCallback]="setAdminRights.bind(this, member, false)"
      cdkMenuItem

      data-testid="revokeAdminRightsAction"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'lock'"></svg-icon>
      <span i18n>Revoke admin rights</span>
    </div>

    <div
      *ngIf="!canRevokeAdminRights(member) && member.isAdmin"
      class="dropdown__list-item disabled"
      [matTooltip]="getRevokeAdminDisabledTooltip(member)"

      cdkMenuItem
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'lock'"></svg-icon>
      <span i18n>Revoke admin rights</span>
    </div>

    <div
      *ngIf="!member.isAdmin && member.isActive"
      class="dropdown__list-item"

      [dialog]="addAdminConfirmation"
      dialogTitle="Admin rights"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'lock_open' }"
      dialogConfirmText="Give admin rights"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--primary"
      [dialogConfirmedCallback]="setAdminRights.bind(this, member, true)"
      cdkMenuItem

      data-testid="giveAdminRightsAction"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'lock_open'"></svg-icon>
      <span i18n>Give admin rights</span>
    </div>

    <div
      class="dropdown__list-item"
      *ngIf="member.isActive && !isMe(member)"

      [dialog]="suspendMemberConfirmation"
      dialogTitle="Suspend member"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'pause', style: 'filled' }"
      dialogConfirmText="Suspend {{ member.fullName }}"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--error"
      [dialogConfirmedCallback]="setActive.bind(this, member, false)"

      i18n
      cdkMenuItem
      data-testid="suspendAction"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'pause'" [svgStyle]="'filled'"></svg-icon>
      Suspend
    </div>


    <div
      class="dropdown__list-item"
      *ngIf="!member.isActive && hasPermissionToInvite"

      [dialog]="reactivateMemberConfirmation"
      dialogTitle="Reactivate member"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'refresh'}"
      dialogConfirmText="Reactivate {{ member.fullName }}"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--primary"
      [dialogConfirmedCallback]="setActive.bind(this, member, true)"

      i18n
      cdkMenuItem
      data-testid="reactivateAction"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'refresh'"></svg-icon>
      Reactivate
    </div>

    <div
      class="dropdown__list-item"

      [dialog]="removeTeamConfirmation"
      [dialogTitle]="removeFromTeamTitle(member)"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'meeting_room'}"
      [dialogConfirmText]="removeFromTeamTitle(member)"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--error"
      [dialogConfirmedCallback]="removeFromTeam.bind(this, member)"

      cdkMenuItem
      data-testid="removeAction"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'meeting_room'"></svg-icon>
      <span i18n *ngIf="isMe(member)">Leave this team</span>
      <span i18n *ngIf="!isMe(member)">Remove from this team</span>
    </div>

    <div
      class="dropdown__list-item"
      *ngIf="!isMe(member)"

      [modalTriggerFor]="permanentlyDeleteConfirmation"
      [modalIcon]="{name: 'trash'}"
      modalTitle="Permanently delete member"
      i18n-modalTitle

      cdkMenuItem
      data-testid="permanentlyDeleteAction"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'trash'"></svg-icon>
      <span i18n>Permanently delete</span>
    </div>

  </div>


  <ng-template #revokeAdminConfirmation>
    <p i18n>Are you sure you want to revoke the admin rights of {{ member.fullName }}?</p>
  </ng-template>

  <ng-template #addAdminConfirmation>
    <p i18n>Are you sure you want to give admin rights to {{ member.fullName }}?</p>
    <p i18n>This will allow them to change all of the team's settings, meeting rooms, meetings and more.</p>
  </ng-template>

  <ng-template #suspendMemberConfirmation>
    <p i18n>Are you sure you want to suspend {{ member.fullName }}?</p>
    <div>
      <div class="d-flex mb-1">
        <svg-icon class="icon icon--sm mr-2 mt-1" [svgName]="'chevron_right'"></svg-icon>
        <span i18n>They will not be able to log in anymore.</span>
      </div>
      <div class="d-flex mb-1">
        <svg-icon class="icon icon--sm mr-2 mt-1" [svgName]="'chevron_right'"></svg-icon>
        <span i18n>Their meeting rooms will still be accessible for other team members.</span>
      </div>
      <div class="d-flex mb-1">
        <svg-icon class="icon icon--sm mr-2 mt-1" [svgName]="'chevron_right'"></svg-icon>
        <div>
          <span i18n>They will be removed as hosts of all their personal meeting types and team meeting types.</span>
          <span i18n>Reactivating this user will not automatically re-add them to the meeting types.</span>
        </div>
      </div>
      <div class="d-flex">
        <svg-icon class="icon icon--sm mr-2 mt-1" [svgName]="'chevron_right'"></svg-icon>
        <span i18n>Their used storage will still count against your team total</span>
      </div>
    </div>
  </ng-template>

  <ng-template #reactivateMemberConfirmation>
    <p i18n>Reactivating {{ member.fullName }} will add a member to your subscription total.</p>
  </ng-template>

  <ng-template #removeTeamConfirmation>
    <div *ngIf="isMe(member)">
      <p i18n>Are you sure you want to leave this team?</p>
      <p i18n>
        You will be added to a new team and will be downgraded to the Free plan, with the option
        to purchase a subscription of your own.
        Your meeting rooms and meeting types will not be accessible to this team anymore.
      </p>
    </div>
    <div *ngIf="!isMe(member)">
      <p i18n>Are you sure you want to remove {{ member.fullName }} from the team?</p>
      <p i18n>
        A new team will be created for {{ member.fullName }}.
        They will be downgraded to the Free plan and have the option to purchase a subscription of their own.
        Their meeting rooms and meeting types will not be accessible to you anymore.
      </p>
    </div>
  </ng-template>

  <ng-template #permanentlyDeleteConfirmation>
    <div class="field field--warning d-block mb-4">
      <div i18n>
        Are you absolutely sure you want to <b>permanently delete</b> {{ member.fullName }}'s account and related data?
        This is a permanent action and cannot be reversed!
      </div>
      <div class="mt-2" i18n>
        Want to remove {{ member.fullName }} from your team instead of fully deleting their account and data?
        <a
          [dialog]="removeTeamConfirmation"
          [dialogTitle]="removeFromTeamTitle(member)"
          i18n-dialogTitle
          [dialogIcon]="{ name: 'meeting_room'}"
          [dialogConfirmText]="removeFromTeamTitle(member)"
          i18n-dialogConfirmText
          dialogConfirmButtonClass="btn--error"
          [dialogConfirmedCallback]="removeFromTeam.bind(this, member)"
          modalClose
        >Click here</a>.
      </div>
    </div>
    <p>
      <span i18n data-testid="confirmDeleteInformationText">
        To confirm this action, type the member's full name:
      </span>
      <br>
      <b>{{ member.fullName }}</b>
    </p>
    <div class="mb-3">
      <input
        class="input"
        [(ngModel)]="deleteConfirmationFullName"
        focus-on-add

        data-testid="deleteConfirmationFullNameInput"
      >
    </div>
    <div class="d-flex justify-content-end">
      <div
        class="btn btn--secondary mr-2"
        modalClose
        i18n
        data-testid="cancelAction"
      >
        Cancel
      </div>

      <div
        class="btn btn--error"
        (click)="canPermanentlyDelete(member) && permanentlyDelete(member)"
        [ngClass]="{'disabled': !canPermanentlyDelete(member)}"
        i18n
        data-testid="deletePermanentlyConfirmAction"
        modalClose
        [modalCloseCondition]="canPermanentlyDelete(member)"

        data-testid="permanentlyDeleteConfirmAction"
      >
        Delete permanently
      </div>
    </div>
  </ng-template>
</ng-template>
