import { DeviceKindToStreamType } from  'meeting/meeting-room/stream';
import { storage } from 'utils/util';


const LOCAL_STORAGE_KEY = 'devicePreference';
const LOCAL_STORAGE_MAX_LENGTH = 100;



class LocalStorageManager {
  constructor() {
    this.preference = this._getPreferenceFromLocalStorage();
  }


  _getPreferenceFromLocalStorage() {
    let preference = storage.getJSONItem(LOCAL_STORAGE_KEY, []);
    if(!preference || !Array.isArray(preference)) {
      preference = [];
    }

    return preference;
  }


  _updateLocalStorage(preference) {
    storage.setJSONItem(LOCAL_STORAGE_KEY, preference);
  }


  getPreference(device) {
    let matchedDevice = this._getMatchedDevice(device);
    if(matchedDevice) {
      return matchedDevice.preference;
    } else {
      return 0;
    }
  }

  updatePreference(device) {
    let matchedDevice = this._getMatchedDevice(device);
    if(!matchedDevice) {
      matchedDevice = {
        id: device.id,
        kind: device.kind,
      };

      this.preference.push(matchedDevice);
      if(this.preference.length > LOCAL_STORAGE_MAX_LENGTH) {
        this.preference.splice(0, this.preference.length - LOCAL_STORAGE_MAX_LENGTH);
      }
    }

    matchedDevice.preference = device.preference;
    this._updateLocalStorage(this.preference);
  }


  _getMatchedDevice(device) {
    return this.preference.filter(storedDevice => {
      return storedDevice.kind === device.kind && storedDevice.id === device.id;
    })[0];
  }


  getMaxPreference() {
    let maxPreference = Math.max(...this.preference.map(device => device.preference));
    return maxPreference != null && isFinite(maxPreference) ? maxPreference : 0;
  }
}


let localStorageManager = new LocalStorageManager();



export default class MediaDevice {
  static get Name() {
    return Object.freeze({
      audioinput: gettext('microphone'),
      videoinput: gettext('camera'),
      audiooutput: gettext('speaker'),
    });
  }

  static get ExternalName() {
    return Object.freeze({
      audioinput: gettext('microphone/headset'),
      videoinput: gettext('webcam'),
    });
  }

  constructor(device, otherDevices) {
    this.device = device;
    this.stream = null;
    /**
     * Store an integer that signifies the users's preference for that particular device:
     * If the device is selected, assign it a preference of n+1, with n being the highest
     * preference of existing preferences.
     *
     * Initialized with 0, such that devices that have never been selected can easily be filtered
     */
    this.preference = localStorageManager.getPreference(this);
    this._otherDevices = otherDevices;
  }

  destroy() {
    this.device = {
      kind: this.kind,
    };
  }

  get id() {
    return this.device.deviceId || '';
  }
  get label() {
    return this.device.label || '';
  }
  get kind() {
    return this.device.kind;
  }
  get groupId() {
    return this.device.groupId || '';
  }

  get type() {
    return DeviceKindToStreamType[this.kind];
  }
  get name() {
    return this.constructor.Name[this.kind];
  }

  get active() {
    return !!(this.stream && this.stream.enabled);
  }

  get default() {
    return (
      this.id === ''                           // Dummy device
      || this.id === 'default'                 // Chrome
      || this.label.startsWith('default: ')    // Firefox on Linux
      || this.label.startsWith('default ('));  // Firefox on OS X
  }
  get meta() {
    return this.id === 'communications' || this.default;
  }


  getReferenced() {
    // This works in: Chrome on Windows or OS X
    // This doesn't work in: Chrome on Linux, Firefox on Windows, OS X or Linux
    // Other browsers were not tested

    return Object.values(this._otherDevices).find(device => {
      return (
        device.id !== this.id
        && device.groupId
        && device.groupId === this.groupId
        && !device.meta
      );
    });
  }


  setPreferred() {
    this.preference = localStorageManager.getMaxPreference() + 1;
    localStorageManager.updatePreference(this);
  }


  setStream(stream) {
    this.stream = stream;
  }
}
