import { Component, Input } from '@angular/core';
import { AppLogoService } from 'utils/ui-components/logo/app-logo/app-logo.service';
import { LogoColor, LogoVariant } from './app-logo.service';
import { SvgIcon } from '../../svg-icon';

@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss'],
})
export class AppLogoComponent {
  @Input() logoVariant: LogoVariant = 'full';
  @Input() logoColor: LogoColor = 'color';

  constructor(
    public appLogoService: AppLogoService,
  ) { }

  public get icon(): SvgIcon {
    return this.appLogoService.getSvgIcon(this.logoColor, this.logoVariant);
  }

  public get monochrome(): boolean {
    return this.logoColor === 'light' || this.logoColor === 'dark';
  }
}
