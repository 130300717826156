import template from './members.html?raw';
import templateDropdownOptions from './memberOptions.dropdown.html?raw';
import templateDropdownInvite from './inviteNewMember.dropdown.html?raw';

import Dropdown from 'utils/angularjs/dropdown/Dropdown';
import { DROPDOWN_ID_INVITE } from './inviteNewMember.controller.js';
import { DROPDOWN_ID_RESULTS } from 'utils/angularjs/searchUser/searchUser.component.js';

const DROPDOWN_ID_MEMBER_OPTIONS = 'memberOptions';

let selectedMember = null;
let action = null;

class MembersController {
  static get $inject() {
    return [
      'dropdownService',
      'hintService',
      'memberService',
      'requestUserService',
      'siteService',
    ];
  }

  constructor(
    dropdownService,
    hintService,
    memberService,
    requestUserService,
    siteService
  ) {
    this.dropdownService = dropdownService;
    this.hintService = hintService;
    this.memberService = memberService;
    this.requestUserService = requestUserService;
    this.siteService = siteService;

    dropdownService.register(
      DROPDOWN_ID_MEMBER_OPTIONS,
      templateDropdownOptions,
      {
        cssClasses: 'dropdown-deprecated--no-padding-x',
        align: Dropdown.Align.END,
      }
    );
    dropdownService.register(
      DROPDOWN_ID_INVITE,
      templateDropdownInvite,
      {
        embeds: [DROPDOWN_ID_RESULTS],
        preRender: true,
        cssClasses: 'dropdown-deprecated--large-padding dropdown--no-margin'
      });

    this.data = {};
    this.hint = null;
    this.hint = this.hintService.get('teamMembersSubscriptionInfo');

    this.deleteConfirmationFullName = '';
  }

  get inviteTooltip() {
    if(!this.hasPermissionToInvite) {
      return gettextCatalog.getString(
        'Your subscription quantity does not allow more invites'
      );
    } else {
      return '';
    }
  }

  get checkSubscriptionLink() {
    return gettextCatalog.getString(
      'You can check your subscription info <a {{ url }}>here</a>.',
      { url: `href="${this.siteService.site.subscriptionLink}"` }
    );
  }

  get memberCountString() {

    let res = '';

    if(this.memberService.numMembers > 0) {
      res.concat(gettextCatalog.getPlural(
        this.memberService.numMembers,
        'There is 1 member in your team',
        'There are {{ count }} members in your team',
        { count: this.memberService.numMembers }
      ));
      if(this.memberService.numInvitedMembers > 0) {
        res.concat(', ');
      } else {
        res.concat('.');
      }
    }

    if(this.memberService.numInvitedMembers > 0) {
      res.concat(gettextCatalog.getPlural(
        this.memberService.numMembers,
        '1 of whom still has a pending invitation.',
        '{{ $count }} of whom still have a pending invitation.'
      ));
    }

    if(this.memberService.subscription.quantityIsLimited) {
      res.concat(gettextCatalog.getPlural(
        this.memberService.subscription.limitedQuantity,
        'Your current subscription allows only 1 team member.',
        'Your current subscription allows up to {{ $count }} team members.'
      ));
    }

    if(this.memberService.subscription.lifetimeQuantity > 0) {
      res.concat(gettextCatalog.getString(
        '<a {{ url }}>Edit your subscription</a> to add more.',
        { url: `href="${ this.siteService.site.subscriptionLink }"` }
      ));
    } else {
      res.concat(gettextCatalog.getString(
        'Contact <a {{ url }}>sales@vectera.com</a> to add more.',
        { url: 'target="_blank" href="mailto:sales@vectera.com"' }
      ));
    }

    return res;
  }

  getChangeStatusTooltip(member) {
    if(this.hasPermissionToManage && member.isMe) {
      return gettextCatalog.getString('You cannot change your own status');
    } else {
      return '';
    }
  }

  get areYouSureString() {
    if(action === 'remove') {
      return gettextCatalog.getString(
        'Are you sure you want to remove <b>{{ name }}</b>?',
        { name: this.selectedMember.fullName }
      );
    } else if(action === 'suspend') {
      return gettextCatalog.getString(
        'Are you sure you want to suspend <b>{{ name }}</b>?',
        { name: this.selectedMember.fullName }
      );
    } else {
      return '';
    }
  }

  get suspendInfoText() {
    return gettextCatalog.getString(
      `They will not be able to log in to {{ productName }} anymore.
      Their meeting rooms and meeting types will still be accessible for other team members.
      Their used storage will still count against your team total`,
      { productName: this.siteService.site.productName }
    );
  }

  get removeInfoText() {
    return gettextCatalog.getString(
      `A new team will be created for {{ memberName }}.
      They will be downgraded to the {{ productName }} Free plan, and have the option to
      purchase a {{ productName }} subscription of their own. Their meeting rooms and
      meeting types will not be accessible to you anymore.`,
      {
        memberName: this.selectedMember.fullName,
        productName: this.siteService.site.productName,
      }
    );
  }

  get notAllowedString() {
    if(action === 'remove') {
      return gettextCatalog.getString(
        'You cannot remove the only admin in your team.'
      );
    } else if(action === 'suspend') {
      return gettextCatalog.getString(
        'You cannot suspend the only admin in your team.'
      );
    } else if(action === 'delete') {
      return gettextCatalog.getString(
        'You cannot delete the only admin in your team.'
      );
    } else {
      return '';
    }
  }

  isOnlyAdmin(member) {
    return (
      this.memberService.adminMembers.length === 1
      && this.memberService.adminMembers[0] === member
    );
  }
  isRequestUserAdmin() {
    let member = this.memberService.getMember(this.requestUserService.user);
    return member && member.isAdmin;
  }

  get hasPermissionToManage() {
    return this.memberService.hasPermissionToManage;
  }

  get hasPermissionToInvite() {
    return (
      this.hasPermissionToManage
      && (
        !this.memberService.subscription.quantityIsLimited
        || this.memberService.subscription.limitedQuantity > this.memberService.numMembers
      )
    );
  }


  canPermanentlyDelete(member) {
    return this.deleteConfirmationFullName === member.fullName;
  }


  get selectedMember() {
    return selectedMember;
  }

  get action() {
    return action;
  }
  set action(argSelectedMemberAction) {
    action = argSelectedMemberAction;
  }


  toggleOptions(member, $event) {
    selectedMember = member;
    action = null;
    this.dropdownService.toggle(DROPDOWN_ID_MEMBER_OPTIONS, $event);
  }


  invite($event) {
    this.dropdownService.toggle(DROPDOWN_ID_INVITE, $event);
  }

  resendInvite(user) {
    this.memberService.sendInvite(user, this.memberService.defaultInviteMessage, true);
  }
}


export default {
  controller: MembersController,
  controllerAs: 'membersCtrl',
  template,
};
