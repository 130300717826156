

import { Component } from '@angular/core';
import {
  WaitingRoomNotificationService
} from '../waiting-room-notification.service';


@Component({
  selector: 'waiting-room-notification-button',
  templateUrl: './waiting-room-notification-button.component.html',
})
export class WaitingRoomNotificationButtonComponent {
  constructor(
    public waitingRoomNotificationService: WaitingRoomNotificationService
  ) {
  }

  get waitingRoomNotificationsEnabled(): boolean {
    return this.waitingRoomNotificationService.notificationsEnabled;
  }

  toggleWaitingRoomNotifications() {
    this.waitingRoomNotificationService.toggleNotifications();
  }

}
