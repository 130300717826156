<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="form-group">

    <label class="d-flex align-items-center" for="name" class="label--h3" i18n>Name</label>
    <confirm-change
      [value]="instance.name"
      [dialogText]="confirmNameChangeDialogText"
      [shouldConfirm]="!!instance.id"
      data-testid="meetingTypeConfigName"
    >
      <input
        class="input"
        formControlName="name"
        [withSettingsErrors]="errors.name"
        cdkFocusInitial
        data-testid="nameInput"
      />
    </confirm-change>
  </div>
  <div class="form-group">
    <label class="d-flex align-items-center" for="locations" class="label--h3" i18n>Location</label>
    <location-picker
      id="locations"
      formControlName="locations"
      [withSettingsErrors]="errors.locations"
    >
    </location-picker>

    <div class="mt-10" *ngIf="shouldShowOfflineAddress">
      <label class="d-flex align-items-center" for="offlineAddress" i18n>Offline Location</label>
      <input
        id="offlineAddress"
        placeholder="Address"
        i18n-placeholder
        class="input"
        formControlName="offlineAddress"
        [withSettingsErrors]="errors.offlineAddress"
        data-testid="offlineAddressInput"
        >
    </div>
  </div>

  <div class="form-group">
    <label class="d-flex align-items-center" for="duration" class="label--h3" i18n>Duration</label>
    <duration-picker
      id="duration"
      [durationUnit]="'minutes'"
      [durationOptions]="[30, 45, 60]"
      [durationAllowCustom]="true"
      formControlName="duration"
      [withSettingsErrors]="errors.duration"
      data-testid="durationPicker"
    >
    </duration-picker>
  </div>

  <div class="form-group">
    <label class="d-flex align-items-center" for="description" i18n>Description</label>
    <textarea
      class="textarea"
      placeholder="Enter description"
      i18n-placeholder
      id="description"
      formControlName="description"
      [withSettingsErrors]="errors.description"
      data-testid="descriptionInput"
    ></textarea>
  </div>

  <ng-container *ngIf="shouldShowTeamAppointmentType">
    <div class="form-group">
      <h3 i18n>Select meeting type</h3>

      <segmented-button
        [options]="teamAppointmentTypeOptions"
        formControlName="isTeamAppointmentType"
      ></segmented-button>
    </div>

    <div class="form-group" *ngIf="instance.isTeamAppointmentType">
      <h3 i18n>Host selection method</h3>
      <div>
        <input
          type="radio"
          class="radio"
          id="PickHostMethod__SELECT_HOST"
          [value]="PickHostMethod.SELECT_HOST"
          formControlName="pickHostMethod"
        >
        <label
          class="radio__label"
          for="PickHostMethod__SELECT_HOST"
          i18n
        >
          Customers pick the host
        </label>
      </div>

      <div>
        <input
          type="radio"
          class="radio"
          id="PickHostMethod__RANDOM_HOST"
          [value]="PickHostMethod.RANDOM_HOST"
          formControlName="pickHostMethod"
        >
        <label
          class="radio__label"
          for="PickHostMethod__RANDOM_HOST"
          i18n
        >
          Host is selected randomly
        </label>
      </div>

      <div>
        <input
          type="radio"
          class="radio"
          id="PickHostMethod__SELECT_HOST_OR_RANDOM_HOST"
          [value]="PickHostMethod.SELECT_HOST_OR_RANDOM_HOST"
          formControlName="pickHostMethod"
        >
        <label
          class="radio__label"
          for="PickHostMethod__SELECT_HOST_OR_RANDOM_HOST"
          i18n
        >
          Customers can pick the host or random option
        </label>
      </div>
    </div>
  </ng-container>
</form>

<div class="mt-8 mb-8">
  <input
    id="advancedSettingsToggle"
    type="checkbox"
    class="toggle"
    [(ngModel)]="shouldShowAdvanced"
  />
  <label
    for="advancedSettingsToggle"
    class="toggle__label toggle__label--right"
    data-testid="advancedSettingsToggle"
    i18n
  >
    Show advanced settings
  </label>
</div>

<form
  [hidden]="!shouldShowAdvanced"
  [formGroup]="formGroup"
  (ngSubmit)="onSubmit()"
  data-testid="advancedSettingsForm"
>

  <label class="label--h3 d-flex align-items-center" for="customMaxDays">
    <span i18n>Additional invitees</span>
  </label>

  <div class="form-group" data-testid="additionalInviteesConfig">
    <pro-feature-badge
      *ngIf="hasFreeSubscription"
      featureText="Allow your guests to add additional invitees"
      i18n-featureText
    >
    </pro-feature-badge>
    <div
      *ngIf="!hasFreeSubscription"
      class="d-flex flex-row align-items-center"
      [withSettingsErrors]="errors.numExtraInviteesAllowed"
    >
      <span i18n>
        Your guests can add up to
        <input
          id="extraInviteesLimit"
          class="num-invitees-input"
          formControlName="numExtraInviteesAllowed"
          (change)="extraInviteesCannotBeNull()"
          type="number"
        />
        additional invitees (max 6)
      </span>
      <help-tooltip
        *ngIf="instance.id && instance.numExtraInviteesAllowed > 0"
        text="For the best customer experience, you can also set up an email template for additional invitees in the powerful extensions."
        i18n-text
      >
      </help-tooltip>
    </div>
  </div>

  <div class="form-group" data-testid="meetingTemplateConfig">
    <label class="label--h3 d-flex align-items-center" for="meetingTemplateId">
      <span i18n>Meeting room template</span>
      <help-tooltip
        text="When someone books a meeting, this template will be applied to the meeting room that was automatically created."
        i18n-text
      >
      </help-tooltip>
    </label>

    <pro-feature-badge
      *ngIf="hasFreeSubscription"
      featureText="Change your meeting room template"
      i18n-featureText
    >
    </pro-feature-badge>

    <div *ngIf="!hasFreeSubscription" class="d-flex flex-row">
      <div
        *ngIf="meetingTemplatesState === 'loading'"
        i18n
        class="select text-muted w-auto"
      >
        Loading...
      </div>

      <div *ngIf="meetingTemplatesError" i18n>
        Something went wrong while loading your meeting templates.
        Please reload the page to try again.
      </div>

      <dropdown-select
        *ngIf="meetingTemplatesState === 'ready' && !meetingTemplatesError"
        id="meetingTemplateId"
        [options]="meetingTemplateOptions"
        [nullable]="true"
        [textIfNull]="templateNullText"
        formControlName="meetingTemplateId"
        [withSettingsErrors]="errors.meetingTemplateId"
      ></dropdown-select>
      <a
        *ngIf="isAdmin && instance.meetingTemplateId && !meetingTemplatesError"
        class="a-plain ml-2 btn btn--link"
        [attr.href]="instance.meetingTemplateId ? meetingTemplateUrl : null"
        target="_blank"
        i18n
      >
        View room
      </a>
    </div>
  </div>


  <div class="form-group">
    <div>
      <label class="label--h3">
        <span i18n>Cancellation and rescheduling</span>
      </label>
    </div>

    <div class="d-flex">
      <input
        id="allowGuestCancellationReschedule"
        type="checkbox"
        class="checkbox"
        formControlName="allowGuestCancellationReschedule"
      />
      <label class="checkbox__label" for="allowGuestCancellationReschedule">
        <span i18n>Allow guests to cancel or reschedule a meeting</span>
        <help-tooltip
          text="Allows a guest to cancel or reschedule a meeting from the confirmation mail they receive after booking a meeting."
          i18n-text
        >
        </help-tooltip>
      </label>

      <div
        class="ml-2 badge badge--primary"
        *ngIf="shouldShowCancellationNewBadge"
        i18n
      >
        New
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="customMaxDays">
      <span i18n>Bookable range</span>
      <help-tooltip
        text="Defines the day range for booking this meeting type. Eg. if you configure this to 30 days, your guest will be able to choose a date up  to 30 days in the future. The maximum number of days is {{ MAX_BOOKING_DAYS }}."
        i18n-text
      >
      </help-tooltip>
    </label>
    <duration-picker
      id="customMaxDays"
      [durationUnit]="'days'"
      [durationOptions]="[30, 60, 90]"
      [durationAllowCustom]="true"
      formControlName="customMaxDays"
      [withSettingsErrors]="errors.customMaxDays"
      data-testid="bookableRangePicker"
    >
    </duration-picker>
  </div>

  <div class="form-group">
    <div class="d-flex flex-row flex-wrap mr--8">
      <div class="mr-8">
        <label class="d-flex align-items-center" for="customFromDate">
          <span i18n>Booking start date</span>
          <help-tooltip
            text="This is the earliest date for which this meeting type can be booked. If left blank, this will not be taken into account."
            i18n-text
          >
          </help-tooltip>
        </label>
        <datepicker
          id="customFromDate"
          formControlName="customFromDate"
          [withSettingsErrors]="errors.customFromDate"
          data-testid="bookingStartDatepicker"
        >
        </datepicker>
      </div>
      <div class="mr-8">
        <label class="d-flex align-items-center" for="customToDate">
          <span i18n>Booking end date</span>
          <help-tooltip
            text="This is the latest date for which this meeting type can be booked. If left blank, this will not be taken into account."
            i18n-text
          >
          </help-tooltip>
        </label>
        <datepicker
          id="customToDate"
          formControlName="customToDate"
          [withSettingsErrors]="errors.customToDate"
          data-testid="bookingEndDatepicker"
        >
        </datepicker>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="timeSlotsInterval" i18n>Show availability in increments of</label>
    <duration-picker
      id="timeSlotsInterval"
      [durationUnit]="'minutes'"
      [durationOptions]="[15, 30, 60]"
      formControlName="timeSlotsInterval"
      [withSettingsErrors]="errors.timeSlotsInterval"
      data-testid="availabilityIncrementsPicker"
    >
    </duration-picker>
  </div>

  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="customBuffer">
      <span i18n>Booking buffer</span>
      <help-tooltip
        text="You can define the amount of buffer before the earliest bookable meeting. Example: your availability is set from 9AM to 5PM and your booking buffer is set to 3 hours. When a client visits your booking page at 11AM then the earliest timeslot they can book is 2PM that same day."
        i18n-text
      >
      </help-tooltip>
    </label>
    <duration-picker
      id="customBuffer"
      [durationUnit]="'hours'"
      [durationOptions]="[{ value: null, alias: customBufferNullAlias }, 4, 2]"
      [durationAllowCustom]="true"
      formControlName="customBuffer"
      [withSettingsErrors]="errors.customBuffer"
      data-testid="bookingBufferPicker"
    >
    </duration-picker>
  </div>

  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="bufferBefore" i18n>Meeting buffer (before)</label>
    <duration-picker
      id="bufferBefore"
      [durationUnit]="'minutes'"
      [durationOptions]="[{ value: 0, alias: appointmentBufferAlias }, 5, 10]"
      [durationAllowCustom]="true"
      formControlName="bufferBefore"
      [withSettingsErrors]="errors.bufferBefore"
      data-testid="bufferBeforePicker"
    >
    </duration-picker>
  </div>

  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="bufferAfter" i18n>Meeting buffer (after)</label>
    <duration-picker
      id="bufferAfter"
      [durationUnit]="'minutes'"
      [durationOptions]="[{ value: 0, alias: appointmentBufferAlias }, 5, 10]"
      [durationAllowCustom]="true"
      formControlName="bufferAfter"
      [withSettingsErrors]="errors.bufferAfter"
      data-testid="bufferAfterPicker"
    >
    </duration-picker>
  </div>
</form>
