import template from './meetingTemplates.html?raw';
import templateDropdownOptions from './meetingTemplateOptions.dropdown.html?raw';
import templateDropdownNew from './newMeetingTemplate.dropdown.html?raw';

import Dropdown from 'utils/angularjs/dropdown/Dropdown';

const DROPDOWN_ID_TEMPLATE_OPTIONS = 'templateOptions';
const DROPDOWN_ID_NEW_TEMPLATE = 'newtemplate';


class MeetingTemplateController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      'meetingTemplateService',
      'dropdownService',
      'hintService',
      'siteService',
    ];
  }

  constructor(
    $elem,
    $scope,
    meetingTemplateService,
    dropdownService,
    hintService,
    siteService
  ) {
    this._bind();

    this.$elem = $elem;
    this.$scope = $scope;
    this.meetingTemplateService = meetingTemplateService;
    this.dropdownService = dropdownService;
    this.siteService = siteService;

    this.dropdownService.register(
      DROPDOWN_ID_NEW_TEMPLATE,
      templateDropdownNew
    );
    dropdownService.register(
      DROPDOWN_ID_TEMPLATE_OPTIONS,
      templateDropdownOptions,
      {
        align: Dropdown.Align.END,
        cssClasses: 'dropdown-deprecated--no-padding-x'
      }
    );

    this.hint = hintService.get('meetingTemplates');

    this.confirmDeleteTemplate = false;
    this.newTemplateName = '';
    this.processingCreate = false;

    this.$elemInput = $elem.find('input');
    this.$elemInput.on('keydown', this._onKeydown);
  }

  _bind() {
    this._onKeydown = this._onKeydown.bind(this);
  }

  get upgradeLink() {
    return gettextCatalog.getString(
      '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to create room templates.',
      {
        url: `href="${this.siteService.site.subscriptionLink}" target="_blank"`,
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

  get upgradeToProText() {
    return gettextCatalog.getString(
      'Upgrade to {{ proPlanName }} to create room templates',
      { proPlanName: this.siteService.site.proPlanName }
    );
  }

  get helpText() {
    return gettextCatalog.getString(
      `Pre-fill meeting rooms with private notes, content and more.
      Automatically generate meeting rooms based on templates when someone books a meeting.
      Meeting room templates are shared with your <a {{ teamMembersUrl }}>team members</a>.
      Note that meeting room templates count towards your cloud storage.
      Learn more about meeting room templates <a {{ helpUrl }}>here</a>.`,
      {
        teamMembersUrl: `href="${ this.meetingTemplateService.urls.teamMembers }"`,
        helpUrl: `href="${this.siteService.getHelpArticle('meetingTemplates')}" target="_blank"`,
      }
    );
  }

  get createString() {
    if(this.processingCreate) {
      return gettextCatalog.getString('Creating...');
    } else {
      return gettextCatalog.getString('Create');
    }
  }


  _onKeydown($event) {
    if($event.key === 'Enter') {
      this.create();
    }
  }

  toggleCreateNewDropdown($event) {
    this.dropdownService.toggle(DROPDOWN_ID_NEW_TEMPLATE, $event);
  }

  toggleOptions(template, $event) {
    let scope = {
      selectedTemplate: template
    };
    this.dropdownService.toggle(DROPDOWN_ID_TEMPLATE_OPTIONS, $event, scope);
  }


  delete(template) {
    this.confirmDeleteTemplate = false;
    this.meetingTemplateService.delete(template);
  }

  setIsEditing(template, isEditing) {
    if(isEditing) {
      this.meetingTemplateService.meetingTemplates.forEach(template => {
        template.isEditing = false;
      });
    }
    template.isEditing = isEditing;
  }


  updateName(template, name) {
    return this.meetingTemplateService.updateName(template, name)
      .then(() => template.isEditing = false);
  }


  create() {
    if(!this.canSubmitCreate) {
      return;
    }

    this.processingCreate = true;
    this.meetingTemplateService.create(this.newTemplateName).then(() => {
      this.dropdownService.hide(DROPDOWN_ID_NEW_TEMPLATE);
      this.processingCreate = false;
    });
  }

  get canSubmitCreate() {
    return !this.processingCreate && this.newTemplateName;
  }
}


export default {
  controller: MeetingTemplateController,
  controllerAs: 'meetingTemplateCtrl',
  template,
};
