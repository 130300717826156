// These variables should always be kept up to date with the first lines of _variables.scss.
// This is not very ideal, but it's only a few variables, and there's no standardized solution to
// sharing variables between sass and javascript yet.

export const TILE_BORDER_WIDTH = 1;  // Border around the entire tile
export const CONTENT_HEADER_HEIGHT = 47;  // Including bottom border
export const CONTENT_HEADER_HEIGHT_INACTIVE = 36;
export const WHITEBOARD_TOOLS_WIDTH = CONTENT_HEADER_HEIGHT;
export const WHITEBOARD_ERASER_SIZE = 20;

export const SHARED_POINTER_CLICK_DURATION = 1200;
