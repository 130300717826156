import { Component, ElementRef, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'tag-badge[label]',
  template: '{{ label }}',
})
export class TagBadgeComponent implements AfterViewInit {

  @Input() index!: number;
  @Input() label!: string;
  @Output() addItem = new EventEmitter();

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    const tagBadgeElement = this.elementRef.nativeElement;
    const computeBoxElement = tagBadgeElement.parentElement;
    const selectElement = computeBoxElement.parentElement;
    const computedStyle = window.getComputedStyle(selectElement);
    const allowableWidth = (selectElement.clientWidth
      - parseInt(computedStyle.paddingRight)
      - parseInt(computedStyle.paddingLeft)
    );
    const isOverflowed = computeBoxElement.scrollWidth > allowableWidth;

    // If we add one more tag, the "+x" badge will be shown. This property checks if there is
    // space left for this extra badge.
    const willOverflowIfMoreTagsAreAdded = computeBoxElement.scrollWidth + 28 > allowableWidth;

    this.addItem.emit({
      isOverflowed: isOverflowed,
      willOverflowIfMoreTagsAreAdded: willOverflowIfMoreTagsAreAdded,
      index: this.index,
    });
  }
}
