import { format, logger } from 'utils/util';
import Recording from './Recording';


// These match Recording.State in the chrome extension
const State = Object.freeze({
  RECORDING: 'recording',
  INACTIVE: 'inactive',
  CONVERTING: 'converting',
  DONE: 'done',
});

export default class BaseLocalRecording extends Recording {

  static get State() {
    return State;
  }

  constructor(
    $http,
    $cookies,
    apiService,
    notificationService,
    requestUserService,
    sendMessageToExtension,
    ...args
  ) {
    super(...args);
    this._bind();

    this.$http = $http;
    this.$cookies = $cookies;
    this.apiService = apiService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;

    this._sendMessageToExtension = sendMessageToExtension;

    this.isUploading = false;
    this.progressBar = null;
  }

  _bind() {
    this._cancelUpload = this._cancelUpload.bind(this);
    this._onUploadError = this._onUploadError.bind(this);
  }

  get state() {
    return this.properties.state;
  }
  get recording() {
    return this.state === State.RECORDING;
  }
  get inactive() {
    return this.state === State.INACTIVE;
  }
  get converting() {
    return this.state === State.CONVERTING;
  }
  get done() {
    return this.state === State.DONE;
  }

  get meetingOwner() {
    return this.meeting ? this.meeting.owner : null;
  }

  get allowCloudRecordings() {
    if(!this.meeting) {
      return false;
    }
    return this.meeting.settings.allowCloudRecordings;
  }

  get convertProgress() {
    return Math.round(this.properties.convertProgress * 100);
  }


  download() {
    this._sendMessageToExtension({
      action: 'download',
      id: this.id,
    }, gettextCatalog.getString(Recording.ERROR_MESSAGE_DOWNLOAD));
  }


  open() {
    this._sendMessageToExtension({
      action: 'open',
      id: this.id,
    }, gettextCatalog.getString(Recording.ERROR_MESSAGE_DOWNLOAD));
  }


  convert() {
    return this._sendMessageToExtension({
      action: 'convert',
      id: this.id,
    });
  }


  upload() {
    if(this.isUploading || !this.allowCloudRecordings) {
      // Never resolve
      return $q(angular.noop);
    }

    this.isUploading = true;
    this.progressBar = this.notificationService.progress(
      this.uploadMessage,
      this._cancelUpload
    );

    let message = {
      action: 'upload',
      id: this.id,
      name: this.name,
      meetingId: this.meeting.id,
      url: this.uploadUrl,
      xsrfHeaderName: this.$http.defaults.xsrfHeaderName,
      xsrfCookie: this.$cookies.get(this.$http.defaults.xsrfCookieName),
    };
    return this._sendMessageToExtension(message, null, this._onUploadError)
      .finally(() => {
        this.isUploading = false;
        this.progressBar.cancel();
        this.progressBar = null;
      });
  }

  get uploadUrl() {
    return format(
      '%srecordings/',
      this.apiService.baseUrl
    );
  }

  get uploadMessage() {
    return gettextCatalog.getString(
      'Uploading <b>{{ filename }}</b>',
      { filename: this.name }
    );
  }


  _onUploadError(error) {
    let warning;
    /* eslint-disable max-len */
    if(
      error
      && error.nonFieldErrors
      && error.nonFieldErrors[0] === 'Available cloud storage exceeded.'
    ) {
      warning = this.meeting.owner.id === this.requestUserService.user.id ?
        gettextCatalog.getString('You don\'t have enough cloud storage available to upload this recording.') :
        gettextCatalog.getString('The meeting room owner doesn\'t have enough cloud storage available to upload this recording.');
      /* eslint-enable max-len */
    } else {
      logger.warn(error);
      warning = gettextCatalog.getString(Recording.ERROR_MESSAGE_UPLOAD);
    }

    this.notificationService.warning(warning);
  }

  _cancelUpload() {
    if(this.isUploading) {
      this._sendMessageToExtension({
        action: 'upload-cancel',
        id: this.id,
      });
    }
  }


  setUploadProgress(progress) {
    if(this.progressBar) {
      this.progressBar.setProgress(progress);
    }
  }


  delete() {
    return this._sendMessageToExtension({
      action: 'delete',
      id: this.id,
    }, gettextCatalog.getString(Recording.ERROR_MESSAGE_DELETE));
  }
}
