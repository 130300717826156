import { Component, Input } from '@angular/core';
import { Appointment } from 'scheduling/models/Appointment';
import { AppointmentLocation } from 'scheduling/models/appointment-location';

@Component({
  selector: 'appointment-location[appointment]',
  templateUrl: './appointment-location.component.html',
})
export class AppointmentLocationComponent {
  @Input() appointment!: Appointment;


  get AppointmentLocation() {
    return AppointmentLocation;
  }

}
