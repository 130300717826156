export default class WhiteboardAutoNameService {
  static get $inject() {
    return [
      'meetingBroadcastService',
      'whiteboardService',
    ];
  }

  constructor(
    meetingBroadcastService,
    whiteboardService
  ) {
    this._bind();
    this.meetingBroadcastService = meetingBroadcastService;
    this.whiteboardService = whiteboardService;

    this.initializing = true;
    this.autoNameShapes = {};

    whiteboardService.on('add', this._onWhiteboardAdd);
    whiteboardService.on('remove', this._onWhiteboardRemove);

    meetingBroadcastService.afterInitialization().then(() => this.initializing = false);
  }

  _bind() {
    this._onWhiteboardAdd = this._onWhiteboardAdd.bind(this);
    this._onWhiteboardRemove = this._onWhiteboardRemove.bind(this);
    this._onShapeAdd = this._onShapeAdd.bind(this);
    this._onShapeRemove = this._onShapeRemove.bind(this);
    this._onShapesEdit = this._onShapesEdit.bind(this);
  }


  _onWhiteboardAdd(whiteboard) {
    whiteboard.paperRenderer.on('shapeAdd', this._onShapeAdd);
    whiteboard.paperRenderer.on('shapeRemove', this._onShapeRemove);
    whiteboard.paperRenderer.on('shapesEdit', this._onShapesEdit);
  }


  _onWhiteboardRemove(whiteboard) {
    delete this.autoNameShapes[whiteboard.id];
    whiteboard.paperRenderer.off('shapeAdd', this._onShapeAdd);
    whiteboard.paperRenderer.off('shapeRemove', this._onShapeRemove);
    whiteboard.paperRenderer.off('shapesEdit', this._onShapesEdit);
  }


  _onShapeAdd(paperRenderer, shape) {
    let whiteboard = paperRenderer.whiteboard;
    let page = whiteboard.pages[0];
    let autoNameShape = this.autoNameShapes[whiteboard.id];
    if(
      page
      && shape.type === 'text'
      && shape.pageId === page.id
      && (!autoNameShape || autoNameShape.pageId !== page.id)
    ) {
      this.autoNameShapes[whiteboard.id] = shape;
      this._updateAutoName(whiteboard);
    }
  }


  _onShapeRemove(paperRenderer, shape) {
    let whiteboard = paperRenderer.whiteboard;
    if(shape === this.autoNameShapes[whiteboard.id]) {
      this.autoNameShapes[whiteboard.id] = null;
      this._updateAutoNameShape(whiteboard);
    }
  }


  _onShapesEdit(paperRenderer, shapeInfos) {
    let whiteboard = paperRenderer.whiteboard;
    shapeInfos.forEach(shapeInfo => {
      let shape = shapeInfo[0];
      if(shape === this.autoNameShapes[whiteboard.id]) {
        this._updateAutoName(whiteboard);
      }
    });
  }



  _updateAutoNameShape(whiteboard) {
    let shape = this._findAutoNameShape(whiteboard);
    if(shape) {
      this.autoNameShapes[whiteboard.id] = shape;
      this._updateAutoName(whiteboard);
    }
  }


  _findAutoNameShape(whiteboard) {
    let page = whiteboard.pages[0];
    if(!page) {
      return null;
    }

    let pageShapes = whiteboard.paperRenderer.pageShapes[page.id] || [];
    return pageShapes
      .filter(shape => shape.type === 'text')
      .reduce((shape1, shape2) => {
        return shape1 && shape1.properties.top < shape2.properties.top ? shape1 : shape2;
      }, null);
  }


  _updateAutoName(whiteboard) {
    if(this.initializing) {
      return;
    }

    let shape = this.autoNameShapes[whiteboard.id];
    if(whiteboard.nameIsManual || !shape) {
      return;
    }

    let text = shape.properties.text || '';
    let name = text.split('\n')[0].trim();
    if(name === '' || shape.properties.isPristine || shape.properties.isInitTextBox) {
      return;
    }

    if(name.length > 30) {
      name = name.substring(0, 28).trim() + '...';
    }
    whiteboard.setName(name, false);
  }
}
