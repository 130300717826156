import { bind, logger, errors, EventEmitter } from 'utils/util';
import { promisifyJanus } from './Janus';


let counter = 0;

export class JanusError extends errors._CustomError {}

export const State = Object.freeze({
  NEW: 'new',
  INITIALIZING: 'initializing',
  ATTACHING: 'attaching',
  ATTACHED: 'attached',
  JOINING: 'joining',
  JOINED: 'joined',
  NEGOTIATION_REQUESTED: 'negotiationRequested',
  NEGOTIATING: 'negotiating',
  NEGOTIATED: 'negotiated',
  LEAVING: 'leaving',
  DESTROYING: 'destroying',
  DESTROYED: 'destroyed',
});

export const ChannelLabel = Object.freeze({
  KEEP_ALIVE: 'keepAlive',
});

const EVENTS = Object.freeze([
  'state',
  'unrecoverableError',
].concat(Object.values(State)));


export default class JanusHandle {
  static get State() {
    return State;
  }


  // All subclasses should implement the properties roomId, sessionId and id


  constructor(janus) {
    bind(this);
    EventEmitter.setup(this, EVENTS);

    this.uniqueId = ++counter;
    this.logger.info('create', this.constructor.name);

    this.janus = janus;
    this._setState(State.NEW);
    this.handle = null;

    this.promise = $q.resolve();
  }


  get logger() {
    return logger.withContext({ id: this.uniqueId });
  }


  _enqueue(...fns) {
    for(const fn of fns) {
      this.promise = this.promise.then(fn);
    }
    return this.promise;
  }


  _setState(state) {
    if(state !== this.state) {
      this.logger.info('state:', state);
      this.state = state;
      this.emit('state', this, state);
      this.emit(state, this);
    }
  }


  _assertState(legalStates) {
    if(!legalStates.includes(this.state)) {
      const formattedLegalStates = legalStates.join(', ');
      throw new errors.IllegalStateError(
        `JanusHandle is in state ${this.state}, but expected one of ${formattedLegalStates}.`
      );
    }
  }


  _apply(fn) {
    return (...args) => $rootScope.$evalAsync(() => fn(...args));
  }


  init() {
    if(this.state !== State.NEW) {
      return;
    }

    this._setState(State.INITIALIZING);
    return this._enqueue(() => {
      return this._attach();
    });
  }


  destroy() {
    if(this.state !== State.DESTROYED) {
      this._detach();
      this._onDestroyed();
    }
  }


  _onCleanup() {
    // This is called when the ICE connection state goes to failed. In this case the PeerConnection
    // gets closed by Janus, but the handle itself does not, so we do it. If there really is a
    // problem with the network, this will kick of an infinite loop of recreating the handle and
    // then failing the ICE connection.
    this.destroy();
  }


  _onDestroyed() {
    this.handle = null;
    this._setState(State.DESTROYED);
  }


  _onError(error) {
    this.logger.warn('JanusHandle error:', error.error || error);
    // This seems to happen when a handle is restarted (e.g. when joining after knocking), but
    // something went wrong during cleanup so the Janus server still thinks the handle is attached.
    // I'm not sure how to properly resolve that situation, so for now we just restart our entire
    // Janus session.
    if(error.error === `User ID ${this.id} already exists`) {
      this.emit('unrecoverableError', this, error);
    }
    this.destroy();
  }



  sendMessage(message, jsep) {
    const config = {
      message: message,
      jsep: jsep,
    };
    return this._sendJanus(config)
      .then(response => {
        if(response && response.error) {
          throw new JanusError(response.error);
        }
        return response;
      });
  }


  _onMessageEvent(message, jsep) {
    if(!message) {
      return;
    }

    if(jsep && jsep.type === 'offer') {
      this._onRemoteOffer(jsep);
    } else if(jsep && jsep.type === 'answer') {
      this._onRemoteAnswer(jsep);
    }

    if(message && message.error) {
      this._onError(message);
    } else {
      this._onMessage(message);
    }
  }


  _onMessage(message) {
    this.logger.info('message:', message);
  }



  _attach() {
    return $q.resolve()
      .then(() => {
        if(this.state === State.DESTROYED) {
          return;
        }
        this._assertState([State.INITIALIZING]);
        return this._attachJanus();
      })
      .then(handle => {
        if(this.state !== State.ATTACHING) {
          return;
        }
        this.handle = handle;
        this._sendJanus = promisifyJanus(this.handle.send.bind(this.handle));
        this._detachJanus = promisifyJanus(this.handle.detach.bind(this.handle));
      })
      .then(() => {
        if(this.state !== State.ATTACHING) {
          return;
        }
        return this._postAttach();
      })
      .catch(this._onError);
  }

  _attachJanus() {
    this._setState(State.ATTACHING);
    const config = this._getAttachConfig();
    return this.janus.attach(config);
  }

  _getAttachConfig() {
    return {
      plugin: 'janus.plugin.videoroom',
      opaqueId: this.sessionId,
      onmessage: this._apply(this._onMessageEvent),
      ondetached: this._apply(this._onDestroyed),
      oncleanup: this._apply(this._onCleanup),
    };
  }

  _postAttach() {
    if(this.state === State.DESTROYED) {
      return;
    }
    this._setState(State.ATTACHED);
  }



  _detach() {
    if(this.state === State.DESTROYED) {
      return;
    }

    this._setState(State.DESTROYING);
    if(this.handle) {
      return this._detachJanus()
        .catch(error => this.logger.info(error))
        .finally(this._postDetach);
    }
  }

  _postDetach() {}



  _join() {
    return $q.resolve()
      .then(() => {
        if(this.state === State.DESTROYED) {
          return;
        }
        this._assertState([State.ATTACHED]);
        return this._sendJoin();
      })
      .then(() => {
        if(this.state !== State.JOINING) {
          return;
        }
        return this._postJoin();
      })
      .catch(this._onError);
  }

  _sendJoin() {
    this._setState(State.JOINING);
    const config = this._getJoinConfig();
    return this.sendMessage(config);
  }

  _getJoinConfig() {
    return {
      request: 'join',
      room: this.roomId,
    };
  }

  _postJoin() {
    this._setState(State.JOINED);
  }
}
