import { Component, Input } from '@angular/core';
import { object } from 'utils/util';
import { ErrorValue } from '../settings.component';


@Component({
  selector: 'settings-non-field-errors',
  templateUrl: './settings-non-field-errors.component.html',
})
export class SettingsNonFieldErrorsComponent {
  @Input() nonFieldErrors?: ErrorValue;


  get hasErrors() {
    return this.nonFieldErrors && object.length(this.nonFieldErrors) > 0;
  }
}
