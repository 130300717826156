export default function FilesizeFilter() {
  let units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let defaultPrecision = 2;

  function toPrecision(number, precision) {
    if(number === 0) {
      return '0';
    } else if(Math.round(number) < Math.pow(10, precision)) {
      return number.toPrecision(precision);
    } else {
      return Math.round(number).toString();
    }
  }

  return function(number, precision) {
    if(number == null) {
      return '';
    }
    if(precision == null) {
      precision = defaultPrecision;
    }

    let unitIndex = 0;

    while(Math.round(number) >= 1000) {
      number /= 1000;
      unitIndex++;
    }
    let formattedNumber = toPrecision(number, precision);

    return formattedNumber + ' ' + units[unitIndex];  // Non-breaking space
  };
}
