import { browser, getQueryParameter, logger, raf } from 'utils/util';
import NoiseSuppressionEffect
  from 'meeting/angularjs/main/streams/effects/noiseSuppression/NoiseSuppressionEffect';
import { Stream } from  'meeting/meeting-room/stream';
import { MediaDevice } from 'meeting/angularjs/shared/audioVideo/MediaDevice';
import { ElemInfo, ElemState } from './Stream';
import { AudioContextService } from './audioContext.service';


const MONITOR_FPS = 20;
const EMIT_SILENCE_TIMEOUT = 4;  // Seconds
const MAX_ZERO_LEVELS = MONITOR_FPS * EMIT_SILENCE_TIMEOUT;
const FFT_SIZE = 128;


export class AudioStream extends Stream {

  public sourceTrack: MediaStreamTrack | null = null;
  public sourceMediaStream: MediaStream | null = null;

  public noiseSuppressionEffect: NoiseSuppressionEffect | null = null;

  public audioSource: MediaStreamAudioSourceNode | null = null;
  public analyzerNode: AnalyserNode | null = null;
  public analyzerData: Uint8Array | null = null;
  public numZeroLevels = 0;

  public outputDevice: MediaDevice = null;

  public audioContextService: AudioContextService;
  public notificationService: any;
  public settingsService: any;

  constructor(
    type, id, groupId, session,
    readyToPlayService,
    audioContextService,
    notificationService,
    settingsService
  ) {
    super(
      type, id, groupId, session,
      readyToPlayService,
    );

    this.audioContextService = audioContextService;
    this.notificationService = notificationService;
    this.settingsService = settingsService;

    this.eventEmitter.addEvent('level');
    this.eventEmitter.addEvent('outputDevice');
    this.compatible = true;
  }


  override destroy() {
    super.destroy();
  }

  override setTrack(track: MediaStreamTrack) {
    if(this.isLocal && this.settingsService.suppressMicNoise) {
      track = this._createNoiseSuppressedTrack(track);
    }
    super.setTrack(track);
  }

  override removeTrack() {
    this._removeNoiseSuppressedTrack();
    super.removeTrack();
  }

  setOutputDevice(device: MediaDevice) {
    if(device !== this.outputDevice) {
      this.outputDevice = device;
      this.updateElems();
      this.eventEmitter.emit('outputDevice', device);
    }
  }


  override updateElemPlay(elemInfo: ElemInfo): Promise<void> {
    return super.updateElemPlay(elemInfo).then(() => {
      if(
        elemInfo.state === ElemState.PLAYING
        && browser.supportsOutputDevice()
        && this.outputDevice
        // setSinkId is currently (05-2024) only available on chrome and firefox
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
        && (elemInfo.elem as any).setSinkId
      ) {
        try {
          (elemInfo.elem as any).setSinkId(this.outputDevice.id)
            .catch(error => {
              logger.warn(error);
            });
        } catch(error) {
          logger.warn(error);
        }
      }
    });
  }


  override setMediaStream(mediaStream: MediaStream) {
    super.setMediaStream(mediaStream);
    if(mediaStream && mediaStream.getAudioTracks().length > 0) {
      const audioContext = this.audioContextService.get();
      if(audioContext) {
        this.audioSource = audioContext.createMediaStreamSource(mediaStream);
      }
    } else {
      this.audioSource = null;
    }
  }


  override startMonitoring() {
    super.startMonitoring();

    if(
      this.audioSource
      && getQueryParameter('disableAudioMonitoring') == null
    ) {
      const audioContext = this.audioContextService.get();
      if(audioContext) {
        this.analyzerNode = new AnalyserNode(audioContext, { fftSize: FFT_SIZE });
      }
      if(this.analyzerNode) {
        this.audioSource.connect(this.analyzerNode);
        this.analyzerData = new Uint8Array(this.analyzerNode.fftSize);
      }
      this.monitor();
    }
  }


  override stopMonitoring() {
    super.stopMonitoring();

    this.numZeroLevels = 0;

    if(this.analyzerNode) {
      this.analyzerNode.disconnect();
      this.analyzerNode = null;
      this.analyzerData = null;
    }
  }


  private monitor() {
    if(!this.analyzerNode || !this.analyzerData) {
      return;
    }
    raf.requestAnimationFrame(this.monitor, MONITOR_FPS);

    this.analyzerNode.getByteTimeDomainData(this.analyzerData);
    let sumOfSquaredLevels = 0;
    let numZero = 0;

    for(let i = 0; i < this.analyzerData.length; i++) {
      const level = (this.analyzerData[i] - 128) / 128;
      sumOfSquaredLevels += level * level;
      if(level === 0) {
        numZero += 1;
      }
    }
    let rootMeanSquare = Math.sqrt(sumOfSquaredLevels / this.analyzerData.length);
    rootMeanSquare = rootMeanSquare < 1e-5 ? 0 : rootMeanSquare;

    this.eventEmitter.emit('level', this, rootMeanSquare);

    let silence, setSilence = false;
    if(!this.isLocal && !browser.supportsRemoteAudioLevel()) {
      silence = false;

    } else if(numZero / this.analyzerData.length > 0.9 && rootMeanSquare === 0) {
      this.numZeroLevels++;

      if(this.numZeroLevels >= MAX_ZERO_LEVELS && this.numZeroLevels % 40 === 0) {
        setSilence = true;
        silence = true;
      }

    } else {
      this.numZeroLevels = 0;
      if(this.silence || !this.ignoreSilence) {
        setSilence = true;
        silence = false;
      }
    }

    if(setSilence) {
      // UPGRADE NOTE: removed an evalAsync here
      this.setSilence(silence);
    }
  }


  _createNoiseSuppressedTrack(track: MediaStreamTrack): MediaStreamTrack {
    this.sourceTrack = track;
    this.sourceMediaStream = new MediaStream([track]);

    const audioContext = this.audioContextService.get();
    this.noiseSuppressionEffect = new NoiseSuppressionEffect(audioContext, this.sourceMediaStream);
    this.noiseSuppressionEffect.start()
      .catch(error => {
        this.settingsService.setMicNoiseSuppressed(false);
        logger.warn(error);
        this.notificationService.warning(
          // eslint-disable-next-line max-len
          $localize `Something went wrong while enabling noise suppression. Please try again later or with a different browser.`
        );
      });
    return this.noiseSuppressionEffect.trackDestination;
  }


  _removeNoiseSuppressedTrack() {
    if(!this.noiseSuppressionEffect && !this.sourceTrack) {
      return;
    }

    this.sourceTrack?.stop();
    this.sourceTrack = null;
    this.sourceMediaStream = null;

    this.noiseSuppressionEffect.stop();
    this.noiseSuppressionEffect = null;
  }
}
