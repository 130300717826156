import {
  AfterViewInit, Component, ElementRef, EventEmitter,
  Input, OnChanges,
  Output, QueryList,
  SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';
import {
  ContactForm,
  ContactFormAnswer,
  ContactFormQuestion,
  ContactFormResponse
} from 'contactForm/models';
import { Errors } from 'utils/settings/settings.component';
import { SiteService } from 'utils/site.service';
import { bind, object } from 'utils/util';
import { ViewOrganizationService } from 'utils/view-organization.service';
import { Customization } from '../../utils/customization';
import {
  ContactFormQuestionComponent
} from '../contact-form-question/contact-form-question.component';
import { marked } from 'marked';


type QuestionItem = {
  question: ContactFormQuestion,
  answer?: ContactFormAnswer,
  errors?: Errors,
}


/**
 * Render the public view of a contact form. When a `contactForm` is provided, the view is
 * rendered readonly, as there is nowhere to write the answers to. When a `contactFormResponse`
 * is provided, the view is rendered as a fillable form.
 */
@Component({
  selector: 'contact-form-form[contactForm][customization]',
  templateUrl: './contact-form-form.component.html',
  styleUrls: ['./contact-form-form.component.scss'],
})
export class ContactFormFormComponent implements OnChanges, AfterViewInit {
  @Input() public contactForm: ContactForm;
  @Input() public customization!: Customization;
  @Input() public errors: Errors = {};
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public hideRecaptcha = false;
  @Input() public isEmbedded = false;
  @Input() public shouldShowPoweredBy = false;
  @Output() public submitForm = new EventEmitter<ContactFormResponse>();

  public contactFormResponse: ContactFormResponse;
  public questionItems: QuestionItem[] = [];
  public shouldSendCopy = false;
  public acceptTerms = false;
  private recaptchaId?: any;

  @ViewChildren(ContactFormQuestionComponent) public questionRefs!:
    QueryList<ContactFormQuestionComponent>;
  @ViewChild('recaptcha') private recaptchaRef?: ElementRef;


  constructor(
    public viewOrganizationService: ViewOrganizationService,
    private siteService: SiteService,
  ) {
    bind(this);
  }

  ngAfterViewInit() {
    this.renderRecaptcha();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.contactForm) {
      // This should only happen while previewing the form
      changes.contactForm.previousValue?.off('questions', this.createResponse);
      changes.contactForm.currentValue?.on('questions', this.createResponse);
      this.createResponse();
    }
    if(changes.errors) {
      this.updateQuestionErrors();
    }
  }


  get shouldHideNonFreeQuestions() {
    return this.viewOrganizationService.organization.subscription.status === 'free';
  }

  get shouldShowRecaptcha() {
    return ANGULAR_SCOPE.recaptcha.enabled && !this.hideRecaptcha;
  }


  private createResponse() {
    this.contactFormResponse = this.contactForm.createResponse();
    if(this.shouldHideNonFreeQuestions) {
      this.contactFormResponse.answers = this.contactFormResponse.answers.filter(answer => {
        return answer.contactFormQuestion.allowFree;
      });
    }
    this.updateQuestionItems();
  }


  updateQuestionItems() {
    this.questionItems = this.contactFormResponse.answers.map((answer, i) => {
      return {
        question: answer.contactFormQuestion,
        answer,
        errors: this.errors.answers && this.errors.answers[i],
      };
    });
  }

  updateQuestionErrors() {
    this.questionItems.forEach((questionItem, i) => {
      questionItem.errors = this.errors.answers && this.errors.answers[i];
    });
  }


  get termsString(): string | null {
    const termsAndConditions = this.viewOrganizationService.organization.termsAndConditions;
    if(termsAndConditions !== '' && termsAndConditions != null) {
      return marked.parseInline(termsAndConditions);
    }
    return null;
  }


  /*********************
   * Validate & submit *
   *********************/

  submit() {
    if(this.readonly) {
      return;
    }

    const errors = this.validate();
    if(object.length(errors) > 0) {
      this.errors = errors;
      this.updateQuestionErrors();
      return;
    } else {
      this.errors = {};
    }

    if(!this.shouldSendCopy) {
      this.contactFormResponse.submitterNotificationEmail = '';
    }

    this.submitForm.emit(this.contactFormResponse);
    setTimeout(() => {
      this.resetRecaptcha();
    }, 1000);
  }


  validate() {
    const errors: Errors = {};

    this.questionRefs.forEach((questionRef, i) => {
      const questionErrors = questionRef.validate();
      if(questionErrors && object.length(questionErrors) > 0) {
        errors.answers = errors.answers || [];
        errors.answers[i] = questionErrors;
      }
    });

    if(this.shouldShowRecaptcha) {
      const recaptchaResponse = this.getRecaptchaResponse();
      if(recaptchaResponse) {
        this.contactFormResponse.setRecaptchaResponse(recaptchaResponse);
      } else {
        errors.recaptcha = [$localize `Please complete the reCAPTCHA.`];
      }
    }

    const email = this.contactFormResponse.submitterNotificationEmail;
    if(this.shouldSendCopy && !email) {
      errors.submitterNotificationEmail = [$localize `Please enter a valid email address.`];
    }

    if(this.contactForm.askToAcceptTerms && !this.acceptTerms) {
      errors.acceptTerms = [$localize `Please accept the terms and conditions.`];
    }

    return errors;
  }


  /*************
   * reCAPTCHA *
   *************/

  private renderRecaptcha() {
    if(!this.shouldShowRecaptcha) {
      return;
    }

    window.__vecteraOnRecaptchaLoaded(() => {
      if(!this.recaptchaRef) {
        return;
      }
      this.recaptchaId = window.grecaptcha.render(this.recaptchaRef.nativeElement, {
        sitekey: ANGULAR_SCOPE.recaptcha.v2SiteKey,
      });
    });
  }

  private resetRecaptcha() {
    if(this.recaptchaId == null) {
      return;
    }
    window.grecaptcha.reset(this.recaptchaId);
  }


  private getRecaptchaResponse() {
    if(this.recaptchaId == null) {
      return '';
    }
    return window.grecaptcha.getResponse(this.recaptchaId) as string;
  }
}
