import template from './headerPhone.html?raw';

import { HeaderController } from './header.component';


export default {
  controller: HeaderController,
  controllerAs: 'headerPhoneCtrl',
  template,
};
