import template from './wizard.html?raw';

class WizardController {
  static get $inject() {
    return [
      'browserService',
      'modalService',
    ];
  }

  constructor(
    browserService,
    modalService
  ) {
    this.browserService = browserService;
    this.modalService = modalService;
  }

  get scope() {
    return this.wizard.scope;
  }

  get stepString() {
    return gettextCatalog.getString(
      'Step {{ idx }} of {{ len }}',
      {
        idx: this.wizard.currentStepIndex + 1,
        len: this.wizard.steps.length
      }
    );
  }

  closeWizard() {
    this.modalService.hide(this.wizard.id);
  }
}


export default {
  controller: WizardController,
  controllerAs: 'wizardCtrl',
  template,

  bindings: {
    'wizard': '<',
  },
};
