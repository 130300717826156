import { Clipboard } from '@angular/cdk/clipboard';
import { Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewContainerRef
} from '@angular/core';
import { TooltipComponent } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


const HIDE_TOOLTIP_TIMEOUT = 2000;
const SCROLL_THROTTLE_MS = 20;


/**
 * Copy text to the clipboard on mouse click.
 *
 * Example usage:
 *
 * ```
 * <div class="btn" [copyText]="'Some text'">Click me!<div>
 *
 * <div
 *  class="btn"
 *  [copyText]="'https://foo.bar'"
 *  [copyTextConfirmation]="'Link copied!'"
 * >Click me!<div>
 * ```
 *
 * The actual logic for copying a link is only a few lines of code, and uses Angular CDK's
 * Clipboard. Most of this component is a reimplementation of Angular Material's MatTooltip,
 * necessary for showing a confirmation tooltip after copying the link. It's not ideal, but I
 * couldn't find a more elegant way to incorporate a tooltip into an attribute directive.
 */
@Directive({
  selector: '[copyText]',
  exportAs: 'copyText',
})
export class CopyTextDirective implements OnDestroy {
  @Input() public copyText = '';
  @Input() public copyBlob!: Blob | undefined;
  @Input() public copyTextDisabled = false;
  @Input() public copyTextConfirmation = $localize `Copied to clipboard`;
  @Input() public copyTextError = $localize `Copy to clipboard failed`;
  @Input() public copyEmbeddedLink = false;
  @Output() public copyTextCopied = new EventEmitter<void>();

  private overlayRef: OverlayRef | null = null;
  private tooltipInstance: TooltipComponent | null = null;
  private portal: ComponentPortal<TooltipComponent> | null = null;

  private readonly destroyed = new Subject<void>();
  private isDestroyed = false;


  constructor(
    private clipboard: Clipboard,

    // Tooltip dependencies
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef<HTMLElement>,
  ) {}


  ngOnDestroy() {
    this.hideTooltip();
    if(this.overlayRef) {
      this.overlayRef.dispose();
      this.tooltipInstance = null;
    }

    this.isDestroyed = true;
    this.destroyed.next();
    this.destroyed.complete();
  }


  @HostListener('click')
  private onClick() {
    if(this.copyTextDisabled) {
      return;
    }
    if(this.copyBlob) {
      try {
        /* eslint-disable compat/compat */
        navigator.clipboard.write([
          new ClipboardItem({
            [this.copyBlob.type]: this.copyBlob
          })
        ]);
        /* eslint-enable- compat/compat */

        this.showTooltip(this.copyTextConfirmation);
      } catch(e) {
        this.showTooltip(this.copyTextError);
      }

      return;
    }

    let text = this.copyText;
    if(this.copyEmbeddedLink) {
    // eslint-disable-next-line max-len
      text = `<iframe style="width: 100%; height: 600px;" src="${this.copyText}" frameborder="0"></iframe>`;
    }
    const success = this.clipboard.copy(text);
    if(success) {
      this.copyTextCopied.emit();
    }
    // If the element is destroyed as a result of the click (e.g. usualy the case for dropdowns):
    // don't show a tooltip, as it will show in the wrong position and be empty.
    if(!this.isDestroyed) {
      if(success) {
        this.showTooltip(this.copyTextConfirmation);
      } else {
        this.showTooltip(this.copyTextError);
      }
    }
  }


  private showTooltip(message: string) {
    if(this.isTooltipVisible()) {
      this.tooltipInstance?._cancelPendingAnimations();
    }

    const overlayRef = this.createOverlay();
    this.detach();
    if(!this.portal) {
      this.portal = new ComponentPortal(TooltipComponent, this.viewContainerRef);
    }
    const instance = overlayRef.attach(this.portal).instance;
    this.tooltipInstance = instance;
    instance._triggerElement = this.elementRef.nativeElement;
    instance.message = message;
    instance
      .afterHidden()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.detach());
    instance.show(0);
    setTimeout(() => instance.hide(HIDE_TOOLTIP_TIMEOUT));
  }


  private hideTooltip() {
    if(!this.tooltipInstance) {
      return;
    }

    this.tooltipInstance.hide(0);
  }


  private isTooltipVisible() {
    return !!this.tooltipInstance && this.tooltipInstance.isVisible();
  }


  createOverlay() {
    if(this.overlayRef) {
      return this.overlayRef;
    }

    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
      ])
      .withTransformOriginOn('mat-tooltip')
      .withFlexibleDimensions(false)
      .withViewportMargin(6)
      .withScrollableContainers(scrollableAncestors);

    this.overlayRef = this.overlay.create({
      positionStrategy: strategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        scrollThrottle: SCROLL_THROTTLE_MS,
      }),
    });

    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.detach());

    this.overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.tooltipInstance?._handleBodyInteraction());

    return this.overlayRef;
  }


  private detach() {
    if(this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    this.tooltipInstance = null;
  }
}
