import { bind, string } from 'utils/util';

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ContactForm } from 'contactForm/models/ContactForm';
import { FormatStringArrayPipe } from 'utils/pipes/format-string-array.pipe';
import { RequestUserService } from 'utils/requestUser.service';
import { UrlService } from 'utils/url.service';
import { UsageTrackingService } from 'utils/usageTracking.service';

// Used to be able to use django's reverse function to get
// the update url for a contact form. We will replace the contact form id with the
// correct id in this component.
const DUMMY_CONTACT_FORM_ID = '00000000000000000000000000000000';

@Component({
  selector: 'contact-forms-table',
  templateUrl: './contact-forms-table.component.html',
})
export class ContactFormsTableComponent {
  @Input() contactForms: ContactForm[] = [];
  @Input() showHost = false;
  @Input() canCreate = true;
  @Output() contactFormChange = new EventEmitter<void>();


  constructor(
    @Inject('modelFactory') private modelFactory,
    @Inject('notificationService') private notificationService,
    private usageTrackingService: UsageTrackingService,
    private formatStringArrayPipe: FormatStringArrayPipe,
    public urlService: UrlService,
    public requestUserService: RequestUserService,
  ) {
    bind(this);
  }

  get upgradeTooltip() {
    // eslint-disable-next-line max-len
    return $localize `Your current package allows only 1 form within your account. Upgrade to activate this contact form.`;
  }

  shouldWarnAboutDowngrade(contactForm: ContactForm) {
    return contactForm.isActive
      && this.requestUserService.user.organization.subscription.status === 'free'
      && !contactForm.questions.every(q => q.allowFree);
  }

  getEntityCreationText(contactForm: ContactForm) {
    const teamleaderMappingConfig = contactForm.teamleaderMappingConfig;
    if(teamleaderMappingConfig == null) {
      return null;
    }
    const entityTexts: string[] = [];
    if(teamleaderMappingConfig.createContact) {
      entityTexts.push($localize `contact`);
    }
    if(teamleaderMappingConfig.createCompany) {
      entityTexts.push($localize `company`);
    }
    if(teamleaderMappingConfig.createDeal) {
      entityTexts.push($localize `deal`);
    }
    if(entityTexts.length === 0) {
      return null;
    }

    const text = this.formatStringArrayPipe.transform(entityTexts);
    return string.capitalize(text);
  }

  canEdit(contactForm: ContactForm): boolean {
    const user = this.requestUserService.user;
    return (
      user.isAdmin
      || contactForm.createdBy.id === user.id
    );
  }

  canDuplicate(contactForm: ContactForm): boolean {
    return (
      this.canEdit(contactForm)
      && this.canCreate
    );
  }

  shouldShowEntityCreationInfo(contactForm: ContactForm) {
    const teamleaderMappingConfig = contactForm.teamleaderMappingConfig;
    if(teamleaderMappingConfig == null) {
      return false;
    }
    if(
      !teamleaderMappingConfig.createContact
      && !teamleaderMappingConfig.createCompany
      && !teamleaderMappingConfig.createDeal
    ) {
      return false;
    }

    return this.isTeamleaderUser;
  }

  get isTeamleaderUser() {
    return this.requestUserService.user.isTeamleaderUser;
  }

  getNumResponsesText(contactForm: ContactForm) {
    return string.nLocalize(
      $localize `1 response`,
      $localize `${contactForm.numResponses} responses`,
      contactForm.numResponses,
    );
  }

  getNumRecentResponsesTooltip(contactForm: ContactForm) {
    return string.nLocalize(
      $localize `1 response in the past week`,
      $localize `${contactForm.numRecentResponses} responses in the past week`,
      contactForm.numRecentResponses,
    );
  }

  getEntityCreationMobileText(contactForm: ContactForm) {
    if(!this.shouldShowEntityCreationInfo(contactForm)) {
      return $localize `Completed info will not be sent to Teamleader Focus`;
    }
    const entities = this.getEntityCreationText(contactForm);
    return $localize `:Completed info will be sent to contact and deal:Completed info will be sent to ${entities}`; // eslint-disable-line max-len
  }

  getSharingOptionsTitle(contactForm: ContactForm) {
    return $localize `Share: ${contactForm.name}`;
  }

  /***********
  * Actions *
  ***********/

  getUpdateUrl(contactForm: ContactForm) {
    return this.urlService.urls.contactFormUpdate.replace(
      DUMMY_CONTACT_FORM_ID,
      contactForm.id
    );
  }

  getResponsesUrl(contactForm: ContactForm) {
    return this.urlService.urls.contactFormResponses.replace(
      DUMMY_CONTACT_FORM_ID,
      contactForm.id
    );
  }

  delete(contactForm: ContactForm) {
    contactForm.delete()
      .then(() => this.contactFormChange.emit())
      .catch(error => {
        this.notificationService.handleError(
          error,
          $localize `Something went wrong while deleting your contact form.`
        );
      });
  }

  setActive(contactForm: ContactForm, active: boolean) {
    const oldValue = contactForm.isActive;
    contactForm.isActive = active;
    contactForm.save()
      .then(() => this.contactFormChange.emit())
      .catch(error => {
        contactForm.isActive = oldValue;
        this.notificationService.handleError(
          error,
          $localize `Something went wrong while activating your contact form.`
        );
      });
  }


  duplicate(contactForm: ContactForm) {
    this.modelFactory.createInstance(ContactForm)
      .duplicateFromId(contactForm.id)
      .then(({ data: duplicatedContactForm }) => {
        location.href = this.getUpdateUrl(duplicatedContactForm);
      })
      .catch(error => {
        this.notificationService.handleError(
          error,
          $localize `Something went wrong while duplicating your contact form.`
        );
      });
  }

  track(event: string) {
    this.usageTrackingService.createSegmentEvent(event, 'contactForm');
  }
}
