import { Meeting } from './Meeting';

export class WaitingRoom extends Meeting {

  static get defaultInclude() {
    return Meeting.defaultInclude.concat(['isPersonalWaitingRoom', 'isTeamWaitingRoom']);
  }
}

export class UserWaitingRoom extends WaitingRoom {
  static listPath(identifiers) {
    return `users/${identifiers.userId}/waitingRooms`;
  }
}

export class OrganizationWaitingRoom extends WaitingRoom {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/waitingRooms`;
  }
}
