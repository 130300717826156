import { bind, getQueryParameter, platform, raf } from 'utils/util';
import { StreamState } from  'meeting/meeting-room/stream';
import template from './audioIndicator.html?raw';


const MIN_THRESHOLD = .4;  // levels below this relative threshold will show as 0
const MAX_THRESHOLD = .9;  // levels above this relative threshold will show as 1

const DRAW_MAX_RISE = 4;  // per second
const DRAW_MAX_FALL = .9;  // per second
const DRAW_PEAK_GLUE = 200;  // milliseconds



class AudioIndicatorController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    bind(this);

    this.$elem = $elem;

    this.minLevel = 0;
    this.maxLevel = 0;

    this.numLevels = 0;
    this.level = 0;

    this.drawLevel = 0;
    this.lastDrawLevel = 0;
    this.lastDrawTimestamp = 0;
    this.timestampOfDrawPeak = 0;
    this.renderRAF = null;

    this.$elemItems = [];
  }


  $onChanges(changes) {
    if(changes.hasOwnProperty('stream')) {
      if(!changes.stream.isFirstChange() && changes.stream.previousValue) {
        this._removeStream(changes.stream.previousValue);
      }
      if(this.stream) {
        this._addStream(this.stream);
      }
    }
    if(changes.hasOwnProperty('big')) {
      $timeout(() => {
        this.$elemItems = this.$elem.find('.audio-indicator__item');
      });
    }
  }


  _addStream(stream) {
    stream.eventEmitter.on('state enabled', this._updateIsActive);
    stream.eventEmitter.on('level', this._onLevel);
    this._updateIsActive();
  }

  _removeStream(stream) {
    stream.off('state enabled', this._updateIsActive);
    stream.off('level', this._onLevel);
    this._updateIsActive();
  }


  _updateIsActive() {
    let isActive = (
      this.stream
      && this.stream.enabled
      && (
        this.stream.state === StreamState.CONNECTING
        || this.stream.state === StreamState.CONNECTED
        || this.stream.state === StreamState.PLAYING
      )
    );

    if(!this.isActive && isActive) {
      this._start();
    } else if(this.isActive && !isActive) {
      this._stop();
    }
    this.isActive = isActive;
  }

  _start() {
    if(getQueryParameter('disableAudioMonitoring') != null) {
      return;
    }

    this.minLevel = 1;
    this.maxLevel = 0;
    this.level = 0;
    this.numLevels = 0;

    this._draw();
  }

  _stop() {
    raf.cancelAnimationFrame(this.renderRAF);
    this.renderRAF = null;
  }


  _onLevel(stream, level) {
    if(!this.isActive) {
      return;
    }

    this.level += level;
    this.numLevels += 1;

    if(level > 0.0001) {
      this.maxLevel = Math.max(this.maxLevel, level);
      this.minLevel = Math.min(this.minLevel, level);
    }
  }


  _draw() {
    let now = performance.now();
    let timeSinceRender = now - this.lastDrawTimestamp;
    this.lastDrawTimestamp = now;

    let level = this.level / this.numLevels;
    this.level = 0;
    this.numLevels = 0;

    let instantDrawLevel = this._getDrawLevel(level);
    let cappedDrawLevel = this._capDrawLevel(instantDrawLevel);
    this.drawLevel = this._smoothenDrawLevel(
      cappedDrawLevel,
      this.lastDrawLevel,
      now,
      timeSinceRender
    );

    if(Math.abs(this.drawLevel - this.lastDrawLevel) > .01) {
      this._drawElems();
      this.lastDrawLevel = this.drawLevel;
    }

    this.renderRAF = raf.requestAnimationFrame(this._draw);
  }


  _getDrawLevel(level) {
    if(!this.stream) {
      return 0;
    }

    let maxLevel = Math.max(this.maxLevel, 20 * this.minLevel);
    let drawLevel = Math.log(level / this.minLevel) / Math.log(maxLevel / this.minLevel);
    if(isNaN(drawLevel)) {
      drawLevel = 0;
    }
    return drawLevel;
  }


  _capDrawLevel(drawLevel) {
    return platform(
      0,
      (drawLevel - MIN_THRESHOLD) / (MAX_THRESHOLD - MIN_THRESHOLD),
      1
    );
  }


  _smoothenDrawLevel(drawLevel, lastDrawLevel, now, timeSinceRender) {
    if(drawLevel >= lastDrawLevel) {
      this.timestampOfDrawPeak = now;
    } else if(now - this.timestampOfDrawPeak < DRAW_PEAK_GLUE) {
      return lastDrawLevel;
    }

    let smoothDrawLevel = platform(
      lastDrawLevel - DRAW_MAX_FALL * timeSinceRender / 1000,
      drawLevel,
      lastDrawLevel + DRAW_MAX_RISE * timeSinceRender / 1000
    );
    return smoothDrawLevel;
  }


  _drawElems() {
    let numElems = this.$elemItems.length;

    let drawLevel = this.drawLevel * numElems;
    let lastDrawLevel = this.lastDrawLevel * numElems;

    for(let index = 0; index < numElems; index++) {
      let lastOpacity = platform(0, lastDrawLevel - index, 1);
      let opacity     = platform(0, drawLevel     - index, 1);
      if(opacity !== lastOpacity) {
        this.$elemItems[index].style.opacity = opacity;
      }
    }
  }
}


export default {
  controller: AudioIndicatorController,
  controllerAs: 'audioIndicatorCtrl',
  template,

  bindings: {
    stream: '<',
    big: '<',
  },
};
