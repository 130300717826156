
import { Component, Inject, OnInit } from '@angular/core';
import {
  AppointmentType, OrganizationAppointmentType, UserAppointmentType
} from 'scheduling/models/AppointmentType';
import { SchedulingState } from 'scheduling/variables';
import {
  DashboardScope, DataPageComponent
} from 'dashboard/data-page/data-page.component';
import { getAppoinmentTypeLocationBadges } from 'scheduling/utils/location-badges';
import { StateService } from 'utils/state.service';
import { Badge } from 'utils/ui-components/badge';
import { UrlService } from 'utils/url.service';
import {
  AppointmentTypeConfigurationCheckService
} from './appointment-type-configuration-check.service';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { UrlQueryParamsService } from 'scheduling/manage/appointments/url-query-params.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';


class ConfigurationAction {
  constructor(
    public warningText: string,
    public buttonText = $localize`Reconnect`,
  ) { }
}


// Used in appointment_types.html to be able to use django's reverse function to get the
// update url for an appointment type. We will replace the dummy appointment id with the correct id
// in this component.
const DUMMY_APPOINTMENT_ID = '00000000000000000000000000000000';


@Component({
  selector: 'appointment-types',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss'],
  providers: [StateService, UrlQueryParamsService]
})
export class AppointmentTypesComponent extends DataPageComponent implements OnInit {
  override perPage = 10;

  public appointmentTypes: AppointmentType[] = [];
  public isStripeConnected!: boolean;

  public isOpen = false;
  public testVariable = 'Variable';
  public noResultsMessage = '';

  constructor(
    @Inject('notificationService') private notificationService,

    public urlService: UrlService,
    public appointmentTypeConfigurationCheckService: AppointmentTypeConfigurationCheckService,
    private usageTrackingService: UsageTrackingService,
    private urlQueryParamsService: UrlQueryParamsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeQueryParams();
  }

  private initializeQueryParams(): void {
    const scope = this.urlQueryParamsService.queryParams.get('scope');
    if (scope) {
      this.scope = this.findScope(scope);
    }

    const page = this.urlQueryParamsService.queryParams.get('page');
    if (page) {
      this.page = parseInt(page, 10);
    }
  }

  public onScopeChange(scope: Scope): void {
    if (this.isDefaultScope(scope)) {
      this.urlQueryParamsService.removeQueryParam('scope');
    } else {
      this.urlQueryParamsService.setQueryParam('scope', scope.id);
    }
    this.scope = scope;
  }

  private isDefaultScope(scope: Scope): boolean {
    return scope === DashboardScope.PERSONAL;
  }

  public onPageChange(page: number): void {
    if (this.isDefaultPage(page)) {
      this.urlQueryParamsService.removeQueryParam('page');
    } else {
      this.urlQueryParamsService.setQueryParam('page', page.toString());
    }
    this.page = page;
  }

  private isDefaultPage(page: number): boolean {
    return page === 1;
  }

  /*************************
   * Fetch appointment types *
   **************************/

  override fetch() {
    return this.fetchAppointmentTypes();
  }

  private fetchAppointmentTypes() {
    const modelConfig = this.scope === DashboardScope.PERSONAL ?
      {
        model: UserAppointmentType,
        identifiers: { userId: this.requestUserService.user.id }
      } :
      {
        model: OrganizationAppointmentType,
        identifiers: { organizationId: this.requestUserService.user.organizationId }
      };

    const apiConfig = {
      params: {
        perPage: this.perPage,
        page: this.page,
        orderBy: '-active',
      },
    };
    const query = this.searchQuery;
    if (query) {
      apiConfig.params['search'] = this.searchQuery;
    }

    return this.listCancelable(modelConfig, apiConfig)
      .then(({ data: appointmentTypes }) => {
        this.appointmentTypes = appointmentTypes;
        this.noResultsMessage = appointmentTypes.length === 0 ?
          query ?
            $localize`No meeting types matched your search.` :
            $localize`You don't have any meeting types yet.` :
          '';
      });
  }


  get configurationAction(): ConfigurationAction | null {
    const schedulingState: SchedulingState = this.requestUserService.user.schedulingConfig.state;
    switch (schedulingState) {
      case SchedulingState.NEW: {
        return new ConfigurationAction(
          // eslint-disable-next-line max-len
          $localize`You have not set up your calendar connection. Until you set up this connection, your customers won't be able to book you.`,
          $localize`Connect a calendar`
        );
      }
      case SchedulingState.AUTHENTICATED: {
        return new ConfigurationAction(
          // eslint-disable-next-line max-len
          $localize`We couldn't set up your calendar connection. Perhaps you have not given all the required permissions? Click the button below to reconnect your calendar.`,
        );
      }
      case SchedulingState.AUTHORIZED: {
        return new ConfigurationAction(
          // eslint-disable-next-line max-len
          $localize`You have connected your calendar but we need some more information before people can start booking meetings with you.`,
          $localize`Finish your configuration`
        );
      }
      case SchedulingState.EXPIRED: {
        return new ConfigurationAction(
          // eslint-disable-next-line max-len
          $localize`Your calendar has been disconnected because your credentials expired. Please reconnect your calendar.`,
        );
      }

      default: {
        return null;
      }
    }
  }

  canEdit(appointmentType): boolean {
    const user = this.requestUserService.user;
    const hosts = this.getHosts(appointmentType);
    return (
      user.isAdmin
      || (hosts.length === 1 && hosts[0].id === user.id)
    );
  }

  canDuplicate(appointmentType): boolean {
    return (
      this.canEdit(appointmentType)
      && this.canCreate
    );
  }

  get canCreate(): boolean {
    return (
      !this.hasFreeSubscription
      || this.existingActiveAppointmentTypes.length < 1
    );
  }

  get canBulkUpdate(): boolean {
    return !this.hasFreeSubscription;
  }


  get existingActiveAppointmentTypes() {
    return this.appointmentTypes.filter(
      appointmentType => {
        const hasActiveConfig = appointmentType.appointmentTypeConfigs.some(
          config => config.userId === this.requestUserService.user.id && config.active
        );
        return (
          appointmentType.active
          && hasActiveConfig
        );
      }
    );
  }

  get hasFreeSubscription() {
    return this.requestUserService.user.subscription.status === 'free';
  }

  getLocationBadges(appointmentType: AppointmentType): Badge[] {
    return getAppoinmentTypeLocationBadges(appointmentType);
  }


  get shouldShowTeamBookingLinks() {
    return (
      !this.requestUserService.user.organization.hasOneActiveMember
      && !this.hasFreeSubscription
    );
  }

  getHosts(appointmentType) {
    return appointmentType.appointmentTypeConfigs
      .filter(config => config.active)
      .map(config => config.user)
      .sort(this._sortHosts);
  }

  _sortHosts(user1, user2): number {
    if (user1.id === this.requestUserService.user.id) {
      return -1;
    } else if (user2.id === this.requestUserService.user.id) {
      return 1;
    } else {
      return user1.fullName.localeCompare(user2.fullName);
    }
  }

  getUpdateUrl(appointmentType: AppointmentType) {
    return this.urlService.urls.appointmentTypeUpdate.replace(
      DUMMY_APPOINTMENT_ID, appointmentType.id
    );
  }

  getSharingOptionsTitle(appointmentType: AppointmentType) {
    return $localize`Share: ${appointmentType.name}`;
  }

  /*********
   * Actions *
   **********/

  setActive(appointmentType, active) {
    appointmentType.active = active;
    appointmentType.save()
      .catch(error => {
        appointmentType.active = !active;
        this.notificationService.handleError(
          error,
          $localize`Something went wrong while updating your meeting type.`,
        );
      });
  }

  getActivateDisabledTooltip(appointmentType: AppointmentType) {
    if (this.appointmentTypeConfigurationCheckService.hasNoHostsLinked(appointmentType)) {
      // eslint-disable-next-line max-len
      return $localize`You need to link at least one team member to this meeting type before you can activate it.`;
    } else {
      return $localize`Upgrade your subscription to have more than one active meeting type`;
    }
  }

  isActivateDisabled(appointmentType: AppointmentType): boolean {
    return (
      // If not allowed to create, only allow deactivating
      (!this.canCreate && !appointmentType.active)
      // If no hosts linked, don't allow activating
      || this.appointmentTypeConfigurationCheckService.hasNoHostsLinked(appointmentType)
    );
  }


  duplicate(appointmentType) {
    this.modelFactory.createInstance(AppointmentType)
      .duplicateFromId(appointmentType.id)
      .then(({ data: duplicatedAppointmentType }) => {
        location.href = this.getUpdateUrl(duplicatedAppointmentType);
      })
      .catch(error => {
        this.notificationService.handleError(
          error,
          $localize`Something went wrong while duplicating your meeting type.`
        );
      });
  }


  delete(appointmentType) {
    appointmentType.delete()
      .catch(error => {
        this.notificationService.handleError(
          error,
          $localize`Something went wrong while deleting your meeting type.`
        );
      })
      .finally(() => {
        this.fetchAppointmentTypes();
      });
  }

  /**********************
 * Configuration issues *
 ***********************/

  configurationIssueWarnings(appointmentType: AppointmentType): string[] {
    return this.appointmentTypeConfigurationCheckService.configurationIssueWarnings(
      appointmentType
    );
  }

  /****************
 * Segment events *
 *****************/

  trackStartedCreatingEvent() {
    this.usageTrackingService.createSegmentEvent(
      'appointmentType.startedCreating',
      'appointmentTypeConfig',
    );
  }
}
