import { ContactWidget } from 'contactWidget/models/ContactWidget';
import { AppointmentType, OrganizationAppointmentType } from 'scheduling/models/AppointmentType';
import { Member } from 'organization/models/Member';
import { ContactForm, OrganizationContactForm } from 'contactForm/models/ContactForm';
import { WaitingRoom, OrganizationWaitingRoom } from 'meeting/models/WaitingRoom';
import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { StateService } from 'utils/state.service';
import { UrlService } from 'utils/url.service';

import { Errors } from 'utils/settings/settings.component';
import { errors, logger } from 'utils/util';
import { TeamMembersService } from 'organization/teamMembers.service';
import { RequestUserService } from 'utils/requestUser.service';
import {
  SchedulingOnboardingWizardService
} from 'scheduling/manage/scheduling-onboarding-wizard/scheduling-onboarding-wizard.service';
import { UsageTrackingService } from 'utils/usageTracking.service';


@Component({
  selector: 'contact-widget-config',
  templateUrl: './contact-widget-config.component.html',
})
export class ContactWidgetConfigComponent {
  public contactWidget?: ContactWidget;
  public errors: Errors = {};
  public fetchError?: string;
  public isSaving = false;

  @ViewChild('modalSuccess') modalSuccess!: TemplateRef<any>;
  public appointmentTypes: AppointmentType[] = [];
  public teamMembers: Member[] = [];
  public contactForms: ContactForm[] = [];
  public waitingRooms: WaitingRoom[] = [];

  get contactWidgetId(): string {
    return ANGULAR_SCOPE.contactWidgetId;
  }

  constructor(
    @Inject('modelFactory') private modelFactory,
    @Inject('notificationService') private notificationService,

    private usageTrackingService: UsageTrackingService,
    public schedulingOnboardingWizardService: SchedulingOnboardingWizardService,
    private requestUserService: RequestUserService,
    private teamMemberService: TeamMembersService,
    public stateService: StateService,
    public urlService: UrlService,
  ) {
    this.initialize();
  }


  /*******************
   * INITIALIZATION *
   ******************/

  private async initialize() {
    this.stateService.setState(this.stateService.State.LOADING);

    const fetchTeamMembersPromise = this.fetchTeamMembers();
    const fetchAppointmentTypePromise = this.fetchAppointmentTypes();
    const fetchContactFormPromise = this.fetchContactForms();
    const fetchWaitingRoomsPromise = this.fetchWaitingRooms();
    const fetchContactWidgetPromise = this.setContactWidget();

    return Promise.all([
      fetchTeamMembersPromise,
      fetchContactWidgetPromise,
      fetchAppointmentTypePromise,
      fetchContactFormPromise,
      fetchWaitingRoomsPromise,
    ])
      .finally(() => this.stateService.setState(this.stateService.State.READY))
      .catch(error => this.handleFetchError(error));
  }

  private async setContactWidget(): Promise<ContactWidget> {
    if(this.contactWidgetId) {
      this.contactWidget =  await this.fetchContactWidget();
    } else {
      this.contactWidget =  this.initializeNewContactWidget();
    }
  }

  private async fetchContactWidget(): Promise<ContactWidget> {
    const response = await this.modelFactory.read({
      model: ContactWidget,
      identifiers: { id: this.contactWidgetId },
    });
    const contactWidget: ContactWidget = response.data;
    return contactWidget;
  }

  private async fetchTeamMembers() {
    this.teamMembers = await this.teamMemberService.get();
  }

  private initializeNewContactWidget(): ContactWidget  {
    const values: any = {
      text: $localize `Book a meeting`,
      showIcon: true,
    };
    if(this.requestUserService.user.organization.hasOneActiveMember) {
      values.user = this.requestUserService.user;
    } else {
      values.organization = this.requestUserService.user.organization;
    }
    return this.modelFactory.createInstance(ContactWidget, {
      values: values,
    });
  }

  private async fetchAppointmentTypes() {
    const apiConfig = {
      params: {
        perPage: 'all',
        orderBy: '-active',
      },
    };

    const response = await this.modelFactory.list({
      model: OrganizationAppointmentType,
      identifiers: { organizationId: this.requestUserService.user.organizationId }
    }, apiConfig);
    this.appointmentTypes = response.data as AppointmentType[];
  }

  private async fetchContactForms() {
    const apiConfig = {
      params: {
        perPage: 'all',
        orderBy: '-isActive',
      },
    };

    const response = await this.modelFactory.list({
      model: OrganizationContactForm,
      identifiers: { organizationId: this.requestUserService.user.organizationId }
    }, apiConfig);
    this.contactForms = response.data as ContactForm[];
  }

  private async fetchWaitingRooms() {
    // Currently only waiting rooms
    const response = await this.modelFactory.list({
      model: OrganizationWaitingRoom,
      identifiers: { organizationId: this.requestUserService.user.organizationId }
    });
    this.waitingRooms = response.data as WaitingRoom[];
  }

  async save() {
    if(this.isSaving) {
      return;
    }

    this.setIsSaving(true);
    try {
      if(this.contactWidget.id) {
        await this.contactWidget.update();
        this.track('contactWidget.updated');
      } else {
        await this.contactWidget.create();
        this.track('contactWidget.created');
      }
      this.clearSaveError();
      this.showSuccessNotification();

    } catch(error: any) {
      this.errors = this.notificationService.handleError(
        error,
        $localize `Something went wrong while saving your contact widget.`,
        'page',
      );
    } finally {
      // Set a timeout so the "processing" state doesn't just immediately goes back to the
      // initial state.
      setTimeout(() => this.setIsSaving(false), 3000);
    }
  }

  setIsSaving(isSaving) {
    this.isSaving = isSaving;
  }

  clearSaveError() {
    this.errors = {};
  }

  private showSuccessNotification() {
    this.notificationService.success(
      $localize `Contact widget successfully saved!`,
      {
        persistOnReload: true,
      }
    );
    this.goToOverview();
  }

  private goToOverview() {
    window.location.href = this.urlService.urls.contactWidgetsOverview;
  }


  private handleFetchError(error) {
    /* eslint-disable max-len */
    if(error.constructor === errors.DoesNotExistError) {
      this.fetchError = $localize `We couldn't find the widget you were looking for. Perhaps it was removed, or you don't have access?`;
    } else if(error.constructor === errors.OfflineError) {
      this.fetchError = $localize `You seem to be offline. Please check your internet connection and reload the page.`;
    } else {
      logger.warn(error);
      this.fetchError = $localize `Something went wrong while fetching your widget. Please try again later.`;
    }
    /* eslint-enable max-len */
  }

  get canSave() {
    const isSchedulingContext = (
      this.contactWidget.appointmentType
      || this.contactWidget.organization
      || this.contactWidget.user
    );
    return (
      (
        isSchedulingContext
        && this.schedulingOnboardingWizardService.hasSeenWizard
      )
      || this.contactWidget.contactForm
      || this.contactWidget.meeting
    );
  }

  get saveWidgetText() {
    if(this.contactWidget.id) {
      return $localize `Update`;
    } else {
      return $localize `Save`;
    }
  }
  get savingWidgetText() {
    if(this.contactWidgetId) {
      return $localize `Updating...`;
    } else {
      return $localize `Saving...`;
    }
  }


  track(event: string) {
    this.usageTrackingService.createSegmentEvent(event, 'contactWidget');
  }
}
