import {
  array,
  browser,
  errors,
  format,
  logger,
  object,
} from 'utils/util';
import MediaDevice from '../../shared/audioVideo/MediaDevice';
import { StreamType, StreamTypeToDeviceKind } from  'meeting/meeting-room/stream';



export default class LocalStreamService {
  static get $inject() {
    return [
      '$interpolate',
      'chromeExtensionService',
      'notificationService',
      'mediaDeviceService',
      'meetingService',
      'permissionArrowService',
      'pictureInPictureService',
      'screenshareStreamService',
      'settingsService',
      'siteService',
      'streamFactory',
      'streamService',
      'userService',
    ];
  }

  constructor(
    $interpolate,
    chromeExtensionService,
    notificationService,
    mediaDeviceService,
    meetingService,
    permissionArrowService,
    pictureInPictureService,
    screenshareStreamService,
    settingsService,
    siteService,
    streamFactory,
    streamService,
    userService
  ) {
    this._bind();

    this.$interpolate = $interpolate;
    this.chromeExtensionService = chromeExtensionService;
    this.notificationService = notificationService;
    this.mediaDeviceService = mediaDeviceService;
    this.meetingService = meetingService;
    this.permissionArrowService = permissionArrowService;
    this.pictureInPictureService = pictureInPictureService;
    this.screenshareStreamService = screenshareStreamService;
    this.settingsService = settingsService;
    this.siteService = siteService;
    this.streamFactory = streamFactory;
    this.streamService = streamService;
    this.userService = userService;

    this.streams = {};
    Object.values(StreamType).forEach(type => this.streams[type] = {});
    this.maxScreenGroupId = 0;

    this.addRemovePromise = $q.resolve();

    this.userService.mySession.on('state', this._onLocalSessionState);
    this.streamService.on('add', this._onAdd);
    this.streamService.on('remove', this._onRemove);
  }

  _bind() {
    this._onLocalSessionState = this._onLocalSessionState.bind(this);
    this.remove = this.remove.bind(this);
    this._onAdd = this._onAdd.bind(this);
    this._onRemove = this._onRemove.bind(this);
  }

  _onLocalSessionState() {
    // This happens when you push "Leave"
    let session = this.userService.mySession;
    if(session.isDead()) {
      Object.values(this.streams).forEach(streamsOfType => {
        Object.values(streamsOfType).forEach(this.remove);
      });
    }
  }



  /*****************************************
   * Create MediaStreams with getUserMedia *
   *****************************************/

  /**
   * Simply wrap getUserMedia in a angular promise
   */
  _getUserMedia(constraints) {
    return $q.resolve().then(() => {
      return navigator.mediaDevices.getUserMedia(constraints);  // eslint-disable-line compat/compat, max-len
    });
  }


  _getAudioVideoStream(types) {
    let constraints = {};
    let devices = {};
    let mediaStream = null;

    if(
      types.includes(StreamType.AUDIO)
      && object.length(this.streams[StreamType.AUDIO]) === 0
    ) {
      constraints.audio = this._getBaseAudioConstraints();
    }
    if(
      types.includes(StreamType.VIDEO)
      && object.length(this.streams[StreamType.VIDEO]) === 0
    ) {
      constraints.video = this._getBaseVideoConstraints();

      // Check if the camera is PTZ capable, and request access
      if(this.meetingService.settings.allowVideoViewport) {
        const supports = navigator.mediaDevices.getSupportedConstraints();
        if(supports.pan && supports.tilt && supports.zoom) {
          Object.assign(constraints.video, {
            pan: true,
            tilt: true,
            zoom: true,
          });
        }
      }
    }



    return this.mediaDeviceService.update()
      .then(() => {
        for(let type in constraints) {
          let kind = StreamTypeToDeviceKind[type];
          let device = this.mediaDeviceService.getPreferredDevice(kind);
          if(device && device.id) {
            devices[type] = device;
            constraints[type].deviceId = { exact: device.id };
            delete constraints[type].facingMode;
          }
        }

        this._showPermissionArrow(types);

        return this._getUserMedia(constraints);
      })
      .then(argMediaStream => {
        mediaStream = argMediaStream;
        // Update the mediaDevices. If we received new permissions, this makes sure device.label
        // gets set
        return this.mediaDeviceService.update();
      })
      .then(() => {
        return {
          devices: devices,
          mediaStream: mediaStream,
        };
      })
      .finally(() => {
        this._cancelPermissionArrow(types);
      });
  }


  _getBaseAudioConstraints() {
    return {
      echoCancellation: true,
    };
  }

  _getBaseVideoConstraints() {
    let constraints = {
      height: {
        ideal: 720,
      },
      aspectRatio: {
        ideal: 16 / 9,
      },
      facingMode: 'user',
      advanced: [],
    };

    // The getUserMedia request to the bednet PTZ camera never resolves when setting a framerate
    // different than 30.
    if(
      browser.supportsFramerate()
      && this.meetingService.bednetLocation !== this.meetingService.BednetLocation.CLASSROOM
    ) {
      constraints.frameRate = { ideal: 24 };
    } else {
      // Basically every device out there supports framerate 30
      constraints.frameRate = { ideal: 30 };
    }

    return constraints;
  }



  /*****************************
   * Show the permission arrow *
   *****************************/

  _showPermissionArrow(types) {
    if(this._shouldShowPermissionArrow(types)) {
      this.permissionArrowService.show();
    }
  }

  _cancelPermissionArrow() {
    this.permissionArrowService.hide();
  }

  _shouldShowPermissionArrow(types) {
    let shouldShow = false;
    types.forEach(type => {
      let deviceKind = StreamTypeToDeviceKind[type];
      if(!this.mediaDeviceService.permissions[deviceKind]) {
        shouldShow = true;
      }
    });

    return shouldShow;
  }



  /**************************
   * Start and stop streams *
   **************************/

  add(types, screenType) {
    if(types.length === 0) {
      return;
    }

    if(!this._checkTypes(types)) {
      throw new errors.InvalidArgumentError(
        'Invalid types list to start stream: ' + types.join(', '));
    }

    this.addRemovePromise = this.addRemovePromise
      .then(() => {
        if(types[0] === StreamType.SCREEN || types[0] === StreamType.COBROWSE) {
          return this._addScreenStream(types, screenType);

        } else {
          return this._addAudioVideoStream(types);
        }

      }).catch(error => {
        if(
          error
          && error.constructor !== errors.EscapePromiseError
          && error.message !== 'A Chrome Web Store installation is already pending.'
        ) {
          this._showError(types, error);
        }
      });

    return this.addRemovePromise;
  }


  remove(stream) {
    this.addRemovePromise = this.addRemovePromise.then(() => this._removeStream(stream));
    return this.addRemovePromise;
  }



  _checkTypes(types) {
    if(types[0] === StreamType.SCREEN) {
      return (
        types.length === 1
        || types.length === 2 && types[1] === StreamType.SCREEN_AUDIO
      );

    } else if(types[0] === StreamType.COBROWSE) {
      return (
        types.length === 1
        || types.length === 2 && types[1] === StreamType.COBROWSE_AUDIO
      );

    } else {
      return (
        types.length === 1
        && array.has([StreamType.AUDIO, StreamType.VIDEO], types[0])
        || types.length === 2
        && types[0] === StreamType.VIDEO && types[1] === StreamType.AUDIO
      );
    }
  }


  _addScreenStream(types, screenType) {
    // Don't start PiP when we know the browser doesn't support screenshare: Safari doesn't handle
    // entering and immediately leaving PiP well.
    let shouldEnterPictureInPicture = (
      types[0] === StreamType.SCREEN
      && this.screenshareStreamService.supports(types[0])
    );
    if(shouldEnterPictureInPicture) {
      this.pictureInPictureService.enterImplicit();
    }

    return this.screenshareStreamService.get(types[0], screenType)
      .then(mediaStream => {
        let groupId = this._getUniqueScreenGroupId();
        let videoTrack = mediaStream.getVideoTracks()[0];
        this._addStream(types[0], groupId, videoTrack, null, screenType);

        let audioTrack = mediaStream.getAudioTracks()[0];
        if(audioTrack) {
          this._addStream(types[1], groupId, audioTrack);
        }
      })
      .catch(error => {
        if(shouldEnterPictureInPicture) {
          this.pictureInPictureService.leaveImplicit();
        }
        throw error;
      });
  }


  _getUniqueScreenGroupId() {
    return format('screen-%s', ++this.maxScreenGroupId);
  }


  _addAudioVideoStream(types) {
    let typesToAdd;

    return $q.resolve()
      .then(() => {
        let allowedTypes = types.filter(type => object.length(this.streams[type]) === 0);
        let availableTypes = this._getAvailableStreamTypes();
        let unavailableTypes = allowedTypes.filter(type => {
          return !array.has(availableTypes, type);
        });

        if(unavailableTypes.length > 0) {
          let error = new errors.DoesNotExistError(format(
            'No device of type %s found.', unavailableTypes.join(', ')));
          this._showError(unavailableTypes, error);
        }

        typesToAdd = availableTypes.filter(type => {
          return array.has(allowedTypes, type);
        });

        if(typesToAdd.length > 0) {
          return this._getAudioVideoStream(typesToAdd);
        } else {
          throw new errors.EscapePromiseError('typesToAdd is empty, expecting length > 0');
        }
      })
      .then(info => {
        let devices = info.devices;
        let mediaStream = info.mediaStream;

        if(array.has(typesToAdd, StreamType.AUDIO)) {
          let audioTrack = mediaStream.getAudioTracks()[0];
          if(audioTrack) {
            this._addStream(
              StreamType.AUDIO,
              'user',
              audioTrack,
              devices[StreamType.AUDIO]
            );
          } else {
            logger.info('No audio tracks found');
            this._showError([StreamType.AUDIO], 'NoTracksError');
          }
        }

        if(array.has(typesToAdd, StreamType.VIDEO)) {
          let videoTrack = mediaStream.getVideoTracks()[0];
          if(videoTrack) {
            let capabilities = videoTrack.getCapabilities ? videoTrack.getCapabilities() : {};
            let supportsPTZ = (
              this.meetingService.settings.allowVideoViewport
              && capabilities.pan
              && capabilities.tilt
              && capabilities.zoom
            );
            this._addStream(
              StreamType.VIDEO,
              'user',
              videoTrack,
              devices[StreamType.VIDEO],
              null,
              supportsPTZ
            );
          } else {
            logger.info('No video tracks found');
            this._showError([StreamType.VIDEO], 'NoTracksError');
          }
        }
      });
  }


  _getAvailableStreamTypes() {
    let availableKinds = this.mediaDeviceService.getAvailableKinds();
    let availableTypes = [];
    if(array.has(availableKinds, 'audioinput')) {
      availableTypes.push(StreamType.AUDIO);
    }
    if(array.has(availableKinds, 'videoinput')) {
      availableTypes.push(StreamType.VIDEO);
    }

    return availableTypes;
  }


  _addStream(type, groupId, track, device, screenType, supportsPTZ) {
    if(device == null) {
      device = this._guessTrackDevice(type, track, device);
    }

    if(device == null || !device.active) {
      let session = this.userService.mySession;
      let stream = this.streamFactory.create(type, track.id, groupId, session);
      stream.setTrack(track);
      stream.setInputDevice(device);
      if(screenType) {
        stream.setScreenType(screenType);
      }
      if(supportsPTZ) {
        stream.enablePTZ();
      }

      this._onAdd(stream);
      stream.start();
      this.streamService.addLocal(stream);
    }
  }

  _removeStream(stream) {
    this._onRemove(stream, true);
    this.streamService.removeLocal(stream);
  }


  _onAdd(stream) {
    if(!stream.session.isLocal) {
      return;
    }

    this.streams[stream.type][stream.id] = stream;
    stream.on('ended', this.remove);
  }

  _onRemove(stream) {
    if(!stream.session.isLocal) {
      return;
    }

    stream.off('ended', this.remove);
    delete this.streams[stream.type][stream.id];
  }


  _guessTrackDevice(type, track) {
    if(type !== StreamType.VIDEO && type !== StreamType.AUDIO) {
      return null;
    }

    let deviceLabel = track.label;
    let deviceKind = StreamTypeToDeviceKind[type];
    let devicesOfType = Object.values(this.mediaDeviceService.devices[deviceKind]);
    let guessedDevice;

    if(devicesOfType.length === 1) {
      guessedDevice = devicesOfType[0];

    } else if(deviceLabel) {
      guessedDevice = devicesOfType.find(device => device.label === deviceLabel);
    }

    return guessedDevice;
  }


  setEnabled(stream, enabled) {
    stream.setEnabled(enabled);
  }



  /****************************
   * Show error notifications *
   ****************************/

  _showError(streamTypes, error) {
    let message;
    if(streamTypes[0] === StreamType.SCREEN || streamTypes[0] === StreamType.COBROWSE) {
      message = this._getScreenCobrowseErrorMessage(streamTypes[0], error);
    } else {
      message = this._getAudioVideoErrorMessage(streamTypes, error);
    }

    let notification;
    if(message.message) {
      notification = this.notificationService.error(message.message);
    }
    if(message.log) {
      logger.warn(error);
    } else {
      logger.info(error);
    }

    return notification;
  }

  _getAudioVideoErrorMessages(device) {
    /* eslint-disable max-len */
    return Object.freeze({
      PermissionDismissedError: gettextCatalog.getString('We could not connect to your {{ device }}. Accept the browser notification to start sharing your {{ device }}. <a {{ helpUrl }}>Get help</a>.', { device: device, helpUrl: `href="${this.siteService.getHelpArticle('enableMicCamPermissions')}" target="_blank"` }),
      NotAllowedError: gettextCatalog.getString('We could not connect to your {{ device }}. Unblock the device in the browser settings. <a {{ helpUrl }}>Get help</a>.', { device: device, helpUrl: `href="${this.siteService.getHelpArticle('unknownDeviceError')}" target="_blank"` }),
      DoesNotExistError: gettextCatalog.getString('We could not find a {{ device }}. Connect a device or unblock the device in the browser settings. <a {{ helpUrl }}>Get help</a>.', { device: device, helpUrl: `href="${this.siteService.getHelpArticle('unknownDeviceError')}" target="_blank"` }),
      NotReadableError: gettextCatalog.getString('We could not connect to your {{ device }}. Make sure the device is not used by another application, or try restarting your browser.', { device: device }),
      FailedError: gettextCatalog.getString('We could not connect to your {{ device }}. <a {{ helpUrl }}>Get help</a>.', { device: device, helpUrl: `href="${this.siteService.getHelpArticle('unknownDeviceError')}" target="_blank"` }),
      EnumerateDevicesTimeoutError: gettextCatalog.getString('We could not connect to your {{ device }}. Reload the page or <a {{ helpUrl }}>get help</a>.', { device: device, helpUrl: `href="${this.siteService.getHelpArticle('unknownDeviceError')}" target="_blank"` }),
    });
    /* eslint-enable max-len */
  }

  _getAudioVideoErrorMessage(streamTypes, error) {
    let errorName = this._getErrorName(error);
    if(streamTypes.length > 2) {
      logger.error('Invalid streamTypes list:', streamTypes);
      return;
    }

    let deviceNames = streamTypes
      .map(type => StreamTypeToDeviceKind[type])
      .filter(angular.identity)
      .map(kind => gettextCatalog.getString(MediaDevice.Name[kind]));

    let device = array.joinVerbose(deviceNames, ',', gettextCatalog.getString('and'));
    let messageTexts = this._getAudioVideoErrorMessages(device);

    let logError = false;
    if(!errorName || !messageTexts.hasOwnProperty(errorName)) {
      errorName = 'FailedError';
      logError = true;
    }

    return {
      message: messageTexts[errorName],
      log: logError,
    };
  }

  get _screenErrorMessages() {
    /* eslint-disable max-len */
    return Object.freeze({
      NotSupportedError: browser.errorMessages.screenshare(),

      NotAllowedError: (
        browser.isFirefox() ?
          gettextCatalog.getString('You haven\'t given permission to share a screen. <a {{ helpUrl }}>Get help</a>', { helpUrl: `href="${this.siteService.getHelpArticle('screenshareBlockedFirefox')}" target="_blank"` }) :
          browser.isSafari() ?
            gettextCatalog.getString('You haven\'t given permission to share a screen. <a {{ helpUrl }}>Get help</a>', { helpUrl: `href="${this.siteService.getHelpArticle('screenshareBlockedSafari')}" target="_blank"` }) :
            null  // Chrome: interface is clear enough, we don't need a warning
      ),

      NotAllowedBySystemError: gettextCatalog.getString('Your system does not allow sharing your screen. <a {{ helpUrl }}>Get help</a>', { helpUrl: `href="${this.siteService.getHelpArticle('screenshareBlockedMac')}" target="_blank"` }),
      ChromeInstallPopupDismissed: null,
      PermissionDeniedError: null, // Chrome: click "cancel" in popup

      FailedError: gettextCatalog.getString('An unknown error occurred while trying to share your screen. Try restarting your browser. We\'re sorry for the inconvenience.'),
    });
    /* eslint-enable max-len */
  }

  get _cobrowseErrorMessages() {
    /* eslint-disable max-len */
    return Object.freeze({
      NotSupportedError: browser.errorMessages.cobrowse(),
      ChromeInstallPopupDismissed: null,
      PermissionDeniedError: null, // Chrome: click "cancel" in popup

      FailedError: gettextCatalog.getString('An unknown error occurred while trying to start cobrowsing. Try restarting your browser. We\'re sorry for the inconvenience.'),
    });
    /* eslint-enable max-len */
  }

  _getScreenCobrowseErrorMessages(streamType) {
    switch(streamType) {
      case StreamType.SCREEN:
        return this._screenErrorMessages;
      case StreamType.COBROWSE:
        return this._cobrowseErrorMessages;
    }
  }

  _getScreenCobrowseErrorMessage(streamType, error) {
    let errorName = this._getErrorName(error);
    let logError = false;
    if(!errorName || !this._getScreenCobrowseErrorMessages(streamType).hasOwnProperty(errorName)) {
      errorName = 'FailedError';

      if(errorName) {
        logError = true;
      }
    }

    return {
      message: this._getScreenCobrowseErrorMessages(streamType)[errorName],
      log: logError,
    };
  }


  _getErrorName(error) {
    let errorName = (!error || typeof error === 'string') ?
      error :
      error.name === 'Error' ?
        error.constructor.name :
        error.name;
    if(errorName === 'NotAllowedError' && error.message === 'Permission denied by system') {
      errorName = 'NotAllowedBySystemError';
    }
    return errorName;
  }
}
