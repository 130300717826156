import { array, platform, Rect } from 'utils/util';


export function getLayout(tiles, rect, tileMargin) {
  if(tiles.length === 0) {
    return {
      rect: rect,
      tiles: [],
      loss: 0,
    };
  }

  let numRowOptions = array.range(1, tiles.length + 1);
  let layouts = numRowOptions.map(getLayoutWithNumRows.bind(null, tiles, rect, tileMargin));
  return getBestLayout(layouts);
}


export function getBestLayout(layouts) {
  return array.min(layouts, layout => layout.loss);
}


export function getFlexibleLayout(tiles, maxSize, aspectRatio) {
  if(tiles.length === 1) {
    let tile = tiles[0];
    let width, height;
    if(tile.aspectRatio > 1) {
      width = maxSize;
      height = width / tile.aspectRatio;
    } else {
      height = maxSize;
      width = height * tile.aspectRatio;
    }

    let rect = new Rect({
      top: 0,
      left: 0,
      width: width,
      height: height
    });

    return {
      tiles: [
        {
          tile: tiles[0],
          rect: rect,
          loss: 0,
        }
      ],
      rect: rect,
      loss: 0,
    };

  } else {
    let layoutLandscape = getLayout(tiles, new Rect({
      top: 0,
      left: 0,
      width: maxSize,
      height: maxSize / aspectRatio,
    }), 0);
    let layoutPortrait = getLayout(tiles, new Rect({
      top: 0,
      left: 0,
      width: maxSize / aspectRatio,
      height: maxSize,
    }), 0);
    return getBestLayout([layoutLandscape, layoutPortrait]);
  }
}


function getLayoutWithNumRows(tiles, rect, tileMargin, numRows) {
  let numTiles = tiles.length;
  let numWideRows = numRows - (numTiles % numRows);

  let tileIndex = 0;
  let totalLoss = 0;
  let layoutTiles = Array(numTiles);
  let top = rect.top;

  for(let rowI = 0; rowI < numRows; rowI++) {
    let rowHeight = getTileSize(rect.height, tileMargin, numRows, rowI);
    let numTilesInRow = Math.floor(numTiles / numRows) + (rowI < numWideRows ? 0 : 1);

    let left = rect.left;
    for(let colI = 0; colI < numTilesInRow; colI++) {
      let tile = tiles[tileIndex];
      let colWidth = getTileSize(rect.width, tileMargin, numTilesInRow, colI);
      let loss = calculateLoss(tile, colWidth / rowHeight);

      layoutTiles[tileIndex] = {
        tile: tile,
        rect: new Rect({
          top: top,
          left: left,
          width: colWidth,
          height: rowHeight,
        }),
        loss: loss,
      };

      tileIndex++;
      totalLoss += loss;
      left += colWidth + tileMargin;
    }

    top += rowHeight + tileMargin;
  }

  return {
    rect: rect,
    tiles: layoutTiles,
    loss: totalLoss,
  };
}


function getTileSize(totalSize, tileMargin, numItems, index) {
  let totalTileSize = totalSize - (numItems - 1) * tileMargin;
  return (
    Math.round(totalTileSize / numItems * (index + 1))
    - Math.round(totalTileSize / numItems * index));
}



function calculateLoss(tile, renderAspectRatio) {
  // Don't care about widescreens
  let nativeAspectRatio = platform(3 / 4, tile.aspectRatio, 4 / 3);

  // We don't care about loss when there is no video, except when there are only tiles without
  // video.
  let multiplierNoVideo = tile.videoActive ? 1 : 0.01;

  // Cuttoff from the wide dimension of a stream is less important than the narrow dimension
  let multiplierWidePart = (nativeAspectRatio > 1 === renderAspectRatio > nativeAspectRatio) ?
    1 :
    0.5;


  let loss = nativeAspectRatio > renderAspectRatio ?
    (1 - renderAspectRatio / nativeAspectRatio) :
    (1 - nativeAspectRatio / renderAspectRatio);


  return loss * multiplierNoVideo * multiplierWidePart;
}
