export function freeze(set) {
  let frozenSet = new Set(set);

  let overrideProperties = ['add', 'delete', 'clear'];
  function raiseError() {
    throw new TypeError('Cannot preform this action on a frozen set');
  }

  overrideProperties.forEach(property => {
    Object.defineProperty(frozenSet, property, {
      value: raiseError,
    });
  });

  return frozenSet;
}


export function filter(set, fn) {
  return new Set([...set].filter(fn));
}


export function union(set1, set2) {
  return new Set([...set1, ...set2]);
}

export function intersection(set1, set2) {
  return new Set([...set1].filter(element => set2.has(element)));
}

export function difference(set1, set2) {
  return new Set([...set1].filter(element => !set2.has(element)));
}
