export default class NoiseSuppressionEffect {
  constructor(audioContext, mediaStream) {
    this.audioContext = audioContext;

    this.audioSource = this.audioContext.createMediaStreamSource(mediaStream);
    this.audioDestination = this.audioContext.createMediaStreamDestination();
  }


  get mediaStreamSource() {
    return this.audioSource.mediaStream;
  }

  get mediaStreamDestination() {
    return this.audioDestination.stream;
  }

  get trackSource() {
    return this.mediaStreamSource.getAudioTracks()[0];
  }

  get trackDestination() {
    return this.mediaStreamDestination.getAudioTracks()[0];
  }


  start() {
    const workletUrl = ANGULAR_SCOPE.noiseSuppressorWorkletSrc;
    return this.audioContext.audioWorklet.addModule(workletUrl)
      .then(() => {
        if(window.AudioWorkletNode) {
          this.noiseSuppressorNode = new AudioWorkletNode(
            this.audioContext,
            'NoiseSuppressorWorklet',
            {
              numberOfInputs: 1,
              numberOfOutputs: 1,
              outputChannelCount: [1],
            }
          );
          this.audioSource.connect(this.noiseSuppressorNode).connect(this.audioDestination);
        }
      });
  }

  stop() {
    if(this.noiseSuppressorNode) {
      this.noiseSuppressorNode.port.close();
      this.noiseSuppressorNode.disconnect();
    }

    if(this.audioDestination) {
      this.audioDestination.disconnect();
      this.audioSource.disconnect();
    }
  }
}
