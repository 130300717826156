import { Component, Input } from '@angular/core';
import { Customization } from '../../utils/customization';
import { ContactForm } from 'contactForm/models/ContactForm';
import { logger } from 'utils/util';
import { RequestUserService } from 'utils/requestUser.service';

@Component({
  selector: 'contact-form-success[customization]',
  templateUrl: './contact-form-success.component.html',
  styleUrls: ['./contact-form-success.component.scss'],
})
export class ContactFormSuccessComponent {
  @Input() customization!: Customization;
  @Input() contactForm: ContactForm;
  @Input() public shouldShowPoweredBy = false;

  constructor(
    private requestUserService: RequestUserService,
  ) {
  }

  get hasFreeSubscription() {
    if(this.contactForm.id) {
      return ANGULAR_SCOPE.creatorHasFreeSubscription;
    } else {
      return this.requestUserService.isFree;
    }
  }

  get hasDownloadLink()  {
    return this.contactForm.downloadLink != null && this.contactForm.downloadLink !== '';
  }
  get hasDownloadFile()  {
    return this.contactForm.files.length > 0;
  }

  get downloadLink() {
    if(this.hasDownloadFile) {
      return this.contactForm.files[0].file;
    } else if(this.hasDownloadLink) {
      return this.contactForm.downloadLink;
    }
  }

  get downloadFileName() {
    if(this.hasDownloadFile) {
      return this.contactForm.files[0].name;
    }
  }

  downloadFile($event) {
    if(!this.hasDownloadFile) {
      return;
    }
    $event.preventDefault();
    fetch(this.downloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = this.downloadFileName;
        link.click();
      })
      .catch((error) => {
        logger.error(error);
      });
  }
}
