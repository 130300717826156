import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WizardHostDirective } from './wizard-host.directive';
import Wizard from './Wizard';


@Component({
  selector: 'angular-wizard[wizard]',
  templateUrl: './angular-wizard.component.html',
  styleUrls: ['./angular-wizard.component.scss'],
})
export class AngularWizardComponent implements OnInit {
  @Input() wizard!: Wizard;
  @ViewChild(WizardHostDirective, { static: true }) wizardHost!: WizardHostDirective;


  ngOnInit() {
    this.loadComponent();
  }


  /**
   * Copy the WizardComponent validate and handleNext functions from the WizardComponent
   * to the wizard object, so they can be called by the wizard itself.
   */
  loadComponent() {
    const viewContainer = this.wizardHost.viewContainerRef;
    viewContainer.clear();
    const component = this.wizard.component;
    const componentRef = viewContainer.createComponent<any>(component);
    componentRef.instance.wizard = this.wizard;
    componentRef.instance.wizard.componentHandleNext = componentRef.instance.wizardHandleNextStep;
    componentRef.instance.wizard.componentValidateNext =
      componentRef.instance.wizardValidateNextStep;
  }


  closeWizard() {
    this.wizard.hide();
  }
}
