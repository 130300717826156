angular.module('vecteraUtil')
  .run(['tmhDynamicLocaleCache', function(tmhDynamicLocaleCache) {
    var PLURAL_CATEGORY = {
      ZERO: 'zero',
      ONE: 'one',
      TWO: 'two',
      FEW: 'few',
      MANY: 'many',
      OTHER: 'other'
    };
    function getDecimals(n) {
      n = n + '';
      var i = n.indexOf('.');
      return (i === -1) ? 0 : n.length - i - 1;
    }

    function getVF(n, opt_precision) {
      var v = opt_precision;

      if(undefined === v) {
        v = Math.min(getDecimals(n), 3);
      }

      var base = Math.pow(10, v);
      var f = ((n * base) | 0) % base;
      return { v: v, f: f };
    }

    tmhDynamicLocaleCache.put('it', {
      'DATETIME_FORMATS': {
        'AMPMS': [
          'AM',
          'PM'
        ],
        'DAY': [
          'domenica',
          'luned\u00ec',
          'marted\u00ec',
          'mercoled\u00ec',
          'gioved\u00ec',
          'venerd\u00ec',
          'sabato'
        ],
        'ERANAMES': [
          'avanti Cristo',
          'dopo Cristo'
        ],
        'ERAS': [
          'a.C.',
          'd.C.'
        ],
        'FIRSTDAYOFWEEK': 0,
        'MONTH': [
          'gennaio',
          'febbraio',
          'marzo',
          'aprile',
          'maggio',
          'giugno',
          'luglio',
          'agosto',
          'settembre',
          'ottobre',
          'novembre',
          'dicembre'
        ],
        'SHORTDAY': [
          'dom',
          'lun',
          'mar',
          'mer',
          'gio',
          'ven',
          'sab'
        ],
        'SHORTMONTH': [
          'gen',
          'feb',
          'mar',
          'apr',
          'mag',
          'giu',
          'lug',
          'ago',
          'set',
          'ott',
          'nov',
          'dic'
        ],
        'STANDALONEMONTH': [
          'gennaio',
          'febbraio',
          'marzo',
          'aprile',
          'maggio',
          'giugno',
          'luglio',
          'agosto',
          'settembre',
          'ottobre',
          'novembre',
          'dicembre'
        ],
        'WEEKENDRANGE': [
          5,
          6
        ],
        'fullDate': 'EEEE d MMMM y',
        'longDate': 'd MMMM y',
        'medium': 'dd MMM y HH:mm:ss',
        'mediumDate': 'dd MMM y',
        'mediumTime': 'HH:mm:ss',
        'short': 'dd/MM/yy HH:mm',
        'shortDate': 'dd/MM/yy',
        'shortTime': 'HH:mm'
      },
      'NUMBER_FORMATS': {
        'CURRENCY_SYM': '\u20ac',
        'DECIMAL_SEP': ',',
        'GROUP_SEP': '.',
        'PATTERNS': [
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 3,
            'minFrac': 0,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '',
            'posPre': '',
            'posSuf': ''
          },
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 2,
            'minFrac': 2,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '\u00a0\u00a4',
            'posPre': '',
            'posSuf': '\u00a0\u00a4'
          }
        ]
      },
      'id': 'it-it',
      'localeID': 'it_IT',
      'pluralCat': function(n, opt_precision) {
        var i = n | 0;
        var vf = getVF(n, opt_precision);
        if(i === 1 && vf.v === 0) {
          return PLURAL_CATEGORY.ONE;
        }
        return PLURAL_CATEGORY.OTHER;
      }
    });
  }]);
