import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from 'utils/utils.module';
import { BookingLinksMenuComponent } from './booking-links-menu.component';
import { BookingLinksMenuService } from './booking-links-menu.service';


@NgModule({
  imports: [
    UtilsModule,
    CommonModule,
  ],
  providers: [
    BookingLinksMenuService,
  ],
  declarations: [
    BookingLinksMenuComponent,
  ],
  exports: [
    BookingLinksMenuComponent,
  ],
})
export class BookingLinksMenuModule {}
