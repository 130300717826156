import { MODAL_ID as MODAL_ID_SHARING } from '../components/sharing/sharing.controller';
import { MODAL_ID as MODAL_ID_SETTINGS } from '../components/settings/settings.service';
import { StreamType } from  'meeting/meeting-room/stream';
import { EventEmitter } from 'utils/util';


export const Hint = Object.freeze({
  SETTINGS: 'settings',
  PARTICIPANTS: 'participants',
  INVITE: 'invite',
  CONTENT: 'content',
  CAM_MIC: 'cam-mic'
});

const UserProperties = Object.freeze({
  [Hint.CAM_MIC]: 'meetingTutorialShowCamMic',
  [Hint.SETTINGS]: 'meetingTutorialShowSettings',
  [Hint.INVITE]: 'meetingTutorialShowInvite',
  [Hint.CONTENT]: 'meetingTutorialShowContent',
  [Hint.PARTICIPANTS]: 'meetingTutorialShowParticipants',
});


export default class TutorialService {
  static get $inject() {
    return [
      'userService',
      'userStreamService',
      'modalService',
      'streamService',
      'meetingBroadcastService',
    ];
  }

  constructor(
    userService,
    userStreamService,
    modalService,
    streamService,
    meetingBroadcastService
  ) {
    this._bind();

    EventEmitter.setup(this, ['redraw']);

    this.userService = userService;
    this.userStreamService = userStreamService;
    this.modalService = modalService;
    this.streamService = streamService;
    this.meetingBroadcastService = meetingBroadcastService;

    this.showHints = {};
    this.numShownHints = 0;

    this.meetingBroadcastService.afterInitialization().then(this._init);
  }

  _bind() {
    this._init = this._init.bind(this);
    this._onModal = this._onModal.bind(this);
    this._onStreamAdd = this._onStreamAdd.bind(this);
    this.setHintSeen = this.setHintSeen.bind(this);
  }


  _init() {
    Object.values(Hint).forEach(hint => {
      this.showHints[hint] = this._getDefaultShow(hint);
    });
    this._updateNumShownHints();

    this.streamService.on('add', this._onStreamAdd);
    this.modalService.on('show', this._onModal);
  }



  shouldShow(hint) {
    if(hint == null) {
      return this.numShownHints > 0;
    }
    if(hint === Hint.SETTINGS && this.showHints[Hint.CAM_MIC]) {
      return false;
    }
    return this.showHints[hint];
  }


  show() {
    Object.values(Hint).forEach(hint => this.showHints[hint] = true);
    if(this.userStreamService.hasVideoEnabled() || this.userStreamService.hasAudioEnabled()) {
      this.showHints[Hint.CAM_MIC] = false;
    }
    this._updateNumShownHints();
  }

  hide() {
    Object.values(Hint).forEach(this.setHintSeen);
  }



  _getDefaultShow(hint) {
    return this.userService.me[UserProperties[hint]];
  }

  _setDefaultShow(hint, show) {
    this.userService.me[UserProperties[hint]] = show;
  }


  _onModal(modal) {
    if(!modal) {
      return;
    }

    if(modal.id === MODAL_ID_SHARING) {
      this.setHintSeen(Hint.INVITE);
    } else if(modal.id === MODAL_ID_SETTINGS) {
      this.setHintSeen(Hint.SETTINGS);
    }
  }


  _onStreamAdd(stream) {
    if(
      stream.isLocal
      && (stream.type === StreamType.VIDEO || stream.type === StreamType.AUDIO)
    ) {
      this.setHintSeen(Hint.CAM_MIC);
    }
  }


  setHintSeen(hint) {
    this.showHints[hint] = false;
    this._setDefaultShow(hint, false);
    this._updateNumShownHints();
  }


  _updateNumShownHints() {
    this.numShownHints = Object.values(Hint)
      .map(hint => this.shouldShow(hint) ? 1 : 0)
      .reduce((sum, n) => sum + n, 0);
  }
}
