import templateModal from './recording.modal.html?raw';

import { browser, logger, format } from 'utils/util';
import BaseLocalRecording from '../../shared/recordings/BaseLocalRecording';


const MODAL_ID = 'recording';


export default class RecordingService {
  static get $inject() {
    return [
      'chromeExtensionService',
      'meetingService',
      'meetingBroadcastService',
      'notificationService',
      'meetingRecordingFactory',
      'modalService',
      'userService',
      'apiService',
    ];
  }

  constructor(
    chromeExtensionService,
    meetingService,
    meetingBroadcastService,
    notificationService,
    meetingRecordingFactory,
    modalService,
    userService,
    apiService
  ) {
    this._bind();

    this.chromeExtensionService = chromeExtensionService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.meetingRecordingFactory = meetingRecordingFactory;
    this.modalService = modalService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.userService = userService;
    this.apiService = apiService;

    this.notification = null;

    modalService.register(
      MODAL_ID,
      gettext('Recordings'),
      'utils/icons/tl/24x24_record_outline.svg',
      templateModal
    );


    this.recordings = {
      local: {},
      cloud: {},
      array: {
        local: [],
        cloud: [],
      }
    };

    this.chromeExtensionService.on('recording', this._onExtensionMessage);
    this.meetingService.on('recordings', this._setCloud);

    this.chromeExtensionService.on('isInstalled', this._updateLocalRecordings);

    // 'isInstalled' message is not received during init, so poll to be sure
    if(this.chromeExtensionService.isInstalled) {
      this._updateLocalRecordings();
    }
  }

  _bind() {
    this._onExtensionMessage = this._onExtensionMessage.bind(this);
    this.sendMessageToExtension = this.sendMessageToExtension.bind(this);
    this._updateLocalRecordings = this._updateLocalRecordings.bind(this);
    this._setLocalRecordings = this._setLocalRecordings.bind(this);
    this._getCloudRecordings = this._getCloudRecordings.bind(this);
    this._setCloud = this._setCloud.bind(this);
    this._addCloud = this._addCloud.bind(this);
  }


  _updateArray(location) {
    this.recordings.array[location] = Object.values(this.recordings[location])
      .sort((r1, r2) => r2.start - r1.start);
  }


  get MODAL_ID() {
    return MODAL_ID;
  }

  showModal() {
    if(this.notification) {
      this.notification.cancel();
    }
    this._updateLocalRecordings().then(() => {
      this.modalService.show(MODAL_ID);
    });
  }

  hideModal() {
    this.modalService.hide(MODAL_ID);
  }

  /********************
   * Local recordings *
   ********************/

  _onExtensionMessage(message) {
    switch(message.action) {
      case 'list':
        if(message.meetingName === this.meetingService.key) {
          this._setLocalRecordings(message.recordings);
        }
        break;

      case 'upload-progress':
        this._onUploadProgress(message.id, message.progress);
        break;
    }
  }



  sendMessageToExtension(message, errorMessage, errorCallback) {
    if(
      !browser.supportsRecording()
      || !this.chromeExtensionService.isInstalled
    ) {
      return $q.resolve();
    }

    if(errorCallback == null) {
      errorCallback = error => {
        if(error === 'NotSupportedError') {
          return;
        }
        logger.warn(error);
        if(errorMessage) {
          this.notificationService.warning(errorMessage);
        }
      };
    }

    let extensionMessage = Object.assign(
      { type: 'recording' },
      message
    );

    return this.chromeExtensionService.sendMessage(extensionMessage).catch(errorCallback);
  }


  _updateLocalRecordings() {
    return this.sendMessageToExtension({
      action: 'list',
      meetingName: this.meetingService.key,
    })
      .then(this._setLocalRecordings);
  }


  _setLocalRecordings(recordings) {
    if(recordings == null) {
      recordings = [];
    }

    recordings = recordings.filter(r => r.state !== BaseLocalRecording.State.RECORDING);

    let newObject = {};
    recordings.forEach(recording => {
      if(this.recordings.local[recording.id]) {
        newObject[recording.id] = this.recordings.local[recording.id];
        newObject[recording.id].update(recording);
      } else {
        newObject[recording.id] = this.meetingRecordingFactory.createMeetingLocalRecording(
          this.sendMessageToExtension, recording);
      }
    });

    this.recordings.local = newObject;
    this._updateArray('local');
  }


  _onUploadProgress(id, progress) {
    let recording = this.recordings.local[id];
    if(recording) {
      recording.setUploadProgress(progress * 100);
    }
  }

  get isUploading() {
    return Object.values(this.recordings.local).some(recording => recording.isUploading);
  }
  get isDownloading() {
    return Object.values(this.recordings.cloud).some(recording => recording.isDownloading);
  }

  /**
   * Check if all recordings are uploaded to the cloud.
   *
   * @returns {boolean}
   */
  get isSynched() {
    return Object.keys(this.recordings.local).length === 0;
  }

  // Recordings can be uploaded from outside the meeting room. In that case, the client will
  // not get updates of the meeting room, so the cloud recordings won't be updated.
  // We just get the cloud recordings after uploading a local recording.
  upload(localRecording) {
    localRecording.upload()
      .finally(this._getCloudRecordings);
  }




  /********************
   * Cloud recordings *
   ********************/

  _getCloudRecordings() {
    let queryParams = {
      perPage: 'all',
      include: 'meeting,createdBy',
    };
    let query = new URLSearchParams(queryParams);
    let url = format('meetings/%s/recordings?%s', this.meetingService.id, query.toString());
    this.apiService.get(url)
      .then(response => this._setCloud(response.data));
  }

  _setCloud(newRecordings) {
    for(let id in this.recordings.cloud) {
      this._removeCloud(id);
    }
    newRecordings.forEach(this._addCloud);
  }


  _addCloud(info) {
    this._removeCloud(info.id);
    let properties = Object.assign({}, info, {
      start: new Date(info.start).getTime(),
    });

    let recording = this.meetingRecordingFactory.createMeetingCloudRecording(properties);
    this.recordings.cloud[recording.id] = recording;

    this._updateArray('cloud');
  }


  _removeCloud(id) {
    delete this.recordings.cloud[id];
    this._updateArray('cloud');
  }
}
