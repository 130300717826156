<form [formGroup]="formGroup">
  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="calendarReminder" i18n>Send a calendar reminder before the meeting</label>
    <duration-picker
      id="calendarReminder"
      [durationUnit]="'minutes'"
      [durationOptions]="[{ value: null, alias: reminderNullAlias }, 15, 60]"
      [durationAllowCustom]="true"
      formControlName="calendarReminder"
      [withSettingsErrors]="errors.calendarReminder"
      data-testid="calendarReminder"
    >
    </duration-picker>
  </div>

  <div class="form-group">
    <label class="label--h3 d-flex align-items-center" for="emailReminder" i18n>Send an email reminder before the meeting</label>
    <duration-picker
      id="emailReminder"
      [durationUnit]="'minutes'"
      [durationOptions]="[{ value: null, alias: reminderNullAlias }, 15, 60]"
      [durationAllowCustom]="true"
      formControlName="emailReminder"
      [withSettingsErrors]="errors.emailReminder"
      data-testid="emailReminder"
    >
    </duration-picker>
  </div>
</form>
