<form [formGroup]="formGroup">
  <div class="checkbox__wrapper">
    <input
      type="checkbox"
      class="checkbox"
      id="askToAcceptTerms_checkbox"
      formControlName="askToAcceptTerms"
      data-testid="contactFormTermsandconditionsCheckbox"
    />
    <label
      class="checkbox__label"
      for="askToAcceptTerms_checkbox"
      i18n
      data-testid="contactFormTermsandconditionsLabel"
    >
      Ask respondents to accept terms and conditions.
    </label>
  </div>
  <div class="text-muted" i18n>
    You can set a custom privacy policy for your team in the <a [href]="urlService.urls.customization" target="_blank" data-testid="linkToTeamSettingsPage">team settings.</a>
  </div>
</form>
