import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactFormPageModule } from '../contact-form-page';
import { UtilsModule } from 'utils/utils.module';
import { ContactFormPublicComponent } from './contact-form-public.component';


@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ContactFormPageModule,
  ],
  declarations: [
    ContactFormPublicComponent,
  ],
  exports: [
    ContactFormPublicComponent,
  ]
})
export class ContactFormPublicModule {}
