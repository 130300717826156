import { Component, Host, Inject } from '@angular/core';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { Memoize } from 'typescript-memoize';
import {
  SettingsSegmentComponent,
  SettingsSegmentState
} from 'utils/settings/settings-segment/settings-segment.component';
import {
  SettingsComponent
} from 'utils/settings/settings.component';
import { logger, string } from 'utils/util';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';
import { SiteService } from 'utils/site.service';
import { DropdownOption } from 'utils/form-elements/dropdowns';


type TemplateTag = {
  name: string,
  label: string,
  requiredSubject: boolean,
  requiredText: boolean,
  allowedSubject: boolean,
  allowedText: boolean,
}

type Template = {
  fieldName: string,
  label: string,
  subjectLabel: string,
  textLabel: string,
  subjectDefault: string,
  textDefault: string,
  tags: TemplateTag[],
}

type ExtraContent = 'preview' | 'help';
type State = 'initializing' | 'loading' | 'ready';


@Component({
  selector: 'appointment-type-templates[instance]',
  templateUrl: 'appointment-type-templates.component.html',
  styleUrls: ['appointment-type-templates.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeTemplatesComponent
  }]
})
export class AppointmentTypeTemplatesComponent extends AppointmentTypeSettingsComponent {

  public readonly templates: ReadonlyArray<Template>;
  private selectedTemplate: Template | null = null;
  public extraContent: ExtraContent | null = null;

  /* eslint-disable max-len */
  public readonly hintTexts = {
    confirmationEmailTemplate: $localize `The confirmation email is sent to your customers after they book a meeting with you.`,
    calendarEventTemplate: $localize `When someone books a meeting, we will create an event in your online calendar. Your customer will also receive an invite for this calendar event.`,
    reminderEmailTemplate: $localize `Reminder emails are optional. If you have enabled them under the "Reminders" title, they are sent shortly before the meeting starts.`,
    cancellationEmailTemplate: $localize `A cancellation email is sent after you cancel a meeting.`,
    extraInviteeConfirmationEmailTemplate: $localize `This is the confirmation email that is sent to additional invitees. This requires allowing additional invitees in the advanced settings.`,
    extraInviteeCancellationEmailTemplate: $localize `This is the cancellation email that is sent to additional invitees when a meeting is cancelled. This requires allowing additional invitees in the advanced settings.`,
  };
  /* eslint-enable max-len */

  public otherAppointmentTypes: AppointmentType[] = [];
  public otherAppointmentTypesState: State = 'initializing';
  public otherAppointmentTypesError: string | null = null;
  public selectedOtherAppointmentType: AppointmentType | null = null;
  public otherAppointmentTypeOptions: DropdownOption[] = [];


  constructor(
    @Inject('hintService') private hintService,
    @Inject('modelFactory') private modelFactory,
    @Inject('notificationService') private notificationService,
    public siteService: SiteService,
    @Host() private segment: SettingsSegmentComponent,
  ) {
    super();
    this.templates = this.buildTemplates();

    segment.stateChange.subscribe(state => {
      if(this.hasPermissionToEdit && state === SettingsSegmentState.SELECTED) {
        this.fetchOtherAppointmentTypes();
      }
    });
  }

  buildTemplates() {
    return Object.values(ANGULAR_SCOPE.templateInfo) as Template[];
  }


  get upgradeText() {
    const proPlanName = this.siteService.site.proPlanName;
    return $localize `Upgrade to ${proPlanName} to use email templates`;
  }

  getErrors(template: Template) {
    return  (this.errors[template.fieldName] || {}) as { [field: string]: string[] };
  }
  hasErrors(template: Template) {
    const errors = this.getErrors(template);
    return Object.values(errors).some(error => error?.length > 0);
  }
  override get valid() {
    return !this.templates.some(template => this.hasErrors(template));
  }


  @Memoize()
  getHint(template: Template) {
    return this.hintService.get('appointmentType.' + template.fieldName);
  }


  supportsMarkdown(template: Template) {
    return template.fieldName !== 'calendarEventTemplate';
  }

  getSanitizedText(template: Template) {
    const text = this.instance[template.fieldName].text;
    const sanitizedText = string.escapeHTML(text);
    return sanitizedText;
  }


  toggleSelected(template: Template) {
    if(template !== this.selectedTemplate) {
      this.selectedTemplate = template;
    } else {
      this.selectedTemplate = null;
    }
  }
  isSelected(template: Template) {
    return template === this.selectedTemplate;
  }

  toggleExtraContent(extraContent: ExtraContent) {
    if(extraContent !== this.extraContent) {
      this.extraContent = extraContent;
    } else {
      this.extraContent = null;
    }
  }


  reset(template: Template) {
    this.instance[template.fieldName].subject = template.subjectDefault;
    this.instance[template.fieldName].text = template.textDefault;
  }


  /***************************
   * Other appointment types *
   ***************************/

  private fetchOtherAppointmentTypes() {
    if(this.otherAppointmentTypesState !== 'initializing') {
      return;
    }
    this.otherAppointmentTypesState = 'loading';
    this.modelFactory
      .list({
        model: AppointmentType,
      })
      .then(({ data: appointmentTypes }) => {
        this.otherAppointmentTypes = appointmentTypes.filter(appointmentType => {
          return appointmentType.id !== this.instance.id;
        });
        this.otherAppointmentTypeOptions = this.otherAppointmentTypes.map(appointmentType => {
          return {
            label: appointmentType.name,
            value: appointmentType,
          };
        });
      })
      .catch(error => {
        logger.warn(error);
        this.otherAppointmentTypesError = error;
      })
      .finally(() => {
        this.otherAppointmentTypesState = 'ready';
      });
  }

  copyFrom(appointmentType: AppointmentType) {
    // Email templates are not included in list requests, so we need to fetch the appointment type
    // again.
    this.modelFactory
      .read({
        model: AppointmentType,
        identifiers: {
          id: appointmentType.id,
        },
      })
      .then(({ data: appointmentType }) => {
        this.templates
          .map(template => template.fieldName)
          .forEach(fieldName => {
            this.instance[fieldName].subject = appointmentType[fieldName].subject;
            this.instance[fieldName].text = appointmentType[fieldName].text;
          });
        this.notificationService.success($localize `Email templates copied!`);
      })
      .catch(error => {
        logger.warn(error);
        this.notificationService.error(
          // eslint-disable-next-line max-len
          $localize `Something went wrong while copying your email templates. Please try again later.`
        );
      });
  }
}
