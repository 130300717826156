import {
  Component,
  Inject,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { StateService } from 'utils/state.service';
import { UrlService } from 'utils/url.service';
import { errors, logger } from 'utils/util';

import { TemplatePortal } from '@angular/cdk/portal';
import {
  ContactFormSettingsComponent
} from 'contactForm/contact-form-config/contact-form-settings.component';
import { ContactForm, UserContactForm } from 'contactForm/models/ContactForm';
import { Memoize } from 'typescript-memoize';
import { ModalService } from 'utils/ui-components/modal/modal.service';
import { ViewOrganizationDeprecatedService } from 'utils/view-organization-deprecated.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';


@Component({
  selector: 'contact-form-config[instance]',
  templateUrl: './contact-form-config.component.html',
})
export class ContactFormConfigComponent
  extends ContactFormSettingsComponent {

  public fetchError?: string;

  public showAdvancedSettings = false;

  @ViewChild('modalSuccess') modalSuccess!: TemplateRef<any>;

  static override formFields = [
    'name',
  ];

  constructor(
    @Inject('modelFactory') private modelFactory,
    @Inject('hintService') private hintService,
    @Inject('notificationService') private notificationService,

    private usageTrackingService: UsageTrackingService,
    public stateService: StateService,
    public urlService: UrlService,
    private modalService: ModalService,
    private viewOrganizationDeprecatedService: ViewOrganizationDeprecatedService,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();

    this.stateService.setState(this.stateService.State.LOADING);

    this.getContactForm()
      .finally(() => this.stateService.setState(this.stateService.State.READY))
      .catch(this.handleFetchError);
  }

  get contactFormId(): string {
    return ANGULAR_SCOPE.contactFormId;
  }


  get headerText() {
    return this.contactFormId === '' ?
      $localize `Create a contact form` :
      $localize `Edit a contact form`;
  }


  @Memoize()
  private async getContactForm() {
    if(this.contactFormId) {
      this.instance = await this.fetchContactForm();
    } else {
      const numForms = await this.getNumForms();
      const name = $localize `Form ${numForms + 1}`;
      this.instance = this.modelFactory.createInstance(ContactForm, {
        values: {
          name,
        },
      });
    }

    // The customization form can change the logo/favicon on the organization, and these changes
    // need to be immediately reflected in the preview. To do this, we need to use the
    // organization from viewOrganizationDeprecatedService.
    this.instance.organization = this.viewOrganizationDeprecatedService.organization;

    if(!this.instance.teamleaderMappingConfig) {
      this.instance.createChildInstance('teamleaderMappingConfig');
    }

    // We are abusing this.instance here, because it is supposed to be a @Input. Since we are not
    // using it as an input, change detection will not work, so we have to call onInstanceChange
    // manually.
    this.onInstanceChange();
  }


  private async fetchContactForm() {
    const response = await this.modelFactory.read({
      model: ContactForm,
      identifiers: { id: this.contactFormId },
    });

    return response.data as ContactForm;
  }

  private async getNumForms() {
    const { response } = await this.modelFactory.list({
      model: UserContactForm,
      identifiers: { userId: 'me' },
    });
    return response.link.totalCount;
  }

  private handleFetchError(error) {
    /* eslint-disable max-len */
    if(error.constructor === errors.DoesNotExistError) {
      this.fetchError = $localize `We couldn't find the Contact form you were looking for. Perhaps it was removed, or you don't have access?`;
    } else if(error.constructor === errors.OfflineError) {
      this.fetchError = $localize `You seem to be offline. Please check your internet connection and reload the page.`;
    } else {
      logger.warn(error);
      this.fetchError = $localize `Something went wrong while fetching your Contact form. Please try again later.`;
    }
    /* eslint-enable max-len */
  }

  async save() {
    if(this.stateService.isSaving) {
      return;
    }
    this.stateService.setState(this.stateService.State.SAVING);

    try {
      if(this.shouldSaveOrganization) {
        try {
          await this.instance.organization.update();
        } catch(error: any) {
          if(error.constructor === errors.ValidationError) {
            error.response.data = {
              organization: error.response.data,
            };
          }
          throw error;
        }
      }

      if(this.instance.id) {
        await this.instance.update();
        this.track('contactForm.updated');
        this.showUpdateNotification();
      } else {
        await this.instance.create();
        this.track('contactForm.created');
        this.showSuccessModal();
      }
      // The dashboard "welcome" interface should no longer be shown once
      // a user has created an appointment type, joined a meeting room or created a contact form
      this.hintService.get('dashboardWelcome').setSeen();
      this.clearSaveError();

    } catch(error: any) {
      this.errors = this.notificationService.handleError(
        error,
        $localize `Something went wrong while saving your contact form.`,
        'page',
      );
    } finally {
      // Set a timeout so the "processing" state doesn't just immediately goes back to the
      // initial state.
      setTimeout(() => this.stateService.setState(this.stateService.State.READY), 3000);
    }
  }

  private clearSaveError() {
    this.errors = {};
  }


  get shouldSaveOrganization() {
    return (
      this.instance.organization.isDirty('logo')
      || this.instance.organization.isDirty('favicon')
    );
  }


  private showSuccessModal() {
    const templatePortal = new TemplatePortal(this.modalSuccess, this.viewContainerRef);
    this.modalService.open({
      templatePortal: templatePortal,
      modalClass: 'modal--sm',
      hideHeader: true,
    });
  }

  get sharingOptionsTitle() {
    return $localize `Share: ${this.instance.name}`;
  }

  private showUpdateNotification() {
    this.notificationService.success(
      $localize `Contact form successfully updated!`,
      {
        persistOnReload: true,
      }
    );
    this.goToOverview();
  }

  public goToOverview() {
    window.location.href = this.urlService.urls.contactFormsOverview;
  }

  track(event: string) {
    this.usageTrackingService.createSegmentEvent(event, 'contactForm');
  }
}
