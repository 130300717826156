export interface Subscription {
  status: SubscriptionStatus,
  quantityIsLimited: boolean,
  limitedQuantity: number,
  chargebeeStatus: ChargebeeStatus,
  smartSummarySecondsUsed: number,
  totalAllowedSmartSummarySeconds: number,
}


export enum SubscriptionStatus {
  FREE = 'free',
  TRIAL = 'trial',
  ACTIVE = 'active',
}

export enum ChargebeeStatus {
  FUTURE = 'future',
  ACTIVE = 'active',
  NON_RENEWING = 'non_renewing',
  CANCELLED = 'cancelled',
}
