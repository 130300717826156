import errors from './errors';


type AnyFunc = (...args: any[]) => any;
type DebouncedFunc<Func extends AnyFunc> = {
  (...args: Parameters<Func>): void;
  cancel(): void;
}
type ThrottledFunc<Func extends AnyFunc> = DebouncedFunc<Func>;

export function debounce<Func extends AnyFunc>(
  callback: Func,
  delay: number,
): DebouncedFunc<Func> {
  if(typeof callback !== 'function') {
    throw new errors.InvalidArgumentError('callback must be a function');
  }
  if(delay == null) {
    delay = 0;
  }

  let that, args, timeoutId, lastCall: number | null = 0;

  function exec() {
    timeoutId = null;

    if(lastCall) {
      timeoutId = setTimeout(exec, lastCall + delay - Date.now());
    } else {
      callback.apply(that, args);
    }

    lastCall = 0;
  }


  const fn: DebouncedFunc<Func> = function debounced(this: any, ...localArgs: any[]) {
    that = this;  // eslint-disable-line
    args = localArgs;

    if(timeoutId) {
      lastCall = Date.now();
    } else {
      timeoutId = setTimeout(exec, delay);
    }
  };

  fn.cancel = function cancel() {
    if(timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = null;
    lastCall = null;
  };

  return fn;
}

/**
 * Creates a function that, when called, will execute the "callback" parameter only if enough time
 * has passed since the previous call, otherwise it will be discarded. If execTrailing is true,
 * instead of being discarded execution will wait until the delay has passed.
 *
 * @param {*} callback
 * @param {Number} delay - amount of time between calls, in milliseconds
 * @param {Boolean} execTrailing
 *
 * @returns a function that, when called, will execute the callback
 */
export function throttle<Func extends AnyFunc>(
  callback: Func,
  delay: number,
  execTrailing: boolean,
): ThrottledFunc<Func> {
  if(typeof callback !== 'function') {
    throw new errors.InvalidArgumentError('callback must be a function');
  }

  let that, lastExec = 0, args, timeoutId;

  function exec() {
    fn.cancel();
    lastExec = Date.now();
    callback.apply(that, args);
  }

  const fn: ThrottledFunc<Func> = function throttled(this: any, ...localArgs: any[]) {
    that = this;  // eslint-disable-line
    args = localArgs;

    if(!timeoutId) {
      const elapsed = Date.now() - lastExec;
      if(elapsed > delay) {
        timeoutId = setTimeout(exec);
      } else if(execTrailing) {
        timeoutId = setTimeout(exec, delay - elapsed);
      }
    }
  };

  fn.cancel = function cancel() {
    if(timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return fn;
}
