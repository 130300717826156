import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleControlValueAccessor } from 'utils/form-elements/simple-control-value-accessor';

export type DropdownOptionFull = {
  label: string;
  value: any;
  disabled?: boolean;
};
export type DropdownOption = DropdownOptionFull | string;

@Component({
  selector: 'dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true,
    },
  ],
})
export class DropdownSelectComponent
  extends SimpleControlValueAccessor<any> {
  @Input() options : readonly DropdownOption[] = [];
  @Input() nullable = false;
  @Input() textIfNull = $localize `No selection`;
  @Input() placeholder = $localize `Select…`;


  pickOption(option: DropdownOption) {
    this.writeValue(this.getOptionValue(option));
  }

  getOptionValue(option: DropdownOption) {
    return typeof option === 'object' ? option.value : option;
  }
  getOptionLabel(option: DropdownOption) {
    return typeof option === 'object' ? option.label : option;
  }
  getOptionDisabled(option: DropdownOption) {
    return typeof option === 'object' && option.hasOwnProperty('disabled') ?
      option.disabled :
      false;
  }
  optionToTestId(option: DropdownOption) {
    return this.getOptionLabel(option).toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  }
  textIfNullToTestId() {
    return this.textIfNull.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  }
}
