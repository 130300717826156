import { Component, Input } from '@angular/core';
import { Appointment } from 'scheduling/models/Appointment';
import { locationLabels } from 'scheduling/utils/location-badges';
import { ViewOrganizationService } from 'utils/view-organization.service';

@Component({
  selector: 'appointment-details',
  templateUrl: './appointment-details.component.html',
})
export class AppointmentDetailsComponent {
  @Input() appointment: Appointment;
  public Location = Location;

  constructor(
    private viewOrganizationService: ViewOrganizationService,
  ) {
  }

  get appointmentLocation(): string {
    return locationLabels[this.appointment.location];
  }

  get showMembersPersonalInfo() {
    return this.viewOrganizationService.organization.showMembersPersonalInfo;
  }

  get viewOrganization() {
    return this.viewOrganizationService.organization;
  }
}
