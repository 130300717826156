import {
  animate, group, keyframes, query, style, transition, trigger
} from '@angular/animations';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { SvgIcon } from '../svg-icon';


export type ModalConfig = {
  /**
   * The template that will be used as the body of the modal. You must either provide
   * `templatePortal` or `componentPortal`, but not both.
   * The difference between a `TemplatePortal` and a `TemplateRef` is that a portal is already
   * bound to a component instance. The advantage is that you can use component variables in the
   * same way that you would in your main template, instead of manually having to pass data (like
   * e.g. in CDK Dialog).
   **/
  templatePortal?: TemplatePortal<any>,
  /** A component that will be used as the body of the modal. You must either provide
   * `templatePortal` or `componentPortal`, but not both. */
  componentPortal?: ComponentPortal<any>,

  /** The title of the modal. Ignored if `hideHeader === true`. */
  title?: string;
  /** The icon of the modal. Ignored if `hideHeader === true`. */
  icon?: SvgIcon;

  /** Additional css classes that will be applied to the .modal element. */
  modalClass: string | string[] | Set<string> | { [klass: string]: any; } | null | undefined;
  /** Additional css classes that will be applied to the .modal__body element. */
  bodyClass: string | string[] | Set<string> | { [klass: string]: any; } | null | undefined;
  /** Whether the modal header bar should be omitted. Defaults to false. */
  hideHeader: boolean;
  /**
   * Whether the modal can be closed with the escape key and a close button in the header. Defaults
   * to false.
   **/
  disableClose: boolean;
  /** Whether the backdrop should have a blur effect. Defaults to false. */
  blurBackdrop: boolean;
}

export const defaultModalConfig: ModalConfig = {
  modalClass: '',
  bodyClass: '',
  hideHeader: false,
  disableClose: false,
  blurBackdrop: false,
};


interface ModalData {
  config: Partial<ModalConfig>;
}


@Component({
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('enter', [
      transition(':enter', [
        group([
          query(':self', [
            style({ opacity: 0 }),
            animate(200, style({ opacity: 1 })),
          ]),
          query('.modal', [
            style({ opacity: 0, transform: 'translateY(-20px)' }),
            animate('200ms 100ms', style({ opacity: 1, transform: 'translateY(0)' })),
          ]),
        ]),
      ]),
    ]),

    trigger('attention', [
      transition('false => true', [
        animate(200, keyframes([
          style({ offset: 0.0, transform: 'scale(1)', }),
          style({ offset: 0.5, transform: 'scale(1.08)' }),
          style({ offset: 1.0, transform: 'scale(1)' }),
        ]))
      ]),
    ]),
  ]
})
export class ModalComponent {
  public config: ModalConfig;
  public drawAttention = false;

  constructor(
    @Inject(DIALOG_DATA) public data: ModalData,
    public dialogRef: DialogRef
  ) {
    this.config = { ...defaultModalConfig, ...data.config };
  }


  /**
   * Draw attention to the modal with an animation when the user clicks somewhere outside of the
   * modal. The animation is triggered by a transition of `this.drawAttention` from `false` to
   * `true`, so when the animation is done `this.drawAttention` is reset to `false`.
   * @param drawAttention
   */
  setAttention(drawAttention: boolean) {
    this.drawAttention = drawAttention;
  }


  onWrapperClick($event: MouseEvent) {
    if($event.target === $event.currentTarget) {
      this.setAttention(true);
    }
  }


  close(result?: any) {
    this.dialogRef.close(result);
  }
}
