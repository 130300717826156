import { Component } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { ContactFormSettingsComponent } from '../../contact-form-settings.component';
import { UrlService } from 'utils/url.service';


@Component({
  selector: 'contact-form-legal[instance]',
  templateUrl: './contact-form-legal.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactFormLegalComponent
  }]
})
export class ContactFormLegalComponent extends ContactFormSettingsComponent {

  constructor(
    public urlService: UrlService,
  ) {
    super();
  }


  static override formFields = [
    'askToAcceptTerms',
  ];
}
