import { errors } from 'utils/util';
import {
  CAN_ALWAYS_JOIN_MESSAGE_TEMPLATE,
  SHOULD_KNOCK_MESSAGE_TEMPLATE,
  IS_HOST_MESSAGE_TEMPLATE,
  TEMPLATE_MAP
} from './sharing.service';
export const DROPDOWN_ID = 'sharingNew';

export default class SharingNewController {

  static get $inject() {
    return [
      '$element',
      '$scope',
      'meetingService',
      'userService',
      'userInfoService',
      'dropdownService',
      'sharingService'
    ];
  }

  constructor(
    $elem,
    $scope,
    meetingService,
    userService,
    userInfoService,
    dropdownService,
    sharingService
  ) {
    this._bind();

    this.$elem = $elem;
    this.$scope = $scope;
    this.meetingService = meetingService;
    this.userService = userService;
    this.userInfoService = userInfoService;
    this.dropdownService = dropdownService;
    this.sharingService = sharingService;


    this.userInfo = null;
    this.message = this.sharingService.getInviteMessage(CAN_ALWAYS_JOIN_MESSAGE_TEMPLATE);
    this.accessLevel = sharingService.defaultUserAccessLevel;

    dropdownService.on('visible', this._onDropdownActive);
  }

  _bind() {
    this._onDropdownActive = this._onDropdownActive.bind(this);
  }


  validate(userInfo) {
    if(userInfo.id) {
      this.userInfoService.updateUserInfo(userInfo);
      let user = this.userService.getOrCreate(userInfo.id);

      let invitedUserIds = new Set(
        Object.values(this.sharingService.permissions)
          .map(permission => permission.user.id)
      );

      if(user.isMe) {
        return [false, gettextCatalog.getString('You')];
      } else if(this.meetingService.userIsOwner(user)) {
        return [false, gettextCatalog.getString('Meeting host')];
      } else if(user.id in invitedUserIds) {
        return [false, gettextCatalog.getString('Already invited')];
      } else {
        return [true, ''];
      }
    } else if(userInfo.email) {
      return [true, gettextCatalog.getString('Not a member of your team')];
    } else {
      throw new errors.InvalidArgumentError(
        'userInfo should have `id` or `email` set, got neither: '
        + JSON.serialize(userInfo)
      );
    }
  }

  onResult(userInfo) {
    this.userInfo = userInfo;
    this.$elem.find('.sharing__message').focus();
  }

  setAccessLevel(accessLevel) {
    this.accessLevel = accessLevel;

    if(
      this.message === this.sharingService.getInviteMessage(CAN_ALWAYS_JOIN_MESSAGE_TEMPLATE)
      || this.message === this.sharingService.getInviteMessage(SHOULD_KNOCK_MESSAGE_TEMPLATE)
      || this.message === this.sharingService.getInviteMessage(IS_HOST_MESSAGE_TEMPLATE)
    ) {
      this.message = this.sharingService.getInviteMessage(TEMPLATE_MAP[accessLevel.id]);
    }
  }

  _onDropdownActive(dropdown) {
    if(dropdown.id === DROPDOWN_ID) {
      $timeout(() => this.$scope.$broadcast('searchUserFocus'));
    }
  }


  invite() {
    this.sharingService.inviteUser(
      this.userInfo.id,
      this.userInfo.email,
      this.message,
      this.accessLevel
    );

    // Wait until the dropdown is closed
    $timeout(() => {
      this.$scope.$broadcast('searchUserClear');
      this.message = this.sharingService.getInviteMessage(CAN_ALWAYS_JOIN_MESSAGE_TEMPLATE);
      this.accessLevel = this.sharingService.defaultUserAccessLevel;
    });
  }
}
