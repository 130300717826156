import template from './screenTile.html?raw';


import ContentTileController from '../content/contentTile.controller';
import ViewportTileControllerMixin from '../videoViewport/ViewportTileControllerMixin';


const BaseController = ViewportTileControllerMixin(ContentTileController);
class ScreenTileController extends BaseController {
  static get $inject() {
    return [
      'screenshareService',
      'snapshotService',
    ].concat(BaseController.$inject);
  }

  constructor(
    screenshareService,
    snapshotService,
    ...args
  ) {
    super(...args);

    this.screenshareService = screenshareService;
    this.snapshotService = snapshotService;
  }


  takeSnapshot() {
    let stream = this.tile.streams.video;
    if(!stream) {
      return;
    }

    this.snapshotService.takeSnapshot(stream, this.tile.zoomLevel, this.tile.offset);
  }


  remove() {
    this.screenshareService.remove(this.tile);
  }

  get userIsOfflineString() {
    return gettextCatalog.getString(
      '{{ name }} seems to be offline...',
      { name: this.tile.user.shortName }
    );
  }
}



export default {
  controller: ScreenTileController,
  controllerAs: 'screenTileCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
