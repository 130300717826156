import * as browser from '../util/browser';

export default function FocusOnAddDirective() {
  return {
    restrict: 'A',

    link: function($scope, $elem) {
      // Auto-focus doesn't work reliably on iPad. Moreover, there is a bug that causes the
      // interface to jump with a few hundres of pixels when removing an auto-focused element from
      // the DOM (eg. the private notes dropdown).
      if(browser.isIOS()) {
        return;
      }

      $timeout(() => {
        $elem[0].focus();
      });
    }
  };
}
