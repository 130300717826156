import { EventEmitter } from 'utils/util';
import * as languages from 'utils/util/ajsLanguages';

export default class LanguageService {
  static get $inject() {
    return [
      '$interpolate',
      'gettextCatalog',
      'requestUserService',
      'tmhDynamicLocale',
    ];
  }

  constructor(
    $interpolate,
    gettextCatalog,
    requestUserService,
    tmhDynamicLocale
  ) {
    EventEmitter.setup(this, ['language']);

    this.$interpolate = $interpolate;
    window.gettextCatalog = gettextCatalog;
    this.requestUserService = requestUserService;
    this.tmhDynamicLocale = tmhDynamicLocale;

    this._cache = {};
    gettextCatalog.debug = ANGULAR_SCOPE.deployEnv !== 'prod';

    this.localizedLanguages = languages.localized;
    this.currentLanguage = 'en';

    if(
      window.ANGULAR_SCOPE.translationEnabled
      && this.requestUserService.user
      && this._isSupported(this.requestUserService.user.languageCode)
    ) {
      this._setInterfaceLanguage(this.requestUserService.user.languageCode);
    }
  }


  /**
   * Set the interface language, only if translation is enabled in the environment. Otherwise,
   * default to English
   *
   * @param {string} language - an ISO language code
   */
  setLanguage(language) {
    let languageWithRegion = this._guessLanguageWithRegion(language);
    if(!this._isSupported(languageWithRegion)) {
      languageWithRegion = 'en';
    }
    this._setInterfaceLanguage(languageWithRegion);
    this._syncLanguage(languageWithRegion);
  }

  get browserLanguage() {
    if(!navigator.language) {
      return this.currentLanguage;
    }
    return navigator.language.split('-')[0];
  }

  _guessLanguageWithRegion(language) {
    let prefix = language + '-';
    for(let navigatorLanguage of navigator.languages) {
      if(navigatorLanguage.startsWith(prefix)) {
        return navigatorLanguage;
      }
    }
    return language;
  }


  _isSupported(languageWithRegion) {
    let langCode = (languageWithRegion || '').split('-')[0];
    return languages.localized.findIndex( localizedLang => langCode === localizedLang.code ) >= 0;
  }


  _setInterfaceLanguage(languageWithRegion) {
    let language = languageWithRegion.split('-')[0];
    if(language !== this.currentLanguage) {
      this.currentLanguage = language;
      this._cache = {};
      gettextCatalog.setCurrentLanguage(language);
      this.tmhDynamicLocale.set(language);
      this.emit('language', language);
    }
  }


  _syncLanguage(language) {
    this.requestUserService.user.languageCode = language;
    this.requestUserService.user.save().catch(angular.noop);
  }


  /**
   * Get a translated string from a dictionary
   *
   * Get either the custom value (from a "custom string" field in Team settings) or a
   * translated default string from a dictionary and render it to html by interpreting it as
   * markdown.
   *
   * If the string is not translated, fall back to English
   *
   * @param {Object} textDict - an object in which the keys are langcodes and the values are
   *   markdown strings
   */
  getCustomOrTranslatedString(textDict) {
    if(textDict == null) {
      textDict = {};
    }
    // At the moment, when a custom string is set, the dictionary maps every language to this
    // custom string. In this case the current language does not matter
    return textDict[this.currentLanguage] || '';
  }
}
