export default class UserNotifyService {
  static get $inject() {
    return [
      'notifyService',
      'userService',
    ];
  }

  constructor(
    notifyService,
    userService
  ) {
    this._bind();

    this.notifyService = notifyService;
    this.userService = userService;

    this.userService.on('userJoin', this._onUserJoin);
    this.userService.on('userExit', this._onUserExit);
  }

  _bind() {
    this._onUserJoin = this._onUserJoin.bind(this);
    this._onUserExit = this._onUserExit.bind(this);
  }


  _onUserJoin(user, prevUser) {
    if(!prevUser && !user.isMe) {
      this.notifyService.notify();
    }
  }


  _onUserExit(user, nextUser) {
    if(!nextUser && !user.isMe) {
      this.notifyService.notify();
    }
  }
}
