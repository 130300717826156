import { format } from 'utils/util';


const TEMPLATE_WARNING = `
  <div class="mt-2 field field--warning" ng-show="showWarning">
    <div>
      <div>%s</div>
      <div class="mt-1 d-flex justify-content-end">
        <span class="btn" ng-click="reset()" translate>Undo changes</span>
      </div>
    </div>
  </div>
`;


ChangeWarningDirective.$inject = [
  '$compile',
];


export default function ChangeWarningDirective(
  $compile
) {
  return {
    restrict: 'A',
    scope: true,
    link: function($scope, $elem, $attr) {
      let initialValue = $elem.val();

      $scope.showWarning = false;
      $elem.on('change input', () => $scope.$evalAsync(update));


      let templateWarning = format(TEMPLATE_WARNING, $attr.changeWarning);
      let $elemWarning = $compile(templateWarning)($scope);
      $elem.parents('.form-group').append($elemWarning);


      function update() {
        $scope.showWarning = $elem.val() !== initialValue;
      }

      $scope.reset = function reset() {
        $elem.val(initialValue);
        update();
      };
    },
  };
}
