import { Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


@Component({
  selector: 'multi-line-input',
  templateUrl: './multi-line-input.component.html',
})
export class MultiLineInputComponent extends ContactFormQuestionInputComponent<string> {
  parsePrefill(string: string) {
    return string.replace(/\\n/g, '\n');
  }
}
