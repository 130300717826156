import { Pipe, PipeTransform } from '@angular/core';
import { I18nPluralPipe } from '@angular/common';
import { TimeDelta } from 'utils/util';

type FormatId = 'seconds' | 'minutes' | 'hours' | 'days';

class Format {
  constructor(
    public id: FormatId,
    public rank: number,
    public divider: number,
    public timePortionShort: string,
    public timePortionZero: string,
    public timePortionSingular: string,
    public timePortionPlural: string,
  ) {}
}

export const secondsFormat = new Format(
  'seconds',
  0,
  1,
  $localize `:Abbreviation of second:s`,
  $localize `0 seconds`,
  $localize `1 second`,
  $localize `# seconds`,
);
export const minutesFormat = new Format(
  'minutes',
  1,
  60,
  $localize `:Abbreviation of minutes:m`,
  $localize `0 minutes`,
  $localize `1 minute`,
  $localize `# minutes`,
);
export const hoursFormat = new Format(
  'hours',
  2,
  60 * 60,
  $localize `:Abbreviation of hours:h`,
  $localize `0 hours`,
  $localize `1 hour`,
  $localize `# hours`,
);
export const daysFormat = new Format(
  'days',
  3,
  60 * 60 * 24,
  $localize `:Abbreviation of days:d`,
  $localize `0 days`,
  $localize `1 day`,
  $localize `# days`,
);
const formats = [secondsFormat, minutesFormat, hoursFormat, daysFormat];

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor(private i18nPluralPipe: I18nPluralPipe) {}

  transform(
    input: number|TimeDelta,
    fullText = false, numParts = 2,
    minFormatId: FormatId | null = null, // lowest format to include
    maxFormatId: FormatId | null = null, // highest format to include
  ): string {
    let seconds = typeof input === 'number' ? input : input.seconds;
    const maxFormat = formats.find(format => format.id === maxFormatId);
    const minFormat = formats.find(format => format.id === minFormatId);

    if(seconds === 0) {
      const format = formats.find(format => format.id === minFormatId) || secondsFormat;
      if(fullText) {
        return `0 ${format.timePortionZero}`;
      } else {
        return `0${format.timePortionShort}`;
      }
    } else if(seconds < secondsFormat.divider) {
      if (minFormat != null && minFormat.rank > secondsFormat.rank) {
        if(fullText) {
          return `0 ${minFormat.timePortionZero}`;
        } else {
          return `0${minFormat.timePortionShort}`;
        }
      } else if(fullText) {
        return `1 ${secondsFormat.timePortionSingular}`;
      } else {
        return `1${secondsFormat.timePortionShort}`;
      }
    }

    const readableParts: string[] = [];
    let firstPartFound = false;
    for(let i = formats.length - 1; i >= 0; i--) {

      if (maxFormat && formats[i].rank > maxFormat.rank) {
        continue;
      }
      if (minFormat && formats[i].rank < minFormat.rank) {
        break;
      }
      const divider = formats[i].divider;
      const quotient = Math.floor(seconds / divider);
      const remainder = seconds % divider;
      seconds = remainder;
      if(quotient === 0) {
        // Only return adjacent parts: e.g 2h 5m. Not 2h 5s. In the last case, return 2h
        if(firstPartFound) {
          break;
        } else {
          continue;
        }
      }

      let readablePart;
      if(fullText) {
        readablePart = this.transformPluralSingular(
          quotient,
          formats[i].timePortionZero,
          formats[i].timePortionSingular,
          formats[i].timePortionPlural,
        );
      } else {
        readablePart = `${quotient}${formats[i].timePortionShort}`;
      }

      readableParts.push(readablePart);
      firstPartFound = true;
      if(readableParts.length === numParts) {
        return readableParts.join(' ');
      }
    }

    if(readableParts.length === 0 && minFormat != null) {
      let readablePart;
      if(fullText) {
        readablePart = `0${minFormat.timePortionZero}`;
      } else {
        readablePart = `0${minFormat.timePortionShort}`;
      }
      readableParts.push(readablePart);
    }
    return readableParts.join(' ');
  }

  transformPluralSingular(value: number, zero: string, singular: string, plural: string) {
    return this.i18nPluralPipe.transform(value, {
      '=0': zero,
      '=1': singular,
      'other': plural,
    });
  }
}
