import { bind, logger, errors, format, EventEmitter, string } from 'utils/util';
import User from './User';
import AnonymousHostUser from './AnonymousHostUser';
import Session from './Session';
import LocalSession from './LocalSession';


const SLEEP_SESSION_DURATION = 15000;
const EVENTS = Object.freeze([
  'userState',
  'userJoin', 'userExit', 'userSleep', 'userWakeup',
  'userKnockJoin', 'userKnockExit', 'userKnockSleep', 'userKnockWakeup',
  'sessionState',
  'sessionJoin', 'sessionExit', 'sessionSleep', 'sessionWakeup',
  'sessionKnockJoin', 'sessionKnockExit', 'sessionKnockSleep', 'sessionKnockWakeup',
  'me',
]);


const State = Session.State;
const STATE_MACHINE = {
  [State.DEAD]: {
    [State.KNOCKING]: 'knockJoin',
    [State.ALIVE]: 'join',
  },
  [State.KNOCKING]: {
    [State.DEAD]: 'knockExit',
    [State.ASLEEP]: 'knockSleep',
    [State.ALIVE]: 'join',
  },
  [State.ALIVE]: {
    [State.DEAD]: 'exit',
    [State.KNOCKING]: 'knockJoin',
    [State.ASLEEP]: 'sleep',
  },
  [State.ASLEEP]: {
    [State.KNOCKING]: 'knockWakeup',
    [State.ALIVE]: 'wakeup',
    [State.DEAD]: 'exit',
  }
};

function getStateTransition(oldState, state) {
  if(
    STATE_MACHINE[oldState]
    && STATE_MACHINE[oldState].hasOwnProperty(state)
  ) {
    return STATE_MACHINE[oldState][state];
  } else {
    return null;
  }
}


/**
 * Keeps track of the users in a session and emits corresponding events
 *
 * A single user can have multiple sessions in a MR (by having multiple tabs open), so the events
 * emitted take this into account, e.g.:
 *  - A 'userJoin' event should only be emitted if this user has no other session present in the
 *    MR, otherwise a 'sessionJoin' event should be emitted
 *  - Likewise, a 'userExit' event is emitted only when the last session of the user is exited,
 */
export default class UserService {
  static get $inject() {
    // TODO: meetingService is only necessary to get it injected into the LocalSession constructor
    // so this could possibly be removed here
    return [
      'meetingService',
    ];
  }

  constructor(
    meetingService
  ) {
    bind(this);
    EventEmitter.setup(this, EVENTS);

    this.meetingService = meetingService;

    /**
     * users is an Object in which each key is a user ID and each value is a corresponding
     * user that is present in the current Meeting Room.
     */
    this.users = {};
    this.joined = new Set();
    /**
     * sessions is an Object in which each key is a session ID and each value is a corresponding
     * session that is present in the current Meeting Room.
     */
    this.sessions = {};
    this.joinedSessions = new Set();
    this.exitSessionTimeouts = {};

    this.me = new User(this, null);
    this.mySession = new LocalSession(meetingService, this, null);
    this.me.addSession(this.mySession);

    this.getOrCreate(meetingService.ownerId);
    this.anonymousHostUser = new AnonymousHostUser(this, 'host');


    this.on('userJoin', this._addJoinedUser);
    this.on('userExit userKnockJoin', this._removeJoinedUser);
    this.on('sessionJoin', this._addJoinedSession);
    this.on('sessionExit', this._removeJoinedSession);
  }


  getUniqueId(id) {
    // Prefix an id with the session id, so that it is globally unique. The caller is responsible
    // for making sure an id is not used twice for the same type of object.
    if(typeof id !== 'number' || id < 0 || id % 1 !== 0) {
      throw new errors.InvalidArgumentError('id must be a positive integer');
    }
    if(this.mySession.id == null) {
      throw new errors.IllegalStateError(
        'Cannot create a unique id before the local session is initialized'
      );
    }
    return `${this.mySession.id}-${id}`;
  }


  /***********
   * Getters *
   ***********/

  get(userId) {
    if(!this.users.hasOwnProperty(userId)) {
      throw new errors.DoesNotExistError(format(
        'The user with id %s does not exist', userId));
    }
    return this.users[userId];
  }

  getOrCreate(userId) {
    if(!this.users.hasOwnProperty(userId)) {
      this.users[userId] = new User(this, userId);
    }
    return this.users[userId];
  }


  getSession(sessionId) {
    if(this.sessions.hasOwnProperty(sessionId)) {
      return this.sessions[sessionId];
    } else {
      throw new errors.DoesNotExistError(format(
        'The session with id %s does not exist', sessionId));
    }
  }

  getOrCreateSession(sessionId) {
    let session = this.sessions[sessionId];
    if(!session) {
      session = new Session(this, sessionId);
      this.sessions[sessionId] = session;
      this.anonymousHostUser.addSession(session);
    }
    return session;
  }


  get aliveHosts() {
    return (
      Object.values(this.users)
        .filter(user => user.isAlive() && user.accessLevel.isHost)
    );
  }

  get aliveSessions() {
    return (
      Object.values(this.sessions)
        .filter(session => session.isAlive())
    );
  }

  get aliveUsers() {
    return (
      Object.values(this.users)
        .filter(user => user.isAlive())
    );
  }

  get iAmHost() {
    return this.mySession.accessLevel.isHost;
  }

  get iAmOnlyHost() {
    return this.iAmHost && this.aliveHosts.length === 1;
  }


  /*****************************
   * Manage sessions and users *
   *****************************/

  setMyId(id) {
    this.me.id = id;
    this.users[id] = this.me;
  }
  setMySessionId(id) {
    this.mySession.id = id;
    this.sessions[id] = this.mySession;
  }


  joinSession(session, userId, accessLevel, newState, datetime) {
    if(newState === State.KNOCKING) {
      session.dateKnocked = datetime;
    } else {
      session.dateJoined = datetime;
    }
    session.dateExited = null;

    $timeout.cancel(this.exitSessionTimeouts[session.id]);
    delete this.exitSessionTimeouts[session.id];

    let user = this.getOrCreate(userId);
    let oldState = session.state === State.ASLEEP && newState !== session.stateBeforeSleep ?
      session.stateBeforeSleep :
      session.state;
    let oldUserState = user.state === State.ASLEEP && newState !== user.stateBeforeSleep ?
      user.stateBeforeSleep :
      user.state;

    let prevUser = null;
    if(session.user !== user) {
      prevUser = session.user;
      let oldPrevUserState = prevUser.stateWithoutSleep;
      prevUser.removeSession(session);

      if(prevUser === this.anonymousHostUser) {
        prevUser = null;
      } else {
        this._emitEvent(prevUser, oldPrevUserState, user);
      }

      user.addSession(session);
      if(session.isLocal) {
        this.me = user;
        this.emit('me', user, prevUser);
      }
    }
    if(accessLevel) {
      session.setAccessLevel(accessLevel);
    }

    this._setSessionState(session, newState, oldState);

    if(prevUser === this.anonymousHostUser) {
      prevUser = null;
    }
    this._emitEvent(user, oldUserState, prevUser);
  }


  sleepSession(session, exitReason) {
    let user = session.user;
    let oldState = session.state;
    let oldUserState = user.state;
    this._setSessionState(session, State.ASLEEP, oldState, exitReason);
    if(user.constructor !== AnonymousHostUser) {
      this._emitEvent(user, oldUserState);
    }

    // The local session can never be dead because of a lost connection
    if(!session.isLocal && !this.exitSessionTimeouts[session.id]) {
      this.exitSessionTimeouts[session.id] = $timeout(
        this.exitSession.bind(this, session), SLEEP_SESSION_DURATION);
    }
  }


  exitSession(session, exitReason, datetime) {
    session.dateExited = datetime;
    let user = session.user;
    let oldState = session.stateWithoutSleep;
    let oldUserState = user.stateWithoutSleep;

    this._setSessionState(session, State.DEAD, oldState, exitReason);
    this._emitEvent(user, oldUserState);
  }


  _setSessionState(session, state, oldState, exitReason) {
    if(exitReason != null) {
      session.exitReason = exitReason;
    }

    if(state !== oldState) {
      session.setState(state);
      this._emitEvent(session, oldState);
    }
  }



  _emitEvent(object, oldState, ...extraEmitArgs) {
    let state = object.state;
    if(state === oldState) {
      return;
    }

    let prefix;
    switch(object.constructor) {
      case User:
        prefix = 'user';
        break;

      case Session:
      case LocalSession:
        prefix = 'session';
        break;

      default:
        throw new errors.InvalidArgumentError(format(
          'Invalid event object constructor:', object.constructor));
    }

    this.emit(prefix + 'State', object, oldState);
    object.emit('state', state, oldState);

    let event = getStateTransition(oldState, state);
    if(event) {
      this.emit(prefix + string.capitalize(event), object, ...extraEmitArgs);
      object.emit(event, ...extraEmitArgs);
    } else {
      logger
        .withContext({ id: object.id })
        .warn('Illegal %s state transition: from %s to %s', prefix, oldState, state);
    }
  }



  /**********************************
   * Joined users and sessions sets *
   **********************************/

  _addJoinedUser(user) {
    this.joined.add(user);
  }
  _removeJoinedUser(user) {
    this.joined.delete(user);
  }

  _addJoinedSession(session) {
    this.joinedSessions.add(session);
  }
  _removeJoinedSession(session) {
    this.joinedSessions.delete(session);
  }
}
