import './audioIndicator';

import SegmentationSettingsService from '../settings/segmentationSettings.service';
import UserStreamService from './userStream.service';
import RaiseHandService from './raiseHand.service';
import UserTileService from './userTile.service';
import UserTileComponent from './userTile.component';

angular
  .module('meeting')
  .service('segmentationSettingsService', SegmentationSettingsService)
  .service('userStreamService', UserStreamService)
  .service('raiseHandService', RaiseHandService)
  .service('userTileService', UserTileService)
  .component('userTile', UserTileComponent);
