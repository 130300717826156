import { browser, bind } from 'utils/util';
import Recorder from '../components/recording/Recorder';


const LEAVE_MESSAGE = 'Are you sure you want to leave this meeting room?';


export default class UnloadService {
  static get $inject() {
    return [
      'meetingBroadcastService',
      'fileService',
      'meetingService',
      'recorderService',
      'recordingService',
      'sharingService',
      'meetingSocketService',
      'streamService',
      'userService',
    ];
  }

  constructor(
    meetingBroadcastService,
    fileService,
    meetingService,
    recorderService,
    recordingService,
    sharingService,
    meetingSocketService,
    streamService,
    userService
  ) {
    bind(this);

    this.meetingBroadcastService = meetingBroadcastService;
    this.fileService = fileService;
    this.meetingService = meetingService;
    this.recorderService = recorderService;
    this.recordingService = recordingService;
    this.sharingService = sharingService;
    this.meetingSocketService = meetingSocketService;
    this.streamService = streamService;
    this.userService = userService;

    window.addEventListener('beforeunload', this._onBeforeUnload);
    window.addEventListener('unload', this._onUnload);
    window.addEventListener('pagehide', this._onUnload);
    window.addEventListener('pageshow', this._onPageShow);
  }


  redirectWithoutConfirmation(redirectUrl) {
    window.removeEventListener('beforeunload', this._onBeforeUnload);
    window.top.location = redirectUrl;
  }

  reloadPageWithoutConfirmation() {
    window.removeEventListener('beforeunload', this._onBeforeUnload);
    location.reload();
  }


  _onBeforeUnload(event) {
    if(browser.isFirefox()) {
      // On Firefox it's not possible to do this in the 'unload' handler: in case of a refresh
      // the client leaves before the socket has been closed properly. We haven't found a way
      // to distinguish between refresh and tab close.
      this.meetingSocketService.close(this.meetingSocketService.CloseReason.CLIENT_LEAVING);

    } else if(this._shouldConfirmLeave) {
      event.returnValue = LEAVE_MESSAGE;
      return LEAVE_MESSAGE;
    }
  }

  get _shouldConfirmLeave() {
    let isJoined = this.userService.mySession.isAlive();

    let numEnabledStreams = (
      Object.values(this.streamService.getAllLocal())
        .filter(stream => stream.enabled)
        .length
    );
    let numJoinedUsers = this.userService.joined.size;

    return isJoined && (
      numEnabledStreams > 0 && numJoinedUsers > 1
      || this.recorderService.state !== Recorder.State.INACTIVE
      || this.recordingService.isUploading
      || this.recordingService.isDownloading
      || this.fileService.isUploading
      || this.isSaving
    );
  }

  get isSaving() {
    return (
      this.sharingService.isProcessing
      || this.meetingBroadcastService.isSaving
    );
  }


  _onUnload() {
    this.meetingSocketService.close(this.meetingSocketService.CloseReason.CLIENT_LEAVING);
  }


  _onPageShow(event) {
    // Reload when accessed using back/forward
    if(event.persisted) {
      this.reloadPageWithoutConfirmation();
    }
  }
}
