import { bind } from 'utils/util';
import template from './loading.html?raw';


/**
 * Show a loading animation.
 *
 * @param {number} [size=medium] - The size of the loading animation. Options: small, medium,
 *  large, custom. If `custom`, the size is determined by the parent element.
 * @param {number} [timeout=undefined] - The number of milliseconds to wait before showing the
 *  loading animation. If undefined, the animation is shown immediately.
 */
class LoadingController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    bind(this);

    this.$elem = $elem;
    this.shouldShow = false;
  }


  $onInit() {
    if(this.timeout == null) {
      this.show();
    } else {
      $timeout(this.show, this.timeout);
    }
  }

  $onDestroy() {
    if(this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }


  show() {
    this.shouldShow = true;
    $timeout(() => {
      let $elemSvg = this.$elem.find('.loading');

      if(window.ResizeObserver && $elemSvg.length) {
        this.resizeObserver = new window.ResizeObserver(this._onResize);
        this.resizeObserver.observe($elemSvg[0]);
      }
    });
  }


  _onResize(entries) {
    let size = Math.min(entries[0].contentRect.width, entries[0].contentRect.height);
    let paths = this.$elem.find('path');
    paths.css('stroke-width', 24 / size);
  }
}


export default {
  controller: LoadingController,
  controllerAs: 'loadingCtrl',
  template,

  bindings: {
    size: '<',
    timeout: '<',
  },
};
