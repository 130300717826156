import { StreamType } from  'meeting/meeting-room/stream';
import ScreenTile from './ScreenTile';


/**
 * This service was originally written to allow a single participant to share multiple screens.
 * This has proven to cause more confusion than it adds value, so it is not possible to do this
 * through the view anymore. This service still supports it though, which is why we have the
 * property `this.localTiles` (an object of Tile instances) and not `this.localTile` (a single Tile
 * instance).
 */

export default class ScreenshareService {
  static get $inject() {
    return [
      'browserService',
      'notificationService',
      'meetingBroadcastService',
      'streamService',
      'localStreamService',
      'tileService',
      'tileFactory',
    ];
  }

  constructor(
    browserService,
    notificationService,
    meetingBroadcastService,
    streamService,
    localStreamService,
    tileService,
    tileFactory
  ) {
    this._bind();

    this.browserService = browserService;
    this.notificationService = notificationService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.streamService = streamService;
    this.localStreamService = localStreamService;
    this.tileService = tileService;
    this.tileFactory = tileFactory;

    this.tiles = {};
    this.localTiles = {};

    this.sendViewportThrottled = {};

    streamService.on('add', this._onStreamAdd);
    streamService.on('remove', this._onStreamRemove);
    meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);
  }

  _bind() {
    this._onStreamAdd = this._onStreamAdd.bind(this);
    this._onStreamRemove = this._onStreamRemove.bind(this);

    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);

    this._afterBroadcastInitialization = this._afterBroadcastInitialization.bind(this);
  }


  _getTileId(stream) {
    return `${stream.session.id}:${stream.groupId}`;
  }

  _addTile(stream) {
    let id = this._getTileId(stream);
    let tile = this.tileFactory.create(ScreenTile, id, stream);
    this.tiles[id] = tile;
    if(stream.isLocal) {
      this.localTiles[id] = tile;
    }
    this.tileService.add(tile);
  }


  _removeTile(stream) {
    let id = this._getTileId(stream);
    let tile = this.tiles[id];
    if(!tile) {
      return;
    }

    delete this.tiles[id];
    delete this.localTiles[id];
    this.tileService.remove(tile);
  }


  _onStreamAdd(stream) {
    if(!(stream.type === StreamType.SCREEN || stream.type === StreamType.SCREEN_AUDIO)) {
      return;
    }

    if(stream.type === StreamType.SCREEN) {
      this._addTile(stream);

    } else {
      let id = this._getTileId(stream);
      let tile = this.tiles[id];
      if(tile) {
        tile.setAudio(stream);
      }
    }

    this._startStream(stream);
  }


  _onStreamRemove(stream) {
    if(stream.type === StreamType.SCREEN) {
      this._removeTile(stream);
    }
  }


  _afterBroadcastInitialization() {
    Object.values(this.tiles).forEach(tile => {
      this._startStream(tile.streams.video);
      if(tile.streams.audio) {
        this._startStream(tile.streams.audio);
      }
    });
  }


  _startStream(stream) {
    if(
      !this.meetingBroadcastService.initializing
      && (stream.type === StreamType.SCREEN || !stream.user.isMe)
    ) {
      stream.start();
    }
  }



  /********************************
   * Add and remove local streams *
   ********************************/

  add(screenType) {
    this.localStreamService.add([StreamType.SCREEN, StreamType.SCREEN_AUDIO], screenType);
  }


  remove(tiles) {
    if(tiles == null) {
      tiles = Object.values(this.localTiles);
    }
    if(tiles.length === undefined) {
      tiles = [tiles];
    }

    let promises = tiles.map(tile => this._remove(tile));
    return $q.all(promises);
  }


  _remove(tile) {
    if(!tile.session.isLocal) {
      return;
    }

    let promises = [this.localStreamService.remove(tile.streams.video)];
    if(tile.streams.audio) {
      promises.push(this.localStreamService.remove(tile.streams.audio));
    }
    return $q.all(promises);
  }
}
