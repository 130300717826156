import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TruncatedTextComponent } from './truncated-text.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  declarations: [
    TruncatedTextComponent,
  ],
  exports: [
    TruncatedTextComponent,
  ],
})
export class TruncatedTextModule {}
