// By convention, ISO 639 language codes should appear in lower case
// (as opposed to country codes, which are capitalized).

// keep this in sync with languages.py
export const all = [
  { name: gettext('English'), code: 'en' },
  { name: gettext('Dutch'), code: 'nl' },
  { name: gettext('French'), code: 'fr' },
  { name: gettext('German'), code: 'de' },
  { name: gettext('Spanish'), code: 'es' },
  { name: gettext('Italian'), code: 'it' },
  { name: gettext('Chinese'), code: 'zh' },
  { name: gettext('Japanese'), code: 'ja' },
  { name: gettext('Portuguese'), code: 'pt' },
  { name: gettext('Greek'), code: 'el' },
  { name: gettext('Turkish'), code: 'tr' },
  { name: gettext('Czech'), code: 'cs' },
  { name: gettext('Somali'), code: 'so' },
  { name: gettext('Slovak'), code: 'sk' },
  { name: gettext('Russian'), code: 'ru' },
  { name: gettext('Hungarian'), code: 'hu' },
  { name: gettext('Bulgarian'), code: 'bg' },
  { name: gettext('Bosnian'), code: 'bs' },
  { name: gettext('Arabic'), code: 'ar' },
  { name: gettext('Albanian'), code: 'sq' },
  { name: gettext('Romanian'), code: 'ro' },
  { name: gettext('Polish'), code: 'pl' },
  { name: gettext('Catalan'), code: 'ca' },
  { name: gettext('Danish'), code: 'da' },
  { name: gettext('Ukranian'), code: 'uk' },
  { name: gettext('Lithuanian'), code: 'lt' },
  { name: gettext('Norwegian'), code: 'no' },
  { name: gettext('Finnish'), code: 'fi' },
  { name: gettext('Swedish'), code: 'sv' },
];

// languages considered primary in TLF and Vectera
export const primary = [
  { name: gettext('English'), code: 'en' },
  { name: gettext('Dutch'), code: 'nl' },
  { name: gettext('French'), code: 'fr' },
  { name: gettext('German'), code: 'de' },
  { name: gettext('Spanish'), code: 'es' },
  { name: gettext('Italian'), code: 'it' },
];

// KEEP THIS IN SYNC WITH bin/pull-translations
// language with vectera i18n support
export const localized = [
  { name: gettext('English'), code: 'en' },
  { name: gettext('Dutch'), code: 'nl' },
  { name: gettext('French'), code: 'fr' },
  { name: gettext('German'), code: 'de' },
  { name: gettext('Spanish'), code: 'es' },
  { name: gettext('Italian'), code: 'it' },
  { name: gettext('Lithuanian'), code: 'lt' },
];


