import EventEmitter from '../util/EventEmitter';


export default class FocusService {
  constructor() {
    this._bind();
    EventEmitter.setup(this, ['hasFocus', 'isVisible']);

    this.hasFocus = true;
    this.isVisible = true;

    window.addEventListener('focus', this._updateFocus);
    window.addEventListener('blur', this._updateFocus);
    this._updateFocus();

    document.addEventListener('visibilitychange', this._updateVisible);
    this._updateVisible();
  }

  _bind() {
    this._updateFocus = this._updateFocus.bind(this);
    this._updateVisible = this._updateVisible.bind(this);
  }


  _updateFocus() {
    let hasFocus = document.hasFocus();
    if(hasFocus !== this.hasFocus) {
      this.hasFocus = hasFocus;
      this.emit('hasFocus', hasFocus);
    }
  }


  _updateVisible() {
    let isVisible = !document.hidden;
    if(isVisible !== this.isVisible) {
      this.isVisible = isVisible;
      this.emit('isVisible', isVisible);
    }
  }
}
