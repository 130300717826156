<ng-container>
  <div *ngIf="shouldShowUpgradeInfo">
    <p>{{ upgradeText }}</p>

    <div class="d-flex">
      <a
        class="btn btn--primary mr-2"
        [href]="siteService.site.subscriptionLink"
        target="_blank"
        i18n
        data-testid="collectPaymentsUpgradeButton"
      >
        Upgrade now
      </a>

      <a
        class="btn btn--secondary"
        [href]="'stripe' | helpArticle"
        target="_blank"
        i18n
        data-testid="collectPaymentsHelpArticleButton"
      >
        Learn more
      </a>
    </div>
  </div>


  <div *ngIf="shouldShowStripeInfo">
    <img
      [src]="urlService.images.stripeLogo"
      alt="Stripe logo"
      class="appointment-type-payments__stripe-logo"
    />

    <ng-container *ngIf="isAdmin">
      <p i18n>
        Accept payments during the booking process with Stripe.
        You can log in with your existing account or create a new Stripe account.
        When you are connected, your team can set up payment configuration in the meeting type configuration.
      </p>

      <div class="d-flex">
        <a
          class="btn btn--primary mr-2"
          [href]="urlService.urls.stripeConfig"
          target="_blank"
          i18n
        >
          Connect
        </a>

        <a
          class="btn btn--secondary"
          [href]="'stripe' | helpArticle"
          target="_blank"
          i18n
        >
          Get help
        </a>
      </div>
    </ng-container>

    <div *ngIf="!isAdmin" i18n>
      Accept payments during the booking process with Stripe.
      Contact an admin of your team to set up Stripe.
    </div>
  </div>


  <form
    *ngIf="shouldShowForm"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
    class="appointment-type-payments__form"
  >
    <div
      class="field field--warning p"
      *ngIf="stripeConfigInvalid"
    >
      <field-icon [type]="'warning'"></field-icon>
      <p i18n>
        This meeting type is configured to collect payments, but your Stripe integration has expired.
        Your customers will not be able to book meetings of this type.
      </p>

      <p *ngIf="isAdmin" i18n>
        Disable payments below or <a [href]="urlService.urls.stripeConfig" target="_blank">set up your Stripe integration</a> to fix this.
      </p>
      <p *ngIf="!isAdmin" i18n>
        Disable payments below or ask a team admin to set up the Stripe integration.
      </p>
    </div>

    <div class="form-group">
      <div [withSettingsErrors]="errors.paymentOption">
        <input
          id="paymentEnabled"
          type="checkbox"
          class="checkbox"
          [(ngModel)]="paymentEnabled"
          [ngModelOptions]="{standalone: true}"
          [disabled]="!hasPermissionToEdit"
        />
        <label
          for="paymentEnabled"
          class="checkbox__label"
          i18n
        >
          Require payment when booking a meeting
        </label>
      </div>
    </div>

    <div *ngIf="paymentEnabled">
      <div class="form-group">
        <label i18n>Amount</label>
        <div class="d-flex">
          <input
            class="input appointment-type-payments__input-amount"
            type="number"
            formControlName="paymentAmount"
            [class.error]="errors.paymentAmount && errors.paymentAmount.length > 0"
          >

          <dropdown-select
            [options]="currencies"
            formControlName="paymentCurrency"
            [class.error]="errors.paymentCurrency && errors.paymentCurrency.length > 0"
          ></dropdown-select>
        </div>

        <settings-errors [settingsErrors]="errors.paymentAmount"></settings-errors>
        <settings-errors [settingsErrors]="errors.paymentCurrency"></settings-errors>
      </div>

      <div class="form-group">
        <label>
          <span i18n>Payment terms</span>
          <help-tooltip
            text="Add a refund and cancellation policy to improve the performance of your booking page. You can include a URL that links to your payment terms."
            i18n-text
          ></help-tooltip>
        </label>
        <textarea
          class="textarea"
          formControlName="paymentTerms"
          [withSettingsErrors]="errors.paymentTerms"
        ></textarea>
      </div>
    </div>
  </form>
</ng-container>
