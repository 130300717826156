import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { dateTime } from 'utils/util';
import { StartOfWeek } from 'utils/util/dateTime';

const DAYS_IN_WEEK = 7;
// TODO: Remove this constant once availabilities are implemented
const RANDOM_AVAILABILITIES_COUNT = 10;

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Output() public dateSelected = new EventEmitter<Date>();
 
  public visibleYear = new Date().getFullYear();
  public dayNames = dateTime.getWeekdaysShort();
  public selectedDate = new Date();
  public calendar: (Date | null)[][] = [];
  
  private availabilities: number[] = [];
  private visibleMonth = new Date().getMonth();
  
  public get monthAsString(): string {
    return dateTime.getMonthName(this.visibleMonth);
  }

  public ngOnInit(): void {
    this.generateCalendarDates();
    this.generateRandomAvailabilities();
  }

  private generateCalendarDates(): void {
    const firstDayOfMonth = dateTime.getFirstDayOfMonth(this.visibleMonth, this.visibleYear);
    const daysInMonth = dateTime.getDaysInMonth(this.visibleMonth, this.visibleYear);
    const emptyDates = this.calculateEmptyDatesOfPreviousMonth(
      firstDayOfMonth, 
      dateTime.getFirstDayOfWeek()
    );
    const weeksInMonth = this.calculateWeeksInMonth(emptyDates, daysInMonth);

    this.calendar = [];
    let dateCounter = 1;

    for(let weekIndex = 0; weekIndex < weeksInMonth; weekIndex++) {
      const week: (Date | null)[] = [];
      for(let dayIndex = 0; dayIndex < DAYS_IN_WEEK; dayIndex++) {
        if((weekIndex === 0 && dayIndex < emptyDates) || dateCounter > daysInMonth) {
          week.push(null);
        } else {
          week.push(new Date(this.visibleYear, this.visibleMonth, dateCounter));
          dateCounter++;
        }
      }
      this.calendar.push(week);
    }
  }

  private calculateEmptyDatesOfPreviousMonth(
    firstDayOfMonth: number, 
    firstDayOfWeek: StartOfWeek
  ): number {
    // This calculates the number of days from the previous month that need to be displayed as 
    // 'empty days' in the calendar. If firstDayOfMonth is 0 (sunday) and 
    // firstDayOfWeek is 1 (monday for some european countries), we need to display 6 empty days,
    // that's why we add DAYS_IN_WEEK (7) and perform modulo DAYS_IN_WEEK to get the correct 
    // number of empty days instead of a negative number.
    return (firstDayOfMonth - firstDayOfWeek + DAYS_IN_WEEK) % DAYS_IN_WEEK;
  }

  private calculateWeeksInMonth(daysOffset: number, daysInMonth: number): number {
    return Math.ceil((daysOffset + daysInMonth) / DAYS_IN_WEEK);
  }

  // TODO: Remove this method and replace it with a real private  once implemented
  private generateRandomAvailabilities(): void {
    this.availabilities = Array.from({ length: RANDOM_AVAILABILITIES_COUNT }, () =>
      (Math.floor(Math.random() * 31) + 1)
    );
  }

  public previousMonth(): void {
    this.changeMonth(-1);
  }

  public nextMonth(): void {
    this.changeMonth(1);
  }

  private changeMonth(offset: number): void {
    this.visibleMonth += offset;

    if(this.visibleMonth < 0) {
      this.visibleMonth = 11;
      this.visibleYear--;
    } else if(this.visibleMonth > 11) {
      this.visibleMonth = 0;
      this.visibleYear++;
    }

    this.generateCalendarDates();
    this.generateRandomAvailabilities ();
  }

  public isAvailable(date: Date): boolean {
    return this.availabilities .includes(date.getDate());
  }

  public isToday(date: Date): boolean {
    return dateTime.isToday(date);
  }

  public isSelected(date: Date): boolean {
    return dateTime.areSameDay(date, this.selectedDate);
  }

  public selectDate(selectedDate: Date): void {
    if(!dateTime.areSameDay(selectedDate, this.selectedDate)) {
      this.selectedDate = selectedDate;
      this.dateSelected.emit(selectedDate);
    }
  }

  public displayDate(date: Date): string {
    return date.getDate().toString();
  }
}
