<div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

<div class="container container--lg pp">
  <go-back-header
    [url]="urlService.urls.contactFormsOverview"
    [headerText]="headerText"
  ></go-back-header>

  <div *ngIf="shouldShowTLFPromo" class="d-none d-sm-flex field field--marketing p" data-testid="responsePageTLFBanner">
    <field-icon [type]="'marketing'"></field-icon>
    <div>
      <span i18n>Planning to use contact forms for lead generation? Connect Vectera to the Teamleader Focus CRM where you can sell, bill and organise all in one place. <a href="https://www.teamleader.eu/focus/" target="_blank" (click)="trackTLFPromoInteraction()" data-testid="responsePageTLFWebsite">Try Teamleader Focus for free.</a></span>
      <span i18n>Already a Teamleader Focus user? <a [href]="urlService.urls.teamleaderIntegration" target="_blank" (click)="trackTLFPromoInteraction()" data-testid="responsePageIntegrationsLink">Set up the integration.</a></span>
    </div>
    <svg-icon (click)="hideTLFPromo()" class="ml-2 align-self-start clickable" [svgName]="'close'"></svg-icon>
  </div>

  <fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>

  <div
    *ngFor="let response of responses"
    class="card"
    data-testid="responsesCard"
  >
    <h2 i18n>Submitted on {{ response.dateCreated | date:'medium' }}</h2>
    <div class="mb--4">
      <div
        *ngFor="let answer of response.answers"
        class="p"
        data-testid="contactFormAnswer"
      >
        <div class="text-bold" data-testid="contactFormAnswerLabel">{{ answer.label }}</div>
        <div *ngIf="isEmpty(answer)" class="text-muted" i18n>(blank)</div>
        <div *ngIf="!isEmpty(answer)">
          <a *ngIf="isURL(answer)" [href]="getLink(answer.textAnswer)" target="_blank" data-testid="answerIsURL">{{ answer.textAnswer }}</a>
          <div *ngIf="isAttachment(answer)">
            <a
              *ngFor="let attachment of answer.answer"
              [href]="attachment.url"
              target="_blank"
              data-testid="answerIsAttachmentLink"
            >
              {{ attachment.name }}
            </a>
          </div>
          <div *ngIf="isRegular(answer)" class="text-pre" data-testid="answerText">{{ answer.textAnswer }}</div>
        </div>
      </div>
    </div>
  </div>


  <div class="d-flex justify-content-center">
    <pagination
      [page]="page"
      [numPages]="numPages"
      (pageChange)="onPageChange($event)"
    ></pagination>
  </div>
</div>
