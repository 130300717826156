import { downgradeComponent } from '@angular/upgrade/static';
import { ContactFormConfigComponent } from './contact-form-config';
import { ContactFormPublicComponent } from './contact-form-public';
import { ContactFormsComponent } from './contact-forms';
import { ContactFormResponsesComponent } from './contact-form-responses';


angular
  .module('contactForm', [])

  .directive(
    'contactFormConfig',
    downgradeComponent({ component: ContactFormConfigComponent })
  )
  .directive(
    'contactFormPublic',
    downgradeComponent({ component: ContactFormPublicComponent })
  )
  .directive(
    'contactForms',
    downgradeComponent({ component: ContactFormsComponent })
  )
  .directive(
    'contactFormResponses',
    downgradeComponent({ component: ContactFormResponsesComponent })
  );
