import { Badge } from 'utils/ui-components/badge';
import { Appointment } from 'scheduling/models/Appointment';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { validateEnumList } from 'utils/util';
import { AppointmentLocation } from 'scheduling/models/appointment-location';

export const locationLabels: Record<AppointmentLocation, string> = {
  [AppointmentLocation.ONLINE]: $localize `Online`,
  [AppointmentLocation.OFFLINE]: $localize `In person`,
  [AppointmentLocation.PHONE_CALL]: $localize `Phone`,
} as const;

export const locationBadges: Record<AppointmentLocation, Badge> = {
  [AppointmentLocation.ONLINE]: {
    label: locationLabels[AppointmentLocation.ONLINE],
    icon: { name: 'videocam' },
  },
  [AppointmentLocation.OFFLINE]: {
    label: locationLabels[AppointmentLocation.OFFLINE],
    icon: { name: 'building', size: 14 },
  },
  [AppointmentLocation.PHONE_CALL]: {
    label: locationLabels[AppointmentLocation.PHONE_CALL],
    icon: { name: 'phone', size: 14 },
  },
} as const;

export const locationBadgesColor: Record<AppointmentLocation, Badge> = {
  [AppointmentLocation.ONLINE]: {
    ...locationBadges[AppointmentLocation.ONLINE],
    style: 'info',
  },
  [AppointmentLocation.OFFLINE]: {
    ...locationBadges[AppointmentLocation.OFFLINE],
    style: 'success',
  },
  [AppointmentLocation.PHONE_CALL]: {
    ...locationBadges[AppointmentLocation.PHONE_CALL],
    style: 'warning',
  },
} as const;

export function getAppoinmentTypeLocationBadges(
  appointmentType: AppointmentType, color = true
): Badge[] {
  const locations = validateEnumList(AppointmentLocation, appointmentType.locations);
  const badgeDefinitions = color ? locationBadgesColor : locationBadges;
  const badges = locations.map(location => badgeDefinitions[location]);
  return badges;
}
export function getAppointmentLocationBadge(appointment: Appointment, color = true): Badge {
  const badgeDefinitions = color ? locationBadgesColor : locationBadges;
  return badgeDefinitions[appointment.location];
}
