import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilsModule } from 'utils/utils.module';
import { ContactFormsComponent } from './contact-forms.component';
import { ContactFormsTableComponent } from './contact-forms-table/contact-forms-table.component';
import { ContactFormPreviewModule } from 'contactForm/contact-form-preview';


@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    UtilsModule,
    FormsModule,

    ContactFormPreviewModule,
  ],
  declarations: [
    ContactFormsComponent,
    ContactFormsTableComponent,
  ],
  exports: [
    ContactFormsComponent,
  ],
})
export class ContactFormsModule {}
