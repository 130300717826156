import { Component, OnInit } from '@angular/core';
import { DashboardScope, DataPageComponent } from 'dashboard/data-page/data-page.component';
import { SiteService } from 'utils/site.service';
import { UrlService } from 'utils/url.service';
import { getQueryParameter } from 'utils/util';
import { ContactForm, OrganizationContactForm, UserContactForm } from '../models/ContactForm';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { UrlQueryParamsService } from 'scheduling/manage/appointments/url-query-params.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';

@Component({
  selector: 'contact-forms',
  templateUrl: './contact-forms.component.html',
  providers: [UrlQueryParamsService]
})
export class ContactFormsComponent extends DataPageComponent implements OnInit {
  public contactForms: ContactForm[] = [];
  public shouldShowHint = false;
  override perPage = 10;


  constructor(
    private usageTrackingService: UsageTrackingService,
    public siteService: SiteService,
    public urlService: UrlService,
    private urlQueryParamsService: UrlQueryParamsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeQueryParams();
  }

  private initializeQueryParams(): void {
    const scope = this.urlQueryParamsService.queryParams.get('scope');
    if (scope) {
      this.scope = this.findScope(scope);
    }

    const page = this.urlQueryParamsService.queryParams.get('page');
    if (page) {
      this.page = parseInt(page, 10);
    }
  }

  public onScopeChange(scope: Scope): void {
    if (this.isDefaultScope(scope)) {
      this.urlQueryParamsService.removeQueryParam('scope');
    } else {
      this.urlQueryParamsService.setQueryParam('scope', scope.id);
    }
    this.scope = scope;
  }

  private isDefaultScope(scope: Scope): boolean {
    return scope === DashboardScope.PERSONAL;
  }

  public onPageChange(page: number): void {
    if (this.isDefaultPage(page)) {
      this.urlQueryParamsService.removeQueryParam('page');
    } else {
      this.urlQueryParamsService.setQueryParam('page', page.toString());
    }
    this.page = page;
  }

  private isDefaultPage(page: number): boolean {
    return page === 1;
  }

  override fetch() {
    return this.fetchContactForms();
  }

  private fetchContactForms() {
    const modelConfig = this.isDefaultScope(this.scope) ?
      {
        model: UserContactForm,
        identifiers: { userId: this.requestUserService.user.id }
      } :
      {
        model: OrganizationContactForm,
        identifiers: { organizationId: this.requestUserService.user.organizationId }
      };

    const apiConfig = {
      params: {
        page: this.page,
        perPage: this.perPage,
        orderBy: '-isActive,-dateModified',
        include: 'createdBy,responses',
      },
    };
    return this.listCancelable(modelConfig, apiConfig).then(({ data: contactForms }) => {
      if (contactForms.length === 0 && this.shouldSkipWelcome) {
        window.location.href = this.urlService.urls.contactFormCreate;
        // Return a promise that never resolves while we are redirecting.
        return new Promise(() => { });

      } else {
        const search = new URLSearchParams(window.location.search);
        search.delete('skipWelcome');
        this.location.replaceState(window.location.pathname, search.toString());

        this.contactForms = contactForms.filter(contactForm => {
          // TODO VECT-2455 the API should not pass invalid CFs (createdBy not filled in)
          return contactForm.createdBy !== null;
        }) as ContactForm[];
        return null;
      }
    });
  }


  get shouldSkipWelcome() {
    return getQueryParameter('skipWelcome') != null;
  }

  get shouldShowWelcome() {
    return (
      !this.stateService.isInitializing
      && this.contactForms.length === 0
      && !this.fetchError
    );
  }

  get shouldShowMain() {
    return (
      !this.stateService.isInitializing
      && !this.shouldShowWelcome
    );
  }

  get isTeamleader() {
    return this.requestUserService.user.isTeamleaderUser;
  }

  get canCreate() {
    // If someone else in the team already has a contact form, we still want to let this user try
    // it out for themselves, even though the created contact form will be deactivated with no
    // possibility to activate (max 1 active contact form per team).
    const personalContactForms = this.contactForms.filter(contactForm => {
      return (
        contactForm.createdBy.id === this.requestUserService.user.id
        && contactForm.isActive
      );
    });
    return (
      this.requestUserService.user.organization.subscription.status !== 'free'
      || personalContactForms.length === 0
    );
  }

  get contactFormLimitInfo() {
    const freePlanName = this.siteService.site.freePlanName;
    // eslint-disable-next-line max-len
    return $localize`You are currently on the ${freePlanName} plan. Only one Contact form can be active within your team.`;
  }


  track(event: string) {
    this.usageTrackingService.createSegmentEvent(event, 'contactForm');
  }
}
