<h1 class="page-title">UI Elements</h1>

<h2>Color Picker</h2>
<color-picker></color-picker>

<h2>Confirm Change</h2>
<confirm-change
  [value]="'Lorem Ipsum'"
  [shouldConfirm]="true"
  dialogText="Are you sure?"
>
  <input
    class="input"
  />
</confirm-change>

<h2>Datepicker</h2>
<datepicker
  class="text-teal-500"
></datepicker>


<h2>Dropdowns</h2>

<div class="h3 d-flex align-items-center">
  <span>Dropdown Select</span>
  <svg-icon
    class="icon ml-2"
    [svgName]="'info_badged'"
    matTooltip="Single select, no search function, no support for icons"
  ></svg-icon>
</div>
<dropdown-select [options]="dropdownOptions"></dropdown-select>

<div class="h3 d-flex align-items-center">
  <span>Combobox</span>
  <svg-icon
    class="icon ml-2"
    [svgName]="'info_badged'"
    matTooltip="Single select, with categories, search function, support for icons"
  ></svg-icon>
</div>
<combo-box [options]="comboboxOptions"></combo-box>

<div class="h3 d-flex align-items-center">
  <span>Font Picker</span>
  <svg-icon
    class="icon ml-2"
    [svgName]="'info_badged'"
    matTooltip="Combobox that is preconfigured for browsing google fonts library"
  ></svg-icon>
</div>
<font-picker></font-picker>

<div class="h3 d-flex align-items-center">
  <span>Multi select dropdown</span>
  <svg-icon
    class="icon ml-2"
    [svgName]="'info_badged'"
    matTooltip="Multi select, no search function"
  ></svg-icon>
</div>
<multi-select-dropdown [options]="dropdownOptions"></multi-select-dropdown>


<h2>File Pickers</h2>
<file-picker
  uploadText="Upload file"
  i18n-uploadText
  clearText="Clear file"
  i18n-clearText
></file-picker>

<h3> Image picker </h3>
<image-picker
  uploadText="Upload image..."
  i18n-uploadText
  clearText="Clear image"
  i18n-clearText
></image-picker>

<h2>Segmented buttons</h2>
<div class="ui-elements__item" data-testid="segmentedButton.simple">
  <h3>Simple</h3>

  <segmented-button
    [options]="['Option 1', 'Option 2', 'Option 3']"
    [(ngModel)]="segmentedButton.simple"
  ></segmented-button>

  <div class="ui-elements__value">{{ serialize(segmentedButton.simple) }}</div>
</div>


<div class="ui-elements__item" data-testid="segmentedButton.nullable">
  <h3>Nullable</h3>

  <segmented-button
    [options]="['Option 1', 'Option 2', 'Option 3']"
    [(ngModel)]="segmentedButton.nullable"
    [nullable]="true"
  ></segmented-button>

  <div class="ui-elements__value">{{ serialize(segmentedButton.nullable) }}</div>
</div>


<div class="ui-elements__item" data-testid="segmentedButton.withInitialValue">
  <h3>With initial value</h3>

  <segmented-button
    [options]="['Option 1', 'Option 2', 'Option 3']"
    [(ngModel)]="segmentedButton.withInitialValue"
  ></segmented-button>

  <div class="ui-elements__value">{{ serialize(segmentedButton.withInitialValue) }}</div>
</div>


<div class="ui-elements__item" data-testid="segmentedButton.disabled">
  <h3>Disabled</h3>

  <segmented-button
    [options]="['Option 1', 'Option 2', 'Option 3']"
    [(ngModel)]="segmentedButton.disabled"
    [disabled]="true"
  ></segmented-button>

  <div class="ui-elements__value">{{ serialize(segmentedButton.disabled) }}</div>
</div>


<div class="ui-elements__item" data-testid="segmentedButton.fullOptions">
  <h3>Full options</h3>

  <segmented-button
    [options]="[{ value: 1, label: 'Option 1' }, { value: 2, label: 'Option 2' }, { value: 3, label: 'Option 3' }]"
    [(ngModel)]="segmentedButton.fullOptions"
  ></segmented-button>

  <div class="ui-elements__value">{{ serialize(segmentedButton.fullOptions) }}</div>
</div>

<h3>Duration picker</h3>
<duration-picker
  [durationUnit]="'minutes'"
  [durationOptions]="[30, 45, 60]"
  [durationAllowCustom]="true"
>
</duration-picker>
