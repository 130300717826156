import { logger, platform, EventEmitter } from 'utils/util';

export const ConnectionType = {
  MEETING: 'meeting',
  WAITING_ROOM: 'waitingRoom'
};


export const ReadyState = Object.freeze({
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
});

// This dict should be kept up to date with node script WebSocketConnection.js
export const CloseReason = Object.freeze({
  // These are used during the connecting stage
  BAD_REQUEST: 'bad request',
  INVALID_SESSION: 'invalid session',
  NOT_AUTHORIZED: 'not authorized',

  // These can be used both during connection and after a connection has been established
  INTERNAL_SERVER_ERROR: 'internal server error',

  // These are used only after a connection has been established
  LOST: 'lost',
  STALE_SESSION: 'stale session',
  STALE_ACCESS_LEVEL: 'stale access level',
  NEW_CONNECTION: 'new connection',
  ACCESS_DENIED: 'kicked out',
  OLD_SESSION: 'old session',
  PING_TIMEOUT: 'ping timeout',
  CLIENT_LEAVING: 'client leaving',
  SEND_FAILURE: 'send failure',
  SERVER_EXIT: 'server exit',
  MEETING_RESET: 'meeting reset',
  BAD_MESSAGE: 'bad message',
  INACTIVE_SESSION: 'inactive session',
  REDIRECT: 'redirect',

  // We need this for when a session is exited on the server due to ending a call for everyone
  EXITED: 'exited',
  FORCED_ENDED: 'forced ended',
});

const WS_PROTOCOL = 'echo-protocol';

const PING_MESSAGE = '--ping--';
const PONG_MESSAGE = '--pong--';
const PING_TIMEOUT = 10000;

const OPEN_BACKOFF_BASE = 1000;
const OPEN_BACKOFF_MULTIPLIER = 1.5;
const OPEN_BACKOFF_MAX = 60000;
const OPEN_ABORT_TIMEOUT = 8000;

export default class SocketService {
  get CloseReason() {
    return CloseReason;
  }

  static get $inject() {
    return [
    ];
  }

  constructor() {
    this._bind();
    EventEmitter.setup(this, ['startOpen', 'open', 'close', 'localClose', 'message']);

    this.openTimeout = null;
    this.nextOpenTime = 0;
    this.abortOpenTimeout = null;
    this.pingTimeout = null;

    this.openBackOff = 0;
    this.abortOpenDuration = 8000;
    this.resetOpenBackOffTimeout = null;
    this.firstOpen = false;

    this.closeEmitted = false;

    this.ws = null;
  }


  _bind() {
    this.open = this.open.bind(this);
    this._openWithBackoff = this._openWithBackoff.bind(this);
    this._abortOpen = this._abortOpen.bind(this);
    this._onOpen = this._onOpen.bind(this);
    this._onCloseEvent = this._onCloseEvent.bind(this);
    this._onMessage = this._onMessage.bind(this);
    this._onPingTimeout = this._onPingTimeout.bind(this);
    this._resetOpenBackOff = this._resetOpenBackOff.bind(this);
  }


  get state() {
    if(this.ws) {
      return this.ws.readyState;
    } else {
      return ReadyState.CLOSED;
    }
  }

  _emitWrapped(...args) {
    $rootScope.$evalAsync(() => {
      this.emit(...args);
    });
  }


  /******************
   * Open and close *
   ******************/

  _resetOpenBackOff() {
    clearTimeout(this.resetOpenBackOffTimeout);
    this.resetOpenBackOffTimeout = null;
    this.openBackOff = 0;
  }


  _openWithBackoff() {
    if(this.firstOpen) {
      this.firstOpen = false;
      this.open();

    } else if(!this.openTimeout) {
      this.openBackOff = platform(
        OPEN_BACKOFF_BASE,
        this.openBackOff * OPEN_BACKOFF_MULTIPLIER,
        OPEN_BACKOFF_MAX);

      logger.info('Trying to connect in %s s', this.openBackOff / 1000);
      this.openTimeout = setTimeout(this.open, this.openBackOff);
      this.nextOpenTime = Date.now() + this.openBackOff;
    }
  }


  getTimeUntilReopen() {
    return this.nextOpenTime - Date.now();
  }


  open() {
    if(this.openTimeout) {
      clearTimeout(this.openTimeout);
      this.openTimeout = null;
    } else {
      this._emitWrapped('startOpen');
    }
    this.nextOpenTime = 0;

    if(this.state === ReadyState.CLOSED) {
      let url = this._getWsUrl();
      this.ws = new WebSocket(url, WS_PROTOCOL);

      // This listeners must be unset in `close() and _abortOpen()`
      this.ws.onopen = this._onOpen;
      this.ws.onclose = this._onCloseEvent;
      this.ws.onmessage = this._onMessage;

      this.abortOpenTimeout = setTimeout(this._abortOpen, OPEN_ABORT_TIMEOUT);
    }
  }


  _getWsUrl() {
    let path = '/_ws/';
    if(location.host === ANGULAR_SCOPE.teamleaderCloudSiteDomain) {
      path = '/_customer-meeting' + path;
    }
    let searchParams = this._getWsSearchParms();
    let url = `wss://${location.host}${path}?${searchParams.toString()}`;
    return url;
  }


  _abortOpen() {
    logger.info('Websocket connect timed out');

    if(this.ws) {
      this.ws.onopen = null;
      this.ws.onclose = null;
      this.ws.onmessage = null;
      this.ws = null;
    }

    this._openWithBackoff();
  }

  close(code, reason) {
    if(reason == null) {
      reason = code;
      code = 1000;
    }

    logger.info('Closing websocket with reason "%s"', reason);
    clearTimeout(this.openTimeout);

    if(this.ws) {
      this.ws.onopen = null;
      this.ws.onclose = null;
      this.ws.onmessage = null;

      if(this.state === ReadyState.OPEN) {
        this.ws.close(code, reason);
      } else {
        logger.info('Failed to close websocket: state is', this.ws.readyState);
      }

      this.ws = null;
      this._onClose(reason);
    }
    this.emit('localClose', reason, false);
  }


  _onOpen() {
    logger.info('Websocket connected');
    clearTimeout(this.abortOpenTimeout);
    clearTimeout(this.resetOpenBackOffTimeout);
    this.resetOpenBackOffTimeout = setTimeout(this._resetOpenBackOff, 5000);

    this._emitWrapped('open');

    this.closeEmitted = false;
  }

  _onCloseEvent(error) {
    let level = error.reason === CloseReason.INACTIVE_SESSION ? 'warn' : 'info';
    logger[level]('Websocket closed:', error.code, error.reason);

    let closeReason = error.reason;
    this._onClose(closeReason);
  }


  _onClose(closeReason) {
    clearTimeout(this.abortOpenTimeout);
    clearTimeout(this.resetOpenBackOffTimeout);
    clearTimeout(this.pingTimeout);

    let reopen = false;
    let timeout = 0;

    switch(closeReason) {
      case CloseReason.INVALID_SESSION:
      case CloseReason.NOT_AUTHORIZED:
      case CloseReason.MEETING_RESET:
      case CloseReason.OLD_SESSION:
      case CloseReason.BAD_REQUEST:
      case CloseReason.ACCESS_DENIED:
      case CloseReason.FORCED_ENDED:
      case CloseReason.EXITED:
      case CloseReason.CLIENT_LEAVING:
        // Do nothing, other services will prompt something to the user
        break;

      case CloseReason.STALE_SESSION:
        // Provide some extra time, so we are sure local cookies are set after login
        timeout = 3000;
        // falls through

      default:
        reopen = true;
        break;
    }

    if(reopen) {
      $timeout(this._openWithBackoff, timeout);
    }

    this._emitClose(closeReason, reopen);
  }


  // TODO: It's not really ideal to have to send the reopen parameter in the emit but we need it on
  // the dashboard to check if we need to show an error message if the ws closed without intention
  // to reopen.
  _emitClose(closeReason, reopen) {
    if(reopen == null) {
      reopen = false;
    }
    if(!this.closeEmitted) {
      this._emitWrapped('close', closeReason, reopen);
      this.closeEmitted = true;
    }
  }


  _onMessage(event) {
    let messageString = event.data;

    if(messageString === PING_MESSAGE) {
      this._onPing();

    } else {
      let message = JSON.parse(messageString);
      this._emitWrapped('message', message);
    }
  }


  _onPing() {
    clearTimeout(this.pingTimeout);
    this.pingTimeout = setTimeout(this._onPingTimeout, PING_TIMEOUT);

    this.send(PONG_MESSAGE);
  }


  _onPingTimeout() {
    // Speed up reconnection by closing the websocket manually
    this.close(CloseReason.PING_TIMEOUT);
  }


  send(message) {
    if(this.state === ReadyState.OPEN) {
      let messageString = typeof message === 'string' ? message : JSON.stringify(message);

      try {
        this.ws.send(messageString);

      } catch(error) {
        logger.info('Failed to send data:', error);
        this.close(CloseReason.SEND_FAILURE);
      }
    } else {
      console.info(`SocketService: not sending message because state = ${this.state}.`);
    }
  }
}
