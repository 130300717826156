import SearchUserComponent from './searchUser.component';

/**
 * Usage:
 * <search-user
 *   on-result="ctrl.setResult(user)"
 *   validate="ctrl.validate(user)"
 * ></search-user>
 *
 * Parameters:
 *   - onResult: function
 *     Will be called each time a user is chosen.
 *   - validate: function (optional)
 *     Will be called for each search result. It should return a tuple
 *     [selectable: boolean, extraInfo: string]. If a falsey value is returned, the default is
 *     [true, ''].
 *
 * The component listens for the following events:
 *   - searchUserFocus: focus the query element if it is visible
 *   - searchUserClear: clear the search result
 */

angular
  .module('vecteraUtil')
  .component('searchUser', SearchUserComponent);
