import { object, EventEmitter } from 'utils/util';

import Tile from './Tile';


const REDRAW_THROTTLE = 200;


/**
 * Stores all tiles in the meeting room and syncs which one is active between the participants in
 * the meeting.
 */
export default class TileService {
  static get $inject() {
    return [
      'meetingBroadcastService',
    ];
  }

  constructor(
    meetingBroadcastService
  ) {
    this._bind();
    EventEmitter.setup(this, ['add', 'remove', 'draw']);

    this.meetingBroadcastService = meetingBroadcastService;

    this.tiles = {};

    this.activeContentTile = null;
    this.activeContentTileSynced = null;

    this.meetingBroadcastService.on('content-item-active', this._onBroadcastActive, true);
    this.meetingBroadcastService.afterInitialization().then(this._drawThrottled);
  }

  _bind() {
    this._onContentTileActive = this._onContentTileActive.bind(this);
    this._broadcastActive = this._broadcastActive.bind(this);
    this._onBroadcastActive = this._onBroadcastActive.bind(this);
    this._draw = this._draw.bind(this);

    this._broadcastActiveDebounced = debounce(this._broadcastActive);
    this._drawThrottled = throttle(this._draw, REDRAW_THROTTLE, true);
  }

  get activeTiles() {
    if(this.activeContentTile) {
      return [this.activeContentTile];
    } else {
      return Object.values(this.peopleTiles).filter(tile => tile.active);
    }
  }

  get peopleTiles() {
    return this._getTilesOfCategory(Tile.Category.PEOPLE);
  }
  get contentTiles() {
    return this._getTilesOfCategory(Tile.Category.CONTENT);
  }
  _getTilesOfCategory(category) {
    return object.filter(this.tiles, (id, tile) => tile.category === category);
  }


  add(tile) {
    if(tile.id in this.tiles) {
      return;
    }

    this.tiles[tile.id] = tile;
    tile.on('change active', this._drawThrottled);
    if(tile.category === Tile.Category.CONTENT) {
      tile.setActive(!(tile.whiteboard && tile.whiteboard.isSnapshot));
      tile.on('active', this._onContentTileActive);

      this.activeContentTileSynced = tile;
      this._onContentTileActive(tile);
    }

    this.emit('add', tile);
    this._drawThrottled();
  }


  remove(tile) {
    if(!(tile.id in this.tiles)) {
      return;
    }

    delete this.tiles[tile.id];
    tile.destroy();
    tile.off('change active', this._drawThrottled);
    tile.off('active', this._onContentTileActive);

    if(tile === this.activeContentTile) {
      this.activeContentTile = null;
    }
    if(tile === this.activeContentTileSynced) {
      this.activeContentTileSynced = null;
    }

    this.emit('remove', tile);
    this._drawThrottled();
  }


  /*************************
   * Manage active tile(s) *
   *************************/

  _onContentTileActive(tile) {
    if(tile.active) {
      if(this.activeContentTile) {
        this.activeContentTile.setActive(false);
      }

      this.activeContentTile = tile;

    } else if(tile === this.activeContentTile) {
      this.activeContentTile = null;
    }

    this._broadcastActiveDebounced();
  }


  _broadcastActive() {
    // != is intentional: this way we treat null and undefined the same.
    if(this.activeContentTile != this.activeContentTileSynced) {  // eslint-disable-line eqeqeq
      let tileId = this.activeContentTile && this.activeContentTile.id;
      this.meetingBroadcastService.send('content-item-active', false, [], tileId);
    }
  }


  _onBroadcastActive(channel, session, datetime, id) {
    let tile = this.tiles[id] || null;
    this.activeContentTileSynced = tile;

    if(tile) {
      tile.setActive(true, datetime);

    } else if(this.activeContentTile) {
      this.activeContentTile.setActive(false);
    }
  }


  _draw() {
    this._drawThrottled.cancel();
    this.emit('draw');
  }
}
