import format from './format';
import * as color from './color';

const LIGHTNESS = 0.63;
const SATURATION = 0.61;
const HUE_START = 204;
const HUE_STEP = 134;

const LUMINANCE = getHslLuminance({ h: 60, s: SATURATION, l: LIGHTNESS }); // Yellow
const DEFAULT_COLOR = getHslColor(0, 0);


/**************************************************
 * Remember used colors so no color is used twice *
 **************************************************/

export default class ColorManager {
  constructor() {
    this.claimedColors = [];
  }


  claimColorIndex() {
    let colorIndex;
    for(colorIndex = 0; colorIndex < this.claimedColors.length; colorIndex++) {
      if(!this.claimedColors[colorIndex]) {
        break;
      }
    }

    this.claimedColors[colorIndex] = true;
    return colorIndex;
  }

  forsakeColorIndex(colorIndex) {
    this.claimedColors[colorIndex] = false;
  }


  getDefaultColor() {
    return DEFAULT_COLOR;
  }

  getColor(index) {
    return getColor(index);
  }
}


/********************
 * Calculate colors *
 ********************/


let colorCache = {};


function getColor(index) {
  if(index == null) {
    return DEFAULT_COLOR;
  }

  if(!colorCache.hasOwnProperty(index)) {
    colorCache[index] = getHslColor(getHue(index));
  }
  return colorCache[index];
}



function getHslColor(hue, saturation) {
  if(saturation == null) {
    saturation = SATURATION;
  }

  let minLightness = 0, maxLightness = 1, lightness;

  while(maxLightness - minLightness > 0.01) {
    lightness = (maxLightness + minLightness) / 2;
    let luminance = getHslLuminance({ h: hue, s: saturation, l: lightness });
    if(luminance > LUMINANCE) {
      maxLightness = lightness;
    } else {
      minLightness = lightness;
    }
  }

  lightness = (LIGHTNESS + lightness) / 2;
  return format('hsl(%s, %s%%, %s%%)', hue, saturation * 100, lightness * 100);
}


function getHslLuminance(hsl) {
  return getRgbLuminance(color.hslToRgb(hsl));
}

function getRgbLuminance(rgb) {
  return (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
}

function getHue(index) {
  return HUE_START + HUE_STEP * index;
}
