import { logger, errors, format, EventEmitter } from 'utils/util';


export default class WaitingRoomBroadcastService {
  static get $inject() {
    return [
      'waitingRoomReliableSocketService',
    ];
  }

  constructor(
    waitingRoomReliableSocketService
  ) {
    this._bind();

    this.waitingRoomReliableSocketService = waitingRoomReliableSocketService;

    this.eventEmitter = new EventEmitter([], true);
    this.emit = this.eventEmitter.emit;

    this.registeredChannels = new Set();

    this.receivedIds = new Set();
    this.receiveQueue = [];


    this._registerChannel('session-join');
  }

  _bind() {
    this._onMessage = this._onMessage.bind(this);
  }


  _getEmitterChannel(channel) {
    return format('%s:%s', channel);
  }


  /********************
   * Receive messages *
   ********************/

  _registerChannel(channel) {
    if(!this.registeredChannels.has(channel)) {
      this.registeredChannels.add(channel);
      this.waitingRoomReliableSocketService.on(channel, this._onMessage);
    }
  }


  on(channel, callback) {
    if(typeof channel !== 'string') {
      throw new errors.InvalidArgumentError(
        `Argument 'channel' must be a string, got '${ channel }'`
      );
    }
    if(channel.indexOf(':') !== -1) {
      throw new errors.InvalidArgumentError(
        `Argument 'channel' cannot contain semicolons, got '${ channel }'`
      );
    }
    if(typeof callback !== 'function') {
      throw new errors.InvalidArgumentError(
        `Argument 'callback' must be a function, got '${ callback }'`
      );
    }

    this._registerChannel(channel);

    this.eventEmitter.on(this._getEmitterChannel(channel), callback);
  }


  _onMessage(channel, data) {
    if(channel[0] === '_') {
      this._onMetaMessage(channel, ...data);
    } else {
      let item = {
        id: data[0],
        channel: channel,
        senderId: data[1],
        datetime: new Date(data[2]),
        message: data[3],
      };

      this.receiveQueue.push(item);
      this._processReceiveQueue();
    }
  }


  _onMetaMessage(channel, ...data) {
    let emitterChannel = this._getEmitterChannel(channel);
    this.emit(emitterChannel, ...data);
  }


  _processReceiveQueue() {
    while(this.receiveQueue.length) {
      let item = this.receiveQueue.shift();
      let id = item.id;

      if(id === null || !this.receivedIds.has(id)) {
        if(id !== null) {
          this.receivedIds.add(id);
        }

        let channel = item.channel;
        let senderId = item.senderId;
        let message = item.message;

        logger.debug('Got BM %s on channel "%s" from %s:',
          id, channel, senderId, message);

        let args = [channel, senderId, item.datetime].concat(message);

        let emitterChannel = this._getEmitterChannel(channel);
        this.emit(emitterChannel, ...args);
      }
    }
  }
}
