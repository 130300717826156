import { array, bind, EventEmitter, format, Rect, storage } from 'utils/util';

import Tile from '../Tile';
import UserTileLayout from './UserTileLayout';
import { CONTENT_HEADER_HEIGHT_INACTIVE } from 'meeting/angularjs/variables';


const MARGIN = 6;
const MARGIN_TILES = 6;
const MARGIN_INACTIVE_TILES = 6;
const MIN_INACTIVE_CONTENT_TILE_HEIGHT = 64;
const MIN_ASPECT_RATIO_INACTIVE = 2 / 3;
const INACTIVE_USER_TILE_ASPECT_RATIO = 16 / 9;

const KEY_LOCAL_TILE_ACTIVE = 'localTileActive';



export default class TileRendererDesktop {
  static get $inject() {
    return [
      'browserService',
      'languageService',
      'recorderService',
      'tileService',
      'userService',
    ];
  }

  constructor(
    browserService,
    languageService,
    recorderService,
    tileService,
    userService
  ) {
    bind(this);
    EventEmitter.setup(this, ['draw']);

    this.browserService = browserService;
    this.languageService = languageService;
    this.recorderService = recorderService;
    this.tileService = tileService;
    this.userService = userService;

    /**
     * the main element is the area where active tiles can be placed
     * @type {jQuery}
     * @public
     */
    this.$elem = null;
    /**
     * the sidebar element is the area where inactive tiles can be placed
     * @type {jQuery}
     * @public
     */
    this.$elemSidebar = null;

    this.rectMain = null;
    this.rectSidebar = null;

    this.sidebarTilesHeight = 0;
    this.sidebarOverflow = false;
    this.sidebarScroll = 0;
    this.sidebarSeparatorStyle = null;

    this.localTileActive = storage.getBooleanItem(KEY_LOCAL_TILE_ACTIVE, false);
    this.localTileIgnoreActive = false;
    this.tileService.on('add', this._onAdd);
    this.tileService.on('remove', this._onRemove);

    this.browserService.on('deviceType', this._onDeviceType);
    this.tileService.on('draw', this.draw);
    // Timeout is necessary to get the correct size of the minimized tiles
    this.userService.mySession.on('isSpectator', () => $timeout(this.draw));
    // When we are recording, pinned user tiles are also shown inside Vectera
    this.recorderService.on('state', this.draw);
    // When the language changes, the width of the sidebar may have changed
    this.languageService.on('language', this.draw);

    // Ideally, we wouldn't need to do this because the ResizeObserver in TilesComponent would
    // catch all size changes. But Safari < 13.1 and Firefox < 69 don't support that, so we have
    // this as a fallback for now.
    window.addEventListener('resize', () => {
      this._onWindowResize();
      // Mobile Safari sometimes reports the old window size for a while. This is reproducible
      // (unreliably) by connecting an external keyboard, and (un)focusing an input element.
      setTimeout(() => this._onWindowResize(), 1000);
    });
  }


  /**
   * Get the width at which inactive tile should be rendered.
   */
  get inactiveTileWidth() {
    return this.$elemSidebar[0].clientWidth;
  }

  /**
   * Get the width of an inactive tile *assuming there is currently no scrollbar present*.
   *
   * This is dirty, but we need it to avoid layout thrashing as a result of adding/removing the
   * scrollbar. Consider for example the following situation:
   *  - Initially there are a lot of whiteboards in the sidebar, but not enough to require
   *    scrolling.
   *  - A video tile is added. Because of this, the sidebar overflows a few pixels (for example
   *    4px), so we add a scrollbar.
   *  - Because the scrollbar is added, the video tile becomes around 10px less wide, and 6px less
   *    high.
   *  - Since the sidebar was only overflowing 4px, and it has now become 6px less high, it is no
   *    longer overflowing. So we remove the scrollbar again. We are now back in step 2. Repeat ad
   *    infinitum.
   *
   * Thanks to using expandedInactiveTileWidth for calculating the tile height, step 3 no longer
   * results in the video tile being drawn less high, and layout thrashing is avoided.
   */
  get expandedInactiveTileWidth() {
    let $elemScrollable = this.$elemSidebar.parent();
    return (
      this.inactiveTileWidth
      + ($elemScrollable[0].offsetWidth - $elemScrollable[0].clientWidth)
    );
  }


  setElem($elem) {
    this.$elem = $elem;
    this.draw();
  }

  setElemSidebar($elem) {
    this.$elemSidebar = $elem;
    this._onWindowResize();
  }


  setSidebarScroll(scroll) {
    if(scroll !== this.sidebarScroll) {
      this.sidebarScroll = scroll;
      Object.values(this.tileService.tiles).forEach(tile => tile.setScrollInactive(scroll));
    }
  }


  _onAdd(tile) {
    tile.setScrollInactive(this.sidebarScroll);
    if(tile.type === Tile.Type.USER && tile.user.isMe) {
      tile.on('active', this._onLocalTileActive);
    }
  }

  _onRemove(tile) {
    tile.off('active', this._onLocalTileActive);
  }

  _onLocalTileActive(tile) {
    if(!this.localTileIgnoreActive) {
      this.localTileActive = tile.active;
      storage.setBooleanItem(KEY_LOCAL_TILE_ACTIVE, tile.active);
    }
  }


  _onWindowResize() {
    // requestAnimationFrame is necessary on mobile safari: otherwise the old window size is
    // reported
    requestAnimationFrame(() => $timeout(this.draw));
  }


  _onDeviceType(deviceType) {
    if(deviceType === this.browserService.DeviceType.DESKTOP) {
      this.draw();
    } else {
      this.setSidebarScroll(0);
    }
  }


  draw() {
    if(
      !this.$elem
      || !this.$elemSidebar
      || this.browserService.deviceType !== this.browserService.DeviceType.DESKTOP
    ) {
      return;
    }

    this.rectMain = Rect.fromElem(this.$elem[0]);
    this.$elemSidebar.css('flexShrink', 1);
    this.rectSidebar = Rect.fromElem(this.$elemSidebar[0]);
    this.$elemSidebar.css('flexShrink', '');

    if(this.rectMain.width <= 0
        || this.rectMain.height <= 0
        || this.rectSidebar.width <= 0
        || this.rectSidebar.height <= 0) {
      return;
    }

    this._clipElem();
    this._updateActiveUserTiles();
    this._drawActiveTiles();
    this._drawInactiveTiles();

    this.emit('draw');
  }


  _clipElem() {
    // We clip this kind of shape so that scrolled tiles don't end up behind the content buttons:
    //
    // ██████████████
    // ██████████████
    // ██████████████
    // ████████████████████
    // ████████████████████
    // ████████████████████
    // ████████████████████
    // The "+ 6" and "- 3" are there to make sure the shadows are not clipped.

    let insetLeft = this.rectMain.width + 3 + 'px';
    let insetBottom = this.rectSidebar.top - this.rectMain.top - 3 + 'px';
    let right = this.rectSidebar.right - this.rectMain.left + 6 + 'px';
    let bottom = this.rectSidebar.bottom - this.rectMain.top + 6 + 'px';

    this.$elem.css(
      'clipPath',
      format(
        'polygon(0 0, %s 0, %s %s, %s %s, %s %s, 0 %s)',
        insetLeft,
        insetLeft, insetBottom,
        right, insetBottom,
        right, bottom,
        bottom
      )
    );
  }


  _updateActiveUserTiles() {
    this.localTileIgnoreActive = true;
    Object.values(this.tileService.tiles)
      .filter(tile => tile.type === Tile.Type.USER)
      .forEach(tile => {
        tile.isEmbeddedInFloatingTile = false;
        if(!tile.videoVisible || this.tileService.activeContentTile) {
          tile.setActive(false);
        } else if(tile.videoVisible && (!tile.user.isMe || this.localTileActive)) {
          tile.setActive(true);
        }
      });
    this.localTileIgnoreActive = false;
  }


  _drawActiveTiles() {
    if(this.tileService.activeContentTile) {
      if(this.tileService.activeContentTile.type === Tile.Type.WHITEBOARD) {
        this._drawActiveWhiteboard(this.tileService.activeContentTile);
      } else {
        this._drawActiveScreenTile(this.tileService.activeContentTile);
      }
    } else {
      this._drawActiveUserTiles(this.tileService.activeTiles);
    }
  }


  _drawActiveWhiteboard(tile) {
    let rect = new Rect({
      left: MARGIN,
      top:  MARGIN,
      width:  this.rectMain.width - (MARGIN * 2),
      height: this.rectMain.height - (MARGIN * 2),
    });
    tile.draw(rect);
  }

  _drawActiveScreenTile(tile) {
    let availWidth = this.rectMain.width - MARGIN * 2;
    let availHeight = this.rectMain.height - MARGIN * 2;

    let width = Math.min(
      availWidth,
      (availHeight - tile.headerHeight) * tile.aspectRatio + tile.sidebarWidth);
    let height = (width - tile.sidebarWidth) / tile.aspectRatio + tile.headerHeight;
    let left = MARGIN + Math.floor((availWidth  - width ) / 2);
    let top  = MARGIN + Math.floor((availHeight - height) / 2);

    let rect = new Rect({
      left: left,
      top: top,
      width: width,
      height: height,
    });
    tile.draw(rect);
  }


  _drawActiveUserTiles(argTiles) {
    if(argTiles.length === 0) {
      return;
    }

    let tiles = argTiles.sort(this._sortActivePeopleTiles);
    tiles.forEach(tile => tile.isCollapsed = false);

    let rect = new Rect({
      left: MARGIN,
      top:  MARGIN,
      width:  this.rectMain.width - MARGIN,
      height: this.rectMain.height - MARGIN,
    });

    let layout = new UserTileLayout(tiles, rect, MARGIN_TILES);
    layout.draw();
  }


  _drawInactiveTiles() {
    let rect = new Rect({
      left: this.rectSidebar.left - this.rectMain.left,
      top:  this.rectSidebar.top  - this.rectMain.top,
      width: this.inactiveTileWidth,
      bottom: this.rectSidebar.bottom - this.rectMain.top,
    });

    let peopleTiles = this._getInactivePeopleTiles();
    this._updatePeopleTilesCollapsed(peopleTiles);
    let peopleHeight = 0;
    if(peopleTiles.length > 0) {
      let peopleHeights = peopleTiles.map(this._getInactivePeopleTileHeight);
      peopleHeight = array.sum(peopleHeights) + (peopleTiles.length - 1) * MARGIN_INACTIVE_TILES;
    }

    let contentTiles = this._getInactiveContentTiles();
    if(contentTiles.length > 0) {
      let maxHeight = rect.height;
      if(peopleTiles.length > 0) {
        maxHeight -= peopleHeight + MARGIN_INACTIVE_TILES;
      }
      rect = this._drawInactiveContentTiles(contentTiles, rect, maxHeight);
    }

    if(contentTiles.length > 0 && peopleTiles.length > 0) {
      rect.top += MARGIN_INACTIVE_TILES;
    }

    if(peopleTiles.length > 0) {
      if(peopleHeight < rect.height) {
        rect.top += rect.height - peopleHeight;
      }
      rect = this._drawInactivePeopleTiles(peopleTiles, rect);
    }

    this.sidebarTilesHeight = this.rectSidebar.height;
    let sidebarOverflow = rect.height < 0;
    if(sidebarOverflow) {
      this.sidebarTilesHeight -= rect.height;
    }

    // If the sidebar overflow changes, the width of the sidebar changes as well due to the
    // scrollbar being added/removed, so we need to redraw
    if(sidebarOverflow !== this.sidebarOverflow) {
      this.sidebarOverflow = sidebarOverflow;
      $timeout(this.draw);
    }

    this.emit('draw');
  }


  _getInactivePeopleTiles() {
    return Object.values(this.tileService.peopleTiles)
      .filter(tile => !tile.active)
      .sort(this._sortInactivePeopleTiles);

  }
  _getInactiveContentTiles() {
    if(this.userService.mySession.isSpectator) {
      return [];
    } else {
      // We don't filter on inactive tiles only, since we show active tiles in the sidebar also
      return Object.values(this.tileService.contentTiles);
    }
  }


  _updatePeopleTilesCollapsed(tiles) {
    tiles.forEach(tile => {
      tile.isCollapsed = (
        tile.type !== Tile.Type.KNOCKER
        && !tile.videoVisible
      );
    });
  }


  _getInactivePeopleTileHeight(tile) {
    return tile.isCollapsed ?
      CONTENT_HEADER_HEIGHT_INACTIVE :
      Math.floor(this.expandedInactiveTileWidth / INACTIVE_USER_TILE_ASPECT_RATIO);
  }


  _drawInactivePeopleTiles(tiles, argRect) {
    let rect = argRect.clone();

    for(let i = 0; i < tiles.length; i++) {
      let tile = tiles[i];
      let height = this._getInactivePeopleTileHeight(tile);
      let tileRect = new Rect({
        right: rect.right,
        width: rect.width,
        top: rect.top,
        height: height,
      });
      tile.draw(tileRect);
      rect.top += height + MARGIN_INACTIVE_TILES;
    }

    if(tiles.length > 0) {
      rect.top -= MARGIN_INACTIVE_TILES;
    }

    return rect;
  }


  _drawInactiveContentTiles(tiles, argRect, availHeight) {
    let rect = argRect.clone();

    let tileHeights = {};
    let totalHeight = -MARGIN_INACTIVE_TILES;
    tiles.forEach(tile => {
      let aspectRatio = Math.max(tile.aspectRatioInactive, MIN_ASPECT_RATIO_INACTIVE);
      let height = tile.previewAvailable ?
        Math.floor(tile.headerHeightInactive + this.expandedInactiveTileWidth / aspectRatio) :
        tile.headerHeightInactive;
      tileHeights[tile.id] = height;
      totalHeight += height + MARGIN_INACTIVE_TILES;
    });

    let sortedHeights = Object.values(tileHeights).sort((h1, h2) => h2 - h1);
    let maxTileHeightInitial = sortedHeights[0] || 0;
    let maxTileHeight = maxTileHeightInitial;

    for(let i = 0; i < tiles.length; i++) {
      if(totalHeight <= availHeight) {
        break;
      }

      let reduce = Math.min(
        Math.ceil((totalHeight - availHeight) / (i + 1)),
        i === tiles.length - 1 ?
          Infinity :
          sortedHeights[i] - sortedHeights[i + 1]);
      maxTileHeight -= reduce;
      totalHeight -= reduce * (i + 1);
    }

    let collapse = (
      maxTileHeight < Math.min(maxTileHeightInitial, MIN_INACTIVE_CONTENT_TILE_HEIGHT));

    tiles.forEach(tile => {
      tile.isCollapsed = collapse || !tile.previewAvailable;
      let height = tile.isCollapsed ?
        tile.headerHeightInactive :
        Math.min(maxTileHeight, tileHeights[tile.id]);
      let tileRect = new Rect({
        right: rect.right,
        width: rect.width,
        top: rect.top,
        height: height,
      });
      tile.drawInactive(tileRect);

      rect.top += tileRect.height + MARGIN_INACTIVE_TILES;
    });

    if(tiles.length > 0) {
      rect.top -= MARGIN_INACTIVE_TILES;
    }

    return rect;
  }


  _sortInactivePeopleTiles(t1, t2) {
    if(t1.type === Tile.Type.KNOCKER) {
      return -1;
    } else if(t2.type === Tile.Type.KNOCKER) {
      return 1;
    } else if(t1.user.isMe) {
      return 1;
    } else if(t2.user.isMe) {
      return -1;
    } else {
      return t2.id - t1.id;
    }
  }


  _sortActivePeopleTiles(t1, t2) {
    if(t1.user.isMe) {
      return 1;
    } else if(t2.user.isMe) {
      return -1;
    } else {
      return t1.aspectRatio - t2.aspectRatio;
    }
  }
}
