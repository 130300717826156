import { format, browser } from 'utils/util';

const ModalMessage = Object.freeze({
  HOST_AND_SIGNUP: 'host and signup',
  HOST: 'host',
  UPGRADE: 'upgrade',
  NOT_SUPPORTED: 'not supported',
  INSTALL_EXTENSION: 'extension',
  START_RECORDING: 'start recording',
});


export default class RecordingController {
  static get $inject() {
    return [
      'meetingService',
      'chromeExtensionService',
      'userService',
      'recordingService',
      'recorderService',
      'notificationService',
      'modalService',
      'dropdownService',
      'siteService',
    ];
  }

  constructor(
    meetingService,
    chromeExtensionService,
    userService,
    recordingService,
    recorderService,
    notificationService,
    modalService,
    dropdownService,
    siteService
  ) {
    this._bind();

    this.meetingService = meetingService;
    this.chromeExtensionService = chromeExtensionService;
    this.userService = userService;
    this.recordingService = recordingService;
    this.recorderService = recorderService;
    this.notificationService = notificationService;
    this.modalService = modalService;
    this.dropdownService = dropdownService;
    this.siteService = siteService;

    this.modalMessage = null;
    this.confirmDeleteRecording = null;
  }

  _bind() {
    this._onModalShow = this._onModalShow.bind(this);
    this._updateModalMessage = this._updateModalMessage.bind(this);
  }


  $onInit() {
    this.chromeExtensionService.on('isInstalled', this._updateModalMessage);
    this.modalService.on('show', this._onModalShow);
    this._onModalShow();
  }

  $onDestroy() {
    this.chromeExtensionService.off('isInstalled', this._updateModalMessage);
    this.modalService.off('show', this._onModalShow);
  }


  get ModalMessage() {
    return ModalMessage;
  }

  get me() {
    return this.userService.me;
  }
  get storageUsed() {
    return this.me.organization.storageUsed;
  }
  get storageTotal() {
    return this.me.organization.storageTotal;
  }
  get storageRatio() {
    return this.storageUsed / this.storageTotal;
  }

  get countdown() {
    if(!this.recorderService.countingDown) {
      return 0;
    }

    return Math.round((this.recorderService.startTimestamp - Date.now()) / 1000);
  }

  get duration() {
    if(!this.recorderService.recorder) {
      return 0;
    }

    let duration = Math.floor(this.recorderService.recorder.duration / 1000);
    return format(
      '%s:%s:%s',
      (Math.floor(duration / 3600) + '').padStart(2, '0'),
      (Math.floor(duration / 60) % 60 + '').padStart(2, '0'),
      (duration % 60 + '').padStart(2, '0')
    );
  }

  get allowCloudRecordings() {
    return this.recordingService.meetingService.settings.allowCloudRecordings;
  }


  supportsPreview() {
    return browser.supportsWebm();
  }
  get previewTooltip() {
    return this.supportsPreview() ?
      gettextCatalog.getString('Preview') :
      browser.errorMessages.webm();
  }
  showPreviewError() {
    this.notificationService.warning(browser.errorMessages.webm());
  }


  showModal() {
    this.recordingService.showModal();
  }

  _onModalShow() {
    if(this.modalService.get(this.recordingService.MODAL_ID).isShown) {
      this._updateModalMessage();
    }
  }


  _updateModalMessage() {
    let session = this.userService.mySession;
    if(!session.accessLevel.isHost && this.meetingService.settings.whitelabel.hasAddon) {
      this.modalMessage = ModalMessage.HOST;

    } else if(!session.accessLevel.isHost) {
      this.modalMessage = ModalMessage.HOST_AND_SIGNUP;

    } else if(!this.meetingService.settings.allowRecording) {
      this.modalMessage = ModalMessage.UPGRADE;

    } else if(!browser.supportsRecording()) {
      this.modalMessage = ModalMessage.NOT_SUPPORTED;

    } else if(!this.chromeExtensionService.isInstalled) {
      this.modalMessage = ModalMessage.INSTALL_EXTENSION;

    } else {
      this.modalMessage = ModalMessage.START_RECORDING;
    }
  }


  delete(recording) {
    recording.delete();
  }

  get upgradeLink() {
    return gettextCatalog.getString(
      '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to create recordings.',
      {
        url: format('href="%s"', this.siteService.site.subscriptionLink),
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

  get signupLink() {
    return gettextCatalog.getString(
      // eslint-disable-next-line max-len
      'Only the meeting host can record a meeting. <a {{ url }}>Sign up for an account</a> to create your own meeting rooms and recordings.',
      { url: format('href="%s" target="_blank"', window.URLS.signup) }
    );
  }

  get extensionLink() {
    return gettextCatalog.getString(
      'Install <a {{ url }}>the browser extension</a> to start recording',
      {
        url: format(
          'href="%s" target="_blank"',
          this.chromeExtensionService.extensionUrl
        )
      }
    );
  }
}
