import { bind, logger } from 'utils/util';

import { AfterViewInit, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { Appointment } from 'scheduling/models/Appointment';
import { Meeting } from 'meeting/models/Meeting';
import { Input, Output, EventEmitter } from '@angular/core';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { DashboardScope } from 'dashboard/data-page/data-page.component';
import { Subscription } from 'rxjs';
import {
  MeetingRoomsMenuComponent
} from 'dashboard/home/meeting-rooms-menu/meeting-rooms-menu.component';
import { UrlService } from 'utils/url.service';
import { getAppointmentLocationBadge } from 'scheduling/utils/location-badges';
import { AppointmentLocation } from 'scheduling/models/appointment-location';
import { AppointmentUser } from 'scheduling/models/AppointmentUser';

@Component({
  selector: 'appointments-table',
  templateUrl: './appointments-table.component.html',
})
export class AppointmentsTableComponent implements AfterViewInit {
  @Input() appointments: Appointment[] = [];
  @Input() public scope: Scope = DashboardScope.PERSONAL;

  @Output() appointmentChange = new EventEmitter<void>();

  @ViewChildren('meetingRoomsMenu') meetingRoomsMenu!: QueryList<MeetingRoomsMenuComponent>;

  private meetingRoomsMenuSubscriptions: Subscription[] = [];
  public DashboardScope = DashboardScope;

  constructor(
    public urlService: UrlService,

    @Inject('notificationService') private notificationService,
  ) {
    bind(this);
  }

  ngAfterViewInit() {
    this.meetingRoomsMenu.changes.subscribe(this.onMeetingRoomsMenuChanged);
  }

  get AppointmentLocation() {
    return AppointmentLocation;
  }

  cancel(appointment: Appointment, sendUpdates: boolean) {
    appointment.cancel(sendUpdates, false)
      .catch(error => {
        this.notificationService.warning(
          $localize `Something went wrong while canceling your appointment.`
        );
        logger.error(error);
      })
      .finally(() => {
        // soft-remove the appointment from the table
        this.appointments = this.appointments.filter((apt) => apt.id !== appointment.id);
      });
  }

  onMeetingRoomsMenuChanged(next: QueryList<MeetingRoomsMenuComponent>) {
    this.meetingRoomsMenuSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.meetingRoomsMenuSubscriptions = [];

    next.forEach((meetingRoomsMenu: MeetingRoomsMenuComponent) => {
      if(meetingRoomsMenu.data.appointment) {
        meetingRoomsMenu.meetingRoomSelected.subscribe((meeting: Meeting) => {
          this.changeAppointmentMeeting(meeting, meetingRoomsMenu.data.appointment);
        });
      }
    });
  }

  changeAppointmentMeeting(meeting: Meeting, appointment: Appointment) {
    appointment.meetingId = meeting.id;
    appointment.save()
      .catch(error => this.notificationService.handleError(
        error,
        $localize `Something went wrong while updating your meeting.`
      ));
  }

  trackByAppointment(index, appointment: Appointment) {
    return appointment.guests[0].id + appointment.start;
  }

  trackByGuest(index, guest: AppointmentUser) {
    return guest.id;
  }

  getLocationBadge(appointment) {
    return getAppointmentLocationBadge(appointment);
  }

}
