import { format, getTimestring } from 'utils/util';



export default class Recording {

  /* eslint-disable max-len */
  static get ERROR_MESSAGE_DOWNLOAD() {
    return gettext('Something went wrong with your download. Please restart your browser or try again later.');
  }
  static get ERROR_MESSAGE_UPLOAD() {
    return gettext('Something went wrong with your upload. Please restart your browser or try again later.');
  }
  static get ERROR_MESSAGE_DELETE() {
    return gettext('Something went wrong while deleting the recording. Please restart your browser or try again later.');
  }
  /* eslint-enable max-len */


  constructor(
    properties
  ) {
    this._bind();

    this.properties = {};
    this.update(properties);
  }

  _bind() {}


  update(properties) {
    Object.assign(this.properties, properties);
  }


  get id() {
    return this.properties.id;
  }
  get start() {
    return this.properties.start;
  }
  get size() {
    return this.properties.size;
  }
  get duration() {
    return this.properties.duration;
  }

  get meeting() {
    return this.properties.meeting;
  }

  get meetingKey() {
    if(!this.meeting) {
      return 'deleted meeting room';
    }
    return this.meeting.key;
  }

  get name() {
    // This should be synced with extension Recording.js. (Except the extension always assumes
    // whitelabel is disabled.)
    return format('%s recording in %s at %s.webm',
      'Meeting',
      this.meetingKey,
      getTimestring(this.start));
  }

  get canDelete() {
    return true;
  }
}
