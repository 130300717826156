import clearContentModal from './clearContent.modal.html?raw';
import Tile from '../tiles/Tile';
import { bind } from 'utils/util';

export const CLEAR_CONTENT_MODAL_ID = 'clear_content';

export default class ClearContentService {
  static get $inject() {
    return [
      'modalService',
      'resetService',
      'hintService',
      'userService',
      'meetingService',
      'tileService',
      'meetingBroadcastService'
    ];
  }

  constructor(
    modalService,
    resetService,
    hintService,
    userService,
    meetingService,
    tileService,
    meetingBroadcastService
  ) {
    bind(this);

    this.modalService = modalService;
    this.resetService = resetService;
    this.hintService = hintService;
    this.userService = userService;
    this.meetingService = meetingService;
    this.tileService = tileService;
    this.meetingBroadcastService = meetingBroadcastService;

    modalService.register(
      CLEAR_CONTENT_MODAL_ID,
      gettext('Clear meeting room content'),
      'utils/icons/tl/24x24_refresh_outline.svg',
      clearContentModal,
      {
        'cssClasses': 'modal-deprecated--sm'
      }
    );

    this.modalHint = this.hintService.get('clearMeetingRoomContentsModal');
    this.bannerHint = this.hintService.get('clearMeetingRoomContentsBanner');


    // Listeners to activate the banner
    this.meetingBroadcastService.on('notepad-create', this._setActive, true);
    this.meetingBroadcastService.on('notepad-name', this._setActive, true);
    this.meetingBroadcastService.on('notepad-page-add', this._setActive, true);
    this.meetingBroadcastService.on('paper-path-add', this._setActive, true);
    this.meetingBroadcastService.on('paper-path-remove', this._setActive, true);
    this.meetingBroadcastService.on('paper-path-edit', this._setActive, true);
    this.meetingBroadcastService.on('chat-message', this._setActive, true);

    this.hasRelevantActivity = false;
  }


  get modalEnabled() {
    return (
      !this.modalHint.isSeen
      && this._shouldOfferToClear
    );
  }

  get bannerEnabled() {
    return (
      !this.bannerHint.isSeen
      && this._shouldOfferToClear
      && !this.activeScreenOrCobrowseTile
    );
  }

  get _shouldOfferToClear() {
    return (
      this.userService.amOnlyHost
      && this.hasRelevantActivity
      && !this.meetingService.isTemplate
      && !this.meetingService.oneTimeMeetingDate
    );
  }

  get activeScreenOrCobrowseTile() {
    return Object.values(this.tileService.tiles).find(
      tile => (tile.type === Tile.Type.SCREEN || tile.type === Tile.Type.COBROWSE) && tile.active
    );
  }

  _setActive() {
    if(this.meetingBroadcastService.initializing) {
      return;
    }
    this.hasRelevantActivity = true;
  }


  /*********
   * MODAL *
   ********/

  showModal(beforeMeetingEnd, callback) {
    if(callback == null) {
      callback = angular.noop;
    }
    this.modalService.show(
      CLEAR_CONTENT_MODAL_ID,
      {
        'callback': callback,
        'beforeMeetingEnd': beforeMeetingEnd
      }
    );
  }

  hideModal() {
    this.modalService.hide(CLEAR_CONTENT_MODAL_ID);
  }


  /*********
   * HINTS *
   ********/

  setModalSeen() {
    this.modalHint.setSeen();
  }

  setBannerSeen() {
    this.bannerHint.setSeen();
  }


  /***********
   * ACTIONS *
   **********/

  clearContent() {
    return this.resetService.reset({ chat: true, whiteboards:true });
  }
}
