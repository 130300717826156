import template from './organizationNameSetup.html?raw';
import { string, bind } from 'utils/util';

const SET_ORGANIZATION_NAME_DEBOUNCE = 500;

export class OrganizationNameSetupController {
  static get $inject() {
    return [
      '$scope',
      'requestUserService',
      'apiService',
      'siteService',
    ];
  }

  constructor(
    $scope,
    requestUserService,
    apiService,
    siteService
  ) {
    bind(this);
    this._bindDebounced();

    this.requestUserService = requestUserService;
    this.apiService = apiService;
    this.siteService = siteService;

    $scope.$watch(() => this.name, this._onOrganizationName);

    this.errors = {};
  }

  _bindDebounced() {
    this.validateOrganizationSlugDebounced = debounce(
      this.validateOrganizationSlug,
      SET_ORGANIZATION_NAME_DEBOUNCE
    );
  }

  _onOrganizationName() {
    this.onName({ 'name': this.name });
    this.validateOrganizationSlugDebounced();
  }


  get slugifiedOrganizationName() {
    return string.slugify(this.name);
  }

  validateOrganizationSlug() {
    if(this.slugifiedOrganizationName === '') {
      this.unsetFieldError('name');
      this.validateOrganizationSetup();
      return;
    }

    let path = 'checkExistingOrganization/';
    let data = {
      'slug': this.slugifiedOrganizationName,
    };

    return this.apiService.post(path, data)
      .then(response => {
        if(
          response.data.exists
          && response.data.uuid !== this.requestUserService.user.organizationId
        ) {
          this.setFieldError('name', [
            gettextCatalog.getString('A team with this name already exists.')
          ]);
        } else {
          this.unsetFieldError('name');
        }
      })
      // It's not useful to catch API errors here, because these errors are either handled
      // by the backend or by the wizard submit step.
      .catch(angular.noop)
      .finally(() => {
        this.validateOrganizationSetup();
      });
  }


  setFieldError(field, errorMessage) {
    this.errors[field] = errorMessage;
  }
  unsetFieldError(field) {
    delete this.errors[field];
  }

  validateOrganizationSetup() {
    this.onValid({ isValid: this.isValid });
  }

  get isValid() {
    return (
      this.slugifiedOrganizationName.length > 0
      && Object.keys(this.errors).length === 0
    );
  }

  get previewUrl() {
    if(this.siteService.site.isTeamleader) {
      return `https://cloud.teamleader.eu/${this.slugifiedOrganizationName}/meeting-rooms/`;
    } else {
      return `https://${this.siteService.site.domain}/room/${this.slugifiedOrganizationName}`;
    }
  }
}


export default {
  controller: OrganizationNameSetupController,
  controllerAs: 'organizationNameSetupCtrl',
  template,

  bindings: {
    name: '<',
    onValid: '&',
    onName: '&',
  }
};
