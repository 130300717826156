import { EventEmitter } from 'utils/util';
import { browser } from 'utils/util';


export default class VideoCompatibleService {
  static get $inject() {
    return [
      'notificationService',
    ];
  }

  constructor(
    notificationService
  ) {
    EventEmitter.setup(this, [], true);

    this.notificationService = notificationService;

    this.compatible = {};
  }


  set(session, compatible) {
    if(compatible !== this.get(session)) {
      this.compatible[session.id] = compatible;
      this.emit(session.id, compatible);

      if(!compatible) {
        this._showError(session);
      }
    }
  }


  get(session) {
    return this.compatible.hasOwnProperty(session.id) ? this.compatible[session.id] : true;
  }


  _showError(session) {
    let message;
    if(browser.isIOS()) {
      message = gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'Your browser is not fully compatible with {{ username }}\'s browser. You will not be able to see each other. Please use a different device to fix this.',
        { username: session.user.name }
      );
    } else {
      message = gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'Your browser is not fully compatible with {{ username }}\'s browser. You will not be able to see each other. Please update your browser to fix this.',
        { username: session.user.name }
      );
    }

    this.notificationService.info(message);
  }
}
