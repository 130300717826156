import { bind, logger } from 'utils/util';


export default class OneDrivePickerRun {
  static get $inject() {
    return [
      'filePickerWorkerService',
    ];
  }

  constructor(
    filePickerWorkerService
  ) {
    bind(this);

    this.isOpened = false;
    this.filePickerWorkerService = filePickerWorkerService;

    this.filePickerWorkerService.on('pickOneDrive', message => {
      this.pick(message.addImageType, message.whiteboardId);
    });
  }


  pick(addImageType, whiteboardId) {
    if(this.isOpened) {
      this.filePickerWorkerService.warning(gettextCatalog.getString(`
        OneDrive has already opened a popup to pick your file but it may be hidden.
        Please check your browser windows in the taskbar or reload the page.
      `));
    }

    this.isOpened = true;
    window.OneDrive.open({
      clientId: window.MICROSOFT_CLIENT_ID,
      action: 'download',
      multiSelect: true,
      openInNewWindow: true,
      advanced: {
        redirectUri: window.URLS.oneDriveLogin
      },

      success: (files) => this._onSuccess(files, addImageType, whiteboardId),
      error: this._onError,
      cancel: this._onCancel,
    });
  }


  _onSuccess(files, addImageType, whiteboardId) {
    let cloudFilesInfo = files.value.map( file => {
      return {
        url: file['@microsoft.graph.downloadUrl'],
        filename: file['name'],
        size: file['size']
      };
    });
    this.isOpened = false;
    this.filePickerWorkerService.create(cloudFilesInfo, addImageType, whiteboardId);
  }


  _onError(error) {
    this.isOpened = false;
    logger.warn(error);
  }

  _onCancel() {
    this.isOpened = false;
  }
}
