import template from './searchUser.html?raw';
import templateDropdown from './searchUserResults.dropdown.html?raw';

import { logger, format, isValidEmail } from 'utils/util';

export const DROPDOWN_ID_RESULTS = 'searchUserResults';

const SET_QUERY_DEBOUNCE = 500;
const SET_LOADING_TIMEOUT = 500;


class SearchUserController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      'apiService',
      'dropdownService',
    ];
  }

  constructor(
    $elem,
    $scope,
    apiService,
    dropdownService
  ) {
    this._bind();

    this.$elem = $elem;
    this.$scope = $scope;
    this.apiService = apiService;
    this.dropdownService = dropdownService;

    dropdownService.register(
      DROPDOWN_ID_RESULTS,
      templateDropdown,
      { cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x' });

    this.$elemQuery = $elem.find('.search-user__query');

    this.query = '';
    this.results = [];
    this.result = null;
    this.loading = false;

    this.setQueryPromise = $q.resolve();
    this.setLoadingTimeout = null;

    $scope.$watch('searchUserCtrl.query', this._onQuery);
    this.$elemQuery.on('click', this._onClick);
    this.$elemQuery.on('keydown', this._onKeyDown);
    this.$elemQuery.on('blur', this._onBlur);

    $scope.$on('searchUserFocus', this.focusQuery);
    $scope.$on('searchUserClear', this.clearResult);
  }

  _bind() {
    this.focusQuery = this.focusQuery.bind(this);
    this._onQuery = this._onQuery.bind(this);
    this._onClick = this._onClick.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._setSingleResult = this._setSingleResult.bind(this);
    this._setQuery = this._setQuery.bind(this);
    this._setQueryDebounced = debounce(this._setQuery, SET_QUERY_DEBOUNCE);
    this._parseAPIResult = this._parseAPIResult.bind(this);
    this._setResults = this._setResults.bind(this);
    this._updateShowResults = this._updateShowResults.bind(this);
    this.clearResult = this.clearResult.bind(this);
  }

  parseEmail(query) {
    if(query.length < 3) {
      return $q.resolve([]);
    }

    let results;

    if(isValidEmail(query)) {
      results = [this._getEmailResult(query)];
    } else {
      results = [{
        user: null,
        label: gettextCatalog.getString('Email is not valid'),
        selectable: false,
      }];
    }

    return results;
  }

  searchAPI(query) {
    if(query.length < 3) {
      return $q.resolve([]);
    }

    let path = format('users/?search=%s&perPage=5', encodeURIComponent(query));

    return this.apiService.get(path)
      .then(response => {
        let resultsRaw = response.data;
        let results;

        if(resultsRaw.length > 0) {
          results = resultsRaw.map(this._parseAPIResult);
        } else if(isValidEmail(query)) {
          results = [this._getEmailResult(query)];
        } else {
          results = [{
            user: null,
            label: gettextCatalog.getString('No results'),
            selectable: false,
          }];
        }

        return results;
      });
  }


  _parseAPIResult(result) {
    let firstName = result.firstName;
    let lastName = result.lastName;
    let fullName = firstName;
    if(lastName) {
      fullName += ' ' + lastName;
    }
    let user = {
      id: result.id,
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
    };

    let [selectable, extraInfo] = this.validate(user);

    return {
      user: user,
      label: fullName,
      extraInfo: extraInfo,
      selectable: selectable,
    };
  }

  _getEmailResult(email) {
    let user = { email: email };

    let [selectable, extraInfo] = this.validate(user);

    let result  = {
      user: user,
      label: email,
      extraInfo: extraInfo,
      selectable: selectable,
    };
    return result;
  }


  validate(user) {
    return this.callbackValidate({ user: user }) || [true, ''];
  }



  focusQuery() {
    this.$elemQuery.focus();
    this._setQuery();
  }

  _onQuery() {
    this._setQueryDebounced();
  }


  _onClick($event) {
    $event.stopPropagation();
    this.$scope.$evalAsync(this._updateShowResults);
  }

  _onKeyDown($event) {
    if(($event.key === 'Enter' || $event.key === 'Tab')) {
      if(this.results.length !== 1 && isValidEmail(this.query)) {
        this.results = [this._getEmailResult(this.query)];
      }

      if(this.results.length === 1) {
        this.$scope.$evalAsync(this._setSingleResult);
      }
    }
  }

  _onBlur() {
    if(isValidEmail(this.query)) {
      this.results = [this._getEmailResult(this.query)];
      this.$scope.$evalAsync(this._setSingleResult);
    }
  }


  _setSingleResult() {
    if(this.results.length !== 1) {
      return;
    }

    let result = this.results[0];
    this._setResults([]);
    this.setResult(result);
  }


  _setQuery() {
    this.setQueryPromise = this.setQueryPromise.then(() => {
      this._setLoading(true);
      if(this.shouldSearchAPI) {
        return this.searchAPI(this.query);
      } else {
        return this.parseEmail(this.query);
      }
    })
      .then(this._setResults)
      .catch(error => {
        // TODO: show an error to the user
        logger.warn(error);
        this._setResults([]);
      })
      .finally(() => {
        this._setLoading(false);
      });
  }


  _setLoading(loading) {
    if(!loading) {
      $timeout.cancel(this.setLoadingTimeout);
      this.setLoadingTimeout = null;
      this.loading = false;

    } else if(!this.setLoadingTimeout) {
      this.setLoadingTimeout = $timeout(() => {
        this.loading = loading;
      }, SET_LOADING_TIMEOUT);
    }
  }


  _setResults(results) {
    this.results = results || [];
    this._updateShowResults();
  }

  _updateShowResults() {
    if(this.results.length > 0) {
      this.dropdownService.show(DROPDOWN_ID_RESULTS, this.$elemQuery, {
        searchUserCtrl: this,
      });
    } else {
      this.dropdownService.hide(DROPDOWN_ID_RESULTS);
    }
  }


  setResult(result) {
    // Wait until the dropdown is closed: otherwise handling of the click event causes parent
    // dropdowns to close as well
    $timeout(() => {
      this.query = '';
      this.results = [];
      this.result = result;
      this.callbackResult({ user: result.user });
    });
  }


  clearResult() {
    this.result = null;
    this.callbackResult({ user: null });
    $timeout(this.focusQuery);
  }
}

export default {
  controller: SearchUserController,
  controllerAs: 'searchUserCtrl',
  template,

  bindings: {
    callbackResult: '&onResult',
    callbackValidate: '&validate',
    shouldSearchAPI: '<apiSearch',
  },
};
