// Code borrowed from NodeJS: https://github.com/nodejs/node/blob/master/lib/util.js


/**
 * The util.format() method returns a formatted string using the first argument as a
 * printf-like format string which can contain zero or more format specifiers.
 * Each specifier is replaced with the converted value from the corresponding argument.
 *
 * @param {string} f - A printf-like format string.
 * @param {...string} [args] - the arguments corresponding to the fstring specifiers
 *
 * @returns {string} The formatted string
 */
export default function format(f) {
  let argLen = arguments.length;
  if(argLen === 0) {
    return '';
  }

  let str = '';
  let a;

  if(typeof f === 'string') {
    if(argLen === 1) {
      return f;
    }

    a = 1;
    let lastPos = 0;
    for(let i = 0; i < f.length;) {
      if(f.charCodeAt(i) === 37/* '%'*/ && i + 1 < f.length) {
        switch(f.charCodeAt(i + 1)) {
          case 100: // 'd'
            if(a >= argLen) {
              break;
            }
            if(lastPos < i) {
              str += f.slice(lastPos, i);
            }
            str += Number(arguments[a++]);
            lastPos = i = i + 2;
            continue;
          case 106: // 'j'
            if(a >= argLen) {
              break;
            }
            if(lastPos < i) {
              str += f.slice(lastPos, i);
            }
            str += tryStringify(arguments[a++]);
            lastPos = i = i + 2;
            continue;
          case 115: // 's'
            if(a >= argLen) {
              break;
            }
            if(lastPos < i) {
              str += f.slice(lastPos, i);
            }
            str += String(arguments[a++]);
            lastPos = i = i + 2;
            continue;
          case 37: // '%'
            if(lastPos < i) {
              str += f.slice(lastPos, i);
            }
            str += '%';
            lastPos = i = i + 2;
            continue;
        }
      }
      ++i;
    }

    if(lastPos === 0) {
      str = f;
    } else if(lastPos < f.length) {
      str += f.slice(lastPos);
    }

  } else {
    a = 0;
  }

  while(a < argLen) {
    let v = arguments[a++];
    let vStr;
    switch(typeof v) {
      case 'object':
        vStr = tryStringify(v);
        break;

      default:
        vStr = String(v);
    }
    str += (a === 1 ? '' : ' ') + vStr;
  }

  return str;
}


function tryStringify(arg) {
  if(arg instanceof Error) {
    return arg.stack || arg.toString();
  }

  try {
    return JSON.stringify(arg);
  } catch(e) {
    return '[Circular]';
  }
}
