<div class="sharing-options__section" data-testid="sharingOptionsModal">
  <h2 class="sharing-options__header" i18n>Via a link</h2>

  <div class="sharing-options__section-content">
    <input type="text" class="input sharing-options__input" [(ngModel)]="url" disabled>
    <div
      [copyText]="url"
      (click)="track('copiedLink')"
      class="btn btn--secondary"
      data-testid="sharingModalCopyLink"
      i18n
      >
        Copy link
    </div>
  </div>
</div>

<div class="sharing-options__section">
  <h2 i18n>Via embedding HTML code</h2>

  <div class="sharing-options__section-content">
    <textarea
      class="textarea sharing-options__input"
      rows="3"
      readonly
      [value]="embeddedURLSnippet"
    ></textarea>
    <div
      [copyText]="embeddedURLSnippet"
      (click)="track('copiedEmbeddedLink')"
      class="btn btn--secondary"
      data-testid="sharingModalCopyEmbeddedLink"
      i18n
      >
        Copy embedded HTML
    </div>
  </div>
  <div class="text-muted mt-2" *ngIf="context === 'contactForm'">
    <span i18n>Copy the code above and paste it into your website. Your form will appear where you place it.</span>
    <a [href]="'contactFormShare'|helpArticle" target="_blank" i18n>
      Learn more.
    </a>
  </div>
  <div class="text-muted mt-2" *ngIf="context === 'appointmentType'">
    <span i18n>Copy the code above and paste it into your website. Your booking page will appear where you place it.</span>
    <a [href]="'meetingTypesShare'|helpArticle" target="_blank" i18n>
      Learn more.
    </a>
  </div>

</div>


<div class="sharing-options__section">
  <h2 i18n>Via a QR code</h2>

  <div class="sharing-options__section-content">
    <qrcode
      #qrcode
      [qrdata]="embeddedUrl"
      [width]="120"
      [errorCorrectionLevel]="'M'"
      [elementType]="'img'"
      [margin]="0"
      class="sharing-options__qr"
    >
    </qrcode>

    <div class="sharing-options__qr-actions">
      <div>
        <div
          class="btn btn--secondary"
          *ngIf="supportsCopyQR"
          copyText
          [copyBlob]="getQRCodeBlob(qrcode)"
          (click)="track('copiedQRCode')"
          data-testid="sharingModalCopyQR"
          i18n
        >
          Copy QR
        </div>
        <div
          class="btn btn--secondary"
          i18n
          [copyText]="getEmbeddedQRCode(qrcode)"
          (click)="track('copiedQREmbedded')"
          data-testid="sharingModalCopyEmbeddedQR"
        >
          Copy QR embedded HTML
        </div>
      </div>
      <a
        (click)="downloadQRCode(qrcode)"
        class="sharing-options__qr-download"
        data-testid="sharingModalDownloadQR"
      >
        <b i18n>Download image</b>
      </a>
    </div>
  </div>
</div>

<div class="sharing-options__section">
  <h2 i18n>Via a Contact widget</h2>

  <div class="d-flex">
    <a
      *ngIf="requestUserService.user.isAdmin"
      href="{{ urlService.urls.contactWidgetCreate }}?destinationId={{id}}"
      class="btn btn--secondary d-flex align-items-center"
      (click)="track('openedContactWidgetCreate')"
      data-testid="sharingModalCreateWidget"
    >
      <svg-icon class="btn__icon" [svgName]="'handshake'"></svg-icon>
      <span class="ml-2" i18n>Create a widget</span>
    </a>
  </div>
</div>

<div ng-bind-html="getEmbeddedQRCode(qrcode)"></div>
