<div cdkMenu class="dropdown">
  <a *ngIf="email" cdkMenuItem (click)="shareViaEmail()" class="dropdown__list-item">
    <svg-icon class="btn__icon" [svgName]="'send'"></svg-icon>
    <span class="ml-2" i18n>Share via email</span>
  </a>
  <a
    *ngIf="integration && syncService.isActive()"
    cdkMenuItem
    (click)="syncService.canSaveNotes() && syncService.saveNotes()"
    class="dropdown__list-item" 
    [class.disabled]="!syncService.canSaveNotes()"
  >
    <div *ngIf="[State.INITIAL, State.DIRTY].includes(state)">
      <span *ngIf="integrations.length > 1" i18n>
        Save to integrations
      </span>
      <span *ngIf="integrations.length === 1" i18n>
        Save to {{ integrations[0] }}
      </span>
    </div>
    <div *ngIf="state === State.SYNCING">
      <div class="d-flex align-items-center">
        <loading class="mr-2"></loading>
        <span i18n>Saving...</span>
      </div>
    </div>
    <div *ngIf="state === State.SYNCED" class="d-flex align-items-center">
      <svg-icon class="icon mr-2" svgName="checkmark"></svg-icon>
      <span i18n>Saved</span>
    </div>
  </a>
</div>
