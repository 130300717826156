import Model from 'utils/models';
import * as fields from 'utils/fields';

class GeoPickerAppointmentTypeUrlField extends fields.Field {
  toInternalValue(data) {
    let url = new URL(data);
    url.searchParams.append('showBackOnFirstStep', '');
    url.searchParams.append('skipSingleMeetingType', '');

    let currentUrl = new URL(location.href);
    currentUrl.searchParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });

    return url.toString();
  }
}


export default class GeoPickerAppointmentType extends Model {
  static get fields() {
    return {
      url: new GeoPickerAppointmentTypeUrlField(),
    };
  }

  get distance() {
    return this.location.distance;
  }
}
