export default class ResetController {
  static get $inject() {
    return [
      'resetService',
    ];
  }

  constructor(
    resetService
  ) {
    this.resetService = resetService;

    this.resetMeetingActivity = true;
    this.resetSessions = true;
    this.resetChat = true;
    this.resetRecordings = true;
    this.resetWhiteboards = true;
    this.resetUserAccess = false;
  }


  onResetMeetingActivityChange() {
    this.resetSessions = this.resetMeetingActivity;
    this.resetChat = this.resetMeetingActivity;
    this.resetRecordings = this.resetMeetingActivity;
    this.resetWhiteboards = this.resetMeetingActivity;
  }


  onSubCheckBoxChanged() {
    this.resetMeetingActivity = (
      this.resetChat
      && this.resetRecordings
      && this.resetWhiteboards
      && this.resetSessions
    );
    this.resetSessions = this.resetMeetingActivity;
  }


  get isNoneChecked() {
    return !(
      this.resetChat
      || this.resetRecordings
      || this.resetWhiteboards
      || this.resetUserAccess );
  }


  reset() {
    this.resetService.reset(
      {
        sessions: this.resetSessions,
        chat: this.resetChat,
        whiteboards: this.resetWhiteboards,
        recordings: this.resetRecordings,
        userPermissions: this.resetUserAccess
      }
    );
  }
}
