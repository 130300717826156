import { Component, Host, Input } from '@angular/core';
import { User } from 'core/models/User';
import {
  SettingsSegmentComponent
} from 'utils/settings/settings-segment/settings-segment.component';
import {
  SettingsComponent
} from 'utils/settings/settings.component';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';
import { AppointmentLocation } from '../../../../models/appointment-location';


@Component({
  selector: 'appointment-type-summary[instance]',
  templateUrl: 'appointment-type-summary.component.html',
  styleUrls: ['appointment-type-summary.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeSummaryComponent
  }]
})
export class AppointmentTypeSummaryComponent extends AppointmentTypeSettingsComponent {
  @Input() teamMembers: User[] = [];

  public readonly maxHostsInTitle = 3;

  public labelMap: Record<AppointmentLocation, string> = {
    'online': $localize`Online`,
    'offline': $localize`In person`,
    'phone_call': $localize`Phone call`
  };

  static override formFields = [
    'showPublic',
  ];

  constructor(
    @Host() private segment: SettingsSegmentComponent,
  ) {
    super();
  }


  get hosts() {
    const userIds = new Set(
      this.instance.appointmentTypeConfigs
        .filter(config => config.active)
        .map(config => config.userId)
    );
    const users = this.teamMembers
      .filter(user => userIds.has(user.id));
    return users;
  }
  get hostNames() {
    return this.hosts.map(user => user.fullName);
  }


  openDetails() {
    this.segment.segmentGroup.segments.first.select();
  }

  get sortedLocationsString() {
    const sortFn = (locA, locB) => {
      if(locA === AppointmentLocation.ONLINE) {
        return 1;
      } else if(locB === AppointmentLocation.ONLINE) {
        return -1;
      }
      return 0;
    };

    return this.instance.locations.sort(sortFn)
      .map(loc => this.labelMap[loc])
      .join(', ');
  }
}
