import {
  AfterViewInit,
  Component, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef
} from '@angular/core';
import { SiteService } from 'utils/site.service';
import { UrlService } from 'utils/url.service';
import {
  DashboardScope, DataPageComponent
} from 'dashboard/data-page/data-page.component';
import { Meeting, OrganizationMeeting, UserMeeting } from 'meeting/models/Meeting';
import { getQueryParameter, logger, storage, validateEnumWithDefault } from 'utils/util';
import { AdminPulseService } from 'integrations/adminpulse/adminpulse.service';
import { ComboBoxOption } from 'utils/form-elements/dropdowns';
import { ModalConfig, ModalService } from 'utils/ui-components/modal';
import { defaultModalConfig } from 'utils/ui-components/modal/modal.component';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { TemplatePortal } from '@angular/cdk/portal';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { UrlQueryParamsService } from 'scheduling/manage/appointments/url-query-params.service';

const LOCAL_STORAGE_KEY_VIEW_STYLE = 'meetings_view_style';


export enum Layout {
  GRID = 'grid',
  LIST = 'list',
}

@Component({
  selector: 'meeting-rooms',
  templateUrl: './meeting-rooms.component.html',
  styleUrls: ['./meeting-rooms.component.scss'],
  providers: [UrlQueryParamsService]
})
export class MeetingRoomsComponent extends DataPageComponent implements OnInit, AfterViewInit {
  override perPage = 12;

  public meetingRooms: Meeting[] = [];
  public noResultsMessage = '';
  public shouldShowHint = false;

  private _selectedLayout: Layout;
  public Layout = Layout;

  private baseAdminPulseOptions: ComboBoxOption[] = [];
  public adminPulseOptions: ComboBoxOption[] = [];
  public locallyChangedAdminPulseOptions: Map<string, string | null> = new Map();
  public adminPulseOptionPicked = false;
  public selectedAdminPulseOption: string | null = null;

  @ViewChild('addRoom') addRoomModal!: TemplateRef<any>;

  constructor(
    public urlService: UrlService,
    public siteService: SiteService,
    public modalService: ModalService,
    public adminPulseService: AdminPulseService,
    private viewContainerRef: ViewContainerRef,
    private urlQueryParamsService: UrlQueryParamsService,

    @Inject('notificationService') private notificationService,
    @Inject('waitingRoomSessionsService') public waitingRoomSessionsService,
  ) {
    super();
    this._selectedLayout = validateEnumWithDefault(
      Layout,
      storage.getItem(LOCAL_STORAGE_KEY_VIEW_STYLE),
      Layout.GRID
    );
  }

  ngOnInit(): void {
    this.initializeQueryParams();
  }

  private initializeQueryParams(): void {
    const scope = this.urlQueryParamsService.queryParams.get('scope');
    if (scope) {
      this.scope = this.findScope(scope);
    }

    const page = this.urlQueryParamsService.queryParams.get('page');
    if (page) {
      this.page = parseInt(page, 10);
    }
  }

  override get shouldShowAllScope() {
    return true;
  }

  ngAfterViewInit() {
    if(this.shouldShowCreateModal) {
      this.openCreateMRModal();
    }
  }

  public onScopeChange(scope: Scope): void {
    if (this.isDefaultScope(scope)) {
      this.urlQueryParamsService.removeQueryParam('scope');
    } else {
      this.urlQueryParamsService.setQueryParam('scope', scope.id);
    }
    this.scope = scope;
  }

  private isDefaultScope(scope: Scope): boolean {
    return scope === DashboardScope.PERSONAL;
  }

  public onPageChange(page: number): void {
    if (this.isDefaultPage(page)) {
      this.urlQueryParamsService.removeQueryParam('page');
    } else {
      this.urlQueryParamsService.setQueryParam('page', page.toString());
    }
    this.page = page;
  }

  private isDefaultPage(page: number): boolean {
    return page === 1;
  }

  get selectedLayout() {
    return this._selectedLayout;
  }
  set selectedLayout(layout: Layout) {
    this._selectedLayout = layout;
    storage.setItem(LOCAL_STORAGE_KEY_VIEW_STYLE, layout);
  }

  get createMeetingRoom() {
    return getQueryParameter('createMeetingRoom');
  }

  get withKey() {
    return getQueryParameter('withKey');
  }

  get withTemplateId() {
    return getQueryParameter('withTemplateId');
  }

  get withAdminPulseRelationId() {
    return getQueryParameter('withAdminPulseRelationId');
  }

  get shouldShowCreateModal() {
    return (
      this.createMeetingRoom != null
      || this.withKey != null
      || this.withTemplateId != null
      || this.withAdminPulseRelationId != null
    );
  }

  buildAdminPulseOptions() {
    const takenMap: Map<string, string> = new Map();
    this.meetingRooms.forEach(room => {
      if(room.adminpulseRelation) {
        takenMap.set(room.adminpulseRelation, room.key);
      }
    });


    const res =  this.baseAdminPulseOptions.map(opt => {
      if(this.locallyChangedAdminPulseOptions.has(opt.value)) {
        // eslint-disable-next-line max-len
        const disabledHelpText = $localize `This relation is already linked to meeting room ${this.locallyChangedAdminPulseOptions.get(opt.value)}`;
        return {
          ...opt,
          disabled: !!this.locallyChangedAdminPulseOptions.get(opt.value),
          disabledHelpText: disabledHelpText
        };
      } else {
        return opt;
      }
    });

    this.adminPulseOptions = res;
  }

  override fetch() {
    return this.fetchMeetingRooms();
  }

  private async fetchAdminPulseOptions() {
    const relations = await this.adminPulseService.fetchAdminPulseRelations();
    this.baseAdminPulseOptions = this.adminPulseService.getRelationComboBoxOptions(relations);
    this.buildAdminPulseOptions();
  }

  openCreateMRModal() {
    const modalIcon: SvgIcon = {
      name: 'add',
    };
    const templatePortal = new TemplatePortal(
      this.addRoomModal,
      this.viewContainerRef
    );

    const modalConfig: ModalConfig =  {
      ...defaultModalConfig,
      icon: modalIcon,
      modalClass: 'modal--sm',
      title: $localize `Add a new room`,
      templatePortal: templatePortal,
    };
    this.modalService.open(modalConfig);
  }

  getManageAdminPulseText(meetingRoom): string {
    if(meetingRoom.adminpulseRelation != null) {
      return $localize `Manage AdminPulse`;
    } else {
      return $localize `Link AdminPulse`;
    }
  }

  adminPulseRelationIsPresent(relationId: string): boolean {
    return this.adminPulseOptions.find(option => option.value === relationId) != null;
  }

  getAdminPulseLabelFromId(relationId: string): string {
    return this.adminPulseOptions.find(option => option.value === relationId)?.label || '';
  }

  updateAdminPulseLink(meetingRoom, closeButton: HTMLElement) {
    const oldRelation = meetingRoom.adminpulseRelation;
    meetingRoom.adminpulseRelation = this.selectedAdminPulseOption;
    meetingRoom.save()
      .then(() => {
        if(oldRelation) {
          this.locallyChangedAdminPulseOptions.set(oldRelation, null);
        }
        this.locallyChangedAdminPulseOptions.set(meetingRoom.adminpulseRelation, meetingRoom.key);

        const relationOption = this.adminPulseOptions.find(opt => {
          return opt.value === this.selectedAdminPulseOption;
        });
        if(relationOption) {
          relationOption.disabled = true;
        }
        this.selectedAdminPulseOption = null;

        this.modalService.closeNearest(closeButton);
        this.notificationService.success(
          $localize `Successfully updated the linked AdminPulse relation`
        );
        this.buildAdminPulseOptions();
      })
      .catch(error => {
        this.notificationService.warning(
          // eslint-disable-next-line max-len
          $localize `There was a problem updating the linked AdminPulse relation. Refresh the page or try again later.`
        );
        logger.warn(error);
        this.selectedAdminPulseOption = oldRelation;
      });
  }

  private fetchMeetingRooms() {
    let modelConfig;
    if(this.scope === DashboardScope.TEAM) {
      modelConfig = {
        model: OrganizationMeeting,
        identifiers: { organizationId: this.requestUserService.user.organization.id },
      };
    } else if(this.scope === DashboardScope.ALL) {
      modelConfig = {
        model: Meeting,
      };
    } else {
      // Scope.PERSONAL
      modelConfig = {
        model: UserMeeting,
        identifiers: { userId: this.requestUserService.user.id },
      };
    }

    const apiConfig = {
      params: {
        perPage: this.perPage,
        page: this.page,
        include: 'activeUsers,owner'
      }
    };
    const query = this.searchQuery;
    if(query) {
      apiConfig.params['search'] = this.searchQuery;
    }

    return this.listCancelable(modelConfig, apiConfig)
      .then(({ data: meetingRooms }) => {
        this.meetingRooms = meetingRooms as Meeting[];
        this.noResultsMessage = this.meetingRooms.length === 0 ?
          query ?
            $localize `No meeting rooms matched your search.` :
            $localize `You don't have any meeting rooms yet.` :
          '';
      }).then( () => {
        if(this.adminPulseService.isConnected) {
          this.fetchAdminPulseOptions();
        }
      });
  }

  trackByMeetingRoom(index, meetingRoom: Meeting) {
    return meetingRoom.key;
  }

  delete(meetingRoom) {
    meetingRoom.delete()
      .catch(error => {
        this.notificationService.warning(
          $localize `Something went wrong while deleting your meeting room.`
        );
        logger.error(error);
      })
      .finally(() => {
        this.fetchMeetingRooms();
      });
  }

  onMeetingRoomCreated(meeting: Meeting) {
    this.notificationService.success($localize `Meeting room ${meeting.key} created`);
    window.open(meeting.url, '_blank');
    this.fetchMeetingRooms()
      .catch(error => this.handleFetchError(error));
    this.buildAdminPulseOptions();
  }


  shouldShowAccessLevelOverrideWarning(meeting) {
    return (
      this.selectedAdminPulseOption != null
      && meeting.teamAccessLevel.level !== AccessLevel.IS_HOST.level
    );
  }

  get deactivatedUpgradeText() {
    const proPlanName = this.siteService.site.proPlanName;
    // eslint-disable-next-line max-len
    return $localize `This meeting room is deactivated. You can still open it to download its content,but you can't hold any meetings here. Upgrade to ${proPlanName} to activate this meeting room.`;
  }
}
