import errors from './errors';

interface CallbackItem {
  id: number,
  fn: () => void,
  delay: number,
  lastExecution: number,
}

const items: { [key: number]: CallbackItem } = {};
let maxId = 0;


export function setInterval(fn: () => void, delay: number) {
  /**
   * An optimized version of setInterval:
   * - Callbacks are executed at most once every second.
   * - All fns are executed in the same event loop, so that angular change detection only has
   *   to run once.
   * @param fn - A function that should be called repeatedly. The function is called without
   *  parameters.
   * @param delay - Number of milliseconds between each function call.
   * @returns An id that can be used to call @see clearInterval.
   */
  if(delay < 1000) {
    throw new errors.InvalidArgumentError('delay must be at least 1000');
  }

  const id = ++maxId;
  items[id] = {
    id,
    fn,
    delay,
    lastExecution: 0,
  };
  if(Object.values(items).length === 1) {
    scheduleIntervalLoop();
    window.__vecteraRunOutsideAngular(intervalLoop);
  }
  return id;
}

export function clearInterval(id: number) {
  delete items[id];
}


function scheduleIntervalLoop() {
  setTimeout(intervalLoop, 1000);
}

function intervalLoop() {
  window.$rootScope.$evalAsync(intervalLoopInner);
}

function intervalLoopInner() {
  const now = Date.now();
  const itemsArray = Object.values(items);

  if(itemsArray.length > 0) {
    itemsArray.forEach(item => {
      if(now - item.lastExecution >= item.delay) {
        item.fn();
        item.lastExecution = now;
      }
    });

    scheduleIntervalLoop();
  }
}
