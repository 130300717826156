import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, InjectionToken } from '@angular/core';
import { SvgIcon } from '../svg-icon';


export type DialogConfig = {
  text?: string,
  template?: string,
  templatePortal?: TemplatePortal,

  icon?: SvgIcon,
  title?: string,

  cancelText: string,
  confirmText: string,
  confirmButtonClass: string | string[] | Set<string> | { [klass: string]: any; },
}
export const DIALOG_CONFIG = new InjectionToken<DialogConfig>('dialogConfig');


@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  constructor(
    @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
  ) {}
}
