import template from './deviceSettings.html?raw';
import templateDropdownDevice from './devicePicker.dropdown.html?raw';
import templateDropdownBackground from './backgroundPicker.dropdown.html?raw';

import { bind, browser } from 'utils/util';
import DevicePickerInfo from './DevicePickerInfo';
import { StreamType, StreamTypeToDeviceKind } from  'meeting/meeting-room/stream';


const DROPDOWN_ID_DEVICE_PICKER = 'devicePicker';
const DROPDOWN_ID_BACKGROUND_PICKER = 'backgroundPicker';


let devicePickerInfo = null;

class DeviceSettingsController {
  static get $inject() {
    return [
      'settingsService',
      'segmentationSettingsService',
      'permissionArrowService',
      'mediaDeviceService',
      'meetingService',
      'dropdownService',
      'streamService',
      'userStreamService',
    ];
  }

  constructor(
    settingsService,
    segmentationSettingsService,
    permissionArrowService,
    mediaDeviceService,
    meetingService,
    dropdownService,
    streamService,
    userStreamService
  ) {
    bind(this);

    this.settingsService = settingsService;
    this.segmentationSettingsService = segmentationSettingsService;
    this.permissionArrowService = permissionArrowService;
    this.mediaDeviceService = mediaDeviceService;
    this.meetingService = meetingService;
    this.dropdownService = dropdownService;
    this.streamService = streamService;
    this.userStreamService = userStreamService;


    dropdownService.register(
      DROPDOWN_ID_DEVICE_PICKER,
      templateDropdownDevice,
      { cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x' }
    );

    dropdownService.register(
      DROPDOWN_ID_BACKGROUND_PICKER,
      templateDropdownBackground,
      { cssClasses: 'dropdown-deprecated--no-padding-x' }
    );


    this.devicePickerInfos = {};

    streamService.on('add', this._onStreamAdd);
    streamService.on('remove', this._onStreamRemove);
    mediaDeviceService.on(
      'audioinput videoinput audiooutput preferredAudioOutput',
      this._updateDevicePickerInfos
    );
    Object.values(this.streamService.getAllLocal()).forEach(stream => {
      stream.on('enabled', this._onStreamEnabled);
    });
    this._updateDevicePickerInfos();

    this.settingsService.on('suppressMicNoise', this._onSuppressMicNoise);
    this.isMicNoiseSuppressed = this.settingsService.suppressMicNoise;
  }


  get shouldShowTestVideoInput() {
    return this.showTestVideoInput == null ? true : this.showTestVideoInput;
  }
  get shouldShowTestAudioInput() {
    return this.showTestAudioInput == null ? true : this.showTestAudioInput;
  }
  get shouldShowTestAudioOutput() {
    return this.showTestAudioOutput == null ? true : this.showTestAudioOutput;
  }

  get shouldShowDifferentDevicesWarning() {
    let audioStream = Object.values(this.streamService.getAllLocal())
      .filter(stream => stream.type === StreamType.AUDIO)[0];
    if(!audioStream || !audioStream.enabled) {
      return false;
    }

    let inputDevice = audioStream.inputDevice;
    if(inputDevice && inputDevice.getReferenced()) {
      inputDevice = inputDevice.getReferenced();
    }

    let outputDevice = audioStream.outputDevice;
    if(outputDevice && outputDevice.getReferenced()) {
      outputDevice = outputDevice.getReferenced();
    }

    return (
      inputDevice
      && outputDevice
      && inputDevice.id !== outputDevice.id
      && inputDevice.groupId !== outputDevice.groupId
      && inputDevice.label !== outputDevice.label
    );
  }

  get devicePickerInfo() {
    return devicePickerInfo;
  }

  $onInit() {
    this.streamService.on('add', this._onStreamAdd);
    this.streamService.on('remove', this._onStreamRemove);
    this.mediaDeviceService.on(
      'audioinput videoinput audiooutput preferredAudioOutput',
      this._updateDevicePickerInfos
    );
    Object.values(this.streamService.getAllLocal()).forEach(stream => {
      stream.on('enabled', this._onStreamEnabled);
    });
    this._updateDevicePickerInfos();
  }

  $onDestroy() {
    Object.values(this.streamService.getAllLocal()).forEach(stream => {
      stream.off('enabled', this._onStreamEnabled);
    });
  }


  /*******************************
   * Populate the device pickers *
   *******************************/

  _onStreamAdd(stream) {
    if(stream.isLocal) {
      stream.on('enabled', this._onStreamEnabled);
      this._updateDevicePickerInfos();
    }
  }

  _onStreamRemove(stream) {
    if(stream.isLocal) {
      stream.off('enabled', this._onStreamEnabled);
      this._updateDevicePickerInfos();
    }
  }

  _onStreamEnabled() {
    this._updateDevicePickerInfos();
  }

  _updateDevicePickerInfos() {
    this.devicePickerInfos = [];
    this._addDevicePickerInfosInput(StreamType.AUDIO);
    this._addDevicePickerInfoOutput();
    this._addDevicePickerInfosInput(StreamType.VIDEO);
  }

  _addDevicePickerInfosInput(type) {
    let kind = StreamTypeToDeviceKind[type];
    let streams = Object.values(this.streamService.getAllLocal())
      .filter(stream => stream.type === type && stream.enabled);

    if(streams.length === 0) {
      this.devicePickerInfos.push(new DevicePickerInfo(
        this.mediaDeviceService,
        null,
        kind,
        null,
        true,
        true
      ));
    } else {
      streams.forEach(stream => {
        let info = new DevicePickerInfo(
          this.mediaDeviceService,
          stream.inputDevice,
          kind,
          stream,
          true,
          true
        );
        this.devicePickerInfos.push(info);
      });
    }
  }

  _addDevicePickerInfoOutput() {
    let info = new DevicePickerInfo(
      this.mediaDeviceService,
      this.mediaDeviceService.preferredAudioOutput,
      'audiooutput',
      null,
      browser.supportsOutputDevice(),
      true
    );
    this.devicePickerInfos.push(info);
  }

  pickDevice(device) {
    this.mediaDeviceService.setPreferredDevice(device);
  }


  toggleDevicePicker(argDevicePickerInfo, $event) {
    devicePickerInfo = argDevicePickerInfo;

    $q.resolve()
      .then(() => {
        let kind = devicePickerInfo.kind;
        if(!this.mediaDeviceService.permissions[kind]) {
          this.permissionArrowService.show();
          return navigator.mediaDevices.getUserMedia({
            audio: kind === 'audioinput' || kind === 'audiooutput',
            video: kind === 'videoinput',
          })
            .then(mediaStream => {
              mediaStream.getTracks().forEach(track => track.stop());
            })
            .catch(angular.noop)
            .finally(() => {
              this.permissionArrowService.hide();
            });
        }
      })
      .then(() => {
        this.dropdownService.toggle(DROPDOWN_ID_DEVICE_PICKER, $event, {
          devicePickerInfo: devicePickerInfo,
          pickDevice: this.pickDevice,
        });
      });
  }

  /********************************
   * Mic Noise Suppression Toggle *
   ********************************/

  _onSuppressMicNoise() {
    this.setMicNoiseSuppressed(this.settingsService.suppressMicNoise);
  }

  setMicNoiseSuppressed(suppressMicNoise) {
    this.isMicNoiseSuppressed = suppressMicNoise;
    this.settingsService.setMicNoiseSuppressed(suppressMicNoise);
  }

  get supportsNoiseSuppression() {
    return browser.supportsAudioWorkletAPI();
  }


  /*********************************
   * Background Replacement Toggle *
   *********************************/

  toggleBackgroundPicker($event) {
    this.dropdownService.toggle(DROPDOWN_ID_BACKGROUND_PICKER, $event);
  }

  get supportsBlurBackground() {
    return browser.supportsBlurBackground();
  }
}


export default {
  controller: DeviceSettingsController,
  controllerAs: 'deviceSettingsCtrl',
  template,

  bindings: {
    'showTestVideoInput': '<',
    'showTestAudioInput': '<',
    'showTestAudioOutput': '<',
  }
};
