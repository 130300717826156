import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { SvgIconModule } from 'utils/ui-components/svg-icon';
import { SettingsErrorsComponent } from './settings-errors/settings-errors.component';
import { WithSettingsErrorsDirective } from './settings-errors/with-settings-errors.directive';
import { SettingsIconComponent } from './settings-icon/settings-icon.component';
import {
  SettingsNonFieldErrorsComponent
} from './settings-non-field-errors/settings-non-field-errors.component';
import {
  SettingsSegmentGroupComponent
} from './settings-segment/settings-segment-group.component';
import { SettingsSegmentComponent } from './settings-segment/settings-segment.component';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
  ],
  declarations: [
    SettingsErrorsComponent,
    SettingsIconComponent,
    SettingsSegmentComponent,
    SettingsSegmentGroupComponent,
    WithSettingsErrorsDirective,
    SettingsNonFieldErrorsComponent
  ],
  exports: [
    SettingsErrorsComponent,
    SettingsIconComponent,
    SettingsSegmentComponent,
    SettingsSegmentGroupComponent,
    WithSettingsErrorsDirective,
    SettingsNonFieldErrorsComponent,
  ],
})
export class SettingsModule {}
