import template from './chat.html?raw';


class ChatController {
  static get $inject() {
    return [
      '$scope',
      '$element',
      'chatBoxService',
      'emojiService',
      'userService',
    ];
  }

  constructor(
    $scope,
    $elem,
    chatBoxService,
    emojiService,
    userService
  ) {
    this._bind();

    this.chatBoxService = chatBoxService;
    this.emojiService = emojiService;
    this.userService = userService;

    this.$scope = $scope;
    this.$elem = $elem;
    this.$elemTextarea = $elem.find('.chat__input');
    $elem.on('click', this._onClick);

    this.hasNewMessages = false;
    this._showOnlyPersonMessage = true;
  }

  _bind() {
    this._onClick = this._onClick.bind(this);
  }


  _onClick() {
    if(window.getSelection().isCollapsed) {
      this.$elemTextarea.focus();
    }
  }

  closeOnlyPersonMessage() {
    this._showOnlyPersonMessage = false;
  }


  get showOnlyPersonMessage() {
    return (
      this.userService.joined.size === 1
      && this._showOnlyPersonMessage
      && this.chatBox
      && this.chatBox.id === 'main'
    );
  }

  addToInput(str) {
    this.$scope.$broadcast('addToInput', str);
  }
}


export default {
  controller: ChatController,
  controllerAs: 'chatCtrl',
  template,

  bindings: {
    chatBox: '<',
  }
};
