import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SessionBackendService } from 'meeting/meeting-room/communication/session-backend.service';
import { UtilsModule } from 'utils/utils.module';
import {
  AddRoomModalModule
} from '../home/meeting-rooms-menu/add-room-modal/add-room-modal.module';
import { WaitingRoomModule } from '../home/waiting-room/waiting-room-module';
import { MeetingRoomsComponent } from './meeting-rooms.component';

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    UtilsModule,
    WaitingRoomModule,
    AddRoomModalModule,
  ],
  declarations: [
    MeetingRoomsComponent,
  ],
  providers: [
    SessionBackendService,
  ],
  exports: [
    MeetingRoomsComponent,
  ],
})
export class MeetingRoomsModule {}
