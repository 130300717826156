import { Provider } from 'contactForm/models/MappingField';
import * as fields from 'utils/fields';
import Model from 'utils/models';


export const QuestionType = Object.freeze({
  SINGLE_LINE: 'single_line',
  MULTI_LINE: 'multi_line',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  ADDRESS: 'address',
  DATE: 'date',
  URL: 'url',
  NUMBER: 'number',
  INTEGER: 'integer',
  MONEY: 'money',
  SINGLE_SELECT: 'single_select',
  DROPDOWN: 'dropdown',
  LANGUAGE: 'language',
  YES_NO: 'yes_no',
  MULTI_SELECT: 'multi_select',
  CHECKBOX: 'checkbox',
  ATTACHMENT: 'attachment',
  VAT: 'vat',
  KVK: 'kvk',
});


export function isTypeAllowedFree(questionType) {
  return [
    QuestionType.SINGLE_LINE,
    QuestionType.MULTI_LINE,
    QuestionType.EMAIL,
    QuestionType.PHONE_NUMBER,
    QuestionType.ADDRESS,
    QuestionType.URL,
    QuestionType.NUMBER,
    QuestionType.INTEGER,
    QuestionType.VAT,
    QuestionType.KVK,
  ].includes(questionType);
}


export class ContactFormQuestion extends Model {
  static get $inject() {
    return [
      'mappingFieldsService',
    ].concat(Model.$inject);
  }


  static get fields() {
    return {
      type: new fields.ChoiceField({
        choices: Object.values(QuestionType),
      }),
      label: new fields.StringField(),
      required: new fields.BooleanField(),
      order: new fields.NumberField(),
      extra: new fields.JSONField({
        default: () => ({}),
      }),
    };
  }


  static get basePath() {
    throw Error('Contact form questions can only be requested through their contact form parent.');
  }


  toRepresentation(...args) {
    // When updating a ContactForm, none of its questions can be omitted in the request body.
    // To make this easier, a ContactFormQuestion can be represented by just its id, so the
    // toRepresentation of this model is extended to always include its id.
    let data = super.toRepresentation(...args);
    data.id = this.id;
    return data;
  }


  get allowFree() {
    return isTypeAllowedFree(this.type);
  }


  getMappedFieldId(provider) {
    return (
      this.extra.mappedFields
      && this.extra.mappedFields[provider]
      && this.extra.mappedFields[provider].id
    );
  }

  getMappedField(provider) {
    const fieldId = this.getMappedFieldId(provider);
    return this.mappingFieldsService.getMappingField(provider, fieldId);
  }

  get isDefault() {
    return Object.values(Provider).some(provider => {
      const mappingField = this.getMappedField(provider);
      return mappingField && mappingField.required;
    });
  }
}
