import * as fields from 'utils/fields';
import Model from 'utils/models';
import { QuestionType } from './ContactFormQuestion';


export const CRMEntity = Object.freeze({
  CONTACT: 'contact',
  COMPANY: 'company',
  DEAL: 'deal',
});

export const Provider = Object.freeze({
  VECTERA: 'vectera',
  TEAMLEADER: 'teamleader',
});


export class MappingField extends Model {
  static get fields() {
    return {
      id: new fields.StringField(),
      entity: new fields.ChoiceField({
        choices: Object.values(CRMEntity),
      }),
      name: new fields.StringField(),
      required: new fields.BooleanField(),
      group: new fields.StringField(),
      isFallback: new fields.BooleanField(),
      allowFree: new fields.BooleanField(),
      extra: new fields.JSONField({
        default: () => {},
      }),
      questionTypes: new fields.ChoiceField({
        choices: Object.values(QuestionType),
        many: true,
      }),
    };
  }

  get hasPresetQuestionConfig() {
    return this.extra.options != null || this.extra.currency != null;
  }
}



export class AllMappingFields extends Model {
  static get fields() {
    const f = {};
    Object.values(Provider).forEach(crm => {
      f[crm] = new fields.RelatedField({
        Model: MappingField,
        many: true,
      });
    });
    return f;
  }

  static readPath() {
    return 'mappingFields';
  }
}
