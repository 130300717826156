import {
  Component,
  forwardRef,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Memoize } from 'typescript-memoize';
import { array, bind, debounce } from 'utils/util';
import { ComboBoxInput } from '../combo-box.component';
import { SimpleControlValueAccessor } from '../../../simple-control-value-accessor';


/**
 * This is a separate class because we don't want each instance of FontPickerComponent to have to
 * fetch the list of fonts separately.
 */
class FontFetcher {
  @Memoize()
  async fetch(): Promise<string[]> {
    const baseUrl = 'https://www.googleapis.com/webfonts/v1/webfonts';
    const params = {
      key: ANGULAR_SCOPE.googleApiKeyFontPicker,
      sort: 'popularity',
    };
    const url = `${baseUrl}?${new URLSearchParams(params)}`;
    const response = await fetch(url);
    if(!response.ok) {
      return [];
    } else {
      const json = await response.json();
      return json.items.map(item => item.family);
    }
  }
}
const fontFetcher = new FontFetcher();


@Component({
  selector: 'font-picker',
  templateUrl: './font-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FontPickerComponent),
      multi: true,
    },
  ],
})
export class FontPickerComponent extends SimpleControlValueAccessor<string | null> {

  @Input() placeholder = $localize `Select…`;

  public options: ComboBoxInput = [];
  private query = '';
  private updateOptionsDebounced: () => void;

  constructor() {
    super();
    bind(this);
    this.updateOptionsDebounced = debounce(this.updateOptions, 200);
  }


  override writeValue(value: string | null) {
    if(value && this.options.length === 0) {
      this.options = [value];
    }
    super.writeValue(value);
    this.updateOptions();
  }


  onQueryChange(query: string) {
    this.query = query;
    this.updateOptionsDebounced();
  }


  private async updateOptions() {
    const fonts = await fontFetcher.fetch();

    if(fonts.length === 0) {
      this.options = [{
        label: $localize `Failed to load fonts. Please reload the page.`,
        value: new Error(),
        disabled: true,
      }];

    } else {
      let options = [...fonts];

      // Move the selected option to the top of the list.
      if(this.value && options.includes(this.value)) {
        array.remove(options, this.value);
        options.splice(0, 0, this.value);
      }

      // Filter by query
      if(this.query) {
        options = options
          .filter(option => option.toLowerCase().includes(this.query.toLowerCase()));
      }

      this.options = options;

      // Limit the number of options to avoid performance issues. Ideally this would be solved
      // inside ComboBox, but that's out of scope for now.
      if(this.options.length > 20) {
        this.options = this.options.slice(0, 20);
        this.options.push({
          label: $localize `Type to find more fonts…`,
          value: null,
          disabled: true,
        });
      }
    }
  }
}
