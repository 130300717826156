import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon';
import { FetchErrorComponent } from './fetch-error.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule
  ],
  declarations: [
    FetchErrorComponent,
  ],
  exports: [
    FetchErrorComponent,
  ],
})
export class FetchErrorModule {}
