import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { HubSpotService, HubSpotContact } from 'integrations/hubspot/hubspot.service';
import { LocalStateService } from 'utils/state.service';
import { UrlService } from 'utils/url.service';
import { errors } from 'utils/util';
import { StreamType } from  'meeting/meeting-room/stream';
import { RequestUserService } from 'utils/requestUser.service';

@Component({
  selector: 'hubspot-widget',
  styleUrls: ['./hubspot-widget.component.scss'],
  templateUrl: './hubspot-widget.component.html',
})
export class HubSpotWidgetComponent {
  isOpen = false;
  public contactEmail = '';
  public error = '';
  public hubSpotContact: HubSpotContact | null = null;

  public connectedPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
    offsetX: -10,
  };

  constructor(
    @Inject('streamService') private streamService,

    public hubSpotService: HubSpotService,
    public urlService: UrlService,
    public stateService: LocalStateService,
    public requestUserService: RequestUserService,
  ) {
    this.stateService.setState(this.stateService.State.READY);
    this.streamService.on('add', this.onStreamAdd.bind(this));
  }

  fetchHubSpotContact() {
    this.error = '';
    if(this.contactEmail === '') {
      this.error = $localize `Please enter a valid email address.`;
      return;
    }
    this.stateService.setState(this.stateService.State.LOADING);
    this.hubSpotService.getContactData(this.contactEmail)
      .then(hubSpotContact => this.hubSpotContact = hubSpotContact)
      .catch(error => this.handleFetchContactError(error))
      .finally(() =>  this.stateService.setState(this.stateService.State.READY));
  }

  handleFetchContactError(error) {
    if(error.constructor === errors.DoesNotExistError) {
      this.error = $localize `We could not find a contact for that email address.`;
    } else if(error.constructor === errors.UnauthorizedError) {
      this.hubSpotService.setExpired(true);
    } else {
      this.error = $localize `Something went wrong fetching the contact.`;
    }
  }

  clearContactData() {
    this.contactEmail = '';
    this.hubSpotContact = null;
    this.hubSpotService.clearContactData();
    this.error = '';
  }

  toggleOverlay() {
    this.isOpen = !this.isOpen;
  }

  private onStreamAdd(stream) {
    if(stream.type === StreamType.SCREEN && stream.user.isMe) {
      this.isOpen = false;
    }
  }
}
