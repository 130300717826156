<div
  *ngFor="let groupedSession of groupedSessions | keyvalue; trackBy:trackById"
  class="waiting-room__card"
  (click)="handleKnockerCardClick(groupedSession.value)"
>
  <div class="d-flex align-items-center">
    <div [innerHTML]="getKnockerText(groupedSession.value)"></div>
  </div>
  <div class="waiting-room__actions">
    <div
      (click)="handleKnockerCardClick(groupedSession.value)"
      class="btn btn--primary mr-2"
      [stopPropagation]="'click'"
    >
      <svg-icon class="btn__icon" [svgName]="'videocam'"></svg-icon>
      <span i18n>Join</span>
    </div>
    <div
      class="btn btn--secondary mr-2"
      (click)="denyAccess(groupedSession.value)"
      [stopPropagation]="'click'"
      i18n
    >
      Can't join
    </div>

    <div
      class="btn btn--square"
      [cdkMenuTriggerFor]="actions"
      [cdkMenuTriggerData]="{ waitingRoomSessions: groupedSession.value }"
      [stopPropagation]="'click'"
    >
      <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
    </div>
  </div>
</div>



<ng-template #actions let-waitingRoomSessions="waitingRoomSessions">
  <div class="dropdown" cdkMenu>
    <!-- Change meeting -->
    <div
      [cdkMenuTriggerFor]="changeMeeting"
      [cdkMenuTriggerData]="{ 'waitingRoomSessions' : waitingRoomSessions}"
      class="dropdown__list-item"
      [stopPropagation]="'click'"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'videocam'"></svg-icon>
      <span i18n>Change meeting room</span>
    </div>
  </div>
</ng-template>


<ng-template #changeMeeting let-waitingRoomSessions="waitingRoomSessions">
  <meeting-rooms-menu
    #meetingRoomsMenu
    [data]="{waitingRoomSessions: waitingRoomSessions}"
    [scope]="this.scope"
  ></meeting-rooms-menu>
<ng-template>
