<div class="container pp" data-testid="meetingRoomElement">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

    <h1 class="page-title d-flex align-items-center">
      <span i18n class="mr-3">Meeting rooms</span>
      <svg-icon
        class="icon clickable mr-3"
        [svgName]="'info_badged'"
        *ngIf="!shouldShowHint"
        (click)="shouldShowHint = true"
        matTooltip="Click for more info"
        i18n-matTooltip
      ></svg-icon>
    </h1>

    <div class="card pp" *ngIf="shouldShowHint">
      <div>
        <p>
          <span i18n>
            Welcome your guests to your waiting room. You have a personal and a team waiting room. All visitors will be shown here. Transfer guests to an existing meeting room or a new meeting room (based on a template).
          </span>
          <a [attr.href]="'waitingRooms'|helpArticle" target="_blank" i18n>Best practices and more info</a>.
        </p>

        <p>
          <span i18n>Use your meeting rooms for collaboration with your customers and colleagues. Share your screen, try co-browsing, or collaborate using whiteboards.</span>
        </p>

        <p>
          <span i18n>Tip: Allow Sound, Notifications and Pop-up windows in the browser settings to notice knockers when your dashboard isn't the active page on your screen.</span>
          <a [attr.href]="'waitingRoomBrowserSettings'|helpArticle" target="_blank" i18n>Learn more</a>
        </p>
      </div>

      <div
        class="btn btn--secondary"
        (click)="shouldShowHint = false"
        i18n
      >
        Got it!
      </div>
    </div>


  <div class="d-flex justify-content-between align-items-center mb-10">
    <div class="d-flex flex-row align-items-center">
      <scope-switcher
        [scope]="scope"
        [scopes]="scopes"
        (scopeChange)="onScopeChange($event)"
      ></scope-switcher>
    </div>
    <div class="d-none d-md-flex align-items-center">
      <div
        class="btn btn--primary"
        (click)="openCreateMRModal()"
        data-testid="createMeetingRoomButton"
      >
        <svg-icon class="btn__icon" [svgName]="'videocam'"></svg-icon>
        <span i18n>
          Create a meeting room
        </span>
      </div>
    </div>
  </div>

  <h2 class="d-flex align-items-center justify-content-between">
    <div i18n>Waiting room</div>

    <div class="d-flex">
      <div
        class="btn d-none d-md-flex mr-2"
        [copyText]="urlService.urls.personalWaitingRoom"
      >
        <svg-icon class="btn__icon" [svgName]="'link'"></svg-icon>
        <span i18n>
          Personal waiting room
        </span>
      </div>
      <div
        *ngIf="requestUserService.user.organization.teamWaitingRoomEnabled"
        class="btn d-none d-md-flex mr-2"
        [copyText]="urlService.urls.teamWaitingRoom"
      >
        <svg-icon class="btn__icon" [svgName]="'link'"></svg-icon>
        <span i18n>
          Team waiting room
        </span>
      </div>
      <a
        *ngIf="!requestUserService.user.organization.teamWaitingRoomEnabled && !requestUserService.user.organization.hasOneActiveMember && requestUserService.user.isAdmin"
        class="btn d-none d-md-flex mr-2"
        [href]="urlService.urls.teamSettings"
      >
        <svg-icon class="btn__icon" [svgName]="'settings'"></svg-icon>
        <span i18n>
          Set up your team waiting room
        </span>
      </a>

      <waiting-room-notification-button></waiting-room-notification-button>
    </div>
  </h2>

  <waiting-room-notification-warning></waiting-room-notification-warning>

  <div class="card card--sm bg-neutral-200" *ngIf="waitingRoomSessionsService.knockingSessionsList.length ===0" i18n>
    Guests will show up here when they land on your meeting rooms or waiting room.
  </div>

  <waiting-room-card></waiting-room-card>

  <h2 class="mt-6" i18n>Meeting rooms</h2>

  <div class="d-flex mb-10 mt-10">
    <div class="d-flex flex-1 position-relative">
      <input
        class="input input--with-icon"
        [ngModel]="searchQuery"
        (ngModelChange)="setQuery($event)"
        placeholder="Search meeting rooms"
        i18n-placeholder
        cdkFocusInitial
      />
      <svg-icon class="input__icon" [svgName]="'search'"></svg-icon>
    </div>
    <div
      class="d-flex input-group ml-2"
    >
      <div
        class="btn"
        [class.btn--teal]="selectedLayout === Layout.GRID"
        [class.btn--secondary]="selectedLayout === Layout.LIST"
        (click)="selectedLayout = Layout.GRID"
        data-testid="gridViewButton"
      >
        <svg-icon class="icon" [svgName]="'overview'"></svg-icon>
      </div>
      <div
        class="btn"
        [class.btn--teal]="selectedLayout === Layout.LIST"
        [class.btn--secondary]="selectedLayout === Layout.GRID"
        (click)="selectedLayout = Layout.LIST"
        data-testid="listViewButton"
      >
        <svg-icon class="icon" [svgName]="'list'"></svg-icon>
      </div>
    </div>
  </div>

  <fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>

  <div *ngIf="!fetchError">
    <div *ngIf="noResultsMessage" class="card card--sm bg-neutral-200">
      {{ noResultsMessage }}
    </div>

    <div *ngIf="meetingRooms.length > 0">
      <div *ngIf="selectedLayout === Layout.GRID" class="mb-8">
        <div class="card-grid card-grid--sm" data-testid="meetingRoomGrid">
          <a
            [href]="meetingRoom.url"
            *ngFor="let meetingRoom of meetingRooms; trackBy: trackByMeetingRoom"
            class="card-grid__card card-grid__card--clickable d-flex flex-column"
            [ngClass]="{ 'bg-neutral-200': meetingRoom.isDeactivated }"
            data-testid="gridMeetingRoom"
          >
            <div class="d-flex flex-row justify-content-between align-items-center">
              <span class="text-truncate d-flex flex-row align-items-center">
                <b class="text-truncate" data-testid="cardMeetingRoomTitle">{{ meetingRoom.key}}</b>
                <svg-icon
                  *ngIf="adminPulseService.isConnected && meetingRoom.adminpulseRelation && adminPulseRelationIsPresent(meetingRoom.adminpulseRelation)"
                  class="meeting-rooms__integration-logo"
                  svgPath="integrations__adminpulse_logo"
                  [matTooltip]="getAdminPulseLabelFromId(meetingRoom.adminpulseRelation)"
                  data-testid="adminPulseMeetingRoomCardLogo"
                >
                </svg-icon>
              </span>
              <div
                preventDefault
                class="btn btn--square"
                [cdkMenuTriggerFor]="meetingRoomActions"
                [cdkMenuTriggerData]="{ meetingRoom: meetingRoom }"
                [cdkMenuPosition]="[{ originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top' }]"
                data-testid="actionButton"
              >
                <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
              </div>
            </div>
            <div
              *ngIf="meetingRoom.isDeactivated"
              class="badge badge--sm badge--error mb-2 align-self-start"
              [matTooltip]="deactivatedUpgradeText"
              i18n
            >
              Deactivated
            </div>

            <div
              *ngIf="meetingRoom.isOpened"
              class="d-flex justify-content-between align-items-center mt-1"
            >
              <span i18n>Currently opened</span>
              <profile-images [users]="meetingRoom.activeUsers" [maxBubbles]="4"></profile-images>
            </div>
            <p *ngIf="!meetingRoom.isOpened">
              <span *ngIf="!meetingRoom.openedUntil" i18n>Never opened</span>
              <span *ngIf="meetingRoom.openedUntil" matTooltip="{{ meetingRoom.openedUntil | readableTimestamp }}" i18n>Last opened {{ meetingRoom.openedUntil | timeSince }}</span>
            </p>

            <div class="meeting-rooms__snapshot-wrapper">
              <img
                *ngIf="meetingRoom.snapshot"
                src="{{ meetingRoom.snapshot }}"
                class="meeting-rooms__snapshot"
              />
              <div
                *ngIf="!meetingRoom.snapshot"
                class="meeting-rooms__snapshot meeting-rooms__snapshot--placeholder"
              >
                <svg-icon [svgName]="'document'" class="h-full"></svg-icon>
              </div>
            </div>
            <div class="flex-1 text-sm text-break">
              {{ meetingRoom.snapshotName }}
            </div>
          </a>
        </div>
      </div>

      <div *ngIf="selectedLayout === Layout.LIST" class="table__wrapper mb-8">
        <table class="table align-middle">
          <thead>
            <tr>
              <th i18n>Meeting key</th>
              <th class="d-none d-sm-table-cell" i18n>Host</th>
              <th class="d-none d-sm-table-cell" i18n>Last activity</th>
              <th class="d-none d-md-table-cell" i18n>Size</th>
              <th></th>
            </tr>
          </thead>

          <tbody class="border-none" data-testid="meetingRoomTable">
            <tr *ngFor="let meetingRoom of meetingRooms; trackBy: trackByMeetingRoom" data-testid="listMeetingRoom">
              <td>
                <div class="d-flex align-items-center">
                  <a [href]="meetingRoom.url" [ngClass]="{ 'text-muted': meetingRoom.isDeactivated }">
                    <span class="d-flex flex-row align-items-center">
                      <span class="text-truncate" data-testid="listMeetingRoomTitle">{{ meetingRoom.key}}</span>
                      <svg-icon
                        *ngIf="adminPulseService.isConnected && meetingRoom.adminpulseRelation && adminPulseRelationIsPresent(meetingRoom.adminpulseRelation)"
                        class="meeting-rooms__integration-logo"
                        svgPath="integrations__adminpulse_logo"
                        [matTooltip]="getAdminPulseLabelFromId(meetingRoom.adminpulseRelation)"
                        data-testid="adminPulseMeetingRoomRowLogo"
                      >
                      </svg-icon>
                    </span>
                  </a>
                  <div *ngIf="meetingRoom.isDeactivated" class=" ml-2">
                    <span
                      class="badge badge--sm badge--error"
                      [matTooltip]="deactivatedUpgradeText"
                      i18n
                    >
                      Deactivated
                    </span>
                  </div>
                </div>
              </td>

              <td class="d-none d-sm-table-cell">
                <div class="d-flex align-items-center">
                  <profile-image class="mr-2" [user]="meetingRoom.owner"></profile-image>
                  {{ meetingRoom.owner.fullName }}
                </div>
              </td>

              <td class="d-none d-sm-table-cell">
                <div *ngIf="meetingRoom.isOpened" class="d-flex">
                  <profile-images [users]="meetingRoom.activeUsers" [maxBubbles]="4"></profile-images>
                </div>
                <span *ngIf="!meetingRoom.isOpened && !meetingRoom.openedUntil" i18n>Never opened</span>
                <span *ngIf="!meetingRoom.isOpened && meetingRoom.openedUntil" matTooltip="{{ meetingRoom.openedUntil | readableTimestamp }}">{{ meetingRoom.openedUntil | timeSince | capitalize }}</span>
              </td>

              <td class="d-none d-md-table-cell">
                {{ meetingRoom.size | fileSize }}
              </td>

              <td>
                <div
                  class="btn btn--square"
                  [cdkMenuTriggerFor]="meetingRoomActions"
                  [cdkMenuTriggerData]="{ meetingRoom: meetingRoom }"
                  [cdkMenuPosition]="[{ originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top' }]"
                  data-testid="actionButton"
                >
                  <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-center">
        <pagination
          [page]="page"
          [numPages]="numPages"
          (pageChange)="onPageChange($event)"
        ></pagination>
      </div>
    </div>
  </div>
<div>

<ng-template #meetingRoomActions let-meetingRoom="meetingRoom">
  <div class="dropdown" cdkMenu>
    <div
      cdkMenuItem
      [copyText]="meetingRoom.url"
      class="dropdown__list-item"
    >
      <span i18n>Copy link</span>
    </div>
    <div
      *ngIf="adminPulseService.isConnected"
      cdkMenuItem
      class="dropdown__list-item"
      (click)="selectedAdminPulseOption = meetingRoom.adminpulseRelation"
      [modalTriggerFor]="linkAdminPulse"
      [modalIcon]="{path: 'integrations__adminpulse_logo'}"
      [modalClass]="'modal--sm'"
      modalTitle="Link AdminPulse Relation"
      i18n-modalTitle
      data-testid="manageAdminPulseAction"
    >
      {{ getManageAdminPulseText(meetingRoom) }}
      <ng-template #linkAdminPulse>
        <div
          *ngIf="shouldShowAccessLevelOverrideWarning(meetingRoom)"
          class="field field--warning mb-5"
          i18n
        >
          <field-icon [type]="'warning'"></field-icon>
          <span>Linking an AdminPulse relation to this meeting room will overwrite the access level of your team members so that they can always join this meeting room as a host.</span>
        </div>

        <div
          class="form-group mb-4"
          data-testid="linkToAdminPulseRelationForm"
        >
          <label i18n>Link to an AdminPulse relation</label>
          <combo-box
            [clearable]="true"
            [options]="adminPulseOptions"
            [(ngModel)]="selectedAdminPulseOption"
            data-testid="linkToAdminPulseRelationCombobox"
          ></combo-box>
        </div>
        <div class="d-flex justify-content-end">
          <div
            #closeButton
            class="btn btn--primary mr-2"
            (click)="updateAdminPulseLink(meetingRoom, closeButton)"
            data-testid="adminPulseRelationUpdateButton"
            i18n
          >
            Update
          </div>
        </div>
      </ng-template>
    </div>
    <div
      cdkMenuItem
      class="dropdown__list-item text-error-400"
      dialog="Are you sure you want to delete the meeting room with key {{meetingRoom.key}}?"
      i18n-dialog
      dialogTitle="Delete meeting room"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'trash' }"
      dialogConfirmText="Delete"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--error"
      [dialogConfirmedCallback]="delete.bind(this, meetingRoom)"
      data-testid="deleteMeetingRoomButton"
      i18n
    >
      Delete meeting room
    </div>
  </div>
</ng-template>

<ng-template #addRoom>
  <add-room-modal
    (meetingRoomCreated)="onMeetingRoomCreated($event)"
    [initMeetingKey]="withKey"
    [initMeetingTemplate]="withTemplateId"
    [initAdminPulseRelation]="withAdminPulseRelationId"
    [adminPulseOptions]="adminPulseOptions"
  ></add-room-modal>
</ng-template>
