<div class="position-relative d-flex align-items-center">
  <input
    class="multi-select-dropdown"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (focus)="isOpen = true"
    [placeholder]="placeholder"
    [disabled]="disabled"
    value="{{ value.length }} selected"
  >
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayInheritWidth
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayDisableClose]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  (backdropClick)="isOpen = false"
>
  <div class="dropdown dropdown--scrollable" style="max-height: 500px">
    <!--
      When an option is clicked, this typically causes the input element to be blurred.
      When this happens, no "click" event is fired on the option, so we need to listen
      on mouseup instead.
    -->
    <div
      *ngFor="let option of options"
      (mouseup)="pickOption(option)"
    >
      <div
        class="dropdown__list-item"
        [class.disabled]="getOptionDisabled(option)"
      >
        <svg-icon
          class="dropdown__list-icon dropdown__list-icon--sm"
          [svgName]=" isSelected(option) ? 'checkmark' : ''"
        ></svg-icon>
        <span>{{ getOptionLabel(option) }}</span>
      </div>
    </div>
  </div>
</ng-template>
