import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TimeDelta } from 'utils/util';
import { AppointmentLocation } from '../../../models/appointment-location';
import { AppointmentType } from '../../../services/appointment-types.service';

@Component({
  selector: 'appointment-type-card',
  templateUrl: './appointment-type-card.component.html',
  styleUrls: ['./appointment-type-card.component.scss']
})
export class AppointmentTypeCardComponent {
  @Input() appointmentType!: AppointmentType;

  @ViewChild('descriptionElement') descriptionElement!: ElementRef;

  constructor() { }

  readonly AppointmentLocation = AppointmentLocation;

  get name(): string {
    return this.appointmentType.name;
  }

  get description(): string {
    return this.appointmentType.description;
  }

  get duration(): TimeDelta {
    return this.appointmentType.duration;
  }

  get paymentAmount(): number {
    return this.appointmentType.paymentAmount;
  }

  get paymentCurrency(): string {
    return this.appointmentType.paymentCurrency;
  }

  isOverflown(): boolean {
    if(this.descriptionElement) {
      const scrollHeight = this.descriptionElement.nativeElement.scrollHeight;
      const clientHeight = this.descriptionElement.nativeElement.clientHeight;

      return scrollHeight > clientHeight;
    }
    return false;
  }

  appointmentIs(location: AppointmentLocation): boolean {
    return this.appointmentType.locations.includes(location);
  }
}
