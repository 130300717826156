import { getQueryParameter } from 'utils/util';
import SocketService from 'meeting/angularjs/shared/communication/socket.service';
import { ConnectionType } from 'meeting/angularjs/shared/communication/socket.service';

export default class MeetingSocketService extends SocketService {

  static get $inject() {
    return [
      'meetingService',
      'userService',
    ].concat(SocketService.$inject);
  }

  constructor(
    meetingService,
    userService,
    ...args
  ) {
    super(...args);

    this.meetingService = meetingService;
    this.userService = userService;
  }

  _getWsSearchParms() {
    let searchParams = new URLSearchParams();
    searchParams.set('type', ConnectionType.MEETING);
    searchParams.set('meetingId', window.MEETING.id);
    if(this.userService.mySession.isInitialized) {
      searchParams.set('sessionId', this.userService.mySession.id);
    }

    let accessKey = getQueryParameter('accessKey');
    if(accessKey) {
      searchParams.set('accessKey', accessKey);
    }

    let extraInfo = {};
    let bednetLocation = this.meetingService.bednetLocation;
    if(bednetLocation) {
      extraInfo.bednetLocation = bednetLocation;
    }
    searchParams.set('extraInfo', JSON.stringify(extraInfo));
    return searchParams;
  }
}
