import UserTileBin from './UserTileBin';
import { platform } from 'utils/util';


export default class UserBinArray {
  constructor(rect, spacing) {
    this.bins = [];
    this.rect = rect.clone();
    this.spacing = spacing;

    this._tileHeightCached = null;
  }


  get width() {
    let maxWidth = 0;
    this.bins.forEach(bin => maxWidth = Math.max(maxWidth, bin.width));
    return maxWidth;
  }

  get height() {
    if(this.bins.length === 0) {
      return 0;
    } else {
      return (this.tileHeight + this.spacing) * this.bins.length - this.spacing;
    }
  }

  get tileHeight() {
    if(!this._tileHeightCached) {
      this._cacheTileHeight();
    }
    return this._tileHeightCached;
  }


  _cacheTileHeight() {
    // Tile height if constrained by available height
    let tileHeight = (this.rect.height + this.spacing) / this.bins.length - this.spacing;

    // For each bin: check if the bin height is constrained by the available width
    this.bins.forEach(bin => {
      let binHeight = (this.rect.width - (bin.tiles.length - 1) * this.spacing) / bin.aspectRatio;

      if(binHeight < tileHeight) {
        tileHeight = binHeight;
      }
    });

    this._tileHeightCached = tileHeight;
  }


  addBin(tiles, position) {
    this._tileHeightCached = null;

    let bin = new UserTileBin(tiles, this);
    if(position == null) {
      this.bins.push(bin);
    } else {
      this.bins.splice(position, 0, bin);
    }
  }


  draw() {
    let top  = this.rect.top  + platform(0, (this.rect.height - this.height) / 2);
    this.bins.forEach(bin => {
      let left = this.rect.left + platform(0, (this.rect.width  - bin.width) / 2);
      bin.draw(top, left);
      top += this.tileHeight + this.spacing;
    });
  }
}
