<div
  *ngIf="!shouldShowCustom"
  class="duration-picker__main-row"
>
  <div class="mr-2 mb-2">
    <segmented-button
      [options]="durationOptions"
      [ngModel]="numberValue"
      (ngModelChange)="pickOption($event)"
      [disabled]="disabled"
    ></segmented-button>
  </div>

  <div
    *ngIf="durationAllowCustom && !disabled"
    class="mr-2 mb-2 btn btn--link"
    (click)="showCustom()"
    data-testid="customDurationButton"
    i18n
  >
    Custom...
  </div>
</div>

<div *ngIf="shouldShowCustom">
  <div class="d-flex align-items-center">
    <input
      #customValue
      type="number"
      class="input input--duration-picker"
      [ngModel]="numberValue"
      (ngModelChange)="setCustomValue($event)"
      (blur)="onCustomValueBlur()"
      (keyup.enter)="onCustomValueEnter()"
      [disabled]="disabled"
      data-testid="customDurationInput"
    >
    <div class="ml-2">{{ unitName }}</div>
  </div>
</div>
