<div class="money-input">
  <input
    #input
    class="money-input__input text-teal-500"
    [ngModel]="value"
    (ngModelChange)="writeValue($event)"
    type="number"
    step="any"
  />

  <div class="money-input__currency">{{ question.extra.currency }}</div>
</div>
