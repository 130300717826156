import template from './header.html?raw';
import templateDropdownProfile from './profile.dropdown.html?raw';
import templateDropdownLanguage from './language.dropdown.html?raw';
import templateDropdownKnockers from './knockers.dropdown.html?raw';
import templateModalDisplayName from './displayName.modal.html?raw';
import templateModalShortcuts from './shortcuts.modal.html?raw';
import templateModalFeedback from './feedback.modal.html?raw';

import { logger, array, browser } from 'utils/util';
import KnockerTile from '../../../components/knockers/KnockerTile';
import Dropdown from 'utils/angularjs/dropdown/Dropdown';

const DROPDOWN_ID_PROFILE = 'profile';
const DROPDOWN_ID_LANGUAGE = 'language';
const DROPDOWN_ID_KNOCKERS = 'knockers';
export const MODAL_ID_DISPLAY_NAME = 'displayName';
const MODAL_ID_KEYBOARD_SHORTCUTS = 'keyboardShortcuts';
const MODAL_ID_FEEDBACK = 'feedback';

const LOGS_SHORTCUT = 'alt+l';
const SHORTCUTS_OVERVIEW_SHORTCUTS = 'alt+k';

let shouldShowExportLogs = false;


export class HeaderController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      'shortcutService',
      'userService',
      'meetingService',
      'languageService',
      'feedbackService',
      'dropdownService',
      'modalService',
      'tutorialService',
      'browserService',
      'knockerService',
      'fullscreenService',
      'raiseHandService',
      'notificationService',
      'requestUserService',
      'siteService',
    ];
  }

  constructor(
    $elem,
    $scope,
    shortcutService,
    userService,
    meetingService,
    languageService,
    feedbackService,
    dropdownService,
    modalService,
    tutorialService,
    browserService,
    knockerService,
    fullscreenService,
    raiseHandService,
    notificationService,
    requestUserService,
    siteService
  ) {
    this._bind();


    dropdownService.register(
      DROPDOWN_ID_PROFILE,
      templateDropdownProfile,
      {
        cssClasses: 'dropdown-deprecated--no-padding-x',
        align: Dropdown.Align.END,
        embeds: [DROPDOWN_ID_LANGUAGE],
      }
    );
    dropdownService.register(
      DROPDOWN_ID_LANGUAGE,
      templateDropdownLanguage,
      { cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x' }
    );
    dropdownService.register(
      DROPDOWN_ID_KNOCKERS,
      templateDropdownKnockers,
      { cssClasses: 'dropdown-deprecated--no-padding-x' }
    );
    modalService.register(
      MODAL_ID_DISPLAY_NAME,
      gettext('Change your name'),
      'utils/icons/tl/24x24_user_outline.svg',
      templateModalDisplayName
    );
    modalService.register(
      MODAL_ID_KEYBOARD_SHORTCUTS,
      gettext('Keyboard shortcuts'),
      'utils/icons/tl/24x24_keyboard_outline.svg',
      templateModalShortcuts
    );
    modalService.register(
      MODAL_ID_FEEDBACK,
      gettext('Feedback'),
      'utils/icons/tl/24x24_message_feedback_outline.svg',
      templateModalFeedback
    );

    this.$elem = $elem;
    this.$scope = $scope;
    this.shortcutService = shortcutService;
    this.userService = userService;
    this.meetingService = meetingService;
    this.languageService = languageService;
    this.feedbackService = feedbackService;
    this.dropdownService = dropdownService;
    this.modalService = modalService;
    this.tutorialService = tutorialService;
    this.browserService = browserService;
    this.knockerService = knockerService;
    this.fullscreenService = fullscreenService;
    this.raiseHandService = raiseHandService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;
    this.siteService = siteService;

    this.displayName = this.userService.me.fullName;
    this.setDisplayNameError = null;

    this.showLogCounter = 0;
    this.showLogStarted = 0;

    /* eslint-disable max-len */
    this.shortcuts = {
      '': [
        [gettextCatalog.getString('Toggle this overview'),                    'utils/icons/tl/24x24_keyboard_outline.svg',      this.shortcutService.getPrettyParts('alt+k')],
      ]
    };
    this.shortcuts[gettextCatalog.getString('Communication')] = [
      [gettextCatalog.getString('Turn your microphone on and off'),           'utils/icons/tl/24x24_mic_filled.svg',            this.shortcutService.getPrettyParts('alt+a')],
      [gettextCatalog.getString('Turn your camera on and off'),               'utils/icons/tl/24x24_videocam_outline.svg',      this.shortcutService.getPrettyParts('alt+v')],
      [gettextCatalog.getString('Show the chat window'),                      'utils/icons/tl/24x24_message_text_outline.svg',  this.shortcutService.getPrettyParts('alt+c')],
      [gettextCatalog.getString('Pin the other participants to your screen'), 'utils/icons/tl/24x24_branding_outline.svg',      this.shortcutService.getPrettyParts('alt+x'), browser.supportsPictureInPicture()],
      [gettextCatalog.getString('Raise your hand'),                           'utils/icons/tl/24x24_front_hand_outline.svg',    this.shortcutService.getPrettyParts('alt+h')],
    ];
    this.shortcuts[gettextCatalog.getString('Whiteboard')] = [
      [gettextCatalog.getString('Select and move objects'),                   'utils/icons/tl/24x24_cursor_outline.svg',        this.shortcutService.getPrettyParts('alt+s')],
      [gettextCatalog.getString('Click and drag inside the whiteboard'),      'utils/icons/tl/24x24_arrow_all_outline.svg',     this.shortcutService.getPrettyParts('alt+d')],
      [gettextCatalog.getString('Eraser'),                                    'utils/icons/tl/24x24_eraser_outline.svg',        this.shortcutService.getPrettyParts('alt+e')],
      [gettextCatalog.getString('Pencil'),                                    'utils/icons/tl/24x24_edit_outline.svg',          this.shortcutService.getPrettyParts('alt+p')],
      [gettextCatalog.getString('Marker pen'),                                'utils/icons/tl/24x24_highlighter_outline.svg',   this.shortcutService.getPrettyParts('alt+m')],
      [gettextCatalog.getString('Geometric shapes'),                          'utils/icons/tl/24x24_geometric_outline.svg',     this.shortcutService.getPrettyParts('alt+g')],
      [gettextCatalog.getString('Text'),                                      'utils/icons/tl/24x24_text_title_outline.svg',    this.shortcutService.getPrettyParts('alt+t')],
      [gettextCatalog.getString('Formula'),                                   'utils/icons/tl/24x24_text_functions.svg',        this.shortcutService.getPrettyParts('alt+f')],
    ];


    if(this.meetingService.settings.allowVideoViewport) {
      this.shortcuts[gettextCatalog.getString('Camera')] = [
        [gettextCatalog.getString('Zoom In'),                                 'utils/icons/tl/24x24_zoom_in_outline.svg',       this.shortcutService.getPrettyParts('I')],
        [gettextCatalog.getString('Zoom Out'),                                'utils/icons/tl/24x24_zoom_out_outline.svg',      this.shortcutService.getPrettyParts('O')],
        [gettextCatalog.getString('Move the camera'),                         'utils/icons/tl/24x24_arrow_all_outline.svg',     ['←', '→', '↑', '↓']],
      ];
    }

    /* eslint-enable max-len */
  }

  _bind() {
    this._showExportLogs = this._showExportLogs.bind(this);
    this._hideExportLogs = this._hideExportLogs.bind(this);
    this.toggleShortcutsModal = this.toggleShortcutsModal.bind(this);
  }

  $onInit() {
    this.shortcutService.on(LOGS_SHORTCUT, this._showExportLogs);
    this.shortcutService.on(SHORTCUTS_OVERVIEW_SHORTCUTS, this.toggleShortcutsModal);
  }

  $onDestroy() {
    this.shortcutService.off(LOGS_SHORTCUT, this._showExportLogs);
    this.shortcutService.off(SHORTCUTS_OVERVIEW_SHORTCUTS, this.toggleShortcutsModal);
  }


  shouldShowItem(item) {
    let shouldShow = this._isEnabled(item);
    if(item === 'fullscreen') {
      shouldShow = shouldShow && this.fullscreenService.supportsFullscreen;
    }
    return shouldShow;
  }
  _isEnabled(item) {
    return this._items == null ? true : array.has(this._items, item);
  }


  get me() {
    return this.requestUserService.user;
  }

  get translucent() {
    return this._translucent == null ? false : this._translucent;
  }
  get shouldShowExportLogs() {
    return shouldShowExportLogs;
  }
  get shouldShowFeedback() {
    return this.feedbackService.shouldShowMenuItem;
  }

  get fullscreen() {
    return this.fullscreenService.fullscreen;
  }

  toggleFullscreen() {
    this.fullscreenService.toggleFullscreen();
  }


  toggleProfileDropdown($event) {
    this.dropdownService.toggle(DROPDOWN_ID_PROFILE, $event);

    let now = Date.now();
    if(now - this.showLogStarted > 5000) {
      this.showLogStarted = now;
      this.showLogCounter = 0;
    }

    this.showLogCounter++;
    if(this.showLogCounter === 5) {
      this._showExportLogs();
    }
  }

  toggleLanguageDropdown($event) {
    this.dropdownService.toggle(DROPDOWN_ID_LANGUAGE, $event);
  }

  toggleKnockersDropdown($event) {
    this.dropdownService.toggle(DROPDOWN_ID_KNOCKERS, $event);
  }

  showDisplayNameModal() {
    this.modalService.show(MODAL_ID_DISPLAY_NAME);
  }

  toggleShortcutsModal() {
    // Don't use this.modalService.toggle directly: this causes problems if there are multiple
    // instances of HeaderController.
    let modal = this.modalService.get(MODAL_ID_KEYBOARD_SHORTCUTS);
    let show = !modal.isShown;

    $timeout(() => {
      this.modalService.toggle(MODAL_ID_KEYBOARD_SHORTCUTS, show);
    });
  }

  showFeedback() {
    this.modalService.show(MODAL_ID_FEEDBACK);
  }

  sendFeedback() {
    this.feedbackService.send();
    this.modalService.hide(MODAL_ID_FEEDBACK);
    this.notificationService.info(gettextCatalog.getString('Thank you for your feedback!'));
  }


  get shouldShowReleaseNotes() {
    if(this.meetingService.isTemplate) {
      return false;
    }
    if(!this.siteService.site.isStandalone) {
      return false;
    }

    return !(
      this.me.isLazy
      && this.meetingService.settings.whitelabel.hasAddon
    );
  }

  get shouldShowReleaseNotesNotification() {
    return (
      this.shouldShowReleaseNotes
      && this.me.shouldShowReleaseNotesNotification
    );
  }

  openReleaseNotes() {
    window.open(window.URLS.releaseNotes, '_blank');
    this.me.shouldShowReleaseNotesNotification = false;
  }


  get snoozedKnockers() {
    return Object.values(this.knockerService.tiles)
      .filter(
        tile => tile.state === KnockerTile.State.KNOCKING
        && tile.snoozed
      );
  }



  setDisplayName() {
    this.userService.me.setFullName(this.displayName)
      .then(() => {
        this.modalService.hide(MODAL_ID_DISPLAY_NAME);
        this.setDisplayNameError = null;
      }, error => {
        this.setDisplayNameError = error.message ? error.message : error;
      });
  }

  _showExportLogs() {
    shouldShowExportLogs = true;
    $timeout(this._hideExportLogs, 15000);
  }

  _hideExportLogs() {
    shouldShowExportLogs = false;
  }

  exportLogs() {
    logger.download();
  }


  filterShortcut(shortcutInfo) {
    return shortcutInfo[3] == null || shortcutInfo[3];
  }
}


export default {
  controller: HeaderController,
  controllerAs: 'headerCtrl',
  template,

  bindings: {
    _items: '<items',
    _translucent: '<translucent',
  }
};
