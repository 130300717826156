export type Options = {
  name: string,
  shorthand: string,
  showNavigation: boolean,
  canGoBack: boolean,
  validateStep: () => boolean,
  validateSubmit: () => any,
}
const defaultOptions: Options = {
  name: '',
  shorthand: '',
  showNavigation: true,
  canGoBack: true,
  validateStep: () => true,
  validateSubmit: () => true,
};

export abstract class WizardStepComponent {
  handleNext(): Promise<any> {
    return Promise.resolve();
  }

  validate(): boolean {
    return true;
  }
}

/**
 * A WizardStep shows one panel of a Wizard.
 *
 * @param {String} [options.name]: the name of the current step. Not the same as wizard title.
 * @param {String} [options.shorthand]: A short name of the current step, to be used in the
 * progress bar
 * @param {Function} [options.validateStep]: the callback that will determine whether the user
 * can proceed to the next step. This function must return a boolean.
 * @param {Function} [options.validateSubmit]: the callback that is executed if the user proceeds
 * to the next step. If this returns a promise, proceeding to the next step only happens after
 * the Promise resolves.
 */
export default class WizardStep {
  private options: Options;

  constructor(options: Partial<Options>) {
    this.options = Object.assign({}, defaultOptions, options);
    if(options.shorthand == null) {
      this.options.shorthand = this.options.name;
    }
  }

  get name() {
    return this.options.name;
  }
  get shorthand() {
    return this.options.shorthand;
  }
  get shouldShowNavigation() {
    return this.options.showNavigation;
  }
  get canGoBack() {
    return this.options.canGoBack;
  }


  validate() {
    return this.options.validateStep();
  }

  next() {
    try {
      return this.options.validateSubmit();
    } catch(e) {
      console.warn(e);
    }
  }
}
