export default function BindStreamDirective() {
  return {
    restrict: 'A',
    scope: {
      bindStream: '<',
    },

    link: function($scope, $elem) {
      let stream;

      $elem.on('contextmenu', $event => {
        $event.preventDefault();
      });

      $scope.$watch('bindStream', update);
      $scope.$on('$destroy', unsetElem);
      update();

      function update() {
        if($scope.bindStream !== stream) {
          unsetElem();
          stream = $scope.bindStream;
          setElem();
        }
      }


      function setElem() {
        if(stream) {
          // upgrade note: we would like to step away from jquery, so unpack the native DOM element
          stream.setMediaElem($elem[0]);
        }
      }


      function unsetElem() {
        if(stream) {
          // upgrade note: we would like to step away from jquery, so unpack the native DOM element
          stream.unsetMediaElem($elem[0]);
        }
      }
    },
  };
}
