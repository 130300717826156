import { format } from 'utils/util';

let formats = [
  [1, '%ss'],
  [60, '%sm'],
  [60 * 60, '%sh'],
  [60 * 60 * 24, '%sd'],
];

/**
 * Transform a time in seconds into a readable duration format like "1h 5m 10s".
 * @param {number} input - The time in seconds.
 * @param {string} numParts - The number of parts the result string consist of.
 * @returns {string} A readable version of the original time, with numParts adjacent parts (if
 * possible), starting with the largest time value (days).
 */

export default function DurationFilter() {
  return function(input, numParts) {
    if(numParts == null) {
      numParts = 2;
    }
    if(input === 0) {
      return format(formats[0][1], 0);
    } else if(input < formats[0][0]) {
      return format(formats[0][1], 1);
    }

    let readableParts = [];
    let firstPartFound = false;
    for(let i = formats.length - 1; i >= 0; i--) {
      let [divider, timePortionStr] = formats[i];
      let quotient = Math.floor(input / divider);
      let remainder = input % divider;
      input = remainder;
      if(quotient === 0) {
        // Only return adjacent parts: e.g 2h 5m. Not 2h 5s. In the last case, return 2h
        if(firstPartFound) {
          break;
        } else {
          continue;
        }
      }

      readableParts.push(format(timePortionStr, quotient));
      firstPartFound = true;
      if(readableParts.length === numParts) {
        return readableParts.join(' ');
      }
    }
    return readableParts.join(' ');
  };
}
