import { format, errors, getPixelRatio, EventEmitter, Rect, logger, browser } from 'utils/util';


const fabric = window.fabric;
export default fabric;


// On Safari this doesn't work properly, so we force downloading Open Sans in meeting.html.
EventEmitter.setup(fabric, ['fontLoaded', 'selectionStyle']);
if(document.fonts && document.fonts.addEventListener) {
  document.fonts.addEventListener('loadingdone', () => {
    fabric.util.clearFabricFontCache();
    fabric.emit('fontLoaded');
  });
}


fabric.Canvas.prototype.preserveObjectStacking = true;
window.addEventListener('load', () => {
  if(window.URLS && window.URLS.images) {
    let rotationCursor = `url(${window.URLS.images.cursorRotate}) 14 14, crosshair`;
    fabric.Canvas.prototype.rotationCursor = rotationCursor;
  }
});

// Customize appearance
fabric.Object.prototype.borderColor =         '#74c5e6'; // lighten(#165c78, 40%)
fabric.Object.prototype.cornerColor =         '#74c5e6';
fabric.Object.prototype.editingBorderColor =  '#a0d7ed'; // lighten(#165c78, 50%)
fabric.Object.prototype.editingCornerColor =  '#a0d7ed';
fabric.Object.prototype.cornerSize =          12;
fabric.Object.prototype.padding =             5;
fabric.Object.prototype.rotatingPointOffset = 24;

fabric.IText.prototype.cursorWidth =          1;
// lighten(#165c78, 10%) + transparency
fabric.IText.prototype.selectionColor =       'rgba(30, 125, 163, .25)';
fabric.IText.prototype.cursorColor =          'black';

let origDrawControl = fabric.Object.prototype._drawControl;
fabric.Object.prototype._drawControl = function(control) {
  this.cornerStyle = control === 'mtr' ? 'circle' : 'rect';
  origDrawControl.apply(this, arguments);
};




fabric.convertOptionFromTools = function(toolsKey, toolsValue) {
  let fabricKey, fabricValue;
  switch(toolsKey) {
    case 'textSize':
      fabricKey = 'fontSize';
      fabricValue = toolsValue * fabric.fontScaling;
      break;
    case 'textColor':
      fabricKey = 'fill';
      fabricValue = toolsValue;
      break;
    case 'textBold':
      fabricKey = 'fontWeight';
      fabricValue = toolsValue ? 'bold' : 'normal';
      break;
    case 'textItalic':
      fabricKey = 'fontStyle';
      fabricValue = toolsValue ? 'italic' : 'normal';
      break;
    case 'textUnderline':
      fabricKey = 'textDecoration';
      fabricValue = toolsValue ? 'underline' : '';
      break;
    default:
      throw new errors.InvalidArgumentError(format('Unknown tools option:', toolsKey));
  }

  return [fabricKey, fabricValue];
};

fabric.convertOptionToTools = function(fabricKey, fabricValue) {
  let toolsKey, toolsValue;
  switch(fabricKey) {
    case 'fontSize':
      toolsKey = 'textSize';
      toolsValue = Math.round(fabricValue / fabric.fontScaling, 2);
      break;
    case 'fill':
      toolsKey = 'textColor';
      toolsValue = fabricValue;
      break;
    case 'fontWeight':
      toolsKey = 'textBold';
      toolsValue = fabricValue === 'bold';
      break;
    case 'fontStyle':
      toolsKey = 'textItalic';
      toolsValue = fabricValue === 'italic' || fabricValue === 'oblique';
      break;
    case 'textDecoration':
      toolsKey = 'textUnderline';
      toolsValue = fabricValue === 'underline';
      break;
    default:
      throw new errors.InvalidArgumentError(format('Unknown fabric option:', fabricKey));
  }

  return [toolsKey, toolsValue];
};



// Bugfix: paste from Word on Mac OS
fabric.IText.prototype.paste = function(e) {
  let copiedText = null;
  let style = null;
  let clipboardData = this._getClipboardData(e);

  // Check for backward compatibility with old browsers
  if(clipboardData) {
    copiedText = clipboardData.getData('text').replace(/\r\n?/g, '\n');
    for(let i = 0, len = copiedText.length; i < len; i++) {
      this.insertChar(copiedText[i], i < len - 1);
    }
  } else {
    copiedText = fabric.copiedText;
    for(let i = 0, len = copiedText.length; i < len; i++) {
      style = fabric.util.object.clone(fabric.copiedTextStyle[i], true);
      this.insertChar(copiedText[i], i < len - 1, style);
    }
  }

  e.stopImmediatePropagation();
  e.preventDefault();
};


// Optimization: run mousemove and touchmove events outside of the Angular event loop
let origInitEventListeners = fabric.Canvas.prototype._initEventListeners;
fabric.Canvas.prototype._initEventListeners = function() {
  origInitEventListeners.apply(this, arguments);

  fabric.util.removeListener(this.upperCanvasEl, 'mousemove', this._onMouseMove);
  fabric.util.removeListener(this.upperCanvasEl, 'touchmove', this._onMouseMove);
  window.__vecteraRunOutsideAngular(() => {
    fabric.util.addListener(this.upperCanvasEl, 'mousemove', this._onMouseMove);
    fabric.util.addListener(
      this.upperCanvasEl, 'touchmove', this._onMouseMove, { passive: false });
  });
};


fabric.Canvas.prototype._onMouseDown = function(e) {
  this.__onMouseDown(e);

  window.__vecteraRunOutsideAngular(() => {
    fabric.util.addListener(fabric.document, 'touchend', this._onMouseUp, { passive: false });
    fabric.util.addListener(fabric.document, 'touchmove', this._onMouseMove, { passive: false });

    fabric.util.removeListener(this.upperCanvasEl, 'mousemove', this._onMouseMove);
    fabric.util.removeListener(this.upperCanvasEl, 'touchmove', this._onMouseMove);

    if(e.type === 'touchstart') {
      // Unbind mousedown to prevent double triggers from touch devices
      fabric.util.removeListener(this.upperCanvasEl, 'mousedown', this._onMouseDown);
    } else {
      fabric.util.addListener(fabric.document, 'mouseup', this._onMouseUp);
      fabric.util.addListener(fabric.document, 'mousemove', this._onMouseMove);
    }
  });
};


fabric.Canvas.prototype._onMouseUp = function(e) {
  this.__onMouseUp(e);

  window.__vecteraRunOutsideAngular(() => {
    fabric.util.removeListener(fabric.document, 'mouseup', this._onMouseUp);
    fabric.util.removeListener(fabric.document, 'touchend', this._onMouseUp);

    fabric.util.removeListener(fabric.document, 'mousemove', this._onMouseMove);
    fabric.util.removeListener(fabric.document, 'touchmove', this._onMouseMove);

    fabric.util.addListener(this.upperCanvasEl, 'mousemove', this._onMouseMove);
    fabric.util.addListener(
      this.upperCanvasEl, 'touchmove', this._onMouseMove, { passive: false });
  });

  if(e.type === 'touchend') {
    // Wait 400ms before rebinding mousedown to prevent double triggers
    // from touch devices
    var _this = this;
    setTimeout(function() {
      fabric.util.addListener(_this.upperCanvasEl, 'mousedown', _this._onMouseDown);
    }, 400);
  }
};




// Bugfix: remove listeners added in _onMouseDown
let origRemoveListeners = fabric.Canvas.prototype.removeListeners;
fabric.Canvas.prototype.removeListeners = function() {
  origRemoveListeners.apply(this, arguments);
  fabric.util.removeListener(fabric.document, 'touchmove', this._onMouseMove);
  fabric.util.removeListener(fabric.document, 'touchend', this._onMouseUp);
  fabric.util.removeListener(fabric.document, 'mousemove', this._onMouseMove);
  fabric.util.removeListener(fabric.document, 'mouseup', this._onMouseUp);
};


fabric.straightenFrequency = 45;

fabric.Object.prototype._getAngleValueForStraighten = function() {
  let angle = this.getAngle() % 360;
  let straightenFrequency = fabric.straightenFrequency;

  if(angle > 0) {
    return Math.round((angle - 1) / straightenFrequency) * straightenFrequency;
  }

  return Math.round(angle / straightenFrequency) * straightenFrequency;
};





// Show editing user

function getTypingFontSize(zoom) {
  return 11 / Math.pow(zoom, 0.5);
}

fabric.Object.prototype._renderBackground = function(ctx) {
  if(!this.shape) {
    return;
  }

  let user = this.shape.editingUser;
  if(user) {
    let userColor = user.color || '#aaa';
    let colors = ['#ddd', '#eee'];

    let dim = this._getNonTransformedDimensions();
    let stepSize = 5 / dim.x;
    let i = 1;

    let grdSize = Math.max(dim.x, dim.y);
    let grd = ctx.createLinearGradient(
      -dim.x / 2,
      -dim.y / 2,
      -dim.x / 2 + grdSize,
      -dim.y / 2 + grdSize
    );
    while(i * stepSize < 1) {
      grd.addColorStop(i * stepSize, colors[i % 2]);
      grd.addColorStop(i * stepSize, colors[(i + 1) % 2]);
      i++;
    }

    ctx.save();
    ctx.fillStyle = grd;
    ctx.fillRect(
      -dim.x / 2,
      -dim.y / 2,
      dim.x,
      dim.y
    );

    ctx.strokeStyle = userColor;
    ctx.lineWidth = 0.5 / Math.pow(this.zoomX, 0.5);
    ctx.strokeRect(
      -dim.x / 2,
      -dim.y / 2,
      dim.x,
      dim.y
    );

    let fontSize = getTypingFontSize(this.zoomX);
    ctx.font = fontSize + 'px "Open Sans"';
    ctx.fillStyle = userColor;
    ctx.textAlign = 'right';
    ctx.textBaseline = 'hanging';
    ctx.fillText(user.shortName + ' is typing', dim.x / 2, dim.y / 2);
    ctx.restore();
  }
};


let origGetCacheCanvasDimensions = fabric.Object.prototype._getCacheCanvasDimensions;
fabric.Object.prototype._getCacheCanvasDimensions = function() {
  let dim = origGetCacheCanvasDimensions.apply(this, arguments);

  // Make sure "xxx is typing" fits in most cases
  dim.height += 2 * getTypingFontSize(this.zoomX);
  dim.width = Math.max(280, dim.width);

  return dim;
};


// Hack because fabric by default subtracts 0.5, which is quite a lot
fabric.PencilBrush.prototype._addPoint = function(point) {
  if(this._points.length === 0) {
    point.x -= 0.0001;
  }

  this._points.push(point);
};


// Allow using all controls for textbox
fabric.Textbox.getTextboxControlVisibility = function() {
  return {
    tl: true,
    tr: true,
    br: true,
    bl: true,
    ml: true,
    mt: true,
    mr: true,
    mb: true,
    mtr: true,
  };
};


// Don't refocus hiddentextarea: this breaks formula behaviour and doesn't really seem needed
// for anything
fabric.Canvas.prototype._onMouseOut = function(e) {
  let target = this._hoveredTarget;
  this.fire('mouse:out', { target: target, e: e });
  this._hoveredTarget = null;
  if(target) {
    target.fire('mouseout', { e: e });
  }
};


// Don't actually create a hidden textarea for formulas
let origInitHiddenTextarea = fabric.IText.prototype.initHiddenTextarea;
fabric.IText.prototype.initHiddenTextarea = function() {
  if(this.shape && this.shape.type === 'formula') {
    this.hiddenTextarea = {
      focus: angular.noop,
      blur: angular.noop,
      parentNode: {
        removeChild: angular.noop,
      },
      style: {},
      value: '',
    };
  } else {
    origInitHiddenTextarea.apply(this, arguments);
    this.hiddenTextarea.setAttribute('allow-shortcuts', '');

    // The fabric.js default of white-space: nowrap breaks behaviour in Safari when
    // inserting multiple newlines
    this.hiddenTextarea.style.whiteSpace = '';

    // Hide blinking cursor on mobile safari
    this.hiddenTextarea.style.transform = 'scale(0)';
  }
};


// Hack to allow vertical textbox scaling
fabric.Textbox.prototype.lockScalingY = false;
let origSetObjectScale = fabric.Canvas.prototype._setObjectScale;
fabric.Canvas.prototype._setObjectScale = function(
  localMouse, transform, lockScalingX, lockScalingY, by
) {
  let t = transform.target;
  if(t instanceof fabric.Textbox) {
    let width = t.width;
    if(by === 'x' || by === 'equally') {
      width = Math.max(
        t.getMinWidth(),
        width * ((localMouse.x / transform.scaleX) / (width + t.strokeWidth)));
    }

    let height = t.height;
    if(by === 'y' || by === 'equally') {
      height = Math.max(
        t.fontSize * t.lineHeight,
        height * ((localMouse.y / transform.scaleY) / (height + t.strokeWidth)));
    }

    t.set({
      requestWidth: width,
      height: height,
      requestHeight: height,
    });

    return true;

  } else {
    return origSetObjectScale.apply(this, arguments);
  }
};


let origGetTextHeight = fabric.Textbox.prototype._getTextHeight;
fabric.Textbox.prototype._getTextHeight = function() {
  let textHeight = origGetTextHeight.apply(this, arguments);
  let requestHeight = this.requestHeight || textHeight;
  return Math.max(textHeight, requestHeight);
};


// Hack to make textbox auto-scale horizontally
fabric.Textbox.prototype._dimensionAffectingProps.push('requestWidth', 'styles');
let originalInitDimensions = fabric.Textbox.prototype._initDimensions;
fabric.Textbox.prototype._initDimensions = function(ctx) {
  if(this.__skipDimension) {
    return;
  }

  if(!ctx) {
    ctx = fabric.util.createCanvasElement().getContext('2d');
    this._setTextStyles(ctx);
    this.clearContextTop();
  }

  if(this._initDimensionsInProgress) {
    originalInitDimensions.call(this, ctx);

  } else if(this.requestWidth) {
    this.width = this.requestWidth;
    this._initDimensionsInProgress = true;
    originalInitDimensions.call(this, ctx);
    this._initDimensionsInProgress = false;

  } else {
    this.width = this.maxWidth || Infinity;
    this._initDimensionsInProgress = true;
    originalInitDimensions.call(this, ctx);
    this._initDimensionsInProgress = false;

    let lineLengths = this._textLines.map((line, i) => {
      return this._measureLine(ctx, i);
    });
    // The "* 1.01" is necesseray for _splitTextIntoLines() during insertChar to work
    // properly
    this.width = Math.max.apply(null, lineLengths) * 1.01 || 2;
    this.setCoords();
  }
};


let origToObject = fabric.Text.prototype.toObject;
fabric.Text.prototype.toObject = function(propertiesToInclude) {
  let additionalProperties = [
    'requestHeight',
    'requestWidth',
    'maxWidth',
  ].concat(propertiesToInclude);
  return origToObject.call(this, additionalProperties);
};


// Hack to still show controls while editing textbox
let origSetEditingProps = fabric.IText.prototype._setEditingProps;
fabric.IText.prototype._setEditingProps = function() {
  origSetEditingProps.apply(this, arguments);
  this.hasControls = this.selectable = true;
  this.cornerColor = this.editingCornerColor;
};

let origSaveEditingProps = fabric.IText.prototype._saveEditingProps;
fabric.IText.prototype._saveEditingProps = function() {
  origSaveEditingProps.apply(this, arguments);
  this._savedProps.cornerColor = this.cornerColor;
};

let origRestoreEditingProps = fabric.IText.prototype._restoreEditingProps;
fabric.IText.prototype._restoreEditingProps = function() {
  if(this._savedProps) {
    this.cornerColor = this._savedProps.cornerColor;
  }
  origRestoreEditingProps.apply(this, arguments);
};


// Hack to allow clicking on texbox border
fabric.IText.prototype._findTargetCorner = function(pointer) {
  let corner;
  try {
    corner = fabric.Object.prototype._findTargetCorner.call(this, pointer);
  } catch(error) {
    logger.warn(error);
    return false;
  }

  if(corner) {
    return corner;
  }

  if(this.isEditing) {
    let moveRectangles = [
      // Top
      {
        tl: this.oCoords.tl.corner.tl,
        tr: this.oCoords.tr.corner.tr,
        bl: this.oCoords.tl.corner.bl,
        br: this.oCoords.tr.corner.br,
      },
      // Right
      {
        tl: this.oCoords.tr.corner.tl,
        tr: this.oCoords.tr.corner.tr,
        bl: this.oCoords.br.corner.bl,
        br: this.oCoords.br.corner.br,
      },
      // Bottom
      {
        tl: this.oCoords.bl.corner.tl,
        tr: this.oCoords.br.corner.tr,
        bl: this.oCoords.bl.corner.bl,
        br: this.oCoords.br.corner.br,
      },
      // Left
      {
        tl: this.oCoords.tl.corner.tl,
        tr: this.oCoords.tl.corner.tr,
        bl: this.oCoords.bl.corner.bl,
        br: this.oCoords.bl.corner.br,
      },
    ];

    for(let i = 0; i < 4; i++) {
      let lines = this._getImageLines(moveRectangles[i]);
      let xPoints = this._findCrossPoints({ x: pointer.x, y: pointer.y }, lines);
      if(xPoints % 2 === 1) {
        this.__corner = 'side';
        return 'side';
      }
    }
  }

  return false;
};

let origSetCornerCursor = fabric.Canvas.prototype._setCornerCursor;
fabric.Canvas.prototype._setCornerCursor = function(corner) {
  if(corner === 'side') {
    this.setCursor('move');
  } else {
    origSetCornerCursor.apply(this, arguments);
  }
};

let origGetActionFromCorner = fabric.Canvas.prototype._getActionFromCorner;
fabric.Canvas.prototype._getActionFromCorner = function(target, corner) {
  if(corner === 'side') {
    return 'drag';
  } else {
    return origGetActionFromCorner.apply(this, arguments);
  }
};

let origTranslateObject = fabric.Canvas.prototype._translateObject;
fabric.Canvas.prototype._translateObject = function() {
  let transform = this._currentTransform;
  let target = transform.target;

  let origLockMovementX, origLockMovementY;
  if(transform.corner === 'side') {
    origLockMovementX = target.lockMovementX;
    origLockMovementY = target.lockMovementY;
    target.lockMovementX = false;
    target.lockMovementY = false;
  }

  let returnObj = origTranslateObject.apply(this, arguments);

  if(transform.corner === 'side') {
    target.lockMovementX = origLockMovementX;
    target.lockMovementY = origLockMovementY;
  }

  return returnObj;
};


// Hack to clear cursor when rotating & moving
let origBeforeScaleTransform = fabric.Canvas.prototype._beforeScaleTransform;
fabric.Canvas.prototype._beforeScaleTransform = function(e, transform) {
  if(transform.target.isEditing && transform.corner) {
    transform.target.clearContextTop(false);
  }
  origBeforeScaleTransform.apply(this, arguments);
};


// Hack to disable cursor events in textbox when clicking controls
let origHandleEvent = fabric.Canvas.prototype._handleEvent;
fabric.Canvas.prototype._handleEvent = function(e, eventType, targetObj) {
  let setEditable = false;
  if(targetObj && targetObj.__corner && targetObj.editable) {
    setEditable = true;
    targetObj.editable = false;
  }

  origHandleEvent.apply(this, arguments);

  if(setEditable) {
    targetObj.editable = true;
  }
};



// Disable cursor animation (for performance reasons)
fabric.IText.prototype._animateCursor = function(obj, targetOpacity, _duration, completeMethod) {
  let duration = this.cursorDuration;
  if(completeMethod === '_tick') {
    duration /= 1.5;
  }

  obj.set('_currentCursorOpacity', targetOpacity);
  if(obj.canvas && obj.selectionStart === obj.selectionEnd) {
    obj.renderCursorOrSelection();
  }

  let timeout = setTimeout(obj[completeMethod].bind(obj), duration);
  let tickState = {
    abort: function() {
      clearTimeout(timeout);
    }
  };

  return tickState;
};


// Various key events
fabric.fontScaling = 0.854; // Backwards compatibility when we transitioned from Times to Open Sans


fabric._bulletTypes = {
  '*': '\u2003•\u2002',
  '-': '\u2003-\u2002'
};
fabric._tabChar = '\u2003';
fabric._bulletNewLine = '\u2003\u2003';

fabric._reverseBulletTypes = Object.keys(fabric._bulletTypes).reduce(function(obj, key) {
  obj[ fabric._bulletTypes[key] ] = key;
  return obj;
}, {});

fabric._bulletFormatKeys = ['fontSize', 'fill'];

let lastTwoKeys = ['', ''];

let origOnKeyDown = fabric.IText.prototype.onKeyDown;
fabric.IText.prototype.onKeyDown = function(event) {
  let key = event.key ? event.key.toLowerCase() : null;
  let ctrl = (event.ctrlKey || event.metaKey);
  let override = false, scrollIntoView = false;

  let origInsertCharStyle;
  if(!ctrl && key === 'enter') {
    origInsertCharStyle = Object.assign({}, fabric._insertCharStyle);
  }

  if(ctrl && key === 'enter') {
    override = true;
    this.exitEditing();

  } else if(key === 'tab') {
    override = true;
    scrollIntoView = true;
    this.insertChar(fabric._tabChar);

  } else {
    override = this.onKeyDownBulletOperations(event, key);
    scrollIntoView = override;
  }

  if(override) {
    event.preventDefault();
  } else {
    origOnKeyDown.apply(this, arguments);
    scrollIntoView = (this.isEditing && event.keyCode in this.keysMap);
  }

  lastTwoKeys.push(key);
  lastTwoKeys.shift();

  if(origInsertCharStyle) {
    fabric.emit('selectionStyle', origInsertCharStyle);
  }

  if(scrollIntoView) {
    this._fireScrollIntoView();
  }
};


fabric.IText.prototype.onKeyDownBulletOperations = function(event, key) {
  let override = false;

  let position2d = this.get2DCursorLocation(this.selectionStart);
  let charIndex = position2d.charIndex;
  let lineIndex = position2d.lineIndex;
  let line = this._textLines[lineIndex];

  if(key === 'enter') {
    let bulletType = this._getLineBulletType(lineIndex, true);
    if(bulletType) {
      override = true;

      if(event.shiftKey) {
        this.insertChar('\n', false);
        this.insertChar(fabric._bulletNewLine[0], false);
        this.insertChar(fabric._bulletNewLine[1]);

      } else {
        let bulletLength = fabric._bulletTypes[bulletType].length;
        if(charIndex === bulletLength) {
          this.selectionStart -= bulletLength;
          this.removeChars(event);

        } else {
          this.insertChar('\n', false);
          this._insertBullet(bulletType);
        }
      }
    }

  // Bullet insertion and removal
  } else if(
    this.selectionStart === this.selectionEnd && key === ' '
    && charIndex === 1 && line[0] in fabric._bulletTypes
  ) {
    override = true;

    let bulletType = line[0];
    this.selectionStart -= 1;
    this.removeChars(event);
    this._insertBullet(bulletType);

  } else if(this.selectionStart === this.selectionEnd && key === 'backspace') {
    if(charIndex === fabric._bulletNewLine.length && line.startsWith(fabric._bulletNewLine)) {
      override = true;
      this.selectionStart -= fabric._bulletNewLine.length + 1;
      this.removeChars(event);
      this._fireScrollIntoView();

    } else {
      let bulletType = this._getLineBulletType(lineIndex, false);
      if(bulletType) {
        let bulletLength = fabric._bulletTypes[bulletType].length;
        if(charIndex === bulletLength) {
          override = true;
          this.selectionStart -= bulletLength;
          this.removeChars(event);
          if(lastTwoKeys[0] === bulletType && lastTwoKeys[1] === ' ') {
            this.insertChar(bulletType);
            this.insertChar(' ');
            this._fireScrollIntoView();
          }
        }
      }
    }
  }

  return override;
};


fabric.IText.prototype._getLineBulletType = function(lineIndex, checkContinuation) {
  while(true) {
    let line = this._textLines[lineIndex];
    if(checkContinuation && line.startsWith(fabric._bulletNewLine)) {
      lineIndex--;
    } else {
      for(let bulletType in fabric._bulletTypes) {
        if(line && line.startsWith(fabric._bulletTypes[bulletType])) {
          return bulletType;
        }
      }
      return null;
    }
  }
};


fabric.IText.prototype._insertBullet = function(bulletType) {
  let insertStyle = {};
  for(let i = 0; i < fabric._bulletFormatKeys.length; i++) {
    let key = fabric._bulletFormatKeys[i];
    insertStyle[key] = fabric._insertCharStyle[key];
  }

  let bulletChars = fabric._bulletTypes[bulletType];
  for(let i = 0; i < bulletChars.length; i++) {
    let skipUpdate = i + 1 < bulletChars.length;
    this.insertChar(bulletChars[i], skipUpdate, insertStyle);
  }
};



let origFindLineBoundaryLeft = fabric.IText.prototype.findLineBoundaryLeft;
fabric.IText.prototype.findLineBoundaryLeft = function(from) {
  let boundary = origFindLineBoundaryLeft.call(this, from);

  if(from - boundary !== 3
    && this.text.substring(boundary, boundary + 3) in fabric._reverseBulletTypes
  ) {
    boundary += 3;
  }

  return boundary;
};


let origSearchWordBoundary = fabric.IText.prototype.searchWordBoundary;
fabric.IText.prototype.searchWordBoundary = function(selectionStart, direction) {
  let index = origSearchWordBoundary.call(this, selectionStart, direction);
  if(direction < 0 && this.text.substring(index + 1, index + 4) in fabric._reverseBulletTypes) {
    index += 4;
  }
  return index;
};


// Bugfix: the desired behaviour of shiftLineStyles depends on the place it was called from
let origInsertNewlineStyleObject = fabric.Textbox.prototype.insertNewlineStyleObject;
fabric.Textbox.prototype.insertNewlineStyleObject = function() {
  this.shiftLineStylesBehaviour = 'itext';
  return origInsertNewlineStyleObject.apply(this, arguments);
};

let origRemoveStyleObject = fabric.Textbox.prototype._removeStyleObject;
fabric.Textbox.prototype._removeStyleObject = function() {
  this.shiftLineStylesBehaviour = 'textbox';
  return origRemoveStyleObject.apply(this, arguments);
};

let origShiftLineStyles = fabric.Textbox.prototype.shiftLineStyles;
fabric.Textbox.prototype.shiftLineStyles = function() {
  if(this.shiftLineStylesBehaviour === 'textbox') {
    origShiftLineStyles.apply(this, arguments);
  } else if(this.shiftLineStylesBehaviour === 'itext') {
    fabric.IText.prototype.shiftLineStyles.apply(this, arguments);
  } else {
    throw new errors.IllegalStateError(format(
      'shiftLineStylesBehaviour has an illagal value:', this.shiftLineStylesBehaviour));
  }
};

// Bugfix
fabric.Textbox.prototype._removeExtraneousStyles = function() {
  for(let prop in this._styleMap) {
    if(this._textLines[prop] == null) {
      delete this.styles[this._styleMap[prop].line];
    }
  }
};




// Scroll typing text into view
let origOnInput = fabric.IText.prototype.onInput;
fabric.IText.prototype.onInput = function() {
  origOnInput.apply(this, arguments);
  this._fireScrollIntoView();
};


fabric.IText.prototype._fireScrollIntoView = function() {
  if(!this.canvas) {
    return;
  }

  let chars = this.text.split('');
  let offsets = this._getCursorBoundariesOffsets(chars, 'cursor');
  let topLeft = new fabric.Point(this.left + offsets.left, this.top + offsets.top);

  let cursorWidth = this.cursorWidth / this.scaleX / this.canvas.getZoom();
  let cursorLoc = this.get2DCursorLocation();
  let cursorHeight = this.getCurrentCharFontSize(cursorLoc.lineIndex, cursorLoc.charIndex);
  let bottomRight = new fabric.Point(topLeft.x + cursorWidth, topLeft.y + cursorHeight);

  let boxTopLeft = new fabric.Point(this.left, this.top);
  let angle = fabric.util.degreesToRadians(this.angle);
  let topLeftRotated = fabric.util.rotatePoint(topLeft, boxTopLeft, angle);
  let bottomRightRotated = fabric.util.rotatePoint(bottomRight, boxTopLeft, angle);

  this.canvas.fire('text:scrollintoview', new Rect({
    left:   Math.min(topLeftRotated.x, bottomRightRotated.x),
    top:    Math.min(topLeftRotated.y, bottomRightRotated.y),
    right:  Math.max(topLeftRotated.x, bottomRightRotated.x),
    bottom: Math.max(topLeftRotated.y, bottomRightRotated.y),
  }));
};


fabric.IText.prototype._updateAndFire = function(property, index) {
  if(this[property] !== index) {
    this[property] = index;
    this._fireSelectionChanged();
  }
  this._updateTextarea();
};


let origFireSelectionChanged = fabric.IText.prototype._fireSelectionChanged;
fabric.IText.prototype._fireSelectionChanged = function() {
  origFireSelectionChanged.apply(this, arguments);
  if(this.shape && this.shape.type === 'text' && !this._skipUpdateTools) {
    fabric.emit('selectionStyle', this._getFullStyle());
  }
};


fabric.IText.prototype._getFullStyle = function(position) {
  if(position == null) {
    if(this.isEditing) {
      position = this.selectionStart;
    } else {
      position = 0;
    }
  }

  let position2d = this.get2DCursorLocation(position);
  let lineIndex = position2d.lineIndex;
  let line = this._textLines[lineIndex];
  let lineWithoutBullets = this._stripBullets(line);
  let charIndex = position2d.charIndex;

  while(charIndex === (line.length - lineWithoutBullets.length)
    && lineIndex > 0 && lineWithoutBullets.length === 0
  ) {
    lineIndex--;
    line = this._textLines[lineIndex];
    lineWithoutBullets = this._stripBullets(line);
    charIndex = line.length;
  }

  if(charIndex !== (line.length - lineWithoutBullets.length)
    && this.selectionStart === this.selectionEnd
  ) {
    charIndex--;
  }

  let style = this._getStyleDeclaration(lineIndex, charIndex) || {};
  for(let key in fabric._defaultTextStyle) {
    if(!(key in style)) {
      style[key] = fabric._defaultTextStyle[key];
    }
  }

  return style;
};

fabric.IText.prototype._stripBullets = function(line) {
  if(line.substring(0, 3) in fabric._reverseBulletTypes) {
    return line.substring(3);
  } else if(line.substring(0, 2) === fabric._bulletNewLine) {
    return line.substring(2);
  } else {
    return line;
  }
};



// Allow choosing char style while typing
let origInsertChar = fabric.IText.prototype.insertChar;
fabric.IText.prototype.insertChar = function(_char, skipUpdate, styleObject = null) {
  this._skipUpdateTools = true;
  if(styleObject == null) {
    styleObject = Object.assign({}, fabric._insertCharStyle);
  }
  origInsertChar.call(this, _char, skipUpdate, styleObject);
  this._skipUpdateTools = false;
};

let origRemoveChars = fabric.IText.prototype.removeChars;
fabric.IText.prototype.removeChars = function() {
  this._skipUpdateTools = true;
  origRemoveChars.apply(this, arguments);
  this._skipUpdateTools = false;
};

fabric.IText.prototype._removeCharsNearCursor = function(e) {
  if(this.selectionStart === 0) {
    return;
  }

  let bound;
  if(e.altKey) {
    bound = this.findWordBoundaryLeft(this.selectionStart);
  } else {
    bound = this.selectionStart - 1;
  }

  this._removeCharsFromTo(bound, this.selectionStart);
  this.setSelectionStart(bound);
};

let origRemoveCharsFromTo = fabric.IText.prototype._removeCharsFromTo;
fabric.IText.prototype._removeCharsFromTo = function(from, to) {
  if(to < from) {
    to = from;
  }
  let style = this._getFullStyle(from + (this.selectionStart === this.selectionEnd ? 1 : 0));
  fabric.emit('selectionStyle', style);

  origRemoveCharsFromTo.apply(this, arguments);
};





// Always render the cursor in the default color
fabric.IText.prototype.getCurrentCharColor = function() {
  return this.cursorColor;
};


// Fabric.js doesn't support touch events out of the box
let overrideMouseFnNames = ['__onMouseDown', '__onMouseMove', '__onMouseUp'];
for(let i = 0; i < overrideMouseFnNames.length; i++) {
  let fnName = overrideMouseFnNames[i];
  let origFn = fabric.Canvas.prototype[fnName];

  // jshint loopfunc:true
  fabric.Canvas.prototype[fnName] = function(e) {
    if(e.type.startsWith('touch') && e.touches && e.touches.length === 1) {
      e.clientX = e.touches[0].clientX;
      e.clientY = e.touches[0].clientY;
    }

    // Interpret ctrl+click on Mac OS as right click
    if(browser.isMacOS() && e.type === 'mousedown' && e.ctrlKey && e.buttons === 1) {
      // Do nothing

    } else if(!e.type.startsWith('touch') || e.touches && e.touches.length <= 1) {
      origFn.apply(this, arguments);
    }
  };
}


// Fabric.js ignores backingStorePixelRatio + only checks devicePixelRatio on page load.
let dummyContext;
Object.defineProperty(fabric, 'devicePixelRatio', {
  get: function() {
    if(!dummyContext) {
      dummyContext = document.createElement('canvas').getContext('2d');
    }
    return getPixelRatio(dummyContext);
  }
});



// In the whiteboard-exporter script, there seems to be a bug in the canvas package that causes
// svg images to never get loaded (and thus never get a width assigned). In these cases, we need to
// have the real dimensions of the image available during the `toSVG` call.
let origSetWidthHeight = fabric.Image.prototype._setWidthHeight;
fabric.Image.prototype._setWidthHeight = function() {
  if(this.width !== 0) {
    this._width = this.width;
  }
  if(this.height !== 0) {
    this._height = this.height;
  }

  return origSetWidthHeight.apply(this, arguments);
};

const origToSVG = fabric.Image.prototype.toSVG;
fabric.Image.prototype.toSVG = function() {
  let shouldRestoreWidth = this.width === 0 && this._width;
  if(shouldRestoreWidth) {
    this.width = this._width;
    this.height = this._height;
  }

  const retVal = origToSVG.apply(this, arguments);

  if(shouldRestoreWidth) {
    this.width = 0;
    this.height = 0;
  }

  return retVal;
};
