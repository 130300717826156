import template from './tools.html?raw';
import templateDropdownSizeAndColor from './sizeAndColorPicker.dropdown.html?raw';
import templateDropdownTextSize from './textSize.dropdown.html?raw';
import templateDropdownSymbols from './symbols.dropdown.html?raw';
import templateDropdownFormula from './formula.dropdown.html?raw';
import templateDropdownGeometric from './geometric.dropdown.html?raw';
import templateDropdownText from './text.dropdown.html?raw';
import templateTextSize from './textSize.html?raw';
import templateDropdownUploadContent from './uploadContent.dropdown.html?raw';

import { color, object, bind, platform } from 'utils/util';
import Dropdown from 'utils/angularjs/dropdown/Dropdown';
import { AddImageType } from '../whiteboard.service';

const DROPDOWN_ID_UPLOAD = 'toolUpload';
const DROPDOWN_ID_PENCIL = 'toolPencil';
const DROPDOWN_ID_MARKER = 'toolMarker';
const DROPDOWN_ID_GEOMETRIC = 'toolGeometric';
const DROPDOWN_ID_TEXT = 'toolText';
const DROPDOWN_ID_FORMULA = 'toolFormula';

const DROPDOWN_ID_SYMBOLS = 'toolSymbols';
const DROPDOWN_ID_TEXT_SIZE = 'toolTextSize';

const TOOL_DROPDOWN_IDS = {
  'pencil' : DROPDOWN_ID_PENCIL,
  'marker' : DROPDOWN_ID_MARKER,
  'geometric' : DROPDOWN_ID_GEOMETRIC,
  'text' : DROPDOWN_ID_TEXT,
  'formula' : DROPDOWN_ID_FORMULA,
};

export const ALL_DROPDOWN_IDS = [
  DROPDOWN_ID_UPLOAD,
  DROPDOWN_ID_PENCIL,
  DROPDOWN_ID_MARKER,
  DROPDOWN_ID_GEOMETRIC,
  DROPDOWN_ID_TEXT,
  DROPDOWN_ID_FORMULA,
  DROPDOWN_ID_SYMBOLS,
];

const TOOL_NAMES = ['pencil', 'marker', 'formula', 'text', 'geometric'];

const MIN_TEXT_SIZE = 6;
const MAX_TEXT_SIZE = 150;

class ToolsController {
  static get $inject() {
    return [
      '$element',
      '$templateCache',
      'filePickerService',
      'languageService',
      'toolService',
      'tileService',
      'dropdownService',
      'shortcutService',
      'whiteboardService',
    ];
  }

  constructor(
    $elem,
    $templateCache,
    filePickerService,
    languageService,
    toolService,
    tileService,
    dropdownService,
    shortcutService,
    whiteboardService
  ) {
    bind(this);

    this.$elem = $elem;
    this.$templateCache = $templateCache;
    this.filePickerService = filePickerService;
    this.toolService = toolService;
    this.languageService = languageService;
    this.tileService = tileService;
    this.dropdownService = dropdownService;
    this.shortcutService = shortcutService;
    this.whiteboardService = whiteboardService;

    this.isActive = false;
    this.tooltips = {};
    this.shortcutHandlers = {
      'alt+s': this.selectTool.bind(this, 'cursor'),
      'alt+d': this.selectTool.bind(this, 'pan'),
      'alt+e': this.selectTool.bind(this, 'eraser'),
      'alt+p': this.selectTool.bind(this, 'pencil'),
      'alt+m': this.selectTool.bind(this, 'marker'),
      'alt+g': this.selectTool.bind(this, 'geometric'),
      'alt+t': this.selectTool.bind(this, 'text'),
      'alt+f': this.selectTool.bind(this, 'formula'),
      'alt+u': this.toggleUploadDropdown.bind(this),
    };

    this._registerDropdowns();

    this.tileService.on('draw', this._updateActive);
    this.languageService.on('language', this._updateTooltips);
    this._updateTooltips();
  }


  $onDestroy() {
    this._updateActive();
  }

  $onChanges() {
    this._updateActive();
  }


  _registerDropdowns() {
    this.$templateCache.put('whiteboardSizeAndColor.html', templateDropdownSizeAndColor);
    this.$templateCache.put('whiteboardTextSize.html', templateTextSize);

    this.dropdownService.register(
      DROPDOWN_ID_UPLOAD,
      templateDropdownUploadContent,
      {
        position: Dropdown.Position.RIGHT,
        cssClasses: 'dropdown-deprecated--no-padding-x',
        preRender: true
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID_PENCIL,
      templateDropdownSizeAndColor,
      {
        position: Dropdown.Position.RIGHT
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID_MARKER,
      templateDropdownSizeAndColor,
      {
        position: Dropdown.Position.RIGHT,
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID_GEOMETRIC,
      templateDropdownGeometric,
      {
        position: Dropdown.Position.RIGHT,
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID_TEXT,
      templateDropdownText,
      {
        position: Dropdown.Position.RIGHT,
        embeds: [DROPDOWN_ID_TEXT_SIZE],
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID_FORMULA,
      templateDropdownFormula,
      {
        position: Dropdown.Position.RIGHT,
        embeds: [DROPDOWN_ID_SYMBOLS, DROPDOWN_ID_TEXT_SIZE],
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID_SYMBOLS,
      templateDropdownSymbols
    );

    this.dropdownService.register(
      DROPDOWN_ID_TEXT_SIZE,
      templateDropdownTextSize,
      {
        cssClasses: 'dropdown-deprecated--no-padding-x',
      }
    );

  }


  _updateTooltips() {
    /* eslint-disable max-len */
    this.tooltips = {
      undo: gettextCatalog.getString('Undo') + ` (${this.shortcutService.getPrettyString('ctrl+z')})`,
      redo: gettextCatalog.getString('Redo') + ` (${this.shortcutService.getPrettyString('ctrl+y')})`,
      upload: gettextCatalog.getString('Add an image to this whiteboard') + ` (${this.shortcutService.getPrettyString('alt+u')})`,

      cursor: gettextCatalog.getString('Select and move objects') + ` (${this.shortcutService.getPrettyString('alt+s')})`,
      pan: gettextCatalog.getString('Click and drag inside whiteboards') + ` (${this.shortcutService.getPrettyString('alt+d')})`,
      eraser: gettextCatalog.getString('Eraser') + ` (${this.shortcutService.getPrettyString('alt+e')})`,
      pencil: gettextCatalog.getString('Pencil') + ` (${this.shortcutService.getPrettyString('alt+p')})`,
      marker: gettextCatalog.getString('Marker pen') + ` (${this.shortcutService.getPrettyString('alt+m')})`,
      geometric: gettextCatalog.getString('Geometric shapes') + ` (${this.shortcutService.getPrettyString('alt+g')})`,
      text: gettextCatalog.getString('Text') + ` (${this.shortcutService.getPrettyString('alt+t')})`,
      formula: gettextCatalog.getString('Formula') + ` (${this.shortcutService.getPrettyString('alt+f')})`,

      fontSize: gettextCatalog.getString('Font size'),
      fontSizeIncrease: gettextCatalog.getString('Increase font size'),
      fontSizeDecrease: gettextCatalog.getString('Decrease font size'),

      bold: gettextCatalog.getString('Bold') + ` (${this.shortcutService.getPrettyString('ctrl+b')})`,
      italic: gettextCatalog.getString('Italic') + ` (${this.shortcutService.getPrettyString('ctrl+i')})`,
      underline: gettextCatalog.getString('Underline') + ` (${this.shortcutService.getPrettyString('ctrl+u')})`,
    };
    /* eslint-enable max-len */
  }


  _updateActive() {
    let isActive = this.tile && this.tile.active;

    if(!this.isActive && isActive) {
      TOOL_NAMES.forEach(tool => {
        let colorName = this.toolService.getToolColorName(tool);
        this.toolService.on(colorName, this._updateToolIcons);
      });
      this.toolService.on('tool', this._updateToolIcons);
      this._updateToolIcons();

      object.forEach(this.shortcutHandlers, (shortcut, handler) => {
        this.shortcutService.on(shortcut, handler);
      });

    } else if(this.isActive && !isActive) {
      TOOL_NAMES.forEach(tool => {
        let colorName = this.toolService.getToolColorName(tool);
        this.toolService.off(colorName, this._updateToolIcons);
      });
      this.toolService.off('tool', this._updateToolIcons);

      object.forEach(this.shortcutHandlers, (shortcut, handler) => {
        this.shortcutService.off(shortcut, handler);
      });

      ALL_DROPDOWN_IDS.forEach(dropdownId => {
        this.dropdownService.hide(dropdownId);
      });
    }

    this.isActive = isActive;
  }

  selectTool(tool, $event) {
    Object.keys(TOOL_DROPDOWN_IDS)
      .filter(otherTool => otherTool !== tool)
      .forEach(otherTool => this.dropdownService.hide(TOOL_DROPDOWN_IDS[otherTool]));

    if(this.toolService.selected.tool === tool) {
      this.toggleToolDropdown(tool, $event);
    } else if(tool === 'geometric' && this.toolService.selected.geometricShape === 'none') {
      this.toggleToolDropdown('geometric');
      this.toolService.set('geometricShape', 'line');
    }

    this.toolService.set('tool', tool);
  }

  toggleToolDropdown(tool, $elemOrEvent) {
    if($elemOrEvent == null) {
      $elemOrEvent = this.$elem.find(`.tools__item--${tool}`);
    }

    let dropdownId = TOOL_DROPDOWN_IDS[tool];
    if(dropdownId == null) {
      return;
    }

    this._toggleDropdown(dropdownId, $elemOrEvent, tool);
  }


  selectGeometricShape(geometricShape) {
    this.toolService.set('geometricShape', geometricShape);
    this.toolService.set('tool', 'geometric');
  }

  toggleDropdownSymbols($event, symbolCollection) {
    this.toolService.symbolCollectionForDropdown = symbolCollection;
    this._toggleDropdown(DROPDOWN_ID_SYMBOLS, $event, 'formula');
  }

  toggleDropdownTextSize($event) {
    this._toggleDropdown(DROPDOWN_ID_TEXT_SIZE, $event, 'text');
    // textSize dropdown is triggered on an input field: prevent the field from losing focus
    $event.target.focus();
  }

  /**
   * open the "add an image" dropdown. As this is a special case this exists outside of the
   * usual "tool" lists
   *
   * @param {*} $elemOrEvent
   */
  toggleUploadDropdown($elemOrEvent ) {
    if($elemOrEvent == null) {
      $elemOrEvent = this.$elem.find('.tools__item--upload');
    }

    this.dropdownService.toggle(DROPDOWN_ID_UPLOAD, $elemOrEvent, { toolsCtrl: this });
  }

  get AddImageType() {
    return AddImageType;
  }

  /**
   * Fired when the textsize input field receives a keyup event.
   */
  onTextSizeKeypress($keyevent) {
    // Close the textsize dropdown
    this.dropdownService.hide(DROPDOWN_ID_TEXT_SIZE);

    // If enter is pressed, also close the tool dropdown
    if($keyevent.key === 'Enter') {
      this.dropdownService.hideAll();
      this.onTextSizeBlur($keyevent);
    }
  }

  /**
   * Fired when the textsize input field loses focus. Submits the input value to toolService
   */
  onTextSizeBlur($keyevent) {
    if(!isNaN($keyevent.target.value) && !isNaN(parseFloat($keyevent.target.value))) {
      let newVal = platform(MIN_TEXT_SIZE, $keyevent.target.value, MAX_TEXT_SIZE);
      this.toolService.setToolSize('text', newVal);

      // a second onBlur event does not synchronise the ng-value attribute, explicitly set
      // thet input field value just in case.
      $keyevent.target.value = newVal;
    }
  }

  /**
   * Only show a toolSize widget if the tool does not use textSize as size parameter
   *
   * @param {string} tool
   * @returns {boolean}
   */
  shouldShowToolSize(tool) {
    return ( tool !== 'formula' && tool !== 'text');
  }

  _toggleDropdown(dropdownId, $elemOrEvent, tool) {
    this.dropdownService.toggle(dropdownId, $elemOrEvent, {
      toolsCtrl: this,
      toolService: this.toolService,
      tile: this.tile,
      tool: tool,
    });
  }


  _updateToolIcons() {
    TOOL_NAMES.forEach(tool => {
      let colorName = this.toolService.getToolColorName(tool);
      let borderColor = this.toolService.selected[colorName];
      let backgroundColor = '';
      let fillColor = '';

      if(tool === this.toolService.selected.tool) {
        backgroundColor = borderColor;
        fillColor = this._getFillColor(backgroundColor);
      }

      let $elemColor = this.$elem.find('.tools__item--' + tool);
      $elemColor.css({
        background: backgroundColor,
        '--icon-color': fillColor,
      });

      let $elemBorder = $elemColor.find('.tools__item-border');
      $elemBorder.css({
        background: borderColor,
      });

      let $elemTriangle = $elemColor.find('.tools__triangle');
      $elemTriangle.css({
        borderColor: `transparent transparent transparent ${fillColor}`,
      });
    });
  }


  _getFillColor(backgroundColor) {
    let match = backgroundColor.match(/rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\s*\)/);
    let rgb = {
      r: parseInt(match[1]),
      g: parseInt(match[2]),
      b: parseInt(match[3]),
    };
    let hsl = color.rgbToHsl(rgb);
    return (hsl.l > .75 || hsl.h === 60 && hsl.l > .45) ?
      'rgba(var(--teal-500), 0.84)' :  // This should match text-theme-color('teal', 500)
      'white';
  }
}


export default {
  controller: ToolsController,
  controllerAs: 'toolsCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
