<div
  class="modal__wrapper"
  @enter
  (click)="onWrapperClick($event)"
>
  <div
    class="modal"
    [ngClass]="config.modalClass"
    [@attention]="drawAttention"
    (@attention.done)="setAttention(false)"
  >
    <div class="modal__header" *ngIf="!config.hideHeader">
      <svg-icon
        *ngIf="config.icon"
        class="modal__icon"
        [svgIcon]="config.icon"
      ></svg-icon>

      <div class="modal__title">{{ config.title }}</div>

      <div *ngIf="!config.disableClose" class="modal__close">
        <div class="btn btn--square" (click)="close()" data-testid="modalCloseButton">
          <svg-icon class="btn__icon" [svgName]="'close'"></svg-icon>
        </div>
      </div>
    </div>

    <div class="modal__body" [ngClass]="config.bodyClass">
      <ng-template [cdkPortalOutlet]="config.templatePortal || config.componentPortal"></ng-template>
    </div>
  </div>
</div>
