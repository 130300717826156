import { EventEmitter, logger, errors, array, getQueryParameter } from 'utils/util';


const Mode = Object.freeze({
  UDP: 'udp',
  TCP: 'tcp',
});
const FETCH_CREDENTIALS_BUFFER = 5 * 60 * 1000;


export default class RtcConfigurationService {
  static get $inject() {
    return [
      'apiService',
      'meetingService',
      'userService',
      'notificationService',
    ];
  }

  get Mode() {
    return Mode;
  }


  constructor(
    apiService,
    meetingService,
    userService,
    notificationService
  ) {
    EventEmitter.setup(this, ['configuration']);

    this.apiService = apiService;
    this.meetingService = meetingService;
    this.userService = userService;
    this.notificationService = notificationService;

    this.credentials = null;
    let rtcModeQueryParameter = getQueryParameter('rtcMode');
    if(array.has(Object.values(Mode), rtcModeQueryParameter)) {
      this.mode = rtcModeQueryParameter;
    } else {
      this.mode = Mode.UDP;
    }
    this.configuration = null;

    this.fetchPromise = $q.resolve();
  }


  _updateConfiguration() {
    if(!this.credentials) {
      return;
    }

    this.configuration = {
      iceServers: this.credentials.iceServers,
      iceTransportPolicy: this.mode === Mode.UDP ? 'all' : 'relay',
      sdpSemantics: 'unified-plan',
    };

    this.emit('configuration', this.configuration);
  }


  _fetchCredentialsIfNeeded() {
    this.fetchPromise = this.fetchPromise.then(() => {
      let shouldFetchCredentials = (
        !this.credentials
        || performance.now() - this.credentials.fetchedAt
            > this.credentials.ttl - FETCH_CREDENTIALS_BUFFER
      );
      if(shouldFetchCredentials) {
        return this._fetchCredentials();
      }
    });

    return this.fetchPromise;
  }

  _fetchCredentials() {
    return this.apiService.post('turn-credentials/', {}, {
      maxRetries: Infinity,
    })
      .then(response => {
        this.credentials = {
          iceServers: response.data.iceServers,
          ttl: response.data.ttl * 1000,
          fetchedAt: performance.now(),
        };
        this._updateConfiguration();
      })
      .catch(error => {
        if(error.constructor !== errors.DoesNotExistError) {
          logger.error(error);
        }
        this.notificationService.error(
          gettextCatalog.getString(
            'Failed to start audio/video communication. Please reload the page to continue.'
          ),
          { delay: -1 }
        );
      });
  }


  get() {
    return this._fetchCredentialsIfNeeded()
      .then(() => {
        return this.configuration;
      });
  }


  setMode(mode) {
    if(mode !== this.mode) {
      this.mode = mode;
      this._updateConfiguration();
    }
  }
}
