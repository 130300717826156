import template from './cookieNotice.html?raw';

const COOKIE_NAME = 'vectera-cookieconsent';
const COOKIE_VALUE = 'dismiss';
const COOKIE_OPTIONS = {
  path: '/',
  domain: '',
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  secure: true,
  samesite: 'strict',
};


export class CookieNoticeController {
  static get $inject() {
    return [
      '$cookies',
      'languageService',
      'requestUserService',
      'siteService',
    ];
  }

  /**
   *
   * @param {*} $cookies
   * @param {*} languageService
   */
  constructor(
    $cookies,
    languageService,
    requestUserService,
    siteService
  ) {
    this.$cookies = $cookies;
    this.languageService = languageService;
    this.requestUserService = requestUserService;
    this.siteService = siteService;

    this.dismissed = this.$cookies.get(COOKIE_NAME) === COOKIE_VALUE;
  }


  get shouldShow() {
    return (
      this.cookieMessage
      && !this.requestUserService.user.isAuthenticated
      && this.siteService.site.isStandalone
      && !this.dismissed
    );
  }


  get cookieMessage() {
    return this.languageService.getCustomOrTranslatedString(this.cookiePolicy);
  }


  dismiss() {
    this.dismissed = true;
    this.$cookies.put(COOKIE_NAME, COOKIE_VALUE, COOKIE_OPTIONS);
  }
}



/**
 * Show a cookie notice to non-authenticated users.
 * @param {object} cookie-policy - A dictionary that contains the cookie policy in each supported
 *  language.
 */
export default {
  controller: CookieNoticeController,
  controllerAs: 'cookieNoticeCtrl',
  template,

  bindings: {
    cookiePolicy: '<',
  }
};
