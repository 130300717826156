import template from './hiddenSecret.html?raw';


class HiddenSecretController {
  constructor() {
    this.shouldShow = false;
  }
}


export default {
  controller: HiddenSecretController,
  controllerAs: 'hiddenSecretCtrl',
  template,

  bindings: {
    secret: '<',
  },
};
