const ChatStatus = {
  OFFLINE: 'offline',
  AWAY: 'away',
  ONLINE: 'online',
};
Object.freeze(ChatStatus);

export default class SidebarController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    this.ChatStatus = ChatStatus;

    this.$elem = $elem;
    this.chatStatus = ChatStatus.OFFLINE;
    this.chatLoading = true;

    this.$elem.find('.sidebar__menu-group').each((i, elemGroup) => {
      let $elemGroup = angular.element(elemGroup);
      let $elemGroupTitle = $elemGroup.find('.sidebar__menu-item--group-title');

      $elemGroupTitle.on('click', () => this.toggleGroup($elemGroup));
    });

    let $elemSubItemActive = this.$elem.find('.sidebar__menu-item--sub.selected');
    if($elemSubItemActive.length) {
      let $elemGroup = $elemSubItemActive.parents('.sidebar__menu-group');
      this.toggleGroup($elemGroup);
    }
    $elem.attr('data-testid', 'loadedSidebar');
    this.$chatItemOffline = this.$elem.find('#chatItemOffline');
    this.$chatItemOnline = this.$elem.find('#chatItemOnline');
    this.$chatItemAway = this.$elem.find('#chatItemAway');
    this.waitForZendeskChatStatus();
  }


  toggleGroup($elemGroup) {
    $elemGroup.toggleClass('active');
  }

  waitForZendeskChatStatus() {
    if(window.zEChatStatus != null) {
      this.setZendeskChatStatus(window.zEChatStatus);
    } else {
      setTimeout(this.waitForZendeskChatStatus.bind(this), 250);
    }
  }

  setZendeskChatStatus(status) {
    this.chatStatus = status;
    this.chatLoading = false;
  }

  get chatTooltip() {
    if(this.chatLoading) {
      return undefined;
    }
    if(this.chatStatus === ChatStatus.OFFLINE) {
      return this.offlineText;
    } else if(this.chatStatus === ChatStatus.AWAY) {
      return this.awayText;
    } else {
      return undefined;
    }
  }

  get offlineText() {
    return gettextCatalog.getString(
      'Our chat is currently offline but you can still send us a message.'
    );
  }
  get awayText() {
    return gettextCatalog.getString(
      'We\'re currently away but will respond as soon as possible.'
    );
  }
}
