<div class="container container--lg pp" data-testid="contactWidgetConfigElement">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <go-back-header
    [url]="urlService.urls.contactWidgetsOverview"
    headerText="Contact widget"
    i18n-headerText
  ></go-back-header>

  <div
    *ngIf="fetchError"
    class="field field--error"
  >
    <field-icon [type]="'error'"></field-icon>
    {{ fetchError }}
  </div>

  <settings-non-field-errors [nonFieldErrors]="errors.nonFieldErrors"></settings-non-field-errors>

  <div *ngIf="stateService.isReady && !fetchError">
    <contact-widget-settings
      [instance]="contactWidget"
      [appointmentTypes]="appointmentTypes"
      [teamMembers]="teamMembers"
      [contactForms]="contactForms"
      [waitingRooms]="waitingRooms"
      [errors]="errors"
      (submitForm)="save()"
    ></contact-widget-settings>
  </div>
</div>


<div class="action-footer" *ngIf="stateService.isReady && !fetchError">
  <div class="action-footer__button-row">
    <div
      class="btn btn--primary"
      (click)="!isSaving && canSave && save()"
      [ngClass]="{'disabled':isSaving || !canSave }"
      data-testid="saveButton"
    >
      <div class="d-flex align-items-center" *ngIf="isSaving" >
        <span class="mr-2">{{ savingWidgetText }}</span>
        <loading></loading>
      </div>
      <div *ngIf="!isSaving">
        <span>{{ saveWidgetText }}</span>
      </div>
    </div>
  </div>
</div>
