import { Component, Inject } from '@angular/core';
import { SmartSummaryBackendService } from 'meeting/smart-summary/smart-summary-backend.service';
import { logger, dateTime } from 'utils/util';
import { Transcript, Utterance } from 'meeting/smart-summary/smart-summary.service';
import { Summary } from 'meeting/smart-summary/smart-summary.service';
import { FileItem } from 'utils/form-elements/file-picker/file-picker.component';

@Component({
  selector: 'smart-summary-test',
  templateUrl: './smart-summary-test.component.html',
})
export class SmartSummaryTestComponent {
  public transcriptInputText: string | null = null;
  public durationInput: number | null = null;
  public languageCodeInput: string | null = null;
  public transcriptInputErrorMessage: string | null = null;

  public audioFile: FileItem | null = null;
  public summary: Summary | null = null;
  public transcript: Transcript | null = null;
  public isTranscribing = false;
  public isSummarizing = false;

  public prompt = `Summarize the following transcription in language code {language_code}.
Make the summary extensive and make sure to include all topics that were discussed and the
decisions that were made. Add action points of the meeting at the end of the summary,
but only if there are actions points.
Transcription: {transcription}`;

  constructor(
    private smartSummaryBackendService: SmartSummaryBackendService,
    @Inject('notificationService') private notificationService,
  ) {}


  public async transcribe(): Promise<void> {
    if (this.audioFile == null || this.transcript || this.isTranscribing) {
      return;
    }
    this.isTranscribing = true;
    const blob = await (await fetch(this.audioFile.url)).blob();
    try {
      this.transcript = await this.smartSummaryBackendService.transcribeAudio(blob);
    } catch (error) {
      this.notificationService.error(
        'Something went wrong while transcribing the audio. Try again later'
      );
      logger.error(error);
    } finally {
      this.isTranscribing = false;
    }
  }

  public async summarize(): Promise<void> {
    if (this.transcript == null || this.isSummarizing) {
      return;
    }
    this.isSummarizing = true;
    try {
      this.summary = await this.smartSummaryBackendService.summarizeTranscription(
        this.transcript,
        this.prompt
      );
    } catch (error) {
      this.notificationService.error(
        'Something went wrong while transcribing the audio. Try again later'
      );
      logger.error(error);
    } finally {
      this.isSummarizing = false;
    }
  }


  buildTranscriptFromInputs(): void {
    if (this.transcriptInputText == null || this.transcriptInputText === '') {
      this.transcript = null;
      this.clearTranscriptInputError();
      return;
    }

    const utterances: Utterance[] = [];
    let duration = 0;
    const splitStringInUtterances = this.transcriptInputText?.match(/(?=[\s\S])(?:.*\n?){1,3}/g);
    if(splitStringInUtterances == null) {
      this.setTranscriptInputError();
      return;
    }
    splitStringInUtterances.forEach((utteranceText) => {
      const utteranceParts = utteranceText?.split('\n');
      if(utteranceParts == null || utteranceParts.length < 3 || utteranceParts[2] === '') {
        this.setTranscriptInputError();
        return;
      }
      const utterance: Utterance = {
        speaker: utteranceParts[0] || 'Unknown',
        start: this.minutesAndSecondsToMillis(utteranceParts[1] || '0:00'),
        text: utteranceParts[2] || 'Unknown',
      };
      utterances.push(utterance);
    });

    if(utterances.length === 0) {
      return;
    }
    // Not really accurate, but best we can do if no duration is set
    duration = this.durationInput || utterances[utterances.length - 1].start / 1000;
    this.transcript = {
      utterances: utterances,
      languageCode: this.languageCodeInput || 'en-US',
      duration: duration,
    };
    this.clearTranscriptInputError();
  }

  setTranscriptInputError(): void {
    // eslint-disable-next-line max-len
    this.transcriptInputErrorMessage = 'Transcript input is not valid. Use the format <br>{Speaker}<br>{Start time in mm:ss}<br>{Utterance}<br>{Speaker}<br>...';
    this.transcript = null;
  }
  clearTranscriptInputError(): void {
    this.transcriptInputErrorMessage = null;
  }


  public get isTranscriptButtonDisabled(): boolean {
    return this.audioFile == null || this.isTranscribing || this.transcript != null;
  }

  public get isSummaryButtonDisabled(): boolean {
    return this.transcript == null || this.isSummarizing;
  }

  onAudioFileChanged(): void {
    if (this.audioFile == null) {
      this.clearAll();
    } else {
      this.clearTranscriptAndSummary();
    }
  }

  clearTranscriptAndSummary(): void {
    this.transcript = null;
    this.summary = null;
    this.clearTranscriptInput();
  }

  clearSummary(): void {
    this.summary = null;
  }

  clearAll(): void {
    this.audioFile = null;
    this.transcript = null;
    this.summary = null;
    this.clearTranscriptInput();
  }

  clearTranscriptInput(): void {
    this.transcriptInputText = null;
    this.transcriptInputErrorMessage = null;
    this.durationInput = null;
    this.languageCodeInput = null;
  }

  public promptHint(): string {
    // eslint-disable-next-line max-len
    return $localize `Supports <b>{language_code}</b>, <b>{duration}</b> and <b>{transcription}</b> as tags. They will be replaced by the language code, duration in seconds and utterances as readable text`;
  }

  public millisToTimePassed(millis: number): string {
    return dateTime.convertMillisToTime(millis);
  }

  private minutesAndSecondsToMillis(time: string): number {
    const parts = time.split(':').map(Number);
    let millis = 0;

    if (parts.length === 3) {
      const [hours, minutes, seconds] = parts;
      millis = (hours * 3600 + minutes * 60 + seconds) * 1000;
    } else if (parts.length === 2) {
      const [minutes, seconds] = parts;
      millis = (minutes * 60 + seconds) * 1000;
    } else {
      throw new Error('Invalid time format. Expected HH:mm:ss or mm:ss');
    }

    return millis;
  }
}
