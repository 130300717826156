<!-- Header -->
<div class="availability-picker__header">
  <div class="availability-picker__header-buttons">
    <div class="input-group d-none mr-auto d-sm-flex">
      <div
        *ngFor="let interface of interfaces"
        class="btn"
        [class.btn--teal]="interface === currentInterface"
        [class.btn--secondary]="interface !== currentInterface"
        (click)="setInterface(interface)"
      >
        {{ interfaceNames[interface] }}
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div class="text-muted mr-2"><span i18n>Timezone</span>:</div>
      <span *ngIf="schedulingConfig?.timezone">{{ schedulingConfig.timezone }}</span>
      <span *ngIf="!schedulingConfig?.timezone" class="text-muted" i18n>Not set</span>
      <a
        *ngIf="isSelf"
        class="ml-3 a-plain btn btn--outline-primary"
        target="_blank"
        [attr.href]="urlService.urls.schedulingConfig"
        i18n
      >
        Change
      </a>
    </div>
  </div>

  <div
    *ngIf="errors && errors.length > 0"
    class="field field--warning mt-4"
  >
    <field-icon [type]="'warning'"></field-icon>
    <settings-errors
      [settingsErrors]="errors"
      [settingsErrorsListClass]="'ul-plain mb-0'"
      [settingsErrorsItemClass]="''"
    ></settings-errors>
  </div>
</div>


<!-- Tabular interface -->
<div
  *ngIf="currentInterface === Interface.TABULAR"
  class="availability-picker__tabular-grid"
  data-testid="tabularView"
>
  <ng-container *ngFor="let weekday of weekdays; let weekdayIndex = index">
    <div
      class="availability-picker__tabular-weekday-wrapper"
      data-testid="weekday"
    >
      <div class="availability-picker__tabular-weekday">
        <input
          type="checkbox"
          class="checkbox"
          id="availability_{{ weekdayIndex }}"
          [(ngModel)]="tabularWeekdaysEnabled[weekdayIndex]"
          (ngModelChange)="onWeekdayEnabledChanged(weekdayIndex)"
          [disabled]="readonly"
          [readonly]="readonly"
        />
        <label
          class="checkbox__label"
          for="availability_{{ weekdayIndex }}"
          data-testid="weekdayCheckbox"
        >
          {{ weekday }}
        </label>
      </div>
    </div>

    <div
      class="availability-picker__tabular-availabilities"
      data-testid="weekdayAvailabilities"
    >
      <ng-container *ngIf="tabularWeekdaysEnabled[weekdayIndex]">
        <div
          *ngFor="let availabilityRange of tabularState[weekdayIndex]; let availabilityIndex = index"
          class="availability-picker__tabular-availability"
          data-testid="weekdayAvailability"
        >
          <div class="availability-picker__tabular-availability-inputs">
            <span i18n class="mr-3">From</span>
            <input
              type="time"
              class="input input--sm input--availability-picker mr-5"
              [(ngModel)]="availabilityRange.from"
              (ngModelChange)="updateAvailabilityFromTabularState()"
              [disabled]="readonly"
              [readonly]="readonly"
              data-testid="availabilityFromInput"
            />
            <span i18n class="mr-3">To</span>
            <input
              type="time"
              class="input input--sm input--availability-picker"
              [(ngModel)]="availabilityRange.to"
              (ngModelChange)="updateAvailabilityFromTabularState()"
              [disabled]="readonly"
              [readonly]="readonly"
              data-testid="availabilityToInput"
            />
          </div>

          <div
            *ngIf="availabilityIndex=== 0"
            class="btn btn--square"
            [class.disabled]="readonly"
            (click)="!readonly && addAvailabilityRange(weekdayIndex)"
            data-testid="addAvailabilityButton"
          >
            <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
          </div>

          <div
            *ngIf="availabilityIndex> 0"
            class="btn btn--square"
            [class.disabled]="readonly"
            (click)="!readonly && removeAvailabilityRange(weekdayIndex, availabilityIndex)"
            data-testid="removeAvailabilityButton"
          >
            <svg-icon class="btn__icon" [svgName]="'trash'"></svg-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>


<!-- Calendar interface -->
<!--
  We're using `[hidden]` instead of `*ngIf` to simplify the usage of `@ViewChild('calendar')` inside the controller.
  `*ngIf` would remove the element every time you go to the tabular interface, so that we would need to
  run `initCalendar()` every time you switch back.
-->
<div
  [hidden]="currentInterface !== Interface.CALENDAR"
  class="availability-picker__calendar-wrapper"
>
  <table
    class="availability-picker__calendar"
    [class.availability-picker__calendar--disabled]="readonly"
    #calendar
  >
    <tr>
      <th></th>
      <th
        *ngFor="let weekdayAbbreviation of weekdayAbbreviations"
        class="availability-picker__calendar-header"
      >
        {{ weekdayAbbreviation }}
      </th>
    </tr>
    <tr
      *ngFor="let date of calendarDates"
      class="availability-picker__calendar-row"
      [class.availability-picker__calendar-row--border]="date.getMinutes() === 0 && date.getHours() > 0"
    >
      <td class="availability-picker__calendar-time">
        {{ date.getMinutes() === 0 ? date.toLocaleTimeString([], { timeStyle: 'short' }) : '&nbsp;' }}
      </td>
      <td
        *ngFor="let weekday of weekdays"
        class="availability-picker__calendar-cell"
      ></td>
    </tr>
  </table>
</div>
