export default class ConnectionNotificationController {
  static get $inject() {
    return [
      'connectionNotificationService',
    ];
  }

  constructor(
    connectionNotificationService
  ) {
    this.connectionNotificationService = connectionNotificationService;
  }
}
