import { array } from 'utils/util';
import { Inject, Injectable } from '@angular/core';
import { Session } from '../angularjs/main/users/Session';

export type ActiveInterval = [Date, Date | null]

@Injectable({
  providedIn: 'root',
})
export class ActiveIntervalService {
  /**
   * activeIntervals keeps track of the moments the MR is active, by storing a list of
   * "active intervals". Each item in this list stores a list containing 2 Date objects denoting
   * the time at which the active interval started and ended
   */
  public activeIntervals : ActiveInterval[] = [];

  constructor(
    @Inject('userService') private userService,
    @Inject('streamService') private streamService,
  ) {
    this.userService.on('sessionJoin', this.updateActiveIntervals.bind(this));
    this.userService.on('sessionExit', this.updateActiveIntervals.bind(this));
    this.streamService.on('add', this.updateActiveIntervals.bind(this));
    this.streamService.on('remove', this.updateActiveIntervals.bind(this));
  }


  /**
   * Check if the MR is (still) active and update activeIntervals accordingly
   *
   * A MR is considered (still) active if there are 2 or more 'Alive' sessions connected and one
   * of them is the session of the local user
   */
  updateActiveIntervals() {
    const numJoinedSessions =
      Object.values(this.userService.sessions)
        .filter(session => (session as Session).isAlive())
        .length;
    const isActive = (
      numJoinedSessions >= 2
      && this.userService.mySession.isAlive()
      && this.streamService.streams.length > 0
    );

    if(!this.currentIntervalIsActive && isActive) {
      // start a new active interval
      this.activeIntervals.push([new Date(), null]);
    } else if(this.currentIntervalIsActive && !isActive) {
      // end the current active interval
      this.activeIntervals[this.activeIntervals.length - 1][1] = new Date();
    }
  }



  /**
   * The meeting room is considered active if the last interval in activeIntervals has no end time.
   * This means that the current "active interval" has been started but has not been ended yet.
   *
   * @returns {boolean}
   */
  get currentIntervalIsActive() {
    return (
      this.activeIntervals.length > 0
      && this.activeIntervals[this.activeIntervals.length - 1][1] == null
    );
  }

  /**
   * calculate the amount of milliseconds that the MR has been active
   *
   * @returns {number}
   */
  get timeActive() {
    // sum the difference between start and end time for all intervals in activeIntervals.
    return array.sum(
      this.activeIntervals,
      activeInterval => (activeInterval[1] || new Date()) - activeInterval[0]
    );
  }

  get meetingEndTime(): Date | null {
    return this.activeIntervals
      .map((activeInterval) => activeInterval[1])
      .sort()[-1];
  }

}
