import { DragListener, Rect, normalizeWheel, format } from 'utils/util';


const ZOOM_HINT_DURATION = 2000;

/**
 * A mixin that extends a StreamTlie to support a viewport
 *
 * Adds digital zoom and pan/tilt controls
 *
 */

export default function ViewportTileControllerMixin(Superclass) {
  return class ViewportTileController extends Superclass {
    static get $inject() {
      return [
        '$scope',
        '$element',
        'recorderService',
        'browserService',
      ].concat(Superclass.$inject);
    }

    constructor(
      $scope,
      $elem,
      recorderService,
      browserService,
      ...args
    ) {
      super(...args);

      this.$scope = $scope;
      this.$elem = $elem;
      this.recorderService = recorderService;
      this.browserService = browserService;

      this.$elemBody = null;
      this.$elemVideo = null;

      // Dragging
      this.dragListenerVideo = null;
      this.offsetOnStartDrag = null;
      this.dragDiff = null;

      // Scrolling & zooming
      this.scrolling = false;
      this.totalScroll = null;
      this.totalZoom = 0;
      this.lastPointer = null;
      this.showZoomHint = false;
      this.showRepresentation = false;
      this._hideZoomHintDebounced = debounce(this._hideZoomHint, ZOOM_HINT_DURATION);
      this._resetScroll();
    }


    $onInit() {
      if(this.isViewportAllowed) {
        let $elemTile = this.$elem.find('.tile:not(.tile--inactive)');
        this.$elemBody = $elemTile.find('.tile__body');
        this.$elemVideo = $elemTile.find('.tile__video');

        this.$elemBody.on('wheel', this._onScroll);
        $document.on('keydown', this._onKeyDown);
        $document.on('keyup', this._onKeyUp);

        this.dragListenerVideo = new DragListener(this.$elemBody[0], {
          onStart: this._onVideoStartDrag,
          onDrag: this._onVideoDrag,
          preventDefault: false,
        });
        this.tile.on('viewport', this._draw);
        this._draw();
      }

      super.$onInit();
    }

    $onDestroy() {
      if(this.isViewportAllowed) {
        this.tile.off('viewport', this._draw);
        $document.off('keydown', this._onKeyDown);
        $document.off('keyup', this._onKeyUp);
      }
      super.$onDestroy();
    }


    get zoomLevelStr() {
      return Math.round(this.tile.zoomLevel * 100) + '%';
    }
    get showMirrorOverlay() {
      return (
        this.tile.streams.video.isLocal
        && !this.recorderService.recording
        && !this.mirrorOverlayDisabled
      );
    }

    get isViewportAllowed() {
      // Subclasses can override this.
      return true;
    }
    get shouldAllowGestures() {
      return (
        this.tile.isViewportEnabled
        && this.browserService.isDesktop
      );
    }

    /**
     * handle a keypress event to control a PTZ camera, unless:
     *  - the active element is an input field or textarea
     *  - there is no PTZ-supported video stream
     *  - the keydown event is from a button being held down
     *
     * @param {KeyboardEvent} $event
     */
    _onKeyDown($event) {
      let el = document.activeElement;

      if(
        (el
        && (
          el.tagName.toLowerCase() === 'input'
          || el.tagName.toLowerCase() === 'textarea'
        ))
        || !this.tile.streams.video
        || !this.tile.streams.video.supportsPTZ
        || $event.originalEvent.repeat
      ) {
        return;
      }

      switch($event.code) {
        case 'KeyJ':
        case 'ArrowLeft':
          this.tile.startContinuousScroll('left');
          break;
        case 'KeyK':
        case 'ArrowUp':
          this.tile.startContinuousScroll('up');
          break;
        case 'KeyL':
        case 'ArrowDown':
          this.tile.startContinuousScroll('down');
          break;
        case 'Semicolon':
        case 'ArrowRight':
          this.tile.startContinuousScroll('right');
          break;
        case 'KeyI':
          this.tile.zoomIn();
          break;
        case 'KeyO':
          this.tile.zoomOut();
          break;
      }
    }

    _onKeyUp() {
      this.tile.stopContinuousScroll();
    }


    /***************************
   * Pan using video element *
   ***************************/

    _onVideoStartDrag() {
      if(!this.shouldAllowGestures) {
        return;
      }

      this.offsetOnStartDrag = Object.assign({}, this.tile.offset);
    }


    _onVideoDrag(diff) {
      if(!this.shouldAllowGestures) {
        return;
      }

      this.tile.doScroll(diff[0], this.offsetOnStartDrag);
    }



    /*******************
     * Scroll and zoom *
     *******************/

    _resetScroll() {
      this.totalScroll = {
        x: 0,
        y: 0,
      };
      this.totalZoom = 0;
    }


    _onScroll($event) {
      if(!this.shouldAllowGestures) {
        return;
      }

      let delta = normalizeWheel($event);
      let shouldDoScrollAndZoom = false;

      if($event.ctrlKey) {
        this.totalZoom += delta.y;
        shouldDoScrollAndZoom = true;

      } else if(this.tile.zoomLevel === 1 && !this.tile.streams.video.supportsPTZ) {
        this._showZoomHint();

      } else {
        this.totalScroll.x += $event.shiftKey ? delta.y : delta.x;
        this.totalScroll.y += $event.shiftKey ? delta.x : delta.y;
        shouldDoScrollAndZoom = true;
      }

      if(shouldDoScrollAndZoom) {
        $event.preventDefault();
        this.lastPointer = {
          x: $event.pageX,
          y: $event.pageY,
        };

        if(this.scrolling) {
          window.requestAnimationFrame(this._doScrollAndZoom);
        } else {
          this._doScrollAndZoom();
        }
      }
    }



    _doScrollAndZoom() {
      if(this.totalScroll.x !== 0 || this.totalScroll.y !== 0 || this.totalZoom !== 0) {
        this.scrolling = true;

        let scroll = this.totalScroll;
        let pointer = this.lastPointer;
        let zoom = this.totalZoom;
        this._resetScroll();

        this.$scope.$evalAsync(() => {
          if(scroll.x || scroll.y) {
            this.tile.doScroll({ x: -scroll.x, y: -scroll.y });
          }

          if(zoom) {
            let rectBody = Rect.fromElem(this.$elemBody[0]);
            let mouseOffset = {
              x: (pointer.x - rectBody.left) / rectBody.width,
              y: (pointer.y - rectBody.top)  / rectBody.height,
            };
            if(this.tile.isViewportMirrored) {
              mouseOffset.x = 1 - mouseOffset.x;
            }
            this.tile.doZoom(-zoom, mouseOffset);
            this._hideZoomHint();
          }
        });

      } else {
        this.scrolling = false;
      }
    }


    /**
     * called when a 'viewport' event is received
     */
    _draw() {
      super._draw();

      if(!this.$elemVideo) {
        return;
      }

      let tile = this.tile;
      let stream = this.tile.streams.video;
      if(!stream || stream.supportsPTZ) {
        return;
      }
      if(!this.isViewportAllowed) {
        return;
      }

      let size = {
        x: tile.rect.width  - tile.sidebarWidth - 2 * tile.borderWidth,
        y: tile.rect.height - tile.headerHeight - 2 * tile.borderWidth,
      };
      let scale = {
        x: tile.zoomLevel,
        y: tile.zoomLevel,
      };
      let offset = {
        x: -tile.offset.x * scale.x * size.x,
        y: -tile.offset.y * scale.y * size.y,
      };

      if(this.tile.isViewportMirrored) {
        scale.x = -scale.x;
        offset.x = size.x - offset.x;
      }

      this.$elemVideo.css('transform', format(
        'translate(%spx, %spx) scale(%s, %s)',
        offset.x, offset.y,
        scale.x, scale.y
      ));
    }


    _showZoomHint() {
      this._hideZoomHintDebounced();
      if(!this.showZoomHint) {
        this.$scope.$evalAsync(() => this.showZoomHint = true);
      }
    }

    _hideZoomHint() {
      this.showZoomHint = false;
    }
  };
}
