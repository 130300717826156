/**
 * Deserialize a string into an enum, falling back to `undefined` if the key is not valid.
 *
 * Example:
 * enum MyEnum { A, B, C };
 * const s: string = getUntrustedString();
 * const enumValue = deserializerEnum(MyEnum, s, MyEnum.A);
 */
export function validateEnum<T>(
  EnumCls: T,
  key: string | null | undefined,
): T[keyof T] | undefined {
  if((<any>Object).values(EnumCls).includes(key)) {
    return key as unknown as T[keyof T];
  } else {
    return undefined;
  }
}


/**
 * Deserialize a string into an enum, falling back to a default value if the key is not valid.
 *
 * Example:
 * enum MyEnum { A, B, C };
 * const s: string = getUntrustedString();
 * const enumValue = deserializerEnum(MyEnum, s, MyEnum.A);
 */
export function validateEnumWithDefault<T>(
  EnumCls: T,
  key: string | null | undefined,
  defaultValue: T[keyof T],
): T[keyof T] {
  const value = validateEnum(EnumCls, key);
  return value === undefined ? defaultValue : value;
}


export function validateEnumList<T>(
  EnumCls: T,
  keys: Array<string | null | undefined>,
): Array<T[keyof T]> {
  const values = keys
    .map(key => validateEnum(EnumCls, key))
    .filter((value): value is T[keyof T] => !!value);
  return values;
}
