import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogoModule } from 'utils/ui-components/logo';
import { UtilsModule } from 'utils/utils.module';
import { LinkedItemPreviewComponent } from './linked-item-preview.component';

@NgModule({
  imports: [
    CommonModule,
    LogoModule,
    UtilsModule,
  ],
  declarations: [
    LinkedItemPreviewComponent,
  ],
  exports: [
    LinkedItemPreviewComponent,
  ],
})
export class LinkedItemPreviewModule {}
