import { logger } from 'utils/util';


export default class FullscreenService {
  constructor() {
    this._bind();
  }

  _bind() {
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }


  get supportsFullscreen() {
    let elem = document.documentElement;
    return (
      elem.requestFullscreen
      || elem.webkitRequestFullScreen
      || elem.mozRequestFullScreen
      || elem.msRequestFullscreen
    );
  }


  get fullscreen() {
    if(
      document.fullscreen
      || document.webkitIsFullScreen
      || document.mozFullScreen
      || !!document.msFullscreenElement
    ) {
      return true;
    } else {
      return false;
    }
  }


  toggleFullscreen() {
    if(this.fullscreen) {
      this.exitFullscreen();
    } else {
      this.enterFullscreen();
    }
  }


  enterFullscreen() {
    try {
      this._enterFullscreenUnsafe();
    } catch(e) {
      logger.warn(e);
    }
  }

  _enterFullscreenUnsafe() {
    let elem = document.documentElement;
    if(elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if(elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if(elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if(elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }

    // iOS Safari doesn't do this by itself
    $timeout(() => window.dispatchEvent(new CustomEvent('resize')), 1000);
  }


  exitFullscreen() {
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if(document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if(document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if(document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
