// These variables should always be kept up to date with the first lines of _variables.scss.
// This is not very ideal, but it's only a few variables, and there's no standardized solution to
// sharing variables between sass and javascript yet.

export const TOOLTIP_SPACING = 6;
export const MODAL_ATTENTION_DURATION = 200;
// Changed to 425px on 5/11/2020 because it seems a bug in Google Chrome on Android
// does not use the correct media queries when the device width is exactly 424px (as on an
// Honor 8X phone. Chrome version: 86.0.4240.110
// Changed to 433px on 14/01/2021 because the motorola G9 plus showed in desktop mode
export const PHONE_MAX_SIZE = 433;


export const GRID_BREAKPOINTS = Object.freeze({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
});
