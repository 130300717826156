<div class="border-round bg-primary-100 p-4">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="text-h4" i18n>summary</div>

    <div *ngIf="summary !== null" class="d-flex">
      <button class="btn btn--square btn--primary-bg mr-2" (click)="copyToClipboard()">
        <svg-icon class="btn__icon" [svgName]="'copy'"></svg-icon>
      </button>
      <div
        #tooltip="matTooltip"
        i18n-matTooltip
        matTooltip="Copied to clipboard">
      </div>

      <button
        class="btn btn--square btn--primary-bg"
        (click)="$event.preventDefault()"
        [cdkMenuTriggerFor]="actions"
      >
        <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
      </button>
      <ng-template #actions>
        <share-menu 
          [content]="summary.asPlainText"
          [email]="true"
        ></share-menu>
      </ng-template>
    </div>
  </div>

  <div class="d-flex flex-column mt-3">
    <div
      [ngClass]="{
        'collapsed': isCollapsed,
        'collapsed__mask': isCollapsed && canCollapse
      }"
      [innerHTML]="summary?.asHtml"
      #summaryContainer
    ></div>
    <button
      *ngIf="canCollapse"
      class="btn btn--sm btn--primary-bg m-2 m-auto"
      (click)="toggleCollapsed()"
    >
      <div *ngIf="isCollapsed">
        <span i18n>Show more</span>
      </div>
      <div *ngIf="!isCollapsed">
        <span i18n>Show less</span>
      </div>
      <svg-icon
        class="btn__icon btn__icon--right ml-2"
        [svgName]="isCollapsed ? 'chevron_down' : 'chevron_up'">
      </svg-icon>
    </button>
  </div>
</div>
