
<div class="card">
  <h4>
    <span i18n>Meeting with {{ appointment.host.fullName }}:</span>
    <span *ngIf="appointment.isCancelled" class="mr-2 badge badge--error" i18n>Canceled</span>
  </h4>

  <div class="d-flex align-items-center p">
    <svg-icon class="icon mr-3" svgName="clock"></svg-icon>
    <div>
      <span>{{ appointment.start | readableDate:true | capitalize }}, {{ appointment.start | localeTime }} – {{ appointment.end | localeTime }}</span>

    </div>
  </div>
  <div class="d-flex align-items-center p">
    <svg-icon class="icon mr-3" [svgName]="'marker'"></svg-icon>
    <div>
        {{ appointmentLocation }}
    </div>
  </div>

  <h4 i18n>Participants</h4>

  <div class="d-flex align-items-center p">
    <svg-icon class="icon mr-3" [svgName]="'user'"></svg-icon>
    <div>{{ appointment.host.fullName }}<span class="ml-2 badge badge--primary" i18n>Host</span></div>
  </div>

  <div *ngFor="let guest of appointment.guests">
    <div class="d-flex align-items-center p">
      <svg-icon class="icon mr-3" [svgName]="'user'"></svg-icon>
      <div>{{ guest.firstName ? guest.fullName : guest.email }} <span class="ml-2 badge" i18n>Guest</span></div>
    </div>
  </div>
</div>
