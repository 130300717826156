export default class InitRun {
  static get $inject() {
    return [
      '$rootScope',
      '$timeout',
      '$interval',
      '$q',
      '$document',
      'throttle',
      'debounce',
    ];
  }

  constructor(
    $rootScope,
    $timeout,
    $interval,
    $q,
    $document,
    throttle,
    debounce
  ) {
    // This is a shortcut so we don't have to inject these utilities everywhere
    // This would be problematic when there are multiple apps on a single page, which is not the
    // case for us.
    window.$rootScope = $rootScope;
    window.$timeout = $timeout;
    window.$interval = $interval;
    window.$q = $q;
    window.$document = $document;
    window.throttle = throttle;
    window.debounce = debounce;

    // Allow using some global variables in all templates
    $rootScope.window = window;
    $rootScope.URLS = window.URLS;
  }

}
