<div class="field field--marketing d-flex">
  <field-icon [type]="'marketing'"></field-icon>
  <div i18n>Enjoying Smart Summary during your trial? Upgrade now to enjoy even more free hours!</div>
  <a
    [href]="subscriptionLink"
    class="btn btn--outline-marketing ml-4"
    target="_blank"
    i18n
  >Upgrade now</a>
</div>
