import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from 'utils/state.service';
import { Appointment } from 'scheduling/models/Appointment';
import { getQueryParameter, logger, errors } from 'utils/util';
import { UrlService } from 'utils/url.service';

@Component({
  selector: 'appointment-manage',
  templateUrl: './appointment-manage.component.html',
})
export class AppointmentManageComponent implements OnInit {

  public appointment: Appointment | null;
  public isCancelling = false;
  public hasActivelyCancelled = false;
  private apiToken: string | null;
  public hasPermission = true;

  constructor(
    @Inject('modelFactory') private modelFactory,
    @Inject('notificationService') private notificationService,

    public stateService: StateService,
    public urlService: UrlService,
  ) {
    this.stateService.setState(this.stateService.State.LOADING);
    this.apiToken = getQueryParameter('token');
  }

  ngOnInit(): void {
    this.fetchAppointment();
  }


  private async fetchAppointment() {
    const apiConfig = {
      params: {
        token: this.apiToken,
      },
    };

    try {
      const response = await this.modelFactory.read({
        model: Appointment,
        identifiers: { id: ANGULAR_SCOPE.appointmentId },
        include: ['meeting', 'host', 'guest', 'guests', 'meetingType'],
      }, apiConfig);
      this.appointment = response.data as Appointment;
    } catch(error: any) {
      if(error.constructor === errors.UnauthorizedError) {
        this.hasPermission = false;
      }
      this.appointment = null;
    } finally {
      this.stateService.setState(this.stateService.State.READY);
    }
  }

  get isAllowedToManage() {
    if(this.appointment == null && this.hasPermission) {
      return true;
    }
    return this.appointment?.meetingType.allowGuestCancellationReschedule && this.hasPermission;
  }

  async cancel() {
    try {
      this.isCancelling = true;
      await this.appointment.cancel(true, true, this.apiToken);
      this.hasActivelyCancelled = true;
    } catch(error) {
      this.notificationService.warning(
        $localize `Something went wrong while canceling your appointment.`
      );
      logger.error(error);
    } finally {
      this.isCancelling = false;
    }
  }

  get rescheduleLink() {
    // eslint-disable-next-line max-len
    return `${this.urlService.urls.appointmentTypeUrl}?skipSingleMeetingType&rescheduledMeetingId=${this.appointment?.id}`;
  }
}
