import { Component, OnInit } from '@angular/core';
import {
  AppointmentTypesService,
  AppointmentType,
  User
} from '../../services/appointment-types.service';
import { ViewOrganizationService } from 'utils/view-organization.service';
import { dateTime } from 'utils/util';

export interface TimeSlot {
  'start': Date,
  'end': Date
}

@Component({
  selector: 'booking-container',
  templateUrl: './booking.container.html',
  styleUrls: ['./booking.container.scss']
})
export class BookingContainer implements OnInit {
  public appointmentTypes: AppointmentType[] | null = null;
  public appointmentHost: string | null = null;
  public timeSlots: TimeSlot[] = [];
  public selectedDate = new Date();
  public scheduleMeetingStep = ScheduleMeetingFlow.SELECT_APPOINTMENT_TYPE;
  public ScheduleMeetingFlow = ScheduleMeetingFlow;

  private isTeamBooking: boolean;
  private typeSlug: string | null;

  public get loading(): boolean {
    return this.appointmentHost == null || this.appointmentTypes == null;
  }

  constructor(
    private appointmentTypesService: AppointmentTypesService,
    private viewOrganizationService: ViewOrganizationService,
  ) {
    this.isTeamBooking = ANGULAR_SCOPE.hostId === null;
    this.typeSlug = ANGULAR_SCOPE.typeSlug;
  }

  public ngOnInit(): void {
    dateTime.initializeLocale();

    this.initializeAppointmentHost();
    this.loadAppointmentTypes();
    this.timeSlots = this.generateRandomTimeSlots();
  }

  private initializeAppointmentHost(): void {
    if(this.isTeamBooking) {
      this.appointmentHost = this.viewOrganizationService.organization.name;
    } else {
      this.appointmentTypesService.getAppointmentHost(ANGULAR_SCOPE.hostId).subscribe(
        (host: User) => {
          this.appointmentHost = host.fullName;
        }
      );
    }
  }

  private loadAppointmentTypes(): void {
    const appointmentTypesSubscription = this.isTeamBooking ?
      this.appointmentTypesService.getOrganisationPublicAppointmentTypes(
        this.viewOrganizationService.organization.id, this.typeSlug) :
      this.appointmentTypesService.getUserPublicAppointmentTypes(
        ANGULAR_SCOPE.hostId, this.typeSlug);

    appointmentTypesSubscription.subscribe((appointmentTypes: AppointmentType[]) => {
      this.appointmentTypes = appointmentTypes;
    });
  }

  public navigateToAppointmentSelection(): void {
    this.scheduleMeetingStep = ScheduleMeetingFlow.SELECT_APPOINTMENT;
  }

  public navigateToMeetingTypes(): void {
    this.scheduleMeetingStep = ScheduleMeetingFlow.SELECT_APPOINTMENT_TYPE;
  }

  public setSelectedDate(date: Date): void {
    this.selectedDate = date;
    this.timeSlots = this.generateRandomTimeSlots();
  }

  private generateRandomTimeSlots(): TimeSlot[] {
    const timeSlots: TimeSlot[] = [];

    for(let i = 0; i < Math.ceil(Math.random() * 30); i++) {
      timeSlots.push({ start: new Date(), end: new Date() });
    }
    return timeSlots;
  }

  public setAppointmentType(): void {
    this.navigateToAppointmentSelection();
  }
}

enum ScheduleMeetingFlow {
  SELECT_APPOINTMENT_TYPE,
  SELECT_APPOINTMENT
}
