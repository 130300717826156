import ChatBox from './ChatBox';


export default class ChatBoxService {
  static get $inject() {
    return [
      '$sce',
      'userService',
      'resetService'
    ];
  }

  constructor(
    $sce,
    userService,
    resetService
  ) {
    this._bind();

    this.$sce = $sce;
    this.userService = userService;
    this.resetService = resetService;

    this.boxes = {};

    this.resetService.on('reset', this._onMeetingReset);
  }

  _bind() {
    this._onMeetingReset = this._onMeetingReset.bind(this);
  }


  get(id) {
    if(!this.boxes.hasOwnProperty(id)) {
      this.boxes[id] = new ChatBox(this.$sce, this.userService, id);
    }
    return this.boxes[id];
  }

  _onMeetingReset(resetParameters) {
    if(resetParameters['chat']) {
      Object.values(this.boxes).forEach(box => {
        box.reset();
      });
    }
  }
}
