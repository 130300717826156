import { Customization, defaultCustomization } from './customization';

export type StyleMapping = Partial<Record<keyof Customization, string>>;

const styleMapping: StyleMapping = {
  backgroundColor: '--contact-form-background-color',
  formColor: '--contact-form-form-color',
  borderColor: '--contact-form-border-color',

  buttonColor: '--contact-form-button-color',
  buttonTextColor: '--contact-form-button-text-color',
  buttonHoverColor: '--contact-form-button-hover-color',

  textColor: '--contact-form-text-color',
  fontFamily: '--contact-form-font-family',
  fontSize: '--contact-form-font-size',
  fontWeight: '--contact-form-font-weight',
  titleTextColor: '--contact-form-title-text-color',
  titleFontWeight: '--contact-form-title-font-weight',
  titleFontSize: '--contact-form-title-font-size',
  titleFontFamily: '--contact-form-title-font-family',
  linkColor: '--contact-form-link-color',
};


export function getStyleMapping(customization: Customization): StyleMapping {
  const style = {};
  for(const key in styleMapping) {
    if(!(key in styleMapping)) {
      continue;
    }
    style[styleMapping[key]] = customization[key];
  }

  style['--contact-form-font-family'] = [
    customization.fontFamily,
    defaultCustomization.fontFamily,
    ...defaultCustomization.fallbackFontFamilies,
  ].join(',');
  style['--contact-form-title-font-family'] = [
    customization.titleFontFamily,
    defaultCustomization.titleFontFamily,
    ...defaultCustomization.titleFallbackFontFamilies,
  ].join(',');
  if(style['--contact-form-font-size']) {
    style['--contact-form-font-size'] += 'px';
  }
  if(style['--contact-form-title-font-size']) {
    style['--contact-form-title-font-size'] += 'px';
  }

  return style;
}
