import { AppointmentType } from 'scheduling/models/AppointmentType';
import { User } from 'core/models/User';
import { Organization } from 'organization/models/Organization';
import { ContactForm } from 'contactForm/models/ContactForm';
import { ContactWidget } from 'contactWidget/models/ContactWidget';
import { Meeting } from 'meeting/models/Meeting';
import { Member } from 'organization/models/Member';

import { errors } from 'utils/util';

/**
 * A Contact Widget can be linked to different entities, which are implemented as ContactTypes.
 */
export enum ContactType {
  ORGANIZATION = 'organization',
  USER = 'user',
  APPOINTMENT_TYPE = 'appointmentType',
  CONTACT_FORM = 'contactForm',
  MEETING_ROOM = 'meetingRoom',
}

export enum MeetingRoomContactType {
  ROOM = 'room',
  PWR = 'personalWaitingRoom',
  TWR = 'teamWaitingRoom'
}

/**
 * A LinkedItem keeps track of the actual entity that is selected.
 */
export type LinkedItem = {
  type: ContactType
  value: User | AppointmentType | Organization | ContactForm
};


export function getLinkedItem(contactWidget: ContactWidget): LinkedItem | undefined {
  if(contactWidget.organizationId) {
    return {
      type: ContactType.ORGANIZATION,
      value: contactWidget.organization,
    };
  } else if(contactWidget.userId) {
    return {
      type: ContactType.USER,
      value: contactWidget.user,
    };
  } else if(contactWidget.appointmentTypeId) {
    return {
      type: ContactType.APPOINTMENT_TYPE,
      value: contactWidget.appointmentType,
    };
  } else if(contactWidget.contactFormId) {
    return {
      type: ContactType.CONTACT_FORM,
      value: contactWidget.contactForm,
    };
  } else if(contactWidget.meeting) {
    return {
      type: ContactType.MEETING_ROOM,
      value: contactWidget.meeting,
    };
  } else {
    return undefined;
  }
}

export function getMeetingRoomContactType(linkedItem: LinkedItem): MeetingRoomContactType {
  if(linkedItem.type !== ContactType.MEETING_ROOM) {
    throw new errors.InvalidArgumentError(
      'Cannot derive MeetingRoomContactType from ' + linkedItem.type
    );
  }

  if(linkedItem.value.isPersonalWaitingRoom) {
    return MeetingRoomContactType.PWR;
  } else if(linkedItem.value.isTeamWaitingRoom) {
    return MeetingRoomContactType.TWR;
  } else {
    return MeetingRoomContactType.ROOM;
  }
}


export function isLinkedItemActive(
  linkedItem: LinkedItem, teamMembers: Member[], requestUser: User
): boolean {
  switch(linkedItem.type) {
    case ContactType.ORGANIZATION:
      return true;

    case ContactType.USER:
      const user = (linkedItem.value as User);
      return teamMembers.filter(mem => mem.id === user.id && mem.isActive).length > 0;

    case ContactType.APPOINTMENT_TYPE:
      return (linkedItem.value as AppointmentType).active;

    case ContactType.CONTACT_FORM:
      return (linkedItem.value as ContactForm).isActive;

    case ContactType.MEETING_ROOM:
      const meetingType = getMeetingRoomContactType(linkedItem);
      switch(meetingType) {
        case MeetingRoomContactType.PWR:
          const owner = (linkedItem.value as Meeting).owner;
          return teamMembers.filter(mem => mem.id === owner.id && mem.isActive).length > 0;

        case MeetingRoomContactType.TWR:
          return requestUser.organization.teamWaitingRoomEnabled;

        default:
          // generic meeting room? Can't currently be selected as destination trought the frontend
          return true;
      }
    default:
      return false;
  }
}


export function getLinkedItemWarning(
  linkedItem: LinkedItem, teamMembers: Member[], requestUser: User
): string {
  const isActive = isLinkedItemActive(
    linkedItem, teamMembers, requestUser
  );
  if(isActive) {
    return '';
  }

  switch(linkedItem.type) {
    case ContactType.ORGANIZATION:
      return '';
    case ContactType.USER:
      // eslint-disable-next-line max-len
      return $localize `This contact widget points to a booking page of a user that has been suspended`;
    case ContactType.APPOINTMENT_TYPE:
      return $localize `This contact widget points to a meeting type that is not active`;
    case ContactType.CONTACT_FORM:
      return $localize `This contact widget points to a contact form that is not active`;
    case ContactType.MEETING_ROOM:
      const meetingType = getMeetingRoomContactType(linkedItem);
      switch(meetingType) {
        case MeetingRoomContactType.PWR:
          // eslint-disable-next-line max-len
          return $localize `This contact widget points to a waiting room of a user that has been suspended`;
        case MeetingRoomContactType.TWR:
          // eslint-disable-next-line max-len
          return $localize `This contact widget points to the team waiting room, but the team waiting room has been disabled`;
        default:
          return '';
      }
    default:
      return '';
  }
}
