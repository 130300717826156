import { raf } from 'utils/util';

/**
 * Put this on an iframe to make the iframe adjust its size automatically to its content. Does not
 * work on cross-origin iframes.
 */
export default function AutoHeight() {
  return {
    restrict: 'A',
    link: function($scope, $elem) {
      let height = 0;

      resize();

      function resize() {
        let contentDocument = $elem[0].contentDocument;
        if(contentDocument) {
          let body = contentDocument.body;
          if(body) {
            let newHeight = body.offsetHeight;
            if(newHeight !== height) {
              height = newHeight;
              $elem.css('height', height + 'px');
            }
          }
        }

        raf.requestAnimationFrame(resize);
      }
    }
  };
}
