import template from './tilesPhone.html?raw';


class TilesPhoneController {
  static get $inject() {
    return [
      '$element',
      'meetingBroadcastService',
      'sharedPointerService',
      'tileService',
      'tileRendererPhoneService',
    ];
  }

  constructor(
    $elem,
    meetingBroadcastService,
    sharedPointerService,
    tileService,
    tileRendererPhoneService
  ) {
    this.$elem = $elem;
    this.meetingBroadcastService = meetingBroadcastService;
    this.sharedPointerService = sharedPointerService;
    this.tileService = tileService;
    this.tileRendererPhoneService = tileRendererPhoneService;

    meetingBroadcastService.afterInitialization()
      .then(() => tileRendererPhoneService.setElem($elem));
  }

  get activeTiles() {
    return this.meetingBroadcastService.initializing ?
      [] :
      this.tileRendererPhoneService.activeTiles;
  }

  get floatingTile() {
    return this.tileRendererPhoneService.floatingTile.layout.tiles.length > 0 ?
      this.tileRendererPhoneService.floatingTile :
      null;
  }
}


export default {
  controller: TilesPhoneController,
  controllerAs: 'tilesPhoneCtrl',
  template,
};
