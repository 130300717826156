import { EventEmitter, errors } from 'utils/util';
import Session from './Session';


export default class LocalSession extends Session {
  constructor(meetingService, ...args) {
    super(...args);
    this._bind();
    EventEmitter.setup(this, ['accessLevel', 'isSpectator']);

    this.meetingService = meetingService;

    this.isLocal = true;
    this.isSpectator = false;

    meetingService.settings.on('presenterMode', this._updateIsSpectator);
    this.on('accessLevel', this._updateIsSpectator);
    this._updateIsSpectator();
  }

  _bind() {
    this._updateIsSpectator = this._updateIsSpectator.bind(this);
  }


  get isInitialized() {
    return this._id != null;
  }
  get id() {
    if(!this.isInitialized) {
      throw new errors.IllegalStateError('The session has not been initialized');
    }
    return this._id;
  }
  set id(id) {
    this._id = id;
  }


  _updateIsSpectator() {
    let isSpectator = !this.accessLevel.isHost && this.meetingService.settings.presenterMode;
    if(isSpectator !== this.isSpectator) {
      this.isSpectator = isSpectator;
      this.emit('isSpectator', isSpectator);
    }
  }


  setAccessLevel(accessLevel) {
    if(accessLevel !== this.accessLevel) {
      super.setAccessLevel(accessLevel);
      this.emit('accessLevel', accessLevel);
    }
  }
}
