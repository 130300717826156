<div>
  <p i18n>
    This meeting is being transcribed.
    By staying in this meeting, you consent to being transcribed.
  </p>

  <div class="d-flex justify-content-end">
    <div
      class="btn mr-2"
      (click)="leaveMeeting()"
      [modalClose]="false"
      i18n
    >
      Leave meeting room
    </div>

    <div
      class="btn btn--primary"
      [modalClose]="true"
      i18n
    >
      I understand
    </div>
  </div>
</div>
