import { MeetingState } from '../../../meeting-room/meeting.service';
import Session from '../../main/users/Session';
import { bind, format } from 'utils/util';


export default class EndCallService {
  static get $inject() {
    return [
      'meetingService',
      'notificationService',
      'apiService',
      'userService',
      'clearContentService',
      'notesSyncWarningService',
    ];
  }

  constructor(
    meetingService,
    notificationService,
    apiService,
    userService,
    clearContentService,
    notesSyncWarningService
  ) {
    bind(this);

    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.apiService = apiService;
    this.userService = userService;
    this.clearContentService = clearContentService;
    this.notesSyncWarningService = notesSyncWarningService;

    this.notesSyncModalDismissed = false;
  }

  endCall() {
    if(!this._showModalIfNeeded(this.endCall)) {
      this._setMeetingStateEnded();
    }
  }

  endCallForEveryone() {
    if(!this._showModalIfNeeded(this.endCallForEveryone)) {
      this._endCallRequest();
    }
  }

  endCallForEveryoneButMe() {
    this._endCallRequest(
      [this.userService.mySession.id]
    );
  }

  _showModalIfNeeded(callback) {
    if(this.notesSyncWarningService.shouldShow()) {
      this.notesSyncWarningService.showModal(callback);
      return true;
    } else if(this.clearContentService.modalEnabled) {
      this.clearContentService.showModal(true, callback);
      return true;
    } else {
      return false;
    }
  }

  _setMeetingStateEnded() {
    this.meetingService.setState(MeetingState.ENDED, Session.ExitReason.EXIT);
  }

  _endCallRequest(excludedSessionIds) {
    let path = format('meetings/%s/endCall/', this.meetingService.id);
    let data = {
      'excludedSessionIds': excludedSessionIds
    };
    this.apiService.patch(path, data)
      .catch((error) => {
        this.notificationService.error(
          error.message
          || gettextCatalog.getString('Something went wrong')
        );
      });
  }
}
