import { Component, Input } from '@angular/core';


@Component({
  selector: 'truncated-text',
  templateUrl: './truncated-text.component.html',
})
export class TruncatedTextComponent {
  @Input() length = 20;
  @Input() text = '';
}
