import template from './meeting.html?raw';
import { browser, logger, bind } from 'utils/util';
import { Sentry } from 'utils/util/logger';


class MeetingController {
  // All these services are necessary to fully initialize a meeting room. These used to be run
  // using `angular.module(...).run(...)` statements, but we can no longer do this since the
  // the dashboard and meeting code co-exist in a single bundle.
  // The current approach, with ~40 injected services in a single place, is obviously suboptimal.
  // This needs to be refactored in the future, for example by injecting them in a related
  // component instead. Problem is: not all related functionality really has a "component" (e.g.
  // whiteboards, users). Maybe we could solve this by creating a general "whiteboardService", that
  // doesn't really have any functionality, but simply injects all services that are needed to
  // bootstrap the entire whiteboard functionality.
  static get $inject() {
    return [
      'browserService',
      'canvasPolyfillService',
      'cobrowseService',
      'desktopNotificationService',
      'dropdownService',
      'fabricToolsSyncService',
      'knockerService',
      'loadingModalService',
      'mainChatNotifyService',
      'mainChatSyncService',
      'maxUsersNotificationService',
      'mediaDeviceNotificationService',
      'meetingBroadcastService',
      'meetingEndedService',
      'meetingSnapshotService',
      'meetingSyncService',
      'notifyService',
      'notSupportedService',
      'pasteService',
      'peerConnectionService',
      'privateMessagePeerConnectionsService',
      'screenshareService',
      'sessionNotificationsService',
      'settingsService',
      'shapeSyncService',
      'silentDeviceNotificationService',
      'snapshotService',
      'streamsRequestableService',
      'tileService',
      'unloadService',
      'userInfoService',
      'userNotifyService',
      'userService',
      'userStreamService',
      'userSyncService',
      'userTileService',
      'videoQualityExperimentService',
      'videoViewportService',
      'whiteboardAutoNameService',
    ];
  }


  constructor(
    browserService,
    canvasPolyfillService,
    cobrowseService,
    desktopNotificationService,
    dropdownService,
    fabricToolsSyncService,
    knockerService,
    loadingModalService,
    mainChatNotifyService,
    mainChatSyncService,
    maxUsersNotificationService,
    mediaDeviceNotificationService,
    meetingBroadcastService,
    meetingEndedService,
    meetingSnapshotService,
    meetingSyncService,
    notifyService,
    notSupportedService,
    pasteService,
    peerConnectionService,
    privateMessagePeerConnectionsService,
    screenshareService,
    sessionNotificationsService,
    settingsService,
    shapeSyncService,
    silentDeviceNotificationService,
    snapshotService,
    streamsRequestableService,
    tileService,
    unloadService,
    userInfoService,
    userNotifyService,
    userService,
    userStreamService,
    userSyncService,
    userTileService,
    videoQualityExperimentService,
    videoViewportService,
    whiteboardAutoNameService
  ) {
    bind(this);

    this.browserService = browserService;
    this.canvasPolyfillService = canvasPolyfillService;
    this.cobrowseService = cobrowseService;
    this.desktopNotificationService = desktopNotificationService;
    this.dropdownService = dropdownService;
    this.fabricToolsSyncService = fabricToolsSyncService;
    this.knockerService = knockerService;
    this.loadingModalService = loadingModalService;
    this.mainChatNotifyService = mainChatNotifyService;
    this.mainChatSyncService = mainChatSyncService;
    this.maxUsersNotificationService = maxUsersNotificationService;
    this.mediaDeviceNotificationService = mediaDeviceNotificationService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingEndedService = meetingEndedService;
    this.meetingSnapshotService = meetingSnapshotService;
    this.meetingSyncService = meetingSyncService;
    this.notifyService = notifyService;
    this.notSupportedService = notSupportedService;
    this.pasteService = pasteService;
    this.peerConnectionService = peerConnectionService;
    this.privateMessagePeerConnectionsService = privateMessagePeerConnectionsService;
    this.screenshareService = screenshareService;
    this.sessionNotificationsService = sessionNotificationsService;
    this.settingsService = settingsService;
    this.shapeSyncService = shapeSyncService;
    this.silentDeviceNotificationService = silentDeviceNotificationService;
    this.snapshotService = snapshotService;
    this.streamsRequestableService = streamsRequestableService;
    this.tileService = tileService;
    this.unloadService = unloadService;
    this.userInfoService = userInfoService;
    this.userNotifyService = userNotifyService;
    this.userService = userService;
    this.userStreamService = userStreamService;
    this.userSyncService = userSyncService;
    this.userTileService = userTileService;
    this.videoQualityExperimentService = videoQualityExperimentService;
    this.videoViewportService = videoViewportService;
    this.whiteboardAutoNameService = whiteboardAutoNameService;


    // Allow using some global variables in all templates
    $rootScope.ALERTS = window.ALERTS;
    $rootScope.browser = browser;

    this.browserService.on('deviceType', () => {
      this.dropdownService.hideAll();
    });

    this.userService.mySession.on('join knockJoin', this.onJoin);

    this.meetingBroadcastService.afterInitialization().then(() => {
      this.notifyService.setNotificationSound(window.URLS.notificationSound);
    });
  }


  onJoin() {
    this.userService.mySession.off('join knockJoin', this.onJoin);
    Sentry.setExtra('sessionId', this.userService.mySession.id);
    logger.info('Session:', this.userService.mySession.id);
  }
}

export default {
  controller: MeetingController,
  controllerAs: 'meetingCtrl',
  template,
};
