import { format, errors, object, array, logger } from 'utils/util';
import Shape from './Shape';
import fabric from './fabric';
import { buildText, serializePath, normalizePath } from './parser';
import { PAPER_OBJECT_MARGIN } from '../whiteboard.service';
import { WHITEBOARD_ERASER_SIZE } from '../../../variables';


const FORMAT_EVENTS = Object.freeze({
  pencil: ['pencilColor', 'pencilSize'],
  marker: ['markerColor', 'markerSize'],
  geometric: ['geometricColor', 'geometricSize', 'geometricShape'],
  text: ['textColor', 'textSize', 'textBold', 'textItalic', 'textUnderline'],
  formula: ['formulaColor', 'textSize'],
});
const SYNC_TYPING_THROTTLE = 1000;


const TOOL_EVENT_LISTENERS = Object.freeze({
  cursor: [
    ['object:selected',             '_onObjectSelected'],
    ['selection:cleared',           '_onObjectDeselected'],
    // Formula and text
    ['mouse:down',                  '_onTextFormulaSelectMouseDown'],
    ['text:editing:entered',        '_onTextFormulaEditingEntered'],
    ['text:editing:exited',         '_onTextFormulaEditingExited'],
    ['text:changed',                '_onTextFormulaChanged'],
    // Formula
    ['text:changed',                '_onFormulaTextboxChanged'],
    ['object:moving',               '_onFormulaMoving'],
  ],
  pan: [],
  pencil: [
    ['mouse:down',                  '_onDrawingMouseDown'],
    ['path:created',                '_onPathCreatedPencil'],
  ],
  marker: [
    ['mouse:down',                  '_onDrawingMouseDown'],
    ['path:created',                '_onPathCreatedMarker'],
  ],
  eraser: [
    ['mouse:down',                  '_onEraserMouseDown'],
  ],
  geometric: [
    ['mouse:down',                  '_onDrawingMouseDown'],
    ['mouse:down',                  '_onGeometricMouseDown'],
  ],
  text: [
    // General
    ['object:selected',             '_onObjectSelected'],
    ['selection:cleared',           '_onObjectDeselected'],
    ['mouse:down',                  '_onDrawingMouseDown'],
    // Text and formula shared
    ['before:selection:cleared',    '_onTextFormulaBeforeSelectionCleared'],
    ['mouse:down',                  '_onTextFormulaCreateMouseDown'],
    ['mouse:down',                  '_onTextFormulaSelectMouseDown'],
    ['text:editing:entered',        '_onTextFormulaEditingEntered'],
    ['text:editing:exited',         '_onTextFormulaEditingExited'],
    ['text:changed',                '_onTextFormulaChanged'],
  ],
  formula: [
    // General
    ['object:selected',             '_onObjectSelected'],
    ['selection:cleared',           '_onObjectDeselected'],
    ['mouse:down',                  '_onDrawingMouseDown'],
    // Text and formula shared
    ['before:selection:cleared',    '_onTextFormulaBeforeSelectionCleared'],
    ['mouse:down',                  '_onTextFormulaCreateMouseDown'],
    ['mouse:down',                  '_onTextFormulaSelectMouseDown'],
    ['text:editing:entered',        '_onTextFormulaEditingEntered'],
    ['text:editing:exited',         '_onTextFormulaEditingExited'],
    ['formula:changed',             '_onTextFormulaChanged'],
    // Formula specific
    ['text:changed',                '_onFormulaTextboxChanged'],
    ['object:moving',               '_onFormulaMoving'],
  ],
});


const TOOL_CURSOR_TYPE = Object.freeze({
  cursor: 'default',
  pan: null,
  pencil: 'crosshair',
  marker: 'crosshair',
  eraser: 'default',
  geometric: 'crosshair',
  text: 'text',
  formula: 'text',
});


const TOOL_REMEMBER_MAX_DRAWING_BOUNDS = Object.freeze({
  cursor: false,
  pan: false,
  pencil: true,
  marker: true,
  eraser: false,
  geometric: false,
  text: false,
  formula: false,
});



export default class FabricRenderer {
  constructor(
    chromeExtensionService,
    clipboard,
    contextMenuService,
    editingUserService,
    fabricShapeFactory,
    notificationService,
    toolService,
    whiteboard,
    paperRenderer
  ) {
    this._bind();

    this.toolService = toolService;
    this.editingUserService = editingUserService;
    this.contextMenuService = contextMenuService;
    this.fabricShapeFactory = fabricShapeFactory;
    this.clipboard = clipboard;
    this.chromeExtensionService = chromeExtensionService;
    this.notificationService = notificationService;

    this.whiteboard = whiteboard;
    this.paperRenderer = paperRenderer;
    this.paperRenderer.fabricRenderer = this;

    this.canvas = null;
    this.canvasInactive = null;
    this.shouldRenderFromScratch = true;

    this.$elemPaper = null;
    this.$elemInactiveImage = null;
    this.renderImagePromise = $q.resolve();

    this.shapes = {};
    this.tool = null;
    this.clearEvent = null;
    this.drawingOptions = null;
    this.drawingPointerStart = null;

    this._syncTyping = throttle(this._onObjectModified, SYNC_TYPING_THROTTLE, true);

    this.formatListeners = [];
    object.forEach(FORMAT_EVENTS, (tool, events) => {
      events.forEach(event => {
        this.formatListeners.push([event, this._onFormat.bind(this, tool, event)]);
      });
    });

    this.whiteboard.on('page', this._setPage);
    this.paperRenderer.on('elem', this._setElem);
    this.paperRenderer.on('destroy', this._destroy);
  }

  _bind() {
    this._setElem = this._setElem.bind(this);
    this._destroy = this._destroy.bind(this);
    this._onActive = this._onActive.bind(this);
    this._onViewport = this._onViewport.bind(this);

    this._setEditingUser = this._setEditingUser.bind(this);
    this._unsetEditingUser = this._unsetEditingUser.bind(this);

    this._setTool = this._setTool.bind(this);
    this._setPage = this._setPage.bind(this);
    this._render = this._render.bind(this);
    this._renderFromScratch = this._renderFromScratch.bind(this);
    this._renderShapes = this._renderShapes.bind(this);
    this._onFontLoaded = this._onFontLoaded.bind(this);
    this._scrollIntoView = this._scrollIntoView.bind(this);

    this._onShapeAdd = this._onShapeAdd.bind(this);
    this._onShapeRemove = this._onShapeRemove.bind(this);
    this._onShapesEdit = this._onShapesEdit.bind(this);
    this._onShapeEditing = this._onShapeEditing.bind(this);

    this._onObjectScaling = this._onObjectScaling.bind(this);
    this._onObjectModified = this._onObjectModified.bind(this);
    this._onObjectRotating = this._onObjectRotating.bind(this);
    this._onContainerKeyDown = this._onContainerKeyDown.bind(this);
    this._onPaperRendererBlur = this._onPaperRendererBlur.bind(this);

    this._onDrawingMouseMove = this._onDrawingMouseMove.bind(this);
    this._onDrawingMouseUp = this._onDrawingMouseUp.bind(this);
    this._onEraserMouseMove = this._onEraserMouseMove.bind(this);
    this._onEraserMouseUp = this._onEraserMouseUp.bind(this);

    this._onPathCreatedPencil = this._onPathCreated.bind(this, 'pencil');
    this._onPathCreatedMarker = this._onPathCreated.bind(this, 'marker');

    this._onGeometricMouseMove = this._onGeometricMouseMove.bind(this);
    this._onGeometricMouseUp = this._onGeometricMouseUp.bind(this);
    this._onTextFormulaCreateMouseMove = this._onTextFormulaCreateMouseMove.bind(this);
    this._onTextFormulaCreateMouseUp = this._onTextFormulaCreateMouseUp.bind(this);
    this._onTextFormulaSelectMouseMove = this._onTextFormulaSelectMouseMove.bind(this);
    this._onTextFormulaSelectMouseUp = this._onTextFormulaSelectMouseUp.bind(this);
    this._onContextMenu = this._onContextMenu.bind(this);


    this.toolEventListeners = {};
    for(let tool in TOOL_EVENT_LISTENERS) {
      this.toolEventListeners[tool] = [];
      TOOL_EVENT_LISTENERS[tool].forEach(listener => {
        let event = listener[0];
        let fnName = listener[1];
        let fn = this[fnName].bind(this);
        this.toolEventListeners[tool].push([event, fn]);
      });
    }
  }


  _setElem(paperRenderer, $elemWrapper) {
    this.$elemPaper = $elemWrapper.find('.tile:not(.tile--inactive) .whiteboard-tile__paper');
    this.$elemPaper.on('keydown', this._onContainerKeyDown);
    this.$elemPaper.on('contextmenu', this._onContextMenu);
    if(this.$elemPaper.length > 0) {
      this.$elemPaper[0].tabIndex = 1000;

      let $elemCanvas = angular.element('<canvas></canvas>');
      this.$elemPaper.append($elemCanvas);

      this.canvas = new fabric.Canvas($elemCanvas[0]);
      this.canvas.isDrawingMode = false;
      this.canvas.selection = false;
      this.canvas.renderOnAddRemove = false;
      this.canvas.on('object:scaling', this._onObjectScaling);
      this.canvas.on('object:modified', this._onObjectModified);
      this.canvas.on('object:rotating', this._onObjectRotating);
      this.canvas.on('text:scrollintoview', this._scrollIntoView);
    }

    this.canvasInactive = new fabric.StaticCanvas();

    let $elemPaperInactive = $elemWrapper.find(
      '.content-tile__inactive-wrapper .whiteboard-tile__paper');
    this.$elemInactiveImage = angular.element('<img class="whiteboard-tile__image" />');
    $elemPaperInactive.append(this.$elemInactiveImage);

    this._setupListeners();

    this.tool = null;
    this._setTool(this.toolService.selected.tool);

    Object.values(this.paperRenderer.shapes).forEach(origShape => {
      this._addShape(origShape, false);
    });

    this.paperRenderer.active ? this._renderFromScratch() : this._renderInactive();
  }


  _destroy() {
    this._removeListeners();

    if(this.canvas) {
      this.canvas.dispose();
      this.canvas = null;
      this.canvasInactive.dispose();
      this.canvasInactive = null;
    }
  }


  _setupListeners() {
    this._removeListeners();

    this.toolService.on('tool', this._setTool);
    this.formatListeners.forEach(listenerInfo => {
      let [event, listener] = listenerInfo;
      this.toolService.on(event, listener);
    });

    this.editingUserService.on('set', this._setEditingUser);
    this.editingUserService.on('unset', this._unsetEditingUser);

    fabric.on('fontLoaded', this._onFontLoaded);

    this.paperRenderer.on('blur', this._onPaperRendererBlur);
    this.paperRenderer.on('active', this._onActive);
    this.paperRenderer.on('viewport', this._onViewport);
    this.paperRenderer.on('shapeAdd', this._onShapeAdd);
    this.paperRenderer.on('shapeRemove', this._onShapeRemove);
    this.paperRenderer.on('shapesEdit', this._onShapesEdit);
    this.paperRenderer.on('shapeEditing', this._onShapeEditing);
  }

  _removeListeners() {
    this.toolService.off('tool', this._setTool);
    this.formatListeners.forEach(listenerInfo => {
      let [event, listener] = listenerInfo;
      this.toolService.off(event, listener);
    });

    this.editingUserService.off('set', this._setEditingUser);
    this.editingUserService.off('unset', this._unsetEditingUser);

    fabric.off('fontLoaded', this._onFontLoaded);

    this.paperRenderer.off('blur', this._onPaperRendererBlur);
    this.paperRenderer.off('active', this._onActive);
    this.paperRenderer.off('viewport', this._onViewport);
    this.paperRenderer.off('shapeAdd', this._onShapeAdd);
    this.paperRenderer.off('shapeRemove', this._onShapeRemove);
    this.paperRenderer.off('shapesEdit', this._onShapesEdit);
    this.paperRenderer.off('shapeEditing', this._onShapeEditing);
  }


  _onActive() {
    this.shouldRenderFromScratch = true;
  }

  _onViewport() {
    this.paperRenderer.active ? this._render() : this._renderInactive();
  }


  /**************
   * Setup tool *
   **************/

  _setTool(tool) {
    if(!this.canvas || tool === this.tool) {
      return;
    }

    this._getActiveShapes().some(shape => {
      if(shape.tool !== tool) {
        this.canvas.discardActiveObject().deactivateAll();
        return true;
      } else {
        return false;
      }
    });

    if(this.tool) {
      this._unsetTool(this.tool);
    }
    this.tool = tool;

    this.canvas.defaultCursor = TOOL_CURSOR_TYPE[tool];
    this._updateAllSelectable();

    this.toolEventListeners[tool].forEach(listener => {
      this.canvas.on(listener[0], listener[1]);
    });

    switch(tool) {
      case 'cursor':
        this.canvas.selection = true;
        break;

      case 'pencil':
        this.canvas.isDrawingMode = true;
        this.canvas.freeDrawingBrush.color = this.toolService.selected.pencilColor;
        this.canvas.freeDrawingBrush.width = this.toolService.selected.pencilSize;
        break;

      case 'marker':
        this.canvas.isDrawingMode = true;
        let color = this._getToolColor('marker');
        this.canvas.freeDrawingBrush.color = color;
        this.canvas.freeDrawingBrush.width = this.toolService.selected.markerSize;
        break;
    }

    this._renderShapes();
  }


  _unsetTool(tool) {
    this.toolEventListeners[tool].forEach(listener => {
      this.canvas.off(listener[0], listener[1]);
    });

    switch(tool) {
      case 'cursor':
        this.canvas.selection = false;
        break;

      case 'pencil':
      case 'marker':
        this.canvas.isDrawingMode = false;
        break;
    }
  }



  _getDeskProperties(tool) {
    switch(tool) {
      case 'pencil':
        return {
          strokeColor: this.toolService.selected.pencilColor,
          strokeWidth: this.toolService.selected.pencilSize,
        };

      case 'marker':
        return {
          strokeColor: this._getToolColor('marker'),
          strokeWidth: this.toolService.selected.markerSize,
        };

      case 'geometric':
        return {
          strokeColor: this.toolService.selected.geometricColor,
          strokeWidth: this.toolService.selected.geometricSize,
        };

      case 'formula':
        return {
          color: this.toolService.selected.formulaColor,
          fontSize: this.toolService.selected.textSize,
        };

      default:
        throw new errors.InvalidArgumentError(format('Unknown formatting tool:', tool));
    }
  }



  _onFormat(tool, key, value, apply) {
    if(!apply) {
      return;
    }

    if((this.tool === 'marker' || this.tool === 'pencil') && key === this.tool + 'Color') {
      let newValue = this._getToolColor(this.tool);
      this.canvas.freeDrawingBrush.color = newValue;
    }

    if((this.tool === 'marker' || this.tool === 'pencil') && key === this.tool + 'Size') {
      this.canvas.freeDrawingBrush.width = value;
    }

    let shapes = this._getActiveShapes();
    shapes.forEach(shape => {
      if(shape.tool === tool) {
        this._onShapeFormat(shape, key, value);
      }
    });
  }


  _onShapeFormat(shape, key, value) {
    let update;
    if(shape.type === 'text') {
      shape.setSelectionStyle(key, value);
      update = shape.getProperties();
    } else {
      update = this._getDeskProperties(shape.tool);
    }

    this.paperRenderer.editShape(shape.origShape, update);
  }


  _getToolColor(tool) {
    let color = this.toolService.getToolColor(tool);
    if(tool === 'marker') {
      color = color.replace(/rgb\((.*)\)/, 'rgba($1,0.4)');
    }
    return color;
  }

  _parseMarkerColor(color) {
    return color.replace(/rgba\((.*),[ \d.]*\)/, 'rgb($1)');
  }




  /**********************
   * General management *
   **********************/

  _scrollIntoView(position) {
    this.whiteboard.scrollIntoView(position);
  }


  _setPage() {
    if(this.paperRenderer.active) {
      this._renderFromScratch();
    }
  }


  _updateAllSelectable() {
    this.paperRenderer.activeShapes
      .map(origShape => this.shapes[origShape.id])
      .filter(shape => !!shape)
      .forEach(this._updateSelectable.bind(this));
  }


  _getActiveShapes() {
    if(this.canvas) {
      let objects = [];
      let activeObject = this.canvas.getActiveObject();
      if(activeObject) {
        objects = [activeObject];
      } else {
        let activeGroup = this.canvas.getActiveGroup();
        if(activeGroup) {
          objects = activeGroup.getObjects();
        }
      }

      return objects.map(object => object.shape).filter(angular.identity);
    } else {
      return [];
    }
  }



  /***********************
   * Object modification *
   ***********************/

  _onObjectScaling(option) {
    let object = option.target;
    let shape = object.shape;
    if(shape) {
      object.shape.onScale();
    }
  }


  _onObjectRotating(option) {
    let object = option.target;
    let tolerance = 5;

    if((object.angle + tolerance) % fabric.straightenFrequency <= 2 * tolerance) {
      object.straighten();
    }
  }


  _onObjectSelected(option) {
    this.paperRenderer.unsetEditingShape();

    let object = option.target;
    let shape = object.shape;

    if(!shape && object.isType('group') || shape && shape.type === 'formula') {
      object.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false,

        mb: false,
        ml: false,
        mr: false,
        mt: false,

        mtr: false,
      });
    } else if(shape && (shape.type === 'line' || shape.type === 'arrow')) {
      object.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false,

        mb: false,
        ml: true,
        mr: true,
        mt: false,

        mtr: true,
      });
    }

    if(shape) {
      $rootScope.$evalAsync(() => {
        this.paperRenderer.setSelectedShape(shape.origShape);
        this.toolService.set('formatTool', shape.tool);

        let properties = shape.getProperties();
        switch(shape.tool) {
          case 'pencil':
            this.toolService.set('pencilColor', properties.strokeColor, false);
            this.toolService.set('pencilSize', properties.strokeWidth, false);
            break;

          case 'marker':
            let color = this._parseMarkerColor(properties.strokeColor);
            this.toolService.set('markerColor', color, false);
            this.toolService.set('markerSize', properties.strokeWidth, false);
            break;

          case 'geometric':
            this.toolService.set('geometricColor', properties.strokeColor, false);
            this.toolService.set('geometricSize', properties.strokeWidth, false);
            break;

          case 'formula':
            this.toolService.set('formulaColor', properties.color, false);
            this.toolService.set('textSize', properties.fontSize, false);
            break;
        }
      });
    }

    this.paperRenderer.setElemActiveShape(this.$elemPaper);
  }


  _onObjectDeselected() {
    this.paperRenderer.unsetSelectedShape();
    this.paperRenderer.unsetEditingShape();
    this.paperRenderer.unsetElemActiveShape(this.$elemPaper);

    let formatTool = this.tool === 'cursor' ? null : this.tool;
    $rootScope.$evalAsync(() => this.toolService.set('formatTool', formatTool));
  }


  _onPaperRendererBlur() {
    // deactivateAll doesn't fire selection:cleared, so we need both
    // discardActiveObject and deactivateAll
    this.canvas.discardActiveObject().deactivateAll();
    this._renderShapes();
  }


  _onObjectModified(option) {
    let object = option.target;
    // Multiple objects are selected by the user
    let isGroup = (object.isType('group') && !object.hasOwnProperty('shape'));
    let objects = isGroup ? object.getObjects().slice() : [object];

    for(let i = 0; i < objects.length; i++) {
      let shape = objects[i].shape;
      if(shape) {
        this._onShapeModified(shape);
      }
    }
  }


  _onShapeModified(shape) {
    if(shape.object.isEditing || !shape.origShape.isEmpty()) {
      this.paperRenderer.editShape(shape.origShape, shape.getProperties());
    } else {
      this.paperRenderer.removeShape(shape.origShape);
    }
  }


  _onContainerKeyDown(option) {
    // Remove objects with the keyboard
    let keyPressed = option.key ? option.key.toLowerCase() : null;

    let activeObject = this.canvas.getActiveObject();
    if(activeObject) {
      if(!activeObject.isEditing) {
        this._onKeyDown(activeObject, keyPressed, true);
      }

    } else {
      let activeGroup = this.canvas.getActiveGroup();
      if(activeGroup) {
        let objects = activeGroup.getObjects().slice();
        if(keyPressed === 'backspace' || keyPressed === 'delete') {
          this.canvas.discardActiveGroup();
        }

        for(let i = 0; i < objects.length; i++) {
          this._onKeyDown(objects[i], keyPressed, false);
        }
      }
    }
  }


  _onKeyDown(object, keyPressed, allowEditing) {
    if(!object.shape) {
      return;
    }

    if(keyPressed === 'backspace' || keyPressed === 'delete') {
      this.paperRenderer.removeShape(object.shape.origShape);

    } else if(allowEditing && object.shape.type === 'text') {
      object.selectionStart = object.text.length;
      object.selectionEnd = object.text.length;
      object.enterEditing();
    }
  }


  _onContextMenu($event) {
    let object = this.canvas.getActiveObject();

    if(object && object.shape && object.shape.type === 'text' && object.isEditing) {
      let items = [];
      if(object.selectionStart !== object.selectionEnd) {
        items.push(
          { label: 'Copy', callback: this.copy.bind(this, object) },
          { label: 'Cut', callback: this.cut.bind(this, object) }
        );
      }
      items.push({ label: 'Paste', callback: this.paste.bind(this, object) });

      this.contextMenuService.show(items, $event);
    }
  }


  copy(object) {
    this.clipboard.copyText(object.getSelectedText());
    object.copy(new window.ClipboardEvent('copy'));
  }


  cut(object) {
    this.clipboard.copyText(object.getSelectedText());
    object.cut(new window.ClipboardEvent('cut'));
  }


  paste(object) {
    this._getPastedText()
      .then(text => {
        if(!text) {
          return;
        }

        // Chrome does not support a synthetic paste clipboardevent
        let simEvent = {
          stopImmediatePropagation: angular.noop,
          preventDefault: angular.noop,
          clipboardData: {
            getData: type => {
              if(type === 'text') {
                return text;
              }
            }
          }
        };
        object.paste(simEvent);
      })
      .catch(error => {
        logger.warn(error);
        this.notificationService.warning(gettextCatalog.getString(
          // eslint-disable-next-line max-len
          'Something went wrong while pasting your content. Please use the CTRL+V keyboard shortcut instead.'
        ));
      });
  }


  _getPastedText() {
    return $q.resolve().then(() => {
      if(navigator.clipboard && navigator.clipboard.readText) {
        return this._getPastedTextWithNavigatorClipboard();
      } else {
        this.notificationService.warning(gettextCatalog.getString(
          // eslint-disable-next-line max-len
          'Your browser does not support pasting text this way. Use the CTRL+V keyboard shortcut instead.'
        ));
      }
    });
  }


  _getPastedTextWithNavigatorClipboard() {
    return navigator.clipboard.readText()  // eslint-disable-line compat/compat
      .catch(error => {
        logger.info(error);
        this.notificationService.warning(gettextCatalog.getString(
          // eslint-disable-next-line max-len
          'We need access to your clipboard. You can change this permission in your browser settings or use the CTRL+V keyboard shortcut instead.'
        ));
      });
  }



  /****************************
   * Setup and destroy shapes *
   ****************************/

  _onShapeAdd(paperRenderer, origShape) {
    let shouldRender = this.paperRenderer.page && this.paperRenderer.page.id === origShape.pageId;
    this._addShape(origShape, shouldRender);
  }


  _addShape(origShape, shouldRender) {
    let shape;
    shape = this.fabricShapeFactory.create(origShape);
    if(!shape) {
      return;
    }

    this.shapes[origShape.id] = shape;
    if(shape.type === 'text') {
      shape.object.maxWidth = (
        this.whiteboard.contentSize.width - 2 * PAPER_OBJECT_MARGIN);
    }

    this.paperRenderer.editShape(origShape, shape.getProperties());
    this._setEditingUser(shape.id, this.editingUserService.get(shape.id));

    if(shouldRender) {
      if(this.paperRenderer.active) {
        this._canvasAdd(shape);
        this._renderShapes();
      } else {
        this._renderInactive();
      }
    }
  }


  _onShapesEdit(paperRenderer, shapeInfos, temporary) {
    let render = false;
    let updatedTextProperties = [];

    shapeInfos.forEach(shapeInfo => {
      let [origShape, update] = shapeInfo;

      let shape = this.shapes[origShape.id];
      if(!shape) {
        return;
      }

      if(shape.type !== 'text' || !('height' in update) || object.length(update) > 1) {
        shape.update(update, temporary);
        render = render || this._shouldRenderShape(shape, temporary);

        if(shape.type === 'text') {
          let updatedProperties = shape.getProperties();
          updatedTextProperties.push([origShape, updatedProperties]);
        }
      }
    });

    if(updatedTextProperties.length > 0) {
      this.paperRenderer.editShapes(updatedTextProperties);
    }

    if(render) {
      this._renderShapes();
    }
  }

  _shouldRenderShape(shape, temporary) {
    return (
      !temporary
      && this.paperRenderer.page
      && this.paperRenderer.page.id === shape.origShape.pageId);
  }


  _onShapeRemove(paperRenderer, origShape) {
    let shape = this.shapes[origShape.id];
    delete this.shapes[origShape.id];

    if(!shape) {
      return;
    }

    shape.destroy();
    let shouldRender = this.paperRenderer.page && this.paperRenderer.page.id === origShape.pageId;
    if(shouldRender) {
      if(this.paperRenderer.active) {
        this._canvasRemove(shape);
        this._renderShapes();
      } else {
        this._renderInactive();
      }
    }
  }


  _onShapeEditing(paperRenderer, origShape, editing) {
    let shape = this.shapes[origShape.id];
    if(!shape) {
      return;
    }

    if(!editing) {
      shape.object.exitEditing();
    }

    if(shape.type === 'formula') {
      let formatTool = editing ?
        'formulaEdit' :
        this.tool === 'cursor' ?
          null :
          this.tool;
      $rootScope.$evalAsync(() => this.toolService.set('formatTool', formatTool));
    }
  }


  _updateSelectable(shape) {
    let selectable = (
      shape.editingUser == null
      && (
        this.tool === 'cursor'
        || (this.tool === 'text' && shape.type === 'text')
        || (this.tool === 'formula' && shape.type === 'formula')
      )
    );

    shape.object.set({
      selectable: selectable,
      evented: selectable,
    });
  }


  _setEditingUser(shapeId, user) {
    let shape = this.shapes[shapeId];
    if(shape && (shape.type === 'text' || shape.type === 'formula')) {
      shape.editingUser = user;
      this._updateSelectable(shape);

      let activeShapes = this._getActiveShapes();
      if(array.has(activeShapes, shape)) {
        this.canvas.deactivateAll();
      }

      if(shape.object) {
        shape.object.dirty = true;
        this._renderShapes();
      }
    }
  }

  _unsetEditingUser(shapeId) {
    let shape = this.shapes[shapeId];
    if(shape) {
      shape.editingUser = null;
      this._updateSelectable(shape);

      if(shape.object) {
        shape.object.dirty = true;
        this._renderShapes();
      }
    }
  }



  /*******************
   * General drawing *
   *******************/

  _onDrawingMouseDown(option) {
    this.canvas.on('mouse:move', this._onDrawingMouseMove);
    this.canvas.on('mouse:up', this._onDrawingMouseUp);

    this.drawingPointerStart = this.canvas.getPointer(option.e);
    this._onDrawingMouseMove(option);
  }


  _onDrawingMouseMove(option) {
    let pointer = this.canvas.getPointer(option.e);
    this.paperRenderer.setDrawingBounds({
      left:   Math.min(this.drawingPointerStart.x, pointer.x),
      right:  Math.max(this.drawingPointerStart.x, pointer.x),
      top:    Math.min(this.drawingPointerStart.y, pointer.y),
      bottom: Math.max(this.drawingPointerStart.y, pointer.y),
    }, TOOL_REMEMBER_MAX_DRAWING_BOUNDS[this.tool]);
  }


  _onDrawingMouseUp() {
    this.canvas.off('mouse:move', this._onDrawingMouseMove);
    this.canvas.off('mouse:up', this._onDrawingMouseUp);

    this.paperRenderer.resetDrawingBounds();
  }


  _cancelDrawing() {
    if(this.drawingOptions) {
      if(this.drawingOptions.object) {
        delete this.drawingOptions.object.shape;
        this.canvas.remove(this.drawingOptions.object);
        this.canvas.clearContext(this.canvas.contextTop);
      }

      if(this.drawingOptions.onMouseMove) {
        this.canvas.off('mouse:move', this.drawingOptions.onMouseMove);
      }
      if(this.drawingOptions.onMouseUp) {
        this.canvas.off('mouse:up', this.drawingOptions.onMouseUp);
      }

      this.drawingOptions = null;
    }
  }



  /**************
   * Free paths *
   **************/

  _onPathCreated(tool, option) {
    let object = option.path;
    this.canvas.remove(object);

    try {
      if(object.width < 0.1 && object.height < 0.1) {
        throw new Error();
      }

      let left = object.minX - this.canvas.freeDrawingBrush.width / 2;
      let top = object.minY - this.canvas.freeDrawingBrush.width / 2;
      let path = serializePath(normalizePath(object.path, [left, top]));

      let properties = {
        left: left,
        top: top,

        path: path,
        width: object.width,
        height: object.height,

        strokeWidth: this.canvas.freeDrawingBrush.width,
        strokeColor: this.canvas.freeDrawingBrush.color,
      };

      this.paperRenderer.addShape(null, tool, properties);

    } catch(error) {
      this._renderShapes();
    }
  }



  /********************
   * Geometric shapes *
   ********************/

  _onGeometricMouseDown(option) {
    this._cancelDrawing();
    let pointer = this.canvas.getPointer(option.e);

    this.drawingOptions = {
      onMouseMove: this._onGeometricMouseMove,
      onMouseUp: this._onGeometricMouseUp,
      pointer: pointer,
      geometricShape: this.toolService.selected.geometricShape,
      shape: null,
      properties: null,
    };
    this.canvas.on('mouse:move', this.drawingOptions.onMouseMove);
    this.canvas.on('mouse:up', this.drawingOptions.onMouseUp);
  }


  _onGeometricMouseMove(option) {
    if(!this.drawingOptions.shape) {
      let origShape = {
        type: this.drawingOptions.geometricShape,
        properties: this._getDeskProperties('geometric'),
      };

      this.drawingOptions.properties = origShape.properties;
      let shape = this.fabricShapeFactory.create(origShape);
      this.drawingOptions.shape = shape;
      this.drawingOptions.object = shape.object;
      this.canvas.add(shape.object);
    }

    let pointer = this.canvas.getPointer(option.e);
    let startPointer = this.drawingOptions.pointer;

    let left   = Math.min(startPointer.x, pointer.x);
    let top    = Math.min(startPointer.y, pointer.y);
    let width  = Math.abs(startPointer.x - pointer.x);
    let height = Math.abs(startPointer.y - pointer.y);

    let propertiesUpdate;
    switch(this.drawingOptions.geometricShape) {
      case 'rectangle':
        propertiesUpdate = {
          left: left,
          top: top,
          width: width,
          height: height,
        };
        break;

      case 'ellipse':
        propertiesUpdate = {
          left: left,
          top: top,
          rx: width / 2,
          ry: height / 2,
        };
        break;

      case 'triangle':
        propertiesUpdate = {
          left: left,
          top: top,
          width: width,
          height: height,
          flipY: startPointer.y < pointer.y,
        };
        break;

      case 'line':
      case 'arrow':
        let length = Math.sqrt(width * width + height * height);
        let angle = Math.atan2(
          pointer.y - startPointer.y,
          pointer.x - startPointer.x
        ) / Math.PI * 180;
        propertiesUpdate = {
          left: startPointer.x,
          top: startPointer.y,
          width: length,
          angle: angle,
        };
        break;
    }

    Object.assign(this.drawingOptions.properties, propertiesUpdate);
    this.drawingOptions.shape.update(propertiesUpdate);

    if(this.canvas) {
      this.canvas.renderCanvas(this.canvas.contextTop, [this.drawingOptions.object]);
    }
  }


  _onGeometricMouseUp() {
    let geometricShape = this.drawingOptions.geometricShape;
    let properties = this.drawingOptions.properties;
    this._cancelDrawing();
    if(properties) {
      this.paperRenderer.addShape(null, geometricShape, properties);
    }
  }



  /********************
   * Text and formula *
   ********************/

  _onTextFormulaBeforeSelectionCleared(option) {
    let object = option.target;

    if(
      option.e
      && object
      && object.shape
      && (object.shape.type === 'text' || object.shape.type === 'formula')
    ) {
      this.clearEvent = option.e;
    }
  }


  _onTextFormulaCreateMouseDown(option) {
    if(!option.target) {
      this._cancelDrawing();
      let pointer = this.canvas.getPointer(option.e);

      this.drawingOptions = {
        onMouseMove: this._onTextFormulaCreateMouseMove,
        onMouseUp: this._onTextFormulaCreateMouseUp,
        pointer: pointer,
        object: null,
        cleared: (option.e === this.clearEvent),
      };

      this.canvas.on('mouse:move', this.drawingOptions.onMouseMove);
      this.canvas.on('mouse:up', this.drawingOptions.onMouseUp);
    }
  }


  _onTextFormulaCreateMouseMove(option) {
    let object = this.drawingOptions.object;
    let pointer = this.canvas.getPointer(option.e);
    let startPointer = this.drawingOptions.pointer;
    let diff = {
      x: Math.abs(pointer.x - startPointer.x) * this.whiteboard.pixelsPerPoint,
      y: Math.abs(pointer.y - startPointer.y) * this.whiteboard.pixelsPerPoint,
    };

    if(!object && (diff.x > 5 || diff.y > 5)) {
      object = new fabric.Textbox('', {
        left: startPointer.x,
        top: startPointer.y,
        fontSize: this.toolService.selected.textSize,
      });
      this.drawingOptions.object = object;
      this.canvas.add(object);
      this.canvas.setActiveObject(object);
    }

    if(object) {
      let height = Math.abs(pointer.y - startPointer.y);
      let width = Math.abs(pointer.x - startPointer.x);
      object.set({
        left:  Math.min(pointer.x,  startPointer.x),
        top:   Math.min(pointer.y,  startPointer.y),
        requestWidth: width,
        height: height,
        requestHeight: height,
      });

      this._renderShapes();
    }
  }


  _onTextFormulaCreateMouseUp() {
    this.canvas.off('mouse:move', this.drawingOptions.onMouseMove);
    this.canvas.off('mouse:up', this.drawingOptions.onMouseUp);
    let object = this.drawingOptions.object;

    // If the user clicked without moving, we will only create a textbox
    // if no other textbox was selected at the time of clicking
    if(object || !this.drawingOptions.cleared) {
      let properties;
      if(this.tool === 'text') {
        properties = {
          fText: buildText(Shape.defaultText, fabric._insertCharStyle, {}),
          requestHeight: object ?
            object.get('requestHeight') :
            this.toolService.selected.textSize,
        };

        if(object) {
          properties.requestWidth = object.get('width');
        }

      } else {
        properties = this._getDeskProperties('formula');
        properties.latex = '';
        properties.width = object ? object.get('width') : Shape.defaultTextWidth;
      }

      if(object) {
        properties.left = object.get('left');
        properties.top = object.get('top');

        object.editable = false;
        this.canvas.remove(object);

      } else {
        properties.left = this.drawingOptions.pointer.x;
        properties.top = this.drawingOptions.pointer.y - this.toolService.selected.textSize / 2;
      }

      let origShape = this.paperRenderer.addShape(null, this.tool, properties);
      if(!origShape) {
        return;
      }

      let shape = this.shapes[origShape.id];
      this.canvas.setActiveObject(shape.object);
      shape.object.enterEditing();
    }

    this.drawingOptions = null;
  }


  _onTextFormulaSelectMouseDown(option) {
    let object = option.target;

    if(
      object
      && object.shape
      && (object.shape.type === 'text' || object.shape.type === 'formula')
    ) {
      this._cancelDrawing();
      this.drawingOptions = {
        onMouseMove: this._onTextFormulaSelectMouseMove,
        onMouseUp: this._onTextFormulaSelectMouseUp,
        selectObject: object,
      };
      this.canvas.on('mouse:move', this.drawingOptions.onMouseMove);
      this.canvas.on('mouse:up', this.drawingOptions.onMouseUp);
    }
  }


  _onTextFormulaSelectMouseMove() {
    this._cancelDrawing();
  }


  _onTextFormulaSelectMouseUp(option) {
    let object = this.drawingOptions && this.drawingOptions.selectObject;
    this._cancelDrawing();

    if(object && !object.isEditing) {
      // object.canvas == null when the object has been removed while the mouse was down
      if(object.shape.type === 'text' && object.canvas) {
        object.setCursorByClick(option.e);
      }
      object.enterEditing();
    }
  }


  _onTextFormulaEditingEntered(option) {
    this.canvas.defaultCursor = 'default';
    let object = option.target;
    let shape = object.shape;
    if(!shape) {
      return;
    }

    this.paperRenderer.setEditingShape(shape.origShape, true);

    if(shape.type === 'text') {
      // Default text in textbox => select all to change text
      if(object.text.trim() === Shape.defaultText) {
        object.selectAll();
      }
      this.paperRenderer.setElemActiveShape(object.hiddenTextarea);

    } else if(shape.type === 'formula') {
      this.paperRenderer.unsetElemActiveShape(this.$elemPaper);
      object.set('hasBorders', false);
      this._renderShapes();
    }
  }


  _onTextFormulaEditingExited(option) {
    this.canvas.defaultCursor = TOOL_CURSOR_TYPE[this.tool];
    let object = option.target;

    if(object.shape && (object.shape.type === 'text' || object.shape.type === 'formula')) {
      this.paperRenderer.setElemActiveShape(this.$elemPaper);
      this.$elemPaper.focus();

      if(object.shape.type === 'formula') {
        object.set('hasBorders', true);
        this._renderShapes();
      }

      // If this is not in a timeout we get an error with textboxes if the text was changed
      $timeout(() => {
        this.canvas.trigger('object:modified', { target: object });
      });
    }
  }


  _onTextFormulaChanged(option) {
    let object = option.target;
    if(object.shape && object.shape.type === 'text' && object.shape.getProperty('isInitTextBox')) {
      object.shape.update({ isInitTextBox: false });
    }
    this._syncTyping(option);
  }



  /***********
   * Formula *
   ***********/

  _onFormulaTextboxChanged(option) {
    let object = option.target;
    if(object.shape && object.shape.type === 'formula' && object.get('text') !== '') {
      object.set('text', '');
    }
  }


  _onFormulaMoving(option) {
    let object = option.target;
    let isGroup = (object.isType('group') && !object.hasOwnProperty('shape'));
    let objects = isGroup ? object.getObjects() : [object];
    let editArgs = [];

    for(let i = 0; i < objects.length; i++) {
      let shape = objects[i].shape;
      if(shape && shape.type === 'formula') {
        let update = {
          left: shape.getProperty('left'),
          top: shape.getProperty('top'),
        };
        editArgs.push([shape.origShape, update]);
      }
    }

    this.paperRenderer.editShapes(editArgs, false, true);
  }



  /**********
   * Eraser *
   **********/

  _onEraserMouseDown(option) {
    this._erase(option.e);

    this.canvas.on('mouse:move', this._onEraserMouseMove);
    this.canvas.on('mouse:up', this._onEraserMouseUp);
  }


  _onEraserMouseMove(option) {
    this._erase(option.e);
  }


  _onEraserMouseUp() {
    this.canvas.off('mouse:move', this._onEraserMouseMove);
    this.canvas.off('mouse:up', this._onEraserMouseUp);
  }


  _erase(event) {
    let pointer = this.canvas.getPointer(event);
    let maxDistance = WHITEBOARD_ERASER_SIZE / 2 / this.whiteboard.zoomLevel;

    this.paperRenderer.activeShapes.forEach(origShape => {
      if(origShape.intersectsWithEraser(pointer, maxDistance)) {
        this.paperRenderer.removeShape(origShape);
      }
    });
  }



  /***************************
   * Render full size canvas *
   ***************************/

  _isReadyToRender() {
    return (
      this.paperRenderer.active
      && this.whiteboard.canvasSize.width > 0
      && this.whiteboard.canvasSize.height > 0
      && this.canvas
    );
  }


  _renderFromScratch() {
    this.shouldRenderFromScratch = true;
    if(this._isReadyToRender()) {
      this.canvas.clear();
      this.paperRenderer.activeShapes
        .map(origShape => this.shapes[origShape.id])
        .filter(shape => !!shape)
        .forEach(shape => this._canvasAdd(shape));
      this.shouldRenderFromScratch = false;
      this._render();
    }
  }


  _render() {
    if(this.shouldRenderFromScratch) {
      this._renderFromScratch();
      return;
    }
    if(!this._isReadyToRender()) {
      return;
    }

    this._renderCanvas(this.canvas);
  }


  _canvasAdd(shape) {
    this._updateSelectable(shape);
    if(this.canvas) {
      this.canvas.insertAt(shape.object, shape.origShape.indexOnPage);
    }
  }


  _canvasRemove(shape) {
    if(shape && this._isReadyToRender()) {
      let object = shape.object;
      if(object.group) {
        object.group.removeWithUpdate(object);
      }

      let objects = [object];
      if(object.isType('group')) {
        objects = objects.concat(object.getObjects());
      }

      objects.forEach(object => {
        // This is necessary to prevent errors when removing an
        // object during handling of an event on that exact object
        object.editable = false;
        this.canvas.remove(object);
      });
    }
  }


  _renderShapes() {
    if(this._isReadyToRender()) {
      this.canvas.renderAll();
    }
  }


  _onFontLoaded() {
    if(this.canvas && this.canvas._objects.length > 0) {
      this.canvas._objects.forEach(object => object._forceClearCache = true);
      this._renderShapes();
      this.canvas._objects.forEach(object => object._forceClearCache = false);
    }
  }



  /********************************************
   * Generate minimized image for the sidebar *
   ********************************************/

  _isReadyToRenderInactive() {
    return (
      !this.paperRenderer.active
      && this.$elemInactiveImage
      && this.whiteboard.canvasSize.width > 0
      && this.canvasInactive
    );
  }


  _renderInactive() {
    if(!this._isReadyToRenderInactive()) {
      return;
    }
    return this._renderImage().then(canvas => {
      let dataURL = canvas.toDataURL({ format: 'png' });
      this.$elemInactiveImage[0].src = dataURL;
    });
  }


  renderSvgExport() {
    let size = this.whiteboard.canvasSizeSvgExport;
    let offset = this.whiteboard.canvasPanSvgExport;
    let pixelsPerPoint = this.whiteboard.pixelsPerPointSvgExport;

    return this._renderImage(size, offset, pixelsPerPoint).then(canvas => {
      let svg = canvas.toSVG({
        suppressPreamble: true,
      });
      let $elem = angular.element('<div class=".whiteboard-tile__fabric-wrapper"></div>');
      $elem.html(svg);
      return $elem;
    });
  }


  renderSnapshot(width) {
    let size = this.whiteboard.getCanvasSizeSnapshot(width);
    let offset = this.whiteboard.getCanvasPanSnapshot(width);
    let pixelsPerPoint = this.whiteboard.getPixelsPerPointSnapshot(width);

    return this._renderImage(size, offset, pixelsPerPoint).then(canvas => {
      if(canvas) {
        return canvas.toDataURL({ format: 'png' });
      }
    });
  }


  _renderImage(size, offset, zoom) {
    this.renderImagePromise = this.renderImagePromise
      .then(() => this._renderImageNow(size, offset, zoom));
    return this.renderImagePromise;
  }

  _renderImageNow(size, offset, zoom) {
    let getObjectPromises = this.paperRenderer.activeShapes
      .map(origShape => this.shapes[origShape.id])
      .map(shape => {
        if(shape && shape.object) {
          return this._cloneObject(shape);
        } else {
          return null;
        }
      })
      .filter(angular.identity);

    return $q.all(getObjectPromises)
      .then(objects => {
        if(!this.canvasInactive) {
          return null;
        }
        this.canvasInactive.clear();
        this._renderCanvas(this.canvasInactive, size, offset, zoom);
        this.canvasInactive.add(...objects);
        return this.canvasInactive;
      });
  }


  _cloneObject(shape) {
    return this._getObjectWhenLoaded(shape)
      .then(object => {
        return $q(resolve => object.clone(resolve));
      })
      .then(clonedObject => {
        if(shape.type === 'text') {
          clonedObject.maxWidth = shape.object.maxWidth;
        // See fabric.js toSVG for why we do this.
        } else if(shape.type === 'image') {
          clonedObject._width = shape.object._width;
          clonedObject._height = shape.object._height;
        }
        return clonedObject;
      });
  }


  _getObjectWhenLoaded(shape) {
    return $q(resolve => {
      if(shape.type === 'image' && shape.object.getElement() == null) {
        shape.object.on('image:added', () => resolve(shape.object));
      } else {
        resolve(shape.object);
      }
    });
  }


  _renderCanvas(canvas, size, offset, zoom) {
    if(size == null) {
      size = this.whiteboard.canvasSize;
    }
    if(offset == null) {
      offset = this.whiteboard.canvasPan;
    }
    if(zoom == null) {
      zoom = this.whiteboard.pixelsPerPoint;
    }

    // override renderAll so no re-render when setting dimensions and zooming.
    // these renders are unnecessary as fabric will re-render when calling absolutePan.
    canvas.renderAll = angular.noop;
    canvas.setDimensions(size);

    // fabric.js does not really care about pixels and points, it only cares about shapes, the
    // dimensions of which are arbitrary. Likewise, the fabric zoom level is an arbitrary number
    // with 1 meaning "show everything at its original size within the canvas" and 2 meaning
    // "show everything at twice the size within the canvas". This means that when the canvas
    // is made smaller, the size of the shapes stay the same relative to the canvas (a line of
    // half the lenght of the canvas, will still have half the length of the canvas when the
    // canvas is made bigger or smaller)
    //
    // However, pixelsPerPoint is a perfect variable that already tracks how "zoomed in" the canvas
    // is, so while the meaning of "pixels" and "points" is irrelevant for the canvas,
    // pixelsPerPoint is still used to track the zoom level of the canvas.
    canvas.setZoom(zoom);
    delete canvas.renderAll;

    let corner = new fabric.Point(offset.x, offset.y);
    canvas.absolutePan(corner);
  }
}
