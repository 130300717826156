import format from './format';


const MONTH_NAMES = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]);


export default function readableDate(date) {
  let string = null;

  if(isToday(date)) {
    string = getHourString(date);

  } else if(isYesterday(date)) {
    string = getYesterdayString(date);

  } else {
    string = getDateHourString(date);
  }

  return string;
}


function isToday(date) {
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  return date >= today;
}

function isYesterday(date) {
  let yesterday = new Date(Date.now() - 1000 * 60 * 60 * 24);
  yesterday.setHours(0, 0, 0, 0);

  return !isToday(date) && date >= yesterday;
}


function getHourString(date) {
  return format('%s:%s', getNumberString(date.getHours()), getNumberString(date.getMinutes()));
}

function getDateHourString(date) {
  return format('%s %s at %s', date.getDate(), MONTH_NAMES[date.getMonth()], getHourString(date));
}

function getYesterdayString(date) {
  return format('Yesterday at %s', getHourString(date));
}


function getNumberString(number) {
  return format('%s%s', number < 10 ? '0' : '', number);
}
