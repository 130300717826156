import { NgModule } from '@angular/core';
import { components } from './angularjs-upgraded-components';

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
})
export class AngularjsUpgradedUtilsModule {}
