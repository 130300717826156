import { NgModule } from '@angular/core';
import { HubSpotConfigComponent } from './hubspot-config.component';
import { UtilsModule } from 'utils/utils.module';
import { CommonModule } from '@angular/common';
import { HubSpotService } from './hubspot.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    UtilsModule,
  ],
  providers: [
    HubSpotService,
  ],
  declarations: [
    HubSpotConfigComponent,
  ],
  exports: [
    HubSpotConfigComponent,
  ],
})
export class HubSpotModule {}
