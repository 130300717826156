UsernameInputDirective.$inject = [
  '$compile',
];


const TEMPLATE = `
  <div
    class="mt-2 field field--teal"
    ng-class="{'field--warning': changed}"
    data-testid="changeUsernameInfoField"
  >
    <div>
      <p translate>These are the links you can send to your customers:</p>

      <div class="mb-2">
        <label translate>Personal booking link</label>
        <copy-text
          text-to-copy="bookingUrl"
          icon="'utils/icons/tl/24x24_link_outline.svg'"
          class="d-sm-none"
        ></copy-text>
        <copy-text
          text-to-copy="bookingUrl"
          show-text-to-copy="true"
          prettify-link
          class="d-none d-sm-inline-flex"
        ></copy-text>
      </div>

      <div class="mb-2">
        <label translate>Personal waiting room link</label>
        <copy-text
          text-to-copy="waitingRoomUrl"
          icon="'utils/icons/tl/24x24_link_outline.svg'"
          class="d-sm-none"
        ></copy-text>
        <copy-text
          text-to-copy="waitingRoomUrl"
          show-text-to-copy="true"
          prettify-link
          class="d-none d-sm-inline-flex"
        ></copy-text>
      </div>

      <div ng-show="changed" data-testid="changeInfoText">
        <p translate>
          If you change your personal links, the old links will no longer work.
          Save the page before using the new links.
        </p>

        <div>
          <div
            class="btn btn--secondary"
            ng-click="reset()"
            data-testid="resetLinksButton"
          >
            <div class="btn__icon" svg="'utils/icons/tl/24x24_refresh_outline.svg'"></div>
            <div translate>Reset links</div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;

export default function UsernameInputDirective(
  $compile
) {
  return {
    restrict: 'A',
    link: function($scope, $elem) {
      let bookingUrlTemplate = $elem.attr('username-input-booking-url');
      let waitingRoomUrlTemplate = $elem.attr('username-input-waiting-room-url');
      let initial = $elem.val();

      $elem.on('change input', () => $scope.$evalAsync(update));
      update();

      let $elemField = $compile(TEMPLATE)($scope);
      $elem.parents('.form-group').append($elemField);

      function update() {
        let username = $elem.val();
        let bookingUrl = bookingUrlTemplate.replace('{}', username);
        let waitingRoomUrl = waitingRoomUrlTemplate.replace('{}', username);

        $scope.bookingUrl = bookingUrl;
        $scope.waitingRoomUrl = waitingRoomUrl;

        $scope.changed = username !== initial;
      }


      $scope.reset = function reset() {
        $elem.val(initial);
        update();
      };
    }
  };
}
