import template from './loading.modal.html?raw';


const MODAL_ID = 'loading';
const SHOW_TIMEOUT = 2000;


export default class LoadingModalRun {
  static get $inject() {
    return [
      'meetingService',
      'greetService',
      'meetingBroadcastService',
      'modalService',
    ];
  }

  constructor(
    meetingService,
    greetService,
    meetingBroadcastService,
    modalService
  ) {
    this._bind();

    modalService.register(
      MODAL_ID,
      null,
      null,
      template,
      { priority: 10, dismissable: false, rawTemplate: true });

    this.meetingService = meetingService;
    this.greetService = greetService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.modalService = modalService;

    this.hasBeenShown = false;
    this.showTimeout = null;

    this.meetingService.on('state', this._updateModal);
    this.greetService.on('template', this._updateModal);
    this.meetingBroadcastService.afterInitialization().then(this._updateModal);
    this._updateModal();
  }

  _bind() {
    this._updateModal = this._updateModal.bind(this);
    this._show = this._show.bind(this);
  }


  _updateModal() {
    let show = this._shouldShow;
    if(!show) {
      $timeout.cancel(this.showTimeout);
      this.showTimeout = null;
      this.modalService.hide(MODAL_ID);

    } else if(!this.showTimeout) {
      this.showTimeout = $timeout(this._show, SHOW_TIMEOUT);
    }
  }


  _show() {
    $timeout.cancel(this.showTimeout);
    this.showTimeout = null;
    this.hasBeenShown = true;
    this.modalService.show(MODAL_ID);
  }


  get _shouldShow() {
    return (
      this.meetingBroadcastService.initializing
      && this.greetService.template == null
      && !this.hasBeenShown
    );
  }
}
