import Pointer from './Pointer';
import Tile from '../../tiles/Tile';


export default class SharedMousePointerService {
  static get $inject() {
    return [
      'userService',
      'meetingBroadcastService',
      'tileService',
    ];
  }

  constructor(
    userService,
    meetingBroadcastService,
    tileService
  ) {
    this._bind();

    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.tileService = tileService;

    this.pointers = {};

    meetingBroadcastService.on('content-shared-mouse', this._onBroadcast, false);
    userService.on('userExit', this._onUserExit);
    tileService.on('remove', this._onTileRemove);
  }

  _bind() {
    this._send = this._send.bind(this);
    this._onBroadcast = this._onBroadcast.bind(this);
    this._onUserExit = this._onUserExit.bind(this);
    this._onTileRemove = this._onTileRemove.bind(this);
  }


  _getOrCreate(user) {
    if(!this.pointers[user.id]) {
      this.pointers[user.id] = new Pointer(user);
    }
    return this.pointers[user.id];
  }


  set(tile, position, click) {
    this._send(tile, position, click);
  }

  _send(tile, position, click) {
    let tileId = tile == null ? null : tile.id;
    position = position == null ? null : [position.x, position.y];

    this.meetingBroadcastService.send(
      'content-shared-mouse', false, [], tileId, null, position, click
    );
  }


  _onBroadcast(channel, session, datetime, id, type, position, click) {
    let pointer = this._getOrCreate(session.user);
    let tile = id == null ? null : this.tileService.tiles[id];
    position = {
      x: position == null ? 0 : position[0],
      y: position == null ? 0 : position[1],
    };
    pointer.setPosition(tile, position);
    if(click) {
      pointer.click();
    }
  }


  _onUserExit(user) {
    delete this.pointers[user.id];
  }


  _onTileRemove(tile) {
    if(tile.category !== Tile.Category.CONTENT) {
      return;
    }

    Object.keys(this.pointers).forEach(userId => {
      if(this.pointers[userId].tile === tile) {
        delete this.pointers[userId];
      }
    });
  }
}
