import {
  bind,
  dateTime,
  browser,
  format,
  memoize
} from 'utils/util';
import Session from '../../main/users/Session';
import template from './greet.html?raw';
import { Appointment } from 'scheduling/models/Appointment';



class GreetController {
  static get $inject() {
    return [
      '$filter',
      'browserService',
      'greetService',
      'languageService',
      'meetingService',
      'modelFactory',
      'requestUserService',
      'resetService',
      'unloadService',
      'userService',
      'siteService',
      'usageTrackingService',
      'hubSpotService',
    ];
  }

  constructor(
    $filter,
    browserService,
    greetService,
    languageService,
    meetingService,
    modelFactory,
    requestUserService,
    resetService,
    unloadService,
    userService,
    siteService,
    usageTrackingService,
    hubSpotService
  ) {
    bind(this);

    this.$filter = $filter;
    this.browserService = browserService;
    this.greetService = greetService;
    this.languageService = languageService;
    this.meetingService = meetingService;
    this.modelFactory = modelFactory;
    this.requestUserService = requestUserService;
    this.resetService = resetService;
    this.unloadService = unloadService;
    this.userService = userService;
    this.siteService = siteService;
    this.usageTrackingService = usageTrackingService;
    this.hubSpotService = hubSpotService;

    this.shouldShowDeviceOptions = false;
    this._getHeaderItemsMemoized = memoize(this._getHeaderItems);

    if(window.APPOINTMENT) {
      this.upcomingAppointment = this.modelFactory.createInstance(Appointment, {
        data: window.APPOINTMENT,
      });
    }

    this.greetService.on('template', this._onTemplate);
  }


  get me() {
    return this.userService.me;
  }

  get isHost() {
    return this.userService.iAmHost;
  }

  get isOwner() {
    return (
      this.userService.me.isAuthenticated
      && this.meetingService.userIsOwner(this.userService.me)
    );
  }

  get hasBeenJoined() {
    return this.userService.mySession.dateJoined != null;
  }

  _onTemplate() {
    this.shouldShowDeviceOptions = false;
  }


  /***************************
   * Interface customization *
   ***************************/

  get backgroundCss() {
    let url = this.meetingService.settings.whitelabel.waitingRoomBackground;
    return {
      backgroundImage: format('url("%s")', url),
    };
  }

  get shouldShowCustomLogo() {
    return this.meetingService.settings.whitelabel.customLogo;
  }

  get shouldShowPoweredBy() {
    return (
      this.meetingService.settings.whitelabel.showPoweredBy
    );
  }

  get shouldShowHostLogin() {
    return this.userService.me.isLazy && this.meetingService.settings.showHostLogin;
  }

  get audioVideoButtonsEnabled() {
    return (
      browser.supportsWebRTC()
      && this.meetingService.bednetLocation !== this.meetingService.BednetLocation.TEACHER
    );
  }

  get headerItems() {
    return this._getHeaderItemsMemoized(
      this.userService.me.firstName,
      this.meetingService.settings.whitelabel.hasAddon,
      this.browserService.isDesktop
    );
  }
  _getHeaderItems(myFirstName, whitelabel, isDesktop) {
    let items = [];
    if(isDesktop) {
      items.push('fullscreen');
    }
    if(myFirstName || !whitelabel) {
      items.push('profile');
    }
    return items;
  }


  get upgradeLink() {
    return gettextCatalog.getString(
      '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to have group meetings with 3 people or more.', // eslint-disable-line max-len
      {
        url: format(
          'href="%s" target="_blank" class="btn btn--primary"',
          this.siteService.site.subscriptionLink
        ),
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

  get waitingForMeetingHostText() {
    // Meeting Host is present but has not let the user in
    let waitingForHostDict = window.TEXT_STRINGS['waitingForMeetingHost'];
    return this.languageService.getCustomOrTranslatedString(waitingForHostDict);
  }

  get meetingHostNotPresentText() {
    let hostNotPresentDict = window.TEXT_STRINGS['meetingHostNotPresent'];
    return this.languageService.getCustomOrTranslatedString(hostNotPresentDict);
  }



  /*****************
   * Waiting room  *
   ****************/

  get isWaitingRoom() {
    return this.isTeamWaitingRoom || this.isPersonalWaitingRoom;
  }
  get isTeamWaitingRoom() {
    return this.meetingService.isTeamWaitingRoom;
  }
  get isPersonalWaitingRoom() {
    return this.meetingService.isPersonalWaitingRoom;
  }
  get isMyWaitingRoom() {
    if(this.isPersonalWaitingRoom) {
      return this.isOwner;
    } else if(this.isTeamWaitingRoom) {
      return this.userService.me.organization.id === this.meetingService.owner.organizationId;
    }
    return false;
  }

  get bookingLink() {
    return this.meetingService.bookingLink;
  }
  get shouldShowBookingLink() {
    return (
      this.isWaitingRoom
      && this.bookingLink
      && !this.upcomingAppointment
    );
  }

  get noHostLink() {
    return gettextCatalog.getString(
      // eslint-disable-next-line max-len
      'There is no host available right now. Please try again later or <a {{ url }}>book a moment that suits you</a>.',
      { url: format('href="%s"', this.bookingLink) }
    );
  }



  /************************
   * Upcoming appointment *
   ************************/

  get plannedMeetingText() {
    let upcomingAppointment = this.upcomingAppointment;

    let date =  this.$filter('readableDate')(
      this.upcomingAppointment.start,
      this.languageService.currentLanguage
    );
    let time = dateTime.getLocaleTime(
      this.upcomingAppointment.start,
      this.languageService.currentLanguage
    );

    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if(upcomingAppointment.isCancelled) {
      return gettextCatalog.getString(
        'The meeting with {{ conversationPartner }} has been canceled',
        { conversationPartner: this.conversationPartner }
      );
    }
    if(upcomingAppointment.start < new Date()) {
      return gettextCatalog.getString(
        'Meeting with {{ conversationPartner }} started at {{ time }}',
        { conversationPartner: this.conversationPartner, time: time }
      );
    } else {
      if(upcomingAppointment.start.getDate() === today.getDate()) {
        return gettextCatalog.getString(
          'Meeting with {{ conversationPartner }} coming up today at {{ time }}',
          { conversationPartner: this.conversationPartner, time: time }
        );
      } else if(upcomingAppointment.start.getDate() === tomorrow.getDate()) {
        return gettextCatalog.getString(
          'Meeting with {{ conversationPartner }} coming up tomorrow at {{ time }}',
          { conversationPartner: this.conversationPartner, time: time }
        );
      } else {
        return gettextCatalog.getString(
          'Meeting with {{ conversationPartner }} coming up {{ date }} at {{ time }}',
          { conversationPartner: this.conversationPartner, date: date, time: time }
        );
      }
    }
  }

  get conversationPartner() {
    let upcomingAppointment = this.upcomingAppointment;
    if(!upcomingAppointment) {
      return '';
    }
    const numExtraInvitees = upcomingAppointment.guests.length - 1;
    const mainParticipant = this.isMeAppointmentHost ?
      upcomingAppointment.primaryGuest.fullName : upcomingAppointment.host.fullName;
    if(numExtraInvitees > 0) {
      if(numExtraInvitees === 1) {
        return gettextCatalog.getString(
          '{{ participant }} and 1 other',
          { participant: mainParticipant }
        );
      } else {
        return gettextCatalog.getString(
          '{{ participant }} and {{ count }} others',
          {
            participant: mainParticipant,
            count: numExtraInvitees
          }
        );
      }
    } else {
      return mainParticipant;
    }
  }

  get isMeAppointmentHost() {
    return (
      this.userService.me.isInitialized
      && this.userService.me.id === this.upcomingAppointment.hostId
    );
  }

  get urlToChangeMeetingRoom() {
    return format(
      '%s?appointment=%s',
      window.URLS.dashboard,
      this.upcomingAppointment.id
    );
  }



  /************************************
   * Unsupported browser notification *
   ************************************/

  get warning() {
    if(!browser.supportsWebRTC()) {
      return browser.errorMessages.webRTC();
    } else if(!browser.supportsScreenshare() && browser.isSafari() && !browser.isIOS()) {
      return browser.warningMessages.safariScreenShare();
    } else {
      return null;
    }
  }

  get browserNotification() {
    if(this.shouldShowiOSNotification) {
      // eslint-disable-next-line max-len
      return gettextCatalog.getString('There are some issues in your version of iOS that impact all video meeting software. Your meeting can still take place, but audio issues could arise. We recommend upgrading to the latest version of iOS.');
    } else if(this.shouldShowSafariNotification) {
      // eslint-disable-next-line max-len
      return gettextCatalog.getString('There are some issues in your version of Safari that impact all video meeting software. We recommend using a different browser, or updating Safari.');
    } else if(browser.isIOSChrome()) {
      // eslint-disable-next-line max-len
      return gettextCatalog.getString('Chrome for iOS currently has an issue where your mic will stop working if you switch to a different tab or app. We recommend using Safari for now.');
    } else {
      return '';
    }
  }

  get shouldShowiOSNotification() {
    return browser.bowser.satisfies({ ios: { safari: '>=14' } })
    && browser.bowser.satisfies({ ios: { safari: '<14.0.2' } });
  }

  get shouldShowSafariNotification() {
    return browser.bowser.satisfies({ macos: { safari: '>=14' } })
    && browser.bowser.satisfies({ macos: { safari: '<15' } });
  }


  get helpArticle() {
    if(this.shouldShowiOSNotification || this.shouldShowSafariNotification) {
      return this.siteService.getHelpArticle('safariAudioVideoWarning');
    } else {
      return '';
    }
  }



  /*****************
   * Meeting ended *
   *****************/

  get mySessionHasReset() {
    return this.resetService.lastResetOptions.sessions;
  }

  get isForcedEnded() {
    return this.userService.mySession.exitReason === Session.ExitReason.FORCED_ENDED;
  }

  get shouldShowBenefitsStandaloneLazy() {
    return (
      this.meetingService.settings.showTeaserAfterMeeting
      && this.userService.me.isLazy
      && this.siteService.site.isStandalone
    );
  }
  get shouldShowBenefitsStandaloneFree() {
    return (
      this.meetingService.settings.showTeaserAfterMeeting
      && !this.userService.me.isLazy
      && this.siteService.site.isStandalone
    );
  }
  get shouldShowBenefitsCMALazy() {
    return (
      this.meetingService.settings.showTeaserAfterMeeting
      && this.userService.me.isLazy
      && this.siteService.site.isTeamleader
    );
  }
  get shouldShowBenefitsCMAFree() {
    return (
      this.meetingService.settings.showTeaserAfterMeeting
      && !this.userService.me.isLazy
      && this.siteService.site.isTeamleader
    );
  }

  get shouldShowBenefits() {
    return (
      this.shouldShowBenefitsStandaloneLazy
      || this.shouldShowBenefitsStandaloneFree
      || this.shouldShowBenefitsCMALazy
      || this.shouldShowBenefitsCMAFree
    );
  }

  get benefits() {
    const PRO_BENEFITS = [
      gettextCatalog.getString('Group meetings'),
      gettextCatalog.getString('Unlimited meeting rooms'),
      gettextCatalog.getString('Custom branding'),
      gettextCatalog.getString('Cloud recordings'),
      gettextCatalog.getString('Workflow integration'),
      gettextCatalog.getString('Priority support')
    ];

    const VECTERA_BENEFITS =  [
      gettextCatalog.getString('Powerful, persistent meeting rooms'),
      gettextCatalog.getString('Integrated scheduling'),
      gettextCatalog.getString('Free forever plan')
    ];

    const BEYOND_BENEFITS =  [
      gettextCatalog.getString('Unlimited meeting types'),
      gettextCatalog.getString('Unlimited group meetings'),
      gettextCatalog.getString('Unlimited meeting rooms'),
      gettextCatalog.getString('Meeting room templates'),
      gettextCatalog.getString('Record your meetings'),
      gettextCatalog.getString('Custom branding'),
    ];

    const TLF_BENEFITS = [
      gettextCatalog.getString('Schedule, meet and engage'),
      gettextCatalog.getString('Draw up quotations'),
      gettextCatalog.getString('Manage customers and projects'),
      gettextCatalog.getString('Create invoices'),
      gettextCatalog.getString('Receive money'),
    ];

    if(this.shouldShowBenefitsStandaloneLazy) {
      return VECTERA_BENEFITS;
    } else if(this.shouldShowBenefitsStandaloneFree) {
      return PRO_BENEFITS;
    }  else if(this.shouldShowBenefitsCMALazy) {
      return TLF_BENEFITS;
    } else if(this.shouldShowBenefitsCMAFree) {
      return BEYOND_BENEFITS;
    }
    return null;
  }


  rejoin() {
    this.unloadService.reloadPageWithoutConfirmation();
  }

  /**********************
  * Deactivated Meeting *
  **********************/

  get deactivatedMeetingRoomInfoText() {
    return gettextCatalog.getString(
      // eslint-disable-next-line max-len
      'This meeting room is deactivated. You can have only one active meeting room in the {{ freePlanName }} package.',
      { freePlanName: this.siteService.site.freePlanName }
    );
  }

  get subscriptionLink() {
    return this.siteService.site.subscriptionLink;
  }

  get proPlanName() {
    return this.siteService.site.proPlanName;
  }

  joinDeactivated() {
    this.greetService.join();
  }

  /******************
  * Usage tracking *
  *****************/

  trackLogoClick() {
    if(this.siteService.site.isStandalone) {
      this.usageTrackingService.createSegmentEvent(
        'vecteraLogo.clicked',
        'meetingPage'
      );
    } else if(this.siteService.site.isTeamleader) {
      this.usageTrackingService.createSegmentEvent(
        'teamleaderLogo.clicked',
        'meetingPage'
      );
    }
  }
}


export default {
  controller: GreetController,
  controllerAs: 'greetCtrl',
  template,
};
