import { NgModule } from '@angular/core';
import { ActiveAudioStreamService } from 'meeting/meeting-room/stream/active-audio-stream.service';
import { UtilsModule } from 'utils/utils.module';
import {
  SmartSummaryNoticeModalComponent
} from './notice-modal/smart-summary-notice-modal.component';
import { SmartSummaryBackendService } from './smart-summary-backend.service';
import { SmartSummaryService } from './smart-summary.service';
import { SmartSummaryTrackingService } from './smart-summary-tracking.service';

@NgModule({
  imports: [
    UtilsModule,
  ],
  providers: [
    ActiveAudioStreamService,
    SmartSummaryService,
    SmartSummaryBackendService,
    SmartSummaryTrackingService
  ],
  declarations: [
    SmartSummaryNoticeModalComponent,
  ],
  exports: [
    SmartSummaryNoticeModalComponent,
  ]
})
export class MeetingSmartSummaryModule {}
