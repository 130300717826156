import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon';
import { GoBackHeaderComponent } from './go-back-header.component';

@NgModule({
  imports: [
    SvgIconModule,
  ],
  declarations: [
    GoBackHeaderComponent,
  ],
  exports: [
    GoBackHeaderComponent,
  ],
})
export class GoBackHeaderModule {}
