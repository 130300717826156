import { Component } from '@angular/core';
import { ComboBoxCategory } from 'utils/form-elements/dropdowns/combo-box/combo-box.component';
import { DropdownOption } from 'utils/form-elements/dropdowns';
import { TimeDelta } from 'utils/util';


@Component({
  selector: 'form-elements',
  templateUrl: './form-elements.component.html',
  styleUrls: ['./form-elements.component.scss'],
})
export class FormElementsComponent {
  segmentedButton = {
    simple: null as string | null,
    nullable: null as string | null,
    withInitialValue: 'Option 2',
    disabled: 'Option 2',
    fullOptions: 2,
  };

  duration1: TimeDelta | null = null;
  duration2 = new TimeDelta({ hours: 2 });

  dropdownOptions: DropdownOption[] = [
    { label: 'foo', value: 'fooValue' },
    { label: 'bar', value: 'barValue' },
    { label: 'baz', value: 'bazValue', disabled: true },
  ];

  comboboxOptions : ComboBoxCategory[] = [
    {
      label: 'foo',
      options: [
        { label: 'foo1', value: 'foo1' },
        { label: 'foo2', value: 'foo2' }
      ],
    },
    {
      label: 'bar',
      options: [
        { label: 'bar1', value: 'bar1', icon: { name: 'add', size: 14 } },
        { label: 'bar2', value: 'bar2', icon: { name: 'add', size: 14 }, requiresUpgrade: true }
      ],
    },
    {
      label: 'baz',
      options: [
        { label: 'baz1', value: 'baz1', disabled: true },
        { label: 'baz2', value: 'baz2', disabled: true, disabledHelpText: 'disabled help text' }
      ],
    },
  ];

  serialize(value: any) {
    if(value === null) {
      return '<null>';

    } else if(value === undefined) {
      return '<undefined>';

    } else if(value.toRepresentation) {
      return value.toRepresentation();

    } else {
      return value;
    }
  }

}
