import { Inject, Injectable } from '@angular/core';
import Wizard from 'utils/wizard/Wizard';
import { WizardService } from 'utils/wizard/wizard.service';
import { SchedulingOnboardingWizardComponent } from './scheduling-onboarding-wizard.component';
import Hint from 'utils/angularjs/hints/Hint.js';
import { SchedulingState } from 'scheduling/variables';
import { bind } from 'utils/util';
import { Options as WizardStepOptions } from 'utils/wizard/WizardStep';
import { RequestUserService } from 'utils/requestUser.service';
import { Subject } from 'rxjs';
import { SchedulingConfigService } from 'scheduling/schedulingConfig.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulingOnboardingWizardService {
  wizard?: Wizard;
  private hint: Hint;
  public schedulingState: SchedulingState;

  public readonly wizardFinished = new Subject<void>();

  constructor(
    @Inject('hintService') private hintService,
    private usageTrackingService: UsageTrackingService,
    private requestUserService: RequestUserService,
    private wizardService: WizardService,
    private schedulingConfigService: SchedulingConfigService,
  ) {
    bind(this);

    this.hint = this.hintService.get('schedulingWizard');

    this.schedulingState = this.requestUserService.user.schedulingConfig.state;
  }

  get hasSeenWizard() {
    return this.hint.isSeen;
  }

  submitAppointmentTypes() {
    this.hintService.setHintSeen(this.hint, 'dashboardWelcome');
    this.usageTrackingService.createSegmentEvent(
      'onboardingFlow.completed',
      'onboardingFlow'
    );
  }

  continueWizard(showBookingPageStep = true) {
    if (!this.hint.isSeen) {
      this.showWizard(showBookingPageStep);
    }
  }

  async showWizard(showBookingPageStep = true) {
    const stepList: Partial<WizardStepOptions>[] = [
      {
        name: $localize`Calendar connection`,
      },
      {
        name: $localize`Calendar settings`,
      },
      {
        name: $localize`Add meeting types`,
        validateSubmit: this.submitAppointmentTypes,
      }
    ];

    if (showBookingPageStep) {
      stepList.push({
        shorthand: $localize`Booking page`,
        canGoBack: false
      });
    }

    this.wizard = this.wizardService.create(
      'wizardScheduling',
      SchedulingOnboardingWizardComponent,
      stepList,
      {
        title: $localize`Set up your booking page`,
        showSteps: true,
        closeable: true,
        onClose: this.onClose,
      }
    );

    if (this.schedulingState === SchedulingState.NEW) {
      this.usageTrackingService.createSegmentEvent(
        'onboardingFlow.started',
        'schedulingPage'
      );
      this.wizard.showStep(0);
    } else if (this.schedulingState === SchedulingState.AUTHENTICATED
      || this.schedulingState === SchedulingState.DISCONNECTED
      || this.schedulingState === SchedulingState.EXPIRED) {
      this.wizard.showStep(0);
    } else if (this.schedulingState === SchedulingState.AUTHORIZED) {
      const schedulingConfig = await this.schedulingConfigService.get();
      if (schedulingConfig.providerId === 'scheduling_teamleader') {
        await this.schedulingConfigService.updateTLFSchedulingConfig(schedulingConfig);
        this.wizard.showStep(2);
      } else {
        this.wizard.showStep(1);
      }
    } else if (this.schedulingState === SchedulingState.CONFIGURED) {
      this.wizard.showStep(2);
    } else if (this.schedulingState === SchedulingState.BOOKABLE) {
      this.hintService.setHintSeen(this.hint, 'dashboardWelcome');
      this.wizardFinished.next();
      this.wizardFinished.complete();
    }
  }

  onClose() {
    if (this.hint.isSeen) {
      this.wizardFinished.next();
      this.wizardFinished.complete();
    }
  }
}
