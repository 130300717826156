import { bind } from 'utils/util';
import { SignalStrength } from 'meeting/angularjs/main/signalStrength/signalStrength.service';
import { StreamType } from  'meeting/meeting-room/stream';


const TLF_CS_ORGANIZATION_ID = '29c682f0873c48a19c8b01e26860e9c5';


/**
 * This is intended to be a temporary service, as a kind of pre-A/B test. For meetings in the
 * Teamleader CS account:
 * - At the start of the meeting: set the video quality setting to a random value.
 * - When someone provides feedback: create a Segment event that logs the current video quality,
 *   so that we can combine it in Looker with the feedback score that was given.
 */
export default class VideoQualityExperimentService {

  static get $inject() {
    return [
      'feedbackService',
      'meetingBroadcastService',
      'meetingService',
      'peerConnectionService',
      'settingsService',
      'signalStrengthService',
      'streamService',
      'usageTrackingService',
      'userService',
    ];
  }

  constructor(
    feedbackService,
    meetingBroadcastService,
    meetingService,
    peerConnectionService,
    settingsService,
    signalStrengthService,
    streamService,
    usageTrackingService,
    userService
  ) {
    bind(this);

    this.feedbackService = feedbackService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.peerConnectionService = peerConnectionService;
    this.settingsService = settingsService;
    this.signalStrengthService = signalStrengthService;
    this.streamService = streamService;
    this.usageTrackingService = usageTrackingService;
    this.userService = userService;

    this._sendBadSignalStrengthThrottled = throttle(this._sendBadSignalStrength, 60 * 1000);

    this.meetingBroadcastService.afterInitialization().then(this._afterInitialization);
    this.feedbackService.on('feedback', this._onFeedback);
    this.signalStrengthService.on('audio video', this._onSignalStrength);
  }


  _afterInitialization() {
    if(
      this.userService.iAmOnlyHost
      && this.meetingService.owner.organizationId === TLF_CS_ORGANIZATION_ID
    ) {
      let randomVideoQuality = 1 + Math.round(Math.random());
      this.settingsService.setVideoQuality(randomVideoQuality);
    }
  }


  _onFeedback(rating) {
    this.usageTrackingService.createSegmentEvent(
      'meeting.givenFeedback',
      'meetingPage',
      {
        sessionId: this.userService.mySession.id,
        score: rating,
        videoQuality: this.settingsService.videoQuality === 1 ? 'normal' : 'HD',
      }
    );
  }


  _onSignalStrength(signalStrength) {
    if(signalStrength === SignalStrength.BAD) {
      this._sendBadSignalStrengthThrottled();
    }
  }


  _sendBadSignalStrength() {
    let peerConnectionMode = this.peerConnectionService.handleManager ?
      this.peerConnectionService.handleManager.mode :
      'unknown';

    let streams = {
      local: {
        video: this.streamService.streams.filter(stream => {
          return stream.isLocal && stream.type === StreamType.VIDEO;
        }),
        screen: this.streamService.streams.filter(stream => {
          return stream.isLocal && stream.type === StreamType.SCREEN;
        }),
      },
      remote: {
        video: this.streamService.streams.filter(stream => {
          return !stream.isLocal && stream.type === StreamType.VIDEO;
        }),
        screen: this.streamService.streams.filter(stream => {
          return !stream.isLocal && stream.type === StreamType.SCREEN;
        }),
      },
    };

    this.usageTrackingService.createSegmentEvent(
      'meeting.hadBadConnection',
      'meetingPage',
      {
        sessionId: this.userService.mySession.id,
        videoQuality: this.settingsService.videoQuality === 1 ? 'normal' : 'HD',
        peerConnectionMode: peerConnectionMode,
        sendingVideoStreams: streams.local.video.length,
        sendingScreenStreams: streams.local.screen.length,
        receivingVideoStreams: streams.remote.video.length,
        receivingScreenStreams: streams.remote.screen.length,
      }
    );
  }
}
