class FilePickerWorkerController {
  static get $inject() {
    return [
      'drivePickerService',
      'dropboxPickerService',
      'oneDrivePickerService',
    ];
  }
}


export default {
  controller: FilePickerWorkerController,
  controllerAs: 'filePickerWorkerCtrl',
  template: '',
};
