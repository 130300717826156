import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'notes-modal-button',
  templateUrl: './notes-modal-button.component.html',
})
export class NotesModalButtonComponent {
  @Input() public shouldCollapseButton = false;
  @Input() public shouldBeTranslucent = false;

  public isOpen = false;

  public connectedPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetX: 100,
    offsetY: -10,
  };
}
