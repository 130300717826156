import { errors, EventEmitter, interval, logger, platform } from 'utils/util';


const DIRECTIONS = ['inbound', 'outbound'];
const KINDS = ['audio', 'video'];

const UPDATE_INTERVAL = 2000;
const IGNORE_SELECTED_CANDIDATE_CHANGE_DEBOUNCE = 10000;



export default class StatsTracker {
  constructor(pc) {
    this._bind();
    EventEmitter.setup(this, ['stats', 'selectedCandidatePairChanged']);

    this.pc = pc;

    /**
     * Structures the reports of a RTCPeerConnection.getStats() for easy access. Contains the
     * following reports:
     *  {
     *    inbound: {
     *      audio: inbound-rtp,
     *      video: inbound-rtp,
     *    },
     *    outbound: {
     *      audio: outbound-rtp,
     *      video: outbound-rtp,
     *    },
     *  }
     *
     * The inbound-rtp and outbound-rtp reports have been extended with the following derived
     * properties
     * - bytes: equal to bytesSent or bytesReceived, depending on the direction
     * - bytesDiff: the difference with the last report
     * - bytesPerSecond
     * - bytesPerSecondTarget
     * - packets
     * - packetsDiff
     * - packetsPerSecond
     * - packetsLostDiff
     * - packetsLostPerSecond
     *
     * Furthermore, we add some properties so you can access some referenced reports directly, if
     * they exist:
     * - codec: codec
     * - transport: transport
     * - transport.selectedCandidatePair: candidate-pair
     * - transport.selectedCandidatePair.localCandidate: local-candidate
     * - transport.selectedCandidatePair.remoteCanidate: remote-candidate
     */
    this.stats = this._createStats();

    this.ignoreSelectedCandidateChange = true;
    this.onIceRestart();

    /**
     * During negotiation, a PeerConnection implementation will typically request a certain video
     * bitrate. StatsTracker cannot know this bitrate based on getStats(). If you want this value
     * in your reports, you should call `setBytesPerSecondTarget`.
     */
    this.bytesPerSecondTarget = {
      inbound: null,
      outbound: null,
    };

    this.updateInterval = interval.setInterval(this.update, UPDATE_INTERVAL);
  }


  _bind() {
    this.update = this.update.bind(this);
    this._setIgnoreSelectedCandidateChangeDebounced = debounce(
      this._setIgnoreSelectedCandidateChange,
      IGNORE_SELECTED_CANDIDATE_CHANGE_DEBOUNCE
    );
  }


  setBytesPerSecondTarget(direction, bytesPerSecondTarget) {
    this.bytesPerSecondTarget[direction] = bytesPerSecondTarget;
  }


  /**
   * You should call this whenever an ICE restart has taken place, to avoid an infinite loop of
   * `selectedCandidatePairChanged` events and ICE restarts. This will ignore a selected candidate
   * change for a single update cycle.
   */
  onIceRestart() {
    this._setIgnoreSelectedCandidateChange(true);
    this._setIgnoreSelectedCandidateChangeDebounced(false);
  }

  _setIgnoreSelectedCandidateChange(ignore) {
    this.ignoreSelectedCandidateChange = ignore;
  }


  destroy() {
    interval.clearInterval(this.updateInterval);
    this.updateInterval = null;
  }


  _createStats() {
    // Currently we assume that every peerconnection has exactly 0 or 1 audio and video tracks in
    // each direction. When this changes, we should be able to make a mapping from tracks to the
    // correct reportValue through the RTCMediaStreamTrack_(sender_receiver)_x reportValues.
    return {
      inbound: {
        audio: {},
        video: {},
      },
      outbound: {
        audio: {},
        video: {},
      },
    };
  }


  update() {
    return this._getStats()
      .then(stats => {
        if(!stats) {
          return;
        }

        let candidatePairHasChanged = false;
        for(let direction of DIRECTIONS) {
          for(let kind of KINDS) {
            let report = stats[direction][kind];
            let reportPrev = this.stats[direction][kind];

            report = this._addDifferentialStats(report, reportPrev);
            if(this._candidatePairHasChanged(report, reportPrev)) {
              candidatePairHasChanged = true;
            }

            stats[direction][kind] = report;
          }
        }

        this.stats = stats;

        this.emit('stats', this.stats);
        if(candidatePairHasChanged && !this.ignoreSelectedCandidateChange) {
          this.emit('selectedCandidatePairChanged');
        }
      })
      .catch(error => {
        logger.warn(error);
      });
  }


  _getStats() {
    return $q.resolve()
      .then(() => {
        if(this.pc.signalingState === 'closed') {
          throw new errors.EscapePromiseError();
        }
        return this.pc.getStats();
      })
      .then(reports => {
        let stats = this._createStats();

        for(let report of reports.values()) {
          if(report.type === 'inbound-rtp' || report.type === 'outbound-rtp') {
            let direction = report.type === 'inbound-rtp' ? 'inbound' : 'outbound';
            let kind = report.mediaType;
            // Older versions of Safari didn't have report.mediaType, which is why we need this
            // ugly regex version as a fallback. I'm not sure when report.mediaType was added to
            // Safari.
            if(kind == null) {
              let regex = /^RTC(Outbound|Inbound)RTP(Audio|Video)Stream/;
              let match = report.id.match(regex);
              kind = match[2].toLowerCase();
            }

            report.bytes = report.bytesSent || report.bytesReceived || 0;
            report.packets = report.packetsSent || report.packetsReceived || 0;
            report = this._addReferencedReports(report, reports);

            stats[direction][kind] = report;
          }
        }

        stats.inbound.video.bytesPerSecondTarget = this.bytesPerSecondTarget.inbound;
        stats.outbound.video.bytesPerSecondTarget = this.bytesPerSecondTarget.outbound;

        return stats;
      })
      .catch(error => {
        if(error.constructor !== errors.EscapePromiseError) {
          throw error;
        }
      });
  }


  _addReferencedReports(argReport, reports) {
    let report = Object.assign({}, argReport);
    if(report.codecId) {
      report.codec = reports.get(report.codecId);
    }

    if(report.transportId) {
      let transport = reports.get(report.transportId);
      report.transport = transport;

      if(transport && transport.selectedCandidatePairId) {
        let selectedCandidatePair = reports.get(transport.selectedCandidatePairId);
        transport.selectedCandidatePair = selectedCandidatePair;

        if(selectedCandidatePair.localCandidateId) {
          selectedCandidatePair.localCandidate =
            reports.get(selectedCandidatePair.localCandidateId);
        }
        if(selectedCandidatePair.remoteCandidateId) {
          selectedCandidatePair.remoteCandidate =
            reports.get(selectedCandidatePair.remoteCandidateId);
        }
      }

    } else {
      let selectedCandidatePair = [...reports.values()].find(report => {
        return report.type === 'candidate-pair' && report.nominated;
      });

      if(selectedCandidatePair) {
        report.transport = {
          selectedCandidatePair: selectedCandidatePair,
        };
        if(selectedCandidatePair.localCandidateId) {
          selectedCandidatePair.localCandidate =
            reports.get(selectedCandidatePair.localCandidateId);
        }
        if(selectedCandidatePair.remoteCandidateId) {
          selectedCandidatePair.remoteCandidate =
            reports.get(selectedCandidatePair.remoteCandidateId);
        }
      }
    }

    return report;
  }


  _addDifferentialStats(report, reportPrev) {
    let timeDiff = platform(0, report.timestamp - reportPrev.timestamp);
    if(isNaN(timeDiff)) {
      timeDiff = 0;
    }

    let extra = {
      bytesDiff: 0,
      packetsDiff: 0,
      bytesPerSecond: 0,
      packetsPerSecond: 0,
    };
    if(report.packetsLost != null) {
      extra.packetLostDiff = 0;
      extra.packetsLostPerSecond = 0;
    }

    if(timeDiff > 0) {
      extra.bytesDiff = platform(0, report.bytes - reportPrev.bytes),
      extra.packetsDiff = platform(0, report.packets - reportPrev.packets),
      extra.bytesPerSecond = extra.bytesDiff / timeDiff * 1000;
      extra.packetsPerSecond = extra.packetsDiff / timeDiff * 1000;

      if(report.packetsLost != null) {
        extra.packetsLostDiff = platform(0, report.packetsLost - reportPrev.packetsLost),
        extra.packetsLostPerSecond = Math.round(extra.packetsLostDiff / timeDiff * 1000);
      }
    }



    return Object.assign({}, report, extra);
  }


  _candidatePairHasChanged(report, reportPrev) {
    // Detect ice candidate changes. Chrome can decide to change the active local ice candidate
    // outside of renegotiation. I'm not sure when it does this exactly, but you can trigger it by
    // switching between two access points on the same network, or between WiFi and ethernet.
    // This is not according to the spec, and libnice (used in both Janus and Firefox) does not
    // support this if Chrome is not the offerer. The media will stop flowing and it will never
    // recover from itself. You can fix it by triggering an ICE restart.
    // By monitoring the active candidate pair, we can detect this situation. This detection is not
    // waterproof, so you should also monitor if the incoming data has stopped flowing.
    return (
      report.transport
      && reportPrev.transport
      && report.transport.selectedCandidatePairId
      && reportPrev.transport.selectedCandidatePairId
      && report.transport.selectedCandidatePairId !== reportPrev.transport.selectedCandidatePairId
    );
  }
}
