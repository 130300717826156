<div
  *ngIf="waitingRoomNotificationService.isMissingPermissions"
  class="p d-none d-md-flex field field--warning mt-4"
>
  <field-icon [type]="'info'"></field-icon>
  <div>
    <span i18n>
      You have notifications turned on but your browser is blocking desktop notifications.
    </span>


    <span
      *ngIf="waitingRoomNotificationService.notificationPermission === 'denied'"
      i18n
    >
      If you want to see these notifications, you should <a [href]="seeNotificationsUrl" target="_blank"> update your settings</a> and reload the page.
    </span>

    <span *ngIf="waitingRoomNotificationService.notificationPermission === 'default'">
      <div (click)="waitingRoomNotificationService.requestPermission()" class="a" i18n>Give browser permission</div>
      <div class="mt-2" i18n>
        If desktop notifications still aren't working after giving your browser the necessary permissions, you can check out <a [href]="desktopPermsUrl" target="_blank"> this help article</a>.`
      </div>
    </span>
  </div>
</div>
