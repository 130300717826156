import { Component } from '@angular/core';
import { UrlService } from 'utils/url.service';
import { bind } from 'utils/util';
import { WizardComponent } from 'utils/wizard/Wizard';
import { UsageTrackingService } from 'utils/usageTracking.service';


@Component({
  selector: 'scheduling-onboarding-wizard[wizard]',
  templateUrl: './scheduling-onboarding-wizard.component.html',
  styleUrls: ['./scheduling-onboarding-wizard.component.scss'],
})
export class SchedulingOnboardingWizardComponent extends WizardComponent {
  constructor(
    private usageTrackingService: UsageTrackingService,
    private urlService: UrlService,
  ) {
    super();
    bind(this);
  }

  get doneImage() {
    return this.urlService.images.done;
  }

  get bookingPage() {
    return this.urlService.urls.bookingPage;
  }

  openBookingPage() {
    this.trackBookingPageClick();
    window.open(this.urlService.urls.bookingPage, '_blank');
    // Don't do this immediately, it will cause too much flashing because opening the new tab
    // doesn't happen immediately.
    setTimeout(() => this.wizard.next(), 1000);
  }

  trackBookingPageClick() {
    this.usageTrackingService.createSegmentEvent(
      'onboardingFlow.checkedBookingPage',
      'onboardingFlow',
    );
  }
}
