import { Directive, ElementRef, HostListener, Renderer2, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[character-count]'
})
export class CharacterCountDirective implements OnInit {
  private countDisplay: any;
  @Input() initialCount = 0;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input')
  onInputChange() {
    this.renderer.setProperty(this.countDisplay, 'textContent', this.generateText());
  }

  ngOnInit(): void {
    this.countDisplay = this.renderer.createElement('div');

    this.renderer.addClass(this.countDisplay, 'text-muted');
    this.renderer.addClass(this.countDisplay, 'mt-1');
    this.renderer.addClass(this.countDisplay, 'text-right');

    const text = this.renderer.createText(this.generateText(true));
    this.renderer.appendChild(this.countDisplay, text);
    this.renderer.appendChild(this.elementRef.nativeElement.parentElement, this.countDisplay);
  }

  generateText(init = false): string {
    let count = 0;
    if (init && this.elementRef.nativeElement.value.length === 0) {
      count = this.initialCount;
    } else {
      count = this.elementRef.nativeElement.value.length;
    }
    return (
      `${$localize `Characters`}: ${count}/`
      + `${this.maxLength}`
    );
  }

  private get maxLength() : number {
    return this.elementRef.nativeElement.maxLength;
  }
}
