<div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

<h1 class="page-title" i18n>Meetings</h1>

<div class="d-flex justify-content-between align-items-center mb-4" data-testid="meetingsDiv">
  <scope-switcher
    [(scope)]="scope"
    [scopes]="scopes"
    class="mb-2"
  ></scope-switcher>

  <a
    class="btn btn--primary mr-2 mb-2"
    [href]="schedulingAction.url"
    [target]="schedulingAction.target"
  >
    {{ schedulingAction.text }}
  </a>
</div>

<fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>
<div *ngIf="!fetchError">
  <h2 i18n>Upcoming meetings</h2>

  <div
    class="card card--sm bg-neutral-200"
    *ngIf="shouldShowTeamTeaser"
    i18n
  >When you <a href="{{ inviteUrl }}"> invite extra team members</a>, their meetings will show up here.</div>

  <div class="card card--sm bg-neutral-200" *ngIf="!shouldShowTeamTeaser && stateService.isReady && upcomingAppointments.length === 0">
    <span *ngIf="scope === DashboardScope.PERSONAL" i18n>You have no upcoming meetings.</span>
    <span *ngIf="scope === DashboardScope.TEAM" i18n>Your team has no upcoming meetings.</span>
    <a
      [href]="schedulingAction.url"
      [target]="schedulingAction.target"
    >{{ schedulingAction.text }}</a>.
  </div>

  <div *ngIf="!shouldShowTeamTeaser && upcomingAppointments.length > 0">
    <appointments-table
      [appointments]="upcomingAppointments"
      [scope]="scope"
      (appointmentChange)="refetch()"
    ></appointments-table>
  </div>
</div>

<div *ngIf="pastAppointments.length > 0">
  <span></span>
  <h2 i18n>Past meetings</h2>

  <appointments-table
      [appointments]="pastAppointments"
      [scope]="this.scope"
      (appointmentChange)="refetch()"
  ></appointments-table>
</div>
