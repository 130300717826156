import './globals';
import 'angular';

// Bootstrap Angular
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from './environments/environment';


// Load app modules
import 'src/meeting/static-src/filePicker/index';
import 'meeting/angularjs/index';
import 'organization/index';
import 'scheduling/booking';
import 'teamleader/index';


if(environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(error => {
    console.error(error);
    window.__vecteraSetLoadError(error);
  });
