<div
  class="form-group"
  data-testid="contactFormQuestionFormGroup"
>
  <label
    *ngIf="shouldShowLabel"
    class="label"
    [class.label--required]="question.required"
    data-testid="contactFormQuestionLabel"
  >
    {{ question.label }}
  </label>

  <ng-template #questionInput></ng-template>

  <settings-errors [settingsErrors]="errors"></settings-errors>
</div>
