import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmChangeComponent } from './confirm-change.component';
import { DialogModule } from 'utils/ui-components/dialog';
import { SvgIconModule } from 'utils/ui-components/svg-icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    SvgIconModule,
  ],
  declarations: [
    ConfirmChangeComponent,
  ],
  exports: [
    ConfirmChangeComponent,
  ],
})
export class ConfirmChangeModule {}
