<div class="container container--lg pp" data-testid="contactFormsConfigElement">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <div class="d-flex align-items-center flex-wrap mb-2">
    <go-back-header
      [url]="urlService.urls.contactFormsOverview"
      [headerText]="headerText"
    ></go-back-header>
    <a [attr.href]="'contactFormsCreate'|helpArticle" target="_blank" class="ml-2 text-base text-normal" i18n>Learn more</a>
  </div>

  <div
    *ngIf="fetchError"
    class="field field--error mb-2"
  >
    <field-icon [type]="'error'"></field-icon>
    {{ fetchError }}
  </div>

  <settings-non-field-errors [nonFieldErrors]="errors.nonFieldErrors"></settings-non-field-errors>

  <div *ngIf="stateService.isReady && !fetchError" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <settings-segment-group>
      <settings-segment
        data-testid="contactFormConfigBuildSegment"
        name="Build your form"
        i18n-name
      >
        <contact-form-builder
          [instance]="instance"
          [errors]="errors"
        ></contact-form-builder>
      </settings-segment>

      <settings-segment
        data-testid="contactFormConfigCustomizationSegment"
        name="Customize your form"
        i18n-name
        [hideNext]="true"
      >
        <contact-form-customization
          [instance]="instance"
          [errors]="errors"
        ></contact-form-customization>
      </settings-segment>


      <h1 i18n>Powerful extensions</h1>

      <div class="border border-round-lg overflow-hidden">
        <settings-segment
          data-testid="contactFormConfigTeamleaderSegment"
          *ngIf="requestUserService.user.organization.hasActiveTeamleaderIntegration"
          name="Teamleader Focus CRM behavior"
          i18n-name
          description="Determine how form information will be handled in Teamleader Focus."
          i18n-description
          [icon]="{ 'path': 'logo__teamleader_icon' }"
          [optional]="true"
          [hideNext]="true"
        >
          <contact-form-crm-settings
            [instance]="instance"
            [errors]="errors"
          ></contact-form-crm-settings>
        </settings-segment>
        <settings-segment
          data-testid="contactFormConfigNotificationsSegment"
          name="Notifications"
          i18n-name
          description="Set notifications for yourself, colleagues and customers."
          i18n-description
          [icon]="{ 'path': 'scheduling__48x48_reminders' }"
          [optional]="true"
          [hideNext]="true"
        >
          <contact-form-notifications
            [instance]="instance"
            [errors]="errors"
          ></contact-form-notifications>
        </settings-segment>
        <settings-segment
          data-testid="contactFormConfigRedirectingSegment"
          name="Redirecting"
          description="Send your respondents to your custom URL"
          i18n-description
          i18n-name
          [icon]="{ 'path': 'scheduling__48x48_redirecting' }"
          [optional]="true"
          [hideNext]="true"
        >
          <contact-form-redirect
            [instance]="instance"
            [errors]="errors"
          ></contact-form-redirect>
        </settings-segment>
        <settings-segment
          data-testid="contactFormConfigLegalSegment"
          name="Legal"
          i18n-name
          description="Ask users to accept terms and conditions"
          i18n-description
          [icon]="{ 'path': 'contactForm__legal' }"
          [optional]="true"
          [hideNext]="true"
        >
          <contact-form-legal
            [instance]="instance"
            [errors]="errors"
          ></contact-form-legal>
        </settings-segment>
      </div>
    </settings-segment-group>
  </div>
</div>

<div class="action-footer" *ngIf="stateService.isReady && !fetchError">
    <div class="action-footer__button-row">
    <a
      [attr.href]="urlService.urls.contactFormsOverview"
      class="btn btn--secondary"
      data-testid="contactFormsCancelButton"
      i18n
    >
      Cancel
    </a>

    <a
      [modalTriggerFor]="modalPreview"
      [modalIcon]="{name: 'visible'}"
      [modalTitle]="instance.name"
      class="btn btn--secondary d-md-none"
      i18n
    >
      Preview
    </a>

    <div
      data-testid="contactFormConfigSave"
      class="btn btn--primary"
      [class.disabled]="stateService.isSaving"
      (click)="!stateService.isSaving && save()"
    >
      <span i18n>Save the Contact form</span>
      <loading *ngIf="stateService.isSaving" class="ml-2"></loading>
    </div>
  </div>
</div>

<ng-template #modalPreview>
  <contact-form-preview [contactForm]="instance"></contact-form-preview>

</ng-template>


<ng-template #modalSuccess>
  <div class="d-flex flex-column align-items-center">
    <svg-icon
      class="icon text-primary-400 icon--xl"
      [svgName]="'checkmark_badged'"
      [svgStyle]="'filled'"
    ></svg-icon>

    <h3 i18n>Success!</h3>

    <p class="mb-8" i18n>You have successfully created a Contact form.</p>

    <div
      data-testid="contactFormConfigShare"
      class="btn btn--secondary align-self-stretch mb-4"
      [modalTriggerFor]="shareForm"
      [modalTriggerData]="{ contactForm: instance }"
      [modalIcon]="{name: 'share_android'}"
      [modalTitle]="sharingOptionsTitle"
      (modalClosed)="goToOverview()"

      i18n
    >
      Share your form
  </div>

    <a
      data-testid="contactFormConfigGotoOverview"
      class="btn btn--primary align-self-stretch"
      [attr.href]="urlService.urls.contactFormsOverview"
      i18n
    >
      Go to Contact forms
    </a>
  </div>
</ng-template>

<ng-template #shareForm let-contactForm="contactForm">
  <sharing-options
    [name]="contactForm.name"
    [url]="contactForm.url"
    [embeddedUrl]="contactForm.embeddedUrl"
    [id]="contactForm.id"
    context="contactForm"
  >
  </sharing-options>
</ng-template>
