import { Component } from '@angular/core';
import { SiteService } from 'utils/site.service';
@Component({
  selector: 'upgrade-button',
  templateUrl: './upgrade-button.component.html',
})
export class UpgradeButtonComponent {

  constructor(
    public siteService: SiteService,
  ) {}

  get planText() {
    return $localize `:Example; Upgrade to Pro:Upgrade to ${this.siteService.site.proPlanName}`;
  }
}
