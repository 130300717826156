import Model from 'utils/models';
import * as fields from 'utils/fields';
import { Organization } from 'organization/models/Organization';


export class User extends Model {
  static get fields() {
    return {
      firstName: new fields.StringField(),
      lastName: new fields.StringField(),
      dateJoined: new fields.DateTimeField(),
      organization: new fields.RelatedField({
        Model: Organization,
        readOnly: true,
      }),
    };
  }

  // eslint-disable-next-line no-unused-vars
  static get basePath() {
    return 'users';
  }

  get subscription() {
    return this.organization.subscription;
  }

  get hasChargebeeSubscription() {
    return ['future', 'active', 'non_renewing'].includes(this.subscription.chargebeeStatus);
  }

  get isLazy() {
    return !this.isAuthenticated;
  }

  get teamleaderId() {
    if(this.thirdPartyProviderData && this.thirdPartyProviderData.teamleader) {
      return this.thirdPartyProviderData.teamleader.id;
    } else {
      return null;
    }
  }
  get isTeamleaderUser() {
    return this.teamleaderId != null;
  }

  get fullName() {
    if(this.lastName) {
      return this.firstName + ' ' + this.lastName;
    } else {
      return this.firstName;
    }
  }
}
