import ToolService from './tool.service';
import ToolsComponent from './tools.component';
import ShowForToolsDirective from './showForTools.directive';
import ToolColorDirective from './toolColor.directive';
import ToolsSymbolItemDirective from './toolItemFormula.directive';

angular
  .module('meeting')
  .service('toolService', ToolService)
  .controller('ToolsCtrl', ToolsComponent.controller)
  .component('tools', ToolsComponent)
  .directive('showForTools', ShowForToolsDirective)
  .directive('toolColor', ToolColorDirective)
  .directive('toolsSymbolItem', ToolsSymbolItemDirective);
