import 'angular-clipboard';
import 'angularjs-scroll-glue';
import 'mathquill/build/mathquill';
import 'ngtouch';
import 'webrtc-adapter';
import 'fabric';

let dependencies = [
  'angular-clipboard',
  'ngTouch',
  'luegg.directives',
  'vecteraUtil',
];

angular.module('meeting', dependencies);
