import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactFormPageModule } from '../contact-form-page';
import { UtilsModule } from 'utils/utils.module';
import { ContactFormPreviewComponent } from './contact-form-preview.component';


@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    UtilsModule,

    ContactFormPageModule,
  ],
  declarations: [
    ContactFormPreviewComponent,
  ],
  exports: [
    ContactFormPreviewComponent,
  ],
})
export class ContactFormPreviewModule {}
