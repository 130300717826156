import ChatBoxService from './chatBox.service';
import EmojiService from './emoji.service';
import ChatComponent from './chat.component';
import ChatInputDirective from './chatInput.directive';
import emojiInputImageDirective from './emojiImage.directive';
import EmojiController from './emoji.controller';

angular
  .module('meeting')
  .service('chatBoxService', ChatBoxService)
  .service('emojiService', EmojiService)
  .controller('EmojiCtrl', EmojiController)
  .component('chat', ChatComponent)
  .directive('chatInput', ChatInputDirective)
  .directive('emojiImage', emojiInputImageDirective);
