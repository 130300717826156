import { Component, Input, Inject } from '@angular/core';

import {
  IntegrationSyncServiceInterface,
  SYNC_SERVICE,
  State as SyncState
} from '../integration-sync/integration-sync.service';

@Component({
  selector: 'share-menu',
  templateUrl: './share-menu.component.html',
})
export class ShareMenuComponent {
  @Input() content!: string;
  @Input() email = false;
  @Input() integration = false;
  @Input() integrationOptionDisabled = false;
  @Input() trackingViaEmailCallback?: () => any;
  @Input() trackingViaIntegrationCallback?: (integrations: string[]) => any;
  @Input() whiteboard = false;
  @Input() exportToWhiteboardCallback?: () => any;

  readonly State = SyncState;

  constructor(
    @Inject(SYNC_SERVICE) public syncService: IntegrationSyncServiceInterface
  ) {}

  public shareViaEmail(): void {
    const date = new Date().toLocaleDateString();
    const subjectPrefix = $localize`Meeting summary`;
    const subject = encodeURIComponent(`${subjectPrefix} – ${date}`);
    const body = encodeURIComponent(this.content);
    window.open(`mailto:?subject=${subject}&body=${body}`);
    if (this.trackingViaEmailCallback) {
      this.trackingViaEmailCallback();
    }
  }

  public exportToWhiteboard(): void {
    if(this.exportToWhiteboardCallback) {
      this.exportToWhiteboardCallback();
    }
  }

  get state(): SyncState {
    return this.syncService.state;
  }

  get integrations(): string[] {
    return this.syncService.activeIntegrationNames();
  }

  public saveContentClicked(): void {
    if (this.syncService.canSaveContent()) {
      if (this.trackingViaIntegrationCallback) {
        this.trackingViaIntegrationCallback(this.integrations);
      }
      this.syncService.saveContent(this.content);
    }
  }
}
