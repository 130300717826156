import { Pipe, PipeTransform } from '@angular/core';
import { dateTime } from 'utils/util';


@Pipe({
  name: 'readableDate',
})
export class ReadableDatePipe implements PipeTransform {
  transform(
    input: Date,
    hideCurrentYear = false,
    useFullFormat = false,
    optionsParam: Intl.DateTimeFormatOptions | null = null
  ): string {
    if(dateTime.isToday(input) && !useFullFormat) {
      return $localize `today`;
    } else if(dateTime.isTomorrow(input) && !useFullFormat) {
      return $localize `tomorrow`;
    } else {
      const locale = dateTime.getLanguageCode();
      const options: Intl.DateTimeFormatOptions = optionsParam || {
        month: 'long',
        day:'numeric'
      };
      if(!hideCurrentYear || new Date().getFullYear() !== input.getFullYear()) {
        options.year = 'numeric';
      }
      return input.toLocaleDateString(locale, options);
    }
  }
}
