import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactFormPreviewModule } from 'contactForm/contact-form-preview';
import { ContactFormPageModule } from 'contactForm/contact-form-page';
import { UtilsModule } from 'utils/utils.module';
import {
  ContactFormConfigQuestionComponent
} from './contact-form-config-question/contact-form-config-question.component';
import {
  ContactFormConfigQuestionsComponent
} from './contact-form-config-questions/contact-form-config-questions.component';
import {
  ContactFormConfigComponent
} from './contact-form-config.component';
import {
  EntityCreationComponent
} from './entity-creation/entity-creation.component';
import { TagBadgeComponent } from './entity-creation/tag-badge.component';
import { MappingFieldsService } from './mapping-fields.service';
import {
  MoneyConfigComponent, MultiOptionConfigComponent
} from './question-type-config';
import {
  ContactFormNotificationsComponent,
  ContactFormLegalComponent,
  ContactFormBuilderComponent,
  ContactFormCustomizationComponent,
  ContactFormCrmSettingsComponent,
  ContactFormRedirectComponent
} from './segments';


@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    ContactFormPageModule,
    DragDropModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    UtilsModule,

    ContactFormPageModule,
    ContactFormPreviewModule,
  ],
  declarations: [
    ContactFormConfigComponent,
    ContactFormConfigQuestionComponent,
    ContactFormConfigQuestionsComponent,
    EntityCreationComponent,
    TagBadgeComponent,

    // Segments
    ContactFormNotificationsComponent,
    ContactFormLegalComponent,
    ContactFormBuilderComponent,
    ContactFormCustomizationComponent,
    ContactFormCrmSettingsComponent,
    ContactFormRedirectComponent,

    // Config for specific question types
    MoneyConfigComponent,
    MultiOptionConfigComponent,
  ],
  providers: [
    MappingFieldsService,
  ],
  exports: [
    ContactFormConfigQuestionsComponent,
    EntityCreationComponent,
  ]
})
export class ContactFormConfigModule {}
