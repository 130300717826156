import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { ModalConfig, ModalService } from '../modal';
import {
  DialogComponent, DialogConfig, DIALOG_CONFIG
} from './dialog.component';


export const defaultDialogConfig = {
  cancelText: $localize `Cancel`,
  confirmText: $localize `Confirm`,
  confirmButtonClass: 'btn--primary',
};


@Injectable()
export class DialogService {
  constructor(
    private injector: Injector,
    private modalService: ModalService,
  ) {}

  /**
   * Open a dialog.
   *
   * @param config - The configuration that defines the dialog.
   * @returns A reference to the dialog that can be used to interact with it and subscribe to
   *  changes.
   */
  open(config: Partial<DialogConfig>) {
    const fullConfig = { ...defaultDialogConfig, ...config };
    const injector = this.createInjector(fullConfig);
    const componentPortal = new ComponentPortal(DialogComponent, null, injector);
    const modalConfig: Partial<ModalConfig> = {
      icon: fullConfig.icon,
      title: fullConfig.title,
      modalClass: 'modal--sm',

      componentPortal: componentPortal,
    };

    return this.modalService.open(modalConfig);
  }


  private createInjector(config: DialogConfig) {
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DIALOG_CONFIG, useValue: config },
      ],
    });
    return injector;
  }
}
