import errors from '../util/errors';

export default function throttleFactory() {

  return function throttle(callback, delay, execTrailing) {
    if(typeof callback !== 'function') {
      throw new errors.InvalidArgumentError('callback must be a function');
    }
    if(execTrailing == null) {
      execTrailing = false;
    }

    let that, lastExec = 0, args, timeoutId;

    function exec() {
      throttled.cancel();
      lastExec = Date.now();
      callback.apply(that, args);
    }

    function throttled() {
      that = this;  // eslint-disable-line no-invalid-this
      args = arguments;

      if(!timeoutId) {
        let elapsed = Date.now() - lastExec;
        if(elapsed > delay) {
          timeoutId = $timeout(exec);
        } else if(execTrailing) {
          timeoutId = $timeout(exec, delay - elapsed);
        }
      }
    }

    throttled.cancel = function cancel() {
      if(timeoutId) {
        $timeout.cancel(timeoutId);
        timeoutId = null;
      }
    };

    return throttled;
  };
}
