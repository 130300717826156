import Model from 'utils/models';
import * as fields from 'utils/fields';
import { Meeting } from 'meeting/models/Meeting';
import { User } from 'core/models/User';
import { AppointmentUser } from 'scheduling/models/AppointmentUser';
import { AppointmentType } from 'scheduling/models/AppointmentType';


export class Appointment extends Model {
  static get fields() {
    return {
      name: new fields.StringField(),
      location: new fields.StringField(), // Location Enum
      address: new fields.StringField(),
      telephone: new fields.StringField(),
      url: new fields.StringField(),
      isCancelled: new fields.BooleanField(),
      start: new fields.DateTimeField(),
      end: new fields.DateTimeField(),
      meeting: new fields.RelatedField({
        Model: Meeting,
      }),
      meetingType: new fields.RelatedField({
        Model: AppointmentType,
      }),
      sendUpdates: new fields.BooleanField(),
      host: new fields.RelatedField({
        Model: User,
        readOnly: true,
      }),
      guest: new fields.RelatedField({
        Model: User,
        readOnly: true,
      }),
      guests: new fields.RelatedField({
        Model: AppointmentUser,
        many: true,
        readOnly: true,
      }),
    };
  }
  // eslint-disable-next-line no-unused-vars
  static get basePath() {
    return 'appointments';
  }

  static get defaultInclude() {
    return ['meeting', 'host', 'guest', 'guests'];
  }


  cancel(sendUpdates, isCancelledByGuest, token) {
    const data = {
      isCancelled: true,
      sendUpdates: sendUpdates,
      isCancelledByGuest: isCancelledByGuest ?? false
    };

    let apiConfig = {};
    if(token != null) {
      apiConfig = {
        params: {
          token: token,
        }
      };
    }
    return this.apiService.patch(`appointments/${this.id}/cancel`, data, apiConfig)
      .then(response => {
        this.isCancelled = response.data.isCancelled;
        this.sendUpdates = response.data.sendUpdates;
        this.isCancelledByGuest = response.data.isCancelledByGuest;
        return response;
      });
  }

  get primaryGuest() {
    return this.guests.find(guest => guest.isPrimaryGuest);
  }
}


export class UserAppointment extends Appointment {
  static listPath(identifiers) {
    return `users/${identifiers.userId}/appointments`;
  }
}

export class OrganizationAppointment extends Appointment {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/appointments`;
  }
}
