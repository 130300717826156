import TileController from '../tiles/tile.controller';
import { browser } from 'utils/util';


export default class ContentTileController extends TileController {
  constructor(...args) {
    super(...args);

    this.browser = browser;
  }
}
