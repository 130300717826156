<div *ngIf="numPages > 1" class="pagination">
  <div
    class="pagination__item"
    [class.disabled]="page === 1"
    (click)="page !== 1 && previous()"
  >
    <svg-icon class="icon" [svgName]="'chevron_left'"></svg-icon>
  </div>

  <div
    *ngFor="let p of range"
    class="pagination__item"
    [class.pagination__item--active]="p === page"
    [class.pagination__item--ellipsis]="p < 0"
    (click)="p > 0 && setPage(p)"
  >
    {{ p > 0 ? p : '…' }}
  </div>

  <div
    class="pagination__item"
    [class.disabled]="page === numPages"
    (click)="page !== numPages && next()"
  >
    <svg-icon class="icon" [svgName]="'chevron_right'"></svg-icon>
  </div>
</div>
