
import { Component, Input } from '@angular/core';
import { ColorMode } from 'utils/angularjs/profileImage/profileImage.component';
import { FormatStringArrayPipe } from 'utils/pipes/format-string-array.pipe';
import { User } from 'core/models/User';

@Component({
  selector: 'profile-images',
  templateUrl: './profile-images.component.html',
  styleUrls: ['./profile-images.component.scss'],
})
export class ProfileImagesComponent {
  @Input() users!: User[];
  @Input('maxBubbles') _maxBubbles?: number;
  @Input() size = 30;
  @Input() colorMode: ColorMode = ColorMode.AUTO;

  public ColorModeEnum = ColorMode;

  constructor(
    private formatStringArrayPipe: FormatStringArrayPipe
  ) {
  }

  get maxBubbles() {
    if(!this._maxBubbles) {
      return this.users.length;
    }
    return this._maxBubbles;
  }

  get maxShownUsers() {
    const numUsers = this.users.length;
    if(numUsers <= this.maxBubbles) {
      return this.maxBubbles;
    } else if(this.maxBubbles === 1) {
      return 0;
    } else {
      // amount of users is more than max bubbles
      // reserve space for a bubble showing the amount of overflow
      return this.maxBubbles - 1;
    }
  }

  getUserName(user): string {
    return user.fullName;
  }

  get hiddenUserNames(): string {
    return this.formatStringArrayPipe.transform(
      this.users.map(user => user.fullName).slice(this.maxShownUsers, this.users.length)
    );
  }
}
