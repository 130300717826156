export default class MediaDeviceNotificationController {
  static get $inject() {
    return [
      'mediaDeviceNotificationService'
    ];
  }

  constructor(
    mediaDeviceNotificationService
  ) {
    this.mediaDeviceNotificationService = mediaDeviceNotificationService;
  }
}
