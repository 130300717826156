import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from './svg-icon.component';
import { FieldIconComponent } from './field-icon.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SvgIconComponent,
    FieldIconComponent,
  ],
  exports: [
    SvgIconComponent,
    FieldIconComponent,
  ],
})
export class SvgIconModule {}
