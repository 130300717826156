import { DROPDOWN_ID } from './mainChatButton.component';


export default class MainChatController {
  static get $inject() {
    return [
      '$element',
      'dropdownService',
      'chatBoxService',
      'userService',
      'resetService',
    ];
  }

  constructor(
    $elem,
    dropdownService,
    chatBoxService,
    userService,
    resetService
  ) {
    this._bind();

    this.dropdownService = dropdownService;
    this.chatBoxService = chatBoxService;
    this.userService = userService;
    this.resetService = resetService;

    this.$elem = $elem;
    this.$elemTextarea = null;

    this.chatBox = this.chatBoxService.get('main');
  }

  _bind() {
    this._onDropdownActive = this._onDropdownActive.bind(this);
    this._onDropdownInactive = this._onDropdownInactive.bind(this);
  }


  $onInit() {
    this.dropdownService.on('active', this._onDropdownActive);
    this.dropdownService.on('inactive', this._onDropdownInactive);

    $timeout(() => {
      this.$elemTextarea = this.$elem.find('textarea');
    });
  }

  $onDestroy() {
    this.dropdownService.off('active', this._onDropdownActive);
    this.dropdownService.off('inactive', this._onDropdownInactive);
  }


  _onDropdownActive(dropdown) {
    if(dropdown.id === DROPDOWN_ID) {
      $timeout(() => this.$elemTextarea.focus());
    }
  }

  _onDropdownInactive(dropdown) {
    if(dropdown.id === DROPDOWN_ID && this.$elemTextarea) {
      this.$elemTextarea.blur();
    }
  }


  reset() {
    this.resetService.reset({
      chat: true
    });
  }
}
