import { bind, logger } from 'utils/util';
import { Inject, Injectable } from '@angular/core';
import { WaitingRoomNotificationService } from './notifications';
import { Meeting } from 'meeting/models/Meeting';

@Injectable({
  providedIn: 'root',
})
export class WaitingRoomService {
  constructor(
    @Inject('waitingRoomSessionsService') private waitingRoomSessionsService,
    @Inject('waitingRoomSocketService') private waitingRoomSocketService,
    @Inject('modelFactory') private modelFactory,

    public waitingRoomNotificationService: WaitingRoomNotificationService
  ) {
    bind(this);

    this.waitingRoomSocketService.on('close', this.onSocketClose);
    this.waitingRoomSocketService.open();
  }

  /************
  * LISTENERS *
  ************/

  private onSocketClose(closeReason, reopen: boolean) {
    if(reopen) {
      return;
    }
    this.waitingRoomNotificationService.showErrorNotification(
      $localize `Something went wrong loading the page. Please try again.`
    );
  }


  /******************************
  * MEETING AND KNOCKER ACTIONS *
  ******************************/

  /**
   * Create a meeting room with a preferred key, and redirect yourself and optionally a waiting
   * room guest to it.
   */
  createPreferredAndRedirect(
    preferredKey: string,
    meetingTemplate: object | null,
    waitingRoomSession: object
  ): Promise<any> {
    return this.createMeetingRoom(null, preferredKey, meetingTemplate)
      .then(response => this.performRedirects(response.data, waitingRoomSession))
      .catch(this.onApiError);
  }


  /**
   * Redirect yourself and optionally a waiting room guest to an existing meeting.
   */
  redirect(meeting: object, waitingRoomSession: object, shouldRedirectSelf = true): Promise<any> {
    if(shouldRedirectSelf == null)  {
      shouldRedirectSelf = true;
    }
    return this.performRedirects(meeting, waitingRoomSession, shouldRedirectSelf)
      .catch(this.onApiError);
  }


  private createMeetingRoom(
    key: string | null,
    preferredKey: string,
    meetingTemplate: object | null
  ) {
    const values = {};
    if(key) {
      values['key'] = key;
    }
    if(preferredKey) {
      values['preferredKey'] = preferredKey;
    }
    if(meetingTemplate) {
      values['templateId'] = meetingTemplate['id'];
    }

    const meeting = this.modelFactory.createInstance(Meeting, {
      values: values
    });

    return meeting.save();
  }

  /**
   * Redirect yourself and optionally a waiting room guest to a meeting room.
   */
  private performRedirects(
    meeting: Meeting,
    waitingRoomSession: object,
    shouldRedirectSelf = true
  ): Promise<any> {
    let promise = Promise.resolve();
    if(waitingRoomSession) {
      promise = promise.then(() => this.redirectGuest(meeting, waitingRoomSession));
    }

    return promise.then(() => {
      if(shouldRedirectSelf) {
        this.redirectSelf(meeting, false);
      }
    });
  }

  private redirectGuest(meeting: Meeting, waitingRoomSession: object) {
    return this.waitingRoomSessionsService.performRedirect(meeting, waitingRoomSession);
  }
  private redirectSelf(meeting: Meeting, sameTab: boolean) {
    this.openWindow(meeting.url, sameTab ? '_self' : '_blank');
  }

  denyAccess(waitingRoomSession: object) {
    return this.waitingRoomSessionsService.denyAccess(waitingRoomSession)
      .catch(this.onApiError);
  }

  /*******
  * UTIL *
  ********/

  openWindow(url: string, target: string) {
    const newWindow = window.open(url, target);
    if(!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // The popup failed. Just use the same tab in this case.
      window.location.href = url;
    }
  }

  private onApiError(error) {
    let errorMessage =  error && error.message ;
    if(errorMessage == null || error.response.status === 500) {
      logger.error(error);
      errorMessage = $localize `We couldn't process your request. Please try again.`;
    }

    this.waitingRoomNotificationService.showErrorNotification(errorMessage);
  }
}
