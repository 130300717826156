import MainChatSyncService from './mainChatSync.service';
import MainChatNotifyService from './mainChatNotify.service';
import MainChatController from './mainChat.controller';
import mainChatButtonComponent from './mainChatButton.component';
import mainChatHeaderComponent from './mainChatHeader.component';


angular
  .module('meeting')
  .service('mainChatSyncService', MainChatSyncService)
  .service('mainChatNotifyService', MainChatNotifyService)
  .controller('MainChatCtrl', MainChatController)
  .component('mainChatButton', mainChatButtonComponent)
  .component('mainChatHeader', mainChatHeaderComponent);
