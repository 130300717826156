import Sound from './Sound';


export default class SoundFactory {
  static get $inject() {
    return [
      'mediaDeviceService',
    ];
  }

  constructor(
    mediaDeviceService
  ) {
    this.mediaDeviceService = mediaDeviceService;
  }

  create(...args) {
    return new Sound(this.mediaDeviceService, ...args);
  }
}
