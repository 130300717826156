import { EventEmitter, format, logger } from 'utils/util';


const SEND_THROTTLE = 10000;

export default class FeedbackService {
  static get $inject() {
    return [
      'apiService',
      'languageService',
      'meetingBroadcastService',
      'meetingService',
      'modalService',
      'recordingService',
      'userService',
    ];
  }

  constructor(
    apiService,
    languageService,
    meetingBroadcastService,
    meetingService,
    modalService,
    recordingService,
    userService
  ) {
    this._bind();
    EventEmitter.setup(this, ['feedback']);

    this.apiService = apiService;
    this.languageService = languageService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.modalService = modalService;
    this.recordingService = recordingService;
    this.userService = userService;

    this.rating = 0;
    this.message = '';
    this.feedback = null;

    this.sendPromise = $q.resolve();
  }

  _bind() {
    this._sendNow = this._sendNow.bind(this);
    this._sendThrottled = throttle(this._sendNow, SEND_THROTTLE, true);
  }


  get promptIsCustom() {
    return window.TEXT_STRINGS.feedbackMessage.isCustom;
  }

  get prompt() {
    // The custom prompt is intended to be a question from the host to the guest. This is never
    // shown to the host. Instead, we show the default prompt, since we (as Vectera) are still
    // interested in any feedback the host may have about their experience with Vectera.
    return this.promptIsCustom && !this.userService.mySession.accessLevel.isHost ?
      this.customPrompt :
      this.defaultPrompt;
  }
  get defaultPrompt() {
    return gettextCatalog.getString('How was your meeting experience?');
  }
  get customPrompt() {
    let promptDict = window.TEXT_STRINGS.feedbackMessage;
    return this.languageService.getCustomOrTranslatedString(promptDict);
  }

  get shouldShowMenuItem() {
    if(!this.userService.mySession.isInitialized) {
      return false;
    }
    if(this.userService.mySession.accessLevel.isHost) {
      return true;
    }
    return (
      !this.meetingService.settings.whitelabel.hasAddon
      && !this.promptIsCustom
    );
  }

  get shouldAskAfterMeetingEnded() {
    // Some customers with the whitelabel were not happy that feedback (about Vectera) was asked
    // to their meeting guests, so we disabled the feedback modal in these cases. We do offer the
    // "custom feedback prompt" feature if customers have a question of their own that they want to
    // ask their guests.
    return (
      this.userService.mySession.accessLevel.isHost
      || !this.meetingService.settings.whitelabel.hasAddon
      || this.promptIsCustom
    );
  }

  /**************************
   * Set rating and message *
   **************************/

  setRating(rating) {
    if(rating === this.rating) {
      return;
    }
    this.rating = rating;
    this._sendThrottled();
  }


  setMessage(message) {
    if(message === this.message) {
      return;
    }
    this.message = message;
    this._sendThrottled();
  }


  send() {
    this._sendThrottled.cancel();
    this._sendNow();
  }

  _sendNow() {
    this.emit('feedback', this.rating, this.message);
    this.sendPromise = this.sendPromise
      .then(() => {
        if(this.feedback == null) {
          return this._createFeedback();
        } else {
          return this._updateFeedback();
        }
      })
      .catch(error => {
        logger.warn(error);
      });
  }


  _createFeedback() {
    if(!this.rating && !this.message) {
      return $q.resolve();
    }

    let data = {
      sessionId: this.userService.mySession.id,
      rating: this.rating,
      message: this.message,
    };

    return this.apiService.post('feedbacks', data)
      .then((response) => {
        this.feedback = response.data;
        return response;
      });
  }


  _updateFeedback() {
    if(!this.rating && !this.message) {
      return $q.resolve();
    }

    let data = {
      rating: this.rating,
      message: this.message,
    };

    return this.apiService.patch(format('feedbacks/%s', this.feedback.id), data)
      .then((response) => {
        this.feedback = response.data;
        return response;
      });
  }
}
