import * as fields from 'utils/fields';
import { IntegrationConfig } from './IntegrationConfig';

export class HubSpotConfig extends IntegrationConfig {
  static get fields() {
    return Object.assign({}, IntegrationConfig.fields, {
      userEmail: new fields.StringField(),
      selectedProperties: new fields.JSONField({
        default: () => [],
      }),
    });
  }

  static readPath(identifiers) {
    return `organizations/${identifiers.organizationId}/hubSpotConfig`;
  }

  get updatePath() {
    return `organizations/${this.organizationId}/hubSpotConfig`;
  }

  get disconnectPath() {
    return `${this.updatePath}/disconnect`;
  }
}
