export default class Hint {

  constructor(hintService, name) {
    this.hintService = hintService;

    this.name = name;
    this.forceShow = false;
  }

  setSeen() {
    this.forceShow = false;
    return this.hintService.setHintSeen(this.name);
  }
  get isSeen() {
    return this.hintService.hasSeenHint(this.name);
  }
  get shouldShow() {
    return !this.isSeen || this.forceShow;
  }

  toggle() {
    this.forceShow = !this.forceShow;
  }
}
