import Model from 'utils/models';
import * as fields from 'utils/fields';
import { User } from 'core/models/User';
import { Organization } from 'organization/models/Organization';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { ContactForm } from 'contactForm/models/ContactForm';
import { Meeting } from 'meeting/models/Meeting';

export const ContactWidgetBehaviour = Object.freeze({
  TAB: 'tab',
  MODAL: 'modal'
});

export class ContactWidget extends Model {
  static get fields() {
    return {
      appointmentType: new fields.RelatedField({ Model: AppointmentType }),
      user: new fields.RelatedField({ Model: User }),
      organization: new fields.RelatedField({ Model: Organization }),
      contactForm: new fields.RelatedField({ Model: ContactForm }),
      meeting: new fields.RelatedField({ Model: Meeting }),
      showIcon: new fields.BooleanField({ default: true }),
      icon: new fields.FileField(),
      text: new fields.StringField({ maxLength: 30 }),
      behaviour: new fields.ChoiceField({
        choices: Object.values(ContactWidgetBehaviour),
        default: ContactWidgetBehaviour.MODAL,
      }),
      buttonColor: new fields.StringField(),
      textColor: new fields.StringField(),
    };
  }

  static get basePath() {
    return 'contactWidgets';
  }

  static get defaultInclude() {
    return ['appointmentType', 'user', 'contactForm', 'meeting', 'meeting.owner'];
  }
}
