import { Component, EventEmitter, Output, Input } from '@angular/core';

import { SmartSummaryService } from '../../../smart-summary/smart-summary.service';


@Component({
  selector: 'notes-header',
  templateUrl: './notes-header.component.html',
  styleUrls: ['./notes-header.component.scss']
})
export class NotesHeaderComponent {
  @Output() toggleSize = new EventEmitter<void>();
  @Input() isMaximized = false;

  constructor(
    private smartSummaryService: SmartSummaryService
  ) {
  }

  public get canUseSmartSummary(): boolean {
    return this.smartSummaryService.canUseSmartSummary;
  }

  public get smartSummaryLimitReached(): boolean {
    return this.smartSummaryService.state.stage === 'limit-reached';
  }

  public get smartSummarySecondsUsed(): number {
    return this.smartSummaryService.usage.secondsUsed;
  }

  public get totalAllowedSmartSummarySeconds(): number {
    return this.smartSummaryService.usage.secondsAllowed;
  }

  public requestMoreHoursClicked(): void {
    this.smartSummaryService.requestMoreHours();
  }

  changeSize(): void {
    this.toggleSize.emit();
  }
}
