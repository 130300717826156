export default class ResetController {
  static get $inject() {
    return [
      'clearContentService',
    ];
  }

  constructor(
    clearContentService
  ) {
    this.clearContentService = clearContentService;

    this.dontShowAgain = false;
  }


  doAction(shouldClear, callback) {
    let promise = $q.resolve();
    if(shouldClear) {
      promise = promise.then(() => this.clearContentService.clearContent());
    }
    if(this.dontShowAgain) {
      promise = promise.then(() => this.clearContentService.setModalSeen());
    }
    promise.then(() => {
      this.clearContentService.hasRelevantActivity = false;
      this.clearContentService.hideModal();
      callback();
    });
  }


  getInfoText(beforeMeetingEnd) {
    if(beforeMeetingEnd) {
      return gettextCatalog.getString(
        'Clear the meeting room content before leaving?'
      );
    } else {
      return gettextCatalog.getString(
        'Clear the meeting room content?'
      );
    }
  }
}
