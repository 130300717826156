<div
  *ngIf="this.waitingRoomNotificationsEnabled"
  class="btn btn--square btn--secondary btn--text-success"
  (click)="toggleWaitingRoomNotifications()"
  matTooltip="You will be notified of guests on your meeting rooms. Click to disable notifications."
  i18n-matTooltip
  >
  <svg-icon class="btn__icon" [svgName]="'bell'"></svg-icon>
</div>
<div
  *ngIf="!this.waitingRoomNotificationsEnabled"
  class="btn btn--square btn--secondary btn--text-error"
  (click)="toggleWaitingRoomNotifications()"
  matTooltip="Notifications are disabled. Click to be notified of guests on your meeting rooms."
  i18n-matTooltip
>
  <svg-icon class="btn__icon" [svgName]="'bell_off'"></svg-icon>
</div>
