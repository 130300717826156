<div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

<div class="container" *ngIf="shouldShowWelcome" data-testid="contactWidgetPromoField">
  <div class="promo-field">
    <div class="promo-field__title" i18n>Get started with creating contact widgets</div>

    <a
      class="btn btn--primary btn--lg d-sm-none mt-4"
      [href]="urlService.urls.contactWidgetCreate"
      data-testid="getStartedButton"
      i18n
    >
      Get started
    </a>

    <div class="promo-field__steps">
      <div class="promo-field__step">
        <svg-icon
          class="promo-field__step-image"
          [svgPath]="'contactWidget__welcomeCreateButton'"
        ></svg-icon>
        <div class="promo-field__step-title" i18n>Create your button</div>
      </div>

      <div class="promo-field__step">
        <svg-icon
          class="promo-field__step-image"
          [svgPath]="'contactWidget__welcomeAddToWebsite'"
        ></svg-icon>
        <div class="promo-field__step-title" i18n>Add it to your website</div>
      </div>

      <div class="promo-field__step">
        <svg-icon
          class="promo-field__step-image"
          [svgPath]="'contactWidget__welcomeAttractCustomers'"
        ></svg-icon>
        <div class="promo-field__step-title" i18n>Start attracting customers</div>
      </div>
    </div>

    <div class="d-sm-none">
      <p i18n>Add the branded contact button to your website and let your customers to schedule a video call or a meeting on a time and date that suits you both</p>
      <div class="text-h2" i18n>Try an easy way to get new customers with no effort from your side</div>
    </div>

    <div class="d-none d-sm-flex flex-column align-items-center">
      <h2 class="pp" i18n>Try an easy way to get new customers with no effort from your side</h2>

      <p class="pp" i18n>Add the branded contact button to your website and let your customers to schedule a video call or a meeting on a time and date that suits you both</p>

      <a
        class="btn btn--primary btn--lg"
        [href]="urlService.urls.contactWidgetCreate"
        (click)="track('contactWidget.clickedGetStarted')"
        data-testid="getStartedButton"
        i18n
      >
        Get started
      </a>
    </div>
  </div>
</div>


<div class="container container--md" *ngIf="shouldShowMain">
  <h1 class="page-title d-flex align-items-center">
    <span i18n class="mr-3">Contact widgets</span>
    <svg-icon
      class="icon clickable mr-3"
      [svgName]="'info_badged'"
      *ngIf="!shouldShowHint"
      (click)="shouldShowHint = true"
      matTooltip="Click for more info"
      i18n-matTooltip
    ></svg-icon>

    <div class="ml-auto">
      <a
        [attr.href]="urlService.urls.contactWidgetCreate"
        class="a-plain btn btn--primary d-none d-sm-flex"
        data-testid="createButton"
        i18n
      >
        Create a widget
      </a>
      <a
        [attr.href]="urlService.urls.contactWidgetCreate"
        class="a-plain btn btn--primary btn--round d-sm-none"
      >
        <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
      </a>
    </div>
  </h1>


  <div class="card pp" *ngIf="shouldShowHint">
    <p>
      <span i18n>
        Create a contact widget in the form of a branded button and add it into your website.
      </span>
      <a [attr.href]="'contactWidget'|helpArticle" target="_blank" i18n>Get more information.</a>
    </p>

    <div
      class="btn btn--secondary"
      (click)="shouldShowHint = false"
      i18n
    >
      Got it!
    </div>
  </div>


  <fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>


  <ng-container *ngIf="contactWidgets.length > 0">
    <div
      *ngFor="let contactWidget of contactWidgets"
      class="contact-widgets__item"
      data-testid="contactWidget"
    >
      <div class="contact-widgets__item-header">

        <div class="d-flex align-items-center">
          <span class="text-h3">{{ contactWidget.text }}</span>
          <span
            *ngIf="getContactWidgetWarning(contactWidget)"
            class="ml-2"
            [matTooltip]="getContactWidgetWarning(contactWidget)"
            #tooltip="matTooltip"
          >
            <svg-icon class="icon text-error-400" [svgName]="'warning'" [svgStyle]="'badged_filled'"></svg-icon>
          </span>
        </div>

        <div class="flex-1"></div>

        <div
          class="btn btn--square"
          [dialog]="deleteConfirmation"
          dialogTitle="Delete widget"
          i18n-dialogTitle
          [dialogIcon]="{ name: 'trash' }"
          dialogConfirmText="Delete"
          i18n-dialogConfirmText
          dialogConfirmButtonClass="btn--error"
          [dialogConfirmedCallback]="delete.bind(this, contactWidget)"
          data-testid="deleteButton"
        >
          <svg-icon class="icon" [svgName]="'trash'"></svg-icon>
        </div>

        <a
          class="a-plain btn btn--square"
          [attr.href]="getUpdateUrl(contactWidget)"
          data-testid="editButton"
        >
          <svg-icon class="icon" [svgName]="'edit'"></svg-icon>
        </a>
      </div>

      <div class="contact-widgets__item-content">
        <div class="contact-widgets__item-preview">
          <div class="contact-widgets__linked-item-preview">
            <div class="contact-widgets__linked-item-preview-inner">
              <linked-item-preview [contactWidget]="contactWidget"></linked-item-preview>
            </div>
          </div>
          <div class="contact-widgets__button-preview">
            <button-preview
              class="flex-1"
              [contactWidget]="contactWidget"
              [buttonPreviewHideTitle]="true"
            ></button-preview>
          </div>
        </div>

        <input
          id="collapse_header_{{ contactWidget.id }}"
          type="checkbox"
          class="contact-widgets__code-toggle"
        />
        <label
          for="collapse_header_{{ contactWidget.id }}"
          class="contact-widgets__code-header"
        >
          <span class="text-muted" i18n>Use code</span>

          <svg-icon
            [svgName]="'chevron_down'"
            class="icon contact-widgets__code-chevron"
          ></svg-icon>
        </label>

        <div class="contact-widgets__code-content">
          <textarea
            class="textarea bg-none"
            rows="3"
            readonly
            [value]="contactWidget.snippet"
          ></textarea>

          <div class="d-flex align-items-center mb-4">
            <span class="mr-2 text-muted" i18n>Copy the code above into your website. Your widget will appear where you place it.</span>
            <div
              [modalTriggerFor]="tipsModal"
              [modalIcon]="{name: 'info_badged'}"
              modalTitle="Inserting HTML code snippet"
              i18n-modalTitle
              class="clickable"
            >
              <svg-icon class="icon icon--sm icon--inline" [svgName]="'info'" [svgSize]="14" [svgStyle]="'badged_filled'"></svg-icon>
            </div>

            <ng-template #tipsModal>
              <div class="pr-20">
                <h5 i18n>Someone else manages your site?</h5>

                <div class="d-flex">
                  <svg-icon class="mr-5" [svgPath]="'illustrations__84x84_snippet_mail'"></svg-icon>
                  <ol>
                    <li i18n>Send the code snippet to your webmaster and they'll know what to do.</li>
                    <li i18n>Sit back, relax and reel in customers.</li>
                  </ol>
                </div>

                <h5 i18n>You manage your site yourself?</h5>
                <div class="d-flex">
                  <svg-icon class="mr-5" [svgPath]="'illustrations__84x84_snippet'"></svg-icon>
                  <ol>
                    <li i18n>Copy the code of your widget.</li>
                    <li i18n>Paste it into the HTML body code of the page on your website where you want the widget to appear (or any other place in your website platform designed for inserting HTML JavaScript iFrame code).</li>
                    <li i18n>For more information please read <a [href]="helpArticle" target="_blank"> our Help Center article</a>.</li>
                  </ol>
                </div>
              </div>

              <div class="mb-6">
                <h4 class="text-muted mb-4" i18n>Your code</h4>

                <textarea
                  class="textarea bg-none"
                  rows="3"
                  readonly
                  [value]="contactWidget.snippet"
                ></textarea>
              </div>
              <div class="d-none d-md-flex justify-content-center mb-6">
                <img [src]="helpGif">
              </div>

              <div class="field field--warning">
                <field-icon [type]="'warning'"></field-icon>
                <span i18n>Website platforms can have different ways how to add HTML JavaScript code. Check wether your website supports inserting HTML JavaScript code.</span>
              </div>
              <div class="d-flex justify-content-end mt-6">
                <div class="btn btn--primary" [modalClose]>
                  <svg-icon class="btn__icon" [svgName]="'checkmark'"></svg-icon>
                  <span i18n>Ok, got it</span>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="contact-widgets__buttons">
            <a
              class="btn btn--secondary"
              [href]="getEmailToWebmasterLink(contactWidget)"
              target="_blank"
              (click)="track('contactWidget.sentSnippetEmail')"
            >
              <svg-icon class="btn__icon" [svgName]="'send'"></svg-icon>
              <div class="btn__label" i18n>Send to webmaster</div>
            </a>

            <div
              class="btn btn--secondary"
              [copyText]="contactWidget.snippet"
              (copyTextCopied)="track('contactWidget.copiedSnippet')"
            >
              <svg-icon class="btn__icon" [svgName]="'copy'"></svg-icon>
              <div class="btn__label" i18n>Copy to clipboard</div>
            </div>
          </div>
        </div>
      </div>


      <ng-template #deleteConfirmation>
        <p i18n>Are you sure you want to delete the widget <b> {{ contactWidget.text }} </b>?</p>
        <p i18n>This action cannot be undone.</p>
        <p i18n>Don't forget to remove the code from your website!</p>
      </ng-template>
    </div>
  </ng-container>
</div>
