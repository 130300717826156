import { Inject, Injectable, Type } from '@angular/core';
import { default as Wizard, Options as WizardOptions } from './Wizard';
import { Options as WizardStepOptions } from './WizardStep';
import templateModal from './wizard.modal.html?raw';
import Hint from 'utils/angularjs/hints/Hint.js';


const MODAL_ID = 'wizard';


@Injectable({
  providedIn: 'root',
})
export class WizardService {
  currentWizard: Wizard | null = null;


  constructor(
    @Inject('modalService') private modalService,
  ) {
    this._registerModal();
  }


  _registerModal() {
    this.modalService.register(
      MODAL_ID,
      '',
      '',
      templateModal,
      {
        rawTemplate: true,
      }
    );
  }


  create(
    id: string,
    component: Type<any>,
    stepDefinitions: Partial<WizardStepOptions>[],
    options: Partial<WizardOptions>,
  ) {
    return new Wizard(
      this,
      id,
      component,
      stepDefinitions,
      options,
    );
  }


  show(wizard: Wizard) {
    this.currentWizard = wizard;
    this.modalService.show(MODAL_ID, { wizard: wizard });
  }

  hide(wizard: Wizard) {
    if(this.currentWizard === wizard) {
      this.currentWizard = null;
    }
    this.modalService.hide(MODAL_ID);
  }

  finish(wizard: Wizard, hint: Hint) {
    this.hide(wizard);
    hint.setSeen();
  }
}
