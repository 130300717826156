import { equals as arrayEquals } from './array';


export function getQueryParameter(argName) {
  let name = argName.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  let results = regex.exec(window.location.href);

  if(!results) {
    return null;
  } else if(!results[2]) {
    return '';
  } else {
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}


export function getBooleanAttribute($scope, value) {
  return value == null ?
    false :
    value === '' ?
      true :
      !!$scope.$eval(value);
}

/**
 * constrain the value parameter to fall between min and max
 */
export function platform(min, value, max) {
  min = parseFloat(min);
  if(isNaN(min)) {
    min = -Infinity;
  }

  max = parseFloat(max);
  if(isNaN(max)) {
    max = Infinity;
  }

  return Math.min(max, Math.max(min, value));
}


/**
 * Bind all the methods of a class instance to the instance.
 * Based on https://github.com/sindresorhus/auto-bind
 * @param {Object} instance - Any class instance.
 */
export function bind(instance, { include, exclude } = {}) {
  const filter = key => {
    const match = pattern => typeof pattern === 'string' ? key === pattern : pattern.test(key);
    if(include) {
      return include.some(match);
    }
    if(exclude) {
      return !exclude.some(match);
    }
    return true;
  };

  for(const [object, key] of getAllProperties(instance.constructor.prototype)) {
    if(key === 'constructor' || !filter(key)) {
      continue;
    }
    const descriptor = Reflect.getOwnPropertyDescriptor(object, key);
    if(descriptor && typeof descriptor.value === 'function') {
      instance[key] = instance[key].bind(instance);
    }
  }

  return instance;
}


function getAllProperties(object) {
  const properties = new Set();
  do {
    for(const key of Reflect.ownKeys(object)) {
      properties.add([object, key]);
    }
  } while((object = Reflect.getPrototypeOf(object)) && object !== Object.prototype);

  return properties;
}


export function getPixelRatio(ctx) {
  let dpr = window.devicePixelRatio || 1;
  let bsr = (
    ctx.webkitBackingStorePixelRatio
    || ctx.mozBackingStorePixelRatio
    || ctx.msBackingStorePixelRatio
    || ctx.oBackingStorePixelRatio
    || ctx.backingStorePixelRatio
    || 1
  );

  return dpr / bsr;
}


export function isValidEmail(query) {
  return !!query.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
}


export function getTimestring(timestamp) {
  // This should be synced with extension util.js
  let date = new Date(timestamp);
  return (
    date.getFullYear() + '-'
    + ('0' + (date.getMonth() + 1)).slice(-2) + '-'
    + ('0' + date.getDate()).slice(-2) + ' '
    + ('0' + date.getHours()).slice(-2) + ':'
    + ('0' + date.getMinutes()).slice(-2) + ':'
    + ('0' + date.getSeconds()).slice(-2)
  );
}


export function getFixedAncestor(elem) {
  while(elem) {
    let isFixed = getComputedStyle(elem).position === 'fixed';
    if(isFixed) {
      return elem;
    }
    elem = elem.parentElement;
  }

  return document.body;
}


export function runAt(dateTime, callback) {
  let interval = $interval(() => {
    if(new Date() >= dateTime) {
      callback();
      $interval.cancel(interval);
    }
  }, 1000 * 60);
}


/** Implementation details:
 *   * Only the last result is cached.
 *   * Argument comparison is shallow.
 */
export function memoize(fn) {
  let cache = {
    args: null,
    result: null,
  };

  return function memoized(...args) {
    if(cache.args == null || !arrayEquals(args, cache.args)) {
      let result = fn(...args);
      cache.args = args;
      cache.result = result;
    }

    return cache.result;
  };
}

export function requestAnimationFrameWithInactiveSupport(callback) {
  let requestId;
  function onVisibilityChange() {
    cancelAnimationFrame(requestId);
    document.removeEventListener('visibilitychange', onVisibilityChange);
    setTimeout(callback);
  }

  if(document.hidden) {
    setTimeout(callback);

  } else {
    // If the tab becomes inactive before our rAF handler can execute, it will only be executed
    // when the tab becomes active again. To handle this situation, when a 'visibiliychange' event
    // occurs, we cancel the rAF request and switch to setTimeout.
    document.addEventListener('visibilitychange', onVisibilityChange);
    requestId = requestAnimationFrame(() => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      callback();
    });
  }
}
