<div class="notes-header">

  <div class="btn btn--sm btn--square" (click)="changeSize()">
    <svg-icon class="icon" [svgName]="isMaximized ? 'size_minimize' : 'size_maximize'"></svg-icon>
  </div>


  <div *ngIf="canUseSmartSummary" class="d-flex justify-content-between align-items-center">
    <div class="notes-header__smart-summary-usage">
      <div class="d-flex align-items-center gap-2">
        <div i18n>Free smart summary hours used</div>
        <div>&bull;</div>
        <div class="text-normal d-flex align-items-center">
          {{ smartSummarySecondsUsed | duration:false:2:'minutes':'hours' }}
          /
          {{ totalAllowedSmartSummarySeconds | duration:false:2:'minutes':'hours' }}
        </div>
      </div>
      <usage-bar
        [usage]="smartSummarySecondsUsed"
        [allowedUsage]="totalAllowedSmartSummarySeconds"
      ></usage-bar>
    </div>
  </div>
</div>
