import {
  Component,
  ElementRef,
  NgZone,
  ViewChild,
  Input,
  SimpleChanges,
  OnChanges,
  Inject,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { SmartSummaryService, Summary } from '../../../../smart-summary/smart-summary.service';
import {
  SmartSummaryTrackingService
} from '../../../../smart-summary/smart-summary-tracking.service';
import { MatTooltip } from '@angular/material/tooltip';
import { SmartSummarySyncService } from '../integration-sync/smart-summary-sync.service';
import { SYNC_SERVICE } from '../integration-sync/integration-sync.service';
import { ADMIN_PULSE } from 'integrations/adminpulse/adminpulse.service';
import { AddImageType } from '../../../../angularjs/components/whiteboard/whiteboard.service';
import { string, dateTime } from 'utils/util';


@UntilDestroy()
@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  providers: [{ provide: SYNC_SERVICE, useExisting: SmartSummarySyncService }],
})
export class SummaryComponent implements OnChanges {
  @ViewChild('summaryContainer') summaryContainer!: ElementRef;
  @ViewChild('tooltip') tooltip!: MatTooltip;
  @Input() isMaximized = false;

  private _isCollapsed = true;
  private _canCollapse = false;

  private _summary: Summary | null = null;

  constructor(
    private smartSummaryService: SmartSummaryService,
    private smartSummaryTrackingService: SmartSummaryTrackingService,
    private ngZone: NgZone,

    @Inject('whiteboardService') private whiteboardService,
    @Inject('meetingService') private meetingService,
  ) {
    this.smartSummaryService.summaryGenerated$
      .pipe(untilDestroyed(this))
      .subscribe((summary: Summary) => {
        this._summary = summary;

        // Wait for Angular to finish at least 1 render.
        // Summary needs to be filled in and a render needs to happen
        // to give a height to the summaryContainer
        this.ngZone.onStable
          .pipe(untilDestroyed(this), first())
          .subscribe(() => {
            this.checkForOverflow();
          });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isMaximized && this.summaryContainer) {
      this.handleMaximizedChange(changes.isMaximized.currentValue);
    }
  }

  public handleMaximizedChange(isMaximized: boolean): void {
    if(isMaximized) {
      // Never collapse summary on maximized
      this._canCollapse = false;
      this._isCollapsed = false;
      return;
    }

    this._canCollapse = false;
    this._isCollapsed = true;

    this.ngZone.onStable
      .pipe(untilDestroyed(this), first())
      .subscribe(() => {
        this.checkForOverflow();
      });
  }

  checkForOverflow(): void {
    if (this.isOverflown(this.summaryContainer.nativeElement)) {
      this._canCollapse = true;
      this._isCollapsed = true;
    }
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  public get canCollapse(): boolean {
    return this._canCollapse;
  }

  public get summary(): Summary | null {
    return this._summary;
  }

  public toggleCollapsed(): void {
    this._isCollapsed = !this._isCollapsed;
  }

  public isOverflown(element: HTMLElement): boolean {
    return element.offsetHeight < element.scrollHeight;
  }

  public copyToClipboard(): void {
    if (this._summary) {
      this.smartSummaryTrackingService.trackSummaryCopied();
      navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([this._summary.asHtml], { type: 'text/html' }),
          'text/plain': new Blob([this._summary.asPlainText], { type: 'text/plain' }),
        })
      ]).then(() => this.showAndHideTooltipWithDelay());
    }
  }

  private showAndHideTooltipWithDelay(): void {
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 3000);
  }

  public sharedViaEmail(): void {
    this.smartSummaryTrackingService.trackSummarySharedViaEmail();
  }

  public sharedViaIntegration(integrations: string[]): void {
    if (integrations.includes(ADMIN_PULSE)) {
      this.smartSummaryTrackingService.trackSummarySharedToAdminpulse();
    }
  }

  public canShareSummary(): boolean {
    return this.smartSummaryService.state.stage === 'summary-generated';
  }

  public exportToWhiteboard(): void {
    if(
      this.smartSummaryService.state.stage !== 'summary-generated'
      || this.summary == null
      || this.summary.asHtml === ''
    ) {
      return;
    }
    const file = this.buildWhiteboardFile();
    this.whiteboardService.addLocalFiles([file], AddImageType.NEW_WHITEBOARD);
    this.smartSummaryTrackingService.trackSummaryExportedToWhiteboard();
  }

  private buildWhiteboardFile(): File {
    const currentDate = new Date().toLocaleDateString(
      dateTime.getLanguageCode(),
      {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }
    );
    const whiteBoardTitle = string.slugify($localize `Summary: ${currentDate.replace(/\//g, '-')} - Meeting room ${this.meetingService.key}`);  // eslint-disable-line max-len
    const whiteboardContentTitle = $localize `Summary: Meeting room ${this.meetingService.key} on ${currentDate}`; // eslint-disable-line max-len
    const text = `<h3>${whiteboardContentTitle}</h3><br>${this.summary?.asHtml}`;
    return new File(
      [text],
      whiteBoardTitle,
      {
        type: 'text/html',
      }
    );
  }
}
