import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from 'utils/utils.module';
import { WaitingRoomCardComponent } from './waiting-room-card/waiting-room-card.component';
import {
  WaitingRoomNotificationService
} from './notifications/waiting-room-notification.service';
import {
  WaitingRoomNotificationWarningComponent
} from './notifications/waiting-room-notification-warning/waiting-room-notification-warning.component'; // eslint-disable-line max-len
import {
  WaitingRoomNotificationButtonComponent
} from './notifications/waiting-room-notification-button/waiting-room-notification-button.component';  // eslint-disable-line max-len
import {
  WaitingRoomService
} from './waiting-room.service';
import { MeetingRoomsMenuModule } from 'dashboard/home/meeting-rooms-menu';

/**
 * TODO: typing of WaitingRoomSessions is missing in these modules. To be added later.
*/

@NgModule({
  imports: [
    UtilsModule,
    CommonModule,
    MeetingRoomsMenuModule,
  ],
  providers: [
    WaitingRoomNotificationService,
    WaitingRoomService
  ],
  declarations: [
    WaitingRoomCardComponent,
    WaitingRoomNotificationWarningComponent,
    WaitingRoomNotificationButtonComponent,
  ],
  exports: [
    WaitingRoomCardComponent,
    WaitingRoomNotificationWarningComponent,
    WaitingRoomNotificationButtonComponent,
  ],
})
export class WaitingRoomModule {}
