import templateModalError from './error.modal.html?raw';
import { logger } from 'utils/util';


export const MODAL_ID = 'error';


LogConfig.$inject = [
  '$provide',
];


/**
 * Any uncaught exception in AngularJS expressions is delegated to this service.
 *
 * Whenever this occurs, a non-dismissable generic error modal is shown. When this modal is
 * visible, you can download the logs in 2 ways:
 * - Press keyboard shortcut "Alt + L".
 * - Tap/click the modal 5 times.
 */
export default function LogConfig($provide) {
  $provide.decorator('$exceptionHandler', ['$delegate', '$injector', ($delegate, $injector) => {
    function onError() {
      let modalService = $injector.get('modalService');

      modalService.register(
        MODAL_ID,
        gettext('Error'),
        'utils/icons/tl/24x24_warning_badged_filled.svg',
        templateModalError,
        { priority: 100, dismissable: false });

      $document.off('keydown', _onKeyDown);
      $document.on('keydown', _onKeyDown);

      $document.off('click', _onClick);
      $document.on('click', _onClick);

      logger.info('Caught an error; showing the error modal');
      logger.stop();
      modalService.show(MODAL_ID);
    }


    function _onKeyDown($event) {
      if($event.altKey && $event.keyCode === 'L'.charCodeAt(0)) {
        logger.download();
      }
    }


    let clickCounter = 0;
    let clickStarted = 0;

    function _onClick($event) {
      if($($event.target).closest('.modal-deprecated__header')) {
        let now = Date.now();
        if(now - clickStarted > 5000) {
          clickStarted = now;
          clickCounter = 0;
        }

        clickCounter++;
        if(clickCounter === 5) {
          logger.download();
        }
      }
    }

    /**
     * catch any rejected promises that are unhandled: don't show the modal in this case because
     * these are mostly third-party rejections
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
     *
     * This only works in Chrome
     */
    window.onunhandledrejection = function(event) {
      let error = event.reason;
      logger.warn(error);
    };

    /**
     * "main" function for handling AngularJS errors:  explicitly log the error to the storage log
     * (errors are logged to sentry and console by the normal AngularJS loop), then
     * show the error modal
     */
    return function(error, cause) {
      logger.storage.error(error);
      onError();
      $delegate(error, cause); // continue the normal angular loop
    };
  }]);
}
