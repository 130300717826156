import ViewportTileMixin from '../videoViewport/ViewportTileMixin';
import Tile from '../tiles/Tile';


export const DEFAULT_ASPECT_RATIO = 16 / 9;

const Baseclass = ViewportTileMixin(Tile);
export default class UserTile extends Baseclass {
  static get $inject() {
    return [
      'browserService',
      'pictureInPictureService',
      'recorderService',
    ].concat(Baseclass.$inject);
  }
  constructor(
    id,
    user,
    browserService,
    pictureInPictureService,
    recorderService,
    ...args
  ) {
    super(id, DEFAULT_ASPECT_RATIO, ...args);
    this.browserService = browserService;
    this.pictureInPictureService = pictureInPictureService;
    this.recorderService = recorderService;
    this._user = user;

    /**
     * isCollapsed stores whether an inactive tile shows a preview of the content. A collapsed tile
     * will only show the tile footer.
     *
     * @type {boolean}
     * @public
     */
    this.isCollapsed = false;

    /**
     * Tiles that are embedded in the floating tile shouldn't show control buttons.
     */
    this.isEmbeddedInFloatingTile = false;
  }

  get type() {
    return Tile.Type.USER;
  }
  get category() {
    return Tile.Category.PEOPLE;
  }

  get user() {
    return this._user;
  }

  get hasBorder() {
    return this.browserService.isDesktop;
  }

  get videoVisible() {
    return (
      this.videoActive
      && (
        this.recorderService.recording
        || !this.pictureInPictureActive
      )
    );
  }

  get isViewportMirrored() {
    return this.streams.video && this.streams.video.isLocal;
  }


  get pictureInPictureAllowed() {
    return (
      !this.user.isMe
      && this.videoActive
      && this.pictureInPictureService.isSupported
      && !this.pictureInPictureActive
    );
  }

  get pictureInPictureActive() {
    return this.streams.video && this.pictureInPictureService.isActiveStream(this.streams.video);
  }

  get exitPictureInPictureAllowed() {
    return this.pictureInPictureActive;
  }

  togglePictureInPicture() {
    this.pictureInPictureService.toggle(this);
  }
}
