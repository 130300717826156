/* eslint-disable max-len */
export const countries = {
  all: [
    { name: $localize `Afghanistan`, code: 'AF' },
    { name: $localize `Åland Islands`, code: 'AX' },
    { name: $localize `Albania`, code: 'AL' },
    { name: $localize `Algeria`, code: 'DZ' },
    { name: $localize `American Samoa`, code: 'AS' },
    { name: $localize `Andorra`, code: 'AD' },
    { name: $localize `Angola`, code: 'AO' },
    { name: $localize `Anguilla`, code: 'AI' },
    { name: $localize `Antarctica`, code: 'AQ' },
    { name: $localize `Antigua and Barbuda`, code: 'AG' },
    { name: $localize `Argentina`, code: 'AR' },
    { name: $localize `Armenia`, code: 'AM' },
    { name: $localize `Aruba`, code: 'AW' },
    { name: $localize `Australia`, code: 'AU' },
    { name: $localize `Austria`, code: 'AT' },
    { name: $localize `Azerbaijan`, code: 'AZ' },
    { name: $localize `Bahamas`, code: 'BS' },
    { name: $localize `Bahrain`, code: 'BH' },
    { name: $localize `Bangladesh`, code: 'BD' },
    { name: $localize `Barbados`, code: 'BB' },
    { name: $localize `Belarus`, code: 'BY' },
    { name: $localize `Belgium`, code: 'BE' },
    { name: $localize `Belize`, code: 'BZ' },
    { name: $localize `Benin`, code: 'BJ' },
    { name: $localize `Bermuda`, code: 'BM' },
    { name: $localize `Bhutan`, code: 'BT' },
    { name: $localize `Bolivia`, code: 'BO' },
    { name: $localize `Bosnia and Herzegovina`, code: 'BA' },
    { name: $localize `Botswana`, code: 'BW' },
    { name: $localize `Bouvet Island`, code: 'BV' },
    { name: $localize `Brazil`, code: 'BR' },
    { name: $localize `British Indian Ocean Territory`, code: 'IO' },
    { name: $localize `Brunei Darussalam`, code: 'BN' },
    { name: $localize `Bulgaria`, code: 'BG' },
    { name: $localize `Burkina Faso`, code: 'BF' },
    { name: $localize `Burundi`, code: 'BI' },
    { name: $localize `Cambodia`, code: 'KH' },
    { name: $localize `Cameroon`, code: 'CM' },
    { name: $localize `Canada`, code: 'CA' },
    { name: $localize `Cape Verde`, code: 'CV' },
    { name: $localize `Cayman Islands`, code: 'KY' },
    { name: $localize `Central African Republic`, code: 'CF' },
    { name: $localize `Chad`, code: 'TD' },
    { name: $localize `Chile`, code: 'CL' },
    { name: $localize `China`, code: 'CN' },
    { name: $localize `Christmas Island`, code: 'CX' },
    { name: $localize `Cocos (Keeling) Islands`, code: 'CC' },
    { name: $localize `Colombia`, code: 'CO' },
    { name: $localize `Comoros`, code: 'KM' },
    { name: $localize `Congo`, code: 'CG' },
    { name: $localize `Congo, The Democratic Republic of the`, code: 'CD' },
    { name: $localize `Cook Islands`, code: 'CK' },
    { name: $localize `Costa Rica`, code: 'CR' },
    { name: $localize `Cote D\'Ivoire`, code: 'CI' },
    { name: $localize `Croatia`, code: 'HR' },
    { name: $localize `Cuba`, code: 'CU' },
    { name: $localize `Cyprus`, code: 'CY' },
    { name: $localize `Czech Republic`, code: 'CZ' },
    { name: $localize `Denmark`, code: 'DK' },
    { name: $localize `Djibouti`, code: 'DJ' },
    { name: $localize `Dominica`, code: 'DM' },
    { name: $localize `Dominican Republic`, code: 'DO' },
    { name: $localize `Ecuador`, code: 'EC' },
    { name: $localize `Egypt`, code: 'EG' },
    { name: $localize `El Salvador`, code: 'SV' },
    { name: $localize `Equatorial Guinea`, code: 'GQ' },
    { name: $localize `Eritrea`, code: 'ER' },
    { name: $localize `Estonia`, code: 'EE' },
    { name: $localize `Ethiopia`, code: 'ET' },
    { name: $localize `Falkland Islands (Malvinas)`, code: 'FK' },
    { name: $localize `Faroe Islands`, code: 'FO' },
    { name: $localize `Fiji`, code: 'FJ' },
    { name: $localize `Finland`, code: 'FI' },
    { name: $localize `France`, code: 'FR' },
    { name: $localize `French Guiana`, code: 'GF' },
    { name: $localize `French Polynesia`, code: 'PF' },
    { name: $localize `French Southern Territories`, code: 'TF' },
    { name: $localize `Gabon`, code: 'GA' },
    { name: $localize `Gambia`, code: 'GM' },
    { name: $localize `Georgia`, code: 'GE' },
    { name: $localize `Germany`, code: 'DE' },
    { name: $localize `Ghana`, code: 'GH' },
    { name: $localize `Gibraltar`, code: 'GI' },
    { name: $localize `Greece`, code: 'GR' },
    { name: $localize `Greenland`, code: 'GL' },
    { name: $localize `Grenada`, code: 'GD' },
    { name: $localize `Guadeloupe`, code: 'GP' },
    { name: $localize `Guam`, code: 'GU' },
    { name: $localize `Guatemala`, code: 'GT' },
    { name: $localize `Guernsey`, code: 'GG' },
    { name: $localize `Guinea`, code: 'GN' },
    { name: $localize `Guinea-Bissau`, code: 'GW' },
    { name: $localize `Guyana`, code: 'GY' },
    { name: $localize `Haiti`, code: 'HT' },
    { name: $localize `Heard Island and Mcdonald Islands`, code: 'HM' },
    { name: $localize `Holy See (Vatican City State)`, code: 'VA' },
    { name: $localize `Honduras`, code: 'HN' },
    { name: $localize `Hong Kong`, code: 'HK' },
    { name: $localize `Hungary`, code: 'HU' },
    { name: $localize `Iceland`, code: 'IS' },
    { name: $localize `India`, code: 'IN' },
    { name: $localize `Indonesia`, code: 'ID' },
    { name: $localize `Iran, Islamic Republic Of`, code: 'IR' },
    { name: $localize `Iraq`, code: 'IQ' },
    { name: $localize `Ireland`, code: 'IE' },
    { name: $localize `Isle of Man`, code: 'IM' },
    { name: $localize `Israel`, code: 'IL' },
    { name: $localize `Italy`, code: 'IT' },
    { name: $localize `Jamaica`, code: 'JM' },
    { name: $localize `Japan`, code: 'JP' },
    { name: $localize `Jersey`, code: 'JE' },
    { name: $localize `Jordan`, code: 'JO' },
    { name: $localize `Kazakhstan`, code: 'KZ' },
    { name: $localize `Kenya`, code: 'KE' },
    { name: $localize `Kiribati`, code: 'KI' },
    { name: $localize `Korea, Democratic People\'S Republic of`, code: 'KP' },
    { name: $localize `Korea, Republic of`, code: 'KR' },
    { name: $localize `Kuwait`, code: 'KW' },
    { name: $localize `Kyrgyzstan`, code: 'KG' },
    { name: $localize `Lao People\'S Democratic Republic`, code: 'LA' },
    { name: $localize `Latvia`, code: 'LV' },
    { name: $localize `Lebanon`, code: 'LB' },
    { name: $localize `Lesotho`, code: 'LS' },
    { name: $localize `Liberia`, code: 'LR' },
    { name: $localize `Libyan Arab Jamahiriya`, code: 'LY' },
    { name: $localize `Liechtenstein`, code: 'LI' },
    { name: $localize `Lithuania`, code: 'LT' },
    { name: $localize `Luxembourg`, code: 'LU' },
    { name: $localize `Macao`, code: 'MO' },
    { name: $localize `Macedonia, The Former Yugoslav Republic of`, code: 'MK' },
    { name: $localize `Madagascar`, code: 'MG' },
    { name: $localize `Malawi`, code: 'MW' },
    { name: $localize `Malaysia`, code: 'MY' },
    { name: $localize `Maldives`, code: 'MV' },
    { name: $localize `Mali`, code: 'ML' },
    { name: $localize `Malta`, code: 'MT' },
    { name: $localize `Marshall Islands`, code: 'MH' },
    { name: $localize `Martinique`, code: 'MQ' },
    { name: $localize `Mauritania`, code: 'MR' },
    { name: $localize `Mauritius`, code: 'MU' },
    { name: $localize `Mayotte`, code: 'YT' },
    { name: $localize `Mexico`, code: 'MX' },
    { name: $localize `Micronesia, Federated States of`, code: 'FM' },
    { name: $localize `Moldova, Republic of`, code: 'MD' },
    { name: $localize `Monaco`, code: 'MC' },
    { name: $localize `Mongolia`, code: 'MN' },
    { name: $localize `Montserrat`, code: 'MS' },
    { name: $localize `Morocco`, code: 'MA' },
    { name: $localize `Mozambique`, code: 'MZ' },
    { name: $localize `Myanmar`, code: 'MM' },
    { name: $localize `Namibia`, code: 'NA' },
    { name: $localize `Nauru`, code: 'NR' },
    { name: $localize `Nepal`, code: 'NP' },
    { name: $localize `Netherlands`, code: 'NL' },
    { name: $localize `Netherlands Antilles`, code: 'AN' },
    { name: $localize `New Caledonia`, code: 'NC' },
    { name: $localize `New Zealand`, code: 'NZ' },
    { name: $localize `Nicaragua`, code: 'NI' },
    { name: $localize `Niger`, code: 'NE' },
    { name: $localize `Nigeria`, code: 'NG' },
    { name: $localize `Niue`, code: 'NU' },
    { name: $localize `Norfolk Island`, code: 'NF' },
    { name: $localize `Northern Mariana Islands`, code: 'MP' },
    { name: $localize `Norway`, code: 'NO' },
    { name: $localize `Oman`, code: 'OM' },
    { name: $localize `Pakistan`, code: 'PK' },
    { name: $localize `Palau`, code: 'PW' },
    { name: $localize `Palestinian Territory, Occupied`, code: 'PS' },
    { name: $localize `Panama`, code: 'PA' },
    { name: $localize `Papua New Guinea`, code: 'PG' },
    { name: $localize `Paraguay`, code: 'PY' },
    { name: $localize `Peru`, code: 'PE' },
    { name: $localize `Philippines`, code: 'PH' },
    { name: $localize `Pitcairn`, code: 'PN' },
    { name: $localize `Poland`, code: 'PL' },
    { name: $localize `Portugal`, code: 'PT' },
    { name: $localize `Puerto Rico`, code: 'PR' },
    { name: $localize `Qatar`, code: 'QA' },
    { name: $localize `Reunion`, code: 'RE' },
    { name: $localize `Romania`, code: 'RO' },
    { name: $localize `Russian Federation`, code: 'RU' },
    { name: $localize `RWANDA`, code: 'RW' },
    { name: $localize `Saint Helena`, code: 'SH' },
    { name: $localize `Saint Kitts and Nevis`, code: 'KN' },
    { name: $localize `Saint Lucia`, code: 'LC' },
    { name: $localize `Saint Pierre and Miquelon`, code: 'PM' },
    { name: $localize `Saint Vincent and the Grenadines`, code: 'VC' },
    { name: $localize `Samoa`, code: 'WS' },
    { name: $localize `San Marino`, code: 'SM' },
    { name: $localize `Sao Tome and Principe`, code: 'ST' },
    { name: $localize `Saudi Arabia`, code: 'SA' },
    { name: $localize `Senegal`, code: 'SN' },
    { name: $localize `Serbia and Montenegro`, code: 'CS' },
    { name: $localize `Seychelles`, code: 'SC' },
    { name: $localize `Sierra Leone`, code: 'SL' },
    { name: $localize `Singapore`, code: 'SG' },
    { name: $localize `Slovakia`, code: 'SK' },
    { name: $localize `Slovenia`, code: 'SI' },
    { name: $localize `Solomon Islands`, code: 'SB' },
    { name: $localize `Somalia`, code: 'SO' },
    { name: $localize `South Africa`, code: 'ZA' },
    { name: $localize `South Georgia and the South Sandwich Islands`, code: 'GS' },
    { name: $localize `Spain`, code: 'ES' },
    { name: $localize `Sri Lanka`, code: 'LK' },
    { name: $localize `Sudan`, code: 'SD' },
    { name: $localize `Suriname`, code: 'SR' },
    { name: $localize `Svalbard and Jan Mayen`, code: 'SJ' },
    { name: $localize `Swaziland`, code: 'SZ' },
    { name: $localize `Sweden`, code: 'SE' },
    { name: $localize `Switzerland`, code: 'CH' },
    { name: $localize `Syrian Arab Republic`, code: 'SY' },
    { name: $localize `Taiwan, Province of China`, code: 'TW' },
    { name: $localize `Tajikistan`, code: 'TJ' },
    { name: $localize `Tanzania, United Republic of`, code: 'TZ' },
    { name: $localize `Thailand`, code: 'TH' },
    { name: $localize `Timor-Leste`, code: 'TL' },
    { name: $localize `Togo`, code: 'TG' },
    { name: $localize `Tokelau`, code: 'TK' },
    { name: $localize `Tonga`, code: 'TO' },
    { name: $localize `Trinidad and Tobago`, code: 'TT' },
    { name: $localize `Tunisia`, code: 'TN' },
    { name: $localize `Turkey`, code: 'TR' },
    { name: $localize `Turkmenistan`, code: 'TM' },
    { name: $localize `Turks and Caicos Islands`, code: 'TC' },
    { name: $localize `Tuvalu`, code: 'TV' },
    { name: $localize `Uganda`, code: 'UG' },
    { name: $localize `Ukraine`, code: 'UA' },
    { name: $localize `United Arab Emirates`, code: 'AE' },
    { name: $localize `United Kingdom`, code: 'GB' },
    { name: $localize `United States`, code: 'US' },
    { name: $localize `United States Minor Outlying Islands`, code: 'UM' },
    { name: $localize `Uruguay`, code: 'UY' },
    { name: $localize `Uzbekistan`, code: 'UZ' },
    { name: $localize `Vanuatu`, code: 'VU' },
    { name: $localize `Venezuela`, code: 'VE' },
    { name: $localize `Viet Nam`, code: 'VN' },
    { name: $localize `Virgin Islands, British`, code: 'VG' },
    { name: $localize `Virgin Islands, U.S.`, code: 'VI' },
    { name: $localize `Wallis and Futuna`, code: 'WF' },
    { name: $localize `Western Sahara`, code: 'EH' },
    { name: $localize `Yemen`, code: 'YE' },
    { name: $localize `Zambia`, code: 'ZM' },
    { name: $localize `Zimbabwe`, code: 'ZW' }
  ],
  primary: [
    { name: $localize `Belgium`, code: 'BE' },
    { name: $localize `Netherlands`, code: 'NL' },
    { name: $localize `France`, code: 'FR' },
    { name: $localize `Germany`, code: 'DE' },
    { name: $localize `Spain`, code: 'ES' },
    { name: $localize `Italy`, code: 'IT' },
  ]
};

export const countryCodeToNameMap = countries.all.reduce((map, country) => {
  map[country.code] = country.name;
  return map;
}, {});
