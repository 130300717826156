export default function DefaultValueDirective() {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {
      function linkInput() {
        // TODO
      }


      function linkTextArea() {
        let $elemFormGroup = $elem.parents('.form-group');
        let $elemLabel = $elemFormGroup.find('label');

        let resetToDefaultStr = gettextCatalog.getString('Reset to default');

        let $elemBtn = angular.element(
          `
          <div class="d-none d-sm-flex ml-auto mb-1 btn" translate>
            ${resetToDefaultStr}
          </div>
          <div class="d-sm-none ml-auto mb-1 btn btn--square">
            <i class="icon icon--inline" svg="'utils/icons/tl/24x24_undo_outline.svg'"></i>
          </div>'
          `
        );
        $elemBtn.on('click', reset);
        $elemLabel.append($elemBtn);
      }


      function reset() {
        $elem.val($attr.defaultValue);
        $elem.change();
      }


      if($elem[0].tagName === 'TEXTAREA') {
        linkTextArea($scope, $elem);
      } else {
        linkInput($scope, $elem);
      }
    },
  };
}
