import { Pipe, PipeTransform } from '@angular/core';
import { dateTime } from 'utils/util';


@Pipe({
  name: 'readableTimestamp',
})
export class ReadableTimestampPipe implements PipeTransform {
  transform(input: Date): string {
    const locale = dateTime.getLanguageCode();
    const dateOptions: {[k: string]: any} = {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    const timeOptions: {[k: string]: any} = {
      hour: 'numeric',
      minute: 'numeric',
    };
    // eslint-disable-next-line max-len
    return `${input.toLocaleDateString(locale, dateOptions)} ${input.toLocaleTimeString(locale, timeOptions)}`;
  }
}
