import { Component, Input } from '@angular/core';

import { SmartSummaryService, State } from '../../../../smart-summary/smart-summary.service';
import { ActiveAudioStreamService } from '../../../stream/active-audio-stream.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  SmartSummaryTrackingService
} from '../../../../smart-summary/smart-summary-tracking.service';
import { ViewOrganizationService } from 'utils/view-organization.service';
import { RequestUserService } from 'utils/requestUser.service';
import { SiteService } from 'utils/site.service';
import { SubscriptionStatus } from 'subscription/models';


@UntilDestroy()
@Component({
  selector: 'smart-summary',
  templateUrl: './smart-summary.component.html',
  styleUrls: ['./smart-summary.component.scss'],
})
export class SmartSummaryComponent {
  @Input() isMaximized = false;

  public atLeastOneMicrophoneActive = false;

  constructor(
    private smartSummaryService: SmartSummaryService,
    private smartSummaryTrackingService: SmartSummaryTrackingService,
    private activeAudioStreamService: ActiveAudioStreamService,
    private viewOrganizationService: ViewOrganizationService,
    private requestUserService: RequestUserService,
    public siteService: SiteService
  ) {
    this.activeAudioStreamService.hasActiveStreams$
      .pipe(untilDestroyed(this))
      .subscribe((hasActiveStreams: boolean) => {
        this.atLeastOneMicrophoneActive = hasActiveStreams;
      });
  }


  public get state(): State {
    return this.smartSummaryService.state;
  }

  public startRecording(): void {
    this.smartSummaryService.startRecording();
    this.smartSummaryTrackingService.trackStartedTranscription();
  }

  public stopRecording(): void {
    this.smartSummaryService.stopRecording();
    this.smartSummaryTrackingService.trackStoppedTranscription();
  }

  public clear(): void {
    this.smartSummaryService.clear();
  }

  public requestMoreHoursClicked(): void {
    this.smartSummaryService.requestMoreHours();
  }

  public get isTrialAccount(): boolean {
    return (
      this.viewOrganizationService.organization.subscription.status === SubscriptionStatus.TRIAL
    );
  }

  public get isAdmin(): boolean {
    return (
      this.requestUserService.user.isAdmin
      && this.requestUserService.user.organizationId === this.viewOrganizationService.organization.id  // eslint-disable-line max-len
    );
  }
}
