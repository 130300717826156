import { Type } from '@angular/core';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import {
  QuestionTypeConfigComponent
} from '../question-type-config/question-type-config.component';
import { QuestionType } from 'contactForm/models/ContactFormQuestion';
import { MoneyConfigComponent, MultiOptionConfigComponent } from '../question-type-config';



export enum QuestionTypeCategory {
  TEXT = 'text',
  CHOICES = 'choices',
  CONTACT_COMPANY = 'contact_company',
  ATTACHMENTS = 'attachments',
  DATE_NUMBER = 'date_number',
  LEGAL_ACCOUNTING = 'legal_accounting',
}

export type QuestionTypeConfig = {
  label: string,
  labelExtra?: string,
  icon: SvgIcon,
  category: QuestionTypeCategory,
  extraInput?: Type<QuestionTypeConfigComponent>,
  extraInputParams?: Record<string, any>,
}

export const questionTypeConfigs: Record<QuestionType, QuestionTypeConfig> = {
  [QuestionType.SINGLE_LINE]: {
    label: $localize `Short text`,
    labelExtra: $localize `150 characters`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.TEXT,
  },
  [QuestionType.MULTI_LINE]: {
    label: $localize `Long text`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.TEXT,
  },

  [QuestionType.SINGLE_SELECT]: {
    label: $localize `Single selection`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.CHOICES,
    extraInput: MultiOptionConfigComponent,
    extraInputParams: { type: 'radio' },
  },
  [QuestionType.DROPDOWN]: {
    label: $localize `Dropdown selection`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.CHOICES,
    extraInput: MultiOptionConfigComponent,
    extraInputParams: { type: 'radio' },
  },
  [QuestionType.MULTI_SELECT]: {
    label: $localize `Multiple selection`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.CHOICES,
    extraInput: MultiOptionConfigComponent,
    extraInputParams: { type: 'checkbox' },
  },
  [QuestionType.LANGUAGE]: {
    label: $localize `Language`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.CHOICES,
  },
  [QuestionType.YES_NO]: {
    label: $localize `Yes/No`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.CHOICES,
  },
  [QuestionType.CHECKBOX]: {
    label: $localize `Checkbox`,
    icon: { name: 'checkmark', size: 14 },
    category: QuestionTypeCategory.CHOICES,
  },

  [QuestionType.EMAIL]: {
    label: $localize `Email address`,
    icon: { name: 'mail', size: 14 },
    category: QuestionTypeCategory.CONTACT_COMPANY,
  },
  [QuestionType.PHONE_NUMBER]: {
    label: $localize `Phone number`,
    icon: { name: 'phone', size: 14 },
    category: QuestionTypeCategory.CONTACT_COMPANY,
  },
  [QuestionType.URL]: {
    label: $localize `Website`,
    labelExtra: $localize `URL`,
    icon: { name: 'globe', size: 14 },
    category: QuestionTypeCategory.CONTACT_COMPANY,
  },
  [QuestionType.ADDRESS]: {
    label: $localize `Address`,
    icon: { name: 'marker', size: 14 },
    category: QuestionTypeCategory.CONTACT_COMPANY,
  },

  [QuestionType.ATTACHMENT]: {
    label: $localize `Upload files`,
    icon: { name: 'attach_file', size: 14 },
    category: QuestionTypeCategory.ATTACHMENTS,
  },

  [QuestionType.DATE]: {
    label: $localize `Date`,
    icon: { name: 'calendar', size: 14 },
    category: QuestionTypeCategory.DATE_NUMBER,
  },
  [QuestionType.NUMBER]: {
    label: $localize `Number`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.DATE_NUMBER,
  },
  [QuestionType.INTEGER]: {
    label: $localize `Integer`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.DATE_NUMBER,
  },
  [QuestionType.MONEY]: {
    label: $localize `Money`,
    icon: { name: 'euro', size: 14 },
    category: QuestionTypeCategory.DATE_NUMBER,
    extraInput: MoneyConfigComponent,
  },

  [QuestionType.VAT]: {
    label: $localize `VAT number`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.LEGAL_ACCOUNTING,
  },
  [QuestionType.KVK]: {
    label: $localize `KVK`,
    icon: { name: 'add', size: 14 },
    category: QuestionTypeCategory.LEGAL_ACCOUNTING,
  },
};
