<div class="card-grid__card">
  <h2 class="appointment-type__name mb-0">{{ name }}</h2>
  <div class="d-flex flex-row justify-content-end gap-2">
    <div
      *ngIf="appointmentIs(AppointmentLocation.ONLINE)"
      class="badge badge--primary"
      matTooltip="Online meeting"
      matTooltipPosition="above"
      i18n-matTooltip
    >
      <svg-icon class="icon icon--sm" [svgName]="'desktop'"></svg-icon>
    </div>
    <div
      *ngIf="appointmentIs(AppointmentLocation.PHONE_CALL)"
      class="badge badge--primary"
      matTooltip="Phone call"
      matTooltipPosition="above"
      i18n-matTooltip
    >
      <svg-icon class="icon icon--sm" [svgName]="'phone'" [svgSize]="14"></svg-icon>
    </div>
    <div
      *ngIf="appointmentIs(AppointmentLocation.OFFLINE)"
      class="badge badge--primary"
      matTooltip="In person meeting"
      matTooltipPosition="above"
      i18n-matTooltip
    >
      <svg-icon class="icon icon--sm" [svgName]="'building'" [svgSize]="14"></svg-icon>
    </div>
  </div>
  <div class="d-flex flex-column gap-1 appointment-type__description">
    <p [ngClass]="{'appointment-type__short-description': isOverflown()}"
      class="appointment-type__long-description text-neutral-500 mb-0" #descriptionElement>
      {{ description }}
    </p>
    <div class="d-flex flex-column align-items-end" *ngIf="isOverflown()">
      <button 
        class="btn btn--link py-2 px-4"
        [modalTriggerFor]="descriptionDialog"
        modalTitle="Meeting description"
        i18n
        i18n-modalTitle
      >
        Read more
      </button>
    </div>

    <ng-template #descriptionDialog>
      <p>{{ description }}</p>
    </ng-template>
  </div>
  <div class="d-flex flex-row gap-2">
    <div class="d-flex flex-row align-items-center gap-1">
      <svg-icon class="icon icon--sm" [svgName]="'clock'" [svgSize]="14"></svg-icon>
      {{ duration | duration:true }}
    </div>
    <div *ngIf="paymentAmount" class="d-flex flex-row align-items-center gap-1">
      <svg-icon class="icon icon--sm" [svgName]="'credit_card'" [svgSize]="14"></svg-icon>
      {{ paymentAmount | currency : paymentCurrency }}
    </div>
  </div>
  <button class="btn btn--primary btn--lg" i18n>Schedule meeting</button>
</div>
