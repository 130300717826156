import template from './accessLevel.html?raw';
import templateDropdown from './accessLevel.dropdown.html?raw';
export const DROPDOWN_ID_ACCESS_LEVEL = 'accessLevel';

class AccessLevelController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      'dropdownService',
    ];
  }

  constructor(
    $elem,
    $scope,
    dropdownService
  ) {
    this.$elem = $elem;
    this.$scope = $scope;
    this.dropdownService = dropdownService;

    dropdownService.register(
      DROPDOWN_ID_ACCESS_LEVEL,
      templateDropdown,
      { cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x' }
    );

    this.setAccessLevel = this.setAccessLevel.bind(this);
  }

  toggleAccessLevelDropdown($event) {
    let scope = {
      currentValue: this.currentValue,
      accessLevels: this.accessLevels,
      setAccessLevel: this.setAccessLevel,
    };

    this.dropdownService.toggle(DROPDOWN_ID_ACCESS_LEVEL, $event, scope);
  }

  setAccessLevel(accessLevel) {
    this.callback({ accessLevel: accessLevel });
  }
}

export default {
  controller: AccessLevelController,
  controllerAs: 'accessLevelCtrl',
  template,

  bindings: {
    currentValue: '<',
    accessLevels: '<',
    callback: '&'
  }
};
