import { bind, browser, errors, getQueryParameter, logger } from 'utils/util';
import { StreamKind, StreamState } from  'meeting/meeting-room/stream';


const MODE_HANDOVER_TIMEOUT = 5000;


export default class PeerConnectionService {
  static get $inject() {
    return [
      'meetingService',
      'userService',
      'privateMessageService',
      'streamService',
      'p2pHandleManager',
      'janusHandleManagerFactory',
    ];
  }

  constructor(
    meetingService,
    userService,
    privateMessageService,
    streamService,
    p2pHandleManager,
    janusHandleManagerFactory
  ) {
    bind(this);

    this.meetingService = meetingService;
    this.userService = userService;
    this.privateMessageService = privateMessageService;
    this.streamService = streamService;

    this.p2pHandleManager = p2pHandleManager;
    this.janusHandleManagerFactory = janusHandleManagerFactory;
    this.handleManager = null;

    this.connectedStreams = new Set();
    this.setHandleManagerPromise = $q.resolve();

    this._setupListeners();
  }


  _setupListeners() {
    if(!browser.supportsWebRTC()) {
      return;
    }

    this.userService.mySession.on('join knockJoin', this._onMySessionJoin);
    this.userService.mySession.on('exit knockExit', this._onMySessionExit);
    this.userService.mySession.on('wakeup knockWakeup', this._onMySessionWakeup);
  }


  init() {
    this.meetingService.settings.off('mediaserverUrl', this._onMediaserverUrl);
    this.meetingService.settings.on('mediaserverUrl', this._onMediaserverUrl);
    this._onMediaserverUrl();
  }

  destroy() {
    this.meetingService.settings.off('mediaserverUrl', this._onMediaserverUrl);
    this._setHandleManager(null);
  }


  _onMediaserverUrl() {
    // We allow overriding the mediaserver url only for QA purposes
    let mediaserverUrl = getQueryParameter('mediaserverUrl') != null ?
      getQueryParameter('mediaserverUrl') :
      this.meetingService.settings.mediaserverUrl;
    let handleManager = this._createHandleManager(mediaserverUrl);
    this._setHandleManager(handleManager);
  }


  _createHandleManager(mediaserverUrl) {
    if(mediaserverUrl) {
      return this.janusHandleManagerFactory.create(mediaserverUrl);
    } else {
      return this.p2pHandleManager;
    }
  }

  _setHandleManager(handleManager) {
    let prevHandleManager;

    // During a handover, we take the following steps. This process should keep both
    // communications interruption and cpu/bandwidth usage to a minimum.
    // - Start transmitting audio on the new handle manager
    // - Wait for a few seconds, until we can be reasonably sure the audio is transmitting
    // - Stop transmitting audio and video on the old handle manager
    // - Start transmitting video on the new handle manager
    this.setHandleManagerPromise = this.setHandleManagerPromise
      .then(() => {
        prevHandleManager = this.handleManager;
        if(
          prevHandleManager === handleManager
          || (
            prevHandleManager
            && handleManager
            && prevHandleManager.mediaserverUrl === handleManager.mediaserverUrl
          )
        ) {
          // There is nothing to do
          throw new errors.EscapePromiseError();
        }

        if(handleManager) {
          logger.info('Set mediaserver url:', handleManager.mediaserverUrl);
        } else {
          logger.info('Destroy handle manager');
        }
        this.handleManager = handleManager;


        let isHandover = (
          prevHandleManager
          && handleManager
          && this.connectedStreams.size > 0
        );

        if(prevHandleManager) {
          this.prepareDestroyHandleManager(prevHandleManager);
          this.connectedStreams.forEach(stream => stream.off('state', this._onStreamState));
          this.connectedStreams = new Set();
        }
        if(handleManager) {
          this.initHandleManager(handleManager);
          this.streamService.streams
            .filter(stream => stream.kind === StreamKind.AUDIO)
            .forEach(this._onStreamAdd);
        }

        if(isHandover) {
          return $timeout(angular.noop, MODE_HANDOVER_TIMEOUT);
        }
      })
      .then(() => {
        if(prevHandleManager) {
          this.destroyHandleManager(prevHandleManager);
        }
        if(handleManager) {
          this.streamService.streams
            .filter(stream => stream.kind === StreamKind.VIDEO)
            .forEach(this._onStreamAdd);
        }
      })
      .catch(error => {
        if(error.constructor === errors.EscapePromiseError) {
          return;
        }
        throw error;
      });
  }




  initHandleManager(handleManager) {
    handleManager.on('connected', this._onConnected);
    handleManager.on('track', this._onTrackAdd);
    handleManager.on('stats', this._onStats);
  }
  prepareDestroyHandleManager(handleManager) {
    handleManager.off('track', this._onTrackAdd);
    handleManager.off('connected', this._onConnected);
    handleManager.off('stats', this._onStats);
  }
  destroyHandleManager(handleManager) {
    handleManager.destroy();
  }



  /*******************
   * Event listeners *
   *******************/

  _onMySessionJoin() {
    this.streamService.on('add', this._onStreamAdd);
    this.streamService.on('remove', this._onStreamRemove);
    this.init();
  }

  _onMySessionExit() {
    this.streamService.off('add', this._onStreamAdd);
    this.streamService.off('remove', this._onStreamRemove);
    this.destroy();
  }


  _onMySessionWakeup() {
    if(this.handleManager) {
      this.handleManager.doIceRestart();
    }
  }


  _onStreamAdd(stream) {
    stream.off('state', this._onStreamState);
    stream.on('state', this._onStreamState);
    this._onStreamState(stream);
  }

  _onStreamRemove(stream) {
    stream.off('state', this._onStreamState);
    // When someone leaves, its streams are removed without their state being set to STOPPED, so
    // we need to call _onStreamState manually.
    this._onStreamState(stream, StreamState.STOPPED);
  }


  _onStreamState(stream, overrideStreamState = null) {
    if(!this.handleManager) {
      return;
    }

    let isConnected = this.connectedStreams.has(stream);
    let streamState = overrideStreamState == null ? stream.state : overrideStreamState;
    let shouldBeConnected = streamState !== StreamState.STOPPED;

    if(!isConnected && shouldBeConnected) {
      this.connectedStreams.add(stream);
      if(stream.isLocal) {
        this.handleManager.publish(stream);
      } else {
        stream.setConnected(false);
        this.handleManager.subscribe(stream);
      }

    } else if(isConnected && !shouldBeConnected) {
      this.connectedStreams.delete(stream);
      stream.isLocal ?
        this.handleManager.unpublish(stream) :
        this.handleManager.unsubscribe(stream);
    }
  }


  _onTrackAdd(stream, track) {
    stream.setTrack(track);
  }


  _onConnected(streams, isConnected) {
    streams.forEach(stream => stream.setConnected(isConnected));
  }


  _onStats(stream, track, stats) {
    if(stream.track === track) {
      let mode = this.handleManager ? this.handleManager.mode : '';
      stats = Object.assign({ peerConnectionMode: mode }, stats);
      stream.setStats(stats);
    }
  }
}
