/**
 * Service to gather audio recordings to turn into a transcript & summary later on.
 *
 * See the docs on the Web Audio API for an intro on how this stuff works. In summary, you have a
 * DAG with source nodes (audio sources such as mics), destination nodes (audio sinks such as
 * speakers) and modification nodes (like increasing the volume).
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
 */

import { Stream } from 'meeting/meeting-room/stream/Stream';
import { ActiveAudioStreamService } from 'meeting/meeting-room/stream/active-audio-stream.service';
import { assertOrLog, bind, EventEmitter } from 'utils/util';


export class SmartSummaryRecorder {

  public eventEmitter: EventEmitter;

  /** The audio graph */
  private audioContext: AudioContext;
  /** The audio sources per meeting room Stream ID */
  private sourceNodes: Map<string, MediaStreamAudioSourceNode>;
  /** The audio sink that all nodes connect to */
  private destinationNode: MediaStreamAudioDestinationNode;
  /** The object that collects all audio into a file */
  private mediaRecorder: MediaRecorder;
  /** The blobs that will contain all audio */
  private outputChunks: Blob[];

  constructor(
    private activeAudioStreamService: ActiveAudioStreamService,
  ) {
    bind(this);
    this.eventEmitter = EventEmitter.setup(this, ['error']);

    this.audioContext = new AudioContext();
    this.sourceNodes = new Map();
    this.destinationNode = new MediaStreamAudioDestinationNode(this.audioContext);
    this.mediaRecorder = new MediaRecorder(this.destinationNode.stream);
    this.outputChunks = [];

    this.mediaRecorder.ondataavailable = event => {
      this.outputChunks.push(event.data);
    };
    this.mediaRecorder.onerror = event => {
      this.eventEmitter.emit('error', event);
    };

    this.mediaRecorder.start();

    for (const stream of this.activeAudioStreamService.streams()) {
      this.addStream(stream);
    }
    this.activeAudioStreamService.eventEmitter.on('add', this.addStream);
    this.activeAudioStreamService.eventEmitter.on('remove', this.removeStream);
  }

  public stop(): Promise<Blob> {
    this.activeAudioStreamService.eventEmitter.off('add', this.addStream);
    this.activeAudioStreamService.eventEmitter.off('remove', this.removeStream);

    return new Promise((resolve, _reject) => {
      this.mediaRecorder.onstop = _event => {
        if (this.outputChunks.length === 0) {
          return;
        }
        const blob = new Blob(this.outputChunks, { type: this.mediaRecorder.mimeType });
        resolve(blob);
      };
      this.mediaRecorder.stop();
    });
  }

  private addStream(stream: Stream): void {
    assertOrLog(!this.sourceNodes.has(stream.id), 'Streams shouldn\'t get added twice');
    assertOrLog(stream.mediaStream != null, 'Active stream should have a `mediaStream`');
    if (stream.mediaStream == null) {
      return;
    }
    const sourceNode = this.audioContext.createMediaStreamSource(stream.mediaStream);
    sourceNode.connect(this.destinationNode);
    this.sourceNodes.set(stream.id, sourceNode);
  }

  private removeStream(stream: Stream): void {
    const sourceNode = this.sourceNodes.get(stream.id);
    assertOrLog(sourceNode != null, 'Streams shouldn\'t get removed twice');
    if (sourceNode == null) {
      return;
    }
    this.sourceNodes.delete(stream.id);
    sourceNode.disconnect();
  }
}
