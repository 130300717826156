import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { MeetingService } from 'meeting/meeting-room/meeting.service';
import { BASE_URL } from 'utils/base-url';
import { Summary, Transcript } from './smart-summary.service';


@Injectable()
export class SmartSummaryBackendService {
  constructor(
    private http: HttpClient,
    private meetingService: MeetingService,
  ) {}

  public async sendAudio(audio: Blob, sessionId: string): Promise<void> {
    const formData = new FormData();
    formData.append('audio', audio);
    formData.append('sessionId', sessionId);
    await lastValueFrom(
      this.http.post<null>(
        `${BASE_URL}/meetings/${this.meetingService.id}/audio`,
        formData,
      ),
    );
  }

  public async transcribeAudio(audio: Blob): Promise<Transcript> {
    const formData = new FormData();
    formData.append('audio', audio);
    return await lastValueFrom(
      this.http.post<Transcript>(
        `${BASE_URL}/transcribe`,
        formData,
      )
    );
  }

  public async summarizeTranscription(
    transcript: Transcript,
    prompt: string | null = null
  ): Promise<Summary> {
    return await lastValueFrom(
      this.http.post<Summary>(
        `${BASE_URL}/summarize`,
        {
          transcript: transcript,
          prompt: prompt,
        },
      ),
    );
  }
}
