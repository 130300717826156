<div
  *ngIf="!hideTitle"
  class="preview__title"
  i18n
>
  Preview
</div>
<div class="preview__content">
  <ng-content></ng-content>
</div>
