export default class MainChatNotifyService {
  static get $inject() {
    return [
      'notifyService',
      'chatBoxService',
    ];
  }

  constructor(
    notifyService,
    chatBoxService
  ) {
    this._bind();
    this.notifyService = notifyService;

    let chatBox = chatBoxService.get('main');
    chatBox.on('message', this._onMessage);
  }

  _bind() {
    this._onMessage = this._onMessage.bind(this);
  }


  _onMessage(message) {
    if(!message.sender.user.isMe) {
      this.notifyService.notify();
    }
  }
}
