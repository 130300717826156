import { Component, OnInit, Inject } from '@angular/core';
import { UrlService } from 'utils/url.service';
import { HubSpotConfig } from '../models/HubSpotConfig';
import { LocalStateService } from 'utils/state.service';
import { HubSpotService } from './hubspot.service';
import { ComboBoxCategory, ComboBoxInput } from 'utils/form-elements/dropdowns';
import { bind } from 'utils/util';


type HubSpotGroup = {
  label: string,
  properties: HubSpotProperty[]
}

type HubSpotProperty = {
  label: string,
  name: string
}


@Component({
  selector: 'hubspot-config',
  styleUrls: ['./hubspot-config.component.scss'],
  templateUrl: './hubspot-config.component.html',
})
export class HubSpotConfigComponent implements OnInit {
  public hubSpotConfig: HubSpotConfig | null;
  public hubSpotProperties: Record<string, HubSpotGroup> = {};
  public hubSpotOptions?: ComboBoxInput;
  public selectedOptions: Set<HubSpotProperty> = new Set<HubSpotProperty>();
  public currentOption: HubSpotProperty | null = null;

  constructor(
    public urlService: UrlService,
    public stateService: LocalStateService,
    public hubSpotService: HubSpotService,

    @Inject('notificationService') private notificationService,
    @Inject('apiService') private apiService,
  ) {
    bind(this);
  }
  ngOnInit() {
    this.stateService.setState(this.stateService.State.LOADING);

    this.fetchHubSpotConfig()
      .then(this.fetchHubSpotProperties)
      .then(() => this.stateService.setState(this.stateService.State.READY));
  }

  private fetchHubSpotConfig(): Promise<void> {
    if(!this.hubSpotService.isConnected) {
      return Promise.resolve();
    }
    return this.hubSpotService.getConfig().then(hubSpotConfig => {
      this.hubSpotConfig = hubSpotConfig;
    });
  }

  private fetchHubSpotProperties(): Promise<void> {
    if(!this.hubSpotService.isConnected) {
      return Promise.resolve();
    }

    return this.apiService.get('integrations/hubspot/properties')
      .then(({ data: hubSpotProperties }) => {
        this.hubSpotProperties = hubSpotProperties;
        const selectedKeys = this.hubSpotConfig.selectedProperties.map(prop => prop.name);
        this.initializeSelectedOptions(
          this.hubSpotProperties,
          selectedKeys
        );
        this.hubSpotOptions = this.transformPropertiesToComboBoxCategories(
          this.hubSpotProperties
        );
      });
  }

  /**
   * Javascript Sets store references, not values. Therefor, we cannot simply store the
   * hubSpotConfig.selectedProperties as these wouldn't match the items in the
   * this.hubSpotProperties record
   */
  initializeSelectedOptions(
    properties: Record<string, HubSpotGroup>,
    selectedKeys: string[]
  ) {
    Object.keys(properties).forEach(groupName => {
      properties[groupName].properties.forEach( prop => {
        if(selectedKeys.includes(prop.name) ) {
          this.selectedOptions.add(prop);
        }
      });
    });
  }

  transformPropertiesToComboBoxCategories(
    properties: Record<string, HubSpotGroup>
  ): ComboBoxInput {
    const categories: ComboBoxCategory[] = [];

    Object.keys(properties).forEach(groupName => {
      categories.push(
        {
          label: properties[groupName].label,
          options: properties[groupName].properties.map(prop => {
            return {
              value: prop,
              label: prop.label,
              disabled: this.selectedOptions.has(prop),
              disabledHelpText: $localize `Property is already selected`,
            };
          }),
        }
      );
    });

    return categories;
  }

  selectCurrentOption() {
    if(this.currentOption != null) {
      this.selectedOptions.add(this.currentOption);
      this.hubSpotOptions = this.transformPropertiesToComboBoxCategories(this.hubSpotProperties);
      this.currentOption = null;
    }
  }

  removeOption(option) {
    if(option) {
      this.selectedOptions.delete(option);
      this.hubSpotOptions = this.transformPropertiesToComboBoxCategories(this.hubSpotProperties);
    }
  }

  submit() {
    this.hubSpotConfig.selectedProperties = Array.from(this.selectedOptions);
    this.stateService.setState(this.stateService.State.SAVING);
    this.hubSpotConfig.update().then(() => {
      this.notificationService.success(
        $localize `Your selected properties have succesfully been updated.`,
      );
      this.stateService.setState(this.stateService.State.READY);
    });
  }

  async disconnect() {
    await this.hubSpotConfig?.disconnect();
    this.hubSpotConfig = null;
    this.notificationService.success(
      $localize `Successfully disconnected your HubSpot account.`,
    );
  }
}
