
<div
  class="btn"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="isOpen = !isOpen"
  [class.btn--translucent]="shouldBeTranslucent"
  [class.btn--round]="shouldCollapseButton"
  matTooltip="Private notes"
  i18n-matTooltip
  [matTooltipDisabled]="!shouldCollapseButton"
  data-testid="privateNotesButton"
>
  <svg-icon class="btn__icon" [svgName]="'visible_off'"></svg-icon>
  <div *ngIf="!shouldCollapseButton" class="btn__label" i18n>Private notes</div>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayInheritWidth
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="[connectedPosition]"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayMinWidth]="450"
>
  <div *ngIf="privateNotesService.doShow" class="private-notes">
    <svg-icon
      class="private-notes__background-icon use-icon-color"
      [svgName]="'visible_off'"
    ></svg-icon>

    <textarea
      class="textarea private-notes__textarea"
      placeholder="Anything you type here is only visible for you and other meeting hosts"
      i18n-placeholder
      [(ngModel)]="privateNotesService.content"
      maxlength="9500"
      data-testid="privateNotesContent"
    ></textarea>


    <div class="private-notes__footer">
      <div class="text-muted" i18n>
        Max. 9500 characters
      </div>
      <integration-sync></integration-sync>
    </div>
  </div>

  <div *ngIf="!privateNotesService.doShow" class="private-notes">
    <div class="private-notes__disabled">
      <p i18n>You are currently recording. Your private notes are hidden so that they are not recorded.</p>
      <div
        class="btn btn--secondary"
        (click)="privateNotesService.showDuringRecording()"
        i18n
      >
        Show my private notes anyway
      </div>
    </div>
  </div>
</ng-template>
