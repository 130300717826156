import { IntegrationConfig } from './IntegrationConfig';
import * as fields from 'utils/fields';

export class AdminPulseConfig extends IntegrationConfig {
  static get fields() {
    return Object.assign({}, IntegrationConfig.fields, {
      userEmail: new fields.StringField(),
      priceMapping: new fields.JSONField({
        default: () => {},
      }),
      defaultPricelistItemId: new fields.StringField(),
    });
  }

  static readPath(identifiers) {
    return `organizations/${identifiers.organizationId}/adminPulseConfig`;
  }

  get updatePath() {
    return `organizations/${this.organizationId}/adminPulseConfig`;
  }

  get disconnectPath() {
    return `${this.updatePath}/disconnect`;
  }
}
