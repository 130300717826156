import { format, logger } from 'utils/util';
import { browser } from 'utils/util';
import Recording from './Recording';

export default class BaseCloudRecording extends Recording {
  constructor(
    apiService,
    notificationService,
    ...args
  ) {
    super(...args);

    this.apiService = apiService;
    this.notificationService = notificationService;

    this._localDownloadLinkEnabled = null;
    this.isDownloading = false;
  }

  _bind() {
    super._bind();

    this._finishDownload = this._finishDownload.bind(this);
    this._cleanupDownload = this._cleanupDownload.bind(this);
  }

  get meetingOwner() {
    return this.properties.meetingOwner;
  }

  get apiPath() {
    return format(
      'recordings/%s/',
      this.id
    );
  }
  get privateDownloadLink() {
    return format(
      'recordings/%s/file/?include=meeting,createdBy',
      this.id
    );
  }
  get createdBy() {
    return this.properties.createdBy;
  }
  get previewLink() {
    return this.properties.previewLink;
  }
  get downloadLink() {
    return this.properties.downloadLink;
  }
  get downloadLinkEnabled() {
    return this._localDownloadLinkEnabled == null ?
      this.properties.downloadLinkEnabled :
      this._localDownloadLinkEnabled;
  }

  set downloadLinkEnabled(downloadLinkEnabled) {
    this._localDownloadLinkEnabled = downloadLinkEnabled;
    let data = {
      downloadLinkEnabled: this.downloadLinkEnabled,
    };

    this.apiService.patch(this.apiPath, data)
      .then(response => {
        this.update({
          downloadLinkEnabled: response.data.downloadLinkEnabled,
          downloadLink: response.data.downloadLink,
        });
      })
      .catch(error => {
        logger.warn(error);
        this.notificationService.warning(gettextCatalog.getString(`
          Something went wrong configuring the download link for your recording.
          Please restart your browser or try again later.
        `));
      })
      .finally(() => {
        this._localDownloadLinkEnabled = null;
      });
  }


  download() {
    if(this.isDownloading) {
      return;
    }

    this.isDownloading = true;
    this.apiService.get(this.privateDownloadLink, {
      message: this.downloadMessage,
      responseType: 'blob',
      eventHandlers: {
        abort: this._cleanupDownload,
      },
    })
      .then(this._finishDownload)
      .catch(error => {
        logger.warn(error);
        this.notificationService.warning(Recording.ERROR_MESSAGE_DOWNLOAD);
      })
      .finally(this._cleanupDownload);
  }

  _finishDownload(response) {
    let blob = response.data;
    let url = URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', this.name);
    if(!browser.supportsInlineDownload()) {
      a.setAttribute('target', '_blank');
    }

    document.body.appendChild(a);
    a.click();
    setTimeout(() => document.body.removeChild(a));
  }

  _cleanupDownload() {
    this.isDownloading = false;
  }


  get downloadMessage() {
    let translatedMessage = gettextCatalog.getString(
      'Downloading <b>{{ fileName }}</b>',
      { fileName: this.name }
    );
    return format('<p>%s</p>', translatedMessage);
  }


  open() {
    window.open(this.previewLink);
  }

  delete() {
    if(!this.canDelete) {
      return;
    }

    let url = format(
      'recordings/%s/',
      this.id
    );
    return this.apiService.delete(url);
  }
}
