/**
 * These need to be in a separate file, because dashboard/deprecated/dashboard.js needs to include
 * angular.js, but we don't want to include Angular as well.
 */

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { AngularWizardComponent } from '../wizard/angular-wizard.component';
import { FieldIconComponent } from '../ui-components/svg-icon/field-icon.component';
import { SvgIconComponent } from '../ui-components/svg-icon/svg-icon.component';
import { PoweredByComponent } from '../ui-components/logo/powered-by/powered-by.component';
import { RequestUserService } from 'utils/requestUser.service';
import { SiteService } from 'utils/site.service';

angular
  .module('vecteraUtil')

  .directive(
    'angularWizard',
    downgradeComponent({
      component: AngularWizardComponent,
      inputs: ['wizard'],
    })
  )

  .directive(
    'fieldIcon',
    downgradeComponent({
      component: FieldIconComponent,
      inputs: ['type', 'svgName']
    })
  )

  .directive(
    'svgIcon',
    downgradeComponent({
      component: SvgIconComponent,
      inputs: ['svgName']
    })
  )

  .directive(
    'poweredBy',
    downgradeComponent({
      component: PoweredByComponent,
      inputs: ['segmentSource', 'utmSource', 'utmCampaign', 'style', 'tint', 'showText']
    })
  )

  .service(
    'requestUserService',
    downgradeInjectable(RequestUserService)
  )

  .service(
    'siteService',
    downgradeInjectable(SiteService)
  )
;
