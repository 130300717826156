import { Component } from '@angular/core';
import { SmartSummaryService } from '../../../smart-summary/smart-summary.service';

@Component({
  selector: 'notes-header',
  templateUrl: './notes-header.component.html',
  styleUrls: ['./notes-header.component.scss']
})
export class NotesHeaderComponent {
  constructor(
    private smartSummaryService: SmartSummaryService
  ) {
  }

  public get canUseSmartSummary(): boolean {
    return this.smartSummaryService.canUseSmartSummary;
  }

  public get smartSummarySecondsUsed(): number {
    return this.smartSummaryService.usage.secondsUsed;
  }

  public get totalAllowedSmartSummarySeconds(): number {
    return this.smartSummaryService.usage.secondsAllowed;
  }
}
