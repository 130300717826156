import FileService from './file.service';
import FilePickerService from './filePicker.service';
import PasteService from './paste.service';

import DropFileComponent from './dropFile.component';

import UploadSpreadsheetController from './uploadSpreadsheet.controller';
import UploadContentModalController from './uploadContentModal.controller';
import UploadContentService from './uploadContent.service';

angular
  .module('meeting')
  .service('fileService', FileService)
  .service('filePickerService', FilePickerService)
  .service('pasteService', PasteService)

  .component('dropFile', DropFileComponent)
  .controller('UploadContentModalCtrl', UploadContentModalController)
  .service('uploadContentService', UploadContentService)

  .controller('UploadSpreadsheetCtrl', UploadSpreadsheetController);
