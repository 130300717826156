import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UtilsModule } from 'utils/utils.module';
import { ContactFormFormComponent } from './contact-form-form';
import { ContactFormPageComponent } from './contact-form-page.component';
import {
  AddressInputComponent,
  AttachmentInputComponent,
  CheckboxInputComponent,
  DateInputComponent,
  DropdownInputComponent,
  IntegerInputComponent,
  LanguageInputComponent,
  MoneyInputComponent,
  MultiLineInputComponent,
  MultiSelectInputComponent,
  NumberInputComponent,
  SingleLineInputComponent,
  SingleSelectInputComponent,
  YesNoInputComponent
} from './contact-form-question-input';
import {
  ContactFormQuestionComponent
} from './contact-form-question/contact-form-question.component';
import { ContactFormSuccessComponent } from './contact-form-success';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
  ],
  declarations: [
    ContactFormPageComponent,
    ContactFormFormComponent,
    ContactFormQuestionComponent,
    ContactFormSuccessComponent,

    AddressInputComponent,
    AttachmentInputComponent,
    CheckboxInputComponent,
    DateInputComponent,
    DropdownInputComponent,
    IntegerInputComponent,
    LanguageInputComponent,
    MoneyInputComponent,
    MultiLineInputComponent,
    MultiSelectInputComponent,
    NumberInputComponent,
    SingleLineInputComponent,
    SingleSelectInputComponent,
    YesNoInputComponent,
  ],
  exports: [
    ContactFormPageComponent,
    ContactFormQuestionComponent,
  ]
})
export class ContactFormPageModule {}
