import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { ErrorValue } from 'utils/settings/settings.component';
import { DateRange } from 'utils/util';

class OptionalDateRange {
  constructor(
    public from: Date | null = null,
    public to: Date | null = null,
  ) {}
}

/**
 * Render a host's availability as an interactive component.
 *
 * The component has two variants:
 * - A tabular one where each weekday is a row, and each availability range is a subrow.
 * - A calendar view where you can drag a selection around to toggle availability.
 *
 * Each interface has a few properties to maintain its state, prefixed with `tabular` or
 * `calendar`.
 */
@Component({
  selector: 'unavailability-picker[unavailabilities]',
  templateUrl: './unavailability-picker.component.html',
})
export class UnavailabilityPickerComponent implements OnInit, OnChanges {
  interfaceState: OptionalDateRange[] = [];
  isPartiallyFilled = false;

  @Input() unavailabilities!: DateRange[];
  @Output() unavailabilitiesChange = new EventEmitter<DateRange[]>();
  @Input() errors?: ErrorValue;
  @Input() readonly = false;

  get shouldShowAdder() {
    return this.interfaceState.length === this.unavailabilities.length;
  }

  ngOnInit() {
    this.updateInterfaceState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.unavailability) {
      this.updateInterfaceState();
    }
  }

  updateInterfaceState() {
    this.interfaceState = this.unavailabilities.slice();
  }

  updateModelState() {
    this.isPartiallyFilled = this.interfaceState
      .some(dateRange => {
        return (dateRange.from == null && dateRange.to != null)
        || (dateRange.from != null && dateRange.to == null);
      });

    const unavailabilities: DateRange[] = this.interfaceState
      .filter(dateRange => dateRange.from != null && dateRange.to != null)
      .map(dateRange => new DateRange(dateRange.from as Date, dateRange.to as Date));
    this.unavailabilities = unavailabilities;
    this.unavailabilitiesChange.emit(unavailabilities);
  }

  addEmptyUnavailability() {
    this.interfaceState.push(new OptionalDateRange(null, null));
  }

  removeUnavailability(index: number) {
    this.interfaceState.splice(index, 1);
    this.updateModelState();
  }
}
