import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Directive, ElementRef, OnDestroy, Optional } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { bind } from 'utils/util';


/**
 * Can be used on elements with cdkMenuTriggerFor so that the width of the menu is kept in sync
 * with the width of the trigger element.
 *
 * Example usage:
 *
 * ```
 * <div class="btn" [cdkMenuTriggerFor]="menu" cdkMenuInheritWidth></div>
 * ```
 */
@Directive({
  selector: '[cdkMenuInheritWidth]',
})
export class CdkMenuInheritWidthDirective implements OnDestroy {
  private resizeObserver?: ResizeObserver;
  private readonly destroyed = new Subject<void>();


  constructor(
    private elementRef: ElementRef,
    // We don't want Angular to throw a cryptic error message, we throw our own just a few lines
    // down
    @Optional() private cdkMenuTrigger: CdkMenuTrigger,
  ) {
    bind(this);
    if(!this.cdkMenuTrigger) {
      throw new Error(
        'cdkMenuInheritWidth can only be used on elements that have a cdkMenuTriggerFor'
      );
    }

    this.cdkMenuTrigger.opened
      .pipe(takeUntil(this.destroyed))
      .subscribe(this.onMenuOpened);
    this.cdkMenuTrigger.closed
      .pipe(takeUntil(this.destroyed))
      .subscribe(this.onMenuClosed);
  }


  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.removeResizeListener();
  }


  onMenuOpened() {
    this.addResizeListener();
    this.onResize();
  }

  onMenuClosed() {
    this.removeResizeListener();
  }


  addResizeListener() {
    if(window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.elementRef.nativeElement);
    } else {
      // This is not a failsafe way to detect size changes, but it's good enough for the few
      // percent of browsers that don't support ResizeObserver.
      window.addEventListener('resize', this.onResize);
    }
  }

  removeResizeListener() {
    if(this.resizeObserver) {
      this.resizeObserver.unobserve(this.elementRef.nativeElement);
    } else {
      window.removeEventListener('resize', this.onResize);
    }
  }


  private onResize() {
    const menu = this.cdkMenuTrigger.getMenu();
    if(!menu) {
      return;
    }

    const width = this.elementRef.nativeElement.offsetWidth;
    menu.nativeElement.style.width = width + 'px';
  }
}
