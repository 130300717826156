import { Component, Input } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { ContactForm } from 'contactForm/models/ContactForm';
import { AppointmentType } from 'scheduling/models/AppointmentType';

@Component({
  template: '',
})
export abstract class ContactFormSettingsComponent
  extends SettingsComponent<ContactForm> {

  @Input() appointmentTypeInstance?: AppointmentType;

  override get hasPermissionToEdit() {
    return this.isAdmin
      || !this.instance?.id
      || this.instance?.createdById === this.requestUserService.user.id
      // when CF is part of an AppointmentType, it instance.createdBy will not be filled in
      || this.appointmentTypeInstance && !this.isTeamAppointmentType;
  }

  get isTeamAppointmentType() {
    return this.appointmentTypeInstance?.appointmentTypeConfigs.filter(
      atc => atc.active
    ).length > 1;
  }
}
