import MQ from '../paper/MQ';


const SYMBOL_MAP = Object.freeze({
  '\\int': '&int;',
  '\\sum': '&sum;',
  '\\sqrt': '&radic;',
  '\\prod': '&prod;',
});


export default function ToolsSymbolItemDirective() {
  return {
    restrict: 'C',
    link: function($scope, $elem) {
      $timeout(() => {
        let text = $elem.text().trim();
        if(text in SYMBOL_MAP) {
          $elem.addClass('mq-math-mode');
          $elem.html(SYMBOL_MAP[text]);

        } else {
          MQ.StaticMath($elem[0], { supSubsRequireOperand: false });
        }

        $elem.removeClass('hidden');
      });
    }
  };
}
