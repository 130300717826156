import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FilePickerComponent } from './file-picker.component';
import { DirectivesModule } from 'utils/directives';
import { SvgIconModule } from 'utils/ui-components/svg-icon';
import { SettingsModule } from 'utils/settings';
import { ImagePickerComponent } from './image-picker/image-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    SvgIconModule,
    SettingsModule
  ],
  declarations: [
    FilePickerComponent,
    ImagePickerComponent,
  ],
  exports: [
    FilePickerComponent,
    ImagePickerComponent,
  ],
})
export class FilePickerModule {}
