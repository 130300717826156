import { User } from 'core/models/User';
import * as fields from 'utils/fields';


export class AppointmentUser extends User {
  static get fields() {
    return Object.assign({}, User.fields, {
      isPrimaryGuest: new fields.BooleanField(),
    });
  }

  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers) {
    throw Error('AppointmentUsers are only able to be fetched through the appointment endpoint');
  }
}
