<div
  *ngIf="scopes.length > 1"
  class="input-group"
>
  <div
    *ngFor="let s of scopes"
    class="btn flex-1"
    [class.btn--teal]="scope === s"
    [class.btn--secondary]="scope !== s"
    (click)="setScope(s)"
    [attr.data-testid]="'scopeSwitcher_' + s.id"
  >
    {{ s.name }}
    <div
      class="badge ml-2"
      ng-class="{ 'badge--teal': scope === scope }"
      *ngIf="s.numResults > 0"
    >
      {{ s.numResults }}
    </div>
  </div>
</div>
