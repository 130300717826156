import { object, EventEmitter } from 'utils/util';
import * as fields from 'utils/fields';
import { errors } from 'utils/util';

/**
 * A Data Access Object-like implementation for creating classes based on Django models.
 *
 * You can create a child class that maps to a specific Django model, and create instances of that
 * class by having modelFactory create an empty instance or read one (or more) from the API.
 * All fields in the API object will be available as properties on the class
 * instance.
 * By overriding Cls.fields, you can parse fields that are not strings or numbers. For example:
 *
 * class Meeting extends Model {
 *   static get fields() {
 *     return {
 *       openedUntil: DateTimeField(),
 *       owner: RelatedField({ Model: User }),
 *       activeUsers: RelatedField({ Model: User, many: true }),
 *     };
 *   }
 * }
 *
 * Models.js is responsible for manipulating model instances. Together with
 * ModelFactory it implements all CRUDL API operations:
 *  - Any operation that can be applied on an existing model instance (Create, Update, Delete)
 *    is located in Models.js
 *  - Any operation that results in a new model instance (createInstance, Read & List),
 *    is located in the modelFactory
 */

export default class Model {
  static get fields() {
    return {};
  }

  static get $inject() {
    return [
      'modelFactory',
      'apiService',
    ];
  }

  /**
   * reflects whether the model serializer uses the api.serializers.DuplicateFromModelMixin Mixin,
   * which enables the usage of the duplicateFromId helper function.
   */
  static get canDuplicateFromId() {
    return false;
  }

  /**
  * PATHS: For every operation, a specific path is needed. In general, the static paths are used
  * in modelFactory.js, and dynamic paths are used in Model.js.
  *
  * For most models, only redefining the basepath is enough
  */

  // JDC 01-2023: a path that contains an identifier opens up a whole can of worms with
  // passing identifiers to static methods. In an ideal world, it would be possible to have a
  // "true" API->Model mapping by having such "child models" be fetchable from the parent model.
  //
  // For example: instead of the OrganizationAppointments Model having a listPath with an
  // identifiers input param, if it were to be possible to define OrganizationAppointments Model
  // as a child of the Organizations Model, it would be able to retrieve the organizationId from
  // its parent instead. Getting rid of the "identifiers" parameter in the static paths would
  // get rid of having to pass modelconfig.identifiers to modelFactory, and therefor maybe allow
  // us to skip the "modelConfig" object altogether.

  // eslint-disable-next-line no-unused-vars
  static get basePath() {
    throw errors.NotImplementedError('Not implemented');
  }

  /**
   * the statically defined location of the List action of a model.
   */
  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers = {}) {
    return this.basePath;
  }

  /**
   * The location of the Create action on a model Instance.
   */
  get createPath() {
    return this.constructor.basePath;
  }

  /**
   * the statically defined location of the Read action of a model.
   */
  static readPath(identifiers) {
    return `${this.basePath}/${identifiers.id}`;
  }


  /**
   * The location of the Update  action on a model Instance.
   */
  get updatePath() {
    return `${this.constructor.basePath}/${this.id}`;
  }

  /**
   * The location of the Delete action on a model Instance.
   */
  get deletePath() {
    return this.updatePath;
  }


  static get defaultInclude() {
    return [];
  }


  /**
   * A Model is the Javascript equivalent of a Django django.db.models.Model in combination with a
   * rest_framework.serializers.Serializer. It can be initialized either with data received from
   * the API, or with locally generated data.
   *
   * Note that this constructor should never be called directly, as this is the responsibility of
   * ModelFactory.
   *
   * @param {Object} config All config keys are optional:
   *  - {Object} data Data received from the API. Each value will be passed to the field's
   *    `setData()`.
   *  - {Object} values A mapping of field names to values. Values will be set directly on the
   *    field, so should already have the correct datatype.
   *  - {string[]} include A list of fields that will be requested from the API in each request,
   *    using the `?include=xxx` query parameter. Defaults to the static `defaultInclude` property
   *    of the class.
   * @param  {...any} dependencies
   */
  constructor(config = {}, ...dependencies) {
    this.constructor.$inject.forEach((dependencyName, i) => {
      this[dependencyName] = dependencies[i];
    });

    /**
     * Whenever a field value is changed, an event with the field name is emitted.
     */
    EventEmitter.setup(this, [], true);

    /**
     * {Array} Will be passed as a `?include=xxx` query parameter in every future API request.
     */
    this.include = config.include || this.constructor.defaultInclude;

    /**
     * {Object} Mapping of field names to bound fields.
     */
    this.fields = {};

    object.forEach(this.constructor.fields, (fieldName, field) => {
      this._bindField(fieldName, field);
    });
    if(config.data) {
      this.setData(config.data);
    } else if(config.values) {
      object.forEach(config.values, (fieldName, value) => {
        this._bindField(fieldName);
        this[fieldName] = value;
      });
    }

    // If this model is nested inside another model, it will get bound to that "parent" model.
    this.fieldName = null;
    this.parent = null;
  }


  get existsInBackend() {
    return !!this.id;
  }


  bind(fieldName, parent) {
    this.fieldName = fieldName;
    this.parent = parent;
  }

  get isBound() {
    return this.fieldName != null;
  }


  /**
   * Create a bound field on this specific instance.
   * @param {string} fieldName The name of the field
   * @param {Field} [field] If left blank, a simple Field is created, i.e. a field with no
   *  (de)serialization, which is good enough for strings, numbers and booleans.
   */
  _bindField(fieldName, field) {
    if(this.fields[fieldName]) {
      return;
    }

    if(field == null) {
      field = new fields.Field();
    }
    let boundField = new field.constructor(field.config);
    boundField.bind(fieldName, this);
    this.fields[fieldName] = boundField;

    let propertyConfig = {
      get: this._getProperty.bind(this, fieldName),
    };
    if(!field.readOnly) {
      propertyConfig.set = this._setProperty.bind(this, fieldName);
    }
    Object.defineProperty(this, fieldName, propertyConfig);

    // RelatedField: create the corresponding ID field
    if(field instanceof fields.RelatedField && field.withIdField) {
      const idFieldName = field.many ? fieldName + 'Ids' : fieldName + 'Id';
      this._bindField(idFieldName, new fields.UUIDRelatedField());
    }
  }


  _getProperty(key) {
    return this.fields[key].value;
  }

  _setProperty(key, value) {
    const field = this.fields[key];
    // RelatedField: set the value of the corresponding ID field
    if(field instanceof fields.RelatedField && field.withIdField) {
      // For RelatedFields we don't want to send the new value to the API, we send the
      // corresponding ID field instead.
      let idFieldName, idFieldValue;
      if(field.many) {
        idFieldName = key + 'Ids';
        idFieldValue = value.length > 0 ? value.map(valueItem => valueItem.id) : [];
      } else {
        idFieldName = key + 'Id';
        idFieldValue = value ? value.id : null;
      }
      this._setProperty(idFieldName, idFieldValue);
    }

    if(value === field.value) {
      return;
    }

    field.setValue(value);
    this.markDirty(key);
  }


  isDirty(key) {
    const field = this.fields[key];
    return field && field.isDirty;
  }
  markDirty(key) {
    const field = this.fields[key];
    if(!field.readOnly) {
      field.markDirty();
      this.emit(key, this[key]);
    }
  }


  /**
   * Update the local field values based on info we got from the server. Passed field
   * will become clean.
   * @param {Object} data
   */
  setData(data) {
    object.forEach(data, (key, dataItem) => {
      this._bindField(key);
      this.fields[key].setData(dataItem);
      this.fields[key].isDirty = false;
    });
  }


  /**
   * Serialize the local field values into a representation that we can send to the API.
   * @returns {Object} data
   */
  toRepresentation(onlyDirty = false) {
    let data = {};
    Object.values(this.fields)
      // RelatedFields that have corresponding IdFields cannot be updated through this API call,
      // so there is no need to add the model representation.
      .filter(field => !(field instanceof fields.RelatedField && field.withIdField))
      .filter(field => onlyDirty ? field.isDirty : true )
      .forEach(field => data[field.fieldName] = field.toRepresentation(onlyDirty));
    return data;
  }

  /**
   * Serialize the dirty field values into a representation that we can send to the API.
   * @returns {Object} data
   */
  getDirtyFields() {
    return this.toRepresentation(true);
  }

  _buildFullApiOptions(apiConfig = {}) {
    let defaultApiOptions = {
      params: {
        include: this.include.join(','),
      },
    };
    return object.assignDeep({}, defaultApiOptions, apiConfig);
  }

  /** BASE OPERATIONS */

  /**
   * Create this instance in the backend by POSTing to the API.
   * @param {objects} apiConfig? Will be merged with the default config
   * @returns {Promise<Response>}
   */
  create(apiConfig = {}) {
    let data = this.getDirtyFields();
    let fullApiOptions = this._buildFullApiOptions(apiConfig);
    return this.apiService.post(this.createPath, data, fullApiOptions)
      .then(response => {
        this.setData(response.data);
        return response;
      });
  }

  /**
   * Update the instance by PATCHing the dirty fields to the API
   * @param {objects} apiConfig? Will be merged with the default config a
   * @returns {Promise<Response>}
   */
  update(apiConfig = {}) {
    let data = this.getDirtyFields();
    let fullApiOptions = this._buildFullApiOptions(apiConfig);
    return this.apiService.patch(this.updatePath, data, fullApiOptions)
      .then(response => {
        this.setData(response.data);
        return response;
      });
  }


  save(apiConfig = {}) {
    return this.existsInBackend ?
      this.update(apiConfig) :
      this.create(apiConfig);
  }


  delete(apiConfig = {}) {
    let fullApiOptions = this._buildFullApiOptions(apiConfig);
    return this.apiService.delete(this.deletePath, fullApiOptions);
  }

  /** HELPER OPERATIONS */

  /**
   * Create a duplicate instance by POSTing a request containing the given id, which returns
   * the newly created instance containing the same field-value pairs as the original.
   *
   * @param {string} id the id of a model to be duplicated
   * @returns {Promise<Response>}
   */
  duplicateFromId(id) {
    const modelConfig = {};
    const apiConfig =  { params: { duplicateFromId: id } };
    if(this.constructor.canDuplicateFromId) {
      return this.apiService.post(this.createPath, modelConfig, apiConfig)
        .then(response => {
          this.setData(response.data);
          return response;
        });
    } else {
      throw Error(`model ${this.constructor.name} does not allow duplicating from id`);
    }
  }


  /**
   * Create a local child instance for field `fieldName` and set it as the field's current value.
   * @param {string} fieldName - The name of a RelatedField field
   * @param {object} modelConfig - Passed to modelFactory.createInstance()
   * @param {object} apiConfig - Passed to modelFactory.createInstance()
   */
  createChildInstance(fieldName, modelConfig, apiConfig) {
    const field = this.fields[fieldName];
    const cls = field.Model;
    const instance = this.modelFactory.createInstance(cls, modelConfig, apiConfig);
    if(field.many) {
      field.value.push(instance);
    } else {
      field.setValue(instance);
    }
    field.bindChildren();
    this.markDirty(fieldName);
    return instance;
  }
}
