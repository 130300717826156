import { Component, Input } from '@angular/core';


export type SettingsIconType = 'pristine' | 'selected' | 'valid' | 'invalid';


@Component({
  selector: 'settings-icon[settingsIconType]',
  templateUrl: './settings-icon.component.html',
  styleUrls: ['./settings-icon.component.scss'],
})
export class SettingsIconComponent {
  @Input() settingsIconType!: SettingsIconType;
  @Input() settingsIconIndex?: number;
}
