<form [formGroup]="formGroup">
  <div class="mb-4">
    <div class="checkbox__wrapper" *ngIf="!requestUserService.isFree">
      <input
        type="checkbox"
        class="checkbox"
        id="notifyUser_checkbox"
        [(ngModel)]="notifyUser"
        (ngModelChange)="onNotifyUserCheckboxChange()"
        [ngModelOptions]="{standalone: true}"
        data-testid="notifyUserCheckbox"
      />
      <label
        class="checkbox__label"
        for="notifyUser_checkbox"
        i18n
      >
        Notify a team member when a form is completed
      </label>
    </div>

    <div *ngIf="requestUserService.isFree">
      <pro-feature-badge
        featureText="Notify a team member when a form is completed"
        i18n-featureText
      >
      </pro-feature-badge>
    </div>

    <div class="mt-2 ml-6 d-flex align-items-center" *ngIf="notifyUser && !requestUserService.isFree">
      <div i18n class="mr-2">Team member to notify</div>
      <combo-box
        [(ngModel)]="userToNotify"
        (ngModelChange)="onChangeUserToNotify()"
        [options]="notifyOptions"
        [ngModelOptions]="{standalone: true}"
        [clearable]="true"
        data-testid="teamMemberToNotifyComboBox"
      ></combo-box>
    </div>
  </div>

  <div class="checkbox__wrapper">
    <input
      type="checkbox"
      class="checkbox"
      id="allowUserNotification_checkbox"
      formControlName="allowUserNotification"
    />
    <label
      class="checkbox__label"
      for="allowUserNotification_checkbox"
      i18n
    >
      Allow respondents to get a copy of the completed form
    </label>
  </div>
</form>
