import { Component, Input } from '@angular/core';
import { SiteService } from 'utils/site.service';
import { ViewOrganizationService } from 'utils/view-organization.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';


/**
 * Display the logo of the view organization. If the organization has a custom logo, display that.
 * Otherwise, display the default logo of the site.
 *
 * @param logoScale - The scale of the logo. If not provided, the logo will inherit the height of
 *   the parent element.
 * @param logoTarget - The target of the logo link.
 * @param utmSource - The utm_source to append to the homepage URL. Only used if the homepage is
 *   not customized by the organization.
 * @param segmentSource - The source string to add to the segment event that is generated on logo
 *  click. Segment event is only fired if the logo is not customized by the organization.
 */
@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent {
  @Input('logoScale') scale?: number;
  @Input('logoTarget') target: '_blank' | '_self' = '_blank';
  @Input() utmSource: string | null = null;
  @Input() segmentSource: string | null = null;

  constructor(
    private usageTrackingService: UsageTrackingService,
    public viewOrganizationService: ViewOrganizationService,
    private siteService: SiteService,
  ) { }


  get whitelabelSettings() {
    return this.viewOrganizationService.organization.whitelabelSettings;
  }

  get isCustom() {
    return this.whitelabelSettings.customLogo;
  }

  get path() {
    return this.whitelabelSettings.logo.url;
  }

  get href() {
    let urlString = this.whitelabelSettings.homepage;

    if (this.utmSource && !this.whitelabelSettings.customHomepage) {
      const url = new URL(urlString);
      url.searchParams.append('utm_medium', 'referral');
      url.searchParams.append('utm_source', this.utmSource);
      url.searchParams.append('utm_campaign', this.whitelabelSettings.utmCampaign);
      urlString = url.toString();
    }

    return urlString;
  }


  get style() {
    const style = {
      height: '100%',
    };
    if (this.scale != null) {
      style.height = (this.whitelabelSettings.logo.height / 4 * this.scale) + 'px';
    }
    return style;
  }


  /******************
  * Usage tracking *
  *****************/

  trackLogoClick() {
    if (this.isCustom || !this.href || !this.segmentSource) {
      return;
    }

    if (this.siteService.site.isStandalone) {
      this.usageTrackingService.createSegmentEvent(
        'vecteraLogo.clicked',
        this.segmentSource
      );
    } else if (this.siteService.site.isTeamleader) {
      this.usageTrackingService.createSegmentEvent(
        'teamleaderLogo.clicked',
        this.segmentSource
      );
    }
  }
}
