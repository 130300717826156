import { EventEmitter } from 'utils/util';


export default class EditingUserService {
  constructor() {
    this._bind();
    EventEmitter.setup(this, ['set', 'unset']);

    this.shapeUsers = {};
    this.userShapes = {};
    this.debounces = {};
  }

  _bind() {
    this._unsetAfterDebounce = this._unsetAfterDebounce.bind(this);
  }


  set(shapeId, user) {
    let currentShapeId = this.userShapes[user.id];
    if(currentShapeId != null && currentShapeId !== shapeId) {
      this.unset(currentShapeId);
      currentShapeId = null;
    }

    if(currentShapeId == null) {
      this.shapeUsers[shapeId] = user;
      this.userShapes[user.id] = shapeId;
      this.emit('set', shapeId, user);

      if(!(shapeId in this.debounces)) {
        this.debounces[shapeId] = debounce(this._unsetAfterDebounce, 5000);
      }
    }

    if(this.debounces[shapeId]) {
      this.debounces[shapeId](shapeId);
    }
  }


  _unsetAfterDebounce(shapeId) {
    delete this.debounces[shapeId];
    this.unset(shapeId);
  }


  unset(shapeId) {
    let user = this.shapeUsers[shapeId];
    if(user) {
      delete this.shapeUsers[shapeId];
      delete this.userShapes[user.id];
      this.emit('unset', shapeId);
    }
  }


  get(shapeId) {
    return this.shapeUsers[shapeId];
  }
}
