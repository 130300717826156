import {
  Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { bind } from 'utils/util';


/**
 * Can be used to bind "file picking" behaviour to any HTML element.
 *
 * Example usage:
 *
 * ```
 * <div class="btn" (pickFile)="setFile($event)"></div>
 * ```
 */
@Directive({
  selector: '[pickFile]'
})
export class PickFileDirective implements OnInit, OnDestroy {

  /** The extensions and/or mimetypes that are allowed. Will be passed to <input accept="..."/>. */
  @Input() pickFileTypes: string[] = [];
  /** Whether the form element should have the disabled state. */
  @Input() pickFileDisabled = false;
  /** Emitted when the user has picked a file. */
  @Output() pickFile = new EventEmitter<File>();


  private input?: HTMLInputElement;


  constructor() {
    bind(this);
  }


  ngOnInit() {
    this.createInput();
  }

  ngOnDestroy() {
    this.destroyInput();
  }



  private createInput() {
    if(this.input) {
      return;
    }
    this.input = document.createElement('input');
    this.input.type = 'file';
    this.input.style.display = 'none';
    if(this.pickFileTypes.length > 0) {
      this.input.accept = this.pickFileTypes.join(',');
    }
    this.input.addEventListener('change', this.onInputChange);
    document.body.appendChild(this.input);
  }


  private destroyInput() {
    if(!this.input) {
      return;
    }
    this.input.remove();
    this.input = undefined;
  }


  @HostListener('click')
  onClick() {
    if(this.input && !this.pickFileDisabled) {
      this.input.click();
    }
  }


  private onInputChange() {
    if(!this.input) {
      return;
    }

    const file = this.input.files?.[0];
    this.input.value = '';

    if(file) {
      this.pickFile.emit(file);
    }
  }
}
