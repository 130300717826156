<form
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="!readonly && !isEmbedded"
  (ngSubmit)="submit()"
>
  <h1>
    <span class="contact-form-form__title" data-testid="contactFormTitle">{{ contactForm.name }}</span>
  </h1>

  <settings-non-field-errors
    [nonFieldErrors]="errors.nonFieldErrors"
  ></settings-non-field-errors>

  <div class="contact-form-form__main">
    <div *ngIf="customization.introductionText" class="mb-8 text-pre-wrap">{{ customization.introductionText }}</div>

    <div *ngFor="let item of questionItems">
      <contact-form-question
        [question]="item.question"
        [answer]="item.answer"
        [errors]="item.errors"
      ></contact-form-question>
    </div>


    <div *ngIf="contactForm.allowUserNotification" class="p">
      <input
        id="submitterNotificationCheckbox"
        type="checkbox"
        class="checkbox"
        [(ngModel)]="shouldSendCopy"
        [ngModelOptions]="{standalone: true}"
        data-testid="iWantCopyCheckbox"
      >
      <label
        for="submitterNotificationCheckbox"
        class="checkbox__label"
        i18n
        data-testid="iWantCopyLabel"
      >
        I want to receive a copy of the form
      </label>

      <div *ngIf="shouldSendCopy" class="d-flex align-items-center">
        <span class="required text-nowrap" i18n >Send the copy to</span>

        <input
          class="input ml-2"
          [(ngModel)]="contactFormResponse.submitterNotificationEmail"
          [ngModelOptions]="{standalone: true}"
          placeholder="your@emailaddress.com"
          data-testid="sendCopyToInput"
        >
      </div>
      <settings-errors [settingsErrors]="errors.submitterNotificationEmail"></settings-errors>
    </div>


    <div *ngIf="contactForm.askToAcceptTerms && termsString" class="p">
      <input
        id="acceptTerms"
        type="checkbox"
        class="checkbox"
        [(ngModel)]="acceptTerms"
        [ngModelOptions]="{standalone: true}"
        data-testid="termsAndPrivacyPolicyCheckbox"
      >
      <label
        for="acceptTerms"
        class="checkbox__label"
        data-testid="termsAndPrivacyPolicyLabel"
      >
        <span [innerHTML]="termsString" class="required"></span>
      </label>
      <settings-errors [settingsErrors]="errors.acceptTerms"></settings-errors>
    </div>


    <div class="form-group" *ngIf="shouldShowRecaptcha">
      <div
        class="g-recaptcha"
        #recaptcha
      ></div>
      <settings-errors [settingsErrors]="errors.recaptcha"></settings-errors>
    </div>
  </div>


  <div class="d-flex d-sm-none align-items-center flex-column">
    <div class="mb-4 ml-6 align-self-end">
      <input
        type="submit"
        class="contact-form-form__btn mt-2"
        [value]="customization.sendButtonText"
        [disabled]="disabled"
      />
    </div>

    <powered-by
      *ngIf="shouldShowPoweredBy"
      [utmSource]="'contact_form'"
      [segmentSource]="'contact_form'"
      [tint]="customization.poweredByTint"
    ></powered-by>
  </div>

  <div class="d-none d-sm-flex justify-content-between align-items-center mb-4">
    <div class="mt-2">
      <powered-by
        *ngIf="shouldShowPoweredBy"
        [utmSource]="'contact_form'"
        [segmentSource]="'contact_form'"
        [tint]="customization.poweredByTint"
      ></powered-by>
    </div>

    <input
      type="submit"
      class="contact-form-form__btn mt-2"
      [value]="customization.sendButtonText"
      [disabled]="disabled"
      data-testid="sendButton"
    />
  </div>
</form>
