<div class="mb-4">
  <input
    type="radio"
    class="radio"
    name="defaultRedirectRadioButton"
    id="defaultRedirectRadioButton"
    [checked]="hasFreeSubscription || isDefaultRedirect"
    [disabled]="!hasPermissionToEdit"
    (change)="instance.redirectUrl = ''"
    data-testid="contactFormRedirectShowSuccessPageRadioButton"
  />
  <label
    class="radio__label"
    for="defaultRedirectRadioButton"
    i18n
    data-testid="contactFormRedirectShowSuccessPageLabel"
  >
    Show success page
  </label>
</div>

<div [withSettingsErrors]="errors.redirectUrl">
  <input
    type="radio"
    class="radio"
    name="urlRedirectRadioButton"
    id="urlRedirectRadioButton"
    [checked]="!hasFreeSubscription && !isDefaultRedirect"
    [disabled]="hasFreeSubscription || !hasPermissionToEdit "
    (change)="hasPermissionToEdit && updateRedirectUrlModel()"
    data-testid="contactFormRedirectRedirectToRadioButton"
  />
  <label
    *ngIf="!hasFreeSubscription"
    class="radio__label radio__label--input d-flex align-items-center"
    for="urlRedirectRadioButton"
  >
    <span class="text-nowrap mr-2" i18n data-testid="contactFormRedirectRedirectToLabel" >Redirect to</span>
    <input
      class="input"
      [(ngModel)]="redirectUrlInput"
      [disabled]="!hasPermissionToEdit"
      (ngModelChange)="hasPermissionToEdit && updateRedirectUrlModel()"
      placeholder="https://www.yourhomepage.eu"
      i18n-placeholder
      data-testid="contactFormRedirectRedirectToInput"
    />
  </label>
  <pro-feature-badge
      *ngIf="hasFreeSubscription"
      featureText="Redirect your users to any website"
      i18n-featureText
    >
    </pro-feature-badge>
</div>
