import { Directive, ElementRef, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { format } from 'utils/util';

const HEX_DIGITS = '0123456789abcdef';


@Directive({
  selector: '[color-field]',
})
export class ColorFieldDirective implements AfterViewInit {
  
  @Input('color-field-usage') colorFieldUsage: string | string[] | undefined;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement, 
      'innerHTML', 
      this.getColorAndUsage(this.elementRef.nativeElement)
    );
  }

  private getColorAndUsage(element: Element): string {
    const colorHexCode = this.getColorHexCode(element);
    let usage = '';

    if (this.colorFieldUsage) {
      usage += this.getUsage(this.colorFieldUsage);
    }

    return colorHexCode + usage;
  }

  private getColorHexCode(element: Element): string {
    const property = this.getActiveCssProperty(element);
    const rgbaColor = getComputedStyle(element).getPropertyValue(property);
    const hexColor = this.rgba2hex(rgbaColor);
    return format('<span class="text-monospace">%s</span>', hexColor);
  }

  private getUsage(colorFieldUsage): string {
    const usages = Array.isArray(colorFieldUsage) ?
      colorFieldUsage :
      colorFieldUsage.split(',');
    return usages.map(usage => format('<span>%s</span>', usage)).join('');
  }

  private getActiveCssProperty(element: Element): string {
    const style = getComputedStyle(element);
    if (style.borderColor && style.borderWidth !== '0px') {
      return 'border-color';
    } else if (style.backgroundColor !== 'rgba(0, 0, 0, 0)') {
      return 'background-color';
    } else {
      return 'color';
    }
  }

  private rgba2hex(rgba: string): string {
    const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/);
    if (!match) {
      return '#000000';
    }
    const r = parseInt(match[1]);
    const g = parseInt(match[2]);
    const b = parseInt(match[3]);
    const a = match[4] == null ? 1 : parseFloat(match[4]);

    let hex = format('#%s', [r, g, b].map(x => x * a + 255 * (1 - a)).map(this.int2hex).join(''));
    if (a !== 1) {
      hex += format(' / #%s', [r, g, b, a * 255].map(this.int2hex).join(''));
    }

    return hex;
  }

  private int2hex(x: number): string {
    const int = Math.round(x);
    return HEX_DIGITS[(int - int % 16) / 16] + HEX_DIGITS[int % 16];
  }
}
