<div *ngIf="appointments.length > 0" class="table__wrapper pp">
  <table class="table align-middle">
    <thead>
      <tr>
        <th i18n>Meeting with</th>
        <th class="d-none d-sm-table-cell" i18n>Meeting Type</th>
        <th *ngIf="this.scope === DashboardScope.PERSONAL" class="d-none d-sm-table-cell" i18n>Type</th>
        <th *ngIf="this.scope === DashboardScope.TEAM" class="d-none d-md-table-cell" i18n>Host</th>
        <th class="d-none d-lg-table-cell" i18n>Duration</th>
        <th class="d-none d-sm-table-cell" i18n>Location</th>
        <th></th>
      </tr>
    </thead>

    <tbody class="border-none">
      <tr *ngFor="let appointment of appointments; trackBy: trackByAppointment">
        <td>
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <profile-images [users]="appointment.guests" [maxBubbles]="3"></profile-images>
              <div class="ml-3 d-flex flex-column">
                <b>{{ appointment.primaryGuest.fullName }}</b>
              </div>
            </div>
            <div class="text-muted">{{ appointment.start | readableDate:true | capitalize }}, {{ appointment.start | localeTime }}–{{ appointment.end | localeTime }}</div>
            <div class="d-block d-sm-none"><appointment-location [appointment]="appointment"></appointment-location></div>
          </div>
        </td>

        <td class="d-none d-sm-table-cell" >
          <div class="d-flex align-items-center">
            <div>{{ appointment.name }}</div>
          </div>
        </td>

        <td *ngIf="this.scope === DashboardScope.PERSONAL" class="d-none d-md-table-cell">
          <badge
            [badge]="getLocationBadge(appointment)"
            class="mr-2"
          ></badge>
        </td>
        <td *ngIf="this.scope === DashboardScope.TEAM" class="d-none d-md-table-cell">
          <div class="d-flex align-items-center">
            <div class="flex-none mr-3">
              <profile-image [user]="appointment.host"></profile-image>
            </div>
            <div data-testid="hostFullName">{{ appointment.host.fullName }}</div>
          </div>
        </td>

        <td class="d-none d-lg-table-cell" >
          {{ (appointment.end - appointment.start)/1000 | duration:true }}
        </td>

        <td class="d-none d-sm-table-cell">
          <appointment-location [appointment]="appointment"></appointment-location>
        </td>

        <td>
          <div class="d-flex align-items-center justify-content-end">
            <div
              class="btn btn--square"
              [cdkMenuTriggerFor]="appointmentActions"
              [cdkMenuTriggerData]="{ appointment: appointment }"
              [cdkMenuPosition]="[{ originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top' }]"
            >
              <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #appointmentActions let-appointment="appointment">
  <div class="dropdown" cdkMenu>
    <div
      *ngIf="appointment.location === AppointmentLocation.ONLINE"
      cdkMenuItem
      [cdkMenuTriggerFor]="changeAppointmentMeeting"
      [cdkMenuTriggerData]="{ 'appointment' : appointment}"
      class="dropdown__list-item"
    >
      <span i18n>Change meeting room</span>
    </div>
    <div
      cdkMenuItem
      class="dropdown__list-item text-error-400"
      [modalTriggerFor]="cancelAppointment"
      [modalIcon]="{name: 'trash'}"
      modalTitle="Cancel appointment"
      i18n-modalTitle
      [modalTriggerData]="{ appointment: appointment }"
      i18n
    >
      Cancel meeting
    </div>
  </div>
</ng-template>


<ng-template #changeAppointmentMeeting let-appointment="appointment">
  <meeting-rooms-menu
    #meetingRoomsMenu
    [data]="{appointment: appointment}"
    [scope]="this.scope"
  ></meeting-rooms-menu>
</ng-template>

<ng-template #cancelAppointment let-appointment="appointment">
  <p i18n>
    Do you want to send a cancellation email to the guest?
  </p>

  <div class="d-flex justify-content-end">
    <div
      class="btn mr-2"
      modalClose
      i18n
    >
      Don't cancel
    </div>

    <div
      class="btn btn--text-error mr-2"
      (click)="cancel(appointment, false)"
      modalClose
      i18n
    >
      Don't send
    </div>

    <div
      class="btn btn--error"
      (click)="cancel(appointment, true)"
      modalClose
      i18n
    >
      Send
    </div>
  </div>
</ng-template>
