
class DesktopNotificationController {
  static get $inject() {
    return [
      'desktopNotificationService',
    ];
  }

  constructor(desktopNotificationService) {
    this.desktopNotificationService = desktopNotificationService;
  }
}

export default {
  controller: DesktopNotificationController,
  controllerAs: 'desktopNotificationCtrl',
};
