import { array, bind, errors, EventEmitter, format, logger } from 'utils/util';
import VecteraFile from './files/VecteraFile';


/**
 * the size of a standard A4 sheet, in DeskTop Publishing points
 *
 * @constant
 * @type {Object.<string, number>}
 * @default
 */
const A4_SIZE = Object.freeze({ width: 841.92, height: 595.32 });
export const PAPER_OBJECT_MARGIN = 20;  // Minimal margin between objects and the paper boundary
const MULTI_IMAGE_MARGIN = 20;  // margin between images when multiple get added to the same page
const DEFAULT_SHAPES_HEIGHT_MARGIN = 20;
const SOFT_LIMIT_NUM_WHITEBOARDS = 10;
const SEND_NAME_INTERVAL = 5000;
const SEND_VIEWPORT_INTERVAL = 1000;
const TUTORIAL_HINT_DURATION = 3000;
const DOWNLOAD_WHEN_INACTIVE_MAX_FILE_SIZE = 10 * 1024 * 1024;
const SCALE_MODIFIER_DEFAULT = 1;
const SCALE_MODIFIER_SMALL = 0.5;

export const AddImageType = Object.freeze({
  ACTIVE_PAGE: 'activePage',
  NEW_PAGE: 'newPage',
  NEW_WHITEBOARD: 'newWhiteboard',
});

/* eslint-disable max-len */
const SOFT_LIMIT_NOTIFICATION = `
<div ng-controller="WhiteboardMenuCtrl as ctrl">
  <div translate>
    Adding more whiteboards and files may decrease performance.<br>Consider removing unused files and whiteboards.
  </div>
  <div
    class="btn btn--secondary"
    ng-click="ctrl.whiteboardService.setWhiteboardLimitSeen(); notification.onCancelButton()"
  >
    <div class="btn__icon" svg="'utils/icons/tl/24x24_checkmark_outline.svg'"></div>
    <div translate>Don't show this again</div>
  </div>
</div>
`;
/* eslint-enable max-len */

export default class WhiteboardService {
  static get $inject() {
    return [
      'notificationService',
      'userService',
      'meetingBroadcastService',
      'meetingService',
      'tileService',
      'fileService',
      'whiteboardFactory',
      'logoService',
      'resetService',
      'modalService',
    ];
  }

  constructor(
    notificationService,
    userService,
    meetingBroadcastService,
    meetingService,
    tileService,
    fileService,
    whiteboardFactory,
    logoService,
    resetService,
    modalService
  ) {
    bind(this);
    EventEmitter.setup(this, ['add', 'remove']);

    this.notificationService = notificationService;
    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.tileService = tileService;
    this.fileService = fileService;
    this.whiteboardFactory = whiteboardFactory;
    this.logoService = logoService;
    this.resetService = resetService;
    this.modalService = modalService;

    this.whiteboards = {};
    this.whiteboardsCreatedFromStream = {};

    this.numWhiteboards = 0;
    this.maxNameId = 1;
    this.maxId = 0;
    this.shouldShowTutorial = false;
    this.shouldShowTutorialHint = false;

    this.uploadPromises = [];

    this._setupListeners();
  }

  _setupListeners() {
    this.meetingBroadcastService.on('notepad-create', this._onBroadcastCreate, false);
    this.meetingBroadcastService.on('notepad-delete', this._onBroadcastRemove, false);
    this.meetingBroadcastService.on('notepad-name', this._onBroadcastName, false);
    this.meetingBroadcastService.on('notepad-page-add', this._onBroadcastPageAdd, false);
    this.meetingBroadcastService.on('notepad-page-hide', this._onBroadcastPageRemove, false);
    this.meetingBroadcastService.on('notepad-page-select', this._onBroadcastPageSelect, true);
    this.meetingBroadcastService.on('notepad-document', this._onBroadcastDocument, false);
    this.meetingBroadcastService.on('notepad-viewport', this._onBroadcastViewport, false);
    this.meetingBroadcastService.afterInitialization().then(this._updateDownloadWhenInactive);
    this.userService.mySession.on('state', this._onMySessionState);

    this.resetService.on('reset', this._onMeetingReset);
  }


  get(id) {
    return this.whiteboards[id];
  }

  get softLimitReached() {
    return this.numWhiteboards >= SOFT_LIMIT_NUM_WHITEBOARDS;
  }
  get softLimitTooltip() {
    // eslint-disable-next-line max-len
    return gettextCatalog.getString('Adding more whiteboards and files may decrease performance. Consider removing unused files and whiteboards.');
  }


  /************************
   * Server communication *
   ************************/

  _onBroadcastCreate(
    channel,
    session,
    timestamp,
    id,
    name,
    contentSize,
    nameIsManual,
    isSnapshot
  ) {
    if(!(id in this.whiteboards)) {
      this._add(
        id,
        name,
        nameIsManual,
        { width: contentSize[0], height: contentSize[1] },
        false,
        isSnapshot
      );
    }
  }

  _onBroadcastRemove(channel, session, timestamp, id) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      whiteboard.remove(true);
    }
  }

  _onBroadcastName(channel, session, timestamp, id, name, nameIsManual) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      whiteboard.nameSynced = name;
      whiteboard.setName(name, nameIsManual);
    }
  }

  _onBroadcastPageAdd(channel, session, timestamp, id, index) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      whiteboard.addPage(index, true);
    }
  }

  _onBroadcastPageRemove(channel, session, timestamp, id, index) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      whiteboard.removePage(index, true);
    }
  }

  _onBroadcastPageSelect(channel, session, timestamp, id, index) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      whiteboard.setPageIndex(index, true);
    }
  }

  _onBroadcastDocument(channel, session, timestamp, id, fileId, name, numPages) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      let file = this.fileService.get(fileId);
      if(!file.hasInfo) {
        file.name = name;
        file.numPages = numPages;
        file.on('info', this._updateDownloadWhenInactive);
      }

      whiteboard.setDocument(file);
    }
  }

  _onBroadcastViewport(channel, session, timestamp, id, zoomLevel, contentFocusList) {
    let whiteboard = this.whiteboards[id];
    if(whiteboard) {
      let contentFocus = { x: contentFocusList[0], y: contentFocusList[1] };
      whiteboard.zoomLevelSynced = zoomLevel;
      whiteboard.contentFocusSynced = Object.assign({}, contentFocus);
      whiteboard.setViewport(contentFocus, zoomLevel, false);
    }
  }



  _sendCreate(whiteboard) {
    this.meetingBroadcastService.send(
      'notepad-create', false, [],
      whiteboard.id, whiteboard.name,
      [whiteboard.contentSize.width, whiteboard.contentSize.height],
      whiteboard.nameIsManual, whiteboard.isSnapshot);
  }

  _sendRemove(whiteboard) {
    if(whiteboard.removed && !whiteboard.removedSynced) {
      whiteboard.removedSynced = whiteboard.removed;
      this.meetingBroadcastService.send('notepad-delete', false, [], whiteboard.id);
    }
  }

  _sendName(whiteboard) {
    if(whiteboard.name !== whiteboard.nameSynced) {
      whiteboard.nameSynced = whiteboard.name;
      this.meetingBroadcastService.send(
        'notepad-name', false, [],
        whiteboard.id, whiteboard.name, whiteboard.nameIsManual);
    }
  }

  _sendPageAdd(whiteboard, page, index) {
    if(!page.synced) {
      page.synced = true;
      this.meetingBroadcastService.send(
        'notepad-page-add', false, [], whiteboard.id, index
      );
    }
  }

  _sendPageRemove(whiteboard, page, index) {
    if(!page.synced) {
      page.synced = true;
      this.meetingBroadcastService.send('notepad-page-hide', false, [], whiteboard.id, index);
    }
  }

  _sendLogoAdd(whiteboard) {
    this.meetingBroadcastService.send(
      'notepad-logo-add', false, [],
      whiteboard.id, whiteboard.logoId);
  }

  _sendPageSelect(whiteboard, page, index) {
    if(index !== whiteboard.pageIndexSynced) {
      whiteboard.pageIndexSynced = index;
      this.meetingBroadcastService.send('notepad-page-select', false, [], whiteboard.id, index);
    }
  }

  _sendSetDocument(whiteboard, fileId, filename, numPages) {
    this.meetingBroadcastService.send(
      'notepad-document', false, [], whiteboard.id, fileId, filename, numPages);
  }

  _sendViewport(whiteboard) {
    if(whiteboard.zoomLevel !== whiteboard.zoomLevelSynced
      || whiteboard.contentFocus.x !== whiteboard.contentFocusSynced.x
      || whiteboard.contentFocus.y !== whiteboard.contentFocusSynced.y
    ) {
      whiteboard.zoomLevelSynced = whiteboard.zoomLevel;
      whiteboard.contentFocusSynced = Object.assign({}, whiteboard.contentFocus);
      this.meetingBroadcastService.send(
        'notepad-viewport', false, [],
        whiteboard.id, whiteboard.zoomLevel,
        [whiteboard.contentFocus.x, whiteboard.contentFocus.y]
      );
    }
  }


  /*********************************
   * Create and delete whiteboards *
   *********************************/

  /**
   * @returns {Whiteboard} first active whiteboard in this.whiteboards, null when none are active
   */
  getActiveWhiteboard() {
    for(const whiteboard of Object.values(this.whiteboards)) {
      if(whiteboard.tile && whiteboard.tile.active) {
        return whiteboard;
      }
    }
    return null;
  }


  /**
   * get the whiteboard where "isSnapshot" is set to true
   *
   * When (screenshare) snapshots are created they will be added to a singleton
   * "snapshots" whiteboard. This method gets this singleton whiteboard either
   * by retrieving it or creating an empty one if it does not exist yet.
   *
   * @returns {Whiteboard}
   */
  getSnapshotWhiteboard() {
    let snapshotWhiteboard = Object.values(this.whiteboards).find(
      whiteboard => whiteboard.isSnapshot && !whiteboard.removed
    );

    if(!snapshotWhiteboard) {
      return this._addLocal(
        gettextCatalog.getString('Snapshots'),
        true,
        A4_SIZE,
        true
      );
    } else {
      return snapshotWhiteboard;
    }
  }

  addEmpty() {
    let name = this._getUniqueName();
    let contentSize = A4_SIZE;
    let whiteboard = this._addLocal(name, false, contentSize);

    if(whiteboard) {
      whiteboard.addPage(0, null, null, true);
      return whiteboard;
    }
  }

  setWhiteboardLimitSeen() {
    this.userService.me.meetingShowWhiteboardLimit = false;
  }

  _getUniqueName() {
    return gettextCatalog.getString(
      'Whiteboard {{ identifier }}',
      { identifier: this.maxNameId }
    );
  }


  _add(id, name, nameIsManual, contentSize, isLocal, isSnapshot) {
    let whiteboard = this.whiteboardFactory.create(
      id, name, nameIsManual, contentSize, isSnapshot
    );

    this.whiteboards[id] = whiteboard;
    this.numWhiteboards++;
    if(!nameIsManual) {
      this.maxNameId++;
    }

    whiteboard.on('remove', this._onRemove);
    if(isLocal) {
      this._onWhiteboardFinishInitializing(whiteboard);
    } else {
      whiteboard.on('initializing', this._onWhiteboardFinishInitializing);
    }

    this.tileService.add(whiteboard.tile);

    this.emit('add', whiteboard);
    return whiteboard;
  }


  _onWhiteboardFinishInitializing(whiteboard) {
    whiteboard.on('name', throttle(this._sendName, SEND_NAME_INTERVAL, true));
    whiteboard.on('page', this._sendPageSelect);
    whiteboard.on('pageAdd', this._sendPageAdd);
    whiteboard.on('addDefaultShapes', this._addDefaultShapes);
    whiteboard.on('pageRemove', this._sendPageRemove);
    whiteboard.on('viewport', throttle(this._sendViewport, SEND_VIEWPORT_INTERVAL, true));
  }


  _addLocal(name, nameIsManual, contentSize, isSnapshot) {
    if(isSnapshot == null) {
      isSnapshot = false;
    }

    if(this.softLimitReached && this.userService.me.meetingShowWhiteboardLimit) {
      this.notificationService.warning(SOFT_LIMIT_NOTIFICATION);
    }

    let id = this.userService.getUniqueId(++this.maxId);
    let whiteboard = this._add(id, name, nameIsManual, contentSize, true, isSnapshot);

    this._sendCreate(whiteboard);

    if(this.userService.me.meetingTutorialShowWhiteboard) {
      this.showTutorial();
    }

    return whiteboard;
  }


  _onRemove(whiteboard) {
    this.tileService.remove(whiteboard.tile);
    this.numWhiteboards--;
    this._sendRemove(whiteboard);
  }

  _addDefaultShapes(whiteboard, page, addLogo, addInitTextBox) {
    let promise = $q.resolve();
    if(addLogo) {
      promise = promise.then(() => this.addLogo(whiteboard, page));
    }
    if(addInitTextBox) {
      promise = promise.then(logoShape => this._addInitTextBox(whiteboard, page, logoShape));
    }
    return promise;
  }

  addLogo(whiteboard, page) {
    return this.logoService.getVecteraFile()
      .then(vecteraFile => {
        if(!vecteraFile) {
          return;
        }
        let position = { x: PAPER_OBJECT_MARGIN, y: PAPER_OBJECT_MARGIN };
        let scale = .25;
        return whiteboard.paperRenderer.addImage(
          vecteraFile, position, scale, page.id, this.logoService.logo.name);
      });
  }


  _addInitTextBox(whiteboard, page, logoShape) {
    let position = {};
    position.x = PAPER_OBJECT_MARGIN;
    if(logoShape == null) {
      position.y = PAPER_OBJECT_MARGIN;
    } else {
      position.y = (
        DEFAULT_SHAPES_HEIGHT_MARGIN
        + logoShape.properties.top
        + logoShape.properties.height * logoShape.properties.scaleY
      );
    }
    whiteboard.paperRenderer.addInitTextBox(position, page.id);
  }


  /*********
   * Files *
   *********/

  _showError(error) {
    let message;

    switch(error.constructor) {
      case errors.FileTypeError:
        logger.info(error);
        message = gettextCatalog.getString('Files of this type are not supported.');
        break;

      case errors.UnauthorizedError:
        message = gettextCatalog.getString('You are not allowed to upload files.');
        logger.warn('Failed to upload file:', error);
        break;

      case errors.ServerError:
        message = error.message;
        break;

      default:
        logger.warn('Failed to upload file:', error);
        // falls through

      case errors.FileInfoError:
        message = gettextCatalog.getString(`
          Something went wrong while uploading your file.
          Please try again later or with a different file.
        `);
        break;
    }

    if(message) {
      this.notificationService.warning(message);
    }
  }


  _getFileContentSize(vecteraFile) {
    let type = vecteraFile.type;

    if(type === VecteraFile.Type.BITMAP || type === VecteraFile.Type.VECTOR) {
      // We fit all images to an A4 paper
      let ratio = vecteraFile.rect.width / vecteraFile.rect.height;
      let margin = 2 * PAPER_OBJECT_MARGIN;

      if(ratio > 1) {
        return {
          width: A4_SIZE.width,
          height: margin + (A4_SIZE.width - margin) / ratio,
        };

      } else {
        return {
          width: margin + (A4_SIZE.width - margin) * ratio,
          height: A4_SIZE.width,
        };
      }

    } else if(type === VecteraFile.Type.PDF || type === VecteraFile.Type.DOCUMENT) {
      return {
        width: vecteraFile.rect.width,
        height: vecteraFile.rect.height,
      };

    } else {
      throw new Error('Unrecognized filetype:', type);
    }
  }


  _checkFilesLength(localFiles) {
    if(localFiles.length > 4) {
      this.notificationService.warning(gettextCatalog.getString(
        'Oops... Too many files at the same time. You can upload at most 4 files per time.'
      ));
      return false;
    }
    return true;
  }

  /**
   * add local files to the meeting room.
   *
   * @param {Object} localFiles
   * @param {AddImageType} addImageType the destination of the file
   * @param {Whiteboard} [whiteboard] optionally the whiteboard to be added.
   *    must be present when addImageType is NEW_PAGE or ACTIVE_PAGE
   */
  addLocalFiles(localFiles, addImageType, whiteboard) {
    this._addFromLocalOrCloudFiles(localFiles, 'upload', addImageType, whiteboard);
  }


  /**
   * add cloud files to the meeting room.
   *
   * @param {[Object]} cloudFileInfos
   * @param {AddImageType} addImageType the destination of the file
   * @param {Whiteboard} [whiteboard] optionally the whiteboard to be added
   */
  addCloudFiles(cloudFileInfos, addImageType, whiteboard) {
    return this._addFromLocalOrCloudFiles(
      cloudFileInfos,
      'createFromCloudFile',
      addImageType,
      whiteboard
    );
  }

  _addFromLocalOrCloudFiles(fileInfos, fileServiceMethod, addImageType, whiteboard) {
    if(!this._checkFilesLength(fileInfos)) {
      return;
    }

    fileInfos.forEach((fileInfo, index) => {
      this._addFromLocalOrCloudFile(fileInfo, fileServiceMethod, addImageType, whiteboard, index);
    });
  }

  _addFromLocalOrCloudFile(fileInfo, fileServiceMethod, addImageType, whiteboard, index) {
    let promise = this.fileService[fileServiceMethod](fileInfo);
    this.uploadPromises.push(promise);

    promise
      .finally(() => array.remove(this.uploadPromises, promise))
      .then((vecteraFile) => this._addVecteraFile(vecteraFile, addImageType, whiteboard, index))
      .catch(this._showError);
  }

  /**
   * Decide how to add a given vecteraFile to a meeting room
   *
   * Images can be added as a new page or on the active page of the active
   * whiteboard, or as a new whiteboard alltogether. Documents will be added
   * as a whiteboard with a page for each document page.
   *
   * @param {VecteraFile} vecteraFile
   * @param {AddImageType} addImageType the destination of the file
   * @param {Whiteboard} [whiteboard] optionally the whiteboard to be added
   */
  _addVecteraFile(vecteraFile, addImageType, whiteboard, index) {
    if(!vecteraFile) {
      return;
    }

    if(
      (addImageType === AddImageType.ACTIVE_PAGE || addImageType === AddImageType.NEW_PAGE)
      && !whiteboard
    ) {
      throw new errors.InvalidArgumentError(format(
        'whiteboard cannot be null when image add type is ACTIVE_PAGE or NEW_PAGE:',
        vecteraFile, addImageType, whiteboard
      ));
    }


    switch(vecteraFile.type) {
      case VecteraFile.Type.BITMAP:
      case VecteraFile.Type.VECTOR:
        if(addImageType === AddImageType.NEW_WHITEBOARD) {
          this._addVecteraImageAsNewWhiteboard(vecteraFile);
        } else if(addImageType === AddImageType.ACTIVE_PAGE) {
          this._addVecteraImageToPage(vecteraFile, whiteboard, index, SCALE_MODIFIER_SMALL);
        } else if(addImageType === AddImageType.NEW_PAGE) {
          this._addVecteraImageAsNewPage(vecteraFile, whiteboard);
        }
        break;

      case VecteraFile.Type.PDF:
      case VecteraFile.Type.DOCUMENT:
        let contentSize = this._getFileContentSize(vecteraFile);
        let newWb = this._addLocal(vecteraFile.name, true, contentSize);
        if(!newWb) {
          return;
        }
        newWb.setDocument(vecteraFile);
        this._sendSetDocument(newWb, vecteraFile.id, vecteraFile.name, vecteraFile.numPages);
        break;

      default:
        throw new Error('Unrecognized filetype:', vecteraFile.type);
    }
  }

  /**
   * Add a new page to a whiteboard with a Vectera Image on it.
   *
   * @param {VecteraFile} vecteraFile
   * @param {Whiteboard} whiteboard
   */
  _addVecteraImageAsNewPage(vecteraFile, whiteboard) {
    if(!vecteraFile || !whiteboard) {
      throw new errors.InvalidArgumentError(
        format('vecteraFile or whiteboard cannot be null:', vecteraFile, whiteboard)
      );
    }

    whiteboard.addPage(whiteboard.numPages, null, false);
    whiteboard.setPageIndex(whiteboard.numPages - 1);
    this._addVecteraImageToPage(vecteraFile, whiteboard);
  }

  /**
   * Create a new whiteboard and add a vectera image to the first page
   *
   * @param {VecteraFile} vecteraFile
   * @param {Whiteboard} whiteboard
   */
  _addVecteraImageAsNewWhiteboard(vecteraFile) {
    let contentSize = this._getFileContentSize(vecteraFile);

    let whiteboard = this._addLocal(vecteraFile.name, true, contentSize);
    if(whiteboard.pages.length === 0) {
      whiteboard.addPage(0, null, false);
    }

    this._addVecteraImageToPage(vecteraFile, whiteboard);
  }

  /**
   * Add a Vectera Image to the currently active page of a whiteboard.
   *
   * @param {VecteraFile} vecteraFile
   * @param {Whiteboard} whiteboard
   * @param {Float} scaleMod the scale (between 0-1) of the image to be added, compared to the
   *  whiteboard
   */
  _addVecteraImageToPage(vecteraFile, whiteboard, index, scaleMod) {
    if(whiteboard.pages.length === 0) {
      whiteboard.addPage(0, null, false);
    }

    if(!scaleMod) {
      scaleMod = SCALE_MODIFIER_DEFAULT;
    }

    if(!index) {
      index = 0;
    }

    let contentSize = this._getFileContentSize(vecteraFile);
    let position = {
      x: PAPER_OBJECT_MARGIN + index * MULTI_IMAGE_MARGIN,
      y: PAPER_OBJECT_MARGIN + index * MULTI_IMAGE_MARGIN
    };
    let fileWidth = vecteraFile.rect.width;
    let scale = scaleMod * ((contentSize.width - 2 * PAPER_OBJECT_MARGIN) / fileWidth);
    whiteboard.paperRenderer.addImage(vecteraFile, position, scale);
  }


  _onMySessionState() {
    if(!this.userService.mySession.isJoined()) {
      this.uploadPromises.forEach(promise => {
        this.fileService.cancelUpload(promise);
      });
    }
  }


  _onMeetingReset(resetParameters) {
    if(!resetParameters['whiteboards']) {
      return;
    }
    this.uploadPromises.forEach(promise => {
      this.fileService.cancelUpload(promise);
    });
    Object.values(this.whiteboards).forEach(whiteboard => whiteboard.remove(true));
  }

  _updateDownloadWhenInactive() {
    if(this.meetingBroadcastService.initializing) {
      return;
    }

    let totalFileSize = 0;
    for(let whiteboard of Object.values(this.whiteboards)) {
      if(!whiteboard.document || whiteboard.removed) {
        continue;
      }
      if(!whiteboard.document.hasInfo) {
        return;
      }
      totalFileSize += whiteboard.document.size;
    }

    let downloadWhenInactive = totalFileSize < DOWNLOAD_WHEN_INACTIVE_MAX_FILE_SIZE;
    Object.values(this.whiteboards).forEach(whiteboard => {
      whiteboard.setDownloadWhenInactive(downloadWhenInactive);
    });
  }


  /************
   * Tutorial *
   ************/

  showTutorial() {
    this.shouldShowTutorial = true;
    this.shouldShowTutorialHint = false;
    this.userService.me.meetingTutorialShowWhiteboard = false;
  }


  hideTutorial() {
    if(this.shouldShowTutorial) {
      this.shouldShowTutorial = false;
      this.shouldShowTutorialHint = true;
      $timeout(() => {
        this.shouldShowTutorialHint = false;
      }, TUTORIAL_HINT_DURATION);
    }
  }
}
