<div class="mt-4 flex-1 d-flex flex-row">
  <div class="multi-option-config__line"></div>
  <div>
    <ng-container *ngFor="let option of interfaceOptions; let idx = index; trackBy:trackByIndex">
      <div
        class="{{type}}__wrapper d-flex flex-row align-items-center"
        *ngIf="idx === (interfaceOptions.length - 1) && question.extra.otherOption"
        >
        <input type="{{ type }}" class="{{ type }} pointer-events-none">
        <label class="{{ type }}__label {{ type }}__label--input pointer-events-none">
          <input
            #input
            class="input"
            placeholder="Other..."
            i18n-placeholder
            maxlength="150"
            [disabled]="!hasPermissionToEdit"
          />
        </label>
        <div
          *ngIf="hasPermissionToEdit"
          class="btn btn--square ml-2"
          (click)="toggleOtherOption()"
        >
          <svg-icon class="icon" [svgName]="'trash'"></svg-icon>
        </div>
      </div>
      <div class="{{type}}__wrapper d-flex flex-row align-items-center">
        <input type="{{ type }}" class="{{ type }}">
        <label class="{{ type }}__label {{ type }}__label--input">
          <input
            #input
            class="input"
            [(ngModel)]="interfaceOptions[idx]"
            (ngModelChange)="tryAddOption()"
            placeholder="Add an option..."
            i18n-placeholder
            maxlength="150"
            [disabled]="!hasPermissionToEdit"
          />
        </label>
        <div
          class="btn btn--square ml-2"
          *ngIf="hasPermissionToEdit && canDeleteOption(idx)"
          (click)="removeOption(idx)"
        >
          <svg-icon class="icon" [svgName]="'trash'"></svg-icon>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="hasPermissionToEdit && !question.extra.otherOption && allowOtherOption"
      (click)="toggleOtherOption()"
      class="multi-option-config__other"
    >
      <svg-icon
        class="icon mr-3"
        [svgName]="'add'"
        [svgStyle]="'badged_outline'"
      ></svg-icon>
      <span i18n>Add "Other"</span>
    </div>
  </div>
</div>
