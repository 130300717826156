export default class ChromeExtensionModalController {
  static get $inject() {
    return [
      'chromeExtensionService',
    ];
  }

  constructor(
    chromeExtensionService
  ) {
    this.chromeExtensionService = chromeExtensionService;
  }

  get extensionRequiredText() {
    if(!this.chromeExtensionService.installInfo) {
      return '';
    }

    /// {action} can be one of "share your screen", "start cobrowsing" or "start recording"
    return gettextCatalog.getString(
      'The Chrome extension is required to {{ action }}.',
      { action: this.chromeExtensionService.installInfo.action }
    );
  }
}
