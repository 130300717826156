import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fetch-error',
  templateUrl: './fetch-error.component.html'
})
export class FetchErrorComponent  {
  @Input() fetchError?: string;
  @Output() tryAgain = new EventEmitter<void>();
}
