import { Component, Input } from '@angular/core';
import { State as SyncState } from './integration-sync.service';
import { NotesSyncService } from './notes-sync.service';


@Component({
  selector: 'notes-sync-button',
  templateUrl: './notes-sync-button.component.html',
})
export class NotesSyncButtonComponent {
  @Input() content!: string;
  
  readonly SyncState = SyncState;

  constructor(public syncService: NotesSyncService) {
  }

  get state(): SyncState {
    return this.syncService.state;
  }

  get integrations(): string[] {
    return this.syncService.activeIntegrationNames();
  }
}
