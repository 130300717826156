import RecordingsService from './recordings.service.js';
import RecordingsComponent from './recordings.component.js';
import DashboardRecordingFactory from './dashboardRecordingFactory.service.js';


angular
  .module('dashboard')
  .service('dashboardRecordingFactory', DashboardRecordingFactory)
  .service('recordingsService', RecordingsService)
  .component('recordings', RecordingsComponent)
  .controller('RecordingsCtrl', RecordingsComponent.controller);
