import { downgradeInjectable } from '@angular/upgrade/static';

import { MappingFieldsService } from './contact-form-config/mapping-fields.service';


angular
  .module('contactForm')

  .service(
    'mappingFieldsService',
    downgradeInjectable(MappingFieldsService)
  );
