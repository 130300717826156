import { Inject, Injectable } from '@angular/core';
import {
  AllMappingFields, MappingField, Provider, CRMEntity
} from 'contactForm/models/MappingField';
import { Memoize } from 'typescript-memoize';
import { RequestUserService } from 'utils/requestUser.service';
import { array } from 'utils/util';
import { ContactFormQuestion, QuestionType } from 'contactForm/models/ContactFormQuestion';
import { MappingConfig, TeamleaderMappingConfig } from 'contactForm/models/MappingConfig';
import { ComboBoxCategory, ComboBoxOption } from 'utils/form-elements/dropdowns';
import { questionTypeConfigs } from './contact-form-config-question/QuestionTypeConfig';

export enum MappingFieldDisabledReason {
  NONE,
  INCOMPATIBLE,
  IN_USE,
  ENTITY_DISABLED,
  FREE_PLAN,
  CANT_BE_PREFILLED,
}

/* eslint-disable max-len */
const disabledReasonStringMap: Record<MappingFieldDisabledReason, string> = {
  [MappingFieldDisabledReason.NONE]: $localize `This field is disabled`,
  [MappingFieldDisabledReason.INCOMPATIBLE]: $localize `This field is not compatible with the selected question type`,
  [MappingFieldDisabledReason.IN_USE]: $localize `This field is already in use on another question`,
  [MappingFieldDisabledReason.ENTITY_DISABLED]: $localize `The form is not configured to create this entity`,
  [MappingFieldDisabledReason.FREE_PLAN]: $localize `This field is not available in the free plan`,
  [MappingFieldDisabledReason.CANT_BE_PREFILLED]: $localize `This field is not available for prefilled answers`,
};
/* eslint-enable max-len */

export const CRMEntityLabels: Record<CRMEntity, string> = {
  [CRMEntity.CONTACT]: $localize `Contact`,
  [CRMEntity.COMPANY]: $localize `Company`,
  [CRMEntity.DEAL]: $localize `Deal`,
};

export enum QuestionConfigType {
  BASE,
  PREFILLED
}

@Injectable({
  providedIn: 'root',
})
export class MappingFieldsService {
  private _mappingFields: Record<Provider, Record<string, MappingField>> = {};
  public mappingFieldsInUse: Record<Provider, Record<QuestionConfigType, Set<MappingField>>> = {
    [Provider.TEAMLEADER]: {
      [QuestionConfigType.BASE]: new Set(),
      [QuestionConfigType.PREFILLED]: new Set(),
    },
    [Provider.VECTERA]: {
      [QuestionConfigType.BASE]: new Set(),
      [QuestionConfigType.PREFILLED]: new Set(),
    }
  };
  private mappingIdsInUse = new Set<string>;

  constructor(
    private requestUserService: RequestUserService,
    @Inject('modelFactory') private modelFactory,
  ) {}


  @Memoize()
  async get() {
    const response = await this.modelFactory.read({
      model: AllMappingFields,
    });

    Object.values(Provider).forEach((provider: Provider) => {
      this.mappingFields[provider] = {};
      if(response.data[provider]) {
        response.data[provider].forEach(field => {
          this.mappingFields[provider][field.id] = field;
        });
      }
    });

    return this.mappingFields;
  }

  get mappingFields() {
    this.get();
    return this._mappingFields;
  }


  getMappingField(provider, fieldId) {
    this.get();
    return this.mappingFields[provider]?.[fieldId];
  }

  public updateMappingFieldsInUse(
    provider: Provider, mappingFields: Set<MappingField>, configType: QuestionConfigType
  ) {
    this.mappingFieldsInUse[provider][configType] = mappingFields;
    this.mappingIdsInUse = new Set<string>;
    Object.values(this.mappingFieldsInUse).forEach( provider => {
      Object.values(provider).forEach(configType => {
        configType.forEach(mappingField => this.mappingIdsInUse.add(mappingField.id));
      });
    });
  }

  public mappingFieldToComboBoxOption(
    mappingConfig: MappingConfig,
    question: ContactFormQuestion,
    mappingField: MappingField,
    isForPrefilledAnswers: boolean,
  ): ComboBoxOption {
    const disabledReason = this.getMappingFieldDisabledReason(
      mappingConfig,
      question,
      mappingField,
      isForPrefilledAnswers
    );

    const resOption : ComboBoxOption = {
      label: mappingField.name,
      value: mappingField,
      disabled: disabledReason !== MappingFieldDisabledReason.NONE,
      disabledHelpText: disabledReasonStringMap[disabledReason],
      icon: questionTypeConfigs[mappingField.questionTypes[0]].icon,
      requiresUpgrade: disabledReason === MappingFieldDisabledReason.FREE_PLAN,
    };

    if((question.type === QuestionType.SINGLE_SELECT
      || question.type === QuestionType.MULTI_SELECT)
      && question.extra?.options?.length === 0
    ) {
      resOption.disabled = true;
      resOption.disabledHelpText =
        $localize `This field has no options configured, please add some in Teamleader Focus`;
    }
    return resOption;
  }

  public getMappingFieldDisabledReason(
    mappingConfig: MappingConfig,
    question: ContactFormQuestion,
    mappingField: MappingField,
    isForPrefilledAnswers: boolean,
  ): MappingFieldDisabledReason {
    if(
      this.requestUserService.user.organization.subscription.status === 'free'
      && !mappingField.allowFree
    ) {
      return MappingFieldDisabledReason.FREE_PLAN;

    } else if(!mappingConfig.shouldCreate(mappingField.entity)) {
      return MappingFieldDisabledReason.ENTITY_DISABLED;

    } else if(
      question.type != null
      && !array.has(mappingField.questionTypes, question.type)
    ) {
      return MappingFieldDisabledReason.INCOMPATIBLE;

    } else if(
      !mappingField.isFallback
      && this.mappingIdsInUse.has(mappingField.id)
      && question.extra?.mappedFields?.teamleader?.id !== mappingField.id
    ) {
      return MappingFieldDisabledReason.IN_USE;
    } else if(
      isForPrefilledAnswers
      && (
        array.has(mappingField.questionTypes, QuestionType.ATTACHMENT)
        || mappingField.id === 'contact.marketing_consent'
      )
    ) {
      return MappingFieldDisabledReason.CANT_BE_PREFILLED;
    } else {
      return MappingFieldDisabledReason.NONE;
    }
  }

  public generateComboBoxCategories(
    mappingConfig: TeamleaderMappingConfig,
    question: QuestionType,
    isForPrefilledAnswers = false
  ): ComboBoxCategory[] {
    const teamleaderMappingFields = this.mappingFields.teamleader || {};

    // always create a suggestions category first, so it will be the first category that shows up
    const comboBoxCategories : ComboBoxCategory[] = [{ label:'Suggestions', options: [] }];

    Object.values(teamleaderMappingFields).forEach(mappingField => {
      const option = this.mappingFieldToComboBoxOption(
        mappingConfig,
        question,
        mappingField,
        isForPrefilledAnswers
      );

      const entityLabel = CRMEntityLabels[mappingField.entity];
      let entityIdx = 0;
      if(!mappingField.isCustomField) {
        entityIdx = this.getCategoryIdx(comboBoxCategories, entityLabel);
      } else {
        entityIdx = this.getCustomFieldCategoryIdx(comboBoxCategories, entityLabel);
      }
      comboBoxCategories[entityIdx].options.push(option);

      if(!!question.type && !option.disabled) {
        const suggestionsIdx = comboBoxCategories.findIndex(cat => cat.label === 'Suggestions');
        comboBoxCategories[suggestionsIdx].options.push(Object.assign({}, option, {
          label: `${entityLabel} - ${option.label}`
        }));
      }
    });

    const suggestionsIdx = comboBoxCategories.findIndex(cat => cat.label === 'Suggestions');
    if(comboBoxCategories[suggestionsIdx].options.length === 0) {
      comboBoxCategories.splice(suggestionsIdx, 1);
    }

    return comboBoxCategories;
  }

  private getCategoryIdx(comboBoxCategories, label: string) {
    let entityIdx = comboBoxCategories.findIndex(cat => cat.label === label);
    if(entityIdx < 0) {
      comboBoxCategories.push({ label: label, options: [] });
      entityIdx = comboBoxCategories.findIndex(cat => cat.label === label);
    }
    return entityIdx;
  }

  private getCustomFieldCategoryIdx(comboBoxCategories, label: string) {
    const entityCustomFieldsLabel = 'Custom Fields - ' + label;
    let entityCustomFieldsIdx = comboBoxCategories.findIndex(
      cat => cat.label === entityCustomFieldsLabel
    );
    if(entityCustomFieldsIdx < 0) {
      const entityIdx = comboBoxCategories.findIndex(cat => cat.label === label);
      comboBoxCategories.splice(
        entityIdx + 1, 0, { label: entityCustomFieldsLabel, options: [] }
      );
      entityCustomFieldsIdx = comboBoxCategories.findIndex(
        cat => cat.label === entityCustomFieldsLabel
      );
    }
    return entityCustomFieldsIdx;
  }

}
