import template from './mainChatButton.html?raw';
import templateDropdown from './mainChat.dropdown.html?raw';
import templateDropdownReset from './mainChatResetConfirmation.dropdown.html?raw';
import templateModal from './mainChatPhone.modal.html?raw';

import { array, Rect } from 'utils/util';
import Dropdown from 'utils/angularjs/dropdown/Dropdown';
import { DROPDOWN_ID as DROPDOWN_ID_EMOJI } from '../common/emoji.service';

export const DROPDOWN_ID = 'mainChat';
export const DROPDOWN_ID_RESET_CONFIRMATION = 'resetConfirmation';

export const MODAL_ID = 'mainChatPhone';
const MAX_BUBBLES = 5;
const MAX_BUBBLE_AGE = 30000;
const BUBBLE_DURATION = 10000;


class MainChatButtonController {
  static get $inject() {
    return [
      '$element',
      'dropdownService',
      'modalService',
      'browserService',
      'shortcutService',
      'meetingService',
      'meetingBroadcastService',
      'chatBoxService',
    ];
  }

  constructor(
    $elem,
    dropdownService,
    modalService,
    browserService,
    shortcutService,
    meetingService,
    meetingBroadcastService,
    chatBoxService
  ) {
    this._bind();

    this.dropdownService = dropdownService;
    this.modalService = modalService;
    this.browserService = browserService;
    this.shortcutService = shortcutService;
    this.meetingService = meetingService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.chatBoxService = chatBoxService;

    this.$elem = $elem;
    this.$elemBubbles = null;

    this.bubbleMessages = [];
    this.numUnreadMessages = 0;
    this.chatBox = this.chatBoxService.get('main');


    this.dropdownService.register(
      DROPDOWN_ID_RESET_CONFIRMATION,
      templateDropdownReset,
      {
        align: Dropdown.Align.END,
      }
    );

    this.dropdownService.register(
      DROPDOWN_ID,
      templateDropdown,
      {
        cssClasses: 'dropdown-deprecated--no-padding',
        preRender: true,
        embeds: [DROPDOWN_ID_EMOJI, DROPDOWN_ID_RESET_CONFIRMATION],
      });

    this.modalService.register(
      MODAL_ID,
      '',
      '',
      templateModal,
      { padding: false, rawTemplate: true, cssClasses: 'h-full', });
  }

  _bind() {
    this.toggleChat = this.toggleChat.bind(this);
    this._onDropdownActive = this._onDropdownActive.bind(this);
    this._onModalShow = this._onModalShow.bind(this);
    this._onDeviceType = this._onDeviceType.bind(this);
    this._onMessage = this._onMessage.bind(this);
    this._onReset = this._onReset.bind(this);
    this._onWindowResize = this._onWindowResize.bind(this);
    this._updateBubblePosition = this._updateBubblePosition.bind(this);
  }


  $onInit() {
    this.shortcutService.on('alt+c', this.toggleChat);
    $(window).on('resize', this._onWindowResize);
    this.browserService.on('deviceType', this._onDeviceType);
    this.dropdownService.on('active', this._onDropdownActive);
    this.modalService.on('show', this._onModalShow);

    this.meetingBroadcastService.afterInitialization().then(() => {
      this.chatBox.on('message', this._onMessage);
      this.chatBox.on('reset', this._onReset);
    });

    this.$elemBubbles = this.$elem.find('.main-chat__bubbles');
    this.$elemMessageList = this.$elem.find('.chat__message-list');
    this._updateBubblePosition();
  }

  $onDestroy() {
    this.shortcutService.off('alt+c', this.toggleChat);
    $(window).off('resize', this._onWindowResize);
    this.browserService.off('deviceType', this._onDeviceType);
    this.dropdownService.off('active', this._onDropdownActive);
    this.modalService.off('show', this._onModalShow);

    this.meetingBroadcastService.afterInitialization().then(() => {
      this.chatBox.off('message', this._onMessage);
      this.chatBox.off('reset', this._onReset);
    });
  }

  // get tooltip() {
  //   return gettextCatalog.getString('Chat')
  //   + ` (${this.shortcutService.getPrettyString('alt+c')})`;
  // }



  /********************************
   * Toggle the dropdown or modal *
   ********************************/

  toggleChat() {
    this.browserService.isDesktop ?
      this.dropdownService.toggle(DROPDOWN_ID, this.$elem) :
      this.modalService.show(MODAL_ID);
  }


  _onDropdownActive(dropdown) {
    if(dropdown.id === DROPDOWN_ID) {
      this._setAllRead();
    }
  }

  _onModalShow(modal) {
    if(modal && modal.id === MODAL_ID) {
      this._setAllRead();
    }
  }

  _onDeviceType(deviceType) {
    if(deviceType !== this.browserService.DeviceType.PHONE) {
      this.modalService.hide(MODAL_ID);
    }
  }



  /***************************
   * Manage the chat bubbles *
   ***************************/

  _onMessage(message) {
    if(
      message.system
      || message.sender.user.isMe
      || new Date() - message.datetime > MAX_BUBBLE_AGE
      || this.dropdownService.get(DROPDOWN_ID).active
      || this.modalService.get(MODAL_ID).isShown
    ) {
      return;
    }

    this.numUnreadMessages++;
    this.bubbleMessages.push(message);
    if(this.bubbleMessages.length > MAX_BUBBLES) {
      this.bubbleMessages.shift();
    }
    $timeout(this._updateBubblePosition);
    $timeout(this._removeBubble.bind(this, message), BUBBLE_DURATION);
  }


  _onWindowResize() {
    // requestAnimationFrame is necessary on mobile safari: otherwise the old window size is
    // reported
    requestAnimationFrame(this._updateBubblePosition);
  }


  _onReset() {
    this._setAllRead();
  }


  _setAllRead() {
    this.numUnreadMessages = 0;
    array.clear(this.bubbleMessages);
  }


  _removeBubble(message) {
    array.remove(this.bubbleMessages, message);
    $timeout(this._updateBubblePosition);
  }



  _updateBubblePosition() {
    if(!this.$elemBubbles || this.$elemBubbles.length === 0) {
      return;
    }

    let rectDocument = Rect.fromFixedAncestor(this.$elem[0]);
    let rectParent = Rect.fromElem(this.$elem[0]);
    let rectMessageList = Rect.fromElem(this.$elemMessageList[0]);

    if(rectParent.left + rectMessageList.width <= rectDocument.width) {
      this.$elemBubbles.css({
        left: 0,
        right: '',
        justifyContent: 'flex-start',
      });

    } else {
      this.$elemBubbles.css({
        left: '',
        right: 0,
        justifyContent: 'flex-end',
      });
    }
  }



}



export default {
  controller: MainChatButtonController,
  controllerAs: 'mainChatButtonCtrl',
  template,

  bindings:{
    translucent: '<',
    collapsed: '<',
    tooltip: '<mainChatTooltip'
  },
};
