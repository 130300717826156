import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { bind, platform } from 'utils/util';


@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() page = 1;
  @Output() pageChange = new EventEmitter<number>();
  @Input() numPages = 1;
  @Input() maxNumPagesVisible = 7;

  public range: number[] = [];


  constructor() {
    bind(this);
  }

  ngOnChanges() {
    if(this.maxNumPagesVisible < 5) {
      throw new Error('maxNumPagesVisible must be at least 5');
    }
    if(this.maxNumPagesVisible % 2 === 0) {
      throw new Error('maxNumPagesVisible must be odd');
    }

    this.range = this.getRange(this.page, this.numPages, this.maxNumPagesVisible);
  }


  /**
   * Build the range of pages to display in the pagination component.
   *
   * The range is always `maxNumPagesVisible` elements long, unless the total number of pages
   * is less than this.
   *
   * @param page - The current page.
   * @param numPages - The total number of pages.
   * @param maxNumPagesVisible - The maximum number of pages to display.
   *
   * @returns {number[]} The range of pages to display. A negative number indicates an ellipsis.
   */
  public getRange(page: number, numPages: number, maxNumPagesVisible: number): number[] {
    // Our range should always be exactly 7 elements long. (Unless the array is shorter )
    // A negative integer in the range indicates that an ellipsis should be inserted there.
    const range = Array.from({ length: numPages }, (_, i) => i + 1);
    if(numPages <= maxNumPagesVisible) {
      return range;
    }

    // Add ellipsis in second half
    if(numPages - page > maxNumPagesVisible / 2) {
      const spliceStart = Math.max(page + 1, maxNumPagesVisible - 2);
      const spliceEnd = numPages - 1;
      range.splice(
        spliceStart,
        spliceEnd - spliceStart,
        -1
      );
    }
    // Add ellipsis in first half
    if((page - 1) > maxNumPagesVisible / 2) {
      const spliceStart = 1;
      const spliceEnd = Math.min(page - 2, numPages - (maxNumPagesVisible - 2));
      range.splice(spliceStart, spliceEnd - spliceStart, -1);
    }

    return range;
  }


  next() {
    this.setPage(this.page + 1);
  }

  previous() {
    this.setPage(this.page - 1);
  }

  setPage(page: number) {
    // this.numPages is not yet known on page load, so we accept any page number bigger than 1
    page = platform(1, page);
    if(page === this.page) {
      return;
    }

    this.page = page;
    this.pageChange.emit(page);
  }
}
