import { bind } from 'utils/util';

export default class MeetingEndedController {
  static get $inject() {
    return [
      '$element',
      'meetingEndedService',
      'feedbackService',
      'recordingService',
      'languageService',
      'notificationService',
      'notesSyncService',
      'privateNotesService',
    ];
  }

  constructor(
    $elem,
    meetingEndedService,
    feedbackService,
    recordingService,
    languageService,
    notificationService,
    notesSyncService,
    privateNotesService
  ) {
    bind(this);

    this.$elem = $elem;
    this.meetingEndedService = meetingEndedService;
    this.feedbackService = feedbackService;
    this.recordingService = recordingService;
    this.languageService = languageService;
    this.notificationService = notificationService;
    this.notesSyncService = notesSyncService;
    this.privateNotesService = privateNotesService;
  }

  send() {
    this.feedbackService.send();
    this.meetingEndedService.hideModal();
    this.notificationService.info(gettextCatalog.getString('Thank you for your feedback!'));
  }


  skip() {
    this.meetingEndedService.onModalFinish();
  }
}
