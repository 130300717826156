import { object } from 'utils/util';
import fabric from './fabric';


export default class FabricToolsSyncService {
  static get $inject() {
    return [
      'toolService',
    ];
  }

  constructor(
    toolService
  ) {
    this._bind();

    this.toolService = toolService;

    fabric.on('selectionStyle', this._updateToolOptions);
    this._initFabricStyle();
  }

  _bind() {
    this._updateToolOptions = this._updateToolOptions.bind(this);
  }


  _initFabricStyle() {
    fabric._defaultTextStyle = {};
    ['textSize', 'textColor', 'textBold', 'textItalic', 'textUnderline'].forEach(toolsKey => {
      let toolsValue = this.toolService.default[toolsKey];
      let [fabricKey, fabricValue] = fabric.convertOptionFromTools(toolsKey, toolsValue);
      fabric._defaultTextStyle[fabricKey] = fabricValue;

      this.toolService.on(toolsKey, this._onToolOption.bind(this, toolsKey));
    });
    fabric._insertCharStyle = Object.assign({}, fabric._defaultTextStyle);
  }


  _updateToolOptions(fabricStyle) {
    if(!fabricStyle) {
      return;
    }

    $rootScope.$evalAsync(() => {
      object.forEach(fabricStyle, (fabricKey, fabricValue) => {
        let [toolsKey, toolsValue] = fabric.convertOptionToTools(fabricKey, fabricValue);
        this.toolService.set(toolsKey, toolsValue, false);
      });
    });
  }


  _onToolOption(toolsKey, toolsValue) {
    let [fabricKey, fabricValue] = fabric.convertOptionFromTools(toolsKey, toolsValue);
    fabric._insertCharStyle[fabricKey] = fabricValue;
  }
}
