import DeviceSettingsComponent from './deviceSettings.component';
import SegmentationSettingsService from './segmentationSettings.service';
import SettingsController from './settings.controller';
import SettingsService from './settings.service';
import TestAudioInputComponent from './testAudioInput.component';
import TestAudioOutputComponent from './testAudioOutput.component';
import TestVideoInputComponent from './testVideoInput.component';
import VideoQualityExperimentService from './videoQualityExperiment.service';

angular
  .module('meeting')
  .service('settingsService', SettingsService)
  .service('segmentationSettingsService', SegmentationSettingsService)
  .service('videoQualityExperimentService', VideoQualityExperimentService)
  .controller('SettingsCtrl', SettingsController)
  .controller('DeviceSettingsCtrl', DeviceSettingsComponent.controller)
  .component('deviceSettings', DeviceSettingsComponent)
  .component('testAudioInput', TestAudioInputComponent)
  .component('testAudioOutput', TestAudioOutputComponent)
  .component('testVideoInput', TestVideoInputComponent);
