import { Injectable } from '@angular/core';
import { IntegrationSyncService, State } from './integration-sync.service';
import { AdminPulseService } from 'integrations/adminpulse/adminpulse.service';
import { HubSpotService } from 'integrations/hubspot/hubspot.service';
import { SmartSummaryService } from '../../../../smart-summary/smart-summary.service';


@Injectable({ providedIn: 'root' })
export class SmartSummarySyncService extends IntegrationSyncService {
  constructor(
    adminPulseService: AdminPulseService,
    hubSpotService: HubSpotService,
    private smartSummaryService: SmartSummaryService,
  ) {
    super(adminPulseService, hubSpotService);
    this.smartSummaryService.summaryGenerated$.subscribe(() => this.state = State.DIRTY);
  }

  public canSaveContent(): boolean {
    return (
      this.state === State.DIRTY
      && this.isActive()
      && this.smartSummaryService.hasSummary()
    );
  }

  public async withSyncStateManagement(syncFn: () => Promise<void>): Promise<void> {
    if (this.state !== State.DIRTY) {
      return;
    }

    this.state = State.SYNCING;
    try {
      await syncFn();
    } catch (error) {
      // We already show notifications when one of these integrations fails, but we still need to
      // know the fact that an error occurred so we can set the sync state back to dirty.
      this.state = State.DIRTY;
      return;
    }
    this.state = State.SYNCED;
  }
}
