<div class="datepicker">
  <input
    #masterInput
    class="datepicker__master-input"
    placeholder="{{ placeholder }}"
    (click)="picker.open()"
    [ngModel]="value|date:'yyyy-MM-dd'"
    (ngModelChange)="onMasterStateChanged($event)"
    (blur)="restoreFocus(picker, $event)"
    [disabled]="disabled"
    data-testid="datepickerMasterInput"
  >
  <svg-icon
    class="input__icon input__icon--right"
    [svgName]="'calendar'"
  ></svg-icon>

  <mat-form-field>
    <input
      class="datepicker__slave-input"
      matInput
      [matDatepicker]="picker"
      [value]="value"
      (dateChange)="writeValue($event.value)"
    >
    <mat-datepicker #picker (closed)="onDatePickerClose()"></mat-datepicker>
  </mat-form-field>

  <ul class="errorlist" *ngIf="shouldWarnUser">
    <li i18n>Enter a valid date (yyyy-mm-dd)</li>
  </ul>

  <ng-content></ng-content>
</div>
