import { platform } from 'utils/util';

export default class SessionNotificationsController {
  static get $inject() {
    return [
      'sessionNotificationsService',
      'siteService',
    ];
  }

  constructor(
    sessionNotificationsService,
    siteService
  ) {
    this.sessionNotificationsService = sessionNotificationsService;
    this.siteService = siteService;
  }

  timeToReset() {
    if(this.sessionNotificationsService.resetDate) {
      return platform(
        0, Math.ceil((this.sessionNotificationsService.resetDate - new Date()) / 1000)
      );
    }
  }

  get reloadCookiesNotification() {
    let linkAttrs = `href="${this.siteService.getHelpArticle('cookies')} target="_blank"`;
    let buttonAttrs = `
      class="btn btn--primary"
      ng-click="sessionNotificationsCtrl.sessionNotificationsService.reloadPage()"
    `;
    return gettextCatalog.getString(
      `
        <a {{ url }}>Make sure cookies are enabled</a> and
        <span {{ button }}>reload the page</span>
      `,
      { url: linkAttrs, button: buttonAttrs }
    );
  }

  get reloadToContinueNotification() {
    let buttonAttrs = `
      class="btn btn--primary"
      ng-click="sessionNotificationsCtrl.sessionNotificationsService.reloadPage()"
    `;
    return gettextCatalog.getString(
      `
        <p><span {{ button }}>reload the page</span> to continue using this meeting room.</p>
        <p>If you don’t need to use the room right now, we recommend to close the meeting room</p>
      `,
      { button: buttonAttrs }
    );
  }

  get reloadResetNotification() {
    let buttonAttrs = `
      class="btn btn--primary"
      ng-click="sessionNotificationsCtrl.sessionNotificationsService.reloadPage()"
    `;
    return gettextCatalog.getString(
      '<span {{ button }}>reload the page</span> to rejoin',
      { button: buttonAttrs }
    );
  }
}
