import Request from './Request';


export default class RequestService {
  static get $inject() {
    return [
      '$http',
      'notificationService',
    ];
  }

  constructor(
    $http,
    notificationService
  ) {
    this.$http = $http;
    this.notificationService = notificationService;

    this.requests = {};
    this.maxId = 0;

    ['get', 'head', 'delete'].forEach(method => {
      this[method] = function(url, argConfig) {
        let config = Object.assign({}, argConfig, {
          url: url,
          method: method.toUpperCase(),
        });
        return this.call(config);
      };
    });
    ['post', 'put', 'patch'].forEach(method => {
      this[method] = function(url, data, argConfig) {
        let config = Object.assign({}, argConfig, {
          url: url,
          data: data,
          method: method.toUpperCase(),
        });
        return this.call(config);
      };
    });
  }


  _getUniqueId() {
    return ++this.maxId;
  }


  call(config) {
    let id = this._getUniqueId();
    let request = new Request(this.$http, this.notificationService, id, config);

    let promise = request.defer.promise.finally(() => {
      delete this.requests[id];
    });
    promise.__requestId = id;
    this.requests[id] = request;

    return promise;
  }


  cancel(promise) {
    let requestId = promise.__requestId;
    let request = this.requests[requestId];
    if(request) {
      request.cancel();
      delete this.requests[requestId];
    }
  }
}
