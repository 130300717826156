import SocketService from 'meeting/angularjs/shared/communication/socket.service';
import { ConnectionType } from 'meeting/angularjs/shared/communication/socket.service';

export default class WaitingRoomSocketService extends SocketService {
  static get $inject() {
    return [
      'requestUserService',
    ].concat(SocketService.$inject);
  }

  constructor(
    requestUserService,
    ...args
  ) {
    super(...args);

    this.requestUserService = requestUserService;
  }


  _getWsSearchParms() {
    let searchParams = new URLSearchParams();
    searchParams.set('type', ConnectionType.WAITING_ROOM);
    return searchParams;
  }
}
