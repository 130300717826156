import { Component, Inject } from '@angular/core';
import { State as RecorderState } from 'meeting/angularjs/components/recording/Recorder';
import { SmartSummaryService } from '../../../../smart-summary/smart-summary.service';
import { ViewOrganizationService } from 'utils/view-organization.service';
import { RequestUserService } from 'utils/requestUser.service';
import {
  SubscriptionStatus,
  hasChargebeeSubscription
} from 'subscription/models';

@Component({
  selector: 'notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss'],
})
export class NotesModalComponent {
  private showDuringRecording = false;
  public isMaximized = false;

  constructor(
    private smartSummaryService: SmartSummaryService,
    private viewOrganizationService: ViewOrganizationService,
    private requestUserService: RequestUserService,
    @Inject('recorderService') private recorderService,
  ) {
  }

  public get canUseSmartSummary(): boolean {
    return this.smartSummaryService.canUseSmartSummary;
  }

  public get showNotes(): boolean {
    return this.recorderService.state !== RecorderState.RECORDING || this.showDuringRecording;
  }

  public setShowDuringRecording(): void {
    this.showDuringRecording = true;
  }

  toggleSize(): void {
    this.isMaximized = !this.isMaximized;
  }

  public get shouldShowTrialBanner(): boolean {
    return (
      this.canUseSmartSummary
      && this.requestUserService.user.isAdmin
      && this.requestUserService.user.organizationId === this.viewOrganizationService.organization.id  // eslint-disable-line max-len
      && this.viewOrganizationService.organization.subscription.status === SubscriptionStatus.TRIAL
      && !hasChargebeeSubscription(this.viewOrganizationService.organization.subscription)
    );
  }
}
