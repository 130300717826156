import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { UtilsModule } from 'utils/utils.module';

import { NgModule } from '@angular/core';
import { ButtonPreviewModule } from '../button-preview';
import { LinkedItemPreviewModule } from '../linked-item-preview';
import { ContactWidgetSettingsComponent } from './contact-widget-settings';
import { ContactWidgetConfigComponent } from './contact-widget-config.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,

    ButtonPreviewModule,
    LinkedItemPreviewModule,
  ],
  declarations: [
    ContactWidgetConfigComponent,
    ContactWidgetSettingsComponent,
  ],
  exports: [
    ContactWidgetConfigComponent,
  ],
})
export class ContactWidgetConfigModule {}
