import { NgModule } from '@angular/core';

import { DialogModule } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '../svg-icon';
import { ModalTriggerDirective } from './modal-trigger.directive';
import { ModalComponent } from './modal.component';
import { ModalCloseDirective } from './modal-close.directive';
import { ModalService } from './modal.service';


@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    SvgIconModule,
    PortalModule,
  ],
  declarations: [
    ModalComponent,
    ModalTriggerDirective,
    ModalCloseDirective,
  ],
  providers: [
    ModalService,
  ],
  exports: [
    ModalTriggerDirective,
    ModalCloseDirective,
  ],
})
export class ModalModule {}
