import Model from 'utils/models';
import { Organization } from 'organization/models/Organization';
import * as fields from 'utils/fields';

export class IntegrationConfig extends Model {
  static get fields() {
    return {
      organization: new fields.RelatedField({
        Model: Organization,
        readOnly: true,
      }),
      isExpired: new fields.BooleanField(),
      isActive: new fields.BooleanField(),
    };
  }


  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers) {
    throw Error('An IntegrationConfig does not have a list endpoint');
  }

  // eslint-disable-next-line no-unused-vars
  static readPath(identifiers) {
    throw Error('A bare IntegrationConfig does not have a read path.');
  }

  // eslint-disable-next-line no-unused-vars
  static updatePath(identifiers) {
    throw Error('A bare IntegrationConfig does not have an update path.');
  }


  get disconnectPath() {
    throw Error('A bare IntegrationConfig does not have a disconnect path.');
  }

  disconnect() {
    let apiConfig = this._buildFullApiOptions();
    return this.apiService.post(this.disconnectPath, apiConfig);
  }
}
