import { Directive, ElementRef, HostListener, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[character-count]'
})
export class CharacterCountDirective implements OnInit {
  private countDisplay: any;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input')
  onInputChange() {
    this.renderer.setProperty(this.countDisplay, 'textContent', this.text);
  }

  ngOnInit():any {
    this.countDisplay = this.renderer.createElement('div');

    this.renderer.addClass(this.countDisplay, 'text-muted');
    this.renderer.addClass(this.countDisplay, 'mt-1');
    this.renderer.addClass(this.countDisplay, 'text-right');

    const text = this.renderer.createText(this.text);
    this.renderer.appendChild(this.countDisplay, text);
    this.renderer.appendChild(this.elementRef.nativeElement.parentElement, this.countDisplay);
  }

  get text(): string {
    return (
      `${$localize `Characters`}: ${this.elementRef.nativeElement.value.length}/`
      + `${this.elementRef.nativeElement.maxLength}`
    );
  }
}
