import { Component, Inject } from '@angular/core';
import { ContactForm } from '../models/ContactForm';


@Component({
  selector: 'contact-form-public',
  templateUrl: './contact-form-public.component.html',
  styleUrls: ['./contact-form-public.component.scss'],
})
export class ContactFormPublicComponent {
  public contactForm: ContactForm;

  constructor(
    @Inject('modelFactory') private modelFactory,
  ) {
    this.contactForm = this.modelFactory.createInstance(ContactForm, {
      data: ANGULAR_SCOPE.contactForm,
    });
  }

  get isEmbedded() {
    return ANGULAR_SCOPE.isEmbedded;
  }

  get isContactWidgetEmbedded() {
    const search = new URLSearchParams(window.location.search);
    return search.has('contactWidgetEmbedded');
  }
}
