import TooltipService from './tooltip.service';
import TooltipsComponent from './tooltips.component';
import TooltipDirective from './tooltip.directive';


angular
  .module('vecteraUtil')
  .service('tooltipService', TooltipService)
  .component('tooltips', TooltipsComponent)
  .directive('tooltip', TooltipDirective);
