
import { Component, Input } from '@angular/core';

@Component({
  selector: 'go-back-header',
  templateUrl: './go-back-header.component.html',
  styleUrls: ['./go-back-header.component.scss'],
})
export class GoBackHeaderComponent {
  @Input() url!: string;
  @Input() headerText!: string;
}
