
<div class="d-flex">
  <input
    type="text"
    class="input"
    placeholder="{{ defaultColor }}"
    [ngModel]="value"
    (ngModelChange)="onInputChange($event)"
    [disabled]="disabled"
    (blur)="onBlur()"
  >

  <input
    type="color"
    class="input input--color-picker"
    [style.--picker-color]="pickerColor"
    placeholder="{{ defaultColor }}"
    [ngModel]="pickerColor"
    (ngModelChange)="onInputChange($event)"
    [disabled]="disabled"
  >
</div>
