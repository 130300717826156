import template from './videoViewportMiniature.html?raw';

import { DragListener, Rect } from 'utils/util';
import { PTZ_MAX_ZOOM_LEVEL } from './ViewportTileMixin';


const SCALE = 0.15;
const PTZ_SCALE = 0.25;
// When the camera is zoomed in to PTZ_MAX_ZOOM_LEVEL, we will render it as if it were zoomed in
// to PTZ_MAX_ZOOM_SCALE. Otherwise, the miniature becomes tiny.
const PTZ_MAX_ZOOM_SCALE = 2;

/**
 * Adds a miniature representation of the current PTZ settings of the stream to a tile
 *
 * This representation additionally allows for controlling the PTZ settings of
 * the stream by interacting with it (clicking and dragging); after drawying the new location
 * of the viewport in the miniature, the corresponding location of the viewport in the global
 * image is calculated and the camera is moved to that location.
 *
 * The size of the miniature container is defined by the fine tilt and pan range of the camera,
 * every possible step being 1 pixel. A camera with 340 pan steps and 60 tilt steps will have a
 * miniature of 340x60 pixels. The size of the viewport window is half of the smallest dimension
 * when the camera is fully zoomed out.
 */
class VideoViewportMiniatureComponent {
  static get $inject() {
    return [
      '$scope',
      '$element',
    ];
  }

  constructor(
    $scope,
    $elem
  ) {
    this._bind();

    this.$scope = $scope;
    this.$elem = $elem;

    this.$elemContainer = null;
    this.$elemTile = null;

    this.dragListener = null;
    this.isDragging = false;
    this.offsetOnStartDrag = null;

    this.scale = SCALE;
    this.rectContainer = new Rect();
    this.rect = new Rect();
  }

  _bind() {
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onStartDrag = this._onStartDrag.bind(this);
    this._onDrag = this._onDrag.bind(this);
    this._onStopDrag = this._onStopDrag.bind(this);
    this._draw = this._draw.bind(this);
  }


  $onInit() {
    this.$elemContainer = this.$elem.find('.video-viewport__miniature-container');
    this.$elemTile = this.$elem.find('.video-viewport__miniature-tile');

    this.$elemContainer.on('mousedown touchstart', this._onMouseDown);

    this.dragListener = new DragListener(this.$elemContainer[0], {
      onStart: this._onStartDrag,
      onDrag: this._onDrag,
      onStop: this._onStopDrag,
      stopPropagation: true,
      preventDefault: true,
    });

    this.tile.on('viewport draw', this._draw);
  }

  $onDestroy() {
    this.tile.off('viewport draw', this._draw);
  }


  get shouldShow() {
    return this.tile.isViewportDraggable;
  }


  _onMouseDown($event) {
    if(!this.tile.isViewportDraggable) {
      return;
    }
    let stream = this.tile.streams.video;
    if(!stream) {
      return;
    }

    // If the user clicks in the container, but next to the tile: move the tile to where they
    // clicked.
    if($event.target === this.$elemContainer[0] && $event.which === 1) {
      let offset;
      if(stream.supportsPTZ) {
        offset = {
          x: ($event.offsetX / this.scale - this.tile.PTZHorizontalFOV / 2) / this.tile.PTZPanRange,  // eslint-disable-line max-len
          y: ($event.offsetY / this.scale - this.tile.PTZVerticalFOV   / 2) / this.tile.PTZTiltRange,  // eslint-disable-line max-len
        };
        if(this.tile.isViewportMirrored) {
          offset.x = 1 - offset.x;
        }
      } else {
        offset = {
          x: ($event.offsetX - this.rect.width  / 2) / this.rectContainer.width,
          y: ($event.offsetY - this.rect.height / 2) / this.rectContainer.height,
        };
        if(this.tile.isViewportMirrored) {
          offset.x = 1 - 1 / this.tile.zoomLevel - offset.x;
        }
      }

      this.tile.setOffset(offset);
      this.$elemContainer.focus();  // TODO: why?
      this.dragListener._onMouseDown($event);
    }
  }


  _onStartDrag() {
    if(!this.tile.isViewportDraggable) {
      return;
    }

    this.isDragging = true;
    this.offsetOnStartDrag = Object.assign({}, this.tile.offset);
  }


  _onDrag(diff) {
    if(!this.offsetOnStartDrag) {
      return;
    }
    let stream = this.tile.streams.video;
    if(!stream) {
      return;
    }

    diff = diff[0];
    if(this.tile.isViewportMirrored) {
      diff = {
        x: -diff.x,
        y: diff.y,
      };
    }

    let offset;
    if(stream.supportsPTZ) {
      offset = {
        x: this.offsetOnStartDrag.x + diff.x / this.scale / this.tile.PTZPanRange,
        y: this.offsetOnStartDrag.y + diff.y / this.scale / this.tile.PTZTiltRange,
      };
    } else {
      offset = {
        x: this.offsetOnStartDrag.x + diff.x / this.rectContainer.width,
        y: this.offsetOnStartDrag.y + diff.y / this.rectContainer.height,
      };
    }
    this.tile.setOffset(offset);
  }

  _onStopDrag() {
    // $timeout is necessary to prevent jumping when releasing mouse while continuing dragging
    $timeout(() => {
      this.isDragging = false;
    });
  }


  _draw() {
    if(!this.shouldShow) {
      return;
    }

    let stream = this.tile.streams.video;
    if(stream.supportsPTZ) {
      // The width and height of the global image in arbitrary pan/tilt units
      let range = {
        x: this.tile.PTZPanRange  + this.tile.PTZHorizontalFOV,
        y: this.tile.PTZTiltRange + this.tile.PTZVerticalFOV,
      };
      // The ratio between the pixel size of the container and the arbitrary pan/tilt units
      this.scale = Math.max(
        this.tile.rect.width  * PTZ_SCALE / range.x,
        this.tile.rect.height * PTZ_SCALE / range.y
      );
      this.rectContainer = new Rect({
        width:  range.x * this.scale,
        height: range.y * this.scale,
      });

      let renderedZoomLevel = (
        (this.tile.zoomLevel - 1)
        / (PTZ_MAX_ZOOM_LEVEL - 1)
        * (PTZ_MAX_ZOOM_SCALE - 1)
        + 1
      );
      let width  = this.tile.PTZHorizontalFOV * this.scale / renderedZoomLevel;
      let height = this.tile.PTZVerticalFOV   * this.scale / renderedZoomLevel;
      this.rect = new Rect({
        width: width,
        height: height,
        left: (this.tile.PTZPanRange  * this.tile.offset.x + this.tile.PTZHorizontalFOV / 2) * this.scale - width  / 2,  // eslint-disable-line max-len
        top:  (this.tile.PTZTiltRange * this.tile.offset.y + this.tile.PTZVerticalFOV   / 2) * this.scale - height / 2,  // eslint-disable-line max-len
      });

    } else {
      this.scale = SCALE;
      this.rectContainer = new Rect({
        width:  this.tile.rect.width  * this.scale,
        height: this.tile.rect.height * this.scale,
      });
      this.rect = new Rect({
        width:  this.tile.rect.width  * this.scale / this.tile.zoomLevel,
        height: this.tile.rect.height * this.scale / this.tile.zoomLevel,
        left:   this.tile.rect.width  * this.scale * this.tile.offset.x,
        top:    this.tile.rect.height * this.scale * this.tile.offset.y,
      });
    }

    if(this.tile.isViewportMirrored) {
      this.rect.left = this.rectContainer.width - this.rect.left - this.rect.width;
    }

    this.$elemContainer.css({
      width:  Math.round(this.rectContainer.width),
      height: Math.round(this.rectContainer.height),
    });
    this.$elemTile.css({
      left:   Math.round(this.rect.left),
      top:    Math.round(this.rect.top),
      width:  Math.round(this.rect.width),
      height: Math.round(this.rect.height),
    });
  }
}



export default {
  controller: VideoViewportMiniatureComponent,
  controllerAs: 'videoViewportMiniatureCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
