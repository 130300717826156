import templateDropdown from './knockerChat.dropdown.html?raw';
import { DROPDOWN_ID as DROPDOWN_ID_EMOJI } from '../common/emoji.service';


const DROPDOWN_ID = 'knockerChat';


export default class KnockerChatService {
  static get $inject() {
    return [
      'dropdownService',
      'chatBoxService',
    ];
  }

  constructor(
    dropdownService,
    chatBoxService
  ) {
    this._bind();
    this.dropdownService = dropdownService;
    this.chatBoxService = chatBoxService;

    this.requestedChatBox = null;
    this.scrollTops = {};

    dropdownService.register(
      DROPDOWN_ID,
      templateDropdown,
      { cssClasses: 'dropdown-deprecated--no-padding', embeds: [DROPDOWN_ID_EMOJI] });
    this.dropdown = dropdownService.get(DROPDOWN_ID);

    dropdownService.on('inactive', this._onInactive);
  }

  _bind() {
    this._onInactive = this._onInactive.bind(this);
  }


  get chatBox() {
    return this.dropdownService.get(DROPDOWN_ID).active && this.requestedChatBox;
  }


  toggle(chatBox, $event, show) {
    this.requestedChatBox = chatBox;
    if(show == null) {
      this.dropdownService.toggle(DROPDOWN_ID, $event);
    } else if(show) {
      this.dropdownService.show(DROPDOWN_ID, $event);
    } else {
      this.dropdownService.hide(DROPDOWN_ID, $event);
    }

    if(!this.dropdown.active) {
      return;
    }

    $timeout(() => {
      if(chatBox !== this.requestedChatBox) {
        return;
      }

      let $elem = this.dropdown.$elem || angular.element();
      $elem.find('textarea').focus();
      if(!chatBox.glued && chatBox.id in this.scrollTops) {
        $elem.find('.chat__message-list').scrollTop(this.scrollTops[chatBox.id]);
      }
    });
  }


  _onInactive(dropdown) {
    if(
      dropdown !== this.dropdown
      || !dropdown.$elem
      || !this.requestedChatBox
      || !this.requestedChatBox.glued
    ) {
      return;
    }

    let $elemMessageList = dropdown.$elem.find('.chat__message-list');
    this.scrollTops[this.requestedChatBox.id] = $elemMessageList.scrollTop();
  }
}
