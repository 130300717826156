import JanusHandleManager from './JanusHandleManager';


export default class JanusHandleManagerFactory {
  static get $inject() {
    return [
      '$injector',
    ];
  }

  constructor(
    $injector
  ) {
    this.$injector = $injector;
  }

  create(...args) {
    const dependencies = JanusHandleManager.$inject.map(
      dependency => this.$injector.get(dependency)
    );
    const instance = new JanusHandleManager(...dependencies, ...args);
    return instance;
  }
}
