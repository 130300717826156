<div class="position-relative d-flex align-items-center">
  <profile-images
    class="combo-box__label-profile-image"
    *ngIf="selectedOption && shouldShowProfileImage(selectedOption)"
    [users]="[selectedOption.value]"
    [size]="24"
  ></profile-images>
  <input
    class="combo-box"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (focus)="focusInput($event)"
    [(ngModel)]="inputString"
    [placeholder]="placeholder"
    (ngModelChange)="onQueryChange()"
    [disabled]="disabled"
    [class.combo-box--clearable]="clearable && !!inputString"
    [class.combo-box--has-profile-image]="selectedOption && shouldShowProfileImage(selectedOption)"
  >
  <div *ngIf="clearable && !disabled && !!inputString" class="combo-box__clear-button" (click)="!disabled && clearInput()" data-testid="comboBoxClearButton"></div>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayInheritWidth
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayDisableClose]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  (backdropClick)="onBackdropClick()"
>
  <div class="dropdown dropdown--scrollable" style="max-height: 500px">
    <div class="dropdown__list-item disabled" *ngIf="filteredCategories.length === 0" i18n>No options found</div>
    <div
      *ngFor="let category of filteredCategories; let categoryIndex = index"
    >
      <div *ngIf="category.label" class="dropdown__list-category">{{ category.label }}</div>
      <div *ngIf="!category.label && categoryIndex > 0" class="dropdown__list-divider"></div>

      <div class="dropdown__list-item disabled" *ngIf="category.options.length === 0" i18n>No options</div>

      <!--
        When an option is clicked, this typically causes the input element to be blurred.
        When this happens, no "click" event is fired on the option, so we need to listen
        on mouseup instead.
      -->
      <div
        *ngFor="let option of category.options"
        (mouseup)="pickOption(option)"
        data-testid="comboBoxOption"
      >
        <div
          class="dropdown__list-item"
          [class.disabled]="option.disabled"
          *ngIf="option.requiresUpgrade"
        >
          <pro-feature-lock
            tooltip="Upgrade your subscription to access this feature"
            i18n-tooltip
          ></pro-feature-lock>
          <pro-feature-badge
            [featureText]="option.label"
            i18n-featureText
            [showIcon]="false"
          >
          </pro-feature-badge>
        </div>

        <div
          [attr.data-testid]="optionToTestId(option)"
          class="dropdown__list-item"
          [class.disabled]="option.disabled"
          *ngIf="!option.requiresUpgrade"
        >
          <svg-icon
            *ngIf="option.icon"
            class="dropdown__list-icon dropdown__list-icon--sm"
            [svgIcon]="option.icon"
          ></svg-icon>
          <profile-images
            class="mr-2"
            *ngIf="!option.icon && shouldShowProfileImage(option)"
            [users]="[option.value]"
          ></profile-images>
          <span>{{ option.label }}</span>
          <help-tooltip
            *ngIf="option.disabled && option.disabledHelpText"
            [text]="option.disabledHelpText"
            i18n-text
          ></help-tooltip>
        </div>
      </div>
    </div>
  </div>
</ng-template>
