import errors from '../util/errors';

export default function DebounceFactory() {

  return function debounce(callback, delay) {
    if(typeof callback !== 'function') {
      throw new errors.InvalidArgumentError('callback must be a function');
    }
    if(delay == null) {
      delay = 0;
    }

    let that, args, timeoutId, lastCall = 0;

    function exec() {
      timeoutId = null;

      if(lastCall) {
        timeoutId = $timeout(exec, lastCall + delay - Date.now());
      } else {
        callback.apply(that, args);
      }

      lastCall = 0;
    }


    let fn = function debounced() {
      that = this;  // eslint-disable-line no-invalid-this
      args = arguments;

      if(timeoutId) {
        lastCall = Date.now();
      } else {
        timeoutId = $timeout(exec, delay);
      }
    };

    fn.cancel = function cancel() {
      if(timeoutId) {
        $timeout.cancel(timeoutId);
      }
      timeoutId = null;
      lastCall = null;
    };

    return fn;
  };
}
