import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive, EventEmitter, HostListener, Input, Output, TemplateRef, ViewContainerRef
} from '@angular/core';
import { SvgIcon } from '../svg-icon';
import { DialogConfig } from './dialog.component';
import { DialogService } from './dialog.service';


/**
 * Create a dialog to confirm an action.
 *
 * This was inspired by https://ahoy.teamleader.design/3547946e7/p/23b7a1-dialogs.
 *
 * Typical usage:
 *
 * ```
 * <div
 *   class="btn btn--error"
 *   dialog="Are you sure you want to delete this item?"
 *   dialogTitle="Delete item"
 *   dialogIcon="{ name: 'trash' }
 *   dialogConfirmText="Delete"
 *   dialogConfirmButtonClass="btn--error"
 * >
 *   Delete
 * </div>
 * ```
 */
@Directive({
  selector: '[dialog]',
  exportAs: 'dialog',
})
export class DialogDirective {
  /** The content of the dialog. Either a plain text string, or a template reference. */
  @Input('dialog') dialogContent!: TemplateRef<any> | string;
  /** The icon used in the header of the dialog. */
  @Input() dialogIcon?: SvgIcon;
  /** The title of the dialog */
  @Input() dialogTitle = '';
  /** The text in the cancel button. */
  @Input() dialogCancelText = $localize `Cancel`;
  /** The text in the confirm button. */
  @Input() dialogConfirmText = $localize `Confirm`;
  /** Classes to add to the confirm button, in addition to the hardcoded `btn`. */
  @Input() dialogConfirmButtonClass = 'btn--primary';
  /** Can be used to dynamically disable a dialog. */
  @Input() dialogDisabled = false;

  /** Emitted when the user clicks the confirm button inside the dialog. */
  @Output() dialogConfirmed = new EventEmitter<void>();
  /** If using the `dialogConfirmed` output is not possible (e.g. because the trigger element
   * will be deleted by the time the `dialogConfirmed` event is emitted), you can use this callback
   * instead.
   */
  @Input() dialogConfirmedCallback?: () => any;

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
  ) {}


  @HostListener('click')
  onClick() {
    if(!this.dialogDisabled) {
      this.openModal();
    }
  }


  private openModal() {
    const config: Partial<DialogConfig> = {};
    if(this.dialogIcon !== undefined) {
      config.icon = this.dialogIcon;
    }
    if(this.dialogTitle !== undefined) {
      config.title = this.dialogTitle;
    }
    if(this.dialogCancelText !== undefined) {
      config.cancelText = this.dialogCancelText;
    }
    if(this.dialogConfirmText !== undefined) {
      config.confirmText = this.dialogConfirmText;
    }
    if(this.dialogConfirmButtonClass !== undefined) {
      config.confirmButtonClass = this.dialogConfirmButtonClass;
    }

    if(this.dialogContent instanceof TemplateRef) {
      config.templatePortal = new TemplatePortal(this.dialogContent, this.viewContainerRef);
    } else {
      config.text = this.dialogContent;
    }

    const dialogRef = this.dialogService.open(config);
    dialogRef.closed.subscribe(value => {
      if(value) {
        if(this.dialogConfirmedCallback) {
          this.dialogConfirmedCallback();
        }
        this.dialogConfirmed.emit();
      }
    });
  }
}
