import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SvgIconModule } from 'utils/ui-components/svg-icon';
import { DatepickerComponent } from './datepicker.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    SvgIconModule,
  ],
  declarations: [
    DatepickerComponent,
  ],
  exports: [
    DatepickerComponent,
  ],
})
export class DatepickerModule {}
