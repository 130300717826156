import { bind, interval } from 'utils/util';

const SEND_INTERVAL = 5000;
const RESET_TIMEOUT = SEND_INTERVAL * 2.5;


export default class RaiseHandService {
  static get $inject() {
    return [
      'userService',
      'meetingBroadcastService',
      'meetingService',
      'shortcutService',
    ];
  }

  constructor(
    userService,
    meetingBroadcastService,
    meetingService,
    shortcutService
  ) {
    bind(this);

    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.shortcutService = shortcutService;

    this.raisedSessions = new Set();
    this.raisedUsers = new Set();
    this.sendInterval = null;
    this.resetTimeouts = new Map();

    this.meetingBroadcastService.on('raise-hand', this._onBroadcast, false);
    shortcutService.on('alt+h', this.toggle);
  }


  get shouldShowOverlay() {
    return this.meetingService.bednetLocation === this.meetingService.BednetLocation.CLASSROOM;
  }

  get isLocalRaised() {
    return this.raisedSessions.has(this.userService.mySession);
  }



  /***************************
   * Add/remove raised hands *
   ***************************/

  toggle() {
    this.isLocalRaised ? this.lower() : this.raise();
  }

  raise() {
    this._setLocal(this.userService.mySession, true);
  }

  lower(session) {
    if(session == null) {
      session = this.userService.mySession;
    }
    this._setLocal(session, false);
  }

  lowerUser(user) {
    [...this.raisedSessions]
      .filter(session => session.user === user)
      .forEach(session => this._setLocal(session, false));
  }


  _setLocal(session, isRaised) {
    this._set(session, isRaised);
    this._send(session, isRaised);
  }


  _set(session, isRaised) {
    if(isRaised) {
      this.raisedSessions.add(session);
    } else {
      this.raisedSessions.delete(session);
    }
    this._updateRaisedUsers();

    session.isLocal ?
      this._updateLocalInterval(isRaised) :
      this._updateRemoteInterval(session, isRaised);
  }


  _updateLocalInterval(isRaised) {
    interval.clearInterval(this.sendInterval);
    this.sendInterval = null;
    if(isRaised) {
      this.sendInterval = interval.setInterval(this._sendMyHand, SEND_INTERVAL);
    }
  }


  _updateRemoteInterval(session, isRaised) {
    $timeout.cancel(this.resetTimeouts.get(session));
    this.resetTimeouts.delete(session);
    if(isRaised) {
      this.resetTimeouts.set(session, $timeout(() => this._set(session, false), RESET_TIMEOUT));
    }
  }


  _updateRaisedUsers() {
    this.raisedUsers = new Set(
      Array.from(this.raisedSessions).map(session => session.user)
    );
  }



  /*****************
   * Communication *
   *****************/

  _sendMyHand() {
    this._send(this.userService.mySession, this.isLocalRaised);
  }


  _send(session, isRaised) {
    this.meetingBroadcastService.send('raise-hand', false, [], isRaised, session.id);
  }


  _onBroadcast(channel, session, datetime, isRaised, forSessionId) {
    let forSession = forSessionId == null ?
      session :
      this.userService.sessions[forSessionId];
    if(forSession) {
      this._set(forSession, isRaised);
    }
  }
}
