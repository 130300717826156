import template from './profileImage.html?raw';
import { ColorManager } from 'utils/util';

const colorManager = new ColorManager();
let colorMapping = {};

/**
 * ColorMode FIXED will set the profile image background color to the color that has been passed
 * as input to the component.
 * ColorMode AUTO will assign an unused color to the user. This way we can consistently use
 * the same color for all users on the page.
 */
export const ColorMode = Object.freeze({
  AUTO: 'auto',
  FIXED: 'fixed',
});

class ProfileImageController {
  static get $inject() {
    return [
      '$element',
      'requestUserService',
    ];
  }

  constructor(
    $elem,
    requestUserService
  ) {
    this.$elem = $elem;
    this.requestUserService = requestUserService;
  }

  get user() {
    if(this._user == null) {
      return this.requestUserService.user;
    } else {
      return this._user;
    }
  }

  get size() {
    if(this._size == null) {
      return 30;
    } else {
      return this._size;
    }
  }

  get color() {
    if(this.colorMode === ColorMode.FIXED) {
      return this._color;
    }

    if(!colorMapping[this.user.id]) {
      colorMapping[this.user.id] = colorManager.getColor(colorManager.claimColorIndex());
    }
    return colorMapping[this.user.id];
  }

  get colorMode() {
    if(this._color) {
      return ColorMode.FIXED;
    }

    if(!this._colorMode) {
      return ColorMode.AUTO;
    }
    return this._colorMode;
  }
}


export default {
  controller: ProfileImageController,
  controllerAs: 'profileImageCtrl',
  template,

  bindings: {
    '_user': '<user',
    '_size': '<size',
    '_color': '<color',
    '_colorMode': '<colorMode',
  },
};
