import { Component, OnInit } from '@angular/core';
import {
  AppointmentTypesService,
  AppointmentType,
  User
} from '../../services/appointment-types.service';
import { ViewOrganizationService } from 'utils/view-organization.service';

@Component({
  selector: 'booking-container',
  templateUrl: './booking.container.html',
})
export class BookingContainer implements OnInit {
  appointmentTypes: AppointmentType[] = [];
  isTeamBooking: boolean;
  typeSlug: string | null;
  appointmentHost = '';

  private loadingAppointmentHost = true;
  private loadingAppointments = true;

  get loading(): boolean {
    return this.loadingAppointmentHost || this.loadingAppointments;
  }

  constructor(
    private appointmentTypesService: AppointmentTypesService,
    private viewOrganizationService: ViewOrganizationService,
  ) {
    this.isTeamBooking = ANGULAR_SCOPE.hostId === null;
    this.typeSlug = ANGULAR_SCOPE.typeSlug;
  }

  ngOnInit(): void {
    this.initializeAppointmentHost();
    this.loadAppointmentTypes();
  }

  private initializeAppointmentHost(): void {
    if(this.isTeamBooking) {
      this.appointmentHost = this.viewOrganizationService.organization.name;
      this.loadingAppointmentHost = false;
    } else {
      this.appointmentTypesService.getAppointmentHost(ANGULAR_SCOPE.hostId).subscribe(
        (host: User) => {
          this.appointmentHost = host.fullName;
          this.loadingAppointmentHost = false;
        }
      );
    }
  }

  private loadAppointmentTypes(): void {
    const appointmentTypesSubscription = this.isTeamBooking ?
      this.appointmentTypesService.getOrganisationPublicAppointmentTypes(
        this.viewOrganizationService.organization.id, this.typeSlug) :
      this.appointmentTypesService.getUserPublicAppointmentTypes(
        ANGULAR_SCOPE.hostId, this.typeSlug);

    appointmentTypesSubscription.subscribe((appointmentTypes: AppointmentType[]) => {
      this.appointmentTypes = appointmentTypes;
      this.loadingAppointments = false;
    });
  }
}
