<div class="contact-form-config-question" cdkTrapFocus [cdkTrapFocusAutoCapture]="shouldAutoCapture">
   <div class="flex-1">
    <div class="mb--4">
      <div class="form-group mb-4 flex-1">
        <label for="label-input" i18n>Question</label>
        <input
          data-testid="contactFormConfigQuestionInput"

          class="input"
          id="label-input"
          name="label"
          [(ngModel)]="question.label"
          [withSettingsErrors]="errors.label"

          placeholder="Write your question here…"
          i18n-placeholder

          cdkFocusInitial
          [disabled]="!hasPermissionToEdit"
        />
      </div>

      <div class="form-group mb-4">
        <label i18n>Type</label>
        <combo-box
          data-testid="contactFormConfigQuestionType"

          *ngIf="!isVecteraDefault"
          [(ngModel)]="question.type"
          (ngModelChange)="setQuestionType(question.type)"
          [options]="questionTypeOptions"
          [disabled]="question.isDefault || !hasPermissionToEdit"

          [withSettingsErrors]="errors.type"
        ></combo-box>

        <!-- Placeholder so that people can still see which Vectera field this maps to, even when
          they have changed the question name -->
        <select
          *ngIf="isVecteraDefault"
          class="select"
          disabled
        >
          <option>{{ vecteraDefaultName }}</option>
        </select>

        <ng-template #extraInput></ng-template>
      </div>



      <div class="form-group mb-4">
        <input
          type="checkbox"
          class="checkbox"
          id="required"
          [(ngModel)]="question.required"
          [disabled]="question.isDefault || !hasPermissionToEdit"
          data-testid="answerRequiredCheckbox"
        />
        <label for="required" class="checkbox__label" i18n data-testid="answerRequiredLabel">An answer is required </label>
      </div>

      <div class="form-group mb-4" *ngIf="showTeamleaderFieldMapping">
        <div class="label d-flex align-items-center">
          <svg-icon [svgPath]="'illustrations__18x18_teamleader_badge'" class="d-inline-flex"></svg-icon>
          <span class="ml-2" i18n>Link answer to Teamleader Focus</span>
          <svg-icon
            class="icon icon--sm clickable ml-1"
            [svgName]="'info_badged'"
            [svgStyle]="'filled'"
            [svgSize]="14"

            [modalTriggerFor]="tipsModal"
            [modalClass]="'modal--sm'"
            [modalIcon]="{name: 'info_badged'}"
            modalTitle="Linking answers to Teamleader Focus"
            i18n-modalTitle
          ></svg-icon>
        </div>

        <combo-box
          [(ngModel)]="teamleaderMappedField"
          [options]="teamleaderMappingFieldComboBoxCategories"
          [withSettingsErrors]="teamleaderMappingFieldErrors"
          [disabled]="question.isDefault || !hasPermissionToEdit"
        ></combo-box>
      </div>
    </div>
  </div>

  <preview
    class="contact-form-config-question__preview"
    [ngClass]="{'contact-form-config-question__preview--allow-pointer': question.type === QuestionType.DROPDOWN || question.type === QuestionType.LANGUAGE}"
  >
    <contact-form-question [question]="question"></contact-form-question>
  </preview>
</div>


<ng-template #tipsModal>
  <h5 i18n>How linking works</h5>
  <div class="d-flex flex-column-reverse flex-sm-row">
    <div class="flex-1">
      <ol>
        <li i18n>You link a question to a Teamleader Focus field, e.g. Contact – Phone number.</li>
        <li i18n>Teamleader Focus creates a new contact and completes the phone number.</li>
      </ol>
    </div>

    <img [src]="urlService.images.questionMappingHelp" />
  </div>

  <div class="d-flex justify-content-end mt-6">
    <div class="btn btn--primary" [modalClose]>
      <svg-icon class="btn__icon" [svgName]="'checkmark'"></svg-icon>
      <span i18n>Ok, got it</span>
    </div>
  </div>
</ng-template>
