import { bind, object } from 'utils/util';
import Tile from '../Tile';
import template from './tiles.html?raw';


class TilesController {
  static get $inject() {
    return [
      '$element',
      'meetingBroadcastService',
      'userService',
      'tileService',
      'tileRendererDesktopService',
      'sharedPointerService',
      'userStreamService',
    ];
  }

  constructor(
    $elem,
    meetingBroadcastService,
    userService,
    tileService,
    tileRendererDesktopService,
    sharedPointerService,
    userStreamService
  ) {
    bind(this);

    this.$elem = $elem;
    this.meetingBroadcastService = meetingBroadcastService;
    this.userService = userService;
    this.tileService = tileService;
    this.tileRendererDesktopService = tileRendererDesktopService;
    this.sharedPointerService = sharedPointerService;
    this.userStreamService = userStreamService;

    this.initialized = true;

    meetingBroadcastService.afterInitialization().then(() => {
      if(!this.destroyed) {
        // Let other afterInitialization() listeners finish first to ensure smoother rendering
        $timeout(() => {
          tileRendererDesktopService.setElem($elem);
          this.initialized = false;
        });
      }
    });

    if(window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this._onResize);
    }
  }


  $onInit() {
    if(this.resizeObserver) {
      this.resizeObserver.observe(this.$elem[0]);
    }
  }

  $onDestroy() {
    if(this.resizeObserver) {
      this.resizeObserver.unobserve(this.$elem[0]);
    }
  }


  get tiles() {
    if(this.initializing) {
      return [];
    } else if(this.userService.mySession.isSpectator) {
      return object.filter(this.tileService.tiles, (id, tile) => {
        return tile.category === Tile.Category.PEOPLE || tile.active;
      });
    } else {
      return this.tileService.tiles;
    }
  }


  _onResize() {
    this.tileRendererDesktopService.draw();
  }
}

export default {
  controller: TilesController,
  controllerAs: 'tilesCtrl',
  template,
};
