import './angularjs.module';

import { NgModule } from '@angular/core';
import { ButtonPreviewModule } from './button-preview';
import { ContactWidgetConfigModule } from './contact-widget-config';
import { ContactWidgetsModule } from './contact-widgets';
import { LinkedItemPreviewModule } from './linked-item-preview';


@NgModule({
  exports: [
    ButtonPreviewModule,
    ContactWidgetsModule,
    ContactWidgetConfigModule,
    LinkedItemPreviewModule,
  ],
})
export class ContactWidgetModule {}
