import template from './userTile.html?raw';
import templateModalKickOut from './kickOut.modal.html?raw';

import TileController from '../tiles/tile.controller';
import ViewportTileControllerMixin from '../videoViewport/ViewportTileControllerMixin';
import { StreamType } from  'meeting/meeting-room/stream';
import { Hint } from '../../main/tutorial.service';

export class FooterSize {
  constructor(
    maxTileHeight,
    fontSize,
    iconSize,
    logoHeight,
    iconPadding,
    iconMargin,
    logoPadding
  ) {
    if(iconPadding == null) {
      iconPadding = iconSize / 4;
    }
    if(iconMargin == null) {
      iconMargin = iconPadding / 2;
    }

    if(logoPadding == null) {
      logoPadding = logoHeight / 3;
    }

    let footerHeight = iconSize + 2 * iconPadding + 2 * iconMargin;

    this.maxTileHeight = maxTileHeight;

    this.fontSize = fontSize;

    this.footerHeight = footerHeight;
    this.iconSize = iconSize;
    this.iconPadding = iconPadding;
    this.iconMargin = iconMargin;
    this.logoHeight = logoHeight;
    this.logoPadding = logoPadding;
  }
}

const FOOTER_SIZES_PHONE = Object.freeze([
  new FooterSize(-Infinity, 12, 16, 14, 2),
  new FooterSize(150,       15, 24, 18, 4),
  new FooterSize(300,       18, 32, 24, 8, 6),
]);
const FOOTER_SIZES_DESKTOP = Object.freeze([
  new FooterSize(-Infinity, 15, 24, 18, 6),
  new FooterSize(400,       18, 32, 24, 8, 6),
  new FooterSize(800,       21, 40, 32, 10, 6),
]);
const FOOTER_SIZE_DESKTOP_INACTIVE = new FooterSize(-Infinity, 15, 22, 22, 4);

const MODAL_ID_KICK_OUT = 'kickOut';


const BaseController = ViewportTileControllerMixin(TileController);
export class UserTileController extends BaseController {
  static get $inject() {
    return [
      'browserService',
      'meetingService',
      'modalService',
      'raiseHandService',
      'meetingReliableSocketService',
      'userStreamService',
      'userService',
      'tileService',
      'tutorialService',
    ].concat(BaseController.$inject);
  }

  constructor(
    browserService,
    meetingService,
    modalService,
    raiseHandService,
    meetingReliableSocketService,
    userStreamService,
    userService,
    tileService,
    tutorialService,
    ...args
  ) {
    super(...args);

    this.browserService = browserService;
    this.meetingService = meetingService;
    this.modalService = modalService;
    this.raiseHandService = raiseHandService;
    this.meetingReliableSocketService = meetingReliableSocketService;
    this.userStreamService = userStreamService;
    this.userService = userService;
    this.tileService = tileService;
    this.tutorialService = tutorialService;

    this.avatarSize = 0;

    this.modalService.register(
      MODAL_ID_KICK_OUT,
      gettext('Remove from the meeting'),
      'utils/icons/tl/24x24_block_outline.svg',
      templateModalKickOut
    );
  }



  /*******************
   * Display options *
   *******************/

  get shouldShowHostControls() {
    return (
      this.userService.iAmHost
      && !this.tile.user.isMe
    );
  }

  get isViewportAllowed() {
    return this.meetingService.settings.allowVideoViewport;
  }

  get isClickable() {
    if(!this.browserService.isDesktop) {
      return false;
    } else if(this.userService.mySession.isSpectator) {
      return this.tile.user.isMe && this.tileService.activeContentTile == null;
    } else {
      return !this.tile.active && this.tile.videoVisible;
    }
  }

  get shouldShowChrome() {
    return (
      this.tile.isCollapsed
      || !this.tile.isEmbeddedInFloatingTile
    );
  }

  get shouldShowRaisedHand() {
    return this.tile.user && this.raiseHandService.raisedUsers.has(this.tile.user);
  }
  get shouldShowRaisedHandOverlay() {
    return this.shouldShowRaisedHand && this.raiseHandService.shouldShowOverlay;
  }
  get shouldShowRaisedHandIcon() {
    return this.shouldShowRaisedHand && !this.shouldShowRaisedHandOverlay;
  }

  get shouldShowLogoOnVideoTile() {
    return (
      this.tile.active
      && this.tile.videoActive
      && this.meetingService.settings.showLogoVideoTile
    );
  }

  get poweredByStyle() {
    if(this.tile.rect.width < 250) {
      return 'icon-only';
    } else if(this.tile.rect.width < 500) {
      return 'logo-only';
    } else {
      return 'full';
    }
  }

  /*************
   * Rendering *
   *************/

  _draw() {
    super._draw();

    let minSize = Math.min(this.tile.rect.width, this.tile.rect.height);
    this.avatarSize = Math.min(minSize - 6, 24 + 0.6 * (minSize - 24));
    let raisedHandSize = Math.min(
      this.tile.rect.width * .9,
      this.tile.rect.height * .8
    );
    let footerSize = this._getFooterSize(this.tile.rect.height);

    this.$elem.css({
      '--js-user-tile-font-size': footerSize.fontSize + 'px',
      '--js-user-tile-footer-height': footerSize.footerHeight + 'px',
      '--js-user-tile-footer-item-margin': footerSize.iconMargin + 'px',

      '--js-user-tile-avatar-size': this.avatarSize + 'px',
      '--js-user-tile-raised-hand-overlay-size': raisedHandSize + 'px',

      '--js-user-tile-icon-size': footerSize.iconSize + 'px',
      '--js-user-tile-icon-padding': footerSize.iconPadding + 'px',
      '--js-user-tile-audio-indicator-size': footerSize.audioIndicatorSize + 'px',

      '--js-user-tile-logo-height': footerSize.logoHeight + 'px',
      '--js-user-tile-logo-padding': footerSize.logoPadding + 'px',
    });
  }


  _getFooterSize(tileHeight) {
    if(this.browserService.isDesktop && !this.tile.active) {
      return FOOTER_SIZE_DESKTOP_INACTIVE;
    }

    let FOOTER_SIZES = this.browserService.isDesktop ? FOOTER_SIZES_DESKTOP : FOOTER_SIZES_PHONE;
    for(let i = FOOTER_SIZES.length - 1; i >= 0; i--) {
      if(tileHeight >= FOOTER_SIZES[i].maxTileHeight) {
        return FOOTER_SIZES[i];
      }
    }
  }



  /*********************
   * Interface strings *
   *********************/

  get hostTooltip() {
    if(this.tile.user.isMe) {
      return gettextCatalog.getString('You are a meeting host');
    } else {
      return gettextCatalog.getString(
        '{{ name }} is a meeting host',
        { name: this.tile.user.firstName }
      );
    }
  }


  get userIsSharingCameraString() {
    return gettextCatalog.getString(
      '{{ name }} is sharing their camera',
      { name: this.tile.user.firstName }
    );
  }

  get userIsNotSharingCameraString() {
    return gettextCatalog.getString(
      '{{ name }} is not sharing their camera',
      { name: this.tile.user.firstName }
    );
  }

  get userIsSharingMicrophoneString() {
    if(this.shouldShowHostControls) {
      return gettextCatalog.getString(
        '{{ name }} is sharing their microphone. Click to mute them.',
        { name: this.tile.user.firstName }
      );
    } else {
      return gettextCatalog.getString(
        '{{ name }} is sharing their microphone',
        { name: this.tile.user.firstName }
      );
    }
  }

  get userIsNotSharingMicrophoneString() {
    return gettextCatalog.getString(
      '{{ name }} is not sharing their microphone',
      { name: this.tile.user.firstName }
    );
  }

  get userIsOfflineString() {
    return gettextCatalog.getString(
      '{{ name }} seems to be offline...',
      { name: this.tile.user.shortName }
    );
  }

  get userHasRaisedHandString() {
    if(this.shouldShowHostControls) {
      return gettextCatalog.getString(
        '{{ name }} is raising their hand. Click to lower it.',
        { name: this.tile.user.firstName }
      );
    } else {
      return gettextCatalog.getString(
        '{{ name }} is raising their hand',
        { name: this.tile.user.firstName }
      );
    }
  }



  /***********
   * Actions *
   ***********/

  toggleActive() {
    if(!this.active) {
      this.tutorialService.setHintSeen(Hint.PARTICIPANTS);
    }

    if(!this.tile.active && this.tileService.activeContentTile) {
      this.tileService.activeContentTile.setActive(false);
    }
    if(this.tile.user.isMe) {
      this.tile.toggleActive();
    }
  }


  mute() {
    let stream = this.tile.streams.audio;
    if(stream) {
      this.userStreamService.requestStop(stream.session, StreamType.AUDIO);
    }
  }


  lowerHand() {
    this.raiseHandService.lowerUser(this.tile.user);
  }


  kickOut() {
    this.modalService.show(MODAL_ID_KICK_OUT, {
      name: this.tile.user.fullName,
      confirm: this.confirmKickOut,
    });
  }

  confirmKickOut() {
    this.meetingReliableSocketService.send('_admit', [this.tile.user.id, false]);
  }
}


export default {
  controller: UserTileController,
  controllerAs: 'userTileCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
