<div>
  <div class="header">
    <div class="container container--fullwidth d-flex">
      <logo
        [logoScale]="1"
        class="header__logo"
      ></logo>
    </div>
  </div>

  <div class="container container--lg">
    <div *ngIf="!stateService.isReady">
      <loading></loading>
    </div>


    <div *ngIf="stateService.isReady">
      <div *ngIf="!isAllowedToManage">
        <div class="field field--error mb-4">
          <field-icon [type]="'error'"></field-icon>
          <span i18n>You don't have permission to view this page.</span>
        </div>
      </div>

      <div *ngIf="isAllowedToManage">
        <div *ngIf="appointment == null">
          <div class="field field--error mb-4">
            <field-icon [type]="'error'"></field-icon>
            <span i18n>This meeting does not seem to exist. Are you sure you're on the right page?</span>
          </div>
        </div>

        <div *ngIf="appointment != null">
          <h1 i18n>Manage your meeting with {{ appointment.host.firstName }} {{ appointment.host.lastName }}</h1>

          <div *ngIf="appointment.isCancelled">
            <div *ngIf="!hasActivelyCancelled" class="field field--error mb-4">
              <field-icon [type]="'warning'"></field-icon>
              <span i18n>This meeting has already been canceled.</span>
            </div>

            <div *ngIf="hasActivelyCancelled" class="field field--success mb-4">
              <field-icon [type]="'success'"></field-icon>
              <span i18n>Your meeting was canceled sucessfully.</span>
            </div>

            <div class="mt-2">
              <appointment-details [appointment]="appointment"></appointment-details>
            </div>
          </div>

          <div *ngIf="!appointment.isCancelled">
            <div class="mt-2">
              <appointment-details [appointment]="appointment"></appointment-details>
            </div>
            <div class="d-flex">
              <div
                *ngIf="!isCancelling"
                class="btn btn--error mr-2"
                [dialog]="cancelAppointmentConfirmation"
                dialogTitle="Cancel meeting"
                i18n-dialogTitle
                [dialogIcon]="{ name: 'trash' }"
                dialogConfirmText="Cancel meeting"
                i18n-dialogConfirmText
                dialogCancelText="Don't cancel the meeting"
                i18n-dialogCancelText
                dialogConfirmButtonClass="btn--error"
                [dialogConfirmedCallback]="cancel.bind(this)"
                i18n
              >
                Cancel meeting
              </div>
              <div
                *ngIf="isCancelling"
                class="btn btn--error mr-2 disabled"
                disabled
              >
                <span i18n>Canceling meeting...</span>
                <loading class="ml-2"></loading>
              </div>
              <a class="btn btn--primary" [attr.href]="rescheduleLink" i18n>Reschedule meeting</a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
  <ng-template #cancelAppointmentConfirmation>
    <p i18n>Are you sure you want to cancel the meeting with {{ appointment.host.fullName }}?</p>
    <p i18n>{{ appointment.host.fullName }} will be notified and the calendar event will be deleted.</p>
  </ng-template>
</div>
