import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  SchedulingOnboardingWizardService
} from 'scheduling/manage/scheduling-onboarding-wizard/scheduling-onboarding-wizard.service';
import { SchedulingState } from 'scheduling/variables';
import { SiteService } from 'utils/site.service';
import { UrlService } from 'utils/url.service';
import { bind } from 'utils/util';
import { TeamMembersService } from 'organization/teamMembers.service';
import {
  Appointment, OrganizationAppointment, UserAppointment
} from 'scheduling/models/Appointment';
import { browser, errors, logger } from 'utils/util';
import {
  DashboardScope, DataPageComponent
} from 'dashboard/data-page/data-page.component';
import { Subscription } from 'rxjs';
import { MeetingRoomsMenuComponent } from './meeting-rooms-menu/meeting-rooms-menu.component';
import { Meeting } from 'meeting/models/Meeting';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { UrlQueryParamsService } from 'scheduling/manage/appointments/url-query-params.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';


@Component({
  selector: 'home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
  providers: [UrlQueryParamsService]
})
export class HomeComponent extends DataPageComponent implements OnInit, AfterViewInit {
  public appointments: Appointment[] = [];
  public hasTeamMembers = false;
  public shouldShowHint = false;

  @ViewChildren('meetingRoomsMenu') meetingRoomsMenu!: QueryList<MeetingRoomsMenuComponent>;
  private meetingRoomsMenuSubscriptions: Subscription[] = [];

  constructor(
    public schedulingOnboardingWizardService: SchedulingOnboardingWizardService,
    public urlService: UrlService,
    public siteService: SiteService,
    public teamMemberService: TeamMembersService,
    private urlQueryParamsService: UrlQueryParamsService,
    private usageTrackingService: UsageTrackingService,

    @Inject('hintService') private hintService,
    // Even though it is not used in this service, the onboardingService needs to be injected
    // because it will check in its constructor if the onboarding wizard needs to be shown.
    @Inject('onboardingService') private onboardingService,
  ) {
    super();
    bind(this);

    if (this.requestUserService.user.schedulingConfig.state !== SchedulingState.NEW) {
      this.schedulingOnboardingWizardService.continueWizard();
    }

    this.schedulingOnboardingWizardService.wizardFinished.subscribe(() => {
      window.location.href = this.urlService.urls.appointmentTypesOverview;
    });
  }

  ngOnInit(): void {
    this.initializeQueryParams();
  }

  private initializeQueryParams(): void {
    const scope = this.urlQueryParamsService.queryParams.get('scope');
    if (scope) {
      this.scope = this.findScope(scope);
    }
  }

  ngAfterViewInit() {
    this.meetingRoomsMenu.changes.subscribe(this.onMeetingRoomsMenuChanged);
  }

  public onScopeChange(scope: Scope): void {
    if (this.isDefaultScope(scope)) {
      this.urlQueryParamsService.removeQueryParam('scope');
    } else {
      this.urlQueryParamsService.setQueryParam('scope', scope.id);
    }
    this.scope = scope;
  }

  private isDefaultScope(scope: Scope): boolean {
    return scope === DashboardScope.PERSONAL;
  }

  override fetch(): Promise<any> {
    return this.fetchUpcomingAppointments();
  }

  override fetchInitial() {
    return this.fetchHasTeamMembers();
  }

  public override get shouldShowTeamScope() {
    return (
      !this.requestUserService.user.organization.isSolo
      && (this.requestUserService.user.isAdmin
        || this.requestUserService.user.organization.allowEditAppointments)
    );
  }


  private fetchUpcomingAppointments() {
    const modelConfig = this.scope === DashboardScope.PERSONAL ?
      {
        model: UserAppointment,
        identifiers: { userId: this.requestUserService.user.id }
      } :
      {
        model: OrganizationAppointment,
        identifiers: { organizationId: this.requestUserService.user.organizationId }
      };
    const apiConfig = {
      params: {
        filter: `isCancelled = false AND end > ${new Date().toISOString()}`,
        orderBy: 'start',
        perPage: 5,
      }
    };
    return this.listCancelable(modelConfig, apiConfig).then(({ data: appointments }) => {
      this.appointments = appointments as Appointment[];
    });
  }

  private fetchHasTeamMembers() {
    return this.teamMemberService.get().then(teamMembers => {
      this.hasTeamMembers = teamMembers.length > 1;
    });
  }

  override handleFetchError(error) {
    /* eslint-disable max-len */
    if (error.constructor === errors.OfflineError) {
      this.fetchError = $localize`You seem to be offline. Please check your internet connection and reload the page.`;
    } else {
      logger.warn(error);
      this.fetchError = $localize`Something went wrong while fetching your appointments. Please try again later.`;
    }
    /* eslint-enable max-len */
  }

  get shouldShowWelcome() {
    return this.hintService.get('dashboardWelcome').shouldShow;
  }

  get shouldShowConnect() {
    return (
      this.requestUserService.user.schedulingConfig.state === SchedulingState.DISCONNECTED
      || this.requestUserService.user.schedulingConfig.state === SchedulingState.EXPIRED
      || !this.schedulingOnboardingWizardService.hasSeenWizard
    );
  }

  get shouldShowAddMembers() {
    return (
      this.stateService.isReady
      && !this.shouldShowConnect
      && this.scope === DashboardScope.TEAM
      && !this.hasTeamMembers
    );
  }

  get shouldShowUpcomingAppointments() {
    return (!this.shouldShowConnect && !this.shouldShowAddMembers);
  }

  get welcomeText() {
    if (this.siteService.site.isTeamleader) {
      // eslint-disable-next-line max-len
      return $localize`Welcome to the Lead capture Booster`;
    } else {
      // eslint-disable-next-line max-len
      return $localize`Welcome to Vectera`;
    }
  }

  get subWelcomeText() {
    if (this.siteService.site.isTeamleader) {
      // eslint-disable-next-line max-len
      return $localize`Here are the things you can do with the Booster`;
    } else {
      // eslint-disable-next-line max-len
      return $localize`Here are the things you can do`;
    }
  }



  get isMobile() {
    return browser.isMobile();
  }

  get defaultRoomName(): string {
    return ANGULAR_SCOPE.defaultRoomName;
  }


  onMeetingRoomsMenuChanged(next: QueryList<MeetingRoomsMenuComponent>) {
    this.meetingRoomsMenuSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.meetingRoomsMenuSubscriptions = [];

    next.forEach((meetingRoomsMenu: MeetingRoomsMenuComponent) => {
      meetingRoomsMenu.meetingRoomSelected.subscribe((meeting: Meeting) => {
        window.open(meeting.url, '_blank');
      });
    });
  }

  goToMeetings() {
    window.location.href = this.urlService.urls.appointments;
  }

  onConnectCalendarClick() {
    this.usageTrackingService.createSegmentEvent('dashboardWelcome.connectCalendar', 'dashboard');
    this.schedulingOnboardingWizardService.showWizard();
  }

  onCreateFormClick() {
    this.usageTrackingService.createSegmentEvent('dashboardWelcome.createForm', 'dashboard');
    window.location.href = this.urlService.urls.contactFormCreate;
  }

  onStartMeetingClick() {
    this.usageTrackingService.createSegmentEvent('dashboardWelcome.startMeeting', 'dashboard');
    window.open(this.urlService.urls.defaultRoomLink, '_blank');
  }

  connectCalendar() {
    if (this.schedulingOnboardingWizardService.hasSeenWizard) {
      window.location.href = this.urlService.urls.configureScheduling;
    } else {
      this.schedulingOnboardingWizardService.showWizard();
    }
  }
}
