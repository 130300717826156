import 'angular-clipboard';
import 'angular-filter';

let dependencies = [
  'angular-clipboard',
  'angular.filter',
  'vecteraUtil',
];

angular.module('dashboard', dependencies);
