import { Component, Input } from '@angular/core';
import { ContactWidget } from 'contactWidget/models/ContactWidget';
import { siteColor } from 'utils/util';


@Component({
  selector: 'button-preview[contactWidget]',
  templateUrl: './button-preview.component.html',
  styleUrls: ['./button-preview.component.scss']
})
export class ButtonPreviewComponent {
  @Input() contactWidget!: ContactWidget;
  @Input() buttonPreviewHideTitle = false;

  get buttonColor() {
    return siteColor.getButtonColor(this.contactWidget.buttonColor);
  }

  get buttonHoverColor() {
    return siteColor.getButtonHoverColor(this.contactWidget.buttonColor);
  }

  get textColor() {
    return siteColor.getButtonTextColorDeprecated(
      this.contactWidget.textColor,
      this.contactWidget.buttonColor,
    );
  }
}
