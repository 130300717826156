import { Component } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';


@Component({
  selector: 'appointment-type-reminders[instance]',
  templateUrl: 'appointment-type-reminders.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeRemindersComponent
  }]
})
export class AppointmentTypeRemindersComponent extends AppointmentTypeSettingsComponent {

  static override formFields = [
    'calendarReminder',
    'emailReminder',
  ];

  get reminderNullAlias() {
    return $localize `No reminder`;
  }
}
