import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { ErrorValue } from '../settings.component';
import { SettingsErrorsComponent } from './settings-errors.component';


/**
 * Toggle the "error" class on an input field, and render its errors.
 *
 * Usage:
 *
 * ```
 * <input [withSettingsErrors]="errors">
 * ```
 *
 * Which is a shorthand for something like:
 *
 * ```
 * <input [class.error]="errors && errors.length > 0">
 * <settings-errors settingsErrors="errors"></settings-errors>
 * ```
 */
@Directive({
  selector: '[withSettingsErrors]',
})
export class WithSettingsErrorsDirective implements OnInit, OnDestroy, OnChanges {
  @Input() withSettingsErrors?: ErrorValue;

  private componentRef?: ComponentRef<SettingsErrorsComponent>;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {}


  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
    if(this.componentRef) {
      this.componentRef.destroy();
    }
  }


  ngOnChanges() {
    if(this.componentRef) {
      this.updateErrorClass();
      this.setComponentErrors();
    }
  }


  private loadComponent() {
    this.componentRef = this.viewContainerRef.createComponent<SettingsErrorsComponent>(
      SettingsErrorsComponent
    );
    this.setComponentErrors();
  }


  private updateErrorClass() {
    const hasError = !!(
      this.withSettingsErrors
      && Object.values(this.withSettingsErrors).length > 0
    );
    this.elementRef.nativeElement.classList.toggle('error', hasError);
  }


  private setComponentErrors() {
    if(this.componentRef) {
      this.componentRef.instance.errors = this.withSettingsErrors;
    }
  }
}
