import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from 'utils/utils.module';
import { ContactFormResponsesComponent } from './contact-form-responses.component';


@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
  ],
  declarations: [
    ContactFormResponsesComponent,
  ],
  exports: [
    ContactFormResponsesComponent,
  ],
})
export class ContactFormResponsesModule {}
