import { EventEmitter } from 'utils/util';
import JanusHandle, { ChannelLabel } from './JanusHandle';
import { KEEP_ALIVE_TIMEOUT } from './JanusPublisher';
import JanusStreamHandle from './JanusStreamHandle';


export default class JanusSubscriber extends JanusStreamHandle {
  constructor(...args) {
    super(...args);
    EventEmitter.setup(this, ['track']);

    this.mediaStream = null;
    this.negotiateInfo = {
      audio: false,
      video: false,
    };
    this.keepAliveTimeout = null;

    this.dataHandlers = {
      [ChannelLabel.KEEP_ALIVE]: this._onKeepAlive,
    };
  }

  get direction() {
    return 'inbound';
  }


  _onStreamsChanged() {
    super._onStreamsChanged();
    if(this.mediaStream) {
      this._onRemoteStream(this.mediaStream);
      this._onWebrtcState(this.handle.webrtcState);
    }
    this.negotiate(false);
  }


  _bootstrapPeerConnection() {
    super._bootstrapPeerConnection();
    this.statsTracker.on('selectedCandidatePairChanged', this._onCandidatePairChanged);
    this._updateBytesPerSecondTarget();
  }


  _postAttach() {
    super._postAttach();

    const publisher = this.masterHandle.publishers[this.id];
    if(publisher) {
      this._onAddPublisher(publisher);
    } else {
      this.masterHandle.on('addPublisher', this._onAddPublisher);
    }
    this.masterHandle.on('removePublisher', this._onRemovePublisher);
  }


  _onAddPublisher(publisher) {
    if(publisher.id === this.id) {
      this._enqueue(this._join);
      this.masterHandle.off('addPublisher', this._onAddPublisher);
    }
  }


  _onRemovePublisher(publisher) {
    if(publisher.id === this.id) {
      // Destroying the handle causes PeerConnectionJanusService to recreate it. There are
      // probably cleaner ways to do this, but it shouldn't happen that often anyway.
      this.destroy();
      this.masterHandle.off('removePublisher', this._onRemovePublisher);
    }
  }


  _onVideoBandwidth() {
    this._updateBytesPerSecondTarget();
  }

  _updateBytesPerSecondTarget() {
    if(this.statsTracker) {
      this.statsTracker.setBytesPerSecondTarget('inbound', this.videoBandwidth / 8 * 1000);
    }
  }



  _onDestroyed() {
    if(this.statsTracker) {
      this.statsTracker.off('selectedCandidatePairChanged', this._onCandidatePairChanged);
    }
    this.masterHandle.off('addPublisher', this._onAddPublisher);
    this.masterHandle.off('removePublisher', this._onRemovePublisher);

    $timeout.cancel(this.keepAliveTimeout);
    this.keepAliveTimeout = null;

    super._onDestroyed();
  }




  _getAttachConfig() {
    return Object.assign(
      super._getAttachConfig(),
      {
        onremotestream: this._apply(this._onRemoteStream),
        ondata: this._apply(this._onData),
      }
    );
  }


  _getNegotiateInfo() {
    return {
      audio: !!this.streams.audio,
      video: !!this.streams.video,
    };
  }


  _getJoinConfig() {
    const config = {
      ptype: 'subscriber',
      feed: this.id,
    };

    return Object.assign(super._getJoinConfig(), config);
  }


  _startNegotiation(iceRestart) {
    this._setState(JanusHandle.State.NEGOTIATION_REQUESTED);
    if(iceRestart) {
      $timeout.cancel(this.renegotiateOnHaltedTimeout);
      this.renegotiateOnHaltedTimeout = null;
    }

    const message = {
      request: 'configure',
      restart: iceRestart,
      update: true,  // Force a renegotiation
    };
    this.sendMessage(message)
      .catch(this._onError);
  }


  _getJsepConfig() {
    const config = {
      media: {
        audioSend: false,
        videoSend: false,
        audioRecv: this.negotiateInfo.audio,
        videoRecv: this.negotiateInfo.video,
        data: true,
      }
    };

    return Object.assign(super._getJsepConfig(), config);
  }


  _getNegotiateConfig() {
    return Object.assign(
      super._getNegotiateConfig(),
      {
        request: 'start',
      }
    );
  }



  _onRemoteStream(mediaStream) {
    this.mediaStream = mediaStream;
    for(const track of mediaStream.getTracks()) {
      this.emit('track', this, track);
    }
  }


  _onCandidatePairChanged() {
    this.logger.info('Ice candidate pair changed');
    this.renegotiate(true);
  }


  _onKeepAlive() {
    $timeout.cancel(this.keepAliveTimeout);
    this.keepAliveTimeout = $timeout(this._onKeepAliveTimeout, KEEP_ALIVE_TIMEOUT);
  }


  _onKeepAliveTimeout() {
    this.logger.info('Keep alive timeout after %ss', KEEP_ALIVE_TIMEOUT / 1000);
    // We used to do this.renegotiate(true) here, but it seems like there exist states in a janus
    // handle that an ICE renegotiation can't fix. For example, we have the following recurring
    // but unreproducible bug:
    // - A and B are in a meeting room with audio+video enabled. The mediaserver is disabled.
    // - C joins the meeting room with or without audio+video. The mediaserver is enabled.
    // - When switching to the mediaserver, A cannot hear or see B anymore. Every 5 seconds we
    //   end up in _onHalted(), but the issue is never resolved except after a page reload.
    // Hopefully, destroying and recreating the handle does fix the issue. But since the issue
    // is not reproducible, I can't be sure it will.
    this.destroy();
  }


  _onData(data) {
    const { channel, message } = JSON.parse(data);
    const handler = this.dataHandlers[channel];
    if(handler) {
      handler.apply(this, message);
    }
  }
}
