import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Memoize } from 'typescript-memoize';
import * as icons from 'utils/icons';
import { logger } from 'utils/util';


export type SvgSize = 14 | 24;
export type SvgStyle = 'outline' | 'filled' | 'badged_outline' | 'badged_filled';


export type PathSvgIcon = {
  path: string;
}

export type NameSvgIcon = {
  name: string;
  library?: string;
  style?: SvgStyle;
  size?: SvgSize;
}
const defaultNameSvgOptions = {
  library: 'tl',
  style: 'outline' as SvgStyle,
  size: 24 as SvgSize,
};

export type SvgIcon = PathSvgIcon | NameSvgIcon;


const loggedMessages = new Set();


/**
 * Render an svg icon inline.
 *
 * You have 2 choices to make when using this component.
 *  - Either you specify the icon using its full path, or you describe it using its name and
 *    optionally size and style, based on which a path will be generated.
 *  - Either you specify the different options as separate HTML attributes, or you pass an object
 *    that contains the different options to the `svgIcon` attribute. The last option is mainly
 *    useful when configuring an icon dynamically in Javascript.
 *
 * A few examples of the different options:
 *
 * ```
 * # Name (and optionally size and style) as HTML attributes
 * <svg-icon [svgName]="'close'"></svg-icon>
 * <svg-icon
 *  [svgName]="'warning'"
 *  [svgSize]="14"
 *  [svgStyle]="'badged_filled'"
 * ></svg-icon>
 *
 *
 * # Path as a HTML attribute
 * <svg-icon [svgPath]="'logo/icons/standalone/compact.svg'"></svg-icon>
 * <svg-icon [svgPath]="'dashboard/angularjs/onboarding/icons/corporate.svg'"></svg-icon>
 *
 *
 * # Single config object
 *
 * -- my.component.ts
 * class MyComponent {
 *  public icons: SvgIcon[] = [
 *    { name: 'close' },
 *    { name: 'warning', size: 14, style: 'badged_filled' },
 *    { path: 'logo/icons/standalone/compact.svg' },
 *  ];
 * }
 *
 * -- my.component.html
 *
 * <div *ngFor="let icon of icons">
 *   <svg-icon [svgIcon]="icon"></svg-icon>
 * </div>
 * ```
 */
@Component({
  selector: 'svg-icon[svgPath], svg-icon[svgName], svg-icon[svgIcon]',
  template: '<div class="d-flex h-full align-items-center" [innerHTML]="svg"></div>',
  styles: ['svg-icon { display: flex }'],
})
export class SvgIconComponent {
  /** The full path of the icon. Other options are ignored if this is provided. Defaults to
   * ${library}/icons/${size}x${size}_${name}_${variant}.svg.
   */
  @Input() svgPath = '';
  /** The name of the icon. See `svgPath` for how this is used internally. */
  @Input() svgName = '';
  /** The library that contains the icon. See `svgPath` for how this is used internally. */
  @Input() svgLibrary = defaultNameSvgOptions.library;
  /** The style of the icon. See `svgPath` for how this is used internally. */
  @Input() svgStyle = defaultNameSvgOptions.style;
  /** The size of the icon. See `svgPath` for how this is used internally. */
  @Input() svgSize = defaultNameSvgOptions.size;

  /** A single object that describes the icon. Other attributes are ignored if this is provided. */
  @Input() svgIcon?: SvgIcon;

  constructor(
    private sanitizer: DomSanitizer,
  ) {}


  get icon(): SvgIcon {
    if(this.svgIcon) {
      if('path' in this.svgIcon) {
        return this.svgIcon;
      } else {
        return {
          ...defaultNameSvgOptions,
          ...this.svgIcon,
        };
      }

    } else {
      if(this.svgPath) {
        return {
          path: this.svgPath,
        };
      } else {
        return {
          name: this.svgName,
          library: this.svgLibrary,
          style: this.svgStyle,
          size: this.svgSize,
        };
      }
    }
  }


  get svg() {
    const svg = icons[this.path] || '';
    if(svg === '') {
      this._logNonExistent(this.icon);
    }

    return this._trust(svg);
  }


  get path() {
    let path;
    if('path' in this.icon) {
      path = this.icon.path;
    } else {
      path = `${this.icon.size}x${this.icon.size}_${this.icon.name}_${this.icon.style}`;
      if(this.icon.library) {
        path = `${this.icon.library}/${path}`;
      }
    }

    path = path.replace('/', '__');
    return path;
  }


  @Memoize()
  _trust(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }


  /**
   * Log an error for a non-existent icon, but only once per icon.
   */
  _logNonExistent(icon) {
    const message = `Unknown svg icon: ${icon}`;
    if(!loggedMessages.has(message)) {
      logger.error('Unknown svg icon:', this.path, icon, icons);
      loggedMessages.add(message);
    }
  }
}
