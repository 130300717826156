import template from './copyText.html?raw';

import { bind, string } from 'utils/util';

const HIDE_TOOLTIP_TIMEOUT = 2500;


class CopyTextController {

  constructor() {
    bind(this);

    this.tooltipTextAfterAction = '';
    this.hideTooltipAfterActionTimeout = null;
  }

  get textToShow() {
    return this.prettifyLink ? this.textToCopy : this._prettifyLink(this.textToCopy);
  }
  get buttonText() {
    return this._buttonText == null ? gettextCatalog.getString('Copy') : this._buttonText;
  }
  get tooltipText() {
    return this.tooltipTextAfterAction || this._tooltipText;
  }
  get buttonCssClass() {
    return this._buttonCssClass === undefined ? 'btn btn--secondary' : this._buttonCssClass;
  }
  get linkCssClass() {
    return this._linkCssClass === undefined ? this.buttonCssClass : this._linkCssClass;
  }

  _prettifyLink(url) {
    if(url == null) {
      url = '';
    }
    url = string.removePrefix(url, 'https://');
    url = string.removeSuffix(url, '/');
    return url;
  }


  showTooltipSuccess() {
    this.tooltipTextAfterAction = gettextCatalog.getString('Copied to clipboard');
    this.hideTooltipAfterTimeout();
  }

  showTooltipError() {
    this.tooltipTextAfterAction = gettextCatalog.getString('Copy to clipboard failed');
    this.hideTooltipAfterTimeout();
  }

  hideTooltipAfterTimeout() {
    $timeout.cancel(this.hideTooltipAfterActionTimeout);
    this.hideTooltipAfterActionTimeout = $timeout(
      this.hideTooltipAfterAction,
      HIDE_TOOLTIP_TIMEOUT
    );
  }

  hideTooltipAfterAction() {
    this.tooltipTextAfterAction = '';
  }
}


/**
 * Show a button (or plain text) that will copy some text to the clipboard when clicked.
 *
 * @param {string} text-to-copy - The text that should be copied.
 * @param {bool} show-text-to-copy - Whether the text should be shown in an input field with the
 *  copy button next to it. Defaults to false.
 * @param {bool} show-link - Whether to show a button that links to the url that is being copied.
 * @param {bool} prettify-link - If the text is an url: prettify it.
 * @param {string} icon - The url of the icon to show in the button. Defaults to no icon.
 * @param {string} button-text - The text inside the copy button. Defaults to "Copy".
 * @param {string} tooltip-text - A tooltip text shown when hovering over the copy button.
 * @param {string} help-text - A help text to show behind a question tooltip.
 * @param {string} button-css-class - The css classes to put on the button. Defaults to
 *  "btn btn--secondary".
 */
export default {
  controller: CopyTextController,
  controllerAs: 'copyTextCtrl',
  template,

  bindings: {
    textToCopy: '<',
    showTextToCopy: '<',
    showLink: '<',
    prettifyLink: '<',
    icon: '<',
    _buttonText: '<buttonText',
    _tooltipText: '<tooltipText',
    helpText: '<',
    _buttonCssClass: '<buttonCssClass',
    _linkCssClass: '<linkCssClass',
  }
};
