const Position = Object.freeze({
  TOP:    'top',
  BOTTOM: 'bottom',
  LEFT:   'left',
  RIGHT:  'right',
  EXACT:  'exact',
});

const Align = Object.freeze({
  START: 'start',
  END:   'end',
});


const DEFAULT_OPTIONS = Object.freeze({
  cssClasses: '',
  position: Position.BOTTOM,
  align: Align.START,
  preRender: false,
  embeds: [],
});



export default class Dropdown {
  static get Position() {
    return Position;
  }
  static get Align() {
    return Align;
  }

  constructor(id, template, argOptions) {
    let options = Object.assign({}, DEFAULT_OPTIONS, argOptions);

    this.id = id;
    this.template = template;
    this.position = options.position;
    this.align = options.align;
    this.preRender = options.preRender;
    this.embeds = new Set(options.embeds);

    this.cssClasses = options.cssClasses;
    if(typeof this.cssClasses === 'string') {
      this.cssClasses = this.cssClasses.split(/\s+/);
    } {
      this.cssClasses = this.cssClasses.slice();
    }
    this.cssClasses.push(this.isHorizontal ?
      'dropdown-deprecated--horizontal' :
      'dropdown-deprecated--vertical'
    );

    this.$elem = null;
    this.$elemPosition = null;
    this.$eventPosition = null;

    this.active = false;
    this.scope = {};

    this.$elemParents = angular.element();
  }


  show($positionElemOrEvent, scope) {
    this.active = true;
    this.$elemPosition = this.getElemPosition($positionElemOrEvent);
    this.$eventPosition = this.getEventPosition($positionElemOrEvent);
    this.scope = scope || {};

    if(this.$elemPosition) {
      this.$elemParents = this.$elemPosition.parents();
      this.$elemParents.addClass('has-dropdown');
    }
  }

  hide() {
    this.active = false;
    this.$elemPosition = null;
    this.$eventPosition = null;
    if(this.$elemParents) {
      this.$elemParents.removeClass('has-dropdown');
      this.$elemParents = null;
    }
  }


  setElem($elem) {
    this.$elem = $elem;
  }

  unsetElem($elem) {
    if($elem === this.$elem) {
      this.$elem = null;
    }
  }


  get isHorizontal() {
    return this.position === Position.LEFT || this.position === Position.RIGHT;
  }
  get isVertical() {
    return this.position === Position.TOP || this.position === Position.BOTTOM;
  }
  get isExact() {
    return this.position === Position.EXACT;
  }

  getElemPosition($positionElemOrEvent) {
    if(this.isExact || !$positionElemOrEvent) {
      return null;
    } else if($positionElemOrEvent.currentTarget) {
      return angular.element($positionElemOrEvent.currentTarget);
    } else {
      return $positionElemOrEvent;
    }
  }

  getEventPosition($positionElemOrEvent) {
    if(this.isExact) {
      return $positionElemOrEvent;
    } else {
      return null;
    }
  }
}
