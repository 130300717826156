export default function PreventDefaultDirective() {
  return {
    restrict: 'A',

    link: function(scope, $elem) {
      $elem.on('click', function($event) {
        $event.preventDefault();
      });
    }
  };
}
