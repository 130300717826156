import { EventEmitter, Rect, object, array } from 'utils/util';
import Shape from './Shape';
import { PAPER_OBJECT_MARGIN } from '../whiteboard.service';
import { ALL_DROPDOWN_IDS } from '../tools/tools.component';

let maxShapeId = 0;


export default class PaperRenderer {
  constructor(userService, whiteboard) {
    this._bind();
    EventEmitter.setup(this, [
      'elem', 'init', 'destroy',
      'active', 'focus', 'blur', 'viewport',
      'shapeAdd', 'shapeRemove', 'shapesEdit', 'shapeEditing', 'shapeSelected',
    ]);

    this.userService = userService;
    this.whiteboard = whiteboard;
    this.fabricRenderer = null;
    this.formulaRenderer = null;
    this.initializing = true;

    this.$elemTileBody = null;
    this.$elem = null;
    this.$elemActiveShape = null;

    this.active = false;
    this.focused = false;

    this.shapes = {};
    this.addedShapeIds = new Set();
    this.editingShape = null;
    this.selectedShape = null;
    this.temporaryShapes = new Set();
    this.pageShapes = {};
    this.page = null;

    this.notepadBounds = {
      left: 0,
      right: this.whiteboard.contentSize.width,
      top: 0,
      bottom: this.whiteboard.contentSize.height,
    };
    this.shapeBounds = { left: 0, right: 0, top: 0, bottom: 0 };
    this.drawingBounds = { left: 0, right: 0, top: 0, bottom: 0 };
    this.bounds = Object.assign({}, this.notepadBounds);

    this.userService.mySession.on('isSpectator', this._onIsSpectator);
  }

  _bind() {
    this._onIsSpectator = this._onIsSpectator.bind(this);
    this._onDocumentMouseDown = this._onDocumentMouseDown.bind(this);
    this._onDocumentMouseUp = this._onDocumentMouseUp.bind(this);

    if(!window.navigator.userAgent.startsWith('Node.js')) {
      this._updateBounds = throttle(this._updateBounds, 400, true);
    }
  }


  get id() {
    return this.whiteboard.id;
  }


  setElem($elemTile) {
    this.$elemTileBody = $elemTile.find('.tile:not(.tile--inactive) .tile__body');
    this.$elem = this.$elemTileBody.find('.whiteboard-tile__paper');

    this.emit('elem', this, $elemTile);

    this.initializing = false;
    this._resetShapeBounds();
    this.resetDrawingBounds();
  }


  destroy() {
    this.userService.mySession.off('isSpectator', this._onIsSpectator);

    Object.values(this.shapes).forEach(shape => this.removeShape(shape, true));
    this.initializing = true;
    this.emit('destroy', this);
  }


  _onIsSpectator(isSpectator) {
    if(isSpectator) {
      this.emit('blur');
      this.unsetEditingShape();
    }
  }


  setActive(active) {
    if(active === this.active) {
      return;
    }

    if(active) {
      $document.on('mousedown', this._onDocumentMouseDown);
      $document.on('mouseup touchend', this._onDocumentMouseUp);
    } else {
      $document.off('mousedown', this._onDocumentMouseDown);
      $document.off('mouseup touchend', this._onDocumentMouseUp);
    }

    this.active = active;
    this.emit('active', this, active);
  }


  updateViewport() {
    this.emit('viewport');
  }


  /*******************
   * Manage blurring *
   *******************/

  setElemActiveShape($elem) {
    this.$elemActiveShape = $elem;
  }

  unsetElemActiveShape($elem) {
    if($elem === this.$elemActiveShape) {
      this.$elemActiveShape = null;
    }
  }


  _onDocumentMouseDown($event) {
    let [hitTileBody, hitTools] = this._onDocumentMouseEvent($event);

    if(!this.focused && hitTileBody) {
      this.focused = true;
      this.emit('focus', this);

    } else if(this.focused && !(hitTileBody || hitTools)) {
      this.focused = false;
      this.emit('blur', this);
      this.unsetEditingShape();
    }
  }


  _onDocumentMouseUp($event) {
    this._onDocumentMouseEvent($event);
  }


  _onDocumentMouseEvent($event) {
    let $elemTarget = angular.element($event.target);
    let $elemTools = $elemTarget.closest('.tools').slice(0, 1);
    let $elemDropdown = $elemTarget.closest('.dropdown-deprecated').slice(0, 1);

    let hitTileBody = this.$elemTileBody ? this.$elemTileBody[0].contains($event.target) : false;
    let hitTools = (
      $elemTools.length > 0
      || $elemDropdown.length > 0
      && array.has(ALL_DROPDOWN_IDS, $elemDropdown.attr('dropdown-id'))
    );

    if(this.$elemActiveShape && (hitTileBody || hitTools)) {
      this.$elemActiveShape.focus();
    }

    return [hitTileBody, hitTools];
  }




  /**********
   * Bounds *
   **********/

  _updateBounds() {
    if(this.initializing) {
      return;
    }

    /* eslint-disable max-len */
    /* eslint-disable comma-spacing */
    this.bounds = {
      left:   Math.min(this.notepadBounds.left  , this.shapeBounds.left   - PAPER_OBJECT_MARGIN, this.drawingBounds.left   - PAPER_OBJECT_MARGIN),
      right:  Math.max(this.notepadBounds.right , this.shapeBounds.right  + PAPER_OBJECT_MARGIN, this.drawingBounds.right  + PAPER_OBJECT_MARGIN),
      top:    Math.min(this.notepadBounds.top   , this.shapeBounds.top    - PAPER_OBJECT_MARGIN, this.drawingBounds.top    - PAPER_OBJECT_MARGIN),
      bottom: Math.max(this.notepadBounds.bottom, this.shapeBounds.bottom + PAPER_OBJECT_MARGIN, this.drawingBounds.bottom + PAPER_OBJECT_MARGIN),
    };
    /* eslint-enable max-len */
    /* eslint-enable comma-spacing */

    this.whiteboard.setContentRect(new Rect(this.bounds));
  }


  resetDrawingBounds() {
    this.drawingBounds = {
      left:   this.notepadBounds.left   + PAPER_OBJECT_MARGIN,
      right:  this.notepadBounds.right  - PAPER_OBJECT_MARGIN,
      top:    this.notepadBounds.top    + PAPER_OBJECT_MARGIN,
      bottom: this.notepadBounds.bottom - PAPER_OBJECT_MARGIN,
    };

    this._updateBounds();
  }


  setDrawingBounds(bounds, remember) {
    if(remember) {
      this.drawingBounds = {
        left:   Math.min(this.drawingBounds.left,   bounds.left  ),
        right:  Math.max(this.drawingBounds.right,  bounds.right ),
        top:    Math.min(this.drawingBounds.top,    bounds.top   ),
        bottom: Math.max(this.drawingBounds.bottom, bounds.bottom),
      };

    } else {
      this.drawingBounds = Object.assign({}, bounds);
    }

    this._updateBounds();
  }


  _resetShapeBounds() {
    if(this.initializing) {
      return;
    }

    this.shapeBounds = {
      left:   this.notepadBounds.left   + PAPER_OBJECT_MARGIN,
      right:  this.notepadBounds.right  - PAPER_OBJECT_MARGIN,
      top:    this.notepadBounds.top    + PAPER_OBJECT_MARGIN,
      bottom: this.notepadBounds.bottom - PAPER_OBJECT_MARGIN,
    };

    if(this.page) {
      this.activeShapes.forEach(shape => this._updateShapeBoundsOnAdd(shape, false));
      this._updateBounds();
    }
  }


  _updateShapeBoundsOnAdd(shape, update) {
    if(update == null) {
      update = true;
    }
    if(this.initializing) {
      return;
    }

    let bounds = shape.getBounds();
    let changed = false;

    if(bounds.left < this.shapeBounds.left) {
      this.shapeBounds.left = bounds.left;
      changed = true;
    }

    if(bounds.right > this.shapeBounds.right) {
      this.shapeBounds.right = bounds.right;
      changed = true;
    }

    if(bounds.top < this.shapeBounds.top) {
      this.shapeBounds.top = bounds.top;
      changed = true;
    }

    if(bounds.bottom > this.shapeBounds.bottom) {
      this.shapeBounds.bottom = bounds.bottom;
      changed = true;
    }

    if(update && changed) {
      this._updateBounds();
    }
  }



  /*********
   * Pages *
   *********/

  setPage(page) {
    this.page = page;
    this._resetShapeBounds();
  }




  /***********************
   * Edit shapes locally *
   ***********************/

  setEditingShape(shape) {
    if(shape === this.editingShape) {
      return;
    }

    if(this.editingShape) {
      this.unsetEditingShape();
    }
    this.editingShape = shape;
    this.emit('shapeEditing', this, shape, true);
  }

  unsetEditingShape() {
    let shape = this.editingShape;
    if(shape) {
      this.editingShape = null;
      this.emit('shapeEditing', this, shape, false);
    }
  }




  /*****************
   * Manage shapes *
   *****************/

  setSelectedShape(shape) {
    if(shape !== this.selectedShape) {
      this.selectedShape = shape;
      this.emit('shapeSelected', this, shape);
    }
  }

  unsetSelectedShape() {
    this.setSelectedShape(null);
  }


  get activeShapes() {
    if(this.page && this.page.id in this.pageShapes) {
      return this.pageShapes[this.page.id].filter(shape => shape instanceof Shape);
    } else {
      return [];
    }
  }


  addShape(id, type, properties, pageId, synced) {
    if(pageId == null && !this.page) {
      return;
    }

    if(id == null) {
      id = this.userService.getUniqueId(++maxShapeId);
    }
    if(pageId == null) {
      pageId = this.page.id;
    }
    if(synced == null) {
      synced = false;
    }

    // A "pristine" textbox is one that has been created by clicking inside the whiteboard with
    // the text tool, but hasn't been edited yet. These textboxes are not synced to other
    // participants, and are deleted if you click outside them without editing them.
    if(!('isPristine' in properties)) {
      properties.isPristine = (type === 'text' && !synced);
    }

    let shape = new Shape(id, type, properties, pageId);
    this.shapes[id] = shape;

    this._insertShapeIntoPage(shape);
    this._updateShapeBoundsOnAdd(shape);

    if(synced) {
      shape.addSynced = true;
      shape.setPropertiesSynced();
    }

    this.emit('shapeAdd', this, shape);
    return shape;
  }


  removeShape(shapeOrId, synced) {
    if(synced == null) {
      synced = false;
    }

    let shape = typeof shapeOrId === 'string' ? this.shapes[shapeOrId] : shapeOrId;
    if(!shape) {
      return;
    }

    delete this.shapes[shape.id];
    this._removeShapeFromPage(shape);

    if(synced) {
      shape.removeSynced = true;
    }
    this._resetShapeBounds();
    this.emit('shapeRemove', this, shape);
  }


  editShape(shape, update, synced, temporary) {
    this.editShapes([[shape, update]], synced, temporary);
  }


  editShapes(shapeInfos, synced, temporary) {
    if(shapeInfos.length === 0) {
      return;
    }
    if(synced == null) {
      synced = false;
    }
    if(temporary == null) {
      temporary = false;
    }

    let minimalShapeInfos = [];
    shapeInfos.forEach(shapeInfo => {
      let [shapeOrId, update] = shapeInfo;
      let shape = typeof shapeOrId === 'string' ? this.shapes[shapeOrId] : shapeOrId;
      if(!shape) {
        return;
      }

      let minimalUpdate = shape.update(update);
      if(synced) {
        shape.setPropertiesSynced();
      }
      if(shape.type === 'text' && 'fText' in minimalUpdate) {
        shape.properties.isPristine = false;
      }

      if(object.length(minimalUpdate) > 0 || !temporary && this.temporaryShapes.has(shape.id)) {
        minimalShapeInfos.push([shape, minimalUpdate]);
      }
      this.temporaryShapes[temporary ? 'add' : 'delete'](shape.id);
    });

    if(minimalShapeInfos.length > 0) {
      this._resetShapeBounds();
      this.emit('shapesEdit', this, minimalShapeInfos, temporary);
    }
  }


  _insertShapeIntoPage(shape) {
    if(!(shape.pageId in this.pageShapes)) {
      this.pageShapes[shape.pageId] = [];
    }
    let shapes = this.pageShapes[shape.pageId];

    let index = -1;
    if(this.addedShapeIds.has(shape.id)) {
      index = shapes.indexOf(shape.id);
    }
    if(index === -1) {
      index = shapes.length;
    }

    shapes[index] = shape;
    shape.indexOnPage = index;
    this.addedShapeIds.add(shape.id);
  }

  _removeShapeFromPage(shape) {
    let shapes = this.pageShapes[shape.pageId];
    let index = shapes.indexOf(shape);
    if(index !== -1) {
      // We keep the shapeId in this array so we can reinsert the shape in the correct position
      // if it is readded (eg. through undo).
      shapes[index] = shape.id;
    }
  }



  /**************
   * Add shapes *
   **************/
  addInitTextBox(position, pageId) {
    if(position == null) {
      position = {
        x: PAPER_OBJECT_MARGIN,
        y: PAPER_OBJECT_MARGIN
      };
    }

    let properties = {
      left: position.x,
      top: position.y,
      requestHeight: Shape.defaultTextHeight,

      text: Shape.defaultText,
      fText: Shape.defaultText,
      isPristine: false,
      isInitTextBox: true,
    };

    this.addShape(null, 'text', properties, pageId);
  }

  addImage(vecteraFile, position, scale, pageId, logoName) {
    position = Object.assign({}, position);

    if(vecteraFile.rotation === 90 || vecteraFile.rotation === 180) {
      position.x += vecteraFile.rect.width * scale;
    }
    if(vecteraFile.rotation === 180 || vecteraFile.rotation === 270) {
      position.y += vecteraFile.rect.height * scale;
    }

    let properties = {
      fileId: vecteraFile.id,
      logoName: logoName,
      left: position.x,
      top: position.y,

      width: vecteraFile.rect.width,
      height: vecteraFile.rect.height,

      scaleX: scale,
      scaleY: scale,

      angle: vecteraFile.rotation,
      flipX: vecteraFile.flip,
      flipY: false,
    };
    return this.addShape(null, 'image', properties, pageId);
  }
}
