import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { TimeSlot } from '../../containers/booking.container';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {
  @Input() public timeSlots!: TimeSlot[];
  @Input() public selectedDate!: Date;

  @Output() public timeSlotSelected = new EventEmitter<TimeSlot>();

  public timeZone = moment.tz.guess();

  public selectTimeSlot(timeSlot: TimeSlot): void {
    this.timeSlotSelected.emit(timeSlot);
  }
}
