import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TimeDelta } from 'utils/util';
import { AppointmentLocation } from '../../../models/appointment-location';
import { AppointmentType } from '../../../services/appointment-types-backend.service';

@Component({
  selector: 'appointment-type-card',
  templateUrl: './appointment-type-card.component.html',
  styleUrls: ['./appointment-type-card.component.scss']
})
export class AppointmentTypeCardComponent {
  @Input() public appointmentType!: AppointmentType;

  @Output() public scheduleMeetingClicked = new EventEmitter<AppointmentType>();

  @ViewChild('descriptionElement') public descriptionElement!: ElementRef;

  constructor() { }

  public readonly AppointmentLocation = AppointmentLocation;

  public get name(): string {
    return this.appointmentType.name;
  }

  public get description(): string {
    return this.appointmentType.description;
  }

  public get duration(): TimeDelta {
    return this.appointmentType.duration;
  }

  public get paymentAmount(): number | null {
    return this.appointmentType.paymentAmount;
  }

  public get paymentCurrency(): string {
    return this.appointmentType.paymentCurrency;
  }

  public isOverflown(): boolean {
    if(this.descriptionElement) {
      const scrollHeight = this.descriptionElement.nativeElement.scrollHeight;
      const clientHeight = this.descriptionElement.nativeElement.clientHeight;

      return scrollHeight > clientHeight;
    }
    return false;
  }

  public appointmentIs(location: AppointmentLocation): boolean {
    return this.appointmentType.locations.includes(location);
  }

  public scheduleMeeting(): void {
    this.scheduleMeetingClicked.emit(this.appointmentType);
  }
}
