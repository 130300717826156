<div class="appointment-type-summary__wrapper">
  <div class="d-flex flex-row align-items-center">
    <profile-images
      [users]="hosts"
      class="mr-3 d-none"
      [ngClass]="{
        'd-flex': hosts.length <= 3,
        'd-sm-flex': hosts.length <= 10
      }"
    ></profile-images>
    <div
      class="text-h3"
      [matTooltip]="hostNames.join('\n')"
      [matTooltipDisabled]="hosts.length <= maxHostsInTitle"
    >
      {{ hostNames | formatStringArray:maxHostsInTitle }}
    </div>
  </div>

  <h1 class="d-flex align-items-center mt-4 mb-4">
    <span *ngIf="instance.name">{{ instance.name }}</span>
    <span *ngIf="!instance.name" class="text-muted" i18n>(No name set)</span>

    <div *ngIf="hasPermissionToEdit" class="btn btn--square ml-2" (click)="openDetails()">
      <svg-icon class="btn__icon" [svgName]="'edit'"></svg-icon>
    </div>
  </h1>

  <p class="text-muted line-clamp-4" *ngIf="instance.description">
    {{ instance.description }}
  </p>
  <div class="d-flex flex-row p">
    <svg-icon class="icon mr-3" [svgName]="'marker'"></svg-icon>
    <span *ngIf="instance.locations.length === 0" class="text-muted" i18n>(No location set)</span>
    <div>
        {{sortedLocationsString}} <span *ngIf="instance.locations.includes('online')" class="ml-1 badge badge--info" i18n>Video Meeting Room</span>
    </div>
  </div>
  <div class="d-flex flex-row">
    <svg-icon class="icon mr-3" [svgName]="'clock'"></svg-icon>
    <span *ngIf="instance.duration" i18n>{{ instance.duration.minutes }} minutes</span>
    <span *ngIf="!instance.duration" class="text-muted" i18n>(No duration set)</span>
  </div>

  <hr class="appointment-type-summary__divider">

  <form [formGroup]="formGroup">
    <div>
      <input
        id="showPublic"
        type="checkbox"
        class="toggle"
        formControlName="showPublic"
        [withSettingsErrors]="errors.showPublic"
      />
      <label class="d-flex align-items-center" for="showPublic" class="toggle__label toggle__label--right" i18n>
        Publish to the public booking page
      </label>
    </div>
  </form>
</div>
