// siteColor is imported in the contactwidget script, which makes it fall under the same rule:
//
//     Do not import directly from `utils/util`, import from the submodule instead.
//
// See contactWidget/public/index.ts for more info
import * as color from 'utils/util/color';
import errors from 'utils/util/errors';

type COLOR_NAME =
  'text'
  | 'lightText'
  | 'link'
  | 'primaryButton'
  | 'primaryButtonHover'
  | 'buttonText'
  | 'lightButtonText';

const defaultColors: Record<COLOR_NAME, string> = buildDefaultColors();


function buildDefaultColors(doc: Document = document): Record<COLOR_NAME, color.HexColor> {
  return {
    text: color.propertyToHex('--teal-500', doc),
    lightText: color.propertyToHex('--neutral-100', doc),
    link: color.propertyToHex('--info-400', doc),

    primaryButton: color.propertyToHex('--brand-300', doc),
    primaryButtonHover: color.propertyToHex('--brand-400', doc),
    buttonText: color.propertyToHex('--neutral-100', doc),
    lightButtonText: color.propertyToHex('--teal-500', doc),
  };
}


/**
 * Call this method if you need to use the colors of a document that is not the top-level document
 * (i.e. an iframe).
 */
export function updateDefaultColors(doc: Document = document) {
  const colors = buildDefaultColors(doc);
  Object.assign(defaultColors, colors);
}


export function getDefaultColor(name: COLOR_NAME): color.HexColor {
  return defaultColors[name];
}

/**********/
/** TEXT **/
/**********/

/**
 * Selected text color can clash with the background colour. This function overrides requested
 * color to the light button colour if the given background is too dark for a dark text.
 */
export function getValidTextColor(
  requestedTextColor: string, backgroundColor: string
): color.HexColor {
  if(!color.isValidHexColor(backgroundColor)) {
    throw new errors.InvalidArgumentError('background text colour is not a hex colour');
  }
  if(!color.isValidHexColor(requestedTextColor)) {
    throw new errors.InvalidArgumentError('request text colour is not a hex colour');
  }

  const textColorHsl = color.hexToHsl(requestedTextColor);
  const backgroundColorHsl = color.hexToHsl(backgroundColor);
  if(textColorHsl.l > .6 && backgroundColorHsl.l > .6) {
    // light text on light background, change to black
    return getDefaultColor('text');
  } else if(textColorHsl.l < .4 && backgroundColorHsl.l < .4) {
    // dark text on dark background, change to black
    return getDefaultColor('lightText');
  } else {
    return requestedTextColor;
  }
}


/*************/
/** BUTTONS **/
/*************/

export function getButtonColor(argButtonColor: string): color.HexColor {
  let buttonColor;
  if(color.isValidHexColor(argButtonColor)) {
    buttonColor = argButtonColor;
  } else {
    buttonColor = getDefaultColor('primaryButton');
  }

  return buttonColor;
}


export function getButtonHoverColor(buttonColor: string): color.HexColor {
  let buttonHoverColor = getDefaultColor('primaryButtonHover');
  try {
    if(color.isValidHexColor(buttonColor)) {
      const buttonColorHsl = color.hexToHsl(buttonColor);
      if(buttonColorHsl.l > .2) {
        buttonColorHsl.l -= .08;
      } else {
        buttonColorHsl.l += .08;
      }
      buttonHoverColor = color.hslToHex(buttonColorHsl);
    }

  } catch(e) {
    // Do nothing
  }

  return buttonHoverColor;
}

/**
 * This function is made for the contact widget button and has some strange implementation details.
 * Preferred usage is to use the type-safe `getValidTextColor` instead
*/
export function getButtonTextColorDeprecated(
  argTextColor: string, buttonColor: string, defaultLight = false
) {
  let textColor = getDefaultColor('buttonText');
  if(defaultLight) {
    textColor = getDefaultColor('lightButtonText');
  }

  try {
    if(color.isValidHexColor(argTextColor)) {
      textColor = argTextColor;

    } else if(buttonColor) {
      const buttonColorHsl = color.hexToHsl(buttonColor);
      if(buttonColorHsl.l > .6) {
        textColor = getDefaultColor('lightButtonText');
      }
    }
  } catch(error) {
    // Do nothing
  }

  return textColor;
}
