import { Component, Inject, Input } from '@angular/core';
import { User } from 'core/models/User';
import { MeetingTemplate, OrganizationMeetingTemplate } from 'meeting/models/MeetingTemplate';
import { PickHostMethod, MAX_BOOKING_DAYS } from 'scheduling/models/AppointmentType';
import {
  SettingsComponent
} from 'utils/settings/settings.component';
import { logger } from 'utils/util';

import { Memoize } from 'typescript-memoize';
import { DropdownOption } from 'utils/form-elements/dropdowns';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';
import { FeatureFlagService } from 'utils/featureFlags/feature-flag.service';
import * as flags from 'utils/featureFlags/flags';
import { AppointmentLocation } from '../../../../models/appointment-location';

type State = 'initializing' | 'loading' | 'ready';


@Component({
  selector: 'appointment-type-details[instance]',
  templateUrl: './appointment-type-details.component.html',
  styleUrls: ['./appointment-type-details.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeDetailsComponent
  }]
})
export class AppointmentTypeDetailsComponent
  extends AppointmentTypeSettingsComponent {
  readonly PickHostMethod = PickHostMethod;

  @Input() teamMembers: User[] = [];

  private _shouldShowAdvanced = false;

  public MAX_BOOKING_DAYS = MAX_BOOKING_DAYS;

  public meetingTemplates: MeetingTemplate[] = [];
  public meetingTemplatesState: State = 'initializing';
  public meetingTemplatesError: string | null = null;
  public meetingTemplateOptions: DropdownOption[] = [];

  static override formFields = [
    'name',
    'description',
    'duration',
    'locations',
    'offlineAddress',
    'isTeamAppointmentType',
    'pickHostMethod',
    'meetingTemplateId',
    'customFromDate',
    'customToDate',
    'timeSlotsInterval',
    'customMaxDays',
    'customBuffer',
    'bufferBefore',
    'bufferAfter',
    'numExtraInviteesAllowed',
    'allowGuestCancellationReschedule',
  ];


  constructor(
    @Inject('modelFactory') private modelFactory,

    private featureFlagService: FeatureFlagService
  ) {
    super();
  }

  extraInviteesCannotBeNull() {
    if(this.instance.numExtraInviteesAllowed == null) {
      this.instance.numExtraInviteesAllowed = 0;
    }
  }

  get confirmNameChangeDialogText() {
    // eslint-disable-next-line max-len
    return $localize`<p>When you change the name of your meeting type, this will impact both <b>the meeting type URL</b> and <b>the embedded link of your meeting type</b>.</p><p>Are you sure you want to change the name of the meeting type?</p>`;
  }

  get shouldShowOfflineAddress() {
    const locations = this.instance.locations as AppointmentLocation[];
    return locations.includes(AppointmentLocation.OFFLINE);
  }

  get shouldShowAdvanced() {
    return this._shouldShowAdvanced;
  }
  set shouldShowAdvanced(shouldShowAdvanced: boolean) {
    this._shouldShowAdvanced = shouldShowAdvanced;
    if(shouldShowAdvanced) {
      this.fetchMeetingTemplates();
    }
  }


  @Memoize()
  async fetchMeetingTemplates() {
    this.meetingTemplatesState = 'loading';
    try {
      const response = await this.modelFactory.list({
        model: OrganizationMeetingTemplate,
        identifiers: { organizationId: this.requestUserService.user.organizationId },
      });
      this.meetingTemplates = response.data;
      this.meetingTemplateOptions = this.meetingTemplates.map((template) => {
        return ({ 'label': template.name, 'value': template.id });
      });
    } catch(error: any) {
      logger.warn(error);
      this.meetingTemplatesError = error;
    }
    this.meetingTemplatesState = 'ready';
  }

  get meetingTemplateUrl(): string {
    const selectedMeetingTemplate = this.meetingTemplates.find(
      template => template.id === this.instance.meetingTemplateId
    );
    return selectedMeetingTemplate?.url;
  }


  get shouldShowTeamAppointmentType() {
    return this.teamMembers.length > 1;
  }

  get customBufferNullAlias() {
    return $localize `Next working day`;
  }

  get appointmentBufferAlias() {
    return $localize `No buffer`;
  }

  get templateNullText() {
    return $localize `Empty meeting room`;
  }


  get teamAppointmentTypeOptions() {
    return [
      {
        label: $localize `Personal`,
        value: false,
      },
      {
        label: $localize `Team`,
        value: true,
      },
    ];
  }

  get shouldShowCancellationNewBadge() {
    return this.featureFlagService.isEnabled(flags.CANCELLATION_BADGE, false);
  }
}
