import { Injectable, Inject } from '@angular/core';
import { UsageTrackingService } from 'utils/usage-tracking.service';
import { ViewOrganizationService } from 'utils/view-organization.service';
import { Transcript } from './smart-summary.service';

@Injectable({
  providedIn: 'root',
})
export class SmartSummaryTrackingService {
  constructor(
    @Inject('meetingService') private meetingService,
    @Inject('userService') private userService,

    private usageTrackingService: UsageTrackingService,
    private viewOrganizationService: ViewOrganizationService,
  ) { }


  public trackStartedTranscription(): void {
    this.track('meetingRoom.startedTranscription', 'meetingRoom');
  }

  public trackStoppedTranscription(): void {
    this.track('meetingRoom.stoppedTranscription', 'meetingRoom');
  }

  public trackStoppedTranscriptionWhenLeavingMeetingRoom(): void {
    this.track('meetingRoom.stoppedTranscriptionWhenLeavingMeetingRoom', 'meetingRoom');
  }

  public trackSummaryGenerated(transcript: Transcript) {
    this.track(
      'meetingRoom.summaryGenerated',
      'meetingRoom',
      { transcriptDuration: transcript.duration }
    );
  }

  public trackSummaryCopied(): void {
    this.track('meetingRoom.summaryCopied', 'meetingRoom');
  }

  public trackSummarySharedViaEmail(): void {
    this.track('meetingRoom.summarySharedViaEmail', 'meetingRoom');
  }

  public trackSummarySharedToAdminpulse(): void {
    this.track('meetingRoom.summarySharedToAdminpulse', 'meetingRoom');
  }
  public trackSummaryExportedToWhiteboard() {
    this.track('meetingRoom.summaryExportedToWhiteboard', 'meetingRoom');
  }

  private track(event: string, source: string, properties: object | null = null): void {
    properties = properties || {};

    properties = {
      ...properties,
      meetingId: this.meetingService.id,
      numActiveSessions: this.userService.aliveUsers?.length,
      activeSessionIds: this.userService.aliveSessions?.map(session => session.id),
      activeUserIds: this.userService.aliveUsers?.map(user => user.id),
      numHosts: this.userService.aliveHosts?.length,
      hostSessionIds: this.userService.aliveSessions?.filter(
        session => session.accessLevel.isHost
      ).map(session => session.id),
      hostUserIds: this.userService.aliveHosts?.map(host => host.id),
      accountId: this.viewOrganizationService.organization.id,
      sessionId: this.userService.mySession.id,
      userId: this.userService.me.id,
    };
    this.usageTrackingService.createSegmentEvent(event, source, properties);
  }
}
