import { Component } from '@angular/core';
import { ComboBoxInput } from 'utils/form-elements/dropdowns/combo-box/combo-box.component';
import { countries } from 'utils/util/countries';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


type Address = {
  country: string;
  street: string;
  number: string;
  zipcode: string;
  city: string;
};


@Component({
  selector: 'address-input',
  templateUrl: './address-input.component.html',
})
export class AddressInputComponent extends ContactFormQuestionInputComponent<Address> {

  countryOptions: ComboBoxInput = [
    {
      options: countries.primary.map(country => {
        return { label: country.name, value: country.code };
      }),
    },
    {
      options: countries.all.map(country => {
        return { label: country.name, value: country.code };
      })
    },
  ];

  parsePrefill(string: string) {
    const strings = string.split(',');
    if(strings.length === 5) {
      const [street, number, zipcode, city, country] = strings;
      return {
        street,
        number,
        zipcode,
        city,
        country,
      };
    } else {
      return null;
    }
  }


  getSubValue(key: keyof Address) {
    return this.value ? this.value[key] : '';
  }
  setSubValue(key: keyof Address, subValue: string) {
    const value = {
      street: '',
      number: '',
      zipcode: '',
      city: '',
      country: '',
      ...this.value,
      [key]: subValue,
    };
    this.writeValue(value);
  }
}
