import { array } from 'utils/util';
import { AddImageType } from '../whiteboard.service';


export default class FilePickerService {
  static get $inject() {
    return [
      '$rootElement',
      'notificationService',
      'whiteboardService',
    ];
  }

  constructor(
    $rootElement,
    notificationService,
    whiteboardService
  ) {
    this._bind();

    this.$rootElement = $rootElement;
    this.notificationService = notificationService;
    this.whiteboardService = whiteboardService;

    this.$elemFilePicker = angular.element(`
      <iframe
        class="file-picker__iframe"
        src="${window.URLS.filePicker}"
      ></iframe>
    `);
    this.hide();
    $rootElement.append(this.$elemFilePicker);

    this.handlers = {};

    window.addEventListener('message', this._onWindowMessage);
    this.on('error', this._onError);
    this.on('warning', this._onWarning);
    this.on('create', this._onCreate);
    this.on('show', this.show);
    this.on('hide', this.hide);
  }

  _bind() {
    this._onWindowMessage = this._onWindowMessage.bind(this);
    this._onError = this._onError.bind(this);
    this._onWarning = this._onWarning.bind(this);
    this._onCreate = this._onCreate.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }


  on(channel, callback) {
    if(!(channel in this.handlers)) {
      this.handlers[channel] = [];
    }

    this.handlers[channel].push(callback);
  }

  off(channel, callback) {
    if(!(channel in this.handlers)) {
      return;
    }

    array.remove(this.handlers[channel], callback);
  }


  sendMessage(channel, message) {
    this.$elemFilePicker[0].contentWindow.postMessage({
      type: 'vecteraFilePicker',
      data: {
        channel,
        message,
      },
    }, 'https://' + ANGULAR_SCOPE.standaloneSiteDomain);
  }

  sendPickerMessage(channel) {
    let activeWb = this.whiteboardService.getActiveWhiteboard();
    this.sendMessage(channel, {
      addImageType: AddImageType.ACTIVE_PAGE,
      whiteboardId: activeWb.id
    });
  }

  _onWindowMessage(event) {
    if(
      event.origin !== 'https://' + ANGULAR_SCOPE.standaloneSiteDomain
      || event.data.type !== 'vecteraFilePicker'
    ) {
      return;
    }

    $rootScope.$evalAsync(() => {
      let { channel, message } = event.data.data;
      let handlers = this.handlers[channel] || [];
      handlers.forEach(handler => handler(message));
    });
  }


  _onCreate(message) {
    let whiteboard = this.whiteboardService.get(message.whiteboardId);
    this.whiteboardService.addCloudFiles(
      message.cloudFileInfos,
      message.addImageType,
      whiteboard
    );
  }

  _onError(message) {
    this.notificationService.error(message.error);
  }

  _onWarning(message) {
    this.notificationService.warning(message.warning);
  }


  show() {
    this.$elemFilePicker.removeClass('file-picker__iframe--hidden');
  }
  hide() {
    this.$elemFilePicker.addClass('file-picker__iframe--hidden');
  }
}
