import { Injectable } from '@angular/core';
import { AdminPulseService } from 'integrations/adminpulse/adminpulse.service';
import { HubSpotService } from 'integrations/hubspot/hubspot.service';
import { BroadcastService } from 'meeting/meeting-room/communication/broadcast.service';
import { SmartSummaryService } from 'meeting/smart-summary/smart-summary.service';
import { PrivateNotesService } from '../private-notes/private-notes.service';
import { IntegrationSyncService, State } from './integration-sync.service';


const RECENT_NOTES_CUTOFF = 15 * 60 * 1000;  // 15 min


@Injectable({ providedIn: 'root' })
export class NotesSyncService extends IntegrationSyncService {
  constructor(
    adminPulseService: AdminPulseService,
    hubSpotService: HubSpotService,
    private meetingBroadcastService: BroadcastService,
    private privateNotesService: PrivateNotesService,
    private smartSummaryService: SmartSummaryService,
  ) {
    super(adminPulseService, hubSpotService);

    // only set state to dirty after 15
    this.privateNotesService.eventEmitter.on('edited', (_content: string, timestamp: Date) => {
      if (timestamp == null || this.editedLessThen15MinutesAgo(timestamp)) {
        this.state = State.DIRTY;
      }
    });

    meetingBroadcastService.on('private-notes-syncing', () => this.state = State.SYNCING, true);
    meetingBroadcastService.on('private-notes-synced', () => this.state = State.SYNCED, true);
    meetingBroadcastService.on('private-notes-sync-failed', () => this.state = State.DIRTY, true);
  }

  private editedLessThen15MinutesAgo(timestamp: Date): boolean {
    return Date.now() - timestamp.getTime() < RECENT_NOTES_CUTOFF;
  }

  public canSaveContent(): boolean {
    return (
      this.state === State.DIRTY
      && this.isActive()
      && !!this.privateNotesService.content
      && this.syncingIsEnabled()
    );
  }

  public syncingIsEnabled(): boolean {
    return (
      this.smartSummaryService.state.stage !== 'summary-generated'
      || !this.smartSummaryService.canUseSmartSummary
    );
  }

  public async withSyncStateManagement(syncFn: () => Promise<void>): Promise<void> {
    if (this.state !== State.DIRTY) {
      return;
    }

    this.meetingBroadcastService.send('private-notes-syncing', true, []);
    try {
      await syncFn();
    } catch (error) {
      // We already show notifications when one of these integrations fails, but we still need to
      // know the fact that an error occurred so we can set the sync state back to dirty.
      this.meetingBroadcastService.send('private-notes-sync-failed', true, []);
      return;
    }
    this.meetingBroadcastService.send('private-notes-synced', true, []);
  }
}
