import { bind } from 'utils/util';

export default class ActiveCampaignController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    bind(this);

    this.$elem = $elem;
    this.$elemCreateDeal = $elem.find('input[name="generate_deal"]');
    this.$elemAddToList = $elem.find('input[name="add_new_contact_to_list"]');
    this.$elemPipeline = $elem.find('select[name="pipeline"]');
    this.$elemStage = $elem.find('select[name="stage"]');
    this.$elemGenerateNewContact = $elem.find('input[name="generate_contact"]');

    this.$elemAddToListFormDisabled = this.$elemAddToList[0].disabled;
    this.$elemCreateDealFormDisabled = this.$elemCreateDeal[0].disabled;

    this.pipelineId = null;
    this.initialStageId = this.$elemStage.val();

    this._togglePipelineAndStage();
    this._toggleList();
    this._disableOptions();
    this._afterInitialization();
  }

  _afterInitialization() {
    this.$elemCreateDeal.change(this._togglePipelineAndStage);
    this.$elemAddToList.change(this._toggleList);
    this.$elemGenerateNewContact.change(this._disableOptions);
    this.$elemPipeline.change(this._handlePipelineChanged);
  }

  _togglePipelineAndStage() {
    this.$elemPipeline.parent().toggle(this.$elemCreateDeal.is(':checked'));
    this._handlePipelineChanged();
    this.$elemStage.parent().toggle(this.$elemCreateDeal.is(':checked'));
  }

  _toggleList() {
    let $elemContactList = this.$elem.find('select[name="contact_list"]');
    $elemContactList.parent().toggle(this.$elemAddToList.is(':checked'));
  }

  _handlePipelineChanged() {
    let pipelineId = this.$elemPipeline.val();
    this._filterStages(pipelineId);
  }

  _filterStages(pipelineId) {
    this.$elemStage.find('option').hide();
    let $elemValidOptions = this.$elemStage.find(
      'option[value*="pipeline_id=' + pipelineId + '"]'
    );
    $elemValidOptions.show();
    let validOptions = $elemValidOptions.map((_idx, option) => {
      return option.value;
    }).get();
    let value = (
      validOptions && validOptions.includes(this.initialStageId) ?
        this.initialStageId :
        $elemValidOptions.first().val()
    );
    this.$elemStage.val(value);
  }

  _disableOptions() {
    let isChecked = this.$elemGenerateNewContact[0].checked;
    this.$elemAddToList[0].disabled = !isChecked || this.$elemAddToListFormDisabled;
    this.$elemCreateDeal[0].disabled = !isChecked || this.$elemCreateDealFormDisabled;

    if(!isChecked) {
      this.$elemAddToList[0].checked = false;
      this.$elemCreateDeal[0].checked = false;
    }

    this.$elemAddToList.change();
    this.$elemCreateDeal.change();
  }
}
