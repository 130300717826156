/* eslint-disable max-len */
export { default as contactForm__highFive } from 'contactForm/icons/high-five.svg?raw';
export { default as contactForm__welcomeCreate } from 'contactForm/icons/welcome-create.svg?raw';
export { default as contactForm__welcomeLeads } from 'contactForm/icons/welcome-leads.svg?raw';
export { default as contactForm__welcomeShare } from 'contactForm/icons/welcome-share.svg?raw';
export { default as contactForm__customizeUpgrade } from 'contactForm/icons/customize-upgrade.svg?raw';
export { default as contactForm__legal } from 'contactForm/icons/legal.svg?raw';
export { default as contactWidget__contactTypeCalendar } from 'contactWidget/icons/contactType-calendar.svg?raw';
export { default as contactWidget__contactTypeForm } from 'contactWidget/icons/contactType-form.svg?raw';
export { default as contactWidget__contactTypeMeetingRoom } from 'contactWidget/icons/contactType-meeting-room.svg?raw';
export { default as contactWidget__welcomeAddToWebsite } from 'contactWidget/icons/welcome-add-to-website.svg?raw';
export { default as contactWidget__welcomeAttractCustomers } from 'contactWidget/icons/welcome-attract-customers.svg?raw';
export { default as contactWidget__welcomeCreateButton } from 'contactWidget/icons/welcome-create-button.svg?raw';
export { default as dashboard_angularjs_onboarding__corporate } from 'dashboard/angularjs/onboarding/icons/corporate.svg?raw';
export { default as dashboard_angularjs_onboarding__medium } from 'dashboard/angularjs/onboarding/icons/medium.svg?raw';
export { default as dashboard_angularjs_onboarding__accountancy } from 'dashboard/angularjs/onboarding/icons/accountancy.svg?raw';
export { default as dashboard_angularjs_onboarding__consulting } from 'dashboard/angularjs/onboarding/icons/consulting.svg?raw';
export { default as dashboard_angularjs_onboarding__education } from 'dashboard/angularjs/onboarding/icons/education.svg?raw';
export { default as dashboard_angularjs_onboarding__healthcare } from 'dashboard/angularjs/onboarding/icons/healthcare.svg?raw';
export { default as dashboard_angularjs_onboarding__other } from 'dashboard/angularjs/onboarding/icons/other.svg?raw';
export { default as dashboard_angularjs_onboarding__sales } from 'dashboard/angularjs/onboarding/icons/sales.svg?raw';
export { default as dashboard_angularjs_onboarding__paperplane } from 'dashboard/angularjs/onboarding/icons/paperplane.svg?raw';
export { default as dashboard_angularjs_onboarding__small } from 'dashboard/angularjs/onboarding/icons/small.svg?raw';
export { default as dashboard_angularjs_onboarding__solo } from 'dashboard/angularjs/onboarding/icons/solo.svg?raw';
export { default as dashboard_angularjs_onboarding__waitingroom } from 'dashboard/angularjs/onboarding/icons/waitingroom.svg?raw';
export { default as dashboard__meetingWelcome } from 'dashboard/icons/meeting-welcome.svg?raw';
export { default as dashboard__noCalendarConnected } from 'dashboard/icons/no-calendar-connected.svg?raw';
export { default as dashboard__noMeetings } from 'dashboard/icons/no-meetings.svg?raw';
export { default as dashboard__noTeamMembers } from 'dashboard/icons/no-team-members.svg?raw';
export { default as dashboard__schedulingWelcome } from 'dashboard/icons/scheduling-welcome.svg?raw';
export { default as dashboard__tlFormsWelcome } from 'dashboard/icons/tl-forms-welcome.svg?raw';
export { default as dashboard__tlSchedulingWelcome } from 'dashboard/icons/tl-scheduling-welcome.svg?raw';
export { default as meeting__expand } from 'meeting/icons/expand.svg?raw';
export { default as meeting__eye } from 'meeting/icons/eye.svg?raw';
export { default as meeting__save } from 'meeting/icons/save.svg?raw';
export { default as meeting__arrow } from 'meeting/icons/arrow.svg?raw';
export { default as meeting__audio } from 'meeting/icons/audio.svg?raw';
export { default as meeting__audiovideo } from 'meeting/icons/audiovideo.svg?raw';
export { default as meeting__audiovideo_small } from 'meeting/icons/audiovideo_small.svg?raw';
export { default as meeting__audio_indicator } from 'meeting/icons/audio_indicator.svg?raw';
export { default as meeting__audio_small } from 'meeting/icons/audio_small.svg?raw';
export { default as meeting__signal_strength } from 'meeting/icons/signal_strength.svg?raw';
export { default as integrations__hubspot_logo } from 'integrations/icons/hubspot-logo.svg?raw';
export { default as integrations__adminpulse_logo } from 'integrations/icons/adminpulse-logo.svg?raw';
export { default as scheduling__48x48_custom_questions } from 'scheduling/icons/48x48_custom_questions.svg?raw';
export { default as scheduling__48x48_email_templates } from 'scheduling/icons/48x48_email_templates.svg?raw';
export { default as scheduling__48x48_payments } from 'scheduling/icons/48x48_payments.svg?raw';
export { default as scheduling__48x48_redirecting } from 'scheduling/icons/48x48_redirecting.svg?raw';
export { default as scheduling__48x48_reminders } from 'scheduling/icons/48x48_reminders.svg?raw';
export { default as illustrations__18x18_teamleader_badge } from './illustrations/18x18_teamleader_badge.svg?raw';
export { default as illustrations__60x60_upload_static } from './illustrations/60x60_upload_static.svg?raw';
export { default as illustrations__84x84_snippet } from './illustrations/84x84_snippet.svg?raw';
export { default as illustrations__84x84_snippet_mail } from './illustrations/84x84_snippet_mail.svg?raw';
export { default as illustrations__girl } from './illustrations/girl.svg?raw';
export { default as utils__loading } from './loading.svg?raw';
export { default as logo__standalone_compact } from './logo/standalone/compact.svg?raw';
export { default as logo__standalone_compact_monochrome } from './logo/standalone/compact_monochrome.svg?raw';
export { default as logo__standalone_full } from './logo/standalone/full.svg?raw';
export { default as logo__standalone_full_monochrome } from './logo/standalone/full_monochrome.svg?raw';
export { default as logo__standalone_icon } from './logo/standalone/icon.svg?raw';
export { default as logo__standalone_icon_monochrome } from './logo/standalone/icon_monochrome.svg?raw';
export { default as logo__standalone_icon_nopadding_monochrome } from './logo/standalone/icon_nopadding_monochrome.svg?raw';
export { default as logo__standalone_inline_monochrome } from './logo/standalone/inline_monochrome.svg?raw';
export { default as logo__teamleader_compact } from './logo/teamleader/compact.svg?raw';
export { default as logo__teamleader_compact_monochrome } from './logo/teamleader/compact_monochrome.svg?raw';
export { default as logo__teamleader_full } from './logo/teamleader/full.svg?raw';
export { default as logo__teamleader_full_monochrome } from './logo/teamleader/full_monochrome.svg?raw';
export { default as logo__teamleader_icon } from './logo/teamleader/icon.svg?raw';
export { default as logo__teamleader_icon_monochrome } from './logo/teamleader/icon_monochrome.svg?raw';
export { default as logo__teamleader_icon_nopadding_monochrome } from './logo/teamleader/icon_nopadding_monochrome.svg?raw';
export { default as logo__teamleader_inline_monochrome } from './logo/teamleader/inline_monochrome.svg?raw';
export { default as tl__14x14_add_badged_filled } from './tl/14x14_add_badged_filled.svg?raw';
export { default as tl__14x14_add_outline } from './tl/14x14_add_outline.svg?raw';
export { default as tl__14x14_attach_file_outline } from './tl/14x14_attach_file_outline.svg?raw';
export { default as tl__14x14_building_outline } from './tl/14x14_building_outline.svg?raw';
export { default as tl__14x14_calendar_outline } from './tl/14x14_calendar_outline.svg?raw';
export { default as tl__14x14_checkmark_outline } from './tl/14x14_checkmark_outline.svg?raw';
export { default as tl__14x14_clock_outline } from './tl/14x14_clock_outline.svg?raw';
export { default as tl__14x14_close_outline } from './tl/14x14_close_outline.svg?raw';
export { default as tl__14x14_code_outline } from './tl/14x14_code_outline.svg?raw';
export { default as tl__14x14_copy_outline } from './tl/14x14_copy_outline.svg?raw';
export { default as tl__14x14_chevron_right_outline } from './tl/14x14_chevron_right_outline.svg?raw';
export { default as tl__14x14_chevron_left_outline } from './tl/14x14_chevron_left_outline.svg?raw';
export { default as tl__14x14_credit_card_outline } from './tl/14x14_credit_card_outline.svg?raw';
export { default as tl__14x14_edit_outline } from './tl/14x14_edit_outline.svg?raw';
export { default as tl__14x14_euro_outline } from './tl/14x14_euro_outline.svg?raw';
export { default as tl__14x14_external_link_outline } from './tl/14x14_external_link_outline.svg?raw';
export { default as tl__14x14_globe_outline } from './tl/14x14_globe_outline.svg?raw';
export { default as tl__14x14_info_badged_filled } from './tl/14x14_info_badged_filled.svg?raw';
export { default as tl__14x14_lock_outline } from './tl/14x14_lock_outline.svg?raw';
export { default as tl__14x14_mail_outline } from './tl/14x14_mail_outline.svg?raw';
export { default as tl__14x14_marker_outline } from './tl/14x14_marker_outline.svg?raw';
export { default as tl__14x14_phone_outline } from './tl/14x14_phone_outline.svg?raw';
export { default as tl__14x14_search_outline } from './tl/14x14_search_outline.svg?raw';
export { default as tl__14x14_warning_badged_filled } from './tl/14x14_warning_badged_filled.svg?raw';
export { default as tl__14x14_warning_outline } from './tl/14x14_warning_outline.svg?raw';
export { default as tl__24x24_add_badged_outline } from './tl/24x24_add_badged_outline.svg?raw';
export { default as tl__24x24_add_outline } from './tl/24x24_add_outline.svg?raw';
export { default as tl__24x24_arrow_all_outline } from './tl/24x24_arrow_all_outline.svg?raw';
export { default as tl__24x24_arrow_big_up_filled } from './tl/24x24_arrow_big_up_filled.svg?raw';
export { default as tl__24x24_arrow_left_outline } from './tl/24x24_arrow_left_outline.svg?raw';
export { default as tl__24x24_arrow_long_right_outline } from './tl/24x24_arrow_long_right_outline.svg?raw';
export { default as tl__24x24_arrow_right_outline } from './tl/24x24_arrow_right_outline.svg?raw';
export { default as tl__24x24_attach_file_outline } from './tl/24x24_attach_file_outline.svg?raw';
export { default as tl__24x24_bell_off_outline } from './tl/24x24_bell_off_outline.svg?raw';
export { default as tl__24x24_bell_outline } from './tl/24x24_bell_outline.svg?raw';
export { default as tl__24x24_block_outline } from './tl/24x24_block_outline.svg?raw';
export { default as tl__24x24_books_outline } from './tl/24x24_books_outline.svg?raw';
export { default as tl__24x24_branding_outline } from './tl/24x24_branding_outline.svg?raw';
export { default as tl__24x24_building_outline } from './tl/24x24_building_outline.svg?raw';
export { default as tl__24x24_building_add_outline } from './tl/24x24_building_add_outline.svg?raw';
export { default as tl__24x24_calendar_edit_outline } from './tl/24x24_calendar_edit_outline.svg?raw';
export { default as tl__24x24_calendar_outline } from './tl/24x24_calendar_outline.svg?raw';
export { default as tl__24x24_camera_outline } from './tl/24x24_camera_outline.svg?raw';
export { default as tl__24x24_camera_switch_outline } from './tl/24x24_camera_switch_outline.svg?raw';
export { default as tl__24x24_checkmark_add_badged_outline } from './tl/24x24_checkmark_badged_add_outline.svg?raw'; // faulty naming scheme is present in source library, we fix it by using the correct scheme for the import.
export { default as tl__24x24_checkmark_badged_filled } from './tl/24x24_checkmark_badged_filled.svg?raw';
export { default as tl__24x24_checkmark_badged_outline } from './tl/24x24_checkmark_badged_outline.svg?raw';
export { default as tl__24x24_checkmark_outline } from './tl/24x24_checkmark_outline.svg?raw';
export { default as tl__24x24_chevron_down_outline } from './tl/24x24_chevron_down_outline.svg?raw';
export { default as tl__24x24_chevron_left_outline } from './tl/24x24_chevron_left_outline.svg?raw';
export { default as tl__24x24_chevron_right_outline } from './tl/24x24_chevron_right_outline.svg?raw';
export { default as tl__24x24_chevron_up_outline } from './tl/24x24_chevron_up_outline.svg?raw';
export { default as tl__24x24_circle_outline } from './tl/24x24_circle_outline.svg?raw';
export { default as tl__24x24_clock_outline } from './tl/24x24_clock_outline.svg?raw';
export { default as tl__24x24_close_badged_filled } from './tl/24x24_close_badged_filled.svg?raw';
export { default as tl__24x24_close_badged_outline } from './tl/24x24_close_badged_outline.svg?raw';
export { default as tl__24x24_close_outline } from './tl/24x24_close_outline.svg?raw';
export { default as tl__24x24_cloud_queue_outline } from './tl/24x24_cloud_queue_outline.svg?raw';
export { default as tl__24x24_cloud_upload_outline } from './tl/24x24_cloud_upload_outline.svg?raw';
export { default as tl__24x24_cobrowse_outline } from './tl/24x24_cobrowse_outline.svg?raw';
export { default as tl__24x24_code_outline } from './tl/24x24_code_outline.svg?raw';
export { default as tl__24x24_computer_outline } from './tl/24x24_computer_outline.svg?raw';
export { default as tl__24x24_contacts_outline } from './tl/24x24_contacts_outline.svg?raw';
export { default as tl__24x24_copy_outline } from './tl/24x24_copy_outline.svg?raw';
export { default as tl__24x24_credit_card_outline } from './tl/24x24_credit_card_outline.svg?raw';
export { default as tl__24x24_cursor_outline } from './tl/24x24_cursor_outline.svg?raw';
export { default as tl__24x24_datagrid_outline } from './tl/24x24_datagrid_outline.svg?raw';
export { default as tl__24x24_desktop_outline } from './tl/24x24_desktop_outline.svg?raw';
export { default as tl__24x24_document_outline } from './tl/24x24_document_outline.svg?raw';
export { default as tl__24x24_download_outline } from './tl/24x24_download_outline.svg?raw';
export { default as tl__24x24_drag_filled } from './tl/24x24_drag_filled.svg?raw';
export { default as tl__24x24_dropbox_filled } from './tl/24x24_dropbox_filled.svg?raw';
export { default as tl__24x24_edit_outline } from './tl/24x24_edit_outline.svg?raw';
export { default as tl__24x24_elipse_outline } from './tl/24x24_elipse_outline.svg?raw';
export { default as tl__24x24_ellipsis_outline } from './tl/24x24_ellipsis_outline.svg?raw';
export { default as tl__24x24_eraser_outline } from './tl/24x24_eraser_outline.svg?raw';
export { default as tl__24x24_exit_to_app_outline } from './tl/24x24_exit_to_app_outline.svg?raw';
export { default as tl__24x24_extension_outline } from './tl/24x24_extension_outline.svg?raw';
export { default as tl__24x24_external_link_outline } from './tl/24x24_external_link_outline.svg?raw';
export { default as tl__24x24_front_hand_outline } from './tl/24x24_front_hand_outline.svg?raw';
export { default as tl__24x24_fullscreen_exit_outline } from './tl/24x24_fullscreen_exit_outline.svg?raw';
export { default as tl__24x24_fullscreen_outline } from './tl/24x24_fullscreen_outline.svg?raw';
export { default as tl__24x24_geometric_outline } from './tl/24x24_geometric_outline.svg?raw';
export { default as tl__24x24_gift_outline } from './tl/24x24_gift_outline.svg?raw';
export { default as tl__24x24_globe_outline } from './tl/24x24_globe_outline.svg?raw';
export { default as tl__24x24_google_drive_filled } from './tl/24x24_google_drive_filled.svg?raw';
export { default as tl__24x24_graph_trending_up_outline } from './tl/24x24_graph_trending_up__outline.svg?raw';
export { default as tl__24x24_greek_lowercase_outline } from './tl/24x24_greek_lowercase_outline.svg?raw';
export { default as tl__24x24_greek_uppercase_outline } from './tl/24x24_greek_uppercase_outline.svg?raw';
export { default as tl__24x24_grid_filled } from './tl/24x24_grid_filled.svg?raw';
export { default as tl__24x24_hamburger_outline } from './tl/24x24_hamburger_outline.svg?raw';
export { default as tl__24x24_handshake_outline } from './tl/24x24_handshake_outline.svg?raw';
export { default as tl__24x24_help_badged_outline } from './tl/24x24_help_badged_outline.svg?raw';
export { default as tl__24x24_highlighter_outline } from './tl/24x24_highlighter_outline.svg?raw';
export { default as tl__24x24_home_outline } from './tl/24x24_home_outline.svg?raw';
export { default as tl__24x24_image_add_outline } from './tl/24x24_image_add_outline.svg?raw';
export { default as tl__24x24_infinity_outline } from './tl/24x24_infinity_outline.svg?raw';
export { default as tl__24x24_info_badged_filled } from './tl/24x24_info_badged_filled.svg?raw';
export { default as tl__24x24_info_badged_outline } from './tl/24x24_info_badged_outline.svg?raw';
export { default as tl__24x24_keyboard_outline } from './tl/24x24_keyboard_outline.svg?raw';
export { default as tl__24x24_line_outline } from './tl/24x24_line_outline.svg?raw';
export { default as tl__24x24_link_outline } from './tl/24x24_link_outline.svg?raw';
export { default as tl__24x24_list_outline } from './tl/24x24_list_outline.svg?raw';
export { default as tl__24x24_lock_open_outline } from './tl/24x24_lock_open_outline.svg?raw';
export { default as tl__24x24_lock_outline } from './tl/24x24_lock_outline.svg?raw';
export { default as tl__24x24_mail_outline } from './tl/24x24_mail_outline.svg?raw';
export { default as tl__24x24_marker_outline } from './tl/24x24_marker_outline.svg?raw';
export { default as tl__24x24_meeting_room_outline } from './tl/24x24_meeting_room_outline.svg?raw';
export { default as tl__24x24_message_feedback_outline } from './tl/24x24_message_feedback_outline.svg?raw';
export { default as tl__24x24_message_text_outline } from './tl/24x24_message_text_outline.svg?raw';
export { default as tl__24x24_mic_filled } from './tl/24x24_mic_filled.svg?raw';
export { default as tl__24x24_mic_off_outline } from './tl/24x24_mic_off_outline.svg?raw';
export { default as tl__24x24_minimize_outline } from './tl/24x24_minimize_outline.svg?raw';
export { default as tl__24x24_minus_outline } from './tl/24x24_minus_outline.svg?raw';
export { default as tl__24x24_money_outline } from './tl/24x24_money_outline.svg?raw';
export { default as tl__24x24_more_outline } from './tl/24x24_more_outline.svg?raw';
export { default as tl__24x24_note_outline } from './tl/24x24_note_outline.svg?raw';
export { default as tl__24x24_one_drive_filled } from './tl/24x24_one_drive_filled.svg?raw';
export { default as tl__24x24_overview_outline } from './tl/24x24_overview_outline.svg?raw';
export { default as tl__24x24_pause_filled } from './tl/24x24_pause_filled.svg?raw';
export { default as tl__24x24_phone_end_outline } from './tl/24x24_phone_end_outline.svg?raw';
export { default as tl__24x24_phone_outline } from './tl/24x24_phone_outline.svg?raw';
export { default as tl__24x24_picture_in_picture_alt_outline } from './tl/24x24_picture_in_picture_alt_outline.svg?raw';
export { default as tl__24x24_picture_in_picture_outline } from './tl/24x24_picture_in_picture_outline.svg?raw';
export { default as tl__24x24_play_badged_outline } from './tl/24x24_play_badged_outline.svg?raw';
export { default as tl__24x24_play_outline } from './tl/24x24_play_outline.svg?raw';
export { default as tl__24x24_present_outline } from './tl/24x24_present_outline.svg?raw';
export { default as tl__24x24_record_outline } from './tl/24x24_record_outline.svg?raw';
export { default as tl__24x24_rectangle_outline } from './tl/24x24_rectangle_outline.svg?raw';
export { default as tl__24x24_redo_outline } from './tl/24x24_redo_outline.svg?raw';
export { default as tl__24x24_refresh_outline } from './tl/24x24_refresh_outline.svg?raw';
export { default as tl__24x24_reseller_outline } from './tl/24x24_reseller_outline.svg?raw';
export { default as tl__24x24_save_outline } from './tl/24x24_save_outline.svg?raw';
export { default as tl__24x24_screen_share_outline } from './tl/24x24_screen_share_outline.svg?raw';
export { default as tl__24x24_search_outline } from './tl/24x24_search _outline.svg?raw';
export { default as tl__24x24_forms_outline } from './tl/24x24_forms_outline.svg?raw';
export { default as tl__24x24_send_outline } from './tl/24x24_send_outline.svg?raw';
export { default as tl__24x24_settings_outline } from './tl/24x24_settings_outline.svg?raw';
export { default as tl__24x24_shared_mouse_pointer_filled } from './tl/24x24_shared_mouse_pointer_filled.svg?raw';
export { default as tl__24x24_share_android_outline } from './tl/24x24_share_android_outline.svg?raw';
export { default as tl__24x24_signal_cellular_filled } from './tl/24x24_signal_cellular_filled.svg?raw';
export { default as tl__24x24_size_maximize_outline } from './tl/24x24_size_maximize_outline.svg?raw';
export { default as tl__24x24_size_minimize_outline } from './tl/24x24_size_minimize_outline.svg?raw';
export { default as tl__24x24_star_filled } from './tl/24x24_star_filled.svg?raw';
export { default as tl__24x24_stop_outline } from './tl/24x24_stop_outline.svg?raw';
export { default as tl__24x24_stop_filled } from './tl/24x24_stop_filled.svg?raw';
export { default as tl__24x24_sync_outline } from './tl/24x24_sync_outline.svg?raw';
export { default as tl__24x24_tab_outline } from './tl/24x24_tab_outline.svg?raw';
export { default as tl__24x24_team_outline } from './tl/24x24_team_outline.svg?raw';
export { default as tl__24x24_template_outline } from './tl/24x24_template_outline.svg?raw';
export { default as tl__24x24_text_bold_outline } from './tl/24x24_text_bold_outline.svg?raw';
export { default as tl__24x24_text_functions } from './tl/24x24_text_functions.svg?raw';
export { default as tl__24x24_text_italic_outline } from './tl/24x24_text_italic_outline.svg?raw';
export { default as tl__24x24_text_title_outline } from './tl/24x24_text_title_outline.svg?raw';
export { default as tl__24x24_text_underlined_outline } from './tl/24x24_text_underlined_outline.svg?raw';
export { default as tl__24x24_timer_outline } from './tl/24x24_timer_outline.svg?raw';
export { default as tl__24x24_trash_outline } from './tl/24x24_trash_outline.svg?raw';
export { default as tl__24x24_triangle_outline } from './tl/24x24_triangle_outline.svg?raw';
export { default as tl__24x24_undo_outline } from './tl/24x24_undo_outline.svg?raw';
export { default as tl__24x24_user_add_outline } from './tl/24x24_user_add_outline.svg?raw';
export { default as tl__24x24_user_outline } from './tl/24x24_user_outline.svg?raw';
export { default as tl__24x24_verified_outline } from './tl/24x24_verified_outline.svg?raw';
export { default as tl__24x24_videocam_off_outline } from './tl/24x24_videocam_off_outline.svg?raw';
export { default as tl__24x24_videocam_outline } from './tl/24x24_videocam_outline.svg?raw';
export { default as tl__24x24_visible_off_outline } from './tl/24x24_visible_off_outline.svg?raw';
export { default as tl__24x24_visible_outline } from './tl/24x24_visible_outline.svg?raw';
export { default as tl__24x24_volume_up } from './tl/24x24_volume_up.svg?raw';
export { default as tl__24x24_warning_badged_filled } from './tl/24x24_warning_badged_filled.svg?raw';
export { default as tl__24x24_warning_badged_outline } from './tl/24x24_warning_badged_outline.svg?raw';
export { default as tl__24x24_youtube } from './tl/24x24_youtube.svg?raw';
export { default as tl__24x24_zoom_in_outline } from './tl/24x24_zoom_in_outline.svg?raw';
export { default as tl__24x24_zoom_out_outline } from './tl/24x24_zoom_out_outline.svg?raw';
export { default as vectera__24x24_branding_outline } from './vectera/24x24_branding_outline.svg?raw';
