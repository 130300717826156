import { Component, Inject, OnInit } from '@angular/core';
import {
  ContactFormSettingsComponent
} from 'contactForm/contact-form-config/contact-form-settings.component';
import { MappingFieldsService } from 'contactForm/contact-form-config/mapping-fields.service';
import { ContactForm } from 'contactForm/models/ContactForm';
import { ContactFormQuestion } from 'contactForm/models/ContactFormQuestion';
import { CRMEntity, Provider } from 'contactForm/models/MappingField';
import { DialogService } from 'utils/ui-components/dialog';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { array, logger, object, string } from 'utils/util';


type Checkbox = {
  entity: CRMEntity,
  entityName: string,
  label: string,
  icon: SvgIcon,
  cannotCreateHelpText?: string,
  overflowingTagIndex?: number,
  aboutToOverflowTagIndex?: number,
}


@Component({
  selector: 'entity-creation[instance]',
  templateUrl: './entity-creation.component.html',
  styleUrls: ['entity-creation.component.scss'],
})
export class EntityCreationComponent extends ContactFormSettingsComponent implements OnInit {
  public CRMEntity = CRMEntity;
  public checkboxes: Checkbox[] = [
    {
      entity: CRMEntity.CONTACT,
      entityName: $localize `contact`,
      label: $localize `Create new contact`,
      icon: { name: 'contacts' },
      overflowingTagIndex: undefined,
    },
    {
      entity: CRMEntity.COMPANY,
      entityName: $localize `company`,
      label: $localize `Create new company`,
      icon: { name: 'building_add' },
      overflowingTagIndex: undefined,
    },
    {
      entity: CRMEntity.DEAL,
      entityName: $localize `deal`,
      label: $localize `Create new deal`,
      icon: { name: 'checkmark_add', style: 'badged_outline', },
      cannotCreateHelpText: $localize `A deal can only be created if a corresponding contact or company is also being created`,  // eslint-disable-line max-len
      overflowingTagIndex: undefined,
    },
  ];

  public isSaving = false;
  public contactForm: ContactForm;
  public fetchError?: string;

  public tags: string[] = [];
  public tagsAreLoaded = false;

  public isOpen = false;
  public selectedEntity?: CRMEntity = null;
  public triggerOrigin: any;
  public overflowingTagIndex?: number = undefined;

  /**
   * If the user disables entity X, this contains all the questions that will be deleted as a
   * result of that.
   *
   * NOTE: we do this because every question MUST be mapped to a Teamleader field. When this
   * constraint is dropped, we will need to revisit the entire logic around disabling a checkbox.
   * This whole implementation feels very fragile, so I hope this will be soon.
   */
  public questionsToBeRemoved: Record<CRMEntity, ContactFormQuestion[]> = {};


  constructor(
    @Inject('apiService') private apiService,
    @Inject('notificationService') private notificationService,

    private dialogService: DialogService,
    private mappingFieldsService: MappingFieldsService,
  ) {
    super();
    this.CRMEntity = CRMEntity;
  }

  get isStandalone() {
    return !!this.instance.name;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if(!this.instance.teamleaderMappingConfig) {
      this.instance.createChildInstance('teamleaderMappingConfig');
    }

    if(!this.instance.teamleaderMappingConfig.tags) {
      this.instance.teamleaderMappingConfig.tags = {};
    }
    if(!this.instance.teamleaderMappingConfig.tags[CRMEntity.CONTACT]) {
      this.instance.teamleaderMappingConfig.tags[CRMEntity.CONTACT] = [];
      this.instance.teamleaderMappingConfig.markDirty('tags');
    }
    if(!this.instance.teamleaderMappingConfig.tags[CRMEntity.COMPANY]) {
      this.instance.teamleaderMappingConfig.tags[CRMEntity.COMPANY] = [];
      this.instance.teamleaderMappingConfig.markDirty('tags');
    }

    this.apiService.get('teamleader/tags')
      .then(response => {
        this.tags = response.data;
        this.tagsAreLoaded = true;
      })
      .catch(error => {
        this.notificationService.error(
          $localize `We could not load your tags, please refresh the page or try again later.`,
          { delay: -1 }
        );
        logger.warn(error);
      });

    this.instance.on('questions', this.updateQuestionsToBeRemoved);
    this.instance.teamleaderMappingConfig.on(
      'createContact createCompany createDeal',
      this.updateQuestionsToBeRemoved,
    );
    this.mappingFieldsService.get().then(() => this.updateQuestionsToBeRemoved());
  }

  openTagDropdown(entity, trigger) {
    this.triggerOrigin = trigger;
    this.selectedEntity = entity;
    this.isOpen = true;
  }

  getDropdownTagsToShow(entity: CRMEntity) {
    const entityTags = this.instance.teamleaderMappingConfig.tags[entity];
    const allTags = entityTags
      .concat(this.tags)
      .sort((a, b) => a.localeCompare(b));
    return allTags.filter((item, pos) => allTags.indexOf(item) === pos);
  }

  toggleTag(entity, tag) {
    if(this.isTagSelected(entity, tag)) {
      array.remove(this.instance.teamleaderMappingConfig.tags[entity], tag);
    } else {
      this.instance.teamleaderMappingConfig.tags[entity].push(tag);
    }
    this.instance.teamleaderMappingConfig.markDirty('tags');
  }

  isTagSelected(entity, tag) {
    return this.instance.teamleaderMappingConfig.tags[entity].includes(tag);
  }

  handleAddTag(event, checkbox: Checkbox) {
    if(event.isOverflowed) {
      // store the first recorded instance, wipe the record once the tags are no longer overflowing
      checkbox.overflowingTagIndex = checkbox.overflowingTagIndex || event.index;
    } else {
      checkbox.overflowingTagIndex = undefined;
    }
    if(event.willOverflowIfMoreTagsAreAdded) {
      checkbox.aboutToOverflowTagIndex = checkbox.aboutToOverflowTagIndex || event.index;
    } else {
      checkbox.aboutToOverflowTagIndex = undefined;
    }
  }

  getSelectedTagsToShow(checkbox: Checkbox) {
    const tags = this.instance.teamleaderMappingConfig.tags[checkbox.entity];
    if(checkbox.overflowingTagIndex) {
      if(checkbox.aboutToOverflowTagIndex) {
        // the previous badge was about to overflow, and now we've added one:
        // hide both this tag and the previous one
        return tags.slice(0, checkbox.aboutToOverflowTagIndex);
      } else {
        return tags.slice(0, checkbox.overflowingTagIndex);
      }
    } else {
      return tags;
    }
  }

  shouldShowMoreBadgeLabel(checkbox: Checkbox) : string | void {
    const tags = this.instance.teamleaderMappingConfig.tags[checkbox.entity];
    const shownTags = this.getSelectedTagsToShow(checkbox);
    if(tags.length - shownTags.length > 0) {
      return `+${tags.length - shownTags.length}`;
    }
  }

  noTagsSelectedPlaceholder(checkbox: Checkbox): string {
    return $localize `Select ${checkbox.entityName} tag(s)`;
  }

  updateQuestionsToBeRemoved() {
    if(object.length(this.mappingFieldsService.mappingFields) === 0) {
      return;
    }

    this.questionsToBeRemoved = {};
    const chain = this.getDisableChain();
    Object.values(CRMEntity).forEach((entity: CRMEntity) => {
      this.questionsToBeRemoved[entity] = [];
      for(const question of this.instance.questions) {
        const mappedProviders = Object.keys(question.extra.mappedFields || {});
        const field = question.getMappedField(Provider.TEAMLEADER);
        const hasOtherRequiredMapping = mappedProviders
          .filter(provider => provider !== Provider.TEAMLEADER)
          .find(provider => question.getMappedField(provider).required);
        // This condition must be kept in sync with the one in
        // ContactFormQuestionsComponent.removeIllegalMappings
        const shouldRemove = (
          field
          && (field.entity === entity || chain[entity].includes(field.entity))
          && (mappedProviders.length === 1 || !hasOtherRequiredMapping)
          && !(question.isDefault && !question.required)
        );
        if(shouldRemove) {
          this.questionsToBeRemoved[entity].push(question);
        }
      }
    });

  }


  /**
   * For each entity: list the entities that will get disabled as a result of disabling this
   * entity.
   */
  getDisableChain() {
    const chain: Record<CRMEntity, CRMEntity[]> = {
      [CRMEntity.CONTACT]: [],
      [CRMEntity.COMPANY]: [],
      [CRMEntity.DEAL]: [],
    };
    if(this.instance.appointmentType) {
      chain[CRMEntity.CONTACT] = [CRMEntity.COMPANY, CRMEntity.DEAL];
    } else {
      if(!this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.CONTACT)) {
        chain[CRMEntity.COMPANY].push(CRMEntity.DEAL);
      }
      if(!this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.COMPANY)) {
        chain[CRMEntity.CONTACT].push(CRMEntity.DEAL);
      }
    }
    return chain;
  }


  canCreate(entity: CRMEntity) {
    if(!this.instance) {
      return false;
    } else if(entity === CRMEntity.DEAL) {
      return (
        this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.CONTACT)
        || this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.COMPANY)
      );
    } else if(entity === CRMEntity.COMPANY) {
      return (
        this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.CONTACT)
        || this.instance.appointmentType == null
      );
    } else {
      return true;
    }
  }

  getCannotCreateHelpText(entity: CRMEntity) {
    if(entity === CRMEntity.DEAL) {
      // eslint-disable-next-line max-len
      return $localize `A deal can only be created if a corresponding contact or company is also being created`;

    } else if(entity === CRMEntity.COMPANY) {
      // eslint-disable-next-line max-len
      return $localize `A company can only be created if a corresponding contact is also being created`;

    } else {
      return null;
    }
  }

  getLabel(checkbox: Checkbox) {
    return $localize `Create new ${checkbox.entityName}`;
  }


  shouldConfirmDisable(entity: CRMEntity) {
    return (
      this.instance.teamleaderMappingConfig.shouldCreate(entity)
      && this.questionsToBeRemoved[entity].length > 0
    );
  }

  getDialogTitle(checkbox: Checkbox) {
    return $localize `Disable ${checkbox.entityName}`;
  }

  getDialogTemplate(checkbox: Checkbox) {
    const questions = string.escapeHTML(
      this.questionsToBeRemoved[checkbox.entity].map(q => q.label).join(', ')
    );
    // eslint-disable-next-line max-len
    const questionsString = $localize `The following questions are linked to ${checkbox.entityName}\:`;
    // eslint-disable-next-line max-len
    const areYouSureString = $localize `If you disable this option, these questions will be deleted. Do you want to continue?`;

    return questionsString + ` <b>${questions}.</b> ` + areYouSureString;
  }


  onChange(checkbox: Checkbox, event: Event) {
    // Angular doesn't give us a way to "reject" a change on a checkbox. That's why we don't use
    // ngModel on these checkboxes, but instead work with native HTML events and elements. It's a
    // bity dirty, but having a non-disabled, readonly checkbox is just a really weird pattern.
    const entity = checkbox.entity;
    const target = event.target as HTMLInputElement;
    if(this.shouldConfirmDisable(entity)) {
      target.checked = true;
      this.showConfirmDisableDialog(checkbox);
    } else {
      this.set(entity, target.checked);
    }
  }

  set(entity: CRMEntity, value: boolean) {
    this.instance.teamleaderMappingConfig.setShouldCreate(entity, value);
    this.updateEntities();
  }


  showConfirmDisableDialog(checkbox: Checkbox) {
    const entity = checkbox.entity;
    const dialog = this.dialogService.open({
      icon: checkbox.icon,
      title: this.getDialogTitle(checkbox),
      template: this.getDialogTemplate(checkbox),
      confirmButtonClass: 'btn--error',
      confirmText: $localize `Disable`,
    });
    dialog.closed.subscribe(value => {
      if(value) {
        this.set(entity, false);
      }
    });
  }

  get shouldShowRequiredMessage() {
    // For now, standalone forms must be mapped to TLF. This will change once we have a way to
    // show responses in the Vectera dashboard.
    return (
      this.isStandalone
      && !this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.CONTACT)
      && !this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.COMPANY)
      && !this.instance.teamleaderMappingConfig.shouldCreate(CRMEntity.DEAL)
    );
  }


  updateEntities() {
    Object.values(CRMEntity).forEach(entity => {
      if(this.instance.teamleaderMappingConfig.shouldCreate(entity) && !this.canCreate(entity)) {
        this.instance.teamleaderMappingConfig.setShouldCreate(entity, false);
      }
    });
  }
}
