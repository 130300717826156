import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ContactFormPageScope
} from 'contactForm/contact-form-preview/contact-form-preview.component';
import { SettingsComponent } from 'utils/settings/settings.component';
import { SiteService } from 'utils/site.service';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { WhitelabelSettings } from 'utils/view-organization.service';
import { ContactFormSettingsComponent } from '../../contact-form-settings.component';
import { Errors } from 'utils/settings/settings.component';
import { UrlService } from 'utils/url.service';
import { Customization, buildCustomizationFromInstance } from '../../../utils/customization';
import { fixURL } from 'utils/util/url';
import { ContactFormFile } from 'contactForm/models/ContactFormFile';
import { FileItem } from 'utils/form-elements/file-picker/file-picker.component';

const DownloadOption = Object.freeze({
  LINK: 'link',
  FILE: 'file',
});

@Component({
  selector: 'contact-form-customization[instance]',
  templateUrl: './contact-form-customization.component.html',
  styleUrls: ['./contact-form-customization.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactFormCustomizationComponent
  }]
})
export class ContactFormCustomizationComponent
  extends ContactFormSettingsComponent
  implements OnInit, OnChanges {

  readonly DownloadOption = DownloadOption;
  public readonly defaultScope = ContactFormPageScope.FORM_PAGE;
  public scope: Scope = this.defaultScope;
  public readonly scopes = [ContactFormPageScope.FORM_PAGE, ContactFormPageScope.SUCCESS_PAGE];
  public ContactFormPageScope = ContactFormPageScope;

  public origWhitelabelSettings?: WhitelabelSettings;
  public downloadLinkUrlInput = '';
  public file: FileItem | null;
  public downloadOption:string = DownloadOption.LINK;

  static override formFields = [
    'textColor',
    'fontFamily',
    'fontSize',
    'titleTextColor',
    'titleFontFamily',
    'titleFontSize',
    'linkColor',
    'buttonColor',
    'buttonTextColor',
    'formColor',
    'backgroundColor',
    'introductionText',
    'sendButtonText',
    'successTitle',
    'successText',
    'successImage',
    'downloadButtonText',
  ];

  public customization!: Customization;


  constructor(
    private siteService: SiteService,
    public urlService: UrlService,
    @Inject('modelFactory') private modelFactory,
  ) {
    super();
    this.file = null;
  }

  override ngOnInit() {
    super.ngOnInit();
    this.origWhitelabelSettings = { ...this.organization.whitelabelSettings };
    this.customization = buildCustomizationFromInstance(this.instance);

    this.downloadLinkUrlInput = this.instance.downloadLink;
    if(this.instance.files.length > 0) {
      this.file = {
        name: this.instance.files[0].name,
        // FIXME: VECT-2516
        // Dirty hack, but the url here is not really relevant. When POSTing a file, we post
        // the base64 encoded data. The URL we GET is a real url, pointing to our hosted file.
        // By setting this url to an empty string, we can make the distinction between a "real"
        // ContactFormFile that can be posted, or the one we got from our backend.
        url: '',
      };
    }
    this.downloadOption = (
      this.file ?
        DownloadOption.FILE :
        DownloadOption.LINK
    );
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(changes.instance) {
      this.customization = buildCustomizationFromInstance(this.instance);
    }
  }

  get shouldShowUpgradeCTA() {
    return this.requestUserService.isFree;
  }

  get firstName() {
    // eslint-disable-next-line max-len
    return this.requestUserService.user.firstName;
  }

  get proPlanName() {
    return this.siteService.site.proPlanName;
  }


  /***********************************
   * Set organization logo & favicon *
   ***********************************/

  get organization() {
    return this.instance.organization;
  }
  get organizationErrors(): Errors {
    return (this.errors.organization || {}) as Errors;
  }

  get hasSetImages() {
    return this.origWhitelabelSettings?.customLogo || this.origWhitelabelSettings?.customFavicon;
  }


  get logo() {
    return this.organization.logo;
  }
  set logo(logo: string | null) {
    this.organization.logo = logo;
    if(logo) {
      // Multipliers which are used in OrganizationSerializer._get_logo
      const geometryMultiplier = 1.5;
      const retinaDisplayMultiplier = 2;

      this.organization.whitelabelSettings.customLogo = true;
      this.organization.whitelabelSettings.logo = {
        name: '',
        url: logo,
        // Ideal we would calculate the real size of the logo here (which is based on both the
        // width and height, see OrganizationSerializer._get_logo), but this is quite a complex
        // calculation, and showing a slightly different size in the preview while configuring
        // the logo for the first time is fine.
        height: 34 * 2 * geometryMultiplier * retinaDisplayMultiplier,
      };

    } else if(this.origWhitelabelSettings) {
      this.organization.whitelabelSettings.customLogo = this.origWhitelabelSettings.customLogo;
      this.organization.whitelabelSettings.logo = this.origWhitelabelSettings.logo;
    }
  }

  get favicon() {
    return this.organization.favicon;
  }
  set favicon(favicon: string | null) {
    this.organization.favicon = favicon;
    if(favicon) {
      this.organization.whitelabelSettings.customFavicon = true;
      this.organization.whitelabelSettings.favicon = favicon;

    } else if(this.origWhitelabelSettings) {
      this.organization.whitelabelSettings.customFavicon =
        this.origWhitelabelSettings.customFavicon;
      this.organization.whitelabelSettings.favicon = this.origWhitelabelSettings.favicon;
    }
  }


  /***************
 * Download files *
 ****************/

  resetFile() {
    this.file = null;
    this.onChangeFile();
    this.errors.files = {};
  }

  resetDownloadLink() {
    this.instance.downloadLink = '';
    this.downloadLinkUrlInput = '';
    this.errors.downloadLink = {};
  }

  updateDownloadLinkUrlModel() {
    this.instance.downloadLink = fixURL(this.downloadLinkUrlInput);
  }

  onChangeFile() {
    // Check if we actually made any changes to the file. We don't want to create an upload a file
    // if a file already exists.
    if(this.file && !this.file.url) {
      return;
    }

    if(this.file) {
      const contactFormFile = this.modelFactory.createInstance(ContactFormFile, {
        values: {
          name: this.file.name,
          file: [
            this.file.name,
            this.file.url,
          ]
        }
      });
      this.instance.files = [contactFormFile];
    } else {
      this.instance.files = [];
    }
  }
}
