// these locales are copypasted from https://github.com/angular/angular.js/tree/master/src/ngLocale
//
// they have been modified slightly* to be loaded into the
// dynamic locale cache using a simplified language code
// (which is used elsewhere in the codebase) as key
//
// * note the usage of `tmhDynamicLocaleCache` vs $provide

import './de';
import './en';
import './es';
import './fr';
import './it';
import './lt';
import './nl';
