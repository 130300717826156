<div *ngFor="let checkbox of checkboxes" class="mb-2">
  <input
    [id]="checkbox.entity"
    type="checkbox"
    class="checkbox"
    [checked]="instance?.teamleaderMappingConfig.shouldCreate(checkbox.entity)"
    (change)="onChange(checkbox, $event)"
    [disabled]="!hasPermissionToEdit || !canCreate(checkbox.entity)"
  />
  <label [for]="checkbox.entity" class="checkbox__label">
    <span>{{ checkbox.label }}</span>

    <svg-icon
      *ngIf="canCreate(checkbox.entity)"
      class="icon ml-1"
      [svgIcon]="checkbox.icon"
    ></svg-icon>
    <help-tooltip
      *ngIf="!canCreate(checkbox.entity) && checkbox.cannotCreateHelpText"
      [text]="checkbox.cannotCreateHelpText"
    ></help-tooltip>
  </label>
  <div class="d-flex flex-row align-items-center" *ngIf="instance?.teamleaderMappingConfig.shouldCreate(checkbox.entity) && checkbox.entity !== CRMEntity.DEAL && tags.length > 0">
    <div class="ml-12" i18n>Add tag(s)</div>
    <div
      #tagContainer
      class="entity-creation__tag-select"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="openTagDropdown(checkbox.entity, trigger)"
    >
      <div *ngIf="instance.teamleaderMappingConfig.tags[checkbox.entity]?.length === 0" class="position-absolute text-muted">{{ noTagsSelectedPlaceholder(checkbox) }}</div>
      <div class="entity-creation__tag-compute-box">
        <tag-badge
          *ngFor="let tag of instance.teamleaderMappingConfig.tags[checkbox.entity]; let i = index;"
          class="mr-2 badge badge--sm flex-shrink-0"
          (addItem)="handleAddTag($event, checkbox)"
          [index]="i"
          [label]="tag"
        >
        </tag-badge>
      </div>
      <div class="position-absolute">
        <div
          *ngFor="let tag of getSelectedTagsToShow(checkbox); let i = index;"
          class="mr-2 badge badge--sm flex-shrink-0"
        >
          {{ tag }}
        </div>
        <div
          *ngIf="shouldShowMoreBadgeLabel(checkbox)"
          class="mr-2 badge badge--sm flex-shrink-0"
        >
          {{ shouldShowMoreBadgeLabel(checkbox) }}
        </div>
      </div>
    </div>
  </div>
</div>

<ul class="errorlist" *ngIf="shouldShowRequiredMessage">
  <li i18n>Please enable creating a contact and/or company.</li>
</ul>


<ng-template
cdkConnectedOverlay
cdkConnectedOverlayInheritWidth
[cdkConnectedOverlayOrigin]="triggerOrigin"
[cdkConnectedOverlayOpen]="isOpen"
[cdkConnectedOverlayDisableClose]="true"
[cdkConnectedOverlayHasBackdrop]="true"
[cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
[cdkConnectedOverlayFlexibleDimensions]="true"
(backdropClick)="isOpen = false"
>
  <div class="dropdown dropdown--scrollable">
    <div
      *ngFor="let tag of getDropdownTagsToShow(selectedEntity)"
      (click)="toggleTag(selectedEntity, tag)"
    >
      <div
        class="dropdown__list-item"
      >
        <svg-icon
          *ngIf="isTagSelected(selectedEntity, tag)"
          class="dropdown__list-icon dropdown__list-icon--sm"
          [svgName]="'checkmark'"
          [svgSize]="14"
        ></svg-icon>
        <div
          *ngIf="!isTagSelected(selectedEntity, tag)"
          class="dropdown__list-icon dropdown__list-icon--sm"
        ></div>
        <span>{{ tag }}</span>
      </div>
    </div>
    <hr class="mb-0">
  </div>
</ng-template>
