import { User } from 'core/models/User';
import * as fields from 'utils/fields';
import Model from 'utils/models';
import { ContactFormQuestion } from './ContactFormQuestion';
import { ContactFormResponse } from './ContactFormResponse';
import { ContactFormFile } from './ContactFormFile';
import { TeamleaderMappingConfig, VecteraMappingConfig } from './MappingConfig';
import { ContactFormPrefilledAnswer } from './ContactFormAnswer';


export class ContactForm extends Model {
  static get fields() {
    return {
      name: new fields.StringField(),
      createdBy: new fields.RelatedField({ Model: User }),
      questions: new fields.RelatedField({
        Model: ContactFormQuestion,
        withIdField: false,
        many: true,
        readOnly: false,
        default: [],
      }),
      prefilledAnswers: new fields.RelatedField({
        Model: ContactFormPrefilledAnswer,
        withIdField: false,
        many: true,
        readOnly: false,
        default: [],
      }),
      teamleaderMappingConfig: new fields.RelatedField({
        Model: TeamleaderMappingConfig,
        withIdField: false,
        readOnly: false,
      }),
      vecteraMappingConfig: new fields.RelatedField({
        Model: VecteraMappingConfig,
        withIdField: false,
      }),
      usersToNotify: new fields.RelatedField({
        Model: User,
        many: true,
        readOnly: false,
        default: [],
      }),
      responses: new fields.RelatedField({
        Model: ContactFormResponse,
        many: true,
      }),
      files: new fields.RelatedField({
        Model: ContactFormFile,
        withIdField: false,
        many: true,
        readOnly: false,
        default: [],
      }),

      allowUserNotification: new fields.BooleanField(),
      askToAcceptTerms: new fields.BooleanField(),
      redirectUrl: new fields.StringField({ default: '' }),

      // Customization
      formColor: new fields.StringField(),
      backgroundColor: new fields.StringField(),
      buttonColor: new fields.StringField(),
      buttonTextColor: new fields.StringField(),

      textColor: new fields.StringField(),
      fontFamily: new fields.StringField(),
      fontSize: new fields.StringField(),
      fontWeight: new fields.StringField(),

      titleTextColor: new fields.StringField(),
      titleFontFamily: new fields.StringField(),
      titleFontSize: new fields.StringField(),
      titleFontWeight: new fields.StringField(),

      linkColor: new fields.StringField(),

      introductionText: new fields.StringField(),
      sendButtonText: new fields.StringField({ maxLength: 30 }),
      successTitle: new fields.StringField(),
      successText: new fields.StringField(),
      successImage: new fields.FileField(),

      downloadLink: new fields.StringField({ default: '' }),
      downloadButtonText: new fields.StringField({ maxLength: 30 }),
    };
  }

  static get basePath() {
    return 'contactForms';
  }

  static get canDuplicateFromId() {
    return true;
  }


  createResponse() {
    let values = {
      contactForm: this,
      contactFormId: this.id,
    };
    let response = this.modelFactory.createInstance(ContactFormResponse, { values });

    for(let question of this.questions) {
      response.createChildInstance('answers', {
        values: {
          contactFormQuestion: question,
          contactFormQuestionId: question.id,
          answer: '',
        },
      });
    }

    return response;
  }

  get numResponses() {
    return this.responses.length;
  }

  get numRecentResponses() {
    return this.responses.filter(response => {
      const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      return new Date(response.dateCreated) > oneWeekAgo;
    }).length;
  }
}

export class UserContactForm extends ContactForm {
  static listPath(identifiers) {
    return `users/${identifiers.userId}/contactForms`;
  }
}

export class OrganizationContactForm extends ContactForm {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/contactForms`;
  }
}
