import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { UIComponentsComponent } from './ui-components.component';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    UtilsModule,
  ],
  declarations: [
    UIComponentsComponent,
  ],
  exports: [
    UIComponentsComponent,
  ],
})
export class UIComponentsModule {}
