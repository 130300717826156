import { format, bind } from 'utils/util';
import MQ from './MQ';
import mathquillCss  from 'mathquill/build/mathquill.css?raw';

const TOUCH_TO_MOUSE_EVENT_TYPES = {
  touchstart: 'mousedown',
  touchmove: 'mousemove',
  touchend: 'mouseup',
};


const DEFAULT_RENDER_OPTIONS = {
  skipEditingShape: false,
};


// Exporting the formula page to an image requires the following steps:
// - Embed the HTML content in an SVG image (using the template below)
// - Render the SVG onto a canvas
// - Export the canvas as an image
const SVG_TEMPLATE = `
  <svg xmlns="http://www.w3.org/2000/svg" width="%spx" height="%spx">
    <defs>
      <style>
        ${mathquillCss}

        /* Copied from whiteboard/_index.scss */
        .mathTextbox {
          position: absolute;
          top: 0;
          left: 0;
          will-change: transform;
          pointer-events: none;
          border: 0 !important;
          line-height: 1 !important;
        }
        .mq-root-block,
        .mq-math-mode .mq-root-block {
          padding: .08em !important;
        }
        .mq-latex-command-input {
          border: 0 !important;
        }
      </style>
    </defs>

    <foreignObject width="100%" height="100%">
      <div xmlns="http://www.w3.org/1999/xhtml">
        %s
      </div>
    </foreignObject>
  </svg>
`;


export default class FormulaRenderer {
  constructor(toolService, whiteboard, paperRenderer) {
    bind(this);

    this.toolService = toolService;
    this.whiteboard = whiteboard;
    this.paperRenderer = paperRenderer;
    this.paperRenderer.formulaRenderer = this;

    this.$elemPaper = null;
    this.$elem = null;
    this.$elemInactive = null;

    this.formulaShapes = {};

    this.$lastMouseEvent = null;

    this.whiteboard.on('page', this._onPage);

    this.paperRenderer.on('elem', this._setElem);
    this.paperRenderer.on('destroy', this._destroy);
  }


  _setElem(paperRenderer, $elemWrapper) {
    this.$elemPaper = $elemWrapper.find('.tile:not(.tile--inactive) .whiteboard-tile__paper');
    this.$elem = angular.element('<div class="whiteboard-tile__formula-wrapper"></div>');
    this.$elemPaper.append(this.$elem);

    window.__vecteraRunOutsideAngular(() => {
      this.$elemPaper.on(
        'mousedown mousemove mouseup touchstart touchmove touchend',
        this._onMouseEvent
      );
    });

    let $elemPaperInactive = $elemWrapper.find(
      '.content-tile__inactive-wrapper .whiteboard-tile__paper');
    this.$elemInactive = angular.element('<div></div>');
    $elemPaperInactive.append(this.$elemInactive);


    this.toolService.on('formula', this._onAddFormula);
    this.paperRenderer.on('viewport', this._onViewport);
    this.paperRenderer.on('active', this._onActive);
    this.paperRenderer.on('shapeAdd', this._onShapeAdd);
    this.paperRenderer.on('shapeRemove', this._onShapeRemove);
    this.paperRenderer.on('shapesEdit', this._onShapesEdit);
    this.paperRenderer.on('shapeEditing', this._onShapeEditing);

    // iterate over existing shapes as if they are added at the time of init
    Object.values(this.paperRenderer.shapes).forEach(origShape => {
      this._onShapeAdd(this.paperRenderer, origShape);
    });

    this._renderToElemCurrent();
  }


  _destroy() {
    this.toolService.off('formula', this._onAddFormula);
    if(this.$elemPaper) {
      this.$elemPaper.off(
        'mousedown mousemove mouseup touchstart touchmove touchend',
        this._onMouseEvent
      );
    }
  }


  _onPage() {
    this._renderToElemCurrent();
  }

  _onActive() {
    this._renderToElemCurrent();
  }

  _onViewport() {
    this._renderToElemCurrent();
  }


  /**********
   * Render *
   **********/

  get $elemCurrent() {
    return this.paperRenderer.active ? this.$elem : this.$elemInactive;
  }

  get isReadyToRender() {
    return (
      this.$elemCurrent
      && this.whiteboard.canvasSize.width > 0
      && this.whiteboard.canvasSize.height > 0
    );
  }

  get editingShape() {
    return this.paperRenderer.editingShape ?
      this.formulaShapes[this.paperRenderer.editingShape.id] :
      null;
  }



  _renderToElemCurrent() {
    if(!this.isReadyToRender) {
      return;
    }
    this._whenElemCurrentVisible().then(() => {
      let updatedShapes = this._renderToElem(this.$elemCurrent);
      if(this.paperRenderer.active) {
        this._sendShapeSizeUpdates(updatedShapes);
      }
    });
  }


  _whenElemCurrentVisible() {
    // MathQuill can't render to an element that is added to the DOM but not currently visible.
    // If you try to do so, most characters end up ok, but parentheses and other `mq-scale`
    // elements are invisible. So _renderToElemCurrent needs to wait until $elemCurrent is
    // visible before rendering.
    let defer = $q.defer();
    let that = this;

    function checkVisible() {
      if(that.$elemCurrent[0].offsetParent != null) {
        defer.resolve();
      } else {
        requestAnimationFrame(checkVisible);
      }
    }
    checkVisible();

    return defer.promise;
  }


  renderSnapshot(width) {
    let pixelsPerPoint = this.whiteboard.getPixelsPerPointSnapshot(width);
    let size = this.whiteboard.getCanvasSizeSnapshot(width);
    let offset = this.whiteboard.getCanvasPanSnapshot(width);

    return $q.resolve()
      .then(() => {
        let $elem = angular.element('<div></div>');

        // If we were to render the editing shape, the user would lose focus and need to re-select
        // the formula after every snapshot. So we don't include the editing shape in the snapshot,
        // which is not ideal but good enough.
        this._renderToElem($elem, size, offset, pixelsPerPoint, {
          skipEditingShape: true,
        });
        let htmlString = $elem.html().replace(/&nbsp;/g, ' ');
        let svgString = format(SVG_TEMPLATE, size.width, size.height, htmlString);
        let dataURL = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));

        // Rendering a snapshot causes all mathquill elements to be removed from the main
        // whiteboard element, so we need a re-render.
        this._renderToElemCurrent();

        return $q((resolve, reject) => {
          let svgImage = new Image();
          svgImage.onload = () => resolve(svgImage);
          svgImage.onerror = error => reject(error);
          svgImage.src = dataURL;
        });
      })
      .then(svgImage => {
        let canvas = document.createElement('canvas');
        canvas.width = size.width;
        canvas.height = size.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(svgImage, 0, 0, size.width, size.height);
        return canvas.toDataURL({ format: 'png' });
      });
  }


  renderSvgExport() {
    let size = this.whiteboard.canvasSizeSvgExport;
    let offset = this.whiteboard.canvasPanSvgExport;
    let pixelsPerPoint = this.whiteboard.pixelsPerPointSvgExport;

    let $elem = angular.element('<div class="whiteboard-tile__formula-wrapper"></div>');
    this._renderToElem($elem, size, offset, pixelsPerPoint);
    return $elem;
  }


  _renderToElem($elem, size, offset, zoom, argOptions = {}) {
    let options = Object.assign({}, DEFAULT_RENDER_OPTIONS, argOptions);

    let shapesToUpdate = this.paperRenderer.activeShapes
      .map(origShape => this.formulaShapes[origShape.id])
      .filter(shape => !!shape);
    if(options.skipEditingShape && this.editingShape) {
      shapesToUpdate = shapesToUpdate.filter(shape => shape !== this.editingShape);
    }

    let elemsToDetach = new Set($elem.children());

    shapesToUpdate.forEach(shape => {
      if(elemsToDetach.has(shape.container[0])) {
        elemsToDetach.delete(shape.container[0]);
      } else {
        $elem[0].appendChild(shape.container[0]);
        shape.mathField.reflow();
      }
    });
    elemsToDetach.forEach(elem => {
      elem.remove();
    });

    this._updateShapePositions(shapesToUpdate, size, offset, zoom);
    return shapesToUpdate;
  }




  /*****************
   * Manage shapes *
   *****************/

  _createMathField(shape) {
    let container = document.createElement('span');
    container.className = 'mathTextbox';

    let mathField = MQ.MathField(container);
    this._patchMathField(mathField, shape);

    return mathField;
  }


  _patchMathField(mathField, shape) {
    let that = this;

    // iOS doesn't have arrow keys, which makes it impossible to escape super/subscript.
    // We allow using Enter for this.
    // Also, define some key combinations to exit editing
    mathField.__controller.origKeystroke = mathField.__controller.keystroke;
    mathField.__controller.keystroke = function(key, e, ctrlr) {
      if(key === 'Ctrl-Enter' || key === 'Meta-Enter' || key === 'Esc') {
        that.paperRenderer.unsetEditingShape();

      } else {
        if(key === 'Enter') {
          key = 'Right';
        }
        return this.origKeystroke(key, e, ctrlr);
      }
    };

    mathField.__controller.origNotify = mathField.__controller.notify;
    mathField.__controller.notify = function(...args) {
      $timeout(() => that._onLocalShapeEdit(shape));
      return this.origNotify(...args);
    };

    // Bugfix: Mathquill hangs on iOS when typing a circumflex accent
    let textarea = mathField.__controller.textarea;
    textarea.attr('allow-shortcuts', '');
    textarea.on('input', () => {
      let dom = textarea[0];
      if(dom.value.length > 1 && (dom.value[0] === 'ˆ' || dom.value[0] === '^')) {
        dom.value = '';
      }
    });
  }


  _onLocalShapeEdit(shape) {
    let latex = shape.mathField.latex();
    let dummyMathField = MQ.MathField(document.createElement('span')).latex(latex);

    // If we send latex that will be changed at a remote participant, they may send an event back
    // with the changed latex. Example: type "\i", and an event with latex "" is returned.
    if(dummyMathField.latex() === latex) {
      this._sendShapeSizeUpdates([shape]);
    }
  }


  /**
   * Whenever a shape is added, if the shape is a formula: add it to the this.formulaShapes
   * class variable
   *
   * @param {*} paperRenderer
   * @param {Shape} origShape
   * @returns
   */
  _onShapeAdd(paperRenderer, origShape) {
    if(origShape.type !== 'formula') {
      return;
    }

    let shape = {
      id: origShape.id,
      origShape: origShape,
      left: 0,
      top: 0,
      fontSize: 0,
      latex: '',
    };
    this.formulaShapes[origShape.id] = shape;

    let mathField = this._createMathField(shape);
    shape.mathField = mathField;
    shape.container = mathField.__controller.container;
    shape.textarea = mathField.__controller.textarea;

    if(
      this.$elemCurrent
      && this.paperRenderer.page
      && this.paperRenderer.page.id === origShape.pageId
    ) {
      this.$elemCurrent.append(shape.container);
    }
    this._onShapesEdit(this.paperRenderer, [[origShape, origShape.properties]]);
  }


  _onShapeRemove(paperRenderer, origShape) {
    let shape = this.formulaShapes[origShape.id];
    if(shape) {
      delete this.formulaShapes[origShape.id];
      shape.container.remove();
    }
  }


  _onShapesEdit(_paperRenderer, shapeInfos) {
    let shapesToUpdatePosition = [];
    let shapesToUpdateSize = [];

    shapeInfos.forEach(shapeInfo => {
      let [origShape, update] = shapeInfo;
      let shape = this.formulaShapes[origShape.id];
      if(!shape) {
        return;
      }

      if('color' in update) {
        shape.container.css('color', update.color);
      }

      let updateSize = false;
      let updatePosition = false;

      if(
        'latex' in update
        && update.latex !== shape.latex
        && shape.origShape !== this.paperRenderer.editingShape
      ) {
        shape.latex = update.latex;
        shape.mathField.latex(update.latex);
        updateSize = true;
      }

      if('fontSize' in update && update.fontSize !== shape.fontSize) {
        shape.fontSize = update.fontSize;
        updatePosition = true;
        updateSize = true;
      }

      if('left' in update && update.left !== shape.left) {
        shape.left = update.left;
        updatePosition = true;
      }
      if('top' in update && update.top !== shape.top) {
        shape.top = update.top;
        updatePosition = true;
      }

      if(updatePosition) {
        shapesToUpdatePosition.push(shape);
      }
      if(updateSize) {
        shapesToUpdateSize.push(shape);
      }
    });

    if(this.isReadyToRender) {
      this._updateShapePositions(shapesToUpdatePosition);
      this._sendShapeSizeUpdates(shapesToUpdateSize);
    }
  }


  _onShapeEditing(_paperRenderer, origShape, editing) {
    let shape = this.formulaShapes[origShape.id];
    if(shape) {
      if(editing) {
        shape.mathField.focus();
        this.paperRenderer.setElemActiveShape(shape.textarea[0]);
        this._dispatchMouseEvent(this.$lastMouseEvent, true, shape.textarea[0]);
      } else {
        shape.mathField.blur();
      }
    }
  }


  _onAddFormula(formula) {
    if(this.editingShape) {
      this.editingShape.mathField.__controller.paste(formula);
      this._onLocalShapeEdit(this.editingShape);
    }
  }


  /**
   * update the location of formula shapes
   *
   * because formula shapes are not fabric.js objects, changing the fabric canvas
   * will not update the location of the formula shapes. These transformation must be
   * made manually
   *
   * @param {Shape} shapes
   */
  _updateShapePositions(shapes, size, offset, zoom) {
    if(size == null) {
      size = this.whiteboard.canvasSize;
    }
    if(offset == null) {
      offset = this.whiteboard.canvasPan;
    }
    if(zoom == null) {
      zoom = this.whiteboard.pixelsPerPoint;
    }

    shapes.forEach(shape => {
      let left = shape.left * zoom - offset.x;
      let top  = shape.top  * zoom - offset.y;
      let transform = format('translate(%spx, %spx)', left, top);
      shape.container.css({
        transform: transform,
        webkitTransform: transform, // For node.js
        fontSize: (shape.fontSize * zoom) + 'px',
      });
    });
  }


  _sendShapeSizeUpdates(shapes) {
    let ppp = this.whiteboard.pixelsPerPoint;

    let editArgs = shapes.map(shape => {
      let update = {
        width: shape.container.width() / ppp,
        height: shape.container.height() / ppp,
        latex: shape.mathField.latex(),
      };
      return [shape.origShape, update];
    });
    this.paperRenderer.editShapes(editArgs, false, false);
  }




  /***********************
   * Handle mouse events *
   ***********************/

  _onMouseEvent($event) {
    if($event.originalEvent.__customFormulaEvent) {
      return;
    }

    this._patchTouchEvent($event);
    if($event.type !== 'mousemove') {
      this._dispatchMouseEvent($event);
    }
    this.$lastMouseEvent = $event;
  }


  _dispatchMouseEvent($event, isInitialEvent, elem) {
    if(!$event) {
      return;
    }

    let origShape = this.paperRenderer.editingShape;
    if(!origShape) {
      return;
    }
    let shape = this.formulaShapes[origShape.id];
    if(!shape) {
      return;
    }

    if(elem == null) {
      let container = shape.mathField.__controller.container;
      container.css('pointerEvents', 'all');
      elem = document.elementFromPoint($event.clientX, $event.clientY);
      container.css('pointerEvents', 'none');
    }

    if(!elem) {
      return;
    }

    // First send a mousedown event so Mathquill knows it must track mousemove and mouseup
    if(isInitialEvent && $event.type !== 'mousedown') {
      this._dispatchEventOfType(elem, $event, 'mousedown');
    }
    this._dispatchEventOfType(elem, $event);
  }


  _patchTouchEvent($event) {
    if(!$event.type.startsWith('touch')) {
      return;
    }

    let $eventForTouches = ($event.type === 'touchend' ? this.$lastMouseEvent : $event) || {};
    let touches = $eventForTouches.touches || {};

    if(touches.length === 1) {
      $event.clientX = touches[0].clientX;
      $event.clientY = touches[0].clientY;
      $event.pageX = touches[0].pageX;
      $event.pageY = touches[0].pageY;
    }
    $event.type = TOUCH_TO_MOUSE_EVENT_TYPES[$event.type];
  }


  _dispatchEventOfType(elem, $event, type) {
    if(type == null) {
      type = $event.type;
    }

    let event = new MouseEvent(type, $event);
    event.__customFormulaEvent = true;
    elem.dispatchEvent(event);
  }
}
