import { AfterViewInit, Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';
import { DropdownOptionFull } from 'utils/form-elements/dropdowns';


@Component({
  selector: 'dropdown-input',
  templateUrl: './dropdown-input.component.html',
})
export class DropdownInputComponent
  extends ContactFormQuestionInputComponent<string | null>
  implements AfterViewInit {

  ngAfterViewInit() {
    if(!this.question.required) {
      this.writeValue(null);
    }
  }

  trackByValue(index, option: DropdownOptionFull): string {
    return option.value;
  }

  get options(): DropdownOptionFull[] {
    const options: DropdownOptionFull[] = [];
    if(!this.question.extra.options) {
      return [];
    }
    this.question.extra.options.forEach(option => {
      options.push({
        value: option[0],
        label: option[1],
        disabled: false
      });
    });
    return options;
  }

  parsePrefill(string: string) {
    if(this.options.find(option => option[0] === string)) {
      return string;
    } else {
      return null;
    }
  }
}
