import { logger, errors } from 'utils/util';
import { browser } from 'utils/util';
import { StreamType } from  'meeting/meeting-room/stream';

export const ScreenType = Object.freeze({
  SCREEN: 'screen',
  WINDOW: 'window',
  TAB: 'tab',
});

const ScreenTypeDisplaySurface = Object.freeze({
  [ScreenType.SCREEN]: 'monitor',
  [ScreenType.WINDOW]: 'window',
  [ScreenType.TAB]: 'browser',
});


export default class ScreenshareStreamService {
  get ScreenType() {
    return ScreenType;
  }

  static get $inject() {
    return [
      'chromeExtensionService',
      'mediaDeviceService',
      'permissionArrowService',
    ];
  }

  constructor(
    chromeExtensionService,
    mediaDeviceService,
    permissionArrowService
  ) {
    this.chromeExtensionService = chromeExtensionService;
    this.mediaDeviceService = mediaDeviceService;
    this.permissionArrowService = permissionArrowService;
  }


  supportsScreenType() {
    return (
      this.supports('screen')
      && (
        this.chromeExtensionService.isInstalled
        || this._supportsGDMMediaSource()
      )
    );
  }

  _supportsGDM() {
    if(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      return true;
    } else {
      return false;
    }
  }

  _supportsGDMMediaSource() {
    // No browser supports this ATM.
    return false;
  }



  supports(type) {
    return (
      type === StreamType.SCREEN && browser.supportsScreenshare()
      || type === StreamType.COBROWSE && browser.supportsCobrowse()
    );
  }


  get(type, screenType) {
    return $q.resolve().then(() => {
      if(!this.supports(type)) {
        throw new errors.NotSupportedError('Unsupported screenshare browser');
      }

      if(
        this.chromeExtensionService.isInstalled
        || !this._supportsGDM()
        || type === StreamType.COBROWSE
      ) {
        return this._getWithGUM(type, screenType);

      } else {
        return this._getWithGDM(screenType);
      }
    });
  }



  /****************
   * getUserMedia *
   ****************/

  _getWithGUM(type, screenType) {
    let constraints;
    return this._getGUMConstraints(type, screenType)
      .then(argConstraints => {
        constraints = argConstraints;
        this._showPermissionArrow([type]);
        return navigator.mediaDevices.getUserMedia(constraints);  // eslint-disable-line compat/compat, max-len
      })
      .then(mediaStream => {
        if(type === StreamType.COBROWSE) {
          let track = mediaStream.getVideoTracks()[0];
          track.sourceId = constraints.video.mandatory.chromeMediaSourceId;
        }
        return mediaStream;
      });
  }


  _getGUMConstraints(type, screenType) {
    return this._prepareToGetGUMConstraints(type)
      .then(() => {
        if(browser.isBlink()) {
          return this._getGUMConstraintsChrome(type, screenType);
        } else {
          return this._getGUMConstraintsFirefox();
        }
      });
  }


  _prepareToGetGUMConstraints(type) {
    return $q.resolve().then(() => {
      if(
        !browser.isBlink()
        || this.chromeExtensionService.isInstalled
      ) {
        return;
      }

      let requireUserGesture = (type === 'cobrowse');
      return this.chromeExtensionService.install(type, requireUserGesture);
    });
  }


  _getGUMConstraintsFirefox() {
    return $q.resolve({
      audio: false,
      video: {
        mozMediaSource: 'screen',
        mediaSource: 'screen',
        frameRate: { min: 1, max: 10 },
      },
    });
  }


  _getGUMConstraintsChrome(type, screenType) {
    return this._getChromeSourceId(type, screenType)
      .then(response => {
        let constraints = {
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: response.sourceId,
              maxWidth: window.screen.width > 1920 ? window.screen.width : 1920,
              maxHeight: window.screen.height > 1080 ? window.screen.height : 1080,
              minFrameRate: 1,
              maxFrameRate: 10,
            },
            optional: [],
          },
        };

        if(response.canRequestAudioTrack) {
          constraints.audio = {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: response.sourceId,
            },
            optional: [],
          };
        }

        return constraints;
      });
  }


  _getChromeSourceId(type, screenType) {
    if(type === ScreenType.SCREEN && screenType) {
      type += '-' + screenType;
    }

    return this.chromeExtensionService.sendMessage({
      type: 'getSourceId',
      sourceType: type,
    })
      .then(response => {
        if(response == null) {
          logger.info('The chrome extension did not reply to a getSourceId request.');
          throw 'NotInstalledChromeError';
        } else {
          return response;
        }
      });
  }


  /******************
   * getDisplayMedia *
   *******************/

  _getWithGDM(screenType) {
    this._showPermissionArrow();

    let constraints = {
      video: true,
      audio: true,
    };
    if(screenType && this._supportsGDMMediaSource()) {
      constraints.video = {
        displaySurface: ScreenTypeDisplaySurface[screenType]
      };
    }

    return navigator.mediaDevices.getDisplayMedia(constraints)  // eslint-disable-line compat/compat, max-len
      .finally(() => {
        this._hidePermissionArrow();
      });
  }



  /*****************************
   * Show the permission arrow *
   *****************************/

  _showPermissionArrow() {
    if(this._shouldShowPermissionArrow()) {
      this.permissionArrowService.show();
    }
  }

  _hidePermissionArrow() {
    this.permissionArrowService.hide();
  }

  _shouldShowPermissionArrow() {
    return browser.isFirefox();
  }
}
