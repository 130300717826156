import { Component, forwardRef, Input } from '@angular/core';
import { SimpleControlValueAccessor } from '../simple-control-value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { array } from 'utils/util';
import { FormatStringArrayPipe } from 'utils/pipes/format-string-array.pipe';
import { FileSizePipe } from 'utils/pipes/file-size.pipe';
import { ErrorValue } from 'utils/settings/settings.component';
import { file } from 'utils/util';

type DataURL = string;
export type FileItem = {
  name:string,
  url: DataURL
};

type MimeTypeDefinition = {
  name: string,
};
// When we start supporting other files we will need to add them here.
export type MimeType = 'image/jpeg' | 'image/png' | 'image/svg';
export const mimeTypeMap: Record<MimeType, MimeTypeDefinition> = {
  'image/jpeg': {
    name: 'JPG',
  },
  'image/png': {
    name: 'PNG',
  },
  'image/svg': {
    name: 'SVG',
  },
} as const;

@Component({
  selector: 'file-picker',
  templateUrl: './file-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilePickerComponent),
      multi: true,
    },
  ],
})
export class FilePickerComponent extends SimpleControlValueAccessor<any> {
  @Input() uploadText = $localize `Upload file...`;
  @Input() clearText =  $localize `Clear file`;
  @Input() allowedTypes: MimeType[] = [];
  @Input() maxSize = 10 * 1000 * 1000;

  public errors: ErrorValue = [];
  public selectedFile!: File | null;

  constructor(
    private formatStringArrayPipe: FormatStringArrayPipe,
    private fileSizePipe: FileSizePipe,
  ) {
    super();
  }

  get allowedTypesNames() {
    const allowedNames = this.allowedTypes.map(type => mimeTypeMap[type].name);
    return this.formatStringArrayPipe.transform(allowedNames, 0, $localize `or`);
  }

  get allowedTypesText() {
    return $localize `Supported types: ${this.allowedTypesNames}`;
  }

  validateFile(file: File) {
    const errors: string[] = [];
    if(this.allowedTypes.length > 0 && !array.has(this.allowedTypes, file.type)) {
      errors.push(
        $localize `:example; Please choose a JPG or PNG file:Please choose a ${this.allowedTypesNames} file.` // eslint-disable-line max-len
      );
    }
    if(file.size > this.maxSize ) {
      const maxSize = this.fileSizePipe.transform(this.maxSize);
      errors.push($localize `Please choose a file that is smaller than ${maxSize}.`);
    }

    return errors;
  }

  /**
   *
   * @param selectedFile; The file we selected
   *
   * This function will write a FileItem as value. This FileItem keeps track
   * of the name of the file and the Base64 DataUrl.
   */
  setFile(selectedFile: File) {
    this.errors = this.validateFile(selectedFile);
    if(this.errors.length > 0) {
      return;
    }
    file.toDataURL(selectedFile).then((url: DataURL) => {
      const item = {
        name: selectedFile.name,
        url: url
      };
      this.writeValue(item);
    });
  }

  clearFile() {
    const value = null;
    this.writeValue(value);
  }
}
