import Tile from '../Tile';


export default class FloatingTile extends Tile {
  constructor(id) {
    super(id);

    this.tiles = {};
    this.layout = {
      tiles: [],
    };
  }


  draw(...args) {
    super.draw(...args);

    this.layout.tiles.forEach(({ tile, rect }) => tile.draw(rect));
  }


  setLayout(layout) {
    this.layout = layout;
  }
}
