import { AddImageType } from '../../../components/whiteboard/whiteboard.service';


UploadFileDirective.$inject = [
  'whiteboardService',
];

export default function UploadFileDirective(
  whiteboardService
) {
  return {
    restrict: 'A',
    link: function($scope, $elem) {
      let $elemInput = angular.element(
        '<input id="uploadFileInput" type="file" multiple style="display: none;" />'
      );
      angular.element(document.documentElement).append($elemInput);
      $elem.on('click', () => $elemInput.trigger('click'));

      $elemInput.on('change', () => {
        let files = Array.prototype.slice.apply($elemInput[0].files);
        $elemInput[0].value = null;

        $scope.$apply(() => {
          let addImageType = $scope.uploadFile;
          if(addImageType !== AddImageType.NEW_WHITEBOARD) {
            let activeWb = whiteboardService.getActiveWhiteboard();
            whiteboardService.addLocalFiles(files, addImageType, activeWb);
          } else {
            whiteboardService.addLocalFiles(files, addImageType);
          }
        });
      });

      // also destroy the created <input> element
      $scope.$on('$destroy', () => {
        $elemInput.remove();
      });
    },
    scope: {
      uploadFile: '<',
    },
  };
}
