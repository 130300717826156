<fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>
<div *ngIf="!fetchError && appointments.length > 0">
  <h2 i18n>Past meetings</h2>

  <appointments-table 
    [appointments]="appointments"
    [scope]="scope"
    (appointmentChange)="refetch()">
  </appointments-table>

  <div class="d-flex justify-content-center">
    <pagination [(page)]="page" [numPages]="numPages"></pagination>
  </div>
</div>
