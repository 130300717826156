import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { BookingLinksMenuModule } from './booking-links-menu';
import { HomeComponent } from './home.component';
import { MeetingRoomsMenuModule } from 'dashboard/home/meeting-rooms-menu';
import { WaitingRoomModule } from './waiting-room';
import {
  AppointmentsTableModule
} from 'scheduling/manage/appointments-table/appointments-table.module';

@NgModule({
  imports: [
    AppointmentsTableModule,
    BrowserModule,
    UtilsModule,
    BookingLinksMenuModule,
    MeetingRoomsMenuModule,
    WaitingRoomModule,
  ],
  declarations: [
    HomeComponent,
  ],
  exports: [
    HomeComponent,
  ],
})
export class HomeModule {}
