import { ContactWidgetBehaviour, ContactWidget } from 'contactWidget/models/ContactWidget';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { Member } from 'organization/models/Member';
import { ContactForm } from 'contactForm/models/ContactForm';
import { Component, Input, OnInit } from '@angular/core';
import { bind, logger, storage } from 'utils/util';
import {
  SettingsComponent
} from 'utils/settings/settings.component';
import { UrlService } from 'utils/url.service';

import {
  SchedulingOnboardingWizardService
} from 'scheduling/manage/scheduling-onboarding-wizard/scheduling-onboarding-wizard.service';
import { SchedulingState } from 'scheduling/variables';
import { siteColor } from 'utils/util';
import { WaitingRoom } from 'meeting/models/WaitingRoom';
import { AppLogoService } from 'utils/ui-components/logo/app-logo/app-logo.service';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import {
  ContactType, LinkedItem, getLinkedItem, getLinkedItemWarning
} from 'contactWidget/util/contact-type';
import { UsageTrackingService } from 'utils/usage-tracking.service';


/**
* On the Contact Widget creation page, the user will be able to choose whether he wants to link
* a "Contact form" or a "Meeting Type". The last one actually contains 3 different ContactTypes,
* which is why we introduce a ContactTypeGroup concept.
*/
export enum ContactTypeGroup {
  SCHEDULING = 'scheduling',
  CONTACT_FORM = 'contact_form',
  MEETING_ROOM = 'meeting_room',
}

/**
 * Every ContactGroup has some properties that are needed to display them properly on the page.
 */
type ContactTypeGroupConfig = {
  id: string
  name: string
  svgPath: string
  contactTypes: readonly ContactType[];
}

const LOCAL_STORAGE_FORM_KEY = 'contact-widget-settings';
const LOCAL_STORAGE_ICON_KEY = 'contact-widget-settings-icon';



@Component({
  selector: 'contact-widget-settings[instance]',
  templateUrl: './contact-widget-settings.component.html',
  styleUrls: ['./contact-widget-settings.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactWidgetSettingsComponent
  }],
})

export class ContactWidgetSettingsComponent
  extends SettingsComponent<ContactWidget>
  implements OnInit {

  @Input() appointmentTypes!: AppointmentType[];
  @Input() contactForms!: ContactForm[];
  @Input() teamMembers!: Member[];
  @Input() waitingRooms!: WaitingRoom[];
  readonly ContactWidgetBehaviour = ContactWidgetBehaviour;
  private trackedFields = new Set<string>();

  public ContactType = ContactType;
  public contactTypeGroups = [
    ContactTypeGroup.SCHEDULING,
    ContactTypeGroup.CONTACT_FORM,
    ContactTypeGroup.MEETING_ROOM,
  ];
  public contactTypeGroup: ContactTypeGroup;
  public ContactTypeGroup = ContactTypeGroup;

  static override formFields = [
    'text',
    'textColor',
    'buttonColor',
    'showIcon',
    'behaviour',
    'icon',
  ];

  public destinationOptions: LinkedItem[] = [];
  public linkedItemWarning = '';

  readonly contactTypeGroupConfigMap: Record<
    ContactTypeGroup,
    ContactTypeGroupConfig
  > = {
    [ContactTypeGroup.SCHEDULING]: {
      id: 'appointmentType',
      name: $localize`Meeting type`,
      svgPath: 'contactWidget__contactTypeCalendar',
      contactTypes: [
        ContactType.ORGANIZATION,
        ContactType.USER,
        ContactType.APPOINTMENT_TYPE
      ],
    },
    [ContactTypeGroup.CONTACT_FORM]: {
      id: 'contactForm',
      name: $localize`Contact form`,
      svgPath: 'contactWidget__contactTypeForm',
      contactTypes: [ContactType.CONTACT_FORM],
    },
    [ContactTypeGroup.MEETING_ROOM]: {
      id: 'meetingRoom',
      name: $localize`Waiting room`,
      svgPath: 'contactWidget__contactTypeMeetingRoom',
      contactTypes: [ContactType.MEETING_ROOM],
    }
  } as const;

  constructor(

    private usageTrackingService: UsageTrackingService,
    public urlService: UrlService,
    public schedulingOnboardingWizardService: SchedulingOnboardingWizardService,
    private appLogoService: AppLogoService,
  ) {
    super();
    bind(this);

    setTimeout(() => {
      ContactWidgetSettingsComponent.formFields.forEach(field => {
        this.formGroup.get(field)?.valueChanges.subscribe(() => this.trackFieldChanged(field));
      });
    });

    this.contactTypeGroup = ContactTypeGroup.SCHEDULING;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.shouldShowSchedulingOnboarding) {
      this.schedulingOnboardingWizardService.wizardFinished.subscribe(() => {
        storage.removeItem(LOCAL_STORAGE_FORM_KEY);
        storage.removeItem(LOCAL_STORAGE_ICON_KEY);
      });

      try {
        const inputsToRestore = storage.getJSONItem(LOCAL_STORAGE_FORM_KEY, {});
        if (Object.keys(inputsToRestore).length !== 0) {
          this.formGroup.setValue(inputsToRestore);
        }

        const iconToRestore = storage.getItem(LOCAL_STORAGE_ICON_KEY);
        if (iconToRestore != null) {
          this.instance.icon = iconToRestore;
        }
      } catch (error) {
        logger.warn('could not restore the form inputs');
        logger.warn(error);
      }
    } else {
      storage.removeItem(LOCAL_STORAGE_FORM_KEY);
      storage.removeItem(LOCAL_STORAGE_ICON_KEY);
    }


    if (!this.requestUserService.user.organization.hasOneActiveMember) {
      this.destinationOptions.push(
        {
          type: ContactType.ORGANIZATION,
          value: this.requestUserService.user.organization,
        }
      );
    }
    this.destinationOptions = this.destinationOptions.concat(
      this.teamMembers
        .filter(member => member.isActive)
        .map(
          member => {
            return {
              type: ContactType.USER,
              value: member,
            };
          }
        )
    );
    this.destinationOptions = this.destinationOptions.concat(
      this.appointmentTypes
        .filter(appointmentType => appointmentType.active)
        .map(
          appointmentType => {
            return {
              type: ContactType.APPOINTMENT_TYPE,
              value: appointmentType,
            };
          }
        )
    );

    this.destinationOptions = this.destinationOptions.concat(
      this.contactForms
        .filter(contactForm => contactForm.isActive)
        .map(
          contactForm => {
            return {
              type: ContactType.CONTACT_FORM,
              value: contactForm,
            };
          }
        )
    );

    this.destinationOptions = this.destinationOptions.concat(
      this.waitingRooms
        .map(
          meeting => {
            return {
              type: ContactType.MEETING_ROOM,
              value: meeting,
            };
          }
        )
    );

    if (this.requestUserService.user.schedulingConfig.state !== SchedulingState.NEW) {
      this.schedulingOnboardingWizardService.continueWizard(false);
    }

    // On the contact form overview page, it's possible to create a contact widget from
    // a specific contact form. In that case, we should set the correct contact form in the
    // dropdown
    const urlParams = new URLSearchParams(window.location.search);
    const destinationId = urlParams.get('destinationId');
    const selectedOption = this.destinationOptions.find(
      option => option.value.id === destinationId
    );
    if (selectedOption != null) {
      this.selectOption(selectedOption);
    } else {
      this.selectOption(this.selectedOption);
    }
  }

  getContactTypes(contactTypeGroup: ContactTypeGroup) {
    return this.contactTypeGroupConfigMap[contactTypeGroup].contactTypes;
  }

  setContactTypeGroup(contactTypeGroup: ContactTypeGroup) {
    this.contactTypeGroup = contactTypeGroup;
    this.selectOption(this.filteredDestinationOptions[0]);
  }

  isCurrentContactTypeGroup(contactTypeGroup: ContactTypeGroup) {
    return this.contactTypeGroup === contactTypeGroup;
  }

  hasDestinationOptions(contactTypeGroup: ContactTypeGroup) {
    return this.destinationOptions.filter(
      option => this.getContactTypes(contactTypeGroup).includes(option.type)
    ).length > 0;
  }

  get filteredDestinationOptions() {
    return this.destinationOptions.filter(
      option => this.getContactTypes(this.contactTypeGroup).includes(option.type)
    );
  }

  get defaultButtonColor() {
    return siteColor.getDefaultColor('primaryButton');
  }

  get defaultButtonTextColor() {
    // We need some more logic to get the text color, since the default color can be light or dark,
    // depending on the current button color.
    return siteColor.getButtonTextColorDeprecated(
      this.instance.textColor,
      this.instance.buttonColor
    );
  }

  get hasPermissionToEditIcon() {
    return !this.hasFreeSubscription;
  }


  get shouldShowSchedulingOnboarding() {
    return this.contactTypeGroup === ContactTypeGroup.SCHEDULING
      && !this.schedulingOnboardingWizardService.hasSeenWizard;
  }

  startOnboarding() {
    storage.setJSONItem(LOCAL_STORAGE_FORM_KEY, this.formGroup.getRawValue());
    if (this.instance.icon != null) {
      storage.setItem(LOCAL_STORAGE_ICON_KEY, this.instance.icon);
    }
    this.schedulingOnboardingWizardService.showWizard(false);
  }

  get selectedOption(): LinkedItem {
    const linkedItem = getLinkedItem(this.instance);
    if (linkedItem === undefined) {
      return {
        type: ContactType.ORGANIZATION,
        value: this.requestUserService.user.organization,
      };
    } else {
      return linkedItem;
    }
  }

  isSelectedOption(option: LinkedItem) {
    const selectedOption = this.selectedOption;
    if (option.type === 'organization' && selectedOption.type === 'organization') {
      return true;
    } else {
      return (option.type === selectedOption.type
        && (option.value && selectedOption.value && option.value.id === selectedOption.value.id));
    }
  }

  changeOption(option: LinkedItem | undefined) {
    this.trackFieldChanged('linkedItem');
    this.selectOption(option);
  }

  private selectOption(option: LinkedItem | undefined) {
    this.instance.organization = null;
    this.instance.user = null;
    this.instance.appointmentType = null;
    this.instance.contactForm = null;
    this.instance.meeting = null;

    switch (option?.type) {
      case ContactType.ORGANIZATION:
        this.instance.organization = option.value;
        this.contactTypeGroup = ContactTypeGroup.SCHEDULING;
        break;
      case ContactType.USER:
        this.instance.user = option.value;
        this.contactTypeGroup = ContactTypeGroup.SCHEDULING;
        break;
      case ContactType.APPOINTMENT_TYPE:
        this.instance.appointmentType = option.value;
        this.contactTypeGroup = ContactTypeGroup.SCHEDULING;
        break;
      case ContactType.CONTACT_FORM:
        this.instance.contactForm = option.value;
        this.contactTypeGroup = ContactTypeGroup.CONTACT_FORM;
        break;
      case ContactType.MEETING_ROOM:
        this.instance.meeting = option.value;
        this.formGroup.get('behaviour')?.setValue(ContactWidgetBehaviour.TAB);

        this.contactTypeGroup = ContactTypeGroup.MEETING_ROOM;
        break;
    }

    this.linkedItemWarning = getLinkedItemWarning(
      this.selectedOption, this.teamMembers, this.requestUserService.user
    );
  }

  get canSelectModalBehaviour() {
    return this.instance.meeting == null;
  }

  get defaultImage(): SvgIcon {
    return this.appLogoService.getSvgIcon('dark', 'icon');
  }

  trackByDestinationOption(index, option: LinkedItem) {
    return option.type + option.value?.id;
  }


  trackFieldChanged(field: string) {
    // Only send each event a single time.
    if (this.trackedFields.has(field)) {
      return;
    }
    this.trackedFields.add(field);

    this.usageTrackingService.createSegmentEvent(
      'contactWidget.changedField',
      'contactWidget',
      {
        field: field,
      },
    );
  }
}
