IntervalIfVisibleFactory.$inject = [
  'focusService',
];

export default function IntervalIfVisibleFactory(
  focusService
) {
  let intervals = {};
  let maxId = 0;

  focusService.on('isVisible', onVisible);


  function setIntervalFn(fn, ...args) {
    let id = ++maxId;
    intervals[id] = {
      fn: fn,
      args: args,
      promise: null,
    };

    if(focusService.isVisible) {
      startInterval(id, false);
    }

    return id;
  }


  function onVisible() {
    if(focusService.isVisible) {
      Object.keys(intervals).forEach(id => startInterval(id, true));
    }
  }


  function startInterval(id, runNow) {
    let definition = intervals[id];
    if(!definition.promise) {
      definition.promise = $interval(() => callback(id), ...definition.args);
      if(runNow) {
        callback(id);
      }
    }
  }

  function stopInterval(id) {
    let definition = intervals[id];
    $interval.cancel(definition.promise);
    definition.promise = null;
  }

  function callback(id) {
    let definition = intervals[id];
    if(focusService.isVisible) {
      definition.fn();
    } else {
      stopInterval(id);
    }
  }


  setIntervalFn.cancel = stopInterval;
  return setIntervalFn;
}
