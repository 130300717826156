import templateModalUploadContent from './uploadContent.modal.html?raw';
import filetypes from 'main/supportedFiletypes.json';
import { AddImageType } from '../whiteboard.service';

export const ADD_FILE_MODAL_ID = 'addFile';

export default class UploadContentService {
  static get $inject() {
    return [
      'modalService',
      'whiteboardService',
    ];
  }

  constructor(
    modalService,
    whiteboardService
  ) {
    this.modalService = modalService;
    this.whiteboardService = whiteboardService;

    this.modalService.register(
      ADD_FILE_MODAL_ID,
      gettext('Add File'),
      'utils/icons/tl/24x24_cloud_upload_outline.svg',
      templateModalUploadContent
    );

    this.files = null;
    this.activeWb = null;
  }

  get isMultipleFiles() {
    return this.files && this.files.length > 1;
  }

  /**
   * Decide if the user should select a location for the new files
   *
   * only if all files are vector or bitmap options, and a whiteboard is currently
   * active, will the modal be shown to add the new images to the existing whiteboard.
   *
   * @param {[Object]} files - list of files, each of which should contain a 'type' MIMEtype attr
   */
  uploadContent(files) {
    if(!files) {
      return;
    }

    this.files = files;
    const imageMimetypes = filetypes['bitmap']['mimetype'].concat(filetypes['vector']['mimetype']);
    let isAllImages = files.reduce(
      (prev, curr) => prev && imageMimetypes.includes(curr.type),
      true
    );
    let activeWb = this.whiteboardService.getActiveWhiteboard();
    if(activeWb && isAllImages) {
      this.activeWb = activeWb;
      this.modalService.show(ADD_FILE_MODAL_ID);
    } else {
      this.whiteboardService.addLocalFiles(this.files, AddImageType.NEW_WHITEBOARD);
    }
  }

  /**
   * If a location is selected in the modal, pass this to whiteboardService and close the modal
   *
   * @param {AddImageType} addImageType
   */
  addLocalFiles(addImageType) {
    this.modalService.hide(ADD_FILE_MODAL_ID);
    this.whiteboardService.addLocalFiles(this.files, addImageType, this.activeWb);
    this.files = null;
  }
}
