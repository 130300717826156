import { format } from 'utils/util';


const HEX_DIGITS = '0123456789abcdef';


export default function ColorFieldDirective() {
  return {
    restrict: 'A',
    link: function($scope, $elem) {
      setText($elem);


      function setText($elem) {
        let property = getProperty($elem);
        let rgbaColor = $elem.css(property);
        let hexColor = rgba2hex(rgbaColor);
        let innerHTML = format('<span class="text-monospace">%s</span>', hexColor);
        if($elem.attr('color-field-usage')) {
          innerHTML += $elem.attr('color-field-usage')
            .split(',')
            .map(usage => format('<span>%s</span>', usage))
            .join('');
        }
        $elem.html(innerHTML);
      }

      function getProperty($elem) {
        if($elem.css('borderColor') && $elem.css('borderWidth') !== '0px') {
          return 'borderColor';
        } else if($elem.css('backgroundColor') !== 'rgba(0, 0, 0, 0)') {
          return 'backgroundColor';
        } else {
          return 'color';
        }
      }


      function rgba2hex(rgba) {
        let match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/);
        let r = parseInt(match[1]);
        let g = parseInt(match[2]);
        let b = parseInt(match[3]);
        let a = match[4] == null ? 1 : parseFloat(match[4]);

        let hex = format('#%s', [r, g, b].map(x => x * a + 255 * (1 - a)).map(int2hex).join(''));
        if(a !== 1) {
          hex += format(' / #%s', [r, g, b, a * 255].map(int2hex).join(''));
        }

        return hex;
      }

      function int2hex(x) {
        let int = Math.round(x);
        return HEX_DIGITS[(int - int % 16) / 16] + HEX_DIGITS[int % 16];
      }
    }
  };
}
