import './angularjs/angularjs.module';
import './angularjs/angularjsDowngradedComponents';
import './icons';
import './util';

import { CdkMenuModule } from '@angular/cdk/menu';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularjsUpgradedUtilsModule } from './angularjs-upgraded';
import { DirectivesModule } from './directives';
import { FormElementsModule } from './form-elements';
import { PipesModule } from './pipes';
import { SettingsModule } from './settings';
import { LocalStateService } from './state.service';
import { BadgeModule } from './ui-components/badge';
import { DialogModule } from './ui-components/dialog';
import { FetchErrorModule } from './ui-components/fetch-error';
import { GoBackHeaderModule } from './ui-components/go-back-header';
import { HelpTooltipModule } from './ui-components/help-tooltip/help-tooltip.module';
import { LogoModule } from './ui-components/logo';
import { ModalModule } from './ui-components/modal';
import { PaginationModule } from './ui-components/pagination';
import { PreviewModule } from './ui-components/preview';
import { ProFeatureBadgeModule } from './ui-components/pro-feature-badge';
import { ProFeatureLockModule } from './ui-components/pro-feature-lock';
import { ProfileImagesModule } from './ui-components/profile-images';
import { ScopeSwitcherModule } from './ui-components/scope-switcher';
import { SharingOptionsModule } from './ui-components/sharing-options/sharing-options.module';
import { SvgIconModule } from './ui-components/svg-icon';
import { UpgradeButtonModule } from './ui-components/upgrade-button';
import { WizardModule } from './wizard';
import { upgradedProviders } from './angularjs-upgraded-providers';
import { TruncatedTextModule } from './ui-components/truncated-text/trunctated-text.module';

@NgModule({
  providers: [
    LocalStateService,
    ...upgradedProviders
  ],
  exports: [
    AngularjsUpgradedUtilsModule,
    BadgeModule,
    CdkMenuModule,
    DialogModule,
    DirectivesModule,
    FormElementsModule,
    FetchErrorModule,
    GoBackHeaderModule,
    HelpTooltipModule,
    LogoModule,
    MatTooltipModule,
    ModalModule,
    PaginationModule,
    PipesModule,
    PreviewModule,
    ProfileImagesModule,
    ProFeatureBadgeModule,
    ProFeatureLockModule,
    ScopeSwitcherModule,
    SettingsModule,
    SharingOptionsModule,
    TruncatedTextModule,
    SvgIconModule,
    UpgradeButtonModule,
    WizardModule,
  ],
})
export class UtilsModule {}
