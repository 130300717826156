import { NgModule } from '@angular/core';
import { MembersPageComponent } from '../members/members-page/members-page.component';
import { UtilsModule } from 'utils/utils.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MembersBackendService } from './../members/members-backend.service';
import { InviteModalComponent } from './invite-modal/invite-modal.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    UtilsModule,
  ],
  providers: [
    MembersBackendService
  ],
  declarations: [
    MembersPageComponent,
    InviteModalComponent,
  ],
  exports: [
    MembersPageComponent,
  ],
})
export class MembersModule {}
