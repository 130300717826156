import logger from './logger';


export function assertOrLog(condition: boolean, message?: string): asserts condition {
  if (ANGULAR_SCOPE.deployEnv === 'dev') {
    // We still throw an error when developing so we're definitely aware
    return assertOrThrow(condition, message);
  }
  if(!condition) {
    logger.error(new Error(`Assertion failed${message != null ? `: ${message}` : '.'}`));
  }
}

export function assertOrThrow(condition: boolean, message?: string): asserts condition {
  if(!condition) {
    throw new Error(`Assertion failed${message != null ? `: ${message}` : '.'}`);
  }
}
