export default class DownloadFileController {
  static get $inject() {
    return [
      'fileService',
    ];
  }

  constructor(
    fileService
  ) {
    this.fileService = fileService;
  }
}
