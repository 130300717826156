import { Component, Inject } from '@angular/core';
import { DataPageComponent } from 'dashboard/data-page/data-page.component';
import { SiteService } from 'utils/site.service';
import { UrlService } from 'utils/url.service';
import { getQueryParameter } from 'utils/util';
import { ContactWidget } from '../models/ContactWidget';
import { TeamMembersService } from 'organization/teamMembers.service';
import { Member } from 'organization/models/Member';
import { getLinkedItem, getLinkedItemWarning } from 'contactWidget/util/contact-type';
import { UsageTrackingService } from 'utils/usageTracking.service';


// Used in contact_widget/overview.html to be able to use django's reverse function to get
// the update url for an appointment type. We will replace the dummy appointment id with the
// correct id in this component.
const DUMMY_CONTACT_WIDGET_ID = '00000000000000000000000000000000';


@Component({
  selector: 'contact-widgets',
  templateUrl: './contact-widgets.component.html',
  styleUrls: ['./contact-widgets.component.scss']
})
export class ContactWidgetsComponent extends DataPageComponent {
  public contactWidgets: ContactWidget[] = [];
  public shouldShowHint = false;
  public teamMembers: Member[] = [];

  constructor(
    @Inject('notificationService') private notificationService,

    private usageTrackingService: UsageTrackingService,
    private siteService: SiteService,
    public urlService: UrlService,
    public teamMemberService: TeamMembersService,
  ) {
    super();
  }


  get shouldSkipWelcome() {
    return getQueryParameter('skipWelcome') != null;
  }

  get shouldShowWelcome() {
    return (
      this.stateService.isReady
      && this.contactWidgets.length === 0
      && !this.fetchError
    );
  }

  get shouldShowMain() {
    return (
      this.stateService.isReady
      && !this.shouldShowWelcome
    );
  }


  getContactWidgetWarning(contactWidget): string {
    const linkedItem = getLinkedItem(contactWidget);
    if(linkedItem === undefined) {
      return '';
    }

    return getLinkedItemWarning(linkedItem, this.teamMembers, this.requestUserService.user);
  }

  override fetch() {
    const fetchTeamMembersPromise = this.fetchTeamMembers();
    const fetchContactWidgetPromise = this.fetchContactWidgets();

    return Promise.all([
      fetchTeamMembersPromise,
      fetchContactWidgetPromise,
    ]);
  }

  private async fetchTeamMembers() {
    this.teamMembers = await this.teamMemberService.get();
  }

  fetchContactWidgets() {
    const modelConfig = {
      model: ContactWidget,
    };
    const apiConfig = {
      params: {
        perPage: 'all',
        orderBy: '-dateUpdated',
      },
    };

    return this.listCancelable(modelConfig, apiConfig).then(({ data: contactWidgets }) => {
      if(contactWidgets.length === 0 && this.shouldSkipWelcome) {
        window.location.href = this.urlService.urls.contactWidgetCreate;
        // Return a promise that never resolves while we are redirecting.
        return new Promise(() => {});

      } else {
        const search = new URLSearchParams(window.location.search);
        search.delete('skipWelcome');
        this.location.replaceState(window.location.pathname, search.toString());

        this.contactWidgets = contactWidgets as ContactWidget[];
        return null;
      }
    });
  }



  /***********
   * Actions *
   ***********/

  getUpdateUrl(contactWidget: ContactWidget) {
    return this.urlService.urls.contactWidgetUpdate.replace(
      DUMMY_CONTACT_WIDGET_ID,
      contactWidget.id
    );
  }

  getEmailToWebmasterLink(contactWidget: ContactWidget) {
    return `mailto:?body=${contactWidget.snippet}`;
  }


  delete(contactWidget: ContactWidget) {
    contactWidget.delete()
      .catch(error => {
        this.notificationService.handleError(
          error,
          $localize `Something went wrong while deleting your widget.`,
        );
      })
      .finally(() => {
        this.fetch();
      });
  }

  get helpArticle() {
    return this.siteService.getHelpArticle('contactWidgetSnippet');
  }

  get helpGif() {
    if(this.siteService.site.isTeamleader) {
      return this.urlService.images.cmaContactWidgetHelp;
    } else {
      return  this.urlService.images.standaloneContactWidgetHelp;
    }
  }


  track(event: string) {
    this.usageTrackingService.createSegmentEvent(event, 'contactWidget');
  }
}
