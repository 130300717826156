import { format, logger } from 'utils/util';


const State = Object.freeze({
  EMPTY: 'empty',
  FETCHING: 'fetching',
  READY: 'ready',
});

/**
 * Keeps track of the users in a session and emits corresponding events
 *
 */
export default class UserInfoManager {
  static get State() {
    return State;
  }


  constructor(apiService, userService, requestUserService, user) {
    this.apiService = apiService;
    this.userService = userService;
    this.requestUserService = requestUserService;

    this.user = user;
    this.info = {};
    if(user.isInitialized) {
      this.info.id = user.id;
    }

    this.uploadPromise = $q.resolve();
    this.state = State.EMPTY;
  }


  get apiPath() {
    let id = this.info.id || 'me';
    return format(
      'users/%s/?include=organization.subscription,organization.integrationStatus',
      id
    );
  }


  update(info) {
    /**
     * Update the local info based on info we got from the server.
     */
    Object.assign(this.info, info);
    if(this.user.isMe) {
      this.userService.setMyId(this.info.id);
      this.requestUserService.user.setData(this.info);
    }
    this.state = State.READY;
  }


  set(info) {
    /**
     * Update the local info, and propagate these changes to the server.
     */

    // We need a defer because we have the following conflicting requirements when an error occurs
    // in the API call
    // - Reject the return promise, so that the caller can handle it (eg. by showing the error to
    //   the user).
    // - Ignore the error locally, so that we can handle subsequent upload() calls
    // Maybe there are more elegant ways to do this, but I can't figure them out.
    let defer = $q.defer();

    this.uploadPromise = this.uploadPromise.then(() => {
      return this.apiService.patch(this.apiPath, info, {
        maxRetries: Infinity,
      });
    })
      .then(response => {
        this.update(response.data, true);
        defer.resolve();
      }, error => {
        defer.reject(error);
      });

    return defer.promise;
  }


  fetch() {
    if(this.state === State.FETCHING) {
      return;
    }
    this.state = State.FETCHING;

    this.apiService.get(this.apiPath, {
      maxRetries: Infinity,
    })
      .then(response => {
        this.update(response.data, true);
      })
      .catch(error => {
        if(!error.response || error.response.status !== 404) {
          logger.warn('User info download failed:', error);
        }
        this.update({
          firstName: 'Deleted user',
          lastName: '',
          fullName: 'Deleted user',
          initials: '/',
        });
      });
  }
}
