import { MODAL_ID_REMOTE_RECORDING } from './recorder.service';


export default class RemoteRecordingModalController {
  static get $inject() {
    return [
      'endCallService',
      'modalService',
    ];
  }

  constructor(
    endCallService,
    modalService
  ) {
    this.endCallService = endCallService;
    this.modalService = modalService;
  }


  closeModal() {
    this.modalService.hide(MODAL_ID_REMOTE_RECORDING);
  }


  leaveMeeting() {
    this.endCallService.endCall();
  }
}
