import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}

  private readonly units = ['B', 'KB', 'MB', 'GB', 'TB'];

  toPrecision(number, precision) {
    if(number === 0) {
      return '0';
    } else if(Math.round(number) < Math.pow(10, precision)) {
      return number.toPrecision(precision);
    } else {
      return Math.round(number).toString();
    }
  }

  transform(value, precision = 2): string {
    if(value == null) {
      return '';
    }

    let unitIndex = 0;

    while(Math.round(value) >= 1000) {
      value /= 1000;
      unitIndex++;
    }
    const formattedValue = this.toPrecision(value, precision);

    return formattedValue + ' ' + this.units[unitIndex];  // Non-breaking space
  }
}
