<div class="container container--lg pp">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <go-back-header
    [url]="urlService.urls.appointmentTypesOverview"
    [headerText]="headerText"
  ></go-back-header>

  <div
    *ngIf="fetchError"
    class="field field--error mb-2"
  >
    <field-icon [type]="'error'"></field-icon>
    {{ fetchError }}
  </div>

  <div
    *ngIf="stateService.isReady && !isTeamAppointmentType && requestUserConfigurationWarning"
    class="field field--warning mb-2"
  >
    <field-icon [type]="'warning'"></field-icon>
    {{ requestUserConfigurationWarning }}
  </div>

  <settings-non-field-errors [nonFieldErrors]="errors.nonFieldErrors"></settings-non-field-errors>

  <div *ngIf="stateService.isReady && !fetchError" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <settings-segment-group>
      <settings-segment
        name="Meeting type details"
        i18n-name
      >
        <appointment-type-details
          [instance]="appointmentType"
          [teamMembers]="teamMembers"
          [errors]="errors"
        ></appointment-type-details>
      </settings-segment>

      <settings-segment
        name="Availability"
        i18n-name
      >
        <appointment-type-availability
          [instance]="appointmentType"
          [teamMembers]="teamMembers"
          [errors]="errors"
        ></appointment-type-availability>
      </settings-segment>

      <settings-segment
        name="Summary"
        i18n-name
        [hideNext]="true"
      >
        <appointment-type-summary
          [instance]="appointmentType"
          [teamMembers]="teamMembers"
        ></appointment-type-summary>
      </settings-segment>


      <h1 i18n>Powerful extensions</h1>


      <div class="border border-round-lg overflow-hidden">
        <settings-segment
          name="Ask questions"
          i18n-name
          description="Write questions for your invitees to answer"
          i18n-description
          [icon]="{ 'path': 'scheduling__48x48_custom_questions' }"
          [optional]="true"
          [hideNext]="true"
          data-testid="askQuestionsCollapser"
        >
          <appointment-type-questions
            [instance]="appointmentType"
            [errors]="errors"
          ></appointment-type-questions>
        </settings-segment>

        <settings-segment
          name="Reminders"
          i18n-name
          description="Send reminders to invitees before meetings"
          i18n-description
          [icon]="{ 'path': 'scheduling__48x48_reminders' }"
          [optional]="true"
          [hideNext]="true"
          data-testid="remindersCollapser"
        >
          <appointment-type-reminders
            [instance]="appointmentType"
            [errors]="errors"
          ></appointment-type-reminders>
        </settings-segment>

        <settings-segment
          name="Redirecting"
          i18n-name
          description="Send your invitees to your custom URL"
          i18n-description
          [icon]="{ 'path': 'scheduling__48x48_redirecting' }"
          [optional]="true"
          [hideNext]="true"
          data-testid="redirectingCollapser"
        >
          <appointment-type-redirecting
            [instance]="appointmentType"
            [errors]="errors"
          ></appointment-type-redirecting>
        </settings-segment>

        <settings-segment
          name="Customize email templates"
          i18n-name
          description="Set custom email replies for your meetings"
          i18n-description
          [icon]="{ 'path': 'scheduling__48x48_email_templates' }"
          [optional]="true"
          [hideNext]="true"
          data-testid="customizeEmailTemplatesCollapser"
        >
          <appointment-type-templates
            [instance]="appointmentType"
            [errors]="errors"
            data-testid="customizeEmailTemplatesConfigBody"
          ></appointment-type-templates>
        </settings-segment>

        <settings-segment
          name="Collect payments"
          i18n-name
          description="Set up a payment method for your meetings"
          i18n-description
          [icon]="{ 'path': 'scheduling__48x48_payments' }"
          [optional]="true"
          [hideNext]="true"
          data-testid="collectPaymentCollapser"
        >
          <appointment-type-payments
            [instance]="appointmentType"
            [errors]="errors"
            data-testid="collectPaymentsConfigBody"
          ></appointment-type-payments>
        </settings-segment>
      </div>
    </settings-segment-group>
  </div>
</div>


<div class="action-footer" *ngIf="stateService.isReady && !fetchError">
  <div class="action-footer__button-row">
    <a
      [attr.href]="urlService.urls.appointmentTypesOverview"
      class="btn btn--secondary"
      i18n
      data-testid="meetingTypeConfigCancelButton"
    >
      Cancel
    </a>

    <div
      class="btn btn--primary"
      [class.disabled]="stateService.isSaving"
      (click)="!stateService.isSaving && save()"
      data-testid="saveButton"
    >
      <span i18n>Save the meeting type</span>
      <loading *ngIf="stateService.isSaving" class="ml-2"></loading>
    </div>
  </div>
</div>


<ng-template #modalSuccess>
  <div class="d-flex flex-column align-items-center">
    <svg-icon
      class="icon text-primary-400 icon--xl"
      [svgName]="'checkmark_badged'"
      [svgStyle]="'filled'"
    ></svg-icon>

    <h3 i18n>Success!</h3>

    <p class="mb-8" i18n>You have successfully created a meeting type.</p>

    <a
      class="btn btn--primary align-self-stretch mb-4"
      [attr.href]="testItLiveUrl"
      data-testid="testLink"
      i18n
    >
      Test it live
    </a>

    <a
      class="btn btn--secondary align-self-stretch"
      [attr.href]="urlService.urls.appointmentTypesOverview"
      data-testid="goToMeetinTypesOverview"
      i18n
    >
      Go to meeting types
    </a>
  </div>
</ng-template>
