import { format } from 'utils/util';
import EMOJIS from './emojis.json';

/**
 * Directive for adding an inline emoji.
 *
 * Emojis are taken from the emojis.png spritesheet which is generated by the emoji.py script,
 * which bases itself on the emoji.csv datasheet.
 */
export default function EmojiImageDirective() {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {
      $scope.$watch($attr.emojiImage, setCode);

      $elem.css({
        width: '20px',
        height: '20px',
        display :'inline-block',
        backgroundSize: 'auto 20px',
        verticalAlign: 'top',
      });

      function setCode(code) {
        if(code in EMOJIS.codes) {
          let offset = format('-%s% 0', 100 * EMOJIS.codes[code]);
          $elem.css({
            backgroundImage: `url("${window.URLS.images.emoji}")`,
            backgroundPosition: offset,
          });

        } else {
          $elem.css('backgroundImage', '');
        }
      }
    }
  };
}
