import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { ModalComponent, ModalConfig, defaultModalConfig } from './modal.component';
import { getClosestDialog } from './modal-close.directive';
import { logger } from 'utils/util';


@Injectable()
export class ModalService {
  private openDialogTypes = new Set<string>();

  constructor(
    public dialog: Dialog
  ) {}

  /**
   * Open a modal.
   *
   * Internally this uses Angular's CDK Dialog, and the usage of this method is similar to
   * Dialog.open(). The options you provide when creating a modal are mainly for customizing the
   * appearance of the modal.
   *
   * @param config - The configuration that defines the modal.
   * @returns A reference to the dialog that can be used to interact with it and subscribe to
   *  changes.
   */
  public open<Return>(config: Partial<ModalConfig>): DialogRef<Return, ModalComponent> {
    const fullConfig: ModalConfig = { ...defaultModalConfig, ...config };
    let backdropClass = 'modal__backdrop';
    if(fullConfig.blurBackdrop) {
      backdropClass += ' modal__backdrop--blur';
    }

    return this.dialog.open(ModalComponent, {
      width: '100%',
      height: '100%',

      backdropClass: backdropClass,
      autoFocus: '__non_existing_element__',
      disableClose: fullConfig.disableClose,

      data: {
        config: config,
      },
    });
  }

  public openOnceAtATime<Return>(
    uniqueId: string,
    config: Partial<ModalConfig>,
  ): DialogRef<Return, ModalComponent> | null {
    if (this.openDialogTypes.has(uniqueId)) {
      return null;
    }
    const dialog = this.open<Return>(config);
    this.openDialogTypes.add(uniqueId);
    dialog.closed.subscribe(_ => this.openDialogTypes.delete(uniqueId));
    return dialog;
  }

  public closeNearest(element: HTMLElement) {
    const nearestDialog = getClosestDialog(element, this.dialog.openDialogs);
    if(nearestDialog) {
      nearestDialog.close();
    } else {
      logger.warn('could not find a dialog near to ', element);
    }
  }
}
