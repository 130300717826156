import { Inject, Injectable } from '@angular/core';
import { SchedulingConfig } from 'scheduling/models/SchedulingConfig';
import { Memoize } from 'typescript-memoize';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable()
export class SchedulingConfigService {
  constructor(
    @Inject('modelFactory') private modelFactory,
  ) {}

  @Memoize()
  get(): Promise<SchedulingConfig> {
    return this.modelFactory.read({ model: SchedulingConfig, identifiers: { userId: 'me' } })
      .then(({ data: schedulingConfig }) => {
        return schedulingConfig;
      });
  }


  public async updateTLFSchedulingConfig(schedulingConfig) {
    schedulingConfig.readCalendars = [this.getTLFReadCalendar(schedulingConfig).id];
    schedulingConfig.writeCalendar = this.getTLFWriteCalendar(schedulingConfig).id;
    schedulingConfig.timezone = moment.tz.guess();
    return await schedulingConfig.save();
  }

  private getTLFReadCalendar(schedulingConfig) {
    const providerCalendars = schedulingConfig.providerCalendars;
    return providerCalendars[0];
  }

  private getTLFWriteCalendar(schedulingConfig) {
    const providerCalendars = schedulingConfig.providerCalendars;
    return providerCalendars.filter(el => !el.readOnly)[0];
  }
}
