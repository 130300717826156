import { identity } from './functional';


export function has(array, element) {
  return array.indexOf(element) !== -1;
}


export function remove(array, element) {
  let index = array.indexOf(element);
  if(index !== -1) {
    array.splice(index, 1);
  }
}


export function clear(array) {
  array.splice(0, array.length);
}



export function diff(array1, array2) {
  return array2.filter(element => !has(array1, element));
}


export function move(array, fromIndex, toIndex) {
  const item = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, item);
}


export function equals(array1, array2) {
  if(array1.length !== array2.length) {
    return false;
  }

  for(let i = 0; i < array1.length; i++) {
    if(array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}



export function fromArguments(args) {
  return Array.prototype.slice.call(args);
}


export function distinct(array) {
  return [...new Set(array)];
}


export function zip(...arrays) {
  let length = Math.min(...arrays.map(array => array.length));
  let array = new Array(length);
  for(let i = 0; i < length; i++) {
    array[i] = arrays.map(array => array[i]);
  }
  return array;
}


export function sum(array, key = identity) {
  return array.reduce((sum, value) => sum + key(value), 0);
}


export function min(array, key = identity) {
  let minElement = null;
  let minValue = null;
  for(let i = 0; i < array.length; i++) {
    let element = array[i];
    let value = key(element);
    if(minValue == null || value < minValue) {
      minElement = element;
      minValue = value;
    }
  }
  return minElement;
}
export function max(array, key = identity) {
  let maxElement = null;
  let maxValue = null;
  for(let i = 0; i < array.length; i++) {
    let element = array[i];
    let value = key(element);
    if(maxValue == null || value > maxValue) {
      maxElement = element;
      maxValue = value;
    }
  }
  return maxElement;
}


export function range(start, stop, step = 1) {
  if(stop == null) {
    stop = start;
    start = 0;
  }

  let length = Math.max(0, Math.ceil((stop - start) / step));
  return Array(length).fill(start).map((x, y) => x + y * step);
}


/**
 * Flatten an array of arrays into a single array.
 *
 * This is equivalent to Array.flat(), but with better browser support.
 * @param {any[][]} arrayOfArrays
 * @returns {any[]}
 */
export function flat(arrayOfArrays) {
  return arrayOfArrays.reduce((flat, array) => flat.concat(array), []);
}

/**
 * Joins a list of strings in such a way to form a semantically correct enumeration
 *
 * @param {[Object]} array
 * @param {string} mainSeparator - the separator between all items except the last
 * @param {string} lastSeparator -  the separator between the last items
 * @returns {string}
 */
export function joinVerbose(array, mainSeparator, lastSeparator) {
  if(lastSeparator == null) {
    lastSeparator = 'and';
  }
  if(mainSeparator == null) {
    mainSeparator = ',';
  }

  return [
    array.slice(0, -1).join(mainSeparator + ' '),
    array.slice(-1)[0]
  ].join(array.length < 2 ? '' : (' ' + lastSeparator + ' '));
}

/**
 * From a sorted list of numbers rising in value, find the given value and return the next
 * element in the list. If the value is not present, return the next larger element.
 * If the value is larger than the largest value, return the value.
 *
 * @param {[number]} array - A sorted list rising in value
 * @param {number} value
 * @returns {number}
 */
export function getNextIfExists(array, value) {
  let arr_min = array[0];
  let arr_max = array[array.length - 1];

  if( value < arr_min) {
    return arr_min;
  } else if(value > arr_max) {
    return value;
  } else {
    // Value is within the array interval
    let idx = array.indexOf(value);
    if(idx < 0) { // Value not in array
      return array.reduce((prev, cur) => {
        // Prev value is a better match than current value if it is closer to the value than
        // the previous value while still larger than the value
        return (Math.abs(prev - value) < Math.abs(cur - value)) && (prev > value) ? prev : cur;
      });
    } else if(idx < array.length - 1) { // There is a next element
      return array[idx + 1];
    } else {
      return value;
    }
  }

}

/**
 * From a sorted list of numbers rising in value, find the given value and return the previous
 * element in the list. If the value is not present, return the next smaller element.
 * If the value is smaller than the smallest value, return the value.
 *
 * @param {[number]} array - A sorted list rising in value
 * @param {number} value
 * @returns {number}
 */
export function getPrevIfExists(array, value) {
  let arr_min = array[0];
  let arr_max = array[array.length - 1];

  if( value < arr_min) {
    return value;
  } else if(value > arr_max) {
    return arr_max;
  } else {
    // Value is within the array interval
    let idx = array.indexOf(value);
    if(idx < 0) { // Value not in array
      return array.reduce((prev, cur) => {
        // Current value is a better match than previous value if it is closer to the value than
        // the previous value while still smaller than the value
        return (Math.abs(cur - value) < Math.abs(prev - value)) && (cur < value) ? cur : prev;
      });
    } else if(idx >= 1) { // There is a previous element
      return array[idx - 1];
    } else {
      return value;
    }
  }
}

/**
 * Insert a value into a sorted list that keeps the ordering.
 *
 * @template T
 * @param {[T]} array
 * @param {T} value
 * @param {(T, T) => boolean} comparator A function that takes two elements and returns whether the
 *                                       first is smaller than the second
 * @returns {void}
 */
export function insertIntoOrderedArray(array, value, comparator) {
  const insertionIndex = array.findIndex(element => comparator(element, value));
  if (insertionIndex === -1) {
    array.push(value);
  } else {
    array.splice(insertionIndex, 0, value);
  }
}
