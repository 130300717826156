<select
  class="dropdown-select"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  [ngModelOptions]="{standalone: true}"
  [disabled]="disabled"
  [class.dropdown-select--null]="!nullable && value == null"
  data-testid="dropdownSelect"
>
  <option
    *ngIf="nullable"
    [ngValue]="null"
    [attr.data-testid]="textIfNullToTestId()"
  >
    {{ textIfNull }}
  </option>
  <option
    *ngIf="!nullable"
    [ngValue]="null"
    disabled
    class="d-none"
  >
    {{ placeholder }}
  </option>

  <option
    *ngFor="let option of options"
    [ngValue]="getOptionValue(option)"
    [disabled]="getOptionDisabled(option)"
    [attr.data-testid]="optionToTestId(option)"
  >
    {{ getOptionLabel(option) }}
  </option>
</select>
