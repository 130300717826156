<div *ngIf="showNotes" class="notes" [ngClass]="{'notes--maximized': isMaximized}">
  <notes-trial-banner
    *ngIf="shouldShowTrialBanner"
  ></notes-trial-banner>

  <notes-header
    [isMaximized]="isMaximized"
    (toggleSize)="toggleSize()"
  ></notes-header>

  <div class="notes__container">
    <private-notes class="notes__private-notes"></private-notes>
    <smart-summary
      [isMaximized]="isMaximized"
      *ngIf="canUseSmartSummary"
      class="notes__smart-summary"
    ></smart-summary>
  </div>
</div>

<div *ngIf="!showNotes" class="notes">
  <div class="notes__disabled-text">
    <p i18n>You are currently recording. Your notes are hidden so that they are not recorded.</p>
    <div class="btn btn--secondary" (click)="setShowDuringRecording()" i18n>
      Show my notes anyway
    </div>
  </div>
</div>
