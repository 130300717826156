import { bind, browser, EventEmitter, getQueryParameter } from 'utils/util';
import { PHONE_MAX_SIZE } from 'utils/cssFramework/variables.js';


export const DeviceType = Object.freeze({
  DESKTOP: 'desktop',
  PHONE: 'phone',
});


export default class BrowserService {
  static get $inject() {
    return [
      'meetingService',
      'notificationService',
    ];
  }

  constructor(
    meetingService,
    notificationService
  ) {
    bind(this);
    EventEmitter.setup(this, ['deviceType', 'isLandscape']);

    this.meetingService = meetingService;
    this.notificationService = notificationService;

    this.deviceType = DeviceType.DESKTOP;
    this.isLandscape = false;
    window.addEventListener('resize', () => {
      // requestAnimationFrame is necessary on mobile safari: otherwise the old window size is
      // reported
      requestAnimationFrame(this._updateLayout);
    });
    this.meetingService.eventEmitter.on('bednet-view', () => this._updateLayout());
    this._updateLayout();


    this.wrapWebRTCSupport = this._wrapSupport.bind(
      this,
      browser.supportsWebRTC,
      browser.errorMessages.webRTC()
    );
    this.wrapOutputDeviceSupport = this._wrapSupport.bind(
      this,
      browser.supportsOutputDevice,
      browser.errorMessages.outputDevice()
    );
  }


  get DeviceType() {
    return DeviceType;
  }

  get isDesktop() {
    return this.deviceType === DeviceType.DESKTOP;
  }
  get isPhone() {
    return this.deviceType === DeviceType.PHONE;
  }


  /**
   * Update the `device` and `isLandscape` properties based on the window size and possible
   * overrides.
   */
  _updateLayout() {
    let width = window.innerWidth;
    let height = window.innerHeight;

    let deviceType = this._getDeviceType(width, height);
    let isLandscape = width >= height;

    if(deviceType !== this.deviceType) {
      this.deviceType = deviceType;
      $rootScope.$evalAsync(() => this.emit('deviceType', this.deviceType));
    }
    if(isLandscape !== this.isLandscape) {
      this.isLandscape = isLandscape;
      $rootScope.$evalAsync(() => this.emit('isLandscape', this.isLandscape));
    }
  }


  _getDeviceType(width, height) {
    let override = getQueryParameter('layout');
    let bednetLocation = this.meetingService.bednetLocation;

    if(Object.values(DeviceType).includes(override)) {
      return override;
    } else if(bednetLocation === this.meetingService.BednetLocation.CLASSROOM) {
      return this.meetingService.bednetView;
    } else if(width > PHONE_MAX_SIZE && height > PHONE_MAX_SIZE) {
      return DeviceType.DESKTOP;
    } else {
      return DeviceType.PHONE;
    }
  }


  _wrapSupport(test, message, fn) {
    let _this = this;

    return function wrapper() {
      if(test()) {
        return fn(...arguments);

      } else {
        _this.notificationService.warning(message);
      }
    };
  }
}
