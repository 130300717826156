import template from './testVideoInput.html?raw';


class TestVideoInputController {}

export default {
  controller: TestVideoInputController,
  controllerAs: 'testVideoInputCtrl',
  template,

  bindings: {
    stream: '<',
  },
};
