/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { MeetingRoomsMenuModule } from 'dashboard/home/meeting-rooms-menu/meeting-rooms-menu.module';
import { AppointmentsTableComponent } from './appointments-table.component';
import { AppointmentLocationComponent } from './appointment-location.component';
/* eslint-enable max-len */


@NgModule({
  declarations: [
    AppointmentsTableComponent,
    AppointmentLocationComponent
  ],
  exports: [
    AppointmentsTableComponent,
  ],
  imports: [
    BrowserModule,
    UtilsModule,
    MeetingRoomsMenuModule
  ]
})
export class AppointmentsTableModule {}
