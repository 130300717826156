 <div class="container pp">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <div *ngIf="shouldShowWelcome" class=>
    <h1 class="page-title">
      {{ welcomeText }}, 
      <span class="text-primary-300">{{ requestUserService.user.firstName}}!</span>
    </h1>
    <h2>{{subWelcomeText}}:</h2>
    <div class="home__welcome-container">
      <div class="home__welcome-card">
        <svg-icon *ngIf="siteService.site.isTeamleader" svgPath="dashboard__tlSchedulingWelcome"
          class="home__welcome-image"></svg-icon>
        <svg-icon *ngIf="!siteService.site.isTeamleader" svgPath="dashboard__schedulingWelcome"
          class="home__welcome-image"></svg-icon>
        <h1 i18n>
          <span 
            [class.text-warning-400]="!siteService.site.isTeamleader"
            [class.text-primary-300]="siteService.site.isTeamleader">
              Schedule
          </span> meetings with people
        </h1>
        <p class="text-muted" i18n>Create meeting types and send it to your customers to schedule meetings</p>
        <hr>
        <p class="text-muted" i18n>Scheduling works with calendars.</p>
        <div class="text-h2" i18n>Add your calendar</div>
        <div class="mt-8">
          <a (click)="onConnectCalendarClick()" class="btn btn--primary">
            <svg-icon class="btn__icon" [svgName]="'calendar'"></svg-icon>
            <span i18n>
              Connect now
            </span>
          </a>
        </div>
      </div>

      <div *ngIf="siteService.site.isTeamleader" class="home__welcome-card">
        <svg-icon svgPath="dashboard__tlFormsWelcome" class="home__welcome-image"></svg-icon>
        <h1 i18n><span class="text-primary-300">Contact</span> people and turn them into leads</h1>
        <p class="text-muted" i18n>Create state-of-the-art forms to share on your site, via a link,...</p>
        <hr>
        <p class="text-muted" i18n>Get valuable leads into Teamleader Focus.</p>
        <div class="text-h2" i18n>Try an easy way to capture leads</div>
        <div class="mt-8">
          <a (click)="onCreateFormClick()" class="btn btn--outline-primary">
            <svg-icon class="btn__icon" [svgName]="'forms'"></svg-icon>
            <span i18n>
              Create a form
            </span>
          </a>
        </div>
      </div>

      <div *ngIf="!siteService.site.isTeamleader" class="home__welcome-card">
        <svg-icon svgPath="dashboard__meetingWelcome" class="home__welcome-image"></svg-icon>
        <h1 i18n><span class="text-primary-300">Meet</span> with people in your online meeting room</h1>
        <p class="text-muted" i18n>Use your default online meeting room and add more rooms if you want</p>
        <hr>
        <p class="text-muted" i18n>Default room</p>
        <div class="text-h2">{{ defaultRoomName }}</div>
        <div class="mt-8">
          <a (click)="onStartMeetingClick()" class="btn btn--outline-primary">
            <svg-icon class="btn__icon" [svgName]="'videocam'" data-testid="startDefaultMeetingButton"></svg-icon>
            <span i18n>
              Start a meeting
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!shouldShowWelcome">

    <h1 class="page-title d-flex align-items-center">
      <span i18n class="mr-3">Dashboard</span>
      <svg-icon
        class="icon clickable mr-3"
        *ngIf="!shouldShowHint"
        [svgName]="'info_badged'"
        (click)="shouldShowHint = true"
        matTooltip="Click for more info"
        i18n-matTooltip>
      </svg-icon>
    </h1>

    <div class="card pp" *ngIf="shouldShowHint">
      <div>
        <p i18n>
          On the dashboard page you have several options: you can copy the personal and team booking links,
          start a meeting in one of your existing or new meeting rooms, accept and deny knocking guests and check your first 5 upcoming meetings.
        </p>
        <p>
          <span i18n>Tip: Allow Sound, Notifications and Pop-up windows in the browser settings to notice knockers when your dashboard isn't the active page on your screen.</span>
          <a [attr.href]="'waitingRoomBrowserSettings'|helpArticle" target="_blank" i18n>Learn more.</a>
        </p>
      </div>

      <div 
        class="btn btn--secondary"
        (click)="shouldShowHint = false"
        i18n>
        Got it!
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-10">
      <div class="d-flex flex-row align-items-center">
        <scope-switcher
          [scope]="scope"
          [scopes]="scopes"
          (scopeChange)="onScopeChange($event)">
        </scope-switcher>
      </div>
      <div class="d-none d-md-flex align-items-center">
        <waiting-room-notification-button class="mr-2"></waiting-room-notification-button>
        <div
          class="btn btn--secondary mr-2"
          *ngIf="!shouldShowConnect"
          [cdkMenuTriggerFor]="copyBookingLinksDropdown"
          [cdkMenuPosition]="[{ originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top' }]">
          <span i18n>
            Booking links
          </span>
          <svg-icon class="btn__icon btn__icon--right" [svgName]="'chevron_down'"></svg-icon>
        </div>

        <div 
          class="btn btn--primary"
          [cdkMenuTriggerFor]="startMeetingDropdown"
          [cdkMenuPosition]="[{ originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top' }]"
          data-testid="startMeetingButton">
          <svg-icon class="btn__icon" [svgName]="'videocam'"></svg-icon>
          <span i18n>
            Start a meeting
          </span>
        </div>
      </div>
    </div>
    <waiting-room-notification-warning></waiting-room-notification-warning>

    <waiting-room-card></waiting-room-card>

    <div *ngIf="shouldShowConnect">
      <div class="home__appointments-card">
        <svg-icon svgPath="dashboard__noCalendarConnected"></svg-icon>
        <h3 class="mr-2" i18n>You haven’t connected your calendar.</h3>
        <span class="text-muted" i18n>Add your calendar to create meeting types.</span>
        <div class="mt-8">
          <a (click)="connectCalendar()" class="btn btn--outline-primary btn--opaque">
            <svg-icon class="btn__icon" [svgName]="'calendar'"></svg-icon>
            <span i18n>
              Connect your calendar
            </span>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="shouldShowAddMembers">
      <div class="home__appointments-card">
        <svg-icon svgPath="dashboard__noTeamMembers"></svg-icon>
        <h3 class="mr-2" i18n>You haven’t added your team members.</h3>
        <span class="text-muted" i18n>After adding your team members you will be able to plan meetings.</span>
        <div class="mt-8">
          <a target="_blank" [href]="urlService.urls.teamMembers" class="btn btn--outline-primary btn--opaque">
            <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
            <span i18n>
              Add a team member
            </span>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="shouldShowUpcomingAppointments" class="d-flex justify-content-between align-items-center mb-3">
      <h2 i18n>Upcoming meetings</h2>
      <div (click)="goToMeetings()" class="btn btn--outline-primary" i18n>
        See all
      </div>
    </div>
    <div *ngIf="shouldShowUpcomingAppointments">

      <fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>

      <div *ngIf="!fetchError">
        <div *ngIf="stateService.isReady && appointments.length === 0">
          <div class="promo-field">
            <svg-icon svgPath="dashboard__noMeetings"></svg-icon>
            <h3 class="mr-2" i18n>You have no upcoming meetings.</h3>
            <span class="text-muted" i18n>Share your booking link with people to have meetings.</span>
            <div class="mt-8">
              <a class="btn btn--outline-primary btn--opaque"
                [copyText]="scope === DashboardScope.PERSONAL ? urlService.urls.personalBookingPage : urlService.urls.teamBookingPage">
                <svg-icon class="btn__icon" [svgName]="'copy'"></svg-icon>
                <span i18n>
                  Copy booking link
                </span>
              </a>
            </div>
          </div>
        </div>
        <appointments-table
          [appointments]="appointments"
          [scope]="scope"
          (appointmentChange)="refetch()"></appointments-table>
      </div>
    </div>
  </div>
</div>

<div class="d-md-none d-flex action-footer" *ngIf="stateService.isReady && !fetchError">
  <div class="action-footer__button-row">
    <div
      class="btn btn--secondary"
      *ngIf="!shouldShowConnect"
      [cdkMenuTriggerFor]="copyBookingLinksDropdown"
      [cdkMenuPosition]="[{ originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom' }]">
      <span i18n>
        Booking links
      </span>
      <svg-icon class="btn__icon btn__icon--right" [svgName]="'chevron_down'"></svg-icon>
    </div>

    <div
      class="btn btn--primary"
      [cdkMenuTriggerFor]="startMeetingDropdown"
      [cdkMenuPosition]="[{ originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom' }]">
      <svg-icon class="btn__icon" [svgName]="'videocam'"></svg-icon>
      <span i18n>
        Start a meeting
      </span>
    </div>
  </div>
</div>

<ng-template #copyBookingLinksDropdown>
  <booking-links-menu [scope]="this.scope"></booking-links-menu>
</ng-template>


<ng-template #startMeetingDropdown>
  <meeting-rooms-menu 
    #meetingRoomsMenu
    [data]="{}"
    [scope]="this.scope">
  </meeting-rooms-menu>
</ng-template>
