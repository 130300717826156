import { Component, Input } from '@angular/core';
import { DurationPipe } from 'utils/pipes/duration.pipe';
import { Badge } from 'utils/ui-components/badge';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { ContactWidget } from '../models/ContactWidget';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { getAppoinmentTypeLocationBadges } from 'scheduling/utils/location-badges';
import {
  ContactType, LinkedItem, MeetingRoomContactType, getLinkedItem, getMeetingRoomContactType
} from 'contactWidget/util/contact-type';


@Component({
  selector: 'linked-item-preview',
  templateUrl: './linked-item-preview.component.html',
  styleUrls: ['./linked-item-preview.component.scss'],
})
export class LinkedItemPreviewComponent {
  @Input() contactWidget?: ContactWidget;
  @Input('linkedItem') _linkedItem?: LinkedItem;
  @Input() hideBadges = false;

  constructor(
    private durationPipe: DurationPipe,
  ) {}


  get linkedItem(): LinkedItem | undefined {
    if(this._linkedItem) {
      return this._linkedItem;
    } else {
      return getLinkedItem(this.contactWidget);
    }
  }

  get icon(): SvgIcon | undefined {
    switch(this.linkedItem?.type) {
      case ContactType.ORGANIZATION:
        return { name: 'team' };
      case ContactType.USER:
        return { name: 'user' };
      case ContactType.APPOINTMENT_TYPE:
        return { name: 'calendar' };
      case ContactType.CONTACT_FORM:
        return { name: 'document' };
      case ContactType.MEETING_ROOM:
        const meetingType = getMeetingRoomContactType(this.linkedItem);
        switch(meetingType) {
          case MeetingRoomContactType.PWR:
            return { name: 'user' };
          case MeetingRoomContactType.TWR:
            return { name: 'team' };
          default:
            return { name: 'videocam' };
        }
      default:
        return undefined;
    }
  }


  get label(): string | undefined {
    switch(this.linkedItem?.type) {
      case ContactType.ORGANIZATION:
        return $localize `Team booking page`;
      case ContactType.USER:
        const userName = this.linkedItem?.value.fullName;
        return $localize `${userName}'s booking page`;
      case ContactType.APPOINTMENT_TYPE:
        return this.linkedItem?.value.name;
      case ContactType.CONTACT_FORM:
        return this.linkedItem?.value.name;
      case ContactType.MEETING_ROOM:
        const meetingType = getMeetingRoomContactType(this.linkedItem);
        switch(meetingType) {
          case MeetingRoomContactType.PWR:
            return $localize `Waiting room of ${this.linkedItem?.value.owner.fullName}`;
          case MeetingRoomContactType.TWR:
            return $localize `Team waiting room`;
          default:
            return this.linkedItem?.value.key;
        }
      default:
        return undefined;
    }
  }


  get badges(): Badge[] {
    if(this.hideBadges || this.linkedItem?.type !== ContactType.APPOINTMENT_TYPE) {
      return [];
    }

    const appointmentType = this.linkedItem?.value as AppointmentType;
    const badges: Badge[] = [
      {
        label: this.durationPipe.transform(appointmentType.duration, true),
        icon: { name: 'clock', size: 14 },
      },
      ...getAppoinmentTypeLocationBadges(appointmentType),
    ];

    return badges;
  }
}
