<div class="p-4 d-flex flex-column">
  <div class="mb-6">
    <div class="text-h4" i18n>transcription</div>
  </div>

  <div class="d-flex flex-column mt-3">
    <div
      class="mb-4"
      [ngClass]="{
        'collapsed': isCollapsed,
        'collapsed__mask': isCollapsed && canCollapse
      }"
      #transcriptionContainer
    >
      <div *ngFor="let utterance of transcription.utterances" class="transcription__utterance">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <div class="text-bold">{{ utterance.speaker }}</div>
            </div>
            <div>{{ millisToTimePassed(utterance.start) }}</div>
          </div>
          <div>
            {{ utterance.text }}
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="canCollapse" class="btn btn--sm m-auto" (click)="toggleCollapsed()">
      <div *ngIf="isCollapsed">
        <span i18n>Show more</span>
      </div>
      <div *ngIf="!isCollapsed">
        <span i18n>Show less</span>
      </div>
      <svg-icon class="btn__icon btn__icon--right ml-2" [svgName]="isCollapsed ? 'chevron_down' : 'chevron_up'">
      </svg-icon>
    </button>
  </div>
</div>
