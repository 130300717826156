import { browser, EventEmitter, interval } from 'utils/util';
import templateModalInstall from './chromeExtensionInstall.modal.html?raw';
import templateModalSuccess from './chromeExtensionSuccess.modal.html?raw';


const CHECK_INSTALLED_INTERVAL = 1000;

const INSTALL_ACTIONS = {
  /// used in "The Chrome extension is required to {action}."
  screen: gettext('share your screen'),
  /// used in "The Chrome extension is required to {action}."
  cobrowse: gettext('start cobrowsing'),
  /// used in "The Chrome extension is required to {action}."
  record: gettext('start recording'),
};

const MODAL_ID_INSTALL = 'chromeExtensionInstall';
const MODAL_ID_SUCCESS = 'chromeExtensionSuccess';



let maxMessageId = 0;
function getUniqueMessageId() {
  return ++maxMessageId;
}


export default class ChromeExtensionService {
  static get $inject() {
    return [
      'modalService',
      'languageService'
    ];
  }

  constructor(
    modalService,
    languageService
  ) {
    this._bind();
    EventEmitter.setup(this, ['isInstalled'], true);

    this.modalService = modalService;
    this.languageService = languageService;

    this.installInfo = null;
    this.extensionId = (
      angular.element('[data-vectera-extension-requested]')
        .attr('data-vectera-extension-id')
    );

    this.sentMessages = {};

    if(browser.isBlink()) {
      modalService.register(
        MODAL_ID_INSTALL,
        gettext('Chrome extension required'),
        'utils/icons/tl/24x24_extension_outline.svg',
        templateModalInstall
      );

      modalService.register(
        MODAL_ID_SUCCESS,
        gettext('Chrome extension installed'),
        'utils/icons/tl/24x24_extension_outline.svg',
        templateModalSuccess
      );

      window.addEventListener('message', this._onMessage);
    }
  }

  _bind() {
    this._onMessage = this._onMessage.bind(this);
    this._installCheckInstalled = this._installCheckInstalled.bind(this);
    this._onModalShow = this._onModalShow.bind(this);
  }


  get extensionUrl() {
    return 'https://chrome.google.com/webstore/detail/' + this.extensionId;
  }


  sendMessage(message) {
    let id = getUniqueMessageId();
    window.postMessage({
      type: 'vecteraToExtension',
      extensionId: this.extensionId,
      id: id,
      message: message,
    });

    let defer = $q.defer();
    this.sentMessages[id] = {
      defer: defer,
    };
    return defer.promise;
  }


  _onMessage(event) {
    let extensionMessage = event.data;

    if(extensionMessage.type === 'extensionToVectera') {
      let message = extensionMessage.message;
      $rootScope.$applyAsync(() => {
        if(message.type === 'isInstalled') {
          this.emit('isInstalled', message.isInstalled);
        } else {
          this.emit(message.type, message);
        }
      });
    }

    if(extensionMessage.type === 'vecteraToExtensionResponse') {
      let id = extensionMessage.id;
      let sentMessage = this.sentMessages[id];
      delete this.sentMessages[id];

      if(sentMessage) {
        let response = extensionMessage.response;
        let defer = sentMessage.defer;
        if(response && response.error) {
          defer.reject(response.error);
        } else {
          defer.resolve(response);
        }
      }
    }
  }



  get isSupported() {
    return browser.isBlink() && browser.isDesktop();
  }
  get isInstalled() {
    return !!document.querySelector('[data-vectera-extension-installed]');
  }



  /****************************
   * Install Chrome extension *
   ****************************/

  install(reason, requireUserGesture) {
    this._installStartListening(reason, requireUserGesture);
    return this.installInfo.defer.promise;
  }


  _installStartListening(reason, requireUserGesture) {
    if(this.installInfo) {
      return;
    }

    this.installInfo = {
      defer: $q.defer(),
      checkInstalledInterval: interval.setInterval(
        this._installCheckInstalled,
        CHECK_INSTALLED_INTERVAL
      ),
    };

    if(reason) {
      Object.assign(this.installInfo, {
        reason: reason,
        action: gettextCatalog.getString(INSTALL_ACTIONS[reason]),
        requireUserGesture: requireUserGesture,
        hasUserGesture: false,
      });

      this.modalService.on('show', this._onModalShow);
      this.modalService.show(MODAL_ID_INSTALL);
    }
  }



  _onModalShow() {
    if(
      this.installInfo
      && !this.modalService.get(MODAL_ID_INSTALL).isShown
      && !this.modalService.get(MODAL_ID_SUCCESS).isShown
    ) {
      this._installStopListening('ChromeInstallPopupDismissed');
    }
  }


  _installStopListening(error) {
    if(!this.installInfo) {
      return;
    }

    interval.clearInterval(this.installInfo.checkInstalledInterval);

    this.modalService.off('show', this._onModalShow);
    this.modalService.hide(MODAL_ID_INSTALL);
    this.modalService.hide(MODAL_ID_SUCCESS);

    if(error) {
      this.installInfo.defer.reject(error);
      this.installInfo = null;

    } else if(this.installInfo.requireUserGesture && !this.installInfo.hasUserGesture) {
      this.modalService.on('show', this._onModalShow);
      this.modalService.show(MODAL_ID_SUCCESS);

    } else {
      this.installInfo.defer.resolve();
      this.installInfo = null;
    }
  }


  onUserGesture() {
    if(!this.installInfo) {
      return;
    }

    this.installInfo.hasUserGesture = true;
    this._installStopListening();
  }


  _installCheckInstalled() {
    if(this.isInstalled) {
      this._installStopListening();
    }
  }
}
