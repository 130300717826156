import { EventEmitter, Rect } from 'utils/util';
import { TILE_BORDER_WIDTH } from '../../variables';


const Type = Object.freeze({
  USER: 'user',
  KNOCKER: 'knocker',
  SCREEN: 'screen',
  COBROWSE: 'cobrowse',
  WHITEBOARD: 'whiteboard',
});

const Category = Object.freeze({
  PEOPLE: 'people',
  CONTENT: 'content',
});

let maxUniqueId = 0;


export default class Tile {
  static get $inject() {
    return [
      'userService',
      'browserService',
    ];
  }

  static get Type() {
    return Type;
  }
  get Type() {
    return Type;
  }

  static get Category() {
    return Category;
  }


  constructor(id, userService, browserService) {
    this._bind();
    EventEmitter.setup(this, ['active', 'change', 'draw']);

    this.userService = userService;
    this.browserService = browserService;

    this.id = id;
    this.active = false;
    // We need a separate property that is used in the html because tileService._draw() is
    // throttled: this means there is at least 1 render in which this.active has changed but
    // this.rect has not.
    this.drawActive = false;
    this.lastActiveDatetime = null;

    // We need a unique id to track by in case a tile is removed and later added again. Eg.:
    // * A joins
    // * B joins
    // * B leaves, but does not reload the page
    // * A reloads the page and joins again
    // * B joins again
    // Result in B's browser without unique id: the user-tile element for A is not removed and
    // re-added, the $onInit() method is not called and Tile.setElem() is never called.
    this._uniqueId = ++maxUniqueId;

    this.$elem = null;
    this.$elemActive = null;
    this.$elemInactive = null;

    this.rect = new Rect({ left: 0, top: 0, width: 0, height: 0 });
    this.rectBody = this.rect.clone();
    this.scrollInactive = 0;
  }

  _bind() {
    this.draw = this.draw.bind(this);
  }

  destroy() {
    this.active = false;
  }

  get hasBorder() {
    return true;
  }

  get headerHeight() {
    return 0;
  }
  get sidebarWidth() {
    return 0;
  }
  get borderWidth() {
    return this.hasBorder ? TILE_BORDER_WIDTH : 0;
  }

  get isSpectator() {
    return this.userService.mySession.isSpectator || !this.browserService.isDesktop;
  }


  setActive(active, datetime) {
    if(active !== this.active) {
      this.active = active;
      this.lastActiveDatetime = datetime;
      this.emit('active', this);
    }
  }

  toggleActive() {
    this.setActive(!this.active);
  }


  draw(rect) {
    if(rect != null) {
      this.rect = rect.clone();
      this.rectBody = new Rect({
        left: this.rect.left + this.sidebarWidth + this.borderWidth,
        top: this.rect.top + this.headerHeight + this.borderWidth,
        right: this.rect.right - this.borderWidth,
        bottom: this.rect.bottom - this.borderWidth,
      });
    }

    if(this.$elemActive && this.rect) {
      this.$elemActive.css({
        left: this.rect.left,
        top: this.rect.top - (this.active ? 0 : this.scrollInactive),
        width: this.rect.width,
        height: this.rect.height,
      });
      this.drawActive = this.active;
    }

    this.emit('draw', this);
  }


  setScrollInactive(scroll) {
    this.scrollInactive = scroll;
    this.draw();
  }


  setElem($elem) {
    if(this.$elem) {
      this.removeElem(this.$elem);
    }
    this._setElem($elem);

    this.draw();
  }

  removeElem($elem) {
    if($elem && $elem === this.$elem) {
      this._removeElem();
    }
  }

  _setElem($elem) {
    this.$elem = $elem;
    this.$elemActive = this.$elem.find('.tile:not(.tile--inactive)');
    if(this.$elemActive.length === 0) {
      // Happens with knocker tiles
      this.$elemActive = this.$elem;
    }
    this.$elemInactive = this.$elem.find('.content-tile__inactive-wrapper');
  }

  _removeElem() {
    this.$elem = null;
  }
}
