<div *ngIf="wizard.currentIndex === 0">
  <calendar-connect></calendar-connect>
</div>

<div *ngIf="wizard.currentIndex === 1">
  <calendar-config></calendar-config>
</div>

<div *ngIf="wizard.currentIndex === 2">
  <appointment-type-init></appointment-type-init>
</div>


<div *ngIf="wizard.currentIndex === 3" class="d-flex flex-column align-items-center">
  <img [src]="doneImage" class="scheduling-onboarding-wizard__done-image">

  <h1 i18n>Congratulations!</h1>
  <p class="pp" i18n>You have successfully completed the quick setup wizard.</p>

  <div class="p">
    <a
      class="btn btn--lg btn--primary"
      (click)="openBookingPage()"
    >
      <span i18n>Check your booking page</span>
      <svg-icon class="btn__icon btn__icon--right" [svgName]="'external_link'"></svg-icon>
    </a>
  </div>
</div>
