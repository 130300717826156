import { inject } from '@angular/core';
import UserService from 'meeting/angularjs/main/users/user.service';
import { ActiveIntervalService } from 'meeting/meeting-room/activeInterval.service';
import { MeetingService, MeetingState } from 'meeting/meeting-room/meeting.service';
import { errors, logger } from 'utils/util';
import { RequestUserService } from 'utils/requestUser.service';
import { NotificationService } from 'utils/angularjs-upgraded-providers';


export type IntegrationStatus = {
  activecampaign: IntegrationState,
  adminPulse: IntegrationState,
  hubspot: IntegrationState,
  salesflare: IntegrationState,
  stripe: IntegrationState,
  teamleader: IntegrationState
}

export enum IntegrationState {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  EXPIRED = 'expired',
  UNAUTHORIZED = 'unauthorized',
}


export abstract class IntegrationService {
  public isExpired = false;
  public requestUserService = inject(RequestUserService);
  public userService = inject(UserService);
  public activeIntervalService = inject(ActiveIntervalService);
  public meetingService = inject(MeetingService);
  public notificationService = inject(NotificationService);

  // Returns whether the integration is not just configured, but also being used
  public abstract get isActive(): boolean;
  // Pushes given meeting records to the 3rd party service. Should at least be able to push
  // notes. Notifications and error handling are happening in `push`.
  public abstract rawPush(data: { notes?: string }): Promise<void>;

  constructor(
    public integrationKey: keyof IntegrationStatus,
    public displayName: string,
    private meetingRecordEntity: string
  ) {
    const state = 
      this.requestUserService.user.organization?.integrationStatus[this.integrationKey];
    this.setExpired(state === IntegrationState.EXPIRED);

    this.meetingService.eventEmitter.on(MeetingState.ENDED, this.onMeetingEnded.bind(this));
  }    
  
  get isConnected() {
    // eslint-disable-next-line max-len
    const state = this.requestUserService.user.organization?.integrationStatus[this.integrationKey];
    return state === IntegrationState.CONNECTED;
  }

  setExpired(value)  {
    this.isExpired = value;
  }

  // Pushes given meeting records to the 3rd party service. Should at least be able to push notes.
  public async push(data: { notes?: string }): Promise<void> {
    if(!this.isActive) {
      return;
    }

    try {
      await this.rawPush(data);
    } catch(error: any) {
      logger.error(error);
      this.notificationService.error(
        // eslint-disable-next-line max-len
        $localize `Something went wrong trying to save the ${this.meetingRecordEntity}. Please try again later.`
      );
      if(error.constructor === errors.UnauthorizedError) {
        this.setExpired(true);
      }
      // We throw again to be able to set the sync state further up the stack.
      throw error;
    }

    this.notificationService.success(
      $localize `Successfully saved the ${this.meetingRecordEntity}.`
    );
  }

  get shouldPushMeeting() {
    return (
      this.isActive
      && this.activeIntervalService.timeActive > 60 * 1000
      && this.userService.iAmHost
    );
  }

  onMeetingEnded() {
    if(this.shouldPushMeeting) {
      try {
        this.push({});
      } catch(error) {
        // We have already shown a notification at this point and we don't need to update the sync
        // state.
      }
    }
  }
}
