ValueWhileSubmittingDirective.$inject = [
  '$compile',
];

export default function ValueWhileSubmittingDirective(
  $compile
) {
  return {
    restrict: 'A',

    link: function($scope, $elem, $attrs) {
      let submitted = false;
      $elem.parents('form').on('submit', $event => {
        if(submitted || $event.isDefaultPrevented()) {
          $event.preventDefault();
          return;
        }
        submitted = true;

        let cssClasses = $elem.attr('class');
        let valueWhileSubmitting = $scope.$eval($attrs.valueWhileSubmitting);
        let htmlWhileSubmitting = `
          <div class="disabled ${cssClasses}">
            <span>${valueWhileSubmitting}</span>
            <loading class="ml-2 mr--2"></loading>
          </div>
        `;

        let $elemWhileSubmitting = $compile(htmlWhileSubmitting)($scope);
        $elem.after($elemWhileSubmitting);
        $elem.hide();
      });
    },
  };
}
