import { Injectable, Inject } from '@angular/core';

import { AdminPulseService } from 'integrations/adminpulse/adminpulse.service';
import { HubSpotService } from 'integrations/hubspot/hubspot.service';
import { NotesIntegrationService } from 'integrations/notes-integration.service';
import { PrivateNotesService } from '../private-notes.service';


const RECENT_NOTES_CUTOFF = 15 * 60 * 1000;  // 15 min


export enum State {
  INITIAL,
  DIRTY,
  SYNCING,
  SYNCED,
}


@Injectable({
  providedIn: 'root',
})
export class IntegrationSyncService {
  public state: State = State.INITIAL;
  private integrations: NotesIntegrationService[];

  constructor(
    @Inject('meetingBroadcastService') private meetingBroadcastService,
    private privateNotesService: PrivateNotesService,
    adminPulseService: AdminPulseService,
    hubSpotService: HubSpotService,
  ) {
    this.integrations = [
      hubSpotService,
      adminPulseService,
    ];

    privateNotesService.eventEmitter.on('edited', (_content: string, timestamp: Date) => {
      if(timestamp == null || Date.now() - timestamp.getTime() < RECENT_NOTES_CUTOFF) {
        this.state = State.DIRTY;
      }
    });
    meetingBroadcastService.on('private-notes-syncing', () => this.state = State.SYNCING, true);
    meetingBroadcastService.on('private-notes-synced', () => this.state = State.SYNCED, true);
    meetingBroadcastService.on('private-notes-sync-failed', () => this.state = State.DIRTY, true);
  }

  public isActive(): boolean {
    return this.integrations.some(integration => integration.isActive);
  }

  public activeIntegrationNames(): string[] {
    return this.integrations
      .filter(integration => integration.isActive)
      .map(integration => integration.displayName);
  }

  public async saveNotes(): Promise<void> {
    await this.withSyncStateManagement(async () => {
      await Promise.all(
        this.integrations
          .filter(integration => integration.isActive)
          .map(integration =>
            integration.push({
              notes: this.privateNotesService.content,
            })
          )
      );
    });
  }

  public canSaveNotes() {
    return (
      this.state === State.DIRTY
      && this.isActive()
      && this.privateNotesService.content
    );
  }

  private async withSyncStateManagement(syncFn: () => Promise<void>): Promise<void> {
    if(this.state !== State.DIRTY) {
      return;
    }

    this.meetingBroadcastService.send('private-notes-syncing', true, []);
    try {
      await syncFn();
    } catch(error) {
      // We already show notifications when one of these integrations fails, but we still need to
      // know the fact that an error occurred so we can set the sync state back to dirty.
      this.meetingBroadcastService.send('private-notes-sync-failed', true, []);
      return;
    }
    this.meetingBroadcastService.send('private-notes-synced', true, []);
  }
}
