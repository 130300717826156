<div
  class="field field--info p"
  *ngIf="hasFreeSubscription"
>
  <field-icon [type]="'info'"></field-icon>
  <div>
    <p>{{ upgradeText }}</p>
    <a
      class="btn btn--primary"
      [href]="siteService.site.subscriptionLink"
      target="_blank"
      i18n
      data-testid="emailTemplateUpgradeNowButton"
    >
      Upgrade now
    </a>
  </div>
</div>

<div>
  <div
    *ngFor="let template of templates"
    class="appointment-type-templates__item"
    data-testid="emailTemplateConfig"
  >
    <div
      class="appointment-type-templates__header"
      [class.disabled]="hasFreeSubscription"
      (click)="!hasFreeSubscription && toggleSelected(template)"
    >
      <div class="text-h3 mr-3">{{ template.label }}</div>

      <settings-icon
        *ngIf="hasErrors(template)"
        [settingsIconType]="'invalid'"
        class="mr-3"
      ></settings-icon>

      <div class="ml-auto">
        <svg-icon
          class="icon"
          [svgName]="'chevron_up'"
          *ngIf="isSelected(template)"
        ></svg-icon>
        <svg-icon
          class="icon"
          [svgName]="'chevron_down'"
          *ngIf="!isSelected(template)"
        ></svg-icon>
      </div>
    </div>


    <form
      class="appointment-type-templates__content"
      *ngIf="isSelected(template)"
      (ngSubmit)="toggleSelected(template)"
      cdkTrapFocus cdkTrapFocusAutoCapture
      data-testid="emailTemplateConfigs"
    >
      <div
        class="field field--info p"
        *ngIf="getHint(template).shouldShow && hintTexts[template.fieldName]"
      >
        <field-icon [type]="'info'"></field-icon>
        <div>
          <div class="mb-2">{{ hintTexts[template.fieldName] }}</div>
          <div>
            <div
              class="btn btn--sm btn--info"
              (click)="getHint(template).setSeen()"
              i18n
            >
              Got it
            </div>
          </div>
        </div>
      </div>

      <settings-non-field-errors
        [nonFieldErrors]="getErrors(template).nonFieldErrors"
      ></settings-non-field-errors>

      <div class="p">
        <label>{{ template.subjectLabel }}</label>
        <input
          class="input"
          name="subject"
          [(ngModel)]="instance[template.fieldName].subject"
          [withSettingsErrors]="getErrors(template).subject"
          [disabled]="!hasPermissionToEdit"
          cdkFocusInitial
        />
      </div>

      <div class="p">
        <label>{{ template.textLabel }}</label>
        <textarea
          class="textarea"
          rows="10"
          name="text"
          [(ngModel)]="instance[template.fieldName].text"
          [withSettingsErrors]="getErrors(template).text"
          [disabled]="!hasPermissionToEdit"
        ></textarea>
      </div>

      <div class="d-flex align-items-center mr--2">
        <div
          class="btn btn--secondary mr-2"
          [class.active]="extraContent === 'preview'"
          (click)="toggleExtraContent('preview')"
          i18n
        >
          Show preview
        </div>
        <div
          class="btn btn--secondary btn--round btn--sm mr-2"
          [class.active]="extraContent === 'help'"
          (click)="toggleExtraContent('help')"
          i18n
        >
          ?
        </div>
        <div
          class="btn btn--secondary ml-auto mr-2"
          *ngIf="hasPermissionToEdit"
          (click)="reset(template)"
          i18n
        >
          Reset to default
        </div>
      </div>

      <div *ngIf="extraContent === 'preview'" class="mt-4">
        <p *ngIf="supportsMarkdown(template)">
          <span i18n>Below you can see what your email will look like when it is sent. Did you know you can use Markdown to style your emails?</span>
          <a
            [href]="'markdown' | helpArticle"
            target="_blank"
            i18n
          >
            Learn more.
          </a>
        </p>
        <p *ngIf="!supportsMarkdown(template)" i18n>
          Calendar events are shown in plain text in your calendar app. They do not support any styling.
        </p>

        <div class="appointment-type-templates__preview">
          <h2>{{ instance[template.fieldName].subject }}</h2>
          <markdown *ngIf="supportsMarkdown(template)" [data]="getSanitizedText(template)"></markdown>
          <div *ngIf="!supportsMarkdown(template)">{{ instance[template.fieldName].text }}</div>
        </div>
      </div>

      <ng-container *ngIf="extraContent === 'help'">
        <h2 i18n>Email tags and descriptions</h2>
        <p i18n>You can include dynamic information in your template by including the tags that are listed below. Check the default template text to get an idea about how to use these.</p>

        <div class="table__wrapper">
          <table class="table">
            <thead>
              <tr>
                <th i18n>Tag</th>
                <th class="d-none d-sm-table-cell">{{ template.subjectLabel }}</th>
                <th class="d-none d-sm-table-cell">{{ template.textLabel }}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let tag of template.tags" class="align-middle">
                <td>
                  <div class="appointment-type-templates__tag" [copyText]="'{' + tag.name + '}'">
                    <svg-icon
                      class="icon icon--sm mr-1"
                      [svgName]="'copy'"
                      [svgSize]="14"
                      [matTooltip]="'Click to copy'"
                    ></svg-icon>
                    <span>{{ '{' + tag.name + '}' }}</span>
                  </div>
                  <div>{{ tag.label }}</div>
                </td>

                <td *ngIf="tag.allowedSubject" class="d-none d-sm-table-cell text-center vertical-middle bg-success-100">
                  <svg-icon class="icon d-inline-block text-success-400" [svgName]="'checkmark_badged'" [svgStyle]="'filled'"></svg-icon>
                </td>
                <td *ngIf="!tag.allowedSubject" class="d-none d-sm-table-cell text-center vertical-middle bg-error-100">
                  <svg-icon class="icon d-inline-block text-error-400" [svgName]="'close_badged'" [svgStyle]="'filled'"></svg-icon>
                </td>

                <td *ngIf="tag.allowedText" class="d-none d-sm-table-cell text-center vertical-middle bg-success-100">
                  <svg-icon class="icon d-inline-block text-success-400" [svgName]="'checkmark_badged'" [svgStyle]="'filled'"></svg-icon>
                </td>
                <td *ngIf="!tag.allowedText" class="d-none d-sm-table-cell text-center vertical-middle bg-error-100">
                  <svg-icon class="icon d-inline-block text-error-400" [svgName]="'close_badged'" [svgStyle]="'filled'"></svg-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </form>
  </div>
</div>


<div
  class="mt-8"
  *ngIf="hasPermissionToEdit && !hasFreeSubscription && (otherAppointmentTypesState === 'loading' || otherAppointmentTypeOptions.length > 0)"
>
  <label i18n>Copy email templates from another meeting type</label>

  <div class="d-flex">
    <div
      *ngIf="otherAppointmentTypesState === 'loading'"
      i18n
      class="flex-1 mr-2 select text-muted"
    >
      Loading...
    </div>
    <dropdown-select
      *ngIf="otherAppointmentTypesState === 'ready'"
      [options]="otherAppointmentTypeOptions"
      [(ngModel)]="selectedOtherAppointmentType"
      class="flex-1 mr-2"
    ></dropdown-select>

    <div
      class="btn btn--outline-primary"
      [class.disabled]="selectedOtherAppointmentType == null"
      (click)="selectedOtherAppointmentType != null && copyFrom(selectedOtherAppointmentType)"
      i18n
      data-testid="copyEmailTemplateConfirmButton"
    >
      Confirm
    </div>
  </div>


  <div *ngIf="otherAppointmentTypesError" class="mt-4" i18n>
    Something went wrong while loading your other meeting types.
    Please reload the page to try again.
  </div>
</div>
