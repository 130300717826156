import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Directive, OnDestroy, Optional } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { bind } from 'utils/util';


/**
 * Can be used on elements with cdkConnectedOverlay so that the width of the overlay is kept in
 * sync with the width of the trigger element.
 *
 * Example usage:
 *
 * ```
 * <input class="select" cdkOverlayOrigin>
 * <ng-template cdkConnectedOverlay cdkConnectedOverlayInheritWidth>content<ng-template>
 * ```
 */
@Directive({
  selector: '[cdkConnectedOverlayInheritWidth]',
})
export class CdkConnectedOverlayInheritWidthDirective implements OnDestroy {
  private resizeObserver?: ResizeObserver;
  private readonly destroyed = new Subject<void>();

  constructor(
    @Optional() private cdkConnectedOverlay: CdkConnectedOverlay,
  ) {
    bind(this);
    if(!this.cdkConnectedOverlay) {
      throw new Error(
        'cdkConnectedOverlayInheritWidth can only be used on elements '
        + 'that have a cdkConnectedOverlay'
      );
    }

    this.cdkConnectedOverlay.growAfterOpen = true;

    this.cdkConnectedOverlay.attach
      .pipe(takeUntil(this.destroyed))
      .subscribe(this.onOverlayOpened);
    this.cdkConnectedOverlay.detach
      .pipe(takeUntil(this.destroyed))
      .subscribe(this.onOverlayClosed);
  }


  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.removeResizeListener();
  }


  onOverlayOpened() {
    this.addResizeListener();
    this.onResize();
  }

  onOverlayClosed() {
    this.removeResizeListener();
  }

  addResizeListener() {
    if(window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(
        (this.cdkConnectedOverlay.origin as CdkOverlayOrigin).elementRef.nativeElement
      );
    } else {
      // This is not a failsafe way to detect size changes, but it's good enough for the few
      // percent of browsers that don't support ResizeObserver.
      window.addEventListener('resize', this.onResize);
    }
  }

  removeResizeListener() {
    if(this.resizeObserver) {
      this.resizeObserver.unobserve(
        (this.cdkConnectedOverlay.origin as CdkOverlayOrigin).elementRef.nativeElement
      );
    } else {
      window.removeEventListener('resize', this.onResize);
    }
  }


  private onResize() {
    const width =
      (this.cdkConnectedOverlay.origin as CdkOverlayOrigin).elementRef.nativeElement.offsetWidth;
    this.cdkConnectedOverlay.overlayRef.updateSize({ width: width });
  }
}
