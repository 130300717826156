import './documents';
import './files';
import './paper';
import './tools';


import WhiteboardService from './whiteboard.service';
import WhiteboardFactory from './whiteboardFactory.service';
import WhiteboardAutoNameService from './whiteboardAutoName.service';
import SnapshotService from './snapshot.service';
import WhiteboardMenuController from './whiteboardMenu.controller';
import WhiteboardTileComponent from './whiteboardTile.component';
import WhiteboardTileBodyDirective from './whiteboardTileBody.directive';
import WhiteboardTileScrollbarDirective from './whiteboardTileScrollbar.directive';


angular
  .module('meeting')
  .service('whiteboardFactory', WhiteboardFactory)
  .service('whiteboardService', WhiteboardService)
  .service('whiteboardAutoNameService', WhiteboardAutoNameService)
  .service('snapshotService', SnapshotService)
  .component('whiteboardTile', WhiteboardTileComponent)
  .controller('WhiteboardMenuCtrl', WhiteboardMenuController)
  .directive('whiteboardTileBody', WhiteboardTileBodyDirective)
  .directive('whiteboardTileScrollbar', WhiteboardTileScrollbarDirective);
