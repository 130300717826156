const PLAY_SOUND_THROTTLE = 30000;


export default class NotifyService {
  static get $inject() {
    return [
      'focusService',
      'soundFactory',
    ];
  }

  constructor(
    focusService,
    soundFactory
  ) {
    this._bind();

    this.focusService = focusService;
    this.soundFactory = soundFactory;

    this.focusService.on('hasFocus', this._onFocus);

    this.notificationSound = null;
    this.hasUpdates = false;
    this.documentTitle = document.title;

    this._playSoundThrottled = throttle(this._playSound, PLAY_SOUND_THROTTLE, false);
  }

  _bind() {
    this._onFocus = this._onFocus.bind(this);
    this._playSound = this._playSound.bind(this);
  }


  // The throttle parameter is used to make sure we are able to NOT throttle knockers on the
  // dashboard - waiting room.
  notify(throttle, allowWithFocus) {
    if(throttle == null) {
      throttle = true;
    }

    if(allowWithFocus == null) {
      allowWithFocus = false;
    }

    this.hasUpdates = true;
    this._updateDocumentTitle(allowWithFocus);
    if(throttle) {
      this._playSoundThrottled(allowWithFocus);
    } else {
      this._playSound(allowWithFocus);
    }
  }


  _onFocus(hasFocus) {
    if(hasFocus) {
      this.hasUpdates = false;
      this._updateDocumentTitle();
    }
  }


  _updateDocumentTitle(allowWithFocus) {
    let showPrefix = this.hasUpdates && (!this.focusService.hasFocus || allowWithFocus);
    let prefix = showPrefix ? '* ' : '';
    document.title = prefix + this.documentTitle;
  }


  _playSound(allowWithFocus) {
    if(this.focusService.hasFocus && !allowWithFocus) {
      return;
    }

    if(this.notificationSound) {
      this.notificationSound.play();
    }
  }

  // This allows us to change the sound for this notify service. For the waiting room we use
  // the knocker sound instead of the "pop" sound.
  setNotificationSound(notificationSound) {
    this.notificationSound = this.soundFactory.create(
      notificationSound,
      {
        prefetch: true
      }
    );
  }
}
