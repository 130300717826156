import { bind } from 'utils/util';
import templateModal from './onboarding.modal.html?raw';
import { WIZARD_ID_ONBOARDING } from './onboarding.controller';

export default class OnboardingService {
  static get $inject() {
    return [
      'requestUserService',
      'modalService',
    ];
  }

  constructor(
    requestUserService,
    modalService
  ) {
    bind(this);

    this.requestUserService = requestUserService;
    this.modalService = modalService;

    this.modalService.register(
      WIZARD_ID_ONBOARDING,
      '',
      '',
      templateModal,
      {
        blurOverlay: true,
        rawTemplate: true,
      }
    );

    this._checkNeedsOnboarding();
  }


  _checkNeedsOnboarding() {
    if(
      this.requestUserService.user.organization.needsOnboarding
      && this.requestUserService.user.isAdmin
    ) {
      this.startWizard();
    }
  }

  startWizard() {
    this.modalService.show(WIZARD_ID_ONBOARDING);
  }

  finishWizard() {
    this.modalService.hide(WIZARD_ID_ONBOARDING);
    location.reload();
  }
}
