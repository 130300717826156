import { Component, Input } from '@angular/core';
import { AppLogoService } from 'utils/ui-components/logo/app-logo/app-logo.service';


export type LogoVariant = 'icon' | 'icon_nopadding' | 'compact' | 'full' | 'inline';


@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss'],
})
export class AppLogoComponent {
  @Input() logoVariant: LogoVariant = 'full';
  @Input() logoMonochrome = false;

  constructor(
    public appLogoService: AppLogoService,
  ) {}


  get icon() {
    return this.appLogoService.getSvgIcon(this.logoMonochrome, this.logoVariant);
  }
}
