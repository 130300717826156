import template from './floatingTile.html?raw';

import { DragListener } from 'utils/util';
import TileController from '../tile.controller';

const SWITCH_CAMERA_TIMEOUT = 3000;


class FloatingTileController extends TileController {
  static get $inject() {
    return [
      '$element',
      'mediaDeviceService',
      'tileRendererPhoneService',
    ].concat(TileController.$inject);
  }

  constructor(
    $elem,
    mediaDeviceService,
    tileRendererPhoneService,
    ...args
  ) {
    super(...args);

    this.$elem = $elem;
    this.mediaDeviceService = mediaDeviceService;
    this.tileRendererPhoneService = tileRendererPhoneService;


    this.dragStartPosition = null;
    this.dragListener = new DragListener(this.$elem[0], {
      onStart: this.onDragStart,
      onDrag: this.onDrag,
      onStop: this.onDragEnd,
      preventDefault: 'touchmove',
    });

    this.hasDragged = false;
    this.switchCameraButtonIsActive = false;

    this._hideSwitchCameraButtonDebounced = debounce(
      this._hideSwitchCameraButton,
      SWITCH_CAMERA_TIMEOUT
    );
  }


  $onDestroy() {
    this.dragListener.disable();
  }


  onDragStart() {
    this.dragStartPosition = {
      left: this.tile.rect.left,
      top: this.tile.rect.top,
    };
  }

  onDrag(move) {
    this.hasDragged = true;

    this._hideSwitchCameraButton();

    this.tileRendererPhoneService.setFloatingTilePosition({
      left: this.dragStartPosition.left + move[0].x,
      top: this.dragStartPosition.top + move[0].y,
    });
  }

  onDragEnd() {
    // $timeout so that `hasDragged` is still true in the onMouseUp handler.
    $timeout(() => {
      this.hasDragged = false;
    });
  }

  onMouseUp($event) {
    if(!this.hasDragged && !this.switchCameraButtonIsActive) {
      this._showSwitchCameraButton();
      // Prevent triggering the click handler
      $event.preventDefault();
    }
  }

  _getNextCamera() {
    return this.mediaDeviceService.getNextAvailableInput('videoinput');
  }

  /**
   * Show the switch camera button.
   */
  _showSwitchCameraButton() {
    let device = this._getNextCamera();
    if(device) {
      this.switchCameraButtonIsActive = true;
      this._hideSwitchCameraButtonDebounced();
    }
  }

  /**
   * Hide the switch camera button.
   */
  _hideSwitchCameraButton() {
    this.switchCameraButtonIsActive = false;
  }


  switchCamera() {
    let device = this._getNextCamera();
    if(device) {
      this.mediaDeviceService.setPreferredDevice(device);
    }
    this._hideSwitchCameraButton();
  }
}


export default {
  controller: FloatingTileController,
  controllerAs: 'floatingTileCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
