import template from './tutorial.html?raw';


import { Rect, format } from 'utils/util';
import { Hint } from './tutorial.service';

class TutorialController {
  get Hint() {
    return Hint;
  }
  get HintText() {
    return Object.freeze({
      [Hint.CAM_MIC]: gettextCatalog.getString('Activate your camera & microphone'),
      [Hint.SETTINGS]: gettextCatalog.getString('Camera & microphone settings'),
      [Hint.INVITE]: gettextCatalog.getString('Invite others'),
      [Hint.CONTENT]: gettextCatalog.getString('Share your screen, files and take notes'),
      [Hint.PARTICIPANTS]: gettextCatalog.getString('Participants'),
    });
  }

  static get $inject() {
    return [
      '$element',
      'tutorialService',
      'tileRendererDesktopService',
      'meetingService',
    ];
  }

  constructor(
    $elem,
    tutorialService,
    tileRendererDesktopService,
    meetingService
  ) {
    this._bind();

    this.tutorialService =  tutorialService;
    this.tileRendererDesktopService = tileRendererDesktopService;
    this.meetingService = meetingService;

    this.$elem = $elem;
    this.$elemHint = {};
    this.initializing = true;

    $timeout(this._setup);
  }

  _bind() {
    this._setup = this._setup.bind(this);
    this._findElements = this._findElems.bind(this);
    this._updateHints = this._updateHints.bind(this);
    this._updateHint = this._updateHint.bind(this);

    this._getAnchor = {
      [Hint.CAM_MIC]: this._getAnchorCamMic.bind(this),
      [Hint.SETTINGS]: this._getAnchorSettings.bind(this),
      [Hint.INVITE]: this._getAnchorInvite.bind(this),
      [Hint.CONTENT]: this._getAnchorContent.bind(this),
      [Hint.PARTICIPANTS]: this._getAnchorParticipants.bind(this),
    };
  }


  _setup() {
    this._findElems();

    window.addEventListener('resize', () => {
      requestAnimationFrame(this._updateHints);
    });
    // Necessary when the sidebar scrollbar is added/removed
    this.tileRendererDesktopService.on('draw', this._updateHints);
    this._updateHints();
    this.initializing = false;
  }


  _findElems() {
    Object.values(Hint).forEach(hint => {
      let selector = format('.tutorial__item--%s', hint);
      this.$elemHint[hint] = this.$elem.find(selector);
    });
  }


  shouldShow(hint) {
    return (
      !this.initializing
      && this.tutorialService.shouldShow(hint)
      && this.tileRendererDesktopService.tileService.activeTiles.length === 0
      && !this.meetingService.isTemplate
    );
  }


  _updateHints() {
    Object.values(Hint).forEach(this._updateHint);
  }


  _updateHint(key) {
    let anchor = this._getAnchor[key]();
    this.$elemHint[key].css({
      top: anchor.top,
      left: anchor.left,
    });
  }


  _getAnchorCamMic() {
    let elemCam = $document.find('.meeting-footer__item--cam')[0] || document.body;
    let elemMic = $document.find('.meeting-footer__item--mic')[0] || document.body;
    let rectCam =  Rect.fromElem(elemCam);
    let rectMic = Rect.fromElem(elemMic);
    return {
      top: rectCam.top,
      left: (rectCam.right + rectMic.left) / 2,
    };
  }

  _getAnchorSettings() {
    let elemSettings = $document.find('.meeting-footer__item--settings')[0] || document.body;
    let rectSettings = Rect.fromElem(elemSettings);
    return {
      top: rectSettings.top,
      left: (rectSettings.left + rectSettings.right) / 2,
    };
  }

  _getAnchorInvite() {
    let elemInvite = $document.find('.meeting-footer__item--invite')[0] || document.body;
    let rectInvite =  Rect.fromElem(elemInvite);
    return {
      top: rectInvite.top,
      left: (rectInvite.left + rectInvite.right) / 2,
    };
  }

  _getAnchorContent() {
    let elemContent = $document.find('.content-buttons')[0] || document.body;
    let rectContent =  Rect.fromElem(elemContent);
    return {
      top: (rectContent.top + rectContent.bottom) / 2,
      left: rectContent.left,
    };
  }

  _getAnchorParticipants() {
    let elemParticipants = $document.find('.meeting-sidebar__tiles')[0] || document.body;
    let rectParticipants =  Rect.fromElem(elemParticipants);
    return {
      top: rectParticipants.bottom - 64,
      left: rectParticipants.left,
    };
  }
}

export default {
  controller: TutorialController,
  controllerAs: 'tutorialCtrl',
  template,

  bindings: {
    translucent: '<',
  },
};
