import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { bind } from 'utils/util';
import {
  Appointment,
  UserAppointment,
  OrganizationAppointment
} from 'scheduling/models/Appointment';
import { UrlService } from 'utils/url.service';
import {
  DashboardScope, DataPageComponent
} from 'dashboard/data-page/data-page.component';

@Component({
  selector: 'past-appointments',
  templateUrl: './past-appointments.component.html',
})
export class PastAppointmentsComponent extends DataPageComponent implements OnChanges {
  // we cannot use 'scope' since it's a property on DataPageComponent. scopePasser is only
  // used to pass on 'scope' from the <scope-switcher> to the DataPageComponent
  @Input() public scopePasser = DashboardScope.PERSONAL;

  public appointments: Appointment[] = [];

  constructor(
    public urlService: UrlService,
  ) {
    super();
    bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scopePasser) {
      this.scope = changes.scopePasser.currentValue;
    }
  }

  override fetch(): Promise<any> {
    const modelConfig = this.scope === DashboardScope.PERSONAL ?
      {
        model: UserAppointment,
        identifiers: { userId: this.requestUserService.user.id }
      } :
      {
        model: OrganizationAppointment,
        identifiers: { organizationId: this.requestUserService.user.organizationId }
      };

    const apiConfig = {
      params: {
        filter: `isCancelled = false AND end <= ${new Date().toISOString()}`,
        orderBy: '-start',
        page: this.page,
        perPage: 10,
      },
    };

    return this.listCancelable(modelConfig, apiConfig).then(({ data: appointments }) => {
      this.appointments = appointments as Appointment[];
    });
  }
}
