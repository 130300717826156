import template from './imageInput.html?raw';


ImageInputDirective.$inject = [
  '$compile',
];


export default function ImageInputDirective(
  $compile
) {
  return {
    restrict: 'A',
    link: function($scope, $elem) {
      let $elemFormGroup = $elem.parents('.form-group');
      $elemFormGroup.attr('ng-controller', 'ImageInputCtrl as imageInputCtrl');

      $elemFormGroup.find('.file-input__current-wrapper, input').hide();
      $elem.removeAttr('image-input');  // Avoid infinite compilation

      let $elemInsert = angular.element(template);
      $elemFormGroup.prepend($elemInsert);

      $compile($elemFormGroup)($scope);
    },
  };
}
