import Model from 'utils/models';
import * as fields from 'utils/fields';
import { User } from 'core/models/User';
import { MeetingTemplate } from './MeetingTemplate';



export class Meeting extends Model {
  static get fields() {
    return {
      key: new fields.StringField(),
      openedSince: new fields.DateTimeField(),
      openedUntil: new fields.DateTimeField(),
      activeSince: new fields.DateTimeField(),
      activeUntil: new fields.DateTimeField(),
      owner: new fields.RelatedField({
        Model: User,
      }),
      activeUsers: new fields.RelatedField({
        Model: User,
        many: true,
      }),
      template: new fields.RelatedField({
        Model: MeetingTemplate,
      }),
      teamAccessLevel: new fields.AccessLevelField(),
      preferredKey: new fields.StringField(),
      urls: new fields.StringField(),
      size: new fields.NumberField(),
      snapshot: new fields.FileField(),
      snapshotName: new fields.StringField(),
      adminpulseRelation: new fields.StringField(),
    };
  }

  // eslint-disable-next-line no-unused-vars
  static get basePath() {
    return 'meetings';
  }

  static get defaultInclude() {
    return ['owner'];
  }
}

export class UserMeeting extends Meeting {
  static listPath(identifiers) {
    return `users/${identifiers.userId}/meetings`;
  }
}

export class OrganizationMeeting extends Meeting {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/meetings`;
  }
}
