export default class WhiteboardMenuController {
  static get $inject() {
    return [
      'tileService',
      'whiteboardService',
    ];
  }

  constructor(
    tileService,
    whiteboardService
  ) {
    this.tileService = tileService;
    this.whiteboardService = whiteboardService;
  }


  get whiteboard() {
    let tile = this.tileService.activeContentTile;
    return tile && tile.whiteboard;
  }
}
