import { Component, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { AppointmentTypeConfig } from 'scheduling/models/AppointmentTypeConfig';
import { StateService } from 'utils/state.service';
import { UrlService } from 'utils/url.service';

import { TemplatePortal } from '@angular/cdk/portal';
import { Member } from 'organization/models/Member';
import { Errors } from 'utils/settings/settings.component';
import { ModalService } from 'utils/ui-components/modal';
import { bind, errors, logger } from 'utils/util';
import { TeamMembersService } from 'organization/teamMembers.service';
import { Memoize } from 'typescript-memoize';
import { RequestUserService } from 'utils/requestUser.service';
import {
  AppointmentTypeConfigurationCheckService
} from '../appointment-types/appointment-type-configuration-check.service';
import { UsageTrackingService } from 'utils/usageTracking.service';


@Component({
  selector: 'appointment-type-config',
  templateUrl: './appointment-type-config.component.html',
})
export class AppointmentTypeConfigComponent {
  public appointmentType?: AppointmentType;
  public errors: Errors = {};
  public fetchError?: string;
  public teamMembers: Member[] = [];

  @ViewChild('modalSuccess') modalSuccess!: TemplateRef<any>;
  memberConfigurationWarnings: string | void = undefined;
  requestUserConfigurationWarning: string | void;


  constructor(
    @Inject('modelFactory') private modelFactory,
    @Inject('notificationService') private notificationService,

    private appointmentTypeConfigurationCheckService: AppointmentTypeConfigurationCheckService,
    private requestUserService: RequestUserService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,

    public stateService: StateService,
    public urlService: UrlService,
    private usageTrackingService: UsageTrackingService,
    private teamMemberService: TeamMembersService,
  ) {
    bind(this);
    this.initialize();

    this.requestUserConfigurationWarning =
      this.appointmentTypeConfigurationCheckService.requestUserConfigurationWarning();
  }


  get appointmentTypeId(): string {
    return ANGULAR_SCOPE.appointmentTypeId;
  }

  get headerText() {
    return this.appointmentTypeId === '' ?
      $localize `Create a meeting type` :
      $localize `Edit a meeting type`;
  }

  get hasPermissionToEdit() {
    return (
      this.requestUserService.user.isAdmin
      || this.appointmentType && !this.appointmentType.isTeamAppointmentType
    );
  }

  get testItLiveUrl() {
    if(this.appointmentType && this.appointmentType.showPublic) {
      return this.urlService.urls.personalBookingLink;
    } else {
      return this.appointmentType.url;
    }
  }

  get isTeamAppointmentType() {
    return this.appointmentType?.appointmentTypeConfigs.filter(atc => atc.active).length > 1;
  }

  private async initialize() {
    this.stateService.setState(this.stateService.State.LOADING);

    this.getAppointmentType()
      .then(() => {
        // While we are waiting for the full list of team members to come in through
        // `fetchTeamMembers`, we can already show the currently configured members.
        if(this.teamMembers.length === 0) {
          this.teamMembers = this.appointmentType.appointmentTypeConfigs
            .map(config => config.user)
            .sort(this._sortMembers);
        }
        this.memberConfigurationWarnings = this.appointmentTypeConfigurationCheckService
          .buildUnconfiguredMembersWarning(this.appointmentType);
      })
      .finally(() => {
        this.stateService.setState(this.stateService.State.READY);
      })
      .catch(this.handleFetchError);

    // If people don't have permissions to list the team members, we only show the currently
    // configured users (this list is set a bit higher in this method).
    if(
      this.requestUserService.user.isAdmin
      || this.requestUserService.user.organization.showTeamMembersNonAdmin
    ) {
      this.fetchTeamMembers().catch(this.handleFetchError);
    }
  }

  private async fetchTeamMembers() {
    this.teamMembers = (await this.teamMemberService.get(false, { perPage: 'all' }))
      .filter(member => member.isActive)
      .sort(this._sortMembers);
  }

  @Memoize()
  private async getAppointmentType() {
    if(this.appointmentTypeId) {
      this.appointmentType = await this.fetchAppointmentType();
    } else {
      this.appointmentType = this.initializeNewAppointmentType();
    }
  }

  private async fetchAppointmentType() {
    const response = await this.modelFactory.read({
      model: AppointmentType,
      identifiers: { id: this.appointmentTypeId },
      include: ['contactForm.questions', 'appointmentTypeConfigs.user.schedulingConfig'],
    });
    const appointmentType: AppointmentType = response.data;
    return appointmentType;
  }


  private initializeNewAppointmentType() {
    const appointmentTypeConfig: AppointmentTypeConfig = this.modelFactory.createInstance(
      AppointmentTypeConfig, {
        values: {
          user: this.requestUserService.user,
          active: true,
        },
      },
    );
    const appointmentType: AppointmentType = this.modelFactory.createInstance(
      AppointmentType, {
        values: {
          appointmentTypeConfigs: [appointmentTypeConfig],
        },
        include: ['all'],
      },
    );
    return appointmentType;
  }

  _sortMembers(member1, member2) {
    const isMe1 = member1.id === this.requestUserService.user.id ? 1 : 0;
    const isMe2 = member2.id === this.requestUserService.user.id ? 1 : 0;
    return (
      isMe2 - isMe1
      || member1.fullName.localeCompare(member2.fullName)
    );
  }


  handleFetchError(error) {
    /* eslint-disable max-len */
    if(error.constructor === errors.DoesNotExistError) {
      this.fetchError = $localize `We couldn't find the meeting type you were looking for. Perhaps it was removed, or you don't have access?`;
    } else if(error.constructor === errors.OfflineError) {
      this.fetchError = $localize `You seem to be offline. Please check your internet connection and reload the page.`;
    } else {
      logger.warn(error);
      this.fetchError = $localize `Something went wrong while fetching your meeting type. Please try again later.`;
    }
    /* eslint-enable max-len */
  }

  async save() {
    if(this.stateService.isSaving) {
      return;
    }
    this.stateService.setState(this.stateService.State.SAVING);

    try {
      await this.clientSideValidation();

      if(this.appointmentType.id) {
        if(this.hasPermissionToEdit) {
          await this.appointmentType.update();
          this.track('appointmentType.updated');
        } else {
          await this.updateAppointmentTypeConfig();
        }
        this.showSuccessNotification();
      } else {
        await this.appointmentType.create();
        this.track('appointmentType.created');
        this.showSuccessModal();
      }
      this.clearSaveError();

    } catch(error: any) {
      this.errors = this.notificationService.handleError(
        error,
        $localize `Something went wrong while saving your meeting type.`,
        'page',
      );

    } finally {
      // Set a timeout so the "processing" state doesn't just immediately goes back to the
      // initial state.
      setTimeout(() => this.stateService.setState(this.stateService.State.READY), 3000);
    }
  }

  private track(event: string) {
    this.usageTrackingService.createSegmentEvent(
      event,
      'appointmentTypeConfig',
      { isTeam: this.isTeamAppointmentType }
    );
  }


  private clientSideValidation() {
    const clientSideErrors: Errors = {};

    if(!this.appointmentType.name) {
      clientSideErrors.name = [$localize `Please make sure to enter a name`];
    }

    if(this.appointmentType.locations.length === 0) {
      clientSideErrors.locations = [$localize `Please make sure that a location is selected`];
    }

    const hasActiveConfig = this.appointmentType.appointmentTypeConfigs.some(atc => atc.active);
    if(!hasActiveConfig) {
      clientSideErrors.availability = [
        $localize `Please make sure that at least one host is active`
      ];
    }

    const availabilityErrors = this.appointmentType.appointmentTypeConfigs.map(
      (atc: AppointmentTypeConfig) => {
        const availabilityIsEmpty = atc.availability.every( availabilityDay => {
          return availabilityDay.length === 0;
        });

        if(availabilityIsEmpty) {
          return { availability: [$localize `Please make sure the schedule has active slots.`] };
        } else {
          return {};
        }
      });

    if(availabilityErrors.some(errors => Object.keys(errors).length > 0)) {
      clientSideErrors.appointmentTypeConfigs = availabilityErrors;
    }

    if(Object.keys(clientSideErrors).length > 0) {
      const e = new errors.ValidationError(
        $localize `Meeting type couldn't be saved. Please check the page for errors.`
      );
      e.response = { data: clientSideErrors };
      throw e;
    }
  }

  async updateAppointmentTypeConfig() {
    const configIndex = this.appointmentType.appointmentTypeConfigs.findIndex(config => {
      return config.userId === this.requestUserService.user.id;
    });
    const config = this.appointmentType.appointmentTypeConfigs[configIndex];
    try {
      await config.update();
    } catch(error: any) {
      // If we run into any validation errors, we transform them so that it appears that they
      // originated from `appointmentType.update()` with a nested `AppointmentTypeConfig`, and
      // not the `appointmentTypeConfig.update()` that we actually did.
      if(error.constructor === errors.ValidationError) {
        const configErrors = error.response.data;
        const allConfigErrors: any[] = [];
        allConfigErrors[configIndex] = configErrors;
        const errors = {
          appointmentTypeConfigs: allConfigErrors,
        };
        error.response.data = errors;
      }
      throw error;
    }
  }


  private clearSaveError() {
    this.errors = {};
  }


  async delete() {
    if(!this.appointmentType.id) {
      return;
    }

    try {
      await this.appointmentType.delete();
      this.goToOverview();
    } catch(error) {
      this.handleDeleteError();
    }
  }


  handleDeleteError() {
    this.notificationService.error(
      $localize `Meeting type couldn't be deleted. Please try again later.`,
    );
  }


  private showSuccessModal() {
    const templatePortal = new TemplatePortal(this.modalSuccess, this.viewContainerRef);
    this.modalService.open({
      templatePortal: templatePortal,
      modalClass: 'modal--sm',
      hideHeader: true,
    });
  }


  private showSuccessNotification() {
    this.notificationService.success(
      $localize `Meeting type successfully saved!`,
      {
        persistOnReload: true,
      }
    );
    this.goToOverview();
  }


  private goToOverview() {
    window.location.href = this.urlService.urls.appointmentTypesOverview;
  }

}
