import { Component, OnInit } from '@angular/core';
import { Errors, SettingsComponent } from 'utils/settings/settings.component';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';


@Component({
  selector: 'appointment-type-questions[instance]',
  templateUrl: 'appointment-type-questions.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeQuestionsComponent
  }]
})
export class AppointmentTypeQuestionsComponent
  extends AppointmentTypeSettingsComponent
  implements OnInit {

  override ngOnInit(): void {
    super.ngOnInit();
    if(!this.instance.contactForm.vecteraMappingConfig) {
      this.instance.contactForm.createChildInstance('vecteraMappingConfig');
    }
    this.instance.contactForm.vecteraMappingConfig.createContact = true;
  }


  get shouldShowTeamleaderFieldMapping() {
    return this.requestUserService.user.organization.hasActiveTeamleaderIntegration;
  }


  get contactFormErrors() {
    return this.errors.contactForm as Errors;
  }

  override get valid() {
    return (this.contactFormErrors?.questions || []).length === 0;
  }
}
