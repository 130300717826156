import { EventEmitter, object, set, errors, format, logger } from 'utils/util';
import { inject } from '@angular/core';
import {
  API_SERVICE_TOKEN, NotificationService,
} from 'utils/angularjs-upgraded-providers';
import { MeetingService } from './meeting.service';

const WRITEABLE_SETTINGS = set.freeze([
  'presenterMode',
  'showPrivateNotes',
]);

type WhiteLabelSettings = {
  utmCampaign: string,
  customHomepage: string,
  customLogo: any,
  homepage: string,
  hasAddon: boolean,
  showPoweredBy: boolean,
  waitingRoomBackground: any,
}


export default class MeetingSettings {

  private _settings = {};
  private _syncedSettings = {};

  public eventEmitter: EventEmitter;
  private apiService = inject(API_SERVICE_TOKEN);
  private notificationService = inject(NotificationService);


  /**
   * Using Object.defineProperty, a bunch of class properties are added on this object.
   *
   * This list tries to catalogue those properties.
   *
   * The list is preliminary: in an AJS context no typing is happening, so any mistakes will only
   * become apparent when the respective origin is upgraded.
   *
   * At that time, an effort should also be made to give each property the correct type
   */
  public whitelabel!: WhiteLabelSettings;
  public enableLiveSnapsShots;
  public allowGroupMeetings;
  public showPrivateNotes;
  public allowPrivateNotes;
  public showRecording;
  public allowRecording;
  public allowCloudRecordings;
  public autoReset;
  public presenterMode;
  public allowSaveAsTemplate;
  public allowTeamAccessLevelOverride;
  public allowVideoViewport;
  public showLogoVideoTile;
  public metdiaServerUrl;
  public allowUserMediaServer;
  public showHostLogin;
  public showTeaserAfterMeeting;
  public forwardUrl;
  public forwardImmediately;
  public mediaserverUrl;

  constructor(
    private meetingService: MeetingService
  ) {
    this.eventEmitter = EventEmitter.setup(this, [], true);
  }

  update(settings) {
    object.forEach(settings, (key, value) => {
      if(!this._settings.hasOwnProperty(key)) {
        this._settings[key] = null;
        this.eventEmitter.addEvent(key);

        let propertyConfig = {};
        if(WRITEABLE_SETTINGS.has(key)) {
          propertyConfig = {
            get: this.getProperty.bind(this, key),
            set: this.setLocalProperty.bind(this, key)
          };
        } else {
          propertyConfig = {
            get: this.getProperty.bind(this, key),
          };
        }
        Object.defineProperty(this, key, propertyConfig);
      }

      this.setProperty(key, value, true);
    });
  }


  private getProperty(key) {
    return this._settings[key];
  }

  private setLocalProperty(key, value) {
    this.setProperty(key, value, false);
    this.pushProperty(key, value);
  }


  private setProperty(key, value, synced) {
    if(!this._settings.hasOwnProperty(key)) {
      throw errors.InvalidArgumentError(`Invalid setting: ${key}`);
    }

    if(synced) {
      this._syncedSettings[key] = value;
    }
    if(value !== this._settings[key]) {
      this._settings[key] = value;
      this.eventEmitter.emit(key, value);
    }
  }


  private pushProperty(key, value) {
    const path = format('meetings/%s/settings/', this.meetingService.id);
    const data = {
      [key]: value,
    };
    this.apiService.patch(path, data, {
      maxRetries: Infinity,
    })
      .catch(error => {
        logger.warn(error);
        this.notificationService.error(
          $localize `Failed to change setting <b>${key}</b>. Please try again later.`
        );

        this.setProperty(key, this._syncedSettings[key], false);
      });
  }
}
