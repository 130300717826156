import { VideoStream } from './VideoStream';
import { ScreenType } from '../../angularjs/main/streams/screenshareStream.service';
import { StreamType } from './Stream';
import Session from 'meeting/angularjs/main/users/Session';


export class ScreenStream extends VideoStream {
  public screenType: ScreenType = null;

  constructor(
    type: StreamType,
    id: string,
    groupId: string,
    session: Session,
    readyToPlayService,
    notificationService,
    focusService,
    segmentationSettingsService,
    videoCompatibleService
  ) {
    super(
      type, id, groupId, session,
      readyToPlayService,
      notificationService,
      focusService,
      segmentationSettingsService,
      videoCompatibleService
    );
  }

  setScreenType(screenType: ScreenType) {
    this.screenType = screenType;
  }

  override monitor() {}

  override createSegmentedTrack(track: MediaStreamTrack) {
    return track;
  }
}
