import { AfterViewInit, Component, Input } from '@angular/core';
import { QuestionTypeConfigComponent } from '../question-type-config.component';
import { QuestionType } from 'contactForm/models/ContactFormQuestion';

@Component({
  templateUrl: './multi-option-config.component.html',
  styleUrls: ['./multi-option-config.component.scss'],
  providers: [{
    provide: QuestionTypeConfigComponent,
    useExisting: MultiOptionConfigComponent
  }],
})
export class MultiOptionConfigComponent
  extends QuestionTypeConfigComponent
  implements AfterViewInit {

  @Input() type!: string;

  public interfaceOptions: string[] = ['Option 1'];


  ngAfterViewInit() {
    if(this.question.extra.otherOption == null || !this.allowOtherOption) {
      this.question.extra.otherOption = false;
    }
    if(this.question.extra.options == null) {
      this.updateInstance();
    } else {
      this.updateInterfaceState();
    }
    this.tryAddOption();
  }


  private updateInterfaceState() {
    this.interfaceOptions = this.question.extra.options.map(option => option[0]);
  }

  private updateInstance() {
    const resultState = { ...this.question.extra };
    resultState.options = this.interfaceOptions
      .filter(option => option !== '')
      .map(option => [option, option]);

    this.question.extra = resultState;
  }

  get allowOtherOption() {
    return this.question.type !== QuestionType.DROPDOWN;
  }

  get canAddOption() {
    return (
      this.interfaceOptions[this.interfaceOptions.length - 1] !== ''
      && this.interfaceOptions.length < 20
    );
  }

  get canDeleteAnyOption() {
    return this.interfaceOptions
      .filter(option => option !== '')
      .length > 1;
  }

  canDeleteOption(index) {
    const isEmptyOption = this.interfaceOptions[index] === '';
    const isLastOption = index === this.interfaceOptions.length - 1;
    const isOnlyRemainingFilledOption = this.interfaceOptions
      .filter(option => option !== '')
      .length < 2;

    return (isEmptyOption && !isLastOption)
      || (!isEmptyOption && !isOnlyRemainingFilledOption);
  }


  tryAddOption() {
    if(this.canAddOption) {
      this.interfaceOptions.push('');
    }
    this.updateInstance();
  }

  removeOption(index) {
    this.interfaceOptions.splice(index, 1);
    this.updateInstance();
  }

  toggleOtherOption() {
    this.question.extra.otherOption = !this.question.extra.otherOption;
    this.updateInstance;
  }

  trackByIndex(index:number): number {
    return index;
  }
}
