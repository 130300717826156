<div class="container pp" data-testid="meetingTypesElement">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <h1 class="page-title" i18n>Meeting Types</h1>

  <div
    *ngIf="configurationAction"
    class="field field--warning mb-7"
  >
    <field-icon [type]="'warning'"></field-icon>
    <div>
      <p>{{ configurationAction.warningText }}</p>
      <div><a class="btn btn--primary" [href]="urlService.urls.schedulingConfigure">{{ configurationAction.buttonText }}</a></div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <!-- Mobile header -->
    <div class="d-md-none" data-testid="header">
      <div class="d-flex flex-column">
        <scope-switcher
          [(scope)]="scope"
          [scopes]="scopes"
          class="d-md-none"
        ></scope-switcher>

        <a
          [href]="urlService.urls.bulkUpdate"
          class="d-md-none align-self-center mt-5"
          data-testid="bulkUpdateButton"
          i18n
        >
          Bulk update settings
        </a>
      </div>
    </div>

    <!-- Desktop header -->
    <div class="mb-10">
      <div class="d-none d-md-flex flex-wrap justify-content-between mr--2 mb--2" data-testid="header">
        <scope-switcher
          [(scope)]="scope"
          [scopes]="scopes"
          class="mr-2 mb-2"
        ></scope-switcher>

        <div class="d-flex align-items-center">
          <a
            *ngIf="canBulkUpdate"
            [href]="urlService.urls.bulkUpdate"
            class="btn btn--secondary mr-2 mb-2"
            data-testid="bulkUpdateButton"
            i18n
          >
            Bulk update settings
          </a>
          <div
            *ngIf="!canBulkUpdate"
            i18n-matTooltip
            matTooltip="Upgrade your subscription to update settings in bulk"
            class="btn btn--secondary mr-2 mb-2 disabled"
            data-testid="bulkUpdateButton"
            i18n
          >
            Bulk update settings
          </div>
          <div
            class="btn btn--secondary mr-2 mb-2"
            [cdkMenuTriggerFor]="copyBookingLinksDropdown"
            data-testid="copyBookingLinksButton"
          >
            <span i18n>
              Booking links
            </span>
            <svg-icon class="btn__icon btn__icon--right" [svgName]="'chevron_down'"></svg-icon>
          </div>

          <div
            *ngIf="!canCreate"
            class="btn btn--primary mr-2 mb-2 disabled"
            i18n-matTooltip
            matTooltip="Upgrade your subscription to create more than one meeting type"
            data-testid="createLink"
          >
            <span i18n>
              Create new
            </span>
            <svg-icon class="btn__icon btn__icon--right" [svgName]="'add'"></svg-icon>
          </div>

          <a
            *ngIf="canCreate"
            class="btn btn--primary mr-2 mb-2"
            [href]="urlService.urls.newAppointmentType"
            (click)="trackStartedCreatingEvent()"
            data-testid="createLink"
          >
            <span i18n>
              Create new
            </span>
            <svg-icon class="btn__icon btn__icon--right" [svgName]="'add'"></svg-icon>
          </a>
        </div>
      </div>
    </div>
  </div>

  <fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>

  <div>
    <div
      *ngIf="!fetchError"
      class="d-flex flex-1 position-relative mb-6"
    >
      <input
        class="input input--with-icon"
        [ngModel]="searchQuery"
        (ngModelChange)="setQuery($event)"
        placeholder="Search meeting types"
        i18n-placeholder
        cdkFocusInitial
      />
      <svg-icon class="input__icon" [svgName]="'search'"></svg-icon>
    </div>

    <div *ngIf="noResultsMessage" class="card card--sm bg-neutral-200">
      {{ noResultsMessage }}
    </div>

    <div
      class="card-grid"
      *ngIf="!fetchError && appointmentTypes.length > 0"
    >
      <a
        *ngFor="let appointmentType of appointmentTypes"
        class="card-grid__card card-grid__card--clickable a-plain"
        [class.card-grid__card--inactive]="!appointmentType.active"
        [href]="getUpdateUrl(appointmentType)"
        data-testid="meetingType"
      >
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <div *ngIf="appointmentType.active">
            <badge
              *ngFor="let badge of getLocationBadges(appointmentType)"
              [badge]="badge"
              class="mr-2"
            ></badge>
          </div>
          <div *ngIf="!appointmentType.active">
            <span
              class="badge badge--error"
              i18n
            >
              Disabled
            </span>
          </div>
          <div class="d-flex align-items-center">
            <div
              *ngIf="appointmentType.active"
              class="btn btn--square ml-2"
              [ngClass]="{ disabled: !appointmentType.active }"
              [modalTriggerFor]="shareAppointmentType"
              [modalIcon]="{name: 'share_android'}"
              [modalTitle]="getSharingOptionsTitle(appointmentType)"
              (click)="$event.preventDefault()"
            >
              <svg-icon class="icon" [svgName]="'share_android'"></svg-icon>
            </div>

            <div
              *ngIf="!appointmentType.active"
              class="btn btn--square disabled"
              i18n-matTooltip
              matTooltip="Activate this meeting type to share it"
              (click)="$event.preventDefault()"
            >
              <svg-icon class="icon" [svgName]="'share_android'"></svg-icon>
            </div>

            <ng-template #shareAppointmentType>
              <sharing-options
                [name]="appointmentType.name"
                [url]="appointmentType.url"
                [embeddedUrl]="appointmentType.embeddedUrl"
                [id]="appointmentType.id"
                context="appointmentType"
              >
              </sharing-options>
            </ng-template>

            <div
              class="btn btn--square ml-2"
              (click)="$event.preventDefault()"
              [cdkMenuTriggerFor]="actions"
            >
              <svg-icon class="icon" [svgName]="'more'"></svg-icon>
            </div>


            <ng-template #actions>
              <div class="dropdown" cdkMenu>
                <div cdkMenuItem></div>
                <a
                  href="{{ getUpdateUrl(appointmentType) }} "
                  class="dropdown__list-item"
                  cdkMenuItem
                >
                  <svg-icon class="dropdown__list-icon" [svgName]="'edit'"></svg-icon>
                  <span i18n>Edit</span>
                </a>
                <a
                  *ngIf="canDuplicate(appointmentType)"
                  (click)="duplicate(appointmentType)"
                  class="dropdown__list-item"
                  cdkMenuItem
                >
                  <svg-icon class="dropdown__list-icon" [svgName]="'copy'"></svg-icon>
                  <span i18n>Duplicate</span>
                </a>
                <div *ngIf="canEdit">
                  <a
                    class="dropdown__list-item text-error-400"
                    cdkMenuItem

                    [dialog]="deleteConfirmation"
                    dialogTitle="Delete meeting type"
                    i18n-dialogTitle
                    [dialogIcon]="{ name: 'trash' }"
                    dialogConfirmText="Delete"
                    i18n-dialogConfirmText
                    dialogConfirmButtonClass="btn--error"
                    [dialogConfirmedCallback]="delete.bind(this, appointmentType)"
                  >
                    <svg-icon class="dropdown__list-icon" [svgName]="'trash'"></svg-icon>
                    <span i18n>Delete</span>
                  </a>

                  <hr class="m-2">

                  <div
                    class="dropdown__list-item"
                    i18n-matTooltip
                    matTooltip="Upgrade your subscription to have more than one active meeting type"
                    [matTooltipDisabled]="canCreate || appointmentType.active"
                  >
                    <input
                      id="appointmentTypeActivationToggle"
                      type="checkbox"
                      class="toggle"
                      [ngModel]="appointmentType.active"
                      (ngModelChange)="setActive(appointmentType, $event)"
                      [disabled]="!canCreate && !appointmentType.active"
                    />
                    <label for="appointmentTypeActivationToggle" class="toggle__label" i18n>
                      Active
                    </label>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template #deleteConfirmation>
              <p i18n>Are you sure you want to delete the meeting type <b>{{ appointmentType.name }}</b>?</p>
              <p i18n>This action cannot be reverted. Guests will no longer be able to book meetings of this type.</p>
            </ng-template>
          </div>
        </div>


        <div class="d-flex flex-column flex-1">
          <p class="d-flex align-items-center">
            <span class="text-h2">{{ appointmentType.name }}</span>
            <span
              *ngFor="let configurationWarning of configurationIssueWarnings(appointmentType)"
              class="ml-2"
              [matTooltip]="configurationWarning"
              (click)="tooltip.toggle();$event.preventDefault()"
              #tooltip="matTooltip"
            >
              <svg-icon class="icon text-error-400" [svgName]="'warning'" [svgStyle]="'badged_filled'"></svg-icon>
            </span>
          </p>
          <p class="line-clamp-4">
            {{ appointmentType.description }}
          </p>
          <div class="align-items-end d-flex justify-content-between mt-auto">
            <div>
              <div>
                <svg-icon class="icon icon--inline mr-1" [svgName]="'clock'"></svg-icon>
                <span>{{ appointmentType.duration | duration:true }}</span>
              </div>
              <div
                *ngIf="appointmentType.paymentOption === 'payment_necessary'"
                class="mt-2"
              >
                <svg-icon class="icon icon--inline mr-1" [svgName]="'money'"></svg-icon>
                <span>{{ appointmentType.paymentAmount | currency:appointmentType.paymentCurrency:'symbol':'1.2-2'}}</span>
              </div>
            </div>
            <profile-images
              [users]="getHosts(appointmentType)"
              [maxBubbles]=3
            ></profile-images>
          </div>

        </div>
      </a>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination
        [(page)]="page"
        [numPages]="numPages"
      ></pagination>
    </div>
  </div>
</div>


<!-- Mobile actions -->
<div class="action-footer d-md-none">
  <div class="action-footer__button-row">
    <div
      class="btn btn--secondary"
      [cdkMenuTriggerFor]="copyBookingLinksDropdown"
      data-testid="copyBookingLinksButton"
    >
      <span i18n>Booking links</span>
      <svg-icon class="btn__icon btn__icon--right" [svgName]="'chevron_down'"></svg-icon>
    </div>

    <div
      class="btn btn--primary disabled"
      *ngIf="!canCreate"
      matTooltip="Upgrade your subscription to create more than one meeting type"
      i18n-matTooltip
      data-testid="createLink"
    >
      <span i18n>Create new</span>
      <svg-icon class="btn__icon btn__icon--right" [svgName]="'add'"></svg-icon>
    </div>

    <a
      class="btn btn--primary"
      *ngIf="canCreate"
      [attr.href]="urlService.urls.newAppointmentType"
      (click)="trackStartedCreatingEvent()"
      data-testid="createLink"
    >
      <span i18n>Create new</span>
      <svg-icon class="btn__icon btn__icon--right" [svgName]="'add'"></svg-icon>
    </a>
  </div>
</div>


<ng-template #copyBookingLinksDropdown>
  <div class="dropdown" cdkMenu>
    <div
      [copyText]="urlService.urls.personalBookingLink"
      class="dropdown__list-item"
      cdkMenuItem
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'user'"></svg-icon>
      <span i18n>Copy personal booking link</span>
    </div>
    <div
      [copyText]="urlService.urls.personalEmbeddedBookingLink"
      [copyEmbeddedLink]="true"
      class="dropdown__list-item"
      cdkMenuItem
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'user'"></svg-icon>
      <span i18n>Copy personal embedded HTML</span>
    </div>

    <ng-container *ngIf="shouldShowTeamBookingLinks">
      <div
        [copyText]="urlService.urls.teamBookingLink"
        class="dropdown__list-item"
        cdkMenuItem
      >
        <svg-icon class="dropdown__list-icon" [svgName]="'team'"></svg-icon>
        <span i18n>Copy team booking link</span>
      </div>
      <div
        [copyText]="urlService.urls.teamEmbeddedBookingLink"
        [copyEmbeddedLink]="true"
        class="dropdown__list-item"
        cdkMenuItem
      >
        <svg-icon class="dropdown__list-icon" [svgName]="'team'"></svg-icon>
        <span i18n>Copy team embedded HTML</span>
      </div>
    </ng-container>
  </div>
</ng-template>
