import DocumentRenderer from './DocumentRenderer';


export default class DocumentRendererFactory {
  static get $inject() {
    return [
      'notificationService',
      'pdfjsService',
    ];
  }

  constructor(
    notificationService,
    pdfjsService
  ) {
    this.notificationService = notificationService;
    this.pdfjsService = pdfjsService;
  }


  create(whiteboard) {
    return new DocumentRenderer(
      this.notificationService,
      this.pdfjsService,
      whiteboard);
  }
}
