import { logger, errors, format, EventEmitter, set } from 'utils/util';


const HARD_LIMIT_NUM_EVENTS = 100e3;
const SOFT_LIMIT_NUM_EVENTS = HARD_LIMIT_NUM_EVENTS * .9;

const SAVING_EXCLUDED_EVENTS = ['content-shared-mouse'];


export default class BroadcastService {
  static get $inject() {
    return [
      'apiService',
      'meetingService',
      'meetingReliableSocketService',
      'userService',
    ];
  }

  constructor(
    apiService,
    meetingService,
    meetingReliableSocketService,
    userService
  ) {
    this._bind();

    this.apiService = apiService;
    this.meetingService = meetingService;
    this.meetingReliableSocketService = meetingReliableSocketService;
    this.userService = userService;

    this.LEGAL_MEETING_STATES = set.freeze([
      this.meetingService.MeetingState.KNOCKING,
      this.meetingService.MeetingState.JOINED,
    ]);

    this.eventEmitter = new EventEmitter([], true);
    this.emit = this.eventEmitter.emit;

    this.registeredChannels = new Set();
    this.prevLegalMeetingState = meetingService.state;

    this._initializing = true;
    this.afterInitializationDefer = $q.defer();
    this.gettingInitialMessages = false;
    this.shouldGetInitialMessages = false;
    this.shouldResetMaxReceivedId = false;

    // Outbound messages are queued and sent one by one, each time waiting for acknowledgement from
    // the server for the previous message. To avoid conflicts: no inbound messages are accepted as
    // long as the send queue is not empty
    this.sendQueue = [];
    this.sending = false;

    this.receivedIds = new Set();
    this.maxReceivedId = null;
    this.numReceived = 0;
    this.receiveQueue = [];
    this.heldMessages = [];


    this._registerChannel('session-join');
    $timeout(this._setupListeners);
  }

  _bind() {
    this._setupListeners = this._setupListeners.bind(this);
    this._updateMeetingState = this._updateMeetingState.bind(this);
    this._updateMeetingStateDebounced = debounce(this._updateMeetingState.bind(this));
    this._onMessage = this._onMessage.bind(this);
  }

  _setupListeners() {
    this.LEGAL_MEETING_STATES.forEach(state => {
      this.meetingService.on(state, this._updateMeetingState);
    });
  }

  get isSaving() {
    return this.sendQueue.some(
      item => !SAVING_EXCLUDED_EVENTS.includes(item.channel)
    );
  }

  /***********
   * General *
   ***********/

  /**
   * During initialization, broadcastservice will broadcast the entire backlog of events from a
   * meeting room. Once the backlog has been broadcasted this will return false
   *
   * @returns {boolean}
   */
  get initializing() {
    return this._initializing;
  }


  _updateMeetingState() {
    let meetingState = this.meetingService.state;
    let resetMaxReceivedId = (meetingState !== this.prevLegalMeetingState);
    this.prevLegalMeetingState = meetingState;
    this._getInitialMessages(resetMaxReceivedId);
  }


  afterInitialization() {
    return this.afterInitializationDefer.promise;
  }


  _finishAfterInitialization() {
    if(this._initializing) {
      this._initializing = false;
      this.afterInitializationDefer.resolve();
    }
  }


  _getEmitterChannel(channel, ownMessages) {
    return format('%s:%s', channel, ownMessages);
  }



  /********************
   * Receive messages *
   ********************/

  _registerChannel(channel) {
    if(!this.registeredChannels.has(channel)) {
      this.registeredChannels.add(channel);
      this.meetingReliableSocketService.on(channel, this._onMessage);
    }
  }


  on(channel, callback, ownMessages) {
    if(typeof channel !== 'string') {
      throw new errors.InvalidArgumentError(format(
        'Argument `channel` must be a string, got `%s`', channel));
    }
    if(channel.indexOf(':') !== -1) {
      throw new errors.InvalidArgumentError(format(
        'Argument `channel` cannot contain semicolons, got `%s`', channel));
    }
    if(typeof callback !== 'function') {
      throw new errors.InvalidArgumentError(format(
        'Argument `callback` must be a function, got `%s`', callback));
    }
    if(typeof ownMessages !== 'boolean') {
      throw new errors.InvalidArgumentError(format(
        'Argument `ownMessages` must be a boolean, got `%s`', ownMessages));
    }

    this._registerChannel(channel);

    // If `ownMessages == true`, both own and remote messages are received
    this.eventEmitter.on(this._getEmitterChannel(channel, false), callback);
    if(ownMessages) {
      this.eventEmitter.on(this._getEmitterChannel(channel, true), callback);
    }
  }


  _getInitialMessages(resetMaxReceivedId) {
    if(this.gettingInitialMessages) {
      this.shouldGetInitialMessages = true;
      this.shouldResetMaxReceivedId = this.shouldResetMaxReceivedId || resetMaxReceivedId;

    } else {
      logger.info('Get initial messages');
      this.gettingInitialMessages = true;
      this.shouldGetInitialMessages = false;
      this.shouldResetMaxReceivedId = false;

      if(resetMaxReceivedId) {
        this.maxReceivedId = null;
      }
      let now = Date.now();

      let urlEvents = format(
        'sessions/%s/events/?perPage=all&nocache=%s',
        this.userService.mySession.id,
        now
      );
      if(this.maxReceivedId != null) {
        urlEvents += '&gt=' + this.maxReceivedId;
      }

      this.apiService.get(urlEvents, {
        maxRetries: Infinity,
      })
        .then(response => {
          let events = response.data;
          this.receiveQueue = events.map(event => {
            return {
              id: event.id,
              channel: event.channel,
              senderId: event.sender,
              datetime: new Date(event.timestamp),
              message: event.message,
            };
          });

          this._processReceiveQueue();
        });
    }
  }


  _finishGetInitialMessages() {
    this.gettingInitialMessages = false;

    if(this.shouldGetInitialMessages) {
      this._getInitialMessages(this.shouldResetMaxReceivedId);
    } else {
      this.receiveQueue = this.heldMessages;
      this.heldMessages = [];
      this._processReceiveQueue();
    }
  }


  _onMessage(channel, data) {
    if(channel[0] === '_') {
      this._onMetaMessage(channel, ...data);

    } else {
      let item = {
        id: data[0],
        channel: channel,
        senderId: data[1],
        datetime: new Date(data[2]),
        message: data[3],
      };

      let state = this.meetingService.state;
      if(!this.gettingInitialMessages && this.LEGAL_MEETING_STATES.has(state)) {
        this.receiveQueue.push(item);
        this._processReceiveQueue();

      } else {
        this.heldMessages.push(item);
      }
    }
  }


  _onMetaMessage(channel, ...data) {
    let emitterChannel = this._getEmitterChannel(channel, false);
    this.emit(emitterChannel, ...data);
  }


  _processReceiveQueue() {
    while(this.receiveQueue.length) {
      if(this.sending) {
        return;
      }

      let item = this.receiveQueue.shift();
      let id = item.id;

      if(id === null || !this.receivedIds.has(id)) {
        if(id !== null) {
          this.numReceived++;

          if(this.numReceived === SOFT_LIMIT_NUM_EVENTS) {
            this._onMetaMessage('_softLimitNumEvents');
          } else if(this.numReceived === HARD_LIMIT_NUM_EVENTS) {
            this._onMetaMessage('_hardLimitNumEvents');
          }
          this.receivedIds.add(id);
          this.maxReceivedId = id;
        }

        let channel = item.channel;
        let sender = this.userService.getOrCreateSession(item.senderId);
        let message = item.message;

        if(!this.gettingInitialMessages) {
          logger.debug('Got BM %s on channel "%s" from %s:',
            id, channel, sender.id, message);
        }

        let args = [channel, sender, item.datetime].concat(message);

        let emitterChannel = this._getEmitterChannel(channel, sender.isLocal);
        this.emit(emitterChannel, ...args);

        if(channel === 'session-join' && sender.isLocal) {
          this._finishAfterInitialization();
        }
      }
    }

    if(this.gettingInitialMessages) {
      this._finishGetInitialMessages();
    }
  }



  /*****************
   * Send messages *
   *****************/

  send(channel, hostMessage, receivers) {
    if(typeof channel !== 'string') {
      throw new Error('Argument "channel" must be a string');
    }
    if(typeof hostMessage !== 'boolean') {
      throw new Error('Argument "hostMessage" must be a boolean');
    }
    if(!Array.isArray(receivers)) {
      throw new Error('Argument "receivers" must be an array');
    }

    let message = new Array(arguments.length - 3);
    for(let i = 3; i < arguments.length; i++) {
      message[i - 3] = arguments[i];
    }

    let defer = $q.defer();
    this.sendQueue.push({
      defer: defer,
      channel: channel,
      hostMessage: hostMessage,
      receivers: receivers,
      message: message,
    });
    this._processSendQueue();

    return defer.promise;
  }


  _processSendQueue() {
    // Only send 1 request at a time.
    if(this.sending) {
      return;

    } else if(this.sendQueue.length) {
      this.sending = true;

      let item = this.sendQueue[0];
      let channel = item.channel;
      let hostMessage = item.hostMessage;
      let receivers = item.receivers;
      let message = item.message;
      let defer = item.defer;

      this.meetingReliableSocketService.send(channel, [hostMessage, receivers, message])
        .then(response => {
          defer.resolve(response);

        }, error => {
          logger.debug('BM rejected on channel "%s" with reason "%s":', channel, error, message);
          defer.reject(error);
        })

        .finally(() => {
          this.sendQueue.shift();
          this.sending = false;
          this._processSendQueue();
        });

      logger.debug('Sent BM on channel "%s" to %s:', channel, JSON.stringify(receivers), message);

    } else {
      this._processReceiveQueue();
    }
  }
}
