import UserService from './user.service.js';
import UserSyncService from './userSync.service.js';
import UserNotifyService from './userNotify.service.js';
import UserInfoService from './userInfo.service.js';
import MaxUsersNotificationService from './maxUsersNotification.service.js';
import DesktopNotificationService from './desktopNotification.service.js';
import DesktopNotificationComponent from './desktopNotification.component.js';

// ADR: https://teamleader.atlassian.net/wiki/spaces/VEC/pages/4303716590/User+Management

angular
  .module('meeting')
  .service('userService', UserService)
  .service('userSyncService', UserSyncService)
  .service('userNotifyService', UserNotifyService)
  .service('desktopNotificationService', DesktopNotificationService)
  .component('desktopNotification', DesktopNotificationComponent)
  .controller('DesktopNotificationCtrl', DesktopNotificationComponent.controller)
  .service('userInfoService', UserInfoService)
  .service('maxUsersNotificationService', MaxUsersNotificationService);
