// only used in greet.controller.js get plannedMeetingText
export default function ReadableDateFilter() {
  return function(dateTime, locale, prefix) {
    if(dateTime == null) {
      return;
    }

    if(prefix == null) {
      prefix = '';
    }

    if(locale == null) {
      locale = 'en-gb';
    }

    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    let date = dateTime.getDate();

    if(date === today.getDate()) {
      return gettextCatalog.getString('today');
    } else if(date === tomorrow.getDate()) {
      return gettextCatalog.getString('tomorrow');
    } else {
      return prefix + dateTime.toLocaleDateString(locale, {
        month: 'long',
        year:'numeric',
        day:'numeric'
      });
    }
  };
}
