import { errors, format, EventEmitter } from 'utils/util';
import PrivateMessageDispatcher from './PrivateMessageDispatcher';

export default class PrivateMessageService {
  static get $inject() {
    return [
      'meetingReliableSocketService',
      'userService',
    ];
  }

  constructor(
    meetingReliableSocketService,
    userService
  ) {
    this._bind();

    this.meetingReliableSocketService = meetingReliableSocketService;
    this.userService = userService;

    this.dispatchers = {};
    this.registeredChannels = new Set();

    this.eventEmitter = new EventEmitter([], true);
  }


  _bind() {
    this._onMessage = this._onMessage.bind(this);
  }


  /**
   * You can either listen on a channel and receive events from all other sessions, or
   * specify the remote session you are interested in.
   */
  on(channel, session, callback) {
    if(callback == null) {
      callback = session;
      session = null;
    }

    if(channel.indexOf(':') !== -1) {
      throw new errors.InvalidArgumentError(format(
        'Channel cannot contain semicolons, got `%s`', channel));
    }

    this._registerChannel(channel);

    let emitterChannel = PrivateMessageDispatcher.getEmitterChannel(channel, session);
    this.eventEmitter.on(emitterChannel, callback);
  }


  _registerChannel(channel) {
    if(!this.registeredChannels.has(channel)) {
      this.registeredChannels.add(channel);
      this.meetingReliableSocketService.on(channel, this._onMessage);
    }
  }


  _getDispatcher(session) {
    if(!this.dispatchers.hasOwnProperty(session.id)) {
      this.dispatchers[session.id] = new PrivateMessageDispatcher(
        this.meetingReliableSocketService,
        this.eventEmitter,
        session
      );
    }
    return this.dispatchers[session.id];
  }


  send(channel, session, ...message) {
    this._getDispatcher(session).send(channel, message);
  }

  _onMessage(channel, data) {
    let [sessionId, message] = data;
    let session = this.userService.getOrCreateSession(sessionId);
    this._getDispatcher(session).onMessage(channel, message);
  }



  sendUnreliable(channel, session, ...message) {
    this._getDispatcher(session).sendUnreliable(channel, message);
  }

  onUnreliableMessage(channel, session, ...message) {
    this._getDispatcher(session).onUnreliableMessage(channel, message);
  }

  setPeerConnection(session, peerConnection) {
    this._getDispatcher(session).setPeerConnection(peerConnection);
  }
}
