import StreamTileMixin from '../../main/streams/StreamTileMixin';
import Tile from '../tiles/Tile';


const DEFAULT_ASPECT_RATIO = 4 / 3;

const State = {
  EXITED: 'exited',
  KNOCKING: 'knocking',
  JOINING: 'joining',
  EXITING: 'exiting',
};


export default class KnockerTile extends StreamTileMixin(Tile) {
  static get State() {
    return State;
  }

  constructor(id, user, chatBox, ...args) {
    super(id, DEFAULT_ASPECT_RATIO, ...args);

    this._user = user;
    this.chatBox = chatBox;

    this.state = State.EXITED;

    this.snoozed = false;
  }

  get type() {
    return Tile.Type.KNOCKER;
  }
  get category() {
    return Tile.Category.PEOPLE;
  }

  get user() {
    return this._user;
  }

  setState(state) {
    this.state = state;
  }
}
