import template from './contentButtons.html?raw';
import templateDropdownAddContent from './addContent.dropdown.html?raw';
import templateDropdownUploadContent from './uploadContent.dropdown.html?raw';
import templateDropdownScreenshare from './screenshare.dropdown.html?raw';

import { Hint } from '../../../main/tutorial.service';
import { object } from 'utils/util';
import { AddImageType } from '../../../components/whiteboard/whiteboard.service';



const DROPDOWN_ADD_ID = 'addContent';
export const DROPDOWN_UPLOAD_ID = 'uploadContent';
const DROPDOWN_SCREENSHARE_ID = 'screenshare';


export class ContentButtonsController {
  static get $inject() {
    return [
      'whiteboardService',
      'filePickerService',
      'screenshareService',
      'cobrowseService',
      'dropdownService',
      'screenshareStreamService',
      'tutorialService',
      'meetingService',
    ];
  }

  constructor(
    whiteboardService,
    filePickerService,
    screenshareService,
    cobrowseService,
    dropdownService,
    screenshareStreamService,
    tutorialService,
    meetingService
  ) {
    dropdownService.register(
      DROPDOWN_ADD_ID,
      templateDropdownAddContent,
      { cssClasses: 'dropdown-deprecated--no-padding-x' });

    dropdownService.register(
      DROPDOWN_UPLOAD_ID,
      templateDropdownUploadContent,
      { cssClasses: 'dropdown-deprecated--no-padding-x', preRender: true });

    dropdownService.register(
      DROPDOWN_SCREENSHARE_ID,
      templateDropdownScreenshare,
      { cssClasses: 'dropdown-deprecated--no-padding-x' });


    this.whiteboardService = whiteboardService;
    this.filePickerService = filePickerService;
    this.screenshareService = screenshareService;
    this.cobrowseService = cobrowseService;
    this.dropdownService = dropdownService;
    this.screenshareStreamService = screenshareStreamService;
    this.tutorialService = tutorialService;
    this.meetingService = meetingService;
  }


  get ScreenType() {
    return this.screenshareStreamService.ScreenType;
  }


  toggleAddDropdown($event) {
    this.setContentHintSeen();
    this.dropdownService.toggle(DROPDOWN_ADD_ID, $event);
  }

  toggleUploadDropdown($event) {
    this.setContentHintSeen();
    this.dropdownService.toggle(DROPDOWN_UPLOAD_ID, $event);
  }


  get isSharingScreen() {
    return object.length(this.screenshareService.localTiles) > 0;
  }
  toggleShareScreen($event) {
    this.setContentHintSeen();
    if(this.dropdownService.get(DROPDOWN_SCREENSHARE_ID).active) {
      this.dropdownService.hide(DROPDOWN_SCREENSHARE_ID);
      return;
    }

    if(this.isSharingScreen) {
      this.screenshareService.remove();
    } else if(this.screenshareStreamService.supportsScreenType()) {
      this.dropdownService.toggle(DROPDOWN_SCREENSHARE_ID, $event);
    } else {
      this.screenshareService.add();
    }
  }


  get isCobrowsing() {
    return object.length(this.cobrowseService.localTiles) > 0;
  }
  toggleCobrowse() {
    this.setContentHintSeen();
    if(this.isCobrowsing) {
      this.cobrowseService.remove();
    } else {
      this.cobrowseService.add();
    }
  }

  addEmptyWhiteboard() {
    this.setContentHintSeen();
    this.whiteboardService.addEmpty();
  }


  setContentHintSeen() {
    this.tutorialService.setHintSeen(Hint.CONTENT);
  }

  get AddImageType() {
    return AddImageType;
  }

  pickDrive() {
    this.filePickerService.sendMessage(
      'pickDrive',
      { addImageType: AddImageType.NEW_WHITEBOARD }
    );
  }
  pickDropbox() {
    this.filePickerService.sendMessage(
      'pickDropbox',
      { addImageType: AddImageType.NEW_WHITEBOARD }
    );
  }
  pickOneDrive() {
    this.filePickerService.sendMessage(
      'pickOneDrive',
      { addImageType: AddImageType.NEW_WHITEBOARD }
    );
  }
}


export default {
  controller: ContentButtonsController,
  controllerAs: 'contentButtonsCtrl',
  template,

  bindings: {
    translucent: '<',
  },
};
