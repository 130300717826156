import { errors, format, EventEmitter, bind } from 'utils/util';
import { ReadyState } from './socket.service';


// This must be kept in sync with WebsocketConnection.js on the websocket server
export const MessageType = Object.freeze({
  MESSAGE: 'm',
  ACK: 'a',
});

export const State = Object.freeze({
  DISCONNECTED: 'disconnected',
  CONNECTED: 'connected',
});

export const BACKOFF_BASE = 2000;
export const BACKOFF_MULTIPLIER = 1.3;
export const BACKOFF_MAX = 15000;


export default class ReliableSocketService {
  static get $inject() {
    return [
      'requestUserService',
    ];
  }

  constructor(
    requestUserService,
    // Subclasses must inject an instance of socketService
    socketService
  ) {
    bind(this);
    EventEmitter.setup(this, [], true);

    this.requestUserService = requestUserService;
    this.socketService = socketService;

    this.state = State.DISCONNECTED;

    this.resendInterval;
    this.maxMessageId = 0;
    this.sentItems = {};
    this.receivedItems = new Set();

    this.socketService.on('open', this._onOpen);
    this.socketService.on('close', this._onClose);
    this.socketService.on('message', this._onMessage);
  }


  _onOpen() {
    this.receivedItems = new Set();
    this._updateState();
  }

  _onClose() {
    this._updateState();
  }


  _updateState() {
    let newState = this._getNewState();

    if(newState === this.state) {
      return;
    }
    this.state = newState;

    switch(this.state) {
      case State.DISCONNECTED:
        clearInterval(this.resendInterval);
        break;

      case State.CONNECTED:
        this.resendInterval = setInterval(this._resend, 1000);
        this._resetLastAttempt();
        this._resend();
        break;
    }
  }

  _getNewState() {
    return this.socketService.state === ReadyState.OPEN ? State.CONNECTED : State.DISCONNECTED;
  }


  _resetLastAttempt() {
    for(let messageId in this.sentItems) {
      let item = this.sentItems[messageId];
      item.lastAttempt = 0;
      item.backoff = BACKOFF_BASE;
    }
  }


  _resend() {
    let now = Date.now();

    for(let messageId in this.sentItems) {
      let item = this.sentItems[messageId];
      if(this._readyToSend(item) && item.lastAttempt + item.backoff < now) {
        this.socketService.send(item.data);
        item.lastAttempt = now;
        item.backoff = Math.min(item.backoff * BACKOFF_MULTIPLIER, BACKOFF_MAX);
      }
    }
  }

  cancel(promise) {
    let messageId = promise.__messageId;
    let sentItem = this.sentItems[messageId];
    if(sentItem) {
      sentItem.defer.reject('__cancelled');
      delete this.sentItems[messageId];
    }
  }


  _onMessage(data) {
    let type = data[0];
    let messageId = data[1];
    let message = data[3];

    let channel, accepted;
    switch(type) {
      case MessageType.MESSAGE:
        channel = data[2];
        this._receiveMessage(messageId, channel, message);
        break;

      case MessageType.ACK:
        accepted = data[2];
        this._receiveAck(messageId, accepted, message);
        break;

      default:
        throw new errors.InvalidArgumentError(format(
          'Unknown message type:', type));
    }
  }


  _receiveMessage(messageId, channel, message) {
    if(!this.receivedItems.has(messageId)) {
      this.receivedItems.add(messageId);
      this.emit(channel, channel, message);
    }

    this.socketService.send([MessageType.ACK, messageId, []]);
  }


  _receiveAck(messageId, accepted, message) {
    let item = this.sentItems[messageId];
    if(item) {
      let defer = item.defer;

      if(accepted) {
        defer.resolve(message);
      } else {
        defer.reject(message);
      }

      delete this.sentItems[messageId];
    }
  }


  get sessionId() {
    // Implemented on sub classes
    return null;
  }

  _readyToSend(item) {  // eslint-disable-line no-unused-vars
    // Implemented on sub classes
    return true;
  }


  send(channel, message, extra) {
    // messageId must be globally unique in case of private messages
    if(this.sessionId == null) {
      throw new errors.IllegalStateError(
        'Cannot create a unique id before the local session is initialized'
      );
    }
    let messageId = `${this.sessionId}-${++this.maxMessageId}`;
    let data = [MessageType.MESSAGE, messageId, channel, message];

    let item = {
      defer: $q.defer(),
      channel: channel,
      data: data,
      extra: extra,
      lastAttempt: 0,
      backoff: BACKOFF_BASE,
    };
    this.sentItems[messageId] = item;

    if(this._readyToSend(item)) {
      this.socketService.send(data);
      item.lastAttempt = Date.now();
    } else {
      console.info(
        `ReliableSocketService: not sending message because service is not ready.\
        (channel: ${channel})`
      );
    }

    return item.defer.promise;
  }
}
