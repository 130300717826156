import { EventEmitter } from 'utils/util';
import { default as JanusHandle, JanusError } from './JanusHandle';


export default class JanusMaster extends JanusHandle {
  constructor(janus, roomId, sessionId) {
    super(janus);

    EventEmitter.setup(this, ['addPublisher', 'removePublisher']);

    this.roomId = roomId;
    this.sessionId = sessionId;

    this.publishers = {};
  }

  _bind() {
    super._bind();
    this._createRoom = this._createRoom.bind(this);
  }


  get id() {
    return `${this.sessionId}:master`;
  }


  _postAttach() {
    super._postAttach();
    return this._createRoom().then(this._join);
  }


  _onMessage(message) {
    if(
      (message.videoroom === 'joined' || message.videoroom === 'event')
      && message.publishers
    ) {
      for(const publisher of message.publishers) {
        this._addPublisher(publisher);
      }

    } else if(
      message.videoroom
      && message.unpublished
      && this.publishers[message.unpublished]
    ) {
      const publisher = this.publishers[message.unpublished];
      this._removePublisher(publisher);
    }

    super._onMessage(message);
  }


  _createRoom() {
    return this.sendMessage({
      request: 'create',
      room: this.roomId,
      is_private: true,
      publishers: 100,
      videocodec: 'h264',
      h264_profile: '42e01f',
      videoorient_ext: false,  // See https://github.com/meetecho/janus-gateway/issues/1021.
      opus_dtx: false,
    })
      .catch(error => {
        // Ignore 'Room already exists' errors.
        if(
          error.constructor === JanusError
          && error.message.match('Room.*already exists')
        ) {
          return;
        }

        this._onError(error);
      });
  }


  _getJoinConfig() {
    const config = {
      ptype: 'publisher',
      id: this.id,
    };

    return Object.assign(super._getJoinConfig(), config);
  }



  /*********************
   * Manage publishers *
   *********************/

  _addPublisher(publisher) {
    if(this.publishers[publisher.id]) {
      return;
    }

    this.publishers[publisher.id] = publisher;
    this.emit('addPublisher', publisher);
  }

  _removePublisher(publisher) {
    if(!this.publishers[publisher.id]) {
      return;
    }

    delete this.publishers[publisher.id];
    this.emit('removePublisher', publisher);
  }
}
