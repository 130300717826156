/*
 To represent a waiting room session we use an object of this type:
 {
   session: <Django Session object>,
   suggestedMeeting:
      {
        id: optional -> empty if we need to create a new meeting
        key: required
        <other relevant fields>
      }
 }
*/

import { format, logger, dateTime, ColorManager, EventEmitter } from 'utils/util';
import Session from 'meeting/angularjs/main/users/Session';

const SLEEP_SESSION_DURATION = 15000;
const REDIRECT_SESSION_TIMEOUT_DURATION = 2500;
const STATE_READY = 'ready';

const colorManager = new ColorManager();

export default class WaitingRoomSessionsService {
  static get $inject() {
    return [
      'waitingRoomBroadcastService',
      'apiService',
      'requestUserService',
      'notificationService',
      'waitingRoomSocketService',
    ];
  }

  constructor(
    waitingRoomBroadcastService,
    apiService,
    requestUserService,
    notificationService,
    waitingRoomSocketService
  ) {
    this._bind();
    EventEmitter.setup(this, ['knock-join', 'session-exit']);

    this.waitingRoomBroadcastService = waitingRoomBroadcastService;
    this.apiService = apiService;
    this.requestUserService = requestUserService;
    this.notificationService = notificationService;
    this.waitingRoomSocketService = waitingRoomSocketService;

    this.waitingRoomBroadcastService.on('session-exit', this._onSessionExit);
    this.waitingRoomBroadcastService.on('session-join', this._onSessionJoin);
    this.waitingRoomBroadcastService.on('_waiting-room-knock-join', this._onKnockJoin);
    this.waitingRoomBroadcastService.on('_state', this._onState);
    this.waitingRoomSocketService.on('close', this._onSocketClose);

    this.requestUser = this.requestUserService.user;
    this.exitSessionTimeouts = {};
    this.knockingSessions = {};
    this.teamWaitingRoomEnabled = (
      this.requestUserService.user.organization.teamWaitingRoomEnabled
    );
    this.loadingSessions = true;
    this.populatePromise = null;

    $interval(this._updateSessionsTimeSinceKnock, 1000);
  }


  _bind() {
    this._onKnockJoin = this._onKnockJoin.bind(this);
    this._onSessionExit = this._onSessionExit.bind(this);
    this._onSessionJoin = this._onSessionJoin.bind(this);
    this._onSocketClose = this._onSocketClose.bind(this);
    this._onState = this._onState.bind(this);
    this._populateKnockingSessions = this._populateKnockingSessions.bind(this);
    this._updateSessionsTimeSinceKnock = this._updateSessionsTimeSinceKnock.bind(this);
  }


  /************
  * LISTENERS *
  ************/


  _onKnockJoin(waitingRoomSession) {
    this._knockJoinSession(waitingRoomSession);
  }


  _onSessionExit(channel, sessionId, timestamp, exitReason) {
    let waitingRoomSession = this.knockingSessions[sessionId];
    if(!waitingRoomSession) {
      return;
    }


    if(
      [Session.ExitReason.LOST, Session.ExitReason.REDIRECT].includes(exitReason)
      && !this.exitSessionTimeouts[waitingRoomSession.session.id]
    ) {

      let timeoutDuration = (
        exitReason === Session.ExitReason.LOST ?
          SLEEP_SESSION_DURATION :
          REDIRECT_SESSION_TIMEOUT_DURATION
      );

      this.exitSessionTimeouts[waitingRoomSession.session.id] = $timeout(
        this._exitSession.bind(this, waitingRoomSession.session.id), timeoutDuration
      );

      // If we are dealing with a redirect, we set a special flag on the session, to distinguish
      // between a normal exit and a redirect. This session will exit in 5 seconds.
      if(exitReason === Session.ExitReason.REDIRECT) {
        waitingRoomSession.redirected = true;
        colorManager.forsakeColorIndex(waitingRoomSession.colorIndex);
      }
    } else {
      this._exitSession(waitingRoomSession.session.id);
    }
  }


  _onSessionJoin(channel, sessionId) {
    this._exitSession(sessionId);
  }


  _onState(state) {
    if(state === STATE_READY) {
      this._populateKnockingSessions();
    }
  }

  // Stop trying to populate the knocking sessions. We will try again when the socket opens again.
  _onSocketClose() {
    if(this.populatePromise) {
      this.apiService.cancel(this.populatePromise);
      this.populatePromise = null;
    }
  }


  /************
  * SESSIONS *
  ************/

  _populateKnockingSessions() {
    // Don't populate again if we are still populating sessions
    if(this.populatePromise) {
      return;
    }

    this.populatePromise = this.apiService.get(
      'waitingRoomSessions/',
      { maxRetries: Infinity }
    );
    this.populatePromise.then(response => {
      response.data.forEach(waitingRoomSession => {
        this._knockJoinSession(waitingRoomSession);
      });
    }).catch(error => {
      logger.error(error);
      this.notificationService.error(
        gettextCatalog.getString('Something went wrong loading the page. Please try again.'),
        { delay: -1 }
      );
    }).finally(() => {
      this.loadingSessions = false;
      this.populatePromise = null;
    });
  }


  _knockJoinSession(waitingRoomSession) {
    $timeout.cancel(this.exitSessionTimeouts[waitingRoomSession.session.id]);
    delete this.exitSessionTimeouts[waitingRoomSession.session.id];

    // If the existing session is redirected, we set the redirected flag on the session
    let existingSession = this.knockingSessions[waitingRoomSession.session.id];
    if(existingSession && existingSession.redirected) {
      waitingRoomSession.redirected = true;
    }
    this._updateSessionInfo(waitingRoomSession);
    this._handleColors(waitingRoomSession, existingSession ? existingSession.color : null);

    this.knockingSessions[waitingRoomSession.session.id] = waitingRoomSession;
    this.emit('knock-join', waitingRoomSession);
  }


  _exitSession(sessionId) {
    let waitingRoomSession = this.knockingSessions[sessionId];
    if(waitingRoomSession) {
      colorManager.forsakeColorIndex(waitingRoomSession.colorIndex);
      delete this.knockingSessions[sessionId];
    }
    this.emit('session-exit', waitingRoomSession);
  }


  _updateSessionInfo(waitingRoomSession) {
    waitingRoomSession.dateKnocked = dateTime.stringToDate(waitingRoomSession.session.dateKnocked);

    // If we only have one property in the waitingRoomSession.suggestedMeeting, we know we
    // need to calculate some more properties
    if(Object.keys(waitingRoomSession.suggestedMeeting).length === 1) {
      waitingRoomSession.suggestedMeeting.openedUntil = '';
      waitingRoomSession.suggestedMeeting.isHost = true;
      waitingRoomSession.suggestedMeeting.ownerId = this.requestUserService.user.id;
    }

    waitingRoomSession.suggestedMeeting.openedUntil = dateTime.stringToDate(
      waitingRoomSession.suggestedMeeting.openedUntil
    );

    waitingRoomSession.isNewMeeting = waitingRoomSession.suggestedMeeting.id == null;
    waitingRoomSession.isPersonalMeeting = (
      waitingRoomSession.suggestedMeeting.ownerId = this.requestUser.id
      && !waitingRoomSession.session.meeting.isTeamWaitingRoom
    );

    waitingRoomSession.timeSinceKnock = Date.now() - waitingRoomSession.dateKnocked;
  }


  _handleColors(waitingRoomSession, existingColor) {
    if(existingColor) {
      waitingRoomSession.color = existingColor;
    } else {
      waitingRoomSession.colorIndex = colorManager.claimColorIndex();
      waitingRoomSession.color = colorManager.getColor(waitingRoomSession.colorIndex);
    }
  }


  _updateSessionsTimeSinceKnock() {
    Object.values(this.knockingSessions).forEach(waitingRoomSession => {
      waitingRoomSession.timeSinceKnock = Date.now() - waitingRoomSession.dateKnocked;
    });
  }


  get ownMeetingKnockingSessions() {
    return Object.values(this.knockingSessions)
      .filter(waitingRoomSession => waitingRoomSession.isPersonalMeeting);
  }


  get teamWaitingRoomSessions() {
    return Object.values(this.knockingSessions)
      .filter( waitingRoomSession => waitingRoomSession.session.meeting.isTeamWaitingRoom);
  }

  get knockingSessionsList() {
    return Object.values(this.knockingSessions);
  }

  /***********
   * ACTIONS *
   **********/

  performRedirect(meeting, waitingRoomSession) {
    let data = {
      meetingRoomId: meeting.id
    };
    return this.apiService.patch(
      format('sessions/%s/redirect/', waitingRoomSession.session.id),
      data
    );
  }

  denyAccess(waitingRoomSession) {
    return this.apiService.patch(
      format(
        'meetings/%s/users/%s/admit',
        waitingRoomSession.session.meetingId, waitingRoomSession.session.userId
      ),
      {
        admit: false,
      }
    );
  }
}
