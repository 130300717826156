Config.$inject = [
  '$sceDelegateProvider',
  '$compileProvider',
];

export default function Config(
  $sceDelegateProvider,
  $compileProvider
) {
  let debugInfoEnabled = ANGULAR_SCOPE.deployEnv !== 'prod';
  $compileProvider.debugInfoEnabled(debugInfoEnabled);

  // Allow urls from video and audio in ng-src
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    'blob:https://' + window.location.host + '/*',          // Chrome
    'blob:https%3A//' + window.location.host + '/*',        // Chromium
    'mediastream:https://' + window.location.host + '/*',   // Firefox
  ]);
}
