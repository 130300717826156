import { Component, OnInit } from '@angular/core';
import { bind } from 'utils/util';
import { UrlService } from 'utils/url.service';
import { SchedulingState } from 'scheduling/variables';
import {
  DashboardScope
} from 'dashboard/data-page/data-page.component';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { RequestUserService } from 'utils/requestUser.service';
import { StateService } from 'utils/state.service';
import { UrlQueryParamsService } from 'scheduling/manage/appointments/url-query-params.service';

type SchedulingActionTarget = '_self' | '_blank';
class SchedulingAction {
  constructor(
    public text: string,
    public url: string,
    public target: SchedulingActionTarget = '_self',
  ) { }
}

@Component({
  selector: 'appointments',
  templateUrl: './appointments.component.html',
  providers: [UrlQueryParamsService],
})
export class AppointmentsComponent implements OnInit {
  public scope = DashboardScope.PERSONAL;
  public scopes: Scope[] = [];

  constructor(
    public urlService: UrlService,
    public requestUserService: RequestUserService,
    public stateService: StateService,
    private urlQueryParamsService: UrlQueryParamsService
  ) {
    bind(this);
  }

  ngOnInit(): void {
    this.scopes = this.initialiseScopes();
    this.initializeQueryParams();
  }


  private initializeQueryParams(): void {
    const scope = this.urlQueryParamsService.queryParams.get('scope');
    if (scope) {
      this.scope = this.findScope(scope);
    }
  }

  private findScope(scopeId: string): Scope {
    return this.scopes.find((scope: Scope) => scope.id === scopeId) || DashboardScope.PERSONAL;
  }

  private initialiseScopes(): Scope[] {
    const scopes = [DashboardScope.PERSONAL];
    if (this.shouldShowTeamScope) {
      scopes.push(DashboardScope.TEAM);
    }
    return scopes;
  }

  public onScopeChange(scope: Scope): void {
    if (this.isDefaultScope(scope)) {
      this.urlQueryParamsService.removeQueryParam('scope');
    } else {
      this.urlQueryParamsService.setQueryParam('scope', scope.id);
    }
    this.scope = scope;
  }

  private isDefaultScope(scope: Scope): boolean {
    return scope === DashboardScope.PERSONAL;
  }

  public get shouldShowTeamScope() {
    return (
      !this.requestUserService.user.organization.isSolo
      && (this.requestUserService.user.isAdmin
        || this.requestUserService.user.organization.allowEditAppointments)
    );
  }

  get schedulingActions(): Record<SchedulingState, SchedulingAction> {
    return {
      new: new SchedulingAction(
        $localize`Get started: connect your calendar`,
        this.urlService.urls.connectScheduling,
      ),
      authenticated: new SchedulingAction(
        $localize`Authorize your calendar connection`,
        this.urlService.urls.connectScheduling,
      ),
      authorized: new SchedulingAction(
        $localize`Get started: set up your preferences`,
        this.urlService.urls.configureScheduling,
      ),
      configured: new SchedulingAction(
        $localize`Create your first meeting type`,
        this.urlService.urls.appointmentTypes,
      ),
      bookable: new SchedulingAction(
        $localize`Plan a meeting`,
        this.urlService.urls.planMeeting,
        '_blank',
      ),
      expired: new SchedulingAction(
        $localize`Reconnect your calendar`,
        this.urlService.urls.connectScheduling,
      ),
      disconnected: new SchedulingAction(
        $localize`Reconnect your calendar`,
        this.urlService.urls.connectScheduling,
      )
    };
  }

  get shouldShowTeamTeaser(): boolean {
    return (
      this.scope === DashboardScope.TEAM
      && this.requestUserService.user.organization.isSolo
    );
  }

  get schedulingAction(): SchedulingAction {
    const schedulingState: SchedulingState = this.requestUserService.user.schedulingConfig.state;
    return this.schedulingActions[schedulingState];
  }
}
