import fabric from './fabric';
import { errors, format } from 'utils/util';



export function normalizePath(path, offset) {
  let normalizedPath = [];

  for(let i = 0; i < path.length; i++) {
    let command = path[i];
    let commandType = command[0];

    let normalizedCommand = [commandType];
    normalizedPath.push(normalizedCommand);

    if(commandType === 'M' || commandType === 'L' || commandType === 'Q') {
      for(let j = 1; j < command.length; j++) {
        let normalized = command[j] - offset[(j - 1) % 2];
        normalizedCommand.push(Math.round(normalized * 10) / 10);
      }

    } else {
      throw new errors.InvalidArgumentError(format(
        'Unknown path command "%s" in path %s', commandType, path));
    }
  }

  return normalizedPath;
}


export function serializePath(pathArray) {
  // return pathArray;
  /**
   * Convert a fabric.js path in array format to SVG string format.
   */
  return pathArray
    .map(commandArray => commandArray[0] + commandArray.slice(1).join(','))
    .join('');
}

export function deserializePath(pathString) {
  // return pathString;
  /**
   * Convert a SVG string path to the fabric.js array format.
   */
  let match = pathString.match(/[A-Za-z][^A-Za-z]+/g) || [];
  return match.map(commandString => {
    let command = commandString[0];
    let params = commandString.slice(1).split(',').map(param => parseFloat(param));
    return [command, ...params];
  });
}



export function parseText(formattedText) {
  let text, stylesList;
  try {
    [text, stylesList] = parseTextBuildText(formattedText);
  } catch(e) {
    if(e.constructor === errors.InvalidArgumentError) {
      e.message = 'Invalid formatted text: ' + formattedText;
    }
    throw e;
  }

  let styles = parseTextBuildStyles(text, stylesList);
  return [text, styles];
}


function parseTextBuildText(formattedText) {
  let currentStyles = {};
  let text = '';
  let stylesList = [];

  let i = 0;
  while(i < formattedText.length) {
    let char = formattedText[i];

    if(char === '<') {
      let endI = formattedText.indexOf('>', i);
      if(endI === -1) {
        throw new errors.InvalidArgumentError();
      }
      let tagDefinition = formattedText.slice(i + 1, endI).split(':');
      i = endI;

      if(tagDefinition[0][0] !== '/') {
        let [tag, option] = tagDefinition;
        parseTextOpenTag(currentStyles, stylesList, text.length, tag, option);
      } else {
        let tag = tagDefinition[0].slice(1);
        parseTextCloseTag(currentStyles, stylesList, text.length, tag);
      }

    } else {
      if(char === '\\') {
        i++;
      }
      text += formattedText[i];
    }

    i++;
  }

  for(let tag in currentStyles) {
    parseTextCloseTag(currentStyles, stylesList, text.length, tag);
  }

  return [text, stylesList];
}


function getTextTagStyle(tag, extra) {
  switch(tag) {
    case 'fs': return { key: 'fontSize', value: extra * fabric.fontScaling };
    case 'c':  return { key: 'fill', value: extra };
    case 'b':  return { key: 'fontWeight', value: 'bold' };
    case 'i':  return { key: 'fontStyle', value: 'italic' };
    case 'u':  return { key: 'textDecoration', value: 'underline' };
    default:   return;
  }
}


function parseTextOpenTag(currentStyles, stylesList, textLength, tag, option) {
  if(currentStyles[tag]) {
    parseTextCloseTag(currentStyles, stylesList, textLength, tag);
  }

  let style = getTextTagStyle(tag, option);
  if(!style) {
    throw new errors.InvalidArgumentError();
  }

  style.start = textLength;
  currentStyles[tag] = style;
}


function parseTextCloseTag(currentStyles, stylesList, textLength, tag) {
  let style = currentStyles[tag];
  delete currentStyles[tag];

  if(style) {
    style.end = textLength;
    stylesList.push(style);
  }
}


function parseTextBuildStyles(text, stylesList) {
  // Format the styles in a way that fabric.js likes it
  let charStyles = {};
  for(let i = 0; i < stylesList.length; i++) {
    let style = stylesList[i];
    for(let char = style.start; char < style.end; char++) {
      if(!charStyles[char]) {
        charStyles[char] = {};
      }
      charStyles[char][style.key] = style.value;
    }
  }

  let styles = {};
  let lineI = 0;
  let charI = 0;
  for(let i = 0; i < text.length; i++) {
    if(text[i] === '\n') {
      lineI++;
      charI = 0;

    } else {
      if(charStyles[i]) {
        if(!styles[lineI]) {
          styles[lineI] = {};
        }
        styles[lineI][charI] = charStyles[i];
      }

      charI++;
    }
  }

  return styles;
}




export function buildText(text, globalStyle, styles) {
  let formattedText = '';

  let lineI = 0;
  let charI = 0;
  let prevStyle = fabric._defaultTextStyle;

  for(let i = 0; i < text.length; i++) {
    let part = text[i];
    while(i < text.length - 1 && part[part.length - 1] === '\n') {
      i++;
      part += text[i];
      lineI++;
      charI = 0;
    }
    part = part.replace(/([<>\\])/g, '\\$1');

    let charStyle = styles[lineI] && styles[lineI][charI] || {};
    let style = Object.assign({}, globalStyle, charStyle);

    let includeClosing = i > 0;
    formattedText += buildTextChar(prevStyle, style, part, includeClosing, true);

    prevStyle = style;
    charI++;
  }

  formattedText += buildTextChar(prevStyle, fabric._defaultTextStyle, '', true, false);
  return formattedText;
}

function buildTextChar(prevStyle, style, text, includeClosing, includeOpening) {
  let tags = '';
  for(let key in style) {
    let prevValue = prevStyle[key];
    let value = style[key];
    let defaultValue = fabric._defaultTextStyle[key];
    let equal = isEqualStyle(key, prevValue, value);

    if(includeClosing && prevValue !== defaultValue && !equal) {
      tags += getClosingTag(key);
    }
    if(includeOpening && value !== defaultValue && !equal) {
      tags += getOpeningTag(key, value);
    }
  }

  return tags + text;
}


function isEqualStyle(key, value1, value2) {
  switch(key) {
    case 'fontStyle': return (value1 === 'italic') === (value2 === 'italic');
    default: return value1 === value2;
  }
}

function getOpeningTag(key, value) {
  let tagDefinition = [getTagName(key)];
  switch(key) {
    case 'fontSize': tagDefinition.push(parseInt(Math.round(value / fabric.fontScaling))); break;
    case 'fill':    tagDefinition.push(value); break;
  }
  return '<' + tagDefinition.join(':') + '>';
}
function getClosingTag(key) {
  return '</' + getTagName(key) + '>';
}
function getTagName(key) {
  switch(key) {
    case 'fontSize':       return 'fs';
    case 'fill':           return 'c';
    case 'fontWeight':     return 'b';
    case 'fontStyle':      return 'i';
    case 'textDecoration': return 'u';
    default:   return;
  }
}
