import { Component, Inject } from '@angular/core';
import { AppointmentType, UserAppointmentType } from 'scheduling/models/AppointmentType';
import { RequestUserService } from 'utils/requestUser.service';
import { UrlService } from 'utils/url.service';
import { TimeDelta } from 'utils/util';
import { WizardStepComponent } from 'utils/wizard/WizardStep';
import { locationLabels } from 'scheduling/utils/location-badges';
import { AppointmentLocation } from 'scheduling/models/appointment-location';

type AppointmentType = {
  id: string,
  shortName: string,
  name: string,
  description: string,
  locations: AppointmentLocation[],
  duration: TimeDelta,
  selected: boolean,
}

@Component({
  selector: 'appointment-type-init',
  templateUrl: './appointment-type-init.component.html',
  styleUrls: ['./appointment-type-init.component.scss'],
  providers: [{ provide: WizardStepComponent, useExisting: AppointmentTypeInitComponent }]
})
export class AppointmentTypeInitComponent extends WizardStepComponent {

  public AppointmentLocation = AppointmentLocation;
  public locationLabels = locationLabels;

  public defaultAppointmentTypes: AppointmentType[] = [];

  public hasAppointmentTypes = false;

  constructor(
    @Inject('modelFactory') private modelFactory,
    @Inject('usageTrackingService') private usageTrackingService,

    private requestUserService: RequestUserService,
    public urlService: UrlService,
  ) {
    super();

    this._initAppointmentTypes();
  }

  get isFreeUser(): boolean {
    return this.requestUserService.user.organization.subscription.status === 'free';
  }


  get appointmentTypeUrl(): string {
    return this.urlService.urls.appointmentTypes;
  }

  _initAppointmentTypes() {
    this.defaultAppointmentTypes = [{
      id: '30m-online',
      shortName: $localize `30 minute online meeting`,
      name: $localize `30 minute online meeting with ${this.requestUserService.user.fullName}`,
      description: $localize `A 30 minute online meeting`,
      locations: [AppointmentLocation.ONLINE],
      duration: new TimeDelta({ minutes: 30 }),
      selected: true
    },
    {
      id: '1h-online-offline',
      shortName: $localize `1 hour meeting`,
      name: $localize `1 hour meeting with ${this.requestUserService.user.fullName}`,
      description: $localize `A 1 hour meeting`,
      locations: [AppointmentLocation.ONLINE, AppointmentLocation.OFFLINE],
      duration: new TimeDelta({ hours: 1 }),
      selected: false
    },
    {
      id: '1h-offline',
      shortName: $localize `1 hour meeting in person`,
      name: $localize `1 hour meeting in person with ${this.requestUserService.user.fullName}`,
      description: $localize `A 1 hour offline meeting`,
      locations: [AppointmentLocation.OFFLINE],
      duration: new TimeDelta({ hours: 1 }),
      selected: false
    }];

    this.modelFactory.list(
      {
        model: UserAppointmentType,
        identifiers: { userId: this.requestUserService.user.id }
      })
      .then(({ data: ats }) => {
        const hasAppointmentTypes = ats.length > 0;
        if(hasAppointmentTypes) {
          this.defaultAppointmentTypes.forEach(at => at.selected = false);
          this.hasAppointmentTypes = hasAppointmentTypes;
        }
      });
  }

  createAppointmentTypes(): Promise<any> {
    if(this.hasAppointmentTypes) {
      return Promise.resolve();
    }

    const promises = this.defaultAppointmentTypes
      .filter(at => at.selected)
      .map(at => {
        const modelInstance = this.modelFactory.createInstance(AppointmentType, {
          values: {
            name: at.name,
            description: at.description,
            userId: this.requestUserService.user.id,
            locations: at.locations,
            duration: at.duration,
          }
        });

        if(this.requestUserService.user.organization.hasActiveTeamleaderIntegration) {
          modelInstance.contactForm.createChildInstance('teamleaderMappingConfig');
        }

        return modelInstance.create();
      });

    return Promise.all(promises);
  }

  onCardClick(clickedAt) {
    if(this.hasAppointmentTypes) {
      return;
    }

    if(this.isFreeUser) {
      this.defaultAppointmentTypes
        .filter(at => at !== clickedAt)
        .forEach(at => at.selected = false);
    }
    clickedAt.selected = !clickedAt.selected;
  }

  override validate() {
    return this.hasAppointmentTypes
    || this.defaultAppointmentTypes.filter(at => at.selected).length > 0;
  }


  override handleNext() {
    return this.createAppointmentTypes()
      .then(() => {
        this.usageTrackingService.createSegmentEvent(
          'onboardingFlow.selectedAppointmentTypes',
          'onboardingFlow'
        );
      });
  }
}
