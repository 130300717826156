import { Component } from '@angular/core';
import { SchedulingState } from 'scheduling/variables';
import { RequestUserService } from 'utils/requestUser.service';
import { UrlService } from 'utils/url.service';
import {
  SchedulingOnboardingWizardService
} from '../scheduling-onboarding-wizard/scheduling-onboarding-wizard.service';

export const Template = Object.freeze({
  OVERVIEW: 'organizationOverviewDesktop',
});

@Component({
  selector: 'scheduling-onboarding',
  templateUrl: './scheduling-onboarding.component.html',
  styleUrls: ['./scheduling-onboarding.component.scss'],
})
export class SchedulingOnboardingComponent {
  constructor(
    public schedulingOnboardingWizardService: SchedulingOnboardingWizardService,
    private requestUserService: RequestUserService,
    private urlService: UrlService,
  ) {
    if(this.requestUserService.user.schedulingConfig.state !== SchedulingState.NEW) {
      this.schedulingOnboardingWizardService.continueWizard();
    }

    this.schedulingOnboardingWizardService.wizardFinished.subscribe(() => {
      window.location.reload();
    });
  }

  get welcomeImage() {
    return this.urlService.images.welcome;
  }
}
