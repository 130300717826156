import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleControlValueAccessor } from './simple-control-value-accessor';
import { Time } from 'utils/util';


@Directive({
  selector:
    'input[type=time][formControlName],'
    + 'input[type=time][formControl],'
    + 'input[type=time][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeValueAccessorDirective),
      multi: true
    }
  ],
})
export class TimeValueAccessorDirective extends SimpleControlValueAccessor<Time | null> {
  constructor(
    private elementRef: ElementRef,
  ) {
    super();
  }


  override writeValue(value: Time | null) {
    if(value) {
      this.elementRef.nativeElement.value = value.toRepresentation();
    } else {
      this.elementRef.nativeElement.value = '';
    }
    super.writeValue(value);
  }


  @HostListener('blur')
  onBlur() {
    const input = this.elementRef.nativeElement.value;
    let value;
    try {
      value = Time.toInternalValue(input);
    } catch(e) {
      value = null;
    }
    this.writeValue(value);

    this.onTouched();
  }
}
