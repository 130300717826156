import { bind, format, storage } from 'utils/util';

const REQUEST_PERMISSON_TIMEOUT = 7 * 24 * 60 * 60 * 1000;
const NO_NOTIFICATION_KEY = 'no_desktop_notifications';
const LAST_NOTIFICATION_KEY = 'last_notif_permission_request';

const RETRY_INTERVAL = 500;
const NUM_RETRIES = 6;

/* eslint-disable max-len */
const TEMPLATE_NOTIFICATION = `
<div ng-controller="DesktopNotificationCtrl as ctrl">
  <div class="mb-2" translate>
    Do you want to receive a notification when someone joins the meeting room or sends you a message?
  </div>
  <div class="d-flex justify-content-end">
    <span
      class="btn"
      ng-click="ctrl.desktopNotificationService.neverBtn()"
      translate
    >
      Never
    </span>
    <span
      class="btn ml-2"
      ng-click="ctrl.desktopNotificationService.remindLaterBtn()"
      translate
    >
      Not now
    </span>
    <span
      class="btn btn--primary ml-2"
      ng-click="ctrl.desktopNotificationService.requestPermissionBtn()"
      translate
    >
      Yes
    </span>
  </div>
</div>
`;
/* eslint-enable */


/**
 * this is different from "vectera notifications" (which are notifications within the vectera
 * webapp), desktop notifications are handled through the Notification Web API. This service
 * handles getting Notification API permission by requesting it through a vectera notification. It
 * also handles creating the desktop events when certain events are triggered.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/notification
 */
export default class DesktopNotificationService {
  static get $inject() {
    return [
      'focusService',
      'knockerService',
      'meetingBroadcastService',
      'meetingService',
      'notificationService',
      'requestUserService',
      'userService',
    ];
  }

  constructor(
    focusService,
    knockerService,
    meetingBroadcastService,
    meetingService,
    notificationService,
    requestUserService,
    userService
  ) {
    bind(this);

    this.focusService = focusService;
    this.knockerService = knockerService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;
    this.userService = userService;

    if(window.Notification) {
      meetingBroadcastService.afterInitialization().then(this._setupListeners);
    }
  }


  _setupListeners() {
    this._requestPermission();

    this.userService.on('userJoin', this._onUserJoin);
    this.userService.on('userKnockJoin', this._onUserKnock);
  }


  /* Vectera Notification handling */

  _requestPermission() {
    let noNotification = storage.getBooleanItem(NO_NOTIFICATION_KEY, false);
    let lastRequest = storage.getItem(LAST_NOTIFICATION_KEY);

    if(
      this.meetingService.isTemplate
      || !this.requestUserService.user.isAuthenticated
      || Notification.permission !== 'default'
      || noNotification
      || lastRequest != null && Date.now() - lastRequest < REQUEST_PERMISSON_TIMEOUT
    ) {
      return;
    }

    this.notification = this.notificationService.info(
      TEMPLATE_NOTIFICATION,
      { delay: 20000 }
    );
  }

  _hasPermission() {
    if(window.Notification) {
      return Notification.permission === 'granted';
    } else {
      return false;
    }
  }

  /**
   * When pressed, clean localStorage and request Notification API permission
   */
  requestPermissionBtn() {
    this._hideNotification();
    if(storage.getItem(LAST_NOTIFICATION_KEY) != null) {
      storage.removeItem(LAST_NOTIFICATION_KEY);
    }
    if(window.Notification) {
      window.Notification.requestPermission();
    }
  }

  /**
   * When pressed, store the current time in localstorage for tracking
   */
  remindLaterBtn() {
    this._hideNotification();
    storage.setItem(LAST_NOTIFICATION_KEY, Date.now());
  }

  /**
   * When pressed, store this preference in local storage
   */
  neverBtn() {
    this._hideNotification();
    storage.setBooleanItem(NO_NOTIFICATION_KEY, true);
  }

  /**
   * hide the currently active vectera notification
   */
  _hideNotification() {
    if(this.notification) {
      this.notification.cancel();
    }
  }


  /* Listeners */

  /**
   * handle the 'userJoin' event, adding a notification if prevUser is undefined?
   *
   * @param {User} user User object of the user that joined
   * @param {*} prevUser ??
   */
  _onUserJoin(user, prevUser) {
    if(prevUser || user.isMe) {
      return;
    }

    let titleTemplate = '%s has joined your online meeting';
    this._addNotification(user, titleTemplate);
  }

  /**
   * handle the 'userKnockJoin' event, adding a notification if a user wants to join the meeting
   *
   * @param {User} user User object of the user that joined
   */
  _onUserKnock(user) {
    let titleTemplate = '%s wants to join your online meeting';
    this._addNotification(user, titleTemplate);
  }


  /* Desktop Notification handling */

  /**
   * add a notification, but wait until the user has a fullName parameter OR the amount of retries
   * has exceeded NUM_RETRIES
   *
   * @param {User} user user data to fill into the notification
   * @param {String} titleTemplate a template string to insert into the notification
   * @param {number} retry counter for the amount of retries that have happened before
   */
  _addNotification(user, titleTemplate, retry) {
    if(!this._hasPermission() || this.focusService.hasFocus) {
      return;
    }
    if(retry == null) {
      retry = 0;
    }

    if(user.fullName || retry > NUM_RETRIES) {
      this._addNotificationAfterRetries(user, titleTemplate);
    } else {
      $timeout(() => this._addNotification(user, titleTemplate, retry++), RETRY_INTERVAL);
    }
  }

  /**
   * format notification text and add it, regardless of user info being given or not.
   *
   * @param {User} user user data to fill into the notification
   * @param {String} titleTemplate a template string to insert into the notification
   */
  _addNotificationAfterRetries(user, titleTemplate) {
    let userName = user.fullName || 'Someone';
    let title = format(titleTemplate, userName);

    try {
      this._addNotificationWithConstructor(title);

    } catch(error) {
      // `new Notification()` is not allowed on mobile Chrome, this raises a TypeError: "Failed to
      // construct 'Notification': Illegal constructor. Use
      // ServiceWorkerRegistration.showNotification() instead."
      // We don't support mobile notifications for now, but we'll add them in the future.
      if(error.name !== 'TypeError') {
        throw error;
      }
    }
  }

  _addNotificationWithConstructor(title) {
    if(window.Notification) {
      let notification = new window.Notification(title, {
        icon: window.URLS.images.icon,
      });
      notification.addEventListener('click', this._onNotificationClick);
    }
  }


  _onNotificationClick(event) {
    this.knockerService.disableNotification();
    let notification = event.target;
    notification.close();
    window.focus();
  }
}
