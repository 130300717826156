import DropdownService from './dropdown.service';
import DropdownsComponent from './dropdowns.component';
import DropdownComponent from './dropdown.component';
import DropdownSubmenuWrapperDirective from './dropdownSubmenuWrapper.directive';

angular
  .module('vecteraUtil')
  .service('dropdownService', DropdownService)
  .component('dropdownsDeprecated', DropdownsComponent)
  .component('dropdownDeprecated', DropdownComponent)
  .directive('dropdownSubmenuWrapper', DropdownSubmenuWrapperDirective);
