<div class="dropdown dropdown--scrollable" cdkMenu cdkTrapFocus [cdkTrapFocusAutoCapture]="true" style="width: 400px;" data-testid="meetingMenuDropdown">
  <div class="dropdown__list-item disabled border-bottom">
    <span *ngIf="scope === DashboardScope.PERSONAL" i18n>Your meeting rooms</span>
    <span *ngIf="scope === DashboardScope.TEAM" i18n>Your team's meeting rooms</span>
  </div>


  <div class="dropdown__list-item disabled text-default border-bottom">
    <div class="flex-1 position-relative">
      <!-- [stopPropagation]="'keydown'" is used to prevent the cdkMenu typeahead functionality -->
      <input
        class="input input--with-icon"
        [(ngModel)]="query"
        placeholder="Search"
        i18n-placeholder
        cdkFocusInitial
        [stopPropagation]="'keydown'"
        data-testid="searchMeetingInput"
      />
      <svg-icon class="input__icon" [svgName]="'search'"></svg-icon>
    </div>
  </div>


  <ng-container *ngIf="!stateService.isReady">
    <div class="dropdown__list-item disabled justify-content-center">
      <loading [timeout]="2000"></loading>
    </div>
  </ng-container>

  <ng-container *ngIf="stateService.isReady && meetingRooms.length === 0">
    <div class="dropdown__list-item disabled border-bottom" i18n data-testid="noMeetingsFoundText">
      No meeting rooms found
    </div>
    <div class="dropdown__list-item disabled d-flex">
      <div class="mr-16" i18n>Create one?</div>
      <div
        class="btn btn--sm btn--outline-primary ml-auto"
        cdkMenuItem
        [modalTriggerFor]="addRoom"
        [modalIcon]="{name: 'add'}"
        [modalClass]="'modal--sm'"
        modalTitle="Add a new room"
        i18n-modalTitle
      >
        <svg-icon class="btn__icon" [svgName]="'add'" [svgSize]="14"></svg-icon>
        <div class="btn__label" data-testid="addNewMeetingRoom" i18n>Add a new room</div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="stateService.isReady && meetingRooms.length > 0">
    <div data-testid="meetingRoomList">
      <a
        *ngFor="let meetingRoom of meetingRooms"
        class="dropdown__list-item border-bottom text-h3"
        (click)="meetingRoomSelected.emit(meetingRoom)"
        target="_blank"
        data-testid="meetingRoomSearchResult"
        cdkMenuItem
      >
        <div class="mr-4 min-w-0 text-break">{{ meetingRoom.key }}</div>
        <div class="ml-auto">
          <profile-images [users]="meetingRoom.activeUsers"></profile-images>
        </div>
      </a>
    </div>

    <div class="dropdown__list-item disabled d-flex">
      <div class="mr-16" i18n>None of these?</div>
      <div
        class="btn btn--sm btn--outline-primary ml-auto"
        cdkMenuItem
        [modalTriggerFor]="addRoom"
        [modalIcon]="{name: 'add'}"
        [modalClass]="'modal--sm'"
        modalTitle="Add a new room"
        i18n-modalTitle
      >
        <svg-icon class="btn__icon" [svgName]="'add'" [svgSize]="14"></svg-icon>
        <div class="btn__label" i18n>Add a new room</div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #addRoom>
  <add-room-modal (meetingRoomCreated)="meetingRoomSelected.emit($event)"></add-room-modal>
</ng-template>
