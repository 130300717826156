import { Component, Inject } from '@angular/core';
import { State as RecorderState } from 'meeting/angularjs/components/recording/Recorder';
import { SmartSummaryService } from '../../../../smart-summary/smart-summary.service';


@Component({
  selector: 'notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss'],
})
export class NotesModalComponent {
  private showDuringRecording = false;

  constructor(
    private smartSummaryService: SmartSummaryService,
    @Inject('recorderService') private recorderService,
  ) {
  }

  public get canUseSmartSummary(): boolean {
    return this.smartSummaryService.canUseSmartSummary;
  }

  public get showNotes(): boolean {
    return this.recorderService.state !== RecorderState.RECORDING || this.showDuringRecording;
  }

  public setShowDuringRecording(): void {
    this.showDuringRecording = true;
  }
}
