import { Component, Input } from '@angular/core';
import { array, bind } from 'utils/util';
import { ErrorValue } from '../settings.component';

/**
 * Render errors for a form field. The output of this is similar to what Django shows below
 * a form field (see forms.html).
 *
 * Basic usage:
 *
 * ```
 * ---my.component.ts
 * class MyComponent {
 *   public errors = ['This field is required'];
 * }
 * ---my.component.html
 * <input />
 * <settings-errors settingsErrors="errors"></settings-errors>
 * ```
 *
 * In most cases, you will not use this component directly but use WithSettingsErrorsDirective.
 * instead.
 */
@Component({
  selector: 'settings-errors[settingsErrors]',
  templateUrl: './settings-errors.component.html',
})
export class SettingsErrorsComponent {
  @Input('settingsErrors') errors?: ErrorValue;
  @Input() settingsErrorsListClass = 'errorlist';
  @Input() settingsErrorsItemClass = 'error';

  constructor() {
    bind(this);
  }

  get errorList(): string[] {
    return this.errors ? this.buildErrorList(this.errors) : [];
  }

  buildErrorList(errors: ErrorValue): string[] {
    if(Array.isArray(errors) && typeof errors[0] === 'string') {
      return errors as string[];
    } else {
      // Because `Object.values(array) === array`, this works both for
      // { [field: string]: ErrorValue } and { [field: string]: ErrorValue }[].
      const listOfErrorLists = Object.values(errors).map(this.buildErrorList);
      return array.flat(listOfErrorLists);
    }
  }
}
