import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { format } from 'utils/util';

type Format ={
  divider: number,
  singular: string,
  plural: string
}

@Pipe({ name: 'timeSince' })
export class TimeSincePipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}

  private readonly formats: Format[] = [
    {
      divider: 0,
      singular: $localize `just now`,
      plural: $localize `just now`,
    },
    {
      divider: 60,
      singular: $localize `%s minute ago`,
      plural: $localize `%s minutes ago`,
    },
    {
      divider: 60 * 60,
      singular: $localize `%s hour ago`,
      plural: $localize `%s hours ago`,
    },
    {
      divider: 60 * 60 * 24,
      singular: $localize `%s day ago`,
      plural: $localize `%s days ago`,
    },
    {
      divider: 60 * 60 * 24 * 30,
      singular: $localize `%s month ago`,
      plural: $localize `%s months ago`,
    },
    {
      divider: 60 * 60 * 24 * 365,
      singular: $localize `%s year ago`,
      plural: $localize `%s years ago`,
    },
  ];


  transform(value): string {
    if(!value) {
      return $localize `Never`;
    }

    const diff = (Date.now() - value) / 1000;

    for(let i = this.formats.length - 1; i >= 0; i--) {
      const formatDef = this.formats[i];
      const displayDiff = Math.floor(diff / formatDef.divider);
      if(displayDiff === 0) {
        continue;
      }

      const formatStr = displayDiff === 1 ? formatDef.singular : formatDef.plural;
      const str = formatStr.includes('%s') ? format(formatStr, displayDiff) : formatStr;
      return str;
    }
    return $localize `Some time ago`;
  }
}
