<div
  *ngFor="let option of options; let index = index; trackBy: trackByOption"
  class="radio__wrapper"
>
  <input
    type="radio"
    class="radio"
    [name]="question.id"
    [id]="question.id + '.' + index"
    [checked]="!otherOptionSelected && option[0] === value"
    (change)="onOptionChange($event, option)"
  />
  <label
    class="radio__label"
    [for]="question.id + '.' + index"
  >
    {{ option[1] }}
  </label>
</div>

<div
  *ngIf="otherOption"
  class="radio__wrapper mt--2"
>
  <input
    type="radio"
    class="radio"
    [name]="question.id"
    [id]="question.id + '.other'"
    [checked]="otherOptionSelected"
    (change)="onOtherOptionChange($event)"
  />
  <label
    class="radio__label radio__label--input"
    [for]="question.id + '.other'"
  >
    <input
      class="input text-teal-500"
      [ngModel]="otherOptionValue"
      (ngModelChange)="setOtherOptionValue($event)"
      placeholder="Other"
      i18n-placeholder
    />
  </label>
</div>
