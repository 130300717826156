import { browser, logger } from 'utils/util';


const DEFAULT_OPTIONS = Object.freeze({
  loop: false,
  ignoreErrors: false,
  // Fetching an audio resource is often not allowed in an inactive tab. By prefetching the
  // resource, you can still play it in an inactive tab (eg. useful for notification sounds).
  prefetch: false,
});


export default class Sound {
  constructor(mediaDeviceService, url, options = {}) {
    this.mediaDeviceService = mediaDeviceService;
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    this.audio = null;

    this.playStopPromise = $q.resolve();

    this.setUrl(url);
  }


  get currentTime() {
    this._load();
    return this.audio.currentTime;
  }
  get duration() {
    this._load();
    return this.audio.duration;
  }


  setUrl(url) {
    this.url = url || undefined;

    if(this.audio) {
      this.audio.src = url;
    } else if(this.options.prefetch) {
      this._load();
    }
  }


  play() {
    this._load();

    this.playStopPromise = this.playStopPromise
      .then(() => {
        let output = this.mediaDeviceService.preferredAudioOutput;
        if(
          browser.supportsOutputDevice()
          && this.audio.setSinkId
          && output
          && output.id
        ) {
          return this.audio.setSinkId(output.id);
        }
      })
      .catch(error => {
        if(error.message !== 'No permission to use requested device') {
          logger.warn(error);
        }
      })
      .then(() => {
        return this.audio.play();
      })
      .catch(error => {
        if(!(
          this.options.ignoreErrors
          || error.name === 'NotAllowedError'
          || error.name === 'AbortError'
          || error.name === 'NotSupportedError'
        )) {
          logger.warn(error.message);
        }
      });

    return this.playStopPromise;
  }


  stop() {
    this.playStopPromise = this.playStopPromise
      .then(() => {
        if(!this.audio) {
          return;
        }

        this.audio.pause();
        this.audio.currentTime = 0;
      });

    return this.playStopPromise;
  }


  _load() {
    if(this.audio) {
      return;
    }

    try {
      this.audio = new Audio(this.url);
      this.audio.loop = this.options.loop;

    } catch(error) {
      if(!(
        this.options.ignoreErrors
        || error.message === 'Not implemented'
      )) {
        throw error;
      }
      this.audio = {
        play: angular.noop,
        pause: angular.noop,
        currentTime: 0,
        duration: 0,
      };
    }
  }
}
