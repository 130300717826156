import UserTile from './UserTile';
import { format, bind } from 'utils/util';
import { StreamType } from  'meeting/meeting-room/stream';
import Session from '../../main/users/Session';



export default class UserTileService {
  static get $inject() {
    return [
      'meetingBroadcastService',
      'userService',
      'streamService',
      'tileService',
      'tileFactory',
    ];
  }

  constructor(
    meetingBroadcastService,
    userService,
    streamService,
    tileService,
    tileFactory
  ) {
    bind(this);

    this.userService = userService;
    this.streamService = streamService;
    this.tileService = tileService;
    this.tileFactory = tileFactory;

    this.tiles = {};

    meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);
  }


  _afterBroadcastInitialization() {
    this.userService.on('userJoin', this._onUserJoin);
    this.userService.on('userExit userKnockJoin', this._removeUser);
    this.streamService.on('add', this._addStream);
    this.streamService.on('remove', this._removeStream);

    this.userService.joined.forEach(this._onUserJoin);
  }


  _getTileId(user) {
    return format('user-%s', user.id);
  }


  _onUserJoin(user) {
    if(!user.isMe) {
      this._addUser(user);
    }
    Object.values(this.streamService.getFromUser(user)).forEach(this._addStream);
  }


  _addUser(user) {
    if(user.id in this.tiles || !user.isAlive()) {
      return;
    }

    let tile = this.tileFactory.create(UserTile, this._getTileId(user), user);
    this.tiles[user.id] = tile;
    this.tileService.add(tile);
  }


  _removeUser(user) {
    let tile = this.tiles[user.id];
    if(!tile) {
      return;
    }

    delete this.tiles[user.id];
    this.tileService.remove(tile);
  }


  _addStream(stream) {
    if(stream.user.isMe && !stream.isLocal && stream.session.state === Session.State.KNOCKING) {
      return;
    }

    if(stream.user.isMe && stream.type === StreamType.VIDEO) {
      this._addUser(stream.user);
    }

    let tile = this.tiles[stream.user.id];
    if(!tile) {
      return;
    }

    if(stream.type === StreamType.VIDEO) {
      tile.setVideo(stream);
    } else if(stream.type === StreamType.AUDIO && !stream.user.isMe) {
      tile.setAudio(stream);
    }
  }

  _removeStream(stream) {
    let tile = this.tiles[stream.user.id];
    if(!tile) {
      return;
    }

    if(tile.streams.video === stream) {
      tile.removeVideo();
      if(stream.user.isMe) {
        this._removeUser(stream.user);
      }

    } else if(tile.streams.audio === stream) {
      tile.removeAudio();
    }

    // Check if there were any other stream for this user
    Object.values(this.streamService.getFromUser(stream.user))
      .filter(newStream => newStream.type === stream.type)
      .forEach(this._addStream);
  }
}
