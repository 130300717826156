import { Rect, DragListener } from 'utils/util';


export default function WhiteboardTileScrollbarDirective() {

  return {
    restrict: 'C',

    link: function($scope, $elem, $attr) {
      let horizontal = $elem.hasClass('whiteboard-tile__scrollbar--horizontal');
      let $elemThumb = $elem.find('.whiteboard-tile__scrollbar-thumb');

      let whiteboard = null;
      let startFocus;
      let dragListener;


      $scope.$watch($attr.tile, tile => {
        destroy();
        if(tile) {
          init(tile.whiteboard);
        }
      });


      function init(newWhiteboard) {
        whiteboard = newWhiteboard;
        whiteboard.on('draw', draw);
        draw();

        let $elemThumb = $elem.find('.whiteboard-tile__scrollbar-thumb');
        dragListener = new DragListener($elemThumb[0], {
          onStart: onStartDrag,
          onDrag: onDrag,
        });

        $elem.on('mousedown', onMouseDown);
      }


      function destroy() {
        if(!whiteboard) {
          return;
        }

        whiteboard.off('draw', draw);
        whiteboard = null;

        dragListener.disable();
        dragListener = null;

        $elem.off('mousedown', onMouseDown);
      }

      /**
       * draw the scrollbars by calculating css dimensions from whiteboard variables
       */
      function draw() {
        let bounds = whiteboard.rectContentRelative;
        if(horizontal) {
          // at 100% zoom, the scrollbar thumbnail should be half the size of the scrollbar (0.5)
          // at 500% zoom, it should be one sixth (0.166)
          let thumbSize = 1 /  (1 + whiteboard.zoomLevel);

          // location of the thumbnail should correspond to the relative location of
          // contentFocus within the content bound range:
          let scrollRatio =  (whiteboard.contentFocus.x - bounds.left) / bounds.width;
          // relative offset of the thumbnail is equal to the "remaining whitespace" in the
          // scrollbar, multiplied with the relative location of focus within content
          let thumbOffset = (1 - thumbSize) * scrollRatio;

          $elemThumb.css({
            left:  (100 * thumbOffset) + '%',
            width: (100 * thumbSize  ) + '%',
          });
        } else { // vertical
          let thumbSize =  1 /  (1 + whiteboard.zoomLevel);
          let scrollRatio =  (whiteboard.contentFocus.y - bounds.top) / bounds.height;
          let thumbOffset = (1 - thumbSize) * scrollRatio;

          $elemThumb.css({
            top:    (100 * thumbOffset) + '%',
            height: (100 * thumbSize  ) + '%',
          });
        }
      }


      /**
       * Clicking anywhere in the scrollbar rect should immediately pan the whiteboard to the
       * desired location, while also starting the dragListener
       *
       * @param {MouseEvent} $event
       */
      function onMouseDown($event) {
        if($event.target !== $elem[0]) {
          return;
        }

        let rect = Rect.fromElem($elem[0]);
        let rectThumb = Rect.fromElem($elemThumb[0]);
        let newContentFocus;
        let bounds = whiteboard.rectContentRelative;

        if(horizontal) {
          // event.pageX is in pixel distance to the left edge of the window,
          // subtracting left offset of the tile and half the scrollbar thumb width
          // results in the pixel distance from tile left edge to the midpoint of
          // the scrollbar thumb
          let clickLocation = $event.pageY - rect.top - rectThumb.height / 2;

          // the center of the scrollbar thumb can only be located in the part of the scrollbar
          // that is at least half the width of the scrollbar away from the edge.
          let thumbRatio = clickLocation / (rect.height - rectThumb.height);

          newContentFocus = {
            x: thumbRatio * bounds.width,
            y: whiteboard.contentFocus.y
          };
        } else {
          let clickLocation = $event.pageY - rect.top - rectThumb.height / 2;
          let thumbRatio = clickLocation / (rect.height - rectThumb.height);

          newContentFocus = {
            x: whiteboard.contentFocus.x,
            y: thumbRatio * bounds.height,
          };
        }

        whiteboard.setViewport(newContentFocus, whiteboard.zoomLevel, true);
        dragListener._onMouseDown($event);
      }

      /**
       * when starting a drag, store the initial contentFocus so it can be compared to during drag
       */
      function onStartDrag() {
        startFocus = Object.assign({}, whiteboard.contentFocus);
      }

      function onDrag(move) {
        let rect = Rect.fromElem($elem[0]);
        let rectThumb = Rect.fromElem($elemThumb[0]);
        let newContentFocus;

        if(horizontal) {
          let scrollRatio = move[0].x / (rect.width - rectThumb.width);

          newContentFocus = {
            x: startFocus.x + (scrollRatio * whiteboard.rectContentRelative.width),
            y: whiteboard.contentFocus.y
          };
        } else {
          let scrollRatio = move[0].y / (rect.height - rectThumb.height);

          newContentFocus = {
            x: whiteboard.contentFocus.x,
            y: startFocus.y + (scrollRatio * whiteboard.rectContentRelative.height)
          };
        }

        whiteboard.setViewport(newContentFocus, whiteboard.zoomLevel, true);
      }
    },
  };
}
