import template from './feedback.html?raw';

class FeedbackController {
  static get $inject() {
    return [
      '$element',
      'feedbackService',
      'meetingEndedService',
      'notificationService',
    ];
  }

  constructor(
    $elem,
    feedbackService,
    meetingEndedService,
    notificationService
  ) {
    this.$elem = $elem;
    this.feedbackService = feedbackService;
    this.meetingEndedService = meetingEndedService;
    this.notificationService = notificationService;

    this.showMessage = false;

    this.stars = $elem.find('.feedback__star');
    this.stars.each((i, star) => {
      star = angular.element(star);
      star.on('mouseenter', this.setRatingHover.bind(this, i + 1));
      star.on('mouseleave', this.setRatingHover.bind(this, 0));
      star.on('click', this.setRating.bind(this, i + 1));
    });

    this.message = this.feedbackService.message;
    if(this.feedbackService.rating !== 0) {
      this.setRating(this.feedbackService.rating);
    }
  }


  get rating() {
    return this.feedbackService.rating;
  }

  setRatingHover(rating) {
    this.stars.each((i, star) => {
      star = angular.element(star);
      star.toggleClass('hover', i < rating);
    });
  }


  setRating(rating) {
    this.feedbackService.setRating(rating);
    this.showMessage = true;

    this.stars.each((i, star) => {
      star = angular.element(star);
      star.toggleClass('selected', i < this.rating);
    });
  }


  setMessage() {
    this.feedbackService.setMessage(this.message);
  }
}

export default {
  controller: FeedbackController,
  controllerAs: 'feedbackCtrl',
  template,
};
