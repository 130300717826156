<div
  *ngIf="integrations.length > 0"
  class="btn btn--primary"
  (click)="syncService.canSaveNotes() && syncService.saveNotes()"
  [ngClass]="{ 'disabled': !syncService.canSaveNotes() }"
  matTooltip="Save to {{ integrations | formatStringArray }}"
  i18n-matTooltip
  [matTooltipDisabled]="integrations.length <= 1"
>
  <div *ngIf="[SyncState.INITIAL, SyncState.DIRTY].includes(state)">
    <span *ngIf="integrations.length > 1" i18n>
      Save to integrations
    </span>
    <span *ngIf="integrations.length === 1" i18n data-testid="syncNotesButton">
      Save to {{ integrations[0] }}
    </span>
  </div>
  <div *ngIf="state === SyncState.SYNCING">
    <div class="d-flex align-items-center">
      <span i18n>Saving...</span>
      <loading class="ml-2"></loading>
    </div>
  </div>
  <div *ngIf="state === SyncState.SYNCED" class="d-flex align-items-center">
    <span i18n>Saved</span>
    <svg-icon class="icon ml-2" svgName="checkmark"></svg-icon>
  </div>
</div>
