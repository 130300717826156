import ViewportTileMixin from '../videoViewport/ViewportTileMixin';
import ContentTile from '../content/ContentTile';
import Tile from '../tiles/Tile';


const DEFAULT_ASPECT_RATIO = 16 / 9;


export default class ScreenTile extends ViewportTileMixin(ContentTile) {
  constructor(id, stream, ...args) {
    super(id, DEFAULT_ASPECT_RATIO, ...args);

    this.setVideo(stream);
  }

  get type() {
    return Tile.Type.SCREEN;
  }

  get name() {
    let user = this.session.user;
    if(user.isMe) {
      return gettextCatalog.getString('Your screen');
    } else {
      return gettextCatalog.getString(
        '{{ username }}\'s screen',
        { username: user.shortName }
      );
    }
  }
}
