import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { FormElementsComponent } from './form-elements.component';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    UtilsModule,
  ],
  declarations: [
    FormElementsComponent,
  ],
  exports: [
    FormElementsComponent,
  ],
})
export class FormElementsModule {}
