import { User } from 'core/models/User';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestUserService {
  static get $inject() {
    return [
      'modelFactory',
    ];
  }

  public user: User;

  constructor(
    @Inject('modelFactory') private modelFactory,
  ) {
    this.modelFactory = modelFactory;

    if(REQUEST_USER) {
      this.user = this.modelFactory.createInstance(User, { data: REQUEST_USER });
    } else {
      this.user = null;
      const apiConfig = {
        params: {
          'fields': 'all',
        },
      };
      this.modelFactory.read({ model: User, identifiers: { id: 'me' } }, apiConfig)
        .then(({ data: user }) => this.user = user);
    }
  }

  get isFree(): boolean {
    return this.user.organization.subscription.status === 'free';
  }
}
