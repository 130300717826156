import { logger, errors, bind, format, array, EventEmitter } from 'utils/util';
import VecteraFile from '../files/VecteraFile';


export const Status = Object.freeze({
  INACTIVE: 'inactive',
  DOWNLOADING: 'downloading',
  RENDERING: 'rendering',
});

const RenderType = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  SNAPSHOT: 'snapshot',
  CACHE: 'cache',
});

const CACHE_PX_WIDTH = 500;

export default class DocumentRenderer {
  static get Status() {
    return Status;
  }


  constructor(notificationService, pdfjsService, whiteboard) {
    bind(this);

    this.notificationService = notificationService;
    this.pdfjsService = pdfjsService;
    this.whiteboard = whiteboard;

    EventEmitter.setup(this, ['status']);

    this.pages = {};
    this.page = null;
    this.active = false;

    this.file = null;
    this.pdf = null;

    this.status = Status.INACTIVE;

    this.$elemTile = null;
    this.$elemCanvas = null;
    this.$elemInactiveImage = null;
    this.activeRenderInfo = null;

    // Cache stores a rendered png image of the entire canvas and includes the canvas
    // parameters at time of rendering.
    this.cache = {};
    this.cachePage = null;
    this.$elemCacheImage = null;

    this.renderId = 0;
    this.renderIdCancellable = 0;
    this.renderPromise = $q.resolve();
    this.cancelCurrentRender = null;
  }


  setElem($elemTile) {
    this.$elemTile = $elemTile;

    let $elemActive = $elemTile.find('.tile:not(.tile--inactive) .whiteboard-tile__document');
    this.$elemCacheImage = angular.element('<img style="position: absolute;" />');
    $elemActive.append(this.$elemCacheImage);

    this.$elemCanvas = angular.element('<canvas style="position: absolute;"></canvas>');
    $elemActive.append(this.$elemCanvas);

    let $elemInactive = $elemTile.find(
      '.content-tile__inactive-wrapper .whiteboard-tile__document');
    this.$elemInactiveImage = angular.element('<img style="width: 100%" />');
    $elemInactive.append(this.$elemInactiveImage);

    this.active ? this._renderActive() : this._renderInactive();
  }


  _setStatus(status) {
    if(status !== this.status) {
      this.status = status;
      this.emit('status', status);
    }
  }


  setActive(active) {
    this.active = active;
  }


  updateViewport() {
    this._transformCanvas();
    this.active ? this._renderActive() : this._renderInactive();
  }


  setFile(vecteraFile, pages) {
    this.file = vecteraFile;

    let numPages = pages.length;
    for(let i = 0; i < numPages; i++) {
      this.pages[pages[i].id] = i + 1;
    }

    this.active ? this._renderActive() : this._renderInactive();
  }


  download() {
    if(!this.file || this.pdf || this.status === Status.DOWNLOADING) {
      return $q.resolve();
    }

    this._setStatus(Status.DOWNLOADING);

    return this.file.getLocalUrl(VecteraFile.ConvertedToPdf.TRUE, false)
      .then(url => {
        return this.pdfjsService.getDocument(url);
      })
      .then(pdf => {
        this._log('loaded in PDF.JS');
        this.pdf = pdf;
      })
      .finally(() => {
        this._setStatus(Status.INACTIVE);
      });
  }


  get downloadProgress() {
    return this.file ?
      this.file.downloadProgress[VecteraFile.ConvertedToPdf.TRUE] :
      0;
  }



  addPage(page) {
    this.pages[page.id] = null;
  }

  setPage(page) {
    this.page = page;
    this._clearCanvasActive();
    this._placeCache();
    this.active ? this._renderActive() : this._renderInactive();
  }



  /**********************
   * Render entrypoints *
   **********************/

  _renderActive() {
    return this._render(RenderType.ACTIVE)
      .then(renderInfo => {
        if(renderInfo.canvas) {
          this._setCanvasSize(this.$elemCanvas, renderInfo.size);
          this.$elemCanvas[0].getContext('2d').drawImage(
            renderInfo.canvas,
            0, 0,
            renderInfo.size.width, renderInfo.size.height
          );

          this.activeRenderInfo = renderInfo;
          this._transformCanvas();
          this._renderCache();
        } else if(!renderInfo.isCancelled) {
          this._clearCanvasActive();
        }
      })
      .catch(error => {
        // TODO: do we need to handle these?
        this._error(error);
      });
  }


  _renderInactive() {
    return this._render(RenderType.INACTIVE)
      .then(renderInfo => {
        if(renderInfo.canvas) {
          this.$elemInactiveImage[0].src = renderInfo.canvas.toDataURL('image/jpeg');
        } else if(!renderInfo.isCancelled) {
          this._clearCanvasInactive();
        }
      })
      .catch(error => {
        // TODO: do we need to handle these?
        this._error(error);
      });
  }


  _renderCache() {
    if(this.cache.hasOwnProperty(this.page.id)) {
      this._placeCache();
      return $q.resolve();
    }

    return this._render(RenderType.CACHE)
      .then(renderInfo => {
        if(renderInfo.isCancelled) {
          return;
        }
        let dataURL = renderInfo.canvas ? renderInfo.canvas.toDataURL('image/jpeg') : null;
        renderInfo.canvas = null;
        this.cache[renderInfo.page.id] = {
          renderInfo: renderInfo,
          dataURL: dataURL,
        };
        this._placeCache();
      })
      .catch(error => {
        // TODO: do we need to handle these?
        this._error(error);
      });
  }


  renderSnapshot(width) {
    return this._render(RenderType.SNAPSHOT, width).then(renderInfo => {
      if(renderInfo.canvas) {
        return renderInfo.canvas.toDataURL('image/png');
      } else {
        return null;
      }
    });
  }


  _clearCanvasActive() {
    this.activeRenderInfo = null;
    if(this.$elemCanvas) {
      let canvas = this.$elemCanvas[0];
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  _clearCanvasInactive() {
    if(this.$elemInactiveImage) {
      this.$elemInactiveImage.attr('src', '');
    }
  }

  _clearCanvasCache() {
    this.cachePage = null;
    if(this.$elemCacheImage) {
      this.$elemCacheImage.attr('src', '');
      this.$elemCacheImage.css({
        display: 'none',
      });
    }
  }



  /*************************
   * Render inner workings *
   *************************/

  _render(renderType, width = null) {
    let renderInfo = this._getRenderInfo(renderType, width);
    if(renderInfo.isCancellable) {
      this._cancelCancellableRenders(renderInfo);
    }

    this.renderPromise = this.renderPromise
      .then(() => {
        return this._renderNow(renderInfo);
      })
      .catch(error => {
        if(error.constructor === errors.EscapePromiseError) {
          this._log(`Render ${renderInfo.id}: cancelled by new render request`);
        } else {
          throw error;
        }
      })
      .then(() => {
        return renderInfo;
      });
    return this.renderPromise;
  }


  /**
   * Store metadata and the current state of the whiteboard, so it can be
   * recalled during future operations/transformations
   *
   * @param {RenderType} renderType
   * @param {number} width
   * @returns {Object} a renderinfo object to be stored in the cacheItem.info variable
   */
  _getRenderInfo(renderType, width = null) {
    let size, offset, pixelsPerPoint;
    if(renderType === RenderType.ACTIVE || renderType === RenderType.INACTIVE) {
      size = Object.assign({}, this.whiteboard.canvasSize);
      offset = Object.assign({}, this.whiteboard.canvasPan);
      pixelsPerPoint = this.whiteboard.pixelsPerPoint;

    } else if(renderType === RenderType.SNAPSHOT) {
      pixelsPerPoint = this.whiteboard.getPixelsPerPointSnapshot(width);
      size = this.whiteboard.getCanvasSizeSnapshot(width);
      offset = this.whiteboard.getCanvasPanSnapshot(width);

    } else {
      // renderType === RenderType.CACHE: a low-res image of the entire document, used to preview
      // the active document during panning/zooming.
      pixelsPerPoint = CACHE_PX_WIDTH / this.whiteboard.contentSize.width;
      size = {
        width: CACHE_PX_WIDTH,
        height: this.whiteboard.contentSize.height * pixelsPerPoint,
      };
      offset = {
        x: 0,
        y: 0,
      };
    }

    let documentRenderer = this;
    let renderInfo = {
      id: ++this.renderId,
      type: renderType,

      isCancellable: renderType !== RenderType.SNAPSHOT,
      get isCancelled() {
        // TODO: check that `this` refers to renderInfo
        return this.isCancellable && this.id !== documentRenderer.renderIdCancellable;
      },

      page: this.page,
      pdfPageIndex: this.page ? this.pages[this.page.id] : null,
      canvas: null,

      size: size,
      offset: offset,
      zoomLevel: pixelsPerPoint,
    };
    return renderInfo;
  }


  _cancelCancellableRenders(renderInfo) {
    // If we are already rendering: cancel current task. The render promise of the cancelled render
    // task will resolve once cancelling is successful.
    if(this.cancelCurrentRender) {
      this.cancelCurrentRender();
    }
    this.renderIdCancellable = renderInfo.id;
  }


  _renderNow(renderInfo) {
    if(
      !this._isReadyToRender(renderInfo)
      || renderInfo.isCancelled
      || renderInfo.pdfPageIndex == null
    ) {
      return;
    }

    let promise = $q.resolve();
    if(!this.pdf) {
      promise = this.download();
    }
    return promise
      .then(() => {
        if(renderInfo.isCancelled) {
          throw new errors.EscapePromiseError();
        }
        if(renderInfo.type === RenderType.ACTIVE) {
          this._setStatus(Status.RENDERING);
        }
        return this.pdf.getPage(renderInfo.pdfPageIndex);
      })
      .then(pdfPage => {
        if(renderInfo.isCancelled) {
          throw new errors.EscapePromiseError();
        }
        return this._renderPdfPage(renderInfo, pdfPage);
      })
      .then(canvas => {
        if(renderInfo.isCancelled) {
          throw new errors.EscapePromiseError();
        }
        renderInfo.canvas = canvas;
      })
      .finally(() => {
        this._setStatus(Status.INACTIVE);
      });
  }


  _isReadyToRender(renderInfo) {
    if(!renderInfo.page) {
      return false;
    }

    if(
      array.has([RenderType.ACTIVE, RenderType.INACTIVE, RenderType.CACHE], renderInfo.type)
      && !this.$elemTile
    ) {
      return false;
    }

    let canvasSize = this.whiteboard.canvasSize;
    if(
      array.has([RenderType.ACTIVE, RenderType.INACTIVE], renderInfo.type)
      && (canvasSize.width === 0 || canvasSize.height === 0)
    ) {
      return false;
    }

    return true;
  }


  _renderPdfPage(renderInfo, pdfPage) {
    this._log(`Render ${renderInfo.id}: render page`);

    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let viewport = pdfPage.getViewport({
      scale: renderInfo.zoomLevel,
      rotation: this.file.rotation,
    });
    this._setCanvasSize(
      angular.element(canvas),
      renderInfo.size
    );

    let renderTask = pdfPage.render({
      canvasContext: context,
      viewport: viewport,
      transform: [1, 0, 0, 1, -renderInfo.offset.x, -renderInfo.offset.y],
    });
    let defer = $q.defer();

    this.cancelCurrentRender = () => {
      renderTask.cancel();
      defer.resolve();
    };

    $q.when(renderTask.promise)
      .then(() => {
        defer.resolve(canvas);
      })
      .catch(error => {
        if(error.message.startsWith('Rendering cancelled')) {
          defer.resolve();
        } else {
          defer.reject(error);
        }
      });

    return defer.promise;
  }


  _placeCache() {
    if(!this.page || this.cachePage === this.page) {
      return;
    }

    this._clearCanvasCache();
    let cache = this.cache[this.page.id];

    if(cache) {
      this.cachePage = this.page;
      if(cache.dataURL) {
        this.$elemCacheImage.css({
          display: 'block',
          width:  cache.renderInfo.size.width,
          height: cache.renderInfo.size.height,
        });
        this._transformCanvas();
        this.$elemCacheImage[0].src = cache.dataURL;
      }
    }
  }


  /**
   * set the width and height of a html canvas element
   *
   * @param {Object} $elemCanvas a jQuery element of a html canvas
   * @param {Object} size
   */
  _setCanvasSize($elemCanvas, size) {
    let canvas = $elemCanvas[0];
    canvas.width  = size.width;
    canvas.height = size.height;
    canvas.style.width  = size.width + 'px';
    canvas.style.height = size.height + 'px';
  }


  _transformCanvas() {
    if(this.$elemCanvas && this.activeRenderInfo) {
      let scale = this.whiteboard.pixelsPerPointRendered / this.activeRenderInfo.zoomLevel;
      let transform = format(
        'translate(%spx, %spx) scale(%s) translate(%spx, %spx)',
        -this.whiteboard.canvasPanRendered.x,
        -this.whiteboard.canvasPanRendered.y,
        scale,
        this.activeRenderInfo.offset.x,
        this.activeRenderInfo.offset.y
      );
      this.$elemCanvas.css({
        transform: transform,
        transformOrigin: '0px 0px 0px',
      });
    }

    if(this.$elemCacheImage && this.cachePage) {
      let cache = this.cache[this.cachePage.id];
      if(cache) {
        let scale = this.whiteboard.pixelsPerPoint / cache.renderInfo.zoomLevel;
        let transform = format(
          'translate(%spx, %spx) scale(%s)',
          -this.whiteboard.canvasPan.x,
          -this.whiteboard.canvasPan.y,
          scale
        );
        this.$elemCacheImage.css({
          transform: transform,
          transformOrigin: '0px 0px'
        });
      }
    }
  }



  /***********
   * Logging *
   ***********/

  _log() {
    let prefix = format('File %s: ', this.file ? this.file.id : '<empty>');
    let msg = prefix + format.apply(null, arguments);
    logger.debug(msg);
  }


  _error() {
    let prefix = format('File %s: ', this.file ? this.file.id : '<empty>');
    let messageArgs = [].slice.call(arguments, 0, arguments.length - 1);
    let message = prefix + format.apply(null, messageArgs);
    logger.info(message);

    let error = arguments[arguments.length - 1];
    logger.warn(error);

    this.notificationService.error(
      gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'Something went wrong while displaying the file "{{ filename}}". We\'re sorry for the inconvenience',
        { filename: this.file.name }
      )
    );
  }
}
