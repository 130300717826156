// We used to define these libraries using the ProvidePlugin inside
// angular-extra-webpack.config.js. However that completely broke when upgrading to Angular 14, so
// now we're back to putting them on global scope manually.

import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

import adapter from 'webrtc-adapter';
window.adapter = adapter;
