import { NgModule } from '@angular/core';
import { CdkMenuInheritWidthDirective } from './cdk-menu-inherit-width.directive';
import {
  CdkConnectedOverlayInheritWidthDirective
} from './cdk-connected-overlay-inherit-width.directive';
import { CharacterCountDirective } from './character-count.directive';
import { CopyTextDirective } from './copy-text.directive';
import { SelectOnFocusDirective } from './select-on-focus.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { PreventDefaultDirective } from './prevent-default.directive';
import { PickFileDirective } from './pick-file.directive';

const directives = [
  CdkMenuInheritWidthDirective,
  CdkConnectedOverlayInheritWidthDirective,
  CharacterCountDirective,
  CopyTextDirective,
  PreventDefaultDirective,
  SelectOnFocusDirective,
  StopPropagationDirective,
  PickFileDirective
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
