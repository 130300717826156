import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { PrivateNotesService } from './private-notes.service';


@Component({
  selector: 'private-notes',
  templateUrl: './private-notes.component.html',
  styleUrls: ['./private-notes.component.scss'],
})
export class PrivateNotesComponent {
  @Input() public shouldCollapseButton = false;
  @Input() public shouldBeTranslucent = false;

  isOpen = false;

  public connectedPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -10,
  };

  constructor(
    public privateNotesService: PrivateNotesService,
  ) {
  }
}
