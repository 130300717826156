<select
  class="select text-teal-500"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  [ngModelOptions]="{standalone: true}"
>
  <option
    *ngIf="!question.required"
    [value]="null"
    i18n
  >
    Not selected
  </option>

  <option
    *ngFor="let option of options; trackBy:trackByValue"
    [value]="option.value"
  >
    {{ option.label }}
  </option>
</select>
