import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  get urls(): { [key: string]: string } {
    return ANGULAR_SCOPE.urls;
  }
  get images(): { [key: string]: string } {
    return ANGULAR_SCOPE.images;
  }
}
