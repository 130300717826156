export default function ToolColorDirective() {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {
      $scope.$watch($attr.toolColor, color => {
        $elem.find('.custom-fill').css('fill', color);
        $elem.find('.custom-stroke').css('stroke', color);
      });
    }
  };
}
