<div class="linked-item-preview">
  <div class="linked-item-preview__icon">
    <svg-icon
      class="icon"
      *ngIf="icon != null"
      [svgIcon]="icon"
    ></svg-icon>
  </div>

  <div class="overflow-hidden">
    <div class="text-truncate" data-testid="linkedItemPreviewLabel">{{ label }}</div>

    <div
      class="linked-item-preview__badges"
      *ngIf="badges.length > 0"
    >
      <badge *ngFor="let badge of badges" [badge]="badge"></badge>
    </div>
  </div>
</div>
