import { Injectable } from '@angular/core';

type Site = {
  domain: string,
  name: string,
  isStandalone: boolean,
  isTeamleader: boolean,
  productName: string,
  addonName: string,
  addonNameShort: string,
  freePlanName: string,
  proPlanName: string,
  profileLink: string,
  teamMembersLink: string,
  subscriptionLink: string,
  contactLink: string,
  privacyPolicyLink: string,
  homepageAnonymous: string,
  supportEmail: string,
  invoicingEmail: string,
  senderEmail: string,
  senderEmailAppointments: string,
  helpArticles: Record<string, string>
}


@Injectable({
  providedIn: 'root',
})
export class SiteService {
  public site: Site;

  constructor() {
    this.site = ANGULAR_SCOPE.site;
  }

  getHelpArticle(key: string) {
    return this.site.helpArticles[key] || '';
  }
}
