import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from 'core/models/User';
import {
  AppointmentTypeConfigurationCheckService
} from 'scheduling/manage/appointment-types/appointment-type-configuration-check.service';
import { AppointmentTypeConfig } from 'scheduling/models/AppointmentTypeConfig';
import {
  ErrorValue, SettingsComponent, numberOfErrors
} from 'utils/settings/settings.component';
import { TimeRange } from 'utils/util';
import { AppointmentTypeSettingsComponent } from '../appointment-type-settings.component';
import { DropdownOption } from 'utils/form-elements/dropdowns';


@Component({
  selector: 'appointment-type-availability[instance]',
  templateUrl: './appointment-type-availability.component.html',
  styleUrls: ['./appointment-type-availability.component.scss'],
  providers: [{
    provide: SettingsComponent,
    useExisting: AppointmentTypeAvailabilityComponent
  }]
})
export class AppointmentTypeAvailabilityComponent
  extends AppointmentTypeSettingsComponent
  implements OnChanges, OnInit, DoCheck {

  @Input() teamMembers!: User[];
  public copyFromUserOptions: DropdownOption[] = [];

  selectedUser?: User;
  copyFromUser?: User;

  public shouldShowUnavailabilities = false;
  memberConfigurationWarnings: string | void = undefined;

  constructor(
    public appointmentTypeConfigurationCheckService: AppointmentTypeConfigurationCheckService,
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.copyFromUser = this.teamMembers[1];
    this.copyFromUserOptions = this.teamMembers.map(user => ({
      label: user.fullName,
      value: user,
    }));
    this.memberConfigurationWarnings = this.appointmentTypeConfigurationCheckService
      .buildUnconfiguredMembersWarning(this.instance);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(changes.teamMembers) {
      this.copyFromUser = this.teamMembers[1];
      this.copyFromUserOptions = this.teamMembers.map(user => ({
        label: user.fullName,
        value: user,
      }));
      this.memberConfigurationWarnings = this.appointmentTypeConfigurationCheckService
        .buildUnconfiguredMembersWarning(this.instance);
    }
  }


  ngDoCheck() {
    // If the appointment type is changed from team to personal, all configs except one (the
    // request user's) will have been set as inactive. Change our `selectedUser` to match this.
    if(!this.instance.isTeamAppointmentType) {
      const config = this.instance.appointmentTypeConfigs.find(config => config.active);
      const member = this.teamMembers.find(member => member.id === config.userId);
      this.selectedUser = member;
    }
  }


  getAppointmentTypeConfigIndex(user: User): number {
    return this.instance.appointmentTypeConfigs
      .findIndex(config => config.userId === user.id);
  }

  getAppointmentTypeConfig(user: User): AppointmentTypeConfig | undefined {
    const index = this.getAppointmentTypeConfigIndex(user);
    return this.instance.appointmentTypeConfigs[index];
  }

  getAvailabilityErrors(user: User): ErrorValue | undefined {
    const index = this.getAppointmentTypeConfigIndex(user);
    return this.errors.appointmentTypeConfigs?.[index]?.availability;
  }

  getUnavailabilityErrors(user: User): ErrorValue {
    const index = this.getAppointmentTypeConfigIndex(user);
    return this.errors.appointmentTypeConfigs?.[index]?.unavailability;
  }

  hasErrors(user: User) {
    return this.getAvailabilityErrors(user) !== undefined
    || this.getUnavailabilityErrors(user) !== undefined;
  }

  numberOfErrors(errorValue: ErrorValue): number {
    return numberOfErrors(errorValue);
  }

  isUserActive(user: User) {
    const config = this.getAppointmentTypeConfig(user);
    return config?.active;
  }

  setUserActive(user: User, active: boolean) {
    let config = this.getAppointmentTypeConfig(user);
    if(!config && active) {
      config = this.instance.createAppointmentTypeConfig(user);
    }
    if(config) {
      config.active = active;
    }
    this.memberConfigurationWarnings = this.appointmentTypeConfigurationCheckService
      .buildUnconfiguredMembersWarning(this.instance);
  }

  isSelectedUser(user: User) {
    return this.selectedUser && user.id === this.selectedUser.id;
  }

  toggleSelectedUser(user: User) {
    if(this.isSelectedUser(user)) {
      this.selectedUser = undefined;
    } else {
      // Make sure an AppointmentTypeConfig exists for this user.
      this.instance.getOrCreateAppointmentTypeConfig(user);
      this.selectedUser = user;
    }
  }

  resetToDefault(user: User) {
    const config = this.getAppointmentTypeConfig(user);
    const defaultAvailability = AppointmentTypeConfig.fields.availability.default();
    config.availability = defaultAvailability;
  }

  copyAvailability(fromUser: User, toUser: User) {
    const fromConfig = this.instance.getOrCreateAppointmentTypeConfig(fromUser);
    const toConfig = this.instance.getOrCreateAppointmentTypeConfig(toUser);
    toConfig.availability = fromConfig.availability.map(listOfAvailabilityRanges => {
      return listOfAvailabilityRanges.map(availabilityRange => {
        return new TimeRange(availabilityRange.from, availabilityRange.to);
      });
    });
  }



  override get valid() {
    if(this.errors.availability) {
      return false;
    }

    const errors = this.errors.appointmentTypeConfigs as { [field: string]: ErrorValue }[];
    if(errors) {
      for(const configErrors of errors) {
        if(
          (
            configErrors.availability != null
            && numberOfErrors(configErrors.availability) > 0
          ) || (
            configErrors.unavailability != null
            && numberOfErrors(configErrors?.unavailability) > 0
          )
        ) {
          return false;
        }
      }
    }
    return true;
  }

  toggleUnavailabilities() {
    this.shouldShowUnavailabilities = !this.shouldShowUnavailabilities;
  }

  getUnavailabilitiesCount(user) {
    return this.getAppointmentTypeConfig(user).unavailability.length;
  }
}
