<!-- MOBILE -->
<div class="d-md-none">
  <div class="mb--5">
    <div
      *ngFor="let contactForm of contactForms"
      class="card card--sm d-flex flex-column"
      [ngClass]="{'text-muted': !contactForm.isActive }"
      data-testid="contactForm"
    >
      <h3 class="mb-4 d-flex justify-content-between align-items-center">
        <div data-testid="contactFormName">{{ contactForm.name }}</div>
        <div
          *ngIf="shouldWarnAboutDowngrade(contactForm)"
          class="ml-2"
          matTooltip="This form contains questions that are no longer available with your subscription plan"
          i18n-matTooltip
        >
          <svg-icon class="icon text-error-400" [svgName]="'warning_badged'" [svgStyle]="'filled'" ></svg-icon>
        </div>
        <div
          *ngIf="!contactForm.isActive"
          class="ml-2"
          data-testid="disabledContactFormBadge"
        >
          <span
            class="tag tag--sm"
            i18n
          >
            Disabled
          </span>
        </div>

        <div>
          <!-- Edit -->
          <a
            *ngIf="canEdit(contactForm)"
            class="btn btn--square"
            i18n-matTooltip
            matTooltip="Edit form"
            [href]="getUpdateUrl(contactForm)"
          >
            <svg-icon class="icon" [svgName]="'edit'"></svg-icon>
          </a>

          <!-- Sharing modal-->
          <div
            class="btn btn--square ml-2"
            [ngClass]="{ 'disabled': !contactForm.isActive }"
            i18n-matTooltip
            matTooltip="Share your form"
            [modalTriggerFor]="shareForm"
            [modalTriggerData]="{ contactForm: contactForm }"
            [modalIcon]="{name: 'share_android'}"
            [modalTitle]="getSharingOptionsTitle(contactForm)"
          >
            <svg-icon class="icon" [svgName]="'share_android'"></svg-icon>
          </div>


          <!-- Kebab menu -->
          <div
            data-testid="contactFormOverviewKebab"
            class="btn btn--square"
            [cdkMenuTriggerFor]="actions"
            [cdkMenuTriggerData]="{ contactForm: contactForm }"
          >
            <svg-icon class="icon" [svgName]="'more'"></svg-icon>
          </div>
        </div>
      </h3>

      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <div *ngIf="contactForm.numResponses === 0">{{ getNumResponsesText(contactForm) }}</div>
          <a
            *ngIf="contactForm.numResponses > 0"
            class="d-flex align-items-center"
            [href]="getResponsesUrl(contactForm)"
          >
            <span>{{ getNumResponsesText(contactForm) }}</span>
            <svg-icon
              svgName="graph_trending_up"
              *ngIf="contactForm.numRecentResponses > 0"
              class="d-flex align-items-center ml-2 use-icon-color text-success-400"
            ></svg-icon>
          </a>
          <div *ngIf="isTeamleaderUser" class="mt-2">{{ getEntityCreationMobileText(contactForm) }}</div>
        </div>

        <div class="flex-none mr-3 align-self-end">
          <profile-image *ngIf="contactForm.createdBy" [user]="contactForm.createdBy"></profile-image>
          <svg-icon *ngIf="!contactForm.createdBy" class="icon" [svgName]="'calendar_edit'"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- DESKTOP -->
<div class="card card--no-padding d-none d-md-block">
  <table
    data-testid="contactFormsTable"
    class="table table--hoverable align-middle contact-forms__table"
  >
    <thead>
      <tr>
        <th i18n>Title</th>
        <th i18n>Responses</th>
        <th i18n>Created by</th>
        <th *ngIf="isTeamleaderUser" i18n>Data sent to</th>
        <th i18n></th>
      </tr>
    </thead>

    <tbody class="border-none">
      <tr
        *ngFor="let contactForm of contactForms"
        [ngClass]="{'text-muted': !contactForm.isActive }"
        data-testid="contactForm"
      >
          <!-- Title -->
          <td>
            <div class="d-flex align-items-center">
              <span data-testid="contactFormName">
                {{ contactForm.name }}
              </span>
              <div
                *ngIf="shouldWarnAboutDowngrade(contactForm)"
                class="ml-2"
                matTooltip="This form contains questions that are no longer available with your subscription plan"
                i18n-matTooltip
              >
                <svg-icon class="icon text-error-400" [svgName]="'warning_badged'" [svgStyle]="'filled'" ></svg-icon>
              </div>
              <div
                *ngIf="!contactForm.isActive"
                class="ml-2"
                data-testid="disabledContactFormBadge"
              >
                <span
                  class="tag tag--sm"
                  i18n
                >
                  Disabled
                </span>
              </div>
            </div>
          </td>

          <!-- Number of responses -->
          <td>
            <div class="d-flex align-items-center">
              <div *ngIf="contactForm.numResponses === 0">{{ getNumResponsesText(contactForm) }}</div>
              <a
                *ngIf="contactForm.numResponses > 0"
                class="d-flex align-items-center"
                [href]="getResponsesUrl(contactForm)"
              >
                <span>{{ getNumResponsesText(contactForm) }}</span>
                <svg-icon
                  svgName="graph_trending_up"
                  *ngIf="contactForm.numRecentResponses > 0"
                  class="d-flex align-items-center ml-2 use-icon-color text-success-400"
                  [matTooltip]="getNumRecentResponsesTooltip(contactForm)"
                ></svg-icon>
              </a>
            </div>
          </td>

          <!-- Created by -->
          <td>
            <div class="d-flex align-items-center">
              <div class="flex-none mr-3">
                <profile-image [user]="contactForm.createdBy"></profile-image>
              </div>
              <div>{{ contactForm.createdBy.fullName }}</div>
            </div>
          </td>

          <!-- Data sent to -->
          <td *ngIf="isTeamleaderUser">
            {{ getEntityCreationText(contactForm) }}
          </td>

          <!-- Actions -->
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <!-- Edit -->
              <a
                *ngIf="canEdit(contactForm)"
                class="btn btn--square d-none d-md-flex"
                i18n-matTooltip
                matTooltip="Edit form"
                [href]="getUpdateUrl(contactForm)"
              >
                <svg-icon class="icon" [svgName]="'edit'"></svg-icon>
              </a>

              <!-- Sharing modal-->
              <div
                *ngIf="contactForm.isActive"
                class="btn btn--square ml-2 d-none d-md-flex"
                i18n-matTooltip
                matTooltip="Share your form"
                [modalTriggerFor]="shareForm"
                [modalTriggerData]="{ contactForm: contactForm }"
                [modalIcon]="{name: 'share_android'}"
                [modalTitle]="getSharingOptionsTitle(contactForm)"
                data-testid="sharingModalButtonActive"
              >
                <svg-icon class="icon" [svgName]="'share_android'"></svg-icon>
              </div>

              <div
                *ngIf="!contactForm.isActive"
                class="btn btn--square ml-2 d-none d-md-flex disabled"
                i18n-matTooltip
                matTooltip="Activate this contact form to share it"
                data-testid="sharingModalButtonDisabled"
              >
                <svg-icon class="icon" [svgName]="'share_android'"></svg-icon>
              </div>

              <!-- Kebab menu -->
              <div
                data-testid="contactFormOverviewKebab"
                class="btn btn--square ml-2"
                [cdkMenuTriggerFor]="actions"
                [cdkMenuTriggerData]="{ contactForm: contactForm }"
              >
                <svg-icon class="icon" [svgName]="'more'"></svg-icon>
              </div>
            </div>
          </td>
      </tr>
    </tbody>
  </table>
</div>


<ng-template #shareForm let-contactForm="contactForm">
  <sharing-options
    [name]="contactForm.name"
    [url]="contactForm.url"
    [embeddedUrl]="contactForm.embeddedUrl"
    [id]="contactForm.id"
    context="contactForm"
  >
  </sharing-options>
</ng-template>

<ng-template #actions let-contactForm="contactForm">
  <div class="dropdown" cdkMenu>
    <div cdkMenuItem></div>
    <!-- Preview form -->
    <a
      data-testid="contactFormKebabPreview"
      [modalTriggerFor]="modalPreview"
      [modalIcon]="{name: 'visible'}"
      [modalTitle]="contactForm.name"
      class="dropdown__list-item"
      cdkMenuItem
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'visible'"></svg-icon>
      <span i18n>Preview form</span>
    </a>

    <ng-template #modalPreview>
      <contact-form-preview data-testid="contactFormOverviewPreview" [contactForm]="contactForm"></contact-form-preview>

      <div class="modal__footer flex-wrap">
        <div class="flex-1 d-flex align-items-center flex-wrap mr--2 mb--2">
          <!-- Edit form -->
          <a
            href="{{ getUpdateUrl(contactForm) }} "
            class="btn btn--secondary mr-2"
            *ngIf="canEdit(contactForm)"
            data-testid="contactFormPreviewEditForm"
            cdkMenuItem
          >
            <svg-icon class="btn__icon-icon" [svgName]="'edit'"></svg-icon>
            <span i18n>Edit form</span>
          </a>

          <!-- Copy link -->
          <a
            *ngIf="contactForm.isActive"
            [copyText]="contactForm.url"
            (click)="track('contactForm.copiedLink')"
            class="btn btn--secondary"
            data-testid="contactFormPreviewCopyLink"
          >
            <svg-icon class="btn__icon" [svgName]="'link'"></svg-icon>
            <span i18n>Copy link</span>
          </a>
        </div>
      </div>
    </ng-template>

    <!-- Edit form -->
    <a
      data-testid="contactFormKebabEdit"
      href="{{ getUpdateUrl(contactForm) }} "
      class="dropdown__list-item"
      *ngIf="canEdit(contactForm)"
      cdkMenuItem
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'edit'"></svg-icon>
      <span i18n>Edit form</span>
    </a>

    <!-- Duplicate form -->
    <div
      data-testid="contactFormKebabDuplicate"
      *ngIf="canDuplicate(contactForm)"
      class="dropdown__list-item"
      cdkMenuItem

      (click)="duplicate(contactForm)"
    >
      <svg-icon class="dropdown__list-icon" [svgName]="'copy'"></svg-icon>
      <span i18n>Duplicate form</span>
    </div>

    <!-- Delete form -->
    <div *ngIf="canEdit(contactForm)">
      <a
        data-testid="contactFormKebabDelete"
        class="dropdown__list-item text-error-400"
        cdkMenuItem

        [dialog]="deleteConfirmation"
        dialogTitle="Delete Contact form"
        i18n-dialogTitle
        [dialogIcon]="{ name: 'trash' }"
        dialogConfirmText="Delete"
        i18n-dialogConfirmText
        dialogConfirmButtonClass="btn--error"
        [dialogConfirmedCallback]="delete.bind(this, contactForm)"
      >
        <svg-icon class="dropdown__list-icon" [svgName]="'trash'"></svg-icon>
        <span i18n>Delete form</span>
      </a>
    </div>
    <hr class="m-2">

    <div
      class="dropdown__list-item"
      matTooltip="Upgrade your subscription to have more than one active contact form"
      i18n-matTooltip
      [matTooltipDisabled]="canCreate || contactForm.isActive"
    >
      <input
        id="contactFormActivationToggle"
        type="checkbox"
        class="toggle"
        [ngModel]="contactForm.isActive"
        (ngModelChange)="setActive(contactForm, $event)"
        [disabled]="!canCreate && !contactForm.isActive"
      />
      <label for="contactFormActivationToggle" class="toggle__label">
        <div class="d-flex align-items-center">
          <span
            data-testid="contactFormDisableToggle"
            i18n
          >Active</span>
        </div>
      </label>
    </div>
  </div>

  <ng-template #deleteConfirmation>
    <p i18n>Are you sure you want to delete the Contact form <b>{{ contactForm.name }}</b>?</p>
    <p i18n>This action cannot be reverted.</p>
  </ng-template>
</ng-template>
