import './angularjs-init';
import 'utils/utils.module';

import { DoBootstrap, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { SchedulingModule } from 'scheduling/scheduling.module';
import { DashboardModule } from 'dashboard/dashboard.module';
import { ContactFormModule } from 'contactForm/contact-form.module';
import { ContactWidgetModule } from 'contactWidget/contact-widget.module';
import { MeetingModule } from 'meeting/meeting.module';
import { MarkdownModule } from 'ngx-markdown';
import { IntegrationsModule } from 'integrations/integrations.module';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    DashboardModule,
    ContactFormModule,
    ContactWidgetModule,
    MeetingModule,
    IntegrationsModule,
    SchedulingModule,
    UpgradeModule,
    MarkdownModule.forRoot(),
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private readonly upgrade: UpgradeModule,
    private readonly zone: NgZone
  ) {
    window.__vecteraRunOutsideAngular = this.zone.runOutsideAngular.bind(this.zone);
  }

  ngDoBootstrap() {
    window.__vecteraAngularLoaded = true;
    this.upgrade.bootstrap(document.body, ['vectera'], { strictDi: true });
  }
}
