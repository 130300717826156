export function getItem(key: string): string | null {
  try {
    return localStorage.getItem(key);  // eslint-disable-line no-restricted-syntax
  } catch(error) {
    return null;
  }
}

export function setItem(key: string, value: string) {
  try {
    localStorage.setItem(key, value);  // eslint-disable-line no-restricted-syntax
  } catch(error) {
    // do nothing
  }
}

export function removeItem(key: string) {
  try {
    localStorage.removeItem(key);  // eslint-disable-line no-restricted-syntax
  } catch(error) {
    // Do nothing
  }
}


export function getJSONItem(storageKey: string, defaultValue: object): object {
  const stringValue = getItem(storageKey) || '';
  try {
    return JSON.parse(stringValue);
  } catch(error) {
    return defaultValue;
  }
}

export function setJSONItem(key: string, value: object) {
  const stringValue = JSON.stringify(value);
  return setItem(key, stringValue);
}


export function getBooleanItem(key: string, defaultValue: boolean): boolean {
  const stringValue = getItem(key) || '';
  if(stringValue === 'true') {
    return true;
  } else if(stringValue === 'false') {
    return false;
  } else {
    return defaultValue;
  }
}

export function setBooleanItem(key: string, value: boolean) {
  const stringValue = value ? 'true' : 'false';
  return setItem(key, stringValue);
}
