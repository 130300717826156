export default class ContextMenuController {
  static get $inject() {
    return [
      'contextMenuService',
    ];
  }

  constructor(
    contextMenuService
  ) {
    this.contextMenuService = contextMenuService;
  }
}
