import './contentButtons';
import './footer';
import './header';
import './sidebar';


import AppComponent from './app.component';
import FullscreenService from './fullscreen.service';


angular
  .module('meeting')
  .service('fullscreenService', FullscreenService)
  .component('app', AppComponent);
