import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularjsUpgradedUtilsModule } from 'utils/angularjs-upgraded';
import { PipesModule } from 'utils/pipes';
import { SvgIconModule } from 'utils/ui-components/svg-icon';
import { AngularWizardComponent } from './angular-wizard.component';
import { WizardHostDirective } from './wizard-host.directive';


@NgModule({
  imports: [
    AngularjsUpgradedUtilsModule,
    CommonModule,
    FormsModule,
    PipesModule,
    SvgIconModule,
  ],
  declarations: [
    AngularWizardComponent,
    WizardHostDirective,
  ],
  exports: [
    AngularWizardComponent,
  ],
})
export class WizardModule {}
