export default class KnockerChatController {
  static get $inject() {
    return [
      'knockerChatService',
    ];
  }

  constructor(
    knockerChatService
  ) {
    this.knockerChatService = knockerChatService;
  }
}
