import { Inject, Injectable } from '@angular/core';
import {
  OrganizationAppointmentType,
  UserAppointmentType
} from 'scheduling/models/AppointmentType';
import { Memoize } from 'typescript-memoize';
import { RequestUserService } from 'utils/requestUser.service';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { DashboardScope } from 'dashboard/data-page/data-page.component';


@Injectable({
  providedIn: 'root',
})
export class BookingLinksMenuService {
  constructor(
    private requestUserService: RequestUserService,
    @Inject('modelFactory') private modelFactory,
  ) {}


  @Memoize()
  async fetch(scope: Scope) {
    const apiConfig = {
      params: {
        perPage: 'all',
        filter: 'active = true',
      },
    };
    let modelConfig;

    switch(scope) {
      case DashboardScope.PERSONAL:
        modelConfig = {
          model: UserAppointmentType,
          identifiers: { userId: this.requestUserService.user.id },
        };
        break;

      case DashboardScope.TEAM:
        modelConfig = {
          model: OrganizationAppointmentType,
          identifiers: { organizationId: this.requestUserService.user.organizationId },
        };
        break;

      default:
        throw new Error(`Unknown scope: ${scope}`);
    }

    const { data: appointmentTypes } = await this.modelFactory.list(modelConfig, apiConfig);
    return appointmentTypes;
  }
}
