import Bowser from 'bowser';

export const bowser = Bowser.getParser(navigator.userAgent);
export const parsedResult = bowser.parsedResult;

window.bowser = bowser;



// iPad iOS >=13 disguises itself as Mac OS. navigator.maxTouchPoints is a hack that seems to work.
// For now.
if(bowser.isOS('macos') && navigator.maxTouchPoints > 1) {
  parsedResult.os.name = 'iOS';
  parsedResult.os.version = parsedResult.browser.version;
  parsedResult.platform.type = 'tablet';
  parsedResult.platform.model = 'iPad';
}


/*********************
 * WebView detection *
 *********************/

Bowser.BROWSER_MAP.webview = 'WebView';
// iOS Chrome with underlying Safari 14.0.2 has a problem where the mic stop working if you switch
// tabs. This flag is only here to show a warning in this browser.
let _isIOSChrome = false;


// iOS: Webviews are detected either as Safari without a version, or as a different browser
if(bowser.isOS('ios') && (!bowser.isBrowser('safari') || bowser.getBrowserVersion() == null)) {
  if(bowser.isBrowser('chrome')) {
    _isIOSChrome = true;
  }

  parsedResult.browser.name = 'WebView';

  // WebViews are only supported for Safari >= 14.0.2. Make sure we don't detect iOS 14.3 as
  // Safari 14.3
  let floatOSVersion = parseFloat(bowser.getOSVersion());
  if(floatOSVersion >= 14 && floatOSVersion < 14.3) {
    parsedResult.browser.version = '14.0.1';
  } else {
    parsedResult.browser.version = parsedResult.os.version;
  }

  // iOS Safari >= 14.0.2: treat every Webview-based app as Safari.
  if(bowser.compareVersion('>=14.0.2')) {
    parsedResult.browser.name = 'Safari';
  }

// Android: Webviews are detected as Chrome, but their user agent contains "Version/" while
// Chrome's user agent does not.
// In Android you can actually see some devices ("Unknown camera" and "Unknown microphone"), but
// when you select them nothing happens. (Possibly the call to getUserMedia() never resolves, we
// haven't investigated any further.)
} else if(
  bowser.satisfies({ android: { chrome: '>0' } })
  && /Version\//i.test(navigator.userAgent)
) {
  parsedResult.browser.name = 'WebView';
}



/*********************
 * Browser detection *
 *********************/

export function isChrome() {
  return bowser.isBrowser('chrome') || bowser.isBrowser('chromium');
}
export function isEdge() {
  return bowser.isBrowser('edge', true);
}
export function isOpera() {
  return bowser.isBrowser('opera');
}
export function isFirefox() {
  return bowser.isBrowser('firefox');
}
export function isSafari() {
  return bowser.isBrowser('safari');
}


export function isBlink() {
  return isChrome() || isEdge() || isOpera();
}


export function isMacOS() {
  return bowser.isOS('macOS');
}

export function isIOS() {
  return bowser.isOS('ios');
}
export function isAndroid() {
  return bowser.isOS('android');
}


export function isStandalone() {
  return navigator.standalone;
}
export function isIOSChrome() {
  return _isIOSChrome;
}


export function isDesktop() {
  // The platform for ChromeOS is not detected -> this is matched by isPlatform().
  return bowser.isPlatform('desktop') || bowser.isPlatform();
}
export function isMobile() {
  return !isDesktop();
}



/*********************
 * Feature detection *
 *********************/

export function supportsVectera() {
  // This list must always match .browserslistrc
  return (
    bowser.satisfies({
      chrome: '>=78',
      chromium: '>=78',
      edge: '>=131',
      opera: '>=113',
      firefox: '>=115',
      safari: '>=15.6',
    })
  );
}

// Expose this method to the meeting init script
window.supportsVectera = supportsVectera;


export function supportsWebRTC() {
  return _supportsWebRTC() && webRTCEnabled();
}

export function webRTCEnabled() {
  if(!window.RTCPeerConnection) {
    return false;
  }
  return _supportsWebRTC();
}

export function _supportsWebRTC() {
  if(!navigator.mediaDevices) {
    return false;
  }
  return bowser.satisfies({
    android: { edge: '>=42' },
    chrome: '>=69',
    chromium: '>=69',
    edge: '>=79',
    opera: '>=56',
    firefox: '>=60',
    safari: '>=13',
  });
}


export function supportsBlurBackground() {
  return supportsWebRTC() && isDesktop() && isBlink();
}


export function supportsScreenshare() {
  return (
    supportsWebRTC()
    && isDesktop()
    && (
      isBlink()
      || isFirefox()
      || isSafari() && bowser.compareVersion('>=13')
    )
  );
}


export function supportsPictureInPicture() {
  // Safari supports PiP, but not our implementation of it. This hasn't been further investigated,
  // we just disabled it for now.
  return (
    isDesktop()
    && document.pictureInPictureEnabled === true  // eslint-disable-line compat/compat
    && isBlink()
  );
}


// The onaudioprocess event is very buggy on iOS for remote media streams
// STR:
//   - Open Vectera on desktop and start audio.
//   - Restart iOS.
//   - Open Vectera on iOS and start audio.
//   - Result: sometimes onaudioprocess only reports zeros. Sometimes it works for a few seconds,
//     and then stops.
export function supportsRemoteAudioLevel() {
  return !isIOS();
}


export function supportsHoverEvents() {
  // See e.g. https://stackoverflow.com/questions/2741816/
  return !isIOS();
}

export function supportsInlineDownload() {
  return !isIOS();
}
export function supportsDownloadWithoutInteraction() {
  return !isIOS();
}


export function supportsUnfocusedVideo() {
  return !isIOS();
}
export function supportsUnfocusedSnapshot() {
  return !bowser.satisfies({
    safari: '<15',
  });
}


export function supportsPeerConnectionAddRemoveTrack() {
  return !isSafari() || supportsWebRTCTransceivers();
}

export function supportsCobrowse() {
  return isBlink();
}

export function supportsWebRTCTransceivers() {
  return (
    isBlink()
    || isFirefox() && bowser.compareVersion('>=59')
    || isSafari()
  );
}

export function supportsWebRTCTransceiverDirection() {
  return (
    supportsWebRTCTransceivers()
    && (
      isBlink()
      || isFirefox()
      || isSafari()
    )
  );
}

export function supportsEncodingInfo() {
  if(navigator.mediaCapabilities.encodingInfo) {
    // Firefox does actually support this, but refuses to support the codecs that it itself
    // returned in `RtpSender.getCapabilities()`. Should be doable to work around, but we need to
    // hotfix this.
    return !isFirefox();
  } else {
    return false;
  }
}

export function supportsOutputDevice() {
  return isDesktop() && isBlink();
}

export function supportsRecording() {
  return isDesktop() && isBlink();
}

export function supportsWebm() {
  return !isSafari();
}

export function supportsShiftEnter() {
  return !isIOS();
}

export function supportsFramerate() {
  // Firefox supports setting the framerate, but hasn't implemented frame dropping in case of an
  // unsupported framerate, often giving you a framerate that is way lower than what you asked for.
  // For example requesting a frame rate of 24 returns a stream with framerate 15 on Seppe's
  // Thinkpad, and 10 on the QA Macbook.
  return !isFirefox();
}

export function supportsAudioWorkletAPI() {
  if(!isFirefox()) {
    return _supportsWebRTC();
  } else {
    return bowser.satisfies({
      firefox: '>=76',
    });
  }
}


/*****************
 * Help messages *
 *****************/

/* eslint-disable max-len */
function getErrorMessageVectera() {
  let chromeAttributes = 'target="_blank" href="https://www.google.com/chrome/browser"';
  let ffAttributes = 'target="_blank" href="https://www.mozilla.org/firefox"';

  if(isIOS()) {
    return gettextCatalog.getString('We do not support your browser. We recommend that you install the latest version of Safari or switch to the desktop for optimal use.');
  } else if(isSafari()) {
    return gettextCatalog.getString(
      'We do not support your browser. We recommend that you update Safari or switch to <a {{ chromeUrl }}>Chrome</a> or <a {{ ffUrl }}>Firefox</a> for optimal use.',
      { chromeUrl: chromeAttributes, ffUrl: ffAttributes }
    );
  } else {
    return gettextCatalog.getString(
      'We do not support your browser. We recommend that you install the latest version of <a {{ chromeUrl }}>Chrome</a> or <a {{ ffUrl }}>Firefox</a> for optimal use.',
      { chromeUrl: chromeAttributes, ffUrl: ffAttributes }
    );
  }
}


function getErrorMessageWebRTC() {
  let chromeAttributes = 'target="_blank" href="https://www.google.com/chrome/browser"';
  let ffAttributes = 'target="_blank" href="https://www.mozilla.org/firefox"';

  if(isSafari() && isStandalone()) {
    return gettextCatalog.getString('It seems you have added a shortcut to your home screen. Unfortunately iOS does not support sharing your audio or video this way. We recommend reopening this page in Safari.');
  } else if(!_supportsWebRTC()) {
    if(isIOS()) {
      return gettextCatalog.getString('Your browser does not support audio/video communication. We recommend that you install the latest version of Safari or switch to the desktop for optimal use.');
    } else if(isSafari()) {
      return gettextCatalog.getString(
        'Your browser does not support audio/video communication. We recommend that you update Safari or switch to <a {{ chromeUrl }}>Chrome</a> or <a {{ ffUrl }}>Firefox</a> for optimal use.',
        { chromeUrl: chromeAttributes, ffUrl: ffAttributes }
      );
    } else {
      return gettextCatalog.getString(
        'Your browser does not support audio/video communication. We recommend that you install the latest version of <a {{ chromeUrl }}>Chrome</a> or <a {{ ffUrl }}>Firefox</a> for optimal use.',
        { chromeUrl: chromeAttributes, ffUrl: ffAttributes }
      );
    }
  } else {
    // duplicate implementation of SiteService GetHelpArticle as browser.js can't inject services
    const helpArticle = ANGULAR_SCOPE.site.helpArticles['audioVideoDisabled'] || '';
    return gettextCatalog.getString(
      'Audio/video communication seems to be disabled in your browser settings. <a {{ url }}>Get help</a>.',
      { url: `target="_blank" href="${helpArticle}"` }
    );
  }
}


function getErrorMessageOutputDevice() {
  return gettextCatalog.getString('Your browser does not support setting the speaker.');
}


function getErrorMessageScreenshare() {
  let chromeAttributes = 'target="_blank" href="https://www.google.com/chrome/browser"';
  let ffAttributes = 'target="_blank" href="https://www.mozilla.org/firefox"';

  if(isDesktop()) {
    return gettextCatalog.getString(
      'Your system does not support screensharing. We recommend that you switch to <a {{ chromeUrl }}>Chrome</a> or <a {{ ffUrl }}>Firefox</a> to share your screen.',
      { chromeUrl: chromeAttributes, ffUrl: ffAttributes }
    );
  } else {
    return gettextCatalog.getString(
      'Your system does not support screensharing. We recommend that you switch to <a {{ chromeUrl }}>Chrome</a> or <a {{ ffUrl }}>Firefox</a> on the desktop to share your screen.',
      { chromeUrl: chromeAttributes, ffUrl: ffAttributes }
    );
  }
}


function getWarningMessageSafariScreenShare() {
  return gettextCatalog.getString('You can join the meeting but you will not be able to share your screen. We highly recommend using Chrome.');
}


function getErrorMessageCobrowse() {
  let chromeAttributes = 'target="_blank" href="https://www.google.com/chrome/browser"';

  if(isIOS()) {
    return gettextCatalog.getString(
      'Your system does not support cobrowsing. We recommend that you switch to <a {{ chromeUrl }}>Chrome</a> on the desktop to start cobrowsing.',
      { chromeUrl: chromeAttributes }
    );
  } else {
    return gettextCatalog.getString(
      'Your system does not support cobrowsing. We recommend that you switch to <a {{ chromeUrl }}>Chrome</a> to start cobrowsing.',
      { chromeUrl: chromeAttributes }
    );
  }
}


function getErrorMessageWebm() {
  if(isIOS()) {
    return gettextCatalog.getString('Video preview is only possible on the desktop, using Chrome, Firefox or Edge.');
  } else {
    return gettextCatalog.getString('Video preview is only possible in Chrome, Firefox or Edge.');
  }
}
/* eslint-enable max-len */


export const errorMessages = Object.freeze({
  vectera: getErrorMessageVectera,
  webRTC: getErrorMessageWebRTC,
  outputDevice: getErrorMessageOutputDevice,
  screenshare: getErrorMessageScreenshare,
  cobrowse: getErrorMessageCobrowse,
  webm: getErrorMessageWebm,
});

export const warningMessages = Object.freeze({
  safariScreenShare: getWarningMessageSafariScreenShare,
});

window.ERROR_MESSAGES = errorMessages;
