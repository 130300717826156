import { bind, errors, format, logger, object, string } from 'utils/util';



export default class ApiService {
  static get $inject() {
    return [
      'requestService',
      'siteService',
    ];
  }

  constructor(
    requestService,
    siteService
  ) {
    bind(this);

    this.requestService = requestService;
    this.siteService = siteService;

    let baseUrlTemplate;

    // Regular domain
    if(location.host === ANGULAR_SCOPE.site.domain) {
      baseUrlTemplate = 'https://%s/api/v1/';

      // Teamleader cloud
    } else if(location.host === ANGULAR_SCOPE.teamleaderCloudSiteDomain) {
      baseUrlTemplate = 'https://%s/_customer-meeting/api/v1/';

    // Custom domain
    } else {
      baseUrlTemplate = 'https://%s/_api/v1/';
    }
    this.baseUrl = format(baseUrlTemplate, location.host);


    ['get', 'head', 'delete'].forEach(method => {
      this[method] = function(path, argConfig) {
        let config = Object.assign({}, argConfig, {
          path: path,
          method: method.toUpperCase(),
        });
        return this.call(config);
      };
    });
    ['post', 'put', 'patch'].forEach(method => {
      this[method] = function(path, data, argConfig) {
        let config = Object.assign({}, argConfig, {
          path: path,
          data: data,
          method: method.toUpperCase(),
        });
        return this.call(config);
      };
    });
  }


  /* eslint-disable max-len */
  get errorLinks() {
    const helpArticle = this.siteService.getHelpArticle('cookies');

    return [
      {
        /// The translation should be an exact match with the translation of all other strings containing this string as a substring, as it will be used to transform this substring to a link
        regex: new RegExp(gettextCatalog.getString('Verify your email address'), 'gi'),
        url: format('https://%s/profile/', ANGULAR_SCOPE.site.domain),
      },
      {
        /// The translation should be an exact match with the translation of all other strings containing this string as a substring, as it will be used to transform this substring to a link
        regex: new RegExp(gettextCatalog.getString('Upgrade your subscription'), 'gi'),
        url: format('https://%s/subscription/', ANGULAR_SCOPE.site.domain),
      },
      {
        /// The translation should be an exact match with the translation of all other strings containing this string as a substring, as it will be used to transform this substring to a link
        regex: new RegExp(gettextCatalog.getString('Edit your subscription'), 'gi'),
        url: format('https://%s/subscription/', ANGULAR_SCOPE.site.domain),
      },
      {
        regex: new RegExp(
          /// The translation should be an exact match with the translation of all other strings containing this string as a substring, as it will be used to transform this substring to a link
          gettextCatalog.getString('CSRF cookie not set')
          + '|'
          /// The translation should be an exact match with the translation of all other strings containing this string as a substring, as it will be used to transform this substring to a link
          + gettextCatalog.getString('CSRF token missing or incorrect'),
          'gi'),
        append: ` <a href="${helpArticle}" target="_blank">Get help</a>`,
      }
    ];
  }
  /* eslint-enable max-len */

  call(argConfig) {
    let config = Object.assign({}, argConfig, {
      url: this.baseUrl + argConfig.path,
    });
    delete config.path;
    let requestPromise = this.requestService.call(config)
      .then(response => this._parseResponseLink(response));
    let apiPromise = requestPromise.catch(this._onError);
    apiPromise.__requestPromise = requestPromise;
    return apiPromise;
  }


  _onError(error) {
    let message;

    if(error.constructor === errors.RequestTooLargeError) {
      message = gettextCatalog.getString(
        'The file you tried to upload is too large. Please try again with a smaller file.'
      );

    } else {
      let data = this._parseErrorResponse(error.response);
      let messages = this._flattenErrorMessages(data);

      messages = messages.map(message => {
        // Message can be a string, null, or an ArrayBuffer
        if(message.replace) {
          this.errorLinks.forEach(link => {
            message = (message || '').replace(link.regex, match => {
              if(link.url) {
                return format(
                  '<a href="%s" target="_blank">%s</a>',
                  link.url, match);
              } else if(link.append) {
                return match + link.append;
              } else {
                logger.error('Invalid link subtitution: ', link.regex);
              }
            });
          });
        }
        return message;
      });
      message = messages.join(' ');
    }

    error.message = message;
    throw error;
  }


  _parseErrorResponse(response) {
    let data = response.data;
    if(data instanceof ArrayBuffer) {
      let decoder = new TextDecoder('utf-8');
      try {
        data = decoder.decode(data);
      } catch(e) {
        // Do nothing
      }
      try {
        data = JSON.parse(data);
      } catch(e) {
        // Do nothing
      }
    }
    return data;
  }


  _flattenErrorMessages(data) {
    if(string.isString(data) || data == null) {
      return [data];

    } else if(Array.isArray(data)) {
      const arrayOfArrays = data.map(item => this._flattenErrorMessages(item));
      return [].concat(...arrayOfArrays);

    } else if(object.isObject(data)) {
      const arrayOfArrays = Object.entries(data).map(([key, value]) => {
        let messages = this._flattenErrorMessages(value);
        if(object.length(data) > 1) {
          messages = messages.map(message => {
            return format('%s: %s', string.capitalize(key), message);
          });
        }
        return messages;
      });
      return [].concat(...arrayOfArrays);

    } else {
      throw new errors.InvalidArgumentError(
        'Invalid error messages item: ' + JSON.stringify(data)
      );
    }
  }

  /**
   *
   * @param {Object} response - An angular.js response.
   * @returns {Object} Info about the links to related pages, based on the link header. Format:
   * {
   *   first: {Link},
   *   [prev]: {Link},
   *   current: {Link},
   *   [next]: {Link},
   *   last: {Link},
   *   totalCount: number
   * }
   *
   * totalCount is the total number of results on this API endpoint, derived from the X-Total-Count
   * header. Each Link is an object with format:
   * {
   *   url: string<uri>
   *   page: number
   * }
   *
   */
  _parseResponseLink(response) {
    let urlObject = new URL(response.config.url);
    if(response.config.params) {
      object.forEach(
        response.config.params, (key, value) => urlObject.searchParams.append(key, value)
      );
    }
    let url = urlObject.toString();


    let link = {
      current: this._parseLinkUrl(url),
      totalCount: parseInt(response.headers('X-Total-Count')),
    };

    let linkHeader = response.headers('Link');
    if(linkHeader) {
      let linkParts = linkHeader.split(/,\s*</);
      linkParts.forEach(part => {
        let match = part.match(/<?([^>]+)>;\s*rel="([^"]+)"/);
        let url = match[1];
        let rel = match[2];
        link[rel] = this._parseLinkUrl(url);
      });
    }

    response.link = link;
    return response;
  }

  _parseLinkUrl(url) {
    return {
      url: url.toString(),
      page: parseInt(new URLSearchParams(url).get('page')) || 1,
    };
  }

  cancel(promise) {
    return this.requestService.cancel(promise.__requestPromise);
  }
}
