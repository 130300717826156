import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SegmentedButtonComponent } from './segmented-button.component';
import { DurationPickerComponent } from './duration-picker/duration-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    SegmentedButtonComponent,
    DurationPickerComponent,
  ],
  exports: [
    SegmentedButtonComponent,
    DurationPickerComponent,
  ],
})
export class SegmentedButtonModule {}
