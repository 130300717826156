// Based on https://github.com/incuna/angular-bind-html-compile

BindCompileDirective.$inject = [
  '$compile',
];

export default function BindCompileDirective(
  $compile
) {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attrs) {

      $scope.$watch(() => $scope.$eval($attrs.bindCompile), onValue);

      function onValue(value) {
        if(value) {
          // In case value is a TrustedValueHolderType, sometimes it needs to be explicitly called
          // into a string in order to get the HTML string.
          let template = value && value.toString();
          $elem.html(template);
          $compile($elem.contents())($scope);
        } else {
          $elem.empty();
        }
      }
    }
  };
}
