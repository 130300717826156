import template from './testAudioInput.html?raw';

import { bind } from 'utils/util';


const TEST_DURATION = 3000;



class TestAudioInputController {
  static get $inject() {
    return [
      '$element',
      'soundFactory',
    ];
  }

  constructor(
    $elem,
    soundFactory
  ) {
    bind(this);

    this.$elem = $elem;
    this.soundFactory = soundFactory;

    this.testing = false;
    this.recording = false;
    this.testStart = 0;
    this.recorder = null;
    this.testSound = null;
    this.chunks = [];

    this.$elemProgress = null;

    this.stopRecordingTimeout = null;
    this.stopPlaybackTimeout = null;
  }


  /*******************
   * Setup & general *
   *******************/

  $onInit() {
    this.$elemBars = this.$elem.find('.settings__test-audio-level');
    this.$elemProgress = this.$elem.find('.settings__test-audio-progress');
  }


  $onDestroy() {
    if(this.testing) {
      this.stopTesting();
    }
  }


  startTesting() {
    if(!this.stream) {
      return;
    }

    this.testing = true;
    this._startRecording();
    this._draw();
  }


  stopTesting() {
    this.testing = false;
    if(this.recording) {
      this._stopRecording();
    } else {
      this._stopPlayback();
    }
  }


  _startRecording() {
    // This method must be called during handling of a user gesture

    if(!this.stream || !this.stream.mediaStream) {
      return;
    }

    // This is necessary for the recording to play on iPad.
    this.testSound = this.soundFactory.create(null, { ignoreErrors: true });
    this.testSound.play();

    this.recording = true;
    this.testStart = Date.now();

    // eslint-disable-next-line compat/compat
    this.recorder = new MediaRecorder(this.stream.mediaStream);
    this.recorder.addEventListener('dataavailable', this._onDataAvailable);
    this.recorder.addEventListener('stop', this._onStop);
    this.recorder.start();

    this.stopRecordingTimeout = $timeout(this._stopRecording, TEST_DURATION);
  }


  _stopRecording() {
    this.recording = false;
    $timeout.cancel(this.stopRecordingTimeout);
    if(!this.recorder) {
      return;
    }

    this.recorder.stop();
  }


  _onDataAvailable(event) {
    this.chunks.push(event.data);
  }

  _onStop() {
    let blob = new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' });
    this.chunks = [];
    if(this.testing)  {
      let audioURL = URL.createObjectURL(blob);
      this._startPlayback(audioURL);
    }
  }



  _startPlayback(audioUrl) {
    this.testSound.setUrl(audioUrl);
    this.testSound.play();
    this.testStart = Date.now();

    this.stopPlaybackTimeout = $timeout(this.stopTesting, TEST_DURATION);
  }


  _stopPlayback() {
    $timeout.cancel(this.stopPlaybackTimeout);
    if(this.testSound) {
      this.testSound.stop();
      this.testSound = null;
    }
  }


  _draw() {
    if(!this.testing) {
      return;
    }

    if(this.$elemProgress) {
      this.$elemProgress.css(
        'width', (Date.now() - this.testStart) / TEST_DURATION * 100 + '%');
    }

    window.requestAnimationFrame(this._draw);
  }
}


export default {
  controller: TestAudioInputController,
  controllerAs: 'testAudioInputCtrl',
  template,

  bindings: {
    stream: '<',
  },
};
