import { EventEmitter, storage } from 'utils/util';

const BACKGROUND_SETTING_KEY = 'BACKGROUND_SETTING';

export default class SegmentationSettingsService {
  static get $inject() {
    return [
      'userService',
      'meetingService'
    ];
  }

  constructor(
    userService,
    meetingService
  ) {
    this.userService = userService;
    this.meetingService = meetingService;

    EventEmitter.setup(this, ['resetStream']);

    this.BackgroundStyle = Object.freeze({
      NONE: 'none',
      BLUR: 'blur',
      MASK: 'mask',
    });

    this.BackgroundStyleLabel = Object.freeze({
      'none': gettext('No background'),
      'blur': gettext('Blurred background'),
      'mask': gettext('Image background'),
    });
  }

  setBackgroundStyle(value) {
    if(value === this.backgroundSetting) {
      return;
    }
    if(value === this.BackgroundStyle.MASK && !this.backgroundImageAvailable) {
      return;
    }

    storage.setItem(BACKGROUND_SETTING_KEY, value);
    this.emit('resetStream', value);
  }

  getBackgroundSettingLabel(backgroundSetting) {
    return gettextCatalog.getString(this.BackgroundStyleLabel[backgroundSetting]);
  }

  get backgroundSetting() {
    let bgValue = storage.getItem(BACKGROUND_SETTING_KEY);
    if(
      (bgValue === this.BackgroundStyle.MASK && !this.backgroundImageAvailable)
      || !Object.values(this.BackgroundStyle).includes(bgValue)
    ) {
      // If the current settings is image background with no background image available or the
      // setting is not a possible background style, set the setting to NONE.
      storage.setItem(BACKGROUND_SETTING_KEY, this.BackgroundStyle.NONE);
      return this.BackgroundStyle.NONE;
    } else {
      return bgValue || this.BackgroundStyle.NONE;
    }
  }

  get imageBackgroundTooltip() {
    let tooltip;
    if(this.userService.mySession.user.isLazy) {
      tooltip = gettextCatalog.getString('This feature is not available for guest users.');
    // eslint-disable-next-line max-len
    } else if(this.userService.get(this.meetingService.ownerId).organization.subscription.status === 'free') {
      tooltip = gettextCatalog.getString('Upgrade your subscription to access this feature.');
    } else {
      tooltip = gettextCatalog.getString(
        'A background image can be set by an admin in the team settings.'
      );
    }
    return tooltip;
  }

  get shouldSegment() {
    return this.backgroundSetting !== this.BackgroundStyle.NONE;
  }

  get backgroundImageAvailable() {
    let org = this.userService.mySession.user.organization;
    return org.cameraBackgroundImage !== null;
  }

}
