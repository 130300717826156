import {
  ChangeDetectorRef,
  Component, Inject, Input, OnChanges, SimpleChanges
} from '@angular/core';
import { ContactForm } from 'contactForm/models/ContactForm';
import { QuestionType } from 'contactForm/models/ContactFormQuestion';
import { ContactFormResponse } from 'contactForm/models/ContactFormResponse';
import { Errors } from 'utils/settings/settings.component';
import { bind, errors } from 'utils/util';
import { ViewOrganizationService } from 'utils/view-organization.service';
import * as contactFormStyling from '../utils/styling';
import * as cfCustomization from '../utils/customization';


export type View = 'form' | 'success';


const freeCustomizationFields: (keyof cfCustomization.Customization)[] = [
  'introductionText',
  'sendButtonText',
  'successTitle',
  'successText',
  'successImage',
];


@Component({
  selector: 'contact-form-page[contactForm]',
  templateUrl: './contact-form-page.component.html',
  styleUrls: ['./contact-form-page.component.scss'],
})
export class ContactFormPageComponent implements OnChanges {
  @Input() public contactForm: ContactForm;
  @Input() public view: View = 'form';
  @Input() public isEmbedded = false;
  @Input() public isContactWidgetEmbedded = false;
  @Input() public readonly = false;
  @Input() public hideRecaptcha = false;

  public errors: Errors = {};
  public isSubmitting = false;

  private fontsElement?: HTMLLinkElement;

  constructor(
    @Inject('notificationService') private notificationService,
    public viewOrganizationService: ViewOrganizationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    bind(this);
  }

  get shouldShowLogo() {
    return !this.isEmbedded || this.isContactWidgetEmbedded;
  }

  get shouldShowPoweredByOnForm() {
    const showPoweredBySetting = (
      this.viewOrganizationService.organization.whitelabelSettings.showPoweredBy
    );
    const hasCustomLogo = (
      !!this.viewOrganizationService.organization.whitelabelSettings.customLogo
    );
    return showPoweredBySetting && (hasCustomLogo || !this.shouldShowLogo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.contactForm) {
      // This should only happen while previewing the form
      const fontEvents = 'fontFamily fontWeight titleFontFamily titleFontWeight';
      changes.contactForm.previousValue?.off(fontEvents, this.injectFonts);
      changes.contactForm.currentValue?.on(fontEvents, this.injectFonts);
      this.injectFonts();
    }
  }


  /*****************
   * Customization *
   *****************/

  public get customization(): cfCustomization.Customization {
    const fullCustomization = cfCustomization.buildCustomizationFromInstance(this.contactForm);
    if(this.viewOrganizationService.organization.subscription.status === 'free') {
      const customization = { ...cfCustomization.defaultCustomization };
      for(const field of freeCustomizationFields) {
        customization[field] = fullCustomization[field] as any;
      }
      return customization;

    } else {
      return fullCustomization;
    }
  }

  get style() {
    return contactFormStyling.getStyleMapping(this.customization);
  }


  injectFonts() {
    type FontSpec = [string, string];

    if(this.fontsElement) {
      this.fontsElement.remove();
      this.fontsElement = undefined;
    }

    const customization = this.customization;
    const defaultCustomization = cfCustomization.defaultCustomization;
    const googleFonts: FontSpec[] = [];
    if(customization.fontFamily !== defaultCustomization.fontFamily) {
      googleFonts.push([customization.fontFamily, customization.fontWeight]);
    }
    if(customization.titleFontFamily !== defaultCustomization.titleFontFamily) {
      googleFonts.push([customization.titleFontFamily, customization.titleFontWeight]);
    }

    if(googleFonts.length > 0) {
      const fontParams = googleFonts.map(([family, weight]) => {
        return [
          'family',
          `${family.replace(/ /g, '+')}:wght@${weight}`,
        ];
      });
      const params = [
        ['display', 'swap'],
        ...fontParams,
      ];
      const paramString = params.map(([key, value]) => `${key}=${value}`).join('&');
      const url = `https://fonts.googleapis.com/css2?${paramString}`;

      this.fontsElement = document.createElement('link');
      this.fontsElement.rel = 'stylesheet';
      this.fontsElement.href = url;
      document.head.appendChild(this.fontsElement);
    }
  }



  /**********
   * Submit *
   **********/

  async submit(contactFormResponse: ContactFormResponse) {
    if(this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    try {
      await contactFormResponse.create();
      if(this.contactForm.redirectUrl !== '') {
        if(this.isEmbedded && window.top) {
          window.top.location.href = this.contactForm.redirectUrl;
        } else {
          window.location.href = this.contactForm.redirectUrl;
        }
      } else {
        this.view = 'success';
      }
    } catch(error: any) {
      this.handleSaveError(error);
    }
    this.isSubmitting = false;
    this.changeDetectorRef.detectChanges();
  }


  handleSaveError(error) {
    if(error.constructor === errors.RequestTooLargeError) {
      const errors = this.contactForm.questions.map(question => {
        if(question.type === QuestionType.ATTACHMENT) {
          return [$localize `The selected file(s) are too large.`];
        } else {
          return [];
        }
      });
      this.errors = {
        answers: errors,
      };

    } else {
      this.errors = this.notificationService.handleError(
        error,
        $localize `Something went wrong while submitting your form.`,
        'page',
      );
    }
  }
}
