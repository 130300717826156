import { errors } from 'utils/util';


/**
 * When the total canvas memory use is too high, getContext() may return null. This is most easily
 * reproducible in Safari (see https://codepen.io/anon/pen/rgGBJq to reproduce), but we've also
 * had a report from a user using Chrome on Mac OS.
 *
 * To handle this, we patch the default canvas.getContext() implementation, so that when getting
 * the context fails:
 *   - We return an object that behaves like a Context instance, but doesn't actually do anything.
 *     We need this because we don't always control the code that will use the context (i.e.
 *     fabric.js or pdf.js), and if we don't return something that behaves like a Context, that
 *     third-party code will start throwing all sorts of errors.
 *   - We show a notification that tells the user they have too many whiteboards, since this is
 *     usually the what causes this high memory usage.
 *
 * For our own code, we also provide getContextUnsafe that throws a GetContextFailedError when
 * getting the context fails. This is e.g. used in places where we use a canvas for video
 * processing, because we want to show a different error message there.
 */
export default class CanvasPolyfillService {
  static get $inject() {
    return [
      'notificationService',
    ];
  }

  constructor(
    notificationService
  ) {
    this.notificationService = notificationService;
    this.hasNotified = false;

    const that = this;

    /* eslint-disable no-invalid-this */
    function getContext(...args) {
      let context = this.getContextOrig(...args);
      if(!context) {
        that._notifyBrokenContext();
        context = that._createDummyContext(this);
      }
      return context;
    }


    function getContextUnsafe(...args) {
      let context = this.getContextOrig(...args);
      if(!context) {
        throw new errors.GetContextFailedError();
      }
      return context;
    }


    function getDummyContext() {
      return that._createDummyContext(this);
    }
    /* eslint-enable no-invalid-this */

    HTMLCanvasElement.prototype.getContextOrig = HTMLCanvasElement.prototype.getContext;
    HTMLCanvasElement.prototype.getContext = getContext;
    HTMLCanvasElement.prototype.getContextUnsafe = getContextUnsafe;
    HTMLCanvasElement.prototype.getDummyContext = getDummyContext;

    if(window.OffscreenCanvas) {
      const OffscreenCanvas = window.OffscreenCanvas;
      OffscreenCanvas.prototype.getContextOrig = OffscreenCanvas.prototype.getContext;
      OffscreenCanvas.prototype.getContext = getContext;
      OffscreenCanvas.prototype.getContextUnsafe = getContextUnsafe;
      OffscreenCanvas.prototype.getDummyContext = getDummyContext;
    }
  }


  _createDummyContext(canvas) {
    const noop = () => {};
    const gradient = {
      addColorStop: noop,
    };
    const imageData = new ImageData(1, 1);
    const pattern = {
      setTransform: noop,
    };

    return {
      // Properties
      canvas: canvas,
      direction: 'ltr',
      filter: 'none',
      fillStyle: '#000000',
      font: '10px sans-serif',
      fontKerning: 'auto',
      fontStretch: 'normal',
      fontVariantCaps: 'normal',
      globalAlpha: 1,
      globalCompositeOperation: 'source-over',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'low',
      letterSpacing: '0px',
      lineCap: 'butt',
      lineDashOffset: 0,
      lineJoin: 'miter',
      lineWidth: 1,
      miterLimit: 10,
      shadowBlur: 0,
      shadowColor: 'rgba(0, 0, 0, 0)',
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      strokeStyle: '#000000',
      textAlign: 'start',
      textBaseline: 'alphabetic',
      textRendering: 'auto',
      wordSpacing: '0px',

      // Functions
      arc: noop,
      arcTo: noop,
      beginPath: noop,
      bezierCurveTo: noop,
      clearRect: noop,
      clip: noop,
      closePath: noop,
      createConicGradient: () => gradient,
      createImageData: () => imageData,
      createLinearGradient: () => gradient,
      createPattern: () => pattern,
      createRadialGradient: () => gradient,
      drawFocusIfNeeded: noop,
      drawImage: noop,
      ellipse: noop,
      fill: noop,
      fillRect: noop,
      fillText: noop,
      getContextAttributes: () => ({
        alpha: true,
        colorSpace: 'srgb',
        desynchronized: false,
        willReadFrequently: false,
      }),
      getImageData: () => imageData,
      getLineDash: () => [],
      getTransform: () => new DOMMatrix(),
      isContextLost: () => false,
      isPointInPath: () => false,
      isPointInStroke: () => false,
      lineTo: noop,
      measureText: () => ({
        width: 0,
      }),
      moveTo: noop,
      putImageData: noop,
      quadraticCurveTo: noop,
      rect: noop,
      reset: noop,
      resetTransform: noop,
      restore: noop,
      rotate: noop,
      roundRect: noop,
      save: noop,
      scale: noop,
      setLineDash: noop,
      setTransform: noop,
      stroke: noop,
      strokeRect: noop,
      strokeText: noop,
      transform: noop,
      translate: noop,
    };
  }


  _notifyBrokenContext() {
    if(this.hasNotified) {
      return;
    }
    this.hasNotified = true;

    this.notificationService.error(gettextCatalog.getString(`
      It appears your browser can't handle the amount of whiteboards in this meeting room.
      Consider removing some unused whiteboards, and reload the page to continue.
    `), -1);
  }
}
