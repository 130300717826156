import Model from 'utils/models';
import * as fields from 'utils/fields';
import { User } from 'core/models/User';

export class SchedulingConfig extends Model {
  static get fields() {
    return {
      user: new fields.RelatedField({
        Model: User,
        readOnly: true,
      }),
      state: new fields.StringField(),
      providerId: new fields.StringField(),
      provider: new fields.StringField(),
      readCalendars: new fields.StringField(),
      writeCalendar: new fields.StringField(),
      providerCalendars: new fields.StringField(),
      timezone: new fields.StringField(),
    };
  }

  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers) {
    throw Error('SchedulingConfig does not have a list endpoint');
  }

  get createPath() {
    return `users/${this.userId}/schedulingConfig`;
  }

  static readPath(identifiers) {
    return `users/${identifiers.userId}/schedulingConfig`;
  }

  get updatePath() {
    return `users/${this.userId}/schedulingConfig`;
  }

  static get defaultInclude() {
    return ['providerCalendars'];
  }

  get disconnectPath() {
    return `${this.updatePath}/disconnect`;
  }

  disconnect() {
    let apiConfig = this._buildFullApiOptions();
    return this.apiService.post(this.disconnectPath, apiConfig)
      .then(response => {
        this.setData(response.data);
        return response;
      });
  }
}
