import template from './footerPhone.html?raw';
import templateDropdownEndCall from './endCall.dropdown.html?raw';

import { bind } from 'utils/util';
import { StreamType } from  'meeting/meeting-room/stream';
import Dropdown from 'utils/angularjs/dropdown/Dropdown';
const END_CALL_DROPDOWN_ID = 'endCall';


export class FooterPhoneController {
  static get $inject() {
    return [
      '$element',
      'browserService',
      'userService',
      'meetingService',
      'streamService',
      'userStreamService',
      'dropdownService',
      'endCallService',
      'raiseHandService',
    ];
  }

  constructor(
    $elem,
    browserService,
    userService,
    meetingService,
    streamService,
    userStreamService,
    dropdownService,
    endCallService,
    raiseHandService
  ) {
    bind(this);

    this.$elem = $elem;
    this.browserService = browserService;
    this.userService = userService;
    this.meetingService = meetingService;
    this.streamService = streamService;
    this.userStreamService = userStreamService;
    this.dropdownService = dropdownService;
    this.endCallService = endCallService;
    this.raiseHandService = raiseHandService;

    this.dropdownService.register(
      END_CALL_DROPDOWN_ID,
      templateDropdownEndCall,
      {
        position: Dropdown.Position.TOP,
        cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding',
      }
    );

    this.localAudioStream = null;

    if(!this.meetingService.isTemplate) {
      this.$elemEndCall = this.$elem.find('#meeting__leave, #meeting__leave-phone');
      this.$elemEndCall.on('touchend', this.onEndCallTouchEnd);
      this.$elemEndCall.on('mouseenter', this.onEndCallMouseEnter);
      this.$elemEndCall.on('mouseleave', this.onEndCallMouseLeave);
      this.$elemEndCall.on('click', this.onEndCallClick);
    }
    this.endCallIsTouchEvent = false;
    this._endCallMouseIsEntered = false;

    this.streamService.on('add remove', this._updateLocalAudioStream);
    this._updateLocalAudioStream();
  }


  get me() {
    return this.userService.me;
  }


  _updateLocalAudioStream() {
    let mySession = this.userService.mySession;
    let localStreams = this.streamService.getWithType(mySession, StreamType.AUDIO);
    this.localAudioStream = Object.values(localStreams)[0];
  }



  /************
   * End call *
   ************/

  get endCallMouseIsEntered() {
    return this._endCallMouseIsEntered;
  }
  set endCallMouseIsEntered(value) {
    this._endCallMouseIsEntered = value;
    $timeout(() => this._updateEndCallDropdown());
  }


  _updateEndCallDropdown() {
    let shouldShow = (this.userService.mySession.accessLevel.isHost && this.endCallMouseIsEntered);
    if(shouldShow) {
      let scope = {
        'footerCtrl': this,
      };
      this.dropdownService.show(END_CALL_DROPDOWN_ID, this.$elemEndCall, scope);
    } else {
      this.dropdownService.hide(END_CALL_DROPDOWN_ID);
    }
  }


  onEndCallTouchEnd() {
    this.endCallIsTouchEvent = true;
    $timeout(() => this.endCallIsTouchEvent = false, 200);
  }

  onEndCallMouseEnter() {
    if(!this.endCallIsTouchEvent) {
      this.endCallMouseIsEntered = true;
    }
  }

  onEndCallMouseLeave() {
    if(!this.endCallIsTouchEvent) {
      this.endCallMouseIsEntered = false;
    }
  }

  onEndCallClick() {
    if(this.meetingService.isTemplate) {
      return;
    }
    if(
      !this.userService.mySession.accessLevel.isHost
      || !this.endCallIsTouchEvent
      || this.endCallMouseIsEntered
    ) {
      this.endCall();
    } else {
      this.endCallMouseIsEntered = true;
    }
  }



  endCall() {
    this.endCallService.endCall();
    this.endCallMouseIsEntered = false;
  }

  endCallForEveryone() {
    this.endCallService.endCallForEveryone();
    this.endCallMouseIsEntered = false;
  }

  endCallForEveryoneButMe() {
    this.endCallService.endCallForEveryoneButMe();
    this.endCallMouseIsEntered = false;
  }
}


export default {
  controller: FooterPhoneController,
  controllerAs: 'footerPhoneCtrl',
  template,
};
