export const DROPDOWN_ID_INVITE = 'inviteNewMember';



export default class InviteNewMemberController {
  static get $inject() {
    return [
      '$scope',
      '$element',
      'memberService',
      'dropdownService',
    ];
  }

  constructor(
    $scope,
    $elem,
    memberService,
    dropdownService
  ) {
    this._bind();

    this.$scope = $scope;
    this.$elem = $elem;
    this.dropdownService = dropdownService;
    this.memberService = memberService;

    this.user = null;
    this.message = this.memberService.defaultInviteMessage;

    this.dropdownService.on('visible', this._onDropdownActive);
    this.tooltipText = '';
  }

  _bind() {
    this._onDropdownActive = this._onDropdownActive.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }


  get member() {
    return this.user ? this.memberService.getMember(this.user) : null;
  }

  get sendInviteStr() {
    if(this.member) {
      return gettextCatalog.getString('Resend invite');
    } else {
      return gettextCatalog.getString('Send invite');
    }
  }

  get permInviteStr() {
    if(!this.memberService.organization.permanentInviteLinkEnabled) {
      return gettextCatalog.getString('Create link');
    } else {
      return gettextCatalog.getString('Disable link');
    }
  }

  _onDropdownActive(dropdown) {
    if(dropdown.id === DROPDOWN_ID_INVITE) {
      this.$scope.$broadcast('searchUserFocus');
    }
  }

  onSearchResult(user) {
    this.user = user;
    this.$elem.find('textarea').focus();
  }

  validateSearchResult(user) {
    let member = this.memberService.getMember(user);
    return member && member.isAccepted ?
      [false, gettextCatalog.getString('Already a member of your team')] :
      [true, ''];
  }



  sendInvite() {
    let isResend = !!this.member;
    this.memberService.sendInvite(this.user, this.message, isResend);

    this.message = this.memberService.defaultInviteMessage;
    this.$scope.$broadcast('searchUserClear');
  }

  showTooltipSuccess() {
    this.tooltipText = gettextCatalog.getString('Copied to clipboard');
    this.hideTooltipAfterTimeout();
  }

  showTooltipError() {
    this.tooltipText = gettextCatalog.getString('Copy to clipboard failed');
    this.hideTooltipAfterTimeout();
  }

  hideTooltipAfterTimeout() {
    $timeout.cancel(this.hideTooltipTimeout);
    this.hideTooltipTimeout = $timeout(this.hideTooltip, 2500);
  }

  hideTooltip() {
    this.tooltipText = '';
  }
}
