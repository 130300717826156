import { Component, Input } from '@angular/core';


@Component({
  selector: 'pro-feature-lock[tooltip]',
  templateUrl: './pro-feature-lock.component.html',
  styleUrls: ['./pro-feature-lock.component.scss'],
})
export class ProFeatureLockComponent {

  @Input() tooltip!: string;

  constructor(
  ) {}
}
