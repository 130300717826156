import * as fields from 'utils/fields';
import Model from 'utils/models';
import { TimeRange, DateRange } from 'utils/util';
import { User } from 'core/models/User';



class AvailabilityField extends fields.Field {
  toRepresentation() {
    return this.value.map(listOfAvailabilityRanges => {
      return listOfAvailabilityRanges.map(availabilityRange => {
        return availabilityRange.toRepresentation();
      });
    });
  }

  toInternalValue(data) {
    return data.map(dataListOfAvailabilityRanges => {
      return dataListOfAvailabilityRanges.map(dataAvailabilityRange => {
        return TimeRange.toInternalValue(dataAvailabilityRange);
      });
    });
  }

}

class UnavailabilityField extends fields.Field {
  toRepresentation() {
    return this.value.map(dateRange => {
      return dateRange.toRepresentation();
    });
  }

  toInternalValue(data) {
    return data.map(dateRange => {
      return DateRange.toInternalValue(dateRange);
    });
  }
}



export class AppointmentTypeConfig extends Model {
  static get fields() {
    return {
      active: new fields.BooleanField(),
      availability: new AvailabilityField({
        default: AppointmentTypeConfig.getDefaultAvailability,
      }),
      user: new fields.RelatedField({
        Model: User,
      }),
      unavailability: new UnavailabilityField({
        default: []
      })
    };
  }

  static getDefaultAvailability() {
    return [
      [TimeRange.toInternalValue(['9:00', '17:00'])],
      [TimeRange.toInternalValue(['9:00', '17:00'])],
      [TimeRange.toInternalValue(['9:00', '17:00'])],
      [TimeRange.toInternalValue(['9:00', '17:00'])],
      [TimeRange.toInternalValue(['9:00', '17:00'])],
      [],
      [],
    ];
  }


  static get basePath() {
    return 'appointmentTypeConfigs';
  }


  toRepresentation(...args) {
    // This model can be nested inside an AppointmentType. In this case, the userId field is used
    // to identify the instance, so it always needs to be present on the representation.
    let data = super.toRepresentation(...args);
    data.userId = this.userId;
    return data;
  }
}
