import * as array from '../util/array';

export default function IfDeployEnvDirective() {
  return {
    restrict: 'A',

    link: function($scope, $elem, $attr) {
      let deployEnvs = $attr.ifDeployEnv.split(/ +/);
      if(!array.has(deployEnvs, ANGULAR_SCOPE.deployEnv)) {
        $elem.remove();
      }
    }
  };
}
