<div class="pro-feature-badge__container">
  <pro-feature-lock
    *ngIf="showIcon"
    tooltip="Upgrade your subscription to access this feature"
    i18n-tooltip
  ></pro-feature-lock>

  <span class="mr-4">{{ featureText }}</span>
  <a
    class="a-plain badge badge--marketing clickable"
    [href]="siteService.site.subscriptionLink"
    target="_blank"
  >
    <span [innerHTML]="planText"></span>
  </a>
</div>
