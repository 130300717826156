import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestUserService } from 'utils/requestUser.service';
import { StateService } from 'utils/state.service';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { bind, errors, logger, throttle } from 'utils/util';

import { MODEL_FACTORY_TOKEN } from 'utils/angularjs-upgraded-providers';


export const DashboardScope = Object.freeze({
  PERSONAL: new Scope('personal', $localize`Personal`),
  TEAM: new Scope('team', $localize`Team`),
  ALL: new Scope('all', $localize`All`)
});

type PerPage = 'all' | number;

/**
 * DataPageComponent is an abstract component that handles the management of API calls for a page
 * that displays data.
 *
 * The component template can optionally use the following components:
 *
 * scope switcher:
 * ```
 * <scope-switcher [(scope)]="scope" [scopes]="scopes"></scope-switcher>
 * ```
 *
 * search:
 * ```
 *  <div
 *    class="d-flex flex-1 position-relative"
 *  >
 *    <input
 *      class="input input--with-icon"
 *      [ngModel]="searchQuery"
 *      (ngModelChange)="setQuery($event)"
 *      placeholder="Search meeting types"
 *      i18n-placeholder
 *    />
 *    <svg-icon class="input__icon" [svgName]="'search'"></svg-icon>
 *  </div>
 * ```
 *
 * pagination:
 * ```
 * <pagination [(page)]="page" [numPages]="numPages"></pagination>
 * ```
 *
 * Any items in the component that re-fetch the instances, should use the `refetch()` method
 *
 * Errors should be communicated in the frontend by observing the fetchError property, preferably
 * using the <fetch-error> component
 */
@Component({
  template: '',
})
export abstract class DataPageComponent {
  public readonly fetchCanceled = new Subject<void>();
  // eslint-disable-next-line max-len
  public readonly unknownFetchError = $localize`Something went wrong while loading the page. Please try again later.`;
  public fetchError?: string;

  public perPage: PerPage = 'all';
  public readonly defaultPage = 1;
  public _page = this.defaultPage;
  public numPages = 1;

  public searchQuery;
  public refetchDebounced = throttle(this.refetch, 500, true);

  public DashboardScope = DashboardScope;
  public readonly defaultScope = DashboardScope.PERSONAL;
  private _scope: Scope = this.defaultScope;

  requestUserService = inject(RequestUserService);
  stateService = inject(StateService);
  location = inject(Location);
  modelFactory = inject(MODEL_FACTORY_TOKEN);


  constructor() {
    bind(this);

    // Give child constructors time to set up
    setTimeout(() => this.safeFetch());
  }

  /*********
   * Fetch *
   *********/

  /**
   * safeFetch will perform a fetch while setting State and handling errors.
   */
  private safeFetch() {
    this.fetchCanceled.next();

    const promises = [this.fetch()];
    if (this.stateService.isInitializing) {
      promises.push(this.fetchInitial());
    } else {
      this.stateService.setState(this.stateService.State.LOADING);
    }

    Promise.all(promises)
      .then(() => {
        this.stateService.setState(this.stateService.State.READY);
        this.handleSuccessfulFetch();
      })
      .catch(error => {
        if (!this.stateService.isInitializing) {
          this.stateService.setState(this.stateService.State.READY);
        }
        this.handleFetchError(error);
      });
  }

  refetch() {
    this.safeFetch();
  }

  setQuery(query) {
    this.searchQuery = query;
    this._page = 1;
    this.refetchDebounced();
  }

  /**
   * The fetch function is the primary entry point for the customization of the component. It will
   * be called on page load, and every time the scope or pagination settings change.
   *
   * - It must resolve when the fetch is complete.
   * - It must respect the current scope, searchQuery and pagination settings.
   * - It must observe `this.fetchCanceled` and cancel any ongoing fetches when it emits.
   */
  abstract fetch(): Promise<any>;

  /**
   * The fetchInitial function is only called one on page load. Components can optionally use it
   * to fetch resources that do not depend on scope or pagination settings.
   */
  fetchInitial(): Promise<any> {
    return Promise.resolve();
  }


  handleSuccessfulFetch() {
    delete this.fetchError;
  }


  handleFetchError(error) {
    if (error.constructor === errors.OfflineError) {
      this.fetchError = $localize`You seem to be offline. Reload the page to try again.`;
    } else {
      logger.error(error);
      this.fetchError = this.unknownFetchError;
    }
  }


  // Components can use these methods as a shortcut to call a modelFactory method that should be
  // canceled when fetchCanceled emits.

  listCancelable(modelConfig, apiConfig: any = {}) {
    const promise = this.modelFactory.list(modelConfig, apiConfig);
    this.fetchCanceled.subscribe(() => this.modelFactory.cancel(promise));
    return promise.then(({ data, response }) => {
      const page = apiConfig.params?.page || 1;
      this.numPages = response.link.last.page;
      if (page > this.numPages) {
        // This will trigger a new fetch
        this.page = this.numPages;
      }

      return { data, response };
    });
  }

  readCancelable(modelConfig, apiConfig = {}) {
    const promise = this.modelFactory.read(modelConfig, apiConfig);
    this.fetchCanceled.subscribe(() => this.modelFactory.cancel(promise));
    return promise;
  }


  /*********
   * Scope *
   *********/

  get scopes() {
    const scopes = [DashboardScope.PERSONAL];
    if (this.shouldShowTeamScope) {
      scopes.push(DashboardScope.TEAM);
    }
    if (this.shouldShowAllScope) {
      scopes.push(DashboardScope.ALL);
    }
    return scopes;
  }

  get shouldShowAllScope() {
    return false;
  }
  get shouldShowTeamScope() {
    return (
      !this.requestUserService.user.organization.isSolo
      && this.requestUserService.user.isAdmin
    );
  }

  public findScope(scopeId: string): Scope {
    return this.scopes.find((scope: Scope) => scope.id === scopeId) || DashboardScope.PERSONAL;
  }


  get scope(): Scope {
    return this._scope;
  }

  set scope(scope: Scope) {
    if (scope === this._scope) {
      return;
    }
    this._scope = scope;
    this.refetch();
  }


  /**************
   * Pagination *
   **************/

  get page() {
    return this._page;
  }

  set page(page: number) {
    if (page === this._page) {
      return;
    }
    this._page = page;
    this.refetch();
  }
}
