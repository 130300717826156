import { inject } from '@angular/core';
import { RequestUserService } from 'utils/requestUser.service';


export type IntegrationStatus = {
  activecampaign: IntegrationState,
  adminPulse: IntegrationState,
  hubspot: IntegrationState,
  salesflare: IntegrationState,
  stripe: IntegrationState,
  teamleader: IntegrationState
}

export enum IntegrationState {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  EXPIRED = 'expired',
  UNAUTHORIZED = 'unauthorized',
}

export abstract class IntegrationService {
  public isExpired = false;
  requestUserService = inject(RequestUserService);

  constructor(public integrationKey: keyof IntegrationStatus, public displayName: string) {
    // eslint-disable-next-line max-len
    const state = this.requestUserService.user.organization?.integrationStatus[this.integrationKey];
    this.setExpired(state === IntegrationState.EXPIRED);
  }

  get isConnected() {
    // eslint-disable-next-line max-len
    const state = this.requestUserService.user.organization?.integrationStatus[this.integrationKey];
    return state === IntegrationState.CONNECTED;
  }

  setExpired(value)  {
    this.isExpired = value;
  }
}
