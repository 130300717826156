import template from './dropFile.html?raw';
import { bind } from 'utils/util';


class DropFileController {
  static get $inject() {
    return [
      'uploadContentService',
      'userService',
      '$scope',
      '$element',
    ];
  }

  constructor(
    uploadContentService,
    userService,
    $scope,
    $elem
  ) {
    bind(this);

    this.uploadContentService = uploadContentService;
    this.userService = userService;

    this.$scope = $scope;
    this.$elem = $elem;
    this.$elemParent = $elem.parent();

    this.dragCount = 0;

    this._setupListeners();
    this._hide();
  }

  _setupListeners() {
    this.$elemParent.on('dragover', this._onDragOver);
    this.$elemParent.on('dragenter', this._onDragEnter);
    this.$elemParent.on('dragleave', this._onDragLeave);
    this.$elemParent.on('drop', this._onDrop);
  }


  _hide() {
    this.$elem.css('visibility', 'hidden');
  }

  _show() {
    if(this.userService.mySession.isSpectator) {
      this._hide();
    } else {
      this.$elem.css('visibility', 'visible');
    }
  }


  _containsFiles($event) {
    let items = $event.originalEvent.dataTransfer.items || [];
    return Array.from(items).some(item => item.kind === 'file' || item.type === 'text/uri-list');
  }


  _onDragOver($event) {
    $event.preventDefault();
  }


  _onDragEnter($event) {
    $event.preventDefault();
    this.dragCount++;

    if(this.dragCount === 1 && this._containsFiles($event)) {
      this._show();
    }
  }


  _onDragLeave($event) {
    $event.preventDefault();
    this.dragCount--;

    if(this.dragCount === 0) {
      this._hide();
    }
  }

  _onDrop($event) {
    $event.preventDefault();
    this.dragCount = 0;
    this._hide();

    if(this.userService.mySession.isSpectator) {
      return;
    }

    let dataTransfer = $event.originalEvent.dataTransfer;
    let files = dataTransfer.files;
    if(files && files.length) {
      files = Array.from(files);
      this.$scope.$apply(() => this.uploadContentService.uploadContent(files));
    } else {
      let url = dataTransfer.getData('text/uri-list');
      if(url) {
        let filename = url.match(/.*\/([^?]*)/)[1];
        let info = {
          url: url,
          filename: filename,
        };
        this.$scope.$apply(() => this.uploadContentService.uploadContent([info]));
      }
    }
  }
}



export default {
  controller: DropFileController,
  controllerAs: 'dropFileCtrl',
  template,
};
