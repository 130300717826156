import { Component, forwardRef, Input } from '@angular/core';
import { SimpleControlValueAccessor } from '../simple-control-value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { color } from 'utils/util';


@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent extends SimpleControlValueAccessor<string> {
  @Input('defaultColor') defaultColor = '#ffffff';
  public showValidation = false;

  onInputChange(color) {
    if(this.isValidColor()) {
      this.showValidation = false;
    }
    this.writeValue(color);
  }

  isEmptyValue() {
    return this.value == null || this.value === '';
  }

  isValidColor() {
    return this.isEmptyValue() || color.isValidHexColor(this.value);
  }

  get pickerColor() {
    if(this.isEmptyValue() || !this.isValidColor()) {
      return this.defaultColor;
    }

    return color.fullHexValue(this.value);
  }

  onBlur() {
    this.showValidation = true;
    this.onTouched();
  }
}
