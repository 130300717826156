import './init';

import FilePickerWorkerService from './filePickerWorker.service';
import DrivePickerService from './drivePicker.service';
import DropboxPickerService from './dropboxPicker.service';
import OneDrivePickerService from './oneDrivePicker.service';
import filePickerWorkerComponent from './filePickerWorker.component';

/**
 * Some of these pickers work with a whitelist of domains on which you can use your app key. This
 * means it's not possible to use these pickers on a custom domain. To circumvent this, we include
 * an iframe in the meeting room, the "file picker worker". This page is hosted on www.vectera.com,
 * regardless of the domain of the meeting room. Interaction between the meeting room and the file
 * picker worker happens through the `postMessage` API..
 */


angular
  .module('filePicker')

  .service('filePickerWorkerService', FilePickerWorkerService)
  .service('drivePickerService', DrivePickerService)
  .service('dropboxPickerService', DropboxPickerService)
  .service('oneDrivePickerService', OneDrivePickerService)

  .component('filePickerWorker', filePickerWorkerComponent);
