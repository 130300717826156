import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom, map, retry } from 'rxjs';

import { BASE_URL } from 'utils/base-url';
import { assertOrThrow } from 'utils/util';


type SerializedIncomingEvent = {
  id: string;  // UUID
  channel: string;
  sender: string;  // UUID
  timestamp: string;  // ISO format
  message: unknown;
};

export type IncomingEvent = {
  id: string | null;  // ID only for database-peristent events
  channel: string;
  senderId: string | null;  // Session ID or null if sent by back end
  datetime: Date,
  message: unknown;
};

export enum CachedSmartSummaryState {
  UNSTARTED = 'unstarted',
  RECORDING = 'recording',
  GENERATING_TRANSCRIPTION = 'generating-transcription',
  GENERATING_SUMMARY = 'generating-summary',
  SUMMARY_GENERATED = 'summary-generated',
  ERRORED = 'errored',
}
export type CachedMeetingState = {
  timestamp: Date;
  smartSummaryState: CachedSmartSummaryState | null,
};

type OutgoingSmartSummaryEvent = {
  type: string;
};


@Injectable()
export class SessionBackendService {
  constructor(
    private http: HttpClient,
    @Inject('userService') private userService,
  ) {}

  public async fetchEvents(maxReceivedId: string | null): Promise<IncomingEvent[]> {
    const sessionId = this.userService.mySession.id;
    const url = `${BASE_URL}/sessions/${sessionId}/events/`;
    let params = new HttpParams()
      .append('perPage', 'all')
      .append('nocache', Date.now());
    if (maxReceivedId != null) {
      params = params.append('gt', maxReceivedId);
    }
    return firstValueFrom(
      this.http.get<SerializedIncomingEvent[]>(url, { params }).pipe(
        retry(),
        map((events) => events.map(mapIncomingEventToFrontEndModel)),
      ),
    );
  }

  public fetchCachedMeetingState(): Promise<CachedMeetingState> {
    const sessionId = this.userService.mySession.id;
    const url = `${BASE_URL}/sessions/${sessionId}/state/`;
    return firstValueFrom(this.http.get<CachedMeetingState>(url).pipe(retry()));
  }

  public sendCachePersistentEvent(channel: string): Promise<void> {
    // Ideally, we'd type these events, but let's do some bare bones checking for now
    assertOrThrow(channel.startsWith('smart-summary'));

    const sessionId = this.userService.mySession.id;
    const url = `${BASE_URL}/sessions/${sessionId}/events/smartSummary`;
    const data: OutgoingSmartSummaryEvent = {
      type: channel,
    };
    return firstValueFrom(this.http.post<void>(url, data));
  }
}


function mapIncomingEventToFrontEndModel(serializedEvent: SerializedIncomingEvent): IncomingEvent {
  const { id, channel, sender, timestamp, message } = serializedEvent;
  return {
    id,
    channel,
    senderId: sender,
    datetime: new Date(timestamp),
    message,
  };
}
