import { format, errors, EventEmitter } from 'utils/util';
import Dropdown from './Dropdown';


export default class DropdownService {
  static get $inject() {
    return [
      '$compile',
      '$rootElement',
    ];
  }

  constructor(
    $compile,
    $rootElement
  ) {
    this._bind();
    EventEmitter.setup(this, ['active', 'inactive', 'visible']);

    this.$compile = $compile;
    this.$rootElement = $rootElement;

    this.dropdowns = {};
    this.active = {};
    this.rendered = {};

    // Inject the element after the angular.js bootstrap has been completed, otherwise the element
    // may be compiled twice: once by us and once during bootstrapping.
    $timeout(this._injectElem);
  }

  _bind() {
    this._injectElem = this._injectElem.bind(this);
  }


  _injectElem() {
    let $elem = angular.element('<dropdowns-deprecated></dropdowns-deprecated>');
    let $elemCompiled = this.$compile($elem)($rootScope);
    this.$rootElement.append($elemCompiled);
  }


  register(id, template, options) {
    if(!(id in this.dropdowns)) {
      this.dropdowns[id] = new Dropdown(id, template, options);
    }

    let dropdown = this.dropdowns[id];
    if(dropdown.template !== template) {
      throw new errors.IllegalStateError(format(
        'Dropdown "%s" has already been registered with different properties', id));
    }

    if(dropdown.preRender) {
      this.rendered[dropdown.id] = dropdown;
    }

    return dropdown;
  }


  get(id) {
    let dropdown = this.dropdowns[id];
    if(!dropdown) {
      throw new errors.InvalidArgumentError(format('Unknown dropdown id:', id));
    }

    return dropdown;
  }


  toggle(id, $elemOrEvent, scope) {
    let dropdown = this.get(id);
    if(dropdown.active) {
      this.hide(id);
    } else {
      this.show(id, $elemOrEvent, scope);
    }
  }


  show(id, $elemOrEvent, scope) {
    let dropdown = this.get(id);
    if(dropdown.active) {
      return;
    }

    Object.values(this.active).forEach(dropdown => {
      if(!dropdown.embeds.has(id)) {
        this.hide(dropdown.id);
      }
    });

    dropdown.show($elemOrEvent, scope);
    this.active[id] = dropdown;
    this.rendered[id] = dropdown;

    this.emit('active', dropdown);
  }


  hideAllExcept(exludedDropdownIds) {
    if(exludedDropdownIds == null) {
      exludedDropdownIds = [];
    }
    let dropdownsToHide = Object.values(this.dropdowns).filter(
      dropdown => (dropdown.active && !exludedDropdownIds.includes(dropdown.id))
    );
    dropdownsToHide.forEach(dropdown => this.hide(dropdown.id));
  }
  hideAll() {
    this.hideAllExcept();
  }


  hide(id) {
    let dropdown = this.get(id);
    if(!dropdown.active) {
      return;
    }

    dropdown.hide();
    delete this.active[id];
    if(!dropdown.preRender) {
      delete this.rendered[id];
    }

    this.emit('inactive', dropdown);
  }
}
