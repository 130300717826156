import { EventEmitter } from 'utils/util';


export default class Pointer {
  constructor(user) {
    EventEmitter.setup(this, ['position', 'click']);

    this.user = user;

    this.tile = null;
    this.position = null;

    this.clickTimeout = null;
  }


  setPosition(tile, position) {
    $timeout.cancel(this.clickTimeout);

    this.tile = tile;
    this.position = position;

    this.emit('position', this, tile, position);
  }

  click() {
    this.emit('click');
  }
}
