import { Janus as JanusBase } from 'janus-gateway';
import { bind, EventEmitter, logger } from 'utils/util';


const ABORT_OPEN_TIMEOUT = 10000;


export function promisifyJanus(fn, resolveProperty = 'success', rejectProperty = 'error') {
  return (argConfig) => $q((resolve, reject) => {
    const config = Object.assign({}, argConfig);
    if(resolveProperty) {
      config[resolveProperty] = resolve;
    }
    if(rejectProperty) {
      config[rejectProperty] = reject;
    }
    try {
      fn(config);
    } catch(error) {
      reject(error);
    }
  });
}

const initJanus = promisifyJanus(JanusBase.init.bind(JanusBase), 'callback', null);


const State = Object.freeze({
  NEW: 'new',
  INITIALIZED: 'initialized',
  DESTROYED: 'destroyed',
});

const EVENTS = Object.freeze([
  'state',
  'connectionTimeout',
  'error',
].concat(Object.values(State)));

const PING_INTERVAL = 4000;
const PING_TIMEOUT = 10000;  // Keep this in sync with session_timeout in janus.jcfg

const IGNORE_LOGS = [
  /^Sending data on data channel/,
  /^Received message on data channel/,
];


export default class Janus {
  static get State() {
    return State;
  }

  /**
   * Wrapper that promisifies the interface with Janus, and allows unregistring event handlers.
   */

  constructor() {
    bind(this);
    EventEmitter.setup(this, EVENTS);

    this.janus = null;
    this.ws = null;
    this.openTimeout = null;
    this.pingTimeout = null;

    this.state = State.NEW;
  }


  _apply(fn) {
    return (...args) => $rootScope.$evalAsync(() => fn(...args));
  }


  _setState(state) {
    if(state === this.state) {
      return;
    }

    this.state = state;
    this.emit('state', this.state);
    this.emit(this.state);
  }


  init(config) {
    return this._initJanus().then(() => this._instantiateJanus(config));
  }


  destroy() {
    if(this.janus && this._destroyJanus) {
      this._destroyJanus().catch(error => logger.info(error));
    }
    // this.janus.destroy() doesn't always resolve, so we need to call this._onDestroyed()
    // ourselves for quick recovery
    this._onDestroyed();
  }


  _initJanus() {
    const dependencies = JanusBase.useDefaultDependencies();
    dependencies.newWebSocket = (server, proto) => {
      this.ws = new WebSocket(server, proto);
      this.ws.addEventListener('message', this._onMessage);
      this.ws.addEventListener('open', this._onOpen);
      this.ws.addEventListener('close', this._onClose);

      this._cancelOpenTimeout();
      this.openTimeout = $timeout(this._onOpenTimeout, ABORT_OPEN_TIMEOUT);
      return this.ws;
    };


    return initJanus({
      dependencies: dependencies,
    }).then(() => {
      JanusBase.trace = angular.noop;
      JanusBase.debug = angular.noop;
      JanusBase.vdebug = angular.noop;
      JanusBase.log = (...args) => {
        for(const ignoreLogRe of IGNORE_LOGS) {
          if(args[0] && args[0].match && args[0].match(ignoreLogRe)) {
            return;
          }
        }
        logger.info(...args);
      };
      JanusBase.warn = logger.warn;
      JanusBase.error = logger.error;
    });
  }


  _instantiateJanus(argConfig) {
    return $q(resolve => {
      if(this.state === State.DESTROYED) {
        return;
      }

      const config = Object.assign(argConfig, {
        success: resolve,
        error: this._apply(this._onError),
        destroyed: this._apply(this._onDestroyed),

        keepAlivePeriod: PING_INTERVAL,
      });

      this.janus = new JanusBase(config);

      this.getServer = this.janus.getServer.bind(this.janus);
      this.isConnected = this.janus.isConnected.bind(this.janus);
      this.getSessionId = this.janus.getSessionId.bind(this.janus);
      this.attach = promisifyJanus(this.janus.attach.bind(this.janus));
      this._destroyJanus = promisifyJanus(this.janus.destroy.bind(this.janus));

      this._setState(State.INITIALIZED);
    });
  }


  _onError(error) {
    logger.warn('Janus error:', error);
    this.emit('error', error);
  }


  _onDestroyed() {
    this.janus = null;
    this.getServer = null;
    this.isConnected = null;
    this.getSessionId = null;
    this.attach = null;
    this._destroyJanus = null;

    $timeout.cancel(this.pingTimeout);
    this.pingTimeout = null;

    this._setState(State.DESTROYED);
  }



  /**********************************************************************
   * Proactively close the websocket connection if nothing is happening *
   **********************************************************************/

  _onOpen() {
    this._cancelOpenTimeout();
  }

  _onClose() {
    this.ws = null;
    this._cancelOpenTimeout();
  }

  _cancelOpenTimeout() {
    $timeout.cancel(this.openTimeout);
    this.openTimeout = null;
  }

  _onOpenTimeout() {
    logger.warn('Janus Websocket connect timed out');
    this._cancelOpenTimeout();
    if(this.ws) {
      this.ws.close();
    }
    this.emit('connectionTimeout');
  }



  _onMessage() {
    $timeout.cancel(this.pingTimeout);
    this.pingTimeout = $timeout(this._onPingTimeout, PING_TIMEOUT);
  }

  _onPingTimeout() {
    logger.warn('Janus ping timeout');
    this.emit('connectionTimeout');
  }
}
