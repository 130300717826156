import './angularjs-init';

import { downgradeComponent } from '@angular/upgrade/static';
import { HomeComponent } from './home';
import { MeetingRoomsComponent } from './meeting-rooms';
import { SidebarProfileComponent } from './sidebar-profile';
import { UIComponentsComponent } from './ui-components';
import { FormElementsComponent } from './form-elements';


angular
  .module('dashboard')

  .directive('home', downgradeComponent({ component: HomeComponent }))
  .directive('meetingRooms', downgradeComponent({ component: MeetingRoomsComponent }))
  .directive('sidebarProfile', downgradeComponent({ component: SidebarProfileComponent }))
  .directive('uiComponents', downgradeComponent({ component: UIComponentsComponent }))
  .directive('formElements', downgradeComponent({ component: FormElementsComponent }));
