import { bind, platform } from 'utils/util';


export default class TileController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    bind(this);

    this.$elem = $elem;
  }


  $onInit() {
    this.tile.setElem(this.$elem);
    this.tile.on('draw', this._draw);
    this._draw();
  }

  $onDestroy() {
    this.tile.removeElem(this.$elem);
    this.tile.off('draw', this._draw);
  }


  _draw() {
    let fontSize = platform(15, 13 + .01 * this.tile.rect.height, 22);
    this.$elem.css('--js-tile-overlay-font-size', fontSize + 'px');
  }
}
