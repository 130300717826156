import { DeviceKindToStreamType } from  'meeting/meeting-room/stream';
import MediaDevice from '../../shared/audioVideo/MediaDevice';
import { array, bind, format, object } from 'utils/util';

import { DEVICE_KINDS } from '../../shared/audioVideo/mediaDevice.service';

const NOTIFICATION_TEMPLATE = `
  <div ng-controller="MediaDeviceNotificationCtrl as ctrl">
    <p>
      %s
    </p>
    <div class="d-flex justify-content-end">
      <div
        class="btn mr-2"
        ng-click="ctrl.mediaDeviceNotificationService.cancelSwitchDevice()"
        translate
      >
        No
      </div>
      <div
        class="btn btn--primary"
        ng-click="ctrl.mediaDeviceNotificationService.switchDevice()"
        translate
      >
        Yes
      </div>
    </div>
  </div>
`;


export default class MediaDeviceNotificationService {

  static get $inject() {
    return [
      'mediaDeviceService',
      'notificationService',
      'streamService',
      'userService',
    ];
  }

  constructor(
    mediaDeviceService,
    notificationService,
    streamService,
    userService
  ) {
    bind(this);

    this.mediaDeviceService = mediaDeviceService;
    this.notificationService = notificationService;
    this.streamService = streamService;
    this.userService = userService;

    this.notification;
    this.notificationDevices = {};
    this.mySession = this.userService.mySession;

    this.streamService.on('add', this._onStreamAdd);
    this.mediaDeviceService.on('all', this._onDevices);
  }


  _onStreamAdd(stream) {
    if(stream.isLocal) {
      this._cancelNewDevicesNotification();
    }
  }


  _onDevices(added, removed, permissionChanged) {
    if(this.notification && !this.notification.active) {
      this._cancelNewDevicesNotification();
    }

    let newNotificationDevices = Object.assign({}, this.notificationDevices);

    DEVICE_KINDS.forEach(kind => {
      // Check the removed devices
      let notificationDeviceInfo = newNotificationDevices[kind];
      if(notificationDeviceInfo) {
        removed[kind].forEach(removedDevice => {
          if(removedDevice.id === notificationDeviceInfo.device.id) {
            this._cancelNewDevicesNotification();
            delete newNotificationDevices[kind];
          }
        });
      }


      // Check if we should add a notification
      let localStreams = [];
      let type = DeviceKindToStreamType[kind];
      if(type) {
        localStreams = Object.values(this.streamService.getWithType(this.mySession, type));
      }

      if(
        added[kind].length > 0
        && !permissionChanged[kind]
        && this.mediaDeviceService.permissions[kind]
        && (localStreams.length === 1 || kind === 'audiooutput')
      ) {
        newNotificationDevices[kind] = {
          stream: localStreams[0],
          device: added[kind][0]
        };
      }
    });

    this._updateNotification(newNotificationDevices);
  }


  _updateNotification(newNotificationDevices) {
    DEVICE_KINDS.forEach(kind => {
      if((kind in newNotificationDevices) !== (kind in this.notificationDevices)) {
        this._cancelNewDevicesNotification();
      }
    });

    if(object.length(newNotificationDevices) === 0) {
      return;
    }

    this.notificationDevices = newNotificationDevices;
    let deviceNames = Object.keys(this.notificationDevices)
      .map(kind => gettextCatalog.getString(MediaDevice.Name[kind]));

    let deviceNameStr = array.joinVerbose(deviceNames, ',', gettextCatalog.getString('and'));

    let notificationStr = gettextCatalog.getString(
      'You have connected a new {{ device }}. Do you want to switch to this device?',
      { device: deviceNameStr }
    );

    let message = format(NOTIFICATION_TEMPLATE, notificationStr);

    if(this.notification) {
      this.notification.setMessage(message);
      this.notification.setDelay(this.notificationService.DEFAULT_DELAY);

    } else {
      this.notification = this.notificationService.info(message);
    }
  }


  _cancelNewDevicesNotification() {
    if(this.notification) {
      this.notification.cancel();
      this.notification = null;
      this.notificationDevices = {};
    }
  }


  switchDevice() {
    Object.values(this.notificationDevices).forEach(deviceInfo => {
      this.mediaDeviceService.setPreferredDevice(deviceInfo.device);
    });

    this._cancelNewDevicesNotification();
  }


  cancelSwitchDevice() {
    this._cancelNewDevicesNotification();
  }
}
