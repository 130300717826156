import { format } from 'utils/util';


const TEMPLATE_WARNING = `
  <div class="mt-2 field field--warning" ng-show="showWarning">
    <div>%s</div>
  </div>
`;


ChangeWarningDirective.$inject = [
  '$compile',
];


export default function ChangeWarningDirective(
  $compile
) {
  return {
    restrict: 'A',
    scope: true,
    link: function($scope, $elem, $attr) {
      $scope.showWarning = false;
      $elem.on('change input', () => $scope.$evalAsync(update));
      update();

      let templateWarning = format(TEMPLATE_WARNING, $attr.enabledWarning);
      let $elemWarning = $compile(templateWarning)($scope);
      $elem.parents('.form-group').append($elemWarning);

      function update() {
        $scope.showWarning = $elem.is(':checked');
      }
    },
  };
}
