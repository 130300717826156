import {
  Component, OnInit, Inject, ViewChild, TemplateRef, ViewContainerRef
} from '@angular/core';
import { Errors, SettingsComponent } from 'utils/settings/settings.component';
import { TeamMembersService } from 'organization/teamMembers.service';
import {
  ComboBoxCategory, ComboBoxOption
} from 'utils/form-elements/dropdowns/combo-box/combo-box.component';
import { ContactForm } from 'contactForm/models/ContactForm';
import { ContactFormQuestion, QuestionType } from 'contactForm/models/ContactFormQuestion';
import {
  CRMEntityLabels, MappingFieldsService,
  QuestionConfigType
} from 'contactForm/contact-form-config/mapping-fields.service';
import { MappingField, CRMEntity, Provider } from 'contactForm/models/MappingField';
import {
  ContactFormAnswer, ContactFormPrefilledAnswer
} from 'contactForm/models/ContactFormAnswer';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { formatDate } from '@angular/common';
import { dateTime } from 'utils/util';
import { TemplatePortal } from '@angular/cdk/portal';
import { ModalService } from 'utils/ui-components/modal';
import { UrlService } from 'utils/url.service';
import { countryCodeToNameMap } from 'utils/util/countries';
import { languageCodeToNameMap } from 'utils/util/languages';

export type Pipeline = {
  id: string,
  name: string,
  isDefault: boolean,
}

type CurrentQuestion = {
  question: ContactFormQuestion,
  answer: ContactFormAnswer,
}

type InterfacePrefilledAnswer = {
  mappingField: MappingField,
  instance: ContactFormPrefilledAnswer
}


@Component({
  selector: 'contact-form-crm-settings[instance]',
  templateUrl: './contact-form-crm-settings.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactFormCrmSettingsComponent
  }]
})
export class ContactFormCrmSettingsComponent
  extends SettingsComponent<ContactForm>
  implements OnInit {

  public dealOwner;
  public dealOwnerOptions: ComboBoxOption[] = [];
  public pipelines: Pipeline[] | null = null;

  public dealPipelineOptions: ComboBoxOption[] = [];
  static override formFields = [
    'teamleaderMappingConfig.dealOwner',
    'teamleaderMappingConfig.dealPipelineId',
    'prefilledAnswers',
  ];

  public interfacePrefilledAnswers: InterfacePrefilledAnswer[] = [];
  public teamleaderMappingFieldComboBoxCategories: ComboBoxCategory[] = [];
  public currentQuestion: CurrentQuestion | null = null;
  public selectedMappingField: MappingField | null;

  public entityIcons: Record<CRMEntity, SvgIcon> = {
    [CRMEntity.CONTACT]: { name: 'contacts' },
    [CRMEntity.COMPANY]: { name: 'building_add' },
    [CRMEntity.DEAL]: { name: 'checkmark_add', style: 'badged_outline', },
  };
  public entityLabels!: Record<CRMEntity, string>;

  @ViewChild('modalConfigQuestion') modalConfigQuestion!: TemplateRef<any>;

  constructor(
    private teamMemberService: TeamMembersService,
    private mappingFieldsService: MappingFieldsService,
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
    public urlService: UrlService,

    @Inject('apiService') private apiService,
    @Inject('modelFactory') private modelFactory,
    @Inject('languageService') private languageService,
  ) {
    super();
    this.entityLabels = CRMEntityLabels;
  }

  get shouldShowPrefilledAnswers() {
    return ANGULAR_SCOPE.prefilledAnswersEnabled;
  }

  override ngOnInit() {
    super.ngOnInit();
    this.dealOwnerSetup();
    this.pipelineSetup();
    this.updateInterfaceAnswers();
  }

  override get hasPermissionToEdit() {
    const mappingConfig = this.instance?.teamleaderMappingConfig;
    return this.isAdmin
      || !mappingConfig?.contactForm?.id
      || mappingConfig?.contactForm?.createdById === this.requestUserService.user.id;
  }

  private dealOwnerSetup() {
    this.teamMemberService.get().then(teamMembers => {
      this.dealOwnerOptions = this.teamMemberService.buildComboBoxOptions(teamMembers);
      if(!this.instance.id) {
        this.setDefaultDealOwner(teamMembers);
      } else if(this.instance.id) {
        const member =  teamMembers.find(
          member => (
            member.isActive
            && member.isAccepted
            && member.id === this.instance.dealOwnerId
          )
        );
        if(!member) {
          this.formGroup.get('dealOwner')?.setValue(null);
        }
      }
    });
  }

  private setDefaultDealOwner(teamMembers) {
    const requestUserId = this.requestUserService.user.id;
    const member =  teamMembers.find(
      member => member.isActive && member.isAccepted && member.id === requestUserId
    );
    this.formGroup.get('dealOwner')?.setValue(member);
  }

  private async fetchPipelines(): Promise<Pipeline[]> {
    const response = await this.apiService.get('teamleader/pipelines/');
    return response.data as Pipeline[];
  }

  async pipelineSetup() {
    this.pipelines = await this.fetchPipelines();
    this.dealPipelineOptions = this.buildPipelineOptions();
    if(!this.instance?.teamleaderMappingConfig.id) {
      const defaultPipeline = this.pipelines.find(pl => pl.isDefault);
      if(defaultPipeline != null) {
        this.formGroup.get('dealPipelineId')?.setValue(defaultPipeline.id);
      }
    }
  }

  private buildPipelineOptions(): ComboBoxOption[] {
    if(this.pipelines == null) {
      return [];
    }
    return this.pipelines.map(pipeline => {
      return {
        value: pipeline.id,
        label: pipeline.name,
      };
    });
  }

  private async updateInterfaceAnswers() {
    await this.mappingFieldsService.get();
    const mappingFields = this.mappingFieldsService.mappingFields[Provider.TEAMLEADER];
    this.interfacePrefilledAnswers = this.instance.prefilledAnswers.map( a => {
      return {
        mappingField: mappingFields[a.extra.mappedFields.teamleader.id],
        instance: a
      } as InterfacePrefilledAnswer;
    });
    this.updateMappingFieldsInUse();
  }

  private updateInstanceAnswers() {
    this.instance.prefilledAnswers = this.interfacePrefilledAnswers.map(a => a.instance);
    this.updateMappingFieldsInUse();
  }

  private updateMappingFieldsInUse() {
    const mappedFields = new Set<MappingField>;
    this.interfacePrefilledAnswers.forEach(a => mappedFields.add(a.mappingField));
    this.mappingFieldsService.updateMappingFieldsInUse(
      Provider.TEAMLEADER, mappedFields, QuestionConfigType.PREFILLED
    );

  }

  addQuestion() {
    this.currentQuestion = {
      question: this.modelFactory.createInstance(ContactFormQuestion),
      answer: this.modelFactory.createInstance(ContactFormAnswer),
    };
    this.updateTeamleaderMappingFields();
    const templatePortal = new TemplatePortal(this.modalConfigQuestion, this.viewContainerRef);
    this.modalService.open({
      icon: { name: 'add' },
      title: $localize `Add a prefilled answer`,
      modalClass: 'modal--sm',
      disableClose: true,

      templatePortal: templatePortal,
    });
  }

  cancelQuestion(cancelButton) {
    this.selectedMappingField = null;
    this.currentQuestion = null;
    this.modalService.closeNearest(cancelButton);
  }

  submitQuestion(closeButton) {
    const prefilledAnswer = this.modelFactory.createInstance(
      ContactFormPrefilledAnswer,
      {
        values: {
          answer: this.currentQuestion?.answer.answer,
          type: this.currentQuestion?.question.type,
          extra: this.currentQuestion?.question.extra,
        },
      }
    );
    this.interfacePrefilledAnswers.push({
      mappingField: this.selectedMappingField,
      instance: prefilledAnswer
    });
    this.updateInstanceAnswers();
    this.selectedMappingField = null;
    this.currentQuestion = null;
    this.modalService.closeNearest(closeButton);
  }

  get canSubmitPrefilledAnswer() {
    if(
      this.currentQuestion?.question.type === QuestionType.ADDRESS
      && this.currentQuestion?.answer.answer != null
    ) {
      const address = this.currentQuestion?.answer.answer;
      return (
        address.country !== ''
        && address.street !== ''
        && address.number !== ''
        && address.zipcode !== ''
        && address.city !== ''
      );
    } else {
      return (
        this.currentQuestion?.answer.answer != null
        || this.currentQuestion?.answer.answer === ''
      );
    }
  }


  setQuestionType(option: MappingField) {
    if(this.currentQuestion) {
      if(option.isFallback) {
        this.currentQuestion.question.type = QuestionType.SINGLE_LINE;
      } else {
        this.currentQuestion.question.type = option.questionTypes[0];
      }
      this.currentQuestion.question.label = $localize `Prefilled answer for ${option.name}`;
      const extra: { [key: string]: any } = {
        mappedFields: {
          teamleader: {
            id: this.selectedMappingField.id
          }
        }
      };

      if(this.selectedMappingField?.extra?.options) {
        extra.options = this.selectedMappingField.extra.options.slice();
        extra.otherOption = this.selectedMappingField.extra.otherOption || false;
      }
      if(this.selectedMappingField?.extra?.currency) {
        extra.currency = this.selectedMappingField.extra.currency;
      }

      this.currentQuestion.question.extra = extra;
    }
  }

  updateTeamleaderMappingFields() {
    if(this.currentQuestion?.question ) {
      const isForPrefilledAnswers = true;
      this.teamleaderMappingFieldComboBoxCategories =
        this.mappingFieldsService.generateComboBoxCategories(
          this.instance.teamleaderMappingConfig,
          this.currentQuestion.question,
          isForPrefilledAnswers
        );
    }
  }

  removeAnswer(index: number) {
    if(index > -1) {
      this.interfacePrefilledAnswers.splice(index, 1);
      if(this.errors?.prefilledAnswers && this.errors?.prefilledAnswers.length) {
        (this.errors.prefilledAnswers as Errors[]).splice(index, 1);
      }
      this.updateInstanceAnswers();
      this.currentQuestion = null;
    }
  }

  getErrors(index: number) {
    if(this.errors?.prefilledAnswers) {
      return (this.errors?.prefilledAnswers[index].answer || {}) as { [field: string]: string[] };
    } else {
      return {};
    }
  }

  answerString(answer: ContactFormPrefilledAnswer) {
    switch(answer.type) {
      case QuestionType.ADDRESS:
        const address = answer.answer;
        let countryName = countryCodeToNameMap[address.country] ;
        if(countryName === undefined) {
          countryName = '';
        }
        // eslint-disable-next-line max-len
        return `${address.street} ${address.number}, ${address.zipcode} ${address.city}, ${countryName}`;
      case QuestionType.LANGUAGE:
        const language = answer.answer;
        return languageCodeToNameMap[language];
      case QuestionType.DATE:
        if(answer.answer instanceof Date) {
          return formatDate(answer.answer, 'yyyy-MM-dd', dateTime.getLanguageCode());
        } else {
          return answer.answer;
        }
      case QuestionType.MONEY:
        const money = answer.answer;
        if(money === undefined) {
          return '';
        } else if(money.amount) {
          // from API
          return `${money.amount} ${money.currency}`;
        } else {
          // locally configured
          return `${money} ${answer.extra.currency}`;
        }
      case QuestionType.ATTACHMENT:
        const attachment = answer.answer;
        return `${attachment[0][0]}`; // filename
      case QuestionType.YES_NO:
        const yesno = answer.answer;
        if(yesno === undefined) {
          return '';
        } else if(yesno === '1') {
          return $localize `Yes`;
        } else if(yesno === '0') {
          return $localize `No`;
        } else {
          return yesno;
        }
      case QuestionType.SINGLE_SELECT:
        return this.findAnswerLabelFromOptions(answer);
      default:
        return answer.answer;
    }
  }

  private findAnswerLabelFromOptions(answer: ContactFormPrefilledAnswer) {
    const label = answer.extra.options.find(opt => opt[0] === answer.answer);
    if(label == null && answer.answer) {
      return answer.answer;
    } else if(label.length && label.length > 0) {
      return label[1];
    } else {
      return '';
    }
  }
}
