import { I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DurationPipe } from './duration.pipe';
import { FormatStringArrayPipe } from './format-string-array.pipe';
import { HelpArticlePipe } from './help-article.pipe';
import { LocaleTimePipe } from './locale-time.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ReadableDatePipe } from './readable-date.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { TimeSincePipe } from './time-since.pipe';
import { FileSizePipe } from './file-size.pipe';
import { ReadableTimestampPipe } from './readable-timestamp.pipe';


const pipes = [
  CapitalizePipe,
  DurationPipe,
  FileSizePipe,
  FormatStringArrayPipe,
  HelpArticlePipe,
  LocaleTimePipe,
  ReadableDatePipe,
  ReadableTimestampPipe,
  SafeHtmlPipe,
  TimeSincePipe,
];

@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: [
    ...pipes,
    I18nPluralPipe,
  ],
})
export class PipesModule {}
