import { Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent extends ContactFormQuestionInputComponent<Date> {
  parsePrefill(string: string) {
    const value = new Date(string);
    if(isNaN(value.getTime())) {
      return null;
    } else {
      return value;
    }
  }
}
