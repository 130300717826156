import { Component } from '@angular/core';
import { RequestUserService } from 'utils/requestUser.service';
import { UrlService } from 'utils/url.service';


@Component({
  selector: 'vectera-sidebar-profile',
  templateUrl: './sidebar-profile.component.html',
})
export class SidebarProfileComponent {
  constructor(
    private requestUserService: RequestUserService,
    private urlService: UrlService,
  ) {}

  get user() {
    return this.requestUserService.user;
  }

  get profileUrl() {
    return this.urlService.urls.profile;
  }

  get logoutUrl() {
    return this.urlService.urls.logout;
  }
}
