import { EventEmitter } from 'utils/util';


export const Position = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
});

const DEFAULT_POSITION = 'bottom';
const DEFAULT_DELAY = 200;
const HIDE_DELAY = 100;


export default class TooltipService {
  static get $inject() {
    return [
      '$rootElement',
      '$compile',
    ];
  }

  get Position() {
    return Position;
  }

  constructor(
    $rootElement,
    $compile
  ) {
    this._bind();
    EventEmitter.setup(this, ['show', 'hide']);

    this.$rootElement = $rootElement;
    this.$compile = $compile;

    this.$elem = null;
    this.$elemNext = null;
    this.content = null;
    this.position = null;

    this.showTimeout = null;
    this.hideTimeout = null;

    // Inject the element after the angular.js bootstrap has been completed, otherwise the element
    // may be compiled twice: once by us and once during bootstrapping.
    $timeout(this._injectElem);
  }

  _bind() {
    this._injectElem = this._injectElem.bind(this);
    this._showNow = this._showNow.bind(this);
    this._hideNow = this._hideNow.bind(this);
  }


  _injectElem() {
    let $elem = angular.element('<tooltips></tooltips>');
    let $elemCompiled = this.$compile($elem)($rootScope);
    this.$rootElement.append($elemCompiled);
  }


  show($elem, content, position, delay) {
    if(!content) {
      this._hideNow();
      return;
    }

    if(position == null) {
      position = DEFAULT_POSITION;
    }
    if(delay == null) {
      delay = DEFAULT_DELAY;
    }
    if(
      $elem === this.$elem
      && content === this.content
      && position === this.position
    ) {
      return;
    }

    if(this.$elem) {
      delay = 0;
      if($elem !== this.$elem) {
        this._hideNow();
      }
    }

    this._cancelTimeouts();
    this.$elemNext = $elem;
    this.showTimeout = $timeout(this._showNow.bind(this, $elem, content, position), delay);
  }


  _showNow($elem, content, position) {
    this._cancelTimeouts();
    this.showTimeout = null;

    this.$elem = $elem;
    this.$elemNext = null;
    this.content = content;
    this.position = position;

    this.emit('show', $elem, content, position);
  }


  hide($elem) {
    if($elem === this.$elem || $elem === this.$elemNext) {
      this._cancelTimeouts();
      this.hideTimeout = $timeout(this._hideNow, HIDE_DELAY);
    }
  }


  _hideNow() {
    this._cancelTimeouts();
    this.hideTimeout = null;

    this.$elem = null;
    this.content = null;
    this.position = null;

    this.emit('hide');
  }


  _cancelTimeouts() {
    $timeout.cancel(this.showTimeout);
    this.$elemNext = null;
    $timeout.cancel(this.hideTimeout);
  }
}
