import { Injectable } from '@angular/core';
import { logger } from 'utils/util';

@Injectable({
  providedIn: 'root',
})
export class AudioContextService {
  public audioContext: AudioContext | null;

  constructor() {
    this._bind();

    if(AudioContext) {
      this.audioContext = new AudioContext();
      window.document.addEventListener('click', this._resumeAudioContext);
      window.document.addEventListener('touchend', this._resumeAudioContext);
    } else {
      logger.warn('could not create an AudioContext');
      this.audioContext = null;
    }
  }

  _bind() {
    this._resumeAudioContext = this._resumeAudioContext.bind(this);
  }


  get(): AudioContext | null {
    return this.audioContext;
  }

  _resumeAudioContext() {
    if(this.audioContext) {
      this.audioContext.resume();
      window.document.removeEventListener('click', this._resumeAudioContext);
      window.document.removeEventListener('touchend', this._resumeAudioContext);
    }
  }
}
