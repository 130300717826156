import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleControlValueAccessor } from '../simple-control-value-accessor';


export type SegmentedButtonOptionFull = {
  label: string;
  value: any;
};
export type SegmentedButtonOption = SegmentedButtonOptionFull | string;

@Component({
  selector: 'segmented-button[options]',
  templateUrl: './segmented-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentedButtonComponent),
      multi: true,
    },
  ],
})
export class SegmentedButtonComponent
  extends SimpleControlValueAccessor<any>
  implements OnChanges {

  @Input('options') _options!: readonly SegmentedButtonOption[];
  @Input('nullable') _nullable = false;

  public options: SegmentedButtonOptionFull[] = [];


  ngOnChanges(changes: SimpleChanges) {
    if(changes._options) {
      this.buildOptions();
    }

    // Mimic the behaviour of a select element: select the first element by default.
    // When using a ControlValueAccessor without a form, the first call to writeValue is with value
    // null, regardless of the actual value. In this case, we want to avoid setting the value to
    // a default. We can work around that with setTimeout, but calling setTimeout directly in
    // ngOnChanges can lock up the browser if it is called every digest cycle, so we need to do the
    // shouldSetFirstOption check twice.
    if(this.shouldSetFirstOption) {
      setTimeout(() => {
        if(this.shouldSetFirstOption) {
          this.writeValue(this.options[0].value);
        }
      });
    }
  }


  get isNullable() {
    return (
      this._nullable
      || this.options.some(option => option.value == null)
    );
  }

  private get shouldSetFirstOption() {
    return !this.isNullable && this.value == null && this.options.length > 0;
  }


  buildOptions() {
    this.options = this._options.map(option => {
      if(typeof option === 'string') {
        return {
          label: option,
          value: option,
        };
      } else {
        return option;
      }
    });
  }


  segmentedButtonTrackBy(index: number, option: SegmentedButtonOptionFull) {
    return option.value;
  }


  isSelected(option: SegmentedButtonOptionFull) {
    return option.value === this.value;
  }

  toggleOption(option: SegmentedButtonOptionFull) {
    if(!this.isSelected(option)) {
      this.writeValue(option.value);

    } else if(this.isNullable) {
      this.writeValue(null);
    }
  }
}
