import { logger } from 'utils/util';

export default class LogoService {
  static get $inject() {
    return [
      'meetingService',
      'fileService',
      'meetingBroadcastService',
      'resetService'
    ];
  }

  constructor(
    meetingService,
    fileService,
    meetingBroadcastService,
    resetService
  ) {
    this._bind();
    this.meetingService = meetingService;
    this.fileService = fileService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.resetService = resetService;

    this.meetingBroadcastService.on(
      'paper-path-add', this._onBroadcastShapeAdd, false
    );

    this.resetService.on('reset', this._onMeetingReset);

    this.vecteraFileMapping = {};
  }

  _bind() {
    this._onBroadcastShapeAdd = this._onBroadcastShapeAdd.bind(this);
    this._onMeetingReset = this._onMeetingReset.bind(this);
  }


  get whitelabelSettings() {
    return this.meetingService.settings.whitelabel;
  }
  get logo() {
    return this.whitelabelSettings.logo;
  }


  _onMeetingReset(resetParameters) {
    if(!resetParameters['whiteboards']) {
      return;
    }
    // Empty the cache because the files in the cache don't exist anymore.
    this.vecteraFileMapping = {};
  }

  _createLogo() {
    let fileInfo = {
      filename: this.logo.name,
      url: this.logo.url,
    };
    let promise = this.fileService.createFromCloudFile(
      fileInfo, ''
    );

    return promise
      .then(vecteraFile => this._handleCreatedLogo(vecteraFile))
      .catch(error => logger.warn(error));
  }


  _handleCreatedLogo(vecteraFile) {
    if(!vecteraFile) {
      return;
    }
    this.vecteraFileMapping[this.whitelabelSettings.logo.name] = vecteraFile.id;
    return vecteraFile;
  }

  _onBroadcastShapeAdd(
    channel,
    session,
    timestamp,
    id,
    whiteboardId,
    shapeId,
    properties,
    pageId,  // eslint-disable-line no-unused-vars
    type    // eslint-disable-line no-unused-vars
  ) {
    let logoName = properties.logoName;
    let fileId = properties.fileId;
    if(logoName == null || logoName in this.vecteraFileMapping)  {
      return;
    }

    this.vecteraFileMapping[logoName] = fileId;
  }

  getVecteraFile() {
    if(this.whitelabelSettings.customLogo && this.whitelabelSettings.autoAddLogoWhiteboard) {
      return $q.resolve().then(() => {
        let fileId = this.vecteraFileMapping[this.whitelabelSettings.logo.name];

        let vecteraFile = fileId ?
          this.fileService.get(fileId) :
          null;

        if(!vecteraFile) {
          return this._createLogo();
        } else {
          return vecteraFile;
        }
      });
    }
    return $q.resolve();
  }
}
