import DashboardCloudRecording from './DashboardCloudRecording';
import DashboardLocalRecording from './DashboardLocalRecording';


export default class DashboardRecordingFactory {
  static get $inject() {
    return [
      '$http',
      '$cookies',
      'apiService',
      'notificationService',
      'requestUserService'
    ];
  }

  constructor(
    $http,
    $cookies,
    apiService,
    notificationService,
    requestUserService
  ) {
    this.$http = $http;
    this.$cookies = $cookies;
    this.apiService = apiService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;
  }

  createDashboardLocalRecording(sendMessageToExtension, properties) {
    return new DashboardLocalRecording(
      this.$http,
      this.$cookies,
      this.apiService,
      this.notificationService,
      this.requestUserService,
      sendMessageToExtension,
      properties);
  }

  createDashboardCloudRecording(properties) {
    return new DashboardCloudRecording(
      this.apiService,
      this.notificationService,
      properties);
  }
}
