import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { BroadcastService } from './meeting-room/communication/broadcast.service';
import {
  HubSpotWidgetComponent
} from './meeting-room/sidebar/hubspot-widget/hubspot-widget.component';
import {
  AdminPulseWidgetComponent
} from './meeting-room/sidebar/adminpulse-widget/adminpulse-widget.component';
import {
  NotesSyncButtonComponent
} from './meeting-room/footer/notes/integration-sync/notes-sync-button.component';
import { MeetingService } from './meeting-room/meeting.service';
import { ActiveIntervalService } from './meeting-room/activeInterval.service';
import {
  PrivateNotesService
} from './meeting-room/footer/notes/private-notes/private-notes.service';
import { AudioContextService } from './meeting-room/stream/audioContext.service';
import { StreamService } from './meeting-room/stream/stream.service';
import { StreamFactory } from './meeting-room/stream/streamFactory.service';
import {
  EdgeWarningBannerComponent
} from './meeting-room/edge-warning-banner/edge-warning-banner.component';
import { SmartSummaryService } from './smart-summary/smart-summary.service';
import {
  NotesModalButtonComponent
} from './meeting-room/footer/notes/notes-modal-button.component';
import {
  NotesSyncService
} from './meeting-room/footer/notes/integration-sync/notes-sync.service';

angular
  .module('meeting')

  .directive(
    'notesModalButton',
    downgradeComponent({
      component: NotesModalButtonComponent,
      inputs: ['shouldBeTranslucent', 'shouldCollapseButton']
    })
  )
  .directive(
    'hubspotWidget',
    downgradeComponent({
      component: HubSpotWidgetComponent,
    })
  )
  .directive(
    'adminpulseWidget',
    downgradeComponent({
      component: AdminPulseWidgetComponent,
    })
  )
  .directive(
    'notesSyncButton',
    downgradeComponent({
      component: NotesSyncButtonComponent,
    })
  )
  .directive(
    'edgeWarningBanner',
    downgradeComponent({
      component: EdgeWarningBannerComponent,
    })
  )

  .service(
    'meetingBroadcastService',
    downgradeInjectable(BroadcastService)
  )
  .service(
    'activeIntervalService',
    downgradeInjectable(ActiveIntervalService)
  )
  .service(
    'meetingService',
    downgradeInjectable(MeetingService)
  )
  .service(
    'privateNotesService',
    downgradeInjectable(PrivateNotesService)
  )
  .service(
    'notesSyncService',
    downgradeInjectable(NotesSyncService)
  )

  .service(
    'audioContextService',
    downgradeInjectable(AudioContextService)
  )
  .service(
    'streamService',
    downgradeInjectable(StreamService)
  )
  .service(
    'streamFactory',
    downgradeInjectable(StreamFactory)
  )
  .service(
    'smartSummaryService',
    downgradeInjectable(SmartSummaryService)
  )
;
