<div class="contact-form-success__main">
  <svg-icon
    *ngIf="!customization.successImage"
    [svgPath]="'contactForm__highFive'"
  ></svg-icon>

  <img
    *ngIf="customization.successImage"
    [src]="customization.successImage"
    class="contact-form-success__image"
  >

  <p class="text-pre-wrap">{{ customization.successText }}</p>

  <div *ngIf="!hasFreeSubscription">
    <div class="contact-form-success__files" *ngIf="hasDownloadFile">
      <div class="contact-form-success__download-btn" (click)="downloadFile($event)">{{ customization.downloadButtonText }}</div>
    </div>
    <div class="contact-form-success__files" *ngIf="hasDownloadLink">
      <a [href]="downloadLink" target="_blank" class="contact-form-success__download-btn">{{ customization.downloadButtonText }}</a>
    </div>
  </div>
</div>



<div class="contact-form-success__powered-by">
  <powered-by
    *ngIf="shouldShowPoweredBy"
    [utmSource]="'contact_form'"
    [segmentSource]="'contact_form'"
    [tint]="customization.poweredByTint"
  ></powered-by>
</div>
