<div class="mt-4">
  <label class="label" i18n>Currency</label>

  <div class="money-config__combo-box">
    <combo-box
      [(ngModel)]="currency"
      [options]="currencies"
    ></combo-box>
  </div>
</div>
