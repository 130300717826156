class CustomError extends Error {
  constructor(...args) {
    super(...args);

    if(Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}


// We can't use "export class SomeError extends CustomError {}": this breaks checking the
// constructor name after Closure compilation
export default {
  _CustomError:                   CustomError,
  UnauthorizedError:          class UnauthorizedError         extends CustomError {},
  ServerError:                class ServerError               extends CustomError {},
  DoesNotExistError:          class DoesNotExistError         extends CustomError {},
  /** used when a promise throws an error but we do not want to handle this error immediately */
  EscapePromiseError:         class EscapePromiseError        extends CustomError {},
  FileTypeError:              class FileTypeError             extends CustomError {},
  FileInfoError:              class FileInfoError             extends CustomError {},
  IllegalStateError:          class IllegalStateError         extends CustomError {},
  InvalidArgumentError:       class InvalidArgumentError      extends CustomError {},
  ProgrammingError:           class ProgrammingError          extends CustomError {},
  ValidationError:            class ValidationError           extends CustomError {},
  NotSupportedError:          class NotSupportedError         extends CustomError {},
  NotImplementedError:        class NotImplementedError       extends CustomError {},
  OfflineError:               class OfflineError              extends CustomError {},
  RequestTooLargeError:       class RequestTooLargeError      extends CustomError {},
  EnumerateDevicesTimeout:    class EnumerateDevicesTimeout   extends CustomError {},
  GetContextFailedError:      class GetContextFailedError     extends CustomError {},
};
