import { Component, EventEmitter, Input, Output } from '@angular/core';
import { bind } from 'utils/util';

export class Scope {
  public id: string;
  public name: string;
  public numResults = 0;

  constructor(
    id: string,
    name: string,
  ) {
    this.id = id;
    this.name = name;
  }
}


@Component({
  selector: 'scope-switcher',
  templateUrl: './scope-switcher.component.html'
})
export class ScopeSwitcherComponent {
  @Input() scope: Scope | null = null;
  @Output() scopeChange = new EventEmitter<Scope>();
  @Input() scopes: Scope[] = [];


  constructor() {
    bind(this);
  }


  setScope(scope: Scope) {
    if(this.scope === scope) {
      return;
    }
    this.scope = scope;
    this.scopeChange.emit(scope);
  }
}
