import { Rect, format, DragListener, normalizeWheel } from 'utils/util';
import { RENDER_MARGIN } from './Whiteboard';

let SCROLL_MULTIPLIER = -1;
let ZOOM_MULTIPLIER = 0.025;

WhiteboardTileBodyDirective.$inject = [
  'toolService',
];


export default function WhiteboardTileBodyDirective(
  toolService
) {
  return {
    restrict: 'C',
    link: function($scope, $elem, $attr) {
      let $elemWindow = $elem.find('.whiteboard-tile__window');
      let $elemEraser = $elem.find('.whiteboard-tile__eraser');
      let $elemCanvasWrapper = $elem.find('.whiteboard-tile__canvas-wrapper');

      $elem.on('contextmenu', $event => $event.preventDefault());

      /**
       * To correctely simulate a canvas manipulation with css transformations, the "base" settings
       * of the whiteboard (before any temporary transformations) must be stored to base the
       * temporary transformation on.
       *
       * Whenever a "final" rendering happens, the current whiteboard settings are stored in these
       * variables.
       */
      let renderedZoomLevel = 1;
      let renderedOffset = { x: 0, y: 0 };
      let renderedBodyRect = new Rect({ left: 0, top: 0, width: 0, height: 0 });

      let dragListener = new DragListener($elem[0], {
        onStart: onStartDrag,
        onDrag: onDrag,
        onStop: onStopDrag,
        diffSinceStart: false,
        enabled: false,
      });
      let prevPointers = null;
      let viewportChanged = false;

      let totalScroll;
      let zoomFactor;
      let lastMouseLoc;
      let scrolling = false;
      resetScroll();

      let whiteboard;


      $scope.$watch($attr.tile, tile => {
        destroy();
        if(tile) {
          init(tile.whiteboard);
        }
      });


      function init(newWhiteboard) {
        whiteboard = newWhiteboard;
        whiteboard.on('draw', draw);
        whiteboard.on('contentRect', drawWindow);
        draw();

        window.__vecteraRunOutsideAngular(() => {
          dragListener.enable();
          $elem.on('wheel', onWheel);
        });

        toolService.on('tool', onTool);
        onTool();
      }


      function destroy() {
        if(!whiteboard) {
          return;
        }

        whiteboard.off('draw', draw);
        whiteboard.off('contentRect', drawWindow);
        whiteboard = null;

        dragListener.disable();
        $elem.off('wheel', onWheel);

        toolService.off('tool', onTool);
      }



      function draw(whiteboard, temporary) {
        drawCanvasWrapper(temporary);
        drawWindow();
      }


      function drawCanvasWrapper(temporary) {
        temporary ?
          drawCanvasWrapperTemporary() :
          drawCanvasWrapperFinal();
      }


      /**
       * move the entire fabric.js canvas, which necessitates a fabric.js pan and re-render
       */
      function drawCanvasWrapperFinal() {
        if(!whiteboard.tile.active) {
          return;
        }

        renderedZoomLevel = whiteboard.zoomLevel;
        renderedOffset = Object.assign({}, whiteboard.contentOffset);
        renderedBodyRect = whiteboard.tile.rectBody.clone();

        $elemCanvasWrapper.css({
          left:   Math.round(-renderedBodyRect.width  * RENDER_MARGIN),
          top:    Math.round(-renderedBodyRect.height * RENDER_MARGIN),
          width:  Math.round( renderedBodyRect.width  * (1 + 2 * RENDER_MARGIN)),
          height: Math.round( renderedBodyRect.height * (1 + 2 * RENDER_MARGIN)),
          transform: '',
        });
      }

      /**
       * Use CSS transformations to translate the fabric.js canvas, so no computationally
       * expensive re-render is necessary. This should only happen on client-side interactions, as
       * any "final"  canvas transformations need to be communicated to peers and require a
       * re-render.
       *
       */
      function drawCanvasWrapperTemporary() {
        if(!whiteboard.tile.active) {
          return;
        }

        let zoomScale = whiteboard.zoomLevel / renderedZoomLevel;
        let widthScale = whiteboard.tile.rectBody.width / renderedBodyRect.width;
        let scale = zoomScale * widthScale;

        let transform = format(
          'translate(%spx, %spx) scale(%s) translate(%spx, %spx)',
          whiteboard.contentOffset.x * whiteboard.tile.rectBody.width,
          whiteboard.contentOffset.y * whiteboard.tile.rectBody.height,
          scale,
          -renderedOffset.x * renderedBodyRect.width,
          -renderedOffset.y * renderedBodyRect.height
        );
        let renderRatio = 100 * RENDER_MARGIN / (1 + 2 * RENDER_MARGIN);

        $elemCanvasWrapper.css({
          transform: transform,
          transformOrigin: format('%s% %s%', renderRatio, renderRatio),
        });
      }

      /**
       * Convert the whiteboard contentRect and contentOffset to an appropiate pixel size,
       * taking into account the zoomLevel of the whiteboard. Set the CSS dimentions of the
       * html element to the calculated dimensions
       */
      function drawWindow() {
        if(!whiteboard.tile.active) {
          return;
        }

        let wb = whiteboard;

        // final dimensions of the rendered HTML element, in pixels
        let windowRect = new Rect({
          left: Math.round(
            // convert relative offset on x-axis
            //   from the left edge of the tile tile
            //   to the left edge of the contentRect origin
            // to pixels
            wb.contentOffset.x * wb.tile.rectBody.width
            // convert point distance on x-axis
            //   from the contentRect origin
            //   to the contentRect left edge
            // to pixels
            + wb.contentRect.left * wb.pixelsPerPoint
          ),
          top:  Math.round(
            wb.contentOffset.y * wb.tile.rectBody.height
            + wb.contentRect.top  * wb.pixelsPerPoint
          ),
          width:  Math.floor(wb.contentRect.width  * wb.pixelsPerPoint),
          height: Math.floor(wb.contentRect.height * wb.pixelsPerPoint),
        });

        $elemWindow.css({
          left: windowRect.left,
          top: windowRect.top,
          width: windowRect.width,
          height: windowRect.height,
        });


      }



      /**********
       * Eraser *
       **********/

      function onTool() {
        let tool = toolService.selected.tool;

        if(tool === 'eraser') {
          $elem.on('mousemove', onEraserMove);
          $elem.on('mouseleave', onEraserLeave);
        } else {
          $elem.off('mousemove', onEraserMove);
          $elem.off('mouseleave', onEraserLeave);
          onEraserLeave();
        }

        $elem.toggleClass('whiteboard-tile__body--drag', tool === 'pan');
      }


      function onEraserMove($event) {
        let rect = Rect.fromElem($elem[0]);
        let left = $event.pageX - rect.left;
        let top = $event.pageY - rect.top;

        $elemEraser.css({
          display: 'block',
          transform: format('translate(%spx, %spx) translate(-50%, -50%)', left, top),
        });
      }


      function onEraserLeave() {
        $elemEraser.css('display', 'none');
      }



      /*****************
       * Listen to pan *
       *****************/

      function onStartDrag(pointers) {
        prevPointers = pointers;
        viewportChanged = false;
      }

      /**
       *
       * @param {[Object]} pointers - List of {x,y} pointers, denoted in pixel distance to the
       *  window origin
       */
      function onDrag(pointers) {
        if(
          pointers.length > 1
          || (pointers.length === 1 && toolService.selected.tool === 'pan')
        ) {
          let pointerDiff = getPointersCenter(pointers);
          whiteboard.scroll(pointerDiff, true);
          viewportChanged = true;
        }

        if(pointers.length >= 2) {
          pinchZoom(pointers);
          viewportChanged = true;
        }
      }


      function onStopDrag() {
        if(viewportChanged) {
          whiteboard.scroll({ x: 0, y: 0 }, false);
        }
      }

      /**
       * If there are exactly 2 pointers while zooming, interpret these as a multitouch
       * "pinch" zoom
       *
       * @param {[Object]} pointersDiff - List of 2 {x,y} pointers, denoted in pixel distance to
       * the window origin
       */
      function pinchZoom(pointersDiff) {
        if(prevPointers.length < 2 || pointersDiff.length < 2) {
          return;
        }

        let pointers = [
          {
            x: prevPointers[0].x + pointersDiff[0].x,
            y: prevPointers[0].y + pointersDiff[0].y,
          },
          {
            x: prevPointers[1].x + pointersDiff[1].x,
            y: prevPointers[1].y + pointersDiff[1].y,
          },
        ];

        let dist = Math.hypot(
          pointers[0].x - pointers[1].x,
          pointers[0].y - pointers[1].y);
        let prevDist = Math.hypot(
          prevPointers[0].x - prevPointers[1].x,
          prevPointers[0].y - prevPointers[1].y);
        let zoomFactor = dist / prevDist;

        let pointersCenter = getPointersCenter(pointers);
        doZoom(zoomFactor, pointersCenter);

        prevPointers = pointers;
      }


      /**
       * Calculate the euclidean centre of a list of pointers
       *
       * @param {[Object]} pointers - List of {x,y} pointers, denoted in pixel distance to the
       *  window origin
       * @returns {Object} a single {x,y} pointers
       */
      function getPointersCenter(pointers) {
        let pointer = {
          x: 0,
          y: 0,
        };
        pointers.forEach(p => {
          pointer.x += p.x / pointers.length;
          pointer.y += p.y / pointers.length;
        });
        return pointer;
      }


      /*************************
       * Listen to scroll/zoom *
       *************************/

      function onWheel($event) {
        $event.preventDefault();

        lastMouseLoc = {
          x: $event.pageX,
          y: $event.pageY,
        };
        let delta = normalizeWheel($event);

        if($event.ctrlKey) {
          zoomFactor *= Math.pow(1.1, -delta.y * ZOOM_MULTIPLIER);

        } else {
          totalScroll.x += $event.shiftKey ? delta.y : delta.x;
          totalScroll.y += $event.shiftKey ? delta.x : delta.y;
        }

        if(scrolling) {
          window.requestAnimationFrame(doScrollAndZoom);
        } else {
          doScrollAndZoom();
        }
      }


      function doScrollAndZoom() {
        if(totalScroll.x === 0 && totalScroll.y === 0 && zoomFactor === 1) {
          scrolling = false;
          return;
        }

        scrolling = true;

        let scroll = totalScroll;
        let newZoomFactor = zoomFactor;
        let mouseLoc = lastMouseLoc;
        resetScroll();

        $scope.$evalAsync(() => {
          if(!whiteboard) {
            return;
          }

          doScroll(scroll);
          doZoom(newZoomFactor, mouseLoc);
        });
      }


      function doScroll(scroll) {
        if(scroll.x === 0 && scroll.y === 0) {
          return;
        }

        let offset = {
          x: scroll.x * SCROLL_MULTIPLIER,
          y: scroll.y * SCROLL_MULTIPLIER,
        };
        whiteboard.scroll(offset, true);
      }

      /**
       * Translate the pointer (in pixel distance to window origin) to a
       * whiteboard.ContentCoordinate value and pass it to the whiteboard zoom functionality
       *
       * @param {number} zoomFactor
       * @param {Object} pointer an {x,y} pointer, denoted in pixel distance to the
       *  window origin
       */
      function doZoom(zoomFactor, pointer) {
        if(zoomFactor === 1) {
          return;
        }


        // Location of the pointer within the tile rect, relative to the tile size
        let rect = Rect.fromElem($elem[0]);
        let pointerRelativeToTile = {
          x: (pointer.x - rect.left) / rect.width,
          y: (pointer.y - rect.top) / rect.height,
        };

        // Location of the pointer, relative to contentSize
        let viewportBounds = whiteboard.rectViewportBounds;
        let pointerFocus = {
          x: viewportBounds.left + (pointerRelativeToTile.x * viewportBounds.width),
          y: viewportBounds.top + (pointerRelativeToTile.y * viewportBounds.height)
        };

        whiteboard.zoom(zoomFactor, pointerFocus, true);
      }


      function resetScroll() {
        totalScroll = {
          x: 0,
          y: 0,
        };
        zoomFactor = 1;
      }
    }
  };
}
