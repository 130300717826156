import { bind, EventEmitter, file, logger } from 'utils/util';
import { StreamKind, StreamState } from  'meeting/meeting-room/stream';
import Recorder from './Recorder';
import templateModalRemoteRecording from './remoteRecording.modal.html?raw';


const BROADCAST_STATE_INTERVAL = 5000;
export const START_TIMEOUT = 3000;
const HIDE_MODAL_TIMEOUT = 2500;
export const MODAL_ID_REMOTE_RECORDING = 'remoteRecording';



export default class RecorderService {
  static get $inject() {
    return [
      '$cookies',
      'audioContextService',
      'chromeExtensionService',
      'meetingBroadcastService',
      'meetingService',
      'modalService',
      'notificationService',
      'recordingService',
      'screenshareStreamService',
      'streamService',
      'userService',
    ];
  }

  constructor(
    $cookies,
    audioContextService,
    chromeExtensionService,
    meetingBroadcastService,
    meetingService,
    modalService,
    notificationService,
    recordingService,
    screenshareStreamService,
    streamService,
    userService
  ) {
    bind(this);
    EventEmitter.setup(this, ['state']);

    this.$cookies = $cookies;
    this.audioContextService = audioContextService;
    this.chromeExtensionService = chromeExtensionService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.modalService = modalService;
    this.notificationService = notificationService;
    this.recordingService = recordingService;
    this.screenshareStreamService = screenshareStreamService;
    this.streamService = streamService;
    this.userService = userService;

    this.modalService.register(
      MODAL_ID_REMOTE_RECORDING,
      gettext('This meeting is being recorded'),
      'utils/icons/tl/24x24_record_outline.svg',
      templateModalRemoteRecording,
      { dismissable: false }
    );

    this.documentTitle = document.title;
    this.startTimestamp = null;

    this.videoStream = null;
    this.audioStreams = {};
    this.recorder = null;
    this.recordingId = null;
    this.extensionCommunicationPromise = $q.resolve();

    this.remoteState = Recorder.State.INACTIVE;
    this.broadcastStateTimeout = null;
    this.resetRemoteStateTimeout = null;


    this.meetingBroadcastService.on('recording', this._onBroadcastRecording, false);
    this.userService.mySession.on('state', this._onMySessionState);
    this.streamService.on('add', this._onStreamAdd);
    this.streamService.on('remove', this._onStreamRemove);
  }


  get isEnabled() {
    return this.meetingService.settings.showRecording;
  }

  get isAllowed() {
    return this.userService.iAmHost && this.meetingService.settings.allowRecording;
  }

  get state() {
    return this.recorder ?
      this.recorder.state :
      Recorder.State.INACTIVE;
  }
  get inactive() {
    return this.state === Recorder.State.INACTIVE && !this.startTimestamp;
  }
  get countingDown() {
    return this.state === Recorder.State.INACTIVE && this.startTimestamp;
  }
  get paused() {
    return this.state === Recorder.State.PAUSED;
  }
  get recording() {
    return this.state === Recorder.State.RECORDING;
  }
  get recordingRemote() {
    return this.remoteState === Recorder.State.RECORDING;
  }

  _onMySessionState() {
    if(!this.userService.mySession.isJoined() && this.recorder) {
      this.stop();
      this.notificationService.info(
        gettextCatalog.getString('Your recording has been stopped.')
      );
    }
  }

  start() {
    if(this.recorder || this.videoStream || !this.isAllowed) {
      return;
    }

    document.title = gettextCatalog.getString(
      '~~~~~~~ SELECT THIS TAB TO START RECORDING ~~~~~~~'
    );

    this.screenshareStreamService.get('screen', 'record')
      .then(videoStream => {
        this.videoStream = videoStream;
        this.recorder = new Recorder(this.videoStream, this.audioContextService.get());
        this.recorder.on('chunk', this._onRecorderChunk);
        this.recorder.on('cut', this._onRecorderCut);
        this.recorder.on('stop', this._onRecorderStop);
        this.recorder.on('state', this._onRecorderState);

        this.streamService.streams.forEach(this._onStreamAdd);

        this.startTimestamp = Date.now() + START_TIMEOUT;
        $timeout(() => this.recordingService.hideModal(), HIDE_MODAL_TIMEOUT);
        $timeout(this._startRecorder, START_TIMEOUT);
      })

      .catch(error => {
        logger.info(error);
        let errorName = (!error || typeof error === 'string') ?
          error :
          error.name === 'Error' ?
            error.constructor.name :
            error.name;
        if(errorName === 'FailedError') {
          /* eslint-disable max-len */
          this.notificationService.error(gettextCatalog.getString('An unknown error occurred while trying to start recording. Please try restarting your browser.'));
          /* eslint-enable max-len */
        }
        this._onRecorderStop();
      })

      .finally(() => {
        document.title = this.documentTitle;
      });
  }

  stop() {
    if(this.recorder) {
      let promise = this.recorder.stop();
      return promise;
    }
    return $q.resolve();
  }


  pause() {
    this.recorder.pause();
  }

  resume() {
    this.recorder.resume();
  }


  _startRecorder() {
    if(this.recorder) {
      this.recorder.start();
      this._startExtensionRecording();
    }
    this.startTimestamp = null;
  }


  _onStreamAdd(stream) {
    if(!this.recorder || stream.kind !== StreamKind.AUDIO || stream.id in this.audioStreams) {
      return;
    }

    this.audioStreams[stream.id] = {
      stream: stream,
      isPlaying: false,
    };

    stream.on('state', this._onStreamStateChange);
    this._onStreamStateChange(stream);
  }


  _onStreamRemove(stream) {
    stream.off('state', this._onStreamStateChange);
    this._onStreamStateChange(stream);
    delete this.audioStreams[stream.id];
  }


  _onStreamStateChange(stream) {
    if(!this.recorder || !(stream.id in this.audioStreams)) {
      return;
    }

    let isPlaying = stream.state === StreamState.CONNECTED || stream.state === StreamState.PLAYING;
    let wasPlaying = this.audioStreams[stream.id].isPlaying;

    if(!wasPlaying && isPlaying) {
      this.recorder.addAudioStream(stream);
    } else if(wasPlaying && !isPlaying) {
      this.recorder.removeAudioStream(stream);
    }

    this.audioStreams[stream.id].isPlaying = isPlaying;
  }


  _onRecorderChunk(blob) {
    this._sendChunkToExtension(blob);
  }


  _onRecorderCut() {
    this._stopExtensionRecording();
    this._startExtensionRecording();
  }


  _onRecorderStop() {
    this._stopExtensionRecording();
    this.startTimestamp = null;

    if(this.recorder) {
      this.recorder = null;
    }

    if(this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
      this.videoStream = null;
    }

    Object.values(this.audioStreams).forEach(streamInfo => {
      this._onStreamRemove(streamInfo.stream);
    });
    if(this.userService.mySession.isJoined()) {
      this.recordingService.showModal();
    }
  }


  _onRecorderState() {
    this.emit('state', this.state);
    this._broadcastState();
  }




  /******************************
   * Communicate with extension *
   ******************************/

  _startExtensionRecording() {
    this.extensionCommunicationPromise = this.extensionCommunicationPromise.then(() => {
      if(this.recordingId) {
        return $q.resolve();
      }

      return this.recordingService.sendMessageToExtension({
        action: 'tab-start',
        meetingId: this.meetingService.id,
        meetingName: this.meetingService.key,
        timestamp: Date.now(),
      }, null, this._onExtensionError)

        .then(recordingInfo => {
          if(!this.recorder || !recordingInfo) {
            return;
          }

          this.recordingId = recordingInfo.id;
        });
    });
  }


  _sendChunkToExtension(blob) {
    this.extensionCommunicationPromise = this.extensionCommunicationPromise.then(() => {
      return file.toBinaryString(blob);
    })
      .then(byteString => {
        if(!byteString) {
          return;
        }

        return this.recordingService.sendMessageToExtension({
          action: 'tab-chunk',
          id: this.recordingId,
          byteString: byteString,
          mimeType: blob.type,
        }, null, this._onExtensionError);
      })
      .catch(this._onExtensionError);
  }


  _stopExtensionRecording() {
    this.extensionCommunicationPromise = this.extensionCommunicationPromise.then(() => {
      if(!this.recordingId) {
        return;
      }

      return this.recordingService.sendMessageToExtension({
        action: 'tab-stop',
        id: this.recordingId,
      }, null, this._onExtensionError)

        .then(() => {
          this.recordingId = null;
        });
    });
  }


  _onExtensionError(error) {
    logger.warn(error);
    if(this.recorder) {
      this.recorder.stop();
    }

    this.notificationService.error(
      gettextCatalog.getString(
        // eslint-disable-next-line max-len
        'Something went wrong with your recording. Try reloading the page and restarting your browser.'
      )
    );
  }



  /**************************
   * Communicate with peers *
   **************************/

  _onBroadcastRecording(channel, sender, timestamp, state) {
    this._setRemoteState(state);
  }


  _setRemoteState(state) {
    $timeout.cancel(this.resetRemoteStateTimeout);
    this.resetRemoteStateTimeout = null;

    if(state !== Recorder.State.INACTIVE) {
      this.resetRemoteStateTimeout = $timeout(
        this._setRemoteState.bind(this, Recorder.State.INACTIVE),
        2.5 * BROADCAST_STATE_INTERVAL);
    }

    const State = Recorder.State;
    if(this.remoteState !== State.RECORDING && state === State.RECORDING) {
      this._notifyRemoteRecording();
    } else if(this.remoteState === State.RECORDING && state !== State.RECORDING) {
      this._unNotifyRemoteRecording();
    }

    this.remoteState = state;
  }


  _notifyRemoteRecording() {
    this.modalService.show(MODAL_ID_REMOTE_RECORDING);
  }

  _unNotifyRemoteRecording() {
    this.modalService.hide(MODAL_ID_REMOTE_RECORDING);
  }


  _broadcastState() {
    $timeout.cancel(this.broadcastStateTimeout);
    this.broadcastStateTimeout = null;

    this.meetingBroadcastService.send('recording', false, [], this.state);

    if(this.state !== Recorder.State.INACTIVE) {
      this.broadcastStateTimeout = $timeout(this._broadcastState, BROADCAST_STATE_INTERVAL);
    }

    if(this.recordingId != null) {
      // file.toBinaryString may take a very long time if the computer is under heavy load, so
      // we send a ping regularly to let the extension know we are still recording
      this.recordingService.sendMessageToExtension({
        action: 'tab-ping',
        id: this.recordingId,
      }, null, angular.noop);
    }
  }
}
