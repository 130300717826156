import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { AddRoomModalComponent } from './add-room-modal.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    A11yModule,
    BrowserModule,
    UtilsModule,
    FormsModule,
  ],
  declarations: [
    AddRoomModalComponent,
  ],
  exports: [
    AddRoomModalComponent,
  ],
})
export class AddRoomModalModule {}
