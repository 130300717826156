import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactFormQuestion } from 'contactForm/models/ContactFormQuestion';
import { ErrorValue } from 'utils/settings/settings.component';
import { bind } from 'utils/util';


@Component({
  template: '',
})
export abstract class ContactFormQuestionInputComponent<T> {
  @Input() question!: ContactFormQuestion;
  @Input() value?: T;
  @Output() valueChange = new EventEmitter<T>();

  public errors?: string[];


  constructor() {
    bind(this);
  }


  public writeValue(value: T) {
    if(value === this.value) {
      return;
    }

    this.value = value;
    this.valueChange.emit(value);
  }


  /**
   * Can be overriden by subclasses to provide client-side validation. In general, all validation
   * should happen server-side only, but sometimes this is not possible (e.g. when validating
   * non-number values in a number input field).
   *
   * @returns An array of error messages, or undefined if there are no errors.
   */
  public validate(): ErrorValue | undefined {
    return undefined;
  }



  /**************
   * Prefilling *
   **************/

  public prefill(string: string) {
    const value = this.parsePrefill(string);
    if(value != null) {
      this.writeValue(value);
      this.validate();
    }
  }

  public abstract parsePrefill(string: string): T | null;
}
