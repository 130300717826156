import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive, EventEmitter, HostListener, Input, Output, TemplateRef, ViewContainerRef
} from '@angular/core';
import { SvgIcon } from '../svg-icon';
import { defaultModalConfig, ModalConfig } from './modal.component';
import { ModalService } from './modal.service';


/**
 * A directive that allows you to open a modal directly from a template.
 *
 * Example usage:
 *
 * ```
 * <div
 *  class="btn"
 *  [modalTriggerFor]="modalDemo"
 *  [modalIcon]="{ name: 'add' }"
 *  [modalTitle]="'Modal demo'"
 *  (modalClosed)="handleModalClosed($event)"
 * >
 *  Open modal
 * </div>
 *
 * <ng-template #modalDemo>
 *  <p>
 *    This is the content of the modal.
 *  </p>
 * </ng-template>
 * ```
 */
@Directive({
  selector: '[modalTriggerFor]',
  exportAs: 'modalTriggerFor',
})
export class ModalTriggerDirective {
  @Input() modalTriggerFor!: TemplateRef<any>;

  @Input() modalTitle?: string;
  @Input() modalIcon?: SvgIcon;
  @Input() modalClass?: string;
  @Input() modalBodyClass?: string;
  @Input() modalHideHeader?: boolean;
  @Input() modalDisableClose?: boolean;
  @Input() modalBlurBackdrop?: boolean;
  @Input() modalTriggerData?: any;
  @Input() modalConfig?: ModalConfig;

  /** Emits the modal result when the modal is closed. */
  @Output() modalClosed = new EventEmitter<any>();


  constructor(
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
  ) {}


  @HostListener('click')
  onClick() {
    const config = this.modalConfig ? this.modalConfig : this.buildConfig();
    const dialogRef = this.modalService.open(config);
    dialogRef.closed.subscribe(result => {
      this.modalClosed.emit(result);
    });
  }


  private buildConfig() {
    const templatePortal = new TemplatePortal(
      this.modalTriggerFor,
      this.viewContainerRef,
      this.modalTriggerData
    );
    const config: ModalConfig = {
      ...defaultModalConfig,
      ...(this.modalTitle != null && { title: this.modalTitle }),
      ...(this.modalIcon != null && { icon: this.modalIcon }),
      ...(this.modalClass != null && { modalClass: this.modalClass }),
      ...(this.modalBodyClass != null && { bodyClass: this.modalBodyClass }),
      ...(this.modalHideHeader != null && { hideHeader: this.modalHideHeader }),
      ...(this.modalDisableClose != null && { disableClose: this.modalDisableClose }),
      ...(this.modalBlurBackdrop != null && { blurBackdrop: this.modalBlurBackdrop }),
      templatePortal,
    };
    return config;
  }
}
