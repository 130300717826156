import { array } from 'utils/util';
import template from './logo.html?raw';


class LogoController {
  static get $inject() {
    return [
      '$attrs',
      'meetingService',
      'siteService',
      'usageTrackingService'
    ];
  }

  constructor(
    $attrs,
    meetingService,
    siteService,
    usageTrackingService
  ) {
    this.$attrs = $attrs;
    this.meetingService = meetingService;
    this.siteService = siteService;
    this.usageTrackingService = usageTrackingService;

    this.whitelabelSettings = meetingService.settings.whitelabel;
  }

  get logo() {
    return this.whitelabelSettings.logo;
  }
  get custom() {
    return this.whitelabelSettings.customLogo && this.allowCustom;
  }

  get url() {
    if(!this.custom) {
      return null;
    }

    return this.logo.url;
  }

  get svg() {
    if(this.custom) {
      return null;
    }

    let postFix = this.monochrome ? '_monochrome' : '';
    let product = this.siteService.site.isStandalone ?
      'standalone' :
      'teamleader';
    let svg = `utils/icons/logo/${product}/${this.variant}${postFix}.svg`;
    return svg;
  }


  get variant() {
    if(array.has(['icon', 'compact', 'full'], this._variant)) {
      return this._variant;
    } else {
      return 'full';
    }
  }

  get href() {
    if('logoHref' in this.$attrs.$attr) {
      return this._href;
    } else {
      return this.meetingService.getHomepage(this.utmSource);
    }
  }

  get allowCustom() {
    if('allowCustom' in this.$attrs.$attr) {
      return this._allowCustom;
    } else {
      return true;
    }
  }

  get style() {
    let style = {};
    if(this.scale) {
      style.height = (this.logo.height / 4 * this.scale) + 'px';
    }
    return style;
  }


  /******************
  * Usage tracking *
  *****************/

  trackLogoClick() {
    if(!this.custom && this.href && this.segmentSource) {
      if(this.siteService.site.isStandalone) {
        this.usageTrackingService.createSegmentEvent(
          'vecteraLogo.clicked',
          this.segmentSource
        );
      } else if(this.siteService.site.isTeamleader) {
        this.usageTrackingService.createSegmentEvent(
          'teamleaderLogo.clicked',
          this.segmentSource
        );
      }
    }
  }
}


export default {
  controller: LogoController,
  controllerAs: 'logoCtrl',
  template,

  bindings: {
    monochrome: '<',
    _variant: '<variant',
    _href: '<logoHref',
    scale: '<',
    _allowCustom: '<allowCustom',
    utmSource: '<',
    segmentSource: '<',
  }
};
