import './angularjs.module';
import './angularjs/dashboard.module';

import { NgModule } from '@angular/core';
import { HomeModule } from './home';
import { SidebarProfileModule } from './sidebar-profile';
import { UIComponentsModule } from './ui-components';
import { MeetingRoomsModule } from './meeting-rooms';
import { AngularjsUpgradedUtilsModule } from 'utils/angularjs-upgraded';
import { UtilsModule } from 'utils/utils.module';
import { FormElementsModule } from './form-elements';

@NgModule({
  imports: [
    UIComponentsModule,
    FormElementsModule,
    HomeModule,
    MeetingRoomsModule,
    SidebarProfileModule,
    UtilsModule,
    AngularjsUpgradedUtilsModule,
  ]
})
export class DashboardModule {}
