import Session from './Session';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';


export default class userSyncService {
  static get $inject() {
    return [
      'meetingSocketService',
      'meetingBroadcastService',
      'meetingService',
      'userService',
    ];
  }

  constructor(
    meetingSocketService,
    meetingBroadcastService,
    meetingService,
    userService
  ) {
    this._bind();

    this.meetingSocketService = meetingSocketService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.userService = userService;

    this.meetingBroadcastService.on('session-join', this._onSessionJoin, true);
    this.meetingBroadcastService.on('knock-join', this._onSessionJoin, true);
    this.meetingBroadcastService.on('session-exit', this._onSessionExit, true);
    this.meetingBroadcastService.on('knock-exit', this._onSessionExit, true);

    this.meetingSocketService.on('close', this._onSocketClose);
    this.meetingService.on(this.meetingService.MeetingState.ENDED, this._onMeetingEnded);
  }

  _bind() {
    this._onSessionJoin = this._onSessionJoin.bind(this);
    this._onSessionExit = this._onSessionExit.bind(this);
    this._onSocketClose = this._onSocketClose.bind(this);
    this._onMeetingEnded = this._onMeetingEnded.bind(this);
  }


  _onSessionJoin(channel, session, datetime, userId, accessLevel, extra = {}) {
    session.extra = extra;
    let state = (channel === 'session-join') ? Session.State.ALIVE : Session.State.KNOCKING;
    // Backwards compatibility: accessLevel used to be a boolean isHost
    if(accessLevel === true) {
      accessLevel = AccessLevel.IS_HOST;
    } else if(accessLevel === false) {
      accessLevel = AccessLevel.SHOULD_KNOCK;
    } else {
      accessLevel = AccessLevel.getFromId(accessLevel);
    }
    this.userService.joinSession(session, userId, accessLevel, state, datetime);
  }


  _onSessionExit(channel, session, datetime, exitReason) {
    if(this._shouldExitSession(session, exitReason)) {
      this.userService.exitSession(session, exitReason, datetime);
    } else if(this._shouldSleepSession(session)) {
      this.userService.sleepSession(session, exitReason);
    }
  }

  _shouldExitSession(session, exitReason) {
    return (
      !session.isLocal && (
        !Session.SOFT_EXIT_REASONS.has(exitReason)
        || this.meetingBroadcastService.initializing
      )
    );
  }

  _shouldSleepSession(session) {
    // This happens when we receive the session-exit event during rejoining.
    return !(session.isLocal && session.state === Session.State.DEAD);
  }


  _onSocketClose() {
    let session = this.userService.mySession;
    if(session.state !== Session.State.DEAD) {
      this.userService.sleepSession(session);
    }
  }


  _onMeetingEnded(exitReason) {
    let session = this.userService.mySession;
    this.userService.exitSession(session, exitReason);
  }
}
