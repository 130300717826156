import template from './signalStrength.html?raw';

import { format } from 'utils/util';
import { MODAL_ID as MODAL_ID_SETTINGS } from '../../components/settings/settings.service';
import { SignalStrength } from './signalStrength.service';

class SignalStrengthController {
  static get $inject() {
    return [
      '$element',
      'modalService',
      'signalStrengthService',
    ];
  }

  constructor(
    $elem,
    modalService,
    signalStrengthService
  ) {
    this._bind();

    this.modalService = modalService;
    this.signalStrengthService = signalStrengthService;

    this.$elem = $elem;
    this.$elemImage = null;
    this.currentSignalStrength = null;

    this.TOOLTIPS = {
      [SignalStrength.GOOD]: gettext('Your connection is good.'),
      [SignalStrength.MEDIUM]: gettext('Someone\'s connection is not great.'),
      [SignalStrength.BAD]: gettext('Someone\'s connection is not great.'),
    };
    this.TOOLTIP_SUGGESTION = gettext('Click to change the video quality settings.');
  }

  get tooltip() {
    return format('%s<br/>%s',
      gettextCatalog.getString(this.TOOLTIPS[this.currentSignalStrength]),
      gettextCatalog.getString(this.TOOLTIP_SUGGESTION)
    );
  }

  _bind() {
    this._onSignalStrength = this._onSignalStrength.bind(this);
  }

  $onInit() {
    this.$elemImage = this.$elem.find('.signal-strength__icon');
    this.signalStrengthService.on('audio video', this._onSignalStrength);
    this._onSignalStrength();
  }


  showSettings() {
    this.modalService.show(MODAL_ID_SETTINGS);
  }


  _onSignalStrength() {
    let signalStrength = Math.min(
      this.signalStrengthService.signalStrength.audio,
      this.signalStrengthService.signalStrength.video);
    this.$elemImage.toggleClass(
      'signal-strength__icon--bad', signalStrength === SignalStrength.BAD);
    this.$elemImage.toggleClass(
      'signal-strength__icon--medium', signalStrength === SignalStrength.MEDIUM);
    this.$elemImage.toggleClass(
      'signal-strength__icon--good', signalStrength === SignalStrength.GOOD);

    this.currentSignalStrength = signalStrength;
  }
}


export default {
  controller: SignalStrengthController,
  controllerAs: 'signalStrengthCtrl',
  template,

  bindings: {
    translucent: '<',
    opaque: '<',
    phone: '<',
  },
};
