import {
  Component,
  ElementRef,
  forwardRef,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';


/**
 * Ask confirmation to the user before changing the value of the input.
 *
 * After getting confirmation, the component will try to focus the input element. To do so, it will
 * first look for an element with attribute `confirmChangeFocus`. If it doesn't find one, it will
 * look for an `input` element.
 *
 * Example usage:
 * ```
 * <confirm-change>
 *   <input type="text" [(ngModel)]="value" />
 * </confirm-change>
 * ```
 *
 * @param [value] - The current value of the input. Will be displayed while the input is hidden.
 * @param [shouldConfirm=true] - Whether the user should confirm the change before editing the
 *  input.
 * @param [dialogText] - The text to display in the confirmation dialog.
 */
@Component({
  selector: 'confirm-change',
  templateUrl: './confirm-change.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmChangeComponent),
      multi: true,
    },
  ],
})
export class ConfirmChangeComponent {
  @Input() value = '';
  @Input() shouldConfirm = true;
  @Input() dialogText = $localize `Are you sure you want to edit this field?`;

  constructor(
    private elementRef: ElementRef,
  ) {}

  confirmChange() {
    this.shouldConfirm = false;
    setTimeout(() => {
      let toBeFocused = this.elementRef.nativeElement.querySelector('[confirmChangeFocus]');
      if(!toBeFocused) {
        toBeFocused = this.elementRef.nativeElement.querySelector('input');
      }
      if(toBeFocused) {
        toBeFocused.focus();
      }
    });
  }
}
