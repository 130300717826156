import CanvasPolyfillService from './canvasPolyfill.service.js';
import EditingUserService from './editingUser.service.js';
import PaperRendererFactory from './paperRendererFactory.service.js';
import ShapeSyncService from './shapeSync.service.js';
import FabricShapeFactory from './fabricShapeFactory.service.js';
import FabricToolsSyncService from './fabricToolsSync.service.js';


angular
  .module('meeting')
  .service('canvasPolyfillService', CanvasPolyfillService)
  .service('editingUserService', EditingUserService)
  .service('paperRendererFactory', PaperRendererFactory)
  .service('shapeSyncService', ShapeSyncService)
  .service('fabricShapeFactory', FabricShapeFactory)
  .service('fabricToolsSyncService', FabricToolsSyncService);
