
<div
  *ngIf="!hubSpotService.isExpired"
  class="hubspot-widget__wrapper"
  [class.hubspot-widget__wrapper--active]="hubSpotContact != null"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="hubSpotContact != null && toggleOverlay()"
>
  <h4 *ngIf="hubSpotContact == null" class="d-flex align-items-center">
    <svg-icon svgPath="integrations__hubspot_logo" class="hubspot-widget__logo"></svg-icon>
    <span i18n>Customer lookup</span>
  </h4>

  <div *ngIf="hubSpotContact == null" class="d-flex flex-column">
    <div class="form-group form-group--sm">
      <label i18n>Contact email</label>
      <!-- input size of type email is 20 by default, which results in an invisilbe min-width rule wider than our sidebar -->
      <!-- Setting it to 18 instead will allow the element to shrink to the necessary size -->
      <input
        type="email"
        size="1"
        class="input"
        [(ngModel)]="contactEmail"
      >
      <div class="text-error-400 mt-2" *ngIf="error">
        {{ error }}
      </div>
    </div>
    <div
      class="btn btn--primary"
      [ngClass]="{'disabled': stateService.isLoading }"
      (click)="stateService.isReady && fetchHubSpotContact()"
    >
      <span *ngIf="stateService.isReady" i18n>Search in HubSpot</span>
      <div class="d-flex align-items-center" *ngIf="stateService.isLoading" i18n>Searching...<loading class="ml-2"></loading></div>
    </div>
    <div></div>
  </div>


  <div
    *ngIf="hubSpotContact != null"
    class="d-flex align-items-center justify-content-between"
  >
    <div class="d-flex align-items-center">
      <svg-icon svgPath="integrations__hubspot_logo" class="hubspot-widget__logo"></svg-icon>
      <b>{{ hubSpotContact.firstName }} {{ hubSpotContact.lastName }}</b>
    </div>
    <div class="ml-2 btn btn--square" (click)="clearContactData()">
      <svg-icon class="btn__icon" [svgName]="'close'"></svg-icon>
    </div>

    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayInheritWidth
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="hubSpotContact != null && isOpen"
      [cdkConnectedOverlayPositions]="[connectedPosition]"
      [cdkConnectedOverlayMinWidth]="382"
    >
      <div class="hubspot-widget__overlay">
        <div class="hubspot-widget__overlay-header">
          <div class="d-flex align-items-center">
            <svg-icon svgPath="integrations__hubspot_logo" class="hubspot-widget__overlay-logo"></svg-icon>
            <div class="h3 mt-0 mb-0" i18n>Contact data for {{hubSpotContact.firstName}}</div>
          </div>
          <div class="ml-2 btn btn--square" (click)="isOpen = false">
            <svg-icon [svgName]="'close'"></svg-icon>
          </div>
        </div>
        <div class="hubspot-widget__overlay-content">
          <div *ngIf="(hubSpotContact.properties | keyvalue).length === 0">
            <i i18n>No properties could be found, check your HubSpot integration settings.</i>
          </div>
          <div *ngFor="let prop of hubSpotContact.properties | keyvalue">
            <truncated-text [length]="20" [text]="prop.key"></truncated-text>
            <p
              *ngIf="prop.value"
              class="text-muted"
            >
              <truncated-text [length]="20" [text]="prop.value"></truncated-text>
            </p>
            <p *ngIf="!prop.value" class="text-muted"><i i18n>empty</i></p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div
  *ngIf="hubSpotService.isExpired"
  class="hubspot-widget__expired-wrapper"
>
  <div class="d-flex flex-column">
    <div i18n>Your HubSpot account was disconnected.</div>
    <div *ngIf="requestUserService.user.isAdmin">
      <div i18n>Please reconnect the integration and reload the page.</div>
      <a
        [attr.href]="urlService.urls.hubSpotConnectUrl"
        target="_blank"
        class="btn btn--primary mt-3"
        i18n
      >
        Reconnect
      </a>
    </div>
    <div *ngIf="!requestUserService.user.isAdmin">
      <div i18n>Please ask an admin to reconnect the integration.</div>
    </div>
  </div>
</div>
