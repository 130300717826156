import { Injectable, Inject } from '@angular/core';
import { Organization } from 'organization/models/Organization';


type SubscriptionStatus = 'free' | 'trial' | 'active';
type UrlString = string;
type Image = {
  url: UrlString,
  name: string,
  height: number,
}

export type WhitelabelSettings = {
  hasAddon: boolean,

  brandColor: string,
  customLogo: boolean,
  logo: Image,
  customFavicon: boolean,
  favicon: UrlString,
  customHomepage: boolean,
  homepage: UrlString,
  utmCampaign: string,

  customMeetingBackground: boolean,
  meetingBackground: string | null,
  customWaitingRoomBackground: boolean,
  waitingRoomBackground: string,

  customDomain: string | null,
  customDomainHeadBlock: string | null,
  customDomainRedirect: string | null,

  autoAddLogoWhiteboard: boolean,
  showPoweredBy: boolean,
};

// There are more properties than this, but they are not used yet in the frontend. Feel free to
// add them when needed.
export type ViewOrganization = {
  id: string,
  name: string,
  slug: string,

  logo: string | null,
  favicon: string | null,

  allowEditAppointments: boolean,
  allowEditMeetings: boolean,
  allowTeamAccessLevelOverride: boolean,
  liveMeetingRoomsOverviewEnabled: boolean,
  permanentInviteLinkEnabled: boolean,
  schedulingEnabled: boolean,
  termsAndConditions: string,

  subscription: {
    status: SubscriptionStatus,
  },

  whitelabelSettings: WhitelabelSettings,
}


@Injectable({
  providedIn: 'root',
})
export class ViewOrganizationService {
  public organization: ViewOrganization;

  constructor(
    @Inject('modelFactory') private modelFactory,
  ) {
    this.organization = modelFactory.createInstance(Organization, {
      data: ANGULAR_SCOPE.viewOrganization,
    });
  }
}
