import { bind } from 'utils/util';


export default class DropboxPickerRun {
  static get $inject() {
    return [
      'filePickerWorkerService',
    ];
  }

  constructor(
    filePickerWorkerService
  ) {
    bind(this);

    this.filePickerWorkerService = filePickerWorkerService;

    this.filePickerWorkerService.on('pickDropbox', message => {
      this.pick(message.addImageType, message.whiteboardId);
    });
  }


  pick(addImageType, whiteboardId) {
    window.Dropbox.choose({
      success: (responses) => this._onSuccess(responses, addImageType, whiteboardId),
      linkType : 'direct',
      multiselect: true,
    });
  }


  _onSuccess(responses, addImageType, whiteboardId) {
    let cloudFilesInfo = responses.map(response => {
      return {
        filename: response.name,
        size: response.bytes,
        url: response.link,
      };
    });
    this.filePickerWorkerService.create(cloudFilesInfo, addImageType, whiteboardId);
  }
}
