import Dropdown from '../dropdown/Dropdown';
import templateDropdown from './contextMenu.dropdown.html?raw';

const CONTEXT_MENU_DROPDOWN_ID = 'contextMenu';

export default class ContextMenuService {
  static get $inject() {
    return [
      'dropdownService',
    ];
  }

  constructor(
    dropdownService
  ) {
    this.dropdownService = dropdownService;

    this.items = null;

    this.dropdownService.register(
      CONTEXT_MENU_DROPDOWN_ID,
      templateDropdown,
      {
        position: Dropdown.Position.EXACT,
        cssClasses: 'dropdown-deprecated--no-padding-x',
      }
    );
  }


  show(items, $elemOrEvent) {
    this.items = items;
    this.dropdownService.show(CONTEXT_MENU_DROPDOWN_ID, $elemOrEvent);
  }
}
