import { Rect, throttle } from 'utils/util';


SharePointerDirective.$inject = [
  'focusService',
  'sharedPointerService',
];

export default function SharePointerDirective(
  focusService,
  sharedPointerService
) {
  return {
    restrict: 'A',

    link: function($scope, $elem, $attr) {
      let $elems = $elem.find('.tile__body, .tile__catch-events-overlay');
      let updateSharedPointerThrottled = throttle(updateSharedPointer, 500, true);

      window.__vecteraRunOutsideAngular(() => {
        $elems.on('mousemove', updateSharedPointerThrottled);
        $elems.on('mouseleave', hideSharedPointer);
      });

      focusService.on('hasFocus', onHasFocus);
      focusService.on('isVisible', onIsVisible);

      $scope.$watch($attr.sharePointerClick, click => {
        if(click === false) {
          $elems.off('click', updateSharedPointer);
        } else {
          $elems.on('click', updateSharedPointer);
        }
      });


      function getTile() {
        return $scope.$eval($attr.sharePointer);
      }


      function updateSharedPointer($event) {
        if(!$event) {
          sharedPointerService.set();
          return;
        }

        let tile = getTile();
        let clientRect = Rect.fromElem($elems[0]);
        let position = { x: null, y: null };
        if(tile.whiteboard) {
          // translate pointer px position to pts distance relative to content origin
          position.x = $event.pageX; // pixel X location in entire window
          position.x -= tile.rectBody.left; // subtract tile offset
          position.x -= tile.whiteboard.pixelContentOffset.x; // subtract content offset
          position.x /= tile.whiteboard.pixelsPerPoint; // translate pixels to pts

          position.y = $event.pageY;
          position.y -= tile.rectBody.top;
          position.y -= tile.whiteboard.pixelContentOffset.y;
          position.y /= tile.whiteboard.pixelsPerPoint;
        } else {
          position.x = ($event.pageX - clientRect.left) / clientRect.width;
          position.y = ($event.pageY - clientRect.top)  / clientRect.height;
        }

        let click = $event.type === 'click';
        sharedPointerService.set(tile, position, click);
      }


      function hideSharedPointer() {
        updateSharedPointerThrottled(null);
      }


      function onHasFocus(hasFocus) {
        if(!hasFocus) {
          hideSharedPointer();
        }
      }
      function onIsVisible(isVisible) {
        if(!isVisible) {
          hideSharedPointer();
        }
      }
    }
  };
}
