<div class="d-flex min-h-0">
  <div
    class="wizard__sidebar"
    *ngIf="wizard.options.showSteps"
  >
    <h1>{{ wizard.title }}</h1>

    <div class="progress-tracker">
      <div
       *ngFor="let step of wizard.steps; index as idx"
        class="progress-tracker__step"
      >
        <div
          class="progress-tracker__bullet"
          [ngClass]="{
            'progress-tracker__bullet--completed': wizard.currentIndex > idx,
            'progress-tracker__bullet--in-progress': wizard.currentIndex === idx
          }"
        ></div>
        <div
          class="progress-tracker__name"
          [ngClass]="{
            'progress-tracker__name--in-progress': wizard.currentIndex === idx
          }"
        >
          {{ step.shorthand }}
        </div>
      </div>
    </div>

    <div
      *ngIf="wizard.options.footerHTML"
      ng-bind-html="wizard.translatedFooterHTML"
      class="wizard__sidebar-footer"
    >
    </div>
  </div>


  <div class="wizard__main">
    <div class="wizard__header" *ngIf="wizard.options.showSteps || wizard.options.closeable">
      <div class="wizard__container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="text-muted" *ngIf="wizard.options.showSteps" i18n>
            Step {{ wizard.currentIndex + 1 }} of {{ wizard.steps.length }}
          </div>

          <div class="flex-1"></div>

          <div
            *ngIf="wizard.options.closeable"
            (click)="closeWizard()"
            class="btn btn--round ml-5 mr--2 my--2"
          >
            <svg-icon class="btn__icon" [svgName]="'close'"></svg-icon>
          </div>
        </div>
      </div>
    </div>


    <div class="wizard__step">
      <div class="wizard__container">
        <h2>{{ wizard.currentStep.name }}</h2>
        <div
          *ngIf="wizard.errors.genericErrorMessage"
          class="p field field--error"
        >
          <field-icon [type]="'error'"></field-icon>
          <div [innerHtml]="wizard.errors.genericErrorMessage | safeHtml"></div>
        </div>

        <ng-template wizardHost></ng-template>
      </div>
    </div>


    <div class="wizard__footer" *ngIf="wizard.currentStep.shouldShowNavigation">
      <div class="wizard__container">
        <div class="d-flex">
          <div
            *ngIf="wizard.shouldShowBack"
            (click)="wizard.back()"
            class="mr-5 btn btn--secondary"
            i18n
          >
            Go Back
          </div>

          <div class="flex-1"></div>

          <button
            (click)="wizard.next()"
            class="btn btn--primary"
            type="submit"
            form="wizardForm"
            [ngClass]="{
              disabled: !wizard.validateNext() || wizard.isProcessing
            }"
          >
            {{ wizard.nextText }}
            <loading class="ml-2" *ngIf="wizard.isProcessing"></loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
