import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon';
import { AppLogoComponent } from './app-logo/app-logo.component';
import { LogoComponent } from './logo.component';
import { PoweredByComponent } from './powered-by/powered-by.component';


@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
  ],
  declarations: [
    LogoComponent,
    AppLogoComponent,
    PoweredByComponent,
  ],
  exports: [
    LogoComponent,
    AppLogoComponent,
    PoweredByComponent,
  ],
})
export class LogoModule {}
