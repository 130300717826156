import errors from './errors';
import format from './format';
import * as object from './object';
import { getFixedAncestor } from './misc';

const ERROR_MESSAGE_IMPLICIT = 'An implicit property cannot be set explicitly';

const DEFAULT_OPTIONS = {
  relativeToFixedParent: true,
};

export default class Rect {
  static fromElem(elem, argOptions) {
    let options = Object.assign({}, DEFAULT_OPTIONS, argOptions);

    let rectElem = elem.getBoundingClientRect();

    let elemContainer = options.relativeToFixedParent ?
      getFixedAncestor(elem) :
      document.body;
    let rectContainer = elemContainer ?
      elemContainer.getBoundingClientRect() :
      { top: 0, left: 0 };

    return new Rect({
      top:    rectElem.top  - rectContainer.top,
      left:   rectElem.left - rectContainer.left,
      width:  rectElem.width,
      height: rectElem.height,
    });
  }


  static fromEvent(event, argOptions) {
    let options = Object.assign({}, DEFAULT_OPTIONS, argOptions);

    let elemContainer = options.relativeToFixedParent ?
      getFixedAncestor(event.target) :
      document.body;
    let rectContainer = elemContainer ?
      elemContainer.getBoundingClientRect() :
      { top: 0, left: 0 };

    return new Rect({
      top:    event.pageY - rectContainer.top,
      left:   event.pageX - rectContainer.left,
      width:  0,
      height: 0,
    });
  }



  static fromViewport() {
    let rectBody = document.body.getBoundingClientRect();
    // We cannot use rectBody.height: Mobile Safari reports incorrect height for document and body
    // when the navigation is collapsed.
    return new Rect({
      top:   -rectBody.top,
      left:  -rectBody.left,
      width:  $(window).width(),
      height: $(window).height(),
    });
  }


  static fromFixedAncestor(elem) {
    let elemFixedAncestor = getFixedAncestor(elem);
    let rectFixedAncestor = elemFixedAncestor.getBoundingClientRect();
    return new Rect({
      top: rectFixedAncestor.top,
      left: rectFixedAncestor.left,
      width: rectFixedAncestor.width,
      height: rectFixedAncestor.height,
    });
  }


  constructor(rect) {
    this.rect = Object.assign({}, rect);

    if(this.rect.left == null && this.rect.right == null) {
      this.rect.left = 0;
    }
    if(
      this.rect.left == null && this.rect.width == null
      || this.rect.right == null && this.rect.width == null
    ) {
      this.rect.width = 0;
    }
    if(this.rect.top == null && this.rect.bottom == null) {
      this.rect.top = 0;
    }
    if(
      this.rect.top == null && this.rect.height == null
      || this.rect.bottom == null && this.rect.height == null
    ) {
      this.rect.height = 0;
    }

    let numSet = {
      x: (this.rect.left != null) + (this.rect.right  != null) + (this.rect.width  != null),
      y: (this.rect.top  != null) + (this.rect.bottom != null) + (this.rect.height != null),
    };

    if(numSet.x === 3) {
      if(this.rect.left + this.rect.width !== this.rect.right) {
        throw new errors.InvalidArgumentError(format(
          'Left, right, width don\'t match: got %s, %s, %s',
          this.rect.left, this.rect.right, this.rect.width));
      }
      delete this.rect.right;
    }

    if(numSet.y === 3) {
      if(this.rect.top + this.rect.height !== this.rect.bottom) {
        throw new errors.InvalidArgumentError(format(
          'Top, bottom, height don\'t match: got %s, %s, %s',
          this.rect.top, this.rect.bottom, this.rect.height));
      }
      delete this.rect.bottom;
    }
  }


  set(property, value) {
    let properties = typeof property === 'object' ? property : { [property]: value };

    let rect = this.clone();
    object.forEach(properties, (property, value) => rect[property] = value);

    return rect;
  }


  get left() {
    return this.rect.left == null ? this.rect.right - this.rect.width : this.rect.left;
  }
  set left(value) {
    if(this.rect.left == null) {
      throw new errors.IllegalStateError(ERROR_MESSAGE_IMPLICIT);
    }
    this.rect.left = value;
  }

  get right() {
    return this.rect.right == null ? this.rect.left + this.rect.width : this.rect.right;
  }
  set right(value) {
    if(this.rect.right == null) {
      throw new errors.IllegalStateError(ERROR_MESSAGE_IMPLICIT);
    }
    this.rect.right = value;
  }

  get width() {
    return this.rect.width == null ? this.rect.right - this.rect.left : this.rect.width;
  }
  set width(value) {
    if(this.rect.width == null) {
      throw new errors.IllegalStateError(ERROR_MESSAGE_IMPLICIT);
    }
    this.rect.width = value;
  }


  get top() {
    return this.rect.top == null ? this.rect.bottom - this.rect.height : this.rect.top;
  }
  set top(value) {
    if(this.rect.top == null) {
      throw new errors.IllegalStateError(ERROR_MESSAGE_IMPLICIT);
    }
    this.rect.top = value;
  }

  get bottom() {
    return this.rect.bottom == null ? this.rect.top + this.rect.height : this.rect.bottom;
  }
  set bottom(value) {
    if(this.rect.bottom == null) {
      throw new errors.IllegalStateError(ERROR_MESSAGE_IMPLICIT);
    }
    this.rect.bottom = value;
  }

  get height() {
    return this.rect.height == null ? this.rect.bottom - this.rect.top : this.rect.height;
  }
  set height(value) {
    if(this.rect.height == null) {
      throw new errors.IllegalStateError(ERROR_MESSAGE_IMPLICIT);
    }
    this.rect.height = value;
  }


  equals(rect) {
    return (
      this.top === rect.top
      && this.left === rect.left
      && this.right === rect.right
      && this.bottom === rect.bottom
    );
  }


  clone() {
    return new Rect(this.rect);
  }


  round() {
    return new Rect({
      left:   this.rect.left   == null ? null : Math.round(this.rect.left  ),
      right:  this.rect.right  == null ? null : Math.round(this.rect.right ),
      top:    this.rect.top    == null ? null : Math.round(this.rect.top   ),
      bottom: this.rect.bottom == null ? null : Math.round(this.rect.bottom),
      width:  this.rect.width  == null ? null : Math.round(this.rect.width ),
      height: this.rect.height == null ? null : Math.round(this.rect.height),
    });
  }
}
