import { errors, interval } from 'utils/util';
import JanusHandle, { ChannelLabel } from './JanusHandle';
import JanusStreamHandle from './JanusStreamHandle';


const KEEP_ALIVE_INTERVAL = 3000;
export const KEEP_ALIVE_TIMEOUT = 10000;


export default class JanusPublisher extends JanusStreamHandle {
  constructor(...args) {
    super(...args);

    this.mediaStream = new MediaStream();  // eslint-disable-line compat/compat
    this.negotiateInfo = {
      audio: false,
      video: false,
    };

    this.sendKeepAliveInterval = null;
  }

  get direction() {
    return 'outbound';
  }


  _onDestroyed() {
    super._onDestroyed();
    interval.clearInterval(this.sendKeepAliveInterval);
    this.sendKeepAliveInterval = null;
  }



  addStream(stream) {
    this.mediaStream.addTrack(stream.track);
    return super.addStream(stream);
  }

  removeStream(stream) {
    const track = this.mediaStream.getTracks().find(track => track.kind === stream.kind);
    if(!track) {
      throw new errors.IllegalStateError(`No stream of kind ${stream.kind} is added`);
    }

    this.mediaStream.removeTrack(track);
    return super.removeStream(stream);
  }


  _onStreamsChanged() {
    super._onStreamsChanged();
    this.negotiate();
  }


  _onVideoBandwidth() {
    if(this.peerConnection) {
      this._setVideoParameters();
    }
  }



  _postAttach() {
    super._postAttach();
    return this._join();
  }



  _getJoinConfig() {
    const config = {
      ptype: 'publisher',
      id: this.id,
    };

    return Object.assign(super._getJoinConfig(), config);
  }


  _getNegotiateInfo() {
    return {
      audio: !!this.streams.audio,
      video: !!this.streams.video,
    };
  }


  _startNegotiation(iceRestart) {
    this._setState(JanusHandle.State.NEGOTIATING);
    this.negotiateInfo = this._getNegotiateInfo();
    this._createOffer(iceRestart)
      .then(this._sendJsep)
      .catch(this._onError);
  }


  _getJsepConfig(iceRestart) {
    const config = {
      media: {
        audioRecv: false,
        videoRecv: false,
        audioSend: this.negotiateInfo.audio,
        videoSend: this.negotiateInfo.video,
        addAudio: this.negotiateInfo.audio,
        addVideo: this.negotiateInfo.video,
        data: true,
      },
      stream: this.mediaStream,
      customizeSdp: this._customizeSdp,
    };
    if(iceRestart) {
      config.iceRestart = true;
    }

    return Object.assign(super._getJsepConfig(), config);
  }


  _customizeSdp(jsep) {
    jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;usedtx=1');
  }


  _setVideoParameters() {
    const stream = this.streams.video;
    const sender = this.peerConnection.getSenders().find(s => s.track && s.track.kind === 'video');
    if(!stream || !sender || !sender.setParameters) {
      return $q.resolve();
    }

    let params = this.settingsService.getSenderParameters(sender);
    const bitrateKbps = this.videoBandwidth;
    const videoHeight = this.settingsService.getVideoHeight(stream);
    params = this.settingsService.setVideoParameterBandwidth(sender, params, bitrateKbps);
    params = this.settingsService.setVideoParameterHeight(sender, params, videoHeight);

    this.logger.log('Set video parameters:', params.encodings[0]);
    return sender.setParameters(params);
  }


  _getNegotiateConfig() {
    const config = {
      request: 'configure',
    };

    return Object.assign(super._getNegotiateConfig(), config);
  }


  _postNegotiate() {
    super._postNegotiate();
    interval.clearInterval(this.sendKeepAliveInterval);
    this.sendKeepAliveInterval = interval.setInterval(this._sendKeepAlive, KEEP_ALIVE_INTERVAL);
  }


  _sendKeepAlive() {
    this._sendData(ChannelLabel.KEEP_ALIVE, {});
  }


  _sendData(channel, message) {
    if(this.handle) {
      const data = JSON.stringify({
        channel: channel,
        message: message,
      });
      this.handle.data({
        data: data,
      });
    }
  }
}
