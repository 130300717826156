type Callback = () => void;
interface Item {
  id: number,
  fn: Callback,
  maxFps: number,
  scheduled: number,
}

let queue: { [key: number]: Item } = {};
let rafIsCalled = false;
let maxId = 0;

export function requestAnimationFrame(fn: Callback, maxFps = 0) {
  /**
   * Optimized version of requestAnimationFrame:
   * - Run the callbacks outside of angular change detection.
   * - Run all callbacks in a single event loop. If callbacks do explicitly enable change
   *   detection, it will only have to run once for all the callbacks.
   */
  const id = ++maxId;
  const scheduled = performance.now();
  scheduleItem({ id, fn, maxFps, scheduled });
}


function scheduleItem(item: Item) {
  queue[item.id] = item;
  if(!rafIsCalled) {
    window.__vecteraRunOutsideAngular(() => window.requestAnimationFrame(runCallbacks));
    rafIsCalled = true;
  }
}


export function cancelAnimationFrame(id: number) {
  delete queue[id];
}


function runCallbacks() {
  const now = performance.now();
  const currentQueue = queue;
  queue = [];
  rafIsCalled = false;

  Object.values(currentQueue).forEach(item => {
    if(item.maxFps === 0 || item.maxFps > 1000 / (now - item.scheduled)) {
      item.fn();
    } else {
      scheduleItem(item);
    }
  });
}
