

import { Component } from '@angular/core';
import {
  WaitingRoomNotificationService
} from '../waiting-room-notification.service';
import { SiteService } from 'utils/site.service';


@Component({
  selector: 'waiting-room-notification-warning',
  templateUrl: './waiting-room-notification-warning.component.html',
})
export class WaitingRoomNotificationWarningComponent {
  seeNotificationsUrl: string;
  desktopPermsUrl: string;

  constructor(
    public waitingRoomNotificationService: WaitingRoomNotificationService,
    private siteService: SiteService,
  ) {
    this.seeNotificationsUrl = this.siteService.getHelpArticle('waitingRoomBrowserSettings');
    this.desktopPermsUrl = this.siteService.getHelpArticle('waitingRoomBrowserSettings');
  }
}
