import './angularjsDowngradedComponents';

import { NgModule } from '@angular/core';
import { HubSpotModule } from './hubspot/hubspot.module';
import { AdminPulseModule } from './adminpulse';

@NgModule({
  exports: [
    HubSpotModule,
    AdminPulseModule,
  ],
})
export class IntegrationsModule {}
