import template from './sidebarBubble.html?raw';
import { bind } from 'utils/util';


class SidebarBubbleController {
  static get $inject() {
    return [
      'chromeExtensionService',
      'requestUserService',
      'siteService',
    ];
  }

  constructor(
    chromeExtensionService,
    requestUserService,
    siteService
  ) {
    bind(this);

    this.chromeExtensionService = chromeExtensionService;
    this.requestUserService = requestUserService;
    this.siteService = siteService;

    this.extensionIsInitialized = false;
    this.shouldShowExtensionBubble = false;
    this.chromeExtensionService.on('isInstalled', this._updateShouldShowExtensionBubble);
    // The chromeExtensionService needs a bit of time to know whether the extension is installed.
    $timeout(this._updateShouldShowExtensionBubble, 1000);
  }


  _updateShouldShowExtensionBubble() {
    this.shouldShowExtensionBubble = (
      this.chromeExtensionService.isSupported
      && !this.chromeExtensionService.isInstalled
    );
    this.extensionIsInitialized = true;
  }

  get extensionInfo() {
    if(this.siteService.site.isTeamleader) {
      return gettextCatalog.getString('Get the most out of the Lead capture Booster');
    } else {
      return gettextCatalog.getString(
        'Get the most out of {{ productName }}',
        { productName: this.siteService.site.productName  }
      );
    }
  }
}


export default {
  controller: SidebarBubbleController,
  controllerAs: 'sidebarBubbleCtrl',
  template: template,
};
