import { HubSpotConfigComponent } from './hubspot/hubspot-config.component';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { HubSpotService } from './hubspot/hubspot.service';
import { AdminPulseConfigComponent } from './adminpulse/adminpulse-config.component';
import { AdminPulseService } from './adminpulse/adminpulse.service';

angular
  .module('integrations', [])

  .directive(
    'hubspotConfig',
    downgradeComponent({ component: HubSpotConfigComponent })
  )
  .directive(
    'adminpulseConfig',
    downgradeComponent({ component: AdminPulseConfigComponent })
  )

  .service(
    'hubSpotService',
    downgradeInjectable(HubSpotService)
  )
  .service(
    'adminPulseService',
    downgradeInjectable(AdminPulseService)
  )
;
