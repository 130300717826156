import { Component, Input } from '@angular/core';
import { SvgIcon } from '../svg-icon';


type BadgeSize = 'sm' | 'md' | 'lg';
type BadgeStyle = 'primary' | 'info' | 'warning' | 'error' | 'success' | 'teal';
type BadgeAlign = 'left' | 'right';

const defaultBadgeOptions = {
  size: 'md' as BadgeSize,
  align: 'left' as BadgeAlign,
};

export type Badge = {
  label: string,
  size?: BadgeSize,
  style?: BadgeStyle,
  icon?: SvgIcon,
  align?: BadgeAlign,
}

/**
 * Display a badge. There are two ways to use this component: by passing the options as html
 * attributes, or by passing a `Badge` instance.
 *
 * Example usage:
 *
 * ```
 * # With HTML attributes
 * <badge [badgeLabel]="'My badge'" [badgeIcon]="{name: 'pencil'}"></badge>
 *
 * # With a Badge instance:
 * --- my.component.ts
 * class MyComponent {
 *  public badge: Badge = {
 *    label: 'My badge',
 *    icon: {
 *      name: 'pencil',
 *    },
 *  }
 * }
 *
 * --- my.component.html
 * <badge [badge]="badge"></badge>
 */
@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
})
export class BadgeComponent {
  /** The label shown inside the badge. */
  @Input() badgeLabel = '';
  /** The size of the badge. Corresponds to the css classes badge--{size}. */
  @Input() badgeSize: BadgeSize = defaultBadgeOptions.size;
  /** The style (theme color) of the badge. */
  @Input() badgeStyle?: BadgeStyle;
  /** The icon the show next to the label. */
  @Input() badgeIcon?: SvgIcon;
  /** The alignment of the icon. */
  @Input() badgeAlign: BadgeAlign = defaultBadgeOptions.align;

  /** An optional Badge instance that overrides all the other options. */
  @Input('badge') _badge?: Badge;


  get badge() {
    if(this._badge) {
      return {
        ...defaultBadgeOptions,
        ...this._badge,
      };
    } else {
      return {
        label: this.badgeLabel,
        size: this.badgeSize,
        style: this.badgeStyle,
        icon: this.badgeIcon,
        align: this.badgeAlign,
      };
    }
  }


  get cssClass() {
    let cssClass = `badge badge--${this.badge.size}`;
    if(this.badge.style) {
      cssClass += ` badge--${this.badge.style}`;
    }
    return cssClass;
  }

  get iconCssClass() {
    let cssClass = 'badge__icon';
    if(this.badge.align === 'right') {
      cssClass += ' badge__icon--right';
    }
    return cssClass;
  }
}
