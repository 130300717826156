import { Component, Input } from '@angular/core';

import {
  IntegrationSyncService, State as SyncState
} from '../integration-sync/integration-sync.service';


@Component({
  selector: 'share-menu',
  templateUrl: './share-menu.component.html',
})
export class ShareMenuComponent {
  @Input() content!: string;
  @Input() email = false;
  @Input() integration = false;

  readonly State = SyncState;

  constructor(
    public syncService: IntegrationSyncService,
  ) { }

  public shareViaEmail(): void {
    const date = new Date().toLocaleDateString();
    const subjectPrefix = $localize`Meeting summary`;
    const subject = encodeURIComponent(`${subjectPrefix} – ${date}`);
    const body = encodeURIComponent(this.content);
    window.open(`mailto:?subject=${subject}&body=${body}`);
  }

  get state(): SyncState {
    return this.syncService.state;
  }

  get integrations(): string[] {
    return this.syncService.activeIntegrationNames();
  }
}
