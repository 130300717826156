import { Component, Input, Type, ViewChild } from '@angular/core';
import { bind, logger } from 'utils/util';
import { WizardService } from './wizard.service';
import {
  WizardStepComponent,
  default as WizardStep,
  Options as WizardStepOptions
} from './WizardStep';


export type Options = {
  title: string,
  showSteps: boolean,
  closeable: boolean,
  footerHTML: string,
  scope: Record<string, unknown>,
  onClose: () => any,
}
const defaultOptions: Options = {
  title: '',
  showSteps: false,
  closeable: true,
  footerHTML: '',
  scope: {},
  onClose: () => null,
};
@Component({ template: '' })
export abstract class WizardComponent {
  @Input() wizard!: Wizard;

  // Retrieve the WizardStepComponent that is currently visible in the
  // Wizard Component
  @ViewChild(WizardStepComponent) visibleWizardStepComponent!: WizardStepComponent;

  /**
   * if the wizard executes a 'next step' and there is a WizardStepComponent currently visible
   * return the WizardStepComponent next handler that resolves when the step is ready to move on
   */
  wizardHandleNextStep(): Promise<any> {
    if(this.visibleWizardStepComponent) {
      return Promise.resolve(this.visibleWizardStepComponent.handleNext());
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Check if the visible WizardStepComponent (if present) is ready to go to the next step
   */
  wizardValidateNextStep() {
    if(this.visibleWizardStepComponent) {
      return this.visibleWizardStepComponent.validate();
    } else {
      return true;
    }
  }
}

/**
 * A Wizard consists of a number of steps, reachable using back and next buttons. The Wizard also
 * keeps info about errors that might arise during a step.
 */
export default class Wizard {
  wizardService: WizardService;

  id: string;
  component: Type<any>;
  // componentHandleNext and componentValidateNext are functions of WizardComponent that get
  // executed at the same time as their Wizard counterparts. This exists so that operations within
  // the scope of the wizard component can happen on step submission/validation.
  componentHandleNext: () => Promise<any> = () => Promise.resolve();
  componentValidateNext: () => boolean = () => true;
  steps: WizardStep[];
  options: Options;

  currentIndex: number;
  isProcessing: boolean;
  errors: Record<string, string>;

  constructor(
    wizardService: WizardService,
    id: string,
    component: Type<any>,
    stepDefinitions: Partial<WizardStepOptions>[],
    options: Partial<Options>,
  ) {
    bind(this);

    this.wizardService = wizardService;
    this.id = id;
    this.component = component;
    this.steps = stepDefinitions.map(stepDefinition => {
      return new WizardStep(stepDefinition);
    });
    this.options = Object.assign({}, defaultOptions, options);

    this.currentIndex = 0;

    this.isProcessing = false;
    this.errors = {};
  }


  get title() {
    return this.options.title;
  }

  get currentStep() {
    return this.steps[this.currentIndex];
  }

  get translatedFooterHTML() {
    return this.options.footerHTML;
  }

  /***************
   * WIZARD FLOW *
   **************/

  show() {
    this.wizardService.show(this);
  }

  showStep(step) {
    this.setStep(step);
    this.show();
  }

  hide() {
    this.wizardService.hide(this);
    this.options.onClose();
  }


  get shouldShowBack() {
    return this.currentIndex !== 0 && this.currentStep.canGoBack;
  }


  back() {
    this._clearErrors();
    if(this.currentIndex > 0) {
      this.currentIndex = Math.max(this.currentIndex - 1, 0);
    }
  }

  setStep(step) {
    if(!this.isProcessing) {
      this.currentIndex = step;
    }
  }


  /**
   * Validates if the user can proceed to the next step. This is only possible if the wizard
   * is not processing (i.e in the middle of proceeding to the next step) and the validation
   * callback is successful. The callback function returns a boolean.
   *
   * @returns {boolean}
   */
  validateNext() {
    return !this.isProcessing
    && this.currentStep
    && this.currentStep.validate()
    && this.componentValidateNext();
  }


  /**
   * Marks the wizard as 'processing' while the next callback is executed. If successful,
   * the wizard stops processing and sets the next step. The callback function returns a promise.
   */
  next() {
    this._clearErrors();
    if(!this.validateNext()) {
      return;
    }

    this._startProcessing();

    this.componentHandleNext()
      .then(() => this.currentStep.next())
      .then(() => this._setNextStep())
      .catch(error => {
        this.setAPIErrors(error.response);
      })
      .finally(this._stopProcessing);
  }


  _setNextStep() {
    this._clearErrors();
    if(this.currentIndex < this.steps.length - 1) {
      this.currentIndex += 1;
    } else {
      this.hide();
    }
  }


  get nextText() {
    if(this.isLastStep) {
      return $localize `Finish`;
    } else {
      return $localize `Continue`;
    }
  }

  get isLastStep() {
    return this.currentIndex === this.steps.length - 1;
  }


  /**********
   * ERRORS *
   *********/

  /**
   * Set the errors variable to the data of the errorResponse. If the error status is 500, we set
   * a generic error message and log the error.
   *
   * @param {Object} errorResponse: the error response from an API call
   */
  setAPIErrors(errorResponse) {
    if(errorResponse.status !== 500) {
      Object.assign(this.errors, errorResponse.data);
    } else {
      logger.error(errorResponse);

      const url = 'href="#" onclick="vecteraShowSupport()"';
      // eslint-disable-next-line max-len
      this.errors.genericErrorMessage = $localize `Something went wrong processing your request. Please reload the page or <a ${url}>contact us</a> if the issue persists.`;
    }
  }

  setFieldError(field, errorMessage) {
    this.errors[field] = errorMessage;
  }

  _clearErrors() {
    this.errors = {};
  }


  /*******
  * UTIL *
  ********/

  _startProcessing() {
    this.isProcessing = true;
  }

  _stopProcessing() {
    this.isProcessing = false;
  }
}
