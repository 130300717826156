import { color, siteColor } from 'utils/util';
import { ContactForm } from 'contactForm/models/ContactForm';
import { PoweredByTint } from 'utils/ui-components/logo/powered-by/powered-by.component';
import { HexColor } from 'utils/util/color';

export type Customization = {
  backgroundColor: HexColor,
  formColor: HexColor,
  borderColor: HexColor,
  poweredByTint: PoweredByTint,

  buttonColor: HexColor,
  buttonTextColor: HexColor,
  buttonHoverColor: HexColor,

  textColor: HexColor,
  fontFamily: string,
  fallbackFontFamilies: string[],
  fontSize: string,
  fontWeight: string,
  titleTextColor: HexColor,
  titleFontFamily: string,
  titleFallbackFontFamilies: string[],
  titleFontSize: string,
  titleFontWeight: string,
  linkColor: HexColor,


  introductionText: string,
  sendButtonText: string,
  successTitle: string,
  successText: string,
  successImage: string,

  downloadButtonText: string,
};



export const defaultCustomization: Customization = buildDefaultCustomization();


function buildDefaultCustomization(doc: Document = document) {
  const elemText = doc.createElement('p');
  const elemTitle = doc.createElement('h1');
  doc.body.appendChild(elemText);
  doc.body.appendChild(elemTitle);
  const styleText = getComputedStyle(elemText);
  const styleTitle = getComputedStyle(elemTitle);

  const fontFamilies = styleText.fontFamily.split(',').map(f => f.trim());
  const titleFontFamilies = styleTitle.fontFamily.split(',').map(f => f.trim());

  const defaultCustomization: Customization = {
    backgroundColor: '#ffffff',
    formColor: '#ffffff',
    borderColor: '',
    poweredByTint: 'dark',

    buttonColor: siteColor.getDefaultColor('primaryButton'),
    buttonTextColor: siteColor.getDefaultColor('buttonText'),
    buttonHoverColor: siteColor.getDefaultColor['buttonHover'],

    textColor: siteColor.getDefaultColor('text'),
    fontFamily: fontFamilies[0],
    fallbackFontFamilies: fontFamilies.slice(1),
    fontSize: parseInt(styleText.fontSize).toString(),
    fontWeight: styleText.fontWeight,
    titleTextColor: siteColor.getDefaultColor('text'),
    titleFontFamily: titleFontFamilies[0],
    titleFallbackFontFamilies: titleFontFamilies.slice(1),
    titleFontSize: parseInt(styleTitle.fontSize).toString(),
    titleFontWeight: styleTitle.fontWeight,
    linkColor: siteColor.getDefaultColor('link'),

    introductionText: '',
    sendButtonText: $localize `Send`,
    successTitle: $localize `High five!`,
    successText: $localize `Thank you for taking the time to complete this.`,
    successImage: '',

    downloadButtonText: $localize `Download your files`,
  };

  elemText.remove();
  elemTitle.remove();

  return defaultCustomization;
}


/**
 * Build the customization object based on the settings of the ContactForm.
 *
 * Note that the user is able to change the contactForm instance at will, so this can contain
 * garbage. The customization object is the layer inbetween that sanitizes the user input into
 * a usable customization setup. This function should therefor guarantee that the input is parsed
 * correctly.
 */
export function buildCustomizationFromInstance(contactForm: ContactForm): Customization {
  if(!defaultCustomization.fontFamily) {
    Object.assign(defaultCustomization, buildDefaultCustomization());
  }

  const customization = Object.assign({}, defaultCustomization);
  for(const key of Object.keys(customization)) {
    if(contactForm[key]) {
      if(key.includes('Color') ) {
        // kinda hacky, but if the key includes 'Color', only allow the value to be set on
        // the customization if it is a valid hex Color.
        // If this ever needs addressing, consider extending the Customization type to be able to
        // know which "Type" of settings it is, e.g. a color, a font, a size,...
        if(color.isValidHexColor(contactForm[key])) {
          customization[key] = contactForm[key];
        }
      } else {
        customization[key] = contactForm[key];
      }
    }
  }

  // adapt the button text color, only when the user has (tried to) set a buttonColor,
  // but left the button text color empty
  if(color.isValidHexColor(contactForm.buttonColor) && !contactForm.buttonTextColor) {
    customization.buttonTextColor = siteColor.getValidTextColor(
      customization.buttonTextColor,
      contactForm.buttonColor,
    );
  }

  // adapt form text color when form color has been set
  if(color.isValidHexColor(contactForm.formColor) && !contactForm.textColor) {
    customization.textColor = siteColor.getValidTextColor(
      customization.textColor,
      contactForm.formColor,
    );
  }

  // adapt title text color when background color has been set
  if(color.isValidHexColor(contactForm.backgroundColor) && !contactForm.titleTextColor) {
    customization.titleTextColor = siteColor.getValidTextColor(
      customization.titleTextColor,
      contactForm.backgroundColor,
    );
  }

  // buttonHoverColor cannot be set through the user interface, update it here
  customization.buttonHoverColor = siteColor.getButtonHoverColor(customization.buttonColor);


  try {
    const backgroundColorHsl = color.hexToHsl(customization.backgroundColor);
    customization.poweredByTint = backgroundColorHsl.l > 0.6 ? 'dark' : 'light';
  } catch(error) {
    customization.poweredByTint = 'dark';
  }

  return customization;
}
