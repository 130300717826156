import template from './organizationSetup.html?raw';
import { bind } from 'utils/util';


export class OrganizationSetupController {
  constructor(
  ) {
    bind(this);

    this.isValid = false;
    this.loginUrl = window.URLS.login;
  }

  onOrganizationSetupIsValid(isValid) {
    this.isValid = isValid;
  }

  onOrganizationName(name) {
    this.name = name;
  }

  submit() {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set('organizationName', this.name);
    let url = window.URLS.login + '?' + searchParams.toString();
    location.href = url;
  }
}

export default {
  controller: OrganizationSetupController,
  controllerAs: 'organizationSetupCtrl',
  template,

  bindings: {
    name: '@',
  }
};
