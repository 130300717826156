<h1 class="page-title">UI Components</h1>

<h2> Badge </h2>
<badge badgeLabel="myBadge"></badge>
<badge [badge]="dynamicBadge"></badge>


<h2> Dialog </h2>

<div
  class="btn btn--primary"
  [dialog]="exampleDialog"
  dialogTitle="Dialog title"
  [dialogIcon]="{ name: 'add' }"
  dialogConfirmText="Confirm"
  dialogConfirmButtonClass="btn--primary"
  [dialogConfirmedCallback]="executeCallback.bind(this)"
>
  <svg-icon class="icon" [svgName]="'add'"></svg-icon>
  dialog
</div>

<ng-template #exampleDialog>
  <p i18n>dialog text.</p>
</ng-template>

<h2> Fetch error </h2>
<fetch-error [fetchError]="'something something went wrong while fetching imaginary data'" (tryAgain)="executeCallback()"></fetch-error>

<h2> Go back header </h2>
<go-back-header>
  [url]="urlService.urls.profile"
  [headerText]="'Header text'"
</go-back-header>

<h2> Help tooltip </h2>
<help-tooltip
[text]="'help text'"
></help-tooltip>

<h2> Logo </h2>
<p>Display the logo of the organization. Supports showing the custom logo set in team settings. Can handle click tracking with Segment </p>
<logo [logoScale]="1" ></logo>

<h3> App logo </h3>
<p>just the logo of the application, no bells nor whistles</p>
<app-logo style="height: 50px;"></app-logo>

<h3> Powered by</h3>
<powered-by></powered-by>


<h2> Modal </h2>
<div
  class="btn btn--primary"
  [modalTriggerFor]="modalPreview"
  [modalIcon]="{name: 'visible'}"
  [modalTitle]="'Modal title'"
>
  <svg-icon class="icon" [svgName]="'add'"></svg-icon>
  modal
</div>

<truncated-text [length]="20" text="automatically truncates text with a tooltip to a specified length"></truncated-text>

<ng-template #modalPreview>
  <p i18n>Modal text.</p>
</ng-template>

<h2> Pagination </h2>
<pagination
  [(page)]="page"
  [numPages]="4"
></pagination>


<h2>Preview</h2>
<preview class="contact-form-config-question__preview">
  <div class="d-flex justify-content-center">
    <span>preview text</span>
  </div>
</preview>

<h2>Pro feature badge</h2>
<pro-feature-badge featureText="Advanced thingamajig"></pro-feature-badge>


<h2>Pro feature lock</h2>
<pro-feature-lock tooltip="pro feature lock tooltip"></pro-feature-lock>

<h2>Profile images</h2>
<profile-images [users]="users" [maxBubbles]="4"></profile-images>

<h2>Scope Switcher</h2>
<scope-switcher
  [(scope)]="scope"
  [scopes]="scopes"
></scope-switcher>

<h2>Sharing options</h2>

<div
  class="btn btn--primary"
  [modalTriggerFor]="sharingModal"
  [modalIcon]="{name: 'visible'}"
  [modalTitle]="'Modal title'"
>
  open sharing options component in a modal
</div>

<ng-template #sharingModal>
  <sharing-options
    [name]="'sharing.name'"
    [url]="'sharing.url'"
    [embeddedUrl]="'sharing.embeddedUrl'"
    [id]="'sharing.id'"
    context="sharing"
  >
  </sharing-options>

</ng-template>

<h2>SVG Icon</h2>
<svg-icon class="icon" [svgName]="'add'"></svg-icon>

<h2>Upgrade button</h2>
<upgrade-button></upgrade-button>
