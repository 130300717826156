import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { SmartSummaryService } from '../../../smart-summary/smart-summary.service';


@Component({
  selector: 'notes-modal-button',
  templateUrl: './notes-modal-button.component.html',
})
export class NotesModalButtonComponent {
  @Input() public shouldCollapseButton = false;
  @Input() public shouldBeTranslucent = false;

  public constructor(
    private smartSummaryService: SmartSummaryService
  ) {}

  public isOpen = false;

  public connectedPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetX: 100,
    offsetY: -10,
  };

  get isMeRecording() {
    return (
      this.smartSummaryService.state.stage === 'recording'
      && this.smartSummaryService.state.iAmControllingHost
    );
  }
}
