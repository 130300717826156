import BrowserService from '../shared/browser.service.js';
import Config from './config.js';
import LogConfig from './log.config.js';
import MeetingSyncService from './meetingSync.service.js';
import TutorialComponent from './tutorial.component.js';
import TutorialService from './tutorial.service.js';
import UnloadService from './unload.service.js';

angular
  .module('meeting')

  .config(Config)
  .config(LogConfig)

  .service('meetingSyncService', MeetingSyncService)

  .service('tutorialService', TutorialService)
  .component('tutorial', TutorialComponent)

  .service('unloadService', UnloadService)
  .service('browserService', BrowserService);
