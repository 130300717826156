import { object, errors, logger, bind } from 'utils/util';

const PAPER_METHODS = Object.freeze({
  'paper-path-add': 'addShape',
  'paper-path-remove': 'removeShape',
  'paper-path-edit': 'editShape',
});


let maxEventId = 0;



export default class ShapeSyncService {
  static get $inject() {
    return [
      'userService',
      'meetingBroadcastService',
      'toolService',
      'whiteboardService',
      'editingUserService',
    ];
  }

  constructor(
    userService,
    meetingBroadcastService,
    toolService,
    whiteboardService,
    editingUserService
  ) {
    bind(this);

    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.toolService = toolService;
    this.whiteboardService = whiteboardService;
    this.editingUserService = editingUserService;

    this.events = {};
    /**
     * The ids of all the events that were applied to all the whiteboards, in the order that they
     * were applied. When undoing an operation, we pop the last item from this list, calculate and
     * apply its inverse operation, and add the event id to the end of `undoList`.
     */
    this.eventList = [];
    /**
     * The ids of the events that have been undone. Every time someone clicks "undo" we push an id
     * to this list. When someone clicks "redo", we pop the id from this list, apply the operation,
     * and add the event id back to the end of `eventList`.
     */
    this.undoList = [];
    /**
     * When you join a meeting room, you should only be able to undo/redo events that were applied
     * since you joined, not events from a previous session. These variables help with
     * distinguishing between these cases.
     */
    this.eventListLengthOnLoad = 0;
    this.redoBoundaryEventId = null;

    this.whiteboardService.on('add', this._onWhiteboardAdd);
    this.whiteboardService.on('remove', this._onWhiteboardRemove);

    this.toolService.on('undo', this._localUndo);
    this.toolService.on('redo', this._localRedo);

    this.meetingBroadcastService.on('paper-path-add',    this._onBroadcastAdd, false);
    this.meetingBroadcastService.on('paper-path-remove', this._onBroadcast, false);
    this.meetingBroadcastService.on('paper-path-edit',   this._onBroadcast, false);
    this.meetingBroadcastService.on('paper-path-undo',   this._onBroadcastUndo, false);
    this.meetingBroadcastService.on('paper-path-redo',   this._onBroadcastRedo, false);
    this.meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);
  }


  _afterBroadcastInitialization() {
    this.eventListLengthOnLoad = this.eventList.length;
    this.redoBoundaryEventId = this.undoList.length > 0 ?
      this.undoList[this.undoList.length - 1] :
      null;
    this._updateUndoRedoAvailable();
  }


  _updateUndoRedoAvailable() {
    this.toolService.setUndoAvailable(this.eventList.length > this.eventListLengthOnLoad);
    this.toolService.setRedoAvailable(
      this.undoList.length > 0
      && this.undoList[this.undoList.length - 1] !== this.redoBoundaryEventId
    );
  }


  _onWhiteboardAdd(whiteboard) {
    whiteboard.paperRenderer.on('shapeAdd', this._onShapeAdd);
    whiteboard.paperRenderer.on('shapeRemove', this._onShapeRemove);
    whiteboard.paperRenderer.on('shapesEdit', this._onShapesEdit);
  }


  _onWhiteboardRemove(whiteboard) {
    whiteboard.paperRenderer.off('shapeAdd', this._onShapeAdd);
    whiteboard.paperRenderer.off('shapeRemove', this._onShapeRemove);
    whiteboard.paperRenderer.off('shapesEdit', this._onShapesEdit);
  }


  /********************
   * Broadcast bridge *
   ********************/

  _onBroadcastAdd(
    channel, session, timestamp, eventId, whiteboardId, shapeId, properties, pageId, type
  ) {
    // Backwards compatibility
    if(type === 'text' && 'width' in properties) {
      properties.requestWidth = properties.width;
      delete properties.width;
    }

    this._onBroadcast(
      channel, session, timestamp, eventId, whiteboardId, shapeId, properties, pageId, type);
  }


  _onBroadcast(channel, session, timestamp, eventId, whiteboardId, shapeId, ...args) {
    this._sendToPaperRenderer(channel, whiteboardId, shapeId, ...args);

    this._storeEvent(channel, eventId, whiteboardId, shapeId, ...args);

    if(!this.meetingBroadcastService.initializing) {
      this.editingUserService.set(shapeId, session.user);
    }
  }



  _sendToPaperRenderer(channel, whiteboardId, shapeId, ...args) {
    if(channel === 'paper-path-add') {
      let [properties, pageId, type] = args;
      args = [type, properties, pageId];
    }

    let whiteboard = this.whiteboardService.get(whiteboardId);
    if(!whiteboard) {
      logger.withContext({ whiteboardId: whiteboardId }).warn('Whiteboard not found');
      return;
    }
    whiteboard.paperRenderer[PAPER_METHODS[channel]](shapeId, ...args, true);
  }



  _onShapeAdd(paperRenderer, shape) {
    if(shape.addSynced || shape.isEmpty()) {
      return;
    }

    shape.addSynced = true;
    shape.setPropertiesSynced();
    let properties = Object.assign({}, shape.syncedProperties);
    this._send('paper-path-add', paperRenderer.id, shape.id, properties, shape.pageId, shape.type);
  }


  _onShapeRemove(paperRenderer, shape) {
    if(!shape.addSynced || shape.removeSynced) {
      return;
    }

    shape.removeSynced = true;
    this._send('paper-path-remove', paperRenderer.id, shape.id);
  }


  _onShapesEdit(paperRenderer, shapeInfos, temporary) {
    if(temporary) {
      return;
    }

    shapeInfos.forEach(shapeInfo => this._onShapeEdit(paperRenderer, shapeInfo[0]));
  }

  _onShapeEdit(paperRenderer, shape) {
    if(!shape.addSynced) {
      this._onShapeAdd(paperRenderer, shape);
      return;
    }

    let update = shape.getSyncUpdate();
    if(object.length(update) > 0) {
      shape.setPropertiesSynced();
      this._send('paper-path-edit', paperRenderer.id, shape.id, update);
    }
  }


  _send(channel, ...args) {
    let eventId = this.userService.getUniqueId(++maxEventId);
    args.unshift(eventId);
    this._storeEvent(channel, ...args);
    this.meetingBroadcastService.send(channel, false, [], ...args);
  }




  _storeEvent(channel, eventId, whiteboardId, ...eventArgs) {
    this.events[eventId] = {
      channel: channel,
      whiteboardId: whiteboardId,
      args: eventArgs,
    };
    this.eventList.push(eventId);

    // Clear undo list (no redo's after new add/remove/edit event)
    this.undoList = [];
    this._updateUndoRedoAvailable();
  }



  /********
   * Undo *
   ********/

  _localUndo()  {
    if(this.eventList.length <= this.eventListLengthOnLoad) {
      return;
    }

    let eventId = this.eventList[this.eventList.length - 1];

    this.meetingBroadcastService.send('paper-path-undo', false, [], eventId);
    this._undo(eventId);
  }


  _onBroadcastUndo(channel, session, timestamp, eventId) {
    this._undo(eventId);
  }


  _undo(eventId) {
    // Remove the event from the event list and add it to the undo list
    let index = this.eventList.indexOf(eventId);
    if(index === -1) {
      return;
    }

    this.eventList.splice(index, 1);
    this.undoList.push(eventId);
    this._updateUndoRedoAvailable();

    let event = this.events[eventId];

    let update;
    try {
      update = this._getUndoProperties(event.channel, event.args[0], event.args[1]);
    } catch(error) {
      if(error.constructor !== errors.IllegalStateError) {
        throw error;
      }

      // No add event found. Ignore silently.
    }

    if(update) {
      this._sendToPaperRenderer(update.channel, event.whiteboardId, ...update.args);
    }
  }


  _getUndoProperties(channel, shapeId, properties) {
    let undoChannel = null;
    let undoArgs = [];

    // get event type
    switch(channel) {
      case 'paper-path-add' :
        undoChannel = 'paper-path-remove';
        undoArgs = this._getUndoArgsAdd(shapeId);
        break;

      case 'paper-path-remove' :
        undoChannel = 'paper-path-add';
        undoArgs = this._getUndoArgsRemove(shapeId);
        break;

      case 'paper-path-edit' :
        undoChannel = 'paper-path-edit';
        undoArgs = this._getUndoArgsEdit(shapeId, properties);
        break;
    }

    return {
      channel: undoChannel,
      args: undoArgs,
    };
  }


  _getUndoArgsAdd(shapeId) {
    return [shapeId];
  }


  _getUndoArgsRemove(shapeId) {
    // Find the add event
    for(let i = 0; i < this.eventList.length; i++) {
      let eventId = this.eventList[i];
      let event = this.events[eventId];

      if(event.channel === 'paper-path-add' && event.args[0] === shapeId) {
        let args = event.args.slice();
        // Get the properties of the object at the time it was removed
        args[1] = this._getProperties(shapeId);
        return args;
      }
    }

    throw new errors.IllegalStateError('No add event found');
  }


  _getUndoArgsEdit(shapeId, update) {
    let undoUpdate = this._getProperties(shapeId, update);
    return [shapeId, undoUpdate];
  }


  /**
   * Get all the properties of an object. If `keys` is provided, only the properties whose key is
   * present in `keys` are returned
   */
  _getProperties(shapeId, keys) {
    let numKeys = -1;
    let numKeysFound = 0;
    if(keys != null) {
      numKeys = object.length(keys);
    }

    let update = {};

    for(let i = this.eventList.length - 1; i >= 0; i--) {
      let eventId = this.eventList[i];
      let event = this.events[eventId];

      let channel = event.channel;
      let eventShapeId = event.args[0];

      if(eventShapeId === shapeId
        && (channel === 'paper-path-add' || channel === 'paper-path-edit')
      ) {
        let eventUpdate = event.args[1];
        for(let key in eventUpdate) {
          if(!(key in update) && (keys == null || key in keys)) {
            update[key] = eventUpdate[key];
            numKeysFound++;
          }
        }

        // Stop when all required properties have been found
        if(channel === 'paper-path-add' || numKeysFound === numKeys) {
          return update;
        }
      }
    }

    throw new errors.IllegalStateError('No add event found');
  }



  /********
   * Redo *
   ********/

  _localRedo() {
    if(this.undoList.length === 0) {
      return;
    }

    let eventId = this.undoList[this.undoList.length - 1];
    if(eventId === this.redoBoundaryEventId) {
      return;
    }

    this.meetingBroadcastService.send('paper-path-redo', false, [], eventId);
    this._redo(eventId);
  }


  _onBroadcastRedo(channel, session, timestamp, eventId) {
    this._redo(eventId);
  }


  _redo(eventId) {
    let event = this.events[eventId];

    // Remove event from undo list and re-add to event list
    let index = this.undoList.indexOf(eventId);
    if(index === -1) {
      return;
    }

    this.undoList.splice(index, 1);
    this.eventList.push(eventId);
    if(eventId === this.redoBoundaryEventId) {
      this.redoBoundaryEventId = this.undoList.length > 0 ?
        this.undoList[this.undoList.length - 1] :
        null;
    }
    this._updateUndoRedoAvailable();

    this._sendToPaperRenderer(event.channel, event.whiteboardId, ...event.args);
  }
}
