import { User } from 'core/models/User';
import * as fields from 'utils/fields';
import Model from 'utils/models';
import { Meeting } from './Meeting';


export class MeetingTemplate extends Model {
  static get fields() {
    return {
      createdBy : new fields.RelatedField({
        Model: User,
        readOnly: true,
      }),
      lastUpdated : new fields.DateTimeField(),
      meeting : new fields.RelatedField({
        Model: Meeting,
        readOnly: true,
      }),
      name : new fields.StringField(),
      url : new fields.StringField(),
    };
  }
  static get basePath() {
    throw 'meetingTemplates';
  }

  // eslint-disable-next-line no-unused-vars
  static listPath(identifiers) {
    throw Error('MeetingTemplates are only listable through the Users or Organization API');
  }
}

export class UserMeetingTemplate extends MeetingTemplate {
  static listPath(identifiers) {
    return `users/${identifiers.userId}/meetingTemplates`;
  }
}

export class OrganizationMeetingTemplate extends MeetingTemplate {
  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/meetingTemplates`;
  }
}
