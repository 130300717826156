import template from './inlineEdit.html?raw';


class InlineEditController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    this._bind();

    this.$elem = $elem;

    this.processing = false;
    this.text = null;

    this.$elemInput = $elem.find('input');
    this.$elemInput.on('keydown', this._onKeydown);
  }

  _bind() {
    this._onKeydown = this._onKeydown.bind(this);
    this._focusInput = this._focusInput.bind(this);
  }


  $onChanges(changes) {
    if('originalText' in changes) {
      this.text = this.originalText;
    }
    if('visible' in changes && this.visible) {
      $timeout(this._focusInput);
    }
  }


  get hasChanged() {
    return this.originalText !== this.text;
  }

  get canSubmit() {
    return this.hasChanged && !this.processing && this.text;
  }


  _focusInput() {
    this.$elemInput.focus();
  }

  _onKeydown($event) {
    if($event.key === 'Enter') {
      this.apply();
    }
  }


  apply() {
    if(!this.canSubmit) {
      return;
    }

    this.processing = true;
    $q.resolve()
      .then(() => this.applyCallback({ text: this.text }))
      .then(() => this.processing = false );
  }

  cancel() {
    if(this.processing) {
      return;
    }

    this.text = this.originalText;
    this.cancelCallback();
  }
}





export default {
  controller: InlineEditController,
  controllerAs: 'inlineEditCtrl',
  template,

  bindings: {
    originalText: '<text',
    visible: '<',
    applyCallback: '&',
    cancelCallback: '&',
  }
};
