<div
  class="appointment-type-availability__item"
  *ngIf="!instance.isTeamAppointmentType && selectedUser"
>
  <ng-container *ngTemplateOutlet="userSegment; context: { user: selectedUser }"></ng-container>
</div>


<ng-container *ngIf="instance.isTeamAppointmentType">

  <div
    *ngIf="memberConfigurationWarnings"
    class="field field--warning mb-2"
  >
    <field-icon [type]="'warning'"></field-icon>
    {{ memberConfigurationWarnings }}
  </div>

  <settings-errors [settingsErrors]="errors.availability"></settings-errors>
  <div *ngFor="let user of teamMembers" class="appointment-type-availability__item">
    <div
      class="appointment-type-availability__header"
      (click)="toggleSelectedUser(user)"
    >
      <svg-icon
        class="icon mr-4"
        [svgName]="'chevron_up'"
        *ngIf="isSelectedUser(user)"
      ></svg-icon>
      <svg-icon
        class="icon mr-4"
        [svgName]="'chevron_down'"
        *ngIf="!isSelectedUser(user) && !hasErrors(user)"
      ></svg-icon>
      <settings-icon
        *ngIf="!isSelectedUser(user) && hasErrors(user)"
        class="mr-4"
        [settingsIconType]="'invalid'"
      ></settings-icon>

      <profile-image [user]="user" class="mr-4"></profile-image>
      <div class="text-h3 mr-auto">{{ user.fullName }}</div>

      <div [stopPropagation]="'ckick'">
        <input
          type="checkbox"
          class="toggle"
          id="availability_active__{{ user.id }}"
          [disabled]="!isAdmin"
          [ngModel]="isUserActive(user)"
          (ngModelChange)="isAdmin && setUserActive(user, $event)"
        >
        <label
          class="toggle__label"
          for="availability_active__{{ user.id }}"
        ></label>
      </div>
    </div>

    <div class="appointment-type-availability__content" *ngIf="isSelectedUser(user)">
      <ng-container *ngTemplateOutlet="userSegment; context: { user: user }"></ng-container>
    </div>
  </div>
</ng-container>



<ng-template #userSegment let-user="user">
  <availability-picker
    [(availability)]="getAppointmentTypeConfig(user).availability"
    [schedulingConfig]="user.schedulingConfig"
    [errors]="getAvailabilityErrors(user)"
    [readonly]="!hasPermissionToEditMember(user)"
  ></availability-picker>

  <div class="border-top p-4" *ngIf="hasPermissionToEditMember(user)">
    <div class="appointment-type-availability__actions">
      <div
        class="form-group"
        *ngIf="instance.isTeamAppointmentType"
      >
        <label i18n>Copy availability from</label>

        <div class="d-flex">
          <dropdown-select
            class="flex-1"
            [options]="copyFromUserOptions"
            [(ngModel)]="copyFromUser"
          ></dropdown-select>
          <div
            class="ml-2 btn btn--outline-primary"
            (click)="copyAvailability(copyFromUser, user)"
            i18n
          >Copy</div>
        </div>
      </div>

      <div
        class="btn btn--secondary ml-auto"
        (click)="resetToDefault(user)"
        data-testid="resetToDefaultButton"
        i18n
      >
        Reset to default
      </div>
    </div>
  </div>

  <div *ngIf="getUnavailabilitiesCount(user) > 0 || hasPermissionToEditMember(user)" class="border-top p-4">
    <div>
      <div
        class="btn"
        [class.active]="shouldShowUnavailabilities"
        (click)="toggleUnavailabilities()"
        data-testid="toggleUnavailabilitiesButton"
      >
        <span i18n>{getUnavailabilitiesCount(user), plural, =0 {Unavailable dates} =1 {1 Unavailable date} other {{{getUnavailabilitiesCount(user)}} Unavailable dates}}</span>
        <svg-icon *ngIf="shouldShowUnavailabilities" class="btn__icon btn__icon--right" [svgName]="'chevron_up'"></svg-icon>
        <svg-icon *ngIf="!shouldShowUnavailabilities" class="btn__icon btn__icon--right" [svgName]="'chevron_down'"></svg-icon>
      </div>
    </div>

    <div
      *ngIf="getUnavailabilityErrors(user) && numberOfErrors(getUnavailabilityErrors(user)) > 0"
      class="field field--error mt-4"
    >
      <field-icon [type]="'error'"></field-icon>
      <settings-errors
        [settingsErrors]="getUnavailabilityErrors(user)"
        [settingsErrorsListClass]="'ul-plain mb-0'"
        [settingsErrorsItemClass]="''"
      ></settings-errors>
    </div>

    <div
      class="appointment-type-availability__unavailabilities mt-4"
      *ngIf="shouldShowUnavailabilities"
    >
      <unavailability-picker
        [(unavailabilities)]="getAppointmentTypeConfig(user).unavailability"
        [readonly]="!hasPermissionToEditMember(user)"
      ></unavailability-picker>
    </div>
  </div>
</ng-template>
