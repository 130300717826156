import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginationComponent } from './pagination.component';
import { SvgIconModule } from '../svg-icon';


@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
  ],
  declarations: [
    PaginationComponent,
  ],
  exports: [
    PaginationComponent,
  ],
})
export class PaginationModule {}
