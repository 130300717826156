import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreviewComponent } from './preview.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    PreviewComponent,
  ],
  exports: [
    PreviewComponent,
  ],
})
export class PreviewModule {}
