import { format, errors, logger, object } from 'utils/util';


const DEFAULT_CONFIG = Object.freeze({
  message: '',
  messages: {
    initial: '',
    progress: '',
    done: '',
    error: '',
  },
  progress: {
    start: 0,
    end: 100,
  },
  showCancel: true,
  maxRetries: 3,
  eventHandlers: {},
  uploadEventHandlers: {},
});

const EVENT_HANDLERS_PROPERTY = Object.freeze({
  get:    'eventHandlers',
  head:   'eventHandlers',
  delete: 'eventHandlers',
  post:   'uploadEventHandlers',
  put:    'uploadEventHandlers',
  patch:  'uploadEventHandlers',
});

const Status = Object.freeze({
  IN_PROGRESS: 'in progress',
  DONE: 'done',
  ERROR: 'error',
  CANCELLED: 'cancelled',
});


const RETRY_TIMEOUT_BASE = 1000;
const RETRY_TIMEOUT_MULTIPLIER = 1.3;
const RETRY_TIMEOUT_MAX = 8000;


export default class Request {
  constructor(
    $http,
    notificationService,
    id,
    config
  ) {
    this._bind();

    this.$http = $http;
    this.notificationService = notificationService;

    this.id = id;
    this.defer = $q.defer();
    this.cancelDefer = $q.defer();

    this.config = this._extendConfig(config);
    this.status = Status.IN_PROGRESS;

    this.progress = this.config.progress.start;
    this.progressBar = null;

    this.requestDelay = RETRY_TIMEOUT_BASE;
    this.requestTimeout = null;
    this.numRetries = 0;

    this._makeRequest();
  }

  _bind() {
    this.cancel = this.cancel.bind(this);
    this._onProgress = this._onProgress.bind(this);
    this._onSuccess = this._onSuccess.bind(this);
    this._onError = this._onError.bind(this);
    this._makeRequest = this._makeRequest.bind(this);
  }


  _extendConfig(userConfig) {
    let config = object.assignDeep({}, DEFAULT_CONFIG, userConfig);
    if(!config.messages.progress) {
      config.messages.progress = config.message;
    }
    delete config.message;
    return config;
  }


  _setStatus(status) {
    this.status = status;
    this._updateProgressBar();
  }


  _makeRequest() {
    this.progress = this.config.progress.start;
    this._updateProgressBar();

    let config = this._getRequestConfig();
    this.$http(config).then(this._onSuccess, this._onError);
  }


  _getRequestConfig() {
    let config = object.assignDeep({}, this.config);
    config.timeout = this.cancelDefer.promise;
    if(config.messages.progress) {
      let eventHandlersProperty = EVENT_HANDLERS_PROPERTY[config.method.toLowerCase()];
      config[eventHandlersProperty].progress = this._onProgress;
      delete config[eventHandlersProperty].abort;
    }
    return config;
  }


  _onProgress(event) {
    let progress = event.loaded / event.total;
    this.progress = this.progress.start + progress * (this.progress.end - this.progress.start);
    this._updateProgressBar();

    let eventHandlersProperty = EVENT_HANDLERS_PROPERTY[this.config.method.toLowerCase()];
    let eventHandlerProgress = this.config[eventHandlersProperty].progress;
    if(eventHandlerProgress) {
      eventHandlerProgress(event);
    }
  }


  _updateProgressBar() {
    let message = this.config.messages.progress;
    let showProgress = message && this.status === Status.IN_PROGRESS;

    if(showProgress) {
      if(this.progress === 0 && this.config.messages.initial) {
        message = this.config.messages.initial;
      } else if(this.progress === 100 && this.config.messages.done) {
        message = this.config.messages.done;
      }

      if(this.progressBar) {
        this.progressBar.setMessage(message);
      } else {
        let onCancel = this.config.showCancel ? this.cancel : null;
        this.progressBar = this.notificationService.progress(message, onCancel);
      }
      this.progressBar.setProgress(this.progress);

    } else if(this.progressBar) {
      this.progressBar.cancel();
      this.progressBar = null;
    }
  }


  _onSuccess(response) {
    if(this.status === Status.CANCELLED) {
      return;
    }

    this._setStatus(Status.DONE);
    this.defer.resolve(response);
  }


  _onError(response) {
    if(this.status === Status.CANCELLED) {
      return;
    }

    let status = response.status;
    let data = this._parseErrorResponse(response);
    let rejectionMessage = format('%s: %j', status, data);
    let rejection;

    if(status === 400) {
      rejection = new errors.ValidationError(rejectionMessage);
    } else if(status === 401 || status === 403) {
      rejection = new errors.UnauthorizedError(rejectionMessage);
    } else if(status === 404) {
      rejection = new errors.DoesNotExistError(rejectionMessage);
    } else if(status === 413) {
      rejection = new errors.RequestTooLargeError(rejectionMessage);
    } else if(status === 500) {
      rejection = new errors.ServerError(rejectionMessage);

    } else if(this.numRetries >= this.config.maxRetries) {
      if(status === -1) {
        rejection = new errors.OfflineError(rejectionMessage);
      } else {
        rejection = new errors.ServerError(rejectionMessage);
      }
    }

    if(rejection) {
      this._setStatus(Status.ERROR);
      if(this.config.messages.error) {
        this.notificationService.error(this.config.messages.error);
      }
      rejection.response = response;
      this.defer.reject(rejection);

    } else {
      logger.debug('Got status %s, retrying is %ss...', status, this.requestDelay / 1000);
      this.numRetries++;
      this.requestTimeout = $timeout(this._makeRequest, this.requestDelay);
      this.requestDelay = Math.min(
        this.requestDelay * RETRY_TIMEOUT_MULTIPLIER,
        RETRY_TIMEOUT_MAX
      );
    }
  }


  _parseErrorResponse(response) {
    let data = response.data;
    if(data instanceof ArrayBuffer) {
      let decoder = new TextDecoder('utf-8');
      try {
        data = decoder.decode(data);
      } catch(e) {
        // Do nothing
      }
    }
    return data;
  }


  cancel() {
    if(this.status !== Status.IN_PROGRESS) {
      return;
    }

    let eventHandlersProperty = EVENT_HANDLERS_PROPERTY[this.config.method.toLowerCase()];
    let eventHandlerAbort = this.config[eventHandlersProperty].abort;
    if(eventHandlerAbort) {
      eventHandlerAbort();
    }

    this.cancelDefer.resolve();
    this._setStatus(Status.CANCELLED);
  }
}
