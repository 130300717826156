import { I18nPluralPipe } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleControlValueAccessor } from 'utils/form-elements/simple-control-value-accessor';
import { AppointmentLocation } from '../../../models/appointment-location';

@Component({
  selector: 'location-picker',
  templateUrl: './location-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationPickerComponent),
      multi: true,
    },
  ],
})
export class LocationPickerComponent extends SimpleControlValueAccessor<AppointmentLocation[]> {

  readonly locationOptions = [
    AppointmentLocation.ONLINE,
    AppointmentLocation.OFFLINE,
    AppointmentLocation.PHONE_CALL
  ];

  public labelMap: Record<AppointmentLocation, string> = {
    'online': $localize`Online`,
    'offline': $localize`In person`,
    'phone_call': $localize`Phone call`
  };

  constructor(
    private i18nPluralPipe: I18nPluralPipe
  ) {
    super();
  }

  isLocationSelected(loc: AppointmentLocation) {
    return this.value?.includes(loc);
  }

  toggleLocation(loc: AppointmentLocation) {
    const selection = new Set(this.value);
    if(selection.has(loc)) {
      selection.delete(loc);
    } else {
      selection.add(loc);
    }
    this.writeValue([...selection.values()]);
  }
}
