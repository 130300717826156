<div
  class="btn"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="isOpen = !isOpen"
  [class.btn--translucent]="shouldBeTranslucent"
  [class.btn--round]="shouldCollapseButton"
  matTooltip="Private notes"
  i18n-matTooltip
  [matTooltipDisabled]="!shouldCollapseButton"
  data-testid="privateNotesButton"
>
  <svg-icon class="btn__icon" [svgName]="'edit'"></svg-icon>
  <div *ngIf="!shouldCollapseButton" class="btn__label" i18n>Personal notes</div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="[connectedPosition]"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayMinWidth]="450"
>
  <notes-modal class="d-flex"></notes-modal>
</ng-template>
