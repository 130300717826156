
import { bind, storage } from 'utils/util';

const LEGACY_NOTIFICATION_SETTINGS = 'notificationSettings';
const NOTIFICATION_SETTINGS = 'newNotificationSettings';

import { Inject, Injectable } from '@angular/core';
import { UrlService } from 'utils/url.service';

type LegacyNotificationSettings = {
  ownMeetings: boolean,
  teamWaitingRoom: boolean,
};

@Injectable({
  providedIn: 'root',
})
export class WaitingRoomNotificationService {
  public notificationsEnabled = false;

  constructor(
    @Inject('notificationService') private notificationService,
    @Inject('waitingRoomSessionsService') private waitingRoomSessionsService,
    @Inject('focusService') private focusService,
    @Inject('notifyService') private notifyService,

    private urlService: UrlService,
  ) {
    bind(this);

    this.waitingRoomSessionsService.on('knock-join', this.onKnockJoin);

    const legacyNotificationSettings : LegacyNotificationSettings = {
      ownMeetings: false,
      teamWaitingRoom: false,
      ...storage.getJSONItem(LEGACY_NOTIFICATION_SETTINGS, {}),
    };

    this.notificationsEnabled = (
      storage.getBooleanItem(
        NOTIFICATION_SETTINGS,
        legacyNotificationSettings.ownMeetings || legacyNotificationSettings.teamWaitingRoom,
      )
    );

    this.notifyService.setNotificationSound(this.urlService.urls.knockerSound);
  }

  private onKnockJoin(waitingRoomSession) {
    this.tryNotify(waitingRoomSession);
  }


  toggleNotifications() {
    this.notificationsEnabled = !this.notificationsEnabled;
    storage.setBooleanItem(NOTIFICATION_SETTINGS, this.notificationsEnabled);
  }


  get notificationPermission() {
    if(window.Notification) {
      return Notification.permission;
    } else {
      return null;
    }
  }

  get hasDektopNotificationPermission() {
    return this.notificationPermission === 'granted';
  }


  get isMissingPermissions() {
    return this.notificationsEnabled && !this.hasDektopNotificationPermission;
  }


  tryNotify(waitingRoomSession) {
    if(
      !waitingRoomSession.redirected
      && this.notificationsEnabled
    ) {
      this.notifyService.notify(false, true);
      this.setDesktopNotification(waitingRoomSession);
    }
  }


  private setDesktopNotification(waitingRoomSession) {
    if(!this.hasDektopNotificationPermission || this.focusService.hasFocus) {
      return;
    }
    const fullName = waitingRoomSession.session.user.fullName;
    const message = $localize `${fullName} is knocking in one of your meeting rooms`;

    try {
      if(window.Notification) {
        const notification = new window.Notification(message, {
          icon: this.urlService.urls.icon,
        });
        notification.addEventListener('click', this.onNotificationClick);
      }
    } catch(error: any) {
      // `new Notification()` is not allowed on mobile Chrome, this raises a TypeError: "Failed to
      // construct 'Notification': Illegal constructor. Use
      // ServiceWorkerRegistration.showNotification() instead."
      // We don't support mobile notifications for now, but we'll add them in the future.
      if(error.name !== 'TypeError') {
        throw error;
      }
    }
  }

  requestPermission() {
    if(window.Notification) {
      window.Notification.requestPermission();
    }
  }


  private onNotificationClick(event) {
    const notification = event.target;
    notification.close();
    window.focus();
  }

  showErrorNotification(message: string) {
    this.notificationService.error(message);
  }
}
