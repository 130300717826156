<div data-testid="dialog">
  <div *ngIf="dialogConfig.template" [innerHTML]="dialogConfig.template"></div>
  <div *ngIf="dialogConfig.text">{{ dialogConfig.text }}</div>
  <div *ngIf="dialogConfig.templatePortal">
    <ng-template [cdkPortalOutlet]="dialogConfig.templatePortal"></ng-template>
  </div>

  <div class="modal__footer">
    <div class="dialog__button-row">
      <div
        *ngIf="dialogConfig.cancelText"
        class="btn btn--secondary"
        [modalClose]="false"
        data-testid="dialogCancelButton"
      >
        {{ dialogConfig.cancelText }}
      </div>
      <div
        class="btn"
        [ngClass]="dialogConfig.confirmButtonClass"
        [modalClose]="true"
        data-testid="dialogConfirmButton"
      >
        {{ dialogConfig.confirmText }}
      </div>
    </div>
  </div>
</div>
