import * as browser from './browser';


export function splitExt(filename) {
  let match = filename.match(/(?:.*\/)?([^/]+)(\.[^/.]+)$/);
  return match ?
    [match[1], match[2]] :
    [filename, null];
}


export function toBlob(file, length) {
  if(length == null) {
    length = file.size;
  }
  return file.slice(0, length, file.type);
}


export function fromBlob(blob, name, argOptions) {
  if(argOptions == null) {
    argOptions = {};
  }
  let options = Object.assign({ type: blob.type }, argOptions);

  try {
    return new File([blob], name, options);
  } catch(error) {
    // Edge doesn't support the File constructor, but does support assigning the name property to
    // a Blob instance
    blob.name = name;
    blob.type = options.type;
    return blob;
  }
}


export function fromDataUrl(dataUrl, name) {
  let byteString;

  if(dataUrl.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataUrl.split(',')[1]);
  } else {
    byteString = decodeURI(dataUrl.split(',')[1]);
  }
  let mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  let ia = new Uint8Array(byteString.length);
  for(let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ia], name, { type: mimeString });
}


export function toArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    fileReader.onload = function(event) {
      resolve(event.target.result);
    };

    fileReader.onerror = function(event) {
      reject(event.target.error);
    };

    fileReader.readAsArrayBuffer(blob);
  });
}


export function toBinaryString(blob) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    fileReader.onload = function(event) {
      resolve(event.target.result);
    };

    fileReader.onerror = function(event) {
      reject(event.target.error);
    };

    fileReader.readAsBinaryString(blob);
  });
}


export function toDataURL(blob) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    fileReader.onload = function(event) {
      resolve(event.target.result);
    };

    fileReader.onerror = function(event) {
      reject(event.target.error);
    };

    fileReader.readAsDataURL(blob);
  });
}



export function download(file, name) {
  if(name == null) {
    name = file.name;
  }
  let url = URL.createObjectURL(file);
  downloadUrl(url, name);
}


export function downloadUrl(url, name) {
  // If you try to add the download attribute for a pdf file, Firefox 99 opens the file in the
  // same tab, thus ending the meeting session. This is not desired, didn't happen in older
  // versions, and there doesn't seem to be a way to work around this. It's also not easily
  // reproducible (e.g. see https://codepen.io/seppevectera/pen/bGazdjY?editors=1010, which doesn't
  // exhibit this behaviour.
  // Just don't use the download attribute for now. Hopefully this is fixed in a future version.
  if(browser.supportsInlineDownload() && !browser.isFirefox()) {
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', name);

    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    });

  } else {
    let childWindow = window.open(url);
    childWindow.document.title = name;
  }
}
