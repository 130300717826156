import Hint from './Hint.js';

export default class HintService {
  static get $inject() {
    return [
      'requestUserService',
      'apiService',
    ];
  }

  constructor(
    requestUserService,
    apiService
  ) {
    this.requestUserService = requestUserService;
    this.apiService = apiService;
    this.hasSeenHints = this.requestUserService.user.hasSeenHints;
  }

  hasSeenHint(hintName) {
    return this.hasSeenHints[hintName];
  }

  setHintSeen(...hintsOrNames) {
    let hintNames = hintsOrNames
      .map(hint => hint.name || hint)
      .filter(hintName => !this.hasSeenHints[hintName]);

    if(hintNames.length === 0) {
      return;
    }

    let data = {
      hasSeenHints: {},
    };

    for(let hintName of hintNames) {
      data.hasSeenHints[hintName] = true;
      this.hasSeenHints[hintName] = true;
    }

    return this.apiService.patch('users/me/', data)
      .then(response => {
        this.hasSeenHints = response.data.hasSeenHints;
      });
  }

  get(hintName) {
    return new Hint(this, hintName);
  }
}
