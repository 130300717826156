<div class="container" *ngIf="!loading; else loadingState">
  <div class="d-flex justify-content-center mb-6">
    <h1>Book a meeting with {{appointmentHost}}</h1>
  </div>
  <div class="card-grid card-grid--md">
    <appointment-type-card 
      *ngFor="let appointmentType of appointmentTypes" 
      [appointmentType]="appointmentType">
    </appointment-type-card>
  </div>
</div>

<ng-template #loadingState>
  <div class="d-flex justify-content-center">
    <h3 class="d-flex justify-content-center">
      <div translate>Loading your available meeting types...</div>
      <div class="ml-2">
        <loading size="'custom'"></loading>
      </div>
    </h3>
  </div>
</ng-template>
