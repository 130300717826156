<div class="d-flex flex-row align-items-center">
  <a
    class="go-back-header__button"
    href="{{ url }}"
    data-testid="goBackHeaderGoBackButton"
  >
    <svg-icon class="icon" [svgName]="'arrow_left'"></svg-icon>
  </a>
  <div class="page-title">{{ headerText }}</div>
</div>
