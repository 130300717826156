import template from './whiteboardTile.html?raw';
import templateDropdownMenu from './whiteboardMenu.dropdown.html?raw';
import templateDropdownDelete from './whiteboardDelete.dropdown.html?raw';
import templateDropdownDownload from './whiteboardDownload.dropdown.html?raw';

import ContentTileController from '../content/contentTile.controller';
import DocumentRenderer from './documents/DocumentRenderer';

const MENU_DROPDOWN_ID = 'whiteboardMenu';
const DELETE_DROPDOWN_ID = 'whiteboardDelete';
const DOWNLOAD_DROPDOWN_ID = 'whiteboardDownload';
const ALL_DROPDOWN_IDS = [MENU_DROPDOWN_ID, DELETE_DROPDOWN_ID, DOWNLOAD_DROPDOWN_ID];

const SHOW_RENDERING_TIMEOUT = 1000;


class WhiteboardTileController extends ContentTileController {
  static get $inject() {
    return [
      'tileService',
      'whiteboardService',
      'dropdownService',
    ].concat(ContentTileController.$inject);
  }

  constructor(
    tileService,
    whiteboardService,
    dropdownService,
    ...args
  ) {
    super(...args);

    dropdownService.register(
      MENU_DROPDOWN_ID,
      templateDropdownMenu,
      {
        cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x',
      }
    );

    dropdownService.register(
      DELETE_DROPDOWN_ID,
      templateDropdownDelete,
      {
        cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x',
      }
    );

    dropdownService.register(
      DOWNLOAD_DROPDOWN_ID,
      templateDropdownDownload,
      {
        cssClasses: 'dropdown-deprecated--no-margin dropdown-deprecated--no-padding-x',
      }
    );


    this.tileService = tileService;
    this.whiteboardService = whiteboardService;
    this.dropdownService = dropdownService;

    this.$elemScrollBarThumbX = null;
    this.$elemScrollBarThumbY = null;

    this.dragListenerScrollBarX = null;
    this.dragListenerScrollBarY = null;

    this.whiteboard = null;
    this.name = null;
    this.pageIndex = null;

    this.shouldShowRendering = false;
    this.showRenderingTimeout = null;
  }


  $onChanges(changes) {
    if(changes.tile) {
      if(this.whiteboard) {
        this.tileService.off('draw', this._onTilesDraw);
        this.whiteboard.off('name', this._onWhiteboardName);
        this.whiteboard.off('page', this._onWhiteboardPage);
        this.whiteboard.documentRenderer.off('status', this._updateRendering);
        this.whiteboard = null;
        this.name = null;
      }

      if(this.tile) {
        this.whiteboard = this.whiteboardService.get(this.tile.id);

        this.whiteboard.documentRenderer.setElem(this.$elem);
        this.whiteboard.paperRenderer.setElem(this.$elem);

        this.tileService.on('draw', this._onTilesDraw);
        this.whiteboard.on('name', this._onWhiteboardName);
        this.whiteboard.on('page', this._onWhiteboardPage);
        this.whiteboard.documentRenderer.on('status', this._updateRendering);
        this._onWhiteboardName();
        this._onWhiteboardPage();
        this._updateRendering();
      }
    }
  }


  get zoomLevelStr() {
    return Math.round(this.whiteboard.zoomLevel * 100) + '%';
  }


  _toggleDropdown(id, $event) {
    this.dropdownService.toggle(id, $event, {
      tile: this.tileService.activeContentTile,
    });
  }

  toggleMenu($event) {
    this._toggleDropdown(MENU_DROPDOWN_ID, $event);
  }

  toggleDelete($event) {
    this._toggleDropdown(DELETE_DROPDOWN_ID, $event);
  }

  handleDownload($event) {
    if(!this.whiteboard.hasDocument) {
      // No need for a dropdown, just download the whiteboard.
      this.whiteboard.download();
    } else {
      this._toggleDropdown(DOWNLOAD_DROPDOWN_ID, $event);
    }
  }


  _onTilesDraw() {
    ALL_DROPDOWN_IDS.forEach(dropdownId => {
      let dropdown = this.dropdownService.get(dropdownId);
      if(
        !this.tileService.activeContentTile
        || dropdown.scope.tile !== this.tileService.activeContentTile
      ) {
        this.dropdownService.hide(dropdownId);
      }
    });
  }


  _onWhiteboardName() {
    this.name = this.whiteboard.name;
  }
  setName() {
    this.whiteboard.setName(this.name, true);
  }


  _onWhiteboardPage() {
    this.pageIndex = this.whiteboard.pageIndex + 1;
  }
  setPage() {
    this.whiteboard.setPageIndex(this.pageIndex - 1);
    // Force updating this.pageIndex in case setPageIndex didn't have any effect.
    this._onWhiteboardPage();
  }


  get status() {
    return this.whiteboard && this.whiteboard.documentRenderer.status;
  }


  _updateRendering() {
    let shouldShowRendering = this._shouldShowRendering();
    if(!shouldShowRendering) {
      this._hideRendering();
    } else if(!this.shouldShowRendering && !this.showRenderingTimeout) {
      this.showRenderingTimeout = $timeout(this._showRendering, SHOW_RENDERING_TIMEOUT);
    }
  }

  _shouldShowRendering() {
    return (
      this.status === DocumentRenderer.Status.RENDERING
      || this.status === DocumentRenderer.Status.DOWNLOADING
    );
  }

  _showRendering() {
    this.showRenderingTimeout = null;
    this.shouldShowRendering = true;
  }

  _hideRendering() {
    $timeout.cancel(this.showRenderingTimeout);
    this.showRenderingTimeout = null;
    this.shouldShowRendering = false;
  }


  get shouldShowDownloading() {
    return this.status === DocumentRenderer.Status.DOWNLOADING;
  }
  get downloadProgress() {
    return this.whiteboard && this.whiteboard.documentRenderer.downloadProgress;
  }
}


export default {
  controller: WhiteboardTileController,
  controllerAs: 'whiteboardTileCtrl',
  template,

  bindings: {
    'tile': '<',
  },
};
