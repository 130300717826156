import ReliableSocketService from 'meeting/angularjs/shared/communication/reliableSocket.service';
import { State } from  'meeting/angularjs/shared/communication/reliableSocket.service';
import { ReadyState } from  'meeting/angularjs/shared/communication/socket.service';


export default class MeetingReliableSocketService extends ReliableSocketService {
  static get $inject() {
    return [
      'meetingService',
      'meetingSocketService',
      'userService',
    ].concat(ReliableSocketService.$inject);
  }

  constructor(
    meetingService,
    meetingSocketService,
    userService,
    ...args
  ) {
    super(...args, meetingSocketService);

    this.meetingService = meetingService;
    this.userService = userService;

    this.meetingService.on('state', this._updateState);
  }


  _getNewState() {
    return (
      this.socketService.state === ReadyState.OPEN
      && (
        this.meetingService.state === this.meetingService.MeetingState.KNOCKING
        || this.meetingService.state === this.meetingService.MeetingState.JOINED
      )
    ) ?
      State.CONNECTED :
      State.DISCONNECTED;
  }


  _readyToSend(item) {
    return this.state === State.CONNECTED && (
      this.userService.me.isKnocking()
      || !item.extra.session
      || item.extra.session.isAlive()
      || item.extra.session.isKnocking()
    );
  }

  get sessionId() {
    return this.userService.mySession.id;
  }


  send(channel, message, session) {
    return super.send(channel, message, { session: session });
  }
}
