<div class="usage-track">
  <div
    class="usage-bar"
    [ngStyle]="{
      'width.%': usagePercentage
    }"
    [ngClass]="{
      'usage-bar--medium': hasMediumUsage,
      'usage-bar--high': hasHighUsage
    }"
  ></div>
</div>
