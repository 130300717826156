import { I18nPluralPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimeDelta } from 'utils/util';
import { TimeDeltaUnit } from 'utils/util/TimeDelta';
import { SegmentedButtonOptionFull } from '..';
import { SimpleControlValueAccessor } from '../../simple-control-value-accessor';

type InputOptionFull = {
  value: number | null,
  alias: string,
};

type InputDurationOption = number | InputOptionFull;



/**
 * A preconfigured option can be defined either as a simple number, or an object that defines an
 * alias. Examples:
 *
 * ```
 * // Simple
 * [0, 15, 30]
 *
 * // With alias
 * [{ value: 0, alias: 'No buffer' }, 15, 30]
 * ```
 *
 * If no alias is provided, an option will be shown as the value + unit, e.g. "15 minutes".
 * The value of the duration-picker can be nullable, but only when using InputDurationOptionFull
 */

const UNIT_MAPS = {
  seconds: {
    '=0': $localize `0 seconds`,
    '=1': $localize `1 second`,
    'other': $localize `# seconds`,
    'unit': $localize `seconds`,
  },
  minutes: {
    '=0': $localize `0 minutes`,
    '=1': $localize `1 minute`,
    'other': $localize `# minutes`,
    'unit': $localize `minutes`,
  },
  hours: {
    '=0': $localize `0 hours`,
    '=1': $localize `1 hour`,
    'other': $localize `# hours`,
    'unit': $localize `hours`,
  },
  days: {
    '=0': $localize `0 days`,
    '=1': $localize `1 day`,
    'other': $localize `# days`,
    'unit': $localize `days`,
  },
  weeks: {
    '=0': $localize `0 weeks`,
    '=1': $localize `1 week`,
    'other': $localize `# weeks`,
    'unit': $localize `weeks`,
  },
  months: {
    '=0': $localize `0 months`,
    '=1': $localize `1 month`,
    'other': $localize `# months`,
    'unit': $localize `months`,
  },
  years: {
    '=0': $localize `0 years`,
    '=1': $localize `1 year`,
    'other': $localize `# years`,
    'unit': $localize `years`,
  },
};



/**
 * A duration picker component that shows a few preconfigured options, and optionally an input
 * field for custom values.
 *
 * Example usage:
 * <duration-picker
    [durationUnit]="'minutes'"
    [durationOptions]="[15, 30, 60]"
    [(durationValue)]="durationValue"
    [durationAllowCustom]="true"
  ></duration-picker>
 */
@Component({
  selector: 'duration-picker[durationUnit][durationOptions]',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationPickerComponent),
      multi: true,
    },
  ],
})
export class DurationPickerComponent extends SimpleControlValueAccessor<TimeDelta | null> {
  /** The time unit of the value. */
  @Input() durationUnit!: TimeDeltaUnit;
  /** A list of preconfigured options. See DurationOption for more info. */
  @Input('durationOptions') _durationOptions!: readonly InputDurationOption[];
  /** Whether the user is allowed to enter a custom value.  */
  @Input() durationAllowCustom = false;

  private isCustomValue = false;

  @ViewChild('customValue') elemCustomValue?: ElementRef;

  constructor(
    private i18nPluralPipe: I18nPluralPipe
  ) {
    super();
  }


  override writeValue(value: TimeDelta | null) {
    if(value !== this.value) {
      if(value) {
        if(this.timeDeltaIsCustomValue(value)) {
          this.isCustomValue = true;
        }
      }

      super.writeValue(value);
    }
  }


  get isNullable() {
    return this.durationOptions.some(option => option.value == null);
  }

  get numberValue() {
    if(this.value == null) {
      return null;
    } else {
      return this.value?.getUnit(this.durationUnit);
    }
  }

  get unitName() {
    return UNIT_MAPS[this.durationUnit].unit;
  }


  /*************************
   * Preconfigured options *
   *************************/

  /**
   * Return all the input duration options as DurationOption instances.
   */
  get durationOptions(): SegmentedButtonOptionFull[] {
    return this._durationOptions.map(option => {
      const alias = typeof option === 'object' ? option.alias : undefined;
      const numberValue = typeof option === 'object' ? option.value : option;

      return {
        value: numberValue,
        label: this.getOptionLabel(numberValue, alias)
      };
    });
  }


  getTimeDelta(numberValue: number | null) {
    if(numberValue == null) {
      return null;
    } else {
      return TimeDelta.createFromUnit(this.durationUnit, numberValue);
    }
  }

  getOptionLabel(value: number | null, alias?: string) {
    if(alias != null) {
      return alias;
    } else {
      return this.i18nPluralPipe.transform(value, UNIT_MAPS[this.durationUnit]);
    }
  }


  pickOption(numberValue: number | null) {
    this.isCustomValue = false;
    const timeDelta = this.getTimeDelta(numberValue);
    this.writeValue(timeDelta);
    this.onTouched();
  }



  /**********************
   * Custom input field *
   **********************/

  timeDeltaIsCustomValue(timeDelta: TimeDelta | null | undefined) {
    return !this.durationOptions.some(option => (
      (timeDelta == null && option.value == null)
      || (timeDelta && timeDelta.getUnit(this.durationUnit) === option.value)
    ));
  }

  get shouldShowCustom() {
    return this.durationAllowCustom && this.isCustomValue;
  }


  showCustom() {
    this.isCustomValue = true;
    setTimeout(() => {
      if(this.elemCustomValue) {
        this.elemCustomValue.nativeElement.focus();
      }
    });
  }


  setCustomValue(input: number) {
    if(this.disabled) {
      return;
    } else if(input == null) {
      this.writeValue(null);
    } else {
      const timeDelta = TimeDelta.createFromUnit(this.durationUnit, input);
      this.writeValue(timeDelta);
    }

  }


  onCustomValueBlur() {
    if(this.value == null && !this.isNullable) {
      this.setCustomValue(0);
    }

    this.isCustomValue = this.timeDeltaIsCustomValue(this.value);

    this.onTouched();
  }

  onCustomValueEnter() {
    this.onCustomValueBlur();
  }
}
