import { Component, OnInit, Inject } from '@angular/core';
import { UrlService } from 'utils/url.service';
import { AdminPulseConfig } from '../models/AdminPulseConfig';
import { LocalStateService } from 'utils/state.service';
import { AdminPulseService } from './adminpulse.service';
import { bind, logger } from 'utils/util';
import { TeamMembersService } from 'organization/teamMembers.service';
import { Member } from 'organization/models/Member';
import { DropdownOptionFull } from 'utils/form-elements/dropdowns';
import { RequestUserService } from 'utils/requestUser.service';
import { SiteService } from 'utils/site.service';
import { Hint } from 'utils/angularjs/hints/Hint';

// keep in sync with AdminPulseConfig model
const DEFAULT_MAPPING_VALUE = 'use_default';
type PriceMapping = Record<string, string | null>;

@Component({
  selector: 'adminpulse-config',
  styleUrls: ['./adminpulse-config.component.scss'],
  templateUrl: './adminpulse-config.component.html',
})
export class AdminPulseConfigComponent implements OnInit {
  public adminPulseConfig: AdminPulseConfig | null;
  public teamMembers: Member[] = [];
  public priceListOptions: DropdownOptionFull[] = [];
  public defaultPriceListOptions: DropdownOptionFull[] = [];
  // map vectera user id to adminpulse pricelistitem id
  public priceMapping: PriceMapping = {};
  public shouldWarnDefaultNotPresent = false;
  public emailAddressHint: Hint;
  configHelpArticle: string;
  ttHelpArticle: string;

  constructor(
    public urlService: UrlService,
    public stateService: LocalStateService,
    public adminPulseService: AdminPulseService,
    private teamMemberService: TeamMembersService,
    private requestUserService: RequestUserService,
    private siteService: SiteService,

    @Inject('notificationService') private notificationService,
    @Inject('apiService') private apiService,
    @Inject('hintService') private hintService,
  ) {
    bind(this);

    this.emailAddressHint = this.hintService.get('adminpulseEmailAddresses');

    this.configHelpArticle = this.siteService.getHelpArticle('adminPulseConfig');
    this.ttHelpArticle = this.siteService.getHelpArticle('adminPulseTimeTracking');

  }

  ngOnInit() {
    this.stateService.setLoading();

    Promise.all([
      this.fetchAdminPulseConfig(),
      this.teamMemberService.get().then(teamMembers => {
        this.teamMembers = teamMembers.filter(member => member.isActive);
      })
    ]).then(this.fetchAndSetupPricelist)
      .catch(error => {
        logger.error(error);
        this.notificationService.error(
          $localize `Something went wrong while fetching data. Please try again later.`
        );
      })
      .finally(() => this.stateService.setReady());
  }

  private fetchAdminPulseConfig(): Promise<void> {
    if(!this.adminPulseService.isConnected) {
      return Promise.resolve();
    }
    return this.adminPulseService.getConfig().then(adminPulseConfig => {
      this.adminPulseConfig = adminPulseConfig;
      this.priceMapping = { ...this.adminPulseConfig.priceMapping };
    });
  }

  private fetchAndSetupPricelist(): Promise<void> {
    if(!this.adminPulseService.isConnected) {
      return Promise.resolve();
    }

    return this.apiService.get('integrations/adminpulse/priceListItems')
      .then(({ data: pricelist }) => {
        this.defaultPriceListOptions = pricelist.map(price => {
          return {
            label: price.name,
            value: price.id
          };
        });

        this.priceListOptions = [
          ...this.defaultPriceListOptions,
          {
            label: $localize `Use default setting`,
            value: DEFAULT_MAPPING_VALUE
          }
        ];

        this.shouldWarnDefaultNotPresent = (
          this.adminPulseConfig.defaultPricelistItemId != null
          && this.adminPulseConfig.defaultPricelistItemId !== ''
          && pricelist.find(price => {
            return price.id === this.adminPulseConfig.defaultPricelistItemId;
          }) == null
        );


        if(this.shouldWarnDefaultNotPresent || !this.adminPulseConfig.defaultPricelistItemId) {
          this.adminPulseConfig.defaultPricelistItemId = null;
        }

        this.teamMembers.forEach(member => {

          if(!(member.id in this.priceMapping)) {
            // A member that's not present should see the 'use default' value in the dropdown
            this.priceMapping[member.id] = DEFAULT_MAPPING_VALUE;
          }

          const configuredPriceIsNotPresentInList = (
            this.priceMapping[member.id] != null
            && this.priceMapping[member.id] !== DEFAULT_MAPPING_VALUE
            && pricelist.find(price => price.id === this.priceMapping[member.id]) == null
          );

          if(configuredPriceIsNotPresentInList) {
            this.priceMapping[member.id] = DEFAULT_MAPPING_VALUE;
          }
        });

      });
  }


  get canConnect() {
    return this.requestUserService.user.organization.allowTeamAccessLevelOverride;
  }

  public goToConnectUrl() {
    window.location.href = this.urlService.urls.connectUrl;
  }

  submit() {
    this.adminPulseConfig.priceMapping = this.priceMapping;
    this.stateService.setSaving();
    this.adminPulseConfig.update().then(() => {
      this.shouldWarnDefaultNotPresent = false;
      this.notificationService.success(
        $localize `Your selected pricings have succesfully been updated.`,
      );
      this.stateService.setReady();
    });
  }

  disconnect() {
    this.adminPulseConfig?.disconnect().then(() =>{
      this.adminPulseConfig = null;
      this.notificationService.success(
        $localize `Successfully disconnected your AdminPulse account.`,
      );
    });
  }
}
