export default class TileFactory {
  static get $inject() {
    return [
      '$injector',
    ];
  }

  constructor(
    $injector
  ) {
    this.$injector = $injector;
  }

  create(cls, ...args) {
    let injectStr = cls.$inject;
    let inject = injectStr.map(inject => this.$injector.get(inject));
    return new cls(...args, ...inject);
  }
}
