<div *ngIf="shouldShowTeamleaderFieldMapping">
  <h3 i18n>What happens with a completed form?</h3>
  <entity-creation [instance]="instance.contactForm"></entity-creation>

  <h3>Add questions</h3>
</div>

<contact-form-config-questions
  [instance]="instance.contactForm"
  [appointmentTypeInstance]="instance"
  [errors]="contactFormErrors"
></contact-form-config-questions>
