import {
  AfterViewInit,
  Component,
  ComponentRef, Input, OnChanges,
  SimpleChanges, TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  MappingFieldDisabledReason, MappingFieldsService
} from 'contactForm/contact-form-config/mapping-fields.service';
import { ContactForm } from 'contactForm/models/ContactForm';
import {
  ContactFormQuestion, isTypeAllowedFree, QuestionType
} from 'contactForm/models/ContactFormQuestion';
import { MappingField, Provider } from 'contactForm/models/MappingField';
import {
  ComboBoxCategory
} from 'utils/form-elements/dropdowns/combo-box/combo-box.component';
import { RequestUserService } from 'utils/requestUser.service';
import { Errors, ErrorValue, isErrors } from 'utils/settings/settings.component';
import { SiteService } from 'utils/site.service';
import { UrlService } from 'utils/url.service';
import { bind } from 'utils/util';
import {
  QuestionTypeConfigComponent
} from '../question-type-config/question-type-config.component';
import { QuestionTypeCategory, questionTypeConfigs } from './QuestionTypeConfig';

const QuestionTypeCategoryLabels: Record<QuestionTypeCategory, string> = {
  [QuestionTypeCategory.TEXT]: $localize `Text`,
  [QuestionTypeCategory.CHOICES]: $localize `Choices`,
  [QuestionTypeCategory.CONTACT_COMPANY]: $localize `Contact & Company`,
  [QuestionTypeCategory.ATTACHMENTS]: $localize `Attachments`,
  [QuestionTypeCategory.DATE_NUMBER]: $localize `Date & Number`,
  [QuestionTypeCategory.LEGAL_ACCOUNTING]: $localize `Legal & Accounting`,
};


export enum QuestionTypeDisabledReason {
  NONE,
  FREE_PLAN,
}


/**
 * A contact form question will dynamically load it's preview component and customization input
 * component by mapping the selected questionType to a QuestionTypeConfig in the
 * questionTypeMap.
 */
@Component({
  selector: 'contact-form-config-question[question]',
  templateUrl: './contact-form-config-question.component.html',
  styleUrls: ['contact-form-config-question.component.scss'],
})
export class ContactFormConfigQuestionComponent implements AfterViewInit, OnChanges {
  @Input() contactForm!: ContactForm;
  @Input() question!: ContactFormQuestion;
  @Input() errors: Errors = {};
  @Input() hasPermissionToEdit!: boolean;
  @Input() showTeamleaderFieldMapping = false;

  inputRef!: ComponentRef<QuestionTypeConfigComponent>;

  @ViewChild('questionPreview', { read: ViewContainerRef }) previewContainer?: ViewContainerRef;
  @ViewChild('extraInput', { read: ViewContainerRef }) inputContainer?: ViewContainerRef;
  @ViewChild('typeSelector', { read: TemplateRef, static: false }) typeSelector!: TemplateRef<any>;

  readonly questionTypeOptions: ComboBoxCategory[];

  public teamleaderMappingFieldComboBoxCategories: ComboBoxCategory[] = [];

  public QuestionType = QuestionType;

  private _teamleaderMappedField: MappingField | null = null;
  /**
   * When selecting a mapping field which has preset options, `optionsPreMapping` stores the
   * options that the user had already entered, so that we can restore them if they decide that
   * they don't want to map to that field after all.
   */
  private optionsPreMapping = {
    options: [],
    otherOption: false,
  };


  constructor(
    private requestUserService: RequestUserService,
    private mappingFieldsService: MappingFieldsService,
    public urlService: UrlService,
    public siteService: SiteService,
  ) {
    bind(this);
    this.questionTypeOptions = this.buildQuestionTypeOptions();
  }


  ngAfterViewInit(): void {
    this.setQuestionType(this.question.type);
    if(this.contactForm.teamleaderMappingConfig) {
      this.contactForm.teamleaderMappingConfig.on(
        'createContact createCompany createDeal', this.updateTeamleaderMappingFields
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mappingFieldIdsInUse) {
      this.updateTeamleaderMappingFields();
    }
  }


  get shouldAutoCapture() {
    // Don't autofocus the question when creating a new form. In this case, it's the form name that
    // we want to autofocus instead.
    return !(
      this.contactForm.id == null
      && this.contactForm.questions.length === 1
      && this.question.label == null
    );
  }


  buildQuestionTypeOptions(): ComboBoxCategory[] {
    const categories = new Map<QuestionTypeCategory, ComboBoxCategory>();
    Object.values(QuestionTypeCategory).forEach(category => {
      categories.set(category,  {
        label: QuestionTypeCategoryLabels[category],
        options: [],
      });
    });

    Object.keys(questionTypeConfigs).forEach(questionType => {
      const questionTypeConfig = questionTypeConfigs[questionType];

      let label = questionTypeConfig.label;
      if(questionTypeConfig.labelExtra) {
        label += ` (${questionTypeConfig.labelExtra})`;
      }
      const disabledReason = this.getQuestionTypeDisabledReason(questionType);
      const disabled = disabledReason !== QuestionTypeDisabledReason.NONE;

      categories.get(questionTypeConfig.category)?.options.push({
        value: questionType,
        label: label,
        icon: questionTypeConfig.icon,
        disabled: disabled,
        requiresUpgrade: disabledReason === QuestionTypeDisabledReason.FREE_PLAN,
      });
    });

    return Array.from(categories.values());
  }


  setQuestionType(type: QuestionType) {
    this.question.type = type;
    this.updateQuestionTypeConfig();
    this.updateTeamleaderMappingFields();
  }


  updateQuestionTypeConfig() {
    if(!this.inputContainer) {
      return;
    }

    this.inputContainer.clear();
    if(this.question.type == null || this.teamleaderMappedField?.hasPresetQuestionConfig) {
      return;
    }

    const questionTypeConfig = questionTypeConfigs[this.question.type];
    if(questionTypeConfig.extraInput == null) {
      return;
    }

    this.inputRef = this.inputContainer
      .createComponent<QuestionTypeConfigComponent>(questionTypeConfig.extraInput);
    this.inputRef.instance.question = this.question;
    this.inputRef.instance.hasPermissionToEdit = this.hasPermissionToEdit;

    const extraInputParams = questionTypeConfig.extraInputParams || {};
    Object.entries(extraInputParams).forEach(([key, value]) => {
      this.inputRef.instance[key] = value;
    });
  }


  get isVecteraDefault() {
    const mappingProviders = Object.keys(this.question.extra.mappedFields || {});
    return (
      this.question.isDefault
      && mappingProviders.length === 1
      && mappingProviders[0] === Provider.VECTERA
    );
  }

  get vecteraDefaultName() {
    const fieldId = this.question.getMappedFieldId(Provider.VECTERA);
    return (
      this.mappingFieldsService.mappingFields.vectera
      && this.mappingFieldsService.mappingFields.vectera[fieldId]?.name
    );
  }

  /****************************
   * Teamleader field mapping *
   ****************************/

  get teamleaderMappingFieldErrors(): ErrorValue | undefined {
    if(
      isErrors(this.errors)
      && isErrors(this.errors.extra)
      && isErrors(this.errors.extra.mappedFields)
    ) {
      if(isErrors(this.errors.extra.mappedFields.teamleader)) {
        return this.errors.extra.mappedFields.teamleader.id;
      } else if(this.errors.extra.mappedFields.teamleader) {
        return this.errors.extra.mappedFields.teamleader;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  private getQuestionTypeDisabledReason(questionType: QuestionType): QuestionTypeDisabledReason {
    if(
      this.requestUserService.user.organization.subscription.status === 'free'
      && !isTypeAllowedFree(questionType)
    ) {
      return QuestionTypeDisabledReason.FREE_PLAN;
    } else {
      return QuestionTypeDisabledReason.NONE;
    }
  }

  get teamleaderMappedField() {
    return this._teamleaderMappedField;
  }
  set teamleaderMappedField(mappedField: MappingField | null) {
    if(mappedField?.id === this.teamleaderMappedField?.id) {
      return;
    }

    if(!this.question.extra.mappedFields) {
      this.question.extra.mappedFields = {};
    }
    if(mappedField) {
      this.question.extra.mappedFields.teamleader = {
        id: mappedField.id,
      };
    } else {
      delete this.question.extra.mappedFields.teamleader;
    }
    if(Object.keys(this.question.extra.mappedFields).length === 0) {
      delete this.question.extra.mappedFields;
    }
    this.question.markDirty('extra');

    // Update the question options
    if(this.question.extra.options && !this.teamleaderMappedField?.extra?.options) {
      this.optionsPreMapping = {
        options: this.question.extra.options.slice(),
        otherOption: this.question.extra.otherOption,
      };
    }
    if(mappedField?.extra?.options) {
      this.question.extra.options = mappedField.extra.options.slice();
      this.question.extra.otherOption = mappedField.extra.otherOption || false;
    } else {
      this.question.extra.options = this.optionsPreMapping.options.slice();
      this.question.extra.otherOption = this.optionsPreMapping.otherOption;
    }
    if(mappedField?.extra?.currency) {
      this.question.extra.currency = mappedField.extra.currency;
    }

    this._teamleaderMappedField = mappedField;
    if(mappedField && this.question.type == null) {
      if(mappedField.isFallback) {
        this.setQuestionType(
          mappedField.questionTypes.find((type: QuestionType) => type === QuestionType.MULTI_LINE)
          || mappedField.questionTypes[0]
        );
      } else {
        this.setQuestionType(mappedField.questionTypes[0]);
      }
    } else {
      this.setQuestionType(this.question.type);
    }
  }

  updateTeamleaderMappingFields() {
    this.teamleaderMappingFieldComboBoxCategories =
      this.mappingFieldsService.generateComboBoxCategories(
        this.contactForm.teamleaderMappingConfig,
        this.question
      );

    const teamleaderMappedField = this.mappingFieldsService.getMappingField(
      Provider.TEAMLEADER,
      this.question.getMappedFieldId(Provider.TEAMLEADER)
    );

    this.teamleaderMappedField = teamleaderMappedField;

    // unset the teamleaderMappedField if the question type is changed to something incompatible
    const teamleaderMappingFields = this.mappingFieldsService.mappingFields.teamleader || {};
    Object.values(teamleaderMappingFields).forEach(mappingField => {
      const disabledReason = this.mappingFieldsService.getMappingFieldDisabledReason(
        this.contactForm.teamleaderMappingConfig,
        this.question,
        mappingField,
        false
      );

      const optionIsDisabled = disabledReason !== MappingFieldDisabledReason.NONE;
      if(mappingField.id === this.question.getMappedFieldId(Provider.TEAMLEADER)) {
        this.teamleaderMappedField = !optionIsDisabled ? mappingField : null;
      }
    });
  }
}
