import { format } from 'utils/util';

const SEND_VIEWPORT_INTERVAL = 500;

/**
 * Sync the viewports of video tiles for all participants of a meeting:
 *
 * When enabled on organisation level, a videofeed can be panned, tilted or zoomed,
 * either digitally or by using the PTZ controls of a camera that supports these movements.
 *
 * These movements are synced so every used can manipulate the feed and sees the same
 * representation in the videoViewportMiniature
 */
export default class VideoViewportService {
  static get $inject() {
    return [
      'meetingBroadcastService',
      'streamService',
      'tileService',
    ];
  }

  constructor(
    meetingBroadcastService,
    streamService,
    tileService
  ) {
    this._bind();

    this.meetingBroadcastService = meetingBroadcastService;
    this.streamService = streamService;
    this.tileService = tileService;

    this.sendViewportThrottled = {};
    this.trackCapabilities = {};
    this.cachedViewport = {};

    this.tileService.on('add', this._onTileAdd);
    this.streamService.on('add', this._onStreamAdd);
    meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);
    meetingBroadcastService.on('ptz-constraints', this._onBroadcastPTZContstraints, false);
    meetingBroadcastService.on('video-viewport', this._onBroadcastViewport, false);
  }

  _bind() {
    this._onTileAdd = this._onTileAdd.bind(this);
    this._onTileRemove = this._onTileRemove.bind(this);
    this._onStreamAdd = this._onStreamAdd.bind(this);
    this._afterBroadcastInitialization = this._afterBroadcastInitialization.bind(this);
    this._onBroadcastPTZContstraints = this._onBroadcastPTZContstraints.bind(this);
    this._onTileViewport = this._onTileViewport.bind(this);
    this._onBroadcastViewport = this._onBroadcastViewport.bind(this);
  }

  isViewportTile(tile) {
    return !!tile.setViewport;
  }


  _onTileAdd(tile) {
    if(!this.isViewportTile(tile)) {
      return;
    }

    tile.on('viewport', this._onTileViewport);
    this.sendViewportThrottled[tile.id] = throttle(
      this._sendViewport.bind(this, tile),
      SEND_VIEWPORT_INTERVAL,
      true
    );

    // if a viewport event has been receieved for this tile before it was added,
    // synch the last offset/zoom value that was received
    if(this.cachedViewport[tile.id]) {
      this._syncViewport(
        tile,
        this.cachedViewport[tile.id].offset,
        this.cachedViewport[tile.id].zoomLevel
      );
    }
  }

  _onTileRemove(tile) {
    if(!this.isViewportTile(tile)) {
      return;
    }

    tile.off('viewport', this._onTileViewport);
    this.sendViewportThrottled[tile.id].cancel();
    delete this.sendViewportThrottled[tile.id];
  }



  /*******************************
   * Sync PTZ track capabilities *
   *******************************/

  _onStreamAdd(stream) {
    if(stream.isLocal) {
      this._onStreamAddLocal(stream);
    } else {
      this._onStreamAddRemote(stream);
    }
  }


  _onStreamAddLocal(stream) {
    if(stream.supportsPTZ && !this.meetingBroadcastService.initializing) {
      this.meetingBroadcastService.send(
        'ptz-constraints', false, [],
        stream.id,
        {
          pan: stream.trackCapabilities.pan,
          tilt: stream.trackCapabilities.tilt,
          zoom: stream.trackCapabilities.zoom,
        }
      );
    }
  }


  _onStreamAddRemote(stream) {
    let id = this._getTrackCapabilitiesId(stream.session, stream.id);
    let trackCapabilities = this.trackCapabilities[id];
    if(trackCapabilities) {
      stream.enablePTZ(trackCapabilities);
      delete this.trackCapabilities[id];
    }
  }


  _afterBroadcastInitialization() {
    let streams = this.streamService.getAllLocal();
    Object.values(streams).forEach(stream => this._onStreamAddLocal(stream));
  }


  _onBroadcastPTZContstraints(channel, session, datetime, streamId, trackCapabilities) {
    // We may receive the ptz-constraints event before the stream-add event, so we need to store
    // the track capabilities temporarily.
    let id = this._getTrackCapabilitiesId(session, streamId);
    this.trackCapabilities[id] = trackCapabilities;

    let stream = this.streamService.get(session, streamId);
    if(stream) {
      this._onStreamAdd(stream);
    }
  }


  _getTrackCapabilitiesId(session, streamId) {
    return format('%s:%s', session.id, streamId);
  }



  /*********************
   * Sync the viewport *
   *********************/

  _onTileViewport(tile) {
    this.sendViewportThrottled[tile.id]();
  }

  /**
   * local user manipulated the viewport
   *
   * write the desired viewport paramaters (offset, zoom) to their "synced" variant
   * and broadcast it to the other participants
   */
  _sendViewport(tile) {
    if(
      tile.offset.x === tile.offsetSynced.x
      && tile.offset.y === tile.offsetSynced.y
      && tile.zoomLevel === tile.zoomLevelSynced
    ) {
      return;
    }
    tile.offsetSynced = Object.assign({}, tile.offset);
    tile.zoomLevelSynced = tile.zoomLevel;

    this.meetingBroadcastService.send(
      'video-viewport', false, [],
      tile.id, [tile.offset.x, tile.offset.y], tile.zoomLevel
    );
  }

  /**
   * remote user manipulated the viewport
   *
   * read the desired viewport paramaters (offset, zoom) from the event and
   * use these to sync the local viewport
   */
  _onBroadcastViewport(channel, session, datetime, tileId, offset, zoomLevel) {
    let tile = this.tileService.tiles[tileId];
    if(!tile) {
      // We may receive the video-viewport event before the stream-add event, so we need to the
      // offset and zoomlevel temporarily
      this.cachedViewport[tileId] = {
        'offset': offset,
        'zoomLevel': zoomLevel,
      };
      return;
    } else {
      this._syncViewport(tile, offset, zoomLevel);
    }
  }

  _syncViewport(tile, offset, zoomLevel) {
    offset = {
      x: offset[0],
      y: offset[1],
    };
    zoomLevel = isNaN(zoomLevel) ? 1 : zoomLevel;

    tile.setSyncedViewport(offset, zoomLevel);
  }
}
