import { array, bind } from 'utils/util';


export default class FilePickerWorkerService {
  constructor() {
    bind(this);

    this.handlers = {};

    window.addEventListener('message', this._onWindowMessage);
  }


  on(channel, callback) {
    if(!(channel in this.handlers)) {
      this.handlers[channel] = [];
    }

    this.handlers[channel].push(callback);
  }

  off(channel, callback) {
    if(!(channel in this.handlers)) {
      return;
    }

    array.remove(this.handlers[channel], callback);
  }


  sendMessage(channel, message) {
    window.parent.postMessage({
      type: 'vecteraFilePicker',
      data: {
        channel,
        message,
      },
    }, 'https://' + window.REFERER_DOMAIN);
  }

  _onWindowMessage(event) {
    if(
      event.origin !== 'https://' + window.REFERER_DOMAIN
      || event.data.type !== 'vecteraFilePicker'
    ) {
      return;
    }

    $rootScope.$evalAsync(() => {
      let { channel, message } = event.data.data;
      let handlers = this.handlers[channel] || [];
      handlers.forEach(handler => handler(message));
    });
  }


  create(cloudFileInfos, addImageType, whiteboardId) {
    this.sendMessage('create', {
      cloudFileInfos: cloudFileInfos,
      addImageType: addImageType,
      whiteboardId: whiteboardId
    });
  }

  error(error) {
    this.sendMessage('error', {
      error: error,
    });
  }

  warning(warning) {
    this.sendMessage('warning', {
      warning: warning,
    });
  }


  show() {
    this.sendMessage('show');
  }
  hide() {
    this.sendMessage('hide');
  }
}
