import { default as User, SYNCED_PROPERTIES } from './User';


export default class AnonymousHostUser extends User {
  constructor(...args) {
    super(...args);

    this.info = {
      shortName: 'Meeting host',
      fullName: 'Meeting host',
      initials: 'MH',
    };
  }


  _getSyncedProperty(property) {
    if(property === 'profileImage') {
      return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjYjliOWI5Ii8+PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik0xMiAxMmMyLjIxIDAgNC0xLjc5IDQtNHMtMS43OS00LTQtNC00IDEuNzktNCA0IDEuNzkgNCA0IDR6bTAgMmMtMi42NyAwLTggMS4zNC04IDR2MmgxNnYtMmMwLTIuNjYtNS4zMy00LTgtNHoiLz48L3N2Zz4K';  // eslint-disable-line max-len
    } else if(property in this.info) {
      return this.info[property];
    } else {
      return SYNCED_PROPERTIES[property].default;
    }
  }
}
