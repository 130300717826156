<div>
  <div
    *ngFor="let location of locationOptions"
    class="mt-4"
  >
    <input
      id="{{ location }}-picker"
      type="checkbox"
      class="checkbox"
      [checked]="isLocationSelected(location)"
      (change)="!disabled && toggleLocation(location)"
      [attr.disabled]="disabled ? true : null"
    />
    <label
      for="{{location}}-picker"
      class="checkbox__label"
      [attr.data-testid]="'locationPickerOption--' + location"
    >
      {{ labelMap[location] }}
      <span *ngIf="location === 'online'" class="ml-2 badge badge--info" i18n>Video Meeting Room</span>
    </label>
  </div>
</div>
