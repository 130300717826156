import { Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


type OptionValue = string;
type OptionLabel = string;
export type Option = [OptionValue, OptionLabel];


/**
 * There is a lot of overlap between this component and the multi-select-input component. We
 * decided to keep them separate for now, because the interplay between HTML radio buttons and
 * Angular controller is already tricky, the differences in behaviour are subtle, and a refactor
 * will probably exacerbate both of these elements. Should a third similar component be required,
 * we may need to revisit this decision.
 */
@Component({
  selector: 'single-select-input',
  templateUrl: './single-select-input.component.html',
})
export class SingleSelectInputComponent extends ContactFormQuestionInputComponent<OptionValue> {
  otherOptionSelected = false;
  otherOptionValue: OptionValue = '';

  get options() {
    return this.question.extra.options as Option[];
  }
  get otherOption(): boolean {
    return this.question.extra.otherOption || false;
  }


  parsePrefill(string: string) {
    if(this.options.find(option => option[0] === string)) {
      return string;
    } else if(this.otherOption) {
      this.otherOptionSelected = true;
      this.otherOptionValue = string;
      return string;
    } else {
      return null;
    }
  }


  onOptionChange($event: Event, option: Option) {
    const isChecked = (<HTMLInputElement>$event.target).checked;
    if(isChecked) {
      this.otherOptionSelected = false;
      this.writeValue(option[0]);
    }
  }


  onOtherOptionChange($event: Event) {
    const isChecked = (<HTMLInputElement>$event.target).checked;
    if(isChecked) {
      this.otherOptionSelected = true;
      this.writeValue(this.otherOptionValue);
    }
  }

  setOtherOptionValue(value: OptionValue) {
    this.otherOptionValue = value;
    this.writeValue(value);
  }

  trackByOption(index, option: Option) {
    return option[1];
  }
}
