import { Component, OnInit } from '@angular/core';
import { AdminPulseService, AdminPulseRelation } from 'integrations/adminpulse/adminpulse.service';
import { MeetingService } from 'meeting/meeting-room/meeting.service';
import { RequestUserService } from 'utils/requestUser.service';
import { LocalStateService } from 'utils/state.service';
import { UrlService } from 'utils/url.service';
import { errors } from 'utils/util';

@Component({
  selector: 'adminpulse-widget',
  styleUrls: ['./adminpulse-widget.component.scss'],
  templateUrl: './adminpulse-widget.component.html',
})
export class AdminPulseWidgetComponent implements OnInit {
  public relation: AdminPulseRelation| null = null;
  public relationError = '';

  constructor(
    public adminPulseService: AdminPulseService,
    private meetingService: MeetingService,
    public stateService: LocalStateService,
    public requestUserService: RequestUserService,
    public urlService: UrlService,
  ) {

  }

  ngOnInit() {
    this.stateService.setInitializing();
    this.fetchRelationData();
  }

  async fetchRelationData() {
    if(
      this.meetingService.adminpulseRelation == null
      || this.meetingService.adminpulseRelation === ''
    ) {
      return;
    }

    try {
      this.relation = await this.adminPulseService.fetchRelationData(
        this.meetingService.adminpulseRelation
      );
    } catch(error) {
      this.handleFetchRelationError(error);
    } finally {
      this.stateService.setReady();
    }
  }

  handleFetchRelationError(error) {
    // AdminPulse does not return a 404 but instead returns a ValidationError (400)
    if(
      error.constructor === errors.ValidationError
      && error.message.includes('This relation was not found')
    ) {
      this.relationError = $localize `We couldn't find the relation linked to this meeting room.`;
    } else if(error.constructor === errors.UnauthorizedError) {
      this.adminPulseService.setExpired(true);
    } else {
      this.relationError = $localize `Something went wrong fetching your AdminPulse relation`;
    }
  }

  get relationDisplayInfo() {
    if(this.relation == null) {
      return '';
    }

    return this.adminPulseService.getRelationDisplayInfo(this.relation);
  }

  get isWarningWidget() {
    return (
      this.relationError
      || !this.adminPulseService.relationConnected
      || this.adminPulseService.isExpired
    );
  }
}
