import { file, logger } from 'utils/util';
import { AddImageType } from './whiteboard.service';


export default class SnapshotService {
  static get $inject() {
    return [
      'notificationService',
      'privateMessageService',
      'streamService',
      'whiteboardService',
    ];
  }

  constructor(
    notificationService,
    privateMessageService,
    streamService,
    whiteboardService
  ) {
    this._bind();

    this.notificationService = notificationService;
    this.privateMessageService = privateMessageService;
    this.streamService = streamService;
    this.whiteboardService = whiteboardService;

    privateMessageService.on('take-snapshot', this._onPrivateMessage);
  }

  _bind() {
    this._onPrivateMessage = this._onPrivateMessage.bind(this);
  }


  takeSnapshot(stream, zoomLevel, offset) {
    if(stream.session.isLocal) {
      this._takeSnapshot(stream, zoomLevel, offset);
    } else {
      this.privateMessageService.send(
        'take-snapshot', stream.session, stream.id, zoomLevel, offset);
    }
  }


  _onPrivateMessage(channel, session, streamId, zoomLevel, offset) {
    let stream = this.streamService.getLocal(streamId);
    if(stream) {
      this._takeSnapshot(stream, zoomLevel, offset);
    }
  }

  _takeSnapshot(stream, zoomLevel, offset) {

    stream.takeSnapshot(null, zoomLevel, offset)
      .then(blob => {
        if(!blob) {
          throw new Error('Failed to create snapshot');
        }

        let whiteboardName = gettextCatalog.getString('Snapshots');
        let filename = whiteboardName + '.jpg';
        let localFile = file.fromBlob(blob, filename);

        let snapshotWhiteboard = this.whiteboardService.getSnapshotWhiteboard();

        this.whiteboardService.addLocalFiles(
          [localFile], AddImageType.NEW_PAGE, snapshotWhiteboard
        );

        return snapshotWhiteboard;
      })
      .then(whiteboard => {
        if(!whiteboard) {
          return;
        }
      })
      .catch(error => {
        logger.warn(error);
        this.notificationService.error(
          gettextCatalog.getString(
            'Something went wrong while taking your snapshot. Please try again later.'
          )
        );
      });
  }
}
