<div class="adminpulse-widget__wrapper" [ngClass]="{ 'adminpulse-widget__wrapper--warning': isWarningWidget }" data-testid="adminPulseWidget">

  <div *ngIf="stateService.isInitializing" class="d-flex align-items-center">
    <svg-icon svgPath="integrations__adminpulse_logo" class="adminpulse-widget__logo"></svg-icon>
    <div class="d-flex align-items-center">
      <span i18n>Loading relation...</span>
      <loading class="ml-2"></loading>
    </div>
  </div>

  <div *ngIf="stateService.isReady" class="d-flex align-items-center">


    <div *ngIf="relation">
      <div class="d-flex align-items-center">
        <svg-icon svgPath="integrations__adminpulse_logo" class="adminpulse-widget__logo"></svg-icon>
        <b data-testid="adminPulseRelationInfo">{{ relationDisplayInfo }}</b>
      </div>
    </div>


    <div *ngIf="adminPulseService.relationConnected && !relation && relationError">
      <div *ngIf="relationError">
        <div class="d-flex align-items-center">
          <svg-icon svgPath="integrations__adminpulse_logo" class="adminpulse-widget__logo"></svg-icon>
          <span>{{ relationError }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="adminPulseService.isExpired">
      <span i18n>This meeting room is linked to an AdminPulse relation but your integration has been disconnected.</span>
      <div *ngIf="requestUserService.user.isAdmin">
        <a
          [attr.href]="urlService.urls.adminPulseConnectUrl"
          target="_blank"
          class="btn btn--primary mt-3"
          i18n
        >
          Reconnect
        </a>
      </div>
      <div *ngIf="!requestUserService.user.isAdmin">
        <div i18n>Please ask an admin to reconnect to AdminPulse.</div>
      </div>
    </div>
  </div>
</div>
