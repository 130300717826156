import templateModalSessionExpired from './sessionExpired.modal.html?raw';
import templateModalMeetingReset from './meetingReset.modal.html?raw';
import templateModalSessionTimeout from './sessionTimeout.modal.html?raw';

import { EventEmitter, runAt } from 'utils/util';


const MODAL_ID_SESSION_EXPIRED = 'sessionExpired';
const MODAL_ID_MEETING_RESET = 'meetingReset';
const MODAL_ID_SESSION_TIMEOUT = 'sessionTimeout';

const RESET_TIME =  2 * 60 * 1000;
const RESET_NOTIFICATION_TEMPLATE = `
<div ng-controller="SessionNotificationsCtrl as ctrl" translate>
  The host has left the meeting. This room will be reset in {{ ctrl.timeToReset() }} seconds.
</div>
`;

export default class SessionNotificationsService {
  static get $inject() {
    return [
      'meetingBroadcastService',
      'meetingService',
      'meetingSocketService',
      'modalService',
      'notificationService',
      'smartSummaryService',
      'unloadService',
      'userService',
    ];
  }

  constructor(
    meetingBroadcastService,
    meetingService,
    meetingSocketService,
    modalService,
    notificationService,
    smartSummaryService,
    unloadService,
    userService,
  ) {
    this._bind();
    EventEmitter.setup(this, ['show']);

    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.meetingSocketService = meetingSocketService;
    this.modalService = modalService;
    this.notificationService = notificationService;
    this.smartSummaryService = smartSummaryService;
    this.unloadService = unloadService;
    this.userService = userService;

    this.resetCountdownNotification = null;
    this.resetDate = null;

    modalService.register(
      MODAL_ID_SESSION_EXPIRED,
      gettext('Your session has expired'),
      'utils/icons/tl/24x24_warning_badged_filled.svg',
      templateModalSessionExpired,
      { priority: 21, dismissable: false }
    );

    modalService.register(
      MODAL_ID_MEETING_RESET,
      gettext('Meeting room reset'),
      'utils/icons/tl/24x24_warning_badged_filled.svg',
      templateModalMeetingReset,
      { priority: 21, dismissable: false }
    );

    modalService.register(
      MODAL_ID_SESSION_TIMEOUT,
      gettext('Your session has expired'),
      'utils/icons/tl/24x24_warning_badged_filled.svg',
      templateModalSessionTimeout,
      { priority: 100, dismissable: false }
    );


    this.limitEventsReachedNotification = null;

    meetingSocketService.on('open', this._onOpen);
    meetingSocketService.on('close', this._onClose);
    this.userService.on('sessionExit', this._onSessionExit);
    this.userService.on('sessionJoin', this._onSessionJoin);

    this.meetingBroadcastService.on('_softLimitNumEvents', this._onSoftLimitNumEvents, true);
    this.meetingBroadcastService.on('_hardLimitNumEvents', this._onHardLimitNumEvents, true);

    this.smartSummaryService.on('started', this._onSmartSummaryRecordingStarted);

    let sessionTimeout = 1000 * 60 * 60 * 12;  // 12 hours
    runAt(new Date(new Date().getTime() + sessionTimeout), this._sessionTimeout);
  }


  _bind() {
    this._onOpen = this._onOpen.bind(this);
    this._onClose = this._onClose.bind(this);
    this._sessionTimeout = this._sessionTimeout.bind(this);
    this._onSoftLimitNumEvents = this._onSoftLimitNumEvents.bind(this);
    this._onHardLimitNumEvents = this._onHardLimitNumEvents.bind(this);
    this._onSessionExit = this._onSessionExit.bind(this);
    this._onSessionJoin = this._onSessionJoin.bind(this);
    this._onSmartSummaryRecordingStarted = this._onSmartSummaryRecordingStarted.bind(this);
  }


  _onOpen() {
    this.modalService.hide(MODAL_ID_SESSION_EXPIRED);
    this.modalService.hide(MODAL_ID_MEETING_RESET);
  }


  _onClose(reason) {
    let CloseReason = this.meetingSocketService.CloseReason;

    // If our session is stale, we simply prompt to reload to get a new session id.
    // It is rather complex to fix this situation without reloading (but it could be a lot
    // faster of course).
    if(reason === CloseReason.INVALID_SESSION) {
      this.modalService.show(MODAL_ID_MEETING_RESET);

    } else if(reason === CloseReason.NOT_AUTHORIZED || reason === CloseReason.BAD_REQUEST) {
      this.modalService.show(MODAL_ID_SESSION_EXPIRED);

    } else if(reason === CloseReason.OLD_SESSION) {
      this.modalService.show(MODAL_ID_SESSION_TIMEOUT);
    }
  }

  endSimultaneousSessions() {
    this.meetingSocketService.open();
  }

  reloadPage() {
    this.unloadService.reloadPageWithoutConfirmation();
  }

  _sessionTimeout() {
    this.modalService.show(MODAL_ID_SESSION_TIMEOUT);
  }

  _onSessionExit(session) {
    if(session.accessLevel.isHost
      && !session.isLocal
      && new Date() - session.dateExited < RESET_TIME
      && this.meetingService.settings.autoReset
      && !Object.values(this.userService.sessions).find(
        sess => sess.id !== session.id && sess.user === session.user && sess.state === 'alive'
      )
      && !this.resetCountdownNotification
    ) {
      this._showResetNotification(session);
    }
  }

  _onSessionJoin(session) {
    if(session.accessLevel.isHost) {
      this._cancelResetNotification();
    }
  }

  _showResetNotification(session) {
    this.resetDate = new Date(session.dateExited.getTime() + RESET_TIME);
    this.resetCountdownNotification = this.notificationService.warning(
      RESET_NOTIFICATION_TEMPLATE,
      { delay: -1 }
    );
  }

  _cancelResetNotification() {
    if(this.resetCountdownNotification) {
      this.resetCountdownNotification.cancel();
      this.resetCountdownNotification = null;
      this.resetDate = null;
    }
  }

  _onSoftLimitNumEvents() {
    if(this.userService.iAmHost) {
      this.limitEventsReachedNotification = this.notificationService.warning(
        gettextCatalog.getString(
          // eslint-disable-next-line max-len
          'There has been a lot of activity in this meeting room, which may cause performance to degrade. We recommend resetting the meeting room, or planning your meetings in a different meeting room.'
        ),
        { delay: -1 }
      );
    }
  }

  _onHardLimitNumEvents() {
    if(this.limitEventsReachedNotification) {
      this.limitEventsReachedNotification.cancel();
    }

    /* eslint-disable max-len */
    if(this.userService.iAmHost) {
      this.limitEventsReachedNotification = this.notificationService.error(
        `
          <p translate>
            There has been a lot of activity in this meeting room. We have had to lock the whiteboards to prevent overloading our servers. We recommend resetting the meeting room, or planning your meetings in a different meeting room.
          </p>
          <p translate>
            If you don't want to lose the content of the whiteboards, make sure to download them first.
          </p>
        `,
        { delay: -1 }
      );

    } else {
      this.limitEventsReachedNotification = this.notificationService.error(
        `
          <p translate>
            There has been a lot of activity in this meeting room. We have had to lock the whiteboards to prevent overloading our servers. Please ask the meeting host to fix this situation.
          </p>
        `,
        { delay: -1 }
      );
    }
    /* eslint-enable max-len */
  }

  _onSmartSummaryRecordingStarted() {
    this.smartSummaryService.openNoticeModal();
  }
}
