import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { MeetingRoomsMenuComponent } from './meeting-rooms-menu.component';
import { AddRoomModalModule } from './add-room-modal/add-room-modal.module';


@NgModule({
  imports: [
    A11yModule,
    BrowserModule,
    FormsModule,
    UtilsModule,
    AddRoomModalModule,
  ],
  declarations: [
    MeetingRoomsMenuComponent,
  ],
  exports: [
    MeetingRoomsMenuComponent,
  ],
})
export class MeetingRoomsMenuModule {}
