<div
  class="sidebar-profile"
>
  <div class="mr-2" style="width: 36px; height: 36px;">
    <profile-image [size]="36"></profile-image>
  </div>

  <div class="w-0 flex-1 line-clamp-2">
    {{ user.fullName }}
  </div>

  <div
    class="btn btn--round mr-2"
    [cdkMenuTriggerFor]="menu"
    data-testid="profileMenu"
  >
    <svg-icon class="icon" [svgName]="'ellipsis'"></svg-icon>
  </div>


  <ng-template #menu>
    <div class="dropdown" cdkMenu>
      <a
        href="{{ profileUrl }} "
        class="dropdown__list-item"
        cdkMenuItem
        data-testid="editProfile"
        i18n
      >
        Edit profile
      </a>
      <a
        href="{{ logoutUrl }}"
        class="dropdown__list-item"
        cdkMenuItem
        data-testid="logOut"
        i18n
      >
        Log out
      </a>
    </div>
  </ng-template>
</div>
