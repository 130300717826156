import { format, object, logger, errors } from 'utils/util';
import KnockerTile from './KnockerTile';
import { StreamType } from  'meeting/meeting-room/stream';
import Session from '../../main/users/Session';

import templateModal from './knocker.modal.html?raw';

export const KNOCKER_MODAL_ID = 'knocker';

export default class KnockerService {
  static get $inject() {
    return [
      'browserService',
      'meetingReliableSocketService',
      'meetingBroadcastService',
      'meetingService',
      'userService',
      'chatBoxService',
      'mediaDeviceService',
      'streamService',
      'tileService',
      'tileFactory',
      'notifyService',
      'soundFactory',
      'modalService',
      'knockerChatService',
      'siteService'
    ];
  }

  constructor(
    browserService,
    meetingReliableSocketService,
    meetingBroadcastService,
    meetingService,
    userService,
    chatBoxService,
    mediaDeviceService,
    streamService,
    tileService,
    tileFactory,
    notifyService,
    soundFactory,
    modalService,
    knockerChatService,
    siteService
  ) {
    this._bind();

    modalService.register(
      KNOCKER_MODAL_ID,
      '',
      '',
      templateModal,
      {
        rawTemplate: true,
      }
    );

    this.modalService = modalService;
    this.browserService = browserService;
    this.meetingReliableSocketService = meetingReliableSocketService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.meetingService = meetingService;
    this.userService = userService;
    this.chatBoxService = chatBoxService;
    this.mediaDeviceService = mediaDeviceService;
    this.streamService = streamService;
    this.tileService = tileService;
    this.tileFactory = tileFactory;
    this.notifyService = notifyService;
    this.soundFactory = soundFactory;
    this.modalService = modalService;
    this.knockerChatService = knockerChatService;
    this.siteService = siteService;

    this.tiles = {};

    this.notificationSound = this.soundFactory.create(
      window.URLS.knockerSound,
      {
        loop: true,
        prefetch: true,
      }
    );
    this.notificationDisabled = false;

    this._setupListeners();
  }

  _bind() {
    this._join = this._join.bind(this);
    this._exit = this._exit.bind(this);
    this._onStreamAdd = this._onStreamAdd.bind(this);
    this._onStreamRemove = this._onStreamRemove.bind(this);
    this._afterBroadcastInitialization = this._afterBroadcastInitialization.bind(this);
    this._onBroadcastChatMessage = this._onBroadcastChatMessage.bind(this);
    this._onChatBoxMessage = this._onChatBoxMessage.bind(this);
    this._onMySessionState = this._onMySessionState.bind(this);
    this._updateNotifications = this._updateNotifications.bind(this);
  }

  _setupListeners() {
    this.userService.on('userKnockJoin', this._join);
    this.userService.on('userKnockWakeup', this._join);
    this.userService.on('userKnockExit', this._exit);
    this.userService.on('userJoin', this._exit);
    this.userService.on('userWakeup', this._exit);

    this.streamService.on('add', this._onStreamAdd);
    this.streamService.on('remove', this._onStreamRemove);

    this.meetingBroadcastService.on('knock-chat-message', this._onBroadcastChatMessage, true);
    this.meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);

    this.userService.mySession.on('state', this._onMySessionState);
  }


  get unsnoozedKnockerTiles() {
    return Object.values(this.tiles)
      .filter(
        tile => tile.state === KnockerTile.State.KNOCKING
        && !tile.snoozed
      );
  }

  _getTileId(user) {
    return format('knocker-%s', user.id);
  }

  _getChatBoxId(user) {
    return this._getTileId(user);
  }

  _getOrCreateTile(user) {
    if(!this.tiles.hasOwnProperty(user.id)) {
      let chatBox = this.chatBoxService.get(this._getChatBoxId(user));
      chatBox.on('message', this._onChatBoxMessage.bind(this, user));
      let tileId = this._getTileId(user);
      this.tiles[user.id] = this.tileFactory.create(KnockerTile, tileId, user, chatBox);
    }
    return this.tiles[user.id];
  }


  _join(user, prevUser) {
    if(user.isMe) {
      return;
    }

    let tile = this._getOrCreateTile(user);

    // If prevKnocker exists: transfer properties from prevKnocker to knocker
    if(prevUser && prevUser.id in this.tiles) {
      let prevTile = this.tiles[prevUser.id];
      delete this.tiles[prevUser.id];
      this.tileService.remove(prevTile);

      tile.chatBox.messages.concat(prevTile.chatBox.messages);
      tile.chatBox.messages.sort((m1, m2) => m1.datetime - m2.datetime);

      Object.values(this.streamService.getFromUser(user)).forEach(this._onStreamAdd);
    }

    tile.setState(KnockerTile.State.KNOCKING);

    this.notificationDisabled = false;
    this._updateNotifications();

    this.tileService.add(tile);
    this._updateModal();
  }


  _exit(user) {
    let tile = this.tiles[user.id];
    if(!tile) {
      return;
    }

    if(tile.state !== KnockerTile.State.EXITED) {
      this.knockerChatService.toggle(tile.chatBox, null, false);
      tile.setState(KnockerTile.State.EXITED);
      if(tile.videoActive) {
        tile.streams.video.stop();
        tile.removeVideo();
      }
    }

    this._updateNotifications();
    this.tileService.remove(tile);
    this._updateModal();
  }


  _updateModal() {
    let shouldShow = (
      this.unsnoozedKnockerTiles.length > 0
      && !this.browserService.isDesktop
    );

    this.modalService.toggle(KNOCKER_MODAL_ID, shouldShow);
  }

  selectModalTile(tile) {
    tile.snoozed = false;
    this._updateModal();
  }

  hideModal() {
    this.modalService.hide(KNOCKER_MODAL_ID);
  }


  /****************
   * Show streams *
   ****************/

  _onStreamAdd(stream) {
    let tile = this.tiles[stream.user.id];
    if(
      tile
      && tile.user.stateWithoutSleep === Session.State.KNOCKING
      && stream.type === StreamType.VIDEO
    ) {
      tile.setVideo(stream);

      if(!this.meetingBroadcastService.initializing) {
        stream.start();
      }
    }
  }

  _onStreamRemove(stream) {
    let tile = this.tiles[stream.user.id];
    if(
      tile
      && tile.streams.video === stream
    ) {
      tile.removeVideo();
      stream.stop();
    }
  }


  _afterBroadcastInitialization() {
    Object.values(this.tiles).forEach(tile => {
      if(tile.streams.video) {
        tile.streams.video.start();
      }
    });
    this._updateNotifications();
  }



  /**********************************
   * Send and receive chat messages *
   **********************************/

  _onBroadcastChatMessage(channel, session, datetime, id, userId, content) {
    let user;
    try {
      user = this.userService.get(userId);
    } catch(error) {
      if(error.constructor !== errors.DoesNotExist) {
        logger.warn(user);
      }
      return;
    }
    if(user.isMe) {
      return;
    }

    let tile = this._getOrCreateTile(user);
    let chatBox = tile.chatBox;

    if(session.isLocal) {
      chatBox.removeMessage(id);
    } else {
      this.notifyService.notify();
    }

    chatBox.addMessage(id, session, content, datetime, true);
  }


  _onChatBoxMessage(user, message) {
    if(message.synced) {
      return;
    }

    let receivers = [user.id];
    this.meetingBroadcastService.send(
      'knock-chat-message', true, receivers, message.id, user.id, message.rawContent);
  }


  get allowGroupMeetings() {
    return this.meetingService.settings.allowGroupMeetings;
  }
  get isMeetingFull() {
    let numJoined = this.userService.joined.size;
    // This condition is replicated in models.py:Session, keep them in sync!
    return (
      !this.allowGroupMeetings && numJoined >= 2
      || numJoined >= 10
    );
  }
  get admitIsAllowed() {
    return !this.isMeetingFull && !this.meetingService.isDeactivated;
  }


  admit(user, admit) {
    if(admit && !this.admitIsAllowed) {
      return;
    }

    let tile = this.tiles[user.id];
    if(!tile) {
      return;
    }
    tile.setState(admit ? KnockerTile.State.JOINING : KnockerTile.State.EXITING);
    this.meetingReliableSocketService.send('_admit', [user.id, admit]);

    this._updateNotifications();
    this.tileService.remove(tile);
    this._updateModal();
  }

  snoozeTile(tile) {
    tile.snoozed = true;
    this._updateNotifications();
    this._updateModal();
  }


  /******************
   * Knocker sound *
   *****************/

  _onMySessionState() {
    if(!this.userService.mySession.isJoined()) {
      this.disableNotification();
    }
  }

  disableNotification() {
    this.notificationDisabled = true;
    this._updateNotifications();
  }


  _updateNotifications() {
    if(this.meetingBroadcastService.initializing) {
      return;
    }

    let numKnockers = object.length(this.unsnoozedKnockerTiles);

    if(numKnockers > 0 && !this.notificationDisabled) {
      this.notificationSound.play();
    } else {
      this.notificationSound.stop();
    }
  }

  /********
  * Other *
  ********/

  getKnockerTileText(tile) {
    let name = tile.user.fullName;

    if(this.admitIsAllowed) {
      return this._getAdmitAllowedText(name);

    } else if(this.meetingService.isDeactivated) {
      return this._getMeetingRoomDeactivatedText(name);

    } else {
      return this._getMeetingRoomFullText(name);
    }
  }

  _getAdmitAllowedText(name) {
    return gettextCatalog.getString(
      '<b>{{ fullName }}</b> wants to join the meeting room.',
      { fullName: name }
    );
  }

  _getMeetingRoomDeactivatedText(name) {
    return gettextCatalog.getString(
      (
        '<b>{{ fullName }}</b> wants to join the meeting room, but this meeting room is '
        + 'deactivated. <a {{ url }}>Upgrade to {{ proPlanName }}</a> to activate this '
        + 'meeting room.'
      ),
      {
        fullName: name,
        url: `href="${this.siteService.site.subscriptionLink}" target="_blank"`,
        proPlanName: this.siteService.site.proPlanName,
      }
    );
  }

  _getMeetingRoomFullText(name) {
    let postfix;
    if(!this.allowGroupMeetings) {
      postfix = gettextCatalog.getString(
        '<a {{ url }}>Upgrade to {{ proPlanName }}</a> to have group meetings with 3 or more people.', // eslint-disable-line max-len
        {
          url: `href="${this.siteService.site.subscriptionLink}" target="_blank"`,
          proPlanName: this.siteService.site.proPlanName,
        }
      );
    } else {
      postfix = gettextCatalog.getString(
        'We currently support meetings with up to 10 people.'
      );
    }
    return format(
      '%s %s',
      gettextCatalog.getString(
        '<b>{{ fullName }}</b> wants to join the meeting room, but the meeting room is full.',
        { fullName: name }
      ),
      postfix
    );
  }
}
