import { Component, Input, OnInit } from '@angular/core';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { RequestUserService } from 'utils/requestUser.service';
import { LocalStateService } from 'utils/state.service';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { UrlService } from 'utils/url.service';
import { BookingLinksMenuService } from './booking-links-menu.service';
import { getAppoinmentTypeLocationBadges } from 'scheduling/utils/location-badges';
import { DashboardScope } from 'dashboard/data-page/data-page.component';


@Component({
  selector: 'booking-links-menu',
  templateUrl: './booking-links-menu.component.html',
})
export class BookingLinksMenuComponent implements OnInit {
  public DashboardScope = DashboardScope;

  @Input() public scope: Scope = DashboardScope.PERSONAL;
  public appointmentTypes: AppointmentType[] = [];

  constructor(
    public urlService: UrlService,
    public stateService: LocalStateService,
    private bookingLinksMenuService: BookingLinksMenuService,
    private requestUserService: RequestUserService,
  ) {}


  ngOnInit() {
    this.fetch();
  }


  async fetch() {
    this.stateService.setState(this.stateService.State.LOADING);
    this.appointmentTypes = await this.bookingLinksMenuService.fetch(this.scope);
    this.stateService.setState(this.stateService.State.READY);
  }


  get bookingPageUrl() {
    return this.scope === DashboardScope.PERSONAL ?
      this.urlService.urls.personalBookingPage :
      this.urlService.urls.teamBookingPage;
  }

  getLocationBadges(appointmentType: AppointmentType) {
    return getAppoinmentTypeLocationBadges(appointmentType);
  }


  get canCreate(): boolean {
    return (
      !this.hasFreeSubscription
      || this.existingActiveAppointmentTypes.length < 1
    );
  }

  get hasFreeSubscription() {
    return this.requestUserService.user.subscription.status === 'free';
  }

  get existingActiveAppointmentTypes() {
    return this.appointmentTypes.filter(
      appointmentType => {
        const hasActiveConfig = appointmentType.appointmentTypeConfigs.some(
          config => config.userId === this.requestUserService.user.id && config.active
        );
        return (
          appointmentType.active
          && hasActiveConfig
        );
      }
    );
  }
}
