import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon';
import { HelpTooltipComponent } from '../help-tooltip/help-tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    SvgIconModule,
    MatTooltipModule,
  ],
  declarations: [
    HelpTooltipComponent,
  ],
  exports: [
    HelpTooltipComponent,
  ],
})
export class HelpTooltipModule {}
