import Model from 'utils/models';
import * as fields from 'utils/fields';


export class ContactFormFile extends Model {
  static get fields() {
    return {
      name: new fields.StringField(),
      file: new fields.FileField(),
    };
  }
}
