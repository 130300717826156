import { Component } from '@angular/core';

@Component({
  selector: 'styleguide',
  templateUrl: './styleguide.component.html'
})
export class StyleguideComponent {
  constructor() {
  }
}
