import { array, errors, format, logger, object } from 'utils/util';
import Shape from './Shape';
import fabric from './fabric';
import { normalizePath, serializePath, deserializePath, parseText, buildText } from './parser';
import VecteraFile from '../files/VecteraFile';


const SET_COORDS_KEYS = Object.freeze({
  left: true,
  top: true,
  width: true,
  height: true,
  angle: true,
});


class FabricShape {
  static get objectCaching() {
    return false;
  }

  constructor(origShape) {
    this.origShape = origShape;
    this.id = origShape.id;
    this.type = origShape.type;
    this.tool = this.constructor.tool;
    this.destroyed = false;

    this.object = this._initializeObject();
    this.object.shape = this;
    this.object.objectCaching = this.constructor.objectCaching;

    this._setRegularProperties();
    this.update(origShape.properties);
  }

  destroy() {
    this.destroyed = true;
  }


  _setRegularProperties() {
    this.regularProperties = Object.assign({}, Shape.SYNC_PROPERTIES[this.type]);
    if(this.regularProperties.strokeColor) {
      this.regularProperties.strokeColor = 'stroke';
    }
  }


  _getObjectKey(key) {
    let objectKey = this.regularProperties[key];
    return objectKey === true ? key : objectKey;
  }


  getProperty(key) {
    let value = this.object.get(key);

    if(this.object.group && (key === 'left' || key === 'top')) {
      let centerPoint = this.object.group.getCenterPoint();
      value += centerPoint[key === 'left' ? 'x' : 'y'];
    }

    return value;
  }


  _setObjectProperties(properties) {
    this.object.set(properties);
  }


  _setCoords() {
    if(this.shouldSetCoords) {
      this.object.setCoords();

      let group = this.object.group;
      if(group) {
        group.removeWithUpdate(this.object);
        group.addWithUpdate(this.object);
      }

      this.shouldSetCoords = false;
    }
  }


  getProperties() {
    let properties = {};
    for(let key in this.regularProperties) {
      let objectKey = this._getObjectKey(key);
      properties[key] = this.getProperty(objectKey);
    }

    this._modifyGetProperties(properties);
    return properties;
  }

  _modifyGetProperties() {
    // Child classes can override this
  }


  update(properties, temporary) {
    let objectProperties = this._getObjectProperties(properties);
    this._setObjectProperties(objectProperties);
    if(!temporary) {
      this._setCoords();
    }
  }


  _getObjectProperties(properties) {
    let objectProperties = {};
    object.forEach(properties, (key, value) => {
      let objectKey = this._getObjectKey(key);
      if(!objectKey) {
        return;
      }

      let objectValue = value;
      if(this.object.group && (key === 'left' || key === 'top')) {
        let centerPoint = this.object.group.getCenterPoint();
        objectValue -= centerPoint[key === 'left' ? 'x' : 'y'];
      }

      objectProperties[objectKey] = objectValue;
      if(objectKey in SET_COORDS_KEYS) {
        this.shouldSetCoords = true;
      }
    });

    return objectProperties;
  }


  onScale() {
    // Do nothing by default
  }
}




export class Rectangle extends FabricShape {
  static get tool() {
    return 'geometric';
  }

  _initializeObject() {
    return new fabric.Rect({
      fill: null,
    });
  }

  onScale() {
    this.object.set({
      width: this.object.width * this.object.scaleX,
      height: this.object.height * this.object.scaleY,

      scaleX: 1,
      scaleY: 1,
    });
  }
}




class Ellipse extends FabricShape {
  static get tool() {
    return 'geometric';
  }

  _initializeObject() {
    return new fabric.Ellipse({
      fill: null,
    });
  }

  onScale() {
    this.object.set({
      rx: this.object.rx * this.object.scaleX,
      ry: this.object.ry * this.object.scaleY,

      scaleX: 1,
      scaleY: 1,
    });
  }

  _modifyGetProperties(properties) {
    properties.width = properties.rx * 2;
    properties.height = properties.ry * 2;
  }
}




class Triangle extends FabricShape {
  static get tool() {
    return 'geometric';
  }

  _initializeObject() {
    return new fabric.Triangle({
      fill: null,
    });
  }

  onScale() {
    this.object.set({
      width: this.object.width * this.object.scaleX,
      height: this.object.height * this.object.scaleY,

      scaleX: 1,
      scaleY: 1,
    });
  }
}




class Arrow extends FabricShape {
  static get tool() {
    return 'geometric';
  }
  static get drawTriangle() {
    return true;
  }

  constructor(...args) {
    super(...args);
    this.strokeColor = null;
  }


  _setRegularProperties() {
    super._setRegularProperties();
    delete this.regularProperties.strokeColor;
  }


  _initializeObject() {
    this.line = new fabric.Polygon([
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 1, y: 1 },
      { x: 0, y: 1 },
    ], {
      objectCaching: false,
    });

    let object = new fabric.Group([this.line]);

    if(this.constructor.drawTriangle) {
      this.triangle = new fabric.Polygon([
        { x: 0, y: 0 },
        { x: -1, y: -1 },
        { x: -1, y: 1 },
      ], {
        objectCaching: false,
      });

      object.add(this.triangle);
    }

    return object;
  }


  _modifyGetProperties(properties) {
    properties.strokeColor = this.line.get('fill');
  }


  _getObjectProperties(properties) {
    let objectProperties = super._getObjectProperties(properties);
    if('strokeColor' in properties) {
      objectProperties.fill = properties.strokeColor;
    }
    if('strokeWidth' in properties) {
      this.shouldSetCoords = true;
    }
    return objectProperties;
  }


  _setObjectProperties(properties) {
    super._setObjectProperties(properties);

    let lineProperties = {};
    let triangleProperties = {};

    if('fill' in properties) {
      lineProperties.fill = properties.fill;
      triangleProperties.fill = properties.fill;
    }
    if('width' in properties || 'strokeWidth' in properties) {
      let width = this.getProperty('width');
      let strokeWidth = this.getProperty('strokeWidth');
      let triangleScale = 3 * Math.pow(strokeWidth, 0.8);

      lineProperties.transformMatrix = [
        width + strokeWidth, 0,
        0, strokeWidth,
        -strokeWidth / 2, -strokeWidth / 2 + 0.5
      ];
      triangleProperties.transformMatrix = [
        triangleScale, 0,
        0, triangleScale,
        width / 2, -strokeWidth / 2
      ];
    }

    this.line.set(lineProperties);
    if(this.triangle) {
      this.triangle.set(triangleProperties);
    }
  }


  onScale() {
    let width = this.object.width * this.object.scaleX;
    let height = this.object.height * this.object.scaleY;

    this._setObjectProperties({
      width: width,
      height: height,

      scaleX: 1,
      scaleY: 1,
    });
  }
}




class Line extends Arrow {
  static get drawTriangle() {
    return false;
  }
}




class Path extends FabricShape {
  static get objectCaching() {
    return false;
  }


  _setRegularProperties() {
    super._setRegularProperties();
    delete this.regularProperties.path;
  }


  _initializeObject() {
    return new fabric.Path([], {
      fill: null,
      strokeLineCap: 'round',
      strokeLineJoin: 'round',

      originX: 'left',
      originY: 'top',
    });
  }


  _getObjectProperties(properties) {
    let objectProperties = super._getObjectProperties(properties);
    if('path' in properties) {
      let path = properties.path;
      if(typeof path === 'string') {
        path = deserializePath(properties.path);
      }
      objectProperties.path = path;
    }
    return objectProperties;
  }


  _setObjectProperties(properties) {
    super._setObjectProperties(properties);
    if('path' in properties) {
      this.object._setPositionDimensions({
        left: this.object.left,
        top: this.object.top,
      });
      this.object.set('pathOffset', {
        x: (this.object.width + this.object.strokeWidth) / 2,
        y: (this.object.height + this.object.strokeWidth) / 2,
      });
    }
  }


  _modifyGetProperties(properties) {
    properties.width = this.object.get('width');
    properties.height = this.object.get('height');
    properties.path = serializePath(this.object.get('path'));
  }


  onScale() {
    // Scale each individual point in the path
    let oldPath = this.object.path;
    let path = new Array(oldPath.length);

    let scale = [this.object.scaleX, this.object.scaleY];

    for(let i = 0; i < oldPath.length; i++) {
      let oldCommand = oldPath[i];
      let command = new Array(oldCommand.length);
      path[i] = command;

      let commandType = oldCommand[0];
      command[0] = commandType;

      if(commandType === 'Q' || commandType === 'M' || commandType === 'L') {
        for(let j = 1; j < oldCommand.length; j++) {
          command[j] = oldCommand[j] * scale[(j - 1) % 2];
        }
      } else {
        throw new errors.InvalidArgumentError(format(
          'Unknown path command "%s" in path %s', commandType, oldPath));
      }
    }

    path = normalizePath(path, [0, 0]);

    let width = this.object.width * this.object.scaleX;
    let height = this.object.height * this.object.scaleY;

    this._setObjectProperties({
      path: path,

      width: width,
      height: height,

      scaleX: 1,
      scaleY: 1,
    });
  }
}



class Pencil extends Path {
  static get tool() {
    return 'pencil';
  }
}


class Marker extends Path {
  static get tool() {
    return 'marker';
  }
}




class Text extends FabricShape {
  static get tool() {
    return 'text';
  }
  static get objectCaching() {
    return true;
  }


  _initializeObject() {
    let properties = Object.assign({
      fontFamily: 'Open Sans',
    }, fabric._defaultTextStyle);

    return new fabric.Textbox('', properties);
  }


  _setRegularProperties() {
    super._setRegularProperties();
    delete this.regularProperties.fTextD;
    this.regularProperties.width = true;
  }


  _getObjectProperties(properties) {
    let objectProperties = super._getObjectProperties(properties);
    if(properties.hasOwnProperty('fText')) {
      let [text, styles] = parseText(properties.fText);
      objectProperties.text = text;
      objectProperties.styles = styles;
    }
    return objectProperties;
  }


  _modifyGetProperties(properties) {
    properties.text = this.object.text;
    properties.fText = buildText(this.object.text, fabric._defaultTextStyle, this.object.styles);
    properties.width = this.object.width;
    properties.height = this.object.height;
  }


  setSelectionStyle(toolsKey, toolsValue) {
    let [fabricKey, fabricValue] = fabric.convertOptionFromTools(toolsKey, toolsValue);
    let style = {
      [fabricKey]: fabricValue,
    };

    let start, end;
    if(this.object.isEditing) {
      start = this.object.selectionStart;
      end = this.object.selectionEnd;
    } else {
      start = 0;
      end = this.object.text.length;
    }

    if(array.has(fabric._bulletFormatKeys, fabricKey)) {
      let position2d = this.object.get2DCursorLocation(start);
      if(position2d.charIndex === 3) {
        let lineStart = this.object._textLines[position2d.lineIndex].substring(0, 3);
        if(lineStart in fabric._reverseBulletTypes) {
          start -= 3;
        }
      }
    }

    for(let i = start; i < end; i++) {
      this.object._extendStyles(i, style);
    }
    this.object._forceClearCache = true;
  }
}




class Formula extends FabricShape {
  static get tool() {
    return 'formula';
  }
  static get objectCaching() {
    return true;
  }


  _initializeObject() {
    return new fabric.Textbox('', {
      cursorWidth: 0,
    });
  }


  _setRegularProperties() {
    super._setRegularProperties();
    delete this.regularProperties.latex;
    delete this.regularProperties.fontSize;
  }


  _modifyGetProperties(properties) {
    properties.height = this.height;
    properties.width = this.width;
    properties.fontSize = this.fontSize;
    properties.latex = this.latex;
  }


  _getObjectProperties(properties) {
    let objectProperties = super._getObjectProperties(properties);
    if('fontSize' in properties) {
      this.fontSize = properties.fontSize;
    }
    if('latex' in properties && properties.latex !== this.latex) {
      this.latex = properties.latex;
      if(this.object.canvas) {
        this.object.canvas.trigger('formula:changed', { target: this.object });
      }
    }

    if('height' in properties && properties.height !== this.height) {
      this.height = properties.height;
      objectProperties.fontSize = this.height * 0.88;  // Trial and error
      this.shouldSetCoords = true;
    }
    if('width' in properties && properties.width !== this.width) {
      this.width = properties.width;
      objectProperties.requestWidth = this.width - 0.08 * this.fontSize;  // Trial and error
      this.shouldSetCoords = true;
    }
    return objectProperties;
  }
}



class FabricImage extends FabricShape {
  static get tool() {
    return 'image';
  }
  static get objectCaching() {
    return true;
  }

  constructor(fileService, ...args) {
    super(...args);
    this._bind();

    this.fileService = fileService;
    this.downloaded = false;
    this.initialized = true;

    this._download();
  }

  _bind() {
    this._downloadUrl = this._downloadUrl.bind(this);
    this._onImageLoaded = this._onImageLoaded.bind(this);
  }


  _initializeObject() {
    return new fabric.Image();
  }


  _shouldDownload() {
    return (
      (this.url || this.fileId)
      && !this.downloaded
      && !this.destroyed
      && this.initialized
    );
  }


  _download() {
    if(!this._shouldDownload()) {
      return;
    }

    if(this.url) {
      this._downloadUrl(this.url);
    } else {
      let file = this.fileService.get(this.fileId);
      file
        .getLocalUrl(VecteraFile.ConvertedToPdf.FALSE, false)
        .then(this._downloadUrl.bind(this));
    }
  }


  _downloadUrl(url) {
    if(!this._shouldDownload) {
      return;
    }

    fabric.util.loadImage(url, this._onImageLoaded, null, 'anonymous');
  }


  _onImageLoaded(elemImage) {
    if(elemImage) {
      this.object.setElement(elemImage);
      this.object.setCrossOrigin('anonymous');
      this.object.setCoords();
      this.object.dirty = true;

      if(this.object.canvas) {
        this.object.canvas.renderAll();
      }
    }

    this.object.trigger('image:added');
  }


  _getObjectProperties(properties) {
    if('url' in properties && properties.url !== this.url) {
      this.url = properties.url;
      this.fileId = null;
      this.downloaded = false;
      this._download();

    } else if('fileId' in properties && properties.fileId !== this.fileId) {
      this.fileId = properties.fileId;
      this.url = null;
      this.downloaded = false;
      this._download();
    }
    return super._getObjectProperties(properties);
  }
}




const CONSTRUCTORS = Object.freeze({
  rectangle: Rectangle,
  ellipse: Ellipse,
  triangle: Triangle,
  line: Line,
  arrow: Arrow,
  path: Path,
  pencil: Pencil,
  marker: Marker,
  text: Text,
  formula: Formula,
  image: FabricImage,
});



export default class FabricShapeService {
  static get $inject() {
    return [
      'fileService',
    ];
  }

  constructor(
    fileService
  ) {
    this.fileService = fileService;
  }


  create(origShape) {
    let args = [origShape];
    if(origShape.type === 'image') {
      args.splice(0, 0, this.fileService);
    }
    let Constructor = CONSTRUCTORS[origShape.type];
    if(Constructor) {
      try {
        return new Constructor(...args);
      } catch(e) {
        logger.warn(e);
      }
    }
  }
}
