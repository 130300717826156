import templateGreetSetName from './setName.greet.html?raw';
import templateGreetAudioVideo from './chooseAudioVideo.greet.html?raw';
import templateGreetAudioVideoReview from './audioVideoReview.greet.html?raw';
import templateGreetKnock from './knock.greet.html?raw';
import templateGreetAccessDenied from './accessDenied.greet.html?raw';
import templateGreetOwnerInactive from './ownerInactive.greet.html?raw';
import templateGreetMeetingEnded from './meetingEnded.greet.html?raw';
import templateGreetTemplatePhone from './meetingTemplatePhone.greet.html?raw';
import templateGreetMeetingDeactivated from './deactivated.greet.html?raw';

import { EventEmitter, bind, logger } from 'utils/util';
import { StreamType } from  'meeting/meeting-room/stream';
import Session from 'meeting/angularjs/main/users/Session';
import {
  MODAL_ID_CALL_ENDED,
  MODAL_ID_CALL_ENDED_DISMISSABLE
} from '../meetingEnded/meetingEnded.service';
import { MODAL_ID as MODAL_ID_ERROR } from 'meeting/angularjs/main/log.config';
import { MODAL_ID_DISPLAY_NAME } from 'meeting/angularjs/ui/app/header/header.component';

export const Template = Object.freeze({
  INIT: '',
  SET_NAME: templateGreetSetName,
  CHOOSE_AUDIO_VIDEO: templateGreetAudioVideo,
  AUDIO_VIDEO_REVIEW: templateGreetAudioVideoReview,
  KNOCK: templateGreetKnock,
  ACCESS_DENIED: templateGreetAccessDenied,
  OWNER_INACTIVE: templateGreetOwnerInactive,
  MEETING_ENDED: templateGreetMeetingEnded,
  MEETING_TEMPLATE_PHONE: templateGreetTemplatePhone,
  MEETING_DEACTIVATED: templateGreetMeetingDeactivated,
});

const AUDIO_TESTING_TIMEOUT = 2000;
const VIDEO_TESTING_TIMEOUT = 2000;
const VIDEO_SNAPSHOT_INTERVAL = 300;


/**
 * The greet.html template offers a basic interface that is shown before entering the meeting room
 * or after exiting the meeting room. It is extended by slotting various __.greet.html templates
 * in the greet "tile".
 *
 * The GreetService handles the state machine that determines when to show which greet template,
 * it also builds the relevant html template url for these greet templates, so that the greet
 * controllers can insert these in the "tile"
 */
export default class GreetService {
  static get $inject() {
    return [
      '$location',
      'meetingBroadcastService',
      'browserService',
      'chatBoxService',
      'dropdownService',
      'mediaDeviceService',
      'meetingEndedService',
      'meetingService',
      'modalService',
      'meetingSocketService',
      'streamService',
      'userService',
      'userStreamService',
      'hintService',
    ];
  }

  constructor(
    $location,
    meetingBroadcastService,
    browserService,
    chatBoxService,
    dropdownService,
    mediaDeviceService,
    meetingEndedService,
    meetingService,
    modalService,
    meetingSocketService,
    streamService,
    userService,
    userStreamService,
    hintService
  ) {
    bind(this);
    EventEmitter.setup(this, ['template']);

    this.$location = $location;
    this.meetingBroadcastService = meetingBroadcastService;
    this.browserService = browserService;
    this.dropdownService = dropdownService;
    this.mediaDeviceService = mediaDeviceService;
    this.meetingEndedService = meetingEndedService;
    this.meetingService = meetingService;
    this.modalService = modalService;
    this.meetingSocketService = meetingSocketService;
    this.streamService = streamService;
    this.userService = userService;
    this.userStreamService = userStreamService;
    this.hintService = hintService;

    this.template = Template.INIT;
    this.joining = false;
    this.activatedMeetingUrl = window.ACTIVATED_MEETING_URL;

    this.queryParams = Object.assign({}, $location.search());
    this.meetingState = null;
    meetingService.on('state', this._onMeetingState);
    this._onMeetingState(this.meetingService.state);

    this.displayName = null;
    this.setNameInProgress = false;
    this.setNameError = null;
    userService.on('me', this._onMe);
    this._onMe();

    this.streams = {
      [StreamType.AUDIO]: null,
      [StreamType.VIDEO]: null,
    };
    this.silentDeviceInfo = null;
    this._resetSilentDevices();

    this.audioTestingTimeout = null;
    this.videoTestingTimeout = null;

    this.chatBox = chatBoxService.get('greet');
    meetingBroadcastService.on('knock-chat-message', this._onBroadcastMessage, true);
    this.chatBox.on('message', this._onChatBoxMessage);
  }


  _setTemplate(template) {
    if(template === Template.SET_NAME) {
      if(this.userService.me.firstName) {
        this._setTemplate(Template.CHOOSE_AUDIO_VIDEO);
        return;

      } else if(this.queryParams.displayName) {
        this.displayName = this.queryParams.displayName;
        this.setName();
        return;
      }
    }

    if(template === Template.CHOOSE_AUDIO_VIDEO) {
      if(this.meetingService.isTemplate) {
        this.join();
        this._setTemplate(null);
        return;

      } else if(this.queryParams.share === 'audio') {
        this.joinWithAudio();
        return;

      } else if(this.queryParams.share === 'video') {
        this.joinWithVideo();
        return;

      } else if(this.queryParams.share === 'audiovideo') {
        this.joinWithAudioVideo();
        return;

      } else if(this.queryParams.share === 'none') {
        this.join();
        return;
      }
    }

    if(template === Template.AUDIO_VIDEO_REVIEW) {
      if(this.queryParams.modal) {
        this.join();
        return;
      }
    }

    if(template === Template.MEETING_ENDED) {
      this.modalService.hideAllExcept([
        MODAL_ID_ERROR,
        MODAL_ID_DISPLAY_NAME,
        MODAL_ID_CALL_ENDED,
        MODAL_ID_CALL_ENDED_DISMISSABLE
      ]);
      this.dropdownService.hideAll();
    }

    if(!this.template && template) {
      this.streamService.on('add', this._onStreamAdd);
      this.streamService.on('remove', this._onStreamRemove);
      Object.values(this.streamService.getAllLocal()).forEach(this._onStreamAdd);

    } else if(this.template && !template) {
      this.streamService.off('add', this._onStreamAdd);
      this.streamService.off('remove', this._onStreamRemove);
      Object.values(this.streamService.getAllLocal()).forEach(this._onStreamRemove);
    }

    if(template !== this.template) {
      this.template = template;
      this.emit('template', template);
    }
  }


  _onMeetingState(meetingState, exitReason) {
    // Ignore state changes to exited: these are temporary while the websocket is reconnecting.
    if(meetingState === this.meetingService.MeetingState.EXITED && this.meetingState != null) {
      return;
    }

    if(meetingState === this.meetingService.MeetingState.JOINED) {
      this.joining = false;
      // The dashboard "welcome" interface should no longer be shown once
      // a user has created an appointment type, joined a meeting room or created a contact form
      this.hintService.get('dashboardWelcome').setSeen();
      this.queryParams = {};
      this._resetSilentDevices();
    }

    this.meetingState = meetingState;
    if(!this.meetingService.ownerIsActive) {
      this._setTemplate(Template.OWNER_INACTIVE);

    } else if(this.meetingService.isTemplate && !this.browserService.isDesktop) {
      this._setTemplate(Template.MEETING_TEMPLATE_PHONE);

    } else if(
      this.meetingService.isDeactivated
      && this.activatedMeetingUrl
      && meetingState !== this.meetingService.MeetingState.JOINED
    ) {
      this._setTemplate(Template.MEETING_DEACTIVATED);

    } else if(meetingState === this.meetingService.MeetingState.EXITED) {
      this._setTemplate(Template.SET_NAME);

    } else if(meetingState === this.meetingService.MeetingState.KNOCKING) {
      this._setTemplate(Template.KNOCK);

    } else if(meetingState === this.meetingService.MeetingState.ENDED) {
      this.userStreamService.remove(StreamType.AUDIO, StreamType.VIDEO);
      if(this.meetingEndedService.shouldForwardImmediately) {
        // Do nothing
      } else if(exitReason === Session.ExitReason.ACCESS_DENIED) {
        this._setTemplate(Template.ACCESS_DENIED);
      } else {
        this._setTemplate(Template.MEETING_ENDED);
      }

    } else {
      this._setTemplate(null);
    }
  }


  /********************
   * Join the meeting *
   ********************/

  _onMe() {
    let me = this.userService.me;
    if(me.fullName === me.email) {
      this.displayName = '';
    } else {
      this.displayName = me.fullName;
    }
  }

  setName() {
    if(this.setNameInProgress) {
      return;
    }
    this.setNameInProgress = true;

    this.userService.me.setFullName(this.displayName)
      .then(() => {
        this._setTemplate(Template.CHOOSE_AUDIO_VIDEO);
        this.setNameError = null;
      }, error => {
        this.setNameError = error.message ? error.message : error;
      })
      .finally(() => {
        this.setNameInProgress = false;
      });
  }


  joinWithAudio() {
    // mediaDeviceService has already run its own update() method once, but the first execution
    // usually doesn't find any devices at all, probably because it runs before the DOM rendering
    // has happened, or something like that. So we run it again here.
    this.mediaDeviceService.update(true).then(() => this.userStreamService.startAudio());
    this._setTemplate(Template.AUDIO_VIDEO_REVIEW);
  }
  joinWithVideo() {
    this.mediaDeviceService.update(true).then(() => this.userStreamService.startVideo());
    this.userStreamService.startVideo();
    this._setTemplate(Template.AUDIO_VIDEO_REVIEW);
  }
  joinWithAudioVideo() {
    this.mediaDeviceService.update(true).then(() => this.userStreamService.startAudioVideo());
    this._setTemplate(Template.AUDIO_VIDEO_REVIEW);
  }


  _onStreamAdd(stream) {
    if(!stream.session.isLocal) {
      return;
    }

    if(this.streams[stream.type]) {
      this._onStreamRemove(this.streams[stream.type]);
    }

    if(stream.type === StreamType.VIDEO) {
      this.streams[StreamType.VIDEO] = stream;
      this._startVideoTesting();

    } else if(stream.type === StreamType.AUDIO) {
      this.streams[StreamType.AUDIO] = stream;
      this._startAudioTesting();
    }
  }

  _onStreamRemove(stream) {
    if(!stream.session.isLocal) {
      return;
    }

    if(stream === this.streams[StreamType.VIDEO]) {
      this._stopVideoTesting();
      this.streams[StreamType.VIDEO] = null;
    } else if(stream === this.streams[StreamType.AUDIO]) {
      this._stopAudioTesting();
      this.streams[StreamType.AUDIO] = null;
    }
  }


  join() {
    this.joining = true;
    this.meetingSocketService.open();
  }



  /***********************
   * Test silent devices *
   ***********************/

  _resetSilentDevices() {
    this.silentDeviceInfo = {
      [StreamType.AUDIO]: {
        isTesting: false,
        isSilent: false,
        gotLevelEvent: false,
      },
      [StreamType.VIDEO]: {
        isTesting: false,
        isSilent: false,
        snapshot: null,
      },
    };
  }


  _startAudioTesting() {
    // If the AudioContext is suspended (which happens if there hasn't been a user interaction
    // yet), the audio stream will not emit any 'level' events. This is kind of an edge case, since
    // we don't assume people will start using the share=audio query parameter. So for now, we
    // will just ignore this situation and assume that the mic is working.
    this.silentDeviceInfo[StreamType.AUDIO].isTesting = true;
    this.silentDeviceInfo[StreamType.AUDIO].isSilent = false;
    this.silentDeviceInfo[StreamType.AUDIO].gotLevelEvent = false;
    this.streams[StreamType.AUDIO].on('level', this._onAudioLevel);
    this.audioTestingTimeout = $timeout(this._setAudioSilent, AUDIO_TESTING_TIMEOUT);
  }

  _stopAudioTesting() {
    this.silentDeviceInfo[StreamType.AUDIO].isTesting = false;
    if(this.streams[StreamType.AUDIO]) {
      this.streams[StreamType.AUDIO].off('level', this._onAudioLevel);
    }
    $timeout.cancel(this.audioTestingTimeout);
  }

  _onAudioLevel(stream, level) {
    this.silentDeviceInfo[StreamType.AUDIO].gotLevelEvent = true;
    if(level > 0 && this.audioTestingTimeout != null) {
      this._stopAudioTesting();
    }
  }

  _setAudioSilent() {
    if(this.silentDeviceInfo[StreamType.AUDIO].gotLevelEvent) {
      this.silentDeviceInfo[StreamType.AUDIO].isSilent = true;
    }
    this._stopAudioTesting();
  }


  _startVideoTesting() {
    this.silentDeviceInfo[StreamType.VIDEO].isTesting = true;
    this.silentDeviceInfo[StreamType.VIDEO].isSilent = false;
    this.silentDeviceInfo[StreamType.VIDEO].snapshot = null;
    this.videoTestingTimeout = $timeout(
      () => this._takeSnapshot(new Date()),
      VIDEO_SNAPSHOT_INTERVAL
    );
  }

  _stopVideoTesting() {
    this.silentDeviceInfo[StreamType.VIDEO].isTesting = false;
    $timeout.cancel(this.videoTestingTimeout);
  }


  _takeSnapshot(timestampFirstSnapshot) {
    $timeout.cancel(this.videoTestingTimeout);
    if(!this.streams[StreamType.VIDEO]) {
      return;
    }

    this.streams[StreamType.VIDEO].takeSnapshot(null, null, null, 20, true).then(blob => {
      if(!blob) {
        logger.warn('greetService failed to create snapshot');
        this._stopVideoTesting();
        return;
      }
      let snapshotPrev = this.silentDeviceInfo[StreamType.VIDEO].snapshot;
      this.silentDeviceInfo[StreamType.VIDEO].snapshot = blob;

      let hasChanged = snapshotPrev && blob !== snapshotPrev;
      let timeSinceFirstSnapshot = new Date() - timestampFirstSnapshot;
      if(!hasChanged && timeSinceFirstSnapshot < VIDEO_TESTING_TIMEOUT) {
        this.videoTestingTimeout = $timeout(
          () => this._takeSnapshot(timestampFirstSnapshot),
          VIDEO_SNAPSHOT_INTERVAL
        );

      } else {
        this.silentDeviceInfo[StreamType.VIDEO].isSilent = !hasChanged;
        this._stopVideoTesting();
      }
    });
  }

  get isDeviceTesting() {
    return (
      this.silentDeviceInfo[StreamType.AUDIO].isTesting
      || this.silentDeviceInfo[StreamType.VIDEO].isTesting
    );
  }

  isSilent(streamType) {
    return (
      this.streams[streamType]
      && (this.streams[streamType].silence || this.silentDeviceInfo[streamType].isSilent)
      && !this.streams[streamType].ignoreSilence
    );
  }

  get hasSilentDevices() {
    return this.isSilent(StreamType.AUDIO) || this.isSilent(StreamType.VIDEO);
  }

  get silentDeviceMessage() {
    let message = '';
    if(this.isSilent(StreamType.AUDIO) && this.isSilent(StreamType.VIDEO)) {
      message = gettextCatalog.getString('Your microphone and camera do not seem to work.');

    } else if(this.isSilent(StreamType.AUDIO)) {
      message = gettextCatalog.getString('Your microphone does not seem to work.');

    } else if(this.isSilent(StreamType.VIDEO)) {
      message = gettextCatalog.getString('Your camera does not seem to work.');
    }
    return message + ' ' + gettextCatalog.getString('You can choose another device below.');
  }


  /********
   * Chat *
   ********/

  _onBroadcastMessage(channel, session, datetime, id, userId, content) {
    if(userId !== this.userService.me.id) {
      return;
    }

    if(session.isLocal) {
      this.chatBox.removeMessage(id);
    }
    this.chatBox.addMessage(id, session, content, datetime, true);
  }


  _onChatBoxMessage(message) {
    if(message.synced) {
      return;
    }

    let user = this.userService.me;
    this.meetingBroadcastService.send(
      'knock-chat-message',
      true,
      [user.id],
      message.id,
      user.id,
      message.rawContent
    );
  }
}
