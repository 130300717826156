import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
// The intl-tel-input css file is loaded in angular.json

const VALIDATE_DEBOUNCE = 500;

/*
* As of 02/09/2021, this PhoneNumberDirective is used on the signup page and on the profile edit
* page. The signup page uses Iubenda to track user consent, but this dependency breaks the use of
* listening on "submit" events. This means that the signup page will ALWAYS be submitted if you
* click the signup button, even when we want to prevent this (e.g. a required invalid phone number)
* Even the browser tooltips "Please fill in this field" don't work on the signup page..
*
* To circumvent this problem, we have to disable the signup button if an invalid phone number is
* entered (only if it is a required field though)

* The profile page does not have this problem, so there we can catch the submit event and take
* appropriate actions
*/
export default function PhoneNumberDirective() {
  return {
    restrict: 'A',
    scope: true,
    link: function($scope, $elem) {
      if(!$elem.is('input[type="tel"]')) {
        return;
      }

      let validatePhoneNumberDebounced = debounce(validatePhoneNumber, VALIDATE_DEBOUNCE);
      // The 'intl-tel-input' lib provides an option to submit a hidden input containting the fully
      // internationalized version of the phone number. We want to map this to the correct django
      // form field. To do this, we change the name of the django-generated input field and add
      // a hidden field with the django field name ('phone_number').
      let name = $elem.attr('name');
      $elem.removeAttr('name');

      // An errorcode needs to be mapped to a validation error message.
      let errorMap = [
        gettextCatalog.getString('This number is invalid'),
        gettextCatalog.getString('Please select a country'),
        gettextCatalog.getString('This phone number is too short'),
        gettextCatalog.getString('This phone number is too long'),
        gettextCatalog.getString('This number is invalid')
      ];

      let $elemForm = $elem.parents('form');
      let $elemError = $elem.closest('.form-group').find('.error');

      // geoIpLookup will find the country code based on the current ip address.
      let iti = intlTelInput($elem[0], {
        utilsScript: 'intl-tel-input/build/js/utils',
        hiddenInput: name,
        initialCountry: 'auto',
        geoIpLookup: function(success) {
          $.get('https://ipinfo.io', function() { }, 'json').always(function(resp) {
            let countryCode = (resp && resp.country) ? resp.country : 'be';
            success(countryCode);
          });
        },
      });

      // If the number in iti is invalid, we set the country to the defaultCountry
      // The default behaviour is to not set show any flag.
      iti.promise.then(() => {
        if($elem.val().trim && !iti.isValidNumber()) {
          iti.setCountry(iti.defaultCountry);
        }
        validatePhoneNumber(false);
      });

      function validatePhoneNumber(shouldReset) {
        if(shouldReset || shouldReset == null) {
          reset();
        }

        if($elem.val().trim() && !iti.isValidNumber()) {
          let message;
          let errorCode = iti.getValidationError();
          if(errorCode > errorMap.length || errorCode < 0) {
            message = gettextCatalog.getString('This number is invalid');
          } else {
            message = errorMap[errorCode];
          }
          $elem.addClass('error');
          $elemError.html(message);
        }
      }


      function reset() {
        $elem.removeClass('error');
        $elemError.html('');
      }

      // Setup listeners
      $elem.blur(validatePhoneNumber);
      // Validate debounced, but immediately reset
      $elem.change(validatePhoneNumberDebounced);
      $elem.change(reset);
      $elem.keyup(validatePhoneNumberDebounced);
      $elem.keyup(reset);



      // The form can not be submitted if the phone number is not valid
      $elemForm.submit((e) => {
        if($elem.prop('required') && $elem.val().trim() && !iti.isValidNumber()) {
          e.preventDefault();
        }
      });
    },
  };
}
