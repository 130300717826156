import { bind, browser, errors, interval, logger, raf } from 'utils/util';
import { getFlexibleLayout } from '../../components/tiles/phone/util';
import { StreamType, ElemState } from  'meeting/meeting-room/stream';


const CHECK_INTERVAL = 1000;
const RENDER_MAX_SIZE = 400;
const RENDER_ASPECT_RATIO = 4 / 3;
const DEFAULT_STREAM_ASPECT_RATIO = 4 / 3;


export default class PictureInPictureService {
  static get $inject() {
    return [
      'notificationService',
      'shortcutService',
      'streamService',
      'tileService',
      'userService',
    ];
  }

  constructor(
    notificationService,
    shortcutService,
    streamService,
    tileService,
    userService
  ) {
    bind(this);

    this.notificationService = notificationService;
    this.shortcutService = shortcutService;
    this.streamService = streamService;
    this.tileService = tileService;
    this.userService = userService;

    this.implicit = false;
    this.canvas = null;
    this.ctx = null;
    this.video = null;
    this.checkEndedInterval = null;

    this.shortcutService.on('alt+x', this.toggle);
    this.streamService.on('remove', this._onStreamRemove);
    this.userService.mySession.on('state', this._onMySessionState);
  }


  get isSupported() {
    return browser.supportsPictureInPicture();
  }

  get isEnabled() {
    return !!this.video;
  }

  isActiveStream(stream) {
    return this.isEnabled && this._isAllowedStream(stream);
  }


  _isAllowedStream(stream) {
    return (
      !stream.user.isMe
      && stream.type === StreamType.VIDEO
      && !stream.session.isKnocking()
    );
  }

  getAllowedStreams() {
    return this.streamService.streams.filter(this._isAllowedStream);
  }

  _getVideoElem(stream) {
    return stream.elemInfos.find(elemInfo => elemInfo.state === ElemState.PLAYING);
  }


  toggle() {
    if(!this.isSupported) {
      return;
    }

    if(this.isEnabled) {
      this._leave();
    } else {
      this._enter();
    }
  }


  _enter(implicit) {
    if(this.isEnabled || !this.isSupported) {
      return;
    }

    let allowedStreams = this.getAllowedStreams();
    if(allowedStreams.length === 0) {
      return;
    }

    this.implicit = implicit;

    this.canvas = document.createElement('canvas');
    try {
      this.ctx = this.canvas.getContextUnsafe('2d');
    } catch(e) {
      if(e.constructor === errors.GetContextFailedError) {
        if(!implicit) {
          this._showContextFailedNotification();
        }
        this._leave();
        return;

      } else {
        throw e;
      }
    }
    raf.requestAnimationFrame(this._updateCanvas);

    this.video = document.createElement('video');
    this.video.autoplay = true;
    // This is only supported in Chrome, but hopefully Safari will add it some time in the future
    this.video.addEventListener('leavepictureinpicture', this._onLeave);
    this.video.srcObject = this.canvas.captureStream();

    this.video.addEventListener('loadedmetadata', () => {
      browser.isBlink() ?
        this._enterChrome() :
        this._enterSafari();
    });

    this.tileService._drawThrottled();
  }


  _leave() {
    this._onLeave();
  }

  _onLeave() {
    if(!this.isEnabled) {
      return;
    }

    this.video.removeEventListener('leavepictureinpicture', this._onLeave);

    browser.isBlink() ?
      this._leaveChrome() :
      this._leaveSafari();

    this.implicit = false;
    this.canvas = null;
    this.ctx = null;
    this.video.srcObject = null;
    this.video = null;

    this.tileService._drawThrottled();
  }

  _enterChrome() {
    $q.resolve().then(() => {
      return this.video.requestPictureInPicture();
    })
      .catch(error => {
        if(error.name !== 'InvalidStateError' && error.name !== 'NotAllowedError') {
          logger.warn(error);
        }
        this._onLeave();
      });
  }

  _leaveChrome() {
    if(!document.pictureInPictureElement) {
      return;
    }

    document.exitPictureInPicture()  // eslint-disable-line compat/compat
      .catch(error => {
        if(error.name !== 'NotAllowedError') {
          logger.warn(error);
        }
      });
  }


  _enterSafari() {
    this.checkEndedInterval = interval.setInterval(this._checkEndedSafari, CHECK_INTERVAL);
    this.video.webkitSetPresentationMode('picture-in-picture');
  }

  _leaveSafari() {
    interval.clearInterval(this.checkEndedInterval);
    this.checkEndedInterval = null;

    this.video.webkitSetPresentationMode('inline');
  }


  _checkEndedSafari() {
    if(this.isEnabled && this.video.webkitPresentationMode === 'inline') {
      this._onLeave();
    }
  }



  /*********************
   * Render the canvas *
   *********************/

  _updateCanvas() {
    try {
      this._updateCanvasUnsafe();
    } catch(error) {
      logger.info(error);
      this._leave();
    }
  }


  _updateCanvasUnsafe() {
    let streams = this.getAllowedStreams();
    if(streams.length === 0) {
      this._leave();
      return;
    }

    // Safari pauses every stream that enters PiP => we need to manually restart it
    if(browser.isSafari()) {
      streams.forEach(stream => {
        stream.updateElems();
      });
    }

    let tiles = streams
      .map(stream => {
        let aspectRatio = stream.getAspectRatio();
        return {
          stream: stream,
          videoActive: aspectRatio != null,
          aspectRatio: aspectRatio || DEFAULT_STREAM_ASPECT_RATIO,
        };
      })
      .sort((t1, t2) => t1.aspectRatio - t2.aspectRatio);

    let layout = getFlexibleLayout(tiles, RENDER_MAX_SIZE, RENDER_ASPECT_RATIO, 0);

    this.canvas.width = layout.rect.width;
    this.canvas.height = layout.rect.height;
    layout.tiles.forEach(({ tile, rect: drawRect }) => {
      let elemInfo = this._getVideoElem(tile.stream);

      // We include non-renderable streams in the layout, so that the PiP popup dimensions don't
      // jump during renegotiation. This dimension jumping causes the PiP popup to decrease in size
      // on Safari.
      if(elemInfo) {
        let sourceRect = {
          width:  elemInfo.elem.videoWidth,
          height: elemInfo.elem.videoHeight,
        };
        let scale = Math.max(
          drawRect.width  / sourceRect.width,
          drawRect.height / sourceRect.height
        );

        this.ctx.drawImage(
          elemInfo.elem,
          (sourceRect.width -  drawRect.width  / scale) / 2,
          (sourceRect.height - drawRect.height / scale) / 2,
          drawRect.width  / scale,
          drawRect.height / scale,
          drawRect.left,
          drawRect.top,
          drawRect.width,
          drawRect.height
        );
      }
    });

    raf.requestAnimationFrame(this._updateCanvas);
  }


  /****************************************
   * Show PiP when screen stream is added *
   ****************************************/

  enterImplicit() {
    this._enter(true);
  }


  leaveImplicit() {
    if(this.isEnabled && this.implicit) {
      this._leave();
    }
  }


  _shouldShowImplicit() {
    let contentStreams = Object.values(this.streamService.getAllLocal())
      .filter(stream => stream.type === StreamType.SCREEN);
    return contentStreams.length > 0;
  }


  _onStreamRemove(stream) {
    if(
      stream.isLocal
      && stream.type === StreamType.SCREEN
      && !this._shouldShowImplicit()
    ) {
      this.leaveImplicit();
    }
  }


  _onMySessionState() {
    if(!this.userService.mySession.isJoined()) {
      this._leave();
    }
  }


  /****************
   * Notification *
   ****************/

  _showContextFailedNotification() {
    this.notificationService.warning(gettextCatalog.getString(`
      Pinning the video stream failed because your computer does not have enough memory available.
    `));
  }
}
