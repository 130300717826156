<a
  class="powered-by__container a-plain"
  [class.powered-by__container--dark]="tint === 'dark'"
  [class.powered-by__container--light]="tint === 'light'"
  [attr.href]="siteService.site.homepageAnonymous + utmTags"
  (click)="trackLogoClick()"
  target="_blank"
  data-testid="meetingRoomPoweredByBadge"
>
  <span class="powered-by__label"data-testid="meetingRoomPoweredByBadgeLabel" >{{ poweredByText }}</span>
  <app-logo
    *ngIf="style !== 'text-only'"
    class="powered-by__logo"
    [logoVariant]="logoVariant"
    [logoMonochrome]="true"
    data-testid="meetingRoomPoweredByBadgeIcon"
  ></app-logo>
</a>
