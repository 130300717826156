<fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>
<div *ngIf="!fetchError">
  <h2 i18n>Upcoming meetings</h2>

  <div class="card card--sm bg-neutral-200" *ngIf="shouldShowTeamTeaser" i18n>When you <a href="{{ inviteUrl }}"> invite
      extra team members</a>, their meetings will show up here.</div>

  <div class="card card--sm bg-neutral-200"
    *ngIf="!shouldShowTeamTeaser && stateService.isReady && appointments.length === 0">
    <span *ngIf="scope === DashboardScope.PERSONAL" i18n>You have no upcoming meetings.</span>
    <span *ngIf="scope === DashboardScope.TEAM" i18n>Your team has no upcoming meetings.</span>
    <a [href]="schedulingAction.url" [target]="schedulingAction.target">{{ schedulingAction.text }}</a>.
  </div>

  <div *ngIf="!shouldShowTeamTeaser && appointments.length > 0">
    <appointments-table 
      [appointments]="appointments" 
      [scope]="scope"
      (appointmentChange)="refetch()">
    </appointments-table>

    <div class="d-flex justify-content-center">
      <pagination [(page)]="page" [numPages]="numPages"></pagination>
    </div>
  </div>
</div>
