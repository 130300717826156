import * as fields from 'utils/fields';
import Model from 'utils/models';
import { ContactForm } from './ContactForm';
import { ContactFormAnswer } from './ContactFormAnswer';


export class ContactFormResponse extends Model {
  static get fields() {
    return {
      contactForm: new fields.RelatedField({ Model: ContactForm }),
      contactFormId: new fields.UUIDRelatedField(),
      dateCreated: new fields.DateTimeField(),
      answers: new fields.RelatedField({
        Model: ContactFormAnswer,
        withIdField: false,
        many: true,
        readOnly: false,
        default: [],
      }),
      submitterNotificationEmail: new fields.StringField(),
    };
  }

  static get basePath() {
    return 'contactFormResponses';
  }


  constructor(...args) {
    super(...args);
    this.recaptchaResponse = null;
  }

  setRecaptchaResponse(recaptchaResponse) {
    this.recaptchaResponse = recaptchaResponse;
  }


  create(apiConfig = {}) {
    apiConfig.headers = apiConfig.headers || {};
    if(this.recaptchaResponse) {
      apiConfig.headers['X-Recaptcha-Response'] = this.recaptchaResponse;
    }
    return super.create(apiConfig);
  }
}
