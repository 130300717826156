import { array, bind, format, interval, object } from 'utils/util';
import MediaDevice from '../../shared/audioVideo/MediaDevice';
import { StreamTypeToDeviceKind, StreamState, StreamType } from  'meeting/meeting-room/stream';



const SilenceState = Object.freeze({
  UNKNOWN: 'unknown',
  SILENCE: 'silence',
  SILENCE_IGNORED: 'silence-ignored',
  PLAYING: 'playing',
});


const TEMPLATE_ONE_EXTRA_DEVICE = `
  <div ng-controller="silentDeviceNotificationCtrl as ctrl">
    <div>
      Your %s %s not seem to work.
    </div>
    <div
      class="btn btn--secondary"
      ng-click="ctrl.silentDeviceNotificationService.switchDevice()"
    >
      Switch to your second %s
    </div>
  </div>`;

const TEMPLATE_MULTIPLE_EXTRA_DEVICES = `
  <div ng-controller="silentDeviceNotificationCtrl as ctrl">
    <div>
      Your %s %s not seem to work.
    </div>
    <div
      class="btn btn--secondary"
      ng-click="ctrl.silentDeviceNotificationService.switchDevice()"
    >
      Choose another %s
    </div>
  </div>`;



const TEMPLATE_REMOTE_AUDIO_SILENCE = `
  %s's %s %s not seem to work.
  Hang on while %s is trying to fix the problem.`;

const TEMPLATE_REMOTE_VIDEO_SILENCE = `
  %s's %s %s not seem to work.
  Hang on while %s is trying to fix the problem or continue with audio only.`;

const TEMPLATE_REMOTE_AUDIO_PLAYING = `
  We're not receiving any input from %s's %s.
  Please try refreshing the page.`;

const TEMPLATE_REMOTE_VIDEO_PLAYING = `
  We're not receiving any input from %s's %s.
  Please try refreshing the page or continue with audio only.`;



export default class SilentDeviceNotificationService {

  static get $inject() {
    return [
      'mediaDeviceService',
      'notificationService',
      'privateMessageService',
      'settingsService',
      'siteService',
      'streamService',
      'userService',
    ];
  }

  constructor(
    mediaDeviceService,
    notificationService,
    privateMessageService,
    settingsService,
    siteService,
    streamService,
    userService
  ) {
    bind(this);

    this.mediaDeviceService = mediaDeviceService;
    this.notificationService = notificationService;
    this.privateMessageService = privateMessageService;
    this.settingsService = settingsService;
    this.siteService = siteService;
    this.streamService = streamService;
    this.userService = userService;

    this.streamService.on('add', this._onStreamAdd);
    this.streamService.on('remove', this._onStreamRemove);
    this.mediaDeviceService.on('audioinput videoinput', this._onMediaDevice);

    this.privateMessageService.on('stream-check-req', this._onStreamCheckReq);
    this.privateMessageService.on('stream-check-answer', this._onStreamCheckAnswer);

    this.streamInfos = {};
    this.notifications = new Map();
  }


  _getStreamInfosForUser(user) {
    return Object.values(this.streamInfos).filter(streamInfo => streamInfo.stream.user === user);
  }

  _getSilentStreamInfosForUser(user) {
    return this._getStreamInfosForUser(user)
      .filter(streamInfo => (
        streamInfo.localState === SilenceState.SILENCE
        && (
          streamInfo.stream.user.isMe || streamInfo.playing
          && array.has([SilenceState.SILENCE, SilenceState.PLAYING], streamInfo.remoteState)
        )
      ));
  }


  /*******************
   * Event listeners *
   *******************/

  _onStreamAdd(stream) {
    if(
      stream.user.isMe && !stream.isLocal
      || !array.has([StreamType.AUDIO, StreamType.VIDEO], stream.type)
    ) {
      return;
    }

    let streamInfo = {
      stream: stream,
      playing: false,
      localState: SilenceState.UNKNOWN,
      remoteState: SilenceState.UNKNOWN,

      checkId: 0,
      checkInterval: null,
    };
    this.streamInfos[stream.id] = streamInfo;

    stream.on('silence state', this._updateStreamState);
    this._updateStreamState(stream);
  }


  _onStreamRemove(stream) {
    let streamInfo = this.streamInfos[stream.id];
    if(!streamInfo) {
      return;
    }
    delete this.streamInfos[stream.id];

    stream.off('silence state', this._updateStreamState);
    interval.clearInterval(streamInfo.checkInterval);
    this._updateNotification(stream.session.user);
  }


  _onMediaDevice() {
    this._updateNotification(this.userService.me);
  }


  _updateStreamState(stream) {
    let streamInfo = this.streamInfos[stream.id];
    if(!streamInfo) {
      return;
    }

    streamInfo.localState = this._getLocalStreamState(streamInfo);
    streamInfo.playing = streamInfo.stream.state === StreamState.PLAYING;
    if(!streamInfo.stream.isLocal) {
      this._updateCheckInterval(streamInfo);
    }

    this._updateNotification(streamInfo.stream.user);
  }


  _getLocalStreamState(streamInfo) {
    let stream = streamInfo.stream;
    return stream ?
      stream.silence ?
        SilenceState.SILENCE :
        stream.ignoreSilence ?
          SilenceState.SILENCE_IGNORED :
          SilenceState.PLAYING :
      SilenceState.UNKNOWN;
  }



  /*********************
   * Show notification *
   *********************/

  _updateNotification(user) {
    let shouldShow = this._shouldShowNotification(user);
    if(shouldShow) {
      this._showNotification(user);
    } else {
      this._hideNotification(user);
    }
  }


  _shouldShowNotification(user) {
    return (
      object.length(this._getSilentStreamInfosForUser(user)) > 0
      && !this.userService.mySession.isDead()
    );
  }


  _showNotification(user) {
    let message = this._getNotificationMessage(user);
    let notification = this._getOrCreateNotification(user);
    notification.setMessage(message);
  }


  _hideNotification(user) {
    let notification = this.notifications.get(user);
    this.notifications.delete(user);
    if(notification) {
      notification.cancel();
    }
  }


  _getOrCreateNotification(user) {
    if(!this.notifications.has(user)) {
      this.notifications.set(user, this.notificationService.warning('', { delay: -1 }));
    }
    return this.notifications.get(user);
  }


  _getNotificationMessage(user) {
    return user.isMe ?
      this._getLocalNotificationMessage() :
      this._getRemoteNotificationMessage(user);
  }


  _getLocalNotificationMessage() {
    /* eslint-disable max-len */
    const TEMPLATE_NO_EXTRA_DEVICES = `
      <div>
        Your %s %s not seem to work. Connect an external %s or
        <a href="${this.siteService.getHelpArticle('unknownDeviceError')}" target="_blank">get help</a>.
      </div>`;
    /* eslint-enable max-len */

    let user = this.userService.me;
    let streamInfos = this._getSilentStreamInfosForUser(user);
    let deviceKinds = array.distinct(streamInfos.map(streamInfo => streamInfo.stream.type))
      .map(streamType => StreamTypeToDeviceKind[streamType]);
    let deviceNames = deviceKinds
      .map(deviceKind => gettextCatalog.getString(MediaDevice.Name[deviceKind]))
      .join(' and ');
    let externalDeviceNames = deviceKinds
      .map(deviceKind => gettextCatalog.getString(MediaDevice.ExternalName[deviceKind]))
      .join(' and ');
    let verb = deviceKinds.length === 1 ? 'does' : 'do';

    let maxFreeDevices = Math.max(
      ...streamInfos.map(streamInfo => this._getNumFreeDevices(streamInfo.stream))
    );

    if(maxFreeDevices === 0) {
      return format(TEMPLATE_NO_EXTRA_DEVICES, deviceNames, verb, externalDeviceNames);
    } else if(maxFreeDevices === 1) {
      return format(TEMPLATE_ONE_EXTRA_DEVICE, deviceNames, verb, deviceNames);
    } else {
      return format(TEMPLATE_MULTIPLE_EXTRA_DEVICES, deviceNames, verb, deviceNames);
    }
  }


  _getFreeDevices(stream) {
    let deviceKind = StreamTypeToDeviceKind[stream.type];
    let realDevices = object.filter(
      this.mediaDeviceService.devices[deviceKind],
      (id, device) => !device.meta
    );

    // If there is only one default device, then we assume that device is active. If we don't, and
    // the active device is the default device, and we can't reference from that default device to
    // a real device, then this method would return 1 free device, which is incorrect.
    if(object.length(realDevices) === 1) {
      return {};
    }

    let activeDevice = stream.inputDevice;
    if(activeDevice && activeDevice.getReferenced()) {
      activeDevice = activeDevice.getReferenced();
    }
    if(activeDevice) {
      delete realDevices[activeDevice.id];
    }

    return realDevices;
  }


  _getNumFreeDevices(stream) {
    return object.length(this._getFreeDevices(stream));
  }


  _getRemoteNotificationMessage(user) {
    let streamInfos = this._getSilentStreamInfosForUser(user);
    let deviceKinds = array.distinct(streamInfos.map(streamInfo => streamInfo.stream.type))
      .map(streamType => StreamTypeToDeviceKind[streamType]);
    let deviceNames = deviceKinds
      .map(deviceKind => MediaDevice.Name[deviceKind])
      .join(' and ');
    let verb = deviceKinds.length === 1 ? 'does' : 'do';

    let remoteState = {};
    streamInfos.forEach(streamInfo => {
      remoteState[streamInfo.stream.type] = streamInfo.remoteState;
    });

    if(remoteState[StreamType.AUDIO] === SilenceState.SILENCE) {
      return format(TEMPLATE_REMOTE_AUDIO_SILENCE,
        user.shortName, deviceNames, verb, user.shortName);

    } else if(remoteState[StreamType.AUDIO] === SilenceState.PLAYING) {
      return format(TEMPLATE_REMOTE_AUDIO_PLAYING, user.shortName, deviceNames);

    } else if(remoteState[StreamType.VIDEO] === SilenceState.SILENCE) {
      return format(TEMPLATE_REMOTE_VIDEO_SILENCE,
        user.shortName, deviceNames, verb, user.shortName);

    } else if(remoteState[StreamType.VIDEO] === SilenceState.PLAYING) {
      return format(TEMPLATE_REMOTE_VIDEO_PLAYING, user.shortName, deviceNames);
    }
  }



  /************************
  * Check remote silence *
  ************************/

  _updateCheckInterval(streamInfo) {
    let shouldCheck = this._shouldCheck(streamInfo);
    let isChecking = !!streamInfo.checkInterval;
    if(shouldCheck && !isChecking) {
      this._checkRemoteSilence(streamInfo);
      streamInfo.checkInterval = interval.setInterval(
        this._checkRemoteSilence.bind(null, streamInfo),
        5000
      );

    } else if(isChecking && !shouldCheck) {
      interval.clearInterval(streamInfo.checkInterval);
      delete streamInfo.checkInterval;
    }
  }


  _shouldCheck(streamInfo) {
    return (
      !streamInfo.stream.isLocal
      && streamInfo.playing
      && streamInfo.localState === SilenceState.SILENCE
    );
  }


  _checkRemoteSilence(streamInfo) {
    this.privateMessageService.send('stream-check-req',
      streamInfo.stream.session, streamInfo.stream.id, ++streamInfo.checkId);
  }


  _onStreamCheckReq(channel, session, streamId, checkId) {
    let streamInfo = this.streamInfos[streamId];
    let state = streamInfo ?
      this._getLocalStreamState(streamInfo) :
      SilenceState.UNKNOWN;
    this.privateMessageService.send('stream-check-answer', session, streamId, checkId, state);
  }


  _onStreamCheckAnswer(channel, session, streamId, checkId, remoteState) {
    let streamInfo = this.streamInfos[streamId];
    if(streamInfo && checkId === streamInfo.checkId) {
      streamInfo.remoteState = remoteState;
      this._updateNotification(streamInfo.stream.user);
    }
  }



  /*****************
  * Switch Devices *
  ******************/

  switchDevice() {
    let me = this.userService.me;
    let streamInfos = this._getSilentStreamInfosForUser(me);
    let freeDevices = streamInfos.map(streamInfo => this._getFreeDevices(streamInfo.stream));
    let maxFreeDevices = Math.max(
      ...streamInfos.map(streamInfo => this._getNumFreeDevices(streamInfo.stream)));

    if(maxFreeDevices === 1) {
      for(let i = 0; i < streamInfos.length; i++) {
        let stream = streamInfos[i].stream;
        let currentDevice = stream.inputDevice;
        let newDevice = Object.values(freeDevices[i])[0];
        if(currentDevice && newDevice) {
          this.mediaDeviceService.setPreferredDevice(newDevice);
        }
      }

    } else {
      this.settingsService.showModal();
    }
  }
}
