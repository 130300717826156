const TEMPLATE = `
  <div class="d-flex">
    <div
      class="
        flex-1 ml-2
        d-flex justify-content-center align-items-center
        border border-round
      "
      ng-style="{
        background: color,
        color: textColor,
      }"
      translate
    >
      Preview
    </div>
  </div>
`;


ColorInputDirective.$inject = [
  '$compile',
];


export default function ColorInputDirective(
  $compile
) {
  return {
    restrict: 'A',
    scope: true,
    link: function($scope, $elem) {
      $scope.color = '';
      $scope.textColor = '';

      $elem.on('change input', () => $scope.$evalAsync(update));
      update();

      let $elemInsert = $compile(TEMPLATE)($scope);
      $elem.before($elemInsert);
      $elemInsert.prepend($elem);
      $elem.css('flex', '2');


      function update() {
        let color = $elem.val();
        let textColor = 'rgb(var(--neutral-100))';
        if(!color) {
          color = $elem.attr('placeholder');
        } else if(!color.startsWith('#')) {
          color = '#' + color;
        }
        if($elem.is(':disabled')) {
          color = 'rgb(var(--neutral-200))';
          textColor = 'rgb(var(--neutral-500))';
        }
        $scope.color = color;
        $scope.textColor = textColor;
      }
    }
  };
}
