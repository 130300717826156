export default function StickyDirective() {
  return {
    restrict: 'A',

    link: function($scope, $elem) {
      window.addEventListener('scroll', update);
      window.addEventListener('resize', update);
      $scope.$on('$destroy', () => {
        window.removeEventListener('scroll', update);
        window.removeEventListener('resize', update);
      });


      function update() {
        let windowScroll = getWindowScroll();

        $elem.removeClass('sticky');
        if(windowScroll > $elem[0].offsetTop) {
          $elem.addClass('sticky');
          $elem.css('top', windowScroll - window.pageYOffset);
        }
      }


      function getWindowScroll() {
        return window.pageYOffset != null ? window.pageYOffset : window.scrollTop;
      }
    }
  };
}
