import template from './dropdown.html?raw';

import { Rect, format } from 'utils/util';
import Dropdown from './Dropdown';


let ATTR_CLOSE = Object.freeze({
  NONE: 'none',
  SELF: 'self',
  ALL: 'all',
});


class DropdownController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      'dropdownService',
    ];
  }

  constructor(
    $elem,
    $scope,
    dropdownService
  ) {
    this._bind();

    this.$elem = $elem;
    this.$scope = $scope;
    this.dropdownService = dropdownService;

    this.active = false;
    this.dropdown = null;

    this.$elemTarget = null;
    this.showTimeout = null;

    this.rectParent = null;
    this.watchParentElemInterval = null;

    if(window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this._onResize);
    }
  }

  _bind() {
    this._onActive = this._onActive.bind(this);
    this._onWindowResize = this._onWindowResize.bind(this);
    this._onResize = this._onResize.bind(this);
    this._watchParentElem = this._watchParentElem.bind(this);

    this._onClick = this._onClick.bind(this);
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
  }

  $onInit() {
    this.dropdown.setElem(this.$elem);
    this.dropdownService.on('active inactive', this._onActive);
    this._onActive();
  }

  $onDestroy() {
    this._hide();
    this.dropdown.unsetElem(this.$elem);
    this.dropdownService.off('active inactive', this._onActive);
  }


  get scope() {
    return this.dropdown.scope;
  }


  _onActive() {
    $timeout(() => {
      if(this.active === this.dropdown.active) {
        return;
      }

      this.active = this.dropdown.active;
      if(this.active) {
        this._show();
      } else {
        this._hide();
      }
    });
  }


  _onWindowResize() {
    this._updatePosition();
  }

  _onResize() {
    this._updatePosition();
  }

  _watchParentElem() {
    if(!this.dropdown || !this.dropdown.$elem) {
      return;
    }

    // There is no efficient way to observe the position of the parent element, which can for
    // example change due to content changes. So we simply monitor the position in an interval.
    // This also fixes a bug on Mobile Safari where the reflow of the page as a result of a
    // window resize event only happens some time after the event has fired.
    let rectParent = this._getRectParent();
    if(
      (this.rectParent == null) !== (rectParent == null)
      || this.rectParent && !this.rectParent.equals(rectParent)
    ) {
      this._updatePosition();
    }
  }


  _show() {
    this._render();

    $document.on('click', this._onClick);
    $document.on('mousedown', this._onMouseDown);
    $document.on('touchstart', this._onTouchStart);

    $(window).on('resize', this._onWindowResize);
    if(this.resizeObserver) {
      this.resizeObserver.observe(this.dropdown.$elem[0]);
    }
    this.watchParentElemInterval = setInterval(this._watchParentElem, 1000);
  }


  _hide() {
    $document.off('click', this._onClick);
    $document.off('mousedown', this._onMouseDown);
    $document.off('touchstart', this._onTouchStart);

    $(window).off('resize', this._onWindowResize);
    if(this.resizeObserver && this.dropdown.$elem) {
      this.resizeObserver.unobserve(this.dropdown.$elem[0]);
    }
    clearInterval(this.watchParentElemInterval);
  }


  _hideElem() {
    if(this.dropdown && this.dropdown.$elem) {
      this.dropdown.$elem.css({
        opacity: 0,  // Don't use visibility: hidden, this prevents setting focus on inputs
        translate: '',
      });
    }
  }


  _render() {
    if(!this.dropdown) {
      return;
    }

    // Hide the element until its position is set
    this._hideElem();

    // Wait until the size has settled
    $timeout(() => {
      this._updatePosition();
      this.dropdownService.emit('visible', this.dropdown);
    });
  }


  _updatePosition() {
    if(!this.dropdown || !this.dropdown.$elem) {
      return;
    }

    let rectDocument = this._getRectDocument();
    let rectViewport = Rect.fromViewport();
    let rect = Rect.fromElem(this.dropdown.$elem[0]);
    let rectParent = this._getRectParent();
    this.rectParent = rectParent;

    if(rectDocument == null || rectParent == null) {
      this._hideElem();
      return;
    }


    let preferredPositions = {
      left: [],
      top: [],
    };

    if(this.dropdown.position === Dropdown.Position.RIGHT) {
      preferredPositions.left = [
        rectParent.right + rectDocument.left,
        rectParent.left - rect.width + rectDocument.left,
        rectViewport.width - rect.width,
        0,
      ];
    } else if(this.dropdown.position === Dropdown.Position.LEFT) {
      preferredPositions.left = [
        rectParent.left - rect.width + rectDocument.left,
        rectParent.right + rectDocument.left,
        0,
      ];
    } else if(this.dropdown.align === Dropdown.Align.END) {
      preferredPositions.left = [
        rectParent.right - rect.width + rectDocument.left,
        rectParent.left + rectDocument.left,
        0,
      ];
    } else {
      preferredPositions.left = [
        rectParent.left + rectDocument.left,
        rectParent.right - rect.width + rectDocument.left,
        rectViewport.width - rect.width,
        0,
      ];
    }

    if(this.dropdown.position === Dropdown.Position.BOTTOM) {
      preferredPositions.top = [
        rectParent.bottom + rectDocument.top,
        rectParent.top - rect.height + rectDocument.top,
        rectViewport.height - rect.height,
        0,
      ];
    } else if(this.dropdown.position === Dropdown.Position.TOP) {
      preferredPositions.top = [
        rectParent.top - rect.height + rectDocument.top,
        rectParent.bottom + rectDocument.top,
        0,
      ];
    } else if(this.dropdown.align === Dropdown.Align.END) {
      preferredPositions.top = [
        rectParent.bottom - rect.height + rectDocument.top,
        rectParent.top + rectDocument.top,
        0,
      ];
    } else {
      preferredPositions.top = [
        rectParent.top + rectDocument.top,
        rectParent.bottom - rect.height + rectDocument.top,
        rectViewport.height - rect.height,
        0,
      ];
    }

    let position = [['left', 'width'], ['top', 'height']].map(([property, sizeProperty]) => {
      return Math.round(
        preferredPositions[property].find((position, i, positions) => {
          return (
            i === positions.length - 1
            || position > 0 && position + rect[sizeProperty] <= rectViewport[sizeProperty]
          );
        })
      );
    });

    let style = {
      opacity: '',
      transform: format('translate(%spx, %spx)', position[0], position[1]),
    };
    this.dropdown.$elem.css(style);
  }


  _getRectDocument() {
    if(this.dropdown.position === Dropdown.Position.EXACT) {
      if(this.dropdown.$elem) {
        return Rect.fromFixedAncestor(this.dropdown.$elem[0]);
      } else {
        return null;
      }

    } else {
      if(this.dropdown.$elemPosition) {
        return Rect.fromFixedAncestor(this.dropdown.$elemPosition[0]);
      } else {
        return null;
      }
    }
  }


  _getRectParent() {
    if(this.dropdown.position === Dropdown.Position.EXACT) {
      if(this.dropdown.$eventPosition) {
        return Rect.fromEvent(this.dropdown.$eventPosition.originalEvent);
      } else {
        return null;
      }

    } else {
      if(this._isVisible(this.dropdown.$elemPosition)) {
        return Rect.fromElem(this.dropdown.$elemPosition[0]);
      } else {
        return null;
      }
    }
  }


  _isVisible($elem) {
    return ($elem && $elem[0] && $elem[0].offsetParent != null);
  }


  _onClick($event) {
    let $elemTarget = this.$elemTarget || angular.element($event.target);
    this.$elemTarget = null;

    // Don't close if the element is clicked that initially opened the dropdown
    if($elemTarget.closest(this.dropdown.$elemPosition).length > 0) {
      return;
    }

    let $elemTargetDropdown = $elemTarget.closest('dropdown-deprecated');

    let isChild = $elemTargetDropdown.is(this.dropdown.$elem);
    let hasAttrClose = (
      this._getAttrClose($elemTarget, $event.type, this.dropdown.$elem) !== ATTR_CLOSE.NONE);

    let isEmbeddedChild = false;
    let embeddedChildHasAttrClose = false;
    this.dropdown.embeds.forEach(id => {
      let dropdown = this.dropdownService.get(id);
      if(dropdown && $elemTargetDropdown.is(dropdown.$elem)) {
        isEmbeddedChild = true;
        if(this._getAttrClose($elemTarget, $event.type, dropdown.$elem) === ATTR_CLOSE.ALL) {
          embeddedChildHasAttrClose = true;
        }
      }
    });

    if(
      isChild && hasAttrClose
      || isEmbeddedChild && embeddedChildHasAttrClose
      || !isChild && !isEmbeddedChild
    ) {
      this.$scope.$evalAsync(() => this.dropdownService.hide(this.dropdown.id));
    }
  }


  _getAttrClose($elemTarget, eventType, $elemDropdown) {
    // Don't close the dropdown on touchend events: on iOS this prevents click handlers on the
    // clicked element to be executed
    if(eventType !== 'click') {
      return ATTR_CLOSE.NONE;
    }

    while($elemTarget.length > 0 && !$elemTarget.is($elemDropdown)) {
      if($elemTarget.attr('dropdown-close-all') != null) {
        return ATTR_CLOSE.ALL;
      } else if($elemTarget.attr('dropdown-close') != null) {
        return ATTR_CLOSE.SELF;
      }

      $elemTarget = $elemTarget.parent();
    }

    return ATTR_CLOSE.NONE;
  }


  _onMouseDown($event) {
    this.$elemTarget = angular.element($event.target);

    // Don't close if the element is clicked that initially opened the dropdown
    if(this.$elemTarget.closest(this.dropdown.$elemPosition).length > 0) {
      return;
    }

    let $elemTargetDropdown = this.$elemTarget.closest('dropdown-deprecated');

    let isChild = $elemTargetDropdown.is(this.dropdown.$elem);
    let isEmbeddedChild = false;
    this.dropdown.embeds.forEach(id => {
      let dropdown = this.dropdownService.get(id);
      if(dropdown && $elemTargetDropdown.is(dropdown.$elem)) {
        isEmbeddedChild = true;
      }
    });

    if(!isChild && !isEmbeddedChild) {
      this.$scope.$evalAsync(() => this.dropdownService.hide(this.dropdown.id));
    }
  }


  _onTouchStart() {
    $document.on('touchmove', this._onTouchMove);
    $document.on('touchend', this._onTouchEnd);
    // Do not close the dropdown. The user may be scrolling.
  }

  _onTouchMove() {
    $document.off('touchmove', this._onTouchMove);
    $document.off('touchend', this._onTouchEnd);
  }

  _onTouchEnd($event) {
    $document.off('touchmove', this._onTouchMove);
    $document.off('touchend', this._onTouchEnd);
    this._onClick($event);
  }
}


export default {
  controller: DropdownController,
  controllerAs: 'dropdownCtrl',
  template,

  bindings: {
    dropdown: '<',
  }
};
