<div *ngIf="!isReady"><loading></loading></div>

<div *ngIf="isReady">
  <div
    *ngIf="connectedProviderId && isUnauthorized"
    class="mb-5 p field field--error"
  >
    <field-icon [type]="'error'"></field-icon>
    <div>
      <span *ngIf="connectedProviderId !== 'scheduling_teamleader'" i18n>
        We couldn't authorize your calendar. Perhaps you have not given all the required permissions?</span>
      <span *ngIf="connectedProviderId === 'scheduling_teamleader'" i18n>
        We couldn't authorize your calendar. Make sure you have enabled Calendar access in Teamleader Focus.</span>
    </div>
  </div>

  <a
    *ngFor="let provider of providers; trackBy:providerTrackBy"
    class="calendar-connect__card a-plain"
    [ngClass]="{
      'disabled': connectedProviderId,
      'calendar-connect__card--unavailable': connectedProviderId && connectedProviderId !== provider.id
    }"
    [attr.href]="connectedProviderId ? null : provider.login"
    (click)="trackProviderClick(provider)"
  >
    <img class="calendar-connect__provider-logo" [src]="provider.logo">

    <div class="d-flex flex-column">
      <div>
        {{ provider.title }}
      </div>
      <div
        [ngClass]="{
          'text-success-400': !isUnauthorized,
          'text-error-400': isUnauthorized
        }"
        class=""
        *ngIf="connectedProviderId === provider.id"
      >
        {{ isConnectedString }}
      </div>
    </div>

    <div
      *ngIf="connectedProviderId === provider.id && !isUnauthorized"
      class="btn btn--secondary ml-auto"

      [dialogIcon]="{ name: 'close' }"
      dialogTitle="Disconnect calendar provider"
      i18n-dialogTitle
      dialog="Are you sure you want to disconnect your calendar provider?"
      i18n-dialog
      dialogConfirmText="Disconnect"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--error"
      (dialogConfirmed)="disconnect()"
    >
      <svg-icon class="btn__icon" [svgName]="'close'"></svg-icon>
      <span i18n>Disconnect</span>
    </div>
    <a
      *ngIf="connectedProviderId === provider.id && isUnauthorized"
      class="btn btn--secondary ml-auto"
      (click)="retry()"
    >
      <svg-icon class="btn__icon" [svgName]="'refresh'"></svg-icon>
      <span i18n>Retry</span>
    </a>
  </a>
</div>
