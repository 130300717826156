export enum PaymentOption {
  NO_PAYMENT = 'no_payment',
  PAYMENT_NECESSARY = 'payment_necessary'
}


// duplicate of SchedulingState Enum in scheduling/models.py
export enum SchedulingState {
  NEW = 'new',                     // User has not set up their calendar connection
  AUTHENTICATED = 'authenticated', // User has authenticated with the provider
  AUTHORIZED = 'authorized',       // User has given enough permissions
  CONFIGURED = 'configured',       // User has fully configured their settings
  BOOKABLE = 'bookable',           // User has active appointment types
  EXPIRED = 'expired',             // User's authentication has expired
  DISCONNECTED = 'disconnected',   // User has disconnected their calendar provider
}
