import './angularjs-init';
import './gettext';
import './filters';


// Components
import './accessLevel';
import './chromeExtension';
import './contextMenu';
import './cookieNotice';
import './copyText';
import './dropdown';
import './hiddenSecret';
import './hints';
import './inlineEdit';
import './loading';
import './modal';
import './notification';
import './profileImage';
import './searchUser';
import './tooltip';
import './wizard';
import './locale';


// Services
import Config from './config';

import ThrottleFactory from './throttle.factory';
import DebounceFactory from './debounce.factory';
import IntervalIfVisibleFactory from './intervalIfVisible.factory';

import ApiService from './api.service';
import FocusService from './focus.service';
import RequestService from './request.service';
import LanguageService from './language.service';
import ShortcutService from './shortcut.service';
import UsageTrackingService from './usageTracking.service';
import ModelFactory from './modelFactory.service';

import AutoHeightDirective from './autoHeight.directive';
import BindCompileDirective from './bindCompile.directive';
import ConfirmationDropdownDirective from './confirmationDropdown.directive';
import EnterBlurDirective from './enterBlur.directive';
import FocusOnAddDirective from './focusOnAdd.directive';
import FormInfoDirective from './formInfo.directive';
import IconExternalComponent from './iconExternal.component';
import IfDeployEnvDirective from './ifDeployEnv.directive';
import InputAutoSizeDirective from './inputAutoSize.directive';
import OnDragDirective from './onDrag.directive';
import PreventDefaultDirective from './preventDefault.directive';
import SelectOnFocusDirective from './selectOnFocus.directive';
import StopPropagationDirective from './stopPropagation.directive';
import SvgDirective from './svg.directive';
import StickyDirective from './sticky.directive';
import ValueWhileSubmittingDirective from './valueWhileSubmitting.directive';

// Extend forms functionality
import ChangeWarningDirective from './changeWarning.directive';
import CharacterCountDirective from './characterCount.directive';
import ColorInputDirective from './colorInput.directive';
import DefaultValueDirective from './defaultValue.directive';
import EnabledWarningDirective from './enabledWarning.directive';
import HtmlPreviewMailDirective from './htmlPreviewMailDirective';
import ImageInputController from './imageInput/imageInput.controller';
import ImageInputDirective from './imageInput/imageInput.directive';
import MarkDownPreviewDirective from './markdownPreview.directive';
import PhoneNumberDirective from './phoneNumber.directive';

import InitRun from './init.run';


angular
  .module('vecteraUtil')

  .config(Config)
  .run(InitRun)

  .factory('throttle', ThrottleFactory)
  .factory('debounce', DebounceFactory)
  .factory('intervalIfVisible', IntervalIfVisibleFactory)

  .service('apiService', ApiService)
  .service('focusService', FocusService)
  .service('requestService', RequestService)
  .service('languageService', LanguageService)
  .run(['languageService', angular.noop])  // Set the interface language
  .service('shortcutService', ShortcutService)
  .service('usageTrackingService', UsageTrackingService)
  .run(['usageTrackingService', angular.noop])
  .service('modelFactory', ModelFactory)

  .directive('autoHeight', AutoHeightDirective)
  .directive('bindCompile', BindCompileDirective)
  .directive('confirmationDropdown', ConfirmationDropdownDirective)
  .directive('enterBlur', EnterBlurDirective)
  .directive('focusOnAdd', FocusOnAddDirective)
  .directive('formInfo', FormInfoDirective)
  .component('iconExternal', IconExternalComponent)
  .directive('ifDeployEnv', IfDeployEnvDirective)
  .directive('inputAutoSize', InputAutoSizeDirective)
  .directive('onDrag', OnDragDirective)
  .directive('preventDefault', PreventDefaultDirective)
  .directive('selectOnFocus', SelectOnFocusDirective)
  .directive('stopPropagation', StopPropagationDirective)
  .directive('svg', SvgDirective)
  .directive('sticky', StickyDirective)
  .directive('valueWhileSubmitting', ValueWhileSubmittingDirective)


  // Extend forms functionality
  .directive('changeWarning', ChangeWarningDirective)
  .directive('characterCount', CharacterCountDirective)
  .directive('colorInput', ColorInputDirective)
  .directive('defaultValue', DefaultValueDirective)
  .directive('enabledWarning', EnabledWarningDirective)
  .directive('htmlPreviewMail', HtmlPreviewMailDirective)
  .directive('imageInput', ImageInputDirective)
  .controller('ImageInputCtrl', ImageInputController)
  .directive('markdownPreview', MarkDownPreviewDirective)
  .directive('phoneNumber', PhoneNumberDirective);
