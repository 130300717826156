export function isString(x) {
  return Object.prototype.toString.call(x) === '[object String]';
}


export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export function removePrefix(string, prefix) {
  if(string.startsWith(prefix)) {
    string = string.substring(prefix.length);
  }
  return string;
}

export function removeSuffix(string, suffix) {
  if(string.endsWith(suffix)) {
    string = string.substring(0, string.length - suffix.length);
  }
  return string;
}


export function escapeHTML(unsafe) {
  return unsafe.replace(/[&<>"']/g, function(m) {
    switch(m) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      case '\'':
        return '&#039;';
    }
  });
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}


export function slugify(string) {
  // Loosely based on django.utils.text.slugify
  if(string == null) {
    return '';
  }
  // Regex should not filter out accented symbols like é or ü
  return string
    .replace(new RegExp('[^\\p{L}\\p{M}\\s\\d-]', 'gu'), '')
    .trim()
    .replace(/[-\s]+/gu, '-')
    .toLowerCase();
}


export function wrapInIFrame(string) {
  return `<iframe style="width: 100%; height: 600px;" src="${string}" frameborder="0"></iframe>`;
}



/**
 * Translate a string with a singular and plural variant. API chosen to mimic Python's ngettext,
 * even though this doesn't scale to all languages.
 */
export function nLocalize(singular, plural, count) {
  if(count === 1) {
    return singular;
  } else {
    return plural;
  }
}
