import { EventEmitter, errors, format, array, color } from 'utils/util';

function createColorPalette() {
  let hues = [0, 30, 60, 120, 240, 300];
  let lightnessGrey =  [0 , 17, 33, 50, 67, 83, 100];  // eslint-disable-line comma-spacing
  let lightnessColor = [50, 20, 30, 40, 60, 70, 80 ];  // eslint-disable-line array-bracket-spacing

  return [
    lightnessGrey.map(lightness => getPaletteColor({ h: 0, s: 0, l: lightness / 100 })),
    ...hues.map(hue =>
      lightnessColor.map(lightness => getPaletteColor({ h: hue, s: 1, l: lightness / 100 }))
    ),
  ];
}

function getPaletteColor(hsl) {
  let rgb = color.hslToRgb(hsl);
  return `rgb(${Math.round(rgb.r)},${Math.round(rgb.g)},${Math.round(rgb.b)})`;
}

// These need to be in the rgb format, so FabricRenderer can add opacity for the marker.
const COLORS = createColorPalette();
const BLACK = 'rgb(26,28,32)';  // Same as $text-color
const YELLOW = 'rgb(250,204,50)';  // Same as $logoyellow

const SYMBOL_COLLECTION = [
  {
    tooltip: gettext('Math symbols'),
    icon: 'utils/icons/tl/24x24_infinity_outline.svg',
    symbols: [
      '\\int ', '\\sqrt{}', '\\sqrt[n]{}', '\\sum', '\\prod',
      '\\infty', '\\pi', '\\div', '\\pm', '\\geq', '\\leq', '\\neq',
      '\\approx', '\\forall', '\\exists', '\\in', '\\times', '\\leftarrow',
      '\\rightarrow', '\\Leftrightarrow', 'ℕ', 'ℤ', 'ℚ', 'ℝ',
    ],
  },
  {
    tooltip: gettext('Lowercase Greek'),
    icon: 'utils/icons/tl/24x24_greek_lowercase_outline.svg',
    symbols: [
      '\\alpha', '\\beta', '\\gamma', '\\delta', '\\epsilon', '\\zeta', '\\eta',
      '\\theta', '\\kappa', '\\lambda', '\\mu', '\\nu', '\\xi', '\\pi', '\\rho',
      '\\sigma', '\\tau', '\\upsilon', '\\phi', '\\chi', '\\psi', '\\omega',
    ],
  },
  {
    tooltip: gettext('Uppercase Greek'),
    icon: 'utils/icons/tl/24x24_greek_uppercase_outline.svg',
    symbols: [
      '\\Gamma', '\\Delta', '\\Theta', '\\Lambda', '\\Xi', '\\Pi', '\\Sigma', '\\Upsilon',
      '\\Phi', '\\Psi', '\\Omega',
    ],
  },
];


const OPTIONS = Object.freeze({
  pencilColor: COLORS,
  markerColor: COLORS,
  formulaColor: COLORS,
  textColor: COLORS,
  geometricColor: COLORS,

  pencilSize: [0.5, 1, 2, 3, 6],
  markerSize: [6, 12, 24],
  geometricSize: [0.5, 1, 2, 3, 6],
  geometricShape: {
    'none':      'utils/icons/tl/24x24_geometric_outline.svg',
    'line':      'utils/icons/tl/24x24_line_outline.svg',
    'arrow':     'utils/icons/tl/24x24_arrow_long_right_outline.svg',
    'rectangle': 'utils/icons/tl/24x24_rectangle_outline.svg',
    'ellipse':   'utils/icons/tl/24x24_elipse_outline.svg',
    'triangle':  'utils/icons/tl/24x24_triangle_outline.svg',
  },

  textSize: [6, 8, 10, 12, 14, 18, 22, 30, 40],
  textBold: [false, true],
  textItalic: [false, true],
  textUnderline: [false, true],

  tool: ['cursor', 'pan', 'pencil', 'marker', 'eraser', 'geometric', 'text', 'formula'],
  formatTool: [null, 'pencil', 'marker', 'eraser', 'geometric', 'text', 'formula'],
});


const DEFAULT_OPTIONS = {
  pencilColor: BLACK,
  markerColor: YELLOW,
  formulaColor: BLACK,
  textColor: BLACK,
  geometricColor: BLACK,
  pencilSize: 2,
  markerSize: 12,
  geometricSize: 2,
  geometricShape: 'none',

  textSize: 14,
  textBold: false,
  textItalic: false,
  textUnderline: false,

  tool: 'cursor',
  formatTool: null,
};


const EVENTS = Object.freeze(
  ['undo', 'redo', 'formula'].concat(Object.keys(DEFAULT_OPTIONS))
);



export default class ToolService {
  static get $inject() {
    return [
      'shortcutService',
    ];
  }

  constructor(
    shortcutService
  ) {
    this._bind();

    EventEmitter.setup(this, EVENTS);

    this.shortcutService = shortcutService;

    this.selected = Object.assign({}, DEFAULT_OPTIONS);
    this.undoAvailable = false;
    this.redoAvailable = false;
    this.symbolCollectionForDropdown = null;

    this.shortcutService.on('ctrl+z', this.undo);
    this.shortcutService.on('ctrl+y ctrl+shift+z', this.redo);
    this.shortcutService.on('ctrl+b', this.toggle.bind(this, 'textBold'));
    this.shortcutService.on('ctrl+i', this.toggle.bind(this, 'textItalic'));
    this.shortcutService.on('ctrl+u', this.toggle.bind(this, 'textUnderline'));
    // Force preventDefault()
    this.shortcutService.on('Backspace', angular.noop);
  }

  _bind() {
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
    this.toggle = this.toggle.bind(this);
  }


  getToolColor(tool) {
    let property = this.getToolColorName(tool);
    return this.selected[property];
  }

  getToolColorName(tool) {
    return tool + 'Color';
  }

  /**
   * Get all supported sizes of a tool from the options object
   *
   * @param {string} tool
   * @returns {[number]}
   */
  getToolSizes(tool) {
    let property = this._getToolSizeName(tool);
    return this.options[property];
  }

  getSelectedToolSize(tool) {
    let property = this._getToolSizeName(tool);
    return this.selected[property];
  }

  /**
   * Calculate the size of a tool, relative to its maximum value
   *
   * @param {string} tool the name name of a tool, of which exists a {tool}Size entry in OPTIONS
   * @param {number} size the toolsize to normalize
   * @returns {number} a float from 0 to 1
   */
  getNormalizedToolSize(tool, size) {
    let property = this._getToolSizeName(tool);
    return size / Math.max(...this.options[property]);
  }

  /**
   * Helper function to return the toolsize parameter of a given tool. Necessary because formula
   * uses the textSize parameter.
   *
   * @param {string} tool
   * @returns {string}
   */
  _getToolSizeName(tool) {
    if(tool === 'formula') {
      return 'textSize';
    } else {
      return tool + 'Size';
    }
  }

  getTranslatedTooltip(tooltip) {
    return gettextCatalog.getString(tooltip);
  }

  get options() {
    return OPTIONS;
  }
  get colors() {
    return COLORS;
  }
  get default() {
    return DEFAULT_OPTIONS;
  }
  get symbolCollections() {
    return SYMBOL_COLLECTION;
  }

  /**
   * Increase textSize parameter to the next available OPTION.textSize value
   */
  incrementTextSize() {
    let newTextSize = array.getNextIfExists(this.options.textSize, this.selected.textSize);
    this.setToolSize('text', newTextSize);
  }

  /**
   * Decrease textSize parameter to the next available OPTION.textSize value
   */
  decrementTextSize() {
    let newTextSize = array.getPrevIfExists(this.options.textSize, this.selected.textSize);
    this.setToolSize('text', newTextSize);
  }

  setToolSize(tool, value) {
    let property = this._getToolSizeName(tool);
    this.set(property, value);
  }

  set(key, value, apply) {
    if(apply == null) {
      apply = true;
    }
    if(!(key in this.selected)) {
      throw new errors.InvalidArgumentError(format(
        'Unknown tools option:', key));
    }
    if(value == null) {
      value = DEFAULT_OPTIONS[key];
    }

    if(value !== this.selected[key]) {
      this.selected[key] = value;
      this.emit(key, value, apply);

      if(key === 'tool') {
        this.set('formatTool', array.has(OPTIONS.formatTool, value) ? value : null);
      }
    }
  }

  toggle(key) {
    this.set(key, !this.selected[key]);
  }


  addFormula(latex) {
    this.emit('formula', latex);
  }


  undo() {
    this.emit('undo');
  }

  setUndoAvailable(undoAvailable) {
    this.undoAvailable = undoAvailable;
  }


  redo() {
    this.emit('redo');
  }

  setRedoAvailable(redoAvailable) {
    this.redoAvailable = redoAvailable;
  }
}
