<div *ngIf="!hasAppointmentTypes">
  <p *ngIf="isFreeUser" class="pp" i18n>
    What type of meetings would customers book with you?
    As a free user, you can only create and use 1 meeting type.
    Start from one of these templates, you can edit it later.
  </p>

  <p *ngIf="!isFreeUser" class="pp" i18n>
    What type of meetings would customers book with you?
    Start from these templates, you can edit them later.
  </p>
</div>

<div
  *ngIf="hasAppointmentTypes"
  class="mb-5 p field field--info"
>
  <field-icon [type]="'info'"></field-icon>
  <div>
    <span i18n>
      You seem to already have some existing meeting types, which means you're all set!
      You can edit these meeting types in the <a href="{{ appointmentTypeUrl }}">meeting type overview</a>
    </span>
  </div>
</div>


<div
  *ngFor="let at of defaultAppointmentTypes"
  class="card card--sm"
  [ngClass]="{
    'clickable': !hasAppointmentTypes,
    'appointment-type-init__card--unavailable': hasAppointmentTypes
  }"
  (click)="onCardClick(at)"
>
<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex flex-column mr-3">
    <div class="p">
      <span
        *ngFor="let loc of at.locations"
        class="badge mr-2"
        [ngClass]="{
          'badge--info': loc === AppointmentLocation.ONLINE,
          'badge--success': loc === AppointmentLocation.OFFLINE,
          'badge--warning': loc === AppointmentLocation.PHONE_CALL
        }"
      >
       {{ locationLabels[loc] }}
      </span>
    </div>
    <div class="text-h2">{{ at.name }}</div>
  </div>


  <div
    class="appointment-type-init__indicator"
    *ngIf="!at.selected"
  >
    <svg-icon class="icon" [svgName]="'add'"></svg-icon>
  </div>

  <div
    class="appointment-type-init__indicator appointment-type-init__indicator--selected"
    *ngIf="at.selected"
  >
    <svg-icon class="icon" [svgName]="'checkmark'"></svg-icon>
  </div>
</div>
