import { Component } from '@angular/core';

import { SmartSummaryService, State } from '../../../../smart-summary/smart-summary.service';


@Component({
  selector: 'smart-summary',
  templateUrl: './smart-summary.component.html',
  styleUrls: ['./smart-summary.component.scss'],
})
export class SmartSummaryComponent {
  constructor(private smartSummaryService: SmartSummaryService) {}

  public get state(): State {
    return this.smartSummaryService.state;
  }

  public startRecording(): void {
    this.smartSummaryService.startRecording();
  }

  public stopRecording(): void {
    this.smartSummaryService.stopRecording();
  }

  public clear(): void {
    this.smartSummaryService.clear();
  }
}
