import { Component, Input } from '@angular/core';
import { Appointment } from 'scheduling/models/Appointment';
import { locationLabels } from 'scheduling/utils/location-badges';

@Component({
  selector: 'appointment-details',
  templateUrl: './appointment-details.component.html',
})
export class AppointmentDetailsComponent {
  @Input() appointment: Appointment;
  public Location = Location;

  constructor(
  ) {
  }

  get appointmentLocation(): string {
    return locationLabels[this.appointment.location];
  }
}
