import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UtilsModule } from 'utils/utils.module';
import { SidebarProfileComponent } from './sidebar-profile.component';


@NgModule({
  imports: [
    BrowserModule,
    UtilsModule,
  ],
  declarations: [
    SidebarProfileComponent,
  ],
  exports: [
    SidebarProfileComponent,
  ],
})
export class SidebarProfileModule {}
