import { array, EventEmitter, format } from 'utils/util';
import Message from './Message';
import SystemMessage from './SystemMessage';



export default class ChatBox {
  constructor(
    $sce,
    userService,
    id
  ) {
    this.id = id;
    this.$sce = $sce;
    this.userService = userService;
    EventEmitter.setup(this, ['typing', 'message', 'addInput', 'reset']);

    this.maxMessageId = 0;
    this.messages = [];
    this.input = '';

    this.typingUsers = [];
    this.typingUsersStr = '';
    this.typingUserDebounces = {};

    this._glued = true; // automatically scroll down the chat on new message
    this.hasNewMessages = false;
  }


  get glued() {
    return this._glued;
  }
  set glued(glued) {
    if(glued === this.glued) {
      return;
    }

    this._glued = glued;
    this.hasNewMessages = false;
  }

  scrollDown() {
    this.glued = true;
  }


  addToInput(value) {
    this.emit('addInput', value);
  }


  addSystemMessage(user, action) {
    let datetime = new Date();

    let message = new SystemMessage(user, action, datetime);
    this.messages.push(message);
  }


  addMessage(id, session, content, datetime, synced) {
    if(session.isLocal) {
      this.glued = true;
    }
    if(!this.glued) {
      this.hasNewMessages = true;
    }

    this.removeTyping(session.user);

    let message = new Message(this.$sce, id, session, content, datetime, synced);
    this.messages.push(message);
    this.emit('message', message);
  }


  addMessageFromInput() {
    let content = this._sanitize(this.input);
    if(!content) {
      return;
    }

    let id = this.userService.getUniqueId(++this.maxMessageId);
    let session = this.userService.mySession;
    let datetime = new Date();

    this.addMessage(id, session, content, datetime, false);
    this.input = '';
  }


  removeMessage(id) {
    for(let i = this.messages.length - 1; i >= 0; i--) {
      if(this.messages[i].id === id) {
        this.messages.splice(i, 1);
        break;
      }
    }
  }


  _sanitize(content) {
    return format(content)
      .trim();
  }

  /**************************
   * "user is typing" logic *
   **************************/

  addTyping(user) {
    if(!array.has(this.typingUsers, user)) {
      this.typingUsers.push(user);
      this._updateTypingUsersStr();
      this.emit('typing', this.typingUsers);
    }

    if(!this.typingUserDebounces[user.id]) {
      let removeTypingDebounced = debounce(this.removeTyping.bind(this, user), 5000);
      this.typingUserDebounces[user.id] = removeTypingDebounced;
    }
    this.typingUserDebounces[user.id]();
  }


  removeTyping(user) {
    let index = this.typingUsers.indexOf(user);
    if(index !== -1) {
      this.typingUsers.splice(index, 1);
      this._updateTypingUsersStr();
      this.emit('typing', this.typingUsers);
    }
  }


  _updateTypingUsersStr() {
    let names = this.typingUsers
      .filter(user => !user.isMe)
      .map(user => user.shortName);
    let numTyping = names.length;

    switch(numTyping) {
      case 0:
        this.typingUsersStr = '';
        break;

      case 1:
        this.typingUsersStr = format('%s is typing...', names[0]);
        break;

      default:
        this.typingUsersStr = format(
          '%s and %s are typing...',
          names.slice(0, numTyping - 1).join(', '),
          names[numTyping - 1]);
        break;
    }
  }

  reset() {
    this.messages = [];
    this.emit('reset');
  }
}
