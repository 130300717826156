import template from './cobrowseTile.html?raw';


import { platform, set } from 'utils/util';

import ContentTileController from '../content/contentTile.controller';
import normalizeWheel from 'utils/util/normalizeWheel';
import { ZOOM_LEVELS } from './CobrowseTile';


const MOUSE_EVENT_PROPERTIES = Object.freeze([
  'type',
  'deltaX', 'deltaY',
  'ctrlKey', 'metaKey', 'altKey', 'shiftKey',
  'which', 'button', 'buttons', 'detail',
]);

const KEY_EVENT_PROPERTIES = Object.freeze([
  'type',
  'key', 'keyCode', 'charCode', 'code',
  'ctrlKey', 'metaKey', 'altKey', 'shiftKey'
]);
const KEYS_PREVENT_DEFAULT = set.freeze(['F5', 'Tab', 'Backspace', 'Delete']);
const KEYS_ILLEGAL = set.freeze(['Dead', 'Unidentified']);

const THROTTLE_DURATION_LOCAL = 50;
const THROTTLE_DURATION_REMOTE = 300;
const RESET_TOTAL_ZOOM_DELTA_TIMEOUT = 1000;



class CobrowseTileController extends ContentTileController {
  static get $inject() {
    return [
      'cobrowseService',
      'shortcutService',
      'snapshotService',
    ].concat(ContentTileController.$inject);
  }

  constructor(
    cobrowseService,
    shortcutService,
    snapshotService,
    ...args
  ) {
    super(...args);

    this.cobrowseService = cobrowseService;
    this.shortcutService = shortcutService;
    this.snapshotService = snapshotService;

    this.hasControlPermission = false;
    this.hasFocus = false;

    this.$elemVideo = null;
    this.$elemAddressBar = null;
    this.url = '';

    this.dragging = false;
    this.mouseIsOver = false;
    this.lastTouchEvent = null;

    this.totalScroll = {
      x: 0,
      y: 0,
    };
    this.totalZoomDelta = 0;
    this.resetTotalZoomDeltaTimeout = null;

    let throttleDuration = this.isLocal ? THROTTLE_DURATION_LOCAL : THROTTLE_DURATION_REMOTE;
    this._sendMouseEventThrottled = throttle(this._sendMouseEvent, throttleDuration, true);
    this._sendWheelThrottled = throttle(this._sendWheelEvent, throttleDuration, true);

    this._specialKeyHandlers = {
      'ctrl+l': this._selectUrl,
      'ctrl+c': this._doCopy,
      'ctrl+x': this._doCut,
      'ctrl+v': this._doPaste,
    };
  }


  $onInit() {
    super.$onInit();

    $timeout(() => {
      let $elemTile = this.$elem.find('.tile:not(.tile--inactive)');
      this.$elemVideo = $elemTile.find('.cobrowse-tile__video');
      this.$elemAddressBar = $elemTile.find('.content-tile__input-without-border');

      $document.on('mousedown',  this._onMouseDown);
      $document.on('touchstart', this._onMouseDown);
      $document.on('mousemove', this._onMouseMove);
      $document.on('touchmove', this._onMouseMove);
      $document.on('mouseup',  this._onMouseUp);
      $document.on('touchend', this._onMouseUp);

      $document.on('keydown',  this._onKey);
      $document.on('keypress', this._onKey);
      $document.on('keyup',    this._onKey);

      this.$elemVideo.on('wheel', this._onWheel);

      this.tile.on('url', this._onTileUrl);
      this._onTileUrl();
    });
  }

  $onDestroy() {
    this.tile.off('url', this._onTileUrl);
    super.$onDestroy();
  }


  get zoomLevelStr() {
    return Math.round(this.tile.zoomLevel * 100) + '%';
  }

  get userIsOfflineString() {
    return gettextCatalog.getString(
      '{{ name }} seems to be offline...',
      { name: this.tile.user.shortName }
    );
  }


  _sendEvent(event) {
    let eventMinimal = Object.assign({}, event);
    for(let property in eventMinimal) {
      if(!eventMinimal[property]) {
        delete eventMinimal[property];
      }
    }
    this.cobrowseService.sendMessageToPage(this.tile, eventMinimal);
  }



  /******************
   * Header buttons *
   ******************/

  takeSnapshot() {
    let stream = this.tile.streams.video;
    if(!stream) {
      return;
    }

    this.snapshotService.takeSnapshot(stream);
  }

  remove() {
    this.cobrowseService.remove(this.tile);
  }

  forward() {
    this.cobrowseService.sendMessageToPage(this.tile, { type: 'forward' });
  }

  back() {
    this.cobrowseService.sendMessageToPage(this.tile, { type: 'back' });
  }

  reload() {
    this.cobrowseService.sendMessageToPage(this.tile, { type: 'reload' });
  }

  zoomIn() {
    this._doZoom(-1);
  }

  zoomOut() {
    this._doZoom(1);
  }



  /***************
   * Address bar *
   ***************/

  _onTileUrl() {
    this.url = this.tile.url;
  }


  _selectUrl() {
    this.hasFocus = false;
    this.$elemAddressBar.focus();
    this.$elemAddressBar.select();
  }


  setUrl() {
    let url = this.url;

    if(url) {
      if(!url.match('^https?://')) {
        url = 'http://' + url;
      }

      this.cobrowseService.sendMessageToPage(this.tile, {
        type: 'setUrl',
        url: url
      });
    }
  }



  /****************
   * Mouse events *
   ****************/

  _onMouseDown($event) {
    if($event.target === this.$elemVideo[0]) {
      this.hasFocus = true;
      this.dragging = true;

      this._sendMouseEvent($event);

    } else {
      this.hasFocus = false;
      this._sendEvent({
        type: 'blur'
      });
    }
  }


  _onMouseMove($event) {
    if(this.dragging || $event.target === this.$elemVideo[0] || this.mouseOver) {
      this._sendMouseEventThrottled($event);
    }
    if(this.dragging && $event.name === 'touchmove') {
      $event.preventDefault();
    }
    this.mouseIsOver = ($event.target === this.$elemVideo[0]);
  }


  _onMouseUp($event) {
    if(this.dragging) {
      this.dragging = false;
      this._sendMouseEvent($event);
    }
  }


  _sendMouseEvent($event) {
    if(!$event) {
      return;
    }

    // Cancel any throttled mousemove/touchmove events
    if(!($event.type === 'mousemove' || $event.type === 'touchmove')) {
      this._sendMouseEventThrottled(null);
    }

    let client;
    if($event.type.startsWith('mouse') || $event.type === 'wheel') {
      client = $event;

    } else if($event.type !== 'touchend') {
      client = $event.touches[0];
      this.lastTouchEvent = $event;

    } else if(this.lastTouchEvent) {
      client = this.lastTouchEvent.touches[0];
      this.lastTouchEvent = null;
    }
    if(client == null) {
      return;
    }

    let videoRect = this.$elemVideo[0].getBoundingClientRect();
    let simEvent = {
      x: (client.clientX - videoRect.left) / videoRect.width,
      y: (client.clientY - videoRect.top ) / videoRect.height,
    };
    MOUSE_EVENT_PROPERTIES.forEach(property => simEvent[property] = $event[property]);

    this._sendEvent(simEvent);
  }



  /*******************
   * Scroll and zoom *
   *******************/

  _onWheel($event) {
    let delta = normalizeWheel($event);

    if($event.ctrlKey) {
      $event.preventDefault();

      this.totalZoomDelta += delta.y;
      if(Math.abs(this.totalZoomDelta) > 50) {
        this._doZoom(Math.sign(this.totalZoomDelta));
        this._resetTotalZoomDelta();
      } else {
        clearTimeout(this.resetTotalZoomDeltaTimeout);
        this.resetTotalZoomDeltaTimeout = setTimeout(
          this._resetTotalZoomDelta, RESET_TOTAL_ZOOM_DELTA_TIMEOUT);
      }

    } else {
      this.totalScroll.x += delta.x;
      this.totalScroll.y += delta.y;
      this._sendWheelThrottled($event);
    }
  }


  _sendWheelEvent($event) {
    $event.deltaX = this.totalScroll.x;
    $event.deltaY = this.totalScroll.y;
    this.totalScroll.x = 0;
    this.totalScroll.y = 0;
    this._sendMouseEvent($event);
  }


  _doZoom(deltaY) {
    let indexCurrent = this._getCurrentZoomLevelIndex();

    let direction = -Math.sign(deltaY);
    let indexNew = platform(0, indexCurrent + direction, ZOOM_LEVELS.length - 1);
    let zoomLevel = ZOOM_LEVELS[indexNew];

    this.cobrowseService.sendMessageToPage(this.tile, {
      type: 'setZoomLevel',
      zoomLevel: zoomLevel,
    });
  }


  _getCurrentZoomLevelIndex() {
    let indexCurrent = ZOOM_LEVELS.length - 1;
    for(let i = 1; i < ZOOM_LEVELS.length; i++) {
      if(this.tile.zoomLevel === ZOOM_LEVELS[i]) {
        indexCurrent = i;
        break;

      } else if(this.tile.zoomLevel < ZOOM_LEVELS[i]) {
        let diffLow = this.tile.zoomLevel - ZOOM_LEVELS[i - 1];
        let diffHigh = ZOOM_LEVELS[i] - this.tile.zoomLevel;
        indexCurrent = diffLow < diffHigh ? i - 1 : i;
        break;
      }
    }
    return indexCurrent;
  }


  _resetTotalZoomDelta() {
    this.totalZoomDelta = 0;
  }



  /*******************
   * Keyboard events *
   *******************/

  _onKey($event) {
    if(!this.hasFocus || KEYS_ILLEGAL.has($event.key)) {
      return;
    }

    // Don't preventDefault() on regular keystrokes: this stops the keypress event from firing
    if($event.ctrlKey || $event.metaKey || $event.altKey || KEYS_PREVENT_DEFAULT.has($event.key)) {
      $event.preventDefault();
    }
    $event.stopPropagation();

    let eventCode = this.shortcutService.getEventCodeFromEvent($event);
    if(this._specialKeyHandlers[eventCode]) {
      if($event.type === 'keydown') {
        this._specialKeyHandlers[eventCode]();
      }
    } else {
      this._sendKeyEvent($event);
    }
  }


  _getKeyCombo(event) {
    let key = event.key;
    let modifier = '';

    if(event.ctrlKey && key !== 'Control') {
      modifier += 'Ctrl+';
    }
    if(event.metaKey && key !== 'Meta') {
      modifier += 'Meta+';
    }
    if(event.altKey && key !== 'Alt') {
      modifier += 'Alt+';
    }
    if(event.shiftKey && key !== 'Shift') {
      modifier += 'Shift+';
    }

    return modifier + key;
  }


  _sendKeyEvent($event) {
    let simEvent = {};
    KEY_EVENT_PROPERTIES.forEach(property => simEvent[property] = $event[property]);
    this._sendEvent(simEvent);
  }


  _doCopy() {
    this.cobrowseService.doCopy(this.tile);
  }
  _doCut() {
    this.cobrowseService.doCut(this.tile);
  }
  _doPaste() {
    this.cobrowseService.doPaste(this.tile);
  }
}


export default {
  controller: CobrowseTileController,
  controllerAs: 'cobrowseTileCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
