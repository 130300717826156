import template from './videoViewportControls.html?raw';


/**
 * This component shows up in the top-right of the User Tile. It is a flexbox in which
 * each row is another flexbox that contains up to 3 items:
 *
 * | ZoomOut | ZoomStr | ZoomIn  |
 * |         |    Up   |         |
 * |   Left  |   Home  |  Right  |
 * |         |   Down  |         |
 *
 * - Up, Left, Home, Right and Down are only shown when shouldShowScroll is True
 * - ZoomOut, ZoomStr and Zoomin are only shown when shouldShowZoom is True
 */
class VideoViewportControlsComponent  {
  static get $inject() {
    return [
      '$scope',
      '$element',
    ];
  }

  constructor(
    $scope,
    $elem
  ) {
    this._bind();

    this.$scope = $scope;
    this.$elem = $elem;
  }

  $onInit() {
    this.$elem.find('[viewport-scroll-direction]')
      .on('mousedown touchstart', this._startScrollWithButton)
      .on('mouseup touchend', this._endScrollWithButton);
  }

  _bind() {
    this._startScrollWithButton = this._startScrollWithButton.bind(this);
    this._endScrollWithButton = this._endScrollWithButton.bind(this);
  }


  _startScrollWithButton($event) {
    $event.stopPropagation(); // dont let the drag handler see the event
    let direction = $($event.target).closest('.btn').attr('viewport-scroll-direction');
    this.tile.startContinuousScroll(direction);
  }

  _endScrollWithButton() {
    this.tile.stopContinuousScroll();
  }
}



export default {
  controller: VideoViewportControlsComponent,
  controllerAs: 'videoViewportControlsCtrl',
  template,

  bindings: {
    tile: '<',
    zoomLevelStr: '<',
    shouldShowZoom: '<',
    shouldShowScroll: '<',
  },
};
