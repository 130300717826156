import { Component } from '@angular/core';
import { bind } from 'utils/util';
import {
  Appointment,
  UserAppointment,
  OrganizationAppointment
} from 'scheduling/models/Appointment';
import { UrlService } from 'utils/url.service';
import { SchedulingState } from 'scheduling/variables';
import {
  DashboardScope, DataPageComponent
} from 'dashboard/data-page/data-page.component';


type SchedulingActionTarget = '_self' | '_blank';
class SchedulingAction {
  constructor(
    public text: string,
    public url: string,
    public target: SchedulingActionTarget = '_self',
  ) {}
}

@Component({
  selector: 'appointments',
  templateUrl: './appointments.component.html',
})
export class AppointmentsComponent extends DataPageComponent {
  public pastAppointments: Appointment[] = [];
  public upcomingAppointments: Appointment[] = [];

  constructor(
    public urlService: UrlService,
  ) {
    super();
    bind(this);
  }

  /**********************
   * Fetch appointments *
   **********************/

  override fetch() {
    const modelConfig = this.scope === DashboardScope.PERSONAL ?
      {
        model: UserAppointment,
        identifiers: { userId: this.requestUserService.user.id }
      } :
      {
        model: OrganizationAppointment,
        identifiers: { organizationId: this.requestUserService.user.organizationId }
      };

    return Promise.all([
      this.fetchUpcomingAppointments(modelConfig),
      this.fetchPastAppointments(modelConfig)
    ]);
  }


  private fetchUpcomingAppointments(modelConfig) {
    const apiConfig = {
      params: {
        filter: `isCancelled = false AND end > ${new Date().toISOString()}`,
        orderBy: 'start',
        perPage: 'all',
      }
    };
    return this.listCancelable(modelConfig, apiConfig).then(({ data: appointments }) => {
      this.upcomingAppointments = appointments as Appointment[];
    });
  }

  private fetchPastAppointments(modelConfig) {
    const apiConfig = {
      params: {
        filter: `isCancelled = false AND end <= ${new Date().toISOString()}`,
        orderBy: '-start',
      },
    };
    return this.listCancelable(modelConfig, apiConfig).then(({ data: appointments }) => {
      this.pastAppointments = appointments as Appointment[];
    });
  }


  get schedulingActions(): Record<SchedulingState, SchedulingAction> {
    return {
      new: new SchedulingAction(
        $localize `Get started: connect your calendar`,
        this.urlService.urls.connectScheduling,
      ),
      authenticated: new SchedulingAction(
        $localize `Authorize your calendar connection`,
        this.urlService.urls.connectScheduling,
      ),
      authorized: new SchedulingAction(
        $localize `Get started: set up your preferences`,
        this.urlService.urls.configureScheduling,
      ),
      configured: new SchedulingAction(
        $localize `Create your first meeting type`,
        this.urlService.urls.appointmentTypes,
      ),
      bookable: new SchedulingAction(
        $localize `Plan a meeting`,
        this.urlService.urls.planMeeting,
        '_blank',
      ),
      expired: new SchedulingAction(
        $localize `Reconnect your calendar`,
        this.urlService.urls.connectScheduling,
      ),
      disconnected: new SchedulingAction(
        $localize `Reconnect your calendar`,
        this.urlService.urls.connectScheduling,
      )
    };
  }

  get shouldShowTeamTeaser(): boolean {
    return (
      this.scope === DashboardScope.TEAM
      && this.requestUserService.user.organization.isSolo
    );
  }

  override get shouldShowTeamScope() {
    return (
      !this.requestUserService.user.organization.isSolo
      && (this.requestUserService.user.isAdmin
          || this.requestUserService.user.organization.allowEditAppointments)
    );
  }

  get schedulingAction(): SchedulingAction {
    const schedulingState: SchedulingState = this.requestUserService.user.schedulingConfig.state;
    return this.schedulingActions[schedulingState];
  }


  get inviteUrl(): string {
    return this.urlService.urls.teamMembers;
  }
}
