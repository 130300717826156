import { Injectable } from '@angular/core';
import { logger } from 'utils/util';

type FeatureFlags = Record<string, FeatureFlagValue>

export type FeatureFlagValue = boolean | string | number


@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {

  public featureFlags: FeatureFlags = {};

  constructor(
  ) {
    this.initService();
  }

  private async initService() {
    this.featureFlags = ANGULAR_SCOPE.featureFlags;
  }

  private getFeatureFlagValue(key: string): FeatureFlagValue {
    return this.featureFlags[key];
  }

  public isEnabled(key: string, defaultValue: boolean): boolean {
    const featureFlagValue = this.getFeatureFlagValue(key);
    if(featureFlagValue == null) {
      logger.error(`Feature flag ${key} not found`);
      return defaultValue;
    }

    if(typeof featureFlagValue !== 'boolean') {
      throw new Error('Feature flag is not a boolean');
    }

    return featureFlagValue;
  }

  public variant(key: string, defaultValue: string): string {
    const featureFlagValue = this.getFeatureFlagValue(key);
    if(featureFlagValue == null) {
      logger.error(`Feature flag ${key} not found`);
      return defaultValue;
    }

    if(typeof featureFlagValue !== 'string') {
      throw new Error('Feature flag is not a string');
    }

    return featureFlagValue;
  }

  public variantAsNumber(key: string, defaultValue: number): number {
    const featureFlagValue = this.getFeatureFlagValue(key);
    if(featureFlagValue == null) {
      logger.error(`Feature flag ${key} not found`);
      return defaultValue;
    }

    if(typeof featureFlagValue !== 'number') {
      throw new Error('Feature flag is not a number');
    }

    return featureFlagValue;
  }
}
