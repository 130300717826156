import template from './app.html?raw';

import { bind, Rect } from 'utils/util';

const SET_TEMPLATE_SAVING_FALSE_DEBOUNCE = 1000;

const TOUCH_EVENTS = 'touchend click';

class AppController {
  static get $inject() {
    return [
      '$scope',
      '$element',
      'browserService',
      'chatBoxService',
      'greetService',
      'meetingService',
      'sharingService',
      'tileRendererPhoneService',
      'tileService',
      'unloadService',
      'userService',
      'clearContentService',
      'siteService',
    ];
  }

  constructor(
    $scope,
    $elem,
    browserService,
    chatBoxService,
    greetService,
    meetingService,
    sharingService,
    tileRendererPhoneService,
    tileService,
    unloadService,
    userService,
    clearContentService,
    siteService
  ) {
    bind(this);

    this.$scope = $scope;
    this.$elem = $elem;
    this.browserService = browserService;
    this.chatBoxService = chatBoxService;
    this.greetService = greetService;
    this.meetingService = meetingService;
    this.sharingService = sharingService;
    this.tileRendererPhoneService = tileRendererPhoneService;
    this.tileService = tileService;
    this.unloadService = unloadService;
    this.userService = userService;
    this.clearContentService = clearContentService;
    this.siteService = siteService;

    this.shouldShowInterface = false;
    this._isSavingTemplate = false;

    this._onTouchEventThrottled = throttle(this._onTouchEvent, 200);
    this._setSavingTemplateFalseDebounced = debounce(
      () => this._setSavingTemplate(false),
      SET_TEMPLATE_SAVING_FALSE_DEBOUNCE
    );

    this.$scope.$watch('appCtrl.shouldShowPhoneApp', this._updateTouchEventListeners);
    window.addEventListener('orientationchange', () => {
      // Dirty: timeout = 1000. But the layout is not recalculated after a simple setTimeout or
      // requestAnimationFrame.
      $timeout(this._updateInterfaceSize, 1000);
    });
  }


  $onInit() {
    this.browserService.on('deviceType', this._updateTouchEventListeners);
    this.chatBoxService.get('main').on('message', this._showInterface);
    this._updateTouchEventListeners();
  }
  $onDestroy() {
    this.browserService.off('deviceType', this._updateTouchEventListeners);
    this.chatBoxService.get('main').off('message', this._showInterface);
    this.$elem.off(TOUCH_EVENTS, this._onTouchEventThrottled);
  }


  get shouldShowGreet() {
    return this.greetService.template != null;
  }
  get shouldShowApp() {
    return !this.shouldShowGreet;
  }
  get shouldShowDesktopApp() {
    return (
      this.shouldShowApp
      && this.browserService.deviceType === this.browserService.DeviceType.DESKTOP
    );
  }
  get shouldShowPhoneApp() {
    return this.shouldShowApp && !this.shouldShowDesktopApp;
  }
  get shouldShowDarkBackground() {
    return (
      this.tileRendererPhoneService.activeTiles.length > 0
      && !this.tileRendererPhoneService.activeContentTile
    );
  }


  _updateTouchEventListeners() {
    if(this.shouldShowPhoneApp) {
      this.$elem.on(TOUCH_EVENTS, this._onTouchEventThrottled);

      this.shouldShowInterface = false;
      $timeout(() => this._showInterface());
    } else {
      this.$elem.off(TOUCH_EVENTS, this._onTouchEventThrottled);
    }
  }


  _onTouchEvent($event) {
    let $elemTarget = angular.element($event.target);
    while($elemTarget.length > 0) {
      if($elemTarget.attr('js-disable-phone-interface-toggle') != null) {
        return;
      }
      $elemTarget = $elemTarget.parent();
    }

    this.$scope.$evalAsync(() => this._toggleInterface());
  }

  _toggleInterface(show) {
    if(show == null) {
      show = !this.shouldShowInterface;
    }
    if(show !== this.shouldShowInterface) {
      this.shouldShowInterface = show;
      this._updateInterfaceSize();
    }
  }
  _showInterface() {
    return this._toggleInterface(true);
  }
  _hideInterface() {
    return this._toggleInterface(false);
  }


  _updateInterfaceSize() {
    let interfaceSize = this._getInterfaceSize();
    this.tileRendererPhoneService.setInterfaceSize(interfaceSize);
  }

  _getInterfaceSize() {
    let size = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };

    if(this.shouldShowInterface) {
      let $elemHeader = this.$elem.find('.app__phone-header');
      if($elemHeader.length > 0) {
        let rectHeader = Rect.fromElem($elemHeader[0]);
        if(rectHeader.width > rectHeader.height) {
          size.top = rectHeader.height;
        } else {
          size.right = rectHeader.width;
        }
      }

      let $elemFooter = this.$elem.find('.app__phone-footer');
      if($elemFooter.length > 0) {
        let rectFooter = Rect.fromElem($elemFooter[0]);
        if(rectFooter.width > rectFooter.height) {
          size.bottom = rectFooter.height;
        } else {
          size.left = rectFooter.width;
        }
      }
    }

    return size;
  }


  get isSavingTemplate() {
    if(this.unloadService.isSaving) {
      this._setSavingTemplate(true);
      this._setSavingTemplateFalseDebounced();
    }
    return this._isSavingTemplate;
  }

  _setSavingTemplate(isSavingTemplate) {
    this._isSavingTemplate = isSavingTemplate;
  }

  showClearContentModal() {
    this.clearContentService.showModal(false);
  }

  get dataRetentionInfoText() {
    return gettextCatalog.getString(
      `Meeting room content like whiteboards and chat history will
      be saved in the meeting room after the meeting. <span {{attrs}}>Click here</span> to
      clear the content.`,
      {
        attrs: 'class="a" ng-click="appCtrl.showClearContentModal()"'
      }
    );
  }

  get subscriptionLink() {
    return this.siteService.site.subscriptionLink;
  }

  get proPlanName() {
    return this.siteService.site.proPlanName;
  }
}



export default {
  controller: AppController,
  controllerAs: 'appCtrl',
  template,
};
