export default class UsageTrackingService {
  static get $inject() {
    return [
      'requestUserService',
    ];
  }

  constructor(
    requestUserService
  ) {
    this.requestUserService = requestUserService;
  }

  createSegmentEvent(eventName, source, properties) {
    window.segmentTrack(eventName, source, properties);
  }
}
