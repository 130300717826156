import { Component } from '@angular/core';
import { IntegrationSyncService, State as SyncState } from './integration-sync.service';


@Component({
  selector: 'integration-sync',
  templateUrl: './integration-sync.component.html',
})
export class IntegrationSyncComponent {
  readonly SyncState = SyncState;

  constructor(
    public syncService: IntegrationSyncService,
  ) {
  }

  get state(): SyncState {
    return this.syncService.state;
  }

  get integrations(): string[] {
    return this.syncService.activeIntegrationNames();
  }
}
