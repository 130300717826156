import { array, bind, format, interval, logger } from 'utils/util';
import Tile from '../tiles/Tile';


const TIMING = {
  live: {
    throttle: 5 * 1000,
    interval: 15 * 1000,
  },
  nonLive: {
    throttle: 5 * 60 * 1000,
    interval: 5 * 60 * 1000,
  },
};


export default class MeetingSnapshotService {
  static get $inject() {
    return [
      'apiService',
      'meetingBroadcastService',
      'focusService',
      'meetingService',
      'requestUserService',
      'tileService',
      'userService',
    ];
  }

  constructor(
    apiService,
    meetingBroadcastService,
    focusService,
    meetingService,
    requestUserService,
    tileService,
    userService
  ) {
    bind(this);

    this.apiService = apiService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.focusService = focusService;
    this.meetingService = meetingService;
    this.requestUserService = requestUserService;
    this.tileService = tileService;
    this.userService = userService;

    this.activeContentTile = null;
    this.lastActiveWhiteboard = null;
    this.lastSnapshot = 0;
    this.isTakingSnapshot = false;

    meetingBroadcastService.afterInitialization().then(() => {
      this.tileService.on('draw', this.onTilesDraw);
      interval.setInterval(this.checkForSnapshot, this.timing.interval);
      this.checkForSnapshot();
    });
  }


  get isLiveSnapshotsEnabled() {
    return this.meetingService.settings.enableLiveSnapshots;
  }

  get timing() {
    if(this.isLiveSnapshotsEnabled) {
      return TIMING.live;
    } else {
      return TIMING.nonLive;
    }
  }

  get tileForSnapshot() {
    if(
      this.activeContentTile
      && (
        this.activeContentTile.type === Tile.Type.WHITEBOARD
        || this.isLiveSnapshotsEnabled
      )
    ) {
      return this.activeContentTile;
    } else {
      return this.lastActiveWhiteboardTile;
    }
  }

  get lastActiveWhiteboardTile() {
    let whiteboardTiles = Object.values(this.tileService.tiles)
      .filter(tile => tile.type === Tile.Type.WHITEBOARD);
    let lastActiveWhiteboardTile = array.max(whiteboardTiles, tile => tile.lastActiveDatetime);
    return lastActiveWhiteboardTile;
  }


  onTilesDraw() {
    let oldTileForSnapshot = this.tileForSnapshot;
    this.activeContentTile = this.tileService.activeContentTile;
    let newTileForSnapshot = this.tileForSnapshot;

    if(newTileForSnapshot === oldTileForSnapshot) {
      return;
    }
    if(oldTileForSnapshot) {
      this.unregisterTileListeners(oldTileForSnapshot);
    }
    if(newTileForSnapshot) {
      this.registerTileListeners(newTileForSnapshot);
    }

    this.checkForSnapshot();
  }

  registerTileListeners(tile) {
    if(tile.type === Tile.Type.WHITEBOARD) {
      tile.whiteboard.on('page', this.checkForSnapshot);
    }
  }

  unregisterTileListeners(tile) {
    if(tile.type === Tile.Type.WHITEBOARD) {
      tile.whiteboard.off('page', this.checkForSnapshot);
    }
  }


  getOldestHostSession() {
    let hostSessions = [...this.userService.joinedSessions]
      .filter(session => session.accessLevel.isHost);
    return array.min(hostSessions, session => session.dateJoined);
  }


  checkForSnapshot() {
    if(
      this.isTakingSnapshot
      || Date.now() - this.lastSnapshot < this.timing.throttle
      || this.getOldestHostSession() !== this.userService.mySession
    ) {
      return;
    }

    this.isTakingSnapshot = true;

    // The whiteboard 'page' event is emitted before the new viewport is set, so we wait before
    // taking the snapshot.
    $timeout(() => {
      if(this.tileForSnapshot) {
        return this.tileForSnapshot.takeMeetingSnapshot(300);
      } else {
        return {
          name: null,
          blob: null,
        };
      }
    })
      .then(snapshot => this.uploadSnapshot(snapshot))
      .catch(error => {
        logger.warn(error);
      })
      .finally(() => {
        this.isTakingSnapshot = false;
        this.lastSnapshot = Date.now();
      });
  }

  uploadSnapshot(snapshot) {
    let data = new FormData();
    data.append('snapshotName', snapshot.name || '');
    if(snapshot.blob) {
      data.append('snapshot', snapshot.blob, 'snapshot.jpg');
    } else {
      data.append('snapshot', '');
    }

    let url = format('meetings/%s', this.meetingService.id);
    let config = {
      headers: {
        'Content-Type': undefined,
      },
    };

    return this.apiService.patch(url, data, config);
  }
}
