import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Directive, ElementRef, HostListener, Input, OnInit, Optional } from '@angular/core';
import { logger } from 'utils/util';


/**
 * A directive that closes the containing dialog when it is clicked. Optionally accepts a return
 * value that the dialog will emit through its `closed` Observable.
 *
 * Example usage:
 *
 * - `<div class="btn" modalClose>`: closes the dialog without a return value.
 * - `<div class="btn" [modalClose]="true">`: closes the dialog with return value `true`.
 *
 * Based on MatDialogClose.
 */
@Directive({
  selector: '[modalClose]',
  exportAs: 'modalClose',
})
export class ModalCloseDirective implements OnInit {
  @Input() modalClose: any;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private dialog: Dialog,
    @Optional() private dialogRef?: DialogRef<any>,
  ) {}


  ngOnInit() {
    if(!this.dialogRef) {
      // When this directive is included in a dialog via TemplateRef (rather than being
      // in a Component), the DialogRef isn't available via injection because embedded
      // views cannot be given a custom injector. Instead, we look up the DialogRef by
      // ID. This must occur in `onInit`, as the ID binding for the dialog container won't
      // be resolved at constructor time.
      this.dialogRef = getClosestDialog(this.elementRef.nativeElement, this.dialog.openDialogs);
    }
  }


  @HostListener('click')
  onClick() {
    if(this.dialogRef) {
      this.dialogRef.close(this.modalClose);
    } else {
      logger.error('No dialog found');
    }
  }
}



/**
 * Find the closest DialogRef to an element by looking at the DOM.
 *
 * Based on material/dialog/dialog/content-directives.ts:getClosestDialog.
 *
 * @param element Element relative to which to look for a dialog.
 * @param openDialogs References to the currently-open dialogs.
 */
export function getClosestDialog(
  element: HTMLElement,
  openDialogs: readonly DialogRef<any>[],
) {
  let parent: HTMLElement | null = element.parentElement;

  while(parent && !parent.classList.contains('cdk-dialog-container')) {
    parent = parent.parentElement;
  }

  if(parent == null) {
    return undefined;
  } else {
    const parentId = parent.id;
    return openDialogs.find(dialog => dialog.id === parentId);
  }
}
