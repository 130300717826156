import { EventEmitter, object, array, set, logger, format, bind } from 'utils/util';
import { Stream, StreamKind, StreamState, StreamType } from './Stream';
import Session from 'meeting/angularjs/main/users/Session';
import User from 'meeting/angularjs/main/users/User.js';
import { Inject, Injectable } from '@angular/core';
import { MeetingService } from '../meeting.service';
import { UrlService } from 'utils/url.service';
import { StreamFactory } from './streamFactory.service';
import { UserService } from 'meeting/angularjs-upgraded-providers';
import { AudioStream } from './AudioStream';

export enum StreamKnockingState {
  KNOCKING,
  ALIVE,
}

export type StreamInfo = {
  stream: Stream | null,
  emittedState: StreamKnockingState | null,
  syncedStates: Set<StreamKnockingState>,
  syncedEnabledStates: Set<StreamKnockingState>,
  isDeleted: boolean,
}

export type SessionStream = {
  [streamId: string]: StreamInfo
}

@Injectable({
  providedIn: 'root',
})
export class StreamService {
  public eventEmitter: EventEmitter;

  public sendLocalStreamsPromise = Promise.resolve();
  public sessionStates = new Map();
  /*
  * Format:
  * {
  *   session: {
  *     streamId: {
  *       stream: Stream,
  *       syncedStates: Set([KNOCKING | ALIVE]),
  *       syncedEnabledStates: Set([KNOCKING | ALIVE]),
  *       emittedState: KNOCKING | ALIVE,
  *       isDeleted: boolean,
  *     },
  *     ...
  *   },
  *   ...
  * }
  */
  public sessionStreams = new Map<Session, SessionStream>();
  public remoteGroupIds = new Map();

  constructor(
    @Inject(UserService) private userService,
    @Inject('apiService') private apiService,
    @Inject('notificationService') private notificationService,
    @Inject('meetingBroadcastService') private meetingBroadcastService,
    @Inject('mediaDeviceService') private mediaDeviceService,

    private streamFactory: StreamFactory,
    private meetingService: MeetingService,
    private urlService: UrlService,

  ) {
    bind(this);
    this.eventEmitter = EventEmitter.setup(this, ['add', 'remove']);
    this._setupListeners();
  }


  _setupListeners() {
    this.meetingBroadcastService.on('stream-add', this.onBroadcastAdd, true);
    this.meetingBroadcastService.on('stream-remove', this.onBroadcastRemove, true);
    this.meetingBroadcastService.on('stream-enabled', this.onBroadcastEnabled, true);
    this.userService.on('sessionState', this.onSessionState);
    this.mediaDeviceService.on('preferredAudioOutput', this.updateOutputDevice);
  }

  get audioStreams(): Stream[] {
    const streams: Stream[] = [];
    this.sessionStreams.forEach(sessionStreams => {
      Object.values(sessionStreams).forEach(streamInfo => {
        if(
          this.isActive(streamInfo)
          && streamInfo.stream
          && streamInfo.stream.kind === StreamKind.AUDIO
        ) {
          streams.push(streamInfo.stream);
        }
      });
    });
    return streams;
  }


  get videoStreams(): Stream[] {
    const streams: Stream[] = [];
    this.sessionStreams.forEach(sessionStreams => {
      Object.values(sessionStreams).forEach(streamInfo => {
        if(
          this.isActive(streamInfo)
          && streamInfo.stream
          && streamInfo.stream.kind === StreamKind.VIDEO
        ) {
          streams.push(streamInfo.stream);
        }
      });
    });
    return streams;
  }

  get streams(): Stream[] {
    const streams: Stream[] = [];
    this.sessionStreams.forEach(sessionStreams => {
      Object.values(sessionStreams).forEach(streamInfo => {
        if(
          this.isActive(streamInfo)
          && streamInfo.stream
        ) {
          streams.push(streamInfo.stream);
        }
      });
    });
    return streams;
  }


  get(session: Session, streamId: string) {
    return this.getAll(session)[streamId];
  }

  getLocal(streamId: string) {
    return this.getAllLocal()[streamId];
  }


  getAll(session: Session) {
    this.addSession(session);

    return object.map(
      object.filter(
        this.sessionStreams.get(session),
        (streamId, streamInfo) => this.isActive(streamInfo)
      ),
      (streamId, streamInfo) => [streamId, streamInfo.stream]
    );
  }

  getAllLocal() {
    return this.getAll(this.userService.mySession);
  }


  private getStreamInfo(session: Session, streamId: string): StreamInfo | null {
    const sessionStream = this.sessionStreams.get(session);
    if(sessionStream) {
      return sessionStream[streamId];
    } else {
      return null;
    }
  }

  private isActive(streamInfo: StreamInfo) {
    if(streamInfo.isDeleted || !streamInfo.stream) {
      return false;

    } else
    if(streamInfo.stream.session.isLocal) {
      return true;

    } else {
      const sessionState = this.getSessionStateDeadKnockingOrJoined(streamInfo.stream.session);
      return (
        array.has([Session.State.ALIVE, Session.State.KNOCKING], sessionState)
        && streamInfo.syncedStates.has(sessionState)
      );
    }
  }


  getFromUser(user: User) {
    const sessions = Array.from(user.sessions);
    return Object.assign({}, ...sessions.map(session => this.getAll(session)));
  }


  getWithType(session: Session, type: StreamType) {
    return object.filter(
      this.getAll(session),
      (streamId, stream) => stream.type === type
    );
  }



  private addSession(session: Session) {
    if(!this.sessionStreams.has(session)) {
      this.sessionStreams.set(session, {});
    }
  }



  private emitChangedSessionStreams(session: Session) {
    const sessionStreams = Object.values(this.sessionStreams.get(session) || {});
    const sessionState = this.getSessionStateDeadKnockingOrJoined(session);

    // Emit all 'remove' events before any 'add' events to avoid creating unnecessary
    // peerconnections, tiles,...
    sessionStreams.forEach(streamInfo => {
      if(
        streamInfo.emittedState != null
        && (!this.isActive(streamInfo) || streamInfo.emittedState !== sessionState)
      ) {
        this.eventEmitter.emit('remove', streamInfo.stream);
        streamInfo.emittedState = null;
      }
    });

    sessionStreams.forEach(streamInfo => {
      if(streamInfo.emittedState == null && this.isActive(streamInfo)) {
        this.eventEmitter.emit('add', streamInfo.stream);
        streamInfo.emittedState = sessionState;
      }

      if(
        streamInfo.stream
        && !streamInfo.stream.session.isLocal
        && streamInfo.isDeleted
      ) {
        const sessionStream = this.sessionStreams.get(session);
        if(sessionStream) {
          delete sessionStream[streamInfo.stream.id];
        }
      }
    });

    if(!session.isLocal) {
      const joinedSessionState = this.getSessionStateKnockingOrJoined(session);
      sessionStreams.forEach(streamInfo => {
        if(streamInfo.stream) {
          const enabled = streamInfo.syncedEnabledStates.has(joinedSessionState);
          streamInfo.stream.setEnabled(enabled);
        }
      });
    }
  }



  /************************
   * Server communication *
   ************************/

  private onBroadcastAdd(channel, session: Session, timestamp, ...rawStreamInfos) {
    this.addSession(session);
    const sessionState = this.getSessionStateKnockingOrJoined(session);

    for(let i = 0; i < rawStreamInfos.length; i += 3) {
      const [streamId, groupId, streamType] = rawStreamInfos.slice(i, i + 3);
      let streamInfo = this.getStreamInfo(session, streamId);
      if(!streamInfo) {
        const stream = this.streamFactory.create(streamType, streamId, groupId, session);
        streamInfo = this.add(stream);
      }

      if(!streamInfo.syncedStates.has(sessionState)) {
        streamInfo.syncedStates.add(sessionState);
      }
    }

    this.emitChangedSessionStreams(session);
    if(session.isLocal) {
      this.sendLocalStreams();
    }
  }


  private onBroadcastRemove(channel, session: Session, timestamp, ...streamIds) {
    this.addSession(session);
    const sessionState = this.getSessionStateKnockingOrJoined(session);

    streamIds.forEach(streamId => {
      const streamInfo = this.getStreamInfo(session, streamId);
      if(streamInfo && streamInfo.stream && streamInfo.syncedStates.has(sessionState)) {
        streamInfo.syncedStates.delete(sessionState);
        // We never delete a streamInfo object for local streams, but we do for remote streams.
        // We need to re-add the session state to syncedEnabledStates to keep all parties in sync.
        streamInfo.syncedEnabledStates.add(sessionState);
        streamInfo.stream.stop();

        if(!session.isLocal && streamInfo.syncedStates.size === 0) {
          this.remove(streamInfo.stream);
        }
      }
    });

    this.emitChangedSessionStreams(session);
  }


  private onBroadcastEnabled(channel, session: Session, timestamp, ...rawStreamInfos) {
    this.addSession(session);
    const sessionState = this.getSessionStateKnockingOrJoined(session);

    for(let i = 0; i < rawStreamInfos.length; i += 2) {
      const streamId = rawStreamInfos[i];
      const enabled = rawStreamInfos[i + 1];
      const streamInfo = this.getStreamInfo(session, streamId);
      if(streamInfo) {
        if(enabled) {
          streamInfo.syncedEnabledStates.add(sessionState);
        } else {
          streamInfo.syncedEnabledStates.delete(sessionState);
        }
      }
    }

    this.emitChangedSessionStreams(session);
  }


  private onSessionState(session) {
    if(array.has([Session.State.KNOCKING, Session.State.ALIVE], session.state)) {
      this.sessionStates.set(session, session.state);
    }

    this.emitChangedSessionStreams(session);
    if(session.isLocal) {
      this.sendLocalStreams();
    }
  }


  /**
   * Return Session.State.KNOCKING or Session.State.JOINED, depending on which state the session
   * is currently in or has been in most recently.
   *
   * UPGRADE NOTE: Or DEAD??? look into this when upgrading Users/Sessions
   */
  private getSessionStateKnockingOrJoined(session): Session.State {
    return this.sessionStates.get(session) || Session.State.DEAD;
  }

  /**
   * Return Session.State.DEAD, Session.State.KNOCKING or Session.State.JOINED, depending on which
   * the session is currently in or has been in most recently.
   */
  private getSessionStateDeadKnockingOrJoined(session): Session.State {
    return session.stateWithoutSleep;
  }



  /************************
   * Manage local streams *
   ************************/

  addLocal(stream) {
    stream.on('state', this.sendLocalStreams);
    stream.on('enabled', this.sendLocalStreams);
    this.add(stream);
    this.emitChangedSessionStreams(stream.session);
    this.sendLocalStreams();
  }

  removeLocal(stream) {
    const track = stream.track;
    stream.off('state', this.sendLocalStreams);
    stream.off('enabled', this.sendLocalStreams);
    this.remove(stream);
    this.emitChangedSessionStreams(stream.session);
    this.sendLocalStreams();
    if(track) {
      track.stop();
    }
  }

  private sendLocalStreams(): void {
    this.sendLocalStreamsPromise = this.sendLocalStreamsPromise.then(this.sendLocalStreamsNow);
  }

  private sendLocalStreamsNow(): Promise<void> {
    const session = this.userService.mySession;
    if(!array.has([Session.State.KNOCKING, Session.State.ALIVE], session.state)) {
      return Promise.resolve();
    }

    return this.sendRemovedStreams()
      .then(() => this.sendAddedStreams())
      .then(() => this.sendStreamsEnabled());
  }


  private sendRemovedStreams(): Promise<void> {
    const removedStreams = set.difference(
      new Set(this.syncedStreams),
      new Set(this.playingStreams)
    );
    return this.sendQueue(
      removedStreams,
      'stream-remove',
      stream => [stream.id]
    );
  }


  private sendAddedStreams(): Promise<void> {
    const addedStreams = set.difference(
      new Set(this.playingStreams),
      new Set(this.syncedStreams)
    );
    return this.sendQueue(
      addedStreams,
      'stream-add',
      stream => [stream.id, stream.groupId, stream.type]
    )
      .catch(error => {
        logger.warn(error);
        addedStreams.forEach(streamInfo => {
          this.removeLocal(streamInfo.stream);
        });

        this.showAddStreamError();
      });
  }


  private sendStreamsEnabled(): Promise<void> {
    const session = this.userService.mySession;
    const changedStreams = new Set(this.syncedStreams.filter(streamInfo => {
      return (
        streamInfo.stream
        && streamInfo.stream.enabled !== streamInfo.syncedEnabledStates.has(session.state)
      );
    }));
    return this.sendQueue(
      changedStreams,
      'stream-enabled',
      stream => [stream.id, stream.enabled]
    );
  }


  private sendQueue(queue, event, argsFn): Promise<void> {
    if(queue.size === 0) {
      return Promise.resolve();
    }

    const sendArgs = [...queue].reduce(
      (sendArgs, streamInfo) => sendArgs.concat(argsFn(streamInfo.stream)),
      [event, false, []]
    );
    return this.meetingBroadcastService.send(...sendArgs);
  }

  /* eslint-disable max-len */
  private showAddStreamError() {
    if(this.meetingService.userIsOwner(this.userService.me)) {
      const queryParams = {
        filter: 'isActive = true',
      };
      const query = new URLSearchParams(queryParams);
      const path = format('users/me/meetings/?%s', query.toString());
      this.apiService.get(path).then(response => {
        const urls = response.data
          .map(meeting => meeting.url)
          .map(url => `<li><a href="${url}" target="_blank">${url}</a></li>`)
          .join('');
        const message = `
          <p translate>
            You cannot share your audio/video at this moment because the following meeting rooms are already in use:
          </p>
          <ul>
            ${urls}
          </ul>
          <p translate>
            As soon as one of the other meetings is over, you can use this meeting room again.
          </p>
        `;
        this.notificationService.warning(message);
      });

    } else {
      let message = `
        <p translate>
          You cannot share your audio/video at this moment because the meeting room host has too many active meeting rooms at the same time.
        <p>
        <p translate>
          As soon as one of the other meetings is over, you can use this meeting room again.
        <p>
      `;

      if(!this.meetingService.settings.whitelabel.hasAddon && this.userService.me.isLazy) {
        message += `
          <p translate>
            <a class="btn btn--secondary" href="${this.urlService.urls.signup}" target="_blank">Sign up for free</a> to create your own meeting rooms.
          </p>
        `;
      }

      this.notificationService.warning(message);
    }
  }
  /* eslint-enable max-len */


  private get playingStreams(): StreamInfo[] {
    const session = this.userService.mySession;
    const sessionStream = this.sessionStreams.get(session);
    if(sessionStream) {
      const playingStreams = Object.values(sessionStream)
        .filter(streamInfo => {
          return (
            streamInfo.stream
            && (
              streamInfo.stream.state === StreamState.CONNECTED
              || streamInfo.stream.state === StreamState.PLAYING
            )
          );
        });
      return playingStreams;
    } else {
      return [];
    }
  }

  private get syncedStreams(): StreamInfo[] {
    const session = this.userService.mySession;
    const sessionStream = this.sessionStreams.get(session);
    if(sessionStream) {
      return Object.values(sessionStream)
        .filter(streamInfo => streamInfo.syncedStates.has(session.state));
    } else {
      return [];
    }
  }


  /**************************
   * Add and remove streams *
   **************************/

  private add(stream: Stream): StreamInfo {
    const session = stream.session;
    this.addSession(session);
    this.setOutputDevice(stream);

    let streamInfo = this.getStreamInfo(session, stream.id);
    if(!streamInfo) {
      streamInfo = {
        stream: null,
        syncedStates: new Set(),
        syncedEnabledStates: new Set([Session.State.KNOCKING, Session.State.ALIVE]),
        emittedState: null,
        isDeleted: false,
      };
      const sessionStream = this.sessionStreams.get(session);
      if(sessionStream) {
        sessionStream[stream.id] = streamInfo;
      }
    }

    streamInfo.stream = stream;
    streamInfo.isDeleted = false;

    return streamInfo;
  }


  private remove(stream: Stream): void {
    stream.destroy();
    // The streamInfo object will be deleted in emitChangedSessionStreams()
    const sessionStream = this.sessionStreams.get(stream.session);
    if(sessionStream) {
      sessionStream[stream.id].isDeleted = true;
    }
  }



  /************************
   * Manage output device *
   ************************/

  private updateOutputDevice() {
    this.streams.forEach(this.setOutputDevice);
  }


  private setOutputDevice(stream: Stream) {
    if(stream instanceof AudioStream) {
      const output = this.mediaDeviceService.preferredAudioOutput;
      stream.setOutputDevice(output);
    }
  }
}
