<div class="smart-summary overflow-auto h-full overflow-auto">
  <div class="smart-summary__header p-4">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="text-h4" i18n>Smart summary</div>
        <div class="ml-2 badge badge--info" i18n>Beta</div>
      </div>
      <button *ngIf="state.stage === 'summary-generated'" class="btn btn--outline-error btn--square"
        matTooltip="Clear smart summary" matTooltipPosition="above" i18n-matTooltip [modalTriggerFor]="clearModal"
        modalTitle="Delete summary?" i18n-modalTitle>
        <svg-icon class="btn__icon" svgName="trash"></svg-icon>
      </button>
    </div>
  </div>

  <div class="smart-summary__container p-4">
    <div *ngIf="state.stage === 'limit-reached'" class="smart-summary__info mt-4">
      <div class="smart-summary__text-balloon mt-4 text-center">
        <div i18n>
          You have reached your free hours limit for this month.
          Once the beta period is done, you will be able to buy unlimited hours of smart summary.
        </div>
      </div>
      <svg-icon [svgPath]="'illustrations__girl'"></svg-icon>
    </div>

    <div *ngIf="state.stage === 'unstarted'" class="smart-summary__info">
      <div class="smart-summary__mic-wrapper">
        <svg-icon class="smart-summary__mic-icon use-icon-color" [svgName]="'mic'" [svgStyle]="'filled'">
        </svg-icon>
      </div>

      <div class="text-center">
        <div class="m-4" i18n>
          Have Vectera make a transcript of the meeting and summarize it for you.
        </div>
        <div class="btn btn--secondary" (click)="startRecording()">
          <svg-icon class="btn__icon mr-2" [svgName]="'edit'">
          </svg-icon>
          <span i18n>Start transcription</span>
        </div>
      </div>
    </div>

    <div *ngIf="state.stage === 'recording'" class="smart-summary__info">
      <div class="smart-summary__mic-wrapper smart-summary__mic-wrapper--recording">
        <svg-icon class="smart-summary__mic-icon use-icon-color" [svgName]="'mic'" [svgStyle]="'filled'">
        </svg-icon>
      </div>

      <div *ngIf="state.iAmControllingHost" class="text-center">
        <div class="m-4">
          <div i18n>
            Transcribing...
          </div>
          <div *ngIf="state.recordingLongEnough" i18n>
            Click the button below to stop the transcription.
          </div>
        </div>
        <button class="btn btn--secondary" (click)="stopRecording()">
          <svg-icon class="btn__icon mr-2" [svgName]="'stop'" [svgStyle]="'filled'">
          </svg-icon>
          <span i18n>Stop transcription</span>
        </button>
        <div *ngIf="!state.recordingLongEnough" class="field field--info mt-4">
          <field-icon [type]="'info'"></field-icon>
          <span i18n>To accurately detect the language, the transcription should last more than a minute.</span>
        </div>
      </div>

      <div *ngIf="!state.iAmControllingHost" class="mt-4 text-center">
        <div i18n>
          This meeting is being transcribed.
        </div>
      </div>
    </div>

    <div *ngIf="state.stage === 'generating-transcription' || state.stage === 'generating-summary'"
      class="smart-summary__status">
      <loading size="large"></loading>
      <p *ngIf="state.stage === 'generating-transcription'" i18n>Generating transcription...</p>
      <p *ngIf="state.stage === 'generating-summary'" i18n>Generating summary...</p>
    </div>

    <div *ngIf="state.stage === 'summary-generated'">
      <summary class="mb-4"></summary>
      <transcription></transcription>
    </div>

    <div *ngIf="state.stage === 'errored'" class="mt-4 text-center">
      <p>
        {{ state.errorMessage }}
      </p>
      <div class="btn btn--secondary mt-4" (click)="clear()">
        <svg-icon class="btn__icon mr-2" svgName="refresh"></svg-icon>
        <span i18n>Try again</span>
      </div>
    </div>
  </div>
</div>


<ng-template #clearModal>
  <p i18n>Have you saved the summary? You can also share it via email.</p>
  <p i18n>The summary and transcription will be deleted. You can then start a new transcription.</p>
  <div class="d-flex gap-4 justify-content-end mt-10">
    <button class="btn btn--secondary" modalClose i18n>
      Cancel
    </button>
    <button class="btn btn--error" (click)="clear()" modalClose i18n>
      Delete
    </button>
  </div>
</ng-template>
