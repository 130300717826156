import { AddImageType } from '../whiteboard.service';
export default class UploadContentModalController {
  static get $inject() {
    return [
      'uploadContentService',
    ];
  }

  constructor(
    uploadContentService
  ) {
    this.uploadContentService = uploadContentService;
  }

  get AddImageType() {
    return AddImageType;
  }
}
