import { FormatStringArrayPipe } from 'utils/pipes/format-string-array.pipe';


export default class NotesSyncWarningController {
  static get $inject() {
    return [
      'integrationSyncService',
      'notesSyncWarningService',
      'privateNotesService',
    ];
  }

  constructor(
    integrationSyncService,
    notesSyncWarningService,
    privateNotesService
  ) {
    this.integrationSyncService = integrationSyncService;
    this.notesSyncWarningService = notesSyncWarningService;
    this.privateNotesService = privateNotesService;
  }

  get activeIntegrations() {
    const integrations = this.integrationSyncService.activeIntegrationNames();
    return new FormatStringArrayPipe().transform(integrations);
  }

  endCall(callback) {
    this.notesSyncWarningService.hideModal();
    return callback();
  }
}
