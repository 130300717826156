export default function StopPropagationDirective() {
  return {
    restrict: 'A',

    link: function(scope, elem, attrs) {
      if(attrs.ngClick) {
        elem.on('click', function($event) {
          $event.stopPropagation();
        });
      }
    }
  };
}
