/**
 * Service to gather audio recordings to turn into a transcript & summary later on.
 *
 * See the docs on the Web Audio API for an intro on how this stuff works. In summary, you have a
 * DAG with source nodes (audio sources such as mics), destination nodes (audio sinks such as
 * speakers) and modification nodes (like increasing the volume).
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
 */

import { Stream, StreamKind, StreamState } from 'meeting/meeting-room/stream/Stream';
import { StreamService } from 'meeting/meeting-room/stream/stream.service';
import { assertOrLog, bind, EventEmitter } from 'utils/util';


export class SmartSummaryRecorder {

  public eventEmitter: EventEmitter;

  /** The audio graph */
  private audioContext: AudioContext;
  /** The audio sources per meeting room Stream ID */
  private sourceNodes: Map<string, MediaStreamAudioSourceNode>;
  /** The audio sink that all nodes connect to */
  private destinationNode: MediaStreamAudioDestinationNode;
  /** The object that collects all audio into a file */
  private mediaRecorder: MediaRecorder;
  /** The blobs that will contain all audio */
  private outputChunks: Blob[];

  constructor(
    private streamService: StreamService,
  ) {
    bind(this);
    this.eventEmitter = EventEmitter.setup(this, ['error']);

    this.audioContext = new AudioContext();
    this.sourceNodes = new Map();
    this.destinationNode = new MediaStreamAudioDestinationNode(this.audioContext);
    this.mediaRecorder = new MediaRecorder(this.destinationNode.stream);
    this.outputChunks = [];

    this.mediaRecorder.ondataavailable = event => {
      this.outputChunks.push(event.data);
    };
    this.mediaRecorder.onerror = event => {
      this.eventEmitter.emit('error', event);
    };

    this.mediaRecorder.start();

    for (const stream of this.streamService.audioStreams) {
      this.addStream(stream);
    }
    this.streamService.eventEmitter.on('add', this.addStream);
    this.streamService.eventEmitter.on('remove', this.removeStream);
  }

  public stop(): Promise<Blob> {
    this.streamService.eventEmitter.off('add', this.addStream);
    this.streamService.eventEmitter.off('remove', this.removeStream);

    return new Promise((resolve, _reject) => {
      this.mediaRecorder.onstop = _event => {
        if (this.outputChunks.length === 0) {
          return;
        }
        const blob = new Blob(this.outputChunks, { type: this.mediaRecorder.mimeType });
        resolve(blob);
      };
      this.mediaRecorder.stop();
    });
  }

  private addStream(stream: Stream): void {
    if(stream.kind !== StreamKind.AUDIO) {
      return;
    }
    stream.eventEmitter.on('state enabled', this.onStreamChanged);
    this.onStreamChanged(stream);
  }

  private removeStream(stream: Stream): void {
    if(stream.kind !== StreamKind.AUDIO) {
      return;
    }
    stream.eventEmitter.off('state enabled', this.onStreamChanged);
    this.onStreamChanged(stream);
  }

  /**
   * Ensures the state of the stream is reflected in the audio graph, i.e. if the stream is active
   * and not muted, it should be in the graph, otherwise not.
   */
  private onStreamChanged(stream: Stream): void {
    // The >= CONNECTED is there to cover both the case of local streams which stay at CONNECTED
    // and the case of remote streams which seem to skip CONNECTED and go straight to PLAYING.
    if (stream.state >= StreamState.CONNECTED && stream.enabled) {
      this.tryAddAudioSource(stream);
    } else {
      this.tryRemoveAudioSource(stream);
    }
  }

  private tryAddAudioSource(stream: Stream): void {
    if (this.sourceNodes.has(stream.id)) {
      return;
    }
    assertOrLog(stream.mediaStream != null, 'Active stream should have a `mediaStream`');
    if (stream.mediaStream == null) {
      return;
    }
    const sourceNode = this.audioContext.createMediaStreamSource(stream.mediaStream);
    sourceNode.connect(this.destinationNode);
    this.sourceNodes.set(stream.id, sourceNode);
  }

  private tryRemoveAudioSource(stream: Stream): void {
    const sourceNode = this.sourceNodes.get(stream.id);
    if (sourceNode == null) {
      return;
    }
    this.sourceNodes.delete(stream.id);
    sourceNode.disconnect();
  }
}
