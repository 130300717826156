import templateModal from './settings.modal.html?raw';

import {
  bind,
  browser,
  errors,
  EventEmitter,
  getQueryParameter,
  storage,
} from 'utils/util';
import { StreamType } from  'meeting/meeting-room/stream';


const DISABLE_INCOMING_KEY = 'disableIncomingVideo';
const SUPPRESS_MIC_NOISE_KEY = 'suppressMicNoise';

export const MODAL_ID = 'settings';


export const Quality = Object.freeze({
  NORMAL: 1,
  HIGH: 2,
});

const overrideVideoBandwidth = parseInt(getQueryParameter('videoBandwidth'));



export default class SettingsService {
  get Quality() {
    return Quality;
  }
  get QualityName() {
    return Object.freeze({
      /// Video quality value
      [Quality.NORMAL]: gettextCatalog.getString('Standard'),
      /// Video quality value
      [Quality.HIGH]: gettextCatalog.getString('High'),
    });
  }

  static get $inject() {
    return [
      'mediaDeviceService',
      'meetingBroadcastService',
      'modalService',
      'requestUserService',
    ];
  }

  constructor(
    mediaDeviceService,
    meetingBroadcastService,
    modalService,
    requestUserService
  ) {
    bind(this);
    EventEmitter.setup(this, ['disableIncomingVideo', 'suppressMicNoise', 'videoQuality']);

    this.mediaDeviceService = mediaDeviceService;
    this.meetingBroadcastService = meetingBroadcastService;
    this.modalService = modalService;
    this.requestUserService = requestUserService;

    modalService.register(
      MODAL_ID,
      gettext('Settings'),
      'utils/icons/tl/24x24_settings_outline.svg',
      templateModal
    );


    let disableIncomingVideo = storage.getBooleanItem(DISABLE_INCOMING_KEY, false);
    this.setDisableIncomingVideo(disableIncomingVideo);

    let suppressMicNoise;
    const aprilFirst2023 = new Date( 2023, 4 - 1, 1);
    if(this.requestUserService.user.dateJoined > aprilFirst2023) {
      suppressMicNoise = storage.getBooleanItem(SUPPRESS_MIC_NOISE_KEY, true);
    } else {
      suppressMicNoise = storage.getBooleanItem(SUPPRESS_MIC_NOISE_KEY, false);
    }

    this.setMicNoiseSuppressed(suppressMicNoise);

    this.videoQuality = Quality.NORMAL;
    this.broadcastQualityReplay = Quality.NORMAL;

    meetingBroadcastService.on('video-quality', this._onBroadcastQuality, true);
    meetingBroadcastService.afterInitialization().then(this._afterBroadcastInitialization);
  }

  showModal() {
    this.modalService.show(MODAL_ID);
  }


  /*****************
   * Video Quality *
   *****************/

  /**
   * Set the Video Quality and broadcast the setting it to participants
   *
   * @param {*} quality
   */
  setVideoQuality(quality) {
    if(this.meetingBroadcastService.initializing) {
      return;
    }
    this._setVideoQuality(quality, true);
  }

  /**
   * "video quality" broadcast received. Set the video quality but don't re-broadcast.
   *
   * If the the broadcast service is still initializing, store the quality value instead, so
   * it can be set once initialization is finished.
   *
   * @param {*} quality
   */
  _onBroadcastQuality(_channel, _session, _datetime, quality) {
    if(this.meetingBroadcastService.initializing) {
      this.broadcastQualityReplay = quality;
      return;
    }
    this._setVideoQuality(quality, false);
  }

  _afterBroadcastInitialization() {
    this._setVideoQuality(this.broadcastQualityReplay, false);
  }

  /**
   *
   * @param {settingsService.Quality} quality
   * @param {boolean} localAction - whether the quality change event was launched by the local user
   * @param {boolean} sync - whether the quality change event should be broadcasted
   */
  _setVideoQuality(videoQuality, sync) {
    if(videoQuality === this.videoQuality) {
      return;
    }

    this.videoQuality = videoQuality;
    this.emit('videoQuality');

    if(sync) {
      this.meetingBroadcastService.send('video-quality', false, [], videoQuality);
    }
  }



  /*********************
   * Streams & devices *
   *********************/

  setDisableIncomingVideo(disableIncomingVideo) {
    if(disableIncomingVideo === this.disableIncomingVideo) {
      return;
    }

    this.disableIncomingVideo = disableIncomingVideo;
    storage.setBooleanItem(DISABLE_INCOMING_KEY, disableIncomingVideo);
    this.emit('disableIncomingVideo', this.disableIncomingVideo);
  }

  setMicNoiseSuppressed(suppressMicNoise) {
    if(suppressMicNoise === this.suppressMicNoise) {
      return;
    }

    this.suppressMicNoise = suppressMicNoise;
    storage.setBooleanItem(SUPPRESS_MIC_NOISE_KEY, suppressMicNoise);
    this.emit('suppressMicNoise', this.suppressMicNoise);
  }

  /***********************************************
   * Video bandwidth & setting sender parameters *
   ***********************************************/

  /**
   *
   * @param {number} resolution The resolution of the video stream in total pixels.
   * @param {Quality} [quality] The quality in which the stream will be sent. Defaults to
   *                            this.videoQuality.
   * @returns {number} The bandwidth in kbps.
   */
  getSendBandwidth(resolution, quality) {
    if(quality == null) {
      quality = this.videoQuality;
    }

    let bandwidths = this._getSendBandwidths(resolution);
    return bandwidths[quality];
  }


  _getSendBandwidths(resolution) {
    // Keep the logic in this method in sync with janus_orchestrator/orchestrator.py
    if(!isNaN(overrideVideoBandwidth)) {
      return {
        [Quality.NORMAL]: overrideVideoBandwidth,
        [Quality.HIGH]:   overrideVideoBandwidth,
      };

    } else if(browser.isIOS()) {
      // iOS never scales down the video, it insists on sending 720p even if you request a very low
      // bandwidth. As a result, requesting a low bandwidth results in very low framerates and a
      // low perceived quality. So we have no choice but to request relatively high bandwidths even
      // on NORMAL and HIGH settings.
      return {
        [Quality.NORMAL]: parseInt(400 + 0.7 * Math.sqrt(resolution)),
        [Quality.HIGH]:   parseInt(450 + 1.2 * Math.sqrt(resolution)),
      };

    } else {
      // optimalRequested is the bandwidth for video that is basically indistinguishable
      // from non-throttled video. This is obtained by calling getUserMedia with various native
      // resolutions, disabling PeerConnection bandwidth throttling, and observing the bandwidth
      // that Chrome uses to send these streams. We then linearized that relationship, and
      // subtracted around 20% because Chrome uses a bit too much bandwidth by default.
      let optimalRequested = 250 + 2 * Math.sqrt(resolution);

      // When you are displaying a video on 720p, these quality settings should make Chrome show
      // the videos at respectively 360p, 540p and 720p while hitting 30fps.
      return {
        [Quality.NORMAL]: parseInt(optimalRequested * .2),
        [Quality.HIGH]:   parseInt(optimalRequested * .6),
      };
    }
  }


  /**
   * Get the height in pixels that a video stream should be sent in.
   * @param {VideoStream} stream The stream for which to calculate the height.
   * @param {Quality} [quality] The quality in which the stream will be sent. Defaults to
   *  this.videoQuality.
   * @returns {number} The height in pixels. May return 0 if the stream should be sent in its
   *  original resolution.
   */
  getVideoHeight(stream, quality) {
    if(quality == null) {
      quality = this.videoQuality;
    }

    if(stream.type !== StreamType.VIDEO) {
      return 0;
    }

    switch(quality) {
      case Quality.NORMAL:
        return 360;
      case Quality.HIGH:
        return 720;
      default:
        throw errors.InvalidArgumentError(`Invalid quality: ${quality}`);
    }
  }



  getSenderParameters(sender) {
    let params = sender.getParameters();
    if(!params.encodings) {
      params.encodings = [{}];
    }
    return params;
  }


  setVideoParameterBandwidth(sender, params, bitrateKbps) {
    if(!params.encodings || params.encodings.length === 0) {
      return params;
    }

    params.encodings[0].maxBitrate = bitrateKbps * 1000;
    return params;
  }


  setVideoParameterHeight(sender, params, desiredHeight) {
    if(!params.encodings || params.encodings.length === 0) {
      return params;
    }

    let trackHeight = sender.track.getSettings().height;
    let scaleRatio = trackHeight / desiredHeight;
    if(scaleRatio < 1 || !Number.isFinite(scaleRatio)) {
      scaleRatio = 1;
    }

    params.encodings[0].scaleResolutionDownBy = scaleRatio;
    return params;
  }
}
