import { InjectionToken } from '@angular/core';
import { AdminPulseService } from 'integrations/adminpulse/adminpulse.service';
import { HubSpotService } from 'integrations/hubspot/hubspot.service';
import { IntegrationService } from 'integrations/integration.service';


export enum State {
  INITIAL,
  DIRTY,
  SYNCING,
  SYNCED,
}

export const SYNC_SERVICE = new InjectionToken<IntegrationSyncServiceInterface>('syncService');

export interface IntegrationSyncServiceInterface {
  state: State;

  isActive(): boolean;
  activeIntegrations(): IntegrationService[];
  activeIntegrationNames(): string[];
  canSaveContent(): boolean;
  saveContent(content: string): Promise<void>;
  withSyncStateManagement(syncFn: () => Promise<void>): Promise<void>;
}

export abstract class IntegrationSyncService implements IntegrationSyncServiceInterface {
  public state = State.INITIAL;
  public content = '';
  private integrations: IntegrationService[] = [];

  constructor(
    adminPulseService: AdminPulseService,
    hubSpotService: HubSpotService,
  ) {
    this.integrations = [
      hubSpotService,
      adminPulseService,
    ];
  }

  public isActive(): boolean {
    return this.integrations.some(integration => integration.isActive);
  }

  public activeIntegrations(): IntegrationService[] {
    return this.integrations.filter(integration => integration.isActive);
  }

  public activeIntegrationNames(): string[] {
    return this.activeIntegrations().map(integration => integration.displayName);
  }

  public abstract canSaveContent(): boolean;

  public async saveContent(content: string): Promise<void> {
    await this.withSyncStateManagement(async () => {
      await Promise.all(
        this.integrations
          .filter(integration => integration.isActive)
          .map(integration =>
            integration.push({
              notes: content,
            })
          )
      );
    });
  }

  public abstract withSyncStateManagement(syncFn: () => Promise<void>): Promise<void>;
}
