<div class="d-flex flex-column" cdkTrapFocus cdkTrapFocusAutoCapture data-testid="addRoomModal">

  <div
    *ngIf="shouldShowAccessLevelOverrideWarning"
    class="field field--warning mb-5"
  >
    <field-icon [type]="'warning'"></field-icon>
    <span i18n>Linking an AdminPulse relation to this meeting room will overwrite the access level of your team members so that they can always join this meeting room as a host.</span>
  </div>

  <div class="form-group mb-4">
    <label i18n>Room name</label>
    <input
      [withSettingsErrors]="errors"
      class="input"
      [(ngModel)]="key"
      data-testid="roomTitleInput"
    >
  </div>

  <div
    class="form-group mb-4"
    *ngIf="requestUserService.isFree"
  >
    <label i18n>Start from template</label>
    <pro-feature-badge
      class="select"
      featureText="Select a room template"
      i18n-featureText
    >
    </pro-feature-badge>
  </div>

  <div
    class="form-group mb-4"
    *ngIf="templateOptions.length > 0 && !requestUserService.isFree"
  >
    <label i18n>Start from template</label>
    <dropdown-select
      [options]="templateOptions"
      [(ngModel)]="selectedTemplate"
      [nullable]="true"
      textIfNull="Start from scratch"
      i18n-textIfNull
    ></dropdown-select>
  </div>

  <div
    class="form-group mb-4"
    *ngIf="adminPulseService.isConnected"
  >
    <label i18n>Link to an AdminPulse relation</label>
    <div class="position-relative d-flex align-items-center" *ngIf="stateService.isLoading">
      <loading class="ml-2"></loading>
      <div i18n>Loading AdminPulse relations...</div>
    </div>

    <combo-box
      *ngIf="stateService.isReady"
      [options]="parsedAdminPulseOptions"
      [(ngModel)]="selectedRelation"
      (ngModelChange)="handleRelationSelected()"
      i18n-textIfNull
      data-testid="addRoomModalLinkToAdminPulseRelation"
    ></combo-box>
  </div>

  <div class="d-flex justify-content-end">
    <div class="btn btn--secondary mr-2 mb-2" [modalClose] i18n>
      Cancel
    </div>

    <div
      class="btn btn--primary mr-2 mb-2"
      (click)="create()"
      i18n
      data-testid="confirmCreateMeetingRoomButton"
    >
      Create
    </div>
  </div>
</div>
