import * as icons from 'utils/icons';
import { logger, string } from 'utils/util';


export default function SvgDirective() {
  return {
    restrict: 'A',

    link: function($scope, $elem, $attr) {
      $scope.$watch($attr.svg, src => {
        if(!src) {
          return;
        }


        // Most icons are resolved as follows:
        // - src 'meeting/icons/home.svg' resolves to `{ library: 'meeting', name: 'home' }
        // The only exceptions are the libraries that are nested inside utils (i.e. "tl" and
        // "logo"). Because of historic reasons, these resolve as follows:
        // - src 'utils/icons/tl/home.svg' resolves to { library: 'tl', name: 'home' }
        // - src 'utils/icons/logo/standalone/full.svg' resolves to
        //   { library: 'logo', name: 'standalone_full' }
        let library, name;
        let utilsMatch = src.match(/^utils\/icons\/([^/]+)\/(.+)$/);
        if(utilsMatch) {
          library = utilsMatch[1];
          name = utilsMatch[2];
        } else {
          let match = src.match(/([\w/]+)\/icons\/([\w/.]+)/);
          library = match[1];
          name = match[2];
        }

        if(!name) {
          logNonExistent(src);
          return;
        }

        library = library.replace(/\//g, '_');
        name = string.removeSuffix(name, '.svg').replace(/\//g, '_');
        let importName = `${library}__${name}`;

        let svg = icons[importName];
        if(svg) {
          $elem.html(svg);
        } else {
          logNonExistent(src);
        }
      });
    }
  };
}


let loggedMessages = new Set();

function logNonExistent(icon) {
  const message = `Unknown angular.js svg icon: ${icon}`;
  if(!loggedMessages.has(message)) {
    logger.error('Unknown svg icon:', icon);
    loggedMessages.add(message);
  }
}
