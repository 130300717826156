import { storage } from 'utils/util';


const Type = Object.freeze({
  PROGRESS: 'progress',
  TEXT: 'text',
});

const Level = Object.freeze({
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
});

/**
 * A notification of progress bar shown on the top of the page, within the Vectera browser
 * application. Messages can include HTML code.
 *
 * @param {String} id - Id of the notification.
 * @param {VecteraNotification.Type} type - TEXT or PROGRESS.
 * @param {VecteraNotification.LEVEL} level - INFO, WARNING, ERROR or SUCCESS.
 * @param {String} message - The message shown in the notification.
 * @param {object} options - Options to customize the behaviour of the notification. Accepts the
 *  following keys:
 *  - {number} delay - Timeout after which the notification should be removed.
 *  - {string} retentiveId - A string identifier. Once a notification with this id has its
 *    "never show again" button clicked, never show a notification with this id again.
 *  - {boolean} dismissable - Whether the user can close/cancel the notification.
 *  - {boolean} persistOnReload - If true, the notification will only be shown after the next page
 *    load. Used e.g. for confirmation messages of a "Save page" action. Handling of this parameter
 *    is actually done by `NotificationService`, so it is ignored here.
 *  - {Function} onCancel - Called when the user cancels the notification. This method is
 *    responsible for calling `notification.cancel()`.
 *  - {Function} onRemove - Called when `notification.cancel()` is called. Used by
 *    `NotificationService` to cleanup and emit events.
 */
export default class VecteraNotification {
  constructor(id, type, level, message, options) {
    this.id = id;
    this.type = type;
    this.message = message;

    this.active = true;
    this.attention = false;
    this.onRemove = options.onRemove;
    this.cssClass = '';

    this.cancelTimeout = null;

    if(type === VecteraNotification.Type.TEXT) {
      this.setDelay(options.delay);
    }
    this.setLevel(level);

    this.progress = 0;

    this.dismissable = options.dismissable;
    this.persistOnReload;
    this.onCancel = options.onCancel;

    this.retentiveId = options.retentiveId;
  }


  onNeverAgainButton() {
    storage.setItem(this.retentiveId, Date.now());
    this.onCancelButton();
  }

  onCancelButton() {
    if(this.dismissable) {
      if(this.onCancel) {
        this.onCancel(this);
      } else {
        this.cancel();
      }
    }
  }

  cancel() {
    this.active = false;
    // Avoid that hide transition takes the time from the blink animation
    this.attention = false;
    this.onRemove(this);
  }


  setLevel(level) {
    this.level = level;
    this.cssClass = 'notification__inner--' + level;
  }


  /**
   * @param {number} delay Timeout after which the notification should be removed
   */
  setDelay(delay) {
    if(this.cancelTimeout) {
      $timeout.cancel(this.cancelTimeout);
    }

    if(delay >= 0) {
      this.cancelTimeout = $timeout(this.cancel.bind(this), delay);
    }
  }


  setMessage(message) {
    this.message = message;
  }


  drawAttention() {
    this.attention = false;
    $timeout(() => {
      this.attention = true;
      $timeout(() => {
        this.attention = false;
      }, 1500);
    });
  }


  /************/
  /* PROGRESS */
  /************/

  setProgress(progress) {
    this.progress = progress;
  }


  isProgressBar() {
    return this.type === VecteraNotification.Type.PROGRESS;
  }
}

VecteraNotification.Type = Type;
VecteraNotification.Level = Level;
