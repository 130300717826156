import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import { dateTime } from 'utils/util';
import { SmartSummaryService, Transcript } from '../../../../smart-summary/smart-summary.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.scss']
})
export class TranscriptionComponent {
  @ViewChild('transcriptionContainer') transcriptionContainer!: ElementRef;

  private _isCollapsed = true;
  private _canCollapse = false;
  private _transcription: Transcript = { utterances: [], languageCode: 'en' };

  constructor(private smartSummaryService: SmartSummaryService, private ngZone: NgZone) {
    this.smartSummaryService.transcriptionGenerated$
      .pipe(untilDestroyed(this))
      .subscribe((transcription: Transcript) => {
        this._transcription = transcription;

        // Wait for Angular to finish at least 1 render.
        // Summary needs to be filled in and a render needs to happen
        // to give a height to the summaryContainer
        this.ngZone.onStable
          .pipe(untilDestroyed(this), first())
          .subscribe(() => {
            if (this.isOverflown(this.transcriptionContainer.nativeElement)) {
              this._canCollapse = true;
              this._isCollapsed = true;
            }
          });
      });
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  public get canCollapse(): boolean {
    return this._canCollapse;
  }

  public get transcription(): Transcript {
    return this._transcription;
  }

  public toggleCollapsed(): void {
    this._isCollapsed = !this._isCollapsed;
  }

  public millisToTimePassed(millis: number): string {
    return dateTime.millisToMinutesAndSeconds(millis);
  }

  public isOverflown(element: HTMLElement): boolean {
    return element.offsetHeight < element.scrollHeight;
  }
}
