const DEFAULT_OPTIONS = Object.freeze({
  priority: 0,
  dismissable: true,
  rawTemplate: false,
  padding: true,
  blurOverlay: false,
  cssClasses: '',
});

export default class Modal {
  constructor(id, title, iconUrl, template, argOptions) {
    let options = Object.assign({}, DEFAULT_OPTIONS, argOptions);

    this.id = id;
    this.title = title;
    this.iconUrl = iconUrl;
    this.template = template;
    this.scope = {};

    Object.assign(this, options);

    this.isShown = false;
  }

  /**
   * Modal.title is a unique identifier of the modal, and cannot be changed at runtime. Instead
   * of registring modals with a translated title, translate it only when the title is actually
   * rendered
   */
  get label() {
    return gettextCatalog.getString(this.title);
  }


  show(scope) {
    this.scope = scope || {};
    this.isShown = true;
  }

  hide() {
    this.isShown = false;
  }
}
