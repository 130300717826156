import {
  AfterViewInit, ChangeDetectorRef, Component,
  ComponentRef,
  Input,
  OnChanges, SimpleChanges, Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ContactFormAnswer } from 'contactForm/models/ContactFormAnswer';
import { ContactFormQuestion, QuestionType } from 'contactForm/models/ContactFormQuestion';
import { Errors } from 'utils/settings/settings.component';
import { bind } from 'utils/util';
import {
  AddressInputComponent,
  AttachmentInputComponent,
  CheckboxInputComponent,
  ContactFormQuestionInputComponent,
  DateInputComponent,
  DropdownInputComponent,
  IntegerInputComponent,
  LanguageInputComponent,
  MoneyInputComponent,
  MultiLineInputComponent,
  MultiSelectInputComponent,
  NumberInputComponent,
  SingleLineInputComponent,
  SingleSelectInputComponent,
  YesNoInputComponent
} from '../contact-form-question-input';



const QuestionTypeInputMap: Record<QuestionType, Type<ContactFormQuestionInputComponent<any>>> = {
  [QuestionType.SINGLE_LINE]: SingleLineInputComponent,
  [QuestionType.MULTI_LINE]: MultiLineInputComponent,
  [QuestionType.EMAIL]: SingleLineInputComponent,
  [QuestionType.PHONE_NUMBER]: SingleLineInputComponent,
  [QuestionType.ADDRESS]: AddressInputComponent,
  [QuestionType.DATE]: DateInputComponent,
  [QuestionType.URL]: SingleLineInputComponent,
  [QuestionType.NUMBER]: NumberInputComponent,
  [QuestionType.INTEGER]: IntegerInputComponent,
  [QuestionType.MONEY]: MoneyInputComponent,
  [QuestionType.SINGLE_SELECT]: SingleSelectInputComponent,
  [QuestionType.DROPDOWN]: DropdownInputComponent,
  [QuestionType.LANGUAGE]: LanguageInputComponent,
  [QuestionType.YES_NO]: YesNoInputComponent,
  [QuestionType.MULTI_SELECT]: MultiSelectInputComponent,
  [QuestionType.CHECKBOX]: CheckboxInputComponent,
  [QuestionType.ATTACHMENT]: AttachmentInputComponent,
  [QuestionType.VAT]: SingleLineInputComponent,
  [QuestionType.KVK]: SingleLineInputComponent,
};

/**
 * Render a single question in a contact form.
 *
 * @param question - The question to render.
 * @param answer - The answer to write the user-provided answer to. Can be omitted if you are only
 *   rendering a preview of the question.
 * @param errors - The errors to display for this question.
 */
@Component({
  selector: 'contact-form-question[question]',
  templateUrl: './contact-form-question.component.html',
})
export class ContactFormQuestionComponent implements AfterViewInit, OnChanges {
  @Input() question!: ContactFormQuestion;
  @Input() answer?: ContactFormAnswer;
  @Input() errors?: Errors = {};

  @ViewChild('questionInput', { read: ViewContainerRef }) questionInputContainer?:
    ViewContainerRef;
  private questionInputRef?: ComponentRef<ContactFormQuestionInputComponent<any>>;


  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    bind(this);
  }


  ngAfterViewInit() {
    this.updateQuestionInput();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.question) {
      if(changes.question.previousValue) {
        this.removeListeners(changes.question.previousValue);
      }
      if(changes.question.currentValue) {
        this.setupListeners(changes.question.currentValue);
      }
      this.updateQuestionInput();
    }
  }

  setupListeners(question: ContactFormQuestion) {
    question.on('type', this.updateQuestionInput);
  }
  removeListeners(question: ContactFormQuestion) {
    question.off('type', this.updateQuestionInput);
  }


  get shouldShowLabel() {
    return this.question.type !== QuestionType.CHECKBOX;
  }

  updateQuestionInput() {
    if(!this.questionInputContainer) {
      return;
    }


    this.questionInputContainer.clear();
    if(this.question.type == null) {
      return;
    }

    const QuestionInputComponent = QuestionTypeInputMap[this.question.type];
    this.questionInputRef = this.questionInputContainer.createComponent(QuestionInputComponent);
    this.questionInputRef.instance.question = this.question;
    if(this.answer) {
      this.questionInputRef.instance.writeValue(this.answer.answer);
      this.questionInputRef.instance.valueChange.subscribe(value => this.answer.answer = value);

      // Give the ngOnInit time to run before we set the value
      setTimeout(() => {
        new URLSearchParams(window.location.search).forEach((value, key) => {
          if(this.questionInputRef && key === `questions.${this.question.id}` && value) {
            this.questionInputRef.instance.prefill(value);
          }
        });
      });
    }
    // Force change detection, otherwise you may get ExpressionChangedAfterItHasBeenCheckedError
    this.changeDetectorRef.detectChanges();
  }


  validate() {
    return this.questionInputRef?.instance.validate();
  }
}
