import { downgradeComponent } from '@angular/upgrade/static';
import { AppointmentsComponent } from './manage/appointments/appointments.component';
import {
  SchedulingOnboardingComponent
} from './manage/scheduling-onboarding/scheduling-onboarding.component';
import {
  AppointmentTypesComponent
} from './manage/appointment-types/appointment-types.component.ts';
import {
  AppointmentTypeConfigComponent
} from './manage/appointment-type-config/appointment-type-config.component.ts';
import {
  AppointmentManageComponent
} from './manage/appointment-manage/appointment-manage.component.ts';
import {
  BookingContainer
} from './booking/containers/booking.container.ts';


angular
  .module('scheduling', [])

  .directive('appointments', downgradeComponent({ component: AppointmentsComponent }))
  .directive('appointmentManage', downgradeComponent({ component: AppointmentManageComponent }))
  .directive('appointmentTypes', downgradeComponent({ component: AppointmentTypesComponent }))
  .directive('bookingContainer', downgradeComponent({ component: BookingContainer }))
  .directive(
    'appointmentTypeConfig',
    downgradeComponent({ component: AppointmentTypeConfigComponent }))
  .directive(
    'schedulingOnboarding',
    downgradeComponent({ component: SchedulingOnboardingComponent })
  );
