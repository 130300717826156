import { Component, Input } from '@angular/core';
import { UrlService } from 'utils/url.service';
import { RequestUserService } from 'utils/requestUser.service';
import { string } from 'utils/util';
import { QRCodeComponent } from 'angularx-qrcode';
import { Inject } from '@angular/core';


@Component({
  selector: 'sharing-options',
  templateUrl: './sharing-options.component.html',
  styleUrls: ['./sharing-options.component.scss'],
})
export class SharingOptionsComponent {

  @Input() url!: string;
  @Input() embeddedUrl!: string;
  @Input() id!: string;
  @Input() name!: string;
  @Input() context!: string;

  constructor(
    @Inject('usageTrackingService') private usageTrackingService,

    public urlService: UrlService,
    public requestUserService: RequestUserService,
  ) {
  }

  get embeddedURLSnippet() {
    return string.wrapInIFrame(this.embeddedUrl);
  }

  public track(subEvent: string) {
    const event = `${this.context}.${subEvent}`;
    this.usageTrackingService.createSegmentEvent(event, this.context);
  }

  // Based on https://cordobo.github.io/angularx-qrcode/
  downloadQRCode(qrCode: QRCodeComponent) {
    this.track('downloadedQRCode');
    // converts base 64 encoded image to blobData
    const blobData = this.convertBase64ToBlob(this.getQRCodeSrc(qrCode));
    // saves as image
    const blob = new Blob([blobData], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // name of the file
    link.download = this.name;
    link.click();
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(';base64,');
    // hold the content type
    const imageType = parts[0].split(':')[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for(let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  getQRCodeSrc(qrCode: QRCodeComponent): string {
    return qrCode.qrcElement.nativeElement.querySelector('img')?.src || '';
  }

  getQRCodeBlob(qrCode: QRCodeComponent) {
    const src = this.getQRCodeSrc(qrCode);
    if(!src) {
      return;
    }
    return this.convertBase64ToBlob(src);
  }

  getEmbeddedQRCode(qrCode: QRCodeComponent) {
    return `<img src="${this.getQRCodeSrc(qrCode)}"/>`;
  }

  get supportsCopyQR() {
    // Not supported on FF 60/Safari 13. We hide the button in that case
    // eslint-disable-next-line compat/compat
    return window.ClipboardItem !== undefined;
  }
}
