import './communication';
import './recordings';
import './audioVideo';

import NotifyService from './notify.service';
import BrowserService from './browser.service';


angular
  .module('vecteraUtil')
  .service('notifyService', NotifyService)
  .service('browserService', BrowserService);
