import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon';
import { ProFeatureBadgeComponent } from './pro-feature-badge.component';
import { ProFeatureLockModule } from '../pro-feature-lock';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    MatTooltipModule,
    ProFeatureLockModule
  ],
  declarations: [
    ProFeatureBadgeComponent,
  ],
  exports: [
    ProFeatureBadgeComponent,
  ],
})
export class ProFeatureBadgeModule {}
