import { Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


@Component({
  selector: 'checkbox-input',
  templateUrl: './checkbox-input.component.html',
})
export class CheckboxInputComponent extends ContactFormQuestionInputComponent<string> {
  parsePrefill(string: string) {
    if(string === '1') {
      return '1';
    } else {
      return null;
    }
  }
}
