import { Component, Inject } from '@angular/core';

import { Hint } from 'utils/angularjs/hints/Hint';

import { PrivateNotesService } from './private-notes.service';
import { SmartSummaryService } from '../../../../smart-summary/smart-summary.service';
import { IntegrationSyncService } from '../integration-sync/integration-sync.service';

@Component({
  selector: 'private-notes',
  templateUrl: './private-notes.component.html',
  styleUrls: ['./private-notes.component.scss'],
})
export class PrivateNotesComponent {
  constructor(
    public privateNotesService: PrivateNotesService,
    @Inject('hintService') private hintService,
    private smartSummaryService: SmartSummaryService,
    private integrationSyncService: IntegrationSyncService
  ) {
  }

  public get shouldShowIntegrationSyncButton(): boolean {
    return this.integrationSyncService.activeIntegrations().length > 0;
  }

  public get canUseSmartSummary(): boolean {
    return this.smartSummaryService.canUseSmartSummary;
  }

  public get shouldShowNewIndicator(): boolean {
    return this.integrationSyncService.canSaveNotes() && this.hint.shouldShow;
  }

  public clearNewIndicator(): void {
    this.hint.setSeen();
  }

  private get hint(): Hint {
    return this.hintService.get('meetingNotesSyncMoved');
  }
}
