import logger from '../util/logger';
import * as browser from '../util/browser';

Config.$inject = [
  '$httpProvider',
  '$animateProvider',
  '$locationProvider',
];


export default function Config(
  $httpProvider,
  $animateProvider,
  $locationProvider
) {
  let csrfCookieName = 'vectera-csrftoken';
  let csrfHeaderName = 'X-Vectera-Csrftoken';

  let deployEnv = ANGULAR_SCOPE.deployEnv;
  if(deployEnv && deployEnv !== 'prod') {
    csrfCookieName += '-' + deployEnv;
    csrfHeaderName += '-' + deployEnv[0].toUpperCase() + deployEnv.slice(1);
  }
  $httpProvider.defaults.xsrfCookieName = csrfCookieName;
  $httpProvider.defaults.xsrfHeaderName = csrfHeaderName;

  $animateProvider.classNameFilter(/animate/);


  logger.info('URL:', location.href);
  logger.info('Detected browser:' + JSON.stringify(browser.parsedResult));

  $locationProvider.html5Mode({
    enabled: true,
    rewriteLinks: false,
    requireBase: false,
  });
}
