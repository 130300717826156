import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DropdownOption,
  DropdownSelectComponent
} from '../dropdown-select/dropdown-select.component';



@Component({
  selector: 'multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true,
    },
  ],
})
export class MultiSelectDropdownComponent
  extends DropdownSelectComponent {

  constructor() {
    super();
    this.writeValue([]);
  }

  isOpen = false;
  public selectedOptions: Set<DropdownOption> = new Set<DropdownOption>();

  override pickOption(option: DropdownOption) {
    if(this.getOptionDisabled(option)) {
      return;
    }

    if(this.isSelected(option)) {
      this.selectedOptions.delete(option);
    } else {
      this.selectedOptions.add(option);
    }
    this.writeValue(Array.from(this.selectedOptions).map(option => this.getOptionValue(option)));
  }

  isSelected(option: DropdownOption) {
    return this.selectedOptions.has(option);
  }
}
