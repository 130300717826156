import 'angular';
import 'angular-animate';
import 'angular-cookies';
import './angular-gettext';
import 'angular-sanitize';
import 'angular-dynamic-locale';

let dependencies = [
  'ngSanitize',
  'ngAnimate',
  'ngCookies',
  'gettext',
  'tmh.dynamicLocale',
];
if(window.ANGULAR_SCOPE.sentryEnabled) {
  dependencies.splice(0, 0, 'ngSentry');
}

angular.module('vecteraUtil', dependencies);
