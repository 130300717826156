import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ColorMode } from 'utils/angularjs/profileImage/profileImage.component';


@Directive({
  selector: 'loading'
})
export class LoadingDirective extends UpgradeComponent {
  @Input() size = '';
  @Input() timeout?: number;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('loading', elementRef, injector);
  }
}



@Directive({
  selector: 'profile-image'
})
export class ProfileImageDirective extends UpgradeComponent {
  @Input('user') _user: any;
  @Input('size') _size: number | undefined;
  @Input('color') _color: string | undefined;
  @Input('colorMode') _colorMode: ColorMode = ColorMode.FIXED;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('profileImage', elementRef, injector);
  }
}


export const components = [
  LoadingDirective,
  ProfileImageDirective,
];
