import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Meeting, OrganizationMeeting, UserMeeting } from 'meeting/models/Meeting';
import { LocalStateService } from 'utils/state.service';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { UrlService } from 'utils/url.service';
import { throttle } from 'utils/util';
import { RequestUserService } from 'utils/requestUser.service';
import { DashboardScope } from 'dashboard/data-page/data-page.component';


// Store the meeting rooms so that the dropdown is pre-populated when reopening it.
const state = {
  query: '',
  meetingRooms: [] as Meeting[],
};

/**
 * Show a list of existing meeting rooms in a dropdown menu, with the option to create a new one.
 *
 * Parent components will typically want to listen on `meetingRoomSelected`. However, if the
 * component is instantiated in a menu (as it is intended to be), any subscriptions created in the
 * template are destroyed when the "Create a new meeting room" modal is opened. To avoid this, the
 * parent component should subscribe to `meetingRoomSelected` manually in `ngAfterViewInit`. See
 * `DashboardComponent` for an example.
 */
@Component({
  selector: 'meeting-rooms-menu',
  templateUrl: './meeting-rooms-menu.component.html',
})
export class MeetingRoomsMenuComponent implements OnInit {
  public DashboardScope = DashboardScope;

  /**
   * Any data the parent component may need in the `meetingRoomSelected` subscription. Ignored
   * inside this component.
   */
  @Input() public data: any;  @Input() public scope: Scope = DashboardScope.PERSONAL;
  @Output() meetingRoomSelected = new EventEmitter<Meeting>();

  private fetchDebounced = throttle(this.fetch, 500, true);

  constructor(
    public urlService: UrlService,
    public stateService: LocalStateService,
    private requestUserService: RequestUserService,

    @Inject('modelFactory') private modelFactory,
  ) {}

  ngOnInit() {
    if(state.meetingRooms.length === 0) {
      this.stateService.setState(this.stateService.State.LOADING);
    }
    this.fetchDebounced(this.scope, this.query);
  }


  get meetingRooms() {
    return state.meetingRooms;
  }

  get query() {
    return state.query;
  }
  set query(query) {
    state.query = query;
    this.fetchDebounced(this.scope, query);
  }


  private async fetch(scope: Scope, query: string) {
    const apiConfig = {
      params: {
        search: query,
        perPage: 10,
        filter: 'isDeactivated=false AND oneTimeMeetingDate=null',
        include: 'activeUsers',
      },
    };
    let modelConfig = {};

    switch(scope) {
      case DashboardScope.PERSONAL:
        modelConfig = {
          model: UserMeeting,
          identifiers: { userId: this.requestUserService.user.id },
        };
        break;

      case DashboardScope.TEAM:
        modelConfig = {
          model: OrganizationMeeting,
          identifiers: { organizationId: this.requestUserService.user.organizationId },
        };
        break;

      default:
        throw new Error(`Unknown scope: ${scope}`);
    }

    const { data: meetingRooms } = await this.modelFactory.list(modelConfig, apiConfig);
    state.meetingRooms = meetingRooms;
    this.stateService.setState(this.stateService.State.READY);
  }
}
