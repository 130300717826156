import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvgIcon } from 'utils/ui-components/svg-icon';
import { FilePickerComponent } from '../file-picker.component';
import { MimeType } from '../file-picker.component';

type Alignment = 'end' | 'start' | undefined;

@Component({
  selector: 'image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImagePickerComponent),
      multi: true,
    },
  ],
})
export class ImagePickerComponent extends FilePickerComponent {
 @Input() override allowedTypes = ['image/jpeg' as MimeType, 'image/png' as MimeType];
  @Input() override maxSize = 2 * 1000 * 1000;
  @Input() defaultImage?: SvgIcon | string;
  @Input() alignment?: Alignment;

  get defaultImageAsIcon() {
    if(typeof this.defaultImage === 'string') {
      return undefined;
    } else {
      return this.defaultImage;
    }
  }
  get defaultImageAsUrl() {
    if(typeof this.defaultImage === 'string') {
      return this.defaultImage;
    } else {
      return undefined;
    }
  }


  /** FIXME: VECT-2516
  * The data flow for files is as follows:
  * when POSTing a file, this file should contain a name and base64 url. Our back-end
  * creates a file with that name and a link to our hosting location.
  * When GETing a file, we only receive the hosting url.
  * Currently our front end for images is built to only handle simple string values to display
  * images, be it base64 url or a real url. (etc success image, logo, favicon, ..)
  * We need to introduce an extra layer that transforms the data from the backend into an
  * object that is not gonna be ambigious (is it a hosting location? Is it base64?)
  * This object should contain a name, data and url. We should handle this abstraction in a more
  * complex FileField.
  * This function is overruled to keep the current behaviour because this refactor is out of scope
  * for the whitepaper use case.
   */
  override writeValue(value) {
    if(typeof value === 'string') {
      super.writeValue(value);
    } else {
      super.writeValue(value != null ? value.url : value);
    }
  }
}
