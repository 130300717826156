<div class="container">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <go-back-header
    [url]="urlService.urls.integrationsOverview"
    headerText="Back to integrations"
    i18n-headerText
  ></go-back-header>

  <h1 i18n>HubSpot</h1>

  <div>
    <div class="card">
      <div class="d-flex align-items-center mb-12">
        <svg-icon svgPath="integrations__hubspot_logo" class="hubspot-config__logo"></svg-icon>
        <div>
          <div i18n>Connect your HubSpot account with Vectera, so you can consult the HubSpot data of your customer while having a meeting with them. Additionally, you can sync the meeting room info and private notes to HubSpot.</div>
          <a [attr.href]="'hubspotConfig'|helpArticle" target="_blank" class="text-base text-normal" i18n>Learn more</a>.
        </div>
      </div>

      <loading *ngIf="!stateService.isReady"></loading>

      <div *ngIf="stateService.isReady">
        <div *ngIf="hubSpotConfig == null && !hubSpotService.isExpired">
          <a class="btn btn--primary" [href]="urlService.urls.connectUrl" i18n>Connect</a>
        </div>
        <div
          *ngIf="hubSpotService.isExpired"
          class="mb-5"
        >
          <div class="field field--warning" i18n>Your HubSpot account was disconnected. Please reconnect to keep using the HubSpot integration.</div>
          <a class="btn btn--primary mt-2" [href]="urlService.urls.connectUrl"i18n >Reconnect</a>
        </div>

        <div *ngIf="hubSpotConfig && !hubSpotService.isExpired">
          <div class="mb-5" i18n>Connected account: {{ hubSpotConfig.userEmail }}</div>

          <h2 i18n> Selected properties </h2>

          <i *ngIf="selectedOptions.size === 0" i18n>None so far</i>

          <ul>
            <li
              *ngFor="let option of selectedOptions"
              class="d-flex flex-row align-items-center"
            >
              {{ option.label }}
              <div
                class="btn btn--square ml-2"
                (click)="removeOption(option)"
              >
                <svg-icon class="icon" [svgName]="'trash'"></svg-icon>
              </div>
            </li>
          </ul>

          <div
            class="d-flex flex-row align-items-center"
            *ngIf="hubSpotOptions && selectedOptions.size < 100"
          >
            <span i18n>Search a property to be added</span>
            <combo-box
              class="ml-2"
              [options]="hubSpotOptions"
              [(ngModel)]="currentOption"
              (ngModelChange)="selectCurrentOption()"
            ></combo-box>
          </div>
          <div *ngIf="hubSpotOptions && selectedOptions.size > 100">
            <i i18n>You have reached the limit of 100 selected properties</i>
          </div>

          <div
            class="d-flex flex-row justify-content-end"
            *ngIf="hubSpotConfig && !hubSpotConfig.isExpired"
          >
            <div
              class="btn btn--error mr-2"
              (click)="disconnect()"
              i18n
            >
              Disconnect
            </div>
            <div
              class="btn btn--primary"
              (click)="submit()"
            >
              <span *ngIf="!stateService.isSaving" i18n>Save</span>
              <span *ngIf="stateService.isSaving" i18n>Saving...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
