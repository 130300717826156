import PeerConnectionP2P from '../peerConnections/p2p/PeerConnectionP2P';

export default class PrivateMessagePeerConnectionsService {
  static get $inject() {
    return [
      'privateMessageService',
      'p2pHandleManager',
    ];
  }

  constructor(
    privateMessageService,
    p2pHandleManager
  ) {
    this._bind();

    this.privateMessageService = privateMessageService;
    this.p2pHandleManager = p2pHandleManager;

    this.p2pHandleManager.on('add', this._onAddPeerConnection);
  }

  _bind() {
    this._onAddPeerConnection = this._onAddPeerConnection.bind(this);
    this._onPeerConnectionState = this._onPeerConnectionState.bind(this);
    this._onMessage = this._onMessage.bind(this);
  }

  _onAddPeerConnection(peerConnection) {
    peerConnection.on('state', this._onPeerConnectionState);
    peerConnection.on('privateMessage', this._onMessage);
  }


  _onPeerConnectionState(peerConnection) {
    this._updateSessionPeerConnection(peerConnection.session);
  }


  _updateSessionPeerConnection(session) {
    let peerConnections = this.p2pHandleManager.getSessionPeerConnections(session);
    let peerConnection = peerConnections.find(peerConnection => {
      return peerConnection.state === PeerConnectionP2P.State.CONNECTED;
    });
    this.privateMessageService.setPeerConnection(session, peerConnection);
  }


  _onMessage(peerConnection, data) {
    let [channel, message] = data;
    this.privateMessageService.onUnreliableMessage(channel, peerConnection.session, ...message);
  }
}
