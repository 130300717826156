import { Directive, ElementRef, HostListener } from '@angular/core';


/**
 * Selects the text of an input when it is focused.
 */
@Directive({
  selector: '[selectOnFocus]',
  exportAs: 'selectOnFocus',
})
export class SelectOnFocusDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('focus')
  onFocus() {
    this.elementRef.nativeElement.select();
  }
}
