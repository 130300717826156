<div class="datepicker">
  <h3 class="mb-6" i18n>Choose a meeting date</h3>
  <div class="navigation">
    <svg-icon 
      class="icon clickable"
      [svgName]="'chevron_left'"
      [svgSize]="24"
      (click)="previousMonth()">
    </svg-icon>
    <h4 class="navigation__month mx-6">{{ monthAsString }} {{ visibleYear }}</h4>
    <svg-icon 
      class="icon clickable"
      [svgName]="'chevron_right'"
      [svgSize]="24"
      (click)="nextMonth()">
    </svg-icon>
  </div>
  <div class="days">
    <div class="days__day" *ngFor="let day of dayNames">{{ day }}</div>
  </div>
  <div class="dates" *ngFor="let week of calendar">
    <div *ngFor="let date of week">
      <div *ngIf="date === null" class="dates__date dates__date--invisible"></div>
      <div *ngIf="date !== null"
        class="dates__date clickable"
        [class.dates__date--available]="isAvailable(date)"
        [class.dates__date--today]="isToday(date)"
        [class.dates__date--selected]="isSelected(date)"
        (click)="selectDate(date)"
      >
        {{ displayDate(date) }}
      </div>
    </div>
  </div>
</div>
