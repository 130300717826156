import errors from './errors';
import format from './format';
import { bind } from './misc';


/**
 * NOTE WHEN UPGRADING FROM ANGULARJS
 *
 * This eventemitter is a core functionality of Vectera, but it centers around
 * dynamically adding functions to the class object in which it is setup.
 *
 * This makes it hard to type when upgrading to typescript.
 *
 * The solution was to have the static setup() function return the eventEmitter instance,
 * so that it can be added as an explicit `eventEmitter` class property on the parent
 * component/service/...
 *
 * Concretely, this means that when upgrading any component that uses another component
 * that has an EventEmitter embedded, it should no longer call `component.on()` but
 * `component.eventEmitter.on()` instead
 */
export default class EventEmitter {
  static setup(obj, events, dynamicEvents) {
    if(dynamicEvents == null) {
      dynamicEvents = false;
    }

    if(obj._eventEmitter) {
      if(dynamicEvents !== obj._eventEmitter.dynamicEvents) {
        throw new errors.IllegalStateError('dynamicEvents arguments don\'t match');
      }
      obj._eventEmitter.addEvents(events);

    } else {
      let eventEmitter = new this(events, dynamicEvents);
      obj._eventEmitter = eventEmitter;
      obj.on = eventEmitter.on;
      obj.once = eventEmitter.once;
      obj.off = eventEmitter.off;
      obj.emit = eventEmitter.emit;
      return eventEmitter;
    }
  }

  /**
   * Subscribe to events and emit them
   * @param {array} events The id's of the events to which you can listen
   * @param {boolean} dynamicEvents If true any event id can be passed to
   *                                the "on" function. If it didn't exist
   *                                yet it will simply be created. If false
   *                                an error will be logged in these cases.
   */
  constructor(events, dynamicEvents = false) {
    bind(this);

    this.dynamicEvents = dynamicEvents;

    this.callbacks = {};
    this.addEvents(events);
  }


  addEvents(events) {
    events.forEach(this.addEvent);
  }

  addEvent(event) {
    if(!this.callbacks.hasOwnProperty(event)) {
      this.callbacks[event] = [];
    }
  }


  on(eventsStr, fn, ctx, once = false) {
    if(typeof fn !== 'function') {
      throw new errors.InvalidArgumentError('fn must be a function');
    }

    let events = eventsStr.split(' ');
    events.forEach(event => {
      let exists = this.callbacks.hasOwnProperty(event);

      if(exists || this.dynamicEvents) {
        if(!exists) {
          this.callbacks[event] = [];
        }

        this.callbacks[event].push([fn, ctx, once]);

      } else {
        throw new errors.InvalidArgumentError(format(
          'Unknown event "%s", options are:', event, Object.keys(this.callbacks)));
      }
    });
  }

  once(eventsStr, fn, ctx) {
    return this.on(eventsStr, fn, ctx, true);
  }


  off(eventsStr, fn) {
    let events = eventsStr.split(' ');
    events.forEach(event => {
      let exists = this.callbacks.hasOwnProperty(event);

      if(exists || this.dynamicEvents) {
        if(!exists) {
          this.callbacks[event] = [];
        }

        let eventCallbacks = this.callbacks[event];
        for(let i = 0; i < eventCallbacks.length; i++) {
          if(eventCallbacks[i][0] === fn) {
            eventCallbacks.splice(i, 1);
            i--;
          }
        }

      } else {
        throw new errors.InvalidArgumentError(format(
          'Unknown event "%s", options are:', event, Object.keys(this.callbacks)));
      }
    });
  }


  emit(event, ...emitArgs) {
    if(this.callbacks.hasOwnProperty(event)) {
      let eventCallbacks = this.callbacks[event].slice();
      for(let i = 0; i < eventCallbacks.length; i++) {
        let [fn, ctx, once] = eventCallbacks[i];
        fn.apply(ctx, emitArgs);
        if(once) {
          this.off(event, fn);
        }
      }

    } else if(!this.dynamicEvents) {
      throw new errors.InvalidArgumentError(format(
        'Unknown event "%s", options are:', event, Object.keys(this.callbacks)));
    }
  }
}
