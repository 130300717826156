import { array, bind, EventEmitter, interval, logger } from 'utils/util';
import { MediaDevice } from 'meeting/angularjs/shared/audioVideo/MediaDevice';
import { ReadyToPlayService } from './readyToPlay.service';
import { Session } from 'meeting/angularjs/main/users/Session';
import { User } from 'meeting/angularjs/main/users/User';


export enum StreamType {
  VIDEO = 'video',
  AUDIO = 'audio',

  SCREEN = 'screen',
  SCREEN_AUDIO = 'screen audio',

  COBROWSE = 'cobrowse',
  COBROWSE_AUDIO = 'cobrowse audio',
}

export enum StreamKind {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum StreamState {
  STOPPED,
  REQUESTED,
  CONNECTING,
  CONNECTED,
  PLAYING,
}

export enum ElemState {
  STOPPED,
  STARTING,
  PLAYING,
  FAILED,
}

export enum DeviceKind {
  AUDIO_INPUT = 'audioinput',
  VIDEO_INPUT = 'videoinput',
}

const StreamTypeToKind: Record<StreamType, StreamKind> = Object.freeze({
  [StreamType.VIDEO]: StreamKind.VIDEO,
  [StreamType.AUDIO]: StreamKind.AUDIO,

  [StreamType.SCREEN]: StreamKind.VIDEO,
  [StreamType.SCREEN_AUDIO]: StreamKind.AUDIO,

  [StreamType.COBROWSE]: StreamKind.VIDEO,
  [StreamType.COBROWSE_AUDIO]: StreamKind.AUDIO,
});

export const StreamTypeToDeviceKind: Record<StreamType, DeviceKind> = Object.freeze({
  [StreamType.VIDEO]: DeviceKind.VIDEO_INPUT,
  [StreamType.AUDIO]: DeviceKind.AUDIO_INPUT,

  [StreamType.SCREEN]: DeviceKind.VIDEO_INPUT,
  [StreamType.SCREEN_AUDIO]: DeviceKind.AUDIO_INPUT,

  [StreamType.COBROWSE]: DeviceKind.VIDEO_INPUT,
  [StreamType.COBROWSE_AUDIO]: DeviceKind.AUDIO_INPUT,
});

export const DeviceKindToStreamType: Record<DeviceKind, StreamType> = Object.freeze({
  [DeviceKind.VIDEO_INPUT]: StreamType.VIDEO,
  [DeviceKind.AUDIO_INPUT]: StreamType.AUDIO,
});


const StreamTypeToShouldSilence: Record<StreamType, boolean> = Object.freeze({
  [StreamType.VIDEO]: false,
  [StreamType.AUDIO]: false,

  [StreamType.SCREEN]: false,
  [StreamType.SCREEN_AUDIO]: true,

  [StreamType.COBROWSE]: false,
  [StreamType.COBROWSE_AUDIO]: true,
});


export type ElemInfo = {
  elem: HTMLMediaElement,
  state: ElemState,
  startId: number,
};

export class Stream {
  // When some part of the UI wants to render a stream, it should set this.requested = true.
  public requested = false;
  // Depending on global state, some streams may not be requestable. For example, if
  // SettingsService.disableIncomingVideo is true, all incoming video streams become
  // unrequestable.
  // StreamsRequestableService is responsible for updating this property on all streams.
  // While private requestable === false, private requested is ignored and assumed false.
  public requestable = false;
  // This is analogous to MediaStreamTrack.enabled: enabled === false means the track is muted,
  // and outputs only silence or black frames. Technically the stream is still playing, but as
  // far as the user can tell, it is not.
  public enabled = true;
  public connected: boolean;

  public state: StreamState = StreamState.STOPPED;
  public silence: boolean;
  // Once a stream has worked: ignore future 'silence' events: these are in all likelihood
  // caused by low input + noise filtering in the microphone/OS (audio) or by going to a
  // different tab (video)
  public ignoreSilence = false;

  public track: MediaStreamTrack | null = null;
  public sourceId: string | null = null;
  public mediaStream: MediaStream | null = null;
  public elemInfos: ElemInfo[] = [];
  public stats = null;

  public inputDevice: MediaDevice | null = null;

  public monitoring = false;
  public monitorTrackEndedInterval: number | null = null;
  public eventEmitter: EventEmitter;
  public compatible?: boolean;


  constructor(
    public type: StreamType,
    public id: string,
    public groupId: string,
    public session: Session,
    public readyToPlayService: ReadyToPlayService,
  ) {
    bind(this);
    this.eventEmitter = EventEmitter.setup(this, [
      'state',
      'loadeddata',
      'silence',
      'enabled',
      'ended',
      'inputDevice',
      'message',
    ]);

    this.connected = this.isLocal;
    this.silence = StreamTypeToShouldSilence[type];
  }


  destroy() {
    this.stop();
    this.removeTrack();
    this.setInputDevice(null);
    this.elemInfos.forEach((elemInfo) => this.unsetMediaElem(elemInfo.elem));
  }


  get user(): User {
    return this.session.user;
  }
  get isLocal(): boolean {
    return this.session.isLocal;
  }

  get kind(): StreamKind {
    return StreamTypeToKind[this.type];
  }


  _log(...args) {
    logger.withContext({ stream: this.id }).debug(...args);
  }


  start() {
    this.setRequested(true);
  }
  stop() {
    this.setRequested(false);
  }


  setRequested(requested: boolean) {
    if(requested !== this.requested) {
      this.requested = requested;
      this.updateStreamState();
    }
  }

  setRequestable(requestable: boolean) {
    if(requestable !== this.requestable) {
      this.requestable = requestable;
      this.updateStreamState();
    }
  }

  /**
   * Enable/disable a stream. A disabled stream outputs only frames of silence (audio) or frames
   * filled with black pixels (video). This is useful to temporarily "mute" a local stream, while
   * still keeping the connection to the mic/cam open, as well as the WebRTC connection to other
   * participants. This makes "unmuting" much faster than entirely stopping a stream, and later
   * starting a new stream.
   */
  setEnabled(enabled: boolean) {
    if(enabled !== this.enabled) {
      this.enabled = enabled;
      this.eventEmitter.emit('enabled', this, this.enabled);
      this.updateTrack();
      this.updateMonitoring();
    }
  }

  setConnected(connected: boolean) {
    if(connected !== this.connected) {
      this.connected = connected;
      this.updateStreamState();
    }
  }


  protected updateStreamState() {
    let state;
    if(this.requestable && this.compatible && this.requested) {
      if(this.track) {
        if(this.connected) {
          const elemPlaying = this.elemInfos.some(elemInfo => {
            return elemInfo.state === ElemState.PLAYING;
          });
          if(elemPlaying) {
            state = StreamState.PLAYING;
          } else {
            state = StreamState.CONNECTED;
          }
        } else {
          state = StreamState.CONNECTING;
        }
      } else {
        state = StreamState.REQUESTED;
      }
    } else {
      state = StreamState.STOPPED;
    }

    if(state === this.state) {
      return;
    }

    this._log('set state from %s to %s', this.state, state);
    const oldState = this.state;
    this.state = state;
    if(this.state < StreamState.CONNECTING) {
      this.stats = null;
    }
    this.updateElems();
    this.updateMonitoring();
    this.eventEmitter.emit('state', this, state, oldState);
  }


  setTrack(track: MediaStreamTrack) {
    if(track === this.track) {
      return;
    }
    if(this.track) {
      this.removeTrack();
    }

    this.track = track;
    // upgrade note: https://github.com/w3c/mediacapture-main/pull/13/files
    // webrtc-adapter seems to do some backward-compat magic that makes this work.
    this.sourceId = (track as any).sourceId;
    if(this.track) {
      this.track.addEventListener('ended', this.onTrackEnded);
      this.setMediaStream(new MediaStream([this.track]));
    }
    this.updateTrack();
    this.updateStreamState();
  }

  removeTrack() {
    if(this.track) {
      this.track.removeEventListener('ended', this.onTrackEnded);
    }

    this.track = null;
    this.setMediaStream(null);

    this.updateStreamState();
  }


  protected setMediaStream(mediaStream: MediaStream | null) {
    this.mediaStream = mediaStream;
  }



  setMediaElem(elem: HTMLMediaElement) {
    const elems = this.elemInfos.map(elemInfo => elemInfo.elem);
    if(array.has(elems, elem)) {
      return;
    }

    elem.addEventListener('loadeddata', this.emitLoadedData);
    const elemInfo: ElemInfo = {
      elem: elem,
      state: ElemState.STOPPED,
      startId: 0,
    };
    this.elemInfos.push(elemInfo);
    this.updateElem(elemInfo);
  }

  unsetMediaElem(elem: HTMLMediaElement) {
    if(!elem) {
      return;
    }

    const elemInfo = this.elemInfos.filter(elemInfo => elemInfo.elem === elem)[0];
    if(!elemInfo) {
      return;
    }

    array.remove(this.elemInfos, elemInfo);
    elem.removeEventListener('loadeddata', this.emitLoadedData);
    this.updateElemStop(elemInfo);
  }


  private emitLoadedData() {
    this.eventEmitter.emit('loadeddata');
  }


  private updateTrack() {
    if(this.isLocal && this.track) {
      this.track.enabled = this.enabled;
    }
  }


  updateElems() {
    this.elemInfos.forEach(this.updateElem);
  }

  private updateElem(elemInfo: ElemInfo) {
    if(
      this.state === StreamState.CONNECTED
      || this.state === StreamState.CONNECTING
      || this.state === StreamState.PLAYING
    ) {
      this.updateElemPlay(elemInfo);
    } else {
      this.updateElemStop(elemInfo);
    }
  }


  protected updateElemPlay(elemInfo: ElemInfo): Promise<void> {
    if(
      elemInfo.state === ElemState.STARTING
      || elemInfo.state === ElemState.PLAYING && !elemInfo.elem.paused
    ) {
      return Promise.resolve();
    }

    elemInfo.state = ElemState.STARTING;
    elemInfo.elem.srcObject = this.mediaStream;
    elemInfo.startId++;
    const startId = elemInfo.startId;

    return Promise.resolve().then(() => {
      elemInfo.elem.play();
    }).then(() => {
      elemInfo.state = ElemState.PLAYING;
    }).catch(error => {
      if(startId === elemInfo.startId) {
        logger.warn(error);
        elemInfo.state = ElemState.FAILED;
        elemInfo.elem.srcObject = null;
      }
    }).finally(() => {
      this.updateStreamState();
      this.updateListenForAutoplay();
    });
  }


  private updateElemStop(elemInfo: ElemInfo) {
    elemInfo.state = ElemState.STOPPED;
    elemInfo.elem.srcObject = null;
    elemInfo.startId++;

    this.updateStreamState();
    this.updateListenForAutoplay();
  }


  private updateListenForAutoplay() {
    if(this.elemInfos.some(elemInfo => elemInfo.state === ElemState.FAILED)) {
      this.readyToPlayService.once(this.kind, this.updateElems);
    } else {
      this.readyToPlayService.off(this.kind, this.updateElems);
    }
  }



  /*******************
   * Monitor streams *
   *******************/

  private updateMonitoring() {
    const shouldMonitor = (
      (this.state === StreamState.CONNECTING
        || this.state === StreamState.CONNECTED
        || this.state === StreamState.PLAYING
      )
      && this.enabled
    );
    if(shouldMonitor === this.monitoring) {
      return;
    }

    if(shouldMonitor) {
      this.startMonitoring();
    } else {
      this.stopMonitoring();
    }
  }

  protected startMonitoring() {
    this.monitorTrackEndedInterval = interval.setInterval(this.checkIfTrackEnded, 2000);
    this.monitoring = true;
  }

  protected stopMonitoring() {
    if(this.monitorTrackEndedInterval) {
      interval.clearInterval(this.monitorTrackEndedInterval);
      this.monitorTrackEndedInterval = null;
    }

    this.ignoreSilence = false;
    this.setSilence(StreamTypeToShouldSilence[this.type]);
    this.monitoring = false;
  }

  resetMonitoring() {
    this.stopMonitoring();
    this.updateMonitoring();
  }


  private checkIfTrackEnded() {
    if(this.track && this.track.readyState === 'ended') {
      this.onTrackEnded();
    }
  }

  private onTrackEnded() {
    this.eventEmitter.emit('ended', this);
    this.removeTrack();
  }


  protected setSilence(argSilence: boolean) {
    const silence = argSilence && !this.ignoreSilence && (
      this.session.isLocal || this.session.isAlive() || this.session.isKnocking());

    // Once a stream has worked: ignore future 'silence' events: these are in all likelihood
    // caused by low input + noise filtering in the microphone/OS (audio) or by going to a
    // different tab (video)
    if(!silence) {
      this.ignoreSilence = true;
    }

    if(silence !== this.silence) {
      this.silence = silence;
      this.eventEmitter.emit('silence', this, silence);
    }
  }



  /******************
   * Manage devices *
   ******************/

  setInputDevice(device: MediaDevice) {
    if(device === this.inputDevice) {
      return;
    }

    if(this.inputDevice) {
      this.inputDevice.setStream();
    }

    if(device) {
      this.inputDevice = device;
      this.inputDevice.setStream(this);
    }

    this.eventEmitter.emit('inputDevice', this, device);
  }


  /*********
   * Stats *
   *********/

  setStats(stats) {
    this.stats = stats;
  }
}
