<div class="container">

  <h1 class="mb-5 page-title d-flex justify-content-between">
    <div i18n>Smart Summary Test</div>
    <div class="mb-10">
      <button (click)="clearAll()" class="btn btn--outline-error">
        <span i18n>Clear data</span>
        <svg-icon class="btn__icon--right ml-2" svgName="trash"></svg-icon>
      </button>
    </div>
  </h1>

  <div class="mb-20">
    <h4 i18n>1. Upload audio or input a transcript</h4>
    <label for="audioFile" i18n>Select an audio file:</label>
    <file-picker
      [maxSize]="500 * 1000 * 1000"
      [(ngModel)]="audioFile"
      (ngModelChange)="onAudioFileChanged()"
      [ngModelOptions]="{standalone: true}"
      uploadText="Upload audio file"
      clearText="Clear file"
    ></file-picker>

    <div class="mb-5 mt-10" i18n>
      Or input a transcription:
    </div>

    <div class="mb-5">
      <div i18n>Transcript text</div>
      <textarea
        [(ngModel)]="transcriptInputText"
        (ngModelChange)="buildTranscriptFromInputs()"
        class="textarea"
        rows="5"
      ></textarea>
      <div class="text-muted" i18n>
        Use the format <br><i>Speaker</i><br><i>Start time in mm:ss</i><br><i>Utterance</i><br><i>Speaker</i><br>...
      </div>
      <div class="text-error-400 mt-1" *ngIf="transcriptInputErrorMessage" [innerHTML]="transcriptInputErrorMessage"></div>
      <div class="mt-2" i18n>Language code</div>
      <input
        [(ngModel)]="languageCodeInput"
        (ngModelChange)="buildTranscriptFromInputs()"
        class="input"
      />
      <div class="mt-2" i18n>Duration in seconds (leave empty for guestimation based on transcript text)</div>
      <input
        [(ngModel)]="durationInput"
        (ngModelChange)="buildTranscriptFromInputs()"
        class="input"
        type="number"
      />
    </div>
  </div>

  <div class="mb-20">
    <div class="mb-5">
      <h4 class="">
        <span i18n>2. Transcribe audio</span>
      </h4>
      <button class="btn btn--primary" [ngClass]="{'disabled': isTranscriptButtonDisabled }" (click)="transcribe()">
        <span *ngIf="!isTranscribing" i18n>Transcribe</span>
        <span *ngIf="isTranscribing" class="d-flex align-items-center"><span i18n>Transcribing...</span><loading class="ml-2"></loading> </span>
      </button>
    </div>

    <div *ngIf="transcript">
      <div class="d-flex flex-column mt-3">
        <div i18n>
          Transcript language: {{ transcript.languageCode }} - Transcript duration: {{ transcript.duration }} seconds
        </div>
        <div class="mb-4">
          <div *ngFor="let utterance of transcript.utterances" class="transcription__utterance">
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between mb-2">
                <div class="d-flex align-items-center">
                  <div class="text-bold">{{ utterance.speaker }}</div>
                </div>
                <div>{{ millisToTimePassed(utterance.start) }}</div>
              </div>
              <div>
                {{ utterance.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button (click)="clearTranscriptAndSummary()" class="mt-3 btn btn--outline-error">
        <span i18n>Clear transcript (and summary)</span>
        <svg-icon class="btn__icon--right ml-2" svgName="trash"></svg-icon>
      </button>
    </div>
  </div>


  <div class="mb-20">
    <div>
      <h4 i18n>3. Summarize transcription</h4>

      <div class="mt-5 mb-5">
        <b i18n>Custom prompt</b>
        <textarea
          [(ngModel)]="prompt"
          class="textarea"
          rows="5"
        ></textarea>
        <div class="text-muted" [innerHTML]="promptHint()"></div>
      </div>

      <button class="btn btn--primary" [ngClass]="{'disabled': isSummaryButtonDisabled }" (click)="summarize()">
        <span *ngIf="!isSummarizing" i18n>Summarize</span>
        <span *ngIf="isSummarizing" class="d-flex align-items-center"><span i18n>Summarizing...</span><loading class="ml-2"></loading> </span>
      </button>
    </div>

    <div *ngIf="summary">
      <div class="d-flex flex-column mt-3">
        <div
          [innerHTML]="summary?.asHtml"
        ></div>
      </div>
      <button (click)="clearSummary()" class="mt-3 btn btn--outline-error">
        <span i18n>Clear summary</span>
        <svg-icon class="btn__icon--right ml-2" svgName="trash"></svg-icon>
      </button>
    </div>
  </div>
</div>
