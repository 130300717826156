import MemberService from './member.service';
import MembersComponent from './members.component';
import InviteNewMemberController from './inviteNewMember.controller';


angular
  .module('dashboard')
  .service('memberService', MemberService)
  .component('members', MembersComponent)
  .controller('MembersCtrl', MembersComponent.controller)
  .controller('InviteNewMemberCtrl', InviteNewMemberController);
