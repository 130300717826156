import { browser } from 'utils/util';

const MAX_INPUT_HEIGHT = 84;


ChatInputDirective.$inject = [
  'userService',
];


export default function ChatInputDirective(
  userService
) {
  return {
    restrict: 'C',
    link: function($scope, $elem) {

      // Enter key is used to send messages
      $elem.on('keydown', $event => {
        // On iOS 14, after inserting a emoji through the dropdown, the shift modifier is still
        // active, so pressing enter won't work. Workaround: disable the shift+enter functionality
        // on iOS altogether.
        let hasModifier = $event.shiftKey || $event.ctrlKey;
        let supportsModifierEnter = !browser.isIOS();
        if($event.keyCode === 13 && !(hasModifier && supportsModifierEnter)) {
          $event.preventDefault();
          $scope.$evalAsync(() => {
            $scope.chatCtrl.chatBox.addMessageFromInput();
          });
        }
      });

      $elem.on('input', () => {
        $scope.$evalAsync(() => {
          let me = userService.me;
          $scope.chatCtrl.chatBox.addTyping(me);
        });
      });


      $scope.$watch('chatCtrl.chatBox.input', updateHeight);
      $scope.$on('addToInput', onAddToInput);


      function onAddToInput($event, str) {
        let chatBox = $scope.chatCtrl.chatBox;
        let selectionStart = $elem[0].selectionStart;

        let before = chatBox.input.slice(0, selectionStart);
        let after = chatBox.input.slice(selectionStart);
        chatBox.input = before.concat(str, after);

        selectionStart += str.length;

        $elem.focus();
        $timeout(() => {
          $elem[0].setSelectionRange(selectionStart, selectionStart);
        });
      }


      function updateHeight(isRetry) {
        $elem.css({
          height: 'auto',
          overflowY: 'hidden',
        });
        let height = $elem[0].scrollHeight;

        if(height < 1) {
          if(!isRetry) {
            $timeout(() => updateHeight(true), 500);
          }

        } else if(height <= MAX_INPUT_HEIGHT) {
          $elem.css('height', height);

        } else {
          $elem.css({
            height: MAX_INPUT_HEIGHT,
            overflowY: 'auto',
          });
        }
      }
    }
  };
}
