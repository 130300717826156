import { format, logger, string } from 'utils/util';

/* eslint-disable max-len */
const ERROR_DELETE = gettext('Something went wrong while deleting your meeting room template. Please try again.');
const ERROR_CREATE = gettext('Something went wrong while creating your meeting room template. Please try again.');
const ERROR_UPDATE = gettext('Something went wrong while editing your meeting room template. Please try again.');
const ERROR_DUPLICATE = gettext('Something went wrong while duplicating your meeting room template. Please try again.');
const ERROR_POPULATING_TEMPLATES = gettext('Something went wrong while loading your meeting room templates. Please refresh the page to try again.');
/* eslint-enable max-len */


export default class MeetingTemplateService {
  static get $inject() {
    return [
      'apiService',
      'notificationService',
      'requestUserService',
    ];
  }

  constructor(
    apiService,
    notificationService,
    requestUserService
  ) {
    this._bind();

    this.apiService = apiService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;

    this.meetingTemplates = null;
    this.lastDuplicatedTemplate = null;
    this.loading = true;
    this.updateProgressBar = null;
    this.urls = window.URLS;

    this._populateTemplates();
  }


  _bind() {
    this._populateTemplates = this._populateTemplates.bind(this);
    this._onAPIError = this._onAPIError.bind(this);
    this._onCreationSuccess = this._onCreationSuccess.bind(this);
    this._onAPIFinished = this._onAPIFinished.bind(this);
    this._onAPISuccess = this._onAPISuccess.bind(this);
  }


  get subscription() {
    return this.requestUserService.user.subscription;
  }

  get canCreateTemplates() {
    return this.subscription.status !== 'free';
  }

  /****************
  * Template data *
  ****************/

  _populateTemplates() {
    let url = format(
      'organizations/%s/meetingTemplates?perPage=all&include=createdBy,meeting',
      this.requestUserService.user.organizationId
    );
    return this.apiService.get(url)
      .then(response => this.setMeetingTemplates(response))
      .catch(error => {
        this.notificationService.error(
          gettextCatalog.getString(ERROR_POPULATING_TEMPLATES),
          { delay: -1 }
        );
        logger.warn(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }


  setMeetingTemplates(response) {
    this.meetingTemplates = response.data
      .map(template => this._transformTemplateFromServer(template));
    this.meetingTemplates.sort(this._sortTemplates);
  }

  _transformTemplateFromServer(template) {
    return Object.assign({}, template, {
      lastUpdated: new Date(template.lastUpdated),
      isEditing: false,
    });
  }


  _sortTemplates(template1, template2) {
    return template2.lastUpdated - template1.lastUpdated;
  }


  /**********
  * Actions *
  ***********/

  create(name) {
    return this.apiService.post('meetingTemplates?include=createdBy,meeting', { 'name': name })
      .then(response => this._onCreationSuccess(response))
      .catch(error => this._onAPIError(error, gettextCatalog.getString(ERROR_CREATE)))
      .finally(this._onAPIFinished);
  }


  updateName(template, name) {
    return this.update(template, { name: name });
  }

  update(template, data) {
    let path = format('meetingTemplates/%s?include=createdBy,meeting', template.id);
    return this.apiService.patch(path, data)
      .then(response => this._onUpdateSuccess(template, response.data))
      .catch(error => this._onAPIError(error, gettextCatalog.getString(ERROR_UPDATE)))
      .finally(this._onAPIFinished);
  }


  delete(template) {
    this.setUpdating();
    let path = format('meetingTemplates/%s', template.id);
    this.apiService.delete(path)
      .catch(error => this._onAPIError(error, gettextCatalog.getString(ERROR_DELETE)))
      .finally(this._onAPIFinished);
  }


  duplicate(template, attempt) {
    if(attempt == null) {
      attempt = 1;
    }
    this.setUpdating();
    let name = this._generateDuplicateName(template, attempt);
    let data = {
      'name': name,
      'sourceMeetingId': template.meeting.id
    };
    let path = 'meetingTemplates?include=createdBy,meeting';
    this.apiService.post(path, data)
      .then(response => this._onDuplicateSuccess(response))
      .catch(error => this._onDuplicateError(template, error, attempt))
      .finally(this._onAPIFinished);
  }


  /**
   * Create a new name for the template based on the name of the original template.
   * If duplicates in name or slug exist, the postFix is increased.
   *
   * @param {Object} template- The template that is being duplicated
   * @param {int} postFix - The number which needs to be appended after the name in case of
   *  duplicates. Defaults to 1.
   *
   * @returns {string} the new name of the template
   */
  _generateDuplicateName(template, postFix) {
    if(postFix == null) {
      postFix = 1;
    }
    // Repopulate templates to make sure there are no conflicts
    this._populateTemplates();
    let name = template.name;
    let formatName = '%s-%s';
    while(
      Object.values(this.meetingTemplates)
        .find(template => (
          template.name === format(formatName, name, postFix)
          || string.slugify(template.name) === string.slugify(format(formatName, name, postFix))
        ))
    ) {
      postFix++;
    }

    return format(formatName, name, postFix);
  }


  /**
   * If an error is thrown during duplication we raise a generic error, unless the error
   * indicates the template already exists. In that case, something was probably wrong with
   * the name or slug, so we retry the duplication with a new postFix. This should not happen,
   * since we check for slug/name duplicates in _generateDuplicateName, but the slugify behaviour
   * of Django differs a bit from the front end slugify behaviour, so it's best to keep this error
   * handling. After 10 attempts, we stop trying.
   *
   * @param {Object} template- The template that is being duplicated
   * @param {Object} error - The error containing the message
   * @param {int} attempt - The number of the current attempt to duplicate the template
   *
   */
  _onDuplicateError(template, error, attempt) {
    if(attempt < 10 && error.message && error.message.includes('already exists')) {
      attempt++;
      this.duplicate(template, attempt);
    } else {
      this._onAPIError(error, gettextCatalog.getString(ERROR_DUPLICATE));
    }
  }


  /****************
   * API handling *
   ***************/

  _onCreationSuccess(response) {
    window.open(response.data.url);
  }

  _onUpdateSuccess(template, data) {
    Object.assign(template, this._transformTemplateFromServer(data));
    this.lastDuplicatedTemplate = null;
  }

  _onDuplicateSuccess(response) {
    this.lastDuplicatedTemplate = response.data;
  }

  _onAPISuccess(message) {
    this.notificationService.success(message);
  }

  _onAPIError(error, defaultErrorMessage) {
    let message = error.message || defaultErrorMessage;
    this.notificationService.error(message);
  }

  _onAPIFinished() {
    this._populateTemplates()
      .finally(() => {
        if(this.updateProgressBar) {
          this.updateProgressBar.cancel();
          this.updateProgressBar = null;
        }
      });
  }

  setUpdating() {
    if(this.updateProgressBar) {
      return;
    }

    this.updateProgressBar = this.notificationService.progress(
      gettextCatalog.getString('Updating your meeting room templates')
    );
    this.updateProgressBar.setProgress(100);
  }
}
