import UserTileBinArray from './UserTileBinArray';
import { MIN_TILE_ASPECT_RATIO } from './UserTileBin';
import { platform } from 'utils/util';


export default class UserTileLayout {
  constructor(tiles, rect, spacing) {
    this.tiles = tiles;
    this.rect = rect.clone();
    this.spacing = spacing;

    this.binArray = this._partitionFlexibleTiles();
  }


  _partitionFlexibleTiles() {
    // Calculate the candidates for the number of big bins
    let numTiles = this.tiles.length;
    let totalAspectRatio = this.tiles.reduce(
      (totalAspectRatio, tile) => totalAspectRatio += this._getTileAspectRatio(tile),
      0);
    let numBinsOptimal = Math.sqrt(totalAspectRatio * this.rect.height / this.rect.width);

    let numBinsCandidates;
    if(numBinsOptimal > numTiles) {
      numBinsCandidates = [numTiles];

    } else {
      numBinsCandidates = [Math.ceil(numBinsOptimal)];
      if(numBinsOptimal > 1) {
        numBinsCandidates.push(Math.floor(numBinsOptimal));
      }
    }

    // For all numBins candidates: get the bins, and check the tile height.
    // Pick the set of bins that has the biggest tile height.
    // Assume all bins in a set have the same height.
    let bestBinArray;
    for(let i = 0; i < numBinsCandidates.length; i++) {
      let numBins = numBinsCandidates[i];
      let binArray = this._partitionTiles(numBins);

      if(!bestBinArray || binArray.tileHeight > bestBinArray.tileHeight) {
        bestBinArray = binArray;
      }
    }

    return bestBinArray;
  }


  _partitionTiles(argNumBins) {
    let binArray = new UserTileBinArray(this.rect, this.spacing);
    let numTiles = this.tiles.length;
    if(numTiles === 0) {
      return binArray;
    }

    // Dynamic programming algorithm based on the Algorithmic Design Manual

    let numBins = Math.min(argNumBins, numTiles);
    let aspectRatios = this.tiles.map(tile => this._getTileAspectRatio(tile));

    let p = [aspectRatios[0]];
    for(let i = 1; i < numTiles; i++) {
      p[i] = p[i - 1] + aspectRatios[i];
    }

    let m = [], d = [];
    for(let i = 0; i < numTiles; i++) {
      m.push([p[i]]);
      d.push([]);
    }
    for(let j = 0; j < numBins; j++) {
      m[0][j] = aspectRatios[0];
    }

    for(let i = 1; i < numTiles; i++) {
      for(let j = 1; j < numBins; j++) {

        for(let x = 0; x < i; x++) {
          let cost = Math.max(m[x][j - 1], p[i] - p[x]);
          if(x === 0 || m[i][j] > cost) {
            m[i][j] = cost;
            d[i][j] = x;
          }
        }
      }
    }

    // Reconstruct partitions
    let end = numTiles - 1;
    for(let binIndex = numBins - 1; binIndex >= 0; binIndex--) {
      let begin = d[end][binIndex];
      let binTiles = this.tiles.slice(begin + 1, end + 1);
      end = begin;

      binArray.addBin(binTiles, 0);
    }

    return binArray;
  }


  _getTileAspectRatio(tile) {
    return platform(MIN_TILE_ASPECT_RATIO, tile.aspectRatio);
  }


  draw() {
    this.binArray.draw();
  }
}
