import { Directive, ViewContainerRef } from '@angular/core';

/**
 * This directive enables AngularWizardComponent to include a component that is passed to the
 * Wizard constructor dynamically.
 */
@Directive({
  selector: '[wizardHost]',
})
export class WizardHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
