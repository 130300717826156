import template from './sharedPointer.html?raw';

import { format } from 'utils/util';
import { SHARED_POINTER_CLICK_DURATION } from '../../../variables';



class SharedPointerController {
  static get $inject() {
    return [
      '$element',
    ];
  }

  constructor(
    $elem
  ) {
    this._bind();

    this.tile = null;
    this.show = false;

    this.$elem = $elem;
    this.$elemPointer = null;
  }

  _bind() {
    this._onPosition = this._onPosition.bind(this);
    this._draw = this._draw.bind(this);
    this._onClick = this._onClick.bind(this);
  }

  $onInit() {
    this.$elemPointer = this.$elem.find('.shared-pointer');
    this.pointer.on('position', this._onPosition);
    this.pointer.on('click', this._onClick);
  }


  _onPosition() {
    this._updateTile();
    this._draw();
  }


  _updateTile() {
    if(this.pointer.tile === this.tile) {
      return;
    }

    if(this.tile) {
      this.tile.off('draw', this._draw);
      this.tile = null;
    }

    if(this.pointer.tile) {
      this.tile = this.pointer.tile;
      this.tile.on('draw', this._draw);
    }
  }


  _draw() {
    if(this.pointer.position && this.tile && this.tile.active) {
      let position = { x: null, y: null };

      if(this.tile.whiteboard) {
        let wb = this.tile.whiteboard;

        // the pointer position is in pts relative to content origin
        // translate to pixel values relative to window origin
        position.x = this.tile.rectBody.left; // start from tile edge
        position.x += wb.pixelContentOffset.x; // add content offset
        // add the pointer distance by translating pts pointer distance to px
        position.x += (this.pointer.position.x * wb.pixelsPerPoint);

        position.y = this.tile.rectBody.left;
        position.y += wb.pixelContentOffset.y;
        position.y += (this.pointer.position.y * wb.pixelsPerPoint);
      } else {
        position.x = this.tile.rectBody.left + this.tile.rectBody.width  * this.pointer.position.x;
        position.y = this.tile.rectBody.top  + this.tile.rectBody.height * this.pointer.position.y;
      }
      this.show = true;
      this.$elemPointer.css('transform', `translate(${position.x}px, ${position.y}px)`);
    } else {
      this.show = false;
    }
  }


  _onClick() {
    if(!this.$elemPointer) {
      return;
    }

    let $elemNotify = angular.element(format(
      '<div class="shared-pointer__notify" style="background: %s"></div>',
      this.pointer.user.color));

    this.$elemPointer.append($elemNotify);
    $timeout(() => {
      $elemNotify.remove();
    }, SHARED_POINTER_CLICK_DURATION);
  }
}


export default {
  controller: SharedPointerController,
  controllerAs: 'sharedPointerCtrl',
  template,

  bindings: {
    'pointer': '<',
  },
};
