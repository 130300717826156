import { errors, format, set, file, EventEmitter, Rect, logger } from 'utils/util';
import filetypes from 'main/supportedFiletypes.json';


// KEEP THIS IN SYNC with meeting/models.py:File.Type
const Type = Object.freeze({
  UNSUPPORTED: 'not supported',
  PDF: 'pdf',
  DOCUMENT: 'document',
  BITMAP: 'bitmap',
  VECTOR: 'vector',
});

const SPREADSHEET_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template',
  'application/vnd.google-apps.spreadsheet'
];

const SPREADSHEET_EXTENSIONS = [
  'xls',
  'xlsx',
  'xlts',
  'xlsm',
  'xltm',
  'xlam',
  'xlsb',
  'ods',
  'ots'
];

const ConvertedToPdf = Object.freeze({
  TRUE: 'true',
  FALSE: 'false',
});


const [SUPPORTED_MIME_TYPES, SUPPORTED_EXTENSIONS] =
  constructMimeTypesAndExtensions().map(set.freeze);


function constructMimeTypesAndExtensions() {
  let mimeTypes = new Set();
  let extensions = new Set();
  for(let type in filetypes) {
    if(type === '__comment__') {
      continue;
    }
    filetypes[type].mimetype.forEach(mimetype => mimeTypes.add(mimetype));
    filetypes[type].extension.forEach(extension => extensions.add(extension));
  }

  return [mimeTypes, extensions];
}



export default class VecteraFile {
  static get Type() {
    return Type;
  }
  static get ConvertedToPdf() {
    return ConvertedToPdf;
  }

  static isSupported(mimeType, extension) {
    return (
      mimeType && SUPPORTED_MIME_TYPES.has(mimeType)
      || extension && SUPPORTED_EXTENSIONS.has(extension));
  }

  static isSpreadsheet(mimeType, extension) {
    return (
      mimeType && SPREADSHEET_TYPES.includes(mimeType)
      || extension && SPREADSHEET_EXTENSIONS.includes(extension));
  }

  constructor(apiService, urlTemplate, id) {
    EventEmitter.setup(this, ['info']);

    this.apiService = apiService;

    this.id = id;
    this.hasInfo = false;
    this.name = '';
    this.type = null;
    this.size = 0;

    this.numPages = 0;
    this.rect = new Rect();
    this.rotation = 0;
    this.flip = false;

    this.urls = {
      [ConvertedToPdf.FALSE]: {
        remote: format(urlTemplate, this.id, 'file'),
        local: null,
      },
      [ConvertedToPdf.TRUE]: {
        remote: this.canBeConvertedToPdf ? format(urlTemplate, this.id, 'pdf') : null,
        local: null,
      },
    };

    this.downloadDeferInfos = {
      [ConvertedToPdf.FALSE]: null,
      [ConvertedToPdf.TRUE]: null,
    };
    this.downloadProgress = {
      [ConvertedToPdf.FALSE]: 0,
      [ConvertedToPdf.TRUE]: 0,
    };
  }


  setInfo(info) {
    this.hasInfo = true;
    this.name = info.name;
    this.type = info.filetype;
    this.size = info.size;

    // Backwards compatibility
    this.numPages = info.pages.length;
    if(this.numPages > 0) {
      this.rect = new Rect({
        width: info.pages[0][2],
        height: info.pages[0][3],
      });
      this.rotation = info.pages[0][4];
      this.flip = !!info.pages[0][5];
    }

    if(this.rotation % 180 === 90) {
      this.rect = new Rect({
        width: this.rect.height,
        height: this.rect.width,
      });
    }

    this.emit('info', this);
  }

  get canBeConvertedToPdf() {
    return !(this.type === Type.BITMAP || this.type === Type.VECTOR);
  }


  _checkValidConverted(converted) {
    if(converted === ConvertedToPdf.TRUE && !this.canBeConvertedToPdf) {
      throw new errors.FileTypeError(format(
        'Cannot get pdf url for file of type %s', this.type));
    }
  }


  setLocalFile(file, converted) {
    this._checkValidConverted(converted);

    // When a local file has an incorrect extension: we don't set it, it will have to be
    // downloaded
    if(file.name !== this.name && (this.type === Type.PDF || converted === ConvertedToPdf.FALSE)) {
      return;
    }

    let url = URL.createObjectURL(file);
    if(this.type === Type.PDF) {
      this.urls[ConvertedToPdf.FALSE].local = url;
      this.urls[ConvertedToPdf.TRUE].local = url;
    } else {
      this.urls[converted].local = url;
    }
  }


  getLocalUrl(converted, shouldShowProgress) {
    this._checkValidConverted(converted);
    let url = this.urls[converted].local;

    if(url) {
      return $q.resolve(url);
    } else {
      return this._fetch(converted, shouldShowProgress)
        .then(() => this.getLocalUrl(converted, shouldShowProgress));
    }
  }


  _fetch(converted, shouldShowProgress) {
    if(!this.downloadDeferInfos[converted]) {
      let info = {
        defer: $q.defer(),
        error: null,
        downloading: false,
      };
      if(this.type === Type.PDF) {
        this.downloadDeferInfos[ConvertedToPdf.FALSE] = info;
        this.downloadDeferInfos[ConvertedToPdf.TRUE] = info;
      } else {
        this.downloadDeferInfos[converted] = info;
      }
    }

    let info = this.downloadDeferInfos[converted];
    if(!info.downloading) {
      this._fetchNow(info, converted, shouldShowProgress);
    }

    return info.defer.promise;
  }


  _fetchNow(info, converted, shouldShowProgress) {
    info.downloading = true;
    let apiConfig = {
      eventHandlers: {
        progress: this._onProgress.bind(this, converted),
      },
      // It's not clear why, but non-incognito Chrome refuses to download big files when
      // responseType = blob.
      responseType: 'arraybuffer',
      maxRetries: Infinity,
    };
    if(shouldShowProgress) {
      apiConfig.message = gettextCatalog.getString(
        'Downloading <b>{{ filename }}</b>',
        { filename: this.name }
      );
    }

    this.apiService.get(this.urls[converted].remote, apiConfig)
      .then(response => {
        let arrayBuffer = response.data;
        let blob = new Blob([arrayBuffer], { type: response.headers('Content-Type') });
        let localFile = file.fromBlob(blob, this.name);
        this.setLocalFile(localFile, converted);

        info.error = null;
        info.defer.resolve();
      })
      .catch(error => {
        // The only known case where this happens, is if the host kicks you out of the meeting
        // before you have had time to start the download of a file. This results in a
        // NotAuthorizedError, which can safely be ignored. The request will be retries upon
        // re-joining.
        logger.warn(error);
        info.error = error;
      })
      .finally(() => {
        info.downloading = false;
      });
  }


  _onProgress(converted, event) {
    let progress = event.loaded / event.total * 100;
    if(this.type === Type.PDF) {
      this.downloadProgress[ConvertedToPdf.FALSE] = progress;
      this.downloadProgress[ConvertedToPdf.TRUE] = progress;
    } else {
      this.downloadProgress[converted] = progress;
    }
  }
}
