import { format } from 'utils/util';

export default class SaveAsTemplateController {
  static get $inject() {
    return [
      '$element',
      'meetingTemplateConfigService',
      'notificationService',
    ];
  }

  constructor(
    $elem,
    meetingTemplateConfigService,
    notificationService
  ) {
    this._bind();

    this.$elem = $elem;
    this.meetingTemplateConfigService = meetingTemplateConfigService;
    this.notificationService = notificationService;

    this.templateName = null;

    this.savingAsTemplate = false;
    this.savingAsTemplateErrorMessage = null;

    this.copiedSettings = [
      {
        'name': gettextCatalog.getString('Public and team access level'),
        'icon': 'utils/icons/tl/24x24_lock_outline.svg'
      },
      {
        'name': gettextCatalog.getString('Whiteboards and files'),
        'icon': 'utils/icons/tl/24x24_document_outline.svg'
      },
      {
        'name': gettextCatalog.getString('Private notes'),
        'icon': 'utils/icons/tl/24x24_visible_outline.svg'
      },
      {
        'name': gettextCatalog.getString('Presenter mode setting'),
        'icon': 'utils/icons/tl/24x24_settings_outline.svg'
      }
    ];

    this.SAVING_AS_TEMPLATE_DEFAULT_ERROR = gettextCatalog.getString(
      'We could not save the meeting room as a meeting room template. Please try again.'
    );
    this.SAVING_AS_TEMPLATE_SUCCESS = gettextCatalog.getString(
      'Meeting room template successfully created. <a {{ url }}> View all templates</a>',
      { url: format('target="_blank" href="%s"', window.URLS.meetingTemplates) }
    );
  }

  _bind() {
    this._onSaveSuccess = this._onSaveSuccess.bind(this);
    this._onSaveError = this._onSaveError.bind(this);
  }

  saveAsTemplate() {
    this.savingAsTemplate = true;
    this.meetingTemplateConfigService.saveAsTemplate(this.templateName)
      .then(this._onSaveSuccess)
      .catch(error => this._onSaveError(error));
  }


  _onSaveSuccess() {
    this.meetingTemplateConfigService.hideSaveAsTemplateModal();
    this.notificationService.success(this.SAVING_AS_TEMPLATE_SUCCESS);
    this.savingAsTemplate = false;
  }


  _onSaveError(error) {
    this._setSavingAsTemplateErrorMessage(error.message || this.SAVING_AS_TEMPLATE_DEFAULT_ERROR);
    this.savingAsTemplate = false;
  }


  _setSavingAsTemplateErrorMessage(errorMessage) {
    this.savingAsTemplateErrorMessage = errorMessage;
  }
}
