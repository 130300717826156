<div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

<ng-template #promo>
  <div class="promo-field__steps">
    <div class="promo-field__step">
      <svg-icon
        class="promo-field__step-image"
        [svgPath]="'contactForm__welcomeCreate'"
      ></svg-icon>
      <div *ngIf="!isTeamleader" class="promo-field__step-title" i18n>Create an online contact form</div>
      <div *ngIf="isTeamleader" class="promo-field__step-title" i18n>Create an online form and link it to Teamleader Focus</div>
    </div>

    <div class="promo-field__step">
      <svg-icon
        class="promo-field__step-image"
        [svgPath]="'contactForm__welcomeShare'"
      ></svg-icon>
      <div class="promo-field__step-title" i18n>Share it in an email, via a link, or embed it into your site</div>
    </div>

    <div class="promo-field__step">
      <svg-icon
        class="promo-field__step-image"
        [svgPath]="'contactForm__welcomeLeads'"
      ></svg-icon>
      <div *ngIf="!isTeamleader" class="promo-field__step-title" i18n>Get valuable leads directly in {{ siteService.site.productName }}</div>
      <div *ngIf="isTeamleader" class="promo-field__step-title" i18n>Get valuable leads directly in Teamleader Focus</div>
    </div>
  </div>
</ng-template>


<div class="container" *ngIf="shouldShowWelcome" data-testid="contactFormPromoField">
  <div class="promo-field">
    <div class="promo-field__title" i18n>Try an easy way to capture qualitative leads</div>

    <ng-template *ngTemplateOutlet="promo"></ng-template>

    <a
      class="btn btn--primary btn--lg"
      [href]="urlService.urls.contactFormCreate"
      (click)="track('contactForm.clickedGetStarted')"
      data-testid="getStartedButton"
      i18n
    >
      Get started
    </a>
  </div>
</div>

<div class="container" *ngIf="shouldShowMain">
  <div class="d-flex page-title align-items-center">
    <div i18n class="mr-3">Contact forms</div>
    <svg-icon
      class="icon clickable mr-3"
      [svgName]="'info_badged'"
      *ngIf="!shouldShowHint"
      (click)="shouldShowHint = true"
      matTooltip="Click for more info"
      i18n-matTooltip
    ></svg-icon>

    <div class="ml-auto">
      <div class="d-none d-sm-flex">
        <div
          *ngIf="!canCreate"
          class="btn btn--primary mr-2 mb-2 disabled"
          matTooltip="Upgrade your subscription to create more than one contact form"
          i18n-matTooltip
          data-testid="createContactFormButton"
          i18n
        >
          Create a form
        </div>

        <a
          *ngIf="canCreate"
          [attr.href]="urlService.urls.contactFormCreate"
          class="a-plain btn btn--primary"
          data-testid="createContactFormButton"
          i18n
        >
          Create a form
        </a>
      </div>


      <div class="d-sm-none">
        <div
          *ngIf="!canCreate"
          class="btn btn--primary btn--round disabled"
          matTooltip="Upgrade your subscription to create more than one contact form"
          i18n-matTooltip
        >
          <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
        </div>

        <a
          *ngIf="canCreate"
          [attr.href]="urlService.urls.contactFormCreate"
          class="btn btn--primary btn--round"
        >
          <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="promo-field" *ngIf="shouldShowHint">
    <div class="promo-field__title d-flex flex-wrap align-self-start align-items-center">
      <span i18n>What can forms do for you?</span>
      <a  [attr.href]="'contactFormsOverview'|helpArticle" target="_blank" class="ml-2 text-base text-normal" i18n>Learn more</a>
    </div>

    <ng-template *ngTemplateOutlet="promo"></ng-template>

    <div
      class="btn btn--secondary"
      (click)="shouldShowHint = false"
      i18n
    >
      Got it!
    </div>
  </div>

  <div class="mb-10 d-md-flex">
    <scope-switcher
      [(scope)]="scope"
      [scopes]="scopes"
    ></scope-switcher>
  </div>

  <fetch-error [fetchError]="fetchError" (tryAgain)="refetch()"></fetch-error>

  <div *ngIf="contactForms.length > 0">
    <div
      *ngIf="requestUserService.user.organization.subscription.status === 'free'"
      class="field field--warning mb-5"
    >
      <field-icon [type]="'warning'"></field-icon>
      {{ contactFormLimitInfo }}
    </div>

    <contact-forms-table
      [contactForms]="contactForms"
      [canCreate]="canCreate"
      (contactFormChange)="refetch()"
    ></contact-forms-table>

    <div class="d-flex justify-content-center mt-4">
      <pagination
        [(page)]="page"
        [numPages]="numPages"
      ></pagination>
    </div>
  </div>
</div>
