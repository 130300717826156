import { StreamKind } from  'meeting/meeting-room/stream';
import { bind, format } from 'utils/util';


const SET_REQUESTABLE_TIMEOUT = 15000;

// const MAX_VIDEO_STREAMS = 2;


/**
 * Manage stream.requestable for all streams.
 */
export default class StreamsRequestableService {
  static get $inject() {
    return [
      'meetingService',
      'pictureInPictureService',
      'settingsService',
      'streamService',
      'userService',
      'meetingBroadcastService',
    ];
  }

  constructor(
    meetingService,
    pictureInPictureService,
    settingsService,
    streamService,
    userService,
    meetingBroadcastService
  ) {
    bind(this);

    this.meetingService = meetingService;
    this.pictureInPictureService = pictureInPictureService;
    this.settingsService = settingsService;
    this.streamService = streamService;
    this.userService = userService;
    this.meetingBroadcastService = meetingBroadcastService;

    this.streamService.on('add', this._onStreamAdd);
    this.userService.mySession.on('state', this._onMySessionState);
    this.settingsService.on('disableIncomingVideo', this._onDisableIncoming);
    this.meetingBroadcastService.on('stream-add', this._onStreamEnabled, true);
    this.meetingBroadcastService.on('stream-enabled', this._onStreamEnabled, true);
    this.meetingBroadcastService.on('session-exit', this._onSessionExit, true);
    this.meetingBroadcastService.on('session-join', this._onSessionJoin, true);

    this.setRequestableTimeouts = {};
  }


  _onStreamAdd(stream) {
    this._updateStream(stream, true);
  }

  _onStreamEnabled() {
    if(this.meetingService.bednetLocation === this.meetingService.BednetLocation.TEACHER) {
      const disableIncomingAudio = this.shouldDisableIncomingAudioForBednetTeacher();
      this._updateAllStreams(true, disableIncomingAudio);
    }
  }

  _onSessionExit(channel, sessionId, timestamp, exitReason, extra = {}) {
    if(
      this.meetingService.bednetLocation === this.meetingService.BednetLocation.TEACHER
      && extra.bednet_location
      && extra.bednet_location === this.meetingService.BednetLocation.CLASSROOM
    ) {
      this._updateAllStreams(true, false);
    }
  }

  _onSessionJoin(channel, session, datetime, userId, accessLevel, extra = {}) {
    if(
      this.meetingService.bednetLocation === this.meetingService.BednetLocation.TEACHER
      && extra.bednet_location
      && extra.bednet_location === this.meetingService.BednetLocation.CLASSROOM
    ) {
      this._updateAllStreams(true, true);
    }
  }

  _onMySessionState() {
    const disableIncomingAudio = this.shouldDisableIncomingAudioForBednetTeacher();
    this._updateAllStreams(true, disableIncomingAudio);
  }
  _onVisible() {
    const disableIncomingAudio = this.shouldDisableIncomingAudioForBednetTeacher();
    this._updateAllStreams(false, disableIncomingAudio);
  }
  _onDisableIncoming() {
    const disableIncomingAudio = this.shouldDisableIncomingAudioForBednetTeacher();
    this._updateAllStreams(true, disableIncomingAudio);
  }

  _getTimeoutId(stream) {
    let sessionId = stream.session.isLocal ?
      'local' :
      stream.session.id;
    return format('%s-%s', sessionId, stream.id);
  }

  /**
   *
   * @param {boolean} quickSetRequestable - stream should update immediately or with a delay
   * @param {boolean} disableIncomingAudio
   */
  _updateAllStreams(quickSetRequestable, disableIncomingAudio = false) {
    this.streamService.streams.forEach(stream => {
      this._updateStream(stream, quickSetRequestable, disableIncomingAudio);
    });
  }

  /**
   *
   * @param {Stream} stream
   * @param {boolean} quickSetRequestable - stream should update immediately or with a delay
   * @param {boolean} disableIncomingAudio
   */
  _updateStream(stream, quickSetRequestable, disableIncomingAudio = false) {
    let requestable = this._isRequestable(stream, disableIncomingAudio);
    let quick = quickSetRequestable || requestable || stream.isLocal;
    let timeout = quick ? 0 : SET_REQUESTABLE_TIMEOUT;
    this._setRequestable(stream, requestable, timeout);
  }

  /**
   * Audio should be disabled if there is a classroom session present in the MR which is actively
   * streaming (audio or video)
   *
   * calling this function is is only possible when the streams are actually present in the MR.
   * We can't use this logic for session-join or session-exit events.
   * @returns {boolean}
   */
  shouldDisableIncomingAudioForBednetTeacher() {
    const enabledClassroomStream = this.streamService.streams.find(stream => {
      return (
        stream.enabled
        && stream.session.user.extra.bednet_location === 'classroom'
      );
    });

    return this.meetingService.bednetLocation === this.meetingService.BednetLocation.TEACHER
      && enabledClassroomStream !== undefined;
  }

  /**
   * Determine if a stream should be received/sent by the user
   *
   * @param {Stream} stream
   * @param {boolean} disableIncomingAudio
   * @returns {boolean}
   */
  _isRequestable(stream, disableIncomingAudio = false) {
    if(!stream.isLocal && !this.userService.mySession.isJoined()) {
      return false;
    }

    switch(stream.kind) {
      case StreamKind.AUDIO:
        return (
          stream.isLocal
          || (
            !stream.isLocal
            && !disableIncomingAudio
          )
        );
      case StreamKind.VIDEO:
        return (
          stream.isLocal
          || (!stream.isLocal && !this.settingsService.disableIncomingVideo)
        );
    }
  }

  /**
   * Determine if a stream should be received/sent by the user by setting the stream.requestable
   * property
   *
   * @param {Stream} stream
   */
  _setRequestable(stream, requestable, timeout) {
    let timeoutId = this._getTimeoutId(stream);
    $timeout.cancel(this.setRequestableTimeouts[timeoutId]);
    if(requestable === stream.requestable) {
      return;
    }

    this.setRequestableTimeouts[timeoutId] = $timeout(
      () => stream.setRequestable(requestable),
      timeout
    );
  }
}
