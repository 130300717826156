import templateDropdown from './emoji.dropdown.html?raw';
import Dropdown from 'utils/angularjs/dropdown/Dropdown';

export const DROPDOWN_ID = 'emoji';


export default class EmojiService {
  static get $inject() {
    return [
      'dropdownService',
    ];
  }

  constructor(
    dropdownService
  ) {
    this.dropdownService = dropdownService;

    dropdownService.register(
      DROPDOWN_ID,
      templateDropdown,
      { cssClasses: 'dropdown-deprecated--no-margin', position: Dropdown.Position.TOP }
    );

    this.dropdownChatCtrl = null;
  }

  toggleDropdown($event, chatCtrl) {
    this.dropdownChatCtrl = chatCtrl;
    this.dropdownService.toggle(DROPDOWN_ID, $event);
  }

  hideDropdown() {
    this.dropdownService.hide(DROPDOWN_ID);
  }
}
