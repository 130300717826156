import ReliableSocketService from 'meeting/angularjs/shared/communication/reliableSocket.service';

export default class WaitingRoomReliableSocketService extends ReliableSocketService {
  static get $inject() {
    return [
      'waitingRoomSocketService',
    ].concat(ReliableSocketService.$inject);
  }

  constructor(waitingRoomSocketService, ...args) {
    super(...args, waitingRoomSocketService);
  }
}
