import { WizardStepComponent } from 'utils/wizard/WizardStep';
import { Component, OnInit } from '@angular/core';
import { bind } from 'utils/util';
import SchedulingConfig from 'scheduling/models/SchedulingConfig';
import { SchedulingState } from 'scheduling/variables';
import { SchedulingConfigService } from 'scheduling/schedulingConfig.service';
import { StateService } from 'utils/state.service';
import { RequestUserService } from 'utils/requestUser.service';
import { UsageTrackingService } from 'utils/usage-tracking.service';

export type Provider = {
  title: string,
  id: string,
  login: string,
  logo: string,
}


@Component({
  selector: 'calendar-connect',
  templateUrl: './calendar-connect.component.html',
  styleUrls: ['./calendar-connect.component.scss'],
  providers: [
    { provide: WizardStepComponent, useExisting: CalendarConnectComponent },
    StateService
  ]
})

export class CalendarConnectComponent extends WizardStepComponent implements OnInit {
  private schedulingConfig: SchedulingConfig;

  constructor(
    private usageTrackingService: UsageTrackingService,
    private requestUserService: RequestUserService,
    private schedulingConfigService: SchedulingConfigService,
    public stateService: StateService
  ) {
    super();
    bind(this);
  }

  ngOnInit(): void {
    this.getSchedulingConfig();
  }

  get providers(): Provider[] {
    return JSON.parse(ANGULAR_SCOPE.providers);
  }

  providerTrackBy(index: number, provider: Provider) {
    return provider.id;
  }

  get isReady(): boolean {
    return (
      this.stateService.isReady
      || this.requestUserService.user.schedulingConfig.state === SchedulingState.NEW
    );
  }

  get connectedProviderId() {
    return this.schedulingConfig?.providerId;
  }

  get isUnauthorized() {
    return this.schedulingConfig?.state === SchedulingState.AUTHENTICATED;
  }

  get showIsAuthorized() {
    return this.schedulingConfig?.providerId;
  }

  get isConnectedString() {
    return this.isUnauthorized ? $localize`Not authorized` : $localize`Connected`;
  }

  private getSchedulingConfig(showLoading = true) {
    if (showLoading) {
      this.stateService.setState(this.stateService.State.LOADING);
    }

    this.schedulingConfigService.get().then(schedulingConfig => {
      this.schedulingConfig = schedulingConfig;
      if (showLoading) {
        this.stateService.setState(this.stateService.State.READY);
      }
    });
  }

  disconnect() {
    this.schedulingConfig.disconnect();
  }

  retry() {
    this.schedulingConfig.disconnect();
  }


  override validate() {
    return !!this.connectedProviderId && !this.isUnauthorized;
  }

  trackProviderClick(provider: Provider) {
    if (this.connectedProviderId === provider.id) {
      return;
    }

    this.usageTrackingService.createSegmentEvent(
      'onboardingFlow.startedAddingCalendar',
      'onboardingFlow',
      {
        calendarType: provider.title,
      }
    );
  }
}
