import { format } from 'utils/util';

export default class MemberService {
  static get $inject() {
    return [
      'apiService',
      'notificationService',
      'requestUserService',
    ];
  }

  constructor(
    apiService,
    notificationService,
    requestUserService
  ) {
    this._bind();

    this.apiService = apiService;
    this.notificationService = notificationService;
    this.requestUserService = requestUserService;

    this.organization = window.ANGULAR_SCOPE.viewOrganization;
    this.urls = window.URLS;
    this.hasPermissionToManage = window.HAS_PERMISSION_TO_MANAGE;

    this.members = [];
    this.adminMembers = [];
    this.displayMembers = [];
    this.numMembers = 0;
    this.requestMember = null;
    this._populateMembers();
  }

  _bind() {
    this._populateMembers = this._populateMembers.bind(this);
    this._sortMembers = this._sortMembers.bind(this);
    this._onAPIError = this._onAPIError.bind(this);
  }

  get subscription() {
    return this.requestUserService.user.subscription;
  }

  get apiPathBase() {
    return format('organizations/%s/', this.organization.id);
  }
  _getApiUrlMember(member) {
    return this.apiPathBase + format('members/%s/', member.id);
  }

  _updateOrganizationFromResponse(response) {
    this.organization = response.data;
  }

  getMember(user) {
    return this.members.find(member => (
      user.id && member.id === user.id
      || user.email && member.email === user.email
    ));
  }

  _populateMembers() {
    let url = this.apiPathBase + 'members/?perPage=all&include=storageUsed';
    this.apiService.get(url)
      .then(response => this.setMembers(response))
      .catch(this._onAPIError);
  }

  setMembers(response) {
    this.members = response.data
      .map(member => Object.assign({}, member, {
        dateJoined: member.dateJoined ? new Date(member.dateJoined) : null,
        isMe: member.id === this.requestUserService.user.id,
      }));
    this.adminMembers = this.members.filter(member => member.isActive && member.isAdmin);
    this.requestMember = this.getMember(this.requestUserService.user);
    this.numMembers = this.members.filter(member => member.isActive).length;
    this.numInvitedMembers = this.members.filter(member => !member.isAccepted).length;

    this.displayMembers = this.members.slice();
    if(this.members.some(member => !member.isActive)) {
      this.displayMembers.push({
        isSubtitle: true,
        subtitle: 'Suspended users',
        isActive: false,
        isAccepted: true,
        dateJoined: 0,
      });
    }
    this.displayMembers.sort(this._sortMembers);
  }

  _sortMembers(member1, member2) {
    return (
      member2.isMe - member1.isMe
      || member2.isActive - member1.isActive
      || member2.isAccepted - member1.isAccepted
      || member1.dateJoined - member2.dateJoined
    );
  }



  /*******************
   * API interaction *
   *******************/

  setIsAdmin(member, isAdmin) {
    let url = this._getApiUrlMember(member);
    let data = {
      isAdmin: isAdmin
    };
    this.apiService.patch(url, data)
      .then(() => {
        if(member.isMe) {
          location.reload();
        }
      })
      .catch(this._onAPIError)
      .finally(this._populateMembers);
  }


  setIsActive(member, isActive) {
    let url = this._getApiUrlMember(member);

    this.apiService.patch(url, {
      isActive: isActive,
    })
      .then(() => {
        if(member.isMe) {
          location.reload();
        }
      })
      .catch(this._onAPIError)
      .finally(() => {
        if(!member.isMe) {
          this._populateMembers();
        }
      });
  }


  remove(member) {
    let url = this._getApiUrlMember(member);

    this.apiService.delete(url)
      .then(() => {
        if(member.isMe) {
          location.reload();
        }
      })
      .catch(this._onAPIError)
      .finally(this._populateMembers);
  }


  delete(member) {
    let url = format('users/%s', member.id);

    this.apiService.delete(url)
      .then(() => {
        this.notificationService.success(gettextCatalog.getString('User successfully deleted'));
      })
      .catch(this._onAPIError)
      .finally(this._populateMembers);
  }

  _onAPIError(error) {
    console.warn(error);
    this.notificationService.error(error.message);
  }


  /***************
   * Send invite *
   ***************/

  get defaultInviteMessage() {
    return gettextCatalog.getString(
      `Hello!

      I'd like to invite you to my team '{{ team }}'.

      Kind regards,
      {{ firstName }}`,
      {
        team: this.organization.name,
        firstName: this.requestUserService.user.firstName
      }
    );
  }


  sendInvite(user, message, isResend) {
    let data = {
      message: message,
    };
    if(user.id) {
      data.user = user.id;
    } else {
      data.email = user.email;
    }

    this.apiService.post(this.apiPathBase + 'invites/', data)
      .then(response => this._onInviteSent(response, isResend))
      .catch(this._onAPIError)
      .finally(this._populateMembers);
  }


  _onInviteSent(response, isResend) {
    if(isResend) {
      this.notificationService.success(
        gettextCatalog.getString('Invite successfully resent!')
      );
    }
  }

  /************************
  * Permanent invite link *
  ************************/

  togglePermanentInviteKey() {
    let data = {
      permanentInviteLinkEnabled: !this.organization.permanentInviteLinkEnabled
    };
    this.apiService.patch(this.apiPathBase, data)
      .then(response => this._updateOrganizationFromResponse(response))
      .catch(this._onAPIError);
  }
}
