import { getBooleanAttribute, browser } from 'utils/util';

TooltipDirective.$inject = [
  'tooltipService',
];

export default function TooltipDirective(
  tooltipService
) {
  return {
    restrict: 'A',

    link: function($scope, $elem, $attr) {
      let mouseOver = false;
      let show = false;
      let shownContent = null;

      let updateShownDebounced = debounce(updateShown);


      $elem.on('mouseover', onMouseOver);
      $elem.on('mouseout', onMouseOut);
      $elem.on('click', onClick);

      $scope.$on('$destroy', () => {
        tooltipService.hide($elem);
      });

      $scope.$watch($attr.tooltip, updateShownDebounced);
      $scope.$watch($attr.tooltipOverride, updateShownDebounced);

      function onMouseOver() {
        mouseOver = true;
        updateShownDebounced();
      }

      function onMouseOut() {
        mouseOver = false;
        updateShownDebounced();
      }

      function onClick($event) {
        let hide = getBooleanAttribute($scope, $attr.tooltipHideOnClick);
        let stopPropagation = getBooleanAttribute($scope, $attr.tooltipStopPropagation);
        let stopPropagationTouch = getBooleanAttribute($scope, $attr.tooltipStopPropagationTouch);
        let preventDefault = getBooleanAttribute($scope, $attr.tooltipPreventDefault);
        let preventDefaultTouch = getBooleanAttribute($scope, $attr.tooltipPreventDefaultTouch);

        if(hide) {
          mouseOver = false;
          updateShownDebounced();
        }
        if(stopPropagation || stopPropagationTouch && !browser.isDesktop()) {
          $event.stopPropagation();
        }
        if(preventDefault || preventDefaultTouch && !browser.isDesktop()) {
          $event.preventDefault();
        }
      }



      function updateShown() {
        let content = $scope.$eval($attr.tooltip);

        let delay = parseInt($scope.$eval($attr.tooltipDelay));
        if(isNaN(delay)) {
          delay = null;
        }

        let shouldShow = mouseOver && !!content;
        let shouldShowOverride = $scope.$eval($attr.tooltipOverride);
        if(shouldShowOverride != null) {
          shouldShow = shouldShowOverride;
          delay = 0;
        }

        if(shouldShow === show && content === shownContent) {
          return;
        }
        show = shouldShow;
        shownContent = content;
        if(show) {
          let position = $scope.$eval($attr.tooltipPosition);
          tooltipService.show($elem, content, position, delay);
        } else {
          tooltipService.hide($elem);
        }
      }
    }
  };
}
