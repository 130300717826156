import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkedItemPreviewModule } from '../linked-item-preview';
import { UtilsModule } from 'utils/utils.module';
import { ContactWidgetsComponent } from './contact-widgets.component';
import { ButtonPreviewModule } from '../button-preview';


@NgModule({
  imports: [
    CommonModule,
    UtilsModule,

    ButtonPreviewModule,
    LinkedItemPreviewModule,
  ],
  declarations: [
    ContactWidgetsComponent,
  ],
  exports: [
    ContactWidgetsComponent,
  ],
})
export class ContactWidgetsModule {}
