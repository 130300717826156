import { Component, Input } from '@angular/core';

type Field = 'default' | 'teal' | 'info' | 'success' | 'warning' | 'error' | 'marketing'

@Component({
  selector: 'field-icon',
  template: '<svg-icon class="icon mr-4" [svgName]="icon"></svg-icon>'
})
export class FieldIconComponent {
  @Input() svgName: string | null = null;
  @Input() type: Field | null = null;

  readonly IconMap: Record<Field, string> = {
    default: 'info_badged',
    teal: 'info_badged',
    info: 'info_badged',
    success: 'checkmark_badged',
    warning: 'warning_badged',
    error: 'close_badged',
    marketing: 'reseller',
  };

  get icon() {
    if(this.svgName) {
      return this.svgName;
    } else if(this.type) {
      return this.IconMap[this.type];
    } else {
      return this.IconMap.info;
    }
  }
}
