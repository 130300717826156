import templateModalSave from './saveAsTemplate.modal.html?raw';

export const MODAL_ID_SAVE_AS_TEMPLATE = 'saveAsTemplate';

export default class MeetingTemplateConfigService {
  static get $inject() {
    return [
      'apiService',
      'modalService',
      'meetingService',
    ];
  }

  constructor(
    apiService,
    modalService,
    meetingService
  ) {
    this.apiService = apiService;
    this.modalService = modalService;
    this.meetingService = meetingService;

    this.modalService.register(
      MODAL_ID_SAVE_AS_TEMPLATE,
      gettext('Save as template'),
      'utils/icons/tl/24x24_copy_outline.svg',
      templateModalSave
    );
  }


  saveAsTemplate(templateName) {
    let data = {
      'name': templateName,
      'sourceMeetingId': this.meetingService.id
    };
    return this.apiService.post('meetingTemplates', data);
  }

  hideSaveAsTemplateModal() {
    this.modalService.hide(MODAL_ID_SAVE_AS_TEMPLATE);
  }
}
