<div class="input-group">
  <div
    *ngFor="let option of options; trackBy:segmentedButtonTrackBy"
    class="btn"
    [class.btn--secondary]="!isSelected(option)"
    [class.btn--teal]="isSelected(option)"
    [class.disabled]="disabled"
    [class.bg-neutral-200]="disabled && isSelected(option)"
    (click)="!disabled && toggleOption(option)"
    [attr.data-testid]="'segmentedButtonOption--' + option.value"
    [attr.aria-selected]="isSelected(option) ? '' : null"
  >
    {{ option.label }}
  </div>
</div>
