import * as fields from 'utils/fields';
import Model from 'utils/models';
import { dateTime } from 'utils/util';
import { ContactFormQuestion, QuestionType } from './ContactFormQuestion';


class AnswerField extends fields.Field {
  toRepresentation() {
    let data = super.toRepresentation();
    const questionType = (
      this.parent.contactFormQuestion ? this.parent.contactFormQuestion.type : this.parent.type
    );
    if(data === '') {
      data = null;
    } else if(questionType === QuestionType.DATE) {
      data = dateTime.toISODateString(data);
    } else if(questionType === QuestionType.ADDRESS) {
      if(Object.values(data).every(val => val === null || val === '')) {
        data = null;
      }
    }
    return data;
  }
}


export class ContactFormAnswer extends Model {
  static get fields() {
    return {
      contactFormQuestion: new fields.RelatedField({ Model: ContactFormQuestion }),
      contactFormQuestionId: new fields.UUIDRelatedField(),
      answer: new AnswerField(),
      textAnswer: new fields.StringField(),
    };
  }
}


export class ContactFormPrefilledAnswer extends Model {
  static get fields() {
    return {
      type: new fields.ChoiceField({
        choices: Object.values(QuestionType),
      }),
      answer: new AnswerField(),
      extra: new fields.JSONField(),
    };
  }

  toRepresentation(...args) {
    // When updating a ContactForm, none of its prefilled answers can be omitted in the request
    // body. To make this easier, a ContactFormPrefilledAnswer can be represented by just its id,
    // so the toRepresentation of this model is extended to always include its id.
    let data = super.toRepresentation(...args);
    data.id = this.id;
    return data;
  }
}
