import { NgModule } from '@angular/core';

import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../modal';
import { SvgIconModule } from '../svg-icon';
import { DialogComponent } from './dialog.component';
import { DialogDirective } from './dialog.directive';
import { DialogService } from './dialog.service';


@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    PortalModule,
    SvgIconModule,
  ],
  declarations: [
    DialogComponent,
    DialogDirective,
  ],
  providers: [
    DialogService,
  ],
  exports: [
    DialogDirective,
  ],
})
export class DialogModule {}
