import { Component, OnInit } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';


type OptionValue = string;
type OptionLabel = string;
export type Option = [OptionValue, OptionLabel];

/**
 * There is a lot of overlap between this component and the single-select-input component. See
 * the comment in single-select-input.component.ts for justification.
 */
@Component({
  selector: 'multi-select-input',
  templateUrl: './multi-select-input.component.html',
})
export class MultiSelectInputComponent
  extends ContactFormQuestionInputComponent<string[]>
  implements OnInit {

  checkboxes: boolean[] = [];
  otherOptionSelected = false;
  otherOptionValue: OptionValue = '';


  get options() {
    return this.question.extra.options as Option[];
  }
  get otherOption(): boolean {
    return this.question.extra.otherOption || false;
  }


  parsePrefill(string: string) {
    const strings = string.split(',');
    for(const string of strings) {
      const i = this.options.findIndex(option => option[0] === string);
      if(i !== -1) {
        this.checkboxes[i] = true;
      } else if(this.otherOption) {
        this.otherOptionSelected = true;
        this.otherOptionValue = string;
      }
    }
    this.buildValue();
    return null;
  }


  ngOnInit() {
    this.checkboxes = Array(this.options.length).fill(false);
  }


  onOptionChange($event: Event, index: number) {
    const isChecked = (<HTMLInputElement>$event.target).checked;
    this.checkboxes[index] = isChecked;
    this.buildValue();
  }


  onOtherOptionChange($event: Event) {
    this.otherOptionSelected = (<HTMLInputElement>$event.target).checked;
    this.buildValue();
  }
  setOtherOptionValue(value: OptionValue) {
    this.otherOptionSelected = true;
    this.otherOptionValue = value;
    this.buildValue();
  }


  buildValue() {
    const value: OptionValue[] = [];
    for(let i = 0; i < this.options.length; i++) {
      if(this.checkboxes[i]) {
        value.push(this.options[i][0]);
      }
    }
    if(this.otherOptionSelected) {
      value.push(this.otherOptionValue);
    }
    this.writeValue(value);
  }
}
