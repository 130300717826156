<!-- Select meeting type -->
<div class="container" *ngIf="scheduleMeetingStep === ScheduleMeetingFlow.SELECT_APPOINTMENT_TYPE">
  <div *ngIf="!loading; else loadingState">
    <div class="d-flex justify-content-center mb-6">
      <h1 i18n>Book a meeting with {{appointmentHost}}</h1>
    </div>
    <div class="d-flex flex-wrap justify-content-center gap-6">
      <appointment-type-card
        class="appointment-type-card"
        *ngFor="let appointmentType of appointmentTypes"
        [appointmentType]="appointmentType"
        (scheduleMeetingClicked)="setAppointmentType()">
      </appointment-type-card>
    </div>
  </div>
</div>

<!-- scheduling -->
<div class="container" *ngIf="scheduleMeetingStep === ScheduleMeetingFlow.SELECT_APPOINTMENT">
  <div class="btn" (click)="navigateToMeetingTypes()" i18n>
    <svg-icon 
      class="icon mr-3" 
      [svgName]="'chevron_left'" 
      [svgSize]="24"></svg-icon>
    Back
  </div>
  <div class="scheduling">
    <div class="card card--sm scheduling__card mb-0 d-flex flex-column">
      <date-picker
        class="mb-6"
        (dateSelected)="setSelectedDate($event)">
      </date-picker>
      <div class="legend">
        <div class="legend__icon icon icon--lg mr-2"></div>
        <span class="legend__text" i18n>Availabilities on this day</span>
      </div>
    </div>
    <div class="card card--sm card--primary scheduling__card mb-0">
      <time-picker
        [timeSlots]="timeSlots"
        [selectedDate]="selectedDate"></time-picker>
    </div>
  </div>
</div>

<ng-template #loadingState>
  <h3 class="d-flex justify-content-center">
    <div i18n>Loading your available meeting types...</div>
    <div class="ml-2">
      <loading size="'custom'"></loading>
    </div>
  </h3>
</ng-template>
