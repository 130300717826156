import { bind, format, logger } from 'utils/util';

const PICKER_QUERY = '.picker-dialog';
let GOOGLE_DOC_MIME_TYPES = new Set([
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.google-apps.drawing',
  'application/vnd.google-apps.presentation',
]);


export default class DrivePickerRun {
  static get $inject() {
    return [
      'filePickerWorkerService',
    ];
  }

  constructor(
    filePickerWorkerService
  ) {
    bind(this);

    this.filePickerWorkerService = filePickerWorkerService;

    this.oauthToken = null;
    this.picker = null;

    this.filePickerWorkerService.on('pickDrive', message => {
      this.pick(message.addImageType, message.whiteboardId);
    });
  }


  pick(addImageType, whiteboardId) {
    if(this.oauthToken) {
      this._createPicker(addImageType, whiteboardId);

    } else {
      window.gapi.auth2.authorize({
        client_id: window.GOOGLE_CLIENT_ID,
        scope: [
          'https://www.googleapis.com/auth/drive.file',
        ].join(' '),
        immediate: false,
      }, authResult => {
        if(!authResult.error) {
          this.oauthToken = authResult.access_token;
          this._createPicker(addImageType, whiteboardId);

        } else if(authResult.error === 'idpiframe_initialization_failed') {
          this.filePickerWorkerService.error(`
            Something went wrong while connecting to Google Drive.
            Please make sure third-party cookies are enabled.
          `);

        } else if(authResult.error === 'popup_closed_by_user') {
          // Do nothing

        } else {
          logger.warn(authResult.error, authResult.details);
        }
      });
    }
  }


  _createPicker(addImageType, whiteboardId) {
    if(!this.oauthToken) {
      return;
    }

    this.filePickerWorkerService.show();

    let view = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setIncludeFolders(true)
      .setMode(window.google.picker.DocsViewMode.LIST);

    this.picker = new window.google.picker.PickerBuilder()
      .setOrigin('https://' + window.REFERER_DOMAIN)
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId(window.GOOGLE_APP_ID)
      .setOAuthToken(this.oauthToken)
      .addView(view)
      .setMaxItems(4)
      .setTitle('Select a file')
      .setDeveloperKey(window.GOOGLE_API_KEY)
      .setCallback((data) => this._pickerCallback(data, addImageType, whiteboardId))
      .build();

    // Without timeout, the picker sometimes ends up in the wrong place when closing and reopening
    // the picker.
    setTimeout(() => {
      this.picker.setVisible(true);
    }, 200);
  }


  _pickerCallback(data, addImageType, whiteboardId) {
    if(data.action === 'loaded') {
      this._addLogOutButton(addImageType, whiteboardId);

    } else {
      this._removePicker();

      if(data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
        let cloudFilesInfo = data[window.google.picker.Response.DOCUMENTS].map(doc => {
          let mimeType = doc[window.google.picker.Document.MIME_TYPE];
          let id = doc[window.google.picker.Document.ID];

          let url;
          let originalMimeType = mimeType;
          if(GOOGLE_DOC_MIME_TYPES.has(mimeType)) {
            mimeType = 'application/pdf';
            url = format(
              'https://www.googleapis.com/drive/v3/files/%s/export?mimeType=application/pdf',
              id);
          } else {
            url = format(
              'https://www.googleapis.com/drive/v3/files/%s?alt=media',
              id);
          }

          return {
            filename: doc.name,
            mimeType: mimeType,
            originalMimeType: originalMimeType,
            size: doc.sizeBytes,
            url: url,
            headers: { Authorization: 'Bearer ' + this.oauthToken }
          };
        });
        this.filePickerWorkerService.create(cloudFilesInfo, addImageType, whiteboardId);
      }
    }
  }


  _addLogOutButton(addImageType, whiteboardId) {
    let $elemButton = angular.element('<button>Switch account</button>');
    let $elemButtonWrapper = angular.element('<div class="drive-logout-wrapper"></div>');
    $elemButtonWrapper.append($elemButton);

    $elemButton.on('click', () => {
      $rootScope.$evalAsync(() => {
        this._switchAccount(addImageType, whiteboardId);
      });
    });

    $document.find(PICKER_QUERY).append($elemButtonWrapper);
  }


  _removePicker() {
    if(this.picker) {
      this.picker.dispose();
      this.picker = null;
    }
    $document.find(PICKER_QUERY).remove();

    setTimeout(() => {
      this.filePickerWorkerService.hide();
    });
  }


  _switchAccount(addImageType, whiteboardId) {
    this.oauthToken = null;
    this._removePicker();
    this.pick(addImageType, whiteboardId);
  }
}
