<div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

<h1 class="page-title" i18n>Meetings</h1>

<div class="d-flex justify-content-between align-items-center mb-4" data-testid="meetingsDiv">
  <scope-switcher
    [scope]="scope"
    [scopes]="scopes"
    (scopeChange)="onScopeChange($event)"
    class="mb-2">
  </scope-switcher>

  <a
    class="btn btn--primary mr-2 mb-2"
    [href]="schedulingAction.url"
    [target]="schedulingAction.target"
  >
    {{ schedulingAction.text }}
  </a>
</div>

<upcoming-appointments [scopePasser]="scope"></upcoming-appointments>
<past-appointments [scopePasser]="scope"></past-appointments>
