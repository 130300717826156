import { dateTime } from 'utils/util';

export class DateRange {
  constructor(
    public from: Date,
    public to: Date,
  ) {}


  toRepresentation() {
    return [
      dateTime.toISODateString(this.from),
      dateTime.toISODateString(this.to),
    ];
  }

  static toInternalValue(data) {
    const from = dateTime.stringToDate(data[0]);
    const to = dateTime.stringToDate(data[1]);
    if(!from || !to || from.toString() === 'Invalid Date' || to.toString() === 'Invalid Date') {
      throw Error('Could not parse DateRange input');
    } else {
      return new DateRange(from, to);
    }
  }
}
