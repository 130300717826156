import { Component, Inject, OnInit } from '@angular/core';
import SchedulingConfig from 'scheduling/models/SchedulingConfig';
import * as moment from 'moment';
import 'moment-timezone';
import { bind, logger } from 'utils/util';
import { WizardStepComponent } from 'utils/wizard/WizardStep';
import { SchedulingConfigService } from 'scheduling/schedulingConfig.service';
import { StateService } from 'utils/state.service';

type CalendarProvider = {
  id: string,
  name: string,
  primary: boolean,
  shared: boolean,
  readOnly: boolean,
}

type ReadCalendar = {
  calendar: CalendarProvider,
  checked: boolean
}

@Component({
  selector: 'calendar-config',
  templateUrl: './calendar-config.component.html',
  providers: [
    { provide: WizardStepComponent, useExisting: CalendarConfigComponent },
    StateService
  ]
})
export class CalendarConfigComponent extends WizardStepComponent implements OnInit {
  public ITEM_COLLAPSE_LIMIT = 3;

  public providerCalendars : CalendarProvider[] = [];
  public readCalendars: ReadCalendar[] = [];
  public writeCalendar : string | null = null;
  public showAllReadCalendars = false;
  public showAllWriteCalendars = false;

  private schedulingConfig: SchedulingConfig;
  public writeableCalendars: CalendarProvider[] = [];

  constructor(
    private schedulingConfigService: SchedulingConfigService,
    @Inject('usageTrackingService') private usageTrackingService,
    public stateService: StateService
  ) {
    super();
    bind(this);
  }

  get visibleWriteCalendars() {
    if(this.showAllWriteCalendars) {
      return this.writeableCalendars;
    } else {
      return this.writeableCalendars.slice(0, this.ITEM_COLLAPSE_LIMIT);
    }
  }

  get nReadCalendarsToShow() {
    if(this.showAllReadCalendars) {
      return this.schedulingConfig.providerCalendars.length;
    } else {
      const readLen = this.schedulingConfig.readCalendars.length;
      return (readLen + 1) > this.ITEM_COLLAPSE_LIMIT ? (readLen + 1) : this.ITEM_COLLAPSE_LIMIT;
    }
  }


  readCalendarTrackBy(index: number, calendar: ReadCalendar) {
    return calendar.calendar.id;
  }
  calendarTrackBy(index: number, calendar: CalendarProvider) {
    return calendar.id;
  }


  ngOnInit(): void {
    this.getSchedulingConfig();
  }

  getSchedulingConfig() {
    this.stateService.setState(this.stateService.State.LOADING);
    this.schedulingConfigService.get().then(schedulingConfig => {
      this.schedulingConfig = schedulingConfig;
      this._initSchedulingConfig(schedulingConfig);
    });
  }

  _getSortOrder(calendarProvider: CalendarProvider) {
    if(this.schedulingConfig.readCalendars.includes(calendarProvider.id)) {
      return 0;
    }
    if(calendarProvider.primary) {
      return 1;
    }
    return 2;
  }

  _initSchedulingConfig(schedulingConfig: SchedulingConfig) {
    this.providerCalendars = schedulingConfig.providerCalendars
      .sort((n1, n2) => this._getSortOrder(n1) - this._getSortOrder(n2));

    if(this.providerCalendars && this.providerCalendars.length > 0) {
      const writeCal = schedulingConfig.writeCalendar;
      const readCals = schedulingConfig.readCalendars;

      this.writeableCalendars = this.providerCalendars.filter(el => !el.readOnly);
      this.writeCalendar = writeCal ? writeCal : this.writeableCalendars[0].id;

      this.readCalendars = this.providerCalendars.map(pr => {
        return {
          calendar: pr,
          checked: readCals.length > 0 ? readCals.includes(pr.id) : pr.primary,
        };
      });
    } else {
      logger.error(
        'Could not retrieve calendars from provider',
        schedulingConfig.providerCalendars
      );
    }
    this.stateService.setState(this.stateService.State.READY);
  }

  updateSchedulingConfig(): Promise<any> {
    return this.schedulingConfigService.get().then(schedulingConfig => {
      schedulingConfig.readCalendars = this.readCalendars
        .filter(readCalendar => readCalendar.checked)
        .map(readCalendar => readCalendar.calendar.id);
      schedulingConfig.writeCalendar = this.writeCalendar;
      schedulingConfig.timezone = moment.tz.guess();
      return schedulingConfig.save();
    });
  }

  override handleNext() {
    return this.updateSchedulingConfig()
      .then(() => {
        this.usageTrackingService.createSegmentEvent(
          'onboardingFlow.completedCalendarSettings',
          'onboardingFlow'
        );
      });
  }

  override validate(): boolean {
    return this.providerCalendars.length > 0 && this.writeableCalendars?.length > 0;
  }
}
