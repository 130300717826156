import * as bodyPix from '@tensorflow-models/body-pix';
import '@tensorflow/tfjs';
import {
  browser,
  errors,
  interval,
  logger,
  Rect,
  requestAnimationFrameWithInactiveSupport
} from 'utils/util';
import { Stream } from  'meeting/meeting-room/stream';
import { ElemInfo, ElemState, StreamType } from './Stream';
import Session from 'meeting/angularjs/main/users/Session';

const MONITOR_INTERVAL = 4000;

const BODYPIX_ARCHITECTURE = 'MobileNetV1';
const BODYPIX_OUTPUT_STRIDE = 16;
const BODYPIX_QUANTISATION_BYTES = 2;
const BODYPIX_MULTIPLIER_MOBILE = 0.5;
const BODYPIX_MULTIPLIER_DESKTOP = 0.75;

const SEGMENTPERSON_INTERNAL_RESOLUTION = 0.5;
const SEGMENTPERSON_SEGMENTATION_THRESHOLD = 0.8;
const SEGMENTPERSON_MAX_DETECTIONS = 1;

const MASK_BLUR_FILTER = 'blur(5px)';

const BOKEH_BLUR_AMOUNT = 8;
const BOKEH_EDGE_BLUR_AMOUNT = 20;
const BOKEH_FLIP_HORIZONTAL = false;


type PTZConstraints = {
  pan: number | null,
  tilt: number | null,
  zoom: number | null,
}

type PTZCapabilities = MediaTrackCapabilities & {
  pan: { min: number, max: number, step: number },
  tilt: { min: number, max: number, step: number },
  zoom: { min: number, max: number, step: number }
}

type VideoSize = {
  x: number,
  y: number,
}

type VideoOffset = {
  x: number,
  y: number,
}

export class VideoStream extends Stream {

  public snapshotCanvas: HTMLCanvasElement = document.createElement('canvas');
  public snapshotCtx: CanvasRenderingContext2D | null = this.snapshotCanvas.getContext('2d');

  public notificationService: any;
  public focusService: any;
  public segmentationSettingsService: any;
  public videoCompatibleService: any;

  public monitorInterval: number | null = null;
  public snapshotDataUrls: Blob[] = [];

  public displaySize: VideoSize = {
    x: 0,
    y: 0,
  };


  public sourceVideo: HTMLVideoElement | null = null;
  public sourceTrack: MediaStreamTrack | null = null;
  public sourceMediaStream: MediaStream | null = null;

  public frameWidth: number | null = null;
  public frameHeight: number | null = null;


  // Segmentation variables

  // This canvas will be showing the result
  public viewportCanvas: HTMLCanvasElement | null = null;

  // This canvas is an intermediate on which the segmentation is rendered and blurred
  public blurSegCanvas: OffscreenCanvas | null = null;

  // This canvas is an intermediate on which the team background image is drawn
  // to correct resolution and aspect ration on init
  public backgroundCanvas: OffscreenCanvas | null = null;

  public model: bodyPix.BodyPix | null = null;
  public segmentation: bodyPix.SemanticPersonSegmentation | null = null;
  public backgroundImage: HTMLImageElement | null = null;

  // property for knowing when screen is showing its first segmented & rendered frame
  public isLoading = false;


  // PTZ variables
  public supportsPTZ = false;
  public requestedPTZConstraints: PTZConstraints = {
    pan: null,
    tilt: null,
    zoom: null,
  };

  public trackCapabilities: MediaTrackCapabilities | null = null;
  public appliedPTZConstraints: PTZConstraints = Object.assign({}, this.requestedPTZConstraints);
  public applyConstraintsPromise: Promise<void> = Promise.resolve();

  constructor(
    type: StreamType,
    id: string,
    groupId: string,
    session: Session,
    readyToPlayService,
    notificationService,
    focusService,
    segmentationSettingsService,
    videoCompatibleService
  ) {
    super(type, id, groupId, session, readyToPlayService);


    this.notificationService = notificationService;
    this.focusService = focusService;
    this.segmentationSettingsService = segmentationSettingsService;
    this.videoCompatibleService = videoCompatibleService;

    this.eventEmitter.addEvent('displaySize');

    this.compatible = true;
    if(!this.session.isLocal) {
      this.videoCompatibleService.on(this.session.id, this.updateCompatible);
      this.updateCompatible();
    }
  }


  override setTrack(track: MediaStreamTrack) {
    if(this.isLocal && this.segmentationSettingsService.shouldSegment) {
      track = this.createSegmentedTrack(track);
    }

    super.setTrack(track);
  }


  override removeTrack() {
    this.removeSegmentedTrack();
    return super.removeTrack();
  }


  getAspectRatio(): number | null {
    const videoSize = this.getVideoSize();

    let aspectRatio: number | null = null;
    if(videoSize != null) {
      aspectRatio = videoSize.x / videoSize.y;
    }
    if(aspectRatio && !isFinite(aspectRatio)) {
      aspectRatio = null;
    }
    return aspectRatio;
  }


  getVideoSize(): VideoSize | null {
    const elemInfo = this.elemInfos.find(elemInfo => {
      return elemInfo.state === ElemState.PLAYING;
    });
    if(!elemInfo) {
      return null;
    }

    if(elemInfo && elemInfo.elem instanceof HTMLVideoElement) {
      return {
        x: elemInfo.elem.videoWidth,
        y: elemInfo.elem.videoHeight,
      };
    } else {
      throw Error('tried to get videoSize from an Audio element');
    }

  }


  setDisplaySize(displaySize: VideoSize) {
    if(displaySize.x !== this.displaySize.x || displaySize.y !== this.displaySize.y) {
      Object.assign(this.displaySize, displaySize);
      this.eventEmitter.emit('displaySize', this, this.displaySize);
    }
  }


  private updateCompatible() {
    const compatible = this.videoCompatibleService.get(this.session);

    if(compatible !== this.compatible) {
      this._log('set compatible', compatible);
      this.compatible = compatible;
      this.updateStreamState();
    }
  }



  /**************
   * Monitoring *
   **************/

  override startMonitoring() {
    super.startMonitoring();

    this.monitorInterval = interval.setInterval(this.monitor, MONITOR_INTERVAL);
  }


  override stopMonitoring() {
    super.stopMonitoring();
    if(this.monitorInterval) {
      interval.clearInterval(this.monitorInterval);
      this.monitorInterval = null;
    }
    this.snapshotDataUrls = [];
  }


  protected monitor() {
    if(this.ignoreSilence) {
      return;
    }

    this.takeSnapshot(null, null, null, 20, true)
      .catch(() => {
        return;
      })
      .then(snapshotDataUrl => {
        if(snapshotDataUrl == null) {
          this.snapshotDataUrls = [];
          return;
        }

        this.snapshotDataUrls.push(snapshotDataUrl);
        if(this.snapshotDataUrls.length < 2) {
          return;
        } else if(this.snapshotDataUrls.length > 2) {
          this.snapshotDataUrls.shift();
        }

        const silence = this.snapshotDataUrls[0] === this.snapshotDataUrls[1];
        this.setSilence(silence);
      });
  }



  /*************
   * Snapshots *
   *************/

  takeSnapshot(
    aspectRatio: number | null,
    zoomLevel: number | null,
    offsetRelative: VideoOffset | null,
    drawWidth: number,
    useSourceStream: boolean
  ): Promise<Blob | null> {
    if(useSourceStream == null) {
      useSourceStream = false;
    }

    return Promise.resolve()
      .then(() => {
        const elemInfo = this.getElemForSnapshot(useSourceStream);
        if(elemInfo && elemInfo.elem instanceof HTMLVideoElement ) {
          return elemInfo.elem;
        } else {
          return this.createElemForSnapshot(useSourceStream);
        }
      })
      .then((elem) => {
        return this.takeSnapshotFromElem(
          elem,
          aspectRatio,
          zoomLevel,
          offsetRelative,
          drawWidth
        );
      })
      .catch(error => {
        logger.warn(error);
        return null;
      });
  }

  private takeSnapshotFromElem(
    elem: HTMLVideoElement,
    aspectRatio: number | null,
    zoomLevel: number | null,
    offsetRelative: VideoOffset | null,
    drawWidth: number | null
  ): Promise<Blob|null> {
    if(!this.focusService.hasFocus && !browser.supportsUnfocusedSnapshot()) {
      return Promise.resolve(null);
    }

    const videoSize = {
      x: elem.videoWidth,
      y: elem.videoHeight,
    };
    const origAspectRatio = videoSize.x / videoSize.y;
    if(aspectRatio == null) {
      aspectRatio = origAspectRatio;
    }
    if(zoomLevel == null) {
      zoomLevel = 1;
    }
    if(offsetRelative == null) {
      offsetRelative = {
        x: (Math.max(0, 1 - aspectRatio / origAspectRatio) + (1 - 1 / zoomLevel)) / 2,
        y: (Math.max(0, 1 - origAspectRatio / aspectRatio) + (1 - 1 / zoomLevel)) / 2,
      };
    }

    const offset = {
      x: offsetRelative.x * videoSize.x,
      y: offsetRelative.y * videoSize.y,
    };

    const sizeX = videoSize.x * Math.min(1, aspectRatio / origAspectRatio) / zoomLevel;
    const sizeY = sizeX / aspectRatio;
    const size = {
      x: sizeX,
      y: sizeY
    };

    if(drawWidth == null) {
      drawWidth = size.x;
    }

    const drawSize = {
      x: drawWidth,
      y: size.y * drawWidth / size.x,
    };

    this.snapshotCanvas.width = drawSize.x;
    this.snapshotCanvas.height = drawSize.y;

    if(this.snapshotCtx) {
      this.snapshotCtx.drawImage(
        elem,
        offset.x, offset.y,
        size.x, size.y,
        0, 0,
        drawSize.x, drawSize.y
      );
    }

    return new Promise((resolve) => this.snapshotCanvas.toBlob(resolve, 'image/jpeg'));
  }


  getElemForSnapshot(useSourceStream: boolean): ElemInfo | null {
    if(useSourceStream && this.segmentationSettingsService.shouldSegment) {
      return null;
    }

    const ret = this.elemInfos.find(elemInfo => {
      if(elemInfo.state !== ElemState.PLAYING) {
        return false;
      }

      const rect = Rect.fromElem(elemInfo.elem);
      const rectViewport = Rect.fromViewport();
      return (
        rect.right > rectViewport.left
        && rect.left < rectViewport.right
        && rect.bottom > rectViewport.top
        && rect.top < rectViewport.bottom
      );
    });

    if(ret != null) {
      return ret;
    } else {
      return null;
    }
  }


  private async createElemForSnapshot(useSourceStream: boolean): Promise<HTMLVideoElement> {
    const videoElement = document.createElement('video');
    videoElement.muted = true;
    videoElement.crossOrigin = 'anonymous';
    videoElement.srcObject = (
      useSourceStream && this.sourceMediaStream ? this.sourceMediaStream : this.mediaStream
    );
    await videoElement.play();
    return videoElement;
  }



  /***********************
   * Person segmentation *
   ***********************/

  private getSegmentationContext(canvas): CanvasRenderingContext2D | null {
    try {
      return canvas.getContextUnsafe('2d');
    } catch(e: any) {
      if(e.constructor === errors.GetContextFailedError) {
        this.notificationService.warning(
          // eslint-disable-next-line max-len
          $localize `We disabled your video background because your computer was running out of memory.`
        );
        this.segmentationSettingsService.setBackgroundStyle(
          this.segmentationSettingsService.BackgroundStyle.NONE
        );
        return canvas.getDummyContext();
      } else {
        throw e;
      }
    }
  }

  protected createSegmentedTrack(sourceTrack: MediaStreamTrack): MediaStreamTrack {
    this.isLoading = true;
    this.loadModel();

    this.viewportCanvas = document.createElement('canvas');

    this.sourceTrack = sourceTrack;
    this.sourceVideo = document.createElement('video');
    this.sourceVideo.onloadedmetadata = this.onVideoLoaded;
    this.sourceVideo.autoplay = true;
    this.sourceVideo.srcObject = new MediaStream([sourceTrack]);

    // UPGRADE TODO: replaced MediaStream([this.sourceTrack]) with MediaStream([sourceTrack]);
    this.sourceMediaStream = new MediaStream([sourceTrack]);

    const mediaStream = this.viewportCanvas.captureStream();
    const track = mediaStream.getVideoTracks()[0];

    return track;
  }

  private onVideoLoaded() {
    if(this.sourceVideo && this.viewportCanvas) {
      this.frameWidth = this.sourceVideo.videoWidth;
      this.frameHeight = this.sourceVideo.videoHeight;

      this.sourceVideo.width = this.frameWidth;
      this.sourceVideo.height = this.frameHeight;

      this.viewportCanvas.width = this.frameWidth;
      this.viewportCanvas.height = this.frameHeight;

      if(
        this.segmentationSettingsService.backgroundSetting
        === this.segmentationSettingsService.BackgroundStyle.MASK
      ) {
        this.backgroundImage = new Image();
        this.backgroundImage.onload = this.onLoadBackgroundImage;
        this.backgroundImage.crossOrigin = 'anonymous';
        this.backgroundImage.src = this.session.user.organization.cameraBackgroundImage;
      }

      // only start animation loops once all canvas data is initialized
      // otherwise, weird zooming happens due to race conditions
      this.runPrimaryAnimationLoop();
      this.runSecondaryAnimationLoop();
    }
  }

  private removeSegmentedTrack() {
    if(!this.sourceTrack) {
      return;
    }

    this.sourceTrack.stop();
    this.sourceTrack = null;
    this.sourceMediaStream = null;
    this.sourceVideo = null;
    this.viewportCanvas = null;
  }


  private loadModel() {
    bodyPix.load(
      {
        architecture: BODYPIX_ARCHITECTURE,
        outputStride: BODYPIX_OUTPUT_STRIDE,
        quantBytes: BODYPIX_QUANTISATION_BYTES,
        multiplier:
          browser.isDesktop() ?
            BODYPIX_MULTIPLIER_DESKTOP :
            BODYPIX_MULTIPLIER_MOBILE
      }
    ).then(net => {
      this.model = net;
    });
  }

  /**
   * While the browser tab is visible, primary animation loop handles the segmentation step.
   *
   * When the browser tab changes to hidden, this animation loop is throttled by the browser,
   * so letting segmentation and rendering happen independently results in large gaps between
   * segmentation calculation and frame rendering. In that case, we let the primary animation
   * loop also execute the rendering step immediately after the segmentation step.
   */
  private runPrimaryAnimationLoop() {
    // stream has been reset: break the requestAnimationFrame loop
    if(!this.sourceTrack) {
      return;
    }

    let promise = Promise.resolve();

    if(this.sourceVideo && this.sourceVideo.readyState >= 2  && this.model) {
      if(document.visibilityState !== 'hidden') {
        promise = this.updateSegmentation(this.sourceVideo);

      } else {
        // To make sure both segmentation and rendering use the same video frame
        // we extract the current frame into a canvas and use that as input instead
        // of the HTML Video Element.
        const sourceFrameCanvas = document.createElement('canvas');
        sourceFrameCanvas.width = this.sourceVideo.videoWidth;
        sourceFrameCanvas.height = this.sourceVideo.videoHeight;
        this.getSegmentationContext(sourceFrameCanvas)?.drawImage(this.sourceVideo, 0, 0);

        promise = this.updateSegmentation(sourceFrameCanvas)
          .then(() => this.updateMaskOrBlurCanvas(sourceFrameCanvas));
      }
    }
    promise.then(() => requestAnimationFrameWithInactiveSupport(this.runPrimaryAnimationLoop));
  }

  /**
   * While the browser tab is visible, seconday animation loops handles the rendering of the
   * segmentation information to the canvas in the desired way (blur or replace background)
   *
   * If the tab becomes invisible, finer control over these steps is necessary so this loop
   * keeps running in the background (throttled by the browser) without doing anything
   */
  private runSecondaryAnimationLoop() {
    // stream has been reset: break the requestAnimationFrame loop
    if(!this.sourceTrack) {
      return;
    }

    if(document.visibilityState !== 'hidden') {
      this.updateMaskOrBlurCanvas(this.sourceVideo);
    }
    requestAnimationFrameWithInactiveSupport(this.runSecondaryAnimationLoop);
  }

  private updateMaskOrBlurCanvas(input) {
    const backgroundSetting = this.segmentationSettingsService.backgroundSetting;
    if(backgroundSetting === this.segmentationSettingsService.BackgroundStyle.BLUR) {
      this.updateBlurCanvas(input);
    } else if(backgroundSetting === this.segmentationSettingsService.BackgroundStyle.MASK) {
      this.updateMaskCanvas(input);
    }
  }

  /**
   * updates the relevant segmentation object to contain the segmentation of the most recent frame.
   *
   * if the background setting is set to BLUR, will update the this.segmentation bitvector
   *
   * if the background setting is set to MASK, will update the offscreen this.blurSegCanvas
   *
   * @returns a promise
   */
  private updateSegmentation(input): Promise<void> {
    if(!this.model) {
      return Promise.resolve();
    }
    return this.model.segmentPerson(
      input,
      {
        internalResolution: SEGMENTPERSON_INTERNAL_RESOLUTION,
        segmentationThreshold: SEGMENTPERSON_SEGMENTATION_THRESHOLD,
        maxDetections: SEGMENTPERSON_MAX_DETECTIONS,
      }
    ).then(segBitvector => {
      const backgroundSetting = this.segmentationSettingsService.backgroundSetting;
      if(backgroundSetting === this.segmentationSettingsService.BackgroundStyle.BLUR) {
        this.segmentation = segBitvector;
        return;
      } else if(backgroundSetting === this.segmentationSettingsService.BackgroundStyle.MASK) {
        // convert the segmentation bitvector to an ImageData object where
        // the alpha value signifies the mask while the rgb values remain 0
        const ctx = this.getSegmentationContext(document.createElement('canvas'));
        if(!ctx || !this.frameWidth || !this.frameHeight) {
          throw Error('Could not initialize segmentation');
        }
        const segImageData = ctx?.createImageData(this.frameWidth, this.frameHeight);

        // segBitvector is a bitvector with each element representing a corresponding pixel
        // in the frame being either a person or background. Map this bitvector to the alpha
        // channel of RGBA ImageData
        for(let i = 0; i < segBitvector.data.length; i++) {
          segImageData.data[(i * 4) + 3] = segBitvector.data[i] * 255;
        }

        // convert this ImageData to a blurred bitmap mask by running it
        // through a canvas and applying a css-style filter (offloads to GPU)
        return createImageBitmap(segImageData).then(bitmap => {
          if(this.frameWidth && this.frameHeight) {
            this.blurSegCanvas = new OffscreenCanvas(this.frameWidth, this.frameHeight);
            const bSegCtx = this.getSegmentationContext(this.blurSegCanvas);
            if(! bSegCtx) {
              throw Error('Could not initialize segmentation');
            }
            bSegCtx.filter = MASK_BLUR_FILTER;
            bSegCtx.drawImage(bitmap, 0, 0);
          }
        });
      } else {
        return;
      }
    });
  }

  /**
   * uses the this.segmentation bitvector to blur the background of the camera stream
   * and render it in the this.viewportCanvas
   */
  private updateBlurCanvas(input) {
    if(!this.viewportCanvas) {
      return;
    }
    if(this.segmentation) {
      bodyPix.drawBokehEffect(
        this.viewportCanvas,
        input,
        this.segmentation,
        BOKEH_BLUR_AMOUNT,
        BOKEH_EDGE_BLUR_AMOUNT,
        BOKEH_FLIP_HORIZONTAL
      );

      this.isLoading = false;
    } else {
      if(this.sourceVideo) {
        // in case the video has not finished loading but a frame is available
        // manually set the frame dimensions instead of waiting for the handler
        this.viewportCanvas.width = this.sourceVideo.videoWidth;
        this.viewportCanvas.height = this.sourceVideo.videoHeight;
        this.getSegmentationContext(this.viewportCanvas)?.drawImage(this.sourceVideo, 0, 0);
      }
    }
  }

  /*
   * renders the team background image onto the offscreen this.backgroundcanvas
   * in the correct dimenstions and resolution
   */
  private onLoadBackgroundImage() {
    if(!this.backgroundImage || !this.frameWidth || !this.frameHeight) {
      return;
    }
    // source frame dimensions and offset coordinates
    let sourceWidth: number | null = null;
    let sourceHeight: number | null = null;
    let sourceX: number | null = null;
    let sourceY: number | null = null;

    // background image dimensions
    const bgWidth = this.backgroundImage.naturalWidth;
    const bgHeight = this.backgroundImage.naturalHeight;

    // destination frame dimensions and ratio
    const destWidth = this.frameWidth;
    const destHeight = this.frameHeight;
    const destRatio = destWidth / destHeight;

    this.backgroundCanvas = new OffscreenCanvas(destWidth, destHeight);  // eslint-disable-line compat/compat, max-len

    // calculate offsets and dimensions so the the background is drawn with the
    // same resolution as the source frame while maintaining correct aspect ratio
    if(bgWidth > bgHeight * destRatio) {
      sourceHeight = bgHeight;
      sourceY = 0;
      sourceWidth = sourceHeight * destRatio;
      sourceX = (bgWidth - sourceWidth) / 2;
    } else {
      sourceWidth = bgWidth;
      sourceX = 0;
      sourceHeight = sourceWidth / destRatio;
      sourceY = (bgHeight - sourceHeight) / 2;
    }

    this.getSegmentationContext(this.backgroundCanvas)?.drawImage(
      this.backgroundImage,
      sourceX, sourceY,
      sourceWidth, sourceHeight,
      0, 0,
      destWidth, destHeight
    );
  }

  /**
   * uses the offscreen this.blurSegCanvas to replace the background of the camera stream
   * with an image and render it in this.viewportCanvas
   */
  private updateMaskCanvas(input) {
    if(this.blurSegCanvas && this.backgroundCanvas && this.frameWidth && this.frameHeight) {
      // intermediate canvas for merging the blurred segmentation and the current frame
      // applying the operation directly on the blurred segmentation canvas seems to
      // not work
      const mergingCanvas = new OffscreenCanvas(this.frameWidth, this.frameHeight);

      // merge the blurred segmentation canvas with the sourcevideo by
      // only drawing the pixels of the sourcevideo if it overlaps an existing
      // non-empty pixel in the blurred person mask.
      //
      // the result is a cutout of the person with edgeblur
      const mergingCtx = this.getSegmentationContext(mergingCanvas);
      // draw the merged cutout on top of the background in the viewport canvas
      const viewportCtx = this.getSegmentationContext(this.viewportCanvas);
      if(!mergingCtx || !viewportCtx) {
        throw Error('Could not initialize segmentation');
      }

      mergingCtx.drawImage(this.blurSegCanvas, 0, 0);
      mergingCtx.globalCompositeOperation = 'source-atop';
      mergingCtx.drawImage(input, 0, 0);

      viewportCtx.drawImage(this.backgroundCanvas, 0, 0);
      viewportCtx.globalCompositeOperation = 'source-over';
      viewportCtx.drawImage(mergingCanvas, 0, 0);

      this.isLoading = false;
    } else {
      if(this.viewportCanvas && this.sourceVideo) {
        // in case the video has not finished loading but a frame is available
        // manually set the frame dimensions instead of waiting for the handler
        this.viewportCanvas.width = this.sourceVideo.videoWidth;
        this.viewportCanvas.height = this.sourceVideo.videoHeight;
        this.getSegmentationContext(this.viewportCanvas)?.drawImage(this.sourceVideo, 0, 0);
      }
    }
  }


  /*******
   * PTZ *
   *******/

  get ptzTrack(): MediaStreamTrack | null {
    return this.sourceTrack || this.track;
  }

  enablePTZ(trackCapabilities) {
    this.supportsPTZ = true;
    if(this.isLocal && this.ptzTrack) {
      const cap = this.ptzTrack.getCapabilities() as PTZCapabilities;
      // Throughout our codebase, an offset of { x: 0, y: 0 } means the topleft corner. PTZ
      // camera's work differently: the minimum pan/tilt corresponds to the bottomleft corner. To
      // simplify transformations outside of this class, we invert the trackCapabilities.tilt, and
      // when applying a constraint we invert the requested tilt again.
      [cap.tilt.min, cap.tilt.max] = [-cap.tilt.max, -cap.tilt.min];

      // The squary grey bednet camera's report an incorrect max tilt
      if(
        cap.tilt.min === -306000
        && cap.tilt.max === 108000
        && cap.tilt.step === 3600
      ) {
        cap.tilt.min = -108000;
      }
      this.trackCapabilities = cap;

    } else {
      this.trackCapabilities = trackCapabilities;
    }
  }


  applyPTZConstraints(constraints) {
    Object.assign(this.requestedPTZConstraints, constraints);
    if(this.requestedPTZConstraints && this.requestedPTZConstraints.tilt) {
      this.requestedPTZConstraints.tilt = -this.requestedPTZConstraints.tilt;
    }
    ['pan', 'tilt', 'zoom'].forEach(key => {
      this.applyConstraintsPromise = this.applyConstraintsPromise.then(() => {
        return this.applyPTZConstraint(key, this.requestedPTZConstraints[key]);
      });
    });
  }

  private applyPTZConstraint(key, value): Promise<void> {
    if(
      this.ptzTrack
      && this.ptzTrack.readyState === 'live'
      && value !== this.appliedPTZConstraints[key]
    ) {
      this.appliedPTZConstraints[key] = value;
      return this.ptzTrack.applyConstraints({ advanced: [{ [key]: value }] })
        .catch(error => logger.warn(error));
    } else {
      return Promise.resolve();
    }
  }
}
