import { AfterViewInit, Component, Inject } from '@angular/core';
import { DropdownInputComponent } from '../dropdown-input/dropdown-input.component';
import { DropdownOptionFull } from 'utils/form-elements/dropdowns';
import { languages } from 'utils/util/languages';


@Component({
  selector: 'language-input',
  templateUrl: '../dropdown-input/dropdown-input.component.html',
})
export class LanguageInputComponent extends DropdownInputComponent implements AfterViewInit {


  constructor(
    @Inject('languageService') private languageService,
  ) {
    super();
  }

  override ngAfterViewInit(): void {
    if(this.value == null || this.value === '') {
      if(languages.all.find( lang => lang.code === this.languageService.browserLanguage)) {
        this.writeValue(this.languageService.browserLanguage);
      } else {
        // currentLanguage is guaranteed to be a localized language, and therefor supported.
        this.writeValue(this.languageService.currentLanguage);
      }
    }
  }

  override get options(): DropdownOptionFull[] {
    return languages.all.map(language => {
      return {
        value: language.code,
        label: language.name
      };
    });
  }
}
