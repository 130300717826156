import { Inject, Injectable } from '@angular/core';

import { Session } from 'meeting/angularjs/main/users/Session';
import { BroadcastService } from 'meeting/meeting-room/communication/broadcast.service';
import { MeetingService } from 'meeting/meeting-room/meeting.service';
import { EventEmitter, debounce } from 'utils/util';
import { diff, patch } from 'utils/util/diff';


@Injectable({
  providedIn: 'root',
})
export class PrivateNotesService {
  public eventEmitter: EventEmitter;
  public enabled = false;
  public mySession: Session;

  private _content = '';
  private syncedContent = '';
  private sendContentDebounce: () => void;

  constructor(
    private meetingBroadcastService: BroadcastService,
    @Inject('userService') private userService,
    @Inject('recorderService') private recorderService,

    private meetingService: MeetingService,
  ) {
    this.bind();
    this.eventEmitter = EventEmitter.setup(this, ['enabled', 'edited']);

    this.sendContentDebounce = debounce(this.sendContent, 3000);

    this.mySession = this.userService.mySession;
    this.mySession.on('accessLevel', this.updateEnabled);

    meetingService.settings.eventEmitter.on('showPrivateNotes', this.updateEnabled);
    this.updateEnabled();

    meetingBroadcastService.on('private-notes', this.onBroadcastPrivateNotes, true);
  }

  private bind() {
    this.updateEnabled = this.updateEnabled.bind(this);
    this.sendContent = this.sendContent.bind(this);
    this.onBroadcastPrivateNotes = this.onBroadcastPrivateNotes.bind(this);
  }

  private updateEnabled(): void {
    const enabled = (
      this.mySession.accessLevel.isHost
      && this.meetingService.settings.showPrivateNotes
    );

    if(enabled !== this.enabled) {
      this.enabled = enabled;
      this.eventEmitter.emit('enabled', enabled);
    }
  }

  get content(): string {
    return this._content;
  }
  set content(content: string) {
    this.setContentLocally(content);
    this.sendContentDebounce();
  }

  private setContentLocally(content: string, timestamp?: Date): void {
    if(content === this.content) {
      return;
    }
    this._content = content;
    this.eventEmitter.emit('edited', this.content, timestamp);
  }

  private sendContent(): void {
    const contentPatch = diff(this.syncedContent, this._content);
    if(contentPatch !== '') {
      this.meetingBroadcastService.send('private-notes', true, [], contentPatch);
    }
  }

  private onBroadcastPrivateNotes(channel, session, timestamp, contentPatch) {
    this.syncedContent = patch(this.syncedContent, contentPatch);
    if(!session.isLocal) {
      this.setContentLocally(patch(this._content, contentPatch), timestamp);
    }
  }
}
