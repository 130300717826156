import template from './modals.html?raw';

import { MODAL_ATTENTION_DURATION } from '../../cssFramework/variables.js';

class ModalController {
  static get $inject() {
    return [
      '$element',
      'modalService',
    ];
  }

  constructor(
    $elem,
    modalService
  ) {
    this._bind();

    this.$elem = $elem;
    this.$elemModal = this.$elem.find('.modal');
    this.modalService = modalService;

    this.modal = null;

    modalService.on('show', this._onShow);
    this._onShow();
  }

  _bind() {
    this._onShow = this._onShow.bind(this);
  }

  _onShow() {
    this.modal = this.modalService.getShown();
  }

  onOverlayClick($event) {
    if($event.currentTarget === $event.target) {
      this.$elemModal.addClass('modal-deprecated--attention');
      $timeout(() => {
        this.$elemModal.removeClass('modal-deprecated--attention');
      }, MODAL_ATTENTION_DURATION);
    }
  }


  hide() {
    if(this.modal && this.modal.dismissable) {
      this.modalService.hide(this.modal.id);
    }
  }

  get scope() {
    return this.modal ? this.modal.scope : {};
  }
}


export default {
  controller: ModalController,
  controllerAs: 'modalCtrl',
  template,
};
