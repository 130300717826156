import VecteraNotification from './VecteraNotification';
import { EventEmitter, storage } from 'utils/util';
import { errors, logger } from 'utils/util';

const DEFAULT_OPTIONS = Object.freeze({
  delay: 10000,
  dismissable: true,
  retentiveId: null,
  onCancel: null,
  persistOnReload: false,
});
const DEFAULT_PROGRESS_OPTIONS = Object.freeze(Object.assign({}, DEFAULT_OPTIONS, {
  delay: null,
}));

const STORAGE_RETENTIVE_KEY_PREFIX = 'vecteraNotification:';
const STORAGE_PERSISTENT_KEY = 'vecteraPersistedNotifications';



export default class NotificationService {
  static get $inject() {
    return [
      '$rootElement',
      '$compile',
    ];
  }

  constructor(
    $rootElement,
    $compile
  ) {
    this._bind();

    EventEmitter.setup(this, ['add', 'remove']);

    this.$rootElement = $rootElement;
    this.$compile = $compile;

    this.notifications = [];
    this.maxId = 0;

    this.info = this._add.bind(this, VecteraNotification.Level.INFO);
    this.success = this._add.bind(this, VecteraNotification.Level.SUCCESS);
    this.warning = this._add.bind(this, VecteraNotification.Level.WARNING);
    this.error = this._add.bind(this, VecteraNotification.Level.ERROR);

    // Inject the element after the angular.js bootstrap has been completed, otherwise the element
    // may be compiled twice: once by us and once during bootstrapping.
    $timeout(this._injectElem);
  }

  _bind() {
    this._injectElem = this._injectElem.bind(this);
    this._onCancelHandler = this._onCancelHandler.bind(this);
  }


  _injectElem() {
    let $elem = angular.element('<notifications></notifications>');
    let $elemCompiled = this.$compile($elem)($rootScope);
    this.$rootElement.append($elemCompiled);

    this._loadPersistedNotifications();
  }


  get DEFAULT_DELAY() {
    return DEFAULT_OPTIONS.delay;
  }

  /**
   * Create a VecteraNotification, taking into account if the message is already in a notification,
   * or if it has been asked to never get shown again.
   *
   * @param {VecteraNotification.LEVEL} level - INFO, WARNING, ERROR or SUCCESS
   * @param {String} message - The message shown in the notification, can be html
   * @param {Object} [argOptions] - An object featuring a delay, retentiveId or onCancel key-value
   *                                pair. See VecteraNotification for more information.
   *
   * @returns {VecteraNotification}
   */
  _add(level, message, argOptions = {}) {
    let options = Object.assign(
      {},
      DEFAULT_OPTIONS,
      argOptions,
      {
        onRemove: this._onCancelHandler,
      }
    );

    if(options.persistOnReload) {
      this._persistNotificationForReload(level, message, options);
      return;
    }
    if(options.retentiveId) {
      options.retentiveId = STORAGE_RETENTIVE_KEY_PREFIX + options.retentiveId;
    }

    // Make sure we're not showing any messages twice
    let notification = this.notifications.find(notification => notification.message === message);
    if(notification) {
      notification.setLevel(level);
      notification.setDelay(options.delay);
      notification.drawAttention();

    } else if(options.retentiveId && storage.getItem(options.retentiveId)) {
      // Don't show this notification anymore

    } else {
      notification = this._newNotification(
        VecteraNotification.Type.TEXT,
        level,
        message,
        options
      );
    }

    return notification;
  }


  progress(message, onCancel) {
    const options = Object.assign({}, DEFAULT_PROGRESS_OPTIONS, {
      onCancel: onCancel,
      dismissable: !!onCancel,
      onRemove: this._onCancelHandler,
    });

    return this._newNotification(
      VecteraNotification.Type.PROGRESS,
      VecteraNotification.Level.INFO,
      message,
      options
    );
  }


  _newNotification(type, level, message, options) {
    let id = ++this.maxId;
    let notification = new VecteraNotification(id, type, level, message, options);

    this.notifications.push(notification);
    this.emit('add', notification);

    return notification;
  }


  _persistNotificationForReload(level, message, options) {
    let items = this._getPersistedItems();
    items.push({
      level,
      message,
      options,
    });
    this._setPersistedItems(items);
  }

  _loadPersistedNotifications() {
    let items = this._getPersistedItems();
    this._setPersistedItems([]);

    items.forEach(item => {
      let { level, message, options } = item;
      options.persistOnReload = false;
      this._add(level, message, options);
    });
  }

  _getPersistedItems() {
    let items = storage.getJSONItem(STORAGE_PERSISTENT_KEY, []);
    if(!Array.isArray(items)) {
      items = [];
    }
    return items;
  }
  _setPersistedItems(items) {
    storage.setJSONItem(STORAGE_PERSISTENT_KEY, items);
  }


  // Callback to be executed when a notification is removed.
  _onCancelHandler(notification) {
    let index = this.notifications.indexOf(notification);

    if(index !== -1) {
      this.notifications.splice(index, 1);
      this.emit('remove', notification);
    }
  }


  /**
   * Handle an error returned by requestService or apiService.
   *
   * @param {Error} error - The error returned by requestService or apiService
   * @param {string} notification - The notification to show for unknown errors.
   * @param {'server' | 'page' | 'none'} handleValidationErrors - How to handle ValidationErrors.
   *  - 'server' will show the error message returned by the server in a warning notification. This
   *    is usually what you want for simple actions where the errors are not shown on the page.
   *  - 'none' will not show a notification. This is usually what you want for simple forms where
   *    field errors are shown on the page.
   *  - 'page' will show a notification informing the user that they can find errors on the page.
   *    This is usually what you want for large forms where field errors are shown on the page but
   *    may be missed by the user.
   * @returns {ErrorValue}
   */
  handleError(error, notification, handleValidationErrors = 'server') {
    if(error.constructor === errors.OfflineError) {
      this.warning(
        gettextCatalog.getString('You seem to be offline. Please try again later.')
      );

    // Permission errors should contain a readable error message
    } else if(error.constructor === errors.UnauthorizedError) {
      this.warning(error.message);

    } else if(error.constructor === errors.ValidationError) {
      if(handleValidationErrors === 'server') {
        this.warning(error.message);
      } else if(handleValidationErrors === 'page') {
        this.warning(
          notification + ' ' + gettextCatalog.getString('Please check the page for errors.')
        );
      } else if(handleValidationErrors === 'none') {
        // Do nothing
      }

    } else {
      logger.error(error);
      this.error(
        notification + ' ' + gettextCatalog.getString('Please try again later.')
      );
    }

    // make sure the response data corresponds to the typescript ErrorValue type signature
    const errorResponse = {};
    for(const [key, value] of Object.entries(error.response.data)) {
      let coercedValue;
      if(typeof value === 'string') {
        coercedValue = [value];
      } else {
        coercedValue = value;
      }
      errorResponse[key] = coercedValue;
    }
    return errorResponse;
  }
}
