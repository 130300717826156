import { readableDate } from 'utils/util';


export default class SystemMessage {
  constructor(user, action, datetime) {
    this.user = user;
    this.action = action;

    this.datetime = datetime;
    this.datetimeString = readableDate(datetime);
  }

  get system() {
    return true;
  }
}
