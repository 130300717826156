import { NgModule } from '@angular/core';
import { ColorPickerModule } from './color-picker';
import { DatepickerModule } from './datepicker';
import { SegmentedButtonModule } from './segmented-button';
import { TimeValueAccessorDirective } from './time-value-accessor.directive';
import { ConfirmChangeModule } from './confirm-change';
import { DropdownsModule } from './dropdowns/dropdowns.module';
import { FilePickerModule } from './file-picker/file-picker.module';


@NgModule({
  declarations: [
    TimeValueAccessorDirective,
  ],
  exports: [
    ColorPickerModule,
    ConfirmChangeModule,
    DatepickerModule,
    DropdownsModule,
    FilePickerModule,
    SegmentedButtonModule,
    TimeValueAccessorDirective,
  ]
})
export class FormElementsModule {}
