import { Component, OnInit } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { ContactFormSettingsComponent } from '../../contact-form-settings.component';
import { fixURL } from 'utils/util/url';


@Component({
  selector: 'contact-form-redirect[instance]',
  templateUrl: './contact-form-redirect.component.html',
  providers: [{
    provide: SettingsComponent,
    useExisting: ContactFormRedirectComponent
  }]
})
export class ContactFormRedirectComponent
  extends ContactFormSettingsComponent
  implements OnInit {

  public redirectUrlInput = '';

  override ngOnInit(): void {
    super.ngOnInit();
    this.redirectUrlInput = this.instance.redirectUrl;
  }

  get isDefaultRedirect() {
    return this.instance.redirectUrl === '';
  }

  updateRedirectUrlModel() {
    if(this.redirectUrlInput === '') {
      this.redirectUrlInput = 'https://';
    }
    this.instance.redirectUrl = fixURL(this.redirectUrlInput);
  }
}
