import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf.min.js';


export default class PDFJSService {
  /**
   * pdfjs doesn't retry fetching the worker on a network error, so we wrap that part.
   */

  static get $inject() {
    return [
      'requestService',
    ];
  }

  constructor(
    requestService
  ) {
    this.requestService = requestService;
  }


  getDocument(url) {
    return this.setupWorker().then(() => {
      return pdfjsLib.getDocument(url).promise;
    });
  }


  setupWorker() {
    let promise = this.requestService.get(window.pdfjsLibWorkerSrc, {
      maxRetries: Infinity,
    })
      .then(response => {
        let blob = new Blob([response.data]);
        let url = URL.createObjectURL(blob);
        pdfjsLib.GlobalWorkerOptions.workerSrc = url;
      });

    this.setupWorker = () => promise;
    return promise;
  }
}
