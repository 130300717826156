import { EventEmitter } from 'utils/util';
import StreamTileMixin from '../../main/streams/StreamTileMixin';
import ContentTile from '../content/ContentTile';
import Tile from '../tiles/Tile';


const DEFAULT_ASPECT_RATIO = 16 / 9;
const SHORT_URL_REGEX = /(?:https?:\/\/)?(.*)/;
export const ZOOM_LEVELS = Object.freeze([
  0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5
]);



export default class CobrowseTile extends StreamTileMixin(ContentTile) {
  constructor(id, stream, ...args) {
    super(id, DEFAULT_ASPECT_RATIO, ...args);
    EventEmitter.setup(this, ['url']);

    this.connected = false;
    this.errorMessage = 'Connecting to tab...';

    this.url = '';
    this.urlShort = '';
    this.zoomLevel = 1;
    this.cursor = 'auto';

    this.setVideo(stream);
  }

  get type() {
    return Tile.Type.COBROWSE;
  }


  get minZoomLevel() {
    return ZOOM_LEVELS[0];
  }
  get maxZoomLevel() {
    return ZOOM_LEVELS[ZOOM_LEVELS.length - 1];
  }


  setConnected(connected) {
    this.connected = connected;
  }


  setUrl(url) {
    if(url === this.url) {
      return;
    }

    this.url = url;
    let match = url.match(SHORT_URL_REGEX) || [url, url];
    this.urlShort = match ? match[1] : url;

    this.emit('url', this, this.url, this.urlShort);
  }

  setCursor(cursor) {
    this.cursor = cursor || 'auto';
  }
}
