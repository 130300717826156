import { Component } from '@angular/core';
import { ContactFormQuestionInputComponent } from '../contact-form-question-input.component';
import { file } from 'utils/util';


type DataURL = string;
type FileItem = [string, DataURL];

@Component({
  selector: 'attachment-input',
  templateUrl: './attachment-input.component.html',
  styleUrls: ['./attachment-input.component.scss'],
})
export class AttachmentInputComponent extends ContactFormQuestionInputComponent<FileItem[]> {
  dragover = false;
  dragCount = 0;


  parsePrefill() {
    // Not supported
    return null;
  }


  get maxFileSize(): number {
    // Keep in sync with contact_form/models/question_type.py:AttachmentQuestionSerializer
    const maxFileSize = this.question.getMappedFieldId('teamleader') != null ? 20 : 5;
    return maxFileSize * 1024 * 1024;
  }

  get maxFileSizeText() {
    return $localize `Max size: ${this.maxFileSize / 1024 / 1024} MB`;
  }


  onDragOver($event: DragEvent) {
    $event.preventDefault();
  }

  onDragEnter($event: DragEvent) {
    $event.preventDefault();
    this.dragCount++;
    if(this.dragCount === 1 && this.containsFiles($event)) {
      this.dragover = true;
    }
  }

  containsFiles($event: DragEvent) {
    const items = $event.dataTransfer?.items || [];
    return [...items].some(item => item.kind === 'file');
  }

  onDragLeave($event: DragEvent) {
    $event.preventDefault();
    this.dragCount--;
    if(this.dragCount === 0) {
      this.dragover = false;
    }
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();
    this.dragCount = 0;
    this.dragover = false;
    const files = [...$event.dataTransfer?.files || []];
    files.forEach(file => this.add(file));
  }


  onFileChange($event: Event) {
    const elemInputAdd = $event.target as HTMLInputElement;
    const files = [...elemInputAdd.files || []];
    elemInputAdd.value = '';
    files.forEach(file => this.add(file));
  }

  add(f: File) {
    file.toDataURL(f).then((url: DataURL) => {
      const item: FileItem = [f.name, url];
      const value = [...this.value || [], item];
      this.writeValue(value);
    });
  }

  remove(item: FileItem) {
    const value = (this.value || []).filter(i => i !== item);
    this.writeValue(value);
  }
}
