import template from './sidebar.html?raw';

import { normalizeWheel, DragListener } from 'utils/util';


class SidebarController {
  static get $inject() {
    return [
      'requestUserService',
      'userService',
      'tileRendererDesktopService',
      '$element',
      'hubSpotService',
      'meetingService',
      'adminPulseService',
    ];
  }

  constructor(
    requestUserService,
    userService,
    tileRendererDesktopService,
    $elem,
    hubSpotService,
    meetingService,
    adminPulseService
  ) {
    this._bind();

    this.tileRendererDesktopService = tileRendererDesktopService;
    this.$elem = $elem;
    this.$elemMain = null;
    this.$elemTiles = null;
    this.$elemSizer = null;
    this.userService = userService;
    this.requestUserService = requestUserService;
    this.meetingService = meetingService;
    this.adminPulseService = adminPulseService;

    this.mySession = userService.mySession;

    this.scrollTopStart = 0;
    this.dragListener = new DragListener(document.createElement('div'), {
      onDrag: this._onDrag,
      preventDefault: false,
    });

    this.hubSpotService = hubSpotService;
  }

  get shouldShowHubSpotWidget() {
    return (
      this.userService.mySession.accessLevel.isHost
      && (this.hubSpotService.isConnected || this.hubSpotService.isExpired)
    );
  }

  get shouldShowAdminPulseWidget() {
    return (
      this.userService.mySession.accessLevel.isHost
      && (this.adminPulseService.isConnected || this.adminPulseService.isExpired)
      && (
        this.meetingService.owner
        && this.requestUserService.user.organizationId === this.meetingService.owner.organizationId
      )
      && this.meetingService.adminpulseRelation
    );
  }


  _bind() {
    this._draw = this._draw.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this._onWheel = this._onWheel.bind(this);
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onDrag = this._onDrag.bind(this);
  }


  $onInit() {
    this.$elemMain = this.$elem.find('.meeting-sidebar__main');
    this.$elemTiles = this.$elem.find('.meeting-sidebar__tiles');
    this.$elemSizer = this.$elemTiles.find('.meeting-sidebar__sizer');

    this.tileRendererDesktopService.on('draw', this._draw);
    this.tileRendererDesktopService.setElemSidebar(this.$elemTiles);

    this.$elemMain.on('scroll', this._onScroll);

    $document.on('wheel', '.tile.tile--inactive', this._onWheel);
    $document.on('touchstart', '.tile.tile--inactive', this._onTouchStart);
  }

  $onDestroy() {
    this.tileRendererDesktopService.off('draw', this._draw);

    $document.off('wheel', '.tile.tile--inactive', this._onWheel);
    $document.off('touchstart', '.tile.tile--inactive', this._onTouchStart);
  }


  _draw() {
    this.$elemMain.css(
      'overflowY',
      this.tileRendererDesktopService.sidebarOverflow ? 'scroll' : 'hidden'
    );
    this.$elemTiles.css('flexBasis', this.tileRendererDesktopService.sidebarTilesHeight);
    this.$elemMain.scrollTop(this.tileRendererDesktopService.sidebarTilesHeight);
    // Firefox doesn't always trigger this automatically. For example: when you go from a
    // scrollable $elemMain to a non-scrollable $elemMain, Chrome fires a 'scroll' event, but
    // Firefox doesn't.
    this._onScroll();
  }


  _onScroll() {
    this.tileRendererDesktopService.setSidebarScroll(this.$elemMain.scrollTop());
  }


  _onWheel($event) {
    let delta = normalizeWheel($event);
    this.$elemMain.scrollTop(this.$elemMain.scrollTop() + delta.y);

    // Avoid default Safari behaviour, where the entire viewport is dragged
    return false;
  }


  _onTouchStart($event) {
    this.scrollTopStart = this.$elemMain.scrollTop();
    this.dragListener._onTouchStart($event.originalEvent);
  }


  _onDrag(diff) {
    if(diff[0] && diff[0].y != null) {
      this.$elemMain.scrollTop(this.scrollTopStart - diff[0].y);
    }
  }
}


export default {
  controller: SidebarController,
  controllerAs: 'sidebarCtrl',
  template,

  bindings: {
    translucent: '<',
  },
};
