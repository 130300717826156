<div class="private-notes__container">
  <div class="px-4 py-3 d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <span
        class="text-h4"
        i18n-matTooltip
        i18n
      >Personal notes
      </span>
      <svg-icon
        *ngIf="canUseSmartSummary"
        class="ml-2"
        [svgName]="'info_badged'"
        matTooltip="Excluded from smart summary"
        i18n-matTooltip
      ></svg-icon>
    </div>
    <div class="d-flex">
      <button 
        class="btn btn--square"
        [copyText]="privateNotesService.content"
        data-testid="privateNotesCopyButton"
      >
        <svg-icon class="btn__icon" [svgName]="'copy'"></svg-icon>
      </button>
      <button
        *ngIf="shouldShowIntegrationSyncButton"
        class="btn btn--square position-relative"
        (click)="$event.preventDefault(); this.clearNewIndicator()"
        [cdkMenuTriggerFor]="actions"
        data-testid="privateNotesMenuButton"
      >
        <svg-icon class="btn__icon" [svgName]="'more'"></svg-icon>
        <span
          *ngIf="shouldShowNewIndicator"
          class="private-notes__new-indicator"
        ></span>
      </button>
      <ng-template #actions>
        <share-menu
          [content]="privateNotesService.content"
          [integration]="true"
          [integrationOptionDisabled]="!syncService.syncingIsEnabled()"
          [trackingViaIntegrationCallback]="sharedViaIntegration.bind(this)"
          data-testid="saveToAdminPulseButton">
        </share-menu>
      </ng-template>
    </div>
  </div>

  <div class="private-notes__textarea-wrapper">
    <textarea
      class="textarea private-notes__textarea"
      placeholder="Anything you type here is only visible for you and other meeting hosts"
      i18n-placeholder
      [(ngModel)]="privateNotesService.content"
      maxlength="9500"
      data-testid="privateNotesContent"
      character-count
      [initialCount]="privateNotesService.content.length"
    ></textarea>
  </div>
</div>
