import { Inject, Injectable } from '@angular/core';
import { Memoize } from 'typescript-memoize';
import { HubSpotConfig } from 'integrations/models/HubSpotConfig';
import { assertOrThrow, logger } from 'utils/util';
import { IntegrationService } from 'integrations/integration.service';

export type HubSpotContact = {
  firstName: string,
  lastName: string,
  properties: Record<string, string>
  hubspotId: number,
}

export enum HubSpotOutcome {
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  RESCHEDULED = 'RESCHEDULED',
  NO_SHOW = 'NO_SHOW',
  CANCELLED = 'CANCELLED',
}

type HubSpotMeetingData = {
  endTime?: Date,
  outcome?: HubSpotOutcome,
  hostEmail?: string,
  notes?: string,
}

export const HUB_SPOT = 'HubSpot';

@Injectable()
export class HubSpotService extends IntegrationService {
  public contactData: HubSpotContact | null = null;

  constructor(
    @Inject('apiService') private apiService,
    @Inject('modelFactory') private modelFactory,
  ) {
    super('hubspot', HUB_SPOT, $localize `HubSpot meeting record`);
  }

  public get isActive(): boolean {
    return this.contactConnected;
  }

  @Memoize()
  getConfig(): Promise<HubSpotConfig> {
    return this.modelFactory.read({
      model: HubSpotConfig,
      identifiers: {
        organizationId: this.requestUserService.user.organizationId
      }
    }).then(({ data: hubSpotConfig }) => {
      return hubSpotConfig;
    }).catch(error => {
      logger.error(error);
    });
  }

  @Memoize()
  getContactData(contactEmail): Promise<HubSpotContact> {
    return this.apiService.get(
      'integrations/hubspot/contact',
      { 'params': { 'email': contactEmail } }
    ).then( ({ data: hubSpotContact }) => {
      this.contactData = hubSpotContact as HubSpotContact;
      return this.contactData;
    });
  }

  clearContactData() {
    this.contactData = null;
  }

  get contactConnected() {
    return this.isConnected && this.contactData != null;
  }

  public async rawPush(data: HubSpotMeetingData): Promise<void> {
    assertOrThrow(this.contactData != null);
    const fullData: HubSpotMeetingData = {
      ...data,
      ...{
        meetingId: this.meetingService.id,
        hostEmail: this.userService.me.email,
        contactId: this.contactData.hubspotId,
      }
    };

    await this.apiService.post(
      'integrations/hubspot/meeting',
      fullData
    );
  }

  override onMeetingEnded() {
    if(this.shouldPushMeeting) {
      const meetingEndedData: any = {
        endTime: this.activeIntervalService.meetingEndTime || new Date(),
        outcome: HubSpotOutcome.COMPLETED,
      };
      try {
        this.push(meetingEndedData);
      } catch(error) {
        // We have already shown a notification at this point and we don't need to update the sync
        // state.
      }
    }
  }
}
