import template from './knockerTile.html?raw';

import { UserTileController } from '../users/userTile.component';

const MAX_BUBBLE_AGE = 30000;
const TEXT_WIDTH = 460;


class KnockerTileController extends UserTileController {
  static get $inject() {
    return [
      'knockerService',
      'knockerChatService',
      'dropdownService',
    ].concat(UserTileController.$inject);
  }

  constructor(
    knockerService,
    knockerChatService,
    dropdownService,
    ...args
  ) {
    super(...args);

    this.knockerService = knockerService;
    this.knockerChatService = knockerChatService;
    this.dropdownService = dropdownService;

    this.$elemTile = null;
    this.$elemText = null;
    this.$elemBody = null;

    this.isCompact = false;
    this.numUnreadMessages = 0;
  }


  $onInit() {
    this.$elemTile = this.$elem.find('.knocker-tile');
    this.$elemText = this.$elem.find('.knocker-tile__text');
    this.$elemBody = this.$elem.find('.knocker-tile__body');
    this.tile.chatBox.on('message', this._onMessage);
    this.dropdownService.on('active', this._onDropdownActive);

    super.$onInit();
  }

  $onDestroy() {
    this.tile.chatBox.off('message', this._onMessage);
    this.dropdownService.off('active', this._onDropdownActive);

    super.$onDestroy();
  }


  get isViewportAllowed() {
    return false;
  }


  _draw() {
    super._draw();

    this.$elem.css({
      left: this.tile.rect.left - TEXT_WIDTH,
      width: this.tile.rect.width + TEXT_WIDTH,
    });
    this.$elemTile.css('width', this.tile.rect.width + (this.isCompact ? 0 : TEXT_WIDTH));
    this.$elemText.css('width', TEXT_WIDTH);
    this.$elemBody.css('width', this.tile.rect.width - 2 * this.tile.borderWidth);
  }


  setCompact(isCompact) {
    if(isCompact !== this.isCompact) {
      this.isCompact = isCompact;
      this._draw();
    }
  }
  toggleCompact() {
    this.setCompact(!this.isCompact);
  }


  _onMessage(message) {
    if(message.system
        || message.sender.user.isMe
        || new Date() - message.datetime > MAX_BUBBLE_AGE
        || this.knockerChatService.chatBox === this.tile.chatBox) {
      return;
    }

    this.numUnreadMessages++;
  }

  _onDropdownActive() {
    if(this.knockerChatService.chatBox === this.tile.chatBox) {
      this.numUnreadMessages = 0;
    }
  }


  toggleChat($event) {
    this.knockerChatService.toggle(this.tile.chatBox, $event);
  }
}


export default {
  controller: KnockerTileController,
  controllerAs: 'knockerTileCtrl',
  template,

  bindings: {
    tile: '<',
  },
};
