import { Component, Inject } from '@angular/core';
import { Badge } from 'utils/ui-components/badge';
import { UrlService } from 'utils/url.service';
import { User } from 'core/models/User';
import { DashboardScope } from 'dashboard/data-page/data-page.component';

@Component({
  selector: 'ui-components',
  templateUrl: './ui-components.component.html',
  styleUrls: ['./ui-components.component.scss'],
})
export class UIComponentsComponent {
  dynamicBadge : Badge = {
    label: 'dynamic Badge',
    icon: { name: 'add', size: 14 }
  };

  page = 1;

  users: User[] = [
    {
      id: 1,
      fullName: 'John Doe',
      initials: 'JD',
    },
    {
      id: 2,
      fullName: 'Jane Loe',
      initials: 'JL',
    }
  ];

  scopes = [DashboardScope.PERSONAL, DashboardScope.TEAM, DashboardScope.ALL];
  scope = DashboardScope.PERSONAL;

  constructor(
    @Inject('notificationService') private notificationService,

    public urlService: UrlService,
  ) {
  }

  executeCallback() {
    this.notificationService.success('callback executed');
  }
}
