import { Component, } from '@angular/core';
import { SettingsComponent } from 'utils/settings/settings.component';
import { User } from 'core/models/User';
import { AppointmentType } from 'scheduling/models/AppointmentType';


@Component({
  template: '',
})
export abstract class AppointmentTypeSettingsComponent
  extends SettingsComponent<AppointmentType> {

  override get hasPermissionToEdit() {
    return this.isAdmin || !this.instance.isTeamAppointmentType;
  }

  hasPermissionToEditMember(member: User) {
    return member.id === this.requestUserService.user.id || this.isAdmin;
  }
}
