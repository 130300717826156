<div
  *ngFor="let option of options; let index = index;"
  class="checkbox__wrapper"
>
  <input
    type="checkbox"
    class="checkbox"
    [name]="question.id"
    [id]="question.id + '.' + index"
    [checked]="checkboxes[index]"
    (change)="onOptionChange($event, index)"
  />
  <label
    class="checkbox__label"
    [for]="question.id + '.' + index"
  >
    {{ option[1] }}
  </label>
</div>

<div
  *ngIf="otherOption"
  class="checkbox__wrapper mt--2"
>
  <input
    type="checkbox"
    class="checkbox"
    [name]="question.id"
    [id]="question.id + '.other'"
    [checked]="otherOptionSelected"
    (change)="onOtherOptionChange($event)"
  />
  <label
    class="checkbox__label checkbox__label--input"
    [for]="question.id + '.other'"
  >
    <input
      class="input text-teal-500"
      [ngModel]="otherOptionValue"
      (ngModelChange)="setOtherOptionValue($event)"
      placeholder="Other"
      i18n-placeholder
    />
  </label>
</div>
