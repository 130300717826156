<h1 class="page-title">Styleguide</h1>

<h1 class="style__header-with-link">
  Fonts
  <a class="style__anchor" name="fonts"></a>
  <a class="style__link" href="#fonts">
    <svg-icon
      class="btn__icon"
      [svgName]="'link'"
      [svgStyle]="'outline'">
    </svg-icon>
  </a>
</h1>

<div class="card">
  <p>Default: Inter 14px regular (400) / line height: 21px</p>
  <p class="text-sm">Small: Inter 14px regular (400) / line height: 18px</p>
  <p class="style__text-btn">Button: Inter 14px medium (500) / line height: 18px</p>

  <h1>Heading 1 / Inter 24px bold (700 / line height: 30px</h1>
  <h2>Heading 2 / Inter 18px medium (500) / line height: 30px</h2>
  <h3>Heading 3 / Inter 16px medium (500) / line height: 30px</h3>
  <h4>Heading 4 / Inter 12px bold (700) / line height: 30px / tracking: 0.6px / text transform: uppercase</h4>
</div>



<h1 class="style__header-with-link">
  Colors
  <svg-icon
    class="btn__icon clickable ml-3"
    [svgName]="'info_badged'"
    [svgStyle]="'filled'"
    matTooltip="These colors may change depending on the user that is currently signed in">
  </svg-icon>
  <a class="style__anchor" name="colors"></a>
  <a class="style__link" href="#colors">
    <svg-icon
      class="btn__icon"
      [svgName]="'link'"
      [svgStyle]="'outline'">
    </svg-icon>
  </a>
</h1>

<div class="card card--no-padding">
  <div class="overflow-auto">
    <table class="table align-middle style__color-fields">
      <thead>
        <tr>
          <th>Color</th>
          <th>100</th>
          <th>200</th>
          <th>300</th>
          <th>400</th>
          <th>500</th>
        </tr>
      </thead>
      <tbody class="border-none">
        <tr>
          <td>
            Primary
          </td>
          <td>
            <div class="style__color-field bg-primary-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-primary-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-primary-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-primary-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-primary-500" color-field></div>
          </td>
        </tr>

        <tr>
          <td>Neutral</td>
          <td>
            <div class="style__color-field bg-neutral-100" color-field color-field-usage="Background,Text on dark bg">
            </div>
          </td>
          <td>
            <div class="style__color-field bg-neutral-200" color-field color-field-usage="Page background"></div>
          </td>
          <td>
            <div class="style__color-field bg-neutral-300" color-field color-field-usage="Muted text on dark bg"></div>
          </td>
          <td>
            <div class="style__color-field bg-neutral-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-neutral-500" color-field color-field-usage="Muted text"></div>
          </td>
        </tr>

        <tr>
          <td>Teal</td>
          <td>
            <div class="style__color-field bg-teal-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-teal-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-teal-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-teal-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-teal-500" color-field color-field-usage="Text"></div>
          </td>
        </tr>

        <tr>
          <td>Info</td>
          <td>
            <div class="style__color-field bg-info-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-info-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-info-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-info-400" color-field color-field-usage="Links"></div>
          </td>
          <td>
            <div class="style__color-field bg-info-500" color-field></div>
          </td>
        </tr>

        <tr>
          <td>Success</td>
          <td>
            <div class="style__color-field bg-success-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-success-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-success-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-success-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-success-500" color-field></div>
          </td>
        </tr>

        <tr>
          <td>Warning</td>
          <td>
            <div class="style__color-field bg-warning-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-warning-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-warning-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-warning-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-warning-500" color-field></div>
          </td>
        </tr>

        <tr>
          <td>Error</td>
          <td>
            <div class="style__color-field bg-error-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-error-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-error-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-error-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-error-500" color-field></div>
          </td>
        </tr>

        <tr>
          <td>Violet</td>
          <td>
            <div class="style__color-field bg-violet-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-violet-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-violet-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-violet-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-violet-500" color-field></div>
          </td>
        </tr>

        <tr>
          <td>Marketing</td>
          <td>
            <div class="style__color-field bg-marketing-100" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-marketing-200" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-marketing-300" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-marketing-400" color-field></div>
          </td>
          <td>
            <div class="style__color-field bg-marketing-500" color-field></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>



<h1 class="style__header-with-link">
  Shadows
  <a class="style__anchor" name="shadows"></a>
  <a class="style__link" href="#shadows">
    <svg-icon
      class="btn__icon"
      [svgName]="'link'"
      [svgStyle]="'outline'">
    </svg-icon>
  </a>
</h1>

<div class="card">
  <div class="style__shadow-field d-block shadow-2">2: various subtle elements: toggle, slider, active tile</div>
  <div class="style__shadow-field d-block shadow-4">4: dropdown, pressed filled button</div>
  <div class="style__shadow-field d-block shadow-16">16: modal, drawer</div>
</div>



<h1 class="style__header-with-link">
  Components
  <a class="style__anchor" name="components"></a>
  <a class="style__link" href="#components">
    <svg-icon
      class="btn__icon"
      [svgName]="'link'"
      [svgStyle]="'outline'">
    </svg-icon>
  </a>
</h1>

<div class="card">
  <h2 class="style__header-with-link">
    Buttons
    <a class="style__anchor" name="buttons"></a>
    <a class="style__link" href="#buttons">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <h3>Basic styles</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn">Default</button>
    <button class="mr-2 btn btn--secondary">Secondary</button>
    <button class="mr-2 btn btn--link">Link</button>
    <div class="mr-2 d-flex p-2 pr-0 bg-teal-200 border-round">
      <button class="mr-2 btn btn--opaque">Opaque</button>
      <button class="mr-2 btn btn--translucent">Translucent</button>
    </div>
    <div class="mr-2 d-flex p-2 pr-0 bg-teal-400 border-round">
      <button class="mr-2 btn btn--inverted" matTooltip="This is intended to be used on dark backgrounds">Inverted</button>
      <button class="mr-2 btn btn--translucent-inverted" matTooltip="This is intended to be used on dark backgrounds">
        Translucent inverted
      </button>
    </div>
  </div>

  <h3>Colored background</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn btn--primary">Primary</button>
    <button class="mr-2 btn btn--success">Success</button>
    <button class="mr-2 btn btn--error">Error</button>
    <button class="mr-2 btn btn--warning">Warning</button>
    <button class="mr-2 btn btn--teal">Teal</button>
    <button class="mr-2 btn btn--marketing">Marketing</button>
  </div>

  <h3>Colored text</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn btn--text-primary">Primary</button>
    <button class="mr-2 btn btn--text-success">Success</button>
    <button class="mr-2 btn btn--text-error">Error</button>
    <button class="mr-2 btn btn--text-warning">Warning</button>
    <button class="mr-2 btn btn--text-teal">Teal</button>
    <button class="mr-2 btn btn--text-marketing">Marketing</button>
  </div>

  <h3>Colored outline</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn btn--outline-primary">Primary</button>
    <button class="mr-2 btn btn--outline-success">Success</button>
    <button class="mr-2 btn btn--outline-error">Error</button>
    <button class="mr-2 btn btn--outline-warning">Warning</button>
    <button class="mr-2 btn btn--outline-teal">Teal</button>
    <button class="mr-2 btn btn--outline-marketing">Marketing</button>
  </div>

  <h3>Shapes</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn btn--secondary btn--round">
      <svg-icon
        class="btn__icon"
        [svgName]="'settings'"
        [svgStyle]="'outline'"
        matTooltip="Round">
      </svg-icon>
    </button>
    <button class="mr-2 btn btn--secondary btn--square">
      <svg-icon
        class="btn__icon"
        [svgName]="'settings'"
        [svgStyle]="'outline'"
        matTooltip="Square">
      </svg-icon>
    </button>
  </div>

  <h3>Icons</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn btn--secondary">
      <svg-icon
        class="btn__icon"
        [svgName]="'settings'"
        [svgStyle]="'outline'"
        matTooltip="Round">
      </svg-icon>
      Left icon
    </button>
    <button class="mr-2 btn btn--secondary">
      <svg-icon
        class="btn__icon btn__icon--right"
        [svgName]="'settings'"
        [svgStyle]="'outline'"
        matTooltip="Square">
      </svg-icon>
      Right icon
    </button>
  </div>

  <h3>Sizes</h3>

  <div class="d-flex align-items-center mr--2">
    <button class="mr-2 btn btn--secondary btn--sm">Small</button>
    <button class="mr-2 btn btn--secondary btn--md">Medium (default)</button>
    <button class="mr-2 btn btn--secondary btn--lg">Large</button>
  </div>

  <h3>Group</h3>

  <div class="d-flex">
    <div class="mr-2 input-group">
      <button class="btn btn--secondary">Button</button>
      <button class="btn btn--secondary btn--square">
        <svg-icon
          class="btn__icon"
          [svgName]="'chevron_down'"
          [svgStyle]="'outline'">
        </svg-icon>
      </button>
    </div>

    <div class="mr-2 input-group">
      <button class="btn btn--teal">Option A</button>
      <button class="btn btn--secondary">Option B</button>
      <button class="btn btn--secondary">Option C</button>
    </div>
  </div>


  <h2 class="style__header-with-link">
    Tables
    <a class="style__anchor" name="tables"></a>
    <a class="style__link" href="#tables">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <div class="row">
    <div class="col">
      <table class="table table--hoverable">
        <thead>
          <tr>
            <th>#</th>
            <th>First name</th>
            <th>Last name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Frodo</td>
            <td>Baggins</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Samwise</td>
            <td>Gamgee</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Peregrin</td>
            <td>Took</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Meriadoc</td>
            <td>Brandybuck</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col">
      <table class="table table--striped table--hoverable">
        <thead>
          <tr>
            <th>#</th>
            <th>First name</th>
            <th>Last name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Frodo</td>
            <td>Baggins</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Samwise</td>
            <td>Gamgee</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Peregrin</td>
            <td>Took</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Meriadoc</td>
            <td>Brandybuck</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <h2 class="style__header-with-link">
    Cards
    <a class="style__anchor" name="cards"></a>
    <a class="style__link" href="#cards">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <div class="row">
    <div class="col">
      <div class="card">Card</div>
    </div>
    <div class="col">
      <div class="card clickable">Clickable</div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card card--sm">Small</div>
    </div>
    <div class="col">
      <div class="card card--sm clickable">Small clickable</div>
    </div>
  </div>

  <h2 class="style__header-with-link">
    Fields
    <svg-icon
      class="btn__icon clickable ml-3"
      [svgName]="'info_badged'"
      [svgStyle]="'filled'"
      matTooltip="Fields are cards with preset configuration and color to quickly communicate bitesize info to users.">
    </svg-icon>
    <a class="style__anchor" name="fields"></a>
    <a class="style__link" href="#fields">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <div class="row align-items-center">
    <div class="col p">
      <div class="field field--teal">Teal</div>
    </div>
    <div class="col p">
      <div class="field field--teal"><field-icon [type]="'teal'"></field-icon>With icon</div>
    </div>
    <div class="col p">
      <div class="field field--teal clickable">Clickable</div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col p">
      <div class="field field--info">Info</div>
    </div>
    <div class="col p">
      <div class="field field--info"><field-icon [type]="'info'"></field-icon>With icon</div>
    </div>
    <div class="col p">
      <div class="field field--info clickable">Clickable</div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col p">
      <div class="field field--success">Success</div>
    </div>
    <div class="col p">
      <div class="field field--success"><field-icon [type]="'success'"></field-icon>With icon</div>
    </div>
    <div class="col p">
      <div class="field field--success clickable">Clickable</div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col p">
      <div class="field field--warning">Warning</div>
    </div>
    <div class="col p">
      <div class="field field--warning"><field-icon [type]="'warning'"></field-icon>With icon</div>
    </div>
    <div class="col p">
      <div class="field field--warning clickable">Clickable</div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col p">
      <div class="field field--error">Error</div>
    </div>
    <div class="col p">
      <div class="field field--error"><field-icon [type]="'error'"></field-icon>With icon</div>
    </div>
    <div class="col p">
      <div class="field field--error clickable">Clickable</div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col p">
      <div class="field field--marketing">Marketing</div>
    </div>
    <div class="col p">
      <div class="field field--marketing"><field-icon [type]="'marketing'"></field-icon>With icon</div>
    </div>
    <div class="col p">
      <div class="field field--marketing clickable">Clickable</div>
    </div>
  </div>



  <h2 class="style__header-with-link">
    Tooltips
    <a class="style__anchor" name="tooltips"></a>
    <a class="style__link" href="#tooltips">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <div class="p">
    <div class="input-group">
      <button class="btn btn--secondary" matTooltip="I am a tooltip!">Below</button>
      <button class="btn btn--secondary" matTooltip="I am a tooltip!" matTooltipPosition="right">Right</button>
      <button class="btn btn--secondary" matTooltip="I am a tooltip!" matTooltipPosition="above">Above</button>
      <button class="btn btn--secondary" matTooltip="I am a tooltip!" matTooltipPosition="left">Left</button>
    </div>
  </div>


  <h2 class="style__header-with-link">
    Badges
    <a class="style__anchor" name="badges"></a>
    <a class="style__link" href="#badges">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <h3>Colors</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 badge">Default</div>
    <div class="mr-2 badge badge--primary">Primary</div>
    <div class="mr-2 badge badge--info">Info</div>
    <div class="mr-2 badge badge--success">Success</div>
    <div class="mr-2 badge badge--warning">Warning</div>
    <div class="mr-2 badge badge--error">Error</div>
    <div class="mr-2 badge badge--teal">Teal</div>
    <div class="mr-2 badge badge--violet">Violet</div>
    <div class="mr-2 badge badge--marketing">Marketing</div>
  </div>

  <h3>Sizes</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 badge badge--sm">Small</div>
    <div class="mr-2 badge">Medium (default)</div>
    <div class="mr-2 badge badge--lg">Large</div>
  </div>

  <h3>Shapes</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 badge">Default</div>
    <div class="mr-2 badge badge--round" matTooltip="Round">?</div>
  </div>

  <h3>Icon</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 badge">Without icon</div>
    <div class="mr-2 badge">
      <svg-icon 
        class="badge__icon"
        [svgName]="'clock'"
        [svgStyle]="'outline'"
        [svgSize]="14">
      </svg-icon>
      With icon
    </div>
  </div>

  <h3>Clickable</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 badge">Default</div>
    <div class="mr-2 badge clickable">Clickable</div>
  </div>



  <h2 class="style__header-with-link">
    Tags
    <a class="style__anchor" name="tags"></a>
    <a class="style__link" href="#tags">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <h3>Sizes</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 tag tag--sm">Small</div>
    <div class="mr-2 tag">Medium (default)</div>
    <div class="mr-2 tag tag--lg">Large</div>
  </div>

  <h3>Closeable</h3>

  <div class="d-flex align-items-center mr--2">
    <div class="mr-2 tag">Default</div>
    <div class="mr-2 tag">
      <span>Closeable</span>
      <svg-icon
        class="tag__close"
        [svgName]="'close'"
        [svgStyle]="'outline'"
        [svgSize]="14">
      </svg-icon>
    </div>
  </div>
</div>



<h1 class="style__header-with-link">
  Form elements
  <a class="style__anchor" name="form-elements"></a>
  <a class="style__link" href="#form-elements">
    <svg-icon
      class="btn__icon"
      [svgName]="'link'"
      [svgStyle]="'outline'">
    </svg-icon>
  </a>
</h1>

<div class="card">
  <div class="row">
    <div class="col">
      <h3 class="style__header-with-link">
        Enabled</h3>
    </div>
    <div class="col">
      <h3 class="style__header-with-link">
        Disabled</h3>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <label for="name">Name</label>
      <input class="input" id="name" placeholder="Enter your name">
    </div>
    <div class="col form-group">
      <label for="input1">Name</label>
      <input class="input" id="name-disabled" value="Seppe Lenders" disabled>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <label for="custom-domain">Custom domain</label>
      <div class="input-group">
        <div class="input__prefix">https://</div>
        <input class="input" id="custom-domain">
      </div>
    </div>

    <div class="col form-group">
      <label for="custom-domain">Custom domain</label>
      <div class="input-group">
        <div class="input__prefix">https://</div>
        <input class="input" id="custom-domain" value="meet.vectera.com" disabled>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <label for="vectera-email">Vectera email</label>
      <div class="input-group">
        <input class="input" id="vectera-email">
        <div class="input__postfix">@vectera.com</div>
      </div>
    </div>

    <div class="col form-group">
      <label for="vectera-email">Vectera email</label>
      <div class="input-group">
        <input class="input" id="vectera-email" value="team" disabled>
        <div class="input__postfix">@vectera.com</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <label for="notes">Notes</label>
      <textarea class="textarea" placeholder="Anything you want to tell us?"></textarea>
    </div>
    <div class="col form-group">
      <label for="notes-disabled">Notes</label>
      <textarea class="textarea" id="notes-disabled" disabled>I like Vectera!</textarea>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <label for="country">Country</label>
      <select class="select" id="country">
        <option selected>Belgium</option>
        <option>Netherlands</option>
      </select>
    </div>

    <div class="col form-group">
      <label for="country-disabled">Country</label>
      <select class="select" id="country-disabled" disabled>
        <option selected>Belgium</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <input id="newsletter" type="checkbox" class="checkbox" />
      <label for="newsletter" class="checkbox__label">I want to receive the newsletter</label>
    </div>
    <div class="col">
      <div class="form-group">
        <input id="checkbox-disabled-off" type="checkbox" class="checkbox" disabled />
        <label for="checkbox-disabled-off" class="checkbox__label">Disabled (off)</label>
      </div>
      <div class="form-group">
        <input id="checkbox-disabled-on" type="checkbox" class="checkbox" checked disabled />
        <label for="checkbox-disabled-on" class="checkbox__label">Disabled (on)</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <div class="radio__wrapper">
        <input name="radio" id="option_a" type="radio" class="radio" checked />
        <label for="option_a" class="radio__label">Option A</label>
      </div>
      <div class="radio__wrapper">
        <input name="radio" id="option_b" type="radio" class="radio" />
        <label for="option_b" class="radio__label">Option B</label>
      </div>
      <div class="radio__wrapper">
        <input name="radio" id="option_c" type="radio" class="radio" />
        <label for="option_c" class="radio__label">Option C</label>
      </div>
      <div class="radio__wrapper mt--2">
        <input name="radio" id="option_input" type="radio" class="radio" />
        <label for="option_input" class="radio__label radio__label--input">
          <input class="input" placeholder="Other" />
        </label>
      </div>
    </div>

    <div class="col form-group">
      <div class="radio__wrapper">
        <input name="radio_disabled" id="option_a_disabled" type="radio" class="radio" checked disabled />
        <label for="option_a_disabled" class="radio__label">Option A</label>
      </div>
      <div class="radio__wrapper">
        <input name="radio_disabled" id="option_b_disabled" type="radio" class="radio" disabled />
        <label for="option_b_disabled" class="radio__label">Option B</label>
      </div>
      <div class="radio__wrapper">
        <input name="radio_disabled" id="option_c_disabled" type="radio" class="radio" disabled />
        <label for="option_c_disabled" class="radio__label">Option C</label>
      </div>
      <div class="radio__wrapper mt--2">
        <input name="radio_disabled" id="option_input_disabled" type="radio" class="radio" disabled />
        <label for="option_input_disabled" class="radio__label radio__label--input">
          <input class="input" placeholder="Other" disabled />
        </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col toggle__wrapper">
      <div class="form-group">
        <input id="notifications" type="checkbox" class="toggle" />
        <label for="notifications" class="toggle__label">Receive notifications</label>
      </div>
      <div class="form-group">
        <input id="notifications-right" type="checkbox" class="toggle" />
        <label for="notifications-right" class="toggle__label toggle__label--right">Label on the right</label>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <input id="toggle-disabled-off" type="checkbox" class="toggle" disabled />
        <label for="toggle-disabled-off" class="toggle__label">Disabled (off)</label>
      </div>
      <div class="form-group">
        <input id="toggle-disabled-on" type="checkbox" class="toggle" checked disabled />
        <label for="toggle-disabled-on" class="toggle__label">Disabled (on)</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <label for="meeting-hosts">Meeting hosts</label>
      <input id="meeting-hosts" type="range" class="range" />
    </div>
    <div class="col form-group">
      <label for="range-disabled">Meeting hosts</label>
      <input id="range-disabled" type="range" class="range" disabled />
    </div>
  </div>
</div>



<h1 class="style__header-with-link">
  Brand
  <a class="style__anchor" name="brand"></a><a class="style__link" href="#brand">
    <svg-icon
      class="btn__icon"
      [svgName]="'link'"
      [svgStyle]="'outline'">
    </svg-icon>
  </a>
</h1>

<div class="card">
  <h2 class="style__header-with-link">
    Logo
    <a class="style__anchor" name="logo"></a><a class="style__link" href="#logo">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <h3 class="style__header-with-link">SVG</h3>

  <div class="p px-4 py-2 d-flex align-items-center border-round">
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'icon'" [logoColor]="'color'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'compact'" [logoColor]="'color'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'full'" [logoColor]="'color'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'inline'" [logoColor]="'color'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'icon_nopadding'" [logoColor]="'color'"></app-logo>
  </div>

  <div class="p px-4 py-2 d-flex align-items-center border-round">
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'icon'" [logoColor]="'dark'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'compact'" [logoColor]="'dark'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'full'" [logoColor]="'dark'"></app-logo>
  </div>

  <div class="p px-4 py-2 d-flex align-items-center border-round bg-teal-500">
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'icon'" [logoColor]="'light'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'compact'" [logoColor]="'light'"></app-logo>
    <app-logo class="mr-16" style="height: 60px;" [logoVariant]="'full'" [logoColor]="'light'"></app-logo>
  </div>

  <h2 class="style__header-with-link">
    Loading
    <a class="style__anchor" name="loading"></a><a class="style__link" href="#loading">
      <svg-icon
        class="btn__icon"
        [svgName]="'link'"
        [svgStyle]="'outline'">
      </svg-icon>
    </a>
  </h2>

  <div class="p d-flex align-items-center">
    <div class="mr-2">Default:</div>
    <loading></loading>
  </div>
  <div class="p d-flex align-items-center">
    <div class="mr-2">Custom size:</div>
    <div style="width: 48px;">
      <loading size="'custom'"></loading>
    </div>
  </div>
</div>
