/**
 * This file is used in the browser to provide some translation helpers: gettext and
 * GettextTemplate. These helpers are not exported in the usual way, but instead put directly on
 * window. The reason is that we need to provide similar helpers in the exportWhiteboard script,
 * but we don't want to import the compiled strings there (since they don't exist at build time).
 * So we have alternative implementations of these helpers in `exportWhiteboard/angularGettext.js`
 * that are set on the global scope in the exportWhiteboard script instead of the helpers below.
 *
 * It's a bit dirty, but I couldn't find a more elegant solution.
 */

import '../../../../main/locale/angular-gettext';

// This is a "marker function", it can be used anywhere in the code to mark a string as needing
// translation.
window.gettext = angular.identity;

/**
 * Helper object for lazy translation: instead of passing a string with tokens to a
 * marker function and blindly inserting the token values at runtime, create an
 * object that stores both template and token map.
 *
 * @param {String} template - A string that has been marked for translation with the gettext
 *                            marker function
 * @param {Object} tokenMap - An object that maps all {{ key }} tokens in the templat string
 *                            to values
 */
class GettextTemplate {
  constructor(template, tokenMap) {
    this.template = template;
    this.tokenMap = tokenMap;
  }

  /**
   * Return the english version of the token-substituted template
   */
  get string() {
    let currentLanguage = gettextCatalog.currentLanguage;
    // Hacky: normally you would do gettextCatalog.setCurrentLanguage('en')
    // but this broadcasts an event on $rootScope and we don't want that
    gettextCatalog.currentLanguage = 'en';
    let string = this.translated;
    gettextCatalog.currentLanguage = currentLanguage;
    return string;
  }

  /**
   * Get the resulting string, in the language that has been set in LanguageService
   */
  get translated() {
    return gettextCatalog.getString(this.template, this. tokenMap);
  }
}
window.GettextTemplate = GettextTemplate;
