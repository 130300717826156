import Model from 'utils/models';
import * as fields from 'utils/fields';


export class Organization extends Model {
  static get fields() {
    return {
      adminAccessLevel: new fields.StringField(),
      allowEditAppointments: new fields.BooleanField(),
      allowEditMeetings: new fields.BooleanField(),
      allowTeamAccessLevelOverride: new fields.BooleanField(),
      brandColor: new fields.StringField(),
      // cameraBackgroundImage:
      // customDomain:
      defaultTeamAccessLevel: new fields.AccessLevelField(),
      hasOneActiveMember: new fields.BooleanField(),
      isSolo: new fields.BooleanField(),
      liveMeetingRoomsOverviewEnabled: new fields.BooleanField(),
      // logo:
      name: new fields.StringField(),
      needsOnboarding: new fields.BooleanField(),
      // parent: null
      permanentInviteLinkEnabled: new fields.BooleanField(),
      permanentInviteUrl: new fields.StringField(),
      schedulingEnabled: new fields.BooleanField(),
      showTeamMembersNonAdmin: new fields.BooleanField(),
      slug: new fields.StringField(),
      storageTotal: new fields.NumberField(),
      storageUsed: new fields.NumberField(),
      // stripeConfig:
      // subscription:
      teamWaitingRoomEnabled: new fields.BooleanField(),
      teamWaitingRoomUrl: new fields.StringField(),
      // teamleaderConfig:
      // thirdPartyProviderData:
      // whitelabelSettings:
    };
  }

  static get basePath() {
    return 'organizations';
  }

  get hasActiveTeamleaderIntegration() {
    return !!(
      this.teamleaderConfig && this.teamleaderConfig.isActive && !this.teamleaderConfig.isExpired
      || this.thirdPartyProviderData.teamleader && this.thirdPartyProviderData.teamleader.id
    );
  }
}
