import Model from 'utils/models';
import * as fields from 'utils/fields';


export class EmailTemplate extends Model {
  static get fields() {
    return {
      subject: new fields.StringField(),
      text: new fields.StringField(),
    };
  }
}
