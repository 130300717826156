import DragListener from '../util/DragListener';

OnDragDirective.$inject = [
  '$parse',
];

export default function OnDragDirective(
  $parse
) {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attr) {
      new DragListener($elem[0], {
        onStart: onStart,
        onDrag: onDrag,
        onStop: onStop,
      });

      function onStart(pointers) {
        $parse($attr.onDragStart)($scope, { pointers: pointers });
      }
      function onDrag(move) {
        $parse($attr.onDrag)($scope, { move: move });
      }
      function onStop() {
        $parse($attr.onDragStop)($scope);
      }
    }
  };
}
