import template from './notifications.html?raw';


class NotificationController {
  static get $inject() {
    return [
      'notificationService',
    ];
  }

  constructor(
    notificationService
  ) {
    this.notificationService = notificationService;
  }

  get notifications() {
    return this.notificationService.notifications;
  }
}


export default {
  controller: NotificationController,
  controllerAs: 'notificationCtrl',
  template,
};
