<!-- Two empty questions as a placeholder while loading -->
<ng-container *ngIf="state !== 'ready'">
  <div *ngFor="let i of [1, 2]" class="contact-form-config-questions__question">
    <div class="contact-form-config-questions__question-header">
      <div class="d-flex align-items-center">
        <div class="contact-form-config-questions__drag-handle">
          <svg-icon class="icon text-neutral-500" [svgName]="'drag'" [svgStyle]="'filled'"></svg-icon>
        </div>
        <div class="btn btn--square disabled"></div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="state === 'ready'">
  <settings-non-field-errors
    [nonFieldErrors]="teamleaderMappingNonFieldErrors"
  ></settings-non-field-errors>

  <div
    cdkDropList
    cdkDropListLockAxis="y"
    (cdkDropListDropped)="drop($event)"
    class="contact-form-config-questions"
  >
    <div
      *ngFor="let interfaceQuestion of interfaceQuestions"
      cdkDrag
      cdkDragBoundary=".contact-form-config-questions"
      [cdkDragDisabled]="interfaceQuestion.isSelected || !hasPermissionToEdit"
    >

      <div class="contact-form-config-questions__drag-placeholder" *cdkDragPlaceholder>
        <div class="btn btn--square hidden"></div>
      </div>

      <div
        class="contact-form-config-questions__question"
        [class.clickable]="!interfaceQuestion.isSelected"
      >
        <div
          class="contact-form-config-questions__question-header"
          (click)="!interfaceQuestion.isSelected && setSelected(interfaceQuestion)"
        >
          <div class="flex-1 d-flex align-items-center mr--2">
            <div
              class="contact-form-config-questions__drag-handle"
              cdkDragHandle
              *ngIf="hasPermissionToEdit"
              [stopPropagation]="'click'"
            >
              <svg-icon class="icon text-neutral-500" [svgName]="'drag'" [svgStyle]="'filled'"></svg-icon>
            </div>

            <settings-icon
              *ngIf="hasErrors(interfaceQuestion)"
              [settingsIconType]="'invalid'"
              class="mr-3"
            ></settings-icon>

            <div class="text-h3 d-flex flex-row mr-2">
              <span class="line-clamp-2">
                <span *ngIf="interfaceQuestion.instance.label">{{ interfaceQuestion.instance.label }}</span>
                <span *ngIf="!interfaceQuestion.instance.label" i18n data-testid="contactFormsConfigNewQuestionBox">New question</span>
                <span *ngIf="interfaceQuestion.instance.required" class="text-error-400">*</span>
              </span>
              <div
                *ngIf="shouldWarnAboutDowngrade(interfaceQuestion)"
                class="ml-2"
                [matTooltip]="downgradeTooltip"
              >
                <svg-icon class="icon text-error-400" [svgName]="'warning_badged'" [svgStyle]="'filled'" ></svg-icon>
              </div>
            </div>

            <!-- Hide instead of remove so we don't change anything about the alignment -->
            <div
              class="btn btn--square ml-auto mr-2"
              [class.hidden]="interfaceQuestion.isSelected"
            >
              <svg-icon class="btn__icon" [svgName]="'edit'"></svg-icon>
            </div>

            <!-- Kebab menu -->
            <div
              class="btn btn--square"
              [cdkMenuTriggerFor]="actions"
              [cdkMenuTriggerData]="{ question: interfaceQuestion }"
              [stopPropagation]="'click'"
            >
              <svg-icon class="icon" [svgName]="'more'"></svg-icon>
            </div>
          </div>
        </div>

        <div
          class="contact-form-config-questions__question-body"
          *ngIf="interfaceQuestion.isSelected"
          data-testid="contactFormsConfigQuestionBody"
        >
          <form (ngSubmit)="confirmEditing(interfaceQuestion)">
            <settings-non-field-errors
              [nonFieldErrors]="interfaceQuestion.errors.nonFieldErrors"
            ></settings-non-field-errors>

            <div class="mb-4">
              <contact-form-config-question
                [contactForm]="instance"
                [question]="interfaceQuestion.instance"
                [errors]="interfaceQuestion.errors"
                [hasPermissionToEdit]="hasPermissionToEdit"
                [showTeamleaderFieldMapping]="shouldShowTeamleaderFieldMapping"
              ></contact-form-config-question>
            </div>

            <div class="d-flex mr--2" *ngIf="hasPermissionToEdit">
              <div
                *ngIf="!interfaceQuestion.instance.isDefault"
                class="d-sm-none btn btn--outline-error mr-2"
                (click)="delete(interfaceQuestion)"
                i18n
              >
                Delete
              </div>
              <div
                class="btn btn--secondary ml-auto mr-2"
                (click)="cancelEditing(interfaceQuestion)"
                i18n
              >
                Cancel
              </div>
              <div
                data-testid="contactFormConfigQuestionSave"
                class="btn btn--primary mr-2"
                (click)="confirmEditing(interfaceQuestion)"
              >
                <span class="d-none d-sm-inline" i18n>Save question</span>
                <span class="d-sm-none" i18n>Save</span>
              </div>
            </div>

            <div class="d-flex mr--2" *ngIf="!hasPermissionToEdit">
              <div
                class="btn btn--secondary ml-auto mr-2"
                (click)="cancelEditing(interfaceQuestion)"
                i18n
              >
                Close
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="btn btn--secondary"
    *ngIf="!isEditingNewQuestion && hasPermissionToEdit"
    (click)="add()"
  >
    <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
    <span i18n>Add question</span>
  </div>
</ng-container>


<ng-template #actions let-question="question">
  <div class="dropdown" cdkMenu>
    <div
      *ngIf="question.instance.id"
      [copyText]="question.instance.id"
      class="dropdown__list-item"
      cdkMenuItm
    >
      <svg-icon class="btn__icon" [svgName]="'link'"></svg-icon>
      <span i18n>Copy question id</span>
    </div>
    <div
      *ngIf="!question.instance.id"
      class="dropdown__list-item disabled"
      matTooltip="You need to save the form before you can copy this question id"
      i18n-matTooltip
      cdkMenuItm
    >
      <svg-icon class="btn__icon" [svgName]="'link'"></svg-icon>
      <span i18n>Copy question id</span>
    </div>

    <div
      *ngIf="hasPermissionToEdit && !question.instance.isDefault"
      (click)="hasPermissionToEdit && delete(question); $event.stopPropagation()"
      class="dropdown__list-item text-error-400"
      cdkMenuItm
    >
      <svg-icon class="btn__icon" [svgName]="'trash'"></svg-icon>
      <span i18n>Delete question</span>
    </div>
  </div>
</ng-template>
