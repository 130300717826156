import { array, EventEmitter } from 'utils/util';


const MIMETYPE = 'video/webm;codecs=vp8';
const TIMESLICE = 20 * 1000;
const MAX_DURATION = 2 * 60 * 60 * 1000;  // 2 hours
const VIDEO_BITS_PER_SECOND = 800000;

export const State = Object.freeze({
  INACTIVE: 'inactive',
  PAUSED: 'paused',
  RECORDING: 'recording',
});


export default class Recorder {
  static get State() {
    return State;
  }


  constructor(videoStream, audioContext) {
    this._bind();
    EventEmitter.setup(this, ['state', 'stop', 'chunk', 'cut']);

    this.intervals = [];
    this.durationAtLastCut = 0;

    this.videoStream = videoStream;
    this.audioStreams = {};

    this.audioContext = audioContext;
    this.outputNode = this.audioContext.createMediaStreamDestination();
    this.recorderMediaStream = this.outputNode.stream;
    this.stopDefer = null;

    // Make sure there is always an audio track playing. If there isn't some players cut away the
    // silent parts and put the audio parts back to back. Remuxing always seems to do this as well.
    this.constantSource = new ConstantSourceNode(this.audioContext, { offset: 0 });  // eslint-disable-line compat/compat, max-len
    this.constantSource.connect(this.outputNode);
    this.constantSource.start();

    let videoTrack = this.videoStream.getVideoTracks()[0];
    this.recorderMediaStream.addTrack(videoTrack);

    this.recorderMediaStream.getTracks().forEach(track => {
      track.addEventListener('ended', this._onTrackEnded);
    });

    this._createRecorder();
  }

  _bind() {
    this._onTrackEnded = this._onTrackEnded.bind(this);
    this._onDataAvailable = this._onDataAvailable.bind(this);
    this._onStop = this._onStop.bind(this);
    this._onStopMakeCut = this._onStopMakeCut.bind(this);
  }


  get state() {
    return this.recorder.state;
  }

  get duration() {
    let durations = this.intervals.map(([start, end]) => {
      if(end == null) {
        end = Date.now();
      }
      return end - start;
    });

    return array.sum(durations);
  }

  get lastActive() {
    return this.state === Recorder.State.RECORDING ?
      Date.now() :
      this.intervals[this.intervals.length - 1][1];
  }


  _emitState() {
    this.emit('state', this.state);
  }


  _createRecorder() {
    let options = {
      mimeType: MIMETYPE,
      ignoreMutedMedia: false,
      videoBitsPerSecond: VIDEO_BITS_PER_SECOND,
    };
    this.recorder = new MediaRecorder(this.recorderMediaStream, options);  // eslint-disable-line compat/compat, max-len
    this.recorder.addEventListener('dataavailable', this._onDataAvailable);
    this.recorder.addEventListener('stop', this._onStop);
  }


  addAudioStream(stream) {
    let sourceNode = this.audioContext.createMediaStreamSource(stream.mediaStream);
    sourceNode.connect(this.outputNode);
    this.audioStreams[stream.id] = {
      stream: stream,
      sourceNode: sourceNode,
    };
  }


  removeAudioStream(stream) {
    this.audioStreams[stream.id].sourceNode.disconnect();
    delete this.audioStreams[stream.id];
  }


  start() {
    if(this.recorder.state !== State.INACTIVE) {
      return;
    }

    this.intervals.push([Date.now(), null]);
    this.recorder.start(TIMESLICE);
    this._emitState();
  }


  pause() {
    if(this.recorder.state !== State.RECORDING) {
      return;
    }

    this.intervals[this.intervals.length - 1][1] = Date.now();
    this.recorder.pause();
    this._emitState();
  }

  resume() {
    if(this.recorder.state !== State.PAUSED) {
      return;
    }

    this.intervals.push([Date.now(), null]);
    this.recorder.resume();
    this._emitState();
  }


  stop() {
    if(this.recorder.state === State.INACTIVE) {
      return $q.resolve();
    }

    if(!this.stopDefer) {
      this.stopDefer = $q.defer();
    }

    this.intervals[this.intervals.length - 1][1] = Date.now();
    this.recorder.stop();
    return this.stopDefer.promise;
  }


  makeCut() {
    if(this.recorder.state === State.INACTIVE || this.stopDefer) {
      return;
    }

    this.recorder.removeEventListener('stop', this._onStop);
    this.recorder.addEventListener('stop', this._onStopMakeCut.bind(this, this.state));
    this.recorder.stop();
  }


  _onDataAvailable(event) {
    this.emit('chunk', event.data);
    if(this.duration - this.durationAtLastCut > MAX_DURATION) {
      this.makeCut();
    }
  }


  _onTrackEnded() {
    this.stop();
  }

  _onStop() {
    if(this.intervals[this.intervals.length - 1][1] == null) {
      this.intervals[this.intervals.length - 1][1] = Date.now();
    }

    Object.values(this.audioStreams).forEach(streamInfo => {
      this.removeAudioStream(streamInfo.stream);
    });
    this.recorderMediaStream.getTracks().forEach(track => track.stop());


    this._emitState();
    this.emit('stop');
    if(this.stopDefer) {
      this.stopDefer.resolve();
      this.stopDefer = null;
    }
  }


  _onStopMakeCut(stateBeforeCut) {
    this.durationAtLastCut = this.duration;

    this.recorder.removeEventListener('dataavailable', this._onDataAvailable);

    this.emit('cut');

    this._createRecorder();
    if(stateBeforeCut === State.RECORDING) {
      this.recorder.start(TIMESLICE);
    }
  }
}
