import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { LocalStateService } from 'utils/state.service';
import {
  MeetingTemplate, OrganizationMeetingTemplate
} from 'meeting/models/MeetingTemplate';
import {
  Meeting
} from 'meeting/models/Meeting';
import { ComboBoxOption, DropdownOption } from 'utils/form-elements/dropdowns';
import { string } from 'utils/util';
import { Errors } from 'utils/settings/settings.component';
import { RequestUserService } from 'utils/requestUser.service';
import { ModalService } from 'utils/ui-components/modal';
import { AdminPulseService } from 'integrations/adminpulse/adminpulse.service';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';


@Component({
  selector: 'add-room-modal',
  templateUrl: './add-room-modal.component.html',
})
export class AddRoomModalComponent implements OnInit {

  public errors: Errors = {};

  public key?: string;
  public templateOptions: DropdownOption[] = [];
  public selectedTemplate: MeetingTemplate = null;

  public selectedRelation: string | null = null;
  public parsedAdminPulseOptions: ComboBoxOption[] = [];


  @Input() initMeetingKey?: string;
  @Input() initMeetingTemplate?: string;
  @Input() initAdminPulseRelation?: string;
  @Input() adminPulseOptions?: ComboBoxOption[] | undefined;

  @Output() meetingRoomCreated = new EventEmitter<Meeting>();

  constructor(
    public stateService: LocalStateService,
    public requestUserService: RequestUserService,
    public modalService: ModalService,
    private modalElementRef: ElementRef<HTMLElement>,
    public adminPulseService: AdminPulseService,

    @Inject('notificationService') private notificationService,
    @Inject('modelFactory') private modelFactory,
  ) {

    this.stateService.setLoading();
    const promises = [this.fetchTemplates()];
    if(this.adminPulseService.isConnected) {
      promises.push(this.fetchAdminPulseOptions());
    }
    Promise.all(promises).then(() => this.stateService.setReady);
  }

  ngOnInit(): void {
    if(this.initMeetingKey) {
      this.key = this.initMeetingKey;
    }
  }

  private async fetchAdminPulseOptions() {
    if(!this.adminPulseOptions) {
      const relations = await this.adminPulseService.fetchAdminPulseRelations();
      this.parsedAdminPulseOptions = this.adminPulseService.getRelationComboBoxOptions(relations);
    } else {
      this.parsedAdminPulseOptions = this.adminPulseOptions;
    }
    if(this.initAdminPulseRelation) {
      this.selectedRelation = this.initAdminPulseRelation;
    }
  }

  private async fetchTemplates() {
    const modelConfig = {
      model: OrganizationMeetingTemplate,
      identifiers: { organizationId: this.requestUserService.user.organization.id },
    };

    const { data: meetingTemplates } = await this.modelFactory.list(modelConfig);
    this.templateOptions = meetingTemplates.map(template => {
      return {
        label: template.name,
        value: template,
      };
    });

    if(this.initMeetingTemplate) {
      this.selectedTemplate = meetingTemplates.find(
        template => template.id === this.initMeetingTemplate
      );
    }
  }


  create() {
    // The API allows creating a meeting room without a key (in which case it autogenerates one),
    // but we don't want to allow that here.
    if(!this.key) {
      this.errors.key = [$localize `Please choose a name for your meeting room.`];
      return;
    }

    const meeting = this.modelFactory.createInstance(Meeting);
    meeting.key = string.slugify(this.key);
    if(this.selectedRelation != null) {
      meeting.adminpulseRelation = this.selectedRelation;
    }
    if(this.selectedTemplate != null) {
      meeting.template = this.selectedTemplate;
    }
    meeting.save()
      .then(response => {
        const meeting = response.data as Meeting;
        this.meetingRoomCreated.emit(meeting);
        this.modalService.closeNearest(this.modalElementRef.nativeElement);
      })
      .catch(error => {
        this.errors = this.notificationService.handleError(
          error,
          $localize `Something went wrong while creating your meeting room.`,
          'none',
        );
      });
  }

  handleRelationSelected() {
    if(this.selectedRelation == null || this.key) {
      return;
    }
    const option = this.parsedAdminPulseOptions.find(o => o.value === this.selectedRelation);
    if(option != null) {
      this.key = option.label;
    }
  }

  get shouldShowAccessLevelOverrideWarning() {
    const defaultLevel = this.requestUserService.user.organization.defaultTeamAccessLevel.level;

    return (
      this.selectedRelation != null
      && defaultLevel !== AccessLevel.IS_HOST.level
    );
  }
}
