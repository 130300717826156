import template from './footer.html?raw';

import { Rect } from 'utils/util';
import { FooterPhoneController } from './footerPhone.component';

const NUM_COLLAPSE_GROUPS = 4;


class FooterController extends FooterPhoneController {
  static get $inject() {
    return [
      '$element',
      'languageService',
      'privateNotesService',
      'tileService',
      'tileRendererDesktopService',
      'shortcutService',
    ].concat(FooterPhoneController.$inject);
  }

  constructor(
    $elem,
    languageService,
    privateNotesService,
    tileService,
    tileRendererDesktopService,
    shortcutService,
    ...args
  ) {
    super(...args);

    this.$elem = $elem;
    this.languageService = languageService;
    this.privateNotesService = privateNotesService;
    this.tileService = tileService;
    this.tileRendererDesktopService = tileRendererDesktopService;
    this.shortcutService = shortcutService;

    this.$elemFooter = $elem.find('.meeting-footer');

    this.collapsed = Array(NUM_COLLAPSE_GROUPS).fill(false);
    this._updateCollapsedElemsAfterTimeout = () => $timeout(this._updateCollapsedElems);

    this.tooltips = {};
    this.languageService.on('language', this._updateTooltips);
    this.languageService.on('language', this._updateCollapsedElemsAfterTimeout);
    this._updateTooltips();
  }


  $onInit() {
    this.tileRendererDesktopService.on('draw', this._updateCollapsedElems);
    this.privateNotesService.on('enabled', this._updateCollapsedElemsAfterTimeout);
    this._updateCollapsedElemsAfterTimeout();
  }

  $onDestroy() {
    this.tileRendererDesktopService.off('draw', this._updateCollapsedElems);
    this.privateNotesService.off('enabled', this._updateCollapsedElemsAfterTimeout);
  }

  get shouldShowPoweredBy() {
    return this.meetingService.settings.whitelabel.showPoweredBy;
  }

  get utmCampaign() {
    return this.meetingService.settings.whitelabel.utmCampaign;
  }
  get utmTags() {
    return `?utm_medium=referral&utm_source=meeting+page&utm_campaign=${this.utmCampaign}`;
  }

  /**
   * The cam button needs to be the same width regardless of the text; the html template does
   * this by having a hidden element that is filled with the longest string of the two states.
   *
   * @returns {string}
   */
  get longestCamString() {
    let camon = gettextCatalog.getString('Cam on');
    let camoff = gettextCatalog.getString('Cam off');
    return camoff.length > camon.length ? camoff : camon;
  }

  /**
   * The mic button needs to be the same width regardless of the text; the html template does
   * this by having a hidden element that is filled with the longest string of the two states.
   *
   * @returns {string}
   */
  get longestMicString() {
    let micon = gettextCatalog.getString('Mic on');
    let micoff = gettextCatalog.getString('Mic off');
    return micoff.length > micon.length ? micoff : micon;
  }

  _updateTooltips() {
    /* eslint-disable max-len */
    this.tooltips = {
      enableCam: gettextCatalog.getString('Enable camera') + ` (${this.shortcutService.getPrettyString('alt+v')})`,
      disableCam: gettextCatalog.getString('Disable camera') + ` (${this.shortcutService.getPrettyString('alt+v')})`,

      enableMic: gettextCatalog.getString('Enable microphone') + ` (${this.shortcutService.getPrettyString('alt+a')})`,
      disableMic: gettextCatalog.getString('Disable microphone') + ` (${this.shortcutService.getPrettyString('alt+a')})`,

      settings: gettextCatalog.getString('Settings'),
      switchView: gettextCatalog.getString('Switch to mobile view'),
      recordings: gettextCatalog.getString('Recordings'),
      invite: gettextCatalog.getString('Invite'),

      raiseHand: gettextCatalog.getString('Raise Hand') + ` (${this.shortcutService.getPrettyString('alt+h')})`,
      lowerHand: gettextCatalog.getString('Lower Hand') + ` (${this.shortcutService.getPrettyString('alt+h')})`,

      chat: gettextCatalog.getString('Chat') + ` (${this.shortcutService.getPrettyString('alt+c')})`,
      privateNotes: gettextCatalog.getString('Private notes')
    };
    /* eslint-enable max-len */
  }

  /**
   * update the footer "groups" to collapse one by one
   *   0: logo and icon (if present)
   *   1: cam, mic, leave
   *   2: settings, recordings, invite
   *   3: chat, private notes
   */
  _updateCollapsedElems() {
    let $elemGroups = [];
    for(let i = 0; i < NUM_COLLAPSE_GROUPS; i++) {
      this.collapsed[i] = false;
      let $elemGroup = this.$elemFooter.find(`[footer-collapse-group="${i}"]`);
      this._spread($elemGroup);
      $elemGroups.push($elemGroup);
    }

    for(let i = 0; i < NUM_COLLAPSE_GROUPS; i++) {
      if(!this._isOverflowing()) {
        break;
      }
      this.collapsed[i] = true;
      this._collapse($elemGroups[i]);
    }
  }


  _isOverflowing() {
    let rectFooter = Rect.fromElem(this.$elemFooter[0]);

    let items = this.$elemFooter.find('.meeting-footer__item');
    let rectLastVisibleItem;
    for(let i = items.length - 1; i >= 0; i--) {
      let rect = Rect.fromElem(items[i]);
      if(rect.width > 0) {
        rectLastVisibleItem = rect;
        break;
      }
    }

    if(!rectLastVisibleItem) {
      return false;
    }
    return rectLastVisibleItem.right > rectFooter.right;
  }


  _spread($elems) {
    this._toggleCollapseClass($elems, false);
  }
  _collapse($elems) {
    this._toggleCollapseClass($elems, true);
  }

  _toggleCollapseClass($elems, toggle) {
    $elems.each((index, elem) => {
      let $elem = $(elem);
      let collapseClass = $elem.attr('footer-collapse-class');
      $elem.toggleClass(collapseClass, toggle);
    });
  }
}

export default {
  controller: FooterController,
  controllerAs: 'footerCtrl',
  template,

  bindings: {
    translucent: '<',
  },
};
