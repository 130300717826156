import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';


/**
 * An implementation of ControlValueAccessor that simple form elements can inherit from.
 *
 * Child classes should:
 * - Call `writeValue` when setting the value from the view.
 * - Respect the `disabled` property for the disabled state of the form element.
 * - Call `onTouched` when the user form element is blurred (or equivalent).
 */
@Directive()
export abstract class SimpleControlValueAccessor<T> implements ControlValueAccessor {
  @Input() disabled = false;
  public value?: T;
  private onChange: (value: T) => void = () => {};
  protected onTouched = () => {};


  writeValue(value: T) {
    if(value === this.value) {
      return;
    }
    this.value = value;
    this.onChange(value);
  }


  registerOnChange(fn: (value: T) => void) {
    this.onChange = fn;
  }


  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }


  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
