<div class="container">
  <div class="dashboard__loading" *ngIf="!stateService.isReady"></div>

  <go-back-header
    [url]="urlService.urls.integrationsOverview"
    headerText="Back to integrations"
    i18n-headerText
  ></go-back-header>

  <h1>AdminPulse</h1>

  <div>
    <div class="card">
      <div class="d-flex align-items-center mb-12">
        <svg-icon svgPath="integrations__adminpulse_logo" class="adminpulse-config__logo"></svg-icon>
        <div>
          <p i18n>The AdminPulse integration allows you to send meeting data to AdminPulse and register the billable time that was spent by your team members on meetings with your AdminPulse relations.</p>

          <p i18n>
            On this page you can connect the integration and configure which price list item is used for each team member to bill the hours spent during a meeting with their AdminPulse relations.
          </p>
          <p data-testid="helpArticles" i18n>
            Learn more about <a [href]="configHelpArticle" target="_blank" class="text-base text-normal"> setting up the integration</a> and <a [href]="ttHelpArticle" target="_blank" class="text-base text-normal"> the way time registration works</a>.
          </p>
        </div>
      </div>

      <loading *ngIf="!stateService.isReady"></loading>

      <div *ngIf="stateService.isReady">
        <div
          *ngIf="!canConnect"
          class="field field--warning mb-5"
        >
          <div i18n>To enable the AdminPulse integration, you must allow the overriding of team access level for individual meeting rooms <a [href]="urlService.urls.teamSettings">in the team settings</a>.</div>
        </div>

        <div *ngIf="adminPulseConfig == null && !adminPulseService.isExpired">
          <a
            class="btn btn--primary"
            [class.disabled]="!canConnect"
            (click)="canConnect && goToConnectUrl()"
            data-testid="connectButton"
            i18n
          >
            Connect
          </a>
        </div>

        <div
          *ngIf="adminPulseService.isExpired"
          class="mb-5"
        >
          <div class="field field--warning" i18n>Your AdminPulse account was disconnected. Please reconnect to keep using the AdminPulse integration.</div>
          <div class="mt-2">
            <a
              class="btn btn--primary"
              [class.disabled]="!canConnect"
              (click)="canConnect && goToConnectUrl()"
              i18n
            >
              Reconnect
            </a>
          </div>
        </div>

        <div *ngIf="adminPulseConfig && !adminPulseService.isExpired">
          <div class="mb-6">
            <span i18n>Connected account:</span>
            <span class="badge">{{ adminPulseConfig.userEmail }}</span>
          </div>

          <div class="form-group mb-6">
            <h2 i18n>Price list</h2>

            <div
              *ngIf="shouldWarnDefaultNotPresent"
              class="field field--warning mb-5"
            >
              <field-icon [type]="'warning'"></field-icon>
              <span i18n>It seems that your previously configured default price list item has been deleted. Please make sure that you select an appropriate new default before saving your configuration</span>
            </div>

            <table class="table table--hoverable mb-4">
              <tbody class="border-none">
                <tr>
                  <td>
                    <div class="d-flex align-items-baseline">
                      <b i18n>Default</b>
                      <help-tooltip
                        text="This price list item will be used if a user is configured to use the default price list item."
                        i18n-text
                      ></help-tooltip>
                    </div>
                  </td>
                  <td>
                    <dropdown-select
                      [options]="defaultPriceListOptions"
                      [(ngModel)]="adminPulseConfig.defaultPricelistItemId"
                      [nullable]="true"
                      textIfNull="Don't register time"
                      i18n-textIfNull
                      data-testid="defaultPriceListDropdown"
                    ></dropdown-select>
                  </td>
                </tr>

                <tr *ngFor="let member of teamMembers">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="flex-none mr-4">
                        <profile-image [user]="member"></profile-image>
                      </div>
                      <div>{{ member.fullName }}</div>
                    </div>
                  </td>
                  <td>
                    <dropdown-select
                      [options]="priceListOptions"
                      [(ngModel)]="priceMapping[member.id]"
                      [nullable]="true"
                      textIfNull="Don't register time"
                      i18n-textIfNull
                      data-testid="memberPriceListDropdown"
                    ></dropdown-select>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              *ngIf="emailAddressHint.shouldShow"
              class="field field--info d-flex align-items-center"
            >
              <svg-icon class="icon mr-4" svgName="info" svgStyle="badged_outline"></svg-icon>
              <div i18n>
                We assume that your team will use the same email address for their AdminPulse and Vectera accounts.
              </div>
              <div class="btn btn--round ml-auto" (click)="emailAddressHint.setSeen()">
                <svg-icon class="btn__icon" svgName="close"></svg-icon>
              </div>
            </div>
          </div>

          <div
            class="d-flex flex-row justify-content-end"
            *ngIf="adminPulseConfig && !adminPulseConfig.isExpired"
          >
            <div
              class="btn btn--error mr-2"
              [dialog]="disconnectConfirmation"
              dialogTitle="Disconnect integration"
              i18n-dialogTitle
              dialogConfirmText="Disconnect"
              i18n-dialogConfirmText
              dialogConfirmButtonClass="btn--error"
              [dialogConfirmedCallback]="disconnect"
              data-testid="disconnectButton"
              i18n
            >
              Disconnect
            </div>

            <ng-template #disconnectConfirmation>
              <p i18n>Are you sure you want to disconnect AdminPulse? The connection with AdminPulse will be lost and we will no longer add time registrations for conducted meetings.</p>
            </ng-template>

            <div
              class="btn btn--primary"
              (click)="submit()"
              data-testid="saveButton"
            >
              <span *ngIf="!stateService.isSaving" i18n>Save</span>
              <span *ngIf="stateService.isSaving" i18n>Saving...</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
