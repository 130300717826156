import BaseLocalRecording from '../../shared/recordings/BaseLocalRecording.js';


export default class MeetingLocalRecording extends BaseLocalRecording {
  constructor(
    userService,
    streamService,
    ...args
  ) {
    super(...args);
    this._bind();

    this.userService = userService;
    this.streamService = streamService;
  }

  get uploadMessage() {
    let uploadMessage = super.uploadMessage;

    /* eslint-disable max-len */
    if(this._shouldShowUploadWarning) {
      uploadMessage += `
        <p class="field field--warning" translate>
          You are still in a call. If your audio/video quality is suffering, we recommend uploading the recording after the meeting is over.
        </p>
      `;
    }
    /* eslint-enable max-len */

    return uploadMessage;
  }

  // Recording upload can happen outside of the meeting room, so we need an extra
  // check on the current session state of this user.
  get _shouldShowUploadWarning() {
    return (
      this.userService.mySession.isAlive()
      && this.streamService.streams.find(stream => !stream.isLocal)
    );
  }
}
