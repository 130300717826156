import { marked } from 'marked';
import { string } from 'utils/util';

marked.setOptions({
  mangle: false,
  breaks: true,
});

const TEMPLATE_PREVIEW = `
<div ng-bind-html="previewText" class="mt-2 text-muted text-break"></div>
`;

MarkDownPreviewDirective.$inject = [
  '$compile',
];

export default function MarkDownPreviewDirective(
  $compile
) {
  return {
    restrict:'A',
    scope: true,
    link: function($scope, $elem) {
      $elem.on('change input', () => $scope.$evalAsync(update));
      update();

      $scope.showText  = true;
      let $elemPreview = $compile(TEMPLATE_PREVIEW)($scope);
      $elem.parents('.form-group').append($elemPreview);

      function update() {
        let text = $elem.val();
        if(text) {
          // The newline is used to make sure the first line is rendered correctly, eg. if you
          // enter the text `# Test`.
          let escapedText = string.escapeHTML(text);
          $scope.previewText = marked.parse(
            gettextCatalog.getString('Preview') + ': \n' + escapedText
          );
        } else {
          $scope.previewText = '';
        }
      }
    },
  };
}
